
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { highlightsModel } from "../services/models/highlightsModel";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators'; 
import { Globals } from '../globals';
import "rxjs";

const headerJson = {
    // 'Content-Type': 'application/json',
    'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    // 'accept-encoding': 'gzip, deflate, br',
    // 'accept-language': 'en-US,en;q=0.9',
    // 'cache-control': 'no-cache',
    // 'upgrade-insecure-requests': '1',




//     xhr.open("GET", "https://api.iextrading.com/1.0/stock/msft/batch?types=quote%2Cchart&range=1m&last=10");
// xhr.setRequestHeader("accept", "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8");
// xhr.setRequestHeader("accept-encoding", "gzip, deflate, br");
// xhr.setRequestHeader("accept-language", "en-US,en;q=0.9");
// xhr.setRequestHeader("cache-control", "no-cache");
// xhr.setRequestHeader("pragma", "no-cache");
// xhr.setRequestHeader("upgrade-insecure-requests", "1");
// xhr.setRequestHeader("user-agent", "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.100 Safari/537.36");
    };
    const headersConfig = new HttpHeaders(headerJson); 

// const httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };

@Injectable()

export class HighlightsService {
    constructor(private globalVariables: Globals, private httpClient: HttpClient){ }

    getHighlights(optNum,filter): Observable<highlightsModel[]>{
        let apiURL = this.globalVariables.currentAPIUrl;
        let highlightsUrl = this.globalVariables.getUserHighlightsAPI;

        highlightsUrl = highlightsUrl.replace("[param1]",filter);
        highlightsUrl = highlightsUrl.replace("[param2]",optNum);

        try{
            const api = apiURL + highlightsUrl;
            return this.httpClient.get<highlightsModel[]>(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res)));
        }catch(err){
            return err;
        }
    }

    public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
        statusCode: statusCode,
        error: res
        };
        return observableThrowError(error);
    } 

}

