import { InvoiceDescItem, InvoiceProfitCenterList, InvoiceDescList } from "../models/invoicedesc-model";

export namespace InvoiceDescription {
    export class Add{
        static readonly type = '[Invoice Description] Add_Item';
        constructor( public payload: InvoiceDescItem ){
        }
    }

    export class Clear {
        static readonly type = '[Invoice Description] Clear_Items'
    }

    export class Add_Profit_Center {
        static readonly type = '[Invoice Description] Add_Profit_Center';
        constructor( public payload: InvoiceProfitCenterList){
        }
    }

    export class Clear_Profit_Center {
        static readonly type = '[Invoice Description] Clear_Profit_Center';
    }

    export class Add_Invoice_Desc_List {
        static readonly type = '[Invoice Description] Add_Invoice_Desc_List';
        constructor( public payload: InvoiceDescList){
        }
    }

    export class Clear_Invoice_Desc_List {
        static readonly type = '[Invoice Description] Clear_Invoice_Desc_List';
    }
}