import { Component, OnInit, ElementRef } from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from '../../../../../globals';
import { stringify } from 'querystring';
import { SemlinkaccessService } from '../../../../../services/ea/api/SemlinkaccessService';
import { PartnerselectedComponent } from '../partnerselected.component';
import { MatTableDataSource } from '@angular/material/table';
import { PerfunitmodalComponent } from '../../../../../services/ea/shared/perfunitmodal/perfunitmodal.component';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';
import { error } from 'protractor';

  @Component({
    selector: 'app-unitaward',
    templateUrl: './unitaward.component.html',
    styleUrls: ['./unitaward.component.css']
  })

export class UnitawardComponent implements OnInit {
  optNum: number;
  documentId: any //number;
  editPartner: boolean = true;
  hasData: boolean = false;
  searchButton: boolean = true;
  perfUnit: number = 0;
  countryCoeff: number = 0;
  unitAwardHeader: string = "Unit Award";
  perfUnitCashtxt: string = "Performance Units - Cash: ";
  perfUnitEquitytxt: string = "Performance Units - Equity: ";
  perfUnitTotaltxt: string = "Performance Units - Total: ";
  UnitCash: number;
  UnitEquity: number;
  UnitTotal: number;
  coeffCountryLabel: string = "Coefficient Country: ";
  coeffLabel: string = "Coefficient: ";
  coeffPercentLabel: string = "Coefficient %: ";
  actualUnitsLabel: string = "Actual Units: ";
  coeffCountryValue: string;
  coeffValue: number;
  coeffPercentValue: number;
  actualUnitsValue: number;
  startDateDropdownSelect: string;
  minYear: number;
  endDateDropdownSelect: string;
  maxYear: number;
  awardDateDropdownList: any[];
  unitAwardTable: any;
  dataSource: any;
  displayedColumns: any[] = ['DATE_RANGE','AWARD','OFF_CYCLE','JUNE_ADJ','PERF_RATING','LOR','BASE_UNIT',
                                'PERF_UNIT','TRANSITION_UNIT','TOTAL','PASS_CODE','COEFF_COUNTRY','COEFF_EQUITY'];
  DATE_RANGE: string;
  AWARD: number;
  OFF_CYCLE: number;
  JUNE_ADJ: number
  PERF_RATING: string;
  LOR: string;
  BASE_UNIT: number;
  PERF_UNIT: number;
  TRANSITION_UNIT: number;
  TOTAL: number;
  PASS_CODE: string;
  COEFF_COUNTRY: string;
  COEFF_EQUITY: number;
  loading: boolean = true;
  loading1: boolean = true;
  countryCoeffData: any;
  perfUnitData: any;
  rangeError: boolean = false;
  isError: boolean = false;
  subAwardDate: any = [];

  
  constructor(
    private router: Router,
    private modal: MatDialog,
    private apiService: ApiService,
    private global: Globals,
    private semLinkAccessService: SemlinkaccessService,
    private partnerSelected: PartnerselectedComponent,
    private access: SemaccessService
  ) { }

  ngOnInit() {
    this.optNum = parseInt(sessionStorage.getItem("optNum"));
    this.documentId = parseInt(sessionStorage.getItem(this.global.documentID) || '{}');
    this.getAccess();
    this.partnerSelected.loadingEdit = true;
  }
 
/**********************************************************************************
  * Method Name: getAccess
  * Description: Retrieves access from a reasuable service 
  *
  * #         Date                 Modified by                    Description
  * 1         04/23/2020           Almerie T. Dondonay            Initial draft
  * 2         05/07/2020           Almerie T. Dondonay            Change hardcoded URL into Reusable variable.
***********************************************************************************/
    async getAccess() {
      try {
        await this.access.getSemAccess("unitaward").then();
        if (this.access.role != false) {
          this.partnerSelected.searchInput = "";
          if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
            this.partnerSelected.editHistory(this.documentId, this.optNum);
            this.partnerSelected.partnerSelectedInfo(this.optNum);
            if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
              this.access.role['data'][8][0]['ROLE'] == 2 ? this.router.navigate(['sem/pdsa']) : "";
              this.getAwardDate();
            } else {
              this.router.navigate(['equityadmin/eamain']);
            }
          } else {
            this.router.navigate(['equityadmin/eamain']);
          }
        } else {
          this.router.navigate(['equityadmin/eamain']);
        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getAccess', 'unitaward');
        throw error;
      }
    }


/**********************************************************************************
  * Method Name: getAwardDate()
  * Description: get data list for date range
  *
  * #         Date                 Modified by                    Description
  * 1         04/27/2020           Almerie T. Dondonay            Initial draft
  * 2         05/07/2020           Almerie T. Dondonay            Change hardcoded URL into Reusable variable.
***********************************************************************************/
getAwardDate(){
    let argAwardDate: any = {
      "selected_optnum": this.optNum,
      "functionName": "getAwardDate"
    }

    this.apiService.postData(this.global.api_URL_pdsUnit,argAwardDate).subscribe(data => {
      try{
        if (data) {
          let contentBody = data.body;

          for (let x = 0; x < contentBody['data'].length; x++) {
            this.subAwardDate.push({ FISCAL_YEAR: contentBody['data'][x]['FISCAL_YEAR'], START_DATE: contentBody['data'][x]['START_DATE'], END_DATE: contentBody['data'][x]['END_DATE'] })
          }
          this.awardDateDropdownList = this.subAwardDate;

          this.loading = false;
          this.partnerSelected.loadingEdit = false;
          this.global.searchLoading = false;

        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getAwardDate', 'unitaward');
        throw error;
      }
    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message +=this.apiService.setErrorContents('getAwardDate', 'unitaward');
      throw error;
    });
}

/**********************************************************************************
  * Method Name: getStartDate(startDate:string) and getStartDate(startDate:string)                                                                 
  * Description: Condition for date in order to make Search button to enable.                 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         4/20/2020            Almerie T. Dondonay              Initial draft   
***********************************************************************************/
 getStartDate(startDate:string, minYear: number){
   try {
     this.startDateDropdownSelect = startDate;
     this.minYear = minYear
     this.searchButton = true;

     if ((this.startDateDropdownSelect != undefined) && (this.startDateDropdownSelect != null) && (this.startDateDropdownSelect != "")) {
       if ((this.endDateDropdownSelect != undefined) && (this.endDateDropdownSelect != null) && (this.endDateDropdownSelect != "")) {
         this.searchButton = false;
       } else { this.searchButton = true; }
     } else { this.searchButton = true; }
   } catch (error) {
    this.loading = false;
    this.isError = true;
    error.message += this.apiService.setErrorContents('getStartDate', 'unitaward');
     throw error;
   }

}

getEndDate(endDate:string, maxYear: number){
  try {
    this.endDateDropdownSelect = endDate;
    this.maxYear = maxYear;
    this.searchButton = true;


    if ((this.endDateDropdownSelect != undefined) && (this.endDateDropdownSelect != null) && (this.endDateDropdownSelect != "")) {
      if ((this.startDateDropdownSelect != undefined) && (this.startDateDropdownSelect != null) && (this.startDateDropdownSelect != "")) {
        this.searchButton = false;
      } else { this.searchButton = true; }
    } else { this.searchButton = true; }
  } catch (error) {
    this.loading = false;
    this.isError = true;
    error.message += this.apiService.setErrorContents('getEndDate', 'unitaward');
    throw error;
  }

}

/**********************************************************************************
  * Method Name: unitAwardSearch()                                                                
  * Description: for Search button validation.                 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         4/20/2020            Almerie T. Dondonay              Initial draft   
  * 2         5/4/2020             Jikho Enrique                    Added layer of validation to prevent force enablement
***********************************************************************************/
  unitAwardSearch(){
    try {
      if (!this.rangeError && !this.searchButton) {
        if ((this.startDateDropdownSelect != undefined) && (this.endDateDropdownSelect != undefined)) {
          this.getUnitAward();
        }
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('unitAwardSearch', 'unitaward');
      throw error;
    }
    
}

/**********************************************************************************
  * Method Name: getUnitAward()
  * Description: to get data for table display.
  *
  * #         Date                 Modified by                    Description
  * 1         04/27/2020           Almerie T. Dondonay            Initial draft
  * 2         05/07/2020           Almerie T. Dondonay            Change hardcoded URL into Reusable variable.
***********************************************************************************/
getUnitAward(){
  this.hasData = true;
  this.loading1 = true;
    let argUnitAward: any = {
      "selected_optnum": this.optNum,
      "max_year": this.maxYear,
      "min_year": this.minYear,
      "functionName": "getUnitAward"
    }
    this.apiService.postData(this.global.api_URL_pdsUnit,argUnitAward).subscribe(data => {
      try{
        if (data) {
          let contentBody = data.body;

          if (contentBody['data'] != undefined && contentBody['data'] != null) {
            this.dataSource = new MatTableDataSource(contentBody['data']);
            this.unitAwardTable = contentBody['data'];
            if (this.unitAwardTable.length == 0) {
              this.hasData = false;
            }
          }

        }
        this.loading1 = false;   
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getUnitAward', 'unitaward');
        throw error;
      }
    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message +=this.apiService.setErrorContents('getUnitAward', 'unitaward');
      throw error;
    });
}

/**********************************************************************************
    * Method Name: unitAwardPerfUnits(type, modalHeader) and unitAwardCountryCoeff(type, modalHeader)
    * Description: for modal vdisplay.
    *
    * #         Date                 Modified by                    Description
    * 1         04/23/2020           Almerie T. Dondonay            Initial draft
***********************************************************************************/
unitAwardPerfUnits(type, modalHeader, fiscalyr){
  // Perf Units 
  try {
    this.modal.open(PerfunitmodalComponent, {
      panelClass: 'perfUnit-modal',
      disableClose: true,
      data: [{data: this.perfUnitData, type:type, header:modalHeader, fiscalYear: fiscalyr}]
    });
  } catch (error) {
    this.loading = false;
    this.isError = true;
    error.message += this.apiService.setErrorContents('unitAwardPerfUnits', 'unitaward');
    throw error;
  }
}

unitAwardCountryCoeff(type, modalHeader, fiscalyr){
  // CoEff
  try {
    let content: any = {
      header: this.unitAwardHeader,
    }
    this.modal.open(PerfunitmodalComponent, {
      panelClass: 'perfUnit-modal',
      disableClose: true,
      data: [{ data: this.countryCoeffData, type:type, header:modalHeader, fiscalYear: fiscalyr }]
    });
  } catch (error) {
    this.loading = false;
    this.isError = true;
    error.message += this.apiService.setErrorContents('unitAwardCountryCoeff', 'unitaward');
    throw error;
  }
  }

  checkDate(){
    try {
      if (this.startDateDropdownSelect != undefined && this.endDateDropdownSelect != undefined) {
        let end = new Date(this.endDateDropdownSelect).getTime();
        let start = new Date(this.startDateDropdownSelect).getTime();
        let isValid = end - start;
        if (isValid < 0) {
          this.rangeError = true;
          this.searchButton = true;
        } else {
          this.rangeError = false;
          this.searchButton = false;
        }
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('checkDate', 'unitaward');
      throw error;
    }

  }

 }
