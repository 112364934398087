import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { DatePipe } from "@angular/common";
import { StaticContentService } from '../services/StaticContentService';
import { UserService } from '../services/UserService';
import { AppComponent } from '../app.component';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators'; 
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { HoldingProgramDetailsFounderService } from '../holdingprogramdetailspage-founder/HoldingProgramDetailsFounderService';



@Component({
  selector: 'app-profilepage',
  templateUrl: './profilepage.component.html',
  styleUrls: ['./profilepage.component.css'],
  providers: [Globals]
})
export class ProfilepageComponent implements OnInit {
 // userProfileInfo = ProfileInfo;
  //public fullName: string = this.userProfileInfo[0].firstNm + " " + this.userProfileInfo[0].lastNm;
 
  datePipe = new DatePipe('en-US');
  userprofileInfo: any;

  address: any;
  firstName: string;
  middleInitial: string;
  lastName: string;
  fullName: string;
   //RBA, Start 268544
   PreferredFirstName: string; 
   PreferredMiddleInitial: string;
   PreferredLastName: string;
   PreferredFullName: string;
   //RBA, End 268544
  hireDate: string;
  leaveTermDate: string;
  enterpriseId: string;
  enterpriseIdEmail: string;
  employeeStatus: string;
  birthday: any;
  prsnlSysStaticNote: any;
  brkrsStaticNote: any;
  staticContent: any;
  isAdmin: boolean = false;
  globalId: string;
  personnelNbr: string;
  contactNbr: string;
  loading = true;
  effectivedt: string;
  note: string;
  isFormer: boolean = false;

  profilePictureAPI: any;
  user: any;
  adminProfileURL: any;
  displayProfilePage: boolean = true;
  imagePath: any;
  keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));
  errorLocation: string = " + [Component: Profilepage, Method: methodName]";
  errorProfilePage: boolean;
  userAdmin: boolean = JSON.parse(sessionStorage.getItem("userAdmin"));

  founderProgramList: any;
  founderTransfereeTempList: any;
  founderTransfereeList: any = [];
  hasTransferee: boolean = false;
  displayedTransfereeList: string[] = ['LastNm'];
  transfereeDetailDisplay: any;

  selected: any;

  //constructor(private app: AppComponent, private staticContentService: StaticContentService,private globals: Globals, private UserService: UserService) { }
  constructor(private _sanitizer: DomSanitizer, private userService: UserService, private app: AppComponent, private staticContentService: StaticContentService,private globals: Globals, private UserService: UserService, private httpClient: HttpClient, private errorNotificationService: ErrorNotificationService, private founderService: HoldingProgramDetailsFounderService) { }

  ngOnInit() {
    this.checkErrorMessage();
    this.app.appNavHeaderVisible = true;
    this.app.appFooterVisible = true;
    let counter = 0;
    // this.getProfileStaticContent();
    // if(sessionStorage.getItem("Founder Share Data") != null){
    //   let temp = JSON.parse(sessionStorage.getItem("Founder Share Data"));
    //   this.founderProgramList = temp[1];
    //   this.founderProgramList.forEach(element => {
    //     if(element.GrantGroupId == 88 || element.GrantGroupId == 89 || element.GrantGroupId == 90 || element.GrantGroupId == 91){
    //       counter++;
    //     }
        
    //   });
    // }

    this.getContactDetails();
    this.getUserState();
    this.getUrlForAdminProfile();
    this.getImageFromUserService();
    this.getFounderTransfereeDetails();
 
    sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest));
    sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Homepage"));
  }

  /**********************************************************************************
      Method Name: getFounderTransfereeDetails
      Description: Call transferee list

      #     Date            Modified by               Description
      1     09/02/19        Joshua Avecilla(JPA)      Initial draft
  **********************************************************************************/
  getFounderTransfereeDetails(){
    this.founderService.getFounderTransferee(this.userprofileInfo.OptNum).subscribe(data => {
      try{
        let count = 0;
        if(data[0].length > 0){
          this.founderTransfereeTempList = data[0];
          this.founderTransfereeTempList.forEach( element => {
          element.highlighted = false;
          if(this.userAdmin == true || (element.CanBeViewedBySource == 'N' || element.CanBeViewedBySource == 'n' || element.CanBeViewedBySource == null)){
            this.founderTransfereeList.push(element);
            this.founderTransfereeList[count].Id = count;
            count++;
          }
          });
          if(this.founderTransfereeList.length > 0){
            this.founderTransfereeList[0].highlighted = true;
            this.transfereeDetailDisplay = this.founderTransfereeList[0];
            this.hasTransferee = true;
          }
        }
        this.loading = false;
        this.changeElementjQuery();
      }
      catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFounderTransfereeDetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorProfilePage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getFounderTransfereeDetails");
      error.error.message += errorLocation;
      throw error.error;
    })
  }

  /**********************************************************************************
      Method Name: highlight
      Description: Highlight in row

      #     Date            Modified by               Description
      1     09/02/19        Joshua Avecilla(JPA)      Initial draft
  **********************************************************************************/
  highlight(row){
    this.selected = row.LastNm;
    this.founderTransfereeList.forEach( element => {
      if(element.Id == row.Id){
        element.highlighted = true;
      }
      else{
        element.highlighted = false;
      }
    })
    return this.selected === row.Id;
  }


  /**********************************************************************************
      Method Name: passDetails
      Description: Get change details depending on row selected.

      #     Date            Modified by               Description
      1     09/02/19        Joshua Avecilla(JPA)      Initial draft
  **********************************************************************************/
  passDetails(row){
    this.transfereeDetailDisplay = row;
  }

  getImageFromUserService() {
    try {
      // alert('will attempt to get your face');
      let tdata = null;
      let apiURL = this.globals.currentAPIUrl;
      let temp = this.globals.getProfPicAPI;
      if(this.user != null || this.user != undefined) {
      this.userService.getAPIProfilePicture(this.userprofileInfo.OptNum).subscribe(data => {
        try {
          let body = data;
          this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + body.image);
          
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getImageFromUserService");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getImageFromUserService");
        error.error.message += errorLocation;
        throw error.error;
      })
    }else{
      this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + sessionStorage.getItem("profilePic"));
    }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getImageFromUserService");
      error.message += errorLocation;
      throw error;
    }
  }  

  getUserState(){
    try {
      let checkUser = sessionStorage.getItem(this.globals.isFormer);

      if(checkUser == "true"){
        this.isFormer = true;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getUserState");
      error.message += errorLocation;
      throw error;
    }
  }


  GoToPortal(){
    this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  
    if (this.user.EmployeeStatus == "Active"){
      if(this.globals.stageName == "prod"){
          window.open("https://workday.accenture.com", "_blank");
        }
        else{
          window.open("https://wd3-impl.workday.com/accenture10/d/home.htmld", "_blank");
        }
    } else if (this.user.EmployeeStatus == "Terminated"){
      window.open("https://support.accenture.com/former_employee_portal", "_blank");
    }
  }


  /*******************************************************************************************************************
      Method Name: getContactDetails
      Description: Get Options program details from session 

      #     Date            Modified by                     Description
      1     02/26/19        Katrina Narag(KMN)              Remove logic in displaying leavetermdate
      2     01/27/20        Allan Christopher Salac(ACS)    Change userprofileInfo to ProfileAddress instead of Address
      3     08/13/20        R Jay B. Adrao(RBA)             Added New Processing for New Field Preferred Name
  *********************************************************************************************************************/
  getContactDetails() {
    try {
      this.errorProfilePage = false;
      let period = "";
      this.userprofileInfo = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));      
      
      this.address = this.userprofileInfo.ProfileAddress; // 2, ACS


      this.firstName = this.userprofileInfo.FirstName;
      this.middleInitial = this.userprofileInfo.MiddleInitial;
      this.middleInitial = this.middleInitial.replace(/\s/g, "");
      if(this.middleInitial != ""){
        period = ".";
      }
  
      this.lastName = this.userprofileInfo.LastName;
      this.fullName = this.firstName + " " + this.middleInitial + period + " " + this.lastName;

      //RBA, Start 268544  
      if(this.userprofileInfo.PreferredFirstName != null && this.userprofileInfo.PreferredFirstName != "" && this.userprofileInfo.PreferredLastName != null && this.userprofileInfo.PreferredLastName != ""){
        period = "";
        this.PreferredFirstName = this.userprofileInfo.PreferredFirstName;
        this.PreferredMiddleInitial = this.userprofileInfo.PreferredMiddleName;
        this.PreferredMiddleInitial = this.PreferredMiddleInitial.replace(/\s/g, "");

        if(this.PreferredMiddleInitial != "" && this.PreferredMiddleInitial != null ){
          period = ".";
        }
        this.PreferredLastName = this.userprofileInfo.PreferredLastName;
        this.PreferredFullName = this.PreferredFirstName + " " + this.PreferredMiddleInitial + period + " " + this.PreferredLastName;
      }
      else{
        period = "";
        this.PreferredFirstName = this.firstName;
        this.PreferredMiddleInitial = this.middleInitial;
        this.PreferredMiddleInitial = this.PreferredMiddleInitial.replace(/\s/g, "");

        if(this.PreferredMiddleInitial != "" && this.PreferredMiddleInitial != null ){
          period = ".";
        }
        this.PreferredLastName = this.lastName;
        this.PreferredFullName = this.PreferredFirstName + " " + this.PreferredMiddleInitial + period + " " + this.PreferredLastName;
      }
      //RBA, End   



      this.birthday = this.datePipe.transform(this.userprofileInfo.BirthDate, 'd MMM y'); 
      
      this.hireDate = this.datePipe.transform(this.userprofileInfo.HireDate, 'd MMM y');
      this.enterpriseId = this.userprofileInfo.EnterpriseId;
      this.enterpriseIdEmail = this.userprofileInfo.EnterpriseId + '@accenture.com';
      this.employeeStatus = this.userprofileInfo.EmployeeStatus;
  
      if(this.employeeStatus == "Terminated"){
        this.displayProfilePage = false;
      }
  
      this.globalId = this.userprofileInfo.ParticipantNumber;
      let SSN =  this.userprofileInfo.ParticipantNumber;
      SSN = SSN.substr(0, 2);
  
      if(SSN == "99"){
        this.globalId = this.userprofileInfo.ParticipantNumber; 
      }else{
        this.globalId = "Your SSN";
      }
  
      this.personnelNbr = this.userprofileInfo.PersonnelNumber;
      this.contactNbr = this.userprofileInfo.ContactNumber;
      this.leaveTermDate = this.userprofileInfo.LeaveTermDate;
  
      if(this.leaveTermDate == "01 Jan 1900" || this.leaveTermDate == "1900-01-01T00:00:00.000Z"){
        this.leaveTermDate = "—"; 
      }
      

      this.userAdmin = JSON.parse(sessionStorage.getItem("userAdmin"));
  
      // this.loading = false;
      this.getImageFromUserService();
    } catch(error) {
      this.errorProfilePage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getContactDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  getProfileStaticContent(){
    try {
      let staticPersonalSysImpl = this.globals.staticPersonalSysImpl;
      let brkrNotice = this.globals.staticBrokersNotice;
      this.staticContentService.getBluePageStaticContent(staticPersonalSysImpl).subscribe((data) => {
        try {
          let content =  data[0];
          let effectivedt = data[1];
          
          this.prsnlSysStaticNote = content.Value;
          this.effectivedt = effectivedt.Value;
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getProfileStaticContent");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getProfileStaticContent");
        error.error.message += errorLocation;
        throw error.error;
      }); 
  
      this.staticContentService.getBluePageStaticContent(brkrNotice).subscribe((data) => {
        try {
          let body = data[0];
          let bodyValue = body.Value;
          let note = bodyValue.slice(0, 5);
          bodyValue = bodyValue.replace(note, "");
          
          this.brkrsStaticNote = bodyValue;
          this.note = note;
          // this.loading = false;
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getProfileStaticContent");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getProfileStaticContent");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getProfileStaticContent");
      error.message += errorLocation;
      throw error;
    }
  }

  getUrlForAdminProfile(){
    try {
      this.enterpriseId = this.userprofileInfo.EnterpriseId;
      this.userprofileInfo = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey))
      let baseUrl = this.globals.peopleUrl.replace("param", this.enterpriseId);
      this.adminProfileURL = baseUrl;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getUrlForAdminProfile");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Profilepage") != -1 && this.user != null) {
                webPage = "Profilepage";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  /**********************************************************************************
      Method Name: changeElementjQuery
      Description: Manipulate elements using jQuery

      Date            Modified by       Description
      02/01/19        Katrina Narag     Initial draft; browser detection     
      02/20/19        KMN               Remove setting of height for Chrome browser 
  **********************************************************************************/
 changeElementjQuery() 
 {
  try 
  {
    $(document).ready(function () 
    {
      let browser = window.navigator.userAgent;

      if (browser.indexOf("MSIE") != -1 || browser.indexOf("Trident") != -1) 
      {   //For IE
        // $(".profcard1").css({"height": "479px",
        //                      "border-radius": "8px",
        //                      "box-shadow": "0 2px 6px 0 rgba(128, 128, 128, 0.1)",
        //                     });
        // $(".profcard").css({"height": "479px",
        //                      "border-radius": "8px",
        //                      "box-shadow": "0 2px 6px 0 rgba(128, 128, 128, 0.1)",
        //                     });
      } 
      else if (browser.indexOf("Firefox") != -1) 
      {  //For Firefox
        $(".profcard1").css({"height": "520px",
                             "border-radius": "8px",
                             "box-shadow": "0 2px 6px 0 rgba(128, 128, 128, 0.1)",
                            });
        $(".profcard").css({"height": "520px",
                             "border-radius": "8px",
                             "box-shadow": "0 2px 6px 0 rgba(128, 128, 128, 0.1)",
                            });
        // $(".profcard3").css({"height": "520px",
        //                      "border-radius": "8px",
        //                      "box-shadow": "0 2px 6px 0 rgba(128, 128, 128, 0.1)",
        //                     });
      }
      else if (browser.indexOf("Chrome") != -1)
      { //For Chrome
        // $(".profcard1").css({"height": "479px",
        //                      "border-radius": "8px",
        //                      "box-shadow": "0 2px 6px 0 rgba(128, 128, 128, 0.1)",
        //                     });
        // $(".profcard").css({"height": "479px",
        //                      "border-radius": "8px",
        //                      "box-shadow": "0 2px 6px 0 rgba(128, 128, 128, 0.1)",
        //                     });
      }
    });
  } 
  catch (error) 
  {
    let errorLocation = this.errorLocation.replace("methodName", "changeElementjQuery");
    error.message += errorLocation;
    throw error;
  }
}


}
  