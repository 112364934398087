import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import { PartnerselectedComponent } from '../partnerselected.component';
import { Globals } from '../../../../../../app/globals'
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';


@Component({
  selector: 'app-projectedchanges',
  templateUrl: './projectedchanges.component.html',
  styleUrls: ['./projectedchanges.component.css']
})
export class ProjectedchangesComponent implements OnInit {
  optNum: any;
  documentId: any //number;

  roleId: any;
  secCheck: any;
  editPartner: boolean = true;
  loading: boolean = true;

  projectedChangeNo: any;
  determinationStatus: any;
  changeType: any;
  circumstances: any;
  additionalComments: any;
  circumComments: any;
  letter: any;
  reasonsForLeaving: any;
  lumpSum: any;
  changeFrom: any;
  noticePayment: any;
  changeTo: any;
  bonusThrough: any;
  effectiveDate: any;
  correction: any;
  notes: any;

  letterCheckBox: boolean = false;
  lumpCheckBox: boolean = false;
  correctionCheckBox: boolean = false;
  letterCheck: number;
  lumpCheck: number;
  correctionCheck: number;
  isError: boolean = false;

  constructor(
    private global: Globals,
    private router: Router,
    private apiservice: ApiService,
    private partnerselected: PartnerselectedComponent,
    private access: SemaccessService
  ) { }

  ngOnInit() {
    this.optNum = sessionStorage.getItem('optNum');
    this.documentId = parseInt(sessionStorage.getItem(this.global.documentID) ||'{}');
    this.getAccess();
    this.partnerselected.loadingEdit = true;
  }




/************************************************************************************
  * Method Name: getAccess                                                           
  * Description: Retrieves access from a reasuable service 
  *                                                                                          
  * #         Date                 Modified by                        Description                             
  * 1         05/18/2020           Joshua Paul E. Peralta             API connection
**************************************************************************************/
  async getAccess(){
    try {
      await this.access.getSemAccess("projectedchanges").then();
      if (this.access.role != false) {
        this.partnerselected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerselected.editHistory(this.documentId, this.optNum);
          this.partnerselected.partnerSelectedInfo(this.optNum);
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            this.roleId = this.access.role['data'][8][0]['ROLE'];
            await this.access.getDocumentAccess("projectedchanges");
            if (this.access.document != false) {
              this.secCheck = this.access.document['data'][0][0];
              this.getProjectedChanges();
            } else {
              this.router.navigate(['equityadmin/eamain'])
            }
          } else {
            this.router.navigate(['equityadmin/eamain'])
          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } else {
        this.router.navigate(['equityadmin/eamain'])
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiservice.setErrorContents('getAccess', 'projectedchanges');
      throw error;
    }
  }

/************************************************************************************
  * Method Name: getProjectedChanges                                                        
  * Description: To get the Role of the user
  *                                                                                          
  * #         Date                 Modified by                        Description                             
  * 1         05/18/2020           Joshua Paul E. Peralta             API connection
**************************************************************************************/ 
getProjectedChanges(){
    let accessChanges: any = {
      "functionName": "getProjectedChanges",
      "selected_optnum": this.optNum
    }

    this.apiservice.postData(this.global.api_URL_pdsDocument, accessChanges).subscribe(data=>{
      try{
        if(data){
          let contentBody = data.body;

          if (contentBody != undefined) {
            for (let index = 0; index < contentBody['data'].length; index++) {
              if (index == 0) {
                if (contentBody['data'][index][0] == null || contentBody['data'][index][0] == undefined) {
                  this.projectedChangeNo = "";
                } else {
                  this.projectedChangeNo = contentBody['data'][index][0]['PROJECTED_CHANGE_NO'];
                }
              } else if (index == 1) {
                if (contentBody['data'][index][0] == null || contentBody['data'][index][0] == undefined) {
                  this.bonusThrough = "";
                  this.circumComments = "";
                  this.additionalComments = "";
                  this.correction = "";
                  this.changeFrom = "";
                  this.effectiveDate = "";
                  this.letter = "";
                  this.lumpSum = "";
                  this.changeTo = "";
                  this.notes = "";
                  this.noticePayment = ""
                } else {
                  this.bonusThrough = contentBody['data'][index][0]['BonusThrough'];
                  this.circumComments = contentBody['data'][index][0]['Circumstance_Comments'];
                  this.additionalComments = contentBody['data'][index][0]['Comments'];
                  this.correction = contentBody['data'][index][0]['Correction'];
                  this.changeFrom = contentBody['data'][index][0]['CurrentOfficeName'];
                  this.effectiveDate = contentBody['data'][index][0]['EffectiveDate'];
                  this.letter = contentBody['data'][index][0]['Letter'];
                  this.lumpSum = contentBody['data'][index][0]['LumpSum'];
                  this.changeTo = contentBody['data'][index][0]['NewOffice'];
                  this.notes = contentBody['data'][index][0]['Notes'];
                  this.noticePayment = contentBody['data'][index][0]['NoticePayment']
                }
              } else if (index == 2) {
                if (contentBody['data'][index][0] == null || contentBody['data'][index][0] == undefined) {
                  this.circumstances = "";
                } else {
                  this.circumstances = contentBody['data'][index][0]['CIRCUMSTANCES_TYPE_DESCRIPTION'];
                }
              } else if (index == 3) {
                if (contentBody['data'][index][0] == null || contentBody['data'][index][0] == undefined) {
                  this.determinationStatus = "";
                } else {
                  this.determinationStatus = contentBody['data'][index][0]['DETERMINATION_TYPE_DESCRIPTION'];
                }
              } else if (index == 4) {
                this.changeType = "";
              } else if (index == 5) {
                if (contentBody['data'][index][0] == null || contentBody['data'][index][0] == undefined) {
                  this.changeType = "";
                } else {
                  this.changeType = contentBody['data'][index][0]['CHANGE_TYPE_DESCRIPTION'];
                }
              }
            }

          }

          if (this.roleId != 1) {
            if (this.secCheck != 'E' || this.secCheck != 'e') {
              this.editPartner = false;
            }
          }
          this.lettercheckBox();
          this.lumpcheckBox();
          this.correctioncheckBox();
          this.partnerselected.loadingEdit = false;
          this.loading = false;
          this.global.searchLoading = false;
        }
      } catch (error) {
        this.loading = false;
        this.isError =  true;
        error.message += this.apiservice.setErrorContents('getProjectedChanges', 'projectedchanges');
        throw error;
      }
    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiservice.setErrorContents('getProjectedChanges', 'projectedchanges');
      throw error;
    });

}


/************************************************************************************
  * Method Name: lettercheckBox                                                       
  * Description: set Letter Has Been Received checkbox to checked
  *                                                                                          
  * #         Date                 Modified by                        Description                             
  * 1         05/18/2020           Joshua Paul E. Peralta             set checkbox to checked
**************************************************************************************/ 
lettercheckBox(){
  try {
    if (this.letter == 1) {
      this.letterCheckBox = true;
      this.letterCheck = 1
    } else {
      this.letterCheckBox = false;
      this.letterCheck = 0;
    }
  } catch (error) {
    throw error;
  }
  
}

/************************************************************************************
  * Method Name: lumpcheckBox                                                       
  * Description: set Lump Sum checkbox to checked
  *                                                                                          
  * #         Date                 Modified by                        Description                             
  * 1         05/18/2020           Joshua Paul E. Peralta             set checkbox to checked
**************************************************************************************/ 
lumpcheckBox(){
  try {
    if (this.lumpSum == 1) {
      this.lumpCheckBox = true;
      this.lumpCheck = 1;
    } else {
      this.lumpCheckBox = false;
      this.lumpCheck = 0;
    }
  } catch (error) {
    throw error;
  }

}

/************************************************************************************
  * Method Name: correctioncheckBox                                                       
  * Description: set Correction checkbox to checked
  *                                                                                          
  * #         Date                 Modified by                        Description                             
  * 1         05/18/2020           Joshua Paul E. Peralta             set checkbox to checked
**************************************************************************************/ 
correctioncheckBox(){
  try {
    if (this.correction == 1) {
      this.correctionCheckBox = true;
      this.correctionCheck = 1;
    } else {
      this.correctionCheckBox = false;
      this.correctionCheck = 0;
    }
  } catch (error) {
    throw error;
  }

}

disableInput(){
  event.preventDefault();
}


}



