import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { InvoiceDescItemState, InvoiceDescListState, InvoiceProfitCenterState } from '../../../../../services/ea/states/invoicedescription.state';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceDescItem } from '../../../../../services/ea/models/invoicedesc-model';
import { Globals } from '../../../../../globals';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { BdrLoadingService } from '../../../../../services/ea/api/BdrLoadingService';
import { RoleaccessService } from '../../../../../services/ea/api/RoleaccessService';
import { ModalComponent } from '../../../../../services/ea/shared/modal/modal.component';
import { LoadingOverlayComponent } from '../../../../../services/ea/shared/loading-overlay/loading-overlay.component';
import { InvoiceDescription } from '../../../../../services/ea/actions/invoicedescription.actions';
import * as $ from 'jquery';
import * as moment from 'moment';
import { BdraccessService } from '../../../../../services/ea/api/BdrAccessService';

@Component({
  selector: 'app-idfieldinfo',
  templateUrl: './idfieldinfo.component.html',
  styleUrls: ['./idfieldinfo.component.css']
})
export class IdfieldinfoComponent implements OnInit {

  dataSource: MatTableDataSource<InvoiceDescItem>
  displayedColumns: string[];
  tableData: InvoiceDescItem[] = [];
  dataToUpdate: InvoiceDescItem[] = [];
  descList: any[] = [];
  profCenterList: any[] = [];
  pageSizeOption: number[];
  pageSizeLabel: string = "Show";
  pageIndex: number;
  totalNumOfPage: number;
  tableHasData: boolean = true;
  disableSubmitButton: boolean = true;
  entriesStart: number;
  entriesEnd: number;
  entriesLength: number;
  origEntriesLength: number;
  filterValue = {
    JOURNAL_ENTRY_TYPE_CD: '',
    JOURNAL_GROUP_ID: '',
    JOURNAL_ROW_ID: '',
    PROFIT_CENTER_ID_DESC: '',
    INVOICE_DESC_TEXT: '',
    EFFECTIVE_POSTING_DATE: ''
  }

  @Select(InvoiceDescItemState.getInvoiceDescItems) invoiceItems: any;
  @Select(InvoiceDescListState.getInvoiceDescList) invoiceDescList: any;
  @Select(InvoiceProfitCenterState.getProfitCenterList) invoiceProfCenterList: any;
  @ViewChild(MatPaginator, { static: true }) paginator:  MatPaginator;
  @Input('equityTypeList') equityTypeList: any;
  @Input('invoiceStaticText') invoiceStaticText: any;
  setDate: string = "Invoice Desc";
  onloadTableDataString: string;
  onloadTableStringArray: string[] = [];
  modalHeader: string;
  modalConfirmBody: string;
  modalUnAuthBody: string;
  modalUnchangedBody: string;
  modalSuccessBody: string;
  tableHeaderJet: string;
  tableHeaderJel: string;
  tableHeaderJen: string;
  tableHeaderEqType: string;
  tableHeaderProfCenter: string;
  tableHeaderDesc: string;
  tableHeaderEpd: string;
  modalUnSuccessBody: string;
  roleId: number = this.roleAccessService.getUserRoleAccess();

  constructor(
    private globals: Globals,
    private modal: MatDialog,
    private apiService: ApiService,
    private store: Store,
    private invoiceDescLoadingService: BdrLoadingService,
    private roleAccessService: RoleaccessService,
    private bdrAccessService: BdraccessService
  ) {
   }

  ngOnInit() {
    this.pageSizeOption = this.globals.reportsPageSizeOptions;
    this.displayedColumns = this.globals.invoiceDescDisplayedColumns;
    this.setStaticText();
    this.invoiceItems.subscribe( data => {
      this.onloadTableDataString = JSON.stringify(data);
      for (let i = 0; i < data.length; i++) {
        this.onloadTableStringArray.push(JSON.stringify(data[i]));
      }
      this.tableData = data;
      this.dataSource = new MatTableDataSource(this.tableData)
      this.origEntriesLength = this.tableData.length;
    });
    this.invoiceDescList.subscribe( data => {
      this.descList = data;
    });
    this.invoiceProfCenterList.subscribe( data => {
      this.profCenterList = data;
    });
  }

  ngAfterViewInit(){
    this.dataSource.filterPredicate = (data) => (data.JOURNAL_ENTRY_TYPE_CD.trim().toLowerCase().indexOf(this.filterValue.JOURNAL_ENTRY_TYPE_CD.trim().toLowerCase()) !== -1 ||
                                                      data.JOURNAL_GROUP_ID.toString().trim().toLowerCase().indexOf(this.filterValue.JOURNAL_GROUP_ID.trim().toLowerCase()) !== -1 ||
                                                      data.JOURNAL_ROW_ID.toString().trim().toLowerCase().indexOf(this.filterValue.JOURNAL_ROW_ID.trim().toLowerCase()) !== -1 ||
                                                      data.PROFIT_CENTER_ID_DESC.toString().trim().toLowerCase().indexOf(this.filterValue.PROFIT_CENTER_ID_DESC.trim().toLowerCase()) !== -1 ||
                                                      data.INVOICE_DESC_TEXT.trim().toLowerCase().indexOf(this.filterValue.INVOICE_DESC_TEXT.trim().toLowerCase()) !== -1 ||
                                                      data.EFFECTIVE_POSTING_DATE.toString().trim().toLowerCase().indexOf(this.filterValue.EFFECTIVE_POSTING_DATE.trim().toLowerCase()) !== -1);
      this.dataSource.paginator = this.paginator;
      this.paginator.pageSize = this.pageSizeOption[0];
      this.paginator.firstPage();
  }

  ngAfterContentInit(){
    this.paginator._intl.itemsPerPageLabel = this.pageSizeLabel;
  }

  ngAfterContentChecked(){
    this.getNumberOfPage();
  }

  /**********************************************************************************
 * Method Name: setStaticText                                                                 
 * Description: Sets the values of modal header and table tabs.                   
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/12/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/ 
  setStaticText(){
    try {
      this.modalHeader = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.header")].MESSAGE_VALUE;
      this.modalConfirmBody = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.modalconfirm.body")].MESSAGE_VALUE;
      this.modalUnAuthBody = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.modalunauth.body")].MESSAGE_VALUE;
      this.modalUnchangedBody = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.modalunchanged.body")].MESSAGE_VALUE;
      this.modalSuccessBody = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.modalsuccess.body")].MESSAGE_VALUE;
      this.modalUnSuccessBody = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.modalunsuccess.body")].MESSAGE_VALUE;
      this.tableHeaderJet = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.tableheader.jet")].MESSAGE_VALUE;
      this.tableHeaderJel = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.tableheader.jel")].MESSAGE_VALUE;
      this.tableHeaderJen = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.tableheader.jen")].MESSAGE_VALUE;
      this.tableHeaderEqType = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.tableheader.eqtype")].MESSAGE_VALUE;
      this.tableHeaderProfCenter = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.tableheader.profcenter")].MESSAGE_VALUE;
      this.tableHeaderDesc = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.tableheader.desc")].MESSAGE_VALUE;
      this.tableHeaderEpd = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.tableheader.epd")].MESSAGE_VALUE;
    } catch (error) {
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('setStaticText', 'Idfieldinfo');
      throw error;
    }
  }

 /**********************************************************************************
 * Method Name: getDataIndex                                                                 
 * Description: Returns the index of the given key.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         02/19/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
  getDataIndex(key){
    try {
      let index;
      for (let i = 0; i < this.invoiceStaticText.length; i++) {
        if (this.invoiceStaticText[i].MESSAGE_NAME == key) {
          index = i
        }
      }
      return index;
    } catch (error) {
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('getDataIndex', 'Idfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: updateData                                                                 
  * Description: Updates the data of the table and compare to stringified table data to check if changes were made.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  updateData(type, event, id){
    try {
      switch (type) {
        case 'desc':
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].ID == id) {
              this.tableData[i].INVOICE_DESC_ID = event.value.INVOICE_DESC_ID;
              this.tableData[i].INVOICE_DESC_TEXT = event.value.INVOICE_DESC_TEXT;
            }
          }
          break;
        
        case 'prof':
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].ID == id) {
              this.tableData[i].PROFIT_CENTER_ID = event[0].PROFIT_CENTER_ID;
              this.tableData[i].PROFIT_CENTER_ID_DESC = event[0].PROFIT_CENTER_ID_DESC;
            }
          }
          break;
        
        case 'date':
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].ID == id) {
              this.tableData[i].EFFECTIVE_POSTING_DATE = moment(new Date(event)).format("MM/DD/YYYY")
            }
          }
          break;
      
        default:
          break;
      }
      this.disableSubmitButton = this.onloadTableDataString == JSON.stringify(this.tableData);
    } catch (error) {
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('updateData', 'Idfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getDefaultVal                                                                 
  * Description: Sets the default value of profit center and description dropdown.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getDefaultVal(type,val){
    try {
      switch (type) {
        case 'prof':
          for (let i = 0; i < this.profCenterList.length; i++) {
            if (val == this.profCenterList[i].PROFIT_CENTER_ID) {
              return this.profCenterList[i];
            }            
          }
          break;

        case 'desc':
          for (let i = 0; i < this.descList .length; i++) {
            if (val == this.descList[i].INVOICE_DESC_ID) {
              return this.descList[i];
            }
          }
      
        default:
          break;
      }
    } catch (error) {
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('getDefaultVal', 'Idfieldinfo');
      throw error;
    }
  }
  
  /**********************************************************************************
  * Method Name: getNumberOfPage                                                                 
  * Description: Sets the number of pages and the index of the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/22/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getNumberOfPage(){
    try {
      let dataLength;
      dataLength = this.filterValue.JOURNAL_ENTRY_TYPE_CD != "" ? this.dataSource.filteredData.length : this.dataSource.data.length;
      dataLength > 0 ? this.entriesStart = 1 + (this.paginator.pageIndex * this.paginator.pageSize) : this.entriesStart = 0;
      this.entriesEnd = dataLength < this.paginator.pageSize ? dataLength : this.paginator.pageSize + (this.paginator.pageSize * this.paginator.pageIndex);
      this.entriesEnd =  this.entriesEnd > dataLength ? dataLength : this.entriesEnd;
      this.entriesLength = dataLength;
      this.pageIndex = this.paginator.pageIndex + 1;
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;      
    } catch (error) {
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('getNumberOfPage', 'Idfieldinfo');
      throw error;
    }      
  }
  
  /**********************************************************************************
  * Method Name: applyFilter                                                                 
  * Description: Apply filter using the enterd word in search box.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         5/14/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  applyFilter(filterVal: string) {
    try { 
      let filterValue = filterVal; 
      this.filterValue.JOURNAL_ENTRY_TYPE_CD = filterValue;
      this.filterValue.JOURNAL_GROUP_ID = filterValue;
      this.filterValue.JOURNAL_ROW_ID = filterValue;
      this.filterValue.PROFIT_CENTER_ID_DESC = filterValue;
      this.filterValue.INVOICE_DESC_TEXT = filterValue;
      this.filterValue.EFFECTIVE_POSTING_DATE = filterValue;
      this.dataSource.filter = JSON.stringify(this.filterValue);
      this.tableHasData = this.dataSource.filteredData.length > 0;
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;
      this.paginator.firstPage();
    } catch (error) {
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('applyFilter', 'Idfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openModalOverlay                                                                 
  * Description: Opens an overlay loading icon.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openModalOverlay(){
    try {
      this.modal.open(LoadingOverlayComponent, {disableClose: true, panelClass: 'overlay-modal'})
    } catch(error) {
      this.modal.closeAll();
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('openModalOverlay', 'Idfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getFieldString                                                                 
  * Description: Creates the fields string of action combo items.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getFieldString(){
    try {
      let result = "";
      for (let i = 0; i < this.dataToUpdate.length; i++) {
        result += this.getEquityId(this.dataToUpdate[i].EQUITY_TYPE_DESC) + "~" + this.dataToUpdate[i].JOURNAL_ENTRY_TYPE_CD + "~" + this.dataToUpdate[i].JOURNAL_ROW_ID + "~" + this.dataToUpdate[i].EFFECTIVE_POSTING_DATE + "~" + this.dataToUpdate[i].INVOICE_DESC_ID + "~" + this.dataToUpdate[i].PROFIT_CENTER_ID + "|";
      }
      return result;
    } catch (error) {
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('getFieldString', 'Idfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openErrorModal                                                                 
  * Description: Opens an error modal if there is an error in the api call.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openErrorModal(){
    try {
      let content: any = {
        header: this.modalHeader,
        body: this.modalUnSuccessBody,
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: "OK",
        btn2Name: ""
      }
      let errorModal = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });
  
      errorModal.afterClosed().subscribe( result => {
        if (result) {
          this.modal.closeAll();
        }
      })
    } catch(error) {
      this.modal.closeAll();
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('openErrorModal', 'Idfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getEquityId                                                                 
  * Description: Gets the equity type id.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B. Fernando          Initial draft 
  ***********************************************************************************/
  getEquityId(type){
    try {
      for (let i = 0; i < this.equityTypeList.length; i++) {
        if (type == this.equityTypeList[i].Equity_Type_Desc) {
          return this.equityTypeList[i].Equity_Type_ID;
        }
      }
    } catch (error) {
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('getEquityId', 'Idfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: submitChanges                                                                 
  * Description: Submits the changes made in the table and reloads the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  submitChanges(){
    try {
 
     window.scroll(0,0);
     if (this.roleId == 7 || this.roleId == 6) {
       this.openUnAuthModal();
     }else {
       if (this.onloadTableDataString == JSON.stringify(this.tableData)){
         this.openUnchangedModal();
       }else {
         this.dataToUpdate = [];
         for (let i = 0; i < this.onloadTableStringArray.length; i++) {
           if (this.onloadTableStringArray[i] != JSON.stringify(this.tableData[i])) {
             this.dataToUpdate.push(this.tableData[i]);
           }
         }
         this.openConfirmModal();
       }
     }    
 
    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('submitChanges', 'Idfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: openUnAuthModal                                                                 
  * Description: Opens an unauth modal when user forces to enable submit button.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openUnAuthModal(){
    try {
      let content: any = {
        header: this.modalHeader,
        body: this.modalUnAuthBody,
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: "OK",
        btn2Name: ""
      }
      this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });     
    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('openUnAuthModal', 'Idfieldinfo');
      throw error;
    }
  }


 /**********************************************************************************
 * Method Name: openUnchangedModal                                                                 
 * Description: Opens a modal when the user tries to submit while there is no changes in the table.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
  openUnchangedModal(){
    try {
      let content: any = {
        header: this.modalHeader,
        body: this.modalUnchangedBody,
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: "OK",
        btn2Name: ""
      }
      this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });      
    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('openUnchangedModal', 'Idfieldinfo');
      throw error;
    }
  }

 /**********************************************************************************
 * Method Name: openConfirmModal                                                                 
 * Description: Opens a confirmation modal.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
  openConfirmModal(){
    try {
      let content = {
        header: this.modalHeader,
        body: this.modalConfirmBody,
        hasBtn1: true,
        hasBtn2: true,
        btn1Name: "OK",
        btn2Name: "CANCEL"
      }

      let confirmModal = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });
  
      confirmModal.afterClosed().subscribe( result => {
        if (result) {
          this.openModalOverlay();
          this.postChanges();
        }
      })

    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setInvoiceDescError(true);
      error.message += this.apiService.setErrorContents('openConfirmModal', 'Idfieldinfo');
      throw error
    }
  }

 /**********************************************************************************
 * Method Name: postChanges                                                                 
 * Description: Calls the api postdata.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
  postChanges(){
    let body = {
      functionName: "popInvoiceDescData",
      fieldString: this.getFieldString()
    }
    this.apiService.postData(this.globals.manageInvoiceDescApi, body).subscribe( data =>{
      try {
        if (data != null && data.statusCode == 200 ) {
          let body = data.body;
          if (body && body.length > 0) {
            let retval = body[0].RetVal;
            if (retval == 1) {
              this.modal.closeAll();
              this.openSuccessModal();
            }else {
              this.openErrorModal();
            }
          }else {
            this.openErrorModal();
          }
        }else {
          this.openErrorModal();
        }
      } catch(error) {
        this.modal.closeAll();
        this.bdrAccessService.setInvoiceDescError(true);
        error.message += this.apiService.setErrorContents('postChanges', 'Idfieldinfo');
        throw error
      }
    }, error => {
      this.modal.closeAll();
      this.bdrAccessService.setInvoiceDescError(true);
      error.error.message += this.apiService.setErrorContents('postChanges', 'Idfieldinfo');
      throw error;
    });
  }

 /**********************************************************************************
 * Method Name: openSuccessModal                                                                 
 * Description: Opens sucess modal after submitting changes.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
 openSuccessModal(){
   try {
     let content: any = {
       header: this.modalHeader,
       body: this.modalSuccessBody,
       hasBtn1: true,
       hasBtn2: false,
       btn1Name: "OK",
       btn2Name: ""
     }
     let successModal = this.modal.open(ModalComponent, {
       panelClass: 'custom-modal',
       disableClose: true,
       data: {
         content
       }
     });
                 
     successModal.afterClosed().subscribe( result => {
       if (result) {
         this.reloadTable();
       }
     })

   } catch (error) {
    this.modal.closeAll();
    this.bdrAccessService.setInvoiceDescError(true);
    error.message += this.apiService.setErrorContents('openSuccessModal', 'Idfieldinfo');
    throw error
   }
 }

 /**********************************************************************************
 * Method Name: reloadTable                                                                 
 * Description: Reloads the table after sucessful submit of changes.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
 reloadTable(){
   try {
     this.invoiceDescLoadingService.setLoadingState(true);
     let reloadBody = {
       functionName: "getBusDataInvDesc",
       equityTypeId: this.getEquityId(this.dataToUpdate[0].EQUITY_TYPE_DESC)
     }
     this.disableSubmitButton = true;
     this.apiService.postData(this.globals.manageInvoiceDescApi, reloadBody).subscribe( data => {
      try {
        let body = data.body
        if (body && body != "") {
          let bodyData = body;
          if(bodyData.length > 0 && bodyData != "Invalid Input"){
            this.store.dispatch( new InvoiceDescription.Clear);
            for (let i = 0; i < bodyData.length; i++) {
              this.store.dispatch( new InvoiceDescription.Add(bodyData[i]))
            }
            this.onloadTableDataString = JSON.stringify(bodyData);
            this.onloadTableStringArray = [];
            for (let i = 0; i < bodyData.length; i++) {
              this.onloadTableStringArray.push(JSON.stringify(bodyData[i]));
            }
            this.invoiceDescLoadingService.setLoadingState(false);
          }
        }
      } catch(error) {
        this.bdrAccessService.setInvoiceDescError(true);
        error.message += this.apiService.setErrorContents('reloadTable', 'Idfieldinfo');
        throw error
      }
     }, error => {
      this.bdrAccessService.setInvoiceDescError(true);
      error.error.message += this.apiService.setErrorContents('reloadTable', 'Idfieldinfo');
      throw error
     })
   } catch (error) {
    this.bdrAccessService.setInvoiceDescError(true);
    error.message += this.apiService.setErrorContents('reloadTable', 'Idfieldinfo');
    throw error
   }
 }

}
