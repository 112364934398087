
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { PerformanceawardService } from './PerformanceAwardService';
import { Globals } from '../globals';
import { CurrencySymbol } from '../services/enumerations/enumerations';
import { Router } from "@angular/router";
import { Options } from '@angular-slider/ngx-slider';
import { PageSection } from '../services/enumerations/enumerations';
import { StaticContentService } from '../services/StaticContentService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { DomSanitizer } from '@angular/platform-browser';

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-performanceawardpage',
  templateUrl: './performanceawardpage.component.html',
  styleUrls: ['./performanceawardpage.component.css']
})
export class PerformanceawardpageComponent implements OnInit {
  

  loading = true;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  isExpand: boolean = false;
  companyPerfTooltip: any;

  performanceGrant: any = 60;
  totalTargetCash: any= 949983;
  awardSliderValue: any = 10;
  performanceAwardOpportunity: any = this.totalTargetCash * this.awardSliderValue;
  Scenario1: boolean = false;
  Scenario2: boolean = false;
  Scenario3: boolean = true;

  exceedsValue: any;
  aboveValue: any;
  veryTopValue: any;
  standardValue: any;
  doubleValue: any;
  

  aboveSlider: any = 100;
  standardSlider: any = 100;

  performanceGrantData: any;
  dataPerformance: any;

  rating: any;
  rating2: any;
  rating3: any;
  rating4: any;
  standardAbove: any;

  compensationValue: any;
  performanceLblBelow: boolean;
  isExceed: boolean = false;
  isAbove: boolean = false;
  isStandardDouble: boolean = false;
  localCurrencySymbolPEAP:any;
  isOldView: boolean = false;
  isNewView: boolean = false;
  
  sliderValue: any = 100;
  standardGraph: any = this.sliderValue/2;
  options: Options = {
    floor: 0,
    ceil: 100,
  
    showSelectionBarEnd: false,
    hideLimitLabels: true,
    showSelectionBar: true,
    getSelectionBarColor: (value: number): string => {return '#a101ff'},
    getPointerColor: (value: number): string => {return '#460173'},
    translate: function(value) {
      return value + '%';}
  };

  optionsGraph: Options = {
    floor: 0,
    ceil: 100,
    disabled: true,
    showOuterSelectionBars: false,
    hideLimitLabels: true,
    showSelectionBar: true,
    getSelectionBarColor: (value: number): string => {return '#3b91e5'},
    hidePointerLabels: true

  };

  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  perfDisclaimer: any;
  errorLocation: string = " + [Component: Performanceaward, Method: methodName]";
  errorPeapPage: boolean;
  originalInnerHref: any = [];
  errorcompanyPerfTooltip: boolean = false;
  annualBonusTooltip:any;
  errorannualBonusTooltip: boolean;
  
 UBSlink: any;
 MSlink: any;
 envSetting: any;

  constructor(private sanitizer:DomSanitizer, private staticContentService: StaticContentService, private router: Router, private performanceservice: PerformanceawardService, private globals: Globals, private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {
    let hasPerfAwardsChecker = sessionStorage.getItem("hasPerfAwards");
    if(hasPerfAwardsChecker != "1") this.router.navigate(['homepage']);

    this.checkErrorMessage();
    this.performanceGrantMethod();
    this.getTooltipData();
    this.getAnnualBonusTooltipData();
    this.brokersSSO();
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
    if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
      let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

      if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }else{
      if(this.bodyData != null){
        if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
        }else{
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
        }
      }
    }
  }

  getTooltipData(){
    try {
      this.bodyData[5] = PageSection.companyPerfTooltip;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.companyPerfTooltip = contentBody[0].Body_Desc;  
            }
            else{
              this.companyPerfTooltip = null;
            }
          }          
        } catch(error) {
          this.errorcompanyPerfTooltip = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTooltipData");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorcompanyPerfTooltip = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getTooltipData");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorcompanyPerfTooltip = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
      error.message += errorLocation;
      throw error;
    }
  }

  getAnnualBonusTooltipData(){
    try {
      this.bodyData[5] = PageSection.annualBonusTooltip;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.annualBonusTooltip = contentBody[0].Body_Desc;
              // this.annualBonusTooltip = this.annualBonusTooltip.replace("<p>","")
              // this.annualBonusTooltip = this.annualBonusTooltip.replace("</p>","")
              // this.annualBonusTooltip = this.annualBonusTooltip.replace("&#160;"," ")
            }
            else{
              this.annualBonusTooltip = null;
            }
          }          
        } catch(error) {
          this.errorannualBonusTooltip = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getAnnualBonusTooltipData");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorannualBonusTooltip = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getAnnualBonusTooltipData");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorannualBonusTooltip = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
      error.message += errorLocation;
      throw error;
    }
  }

 async performanceGrantMethod(){
  try {
    this.errorPeapPage = false;
    this.performanceservice.getPerformanceGrant(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      try {
        this.errorPeapPage = false;
        this.performanceGrantData = data[0][0];
        sessionStorage.setItem("performanceStorage",JSON.stringify(this.performanceGrantData))
        this.dataPerformance = JSON.parse(sessionStorage.getItem("performanceStorage"));
           this.localCurrencySymbolPEAP = CurrencySymbol[this.performanceGrantData.CurrencyLoc.replace(/\s/g, "")];
        this.perfOnLoadView();
      } catch(error) {
        this.errorPeapPage = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "performanceGrantMethod");
        error.message += errorLocation;
        throw error;
      }
     }, error => {
      this.errorPeapPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "performanceGrantMethod");
      error.error.message += errorLocation;
      throw error.error;
     });
  } catch(error) {
    this.errorPeapPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "performanceGrantMethod");
    error.message += errorLocation;
    throw error;
  }
 }

 async perfOnLoadView(){
  try {
    this.errorPeapPage = false;
    if(this.performanceGrantData.isSeniorManagingDirector == 1){
      this.rating = 'Exceeds';
      this.rating2 = 'Exceeds';
      this.rating3 = 'Exceeds';
      this.rating4 = 'Exceeds';
  
      this.isOldView = true;
      this.isNewView= false;
  
      this.compensationValue = this.performanceGrantData.xValue;
      this.performanceLblBelow = false;
  
      this.isExceed = true;
      this.exceedsValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
      this.loading = false;
     }
     else {
        if(this.performanceGrantData.isOldView == 1){
          this.standardAbove = "Above";
          this.rating = "At the Very Top | Significantly Above";
          this.rating2 = "At the Very Top | Significantly Above";
          this.rating3 = "Significantly Above";
          this.rating4 = "Significantly Above";
  
          this.compensationValue = this.performanceGrantData.xValue;
          this.exceedsValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
          this.aboveValue = (this.exceedsValue/2)
  
          this.isOldView = true;
          this.isNewView= false;
  
          this.performanceLblBelow = true;
          this.isAbove = true;
          this.loading = false;
        }
        else{
          this.standardAbove = "Standard (1x)";
          this.rating = "Double (2x)";
  
          this.compensationValue = this.performanceGrantData.CurrBasePay;
          this.aboveValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
          this.exceedsValue =  Math.round(this.aboveValue) * 2;
  
          this.isOldView = false;
          this.isNewView= true;
  
          this.isStandardDouble = true;
          this.loading = false;
        }
     }
  
     this.getContentfrommyResources();
     this.loading = false;
  } catch(error) {
    this.errorPeapPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "perfOnLoadView");
    error.message += errorLocation;
    throw error;
  }
 }

 commafy(num: any, value?: number) {
  try {
  // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value >= 0) {
          let number = (num*1).toLocaleString('en', {
            //maximumFractionDigits: value
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message += errorLocation;
    throw error;
  }
}

changeValue(){
  try {
    if(this.isExceed)
    this.exceedsValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
    else if(this.isAbove){
      this.exceedsValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
      this.aboveValue = (this.exceedsValue/2)
    }else if(this.isStandardDouble){
      this.aboveValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
      this.exceedsValue = 2*Math.round(this.aboveValue); 
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "changeValue");
    error.message += errorLocation;
    throw error;
  }
}

 navToPage(strLoc){
  try {
    this.router.navigate([strLoc]);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToPage");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
      Method Name: getContentfrommyResources
      Description: Get content from myResources

      #     Date            Modified by       Description
      1     04/16/2019      Katrina Narag     Initial draft
  **********************************************************************************/
getContentfrommyResources(){
  try {
    this.errorPeapPage = false;
    this.bodyData[5] = PageSection.perfRewardDisclaimer;
    this.bodyData[6] = 1;
  
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
      try {
        this.errorPeapPage = false;
        let content = JSON.parse(data.body);
        let disclaimer;
    
        if(content.data) {
          disclaimer = content.data;
          if(disclaimer.length > 0){
            this.perfDisclaimer = disclaimer[0].Body_Desc;

            if(this.perfDisclaimer.indexOf('<font color="#0000ff">') != -1){
              this.perfDisclaimer = this.perfDisclaimer.replace('<font color="#0000ff">','');
              this.perfDisclaimer = this.perfDisclaimer.replace('</font>','');
            }
          }
          else{
            //NO RESOURCES DATA
            content.body = null;
          }
        }
      } catch(error) {
        this.errorPeapPage = true;
        let errorLocation = this.errorLocation.replace("methodName", "getContentfrommyResources");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorPeapPage = true;
      let errorLocation = this.errorLocation.replace("methodName", "getContentfrommyResources");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorPeapPage = true;
    let errorLocation = this.errorLocation.replace("methodName", "getContentfrommyResources");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
      Method Name: getFilefromContent
      Description: Get filename from href/id then download file

      #     Date            Modified by       Description
      1     04/17/2019      Katrina Narag     Initial draft
  **********************************************************************************/
 validateFileType(eventBody){
  try {
    let href: string = eventBody.target.href;
    let parentNode: any = eventBody.target.parentNode;
    let id: string = eventBody.target.id;
    let clickId = eventBody.which;
    let downloadFile: boolean = false;
    let innerText = eventBody.target.innerText;
    let fileName: string;
    
    if(clickId == 1) { // left click
      if(this.originalInnerHref.length > 0) {
        for(let i = 0; i < this.originalInnerHref.length; i++) {
          if(innerText == this.originalInnerHref[i].innerText) {
            if(href != undefined) {
              href = this.originalInnerHref[i].href;
            } else {
              href = this.originalInnerHref[i].href;

              if(navigator.userAgent.indexOf("Firefox") != -1) { // Firefox
                eventBody.originalTarget.parentElement.href = this.originalInnerHref[i].href;
              } else { // Chrome, Safari & IE
                eventBody.target.parentElement.href = this.originalInnerHref[i].href;
              }
            }
            
            this.originalInnerHref.splice(i, 1);
          }
        }
      } else {
        href = href;
      }
    } else if(clickId == 3) { // right click
      if(href != undefined) {
        if(href.indexOf("/homepage") < 0) {
          this.originalInnerHref.push({innerText: innerText, href: href});
          eventBody.target.href = this.globals.currentWebUrl + "/homepage";
        }
      } else {
        if(navigator.userAgent.indexOf("Firefox") != -1) { // Firefox
          let originalTargetHref = eventBody.originalTarget.parentElement.href; 
          let originalTargetInnerText = eventBody.originalTarget.innerText;
  
          if(originalTargetHref != undefined) {
            if(originalTargetHref.indexOf("/homepage") < 0) {
              this.originalInnerHref.push({innerText: originalTargetInnerText, href: originalTargetHref});
              eventBody.originalTarget.parentElement.href = this.globals.currentWebUrl + "/homepage";
            }
          }
        } else { // Chrome, Safari & IE
          let parentElementHref = eventBody.target.parentElement.href;
          let parentElementInnerText = eventBody.target.parentElement.innerText;
  
          if(parentElementHref != undefined) {
            if(parentElementHref.indexOf("/homepage") < 0) {
              this.originalInnerHref.push({innerText: parentElementInnerText, href: parentElementHref});
              eventBody.target.parentElement.href = this.globals.currentWebUrl + "/homepage";
            }
          }
        }
      }

      // localStorage.setItem("Local User Info Storage", JSON.stringify(this.user));
      // localStorage.setItem("Local Admin Info Storage", JSON.stringify(sessionStorage.getItem(this.globals.AdminCred)));
      // localStorage.setItem(this.globals.isUserInfoCached, JSON.stringify(true));
      // localStorage.setItem("adminPageDataStore", JSON.stringify(true));
      // localStorage.setItem(this.globals.sessionOptNum, JSON.stringify(this.user.OptNum));
    }

    if(clickId == 1) {
      if(href != undefined && href != "") {
        href =  href.toString();
        if((href.indexOf("fileName") != -1) && (href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1)) {
          fileName = href.substring(href.indexOf("=") + 1, href.length).replace(/%20/g, " ");
          eventBody.preventDefault();
          this.getFile(fileName);
        }else if((href.indexOf("fileName") == -1) && (href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1)){
          eventBody.preventDefault();

          if(href.indexOf("/") != -1) {
            href = href.substring(href.lastIndexOf("/") + 1);
          }

          href = href.split("%20").join(" ");
          this.getFile(href);
        }
      }else if(id != undefined && id != "") {
        id =  id.toString();
        if((id.indexOf("fileName") != -1) && (id.indexOf(".pdf") != -1 || id.indexOf(".doc") != -1 || id.indexOf(".docx") != -1 || id.indexOf(".xls") != -1 || id.indexOf(".pdf") != -1)) {
          fileName = href.substring(id.indexOf("=") + 1, id.length).replace(/%20/g, " ");
          eventBody.preventDefault();
          this.getFile(fileName);
        }else if((id.indexOf("fileName") == -1) && (id.indexOf(".pdf") != -1 || id.indexOf(".doc") != -1 || id.indexOf(".docx") != -1 || id.indexOf(".xls") != -1 || id.indexOf(".pdf") != -1)){
          eventBody.preventDefault();
          this.getFile(id);
        }
      }else if(parentNode != undefined && parentNode != ""){
        parentNode =  parentNode.toString();
        parentNode = parentNode.substring(parentNode.lastIndexOf("/") + 1);
    
        if((parentNode != "") && (parentNode.indexOf("fileName") != -1) && (parentNode.indexOf(".pdf") != -1 || parentNode.indexOf(".doc") != -1 || parentNode.indexOf(".docx") != -1 || parentNode.indexOf(".xls") != -1 || parentNode.indexOf(".pdf") != -1)) {
          eventBody.preventDefault();
          fileName = href.substring(href.indexOf("=") + 1, href.length).replace(/%20/g, " ");
          this.getFile(fileName);
        }else if((parentNode != "") && (parentNode.indexOf("fileName") == -1) && (parentNode.indexOf(".pdf") != -1 || parentNode.indexOf(".doc") != -1 || parentNode.indexOf(".docx") != -1 || parentNode.indexOf(".xls") != -1 || parentNode.indexOf(".pdf") != -1)){
          eventBody.preventDefault();
          parentNode = parentNode.split("%20").join(" ");
          this.getFile(parentNode);
        }
      }
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "validateFileType");
    error.message += errorLocation;
    throw error;
  }
}

 /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     04/22/19        Katrina Narag         Initial Draft
  **********************************************************************************************/
 getFile(fileName) {
  try {
    let fileType: string;

    if(fileName.indexOf(".pdf") != -1) {
      fileType = "application/pdf";
    } else {
      fileType = "mime_type";
    }
  
    this.staticContentService.getFile(fileName, "MD").subscribe(data => {
      try {
        let content = JSON.parse(data.body);
        let file = content.data;
    
        this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
        this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
          let writeLogResponse = data;
        });
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
 }

 toogleDetailsMobile(checker){
   try {
    if(checker == 0){
      this.isExpand = true;
     }
     else if(checker == 1){
      this.isExpand = false;
     }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "toogleDetailsMobile");
    error.message += errorLocation;
    throw error;
  }
 }

 showModal(){
  try {
    document.getElementById("disclaimerBtn").click();
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "showModal");
    error.message += errorLocation;
    throw error;
  }
}

 /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Performanceaward") != -1 && this.user != null) {
                webPage = "Performanceaward";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

}


