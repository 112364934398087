import { State, Selector, Action, StateContext } from "@ngxs/store";
import { BillToItem } from "../models/billto-model";
import { BillTo } from "../actions/billto.actions";
import * as moment from 'moment';

export class BillToItemStateModel {
    items: BillToItem[];
}

@State<BillToItemStateModel>({
    name: 'billtoitems',
    defaults: {
        items: []
    }
})

export class BillToItemState {

    @Selector()
    static getBillToItems(state: BillToItemStateModel) {
        return state.items;
    }

    @Action(BillTo.AddBillToItem)
    add(
        store: StateContext<BillToItemStateModel>,
        action: BillTo.AddBillToItem
    ) {
        const state = store.getState();
        action.payload.ID = state.items.length;
        action.payload.EFFECTIVE_POSTING_DATE = action.payload.EFFECTIVE_POSTING_DATE? 
        moment(new Date(action.payload.EFFECTIVE_POSTING_DATE)).format('MM/DD/YYYY'): null;
        store.patchState({
            items: [...state.items, action.payload]
        });
    }

    @Action(BillTo.ClearBillToItem)
    clear(
        store: StateContext<BillToItemStateModel>
    ) {
        store.setState({
            items: []
        });
    }

}

export class StaticDataStateModel {
    staticData: object
}

@State<StaticDataStateModel>({
    name: 'bdrstatic',
    defaults: {
        staticData: null
    }
})

export class StaticDataState {

    @Selector()
    static getStaticData(state: StaticDataStateModel) {
        return state;
    }

    @Action(BillTo.SetStaticData)
    add(
        store: StateContext<StaticDataStateModel>,
        action: BillTo.SetStaticData
    ) {
        store.setState({
            staticData: action.payload
        })
    }

}