
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Globals } from '../globals';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { HoldingprogramdetailsRsuService } from './HoldingProgramDetailsRSUService';
// import { NumberValueAccessor } from '@angular/forms/src/directives';
import * as moment from 'moment';
import { BrokerType, GrantAgreementStatus, AdminType, PageSection, FileType, EmployeeType, MyHoldingsPagesResources, tScript} from '../services/enumerations/enumerations';
//import { MatInput } from '@angular/material';
import { MatInput } from '@angular/material/input';
import { StaticContentService } from '../services/StaticContentService';
import { MultipleGrantsService } from '../multiplegrants/MultipleGrantsService';
//import { HoldingSummaryService } from '../holdingsummarypage/HoldingSummaryService';
import { HoldingsSummaryService } from '../holdingsummarypage/HoldingSummaryService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { RoutingState } from '../services/RoutingState';
import { BrokersPageService } from '../brokerspage/BrokersPageService';
import { PrintService } from '../services/PrintService';
import { RsuDetailsCardComponent } from './rsu-details-card/rsu-details-card.component';
import { RsuVestCardComponent } from './rsu-vest-card/rsu-vest-card.component';
import { RsuRelCardComponent } from './rsu-rel-card/rsu-rel-card.component';
import { RsuDivCardComponent } from './rsu-div-card/rsu-div-card.component';
import { AwardDetailsSummaryComponent } from '../reusable/award-details-summary/award-details-summary.component';
import { LoadingService } from '../services/LoadingService';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {TooltipPosition} from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import {MatDatepickerModule} from '@angular/material/datepicker';


declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-holdingprogramdetailspage-rsu',
  templateUrl: './holdingprogramdetailspage-rsu.component.html',
  styleUrls: ['./holdingprogramdetailspage-rsu.component.css'],
})
export class HoldingprogramdetailspageRsuComponent implements OnInit, AfterViewInit {

  // Variables for Sub Components - START
  // @ViewChild('rsuDetailsCard') rsuDetailsCard: RsuDetailsCardComponent;
  // @ViewChild('rsuVestCard') rsuVestCard: RsuVestCardComponent;
  // @ViewChild('rsuRelCard') rsuRelCard: RsuRelCardComponent;
  // @ViewChild('rsuDivCard') rsuDivCard: RsuDivCardComponent;
  @ViewChild('awardDetailsSummary') awardDetailsSummary: AwardDetailsSummaryComponent;
  pageLoading: boolean = true;
  // Variables for Sub Components - END
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];


  dateFromClicked: boolean = false;
  dateToClicked: boolean = false;
  dateFromHolder: any;
  dateToHolder: any;
  // dateFromPlaceholder: string = " "; // Ngb Datepicker
  // dateToPlaceholder: string = " "; // Ngb Datepicker
  // dateFromInput: NgbDateStruct; // Ngb Datepicker
  // dateToInput: NgbDateStruct; // Ngb Datepicker

  dateFromInput: Date; // Angular Material Datepicker
  dateToInput: Date; // Angular Material Datepicker

  displayedAgreementVestColumns: string[] = ['VestReleaseDate', 'OriginalShares'];
  agreementVestDataSource: any = [];
  displayedAgreementRelColumns: string[] = ['VestReleaseDate', 'OriginalShares'];
  agreementRelDataSource: any = [];

  detailsAccordionCounter: number = 0;

  vestDataSource: any = [];
  displayedVestColumns: string[] = ['VestDate', 'Shares', 'Status'];

  releaseDataSource: any = [];
  displayedRelColumns: string[] = ['ReleaseDate', 'Shares', 'Status']; 
  
  divVestDataSource: any;
  displayedDivVestColumns: string[] = ['EventDate', 'Shares'];
  divRelDataSource: any;
  displayedDivRelColumns: string[] = ['EventDate', 'Shares'];
  
  rsuSalesHistDataSource: any;
  displayedRsuSalesHistColumns: string[] = ['DispositionDate', 'TransactionType', 'ShareAmount', 'PriceInUSD', 'ProceedsInUSD', 'ProceedsInLoc'];
  
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));  
  activeTab: any = JSON.parse(sessionStorage.getItem(this.globals.grantTabInd));
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd: any;
  admin = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));
  
  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsNextRelease: any;
  detailsSharesReleasing: any;
  detailsGrantAccepted: any;
  detailsUnreleased: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;
  displayAgreementTab: boolean;
  
  loading: boolean = true;

  shareActAcceptance: string;
  shareActForfeited: number;
  shareActGrantDate: string;
  shareActGrantDesc: string;
  shareActGrantId: string;
  shareActGrantPrice: number;
  shareActIsGrantAgreementValid: string;
  shareActNetRemainAtBroker: number;
  shareActNextRelease: string;
  shareActNextReleaseVal: number;
  shareActReleased: number;
  shareActSharesReleasing: number;
  shareActSoldTransferred: number;
  shareActTotalAwarded: any;
  shareActUnreleased: number;
  shareActWithholdingForTaxes: number;
  shareActDisplayAgreementAcceptance: boolean = true; 
  shareActDisplayNextRelease: boolean = true;
  shareActDisplaySharesReleasing: boolean = true;
  shareActDisplayNextReleaseVal: boolean = true; 
  shareActDisplayForfeited: boolean;
  shareActAgreementTabInd: boolean;
  shareActActionReq: boolean;
  keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Rsu Details Value"));


  displayVestCard: boolean = true;
  displayReleaseCard: boolean = true;
  displayDivCard: boolean = true;
  noShareAct: boolean = false;
  isEnabledVest: boolean = false;

  vestAdditionalRsu: number;
  vestLocalCurrency: string;
  vestOrigGrantedRsu: number;
  vestTotalAward: number;
  vestValue: number;
  vestValueLocal: number;
  vestVestFmv: number;
  vestDisplayValueLocal: boolean;
  vestTotalShares: number;
  vestLoading: boolean = false;
  vestRsuDate: string = "null";

  relAdditionalRsu: number;
  relGrossReleased: number;
  relGrossReleasedLoc: number;
  relGrossReleasedUsd: number;
  relLocalCurrencyCd: string;
  relNetReleased: number;
  relNetReleasedLoc: number;
  relNetReleasedUsd: number;
  relOrigScheduled: number;
  relReleaseFmv: number;
  relTaxWithholdingMethod: string;
  relTotalScheduledRsu: number;
  relWithheldForTax: any;
  relWithheldForTaxUsd: any;
  relWithheldForTaxValueLoc: any;
  relWithholdingRefund: number;
  relWithholdingRefundLoc: number;
  relWithholdingRefundUsd: number;
  releaseLoading: boolean = false;
  relRsuDate: string = "null";
  relTotalShares: number;
  relCurrencySelected: any;
  relIsSelectedCurrencyUsd: boolean = true;
  relDisplayCurrencyDropdown: boolean;
  relSetCurrencyValue: string;

  divGrantId: string;
  divAdditionalRsusGranted: number;
  divRsuDate: string = "null";
  divVestTable: Object[];
  divReleaseTable: Object[];
  divGrantDate: any;
  divDropdownSelected: string;
  isDivInitialized: boolean = false;
  divLoading: boolean = false;

  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;
  periodSelected: string;
  datePeriod: number = 4;
  dateTo: string = "''";
  dateFrom: string = "''";
  brokerType: any = [];
  brokerTypes: boolean = false;
  disableDatePicker: boolean = true;
  dateFromChecker: string;
  dateToChecker: string;
  dateErrorChecker: boolean = false;
  noSalesHistory: boolean = false;
  salesTableLoading: boolean;
  rsuSalesHistShares: number = 0;
  rsuSalesHistPriceUSD: number = 0;
  rsuSalesHistProceedsUSD: number = 0;
  rsuSalesHistPurchaseValueUSD: number = 0;
  displayContactBroker: boolean;

  agreementRsuGranted: number;
  agreementGrantId: string;
  agreementGrantPrice: number;
  agreementTotalVal: number;
  agreementExchangeRate: number;
  agreementStepOne: string;
  agreementStepOneStatus: string;
  agreementStepTwo: string;
  agreementStepTwoStatus: string;
  agreementStepThree: string;
  agreementStepThreeStatus: string;
  agreementStepThreeValStatus: string;
  agreementVestTable: Object[] = [];
  agreementReleaseTable: Object[] = [];
  agreementImportantNotes: Object[] = [];
  displayReturnSigned: boolean = false;
  displayAgreementTable: boolean;
  stepOneStatus: boolean = false;
  stepTwoStatus: boolean = false;
  stepThreeStatus: boolean = false;
  disableAcceptGrantBtn: boolean;
  displayAgreementContent: boolean = true;
  displayAgreementImptNotes: boolean = false;

  periodCustomChecker: number;
  currencyCdSymbol = '';
  agreementMethodExecuted: boolean = false;
  methodCounter: number = 0;

  showDetailsTab: boolean = true; // remove TRUE
  showHistoryTab: boolean;
  showLearnMoreTab: boolean;
  agreementNotAvailable: boolean;

  keyExecToggle: string = JSON.parse(sessionStorage.getItem("Key Exec Holding Summary Value"));
  keyExecVestHideShow: boolean = false;
  keyExecRelHideShow: boolean = false;
  setMaximumVest: boolean = false;
  setTargetVest: boolean = false;
  setTrendVest: boolean = false;
  isDivInd: string;
  shortAwardName: string;

  awardSharesReleasing: any;
  awardUnreleased: any;
  awardReleased: any;
  awardForfeited: any;
  yearCode: any[] = [];
  planNum: any[] = [];
  awardDate: any;
  estForfeit: boolean = false;
  showKeyExectoggle: boolean = false;  
  releaseCardDetails: boolean = true;
  keyExecDisplayInd: any = sessionStorage.getItem("showKeyExecToggle");

  maxUnreleased: number;
  targetUnreleased: number;
  trendUnreleased: number;
  estPerForfeit: number;
  grantAgreementFile: string;

  // Learn More Tab
  rsuCommonQuestionsContent: any; 
  rsuGuideBooksContent: any; 
  rsuShareIncentiveContent: any;
  rsuCountAwardCOntent: any;
  rsuToolsandRepContent: any;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  bodyLearnMoreData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  grantCountryCd: any; 
  userInfo = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  disableDownloadBtn: boolean = false;
  //programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  //bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  //user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  grantCountryCode: any;
  grantGroupId: any;
  rsuCommonQuestion: any;
  grantGroupIdProgramDetails: any;
  FrenchQualified: any;

  allCountryFormsContent: string;
  stepTwoContent: object;
  errorLocation: string = " + [Component: Rsudetails, Method: methodName]";
  previousRoute: string;

  LocalGroupDetailId7: any;
  LocalGroupDetailId8: any;
  LocalGroupDetailId5: any;
  currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));

  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;


// RSU
originalGrant_shares: number;
originalGrant_grantValue: number;
additional_shares: number;
additional_grantValue: number;
total_shares: number;
total_grantValue: number; 

unvested_shares: number;
vestedButUnreleased: number;
totalUnreleased:number;

latestMarketPrice: any = sessionStorage.getItem("latestPricefromExternalAPI");
sas_released: any;

sasReleasedTotal_shares: number = 0;
sasReleasedTotal_sharesValue: number = 0;

sas_WithheldForTax_Shares: number;
sas_WithheldForTax_Value: number;
sas_WithholdingRefund_Shares: number;
sas_WithholdingRefund_value: number;
sas_WithheldForTax_Shares_Total: number;
sas_TotalWithheld_Shares: number;
sas_TotalWithheld_Value: number;

//PSU
SharesAttainedPSU: any;
PSUPerf1: any;
PSUPerf2: any;
PSUDisplayInd: any;
PSUGrantValue: any;
PSUSharesDisp: boolean = false;

psuKeyExecDisplay: boolean = false;
tt_unreleased: any;
psuGePastInd: string;
psuShares: any = 0;



// Static tooltip
details_Forfeited: string = tScript.rsudetailspage_f;
details_NetRemainingAtBroker: string = tScript.rsudetailspage_nbr;
detailsVest_Vest: string = tScript.rsudetailspage_v;
detailsVest_Status: string = tScript.rsudetailspage_s;
detailsVest_OriginallyScheduledForVest: string = tScript.rsudetailspage_osv;
detailsVest_AdditionalRSUsInLieuOfDividend: string = tScript.rsudetailspage_ald;
detailsVest_TotalScheduledForVest: string = tScript.rsudetailspage_tsv;
detailsVest_VestFMV: string = tScript.rsudetailspage_vf;
detailsVest_Value: string = tScript.rsudetailspage_value;
detailsRelease_Release: string = tScript.rsudetailspage_release;
detailsRelease_Status: string = tScript.rsudetailspage_status;
detailsRelease_OriginallyScheduledForRelease: string = tScript.rsudetailspage_osr;
detailsRelease_AdditionalRSUsInLieuOfDividend: string = tScript.rsudetailspage_ard;
detailsRelease_ReleaseFMV: string = tScript.rsudetailspage_rf;
detailsRelease_TaxWithholdingMethod: string = tScript.rsudetailspage_twm;
detailsRelease_GrossReleased: string = tScript.rsudetailspage_gr;
detailsRelease_WithheldForTax: string = tScript.rsudetailspage_wft;
detailsRelease_WithholdingRefund: string = tScript.rsudetailspage_wr;
detailsARLD_AdditionalRSUsGranted: string = tScript.rsudetailspage_arg;
saleshistory_Type: string = tScript.rsusaleshistorytab_type;
saleshistory_Share: string = tScript.rsusaleshistorytab_share;
saleshistory_Proceeds: string = tScript.rsusaleshistorytab_proceeds;

errorShareActSection: boolean = false;		  
errorVestSection: boolean = false;		
errorReleaseSection: boolean = false;		  
errorAdditionalSection: boolean = false;		  
errorAgreementTab: boolean = false;		  
errorSalesHistTab: boolean = false;		  
errorCommonQuestions: boolean = false;		
errorGuideBooks: boolean = false;		  
errorShareIncentive: boolean = false;		  
errorCountryAward: boolean = false;		  
errorToolsAndReports: boolean = false;
netReleased: any;

fileTypeImagePath: string;
fileTypeAlt: string;

LearnMoreLoading: boolean = true; 
showAcceptBtn: boolean;
showAcceptedBtn: boolean;

commonQAccordionCount: number = 1; // 1 accordion is initially expanded

UBSlink: any;
MSlink: any;
envSetting: any;

  constructor(private sanitizer:DomSanitizer, private brokerService: BrokersPageService, private staticContentService: StaticContentService, private globals: Globals, private app: AppComponent, private router: Router, private holdingProgramDetailsRSUService: HoldingprogramdetailsRsuService, private multiplegrantsService: MultipleGrantsService, private holdingSummaryPage : HoldingsSummaryService, private errorNotificationService: ErrorNotificationService, private routingState: RoutingState, private printService: PrintService, private loadingService: LoadingService, private ngbDateParserFormatter: NgbDateParserFormatter) {
    // this.loadingService.executedComponent$.subscribe(components => {
    //   let executedComponents: number = components;

    //   if(executedComponents == 4) { // 4 sub components under details tab, additional sub components once sales history & learn more is done
    //     this.pageLoading = false;
    //     this.loadingService.resetExecutedComponent();
    //   }
    // });

    this.app.appNavHeaderVisible = true;
    this.app.appFooterVisible = true;
   }

  ngOnInit() {
    try {

      let hasRSUChecker = sessionStorage.getItem("showRSUData");
      if(hasRSUChecker != "1") this.router.navigate(['homepage']);

      if(sessionStorage.getItem(this.globals.selectedCurrencyCd) != null && sessionStorage.getItem(this.globals.selectedCurrencyCd) != "null"){
        this.currencyCd = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));
      }
      else{
        this.currencyCd = this.user.CurrencyCd;
      }
      this.checkErrorMessage();

      if (this.programDetails.GrantNum == null){
        let temp = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
        this.programDetails = temp[0];
      }

      // BACK BUTTON BUG - START
      sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
      let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
      let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

      if(backUrl) {
        if(detailsStorage.length > 0) {
          this.programDetails = detailsStorage[detailsStorage.length - 1];
        }
      } 
      // BACK BUTTON BUG - END

      this.getPsuDetailsbyOptNum();

      this.keyExecVestChecker();
      this.getCodeDetails();
      this.getProgramDetails();
      this.getFromCodeDetail();

      this.getRSUGuideBooks();
      this.getRSUShareIncentive();
      this.getRSUCountryAward();
      this.getRSUToolsAndReports();
      this.brokersSSO();
      this.previousRoute = this.routingState.getPreviousUrl();
      sessionStorage.setItem("Program Component Indicator", JSON.stringify("RSU"));
      setTimeout(()=>{ this.inContentIcon() }, 500);
      sessionStorage.setItem("ShowRSUData", "1");
      sessionStorage.setItem("Highlight Checker", "1");
      let active = sessionStorage.getItem("IsActive Row");
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "rsu details ngOnInit");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
  if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
    let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

    if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
    }else{
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
    }
  }else{
    if(this.bodyData != null){
      if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }
  }
  }

  ngAfterViewInit(): void {
    if(this.awardDetailsSummary != undefined) {
      this.awardDetailsSummary.keyExecToggleValue = this.keyExecToggle;
      this.awardDetailsSummary.getheaderRSUDetails();
    }
  }

  navToPage(strLoc) {
    try {
      if(strLoc == "reportspage") {
        let reportId = {'ReportTypeId': 1};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
      } 
      
      // BACK BUTTON BUG - START
      else if(strLoc == "holdingsummarypage") {
        sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(true));
      }
      // BACK BUTTON BUG - END

      this.router.navigate([strLoc]);
      return false;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
     * Method Name: keyExecVestChecker                                                                 
     * Description: Checks the value of key exec toggle from homepage or detailspage to set 
     * as default value for the radio button.                           
     *                                                                                          
     * #    Date                 Modified by                           Description                             
     * 1    08/13/19             Joshua Avecilla (JPA)                 Initial draft
     *****************************************************************************************/
    keyExecVestChecker() {
      try {
        let keyExecHoldingSummaryValue = JSON.parse(sessionStorage.getItem("Key Exec Holding Summary Value"));
        let keyExecRsuDetailsValue = JSON.parse(sessionStorage.getItem("Key Exec Rsu Details Value"));
        let keyExecComponentInd = JSON.parse(sessionStorage.getItem("Key Exec Component Indicator"));
        let keyExecDashboardValue = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));

        if(keyExecRsuDetailsValue != null && keyExecComponentInd == "Rsu Details"){
          this.keyExecVest = keyExecHoldingSummaryValue;
        } else if(keyExecHoldingSummaryValue != null && keyExecComponentInd == "Holding Summary")  {
          this.keyExecVest = keyExecRsuDetailsValue;
        } else if (keyExecDashboardValue != null && keyExecComponentInd == "Homepage") {
          this.keyExecVest = keyExecDashboardValue;
        } else {
          this.keyExecVest = "Target";
        }
        

           if(this.keyExecVest == "Target") {
             this.setTargetVest = true;
             this.setMaximumVest =  false;
             this.setTrendVest = false;
           } else if(this.keyExecVest == "Trend") {
             this.setTrendVest = true;
             this.setTargetVest = false;
             this.setMaximumVest =  false;
           } else {
             this.setMaximumVest = true;
             this.setTargetVest = false;
             this.setTrendVest =  false;
           }
        

      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "keyExecVestChecker");
        error.message = error.message + errorLocation;
        throw error;
      }
    }

  

  /**********************************************************************************
      Method Name: getProgramDetails
      Description: Get rsu program details from session 

      #     Date            Modified by       Description
      1     01/30/19        Katrina Narag     Initial draft         
      2     02/08/19        KMN               Set award name to grant long desc
      3     02/14/19        KMN               Add logic for FRQ dividends
      4     08/06/2019      JPA               Added if else statement for session the data to prevent multiple api calls. (Performance)
  **********************************************************************************/
  getProgramDetails() {
    try {
      this.shortAwardName = this.programDetails.AwardName;
      this.awardName = this.programDetails.GrantLongDesc; //KMN, 2
      this.detailsAvailableToSell = this.programDetails.AvailableToSell;
      this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
      this.detailsBroker = this.programDetails.BrokerId;
      this.detailsNextRelease = this.programDetails.NextRelease;
      this.detailsSharesReleasing = this.programDetails.SharesReleasing;
      this.awardSharesReleasing = this.programDetails.SharesReleasing;
      this.detailsGrantAccepted = this.programDetails.GrantAccepted;
      this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
      this.awardUnreleased = this.programDetails.UnreleasedUnexercisable;
      this.detailsReleased = this.programDetails.ReleasedExercisableQty;
      this.detailsExpiration = this.programDetails.Expiration;
      this.detailsForfeited = this.programDetails.Forfeited;  
      this.isDivInd = this.programDetails.IsDivInd;
      this.awardDate = this.programDetails.GrantYear;

      if(this.isDivInd == "Y"){  //KMN, 3
        this.displayAgreementTab = true;
        this.showDetailsTab = false;
        this.showHistoryTab = false;
        this.showLearnMoreTab = false;
        if(sessionStorage.getItem("RSU Details Data") != null){
          try {
            let data = JSON.parse(sessionStorage.getItem("RSU Details Data"));
            // if(Array.isArray(data) && data.length > 0) {
              let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
              sessionStorage.setItem("Header RSU Details", JSON.stringify(sortedBody[0]));
              let shareAct = data[0][0];
              this.holdingProgramDetailsRSUService.shareAct = shareAct;
              this.shareActAcceptance = shareAct.Acceptance;
              this.shareActForfeited = shareAct.Forfeited;
              this.shareActGrantDate = shareAct.GrantDate;
              this.shareActGrantDesc = shareAct.GrantDescription;
              this.shareActGrantId = shareAct.GrantID;
              this.shareActGrantPrice = shareAct.GrantPrice;
              this.shareActNetRemainAtBroker = shareAct.NetRemainingAtBroker;
              this.shareActNextRelease = shareAct.NextRelease;
              this.shareActNextReleaseVal = shareAct.SharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
              this.shareActReleased = shareAct.Released;
              this.shareActSharesReleasing = shareAct.SharesReleasing;
              this.shareActSoldTransferred = shareAct.SoldTransferred;
              this.shareActTotalAwarded = shareAct.TotalAwarded;
              this.shareActUnreleased = Math.floor(shareAct.Unreleased);
              if(this.user.EmployeeStatus == "Terminated"){
                this.shareActUnreleased = this.shareActUnreleased;
              }
              this.shareActWithholdingForTaxes = shareAct.WithholdingforTaxes;
              this.shareActIsGrantAgreementValid = shareAct.IsGrantAgreementValid;   

              if(this.planNum != null){
              if((this.programDetails.PlanNum == this.planNum[0]['OI'] || this.programDetails.PlanNum == this.planNum[1]['OI'] || this.programDetails.PlanNum == this.planNum[2]['OI']) && this.showKeyExectoggle != false)
              {        
                this.getheaderRSUDetails(sortedBody,this.keyExecToggle);    
              }
              }
              
              this.getRSUAgreementInfo();
            // }
          } catch(error) {
            this.loading = false;		
            this.errorShareActSection = true;
            let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
            error.message += errorLocation;
            throw error;
          }
        }
        else
        {
          this.getRSUAgreementInfo();
          this.holdingProgramDetailsRSUService.getRSUDetails(this.programDetails.GrantNum).pipe(map(res => res)).subscribe(data => { 
            try {
              if(Array.isArray(data) && data.length > 0) {
                let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
                sessionStorage.setItem("RSU Details Data", JSON.stringify(data));
                sessionStorage.setItem("Header RSU Details", JSON.stringify(sortedBody[0]));
                let shareAct = data[0][0];
                this.holdingProgramDetailsRSUService.shareAct = shareAct;
                this.shareActAcceptance = shareAct.Acceptance;
                this.shareActForfeited = shareAct.Forfeited;
                this.shareActGrantDate = shareAct.GrantDate;
                this.shareActGrantDesc = shareAct.GrantDescription;
                this.shareActGrantId = shareAct.GrantID;
                this.shareActGrantPrice = shareAct.GrantPrice;
                this.shareActNetRemainAtBroker = shareAct.NetRemainingAtBroker;
                this.shareActNextRelease = shareAct.NextRelease;
                this.shareActNextReleaseVal = shareAct.SharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
                this.shareActReleased = shareAct.Released;
                this.shareActSharesReleasing = shareAct.SharesReleasing;
                this.shareActSoldTransferred = shareAct.SoldTransferred;
                this.shareActTotalAwarded = shareAct.TotalAwarded;
                this.shareActUnreleased = Math.floor(shareAct.Unreleased);
                if(this.user.EmployeeStatus == "Terminated"){
                  this.shareActSharesReleasing = this.shareActUnreleased;
                }
                this.shareActWithholdingForTaxes = shareAct.WithholdingforTaxes;
                this.shareActIsGrantAgreementValid = shareAct.IsGrantAgreementValid;   
                
                if(this.planNum != null ){
                if((this.programDetails.PlanNum == this.planNum[0]['OI'] || this.programDetails.PlanNum == this.planNum[1]['OI'] || this.programDetails.PlanNum == this.planNum[2]['OI']) && this.showKeyExectoggle != false)
                {        
                  this.getheaderRSUDetails(sortedBody,this.keyExecToggle);    
                }
                }
                
              }
            } catch(error) {
              this.loading = false;		
              this.errorShareActSection = true;
              let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            this.loading = false;		
            this.errorShareActSection = true;
            let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
            error.error.message += errorLocation;
            throw error.error;
          });
        }

        
      }
      else
      {
        this.showDetailsTab = true;
        this.showHistoryTab = true;
        this.showLearnMoreTab = true;

        this.getRSUDetails();
        this.getRSUAgreementInfo();
        this.holdingProgramDetailsRSUService.getSalesHistoryPeriod().pipe(map(res => res)).subscribe(data => {
          try {
            let b = data;
            b = b.sort((a,b) => 0 - (a.Id > b.Id ? -1 : 1));
            this.yearToDate = b[0].Value;
            this.lastYear = b[1].Value;
            this.last3Years = b[2].Value;
            this.allDates = b[3].Value;
            this.custom = b[4].Value;
            
            this.periodSelected = this.allDates;
      
            // this.getRSUDetails();
            // this.getRSUAgreementInfo();
            // this.getRSUDetailsVestInfo();
            // this.getRSUDetailsRelInfo();
            // this.getRSUDetailsDivInfo();
          } catch(error) {
            this.loading = false;		
            this.errorShareActSection = true;
            let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          this.loading = false;		
          this.errorShareActSection = true;
          let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
          error.error.message += errorLocation;
          throw error.error;
        });
      }
    } catch(error) {
      this.loading = false;		
      this.errorShareActSection = true;
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.message += errorLocation;
      throw error;
    }
  }

/**********************************************************************************
* Method Name: getheaderRSUDetails                                                                 
 * Description: Computation of Shares Releasing and Unreleased Shares                         
 *                                                                                          
 * #         Date                 Modified by                 Description                             
 * 1         26/Feb/19            Kim Phillip Aying           Initial draft  
 * 2         06/Dec/22            Johannah Jane Abuel         Added new computation for key exec (plan num 72 & 74), year 2023 and up
 *********************************************************************************/

async getheaderRSUDetails(bodyRSUDetails,toggle){
  try {
    if(this.planNum != null && this.planNum != undefined && this.planNum.length > 0) {
      if(this.programDetails.PlanNum == Number(this.planNum[0].OI) || 
      this.programDetails.PlanNum == Number(this.planNum[1].OI) || 
      this.programDetails.PlanNum == Number(this.planNum[2].OI))
      { 
        this.maxUnreleased = bodyRSUDetails.Unreleased;
        this.awardSharesReleasing = 0; // key exec
        this.awardUnreleased = 0; // key exec
        this.awardReleased = 0;
        this.awardForfeited = 0;
        this.estPerForfeit = 0;

        if(toggle == "Maximum" && this.programDetails.GrantYear <= this.globals.yearCondition)
        {
          //Shares Releasing
          let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing * (3/2));
          bodyRSUDetails.SharesReleasing = sharesTargetVestTotal;
          this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
          this.shareActSharesReleasing = this.awardSharesReleasing;
          this.shareActNextReleaseVal = this.shareActSharesReleasing * this.marketQuoteCurrentPrice.latestPrice;

          //Unreleased
          let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased * (3/2));
          bodyRSUDetails.Unreleased = unreleasedTargetVestTotal;
          this.awardUnreleased = bodyRSUDetails.Unreleased;
          this.shareActUnreleased = this.awardUnreleased;
          if(this.user.EmployeeStatus == "Terminated"){
            this.shareActSharesReleasing = this.shareActUnreleased;
          }
          this.trendUnreleased = this.maxUnreleased - this.shareActUnreleased;
          this.estPerForfeit = this.trendUnreleased;
          
        }

        else if(toggle == "Maximum" && this.programDetails.GrantYear > this.globals.yearCondition)
        {
          //Shares Releasing
          let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing * (2));
          bodyRSUDetails.SharesReleasing = sharesTargetVestTotal;
          this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
          this.shareActSharesReleasing = this.awardSharesReleasing;
          this.shareActNextReleaseVal = this.shareActSharesReleasing * this.marketQuoteCurrentPrice.latestPrice;

          //Unreleased
          let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased * (2));
          bodyRSUDetails.Unreleased = unreleasedTargetVestTotal;
          this.awardUnreleased = bodyRSUDetails.Unreleased;
          this.shareActUnreleased = this.awardUnreleased;
          if(this.user.EmployeeStatus == "Terminated"){
            this.shareActSharesReleasing = this.shareActUnreleased;
          }
          this.trendUnreleased = this.maxUnreleased - this.shareActUnreleased;
          this.estPerForfeit = this.trendUnreleased;
          
        }

        else if(toggle == "Trend" && this.programDetails.GrantYear <= this.globals.yearCondition)
        {
          let trendObtained: boolean = false;
          for (let k = 0; k < this.yearCode.length; k++)
          {
            if(this.yearCode[k].Code == this.awardDate) {
              
              //Shares Releasing
              let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing);
              let sharesOiPortionTarget = Math.round(sharesTargetVestTotal * (3/4));
              let sharesTsrOptionTarget = Math.round(sharesTargetVestTotal) - Math.round(sharesOiPortionTarget);
              let sharesOiPortionTrend = Math.round(sharesOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
              let sharesTsrOptionTrend = Math.round(sharesTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
              let sharesTrendVestTotal = Math.round(sharesOiPortionTrend) + Math.round(sharesTsrOptionTrend);
              bodyRSUDetails.SharesReleasing = sharesTrendVestTotal;
              this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
              this.shareActSharesReleasing = this.awardSharesReleasing;
              this.shareActNextReleaseVal = this.shareActSharesReleasing * this.marketQuoteCurrentPrice.latestPrice;

              //Unreleased
              let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased);
              let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
              let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
              let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
              let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
              let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
              bodyRSUDetails.Unreleased = unreleasedTrendVestTotal;
              this.awardUnreleased = bodyRSUDetails.Unreleased;
              this.shareActUnreleased = this.awardUnreleased;
              if(this.user.EmployeeStatus == "Terminated"){
                this.shareActSharesReleasing = this.shareActUnreleased;
              }
              this.trendUnreleased = this.maxUnreleased - this.shareActUnreleased;
              this.estPerForfeit = this.trendUnreleased;
              
              trendObtained = true;
            }
          }

          if(!trendObtained) {
            let sharesTargetVestTotal = bodyRSUDetails.SharesReleasing; 
            bodyRSUDetails.SharesReleasing = sharesTargetVestTotal;

            let unreleasedTargetVestTotal = bodyRSUDetails.Unreleased;
            bodyRSUDetails.Unreleased = unreleasedTargetVestTotal;
          }
        }

        else if(toggle == "Trend" && this.programDetails.GrantYear > this.globals.yearCondition)
        {
          let trendObtained: boolean = false;
          for (let k = 0; k < this.yearCode.length; k++)
          {
            if(this.yearCode[k].Code == this.awardDate) {

              //Shares Releasing
              let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing);
              let sharesOiPortionTarget = Math.round(sharesTargetVestTotal * (3/4));
              let sharesTsrOptionTarget = Math.round(sharesTargetVestTotal) - Math.round(sharesOiPortionTarget);
              let sharesOiPortionTrend = Math.round(sharesOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
              let sharesTsrOptionTrend = Math.round(sharesTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
              let sharesTrendVestTotal = Math.round(sharesOiPortionTrend) + Math.round(sharesTsrOptionTrend);
              bodyRSUDetails.SharesReleasing = sharesTrendVestTotal;
              this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
              this.shareActSharesReleasing = this.awardSharesReleasing;
              this.shareActNextReleaseVal = this.shareActSharesReleasing * this.marketQuoteCurrentPrice.latestPrice;

              //Unreleased
              let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased);
              let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
              let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
              let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
              let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
              let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
              bodyRSUDetails.Unreleased = unreleasedTrendVestTotal;
              this.awardUnreleased = bodyRSUDetails.Unreleased;
              this.shareActUnreleased = this.awardUnreleased;
              if(this.user.EmployeeStatus == "Terminated"){
                this.shareActSharesReleasing = this.shareActUnreleased;
              }
              this.trendUnreleased = this.maxUnreleased - this.shareActUnreleased;
              this.estPerForfeit = this.trendUnreleased;
              
              trendObtained = true;
            }
          }

          if(!trendObtained) {
            let sharesTargetVestTotal = bodyRSUDetails.SharesReleasing; 
            bodyRSUDetails.SharesReleasing = sharesTargetVestTotal;

            let unreleasedTargetVestTotal = bodyRSUDetails.Unreleased;
            bodyRSUDetails.Unreleased = unreleasedTargetVestTotal;
          }
        }

        else if(toggle == "Target")
        {
          this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
          this.awardUnreleased = bodyRSUDetails.Unreleased;

          this.awardUnreleased = this.awardUnreleased;

          if(this.PSUDisplayInd == "N") {
            this.shareActSharesReleasing = this.awardSharesReleasing + this.SharesAttainedPSU;
            this.shareActUnreleased = this.awardUnreleased + this.SharesAttainedPSU;

            if(this.user.EmployeeStatus == "Terminated"){
              this.shareActSharesReleasing = this.shareActUnreleased + this.SharesAttainedPSU;
            }
          } else {
            this.shareActSharesReleasing = this.awardSharesReleasing;
            this.shareActUnreleased = this.awardUnreleased;

            if(this.user.EmployeeStatus == "Terminated"){
              this.shareActSharesReleasing = this.shareActUnreleased;
            }
          }
          this.shareActNextReleaseVal = this.shareActSharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
          // this.shareActSharesReleasing = this.shareActNextReleaseVal;

          this.maxUnreleased = this.maxUnreleased - this.shareActUnreleased;
          this.estPerForfeit = this.maxUnreleased;
        }  
      }  
      }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getheaderRSUDetails");
    error.message += errorLocation;
    throw error;
  }
}

  // Details Tab Method - START 
  /**********************************************************************************
   * Method Name: getRSUDetails                                                                 
   * Description: Get the RSU award details                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 08/06/2019           JPA                         Added if else statement for storring session to prevent api to call multiple api calls. 
   * 08/14/2019           JPA                         Adjusted when to call methods for fix to BUG 418365
   * 11/19/2019           Katrina Narag               Removed date format
   * 1/14/2019            Jikho Enrique               Updated value assignment 
   * 1/15/2019            Jikho Enrique               Modified values for Formers
   *********************************************************************************/
  getRSUDetails() {
    try {
      
      if(sessionStorage.getItem("RSU Details Data") != null)
      {
        let data = JSON.parse(sessionStorage.getItem("RSU Details Data"));
        let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
        sessionStorage.setItem("Header RSU Details", JSON.stringify(sortedBody[0]));
        let shareAct = data[0][0];
        this.holdingProgramDetailsRSUService.shareAct = shareAct;
        this.shareActAcceptance = shareAct.Acceptance;
        this.shareActForfeited = shareAct.Forfeited;
        this.shareActGrantDate = shareAct.GrantDate;
        this.shareActGrantDesc = shareAct.GrantDescription;
        this.shareActGrantId = shareAct.GrantID;
        this.shareActGrantPrice = shareAct.GrantPrice;
        this.shareActNetRemainAtBroker = shareAct.NetRemainingAtBroker;
        shareAct.NextRelease != null ? this.shareActNextRelease = shareAct.NextRelease : "";
        this.shareActNextReleaseVal = shareAct.SharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
        this.shareActReleased = shareAct.Released;
        this.shareActSharesReleasing = shareAct.SharesReleasing;
        this.shareActSoldTransferred = shareAct.SoldTransferred;
        this.shareActTotalAwarded = shareAct.TotalAwarded;
        this.shareActUnreleased = Math.floor(shareAct.Unreleased);
        if(this.user.EmployeeStatus == "Terminated"){
          this.shareActSharesReleasing = this.shareActUnreleased;
        }
        this.shareActWithholdingForTaxes = shareAct.WithholdingforTaxes;
        this.shareActIsGrantAgreementValid = shareAct.IsGrantAgreementValid;   

        let sas = data[1][0];
        this.originalGrant_shares = sas.OriginalGranted - this.psuShares; //JCC -- OriginalShares with PSU minus PSU since PSU is separated display.
        this.originalGrant_grantValue = sas.OriginalGrantedValue;
        this.additional_shares = sas.AdditionalRsu;
        this.additional_grantValue = sas.AdditionalRsuValue;
        this.total_shares = sas.TotalAwarded;
        this.total_grantValue = sas.TotalAwardedValue;
        
        
        let sas_ur = data[2][0];
        this.tt_unreleased = sas_ur;
        this.unvested_shares = sas_ur.SharesUnvested;
        this.vestedButUnreleased = sas_ur.VestedButUnreleased;
        this.totalUnreleased = sas_ur.TotalUnreleased;


        this.sas_released = data[4];

        
        for(let i = 0; i <data[4].length; i++){

        this.sasReleasedTotal_shares += data[4][i].Shares;
        this.sasReleasedTotal_sharesValue += data[4][i].SharesValue;

   
        }
       

        this.sas_WithheldForTax_Shares = data[3][0].WithheldForTax;
        this.sas_WithheldForTax_Value = data[3][0].WithheldForTaxValue;
        this.sas_WithholdingRefund_Shares = data[3][0].WithHoldingRefund
        this.sas_WithholdingRefund_value = data[3][0].WithHoldingRefundValue
        this.sas_TotalWithheld_Shares = data[3][0].TotalWithheld;
        this.sas_TotalWithheld_Value = data[3][0].TotalWithheldValue;
        

        
        /* This block of code show/hides Forfeited Shares row */
        let isPsuSummaryTabSelected: string="Y";

        if(isPsuSummaryTabSelected == "Y")
        {
          this.shareActDisplayForfeited = true;
        } 
        else 
        {
          this.shareActDisplayForfeited = false;
        }
        /* End of code to show/hide Forfeited Shares row */

        if(this.shareActAcceptance == "Action Required") {
          this.shareActActionReq = true;
        } else {
          this.shareActActionReq = false;
        }
  
        if(this.shareActIsGrantAgreementValid == "N") {
          this.shareActDisplayAgreementAcceptance = false;
          this.shareActDisplayNextRelease = false;
          this.shareActDisplaySharesReleasing = false;
          this.shareActDisplayNextReleaseVal = false;
          this.shareActAgreementTabInd = false;
        } else {
          this.shareActAgreementTabInd = true;
        }
  
        if(this.shareActNextRelease == "" || this.shareActNextRelease == null) { // if null
          this.shareActDisplayNextRelease = false;
          this.shareActDisplayNextReleaseVal = false;
        } 
  
        if(this.shareActSharesReleasing == 0 || this.shareActSharesReleasing == null) { // if null
          this.shareActDisplaySharesReleasing = false;          
        }
  
        if(this.shareActTotalAwarded.toString() == "Forfeited") {
          this.displayVestCard = false;
          this.displayReleaseCard = false;
          this.displayDivCard = false;
        }    

        if(this.shareActForfeited == null && this.shareActNetRemainAtBroker == null && this.shareActReleased == null &&
          this.shareActSoldTransferred == null && this.shareActTotalAwarded == null && this.shareActUnreleased == null &&
           this.shareActWithholdingForTaxes == null) {
          this.noShareAct = true;
        }
        
        if(this.keyExecDisplayInd == 0)
        {
          this.showKeyExectoggle = false;
        }
        else
        {
          if(this.planNum != null ){
          if(this.programDetails.PlanNum == this.planNum[0]['OI'] || 
             this.programDetails.PlanNum == this.planNum[1]['OI'] || 
             this.programDetails.PlanNum == this.planNum[2]['OI'])
          {
            if(this.shareActUnreleased != 0)
            this.showKeyExectoggle = true;
          }
          else
          {
            this.showKeyExectoggle = false;
          }
        }
        } 

        this.showKeyExectoggle != false ? this.getheaderRSUDetails(sortedBody,this.keyExecToggle) : "";
        this.executeMethod();
        this.getRSUDetailsVestInfo();
        this.getRSUDetailsRelInfo();
        this.getRSUDetailsDivInfo();
        this.getPSUDetails(); 
        this.getVestAccordion();
      }
      else
      {
        this.holdingProgramDetailsRSUService.getRSUDetails(this.programDetails.GrantNum).pipe(map(res => res)).subscribe(data => {   
          try {      
            if(Array.isArray(data) && data.length > 0) {
              let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
              sessionStorage.setItem("RSU Details Data", JSON.stringify(data));
              sessionStorage.setItem("Header RSU Details", JSON.stringify(sortedBody[0]));
              let shareAct = data[0][0];
              this.holdingProgramDetailsRSUService.shareAct = shareAct;
              this.shareActAcceptance = shareAct.Acceptance;
              this.shareActForfeited = shareAct.Forfeited;
              this.shareActGrantDate = shareAct.GrantDate;
              this.shareActGrantDesc = shareAct.GrantDescription;
              this.shareActGrantId = shareAct.GrantID;
              this.shareActGrantPrice = shareAct.GrantPrice;
              this.shareActNetRemainAtBroker = shareAct.NetRemainingAtBroker;
              shareAct.NextRelease != null ? this.shareActNextRelease = shareAct.NextRelease : "";
              this.shareActNextReleaseVal = shareAct.SharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
              this.shareActReleased = shareAct.Released;
              this.shareActSharesReleasing = shareAct.SharesReleasing;
              this.shareActSoldTransferred = shareAct.SoldTransferred;
              this.shareActTotalAwarded = shareAct.TotalAwarded;
              this.shareActUnreleased = Math.floor(shareAct.Unreleased);
              if(this.user.EmployeeStatus == "Terminated"){
                this.shareActSharesReleasing = this.shareActUnreleased;
              }
              this.shareActWithholdingForTaxes = shareAct.WithholdingforTaxes;
              this.shareActIsGrantAgreementValid = shareAct.IsGrantAgreementValid;   
              
              let sas = data[1][0];
              this.originalGrant_shares = sas.OriginalGranted - this.psuShares;
              this.originalGrant_grantValue = sas.OriginalGrantedValue;
              this.additional_shares = sas.AdditionalRsu;
              this.additional_grantValue = sas.AdditionalRsuValue;
              this.total_shares = sas.TotalAwarded;
              this.total_grantValue = sas.TotalAwardedValue;
  
              
              let sas_ur = data[2][0];
              this.tt_unreleased = sas_ur;
              this.unvested_shares = sas_ur.SharesUnvested;
              this.vestedButUnreleased = sas_ur.VestedButUnreleased;
              this.totalUnreleased = sas_ur.TotalUnreleased;
  
              
              this.sas_released = data[4];
              
  
              for(let i = 0; i <data[4].length; i++){
   
              this.sasReleasedTotal_shares += data[4][i].Shares;
              this.sasReleasedTotal_sharesValue += data[4][i].SharesValue;
    
         
              }
             
  
              this.sas_WithheldForTax_Shares = data[3][0].WithheldForTax;
              this.sas_WithheldForTax_Value = data[3][0].WithheldForTaxValue;
              this.sas_WithholdingRefund_Shares = data[3][0].WithHoldingRefund
              this.sas_WithholdingRefund_value = data[3][0].WithHoldingRefundValue
              this.sas_TotalWithheld_Shares = data[3][0].TotalWithheld;
              this.sas_TotalWithheld_Value = data[3][0].TotalWithheldValue;
              
  
              
              /* This block of code show/hides Forfeited Shares row */
              let isPsuSummaryTabSelected: string="Y";
      
              if(isPsuSummaryTabSelected == "Y")
              {
                this.shareActDisplayForfeited = true;
              } 
              else 
              {
                this.shareActDisplayForfeited = false;
              }
              /* End of code to show/hide Forfeited Shares row */
      
              if(this.shareActAcceptance == "Action Required") {
                this.shareActActionReq = true;
              } else {
                this.shareActActionReq = false;
              }
        
              if(this.shareActIsGrantAgreementValid == "N") {
                this.shareActDisplayAgreementAcceptance = false;
                this.shareActDisplayNextRelease = false;
                this.shareActDisplaySharesReleasing = false;
                this.shareActDisplayNextReleaseVal = false;
                this.shareActAgreementTabInd = false;
              } else {
                this.shareActAgreementTabInd = true;
              }
        
              if(this.shareActNextRelease == "" || this.shareActNextRelease == null) { // if null
                this.shareActDisplayNextRelease = false;
                this.shareActDisplayNextReleaseVal = false;
              } 
        
              if(this.shareActSharesReleasing == 0 || this.shareActSharesReleasing == null) { // if null
                this.shareActDisplaySharesReleasing = false;          
              }
        
              if(this.shareActTotalAwarded.toString() == "Forfeited") {
                this.displayVestCard = false;
                this.displayReleaseCard = false;
                this.displayDivCard = false;
              }    
      
              if(this.shareActForfeited == null && this.shareActNetRemainAtBroker == null && this.shareActReleased == null &&
                this.shareActSoldTransferred == null && this.shareActTotalAwarded == null && this.shareActUnreleased == null &&
                 this.shareActWithholdingForTaxes == null) {
                this.noShareAct = true;
              }

              if(this.keyExecDisplayInd == 0)
              {
                this.showKeyExectoggle = false;
              }
              else
              {
                if(this.planNum != null){ 
                if(this.programDetails.PlanNum == this.planNum[0]['OI'] || 
                   this.programDetails.PlanNum == this.planNum[1]['OI'] || 
                   this.programDetails.PlanNum == this.planNum[2]['OI'])
                {
                  if(this.shareActUnreleased != 0)
                  this.showKeyExectoggle = true;
                }
                else
                {
                  this.showKeyExectoggle = false;
                }
              }
              }
  
              // if(this.programDetails.AwardName.toLowerCase().indexOf("keyex") != -1) 
              // {
              //   this.shareActUnreleased == 0 ? this.showKeyExectoggle = false : this.showKeyExectoggle = true;
              // } 
              // else 
              // {
              //   this.showKeyExectoggle = false;
              // }
              this.showKeyExectoggle != false ? this.getheaderRSUDetails(sortedBody,this.keyExecToggle) : "";
            }
      
            this.executeMethod();
            this.getRSUDetailsVestInfo();
            this.getRSUDetailsRelInfo();
            this.getRSUDetailsDivInfo();
            this.getPSUDetails(); 
            this.getVestAccordion();
          } catch(error) {
            let errorLocation = this.errorLocation.replace("methodName", "getRSUDetails");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUDetails");
          error.error.message += errorLocation;
          throw error.error;
        });
      }

    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUDetails");
      error.message += errorLocation;
      throw error;
    }
  }
     /**********************************************************************************
    * Method Name: getVestAccordion                                                                 
    * Description: Hide Vest Accordion for FNQ/Involuntary Termination Grants                               
    *                                                                                          
    * Date                 Modified by                 Description                             
    * 06/5/2023            Nancy Lorraine Mateo        Initial Draft
    *********************************************************************************/

     getVestAccordion(){
      this.isEnabledVest = false;
      const grantNum = this.programDetails.GrantNum;

      this.holdingProgramDetailsRSUService.getVestCard(grantNum).subscribe(data => {
        this.isEnabledVest = data.isEnabledVest === 'true';
      });
    }
  

   /**********************************************************************************
   * Method Name: getRSUDetailsVestInfo                                                                 
   * Description: Get the RSU vest accordion details                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 08/14/2019           JPA                         Added a counter increment and if else statement checker for fix to BUG 418365
   * 08/19/2019           KMN                         BETA - Bug 430535 No default 0 zero if null
   *********************************************************************************/
  async getRSUDetailsVestInfo() {
    try {
      await this.holdingProgramDetailsRSUService.getRSUDetailsVestInfo(this.programDetails.GrantNum, this.vestRsuDate).pipe(map(res => res)).subscribe(data => { 
        try {
          if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0)) { 
            this.vestTotalShares = 0;
            sessionStorage.setItem("Vest Data Source",JSON.stringify(data[0])); //data for the Vest is Session Storaged
            this.vestDataSource = data[0];
            let vest = data[1][0];

            for(let i = 0; i < this.vestDataSource.length; i++) {
              if(this.PSUDisplayInd == "N" && this.vestDataSource[0].Status == "Unvested"){
                this.vestDataSource[i].Shares = this.vestDataSource[i].Shares + this.SharesAttainedPSU;
              } else {
                this.vestDataSource[i].Shares = this.vestDataSource[i].Shares
              }

              this.vestTotalShares += this.vestDataSource[i].Shares;
            }            

            if(vest != null || vest != undefined){
              this.vestAdditionalRsu = vest.AdditionalRsu;

              if(this.vestAdditionalRsu == null){
                this.vestAdditionalRsu = 0;
              }
              this.vestLocalCurrency = vest.LocalCurrency;

              if(this.PSUDisplayInd == "N" && this.vestDataSource[0].Status == "Unvested") {
                this.vestOrigGrantedRsu = vest.OriginalGrantedRsu + this.SharesAttainedPSU;
                this.vestTotalAward = vest.TotalAward + this.SharesAttainedPSU;
              } else {
                this.vestOrigGrantedRsu = vest.OriginalGrantedRsu;
                this.vestTotalAward = vest.TotalAward;
              }

              if(this.vestOrigGrantedRsu == null){
                this.vestOrigGrantedRsu = 0;
              }
              this.vestValue = vest.Value;
              this.vestValueLocal = vest.ValueLocal;
              this.vestVestFmv = vest.VestFMV;
      
              if(this.vestLocalCurrency == "USD" || this.vestLocalCurrency == "") {
                this.vestDisplayValueLocal = false;
              } else {
                this.vestDisplayValueLocal = true;
              }
            }
    
            this.vestLoading = false;
          } else {
            this.displayVestCard = false;
          }
          
          this.executeMethod();
          this.detailsAccordionCounter++;
          this.selectKeyExecToggle(this.keyExecToggle);
          if(this.detailsAccordionCounter == 2){
            this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
          }
          
        } catch(error) {
          this.errorVestSection = true;		
          this.executeMethod();
          this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
          let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsVestInfo");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorVestSection = true;		
        this.executeMethod();
        this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsVestInfo");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorVestSection = true;		
      this.executeMethod();
      this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
      let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsVestInfo");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: selectKeyExecToggle                                                                 
   * Description: Ensures that the Key Exec Toggle only works on Key Exec Awards                              
   *                                                                                          
   * #         Date                 Modified by                 Description                             
   * 1         19/Feb/19            Kim Phillip Aying           Initial draft
   * 2         26/Feb/19            Kim Phillip Aying           Added additional Method   
   * 3         05/27/19             KMN                         Bug 330907 fix - 'Originally Scheduled for Vest', 'Additional RSUs in Lieu of Dividend', 'Total Scheduled for Vest' is not displayed in Vest and Release section in RSU Awards details page 
   *********************************************************************************/
  selectKeyExecToggle(toggle){
    try{
      // Code for Sub Components - START
      // this.rsuDetailsCard.keyExecToggleValue = toggle;
      // this.rsuVestCard.keyExecToggleValue = toggle;
      // this.rsuRelCard.keyExecToggleValue = toggle;
      // this.rsuDivCard.keyExecToggleValue = toggle;
      // this.rsuDetailsCard.getheaderRSUDetails();
      // this.rsuVestCard.displayKeyExecAwardStatus();
      // this.rsuRelCard.displayKeyExecAwardStatus();
      // this.rsuDivCard.displayDividendCard();
      if(this.awardDetailsSummary != undefined) {
          this.awardDetailsSummary.keyExecToggleValue = toggle;
        this.awardDetailsSummary.getheaderRSUDetails();
      }
      // Code for Sub Components - END
      if(this.shareActUnreleased != 0 && this.showKeyExectoggle){
      sessionStorage.setItem("Key Exec Holding Summary Value", JSON.stringify(toggle));      
      sessionStorage.setItem("Key Exec Rsu Details Value", JSON.stringify(toggle));
      sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Rsu Details"));
      }
        if(toggle == "Target") {
          this.setTargetVest = true;
          this.setMaximumVest = false;
          this.setTrendVest = false;
        } else if(toggle == "Trend") {
          this.setTrendVest = true;
          this.setMaximumVest = false;
          this.setTargetVest = false;
        } else {
          this.setMaximumVest = true;
          this.setTargetVest = false;
          this.setTrendVest = false;
        }
        
      if(this.shareActUnreleased != 0){
      this.keyExecToggle = toggle;
      }else{
        this.keyExecToggle = "Target";//Target
      }
      let bodyRSUDetails = JSON.parse(sessionStorage.getItem("Header RSU Details")); //#2
      this.getheaderRSUDetails(bodyRSUDetails,this.keyExecToggle); // #2

      if(this.planNum != null && this.planNum != undefined && this.planNum.length > 0) 
      {
        if(this.programDetails.PlanNum == Number(this.planNum[0].OI) || this.programDetails.PlanNum == Number(this.planNum[1].OI) || this.programDetails.PlanNum == Number(this.planNum[2].OI))
        {    
          this.estForfeit = true;
          if(this.shareActUnreleased != 0){
            this.showKeyExectoggle = true;
          }else{
            this.showKeyExectoggle = false;
          }          
          this.displayKeyExecAwardStatus(this.keyExecToggle);    
        }
        else
        {   
          this.keyExecToggle = "Target";//Target
          this.displayKeyExecAwardStatus(this.keyExecToggle);        
        }
      }
    }
    catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectKeyExecToggle");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: displayKeyExecAwardStatus                                                                 
   * Description: Method that shows and hides awards based on the Toggle Value                              
   *                                                                                          
   * #         Date                 Modified by                 Description                             
   * 1         19/Feb/19            Kim Phillip Aying           Initial draft
   * 2         26/Feb/19            Kim Phillip Aying           Computation was added on the Key Exec Toggle   
   * 3         08/14/19             JPA                         Removed this.loading = false and transferred to different method    
   * 4         1/2/20               Dale                        Updated Vest data source and Release data source
   * 5         1/14/20              Jikho                       Update for Formers
   * 6         10/01/2020           Kenneth de Padua            Added condition for getting the source of data and data2
   * 7         06/08/2023           Jewel C. Caudilla           Update Computation for Max,Target, and Trend of KeyExecToggle.
   *********************************************************************************/
  displayKeyExecAwardStatus(toggle){
    try {
      this.vestTotalShares = 0;
      this.relTotalShares = 0;
      //KDP: Start
      let data = [];
      let data2 = [];
      // VEST
      if (this.vestDataSource.length != 0) {
        if (sessionStorage.getItem("Vest Data Source").length != 0) {
          data = JSON.parse(sessionStorage.getItem("Vest Data Source"));
        } else {
          data = this.vestDataSource;
        }
      }
      // RELEASE 
      if (this.releaseDataSource.length != 0) {
        if (sessionStorage.getItem("Rel Data Source").length != 0) {
          data2 = JSON.parse(sessionStorage.getItem("Rel Data Source"));
        } else {
          data2 = this.releaseDataSource;
        }
      }
      this.vestDataSource = [];
      this.releaseDataSource = [];
      //let data = JSON.parse(sessionStorage.getItem("Vest Data Source"));
      //let data2 = JSON.parse(sessionStorage.getItem("Rel Data Source"));
      //KDP: END

      let newVestData: any[] = [];
      let newRelData: any[] = [];
        if(toggle == "Target")
        {
          newVestData.push(data);
          newRelData.push(data2);

          this.vestDataSource = data;
          this.releaseDataSource = data2;
  
          for(let i = 0; i < this.vestDataSource.length; i++) {
            if(this.PSUDisplayInd == "N" && this.vestDataSource[0].Status == "Unvested"){
              this.vestDataSource[i].Shares = this.vestDataSource[i].Shares + this.SharesAttainedPSU;
            } else {
              this.vestDataSource[i].Shares = this.vestDataSource[i].Shares
            }

            this.vestTotalShares += this.vestDataSource[i].Shares;
          }
  
          for(let i = 0; i < this.releaseDataSource.length; i++) {

            if(this.PSUDisplayInd == "N" && this.psuGePastInd == "N") {
              this.releaseDataSource[i].Shares = this.releaseDataSource[i].Shares + this.SharesAttainedPSU;
            } else {
              this.releaseDataSource[i].Shares = this.releaseDataSource[i].Shares
            }

            this.relTotalShares += this.releaseDataSource[i].Shares;
          }
  
          this.keyExecVestHideShow = true;
          this.keyExecRelHideShow = true;
          this.releaseCardDetails = true;
        }
        else if(toggle == "Maximum" && (this.programDetails.PlanNum != 72 || this.programDetails.PlanNum != 74) && this.programDetails.GrantYear <= this.globals.yearCondition)
        {
          this.releaseCardDetails = true;
          this.keyExecVestHideShow = false;
          this.keyExecRelHideShow = false;
          if(this.user.EmployeeStatus == "Terminated"){

            for(let i=0; i<data.length; i++)
            {
              
              if(data[i].Status == "Vested")
              {
                data[i].Shares = Math.round(data[i].Shares );
              }else{
                data[i].Shares = Math.round(data[i].Shares * (3/2));
              }
                this.vestTotalShares += data[i].Shares;
                newVestData.push(data[i]);
                this.vestDataSource = newVestData;
              
            }
          for(let i=0; i<data2.length; i++)
          {
            if(data2[i].Status == "Forfeited"){
              data2[i].Shares = Math.round(data2[i].Shares );
            }else{
              data2[i].Shares = Math.round(data2[i].Shares * (3/2));
            }
              this.relTotalShares += data2[i].Shares;
              newRelData.push(data2[i]);
              this.releaseDataSource = newRelData;              
          } 


          }else{
            for(let i=0; i<data.length; i++)
          {
            if(data[i].Status == "Unvested")
            {
              data[i].Shares = Math.round(data[i].Shares * (3/2));
              this.vestTotalShares += data[i].Shares;
              newVestData.push(data[i]);
              this.vestDataSource = newVestData;
            }
          }

          for(let i=0; i<data2.length; i++)
          {
            if(data2[i].Status == "Unreleased")
            {
              data2[i].Shares = Math.round(data2[i].Shares * (3/2));
              this.relTotalShares += data2[i].Shares;
              newRelData.push(data2[i]);
              this.releaseDataSource = newRelData;
            }
            // else if(data2[i].Status != "Unreleased")
            // {
            //   this.relTotalShares += data2[i].Shares;
            //   newRelData.push(data2[i]);
            //   this.releaseDataSource = newRelData;            
            // }         
          }  
          }
        }
        else if(toggle == "Maximum" && (this.programDetails.PlanNum == 72 || this.programDetails.PlanNum == 74) && this.programDetails.GrantYear > this.globals.yearCondition)
        {
          this.releaseCardDetails = true;
          this.keyExecVestHideShow = false;
          this.keyExecRelHideShow = false;
          if(this.user.EmployeeStatus == "Terminated"){

            for(let i=0; i<data.length; i++)
            {
              
              if(data[i].Status == "Vested")
              {
                data[i].Shares = Math.round(data[i].Shares );
              }else{
                data[i].Shares = Math.round(data[i].Shares * (2));
              }
                this.vestTotalShares += data[i].Shares;
                newVestData.push(data[i]);
                this.vestDataSource = newVestData;
              
            }
          for(let i=0; i<data2.length; i++)
          {
            if(data2[i].Status == "Forfeited"){
              data2[i].Shares = Math.round(data2[i].Shares );
            }else{
              data2[i].Shares = Math.round(data2[i].Shares * (2));
            }
              this.relTotalShares += data2[i].Shares;
              newRelData.push(data2[i]);
              this.releaseDataSource = newRelData;              
          } 


          }else{
            for(let i=0; i<data.length; i++)
          {
            if(data[i].Status == "Unvested")
            {
              data[i].Shares = Math.round(data[i].Shares * (2));
              this.vestTotalShares += data[i].Shares;
              newVestData.push(data[i]);
              this.vestDataSource = newVestData;
            }
          }

          for(let i=0; i<data2.length; i++)
          {
            if(data2[i].Status == "Unreleased")
            {
              data2[i].Shares = Math.round(data2[i].Shares * (2));
              this.relTotalShares += data2[i].Shares;
              newRelData.push(data2[i]);
              this.releaseDataSource = newRelData;
            }
            // else if(data2[i].Status != "Unreleased")
            // {
            //   this.relTotalShares += data2[i].Shares;
            //   newRelData.push(data2[i]);
            //   this.releaseDataSource = newRelData;            
            // }         
          }  
          }
        }
        else if(toggle == "Trend" && (this.programDetails.PlanNum != 72 || this.programDetails.PlanNum != 74) && this.programDetails.GrantYear <= this.globals.yearCondition)
        {
          this.releaseCardDetails = true;
          this.keyExecVestHideShow = false;
          this.keyExecRelHideShow = false;
          if(this.user.EmployeeStatus == "Terminated"){
            for(let i=0; i<data.length; i++)
            {
              
                let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) {
                    let unreleasedTargetVestTotal = Math.round(data[i].Shares);
                    let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                    let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                    let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                    let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                    let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                    data[i].Shares = unreleasedTrendVestTotal;
                    this.vestTotalShares += data[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //c/o Kim
                if(!trendObtained) {
                  data[i].Shares = Math.round(data[i].Shares);
                  this.vestTotalShares += data[i].Shares;
                }
  
                newVestData.push(data[i]);
                this.vestDataSource = newVestData;
              
            }
    
            for(let i=0; i<data2.length; i++)
            {
              
                let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) 
                  {                  
                    if(data2[i].Status == "Forfeited"){
                      data2[i].Shares = Math.round(data2[i].Shares);
                    }else{
                      let unreleasedTargetVestTotal = Math.round(data2[i].Shares);
                      let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                      let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                      let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                      let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                      let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                      data2[i].Shares = unreleasedTrendVestTotal;
                    }
                    this.relTotalShares += data2[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //For inquiry c/o Kim
                if(!trendObtained) 
                {
                  data2[i].Shares = Math.round(data2[i].Shares);
                  this.relTotalShares += data2[i].Shares;
                }
  
                newRelData.push(data2[i]);
                this.releaseDataSource = newRelData; 
                 
            } 
          }else{
            for(let i=0; i<data.length; i++)
            {
              if(data[i].Status == "Unvested")
              {
                let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) {
                    let unreleasedTargetVestTotal = Math.round(data[i].Shares);
                    let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                    let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                    let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                    let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                    let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                    data[i].Shares = unreleasedTrendVestTotal;
                    this.vestTotalShares += data[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //c/o Kim
                if(!trendObtained) {
                  data[i].Shares = Math.round(data[i].Shares);
                  this.vestTotalShares += data[i].Shares;
                }
  
                newVestData.push(data[i]);
                this.vestDataSource = newVestData;
              }
            }
    
            for(let i=0; i<data2.length; i++)
            {
              if(data2[i].Status == "Unreleased")
              {
                let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) 
                  {                  
                    let unreleasedTargetVestTotal = Math.round(data2[i].Shares);
                    let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                    let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                    let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                    let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                    let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                    data2[i].Shares = unreleasedTrendVestTotal;
                    this.relTotalShares += data2[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //For inquiry c/o Kim
                if(!trendObtained) 
                {
                  data2[i].Shares = Math.round(data2[i].Shares);
                  this.relTotalShares += data2[i].Shares;
                }
  
                newRelData.push(data2[i]);
                this.releaseDataSource = newRelData; 
              }
              // else if(data2[i].Status != "Unreleased")
              // {
              //   newRelData.push(data2[i]);
              //   this.releaseDataSource = newRelData;            
                               
              //   this.relTotalShares += this.releaseDataSource[i].Shares;   
              // }         
            } 
          }
                 
        }
        else if(toggle == "Trend" && (this.programDetails.PlanNum == 72 || this.programDetails.PlanNum == 74) && this.programDetails.GrantYear > this.globals.yearCondition)
        {
          this.releaseCardDetails = true;
          this.keyExecVestHideShow = false;
          this.keyExecRelHideShow = false;
          if(this.user.EmployeeStatus == "Terminated"){
            for(let i=0; i<data.length; i++)
            {
              
                let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) {
                    let unreleasedTargetVestTotal = Math.round(data[i].Shares);
                    let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                    let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                    let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                    let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                    let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                    data[i].Shares = unreleasedTrendVestTotal;
                    this.vestTotalShares += data[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //c/o Kim
                if(!trendObtained) {
                  data[i].Shares = Math.round(data[i].Shares);
                  this.vestTotalShares += data[i].Shares;
                }
  
                newVestData.push(data[i]);
                this.vestDataSource = newVestData;
              
            }
    
            for(let i=0; i<data2.length; i++)
            {
              
                let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) 
                  {                  
                    if(data2[i].Status == "Forfeited"){
                      data2[i].Shares = Math.round(data2[i].Shares);
                    }else{
                      let unreleasedTargetVestTotal = Math.round(data2[i].Shares);
                      let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                      let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                      let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                      let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                      let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                      data2[i].Shares = unreleasedTrendVestTotal;
                    }
                    this.relTotalShares += data2[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //For inquiry c/o Kim
                if(!trendObtained) 
                {
                  data2[i].Shares = Math.round(data2[i].Shares);
                  this.relTotalShares += data2[i].Shares;
                }
  
                newRelData.push(data2[i]);
                this.releaseDataSource = newRelData; 
                 
            } 
          }else{
            for(let i=0; i<data.length; i++)
            {
              if(data[i].Status == "Unvested")
              {
                let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) {
                    let unreleasedTargetVestTotal = Math.round(data[i].Shares);
                    let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                    let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                    let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                    let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                    let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                    data[i].Shares = unreleasedTrendVestTotal;
                    this.vestTotalShares += data[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //c/o Kim
                if(!trendObtained) {
                  data[i].Shares = Math.round(data[i].Shares);
                  this.vestTotalShares += data[i].Shares;
                }
  
                newVestData.push(data[i]);
                this.vestDataSource = newVestData;
              }
            }
    
            for(let i=0; i<data2.length; i++)
            {
              if(data2[i].Status == "Unreleased")
              {
                let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) 
                  {                  
                    let unreleasedTargetVestTotal = Math.round(data2[i].Shares);
                    let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                    let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                    let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                    let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                    let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                    data2[i].Shares = unreleasedTrendVestTotal;
                    this.relTotalShares += data2[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //For inquiry c/o Kim
                if(!trendObtained) 
                {
                  data2[i].Shares = Math.round(data2[i].Shares);
                  this.relTotalShares += data2[i].Shares;
                }
  
                newRelData.push(data2[i]);
                this.releaseDataSource = newRelData; 
              }
              // else if(data2[i].Status != "Unreleased")
              // {
              //   newRelData.push(data2[i]);
              //   this.releaseDataSource = newRelData;            
                               
              //   this.relTotalShares += this.releaseDataSource[i].Shares;   
              // }         
            } 
          }
                 
        }
        // this.loading = false;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "displayKeyExecAwardStatus");
      error.message += errorLocation;
      throw error;
    }
  }

  viewVestDate(vestData) {  
    try {
      this.vestLoading = true;    
      this.vestRsuDate = vestData.VestDate;
      this.vestRsuDate = moment(this.vestRsuDate).format('MM/DD/YYYY');
      this.vestRsuDate = "'" + this.vestRsuDate + "'";
      this.getRSUDetailsVestInfo();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewVestDate");
      error.message += errorLocation;
      throw error;
    }
  }

     /**********************************************************************************
   * Method Name: getRSUDetailsRelInfo                                                                 
   * Description: Get the RSU release accordion details                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 08/14/2019           JPA                         Added a counter increment and if else statement checker for fix to BUG 418365
   * 08/19/2019           KMN                         BETA - Bug 430535 No default 0 zero if null
   *********************************************************************************/
  async getRSUDetailsRelInfo() {
    try {
      await this.holdingProgramDetailsRSUService.getRSUDetailsRelInfo(this.programDetails.GrantNum, this.relRsuDate).pipe(map(res => res)).subscribe(data => {
        try {
          if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0 || data[3].length > 0)) { 
            this.relTotalShares = 0;
            this.relSetCurrencyValue = "USD";        
            let relInfoTable = data[0];            
            let release = data[1][0];

            if(release == undefined)
            {
              this.displayReleaseCard = false;
              this.displayDivCard = false;
            }
            else
            {
              this.displayReleaseCard = true;
              this.displayDivCard = true;
            }
            
            for(let i = 0; i < relInfoTable.length; i++) {
              if(relInfoTable[i].StatusFlag == 1) {
                relInfoTable[i].Status = "Released - " + BrokerType[relInfoTable[i].BrokerCd]; // set the status value base on the status flag
              } else if(relInfoTable[i].StatusFlag == 2) {
                relInfoTable[i].Status = "Released"; // set the status value base on the status flag
              } else if(relInfoTable[i].StatusFlag == 3) {
                relInfoTable[i].Status = "Unreleased"; // set the status value base on the status flag
              } else if(relInfoTable[i].StatusFlag == 4) {
                relInfoTable[i].Status = "Forfeited"; // set the status value base on the status flag
              }
              this.relTotalShares += relInfoTable[i].Shares;
            }

            this.releaseDataSource = relInfoTable;
            sessionStorage.setItem("Rel Data Source",JSON.stringify(this.releaseDataSource)); //data for Release is Session Storaged
            this.relAdditionalRsu = release.AdditionalRsu;
            if(this.relAdditionalRsu == null)
            {
              this.relAdditionalRsu = 0;
            }
            this.relGrossReleased = release.GrossReleased;
            this.relGrossReleasedLoc = release.GrossReleasedLoc;
            this.relGrossReleasedUsd = release.GrossReleasedUsd;
            this.relLocalCurrencyCd = release.LocalCurrencyCd;
            this.relNetReleased = release.NetReleased;
            this.relNetReleasedLoc = release.NetReleasedLoc;
            this.relNetReleasedUsd = release.NetReleasedUsd;
            if(this.PSUDisplayInd == "N" && this.psuGePastInd == "N") {
              this.relOrigScheduled = release.OriginallyScheduled + this.SharesAttainedPSU;
              this.relTotalScheduledRsu = release.TotalScheduledRsu + this.SharesAttainedPSU;
            } else {
              this.relOrigScheduled = release.OriginallyScheduled;
              this.relTotalScheduledRsu = release.TotalScheduledRsu;
            }
            

            if(this.relOrigScheduled == null){
              this.relOrigScheduled = 0;
            }
            this.relReleaseFmv = release.ReleaseFmv;
            this.relTaxWithholdingMethod = release.TaxWithholdingMethod;
            this.relWithheldForTax = release.WithheldForTax;
            this.relWithheldForTaxUsd = release.WithheldForTaxUsd;
            this.relWithheldForTaxValueLoc = release.WithheldForTaxLoc;
            this.relWithholdingRefund = release.WithholdingRefund;
            this.relWithholdingRefundLoc = release.WithholdingRefundLoc;
            this.relWithholdingRefundUsd = release.WithholdingRefundUsd;
    
            this.netReleased = data[3];

            this.checkRelCurrency(this.relSetCurrencyValue);

            if(this.relTaxWithholdingMethod == "Payroll Withholding") {
              this.relWithheldForTax = "Refer to Earnings Statements";
              this.relWithheldForTaxUsd = "Refer to Earnings Statements";
              this.relWithheldForTaxValueLoc = "Refer to Earnings Statements";
            }
    
            if(this.relLocalCurrencyCd == "USD" || this.relLocalCurrencyCd == "") {
              this.relDisplayCurrencyDropdown = false;
            } else {
              this.relDisplayCurrencyDropdown = true;
            }
    
            this.releaseLoading = false;
            this.releaseCardDetails = true;
          } else {
            this.displayReleaseCard = false;
          }

          this.displayReleaseCard == true ? this.keyExecRelHideShow = true : "";
          this.executeMethod();

          this.detailsAccordionCounter++; 
          this.selectKeyExecToggle(this.keyExecToggle);
          if(this.detailsAccordionCounter == 2){
            this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
          }
        } catch(error) {
          this.errorReleaseSection = true;
          this.executeMethod();
          this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
          let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsRelInfo");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorReleaseSection = true;		
        this.executeMethod();
        this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsRelInfo");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorReleaseSection = true;		
      this.executeMethod();
      this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
      let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsRelInfo");
      error.message += errorLocation;
      throw error;
    }
  }

  viewReleaseDate(releaseData) {  
    try {
      this.releaseCardDetails = false;
      this.releaseLoading = true;
    
      // if(releaseData.Status == "Unreleased")
      // {
      //   this.releaseCardDetails = true;
      // }
      // else if(releaseData.Status != "Unreleased")
      // {
      //   this.releaseCardDetails = false;
      // }
      
      if(releaseData.ReleaseDate != null) {
        this.relRsuDate = releaseData.ReleaseDate;
        this.relRsuDate = moment(this.relRsuDate).format('MM/DD/YYYY');
        this.relRsuDate = "'" + this.relRsuDate + "'";
        this.getRSUDetailsRelInfo();
      } else {
        this.releaseLoading = false;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewReleaseDate");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: getRSUDetailsDivInfo                                                                 
   * Description: Get the RSU Div Info details                         
   *                                                                                          
   * Date                 Modified by                 Description         
   * 11/19/2019           Katrina Narag               Removed date format
   *********************************************************************************/
  getRSUDetailsDivInfo() {
    try {
      
      this.holdingProgramDetailsRSUService.getRSUDetailsDivInfo(this.programDetails.GrantNum, this.divRsuDate).pipe(map(res => res)).subscribe(data => {
        try {
          if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0)) { 
            this.divGrantDate = data[0];
            let div = data[1][0];
            let divTable = data[2];
            this.divVestTable = [];
            this.divReleaseTable = [];

            //KMN
            // for(let i=0; i<this.divGrantDate.length; i++){
            //   this.divGrantDate[i]["GrantDate"] = moment(this.divGrantDate[i]["GrantDate"]).format("DD MMM YYYY");
            // }
    
            if(this.isDivInitialized != true) {
              this.divDropdownSelected = data[0][0].GrantDate;
            }

            if(divTable != null || divTable != undefined){
              for(let i = 0; i < divTable.length; i++) {
                if(divTable[i].VestReleaseFlag === 1) {
                  this.divVestTable.push(divTable[i]);
                } else if (divTable[i].VestReleaseFlag === 2) {
                  this.divReleaseTable.push(divTable[i]);
                }
              }
            }

            this.divVestDataSource = this.divVestTable;
            this.divRelDataSource = this.divReleaseTable;

            if(div != null || div != undefined){
              this.divGrantId = div.GrantId;
              this.divAdditionalRsusGranted = div.AdditionalRSUsGranted;
            }
    
            this.isDivInitialized = true;
            this.divLoading = false;
          } else {
            this.displayDivCard = false;
          }

          if(this.showKeyExectoggle) {
            if(this.keyExecToggle == "Target") {//Target
              this.keyExecVestHideShow = true;
            } else {
              this.keyExecVestHideShow = false;
            }
          } else {
            this.displayDivCard == true ? this.keyExecVestHideShow = true : "";
          }
          
          this.executeMethod();
        } catch(error) {
          this.executeMethod();		
          this.errorAdditionalSection = true;		
          this.divLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsDivInfo");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.executeMethod();		
        this.errorAdditionalSection = true;		
        this.divLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsDivInfo");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.executeMethod();		
      this.errorAdditionalSection = true;		
      this.divLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsDivInfo");
      error.message += errorLocation;
      throw error;
    }
  } 

  selectRsuDate(rsuDate) {
    try {
      let dateSelected = moment(rsuDate).format('MM/DD/YYYY');
      this.divLoading = true;
      this.divDropdownSelected = "'" + rsuDate + "'";
      
      this.divRsuDate = this.divDropdownSelected;
      this.divDropdownSelected = rsuDate;
      this.getRSUDetailsDivInfo();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectRsuDate");
      error.message += errorLocation;
      throw error;
    }
  }

  checkRelCurrency(currency) {
    try {
      if(currency == "USD" && currency != null && currency != "") {
        this.relIsSelectedCurrencyUsd = true;
        this.relSetCurrencyValue = "USD";
      } else {
        this.relIsSelectedCurrencyUsd = false;
        this.relSetCurrencyValue = currency;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkRelCurrency");
      error.message += errorLocation;
      throw error;
    }
  }
  // Details Tab Method - END 

  // Sales History Tab Method - START
  getRSUSalesHistory() {
    try {
      this.rsuSalesHistShares = 0;
      this.rsuSalesHistProceedsUSD = 0;
      this.rsuSalesHistPurchaseValueUSD = 0;

      this.holdingProgramDetailsRSUService.getRSUSalesHistory(this.programDetails.GrantNum, this.datePeriod, this.dateTo, this.dateFrom).pipe(map(res => res)).subscribe(data => {
        try {
          if(data[1].length == 0) {
            this.rsuSalesHistDataSource = data[1];
            if(data[0].length == 0) {
              this.displayContactBroker = false;
            } else {
              this.displayContactBroker = true;
              for( let i = 0; i < data[0].length; i++ ){
                this.brokerType[i] = BrokerType[data[0][i].BrokerCd];
                if( i > 0){
                  this.brokerTypes = true;
                }
              }
            }
            this.noSalesHistory = true;
            this.salesTableLoading = false;
          } else {
            this.noSalesHistory = false;
            this.salesTableLoading = false;
            if(data[0].length == 0) {
              this.displayContactBroker = false;
            } else {
              this.displayContactBroker = true;
              for( let i = 0; i < data[0].length; i++ ){
                this.brokerType[i] = BrokerType[data[0][i].BrokerCd];
                if( i > 0){
                  this.brokerTypes = true;
                }
              }
            }
            this.rsuSalesHistDataSource = data[1];
    
            for(let i = 0; i < data[1].length; i++) {
              this.rsuSalesHistShares += (data[1][i].ShareAmount*1);
              if(data[1][i].PriceInUSD != null) {
                this.rsuSalesHistPriceUSD += (data[1][i].PriceInUSD*1);
              }
              if(data[1][i].ProceedsInUSD != null) {
                this.rsuSalesHistProceedsUSD += (data[1][i].ProceedsInUSD*1);
              }
              if(data[1][i].ProceedsInLoc != null) {
                this.rsuSalesHistPurchaseValueUSD += (data[1][i].ProceedsInLoc*1);
              }
            }
          }
          this.loading = false;
        } catch(error) {
          this.errorSalesHistTab = true;		
          this.salesTableLoading = false;		
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getRSUSalesHistory");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorSalesHistTab = true;		
        this.salesTableLoading = false;		
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getRSUSalesHistory");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorSalesHistTab = true;		
      this.salesTableLoading = false;		
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getRSUSalesHistory");
      error.message += errorLocation;
      throw error;
    }
  }

  selectDatePeriod(period) {
    try {
      if(period != 5){
        $(".MDatePicker input").css("background-color","#d3d0d0")
      }else{
        $(".MDatePicker input").css("background-color","white")
      }
      this.datePeriod = period;

      if(this.datePeriod == 1) { // year to date
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.yearToDate;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = moment(currentDate).format('MM/DD/YYYY');
        this.dateTo = "'" +dateToEvent + "'";
        // this.dateToPlaceholder = " "; // Ngb Datepicker

        currentDate.setMonth(0, 1);
        let yearToDate = currentDate;
        let dateFromEvent = moment(yearToDate).format('MM/DD/YYYY');
        this.dateFrom = "'" +dateFromEvent + "'";
        // this.dateFromPlaceholder = " "; // Ngb Datepicker

        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
      } else if(this.datePeriod == 2) { // last year
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.lastYear;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = moment(currentDate).format('MM/DD/YYYY');
        this.dateTo = "'" +dateToEvent + "'";
        // this.dateToPlaceholder = " "; // Ngb Datepicker
        
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        let lastYear = currentDate;
        let dateFromEvent = moment(lastYear).format('MM/DD/YYYY');
        this.dateFrom = "'" +dateFromEvent + "'";
        // this.dateFromPlaceholder = " "; // Ngb Datepicker

        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
      } else if(this.datePeriod == 3) { // last 3 years
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.last3Years;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = moment(currentDate).format('MM/DD/YYYY');
        this.dateTo = "'" +dateToEvent + "'";
        // this.dateToPlaceholder = " "; // Ngb Datepicker

        currentDate.setFullYear(currentDate.getFullYear() - 3);
        let lastThreeYear = currentDate;
        let dateFromEvent = moment(lastThreeYear).format('MM/DD/YYYY');
        this.dateFrom = "'" +dateFromEvent + "'";
        // this.dateFromPlaceholder = " "; // Ngb Datepicker

        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
      } else if(this.datePeriod == 4) { // all dates
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.periodSelected = this.allDates;
        this.disableDatePicker = true;
        this.dateFrom = "' '";
        // this.dateFromPlaceholder = " "; // Ngb Datepicker
        this.dateTo = "' '";
        // this.dateToPlaceholder = " "; // Ngb Datepicker
        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
      } else { // period = 5, custom
        this.disableDatePicker = false;
        this.periodSelected = this.custom;
  
        let currentDate = new Date();
        let inputValue = moment(currentDate).format("YYYY-MM-DD");

        this.dateTo = "'" + inputValue + "'";
        if(this.dateToClicked) {
          // this.dateToInput = this.dateToHolder; // Ngb Datepicker
          this.dateToInput = new Date(this.dateToHolder); // Angular Material Datepicker
        } else {
          this.dateToInput = currentDate; // Angular Material Datepicker
          // this.dateToInput = this.ngbDateParserFormatter.parse(
          //   currentDate.getFullYear().toString() + "-" +
          //   (currentDate.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
          //   currentDate.getDate().toString()
          // ); // Ngb Datepicker
        }
        // this.dateToPlaceholder = moment(inputValue).format("DD MMM YYYY"); // Ngb Datepicker
        
        this.dateFrom = "'" + inputValue + "'";
        if(this.dateFromClicked) {
          this.dateFromInput = new Date(this.dateFromHolder); // Angular Material Datepicker
          // this.dateFromInput = this.dateFromHolder; // Ngb Datepicker
        } else {
          this.dateFromInput = currentDate; // Angular Material Datepicker
          // this.dateFromInput = this.ngbDateParserFormatter.parse(
          //   currentDate.getFullYear().toString() + "-" +
          //   (currentDate.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
          //   currentDate.getDate().toString()
          // ); // Ngb Datepicker
        }
        // this.dateFromPlaceholder = moment(inputValue).format("DD MMM YYYY"); // Ngb Datepicker

        this.dateToChecker = inputValue;
        this.dateFromChecker = inputValue;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDatePeriod");
      error.message += errorLocation;
      throw error;
    }
  }

  // selectDateFrom(event) { // Ngb Datepicker
  //   try {
  //     let dateEvent = event.month + '/' + event.day + '/' + event.year;
  //     dateEvent = moment(dateEvent).format("YYYY-MM-DD");
  //     this.dateFromChecker = dateEvent;
  //     this.dateFrom = "'" + dateEvent + "'";
  //     this.dateFromClicked = true;
  //     this.dateFromHolder = this.dateFromInput;
  //     this.dateFromPlaceholder = event.toString();
  //   } catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }

  selectDateFrom(value: Date) { // Angular Material Datepicker
    try {
      let dateEvent = moment(value).format("YYYY-MM-DD");
      this.dateFromChecker = dateEvent;
      this.dateFrom = "'" + dateEvent + "'";
      this.dateFromClicked = true;
      this.dateFromHolder = dateEvent;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
      error.message += errorLocation;
      throw error;
    }
  }

  // selectDateTo(event) { // Ngb Datepicker
  //   try {
  //     let dateEvent = event.month + '/' + event.day + '/' + event.year;
  //     dateEvent = moment(dateEvent).format("YYYY-MM-DD");
  //     this.dateToChecker = dateEvent;
  //     this.dateTo = "'" + dateEvent + "'";
  //     this.dateToClicked = true;
  //     this.dateToHolder = this.dateToInput;
  //     this.dateToPlaceholder = event.toString();
  //   } catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }

  selectDateTo(value: Date) { // Angular Material Datepicker
    try {
      let dateEvent = moment(value).format("YYYY-MM-DD");
      this.dateToChecker = dateEvent;
      this.dateTo = "'" + dateEvent + "'";
      this.dateToClicked = true;
      this.dateToHolder = dateEvent;
   } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
      error.message += errorLocation;
      throw error;
    }
  }

  viewRSUSalesHist() {
    if(!this.salesTableLoading){
    try {
      this.rsuSalesHistShares = 0;
      this.rsuSalesHistProceedsUSD = 0;
      this.rsuSalesHistPurchaseValueUSD = 0;
      this.dateErrorChecker = false;
      this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
      this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
  
      let tempDateTo = this.dateTo.replace(/'/g, '');
      let tempDateFrom = this.dateFrom.replace(/'/g, '');
  
      tempDateTo = moment(tempDateTo).format("YYYY-MM-DD");
      tempDateFrom = moment(tempDateFrom).format("YYYY-MM-DD");
  
      if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else if (tempDateTo < tempDateFrom && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else{
      this.salesTableLoading = true;
      this.rsuSalesHistShares = 0;
      this.rsuSalesHistPriceUSD = 0;
      this.rsuSalesHistProceedsUSD = 0;
      this.rsuSalesHistPurchaseValueUSD = 0;
      this.getRSUSalesHistory();
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewRSUSalesHist");
      error.message += errorLocation;
      throw error;
    }
    }
  }
  // Sales History Tab Method - END

  /**********************************************************************************
      Method Name: getRSUAgreementInfo
      Description: Get RSU grant agreement details

      #     Date            Modified by       Description
      1     02/14/19        Katrina Narag     Add logic for FRQ dividends
      2     04/22/19        KMN               Add filename in variable
      3     08/14/19        JPA               Removed this.loading = false and transferred it to different method
      4     08/21/19        KMN               Modified flagging (5 - Vest/ 6 - Release)
  **********************************************************************************/
  getRSUAgreementInfo() {
    try {
      let mailAddVis = null;
      this.displayAgreementImptNotes = false;
      this.holdingProgramDetailsRSUService.getRSUAgreementInfo(this.programDetails.GrantNum).pipe(map(res => res)).subscribe(data => {
        try {
          sessionStorage.setItem("getRSUAgreementInfo", JSON.stringify(data));
          this.agreementRelDataSource = [];
          this.agreementVestDataSource = [];
          this.agreementVestTable = [];
          this.agreementReleaseTable = [];
          let agreementContent = data[0][0];
          this.holdingProgramDetailsRSUService.agreementContent = agreementContent;
          let agreementTable = data[1];
          this.agreementImportantNotes = data[2];

          if(this.programDetails.GrantAgreementTabVisibleInd == 'Y') {
            this.displayAgreementTab = true;
          } else {
            this.displayAgreementTab = false;
          }
    
          if(this.agreementImportantNotes.length > 0) {
            this.displayAgreementImptNotes = true;
          }

          if(agreementContent != null || agreementContent != undefined){
            this.agreementExchangeRate = agreementContent.ExchangeRate;
            this.agreementGrantId = agreementContent.GrantId;
            this.agreementGrantPrice = agreementContent.RsuGrantPrice;
            this.agreementRsuGranted = agreementContent.RsuSharesGranted;
            this.agreementTotalVal = agreementContent.RsuTotalValue;
            this.agreementStepOne = agreementContent.Step1Text;
            this.agreementStepTwo = agreementContent.Step2Text;
            this.agreementStepThree = agreementContent.Step3Text;
            this.agreementStepThreeValStatus = GrantAgreementStatus[agreementContent.Step3ValStatus];
            this.agreementStepOneStatus = agreementContent.Step1Status;
            this.agreementStepTwoStatus = agreementContent.Step2Status;
            this.agreementStepThreeStatus = agreementContent.Step3Status;
            this.grantAgreementFile = agreementContent.PDFName;           //KMN, 2
      
            mailAddVis = agreementContent.MailAddVisible;

            if(GrantAgreementStatus[agreementContent.OverallStatus] == "NotYetAvailable" && agreementContent.FrenchQualifiedInd == 1) {
              this.displayAgreementContent = false;
            }

            if(this.awardName == "Div"){  // KMN, 1
              if(GrantAgreementStatus[agreementContent.OverallStatus] == "NotYetAvailable" && agreementContent.FrenchQualifiedInd == 1) {
                this.displayAgreementContent = false;
              }
            }
          }
    
          if(this.grantAgreementFile == "NO EGT PAGE"){
            this.disableDownloadBtn = true;
          }
    
          if(this.agreementStepOneStatus == "(Action Required)") {
            this.displayReturnSigned = true;
            this.stepOneStatus = true;
          } 
    
          if(this.agreementStepTwoStatus == "(Action Required)" && mailAddVis == 1) {
            this.displayReturnSigned = true;
            this.stepTwoStatus = true;
          } 
    
          if(this.agreementStepThreeValStatus == GrantAgreementStatus[1]) { 
            this.disableAcceptGrantBtn = false;
            this.disableAcceptGrantBtn = this.checkUser();            
          }
    
          if(this.agreementStepThreeStatus == "(Action Required)") {
            this.stepThreeStatus = true;
            this.showAcceptBtn = true;          
            this.showAcceptedBtn = false;

          }else{                 
            this.showAcceptBtn = false;          
            this.showAcceptedBtn = true;
            this.stepThreeStatus = false;
            this.disableAcceptGrantBtn =  true;   
          }
          
          if(agreementTable.length > 0) {
            for(let i = 0; i < agreementTable.length; i++) {
              if(agreementTable[i].OriginalShares != null) {
                if(agreementTable[i].VestReleaseFlag == 5) {
                  this.agreementVestTable.push(agreementTable[i]);
                } else {
                  this.agreementReleaseTable.push(agreementTable[i]);
                }
              } else {
                agreementTable[i].OriginalShares = 0;
                if(agreementTable[i].VestReleaseFlag == 5) {
                  this.agreementVestTable.push(agreementTable[i]);
                } else {
                  this.agreementReleaseTable.push(agreementTable[i]);
                }
              }
            }
            this.displayAgreementTable = true;
          } else {
            this.displayAgreementTable = false;
          }
    
          this.agreementVestDataSource = this.agreementVestTable;
          this.holdingProgramDetailsRSUService.agreementVestDataSource = this.agreementVestDataSource;
          this.agreementRelDataSource = this.agreementReleaseTable;
          this.holdingProgramDetailsRSUService.agreementRelDataSource = this.agreementRelDataSource;
          this.agreementMethodExecuted = true;
          
          //this.loading = false;
        } catch(error) {
          this.errorAgreementTab = true;		
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getRSUAgreementInfo");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorAgreementTab = true;		
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getRSUAgreementInfo");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorAgreementTab = true;		
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getRSUAgreementInfo");
      error.message += errorLocation;
      throw error;
    }
  }

  acceptGrant(){
  if(!this.disableAcceptGrantBtn){
    try {
      let userOptnum = this.user.OptNum;
      let adminOptnum;
      let grantNum = this.programDetails.GrantNum;
  
      if(this.admin != null || this.admin != undefined){
        adminOptnum = this.admin.OptNum;
      }else{
        adminOptnum = userOptnum;
      }
      
      this.holdingProgramDetailsRSUService.grantAccepted(userOptnum, adminOptnum, grantNum).subscribe(data => {
        try {
          alert("Thank you for accepting your grant!\n Please check the Alert box on the Holdings page to confirm if you have any additional grants to accept.");          
          this.programDetails.GrantAccepted = 'Accepted';
          sessionStorage.removeItem("RSU Data");
          sessionStorage.removeItem("RSU Details Data"); //remove session for rsu award details
          sessionStorage.removeItem("Header RSU Details"); //remove session for rsu award details
          sessionStorage.setItem(this.globals.programDetailsKey, JSON.stringify(this.programDetails));  
          this.awardDetailsSummary.getProgramDetails();
          this.getRSUAgreementInfo();
          this.getProgramDetails();
          } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
          error.message += errorLocation;
          throw error;
          
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
      error.message += errorLocation;
      throw error;
    }
  }
  }

  checkUser(): boolean {
    try {
      if(this.admin != null) {
        if(this.admin.OptNum == this.user.OptNum) {
          return false; // enables the button
        } else if((this.admin.OptNum != this.user.OptNum) && (AdminType[this.admin.IsAdminCanTransactInd] == AdminType[2])) {
          return false; // enables the button
        } else if((this.admin.IsAdmin == 'Y') && (AdminType[this.admin.IsAdminCanTransactInd] == AdminType[1])) {
          return true; // disables the button
        } else if(this.admin.OptNum == this.user.OptNum) {
          return true; // disables the button
        }
      } else {
        return false;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkUser");
      error.message += errorLocation;
      throw error;
    }
  }
  // Agreement Tab Method - END

  transform(num: any, checker){ 
    try {
      if (num != 0 && num != undefined) {
        if (num.indexOf('-') > -1 ){
        if (checker == 1){
        let number = num.split('-'); 
        return '(' + this.currencyCdSymbol + number[1] + ')'; 
        } 
        else {
        let number = num.split('-');
        return '(' + number[1] + ')'; 
        }
        }
        else 
        { 
        return num;
        } 
        } 
        else {
        return '0';
        }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "transform");
      error.message += errorLocation;
      throw error;
    }
    } 

  // COMMAFY
  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value >= 0) {
            let number = (num*1).toLocaleString('en', {
              //maximumFractionDigits: value
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  setPeriodAllDates(tab) {
    try {
      setTimeout(()=>{ this.inContentIcon() }, 500);
      if(tab.nextId == "sales-history-tab") {
        this.disableDatePicker = true;
        this.dateFrom = "' '";
        this.dateTo = "' '";
        this.periodSelected = this.allDates;
        this.datePeriod = 4;
        this.dateFromClicked = false;
        this.dateToClicked = false;
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        // this.dateFromPlaceholder = " "; // Ngb Datepicker
        // this.dateToPlaceholder = " "; // Ngb Datepicker
        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        this.dateErrorChecker = false;
        this.salesTableLoading = true;
        this.rsuSalesHistShares = 0;
        this.rsuSalesHistPriceUSD = 0;
        this.rsuSalesHistProceedsUSD = 0;
        this.rsuSalesHistPurchaseValueUSD = 0;
        this.getRSUSalesHistory()
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "setPeriodAllDates");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: executeMethod                                                                 
   * Description: Ensures that the method with loading set to false execute last                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 02/08/19             Dalemark P. Suquib          Initial draft       
   *********************************************************************************/
  executeMethod() {
    try {
      if(this.agreementMethodExecuted){ 
        if(this.methodCounter >= 3) {
          this.getRSUSalesHistory();
        } else {
          this.methodCounter += 1;
        }
      } else { 
        if(this.methodCounter >= 3) {
          if(this.programDetails.GrantAgreementTabVisibleInd == 'Y') { 
            this.displayAgreementTab = true;
            this.getRSUAgreementInfo();
          } else {
            this.displayAgreementTab = false;
            this.getRSUSalesHistory();
          }
        } else {
          this.methodCounter += 1;
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "executeMethod");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  getCodeDetails() { 
    try {
      let keyExectData = JSON.parse(sessionStorage.getItem("Key Exec Toggle Data"));
      let code = keyExectData[0];
      
      this.yearCode.push(code[0]);
      this.yearCode.push(code[1]);
      this.yearCode.push(code[2]);
      this.yearCode.push(code[3]);   
      
      this.planNum.push(code[4]);
      this.planNum.push(code[5]);
      this.planNum.push(code[6]);

    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getCodeDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: downloadGrantAgreement
      Description: Download step 1 grant agreement file

      Date            Modified by       Description
      04/22/19        Katrina Narag     Initial draft
  **********************************************************************************/
  downloadGrantAgreement(){
    try {
      if(this.grantAgreementFile != "NO EGT PAGE"){
        this.getFile(this.grantAgreementFile, 'dynamic');
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "downloadGrantAgreement");
      error.message += errorLocation;
      throw error;
    }
  }

  /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     04/22/19        Katrina Narag         Initial Draft
  2     04/26/19        KMN                   Add condition for no file exists
  **********************************************************************************************/
getFile(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
  
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
  
      fileName = file;
    }
  
    this.staticContentService.getFile(fileName, "GA").subscribe(data => { // MD for contacts page
      try {
        let content = data;
        if(content == null || content == ""){
          this.staticContentService.noFileExists(fileName);
        }else{
          if(content.body != null || content.body != undefined) {
            let file = JSON.parse(data.body);
            this.downloadFile(fileName, fileType, this.base64ToArrayBuffer(file.file));
            this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
            });
          } else {
            this.staticContentService.noFileExists(fileName);
          }
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    }); 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
  Method Name: base64ToArrayBuffer
  Description: Converts base 64 to array buffer.

  #     Date            Modified by             Description
  1     04/22/19        Katrina Narag      Initial Draft
  **********************************************************************************************/
base64ToArrayBuffer(base64): Uint8Array {
  try {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);
    
    for(let i = 0; i < binaryLength; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "base64ToArrayBuffer");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
  Method Name: downloadFile
  Description: Downloads the file.

  #     Date            Modified by             Description
  1     04/22/19        Katrina Narag      Initial Draft
  **********************************************************************************************/
downloadFile(reportName, fileType, byte) {
  try {
    // if(navigator.appVersion.toString().indexOf('.NET') > 0) { // browser is IE
    //   let blob = new Blob([byte], {type: fileType});
    //   window.navigator.msSaveBlob(blob, reportName);
    if(navigator.userAgent.indexOf("Firefox") > 0) { // browser is FireFox
      let a = document.createElement('a');
      a.style.display = "none";
      a.style.zIndex = "1002";
      a.style.position = "absolute";
      let blob = new Blob([byte], {type: fileType});
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = reportName;
      document.body.appendChild(a);
      a.click();
  
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
    } else { // browser is Chrome
      let blob = new Blob([byte], {type: fileType});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let fileName = reportName;
      link.download = fileName;
      link.click();
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "downloadFile");
    error.message += errorLocation;
    throw error;
  }
}

    /*****************************************************************************************
   * Method Name: getFromCodeDetail                                                                 
   * Description: Get GroupingId from CodeDetails API to be used in Common Question conditions  
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/25/2019           Iris Jane R. Manloloyo (IJM)         Initial Draft
   *****************************************************************************************/

  getFromCodeDetail(){
    
    try {
      let checker = 0;
      this.holdingProgramDetailsRSUService.getDataFromCodeDetail(7).pipe(map(res => res)).subscribe(data => {
        
          let body = data;          

          data != undefined ? this.LocalGroupDetailId7 = body[0] : "";
          checker++;
          if(checker == 3){
            this.getrsuCommonQuestions();
          }
      });

      this.holdingProgramDetailsRSUService.getDataFromCodeDetail(5).pipe(map(res => res)).subscribe(data => { 
        let body = data;

        data != undefined ? this.LocalGroupDetailId5 = body[0] : "";
        checker++;
          if(checker == 3){
            this.getrsuCommonQuestions();
          }
      });

      this.holdingProgramDetailsRSUService.getDataFromCodeDetail(8).pipe(map(res => res)).subscribe(data => { 
        let body = data;

        data != undefined ? this.LocalGroupDetailId8 = body[0] : "";
        checker++;
        if(checker == 3){
          this.getrsuCommonQuestions();
        }
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFromCodeDetail");
      error.message += errorLocation;
      throw error;
    }
  }
  

  /*****************************************************************************************
   * Method Name:   getrsuCommonQuestions() {
                                                                 
   * Description: Gets the content for common questions from my resource api if the user hasOptionsAward
   *              and GrantCountryCd= CAN   
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/24/2019           Iris Jane R. Manloloyo (IJM)          Initial Draft
   * 2    08/14/19             JPA                                   Removed this.loading and transferred it to different method
   *****************************************************************************************/
  getrsuCommonQuestions() {
    try {
      if (this.programDetails.GrantNum == null){
        
        let temp = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
        this.programDetails = temp[0];
      }   
      
      this.grantCountryCode = this.programDetails.GrantCountryCd; 
      this.grantGroupIdProgramDetails = this.programDetails.GrantGroupId;   
      let awardName = this.programDetails.AwardName;
      this.bodyData[6] = 1;
      
      if (this.programDetails.GrantNum == 0){
        this.FrenchQualified = 0;
        this.loading = false;
      } else {
        if(sessionStorage.getItem("getRSUAgreementInfo") != null){
          try {   
            let data = JSON.parse(sessionStorage.getItem("getRSUAgreementInfo"));                   
            let cqBodyDate: any = [];           
            let agreementContent = data[0][0]; 
            this.FrenchQualified = agreementContent.FrenchQualifiedInd;
            
            let locExchageRate = this.LocalGroupDetailId7.find(i => i.GroupingValue == this.programDetails.GrantGroupId);      
            locExchageRate == undefined ? locExchageRate = [] : "";      
            let locExchageRate8 = this.LocalGroupDetailId8.find(i => i.GroupingValue == this.programDetails.GrantGroupId);      
            locExchageRate8 == undefined ? locExchageRate8 = [] : "";              
            let locExchageRate5 = this.LocalGroupDetailId5.find(i => i.GroupingValue == this.programDetails.GrantGroupId);            
               
            this.bodyData[5] = "";
                   
      if(this.programDetails.GrantCountryCd == "DNK" && awardName.indexOf("VEIP") < 0){      
          if((locExchageRate.GroupingValue == this.programDetails.GrantGroupId) && (awardName.indexOf("RSU") != -1)){          
            this.bodyData[5] = PageSection.DenmarkBonusRSU; 
            cqBodyDate.push(this.bodyData[5]);                        
          }else if(awardName.indexOf("RSU IPO") != -1 || awardName.indexOf("IPO RSU") != -1){          
            this.bodyData[5] = PageSection.IPORSUDenmark;
            cqBodyDate.push(this.bodyData[5]);                           
          }else if(awardName.indexOf("RSU") != -1){
            this.bodyData[5] = PageSection.DenmarkRSU;   
            cqBodyDate.push(this.bodyData[5]);                        
          }        
             
      }else if((locExchageRate.GroupingValue == this.programDetails.GrantGroupId)){
        this.bodyData[5] = PageSection.BonusShareRSU;
        cqBodyDate.push(this.bodyData[5]);                 
      }
      
      if(this.FrenchQualified == 1){
        this.user.JobCd == 10000025 ? this.bodyData[5] = PageSection.GrantFRQSnERSU : this.bodyData[5] = PageSection.GrantFRQSMRRSU; 
        cqBodyDate.push(this.bodyData[5]);            
      }  
  
      let pageSections: any = [{PageSection: PageSection.DenmarkBonusRSU}, 
                                {PageSection: PageSection.IPORSUDenmark},
                                {PageSection: PageSection.DenmarkRSU},
                                {PageSection: PageSection.BonusShareRSU},
                                {PageSection: PageSection.GrantFRQSnERSU},
                                {PageSection: PageSection.GrantFRQSMRRSU}]; 
      
      let pageSectionsValue = cqBodyDate.find(i => i.PageSection == i.cqBodyDate);   

    if(pageSectionsValue == undefined){
      if(this.LocalGroupDetailId8.length > 0){         
        if(awardName.indexOf("RSU") != -1 && this.programDetails.GrantCountryCd == "DNK"){   //hereeee
          this.bodyData[5] = PageSection.MatchDenmarkRSUVeip;               
        }else if(this.programDetails.GrantCountryCd == "DNK"){
          this.bodyData[5] = PageSection.MatchDenmarkVeip;            
        }else if(this.LocalGroupDetailId5.length > 0 && locExchageRate5 != undefined){        
          this.bodyData[5] = PageSection.MatchFRQVeip;   
        }else if (awardName.indexOf("VEIP") != -1){
          this.bodyData[5] = PageSection.MatchRSUVeip;              
        }
      }
    }    
      this.bodyData[5] == "" ? this.bodyData[5] = PageSection.RSUNullContent : "";
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {     
          let rsuCommonQuestionsContents = JSON.parse(data.body);
  
          if(rsuCommonQuestionsContents.data) { // length > {}
          let rsuCommonBody = rsuCommonQuestionsContents.data;
          
          if(rsuCommonBody.length > 0) {
            this.rsuCommonQuestionsContent = rsuCommonBody;
  
            for(let i=0; i<this.rsuCommonQuestionsContent.length; i++){
              if(this.rsuCommonQuestionsContent[i]["Body_Desc"].indexOf("<u>") != -1){
                this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].split("<u>").join("<span style='text-decoration: underline;'>");
                this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].split("</u>").join("</span>");
              }
              if(this.rsuCommonQuestionsContent[i]["Body_Desc"].indexOf("<b>") != -1){
                this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].split("<b>").join("<span style='font-weight: bold;'>");
                this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].split("</b>").join("</span>");
              }
              if(this.rsuCommonQuestionsContent[i]["Body_Desc"].indexOf('<font color="#000000">') != -1){
                this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].replace('<font color="#000000">','');
                this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].replace('</font>','');
              }
  
            } 
          } else {
            this.rsuCommonQuestionsContent = null;
          }
        }
          
        } catch(error) {
          this.errorCommonQuestions = true;		
          this.getRSUGuideBooks();
          let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCommonQuestions = true;		
        this.getRSUGuideBooks();
        let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
  
          } catch(error) {
            this.errorCommonQuestions = true;		
            this.getRSUGuideBooks();
            let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
            error.message += errorLocation;
            throw error;
          }
        }
        else{
          this.multiplegrantsService.getRSUAgreementInfo(this.programDetails.GrantNum).pipe(map(res => res)).subscribe(data => { 
            try {                      
              let cqBodyDate: any = [];           
              let agreementContent = data[0][0]; 
              this.FrenchQualified = agreementContent.FrenchQualifiedInd;
              
              let locExchageRate = this.LocalGroupDetailId7.find(i => i.GroupingValue == this.programDetails.GrantGroupId);      
              locExchageRate == undefined ? locExchageRate = [] : "";      
              let locExchageRate8 = this.LocalGroupDetailId8.find(i => i.GroupingValue == this.programDetails.GrantGroupId);      
              locExchageRate8 == undefined ? locExchageRate8 = [] : "";              
              let locExchageRate5 = this.LocalGroupDetailId5.find(i => i.GroupingValue == this.programDetails.GrantGroupId);            
                 
              this.bodyData[5] = "";
                     
        if(this.programDetails.GrantCountryCd == "DNK" && awardName.indexOf("VEIP") < 0){      
            if((locExchageRate.GroupingValue == this.programDetails.GrantGroupId) && (awardName.indexOf("RSU") != -1)){          
              this.bodyData[5] = PageSection.DenmarkBonusRSU; 
              cqBodyDate.push(this.bodyData[5]);                        
            }else if(awardName.indexOf("RSU IPO") != -1 || awardName.indexOf("IPO RSU") != -1){          
              this.bodyData[5] = PageSection.IPORSUDenmark;
              cqBodyDate.push(this.bodyData[5]);                           
            }else if(awardName.indexOf("RSU") != -1){
              this.bodyData[5] = PageSection.DenmarkRSU;   
              cqBodyDate.push(this.bodyData[5]);                        
            }        
               
        }else if((locExchageRate.GroupingValue == this.programDetails.GrantGroupId)){
          this.bodyData[5] = PageSection.BonusShareRSU;
          cqBodyDate.push(this.bodyData[5]);                 
        }
        
        if(this.FrenchQualified == 1){
          this.user.JobCd == 10000025 ? this.bodyData[5] = PageSection.GrantFRQSnERSU : this.bodyData[5] = PageSection.GrantFRQSMRRSU; 
          cqBodyDate.push(this.bodyData[5]);            
        }  
    
        let pageSections: any = [{PageSection: PageSection.DenmarkBonusRSU}, 
                                  {PageSection: PageSection.IPORSUDenmark},
                                  {PageSection: PageSection.DenmarkRSU},
                                  {PageSection: PageSection.BonusShareRSU},
                                  {PageSection: PageSection.GrantFRQSnERSU},
                                  {PageSection: PageSection.GrantFRQSMRRSU}]; 
        
        let pageSectionsValue = cqBodyDate.find(i => i.PageSection == i.cqBodyDate);     
    
      if(pageSectionsValue == undefined){
        if(this.LocalGroupDetailId8.length > 0){         
          if((awardName.indexOf("RSU") != -1) && this.programDetails.GrantCountryCd == "DNK"){   //hereeee
            this.bodyData[5] = PageSection.MatchDenmarkRSUVeip;        
          }else if(this.programDetails.GrantCountryCd == "DNK"){
            this.bodyData[5] = PageSection.MatchDenmarkVeip;            
          }else if(this.LocalGroupDetailId5.length > 0 && locExchageRate5 != undefined){        
            this.bodyData[5] = PageSection.MatchFRQVeip;   
          }else if (awardName.indexOf("VEIP") != -1){
            this.bodyData[5] = PageSection.MatchRSUVeip;        
          }
        }
      }    
        this.bodyData[5] == "" ? this.bodyData[5] = PageSection.RSUNullContent : "";
    
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          try {     
            let rsuCommonQuestionsContents = JSON.parse(data.body);
    
            if(rsuCommonQuestionsContents.data) { // length > {}
            let rsuCommonBody = rsuCommonQuestionsContents.data;
            
            if(rsuCommonBody.length > 0) {
              this.rsuCommonQuestionsContent = rsuCommonBody;
    
              for(let i=0; i<this.rsuCommonQuestionsContent.length; i++){
                if(this.rsuCommonQuestionsContent[i]["Body_Desc"].indexOf("<u>") != -1){
                  this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].split("<u>").join("<span style='text-decoration: underline;'>");
                  this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].split("</u>").join("</span>");
                }
                if(this.rsuCommonQuestionsContent[i]["Body_Desc"].indexOf("<b>") != -1){
                  this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].split("<b>").join("<span style='font-weight: bold;'>");
                  this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].split("</b>").join("</span>");
                }
                if(this.rsuCommonQuestionsContent[i]["Body_Desc"].indexOf('<font color="#000000">') != -1){
                  this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].replace('<font color="#000000">','');
                  this.rsuCommonQuestionsContent[i]["Body_Desc"] = this.rsuCommonQuestionsContent[i]["Body_Desc"].replace('</font>','');
                }
    
              } 
            } else {
              this.rsuCommonQuestionsContent = null;
            }
          }
            
          } catch(error) {
            this.errorCommonQuestions = true;		
            this.getRSUGuideBooks();
            let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          this.errorCommonQuestions = true;		
          this.getRSUGuideBooks();
          let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
          error.error.message += errorLocation;
          throw error.error;
        });
    
            } catch(error) {
              this.errorCommonQuestions = true;		
              this.getRSUGuideBooks();
              let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            this.errorCommonQuestions = true;		
            this.getRSUGuideBooks();
            let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
            error.error.message += errorLocation;
            throw error.error;
          });
        }
        this.loading = false;
    }                       
    } catch(error) {
      this.errorCommonQuestions = true;		
      this.getRSUGuideBooks();
      let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
      error.message += errorLocation;
      throw error;
    }
  }


  /*****************************************************************************************
   * Method Name: getRSUGuideBooks                                                                 
   * Description: Gets the content for guide books from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getRSUGuideBooks(){
    try {
      this.bodyData[5] = PageSection.rsuGuidebooks;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let guideBooksData = JSON.parse(data.body);
  
          if(guideBooksData.data) { // length > {}
            let guideBooksBody = guideBooksData.data;
            
            if(guideBooksBody.length > 0) {
              this.rsuGuideBooksContent = guideBooksBody[0].Body_Desc;

              

              if(this.rsuGuideBooksContent.indexOf('<a href="rsu.pdf" target="_blank" style="padding-left: 23px;"></a>') != -1){
                this.rsuGuideBooksContent = this.rsuGuideBooksContent.split('<a href="rsu.pdf" target="_blank" style="padding-left: 23px;"></a>').join('');
              }

            } else {
              this.rsuGuideBooksContent = null;
            }
          }
        } catch(error) {
          this.errorGuideBooks = true;		
          this.getRSUShareIncentive();
          let errorLocation = this.errorLocation.replace("methodName", "getRSUGuideBooks");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorGuideBooks = true;		
        this.getRSUShareIncentive();
        let errorLocation = this.errorLocation.replace("methodName", "getRSUGuideBooks");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorGuideBooks = true;		
      this.getRSUShareIncentive();
      let errorLocation = this.errorLocation.replace("methodName", "getRSUGuideBooks");
      error.message += errorLocation;
      throw error;
    }
  }
  
  /*****************************************************************************************
   * Method Name: getRSUShareIncentive                                                                 
   * Description: Gets the content for plan document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getRSUShareIncentive() {
    try {
      this.bodyData[5] = PageSection.rsuShareIncentive;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let shareIncenDocumentData = JSON.parse(data.body);
 
          if(shareIncenDocumentData.data) { // length > {}
            let optDocumentBody = shareIncenDocumentData.data;
    
            if(optDocumentBody.length > 0) {
              this.rsuShareIncentiveContent = optDocumentBody[0].Body_Desc;

            } else {
              this.rsuShareIncentiveContent = null;
            }
          }
        } catch(error) {
          this.errorShareIncentive = true;		
          this.getRSUCountryAward();
          let errorLocation = this.errorLocation.replace("methodName", "getRSUShareIncentive");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorShareIncentive = true;		
        this.getRSUCountryAward();
        let errorLocation = this.errorLocation.replace("methodName", "getRSUShareIncentive");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorShareIncentive = true;		
      this.getRSUCountryAward();
      let errorLocation = this.errorLocation.replace("methodName", "getRSUShareIncentive");
      error.message += errorLocation;
      throw error;
    }
  }


  /*****************************************************************************************
   * Method Name: getRSUCountryAward                                                                 
   * Description: Gets the content for prospectus document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getRSUCountryAward() {
    try {
      this.bodyData[5] = PageSection.rsuCountryAward;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let CountryAwardDocData = JSON.parse(data.body);
  
          if(CountryAwardDocData.data) { // length > {}
            let countryAwardDocBody = CountryAwardDocData.data;
    
            if(countryAwardDocBody.length > 0) {
              this.rsuCountAwardCOntent = countryAwardDocBody[0].Body_Desc;

            } else {
              this.rsuCountAwardCOntent = null;
            }
          }
        } catch(error) {
          this.errorCountryAward = true;		
          this.getRSUToolsAndReports();
          let errorLocation = this.errorLocation.replace("methodName", "getRSUCountryAward");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCountryAward = true;		
        this.getRSUToolsAndReports();
        let errorLocation = this.errorLocation.replace("methodName", "getRSUCountryAward");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCountryAward = true;		
      this.getRSUToolsAndReports();
      let errorLocation = this.errorLocation.replace("methodName", "getRSUCountryAward");
      error.message += errorLocation;
      throw error;
    }
  } 

  
  /*****************************************************************************************
   * Method Name: getRSUToolsAndReports                                                                 
   * Description: Gets the content for tools and reports from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getRSUToolsAndReports() {
    try {
      this.bodyData[5] = PageSection.rsuToolsAndReports;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let toolsAndReportsData = JSON.parse(data.body);
  
          if(toolsAndReportsData.data) { // length > {}
            let toolsAndReportsBody = toolsAndReportsData.data;
           
            if(toolsAndReportsBody.length > 0) {
              this.rsuToolsandRepContent = toolsAndReportsBody;

              for(let i=0; i<this.rsuToolsandRepContent.length; i++){
                if(this.rsuToolsandRepContent[i]["Body_Desc"].indexOf("<u>") != -1){
                  this.rsuToolsandRepContent[i]["Body_Desc"] = this.rsuToolsandRepContent[i]["Body_Desc"].split("<u>").join("<span style='text-decoration: underline;'>");
                  this.rsuToolsandRepContent[i]["Body_Desc"] = this.rsuToolsandRepContent[i]["Body_Desc"].split("</u>").join("</span>");
                }
                if(this.rsuToolsandRepContent[i]["Body_Desc"].indexOf("<b>") != -1){
                  this.rsuToolsandRepContent[i]["Body_Desc"] = this.rsuToolsandRepContent[i]["Body_Desc"].split("<b>").join("<span style='font-weight: bold;'>");
                  this.rsuToolsandRepContent[i]["Body_Desc"] = this.rsuToolsandRepContent[i]["Body_Desc"].split("</b>").join("</span>");
                }
              } 
            } else {
              this.rsuToolsandRepContent = null;
            }
            this.LearnMoreLoading=false;
            setTimeout(()=>{ this.inContentIcon() }, 500);
          }
        } catch(error) {
          this.errorToolsAndReports = true;
          this.loading = false;
          setTimeout(()=>{ this.inContentIcon() }, 500);
          let errorLocation = this.errorLocation.replace("methodName", "getRSUToolsAndReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorToolsAndReports = true;
        this.loading = false;
        setTimeout(()=>{ this.inContentIcon() }, 500);
        let errorLocation = this.errorLocation.replace("methodName", "getRSUToolsAndReports");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorToolsAndReports = true;
      this.loading = false;
      setTimeout(()=>{ this.inContentIcon() }, 500);
      let errorLocation = this.errorLocation.replace("methodName", "getRSUToolsAndReports");
      error.message += errorLocation;
      throw error;
    }
  }


  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let srcElement: HTMLImageElement = event.srcElement;
      let target: string = event.target.target;
      if (target == ""){
        target = "_self";
      }
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != ""){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          // href = href.split("/Default.aspx")[0]; 
          href = event.target.innerText;
          event.preventDefault();

          href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
        } else {
          href = href.toLowerCase();
          if(href.indexOf("resourcespage") != -1) {
            window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
            return false; // prevent execution of pop up blocker and href of element
          }
        }
      }else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
        event.preventDefault();

        if(href.indexOf("resourcespage") != -1) {
          href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
        } else {
          window.open(href, target);
        }
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page = page.toLowerCase();
    page.indexOf("resources") != -1 ? page = "resourcespage" : ""

    window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
  }

  /*********************************************************************************************
 Method Name: getFileData
  Description: Get file data

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getFileData(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
  
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
  
      fileName = file;
    }
  
    this.staticContentService.getFile(fileName, "MD").subscribe(data => {
      try {
        let file = JSON.parse(data.body);
        this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFileData");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFileData");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFileData");
    error.message += errorLocation;
    throw error;
  }
}

getStepTwoContent() {
  try {
    this.bodyData[5] = PageSection.esppTransacStepTwo + this.bodyData[0];
    this.bodyData[6] = 0;
  
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let stepTwoData = JSON.parse(data.body);

        if(stepTwoData.data) { // length > {}
          let stepTwoBody = stepTwoData.data;
     
          if(stepTwoBody.length > 0) {
            this.stepTwoContent = stepTwoBody;
     
            for(let i = 0; i < stepTwoBody.data.length; i++) {
              if(this.stepTwoContent[i].File_Type == "pdf") {
               this.stepTwoContent[i].fileIcon = FileType.pdf;
              } else if(this.stepTwoContent[i].File_Type == "docx") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "doc") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "xls") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "xlsx") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "pptx") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "ppt") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "zip") {
               this.stepTwoContent[i].fileIcon = FileType.zip;
              } 
            }
          } else {
            this.stepTwoContent = null;
          }
        }
     
        this.getAllCountryForms();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
  Method Name: getAllCountryForms
  Description: Gets all the available country forms.

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getAllCountryForms() {
  try {
    this.bodyData[5] = PageSection.esppTransacAllCountryForms;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
     try {
      let allCountryFormsData = JSON.parse(data.body);
     
      if(allCountryFormsData.data) { // length > {}
        let allCountryFormsBody = allCountryFormsData.data;
  
        if(allCountryFormsBody.length > 0) {
          this.allCountryFormsContent = allCountryFormsBody[0].Body_Desc;
          setTimeout(() => { this.inContentIcon() }, 500);
        } else {
          this.allCountryFormsContent = null;
        }
      }
     } catch(error) {
       let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
       error.message += errorLocation;
       throw error;
     }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
    error.message += errorLocation;
    throw error;
  }
}
/*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths

*****************************************************************************************/
async inContentIcon(){
  $("[href='#collapseCQIndexi0']").attr("aria-expanded","true");
  $("[id='collapseCQIndexi0']").attr("class","collapse show");
  $("[href='https://myholdings.accenture.com/Resources/Default.aspx']").attr("aria-label","Resources Link - Common Question (RSU)" );
  $("[id='sales-history-tab']").attr("aria-label","Sales History - Restricted Share Unit (RSU)" );
  $("[id='learn-more-tab']").attr("aria-label","Learn More - Restricted Share Unit (RSU)");

      //matDatepicker mod
    $(".mat-input-infix span").remove();
    $(".mat-input-underline span").remove();
    $(".mat-input-subscript-wrapper").remove();
    $(".mat-input-underline").css("height","0");
    $(".mat-input-element").addClass("datepicker-input");
    $(".mat-input-element").css("height","30px");
    $(".mat-input-element").css("width","173px");
    $(".mat-input-element").css("margin-bottom","4px");
    $(".mat-input-element").css("margin-left","5px");
    $(".mat-input-element").css("text-indent","10px");
    $(".mat-input-element").css("background-color","#d3d0d0");
    $(".mat-input-infix input").css("height","30px");
    $(".mat-input-infix input").css("width","173px");
    $(".mat-input-infix input").css("margin-bottom","2.8px");
    $(".mat-input-infix input").css("background-color","#d3d0d0");
    $(".mat-form-field-infix").css("border-top","0");
    $(".mat-datepicker-toggle button").removeClass("mat-icon-button").addClass("btn btn-outline-secondary calendar");
    $(".mat-datepicker-toggle button span").remove();
    $(".mat-datepicker-toggle button div").remove();
    $(".mat-datepicker-toggle button").removeAttr("mat-icon-button");
    $(".mat-form-field-infix").css("width","0px");
    $(".mat-datepicker-toggle button").css("width","50px");
    $(".mat-datepicker-toggle button").css("height","32px");
    $(".mat-datepicker-toggle button").css("margin-left","0px");
    $(".mat-datepicker-toggle button").css("margin-bottom","-4px");
    $(".mat-datepicker-toggle button").css("background-image","url(/assets/calendar.png)");
    $(".mat-datepicker-toggle button").css("background-repeat","no-repeat");
    $(".mat-datepicker-toggle button").css("background-size","23px");
    $(".mat-datepicker-toggle button").css("background-position","center");
    $(".mat-input-infix input").css("padding-left","16px");
  //end of matDatepicker Mod
  
  this.staticContentService.inContentIconAppend();
}

  /*****************************************************************************************
    * Method Name: onTabChange                                                                 
    * Description: Sets timeout for incontenticon method when a tab is selected.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/26/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
onTabChange() {
  try {
    setTimeout(()=>{ this.inContentIcon() }, 500);
    this.selectDatePeriod(4);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "onTabChange");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
navToReportsPage(event) {
  try {
    let href = event.target.href;
    let id: number;
    let reportId: object;
    let srcElement: HTMLImageElement = event.srcElement;

    if(srcElement.src != undefined){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
    }else if(href != undefined) {
      if(href.indexOf("reportid") != -1) {
        id = href.substring(href.lastIndexOf("=") + 1);
        reportId = {'ReportTypeId': id};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
        window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
        return false;
      }else if(href.indexOf("fileName=") != -1){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    }

  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToReportsPage");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navtoSpecificReportsPage                                                                 
  * Description: Navigate to specific report
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/05/19             KMN                                   Bug 332329 fix 
  *****************************************************************************************/
 navtoSpecificReportsPage(id){
  let reportId: object;

  reportId = {'ReportTypeId': id};
  sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
  window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
}

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Rsudetails") != -1 && this.user != null) {
                webPage = "Rsudetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
  getGlobalID() {
    try {
      this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
        try {
          this.globalIDs = data[0];

          if (this.globalIDs.length == 0) {
            this.phSBGlobalID = true;
            this.globalIDNum1 = ""         
          } else if(this.globalIDs.length == 1){
            this.phSBGlobalID = true;
            if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9) { 
                  this.globalIDNum1 = "Your SSN";
                } else {
                  this.globalIDNum1 = this.globalIDs[0].ParticipantNbr; 
                }
          }
          else{
            if (this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9) {
              this.phSBGlobalID = true;
              this.globalIDNum1 = "Your SSN"
            } else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) == 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) == 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else{
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) " + this.globalIDs[0].ParticipantNbr;
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
  
          }
  
          // if(this.globalIDs.length == 0) {
          //   this.phSBGlobalID = true;
          //   this.globalIDNum1 = "";
          // } else if(this.globalIDs.length == 1) {
          //   this.phSBGlobalID = true;
    
          //   if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
          //     this.globalIDNum1 = "Your SSN";
          //   } else {
          //     this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
          //   }
          // } 

        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
      error.message += errorLocation;
      throw error;
    }
   }
  
   checkBrokersInfo(broker){
    event.stopPropagation();
   

    if(this.ubsLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    
    if(this.sbLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    
    if(this.bigLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    
    if(this.ncbLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    

    
    this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      let brokerAccounts = data[0];
      if(brokerAccounts.length > 0) {

        for(let i = 0; i < brokerAccounts.length; i++) {
  if(broker == "MS"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });

    this.brokerHeader = "Morgan Stanley";
    
    this.getGlobalID();

    this.displayPersonalUbs = false;
    this.displayPersonalBig = false;
    this.displayPersonalNcb = false;

    this.bodyData[5] = PageSection.sb;
    this.bodyData[6] = 1;
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
      
        let msData = JSON.parse(data.body);

        let msBody;

       if(msData.data) { // length > {}
        msBody = msData.data;

          if (msBody.length > 0){
            this.sbDesc = msBody[0].Body_Desc;
            this.displayPersonalSb = true;
            }
          else {
            this.displayPersonalSb = false;
            this.sbDesc = "";
          } 
       }


      this.bodyData[5] = PageSection.sbDoc;
      this.bodyData[6] = 0;

      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      let msDocData = JSON.parse(data.body);
      let msDocBody;
      
     if(msDocData.data) { // length > {}
                      msDocBody = msDocData.data;
                      if(msDocBody.length > 0) {
                        this.sbDoc = msDocBody;
      
                        for(let i=0; i<this.sbDoc.length; i++){
                          this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                        }
                      }
                      else{
                        this.sbDoc = null;
                      }
                   }
                
      });
      this.sbLoaded = true;
  this.resourceLoading = false;
    });

  }else if(broker == "UBS"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    

    this.brokerHeader = "UBS Financial Services Inc";
    this.displayPersonalSb = false;
    this.displayPersonalBig = false;
    this.displayPersonalNcb = false;

  this.ubsAccount = brokerAccounts[i].Account;
  this.bodyData[5] = PageSection.ubs;
  this.bodyData[6] = 1;
  

  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    let ubsData = JSON.parse(data.body);

    let ubsBody;

   if(ubsData.data) { // length > {}
    ubsBody = ubsData.data;
    if(ubsBody.length > 0) {
      this.ubsDesc = ubsBody[0].Body_Desc;
      this.displayPersonalUbs = true;
    }
    else {
    this.displayPersonalUbs = false;
      this.ubsDesc = "";
    }
 }

  this.bodyData[5] = PageSection.ubsDoc;
  this.bodyData[6] = 0;
  
  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

    let ubsDocData = JSON.parse(data.body);

    let ubsDocBody;
    
   if(ubsDocData.data) { // length > {}
        ubsDocBody = ubsDocData.data;
        if(ubsDocBody.length > 0){
          this.ubsDoc = ubsDocBody;
  
          for(let i=0; i<this.ubsDoc.length; i++){
            this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
          }
        }
        else{
          this.ubsDoc = null;
        }
      }
  });
  this.ubsLoaded = true;
  this.resourceLoading = false;
  });
  }else if(broker == "BIG"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    

    this.brokerHeader = "Banco de Investimento Global";

    this.displayPersonalSb = false;
    this.displayPersonalUbs = false;
    
    this.displayPersonalNcb = false;

    this.bigAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.big;
    this.bodyData[6] = 1;
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    
      let bigData = JSON.parse(data.body);

      let bigBody;

     if(bigData.data) { // length > {}
      bigBody = bigData.data;
      if(bigBody.length > 0){
        this.bigDesc = bigBody[0].Body_Desc;
        this.displayPersonalBig = false;
      }else {
        this.displayPersonalBig = false;
        this.bigDesc = "";
      }
   }
    this.bigLoaded = true;
  this.resourceLoading = false;
    });

  }else if(broker == "TA"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    

    this.brokerHeader = "Computershare (formerly National City Bank)";
    this.displayPersonalSb = false;
    this.displayPersonalUbs = false;
    this.displayPersonalBig = false;
    

    this.ncbAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.ncb;
    this.bodyData[6] = 1;
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

      let taData = JSON.parse(data.body);

      let taBody;

    if(taData.data) { // length > {}
      taBody = taData.data;
      if(taBody.length > 0){
      this.ncbDesc = taBody[0].Body_Desc;
      this.displayPersonalNcb = true;
      }else {
        this.displayPersonalNcb = false;
        this.ncbDesc = "";
      }
    } 
    
    this.bodyData[5] = PageSection.ncbDoc;
    this.bodyData[6] = 0;         

       this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

        let taDocData = JSON.parse(data.body);

        let taDocBody;

       if(taDocData.data) { // length > {}
        taDocBody = taDocData.data;
        if(taDocBody.length > 0){
          this.ncbDoc = taDocBody.data;

          for(let i=0; i<this.ncbDoc.length; i++){
            this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
          }
        }else {
          this.displayPersonalNcb = false;
          this.ncbDoc = null;
        }
     }
       });
       this.ncbLoaded = true;
       this.resourceLoading = false;
    });

  }else if(broker.indexOf('/')){
    this.brokerHeader = broker;
    let splittedBroker = broker.split('/');
    for(let i = 0; i < splittedBroker.length; i++){
      if(splittedBroker[i] == "MS"){
        this.getGlobalID();
        this.bodyData[5] = PageSection.sb;
        this.bodyData[6] = 1;
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
          
            let msData = JSON.parse(data.body);

            let msBody;
  
           if(msData.data) { // length > {}
            msBody = msData.data;
  
              if (msBody.length > 0){
                this.sbDesc = msBody[0].Body_Desc;
                this.displayPersonalSb = true;
                }
              else {
                this.displayPersonalSb = false;
                this.sbDesc = "";
              } 
           }
  
  
          this.bodyData[5] = PageSection.sbDoc;
          this.bodyData[6] = 0;
  
          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          let msDocData = JSON.parse(data.body);

          let msDocBody;
          
          if(msDocData.data) { // length > {}
                          msDocBody = msDocData.data;
                          if(msDocBody.length > 0) {
                            this.sbDoc = msDocBody;
          
                            for(let i=0; i<this.sbDoc.length; i++){
                              this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                            }
                          }
                          else{
                            this.sbDoc = null;
                          }
                       }
                    
          });
          this.sbLoaded = true;
      this.resourceLoading = false;
        });
  
      }else if(splittedBroker[i] == "UBS"){
        
      this.ubsAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.ubs;
      this.bodyData[6] = 1;
      
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        let ubsData = JSON.parse(data.body);

        let ubsBody;
  
       if(ubsData.data) { // length > {}
        ubsBody = ubsData.data;
        if(ubsBody.length > 0) {
          this.ubsDesc = ubsBody[0].Body_Desc;
          this.displayPersonalUbs = true;
        }
        else {
        this.displayPersonalUbs = false;
          this.ubsDesc = "";
        }
      }
  
      this.bodyData[5] = PageSection.ubsDoc;
      this.bodyData[6] = 0;
      
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
        let ubsDocData = JSON.parse(data.body);

        let ubsDocBody;
        
        if(ubsDocData.data) { // length > {}
            ubsDocBody = ubsDocData.data;
            if(ubsDocBody.length > 0){
              this.ubsDoc = ubsDocBody;
      
              for(let i=0; i<this.ubsDoc.length; i++){
                this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
              }
            }
            else{
              this.ubsDoc = null;
            }
         }
      });
      this.ubsLoaded = true;
      this.resourceLoading = false;
      });
      }else if(splittedBroker[i] == "BIG"){
        
        this.bigAccount = brokerAccounts[i].Account;
        this.bodyData[5] = PageSection.big;
        this.bodyData[6] = 1;
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        
          let bigData = JSON.parse(data.body);

          let bigBody;
  
         if(bigData.data) { // length > {}
          bigBody = bigData.data;
          if(bigBody.length > 0){
            this.bigDesc = bigBody[0].Body_Desc;
            this.displayPersonalBig = false;
          }else {
            this.displayPersonalBig = false;
            this.bigDesc = "";
          }
        }
        this.bigLoaded = true;
      this.resourceLoading = false;
        });
  
      }else if(splittedBroker[i] == "TA"){
       
        this.displayPersonalSb = false;
        this.displayPersonalUbs = false;
        this.displayPersonalBig = false;
        
  
        this.ncbAccount = brokerAccounts[i].Account;
        this.bodyData[5] = PageSection.ncb;
        this.bodyData[6] = 1;
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
          let taData = JSON.parse(data.body);

          let taBody;
  
         if(taData.data) { // length > {}
          taBody = taData.data;
          if(taBody.length > 0){
          this.ncbDesc = taBody[0].Body_Desc;
          this.displayPersonalNcb = true;
          }else {
            this.displayPersonalNcb = false;
            this.ncbDesc = "";
          }
       } 
        
        this.bodyData[5] = PageSection.ncbDoc;
        this.bodyData[6] = 0;         
  
           this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
  
            let taDocData = JSON.parse(data.body);

            let taDocBody;
  
            if(taDocData.data) { // length > {}
            taDocBody = taDocData.data;
            if(taDocBody.length > 0){
              this.ncbDoc = taDocBody;
  
              for(let i=0; i<this.ncbDoc.length; i++){
                this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
              }
            }else {
              this.displayPersonalNcb = false;
              this.ncbDoc = null;
            }
          }
           });
           this.ncbLoaded = true;
           this.resourceLoading = false;
        });
    }
  }
  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });
  
  }
}
}

});


    


  }

  /*****************************************************************************************
  * Method Name: printFile                                                                 
  * Description: Prints the file.
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/07/19             Dalemark P. Suquib                    Initial Draft
  *****************************************************************************************/
 printFile() {
    try {
      let award: string = "rsu";
      this.printService.print(award);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "printFile");
      error.message += errorLocation;
      throw error;
    }
  }

  fileImage(filyType){
    if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
      this.fileTypeImagePath = '/assets/pdf.svg';
      this.fileTypeAlt = "PDF File"
      return true;
    }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
      this.fileTypeImagePath = '/assets/zip.svg';
      this.fileTypeAlt = "ZIP File"
      return true;
    }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
      this.fileTypeImagePath = '/assets/xls.svg';
      this.fileTypeAlt = "XLS File"
      return true;
    }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
      this.fileTypeImagePath = '/assets/docx.svg';
      this.fileTypeAlt = "DOC File"
      return true;
    }
   }

   /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
  collapseAccordion(index) {
    let className = document.getElementById("collapseCQIndexi" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.rsuCommonQuestionsContent.length; i++) {
        document.getElementById("collapseCQIndexi" + i).className = "collapse";
      }
    }
   }


/**********************************************************************************
   * Method Name: getPSUDetails                                                                 
   * Description: Get the PSU Performance award details                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 06/06/2023           JMC                         Initial Draft       
   * 07/06/2023           JCC                         Added Shares Attaind by PSU at tooltip Unreleased.  
   *********************************************************************************/
  
getPSUDetails(){
  console.log('getPSU holdprog');
  try {
    if(sessionStorage.getItem("RSU Details Data") != null && (this.shareActGrantId != null)){
      console.log('grantID = ' + this.shareActGrantId);
      this.holdingProgramDetailsRSUService.getPSUDetails(this.shareActGrantId, this.user.OptNum).subscribe(data => {
        try {      
          if(Array.isArray(data) && data[0].length > 0) {
            //this.PSUSharesDisp = true;
            let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
            sessionStorage.setItem("PSU Details Data", JSON.stringify(data));
            sessionStorage.setItem("Psu Awards Details", JSON.stringify(sortedBody[0]));
            let psuDetails = data[0][0];
            this.holdingProgramDetailsRSUService.psuDetails = psuDetails;
            this.SharesAttainedPSU = psuDetails.SharesAttainedByPSU;
            this.SharesAttainedPSU = 0; //JCC --Set to zero 08/09/23
            this.psuShares = psuDetails.SharesAttainedByPSU;
            this.PSUPerf1 = psuDetails.PSU_Performance_1;
            this.PSUPerf1 = psuDetails.PSU_Performance_2;
            this.PSUDisplayInd = psuDetails.DisplayInd;
            this.PSUGrantValue = (this.psuShares * this.shareActGrantPrice);
            this.total_shares = (this.total_shares + this.SharesAttainedPSU);
            this.total_grantValue = (this.PSUGrantValue + this.additional_grantValue + this.originalGrant_grantValue);
            this.shareActTotalAwarded = (this.shareActTotalAwarded + this.SharesAttainedPSU);
            this.PSUSharesDisp = this.psuShares > 0;

            if(this.PSUDisplayInd == "N" && this.psuGePastInd == "N") {
              this.unvested_shares = this.tt_unreleased.SharesUnvested + this.SharesAttainedPSU;
              this.vestedButUnreleased = this.tt_unreleased.VestedButUnreleased;
              this.totalUnreleased = this.tt_unreleased.TotalUnreleased + this.SharesAttainedPSU;
            }

            this.psuKeyExecShow();


          } else {
            console.log('else array false');
            this.PSUSharesDisp = false;

            //No PSU
            this.psuKeyExecDisplay = false;
            this.keyExecToggle = "Target";
          }

          this.loadingService.setExecutedComponent();
        } catch(error) {

          let errorLocation = this.errorLocation.replace("methodName", "getPSUDetails");
          error.message += errorLocation;
          throw error;
        }
  
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getPSUDetails");
        error.error.message += errorLocation;
        throw error.error;
      });

    }


  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getPSUDetails");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
   * Method Name: psuKeyExecShow                                                                 
   * Description: Logic for Key Exec Performance Toggle show.                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 06/06/2023           Jewel C. Caudilla                         Initial Draft       
   *********************************************************************************/
  psuKeyExecShow() {
    if(this.PSUDisplayInd == 'Y') {
      this.psuKeyExecDisplay = true;

    } else {
      this.psuKeyExecDisplay = false;
      this.keyExecToggle = "Target";
    }
  }

  /**********************************************************************************
   * Method Name: getPSUDetailsbyOptNum                                                                 
   * Description: This function used to get all PSU Details using OptNum with PastInd 
   *              to determine every grantNum if Unreleased or Released.                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/10/2023           Jewel C. Caudilla           Initial draft       
   *********************************************************************************/
  async getPsuDetailsbyOptNum() {
    try {
      let psuData = JSON.parse(sessionStorage.getItem("PSU Details by OptNum"));
      let award = JSON.parse(sessionStorage.getItem("currentVisitedAward"));
      if(award != null) {
        if(psuData != null) {
          for(let i = 0; i < psuData.length; i++) {
            if(psuData[i].GrantNum == award.GrantNum) {
              this.psuGePastInd = psuData[i].PastInd;
              this.PSUDisplayInd = psuData[i].DisplayInd;
              this.SharesAttainedPSU = psuData[i]["Shares Attained by PSU"];
              this.SharesAttainedPSU = 0; //JCC --Set to zero 08/09/23
              this.psuShares = psuData[i]["Shares Attained by PSU"];
            }
          }
        } else {
          let body: any = 0;
          let optNum = JSON.parse(sessionStorage.getItem(this.globals.sessionOptNum));
          await this.holdingSummaryPage.getPSUDetailsbyOptNum(optNum).subscribe(data => {
            try {
              if (Array.isArray(data) && data.length > 0) {
                body = data[0].sort((a,b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);
                sessionStorage.setItem("PSU Details by OptNum", JSON.stringify(body));
              }

              let psuData = body;
              for(let i = 0; i < psuData.length; i++) {
                if(psuData[i].GrantNum == award.GrantNum) {
                  this.psuGePastInd = psuData[i].PastInd;
                  this.PSUDisplayInd = psuData[i].DisplayInd;
                  this.SharesAttainedPSU = psuData[i]["Shares Attained by PSU"];
                  this.SharesAttainedPSU = 0; //JCC --Set to zero 08/09/23
                  this.psuShares = psuData[i]["Shares Attained by PSU"];
                }
              }
            } catch(error) {
              let errorLocation = this.errorLocation.replace("methodName", "getPsuDetailsbyOptNum");
              error.message += errorLocation;
              throw error;
            }
          })
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getPsuDetailsbyOptNum");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

}