
import {refCount, publish} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ToolaccessService } from "./ToolaccessService";


@Injectable()
export class BdraccessService {

  private _toolAccess: boolean = this.toolAccessService.getUserToolAccess();
  private _access: BehaviorSubject<boolean> = new BehaviorSubject(this._toolAccess);
  private _accountNumberError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _companyCodeError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _invoiceDescError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _billToError: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly access$: Observable<boolean> = this._access.asObservable().pipe(publish(),refCount(),);
  readonly accountNumberError$: Observable<boolean> = this._accountNumberError.asObservable().pipe(publish(),refCount(),);
  readonly companyCodeError$: Observable<boolean> = this._companyCodeError.asObservable().pipe(publish(),refCount(),);
  readonly invoiceDescError$: Observable<boolean> = this._invoiceDescError.asObservable().pipe(publish(),refCount(),);
  readonly billToError$: Observable<boolean> = this._billToError.asObservable().pipe(publish(),refCount(),);

  constructor(
    private toolAccessService: ToolaccessService
  ) { }

  /* istanbul ignore next */
  setBdrAccess(state: boolean): void {
    this._access.next(state);
  }

  /* istanbul ignore next */
  setAccountNumberError(state: boolean): void {
    this._accountNumberError.next(state);
  }

  /* istanbul ignore next */
  setCompanyCodeError(state: boolean): void {
    this._companyCodeError.next(state);
  }

  /* istanbul ignore next */
  setInvoiceDescError(state: boolean): void {
    this._invoiceDescError.next(state);
  }

  /* istanbul ignore next */
  setBillToError(state: boolean): void {
    this._billToError.next(state);
  }

}