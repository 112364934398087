import { Injectable } from '@angular/core';
import { Globals } from '../../../globals';
import { ApiService } from './ApiService';
import { EaLoadingService } from '../EaLoadingService';
import { ToolaccessService } from './ToolaccessService';

@Injectable()
export class ApplicationaccessService {

  applicationAccess: boolean;

  constructor(
    private globals: Globals,
    private apiService: ApiService,
    private loadingService: EaLoadingService
  ) { }

  /***************************************************************************************************************
  * Method Name: getApplicationAccess                                                                 
  * Description: Gets the application access of the user.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/12/20             Dalemark P. Suquib             Initial draft  
  * 2         03/35/20             Dalemark P. Suquib             Added condition for Ejet access and Sem access  
  ****************************************************************************************************************/
  getApplicationAccess(): Promise<boolean> {
    let applicationAccess = new Promise<boolean>((resolve) => {
      let getApplicationAccessBody = { 
        functionName: 'getApplicationAccess'
      };
    
      this.apiService.postData(this.globals.manageUserAccessApi, getApplicationAccessBody).subscribe(data => {
        try {
          if(data) {
            if(data.statusCode == 200) {
              let body = data.body;
              
              if(body != this.globals.noAccess) {
                this.setUserAppAccess(true);
                resolve(true);
              } else {
                this.setUserAppAccess(false);
                resolve(true);
              }
            } else {
              this.setUserAppAccess(false);
              resolve(true);
            }
          } else {
            this.setUserAppAccess(false);
            resolve(true);
          }
        } catch(error) {
          this.loadingService.setLoadingState(false);
          this.loadingService.setAppError(true);
          error.message += this.apiService.setErrorContents('getApplicationAccess', 'ApplicationaccessService');
          throw error;
        }
      }, error => {
        this.loadingService.setLoadingState(false);
        this.loadingService.setAppError(true);
        error.message += this.apiService.setErrorContents('getApplicationAccess', 'ApplicationaccessService');
        throw error;
      });
    });

    return applicationAccess;
  }

  /**********************************************************************************
    * Method Name: setUserAppAccess                                                                 
    * Description: Sets the user's application access.                       
    *                                                                                          
    * #         Date                 Modified by                    Description                             
    * 1         06/12/20             Dalemark P. Suquib             Initial draft  
    ***********************************************************************************/
  setUserAppAccess(access: boolean): void {
    this.applicationAccess = access;
  }

  /**********************************************************************************
  * Method Name: getUserAppAccess                                                                 
  * Description: Gets the user's application access.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/12/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getUserAppAccess(): boolean {
    return this.applicationAccess;
  }

}
