import { Component, OnInit } from '@angular/core';
import { EjetTab } from '../../../services/ea/models/ejettab-model';
import { StaticService } from '../../../services/ea/api/StaticService';
import { Store } from '@ngxs/store';
import { Weeklysummary } from '../../../services/ea/actions/weeklysummary.actions';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApplicationaccessService } from '../../../services/ea/api/ApplicationaccessService';
import { ToolaccessService } from '../../../services/ea/api/ToolaccessService';
import { RoleaccessService } from '../../../services/ea/api/RoleaccessService';
import { EjetaccessService } from '../../../services/ea/EjetaccessService';
import { ApiService } from '../../../services/ea/api/ApiService';

@Component({
  selector: 'app-ejet',
  templateUrl: './ejet.component.html',
  styleUrls: ['./ejet.component.css']
})
export class EjetComponent implements OnInit {

  ejetHeaderTitle: string;
  ejetItems = [];
  activeComponent: any;
  activeMobileTab: string = "";
  activeEjetTab: any;
  ejetTab: EjetTab;
  applicationAccess: boolean = this.applicationAccessService.getUserAppAccess();
  toolAccess: boolean = this.toolAccessService.getUserToolAccess();
  roleId: number = this.roleAccessService.getUserRoleAccess();
  isLoading: boolean = true;
  staticTextContent: any;
  weeklySummaryStaticData: any[] = [];
  activeLink: any;
  isError: boolean = false;
  ejetService: any;

  constructor(
    private staticContentService: StaticService,
    private store: Store,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private applicationAccessService: ApplicationaccessService,
    private toolAccessService: ToolaccessService,
    private roleAccessService: RoleaccessService,
    private ejetAccessService: EjetaccessService,
    private apiService: ApiService
  ) { 
    this.activeLink = router.events.subscribe( event => {
      if(event instanceof NavigationEnd) {
        this.setActiveTab(event.url);
      }
    });

    this.ejetAccessService.access$.subscribe(access => {
      this.toolAccess = access;
    })

    this.ejetService = this.ejetAccessService.ejetError$.subscribe(state => {
      this.isError = state;
    });
  }

  ngOnInit() {

    if (this.applicationAccess && this.toolAccess) {
      this.getStaticContents();
    } else {
      this.isLoading = false;
    }   

  }

  ngOnDestroy(){
    this.ejetService.unsubscribe();
    this.activeLink.unsubscribe();
  }

  /**********************************************************************************
 * Method Name: setActiveTab                                                                 
 * Description: Sets the active tab                   
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/12/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/ 
  setActiveTab(url){
    try {
      for (let i = 0; i < this.ejetItems.length; i++) {
        if (url.includes(this.ejetItems[i].path)) {
          this.ejetItems[i].isActive = true;
        } else {
          this.ejetItems[i].isActive = false;
        }
      }
    } catch (error) {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getStaticContents', 'Ejet');
      throw error;
    }
  }

   /**********************************************************************************
  * Method Name: getStaticContents                                                                 
  * Description: Calls an api to retrieve values for page contents.                   
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/12/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/ 
  getStaticContents(){
    let messageName: string = "%ea.ejet%";
    this.staticContentService.getStaticContent(messageName).then( data => {
      if (data) {
        this.staticTextContent = data; 
        this.setStaticText();  
      } else {
        this.isLoading = false;
      }
    }).catch((error) => {
      if(error) {
        this.isLoading = false;
        this.isError = true;
      }
    });
  }

   /**********************************************************************************
  * Method Name: setStaticText                                                                 
  * Description: Sets the values of page title and tabs.                   
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/12/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/ 
  setStaticText(){
    try {
      for (let i = 0; i < this.staticTextContent.length; i++) {
        if (this.staticTextContent[i].MESSAGE_NAME.includes("ea.ejet.page.tab")) {
          switch (this.staticTextContent[i].MESSAGE_VALUE) {
            case "Import Invoice File":
              if (this.roleId == 5 || this.roleId == 6) {
                this.ejetTab = new EjetTab();
                this.ejetTab.id = this.staticTextContent[i].MESSAGE_VALUE;
                this.ejetTab.path = "importinvoice";
                this.ejetTab.isActive = false;
                this.ejetTab.isDisplayed = (this.roleId == 5 || this.roleId == 6);
                this.ejetItems.push(this.ejetTab);            
              }
              break;
          
            case "Weekly Summary":
              this.ejetTab = new EjetTab();
              this.ejetTab.id = this.staticTextContent[i].MESSAGE_VALUE;
              this.ejetTab.path = "weeklysummary";
              this.ejetTab.isActive = false;
              this.ejetTab.isDisplayed = true;
              this.ejetItems.push(this.ejetTab);          
              break;
          
            default:
              break;
          }       
        }else if(this.staticTextContent[i].MESSAGE_NAME == "ea.ejet.page.title"){
          this.ejetHeaderTitle = this.staticTextContent[i].MESSAGE_VALUE;
        }else {
          this.weeklySummaryStaticData.push(this.staticTextContent[i])
        }
      }
      this.store.dispatch(new Weeklysummary.SetStaticData({data : this.weeklySummaryStaticData}));
      this.setActiveTab(this.ejetItems[0].path);
      this.routeToComp(this.ejetItems[0].path);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('setStaticText', 'Ejet');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: selectComponent                                                                 
  * Description: Sets the active tab on click.                   
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/15/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
 routeToComp(path){
   try {
      if (path == 'weeklysummary') {
        this.router.navigate(['weeklysummary/reports'], {relativeTo: this.activeRoute})
      }else{
        this.router.navigate([path], {relativeTo: this.activeRoute})
      }
   } catch (error) {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('routeToComp', 'Ejet');
      throw error;
   }
 }


}
