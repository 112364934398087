import { ReportItem } from './../models/reportitem-model';

export namespace Weeklysummary{

    export class Add {
        static readonly type = '[Weekly Summary] Add';
        constructor(public payload: ReportItem){
            payload.DESCRIPTION = payload.DESCRIPTION != null ? payload.DESCRIPTION : "";
            payload.SOURCE_NAME = payload.SOURCE_NAME != null ? payload.SOURCE_NAME : "";
            payload.TRANSLATION_DATE = payload.TRANSLATION_DATE != null ? payload.TRANSLATION_DATE : "";
            payload.POSTING_DATE = payload.POSTING_DATE != null ? payload.POSTING_DATE : "";
        }
    }

    export class Remove {
        static readonly type = '[Weekly Summary] Remove';
        constructor(public payload: { id: number }){}
    }

    export class ClearReportItems{
        static readonly type = '[Weekly Summary] Clear_Report_Items'
    }

    export class SetStaticData{
        static readonly type = '[Weekly Summary] Set_Static_Text';
        constructor(public payload: {data: object}){}
    }
    
}