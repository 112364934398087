
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
// import { Http, Headers, RequestOptions } from '@angular/http';
import { map, catchError, retry } from 'rxjs/operators';

@Injectable()
export class MultipleGrantsService {

    headers: Headers;
    // options: RequestOptions;
    token: string = sessionStorage.getItem("msal.idtoken");

    constructor(private globals: Globals, private httpClient: HttpClient) { }
    
  getRSUAgreementInfo(grantNum) {
    let apiUrl = this.globals.currentAPIUrl;
    let rsuAgreementApi = apiUrl + this.globals.getRSUAgreementAPI;

    rsuAgreementApi = rsuAgreementApi.replace("[param1]", grantNum);

    try {
      const api = rsuAgreementApi;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getGrantAgreement(grantNum){
    let apiUrl = this.globals.currentAPIUrl;
    let grantAgreementurl = apiUrl + this.globals.getOtionsAgreement;

    grantAgreementurl = grantAgreementurl.replace("[param1]", grantNum);
    
    try {
      return this.httpClient.get(grantAgreementurl).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  RSUgrantAccepted(userOptnum, adminOptnum, grantNum){
    let apiURL = this.globals.currentAPIUrl;
    let methodPath = this.globals.acceptGrantAcceptBtnRSUAPI;

    methodPath = methodPath.replace("[param1]", userOptnum);
    methodPath = methodPath.replace("[param2]", adminOptnum);
    methodPath = methodPath.replace("[param3]", grantNum);    

    try {
      const apiLogin = apiURL + methodPath;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});
      let body = JSON.stringify({ userOptnum, adminOptnum, grantNum })

      return this.httpClient.put(apiLogin, body).pipe(retry(3));
    }catch(err) {
      return err;
    }
  }

  OptionsgrantAccepted(userOptnum, adminOptnum, grantNum){    
    let apiURL = this.globals.currentAPIUrl;
    let methodPath = this.globals.acceptGrantAcceptBtnOptionsAPI;

    methodPath = methodPath.replace("[param1]", userOptnum);
    methodPath = methodPath.replace("[param2]", adminOptnum);
    methodPath = methodPath.replace("[param3]", grantNum);    

    try {
      const apiLogin = apiURL + methodPath;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});         
      let body = JSON.stringify({ userOptnum, adminOptnum, grantNum })      

      return this.httpClient.put(apiLogin, body).pipe(retry(3));
    }catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 
}