import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/ea/api/ApiService';
import { Globals } from '../../../../globals';
import { Store } from '@ngxs/store';
import { BdrLoadingService } from '../../../../services/ea/api/BdrLoadingService';
import { BdraccessService } from '../../../../services/ea/api/BdrAccessService';
import { StaticService } from '../../../../services/ea/api/StaticService';
import { AccountNumber } from '../../../../services/ea/actions/accountnum.actions';

@Component({
  selector: 'app-accountnumber',
  templateUrl: './accountnumber.component.html',
  styleUrls: ['./accountnumber.component.css']
})
export class AccountnumberComponent implements OnInit {

  equityTypeList: any[] = [];
  accNoStaticText: any[] = [];
  accNoHeader: string
  equityTypeId: number;
  tableLoading: boolean;
  displayTable: boolean;
  tableHasData: boolean;
  loadingService: any;
  loading: boolean = true;
  isError: boolean = false;
  anFieldError: boolean = false;
  bdrService: any;

  constructor(
    private apiService: ApiService,
    private globals: Globals,
    private store: Store,
    private accNumberLoading: BdrLoadingService,
    private bdrAccessService: BdraccessService,
    private staticContentService: StaticService
  ) {
    this.bdrService = this.bdrAccessService.accountNumberError$.subscribe(state => {
      if(state) {
        this.isError = state;
        this.displayTable = false;
        this.tableHasData = false;
        this.loading = false;
      }
    });
   }

  ngOnInit() {
    this.getStaticContents();
    this.loadingService = this.accNumberLoading.BdrLoading$.subscribe(state => {
      this.tableLoading = state;
    });
  }

  ngOnDestroy(){
    this.bdrService.unsubscribe();
    this.loadingService.unsubscribe();
    this.accNumberLoading.setLoadingState(false);
  }

  /**********************************************************************************
 * Method Name: getStaticContents                                                                 
 * Description: Calls an api to retrieve values for page contents.                   
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/12/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/ 
 getStaticContents(){
    let messageName: string = "%ea.bdr.accno%";
    this.staticContentService.getStaticContent(messageName).then( data => {
      if (data) {
        this.accNoStaticText = data; 
        this.accNoHeader = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.header")].MESSAGE_VALUE;
      }
      this.getEquityTypes();
    }).catch((error) => {
      if(error) {
        this.loading = false;
        this.isError = true;
      }
    });
 }

 /**********************************************************************************
 * Method Name: getDataIndex                                                                 
 * Description: Returns the index of the given key.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         02/19/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
  getDataIndex(key) {
    try {
      let index;
      for (let i = 0; i < this.accNoStaticText.length; i++) {
        if (this.accNoStaticText[i].MESSAGE_NAME == key) {
          index = i
        }
      }
      return index;
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getDataIndex', 'Accountnumber');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getValue                                                                 
  * Description: Gets the value.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/05/20             Dalemark P. Suquib             Initial draft  
  * 2         05/13/20             John Paul B Fernando           Added logic to set the selected equity type id.
  ***********************************************************************************/
  getValue(value: number): void {
    this.equityTypeId = value;
  }

  /**********************************************************************************
  * Method Name: getEquityTypes                                                                 
  * Description: Gets the list of equity types.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getEquityTypes(){
      let body = {
        functionName: "getEquityTypeList"
      }
      this.apiService.postData(this.globals.manageImportInvoiceApi, body).subscribe( data => {
        try {
          let body = data.body
          if (body) {
            let bodyData = body;
            if (bodyData) {
              if(bodyData != this.globals.notAuthorized) {
                this.equityTypeList = bodyData;
                this.getValue(this.equityTypeList[0].Equity_Type_ID);
              } else {
                this.bdrAccessService.setBdrAccess(false);
              }
            }
          }
          this.loading = false;
        } catch(error) {
          this.loading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('getEquityTypes', 'Accountnumber');
          throw error;
        }
      }, error => {
        this.loading = false;
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getEquityTypes', 'Accountnumber');
        throw error;
      });
  }

  /**********************************************************************************
  * Method Name: getAccNumberData                                                                 
  * Description: Gets the data of account number.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/01/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getAccNumberData(){
    let body = {
      functionName: "getBusDataAcctNum",
      equityTypeId: this.equityTypeId
    }
    if (this.tableLoading) {
      return
    }
    this.accNumberLoading.setLoadingState(true);
    this.apiService.postData(this.globals.manageAccNumberApi, body).subscribe( data => {
      try {
        let body = data.body;
        if (body && body != "") {
          let bodyData = body;
          if (bodyData.length > 0 && bodyData != "Invalid Input") {
            this.store.dispatch( new AccountNumber.Clear);
            for (let i = 0; i < bodyData.length; i++) {
              this.store.dispatch( new AccountNumber.Add(bodyData[i]))
            }
            this.displayTable = true;
            this.tableHasData = true;
          }else{
            this.displayTable = true;
            this.tableHasData = false;
          }
        }
        this.accNumberLoading.setLoadingState(false);
      } catch(error) { 
        this.accNumberLoading.setLoadingState(false);
        this.isError = true;
        error.message += this.apiService.setErrorContents('getAccNumberData', 'Accountnumber');
        throw error;
      }
    }, error => {
      this.accNumberLoading.setLoadingState(false);
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccNumberData', 'Accountnumber');
      throw error;
    });
  }

}
