import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';

@Injectable()
export class PrintService {

  optionsLayout: boolean;
  rsuLayout: boolean;
  holdingSummaryLayout: boolean;

  constructor(private router: Router, private globals: Globals) { }

  print(award) {
    try {
      this.optionsLayout = false;
      this.rsuLayout = false;
      this.holdingSummaryLayout = false;
      let url: string = this.router.url;
      let path: string;
      document.title = "Essential Grant Terms";

      if(url.indexOf("multiplegrantspage") != -1) {
        path = "/multiplegrantspage";
      } else if(url.indexOf("optionsdetailspage") != -1) {
        path = "/optionsdetailspage";
      } else if(url.indexOf("rsudetailspage") != -1) {
        path = "/rsudetailspage";
      } else if(url.indexOf("holdingsummarypage") != -1){
        path = "/holdingsummarypage";
        document.title = "Holdings Summary"
      }
  
      if(award == "options") {
        this.optionsLayout = true;
        this.router.navigateByUrl(path + "/(print:print)",
          { skipLocationChange: true }
        );
      } else if(award == "rsu") {
        this.rsuLayout = true;
        this.router.navigateByUrl(path + "/(print:print)",
          { skipLocationChange: true }
        );
      } else if(award == "holdingsummarypage") {
        this.holdingSummaryLayout = true;
        this.router.navigateByUrl(path + "/(print:print)",
        { skipLocationChange: true }
        );
      }

      setTimeout(() => {
        if(this.optionsLayout || this.rsuLayout || this.holdingSummaryLayout) {
          window.print();
          document.title = "myHoldings";
          this.router.navigateByUrl(path,
            { skipLocationChange: true }
          );
        }
      });
    } catch(error) {
      alert("Error: Please refresh the page");
      throw error;
    }
  }

}
