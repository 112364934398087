import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rsu-learn-more',
  templateUrl: './rsu-learn-more.component.html',
  styleUrls: ['./rsu-learn-more.component.css']
})
export class RsuLearnMoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
