import { Component, OnInit } from '@angular/core';
import { PageSection, MyHoldingsPagesResources } from '../../services/enumerations/enumerations';
import { StaticContentService } from '../../services/StaticContentService';
import { Router } from '@angular/router';
import { Globals } from '../../globals';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-policiesandotherlinks',
  templateUrl: './policiesandotherlinks.component.html',
  styleUrls: ['./policiesandotherlinks.component.css']
})
export class PoliciesandotherlinksComponent implements OnInit {
  resourceLoading: boolean;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  equityRelatedPoliciesBodyDesc: string = "";
  otherResourcesData: string = "";
  errorPoliciesResources: boolean = false;
  errorLocation: string = " + [Component: Resourcespage, Method: methodName]";

  constructor(private staticContent: StaticContentService, private router:Router, private globals: Globals) { }

  ngOnInit() {
    this.getPoliciesOthers();
  }

  getPoliciesOthers(){  
    try {
      // this.policiesOthersLoading = true;
      this.resourceLoading = true;
  
      this.bodyData[5] = PageSection.policiesEquity;
      this.bodyData[6] = 1;
    
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {    
        try {
          let equityRelatedPoliciesContent = JSON.parse(data._body);
  
          if(equityRelatedPoliciesContent.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
    
          if (equityRelatedPoliciesContent.body == "{}"){
            //NO EQUITY RELATED POLICIES DATA TO DISPLAY
            equityRelatedPoliciesContent.body = null;
          } 
          
          if (equityRelatedPoliciesContent.body.length > 2){
      
            let parsedEquityRelatedPoliciesContent = JSON.parse(equityRelatedPoliciesContent.body);
      
            this.equityRelatedPoliciesBodyDesc =  parsedEquityRelatedPoliciesContent.data[0].Body_Desc;
          }  
        
            this.bodyData[5] = PageSection.policiesOther;
            this.bodyData[6] = 1;
      
            this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {    
              try {
                  //Other Resources
                  let otherResourcesContent = JSON.parse(data._body);
                  
                  if(otherResourcesContent.name == "TokenExpiredError"){
                    this.router.navigate(['homepage']);
                  }
                  
                  if (equityRelatedPoliciesContent.body == "{}"){
                    //NO OTHER RESOURCES DATA TO DISPLAY
                    equityRelatedPoliciesContent.body = null;
                  } 
  
                  if (otherResourcesContent.body.length > 2){
  
                    let parsedOtherResourcesContent = JSON.parse(otherResourcesContent.body);
                    this.otherResourcesData = parsedOtherResourcesContent.data[0].Body_Desc;
                }
                // this.policiesOthersLoading = false;
                this.resourceLoading = false;
                setTimeout(()=>{ this.removeLinksjQuery() }, 500);
              } catch(error) {
                this.errorPoliciesResources = true;
                this.resourceLoading = false;
                let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
                error.message += errorLocation;
                throw error;
              }
          }, error => {
            this.errorPoliciesResources = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
            error.error.message += errorLocation;
            throw error.error;
          });
        } catch(error) {
          this.errorPoliciesResources = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorPoliciesResources = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorPoliciesResources = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
      error.message += errorLocation;
      throw error;
    }
  }

  removeLinksjQuery(){
    $("u").contents().unwrap();
    $("[href='https://publishing.accenture.com/EthicsCompliance']").remove();
  }

  getFileName(eventBody) {
    try {
      let href: string = eventBody.target.href;
      let id: string = eventBody.target.id;
      let srcElement: HTMLImageElement = eventBody.srcElement;
      let target: string = eventBody.target.target;
      let reportId: object;
      
      if(srcElement.src != undefined){
        this.staticContent.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContent.validateFileType(event);
        } else if(href.indexOf("reportid") != -1) {
          id = href.substring(href.lastIndexOf("=") + 1);
          reportId = {'ReportTypeId': id};
          sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
          window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
          return false;
        } else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          let innerTxt = eventBody.target.innerText;
          event.preventDefault();

          if(target == "_blank") {
            if(innerTxt in MyHoldingsPagesResources){
              this.navToMyHoldingsPage(href, "_blank");
            }else if(href.indexOf("Resources") != -1){
              this.navToMyHoldingsPage("Resources","_blank");
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              this.navToMyHoldingsPage("Reports","_blank");
            }
          } else {
            if(href.indexOf("Resources") != -1){
              eventBody.target.href = this.globals.currentWebUrl + "/resourcespage";
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              eventBody.target.href = this.globals.currentWebUrl + "/reportspage";
            }
          }
          

        }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
          if(target == "_blank") {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts","_blank") : "";
          } else {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts","_self"): "";
          }
        } 
        
        // else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
        //   eventBody.preventDefault();
        //   window.open(href, target);
        // }else{ //if has file but no filename text
        //   event.preventDefault();
        //   this.staticContent.validateFileType(event);
        // }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page, redirect){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "contactspage" : "";
    page.indexOf("Reports") != -1 ? page = "reportspage" : "";

    window.open(this.globals.currentWebUrl + "/" + page, redirect);
    return false;
  }

}
