import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Globals } from '../../../../../globals';
import { PartnerselectedComponent } from '../partnerselected.component';
import { Router } from '@angular/router';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';



@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css']
})
export class ChangelogComponent implements OnInit {
  displayedColumns: string[] = ['Field_Changed', 'Table_Changed', 'Type_of_Change', 'Prior_Data', 'New_Data', 'Post_Date', 'Updated_By'];
  dataSource: any;
  documentId: any //number;
  optNum: any;
  loading: boolean = true;
  dataSourceTemp = [];
  tablePromises = [];
  isError: boolean = false;

  @ViewChild('changeLogPaginator') paginator: MatPaginator;

  constructor(
    private apiService: ApiService,
    private global: Globals,
    private partnerSelected: PartnerselectedComponent,
    private router: Router,
    private access: SemaccessService

  ) { }

  ngOnInit() {
    this.documentId = parseInt(sessionStorage.getItem(this.global.documentID) || '{}');
    this.optNum = sessionStorage.getItem('optNum');
    this.getAccess();
    this.partnerSelected.loadingEdit = true;
  }

  /**********************************************************************************
  * Method Name: getAccess                                                                 
  * Description: Retrieves access from a reasuable service 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         5/19/2020            Jikho Enrique                    Initial draft 
  ***********************************************************************************/
  async getAccess() {
    try {
      await this.access.getSemAccess("changelog").then();
      if (this.access.role != false) {
        this.partnerSelected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerSelected.editHistory(this.documentId, this.optNum);
          this.partnerSelected.partnerSelectedInfo(this.optNum);
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            await this.access.getDocumentAccess("changelog");
            if (this.access.document != false) {
              if (this.access.document['data'][0][0] != null && this.access.document['data'][0][0] != undefined) {
                this.getChangeLogData();
              } else {
                this.router.navigate(['equityadmin/eamain'])
              }
            } else {
              this.router.navigate(['equityadmin/eamain']);
            }
          } else {
            this.router.navigate(['equityadmin/eamain'])
          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } else {
        this.router.navigate(['equityadmin/eamain']);
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccess', 'changelog');
      throw error;
    }
  } 

  /**********************************************************************************
  * Method Name: getChangeLogData                                                                 
  * Description: Retrieves Change Log data and store it on a MatTableDataSource object
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         5/19/2020            Jikho Enrique                    Initial draft 
  ***********************************************************************************/
  getChangeLogData() {
      let changeLogDataAPIParam: any = {
        "functionName": "getChangeLog",
        "selected_optnum": this.optNum,
        "page": 1
      }
      let tablePage;
      this.apiService.postData(this.global.api_URL_pds, changeLogDataAPIParam).subscribe(async data => {
        try {
          if (data) {
            let body = data.body;
            if (body != null && body != undefined) {
              tablePage = Math.ceil(body['data'][1][0]['TotalRows'] / 50);
              if (tablePage == 1) {
                this.dataSource = new MatTableDataSource(body['data'][0]);
                setTimeout(() => this.dataSource.paginator = this.paginator);
                this.loading = false;
                this.partnerSelected.loadingEdit = false;
                this.global.searchLoading = false;
              } else {
                for (let i = 0; i < Math.ceil(body['data'][1][0]['TotalRows'] / 50); i++) {
                  await this.getMulti(i).then();
                }
                this.dataSource = new MatTableDataSource(this.dataSourceTemp);
                setTimeout(() => this.dataSource.paginator = this.paginator);
                this.loading = false;
                this.partnerSelected.loadingEdit = false;
                this.global.searchLoading = false;
              }
            }
          }
        } catch (error) {
          this.loading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('getChangeLogData', 'changelog');
          throw error;
        }
      }, error => {
        
        this.loading = false;
        this.isError = true;
        error.error.message +=this.apiService.setErrorContents('getChangeLogData', 'changelog');
        throw error;
        
      });
  }

  getMulti(page) {
      let z = new Promise<void>((resolve) => {
        let changeLogDataAPIParam2: any = {
          "functionName": "getChangeLog",
          "selected_optnum": this.optNum,
          "page": page + 1
        }
        this.apiService.postData(this.global.api_URL_pds, changeLogDataAPIParam2).subscribe(async data2 => {
          try {
            if (data2) {
              let body2 = await data2.body;
              if (body2 != null && body2 != undefined) {
                for (let r = 0; r < body2['data'][0].length; r++) {
                  this.dataSourceTemp.push({ CHANGE_FIELD: body2['data'][0][r]['CHANGE_FIELD'], CHANGE_TABLE_NAME: body2['data'][0][r]['CHANGE_TABLE_NAME'], CHANGE_TYPE_DESCRIPTION: body2['data'][0][r]['CHANGE_TYPE_DESCRIPTION'], PRIOR_DATA: body2['data'][0][r]['PRIOR_DATA'], NEW_DATA: body2['data'][0][r]['NEW_DATA'], Effective_Date: body2['data'][0][r]['Effective_Date'], UPDATED_BY: body2['data'][0][r]['UPDATED_BY'] });
                }
              }
            }
            resolve();
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('getChangeLogData', 'changelog');
            throw error; 
            
          }
        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message +=this.apiService.setErrorContents('getChangeLogData', 'changelog');
          throw error;
        });
      });
      return z;
    
  }
}
