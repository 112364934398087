import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { StaticContentService } from '../services/StaticContentService';
import { Router } from '@angular/router';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../services/UserService';

@Component({
  selector: 'app-pagefooter',
  templateUrl: './pagefooter.component.html',
  styleUrls: ['./pagefooter.component.css']
})
export class PagefooterComponent implements OnInit {
  closeResult: string;
  staticContent: any;
  legalNotice: string;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  errorLocation: string = " + [Component: Pagefooter, Method: methodName]";
  privacyPolicyUserAgreement: boolean;
  isProceedButtonDisabled: boolean = true;
  insertGDPRAgreement: any[] = [];
  removeCheck: any;

  constructor(private globals: Globals, private staticContentService: StaticContentService, private router: Router, private errorNotificationService: ErrorNotificationService, private modalService: NgbModal, private userService: UserService) { }

  ngOnInit() {
    this.getFooterStaticContent();
    if(sessionStorage.getItem("footerSetting") == "new"){
      $( "#thePageFooter" ).css("display","block");
      $( "#thePageFooterOld" ).css("display","none");
    }
  }

  getFooterStaticContent(){
    try {
      let legalNotice = this.globals.staticLegalNotice;
      this.staticContentService.getBluePageStaticContent(legalNotice).subscribe((data) => {
        try {
          let body = data[0];
          this.legalNotice = body.Value;
        } catch(error) {
          // sessionStorage.clear();
          // this.navToPage("landingpage");
          let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        // sessionStorage.clear();
        // this.navToPage("landingpage");
        let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      // sessionStorage.clear();
      // this.navToPage("landingpage");
      let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
      error.message += errorLocation;
      throw error;
    }
  }

 showGDPR(){
   document.getElementById("privacyBtn").click();
 }
  
  showModal(){
    try {
      document.getElementById("legalBtn").click();
      $("#privacyBtn").attr("tabindex","-1");
   } catch(error) {
      // sessionStorage.clear();
      // this.navToPage("landingpage");
      let errorLocation = this.errorLocation.replace("methodName", "showModal");
      error.message += errorLocation;
      throw error;
    }
  }

  navToPage(strLoc) {
    try {
      this.router.navigate([strLoc]);
    } catch(error) {
      // sessionStorage.clear();
      // this.navToPage("landingpage");
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft     
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Pagefooter") != -1 && this.user != null) {
                webPage = "Pagefooter";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
          
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  ppAgreement(){

    this.removeCheck = null;
    
    if (this.privacyPolicyUserAgreement){
    this.insertPrivacyAgreement(1);
    sessionStorage.setItem("isPPAccepted","yes");
    }else{
    this.insertPrivacyAgreement(0);
    }
      
  }

  agreementValue(event){
    this.isProceedButtonDisabled = false;
    if(event == "accepted"){
      this.privacyPolicyUserAgreement = true;
    }else if(event == "unaccepted"){
      this.privacyPolicyUserAgreement = false;
    }
    
  }
  
  insertPrivacyAgreement(flag: number) {
    try {
      let response = flag.toString();
      this.userService.insertGdprFlag(flag).subscribe(data => {
        // sessionStorage.setItem("configuredPP",response);
      
        if(flag == 0){
          this.isProceedButtonDisabled = true;
          sessionStorage.setItem("GDPRdisagree","yes");
          this.navToPage("landingpage");
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "insertPrivacyAgreement");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "insertPrivacyAgreement");
      error.message += errorLocation;
      throw error;
    }
  }
  // pageFooterDelay(){
  //   $( "#thePageFooter" ).css("visibility","hidden");
  
  //   setTimeout(()=> $( "#thePageFooter" ).css("visibility","visible"), 3000);
  
  //   }
  onKeydown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  }
  onModalClose(){
    $("#privacyBtn").attr("tabindex","0");
  }
  
}




