import { BillToItem } from '../models/billto-model'

export namespace BillTo {

    export class AddBillToItem {
        static readonly type = '[Bill To] Add_Bill_To_Item';
        constructor(public payload: BillToItem) { }
    }

    export class ClearBillToItem {
        static readonly type = '[Bill To] Clear_Bill_To_Item';
    }

    export class SetStaticData {
        static readonly type = '[Bill To] Set_Static_Data';
        constructor(public payload: any) {}
    }
}