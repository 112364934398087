import { Directive, ElementRef, Output, OnDestroy } from '@angular/core';
import { EventEmitter } from 'events';
// import { isNumber } from 'util';

@Directive({
  selector: '[appTrillionText]'
})
export class TrillionTextDirective {

  // private changes: MutationObserver;

  // @Output()
  // public domChange = new EventEmitter();

  constructor(private el: ElementRef) {
    const element = this.el.nativeElement;

    // this.changes = new MutationObserver((mutations: MutationRecord[]) => {
    //       mutations.forEach((mutation: MutationRecord) =>
    //       this.checkValue()
    //       );
    //     }
    // );

    // this.changes.observe(element, {
    //   attributes: true,
    //   childList: true,
    //   characterData: true
    // });
  }

  // ngOnDestroy(): void{
  //   this.changes.disconnect();
  // }

  ngAfterViewInit(){
    let osValue = $("#osValue");
    let availableValue = $("#availableValue");
    this.checkValue();
    osValue[0].innerText.length >= 15 ? osValue.addClass("changeOSfont") : osValue.removeClass("changeOSfont");
    availableValue[0].innerText.length > 15 ? availableValue.addClass("changeASfont") : availableValue.removeClass("changeASfont");
  }

  checkValue(){
    let preFix: any;
    let raw = this.el.nativeElement.innerText;
    let currency: string = this.getCurrency(raw);
    let amount = raw.slice(currency.length,raw.length);

    let value = Number(amount.replace(/,/g,""));

    if(raw.includes("lion") != true){
      if(value >= 1000000000 && value < 1000000000000){
        preFix = Math.floor(value/1000000000);
        this.el.nativeElement.innerText = currency + " "+ preFix + " Billion";
      }
      else if(value >= 1000000000000){
        preFix = Math.floor(value/1000000000000);
        this.el.nativeElement.innerText = currency + " "+ preFix + " Trillion";
      }
    }
    
  }

  getCurrency(raw){
    let currency: string = "";
    for (let i = 0; i < raw.length; i++) {
      const element = raw[i];
      // if (isNaN(element) && !isNumber(element)) {
      //   currency += element;
      // }
      
    }
    currency = currency.replace(/[,.]/g,"");
    return currency;
  }

}
