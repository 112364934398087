import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { HoldingProgramDetailsRSUIPOService } from '.././HoldingProgramDetailsRSUIPOService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-iporsu-grants-card',
  templateUrl: './iporsu-grants-card.component.html',
  styleUrls: ['./iporsu-grants-card.component.css']
})
export class IporsuGrantsCardComponent implements OnInit {

  //IPO GRANTS
  IPOgrantDataSource: any;
  IPOdisplayedGrantColumns: string[] = ['GrantId', 'Shares'];

  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  user: any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = this.user.CurrencyCd;
  errorLocation: string = " + [Component: Rsuipodetails, Method: methodName]";

  IPOgrantRsuDate: string = "null";
  IPOgrantTotalShares: number;
  displayGrantCard: boolean;
  errorGrantsSection: boolean;

  ipoGrantDesc: string;
  ipoGrantPrice: number;
  ipoTotalAwarded: number;
  ipoUnreleased: number;
  ipoForfeited: number;
  ipoReleased: number;
  ipoWithholdingTaxes: number;
  ipoSoldTransferred: number;
  ipoNetRemainingAtBroker: number;
  ipoOriginalGranted: number;
  ipoOriginalGrantedValue: any;
  ipoOriginalAdditionalRsu: number;
  ipoOriginalAdditionalRsuValue: any;
  ipototalAwardedValue: any;
  shareActGrantDate: any;

  sharesUnvested: any;
sharesUnvestedValue: any;
vestedButUnreleased: any;
vestedButUnreleasedValue: any;
totalUnreleased: any;
totalUnreleasedValue: any;
iporsuDetailsReleased: any;
withheldForTaxData: any;
withheldForTaxValue: any;
withHoldingRefund: any;
withHoldingRefundValue: any;
totalWithheld: any;
totalWithheldValue: any;
totalSharesRelease: any = 0;
totalSharesReleaseValue: any = 0;
  ipoWithholdingForTaxes: any;
  displayVestCard: boolean;
  displayReleaseCard: boolean;
 OptionsLoading: boolean = true;

  constructor(private globals: Globals, 
    private app: AppComponent, private router: Router, 
    private HoldingProgramDetailsRSUIpoService: HoldingProgramDetailsRSUIPOService,
    private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {
    this.getRSUDetailsGrantInfo();
    this.getRSUIPODetails();
  }

  // GRANT ACCORDION START
getRSUDetailsGrantInfo() {
  try {
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetailsGrantInfo(this.user.OptNum, this.IPOgrantRsuDate).pipe(map(res => res)).subscribe(data => {
      try {
        if(Array.isArray(data) && data[0].length > 0) {
          this.IPOgrantTotalShares = 0;
          this.IPOgrantDataSource = data[0];
      
          for(let i = 0; i < this.IPOgrantDataSource.length; i++) {
            this.IPOgrantTotalShares += this.IPOgrantDataSource[i].Shares;
          }
        } else {
          this.displayGrantCard = false;
       }
  
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsGrantInfo");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsGrantInfo");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsGrantInfo");
    error.message += errorLocation;
    throw error;
  }
}

// DEFAULT ACCORDION START
getRSUIPODetails() {
  try {
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetails(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      try {
        if(Array.isArray(data) && data.length > 0) {

          let ipoRSUDetails = data[0][0];                    

          if(ipoRSUDetails != null || ipoRSUDetails != undefined){
            this.ipoGrantDesc = ipoRSUDetails.GrantDescription;
            this.shareActGrantDate = ipoRSUDetails.GrantDate;
            this.ipoGrantPrice = ipoRSUDetails.GrantPrice;
            this.ipoTotalAwarded = ipoRSUDetails.TotalAwarded;
            this.ipoUnreleased = ipoRSUDetails.Unreleased;
            this.ipoForfeited = ipoRSUDetails.Forfeited;
            this.ipoReleased = ipoRSUDetails.Released;
            this.ipoWithholdingTaxes = ipoRSUDetails.WithheldForTax;
            this.ipoSoldTransferred = ipoRSUDetails.SoldTransferred;
            this.ipoNetRemainingAtBroker = ipoRSUDetails.NetRemainingAtBroker;
            this.ipoWithholdingForTaxes = ipoRSUDetails.WithholdingforTaxes;
          }
    
          let iporsudetailsGrantedData = data[1][0];   
          
          if(iporsudetailsGrantedData != null || iporsudetailsGrantedData != undefined){
            this.ipoOriginalGranted = iporsudetailsGrantedData.OriginalGranted;
            this.ipoOriginalGrantedValue = iporsudetailsGrantedData.OriginalGrantedValue;
            this.ipoOriginalAdditionalRsu = iporsudetailsGrantedData.AdditionalRsu;
            this.ipoOriginalAdditionalRsuValue = iporsudetailsGrantedData.AdditionalRsuValue;
            this.ipototalAwardedValue = this.ipoOriginalGrantedValue + this.ipoOriginalAdditionalRsuValue; 
    
            if (this.ipoOriginalGranted == this.ipoForfeited){
              this.displayVestCard = false;
              this.displayReleaseCard = false;
              this.displayGrantCard = false;
            }
            else {
              this.displayVestCard = true;
              this.displayReleaseCard = true;
              this.displayGrantCard = true;
            }
          }

          let iporsuDetailsUnvestedData = data[2][0];

          if(iporsuDetailsUnvestedData != null || iporsuDetailsUnvestedData != undefined){
            this.sharesUnvested = iporsuDetailsUnvestedData.SharesUnvested;
            this.sharesUnvestedValue = this.sharesUnvested*this.ipoGrantPrice;
            this.vestedButUnreleased = iporsuDetailsUnvestedData.VestedButUnreleased;
            this.vestedButUnreleasedValue = this.vestedButUnreleased*this.ipoGrantPrice;
            this.totalUnreleased = iporsuDetailsUnvestedData.TotalUnreleased;
            this.totalUnreleasedValue = this.totalUnreleased*this.ipoGrantPrice;
          }

          this.iporsuDetailsReleased = data[3];

          for(let i=0; i<this.iporsuDetailsReleased.length;i++){
            this.totalSharesRelease += this.iporsuDetailsReleased[i].Shares;
            this.totalSharesReleaseValue += this.iporsuDetailsReleased[i].Value;
          }

          let iporsuDetailsWithheldData = data[4][0];
          
          if(iporsuDetailsWithheldData != null || iporsuDetailsWithheldData != undefined){
            this.withheldForTaxData = iporsuDetailsWithheldData.WithheldForTax;
            this.withheldForTaxValue = iporsuDetailsWithheldData.WithheldForTaxValue;
            this.withHoldingRefund = iporsuDetailsWithheldData.WithHoldingRefund;
            this.withHoldingRefundValue = iporsuDetailsWithheldData.WithHoldingRefundValue;
            this.totalWithheld = iporsuDetailsWithheldData.TotalWithheld;
            this.totalWithheldValue = iporsuDetailsWithheldData.TotalWithheldValue;
          }
        }
          this.OptionsLoading = false;
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
    error.message += errorLocation;
    throw error;
  }
}
// DEFAULT ACCORDION - END
// GRANT ACCORDION END

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

}
