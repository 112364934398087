import { AccountNumberItem } from "../models/accountnumber-model";


export namespace AccountNumber {
    export class Add {
        static readonly type = '[Account Number] Add_Item';
        constructor ( public payload: AccountNumberItem){
        }
    }
    export class Clear {
        static readonly type = '[Account Number] Clear_Items'
    }
}