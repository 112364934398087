import { Component, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { PartnerselectedComponent } from '../partnerselected.component';
// import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { Globals } from '../../../../../globals';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import { SemlinkaccessService } from '../../../../../services/ea/api/SemlinkaccessService';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';
import * as moment from 'moment';

@Component({
  selector: 'app-unithistory',
  templateUrl: './unithistory.component.html',
  styleUrls: ['./unithistory.component.css']
})
export class UnithistoryComponent implements OnInit {

  //datePipeString: any;
  dataSource: any = [];
  optNum:any;
  documentId:any //number;
  editPartner: boolean=true;
  hasSecurityCheck: boolean;
  exemptCode: any;
  candidateUnit: number;
  dataSize: number;
  isCandidate: boolean;
  directHire: boolean = false;
  directHireDate: any;
  directHireAmount: any;
  directHireCurr: string;
  loading: boolean = true;
  hasData:boolean = true;
  isError: boolean = false;
  currentDateTime: any;

  constructor( 
    private apiService: ApiService, 
    private router: Router,
    private partnerSelected: PartnerselectedComponent,
    // private datepipe: DatePipe,
    private global: Globals,
    private semLinkAccessService: SemlinkaccessService,
    private access: SemaccessService
    ) { 
      this.currentDateTime = moment().format("MM/DD/YYYY");
    }


  ngOnInit() {
    this.optNum = sessionStorage.getItem('optNum');
    this.documentId = parseInt(sessionStorage.getItem(this.global.documentID) || '{}');
    this.getAccess();
    this.partnerSelected.loadingEdit = true;
  }

    /**********************************************************************************
  * Method Name: getAccess                                                               
  * Description: Retrieves access from a reasuable service       
  *                                                                                          
  * #         Date                 Modified by                      Description     
  * 1        4/28/2020           divy.o.ardiente                   API connection
  ***********************************************************************************/  

  async getAccess() {
    try {
      await this.access.getSemAccess("unithistory").then();
      if (this.access.role != false) {
        this.partnerSelected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerSelected.editHistory(this.documentId, this.optNum);
          this.partnerSelected.partnerSelectedInfo(this.optNum);
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            this.access.role['data'][8][0]['ROLE'] == 2 ? this.router.navigate(['equityadmin/sem/pdsa']) : "";
            this.access.role['data'][8][0]['ROLE'] != 1 ? this.editPartner = false : "";
            await this.access.getDocumentAccess("unithistory");
            if (this.access.document != false) {
              if(this.access.document['data'][0][0]['Access'] == 'E' || this.access.document['data'][0][0]['Access'] == 'e') {
                this.hasSecurityCheck = true;
               } else if(this.access.document['data'][0][0]['Access'] == 'V' || this.access.document['data'][0][0]['Access'] == 'v'){
                this.hasSecurityCheck = false;
               }
               this.getPdsBonus();
               this.getUnitHistory();
            } else {
              this.router.navigate(['equityadmin/eamain'])
            }
          } else {
            this.router.navigate(['equityadmin/eamain'])
          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } else {
        this.router.navigate(['equityadmin/eamain'])
      }

    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccess', 'unithistory');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getObjSize                                                                 
  * Description: Returns the length of an object             
  *                                                                                          
  * #         Date                 Modified by                      Description     
  * 1         4/29/2020           divy.o.ardiente                   Initial draft
  ***********************************************************************************/ 
/* istanbul ignore next */
  getObjSize(obj){  //returns the length of an object -JSE
    try {
      let size = 0, key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      } return this.dataSize = size;
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getObjSize', 'unithistory');
      throw error;
    }

  }
  
    /**********************************************************************************
  * Method Name: getUnitHistory                                                                
  * Description: To retrieve the UnitHistory data      
  *                                                                                          
  * #         Date                 Modified by                      Description     
  * 1        3/27/2020           divy.o.ardiente                   API connection
  * 2        3/30/2020           divy.o.ardiente                   API connection
  ***********************************************************************************/  

  getUnitHistory(){
      let accessKey: any = { 
        "selected_optnum": this.optNum,
        "functionName": "getUnitHist"
      }
      this.apiService.postData(this.global.api_URL_pdsUnit,accessKey).subscribe(data => {
        try{
          if (data) {
            let contentBody = data.body;
            let temp;
            if (contentBody != undefined) {
              /* istanbul ignore next */
              if (contentBody['data'][1] != undefined && contentBody['data'][1][0]['UNIT'] != null) {
                if (contentBody['data'][1][0]['CAREER_LEVEL'] == 2 && this.editPartner == true) {
                  this.isCandidate = true;
                  this.exemptCode = contentBody['data'][1][0]['EXEMPTION_CODE'];
                  this.candidateUnit = contentBody['data'][1][0]['UNIT'];
                } else {
                  this.isCandidate = false;
                }
              } else {
                this.isCandidate = false;
              }
              let size = contentBody['data'][0].length;
              let arr = 0;

              //loop to get the unit history data for the table(min-max fiscal year)
              if (contentBody['data'][0].length == 0) {
                this.hasData = false;
              } else {
                for (let i = size - 1; i >= 0; i--) {
                  if (contentBody['data'] != "") {
                    temp = contentBody['data'][0][i];
                    this.dataSource[arr] = temp;
                    arr++;
                  }
                } this.semLinkAccessService.setUnitHistoryTable(this.dataSource);
              }
              this.semLinkAccessService.setTableData(this.hasData);
            } 
            this.loading = false;
            this.partnerSelected.loadingEdit = false;
            this.global.searchLoading = false;

          }
        } catch (error) {
          this.loading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('getUnitHistory', 'unithistory');
          throw error;
        }
      }, error => {
        
        this.loading = false;
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getUnitHistory', 'unithistory');
        throw error;
      });
  }

 
/**********************************************************************************
    * Method Name: getPdsBonus
    * Description: 
    *
    * #         Date                 Modified by                    Description
    * 1
***********************************************************************************/
  getPdsBonus(){
      let param: any = {
        "selected_optnum": this.optNum,
        "functionName": "getpdsBonus"
      }

      this.apiService.postData(this.global.api_URL_pdsUnit, param).subscribe(data => {
        try{
          if (data) {
            let body = data.body;
            if (body['data'][0] != null && body['data'][0] != undefined) {
              this.directHire = true;
              if (body['data'][0][0][""] == 'Invalid Transaction') {
                this.directHireAmount = "";
                this.directHire = false;
              } else {
                if (body['data'][0][0]['BONUS_AMOUNT'] != null && body['data'][0][0]['BONUS_AMOUNT'] != undefined) {
                  this.directHireDate = body['data'][0][0]['BONUS_DATE'];
                  this.directHireAmount = body['data'][0][0]['BONUS_AMOUNT'];
                  this.directHireCurr = body['data'][1][body['data'][0][0]['BONUS_CURRENCY']]['VALUE'];
                } else {
                  this.directHireDate = "";
                  this.directHireAmount = "";
                  this.directHireCurr = "";
                }
              }
            } else {
              this.directHire = false;
            }
          }
        } catch (error) {
          this.loading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('getPdsBonus', 'unithistory');
          throw error;
        }
      }, error => {
        
        error.error.message += this.apiService.setErrorContents('getPdsBonus', 'unithistory');
        throw error;
      });
    
  }

  /**********************************************************************************
  * Method Name:   printUnitHistoryData                                                                
  * Description: To print Unit History data          
  *                                                                                          
  * #         Date                 Modified by                      Description     
  * 1         4/24/2020           divy.o.ardiente                   Initial draft
  ***********************************************************************************/ 
  /* istanbul ignore next */
  printUnitHistoryData(){
    try {
      let doc = new jsPDF();
      let fullName;
      this.partnerSelected.partnerSelectedMiddleName != null ? fullName = this.partnerSelected.partnerSelectedFirstName + " " + this.partnerSelected.partnerSelectedMiddleName + " " + this.partnerSelected.partnerSelectedLastName : fullName = this.partnerSelected.partnerSelectedFirstName + " " + this.partnerSelected.partnerSelectedLastName;
      let peopleKey = this.partnerSelected.partnerSelectedPeopleKey.toString();
      let localPN = this.partnerSelected.partnerSelectedLocalPN;
      let personnelNum = this.partnerSelected.partnerSelectedPersonnelNum;
      let datehrposition = 60;
      let awardhrposition = 60;
      let offCycleAdjhrposition = 60;
      let juneAdjhrposition = 60;
      let perfRatinghrposition = 60;
      let lorhrposition = 60;
      let baseUnithrposition = 60;
      let perfUnithrposition = 60;
      let transUnithrposition = 60;
      let totalhrposition = 60;
      let passCodehrposition = 60;
      let countryCoeffhrposition = 60;
      let equityCoeffhrposition = 60;
      let cchrposition = 60;
      let coeffhrposition = 60;


      doc.setFontSize(8);
      doc.text(this.currentDateTime, 10, 11);

      doc.setTextColor('#460173');
      doc.setFontSize(10);
      doc.text("Unit History", 10, 19);

      doc.setFillColor('#460173');
      doc.rect(10, 20, 190, 20, "F");

      doc.setFontSize(15);
      doc.setTextColor('#FFFFFF');
      doc.setFont(undefined, 'bold'); 
      if(fullName.length > 20){
        var name = doc.splitTextToSize(fullName, 60);
        doc.text(name, 20, 29);
      }else{
        doc.text(fullName, 20, 32);
      }

      doc.setFontSize(13);

      doc.text('People Key #', 90, 29);
      doc.text(peopleKey, 97, 34);

      doc.text('Local PN', 130, 29);
      doc.text(localPN.toString(), 130, 34);

      doc.text('Personnel #', 160, 29);
      doc.text(personnelNum.toString(), 162, 34);

      if (this.isCandidate == true && this.directHire == true) {
        doc.setFontSize(13);
        doc.setTextColor('#460173');
        doc.setFont(undefined, 'bold'); 

        doc.text('Candidate Unit Information', 10, 50);

        doc.setDrawColor(128, 128, 128);
        doc.line(10, 58, 200, 58);
        doc.setFontSize(11);
        doc.setTextColor('#000000');
        doc.setFont(undefined, 'normal');

        doc.text('Candidate Exemption Code: ', 13, 64);

        doc.setDrawColor(128, 128, 128);
        doc.setTextColor('#000000');
        doc.line(10, 68, 200, 68);

        doc.text('Candidate Units: ', 13, 74);

        if (this.candidateUnit != undefined && this.exemptCode != undefined) {
          doc.text(this.exemptCode, 90, 64);
          doc.text(this.candidateUnit.toString(), 90, 74);
        } else {

          doc.text("", 90, 64)
          doc.text("", 90, 74);
        }

        doc.setFontSize(13);
        doc.setTextColor('#460173');
        doc.setFont(undefined, 'bold'); 

        doc.text('Direct Hire Bonus Information', 10, 91);

        doc.setDrawColor(128, 128, 128);
        doc.line(10, 99, 200, 99);

        doc.setFontSize(11);
        doc.setTextColor('#000000');

        doc.text('Date', 45, 105);
        doc.text('Amount', 95, 105);
        doc.text('Currency', 145, 105);

        if (this.directHireDate != undefined) {
          doc.setFont(undefined, 'normal');
          doc.text(this.directHireDate.toString(), 47, 116, { align: 'center' });
          doc.text(this.directHireAmount.toString(), 102, 116, { align: 'center' });
          doc.text(this.directHireCurr, 153, 116, { align: 'center' });
        }
        doc.setDrawColor(128, 128, 128);
        doc.line(10, 109, 200, 109);


        doc.setFontSize(13);
        doc.setFont(undefined, 'bold'); 
        doc.setTextColor('#460173');

        doc.text('Units Information', 10, 130);

        //For the Table
        doc.setDrawColor(75, 0, 130);
        doc.setLineWidth(1);
        doc.line(10, 136, 200, 136);
        doc.setLineWidth(.5);
        doc.line(10, 149, 200, 149);

        //Column Header    
        doc.setFillColor('#faf9fa');
        doc.rect(10, 136, 190, 13, "F");
        doc.rect(10, 159, 190, 10, "F");
        doc.rect(10, 179, 190, 10, "F");
        doc.rect(10, 199, 190, 10, "F");
        doc.rect(10, 219, 190, 10, "F");
        doc.rect(10, 239, 190, 14, "F");
        doc.rect(10, 268, 190, 10, "F");

        doc.setFontSize(11);
        doc.setFont(undefined, 'bold'); 
        doc.setTextColor('#636c72');

        doc.text('Category', 18, 141);

        doc.setFont(undefined, 'normal');
        doc.setTextColor('#000000');

        doc.text('Award', 14, 156);
        doc.text('Off Cycle Adj.', 14, 166);
        doc.text('June Adj.', 14, 176);
        doc.text('Perf Rating *', 14, 186);
        doc.text('LoR', 14, 196);
        doc.text('Base Units', 14, 206);
        doc.text('Perf Units', 14, 216);
        doc.text('Trans Units', 14, 226);
        doc.text('Total', 14, 236);
        var splitPassCode = doc.splitTextToSize('Pass/Exempt CD', 25);
        doc.text(splitPassCode, 14, 246);
        doc.text('Country Coeff', 14, 261);
        doc.text('Equity Coeff', 14, 273);

        //Display of Data
        for (let item of this.dataSource) {
          doc.setFontSize(11);
          doc.setFont(undefined, 'bold'); 
          doc.setTextColor('#636c72');

          if (item.DATE_RANGE.length == 19) {
            var temp = item.DATE_RANGE;
            var output = [temp.slice(0, temp.length - 10), ' ', temp.slice(temp.length - 10)].join('');
            var splitDateRange = doc.splitTextToSize(output, 20, { align: 'center' });
          } else if (item.DATE_RANGE.length == 18) {
            var temp = item.DATE_RANGE;
            var output = [temp.slice(0, temp.length - 9), ' ', temp.slice(temp.length - 9)].join('');
            var splitDateRange = doc.splitTextToSize(output, 20, { align: 'center' });
          } else {
            var splitDateRange = doc.splitTextToSize(item.DATE_RANGE, 20);
          }
          doc.text(splitDateRange, datehrposition, 141, { align: 'center' });
          datehrposition += 30;

          doc.setFont(undefined, 'normal');
          doc.setTextColor('#000000');

          //To display the unit history table values
          item.AWARD != null && item.AWARD != undefined ? doc.text(item.AWARD.toString(), awardhrposition, 156, { align: 'center' }) : "";
          awardhrposition += 31;

          item.OFF_CYCLE_ADJ != null && item.OFF_CYCLE_ADJ != undefined ? doc.text(item.OFF_CYCLE_ADJ.toString(), offCycleAdjhrposition, 166, { align: 'center' }) : "";
          offCycleAdjhrposition += 31;

          item.JUNE_ADJ != null && item.JUNE_ADJ != undefined ? doc.text(item.JUNE_ADJ.toString(), juneAdjhrposition, 176, { align: 'center' }) : "";
          juneAdjhrposition += 31;

          item.PERF_RATING != null && item.PERF_RATING != undefined ? doc.text(item.PERF_RATING.toString(), perfRatinghrposition, 186, { align: 'center' }) : "";
          perfRatinghrposition += 31;

          item.LOR != null && item.LOR != undefined ? doc.text(item.LOR, lorhrposition, 196, { align: 'center' }) : "";
          lorhrposition += 31;

          item.BASE_UNIT != null && item.BASE_UNIT != undefined ? doc.text(item.BASE_UNIT.toString(), baseUnithrposition, 206, { align: 'center' }) : "";
          baseUnithrposition += 31;

          item.PERF_UNIT != null && item.PERF_UNIT != undefined ? doc.text(item.PERF_UNIT.toString(), perfUnithrposition, 216, { align: 'center' }) : "";
          perfUnithrposition += 31;

          item.TRANSITION_UNIT != null && item.TRANSITION_UNIT != undefined ? doc.text(item.TRANSITION_UNIT.toString(), transUnithrposition, 226, { align: 'center' }) : "";
          transUnithrposition += 31;

          item.TOTAL != null && item.TOTAL != undefined ? doc.text(item.TOTAL.toString(), totalhrposition, 236, { align: 'center' }) : "";
          totalhrposition += 31;

          item.PASS_CODE != null && item.PASS_CODE != undefined ? doc.text(item.PASS_CODE, passCodehrposition, 246, { align: 'center' }) : "";
          passCodehrposition += 31;

          if (item.COEFF_COUNTRY != null && item.COEFF_COUNTRY != undefined) {
            if (item.COEFF_COUNTRY == 'Multiple Coefficients') {
              var cc = [item.COEFF_COUNTRY.slice(0, item.COEFF_COUNTRY.length - 13)];
              var coeff = [item.COEFF_COUNTRY.slice(9, item.COEFF_COUNTRY.length)];
              doc.text(cc, cchrposition, 261, { align: 'center' });
              doc.text(coeff, coeffhrposition, 265, { align: 'center' });
            } else { doc.text(item.COEFF_COUNTRY, countryCoeffhrposition, 261, { align: 'center' }); }
            countryCoeffhrposition += 31;
            cchrposition += 31;
            coeffhrposition += 31;
          } else {
            countryCoeffhrposition += 31;
          }
          item.COEFF_EQUITY != null && item.COEFF_EQUITY != undefined ? doc.text(item.COEFF_EQUITY.toString(), equityCoeffhrposition, 273, { align: 'center' }) : "";
          equityCoeffhrposition += 31;
        }
        doc.text("* Based on prior year contribution rating.", 10, 283);

      } else if (this.isCandidate == true && this.directHire == false) {
        doc.setFontSize(13);
        doc.setTextColor('#460173');
        doc.setFont(undefined, 'bold'); 

        doc.text('Candidate Unit Information', 10, 50);

        doc.setDrawColor(128, 128, 128);
        doc.line(10, 58, 200, 58);
        doc.setFontSize(11);
        doc.setTextColor('#000000');
        doc.setFont(undefined, 'normal');

        doc.text('Candidate Exemption Code: ', 13, 64);

        doc.setDrawColor(128, 128, 128);
        doc.setTextColor('#000000');
        doc.line(10, 68, 200, 68);

        doc.text('Candidate Units: ', 13, 74);

        if (this.candidateUnit != undefined && this.exemptCode != undefined) {
          doc.text(this.exemptCode, 90, 64);
          doc.text(this.candidateUnit.toString(), 90, 74);
        } else {
          doc.text("", 90, 64)
          doc.text("", 90, 74);
        }
        doc.setFontSize(13);
        doc.setFont(undefined, 'bold'); 
        doc.setTextColor('#460173');

        doc.text('Units Information', 10, 95);

        //For the Table
        doc.setDrawColor(75, 0, 130);
        doc.setLineWidth(1);
        doc.line(10, 105, 200, 105);
        doc.setLineWidth(.5);
        doc.line(10, 118, 200, 118);

        //Column Header    
        doc.setFillColor('#faf9fa');
        doc.rect(10, 105, 190, 13, "F");
        doc.rect(10, 128, 190, 10, "F");
        doc.rect(10, 148, 190, 10, "F");
        doc.rect(10, 168, 190, 10, "F");
        doc.rect(10, 188, 190, 10, "F");
        doc.rect(10, 208, 190, 14, "F");
        doc.rect(10, 237, 190, 10, "F");

        doc.setFontSize(11);
        doc.setFont(undefined, 'bold'); 
        doc.setTextColor('#636c72');

        doc.text('Category', 18, 110);

        doc.setFont(undefined, 'normal');
        doc.setTextColor('#000000');

        doc.text('Award', 14, 125);
        doc.text('Off Cycle Adj.', 14, 135);
        doc.text('June Adj.', 14, 145);
        doc.text('Perf Rating *', 14, 155);
        doc.text('LoR', 14, 165);
        doc.text('Base Units', 14, 175);
        doc.text('Perf Units', 14, 185);
        doc.text('Trans Units', 14, 195);
        doc.text('Total', 14, 205);
        var splitPassCode = doc.splitTextToSize('Pass/Exempt CD', 25);
        doc.text(splitPassCode, 14, 215);
        doc.text('Country Coeff', 14, 230);
        doc.text('Equity Coeff', 14, 242);

        //Display of Data
        for (let item of this.dataSource) {
          doc.setFontSize(11);
          doc.setFont(undefined, 'bold'); 
          doc.setTextColor('#636c72');

          if (item.DATE_RANGE.length == 19) {
            var temp = item.DATE_RANGE;
            var output = [temp.slice(0, temp.length - 10), ' ', temp.slice(temp.length - 10)].join('');
            var splitDateRange = doc.splitTextToSize(output, 20, { align: 'center' });
          } else if (item.DATE_RANGE.length == 18) {
            var temp = item.DATE_RANGE;
            var output = [temp.slice(0, temp.length - 9), ' ', temp.slice(temp.length - 9)].join('');
            var splitDateRange = doc.splitTextToSize(output, 20, { align: 'center' });
          } else {
            var splitDateRange = doc.splitTextToSize(item.DATE_RANGE, 20);
          }
          doc.text(splitDateRange, datehrposition, 110, { align: 'center' });
          datehrposition += 30;

          doc.setFont(undefined, 'normal');
          doc.setTextColor('#000000');

          //To display the unit history table values
          item.AWARD != null && item.AWARD != undefined ? doc.text(item.AWARD.toString(), awardhrposition, 125, { align: 'center' }) : "";
          awardhrposition += 31;

          item.OFF_CYCLE_ADJ != null && item.OFF_CYCLE_ADJ != undefined ? doc.text(item.OFF_CYCLE_ADJ.toString(), offCycleAdjhrposition, 135, { align: 'center' }) : "";
          offCycleAdjhrposition += 31;

          item.JUNE_ADJ != null && item.JUNE_ADJ != undefined ? doc.text(item.JUNE_ADJ.toString(), juneAdjhrposition, 145, { align: 'center' }) : "";
          juneAdjhrposition += 31;

          item.PERF_RATING != null && item.PERF_RATING != undefined ? doc.text(item.PERF_RATING.toString(), perfRatinghrposition, 155, { align: 'center' }) : "";
          perfRatinghrposition += 31;

          item.LOR != null && item.LOR != undefined ? doc.text(item.LOR, lorhrposition, 165, { align: 'center' }) : "";
          lorhrposition += 31;

          item.BASE_UNIT != null && item.BASE_UNIT != undefined ? doc.text(item.BASE_UNIT.toString(), baseUnithrposition, 175, { align: 'center' }) : "";
          baseUnithrposition += 31;

          item.PERF_UNIT != null && item.PERF_UNIT != undefined ? doc.text(item.PERF_UNIT.toString(), perfUnithrposition, 185, { align: 'center' }) : "";
          perfUnithrposition += 31;

          item.TRANSITION_UNIT != null && item.TRANSITION_UNIT != undefined ? doc.text(item.TRANSITION_UNIT.toString(), transUnithrposition, 195, { align: 'center' }) : "";
          transUnithrposition += 31;

          item.TOTAL != null && item.TOTAL != undefined ? doc.text(item.TOTAL.toString(), totalhrposition, 205, { align: 'center' }) : "";
          totalhrposition += 31;

          item.PASS_CODE != null && item.PASS_CODE != undefined ? doc.text(item.PASS_CODE, passCodehrposition, 215, { align: 'center' }) : "";
          passCodehrposition += 31;

          if (item.COEFF_COUNTRY != null && item.COEFF_COUNTRY != undefined) {
            if (item.COEFF_COUNTRY == 'Multiple Coefficients') {
              var cc = [item.COEFF_COUNTRY.slice(0, item.COEFF_COUNTRY.length - 13)];
              var coeff = [item.COEFF_COUNTRY.slice(9, item.COEFF_COUNTRY.length)];
              doc.text(cc, cchrposition, 230, { align: 'center' });
              doc.text(coeff, coeffhrposition, 234, { align: 'center' });
            } else { doc.text(item.COEFF_COUNTRY, countryCoeffhrposition, 230, { align: 'center' }); }
            countryCoeffhrposition += 31;
            cchrposition += 31;
            coeffhrposition += 31;
          } else {
            countryCoeffhrposition += 31;
          }
          item.COEFF_EQUITY != null && item.COEFF_EQUITY != undefined ? doc.text(item.COEFF_EQUITY.toString(), equityCoeffhrposition, 242, { align: 'center' }) : "";
          equityCoeffhrposition += 31;
        }
        doc.text("* Based on prior year contribution rating.", 10, 252);

      } else if (this.isCandidate == false && this.directHire == true) {
        doc.setFontSize(13);
        doc.setTextColor('#460173');
        doc.setFont(undefined, 'bold'); 

        doc.text('Direct Hire Bonus Information', 10, 50);

        doc.setDrawColor(128, 128, 128);
        doc.line(10, 60, 200, 60);

        doc.setFontSize(11);
        doc.setTextColor('#000000');

        doc.text('Date', 45, 66);
        doc.text('Amount', 95, 66);
        doc.text('Currency', 145, 66);

        if (this.directHireDate != undefined) {
          doc.setFont(undefined, 'normal');
          doc.text(this.directHireDate.toString(), 47, 77, { align: 'center' });
          doc.text(this.directHireAmount.toString(), 102, 77, { align: 'center' });
          doc.text(this.directHireCurr, 153, 77, { align: 'center' });
        }
        doc.setDrawColor(128, 128, 128);
        doc.line(10, 70, 200, 70);

        doc.setFontSize(13);
        doc.setFont(undefined, 'bold'); 
        doc.setTextColor('#460173');

        doc.text('Units Information', 10, 95);

        //For the Table
        doc.setDrawColor(75, 0, 130);
        doc.setLineWidth(1);
        doc.line(10, 105, 200, 105);
        doc.setLineWidth(.5);
        doc.line(10, 118, 200, 118);

        //Column Header    
        doc.setFillColor('#faf9fa');
        doc.rect(10, 105, 190, 13, "F");
        doc.rect(10, 128, 190, 10, "F");
        doc.rect(10, 148, 190, 10, "F");
        doc.rect(10, 168, 190, 10, "F");
        doc.rect(10, 188, 190, 10, "F");
        doc.rect(10, 208, 190, 14, "F");
        doc.rect(10, 237, 190, 10, "F");

        doc.setFontSize(11);
        doc.setFont(undefined, 'bold'); 
        doc.setTextColor('#636c72');

        doc.text('Category', 18, 110);

        doc.setFont(undefined, 'normal');
        doc.setTextColor('#000000');

        doc.text('Award', 14, 125);
        doc.text('Off Cycle Adj.', 14, 135);
        doc.text('June Adj.', 14, 145);
        doc.text('Perf Rating *', 14, 155);
        doc.text('LoR', 14, 165);
        doc.text('Base Units', 14, 175);
        doc.text('Perf Units', 14, 185);
        doc.text('Trans Units', 14, 195);
        doc.text('Total', 14, 205);
        var splitPassCode = doc.splitTextToSize('Pass/Exempt CD', 25);
        doc.text(splitPassCode, 14, 215);
        doc.text('Country Coeff', 14, 230);
        doc.text('Equity Coeff', 14, 242);

        //Display of Data
        for (let item of this.dataSource) {
          doc.setFontSize(11);
          doc.setFont(undefined, 'bold'); 
          doc.setTextColor('#636c72');

          if (item.DATE_RANGE.length == 19) {
            var temp = item.DATE_RANGE;
            var output = [temp.slice(0, temp.length - 10), ' ', temp.slice(temp.length - 10)].join('');
            var splitDateRange = doc.splitTextToSize(output, 20, { align: 'center' });
          } else if (item.DATE_RANGE.length == 18) {
            var temp = item.DATE_RANGE;
            var output = [temp.slice(0, temp.length - 9), ' ', temp.slice(temp.length - 9)].join('');
            var splitDateRange = doc.splitTextToSize(output, 20, { align: 'center' });
          } else {
            var splitDateRange = doc.splitTextToSize(item.DATE_RANGE, 20);
          }
          doc.text(splitDateRange, datehrposition, 110, { align: 'center' });
          datehrposition += 30;

          doc.setFont(undefined, 'normal');
          doc.setTextColor('#000000');

          //To display the unit history table values
          item.AWARD != null && item.AWARD != undefined ? doc.text(item.AWARD.toString(), awardhrposition, 125, { align: 'center' }) : "";
          awardhrposition += 31;

          item.OFF_CYCLE_ADJ != null && item.OFF_CYCLE_ADJ != undefined ? doc.text(item.OFF_CYCLE_ADJ.toString(), offCycleAdjhrposition, 135, { align: 'center' }) : "";
          offCycleAdjhrposition += 31;

          item.JUNE_ADJ != null && item.JUNE_ADJ != undefined ? doc.text(item.JUNE_ADJ.toString(), juneAdjhrposition, 145, { align: 'center' }) : "";
          juneAdjhrposition += 31;

          item.PERF_RATING != null && item.PERF_RATING != undefined ? doc.text(item.PERF_RATING.toString(), perfRatinghrposition, 155, { align: 'center' }) : "";
          perfRatinghrposition += 31;

          item.LOR != null && item.LOR != undefined ? doc.text(item.LOR, lorhrposition, 165, { align: 'center' }) : "";
          lorhrposition += 31;

          item.BASE_UNIT != null && item.BASE_UNIT != undefined ? doc.text(item.BASE_UNIT.toString(), baseUnithrposition, 175, { align: 'center' }) : "";
          baseUnithrposition += 31;

          item.PERF_UNIT != null && item.PERF_UNIT != undefined ? doc.text(item.PERF_UNIT.toString(), perfUnithrposition, 185, { align: 'center' }) : "";
          perfUnithrposition += 31;

          item.TRANSITION_UNIT != null && item.TRANSITION_UNIT != undefined ? doc.text(item.TRANSITION_UNIT.toString(), transUnithrposition, 195, { align: 'center' }) : "";
          transUnithrposition += 31;

          item.TOTAL != null && item.TOTAL != undefined ? doc.text(item.TOTAL.toString(), totalhrposition, 205, { align: 'center' }) : "";
          totalhrposition += 31;

          item.PASS_CODE != null && item.PASS_CODE != undefined ? doc.text(item.PASS_CODE, passCodehrposition, 215, { align: 'center' }) : "";
          passCodehrposition += 31;

          if (item.COEFF_COUNTRY != null && item.COEFF_COUNTRY != undefined) {
            if (item.COEFF_COUNTRY == 'Multiple Coefficients') {
              var cc = [item.COEFF_COUNTRY.slice(0, item.COEFF_COUNTRY.length - 13)];
              var coeff = [item.COEFF_COUNTRY.slice(9, item.COEFF_COUNTRY.length)];
              doc.text(cc, cchrposition, 230, { align: 'center' });
              doc.text(coeff, coeffhrposition, 234, { align: 'center' });
            } else { doc.text(item.COEFF_COUNTRY, countryCoeffhrposition, 230, { align: 'center' }); }
            countryCoeffhrposition += 31;
            cchrposition += 31;
            coeffhrposition += 31;
          } else {
            countryCoeffhrposition += 31;
          }
          item.COEFF_EQUITY != null && item.COEFF_EQUITY != undefined ? doc.text(item.COEFF_EQUITY.toString(), equityCoeffhrposition, 242, { align: 'center' }) : "";
          equityCoeffhrposition += 31;
        }
        doc.text("* Based on prior year contribution rating.", 10, 252);
      } else {
        doc.setFontSize(13);
        doc.setFont(undefined, 'bold'); 
        doc.setTextColor('#460173');

        doc.text('Units Information', 10, 50);

        //For the Table
        doc.setDrawColor(75, 0, 130);
        doc.setLineWidth(1);
        doc.line(10, 60, 200, 60);
        doc.setLineWidth(.5);
        doc.line(10, 73, 200, 73);

        //Column Header    
        doc.setFillColor('#faf9fa');
        doc.rect(10, 60, 190, 13, "F");
        doc.rect(10, 83, 190, 10, "F");
        doc.rect(10, 103, 190, 10, "F");
        doc.rect(10, 123, 190, 10, "F");
        doc.rect(10, 143, 190, 10, "F");
        doc.rect(10, 163, 190, 14, "F");
        doc.rect(10, 192, 190, 10, "F");

        doc.setFontSize(11);
        doc.setFont(undefined, 'bold'); 
        doc.setTextColor('#636c72');

        doc.text('Category', 18, 65);

        doc.setFont(undefined, 'normal');
        doc.setTextColor('#000000');

        doc.text('Award', 14, 80);
        doc.text('Off Cycle Adj.', 14, 90);
        doc.text('June Adj.', 14, 100);
        doc.text('Perf Rating *', 14, 110);
        doc.text('LoR', 14, 120);
        doc.text('Base Units', 14, 130);
        doc.text('Perf Units', 14, 140);
        doc.text('Trans Units', 14, 150);
        doc.text('Total', 14, 160);
        var splitPassCode = doc.splitTextToSize('Pass/Exempt CD', 25);
        doc.text(splitPassCode, 14, 170);
        doc.text('Country Coeff', 14, 185);
        doc.text('Equity Coeff', 14, 197);

        //Display of Data
        for (let item of this.dataSource) {
          doc.setFontSize(11);
          doc.setFont(undefined, 'bold'); 
          doc.setTextColor('#636c72');

          if (item.DATE_RANGE.length == 19) {
            var temp = item.DATE_RANGE;
            var output = [temp.slice(0, temp.length - 10), ' ', temp.slice(temp.length - 10)].join('');
            var splitDateRange = doc.splitTextToSize(output, 20, { align: 'center' });
          } else if (item.DATE_RANGE.length == 18) {
            var temp = item.DATE_RANGE;
            var output = [temp.slice(0, temp.length - 9), ' ', temp.slice(temp.length - 9)].join('');
            var splitDateRange = doc.splitTextToSize(output, 20, { align: 'center' });
          } else {
            var splitDateRange = doc.splitTextToSize(item.DATE_RANGE, 20);
          }
          doc.text(splitDateRange, datehrposition, 65, { align:'center' });
          datehrposition += 30;

          doc.setFont(undefined, 'normal');
          doc.setTextColor('#000000');

          //To display the unit history table values
          item.AWARD != null && item.AWARD != undefined ? doc.text(item.AWARD.toString(), awardhrposition, 80, { align: 'center' }) : "";
          awardhrposition += 31;

          item.OFF_CYCLE_ADJ != null && item.OFF_CYCLE_ADJ != undefined ? doc.text(item.OFF_CYCLE_ADJ.toString(), offCycleAdjhrposition, 90, { align: 'center' }) : "";
          offCycleAdjhrposition += 31;

          item.JUNE_ADJ != null && item.JUNE_ADJ != undefined ? doc.text(item.JUNE_ADJ.toString(), juneAdjhrposition, 100, { align: 'center' }) : "";
          juneAdjhrposition += 31;

          item.PERF_RATING != null && item.PERF_RATING != undefined ? doc.text(item.PERF_RATING.toString(), perfRatinghrposition, 110, { align: 'center' }) : "";
          perfRatinghrposition += 31;

          item.LOR != null && item.LOR != undefined ? doc.text(item.LOR, lorhrposition, 120, { align: 'center' }) : "";
          lorhrposition += 31;

          item.BASE_UNIT != null && item.BASE_UNIT != undefined ? doc.text(item.BASE_UNIT.toString(), baseUnithrposition, 130, { align: 'center' }) : "";
          baseUnithrposition += 31;

          item.PERF_UNIT != null && item.PERF_UNIT != undefined ? doc.text(item.PERF_UNIT.toString(), perfUnithrposition, 140, { align: 'center' }) : "";
          perfUnithrposition += 31;

          item.TRANSITION_UNIT != null && item.TRANSITION_UNIT != undefined ? doc.text(item.TRANSITION_UNIT.toString(), transUnithrposition, 150, { align: 'center' }) : "";
          transUnithrposition += 31;

          item.TOTAL != null && item.TOTAL != undefined ? doc.text(item.TOTAL.toString(), totalhrposition, 160, { align: 'center' }) : "";
          totalhrposition += 31;

          item.PASS_CODE != null && item.PASS_CODE != undefined ? doc.text(item.PASS_CODE, passCodehrposition, 170, { align: 'center' }) : "";
          passCodehrposition += 31;

          if (item.COEFF_COUNTRY != null && item.COEFF_COUNTRY != undefined) {
            if (item.COEFF_COUNTRY == 'Multiple Coefficients') {
              var cc = [item.COEFF_COUNTRY.slice(0, item.COEFF_COUNTRY.length - 13)];
              var coeff = [item.COEFF_COUNTRY.slice(9, item.COEFF_COUNTRY.length)];
              doc.text(cc, cchrposition, 185, { align: 'center' });
              doc.text(coeff, coeffhrposition, 189, { align: 'center' });
            } else { doc.text(item.COEFF_COUNTRY, countryCoeffhrposition, 185, { align: 'center' }); }
            countryCoeffhrposition += 31;
            cchrposition += 31;
            coeffhrposition += 31;
          } else {
            countryCoeffhrposition += 31;
          }
          item.COEFF_EQUITY != null && item.COEFF_EQUITY != undefined ? doc.text(item.COEFF_EQUITY.toString(), equityCoeffhrposition, 197, { align: 'center' }) : "";
          equityCoeffhrposition += 31;
        }
        doc.text("* Based on prior year contribution rating.", 10, 207);
      }
      doc.text('Highly Confidential-Internal Use', 80, 290);
      doc.autoPrint({ variant: 'non-conform' });
      doc.save(fullName + '(Unit History).pdf');
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('printUnitHistoryData', 'unithistory');
      throw error;
    }
    
  }

  disableInput(event: KeyboardEvent){
    event.preventDefault();
  }
}
