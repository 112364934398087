import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Globals } from '../globals';
import { DatePipe } from "@angular/common";
import { AppComponent } from '../app.component';


import { UserService } from '../services/UserService';
import { HighlightsService } from '../highlightspage/HighlightsService';
// import { AuthService } from '../services/auth/auth.service';
import { UserInfoDetailsResolve } from '../services/UserInfoDetails.resolve';
// import { MAT_SORT_HEADER_INTL_PROVIDER_FACTORY } from '@angular/material';
import { MarketQuoteService } from '../services/MarketQuoteService';
import { marketQuoteModel } from '../services/models/marketQuoteModel';
import { HoldingsSummaryService } from '../holdingsummarypage/HoldingSummaryService';
import { KeyexecService } from './keyexec.service';
import { BrokerType, GrantGroup, RedemptionMethod, GrantTransactionType, CurrencySymbol } from '../services/enumerations/enumerations';
// import { log } from 'util';
import * as $ from 'jquery';
// import { stringify } from '@angular/core/src/render3/util';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-keyexec',
  templateUrl: './keyexec.component.html',
  styleUrls: ['./keyexec.component.css'],
  providers: [MarketQuoteService, HighlightsService, Globals]
})
export class KeyexecComponent implements OnInit {

  user: any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  optNum: number;
  careerLevel: any;
  summaryAsOf: any;
  awardYear: any;
  targetPerf: any;
  resultPerf; any;
  targetRSUs1: any;
  restultRSUs1: any;
  targetRSUs2: any;
  restultRSUs2: any;
  targetPercentile: any;
  resultPercentile: any;
  targetTotalRSUs: any;
  resultTotalRSUs: any;
  approximatePercOfTarget: any;
  isSMD: any;
  summAsOf: any;
  rsuAwards: any;
  keyExecData: any;
  loading: boolean = true;
  errorLocation: string = " + [Component: Keyexecpage, Method: methodName]";
  errorKeyExecPage: boolean;
  keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));
  UBSlink: any;
  MSlink: any;
  envSetting: any;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));


  constructor(private sanitizer:DomSanitizer, private app:AppComponent, private marketQuoteService: MarketQuoteService, private route: ActivatedRoute, private router: Router, private userService: UserService, private highlightsService: HighlightsService, private globals: Globals, private userResolve: UserInfoDetailsResolve, private holdingsummaryservice: HoldingsSummaryService, private elRef:ElementRef, private keyexecservice: KeyexecService, private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {

    let hasKeyExecChecker = sessionStorage.getItem("hasKeyExec");
    if(hasKeyExecChecker != "1") this.router.navigate(['homepage']);

    this.checkErrorMessage();
    this.getAPI();
    this.brokersSSO();
    sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest));
    sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Homepage"));
  }

  navToPage(strLoc){
    try
    {
      this.router.navigate([strLoc]);
    }
    catch(error) 
    {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
  if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
    let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

    if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
    }else{
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
    }
  }else{
    if(this.bodyData != null){
      if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }
  }
}

  async getAPI(){
    try
    {
      this.errorKeyExecPage = false;
      this.keyexecservice.getKeyExecSummary(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try
        {          
          this.errorKeyExecPage = false;
          this.keyExecData = data[0];
  
          sessionStorage.setItem('Get API', JSON.stringify(this.keyExecData));
          this.summAsOf = this.keyExecData[0].SummaryAsOf;
          this.getSMD();
        }
        catch(error) 
        {
          this.errorKeyExecPage = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getAPI");
          error.message = error.message + errorLocation;
          throw error;
        }
      },
      error => 
      {
        this.errorKeyExecPage = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getAPI");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
    catch(error) 
    {
      this.errorKeyExecPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getAPI");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  async getSMD(){
    try
    {
      this.errorKeyExecPage = false;
      let counter = 0;

      for(let i = 0; i<this.keyExecData.length; i++)
      {
        if(this.keyExecData[i].isSMD == 1)
        {
          counter += 1;
          if(counter == this.keyExecData.length)
          {
            this.isSMD = "SMD Key Exec Performance RSUs - Company Performance Summary to Date";
          }
          else{
            this.isSMD = "Key Exec Performance RSUs - Company Performance Summary to Date";
          }
        }
      }

      this.loading = false;
  }
  catch(error) 
  {
    this.errorKeyExecPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getSMD");
    error.message = error.message + errorLocation;
    throw error;
  }
}

  commafy(num: any, value?: any) {
    try
    {
      if(num != 0.00 || num != 0) {
        if(value == 1) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: 2
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value == 1) {
          return '0.00';
        }
        return 0;
      }
    }
    catch(error) 
  {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message = error.message + errorLocation;
    throw error;
  }
}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Keyexecpage") != -1 && this.user != null) {
                webPage = "Keyexecpage";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

}


