
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { PageSection } from '../../services/enumerations/enumerations';
import { StaticContentService } from '../../services/StaticContentService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-equitycommunications',
  templateUrl: './equitycommunications.component.html',
  styleUrls: ['./equitycommunications.component.css']
})
export class EquitycommunicationsComponent implements OnInit {

  equityComsSrExecTradingMemo: object;
  equityComsVeip: object;
  equityComsEspp: object;
  equityComsAccDivMemo: object;
  equityComsLastyear: object;
  equityComsPrevYear: object;
  displaySrExecMemo: boolean = false;
  displayEqComsVeip: boolean = false;
  displayEqComsEspp: boolean = false;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  errorLocation: string = " + [Component: Resourcespage, Method: methodName]";
  loading: boolean = true;
  errorEquityCommunications: boolean;

  constructor(private staticContent: StaticContentService, private router: Router) { }

  ngOnInit() {
    this.getEquityCommunications();
  }

  getEquityCommunications(){
    try {
      this.loading = true;
      let empType = this.bodyData[2];
  
      if(empType == "Senior Executive"){
        this.displaySrExecMemo = true;
        this.displayEqComsVeip = true;
        this.displayEqComsEspp = false;
        this.getSrExecTrading();
        this.getEqComsVeip();
        // this.getAccDivMemo();
        // this.getEqComsLastYear(); remove with archive panel
      }else if(empType == "Employees"){
        this.displayEqComsEspp = true;
        this.displaySrExecMemo = false;
        this.displayEqComsVeip = false;
        this.getEqComsEspp();
        // this.getAccDivMemo();
        // this.getEqComsLastYear(); remove with archive panel
      } 
        // this.getAccDivMemo();
  
        // this.resourceLoading = true;
        // setTimeout(()=>{ this.resourceLoading = false }, 300);
    } catch(error) {
      this.errorEquityCommunications = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getEquityCommunications");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getSrExecTrading(){
    try {
      this.bodyData[5] = PageSection.equityCommunicationsSrExecTrading;
      this.bodyData[6] = 0;
    
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);    
  
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
    
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
      
            if(contentBody.data.length > 0){
              this.equityComsSrExecTradingMemo = contentBody.data;  
            }
            else{
              this.equityComsSrExecTradingMemo = null;
            }
          }
        } catch(error) {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getSrExecTrading");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorEquityCommunications = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getSrExecTrading");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorEquityCommunications = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getSrExecTrading");
      error.message += errorLocation;
      throw error;
    }
  }

  getEqComsVeip(){
    try {
      this.bodyData[5] = PageSection.equityCommunicationsVeip;
      this.bodyData[6] = 0;
    
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);   
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
    
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
      
            if(contentBody.data.length > 0){
              this.equityComsVeip = contentBody.data;        
            }
            else{
              this.equityComsVeip = null;
            }
          }
          this.getAccDivMemo();
        } catch(error) {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEqComsVeip");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorEquityCommunications = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getEqComsVeip");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorEquityCommunications = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getEqComsVeip");
      error.message += errorLocation;
      throw error;
    }
  }

  getAccDivMemo(){
    try {
      this.bodyData[5] = PageSection.equityCommunicationsAccentureDividendMemos;
      this.bodyData[6] = 0;
    
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
    
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
      
            if(contentBody.data.length > 0){
              this.equityComsAccDivMemo = contentBody.data;        
            }
            else{
              this.equityComsAccDivMemo = null;
            }
          }
          this.loading = false;
        } catch(error) {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getAccDivMemo");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorEquityCommunications = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getAccDivMemo");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorEquityCommunications = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getAccDivMemo");
      error.message += errorLocation;
      throw error;
    }
  }

  
getEqComsEspp(){
  try {
    this.bodyData[5] = PageSection.equityCommunicationsEspp;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
      try {
        let content = JSON.parse(data._body); 
        
        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if(content.body.length > 2){
          let contentBody = JSON.parse(content.body);
    
          if(contentBody.data.length > 0){
            this.equityComsEspp = contentBody.data;  
          }
          else{
            this.equityComsEspp = null;
          }
        }        
        this.getAccDivMemo();
      } catch(error) {
        this.errorEquityCommunications = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getEqComsEspp");
        error.message += errorLocation;
        throw error;
      }
         }, error => {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEqComsEspp");
          error.error.message += errorLocation;
          throw error.error;
         });
  } catch(error) {
    this.errorEquityCommunications = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getEqComsEspp");
    error.message += errorLocation;
    throw error;
  }
}

getEqComsLastYear(){
  try {
    this.bodyData[5] = PageSection.equityCommunicationsLastYear;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
      try {
        let content = JSON.parse(data._body); 
        
        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if(content.body.length > 2){
          let contentBody = JSON.parse(content.body);
    
          if(contentBody.data.length > 0){
            this.equityComsLastyear = contentBody.data;        
          }
          else{
            this.equityComsLastyear = null;
          }
        }
        
      this.getEqComsPrevYear();
      } catch(error) {
        this.errorEquityCommunications = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getEqComsLastYear");
        error.message += errorLocation;
        throw error;
      }
         }, error => {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEqComsLastYear");
          error.error.message += errorLocation;
          throw error.error;
         });
  } catch(error) {
    this.errorEquityCommunications = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getEqComsLastYear");
    error.message += errorLocation;
    throw error;
  }
}

getEqComsPrevYear(){
  try {
    this.bodyData[5] = PageSection.equityCommunicationsPreviousYear;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
      try {
        let content = JSON.parse(data._body); 
        
        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if(content.body.length > 2){
          let contentBody = JSON.parse(content.body);
    
          if(contentBody.data.length > 0){
            this.equityComsPrevYear = contentBody.data;        
          }
          else{
            this.equityComsPrevYear = null;
          }
        }

        this.loading = false;
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getEqComsPrevYear");
        error.message += errorLocation;
        throw error;
      }
         }, error => {
          let errorLocation = this.errorLocation.replace("methodName", "getEqComsPrevYear");
          error.error.message += errorLocation;
          throw error.error;
         });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getEqComsPrevYear");
    error.message += errorLocation;
    throw error;
  }
}

getFileObject(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
  
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
  
      fileName = file;
    }
  
    this.staticContent.getFile(fileName, "MD").subscribe(data => { // MD for contacts page
      try {
        let content = JSON.parse(data._body);
        let file = JSON.parse(content.body);
        
        this.staticContent.downloadFile(fileName, fileType, this.staticContent.base64ToArrayBuffer(file.file));
        this.staticContent.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
          let writeLogResponse = data;
        });
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFileObject");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFileObject");
      error.error.message += errorLocation;
      throw error.error;
    }); 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFileObject");
    error.message += errorLocation;
    throw error;
  }
 }

 normalizeFiletype(orig){
  let transformed = orig.toLowerCase();
  if (transformed.charAt(transformed.length - 1) == "x" && transformed != "docx") {
    transformed = transformed.slice(0, -1);      
  }else if (transformed == "doc") {
    transformed = transformed.concat("x")
  }
  return transformed;
}

}
