import { Component, OnInit, Output, EventEmitter, Input, Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements OnInit {

  @Input('setDate') setDate: string;
  @Input('billToDate') billToDate: string;
  @Input('invoiceDescData') invoiceDescData: string;
  @Input('compCodeData') compCodeData: string;
  @Input('dateVal') dateVal: string;
  @Output() date: EventEmitter<any> = new EventEmitter<any>();

  displayClearBtn: boolean = false;
  dateValue: Date = new Date();
  minDate: Date;
  maxDate: Date;
  eaUserAgent: any = navigator.userAgent.toLowerCase();
  eaAppVersion: any = navigator.appVersion.toString().toLowerCase();
  chromeIE: boolean = false;
  toClearDate: boolean = true;

  constructor() { }

  ngOnInit() {
    this.minDate = new Date(1900, 0, 1);
    this.maxDate = new Date(2999, 11, 31);
    this.checkBrowser();
  }

  ngOnChanges() {
    this.setDateValue();
  }

  /**********************************************************************************
  * Method Name: checkBrowser                                                                 
  * Description: Checks the browser used to set the top margin of clear icon.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         09/10/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  checkBrowser(): void {
    if(this.eaUserAgent.indexOf('chrome') >= 0 ||
      this.eaAppVersion.indexOf('.net') >= 0 ) {
        this.chromeIE = true;
    }
  }

  /**********************************************************************************
  * Method Name: setDateValue                                                                 
  * Description: Sets the date value.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/05/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setDateValue(): void {
    if(this.setDate != null) {
      if(this.setDate.includes('Import Invoice')) {
        this.dateValue = new Date();
      } else if(this.setDate.includes('Bill To')) {
        this.displayClearBtn = true;
        if(this.billToDate != null) {
          this.dateValue = new Date(this.billToDate);
        } else {
          this.dateValue = null;
        }
      }else if(this.setDate == "Invoice Desc"){
        this.dateValue = this.invoiceDescData != null ? new Date(this.invoiceDescData) : new Date('');
      }else if(this.setDate == "Comp Code"){
        this.dateValue = this.compCodeData != null ? new Date(this.compCodeData) : new Date('');
      }else if(this.setDate == "Account Number"){
        this.dateValue = this.dateVal != null ? new Date(this.dateVal) : new Date('');
      }
    }
  }

  /**********************************************************************************
  * Method Name: getDate                                                                 
  * Description: Gets the date.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/05/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getDate(value: string) {
    this.date.emit(value);
  }

  /**********************************************************************************
  * Method Name: clearDate                                                                 
  * Description: Clears the selected date.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/01/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  clearDate(event: any) {
    event.stopPropagation();

    if(event.type == "keydown") {
      if(event.key != "Enter") {
        this.toClearDate = false;
      }
    }

    if(this.toClearDate) {
      this.dateValue = null;
      this.date.emit(null);
    }
  }

}

export const EA_DATE_FORMAT = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'eaInput',
    monthYearLabel: 'inputMonth',
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  },
};

@Directive({
  selector: '[eaDateFormat]',
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: EA_DATE_FORMAT},
  ],
})
export class CustomEaDateFormat {
}
