
import {throwError as observableThrowError,  BehaviorSubject, Observable } from 'rxjs';

import {refCount, publish,  retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
// import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ErrorNotificationService {
  headers: Headers;
  // options: RequestOptions;
  token: string = sessionStorage.getItem("msal.idtoken");

  private _notification: BehaviorSubject<String[]> = new BehaviorSubject(null);
  readonly notification$: Observable<String[]> = this._notification.asObservable().pipe(publish(),refCount(),);
  private _errors: string[] = [];

  constructor(private globals: Globals, private httpClient: HttpClient) {}

  /**********************************************************************************
   * Method Name: notifyError                                                                 
   * Description: Notifies the error encountered in the application                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft          
   *********************************************************************************/
  notifyError(errorMessage) {
    this._errors.push(errorMessage);
    this._notification.next(this._errors);
  }

  /**********************************************************************************
   * Method Name: refreshErrorList                                                                 
   * Description: Clear the logged errors                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 02/04/19             Dalemark P. Suquib          Initial draft          
   **********************************************************************************/
  refreshErrorList() {
    this._errors = [];
  }

  /**********************************************************************************
   * Method Name: logError                                                                 
   * Description: Logs the error                                
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 05/10/19             Dalemark P. Suquib          Initial draft          
   **********************************************************************************/
  logError(errorData) {
    let apiURL = this.globals.currentAPIUrl;
    let logErrorPath = this.globals.logErrorAPI;

    logErrorPath = logErrorPath.replace("[param1]", errorData[0]);
    logErrorPath = logErrorPath.replace("[param2]", errorData[1]);

    if(errorData[2].indexOf("'") != -1) { // single quote causes an error in BE
      errorData[2] = errorData[2].replace(/'/g, "\"");
    }

    if(errorData[2].indexOf('"') != -1) { // double quote causes an error in BE
      errorData[2] = errorData[2].replace(/"/g, "");
    }

    logErrorPath = logErrorPath.replace("[param3]", errorData[2]);

    try {
      const errorApi = apiURL + logErrorPath;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});
      let body = JSON.stringify({ errorData })

      return this.httpClient.put(errorApi, body).pipe(retry(3), catchError((res: Response) => this.onError(res)));
    }catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
    };

    return observableThrowError(error);
  } 
}