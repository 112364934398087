import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
// import { DatePipe } from '@angular/common';
import { PartnerselectedComponent } from '../partnerselected.component';
import { SemlinkaccessService } from '../../../../../services/ea/api/SemlinkaccessService';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';
import { Globals } from '../../../../../globals';
import * as moment from 'moment';

@Component({
  selector: 'app-personaldata',
  templateUrl: './personaldata.component.html',
  styleUrls: ['./personaldata.component.css']
})
export class PersonaldataComponent implements OnInit {
  optNum: any;

  partnerStatus: string = "1";
  partnerType: string = "3";
  countryType: number = 14;
  documentId:number= 1;

  directHireCheckbox: boolean = false;
  checkBox: any;
  partnerTypeDropdownList: any;
  partnerStatusDropdownList: any;
  citizenshipDropdownList: any;
  personalDataFirstName: any;
  personalDataMiddleName: any;
  personalDataLastName: any;
  personalDataSuffix: any;
  personalDataBirthDate: any;
  personalDataDisableDate: any;
  personalDataStartDate: any;
  personalDataLeaveDate: any;
  personalDataManagerDate: any;
  personalDataDeathDate: any;
  personalDataAssociatePartnerDate: any;
  personalDataFixedPayDate: any;
  personalDataAdmitDate: any;
  personalDataCashOutDate: any;
  personalDataDirectHire: number = 0;
  personalDataDirectHireToggle: Boolean = false;
  personalDataLocalPN: any;
  personalDataSex: any;
  personalDataWorldWideID: any;
  personalDataPartnerType: any;
  personalDataSSN1: any;
  personalDataSSN2: any;
  personalDataSSN3: any;
  personalDataFAMILIAR_NAME: any;
  personalDataPartnerStatus: any;
  personalDataCitezenship: any;
  personalDataEnterpriseID: any;
  personalDataInternetMail: any;
  personalDataBirthPlace: any;
  personalDataMailingName: any;
  personalDataLevelType: any;
  citizenshipList: any;
  editPartner: boolean = true;
  hasSecurityCheck: any;
  personalDataEditor: any;
  personalDataEditDate: any;
  editDate: any;
  isCandidate: boolean = true;
  // datePipeString: any;
  directHiretext: any;
  loading: boolean = true;
  isError:boolean = false;
  currentDateTime: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private global: Globals,
    private semLinkAccessService: SemlinkaccessService,
    private partnerSelected: PartnerselectedComponent,
    // private datePipe: DatePipe,
    private access: SemaccessService

  ) {
    this.currentDateTime = moment().format("MM/DD/YYYY");
    // this.datePipeString = datePipe.transform(Date.now(), 'MM/dd/yyy');
  }

  ngOnInit() {
    this.optNum = parseInt(sessionStorage.getItem("optNum"));
    this.getAccess();
    this.partnerSelected.loadingEdit = true;
    }

    /**********************************************************************************
  * Method Name: getAccess                                                                 
  * Description: Retrieves access from a reasuable service 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         5/19/2020            Jikho Enrique                    Initial draft 
  ***********************************************************************************/
  async getAccess() {
    try {
      await this.access.getSemAccess("personaldata").then();
      if (this.access.role != false) {
        this.partnerSelected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerSelected.editHistory(1, this.optNum);
          this.partnerSelected.partnerSelectedInfo(this.optNum);          
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            this.access.role['data'][8][0]['ROLE'] != 1 ? this.editPartner = false : "";
            await this.access.getDocumentAccess("personaldata");
            if (this.access.document != false) {
              if (this.access.document['data'][0][0]['Access'] == 'E' || this.access.document['data'][0][0]['Access'] == 'e') {
                this.hasSecurityCheck = true;
              } else if (this.access.document['data'][0][0]['Access'] == 'V' || this.access.document['data'][0][0]['Access'] == 'v') {
                this.hasSecurityCheck = false;
              }
                this.personalDataInfo();
            } else {
              this.router.navigate(['equityadmin/eamain']);
            }
          } else {
            this.router.navigate(['equityadmin/eamain'])
          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } else {
        this.router.navigate(['equityadmin/eamain']);
      }
    } catch (error:any) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccess', 'personaldata');
      throw error;
    }
  }

 /**********************************************************************************
  * Method Name: personalDataInfo
  * Description: For serach filter
  *
  * #         Date                 Modified by                      Description
  * 1         03/16/2020           Almerie T. Dondonay             API connection
  * 2         05/07/2020           Almerie T. Dondonay            Change hardcoded URL into Reusable variable.
  ***********************************************************************************/
 personalDataInfo() {
     let argPersonalData: any = {
       "selected_optnum": this.optNum,
       "partnerstatus": this.partnerStatus,
       "partnertype": this.partnerType,
       "functionName": "getPersonalData"
     }
     this.apiService.postData(this.global.api_URL_pds, argPersonalData).subscribe(data => {
      try{
        if (data) {
          let contentBody = data.body;
          contentBody == undefined ? contentBody = "" : "";

          for (let key in contentBody['data'][0][0]) {
            if (contentBody['data'][0][0].hasOwnProperty(key)) {
              if (contentBody['data'][0][0][key] == null || contentBody['data'][0][0][key] == undefined) {
                contentBody['data'][0][0][key] = '';
              }
            }
          }

          this.personalDataFirstName = contentBody['data'][0][0]['FIRST_NAME'];
          this.personalDataMiddleName = contentBody['data'][0][0]['MIDDLE_NAME'];
          this.personalDataLastName = contentBody['data'][0][0]['LAST_NAME'];
          this.personalDataSuffix = contentBody['data'][0][0]['SUFFIX'];
          this.personalDataBirthDate = contentBody['data'][0][0]['BIRTH_DATE'];
          this.personalDataDisableDate = contentBody['data'][0][0]['DISABLED_DATE'];
          this.personalDataStartDate = contentBody['data'][0][0]['START_DATE'];
          this.personalDataLeaveDate = contentBody['data'][0][0]['LEAVE_DATE'];
          this.personalDataManagerDate = contentBody['data'][0][0]['MANAGER_DATE'];
          this.personalDataDeathDate = contentBody['data'][0][0]['DEATH_DATE'];
          this.personalDataAssociatePartnerDate = contentBody['data'][0][0]['AP_NP_DATE'];
          this.personalDataFixedPayDate = contentBody['data'][0][0]['FIXED_PAY_DATE'];
          this.personalDataAdmitDate = contentBody['data'][0][0]['ADMIT_DATE'];
          this.personalDataCashOutDate = contentBody['data'][0][0]['CASH_OUT_DATE'];
          this.personalDataDirectHire = contentBody['data'][0][0]['Direct_Hire'];
          this.personalDataLocalPN = contentBody['data'][0][0]['EMPLID'];
          this.personalDataSex = contentBody['data'][0][0]['GENDER'][0]==undefined? '':contentBody['data'][0][0]['GENDER'][0] ;
          this.personalDataWorldWideID = contentBody['data'][0][0]['WWID'];
          this.personalDataPartnerType = contentBody['data'][0][0]['PartnerType'];
          this.personalDataSSN1 = contentBody['data'][0][0]['SSN1'];
          this.personalDataSSN2 = contentBody['data'][0][0]['SSN2'];
          this.personalDataSSN3 = contentBody['data'][0][0]['SSN3'];
          this.personalDataFAMILIAR_NAME = contentBody['data'][0][0]['FAMILIAR_NAME'];
          this.personalDataPartnerStatus = contentBody['data'][0][0]['PartnerStatus'];
          if (contentBody['data'][1] != null && contentBody['data'][1] != undefined && contentBody['data'][1] != 0) {
            this.personalDataCitezenship = contentBody['data'][1][0]['Country_Name'];
          } else {
            this.personalDataCitezenship = "";
          }

          this.personalDataEnterpriseID = contentBody['data'][0][0]['ENTERPRISEID'];
          this.personalDataInternetMail = contentBody['data'][0][0]['INTERNET_EMAIL'];
          this.personalDataBirthPlace = contentBody['data'][0][0]['PLACE_OF_BIRTH'];
          this.personalDataMailingName = contentBody['data'][0][0]['MAIL_ADDRESS_NAME'];
          this.personalDataLevelType = contentBody['data'][0][0]['CAREER_LEVEL_TYPE_ID'];
          if (contentBody != undefined) {
            if (contentBody['data'][0][0]['CAREER_LEVEL_TYPE_ID'] == 1) {
              this.isCandidate = true;
            } else if (contentBody['data'][0][0]['CAREER_LEVEL_TYPE_ID'] == 2) {
              this.isCandidate = false;
            }

          }

          this.directHire();
          this.partnerSelected.loading = false;
          this.loading = false;
          this.partnerSelected.loadingEdit = false;
        }
      } catch (error:any) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('personalDataInfo', 'personaldata');
        throw error;
      }
     }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('personalDataInfo', 'personaldata');
      throw error;
     });

  }
    /**********************************************************************************
    * Method Name:    directHire
    * Description: Direct hire display
    *
    * #         Date                 Modified by                    Description
    * 1         04/06/2020           Almerie T. Dondonay            Initial draft
    ***********************************************************************************/
  directHire() {
    try {
      if (this.personalDataDirectHire == 0) {
        this.directHireCheckbox = false;
        this.directHiretext = "No";
      } else {
        this.directHireCheckbox = true;
        this.directHiretext = "Yes";
      }
    } catch (error:any) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('directHire', 'personaldata');
      throw error;
    }

  }

    /**********************************************************************************
    * Method Name: printPersonalData
    * Description: print Functionality
    *
    * #         Date                 Modified by                    Description
    * 1         04/14/2020           Almerie T. Dondonay            Initial draft
    ***********************************************************************************/
  getObjSize(obj: { hasOwnProperty: (arg0: string) => any; }){  //returns the length of an object -JSE
    try {
      let size = 0, key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    } catch (error:any) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getObjSize', 'personaldata'); 
      throw error;
    }
    
  }
  /* istanbul ignore next */
  printPersonalData(){
    try{
      let doc = new jsPDF();
    let fullName = this.personalDataFirstName+ ' ' + this.personalDataMiddleName+ ' ' + this.personalDataLastName + ' ' + this.personalDataSuffix;
    let ssn = this.personalDataSSN1 + "-" + this.personalDataSSN2 + "-" + this.personalDataSSN3;
   

    doc.setFontSize(10);
    doc.text(this.currentDateTime, 10, 11);

    doc.setTextColor('#460173');
    doc.setFontSize(10);
    doc.text("Personal Data", 10, 19);

    doc.setFillColor('#460173');
    doc.rect(10, 20, 190, 20, "F");

    doc.setFontSize(15);
    doc.setTextColor('#FFFFFF');
    doc.setFont('bold');
    
    if(fullName.length > 20){
      var name = doc.splitTextToSize(fullName, 60);
      doc.text(name, 20, 29);
    }else{
      doc.text(fullName, 20, 32);
    }

    doc.setFontSize(13);

    doc.text('People Key #', 90, 29);
    doc.text(this.semLinkAccessService.getPeopleKey().toString(), 97, 34);

    doc.text('Local PN', 130, 29);
    doc.text(this.personalDataLocalPN, 130, 34);

    doc.text('Personnel #', 160, 29);
    doc.text(this.personalDataWorldWideID, 162, 34);

    doc.setFontSize(11);
    doc.setTextColor('#000000');

    doc.text('First Name', 45, 45);
    doc.text('Middle Name', 95, 45);
    doc.text('Last Name', 145, 45);
    doc.text('Suffix', 185, 45);
    doc.text('Formal Name', 10, 53);

    doc.setFont('normal');

    doc.text(this.personalDataFirstName, 45, 53);
    doc.text(this.personalDataMiddleName, 95, 53);
    doc.text(this.personalDataLastName, 145, 53);
    doc.text(this.personalDataSuffix, 185, 53);

    doc.setFont('bold');
    doc.text('Birth Date', 10, 63);
    doc.setFont('normal');
    doc.text(this.personalDataBirthDate, 60, 63);
    //***doc.setFont('bold');
    doc.text('Disabled Date', 110, 63);
    doc.setFont('normal');
    doc.text(this.personalDataDisableDate, 145, 63);


    doc.setFont('bold');
    doc.text('Start  Date', 10, 70);
    doc.setFont('normal');
    doc.text(this.personalDataStartDate, 60, 70);
    doc.setFont('bold');
    doc.text('Leave Date', 110, 70);
    doc.setFont('normal');
    doc.text(this.personalDataLeaveDate, 145, 70);

    doc.setFont('bold');
    doc.text('Manager Date', 10, 77);
    doc.setFont('normal');
    doc.text(this.personalDataManagerDate, 60, 77);
    doc.setFont('bold');
    doc.text('Date of Death', 110, 77);
    doc.setFont('normal');
    doc.text(this.personalDataDeathDate, 145, 77);

    doc.setFont('bold');
    doc.text('Associate Partner Date', 10, 84);
    doc.setFont('normal');
    doc.text(this.personalDataAssociatePartnerDate, 60, 84);
    doc.setFont('bold');
    doc.text('Fixed Pay Date', 110, 84);
    doc.setFont('normal');
    doc.text(this.personalDataFixedPayDate, 145, 84);

    doc.setFont('bold');
    doc.text('Admit Date', 10, 91);
    doc.setFont('normal');
    doc.text(this.personalDataAdmitDate, 60, 91);
    doc.setFont('bold');
    doc.text('Cash Out Date', 110, 91);
    doc.setFont('normal');
    doc.text(this.personalDataCashOutDate, 145, 91);
    
    doc.setFont('bold');
    doc.text('Direct Hire', 10, 98);
    doc.setFont('normal');
    doc.text(this.directHiretext, 60, 98);
    doc.setFont('bold');
    doc.text('Local PN', 110, 98);
    doc.setFont('normal');
    doc.text(this.personalDataLocalPN, 145, 98);
    doc.setFont('bold');
    doc.text('World Wide ID', 110, 105);
    doc.setFont('normal');
    doc.text(this.personalDataWorldWideID, 145, 105);

    doc.setFont('bold');
    doc.text('Sex', 10, 112);
    doc.setFont('normal');
    doc.text(this.personalDataSex, 60, 112);
    doc.setFont('bold');
    doc.text('SSN ', 110, 112);
    doc.setFont('normal');
    doc.text(ssn, 145, 112);
    
    doc.setFont('bold');
    doc.text('Partner Type', 10, 119);
    doc.setFont('normal');
    doc.text(this.personalDataPartnerType, 60, 119);
    doc.setFont('bold');
    doc.text('Partner Status', 110, 119);
    doc.setFont('normal');
    doc.text(this.personalDataPartnerStatus, 145, 119);

    doc.setFont('bold');
    doc.text('Familliar Name', 10, 126);
    doc.setFont('normal');
    doc.text(this.personalDataFAMILIAR_NAME, 60, 126);
    doc.setFont('bold');
    doc.text('Enterprise ID', 110, 126);
    doc.setFont('normal');
    doc.text(this.personalDataEnterpriseID, 145, 126);
    
    doc.setFont('bold');  
    doc.text('Citizenship', 10, 133);
    doc.setFont('normal');
    doc.text(this.personalDataCitezenship, 60, 133);
    doc.setFont('bold');  
    doc.text('Internet Mail', 110, 133);
    doc.setFont('normal');
    doc.text(this.personalDataInternetMail, 145, 133);
    doc.setFont('bold');  
    doc.text('Mailing Name', 110, 140);
    doc.setFont('normal');
    doc.text(this.personalDataMailingName, 145, 140);

    doc.setFont('bold');
    doc.text('Birth Place', 10, 140);
    doc.setFont('normal');
    doc.text(this.personalDataBirthPlace, 60, 140);

    doc.text('Highly Confidential-Internal Use', 80, 290);    
    doc.autoPrint({variant: 'non-conform'});
    doc.save(fullName +'(Personal Data).pdf');
    } catch (error:any) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('printPersonalData', 'personaldata'); 
      throw error;
    }
    
  }
  
  disableInput(event: KeyboardEvent){
    event.preventDefault();
  }
}
