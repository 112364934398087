import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import { Globals } from '../../../../../globals';
import { SemlinkaccessService } from '../../../../../services/ea/api/SemlinkaccessService';
import { PartnerselectedComponent } from '../partnerselected.component';
import { jsPDF } from 'jspdf';
// import { DatePipe } from '@angular/common';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';
import * as moment from 'moment';


@Component({
  selector: 'app-businessinformation',
  templateUrl: './businessinformation.component.html',
  styleUrls: ['./businessinformation.component.css']
})
export class BusinessinformationComponent implements OnInit {

  dropDownBool: boolean = true;
  initVal: string;

  editPartner: boolean = true;

  deployedToEntity: string;
  operatingEntity: string;
  clientGroup: string;
  serviceLine: string;
  facility: string;
  workForce: string;
  competency: string;
  GMU_id: string;
  GMU_desc: string;
  LMU_id: string;
  LMU_desc: string;
  officeName: string;
  EMP_id: any;
  roleId: any;
  accessSecCheck: any;
  // datePipeString: any;

  documentID: any //number;
  optNum: any;

  editDateFormat: string = "MM/dd/yyyy h:mm a'"
  businessInfoEditor: string;
  businessInfoEditDate: string;
  editDate: string;

  loading: boolean = true;
  isError: boolean = false;
  currentDateTime: any;

  OfficeList: any[] = [
    { office: "eBloc 2" },
    { office: "eBloc 3" },
    { office: "CG 1" },
    { office: "CG 2" },
    { office: "BGC" }
  ];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private global: Globals,
    private partnerSelected: PartnerselectedComponent,
    // private datePipe: DatePipe,
    private access: SemaccessService
  ) {
    this.initVal = this.OfficeList[0].office;
    this.currentDateTime = moment().format("MM/DD/YYYY");
    // this.datePipeString = this.datePipe.transform(Date.now(), 'MM/dd/yyy');
  }

  ngOnInit() {
    this.documentID = parseInt(sessionStorage.getItem(this.global.documentID) || '{}');
    this.optNum = sessionStorage.getItem('optNum');
    this.getAccess();
    this.partnerSelected.loadingEdit = true;
  }

  /**********************************************************************************
  * Method Name: getAccess                                                                 
  * Description: Retrieves access from a reasuable service 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         5/19/2020            Jikho Enrique                    Initial draft 
  ***********************************************************************************/
  async getAccess() {
    try {
      await this.access.getSemAccess("businessinformation").then();
      if (this.access.role != false) {
        this.partnerSelected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerSelected.editHistory(this.documentID, this.optNum);
          this.partnerSelected.partnerSelectedInfo(this.optNum);
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            this.roleId = this.access.role['data'][8][0]['ROLE'];
            await this.access.getDocumentAccess("businessinformation");
            if (this.access.document != false) {
              if (this.access.document['data'][0][0] != null && this.access.document['data'][0][0] != undefined) {
                this.accessSecCheck = this.access.document['data'][0][0];
                this.getBusinessInfo();
              } else {
                this.router.navigate(['equityadmin/eamain'])
              }
            } else {
              this.router.navigate(['equityadmin/eamain']);
            }
          } else {
            this.router.navigate(['equityadmin/eamain'])
          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } else {
        this.router.navigate(['equityadmin/eamain']);
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccess', 'businessinformation');
      throw error;
    }
  }


  /************************************************************************************
    * Method Name: getBusinessInfo                                                            
    * Description: To get business information of the user                  
    *                                                                                          
    * #         Date                 Modified by                        Description                             
    * 1         03/16/2020           Joshua Paul E. Peralta             API connection
    * 2         04/1/2020            Joshua Paul E. Peralta             Hide update button when EMP_id != 1 or 4
  ***********************************************************************************/

  getBusinessInfo() {
    let accessInfo: any = {
      "functionName": "getBusinessInfo",
      "selected_optnum": this.optNum
    }

    this.apiService.postData(this.global.api_URL_pdsDocument, accessInfo).subscribe(data => {
      try {
        if (data) {
          let contentBody = data.body;

          for (let key in contentBody['data'][0]) {
            if (contentBody['data'][0].hasOwnProperty(key)) {
              if (contentBody['data'][0][key] == null || contentBody['data'][0][key] == undefined) {
                contentBody['data'][0][key] = '';
              }
            }
          }

          if (contentBody != undefined) {
            this.deployedToEntity = contentBody['data'][0]['MARKET_ENTITY_DESCRIPTION'];
            this.operatingEntity = contentBody['data'][0]['OPERATING_ENTITY_DESCRIPTION'];
            this.clientGroup = contentBody['data'][0]['CLIENT_GROUP_DESCRIPTION'];
            this.serviceLine = contentBody['data'][0]['SERVICE_LINE_DESCRIPTION'];
            this.facility = contentBody['data'][0]['FACILITY_DESCRIPTION'];
            this.workForce = contentBody['data'][0]['CAREER_MODEL_DESCRIPTION'];
            this.competency = contentBody['data'][0]['COMPETENCY_DESCRIPTION'];
            this.GMU_id = contentBody['data'][0]['GMU_ID'];
            this.GMU_desc = contentBody['data'][0]['GMU_DESCRIPTION'];
            this.LMU_id = contentBody['data'][0]['LMU_ID'];
            this.LMU_desc = contentBody['data'][0]['LMU_DESCRIPTION'];
            this.officeName = contentBody['data'][0]['OFFICE_NAME'];
            this.EMP_id = contentBody['data'][0]['EMPLOYEE_STATUS_ID'];

            if (this.roleId != 1) {
              if (this.accessSecCheck != 'E' || this.accessSecCheck != 'e') {
                if (this.EMP_id != 1 || this.EMP_id != 4) {
                  this.editPartner = false
                }
              }
            }
          }

          this.loading = false;
          this.partnerSelected.loadingEdit = false;
          this.global.searchLoading = false;

        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getBusinessInfo', 'businessinformation');
        throw error;
      }
    }, error => {
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getBusinessInfo', 'businessinformation');
      throw error;
    });;
  }
  /************************************************************************************
    * Method Name: printBusinessInfo                                                        
    * Description: To print Business Information of the user                 
    *                                                                                          
    * #         Date                 Modified by                        Description                             
    * 1         03/16/2020           Joshua Paul E. Peralta             Display print preview
  ***********************************************************************************/
  getObjSize(obj) {  //returns the length of an object -JSE
    try {
      let size = 0, key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getObjSize', 'businessinformation');
      throw error;
    }
  }
  /* istanbul ignore next */
  printBusinessInfo() {
    try {
      let doc = new jsPDF();

      let fullname = this.partnerSelected.partnerSelectedFirstName + ' ' + this.partnerSelected.partnerSelectedMiddleName + ' ' + this.partnerSelected.partnerSelectedLastName;
      let optEnt = doc.splitTextToSize(this.operatingEntity, 150);

      doc.setFontSize(10);
      doc.text(this.currentDateTime, 10, 11);

      doc.setTextColor('#460173');
      doc.setFontSize(10);
      doc.text('Business Information', 10, 19);

      doc.setFillColor('#460173');
      doc.rect(10, 20, 190, 20, "F");

      doc.setFontSize(15);
      doc.setTextColor('#FFFFFF');
      doc.setFont(undefined, 'bold'); 
      if (fullname.length > 20) {
        var name = doc.splitTextToSize(fullname, 60);
        doc.text(name, 20, 29);
      } else {
        doc.text(fullname, 20, 32);
      }

      doc.setFontSize(13);

      doc.text('People Key #', 90, 29);
      doc.text(this.partnerSelected.partnerSelectedPeopleKey.toString(), 97, 34);

      doc.text('Local PN', 130, 29);
      doc.text(this.partnerSelected.partnerSelectedLocalPN.toString(), 130, 34);

      doc.text('Personnel #', 160, 29);
      doc.text(this.partnerSelected.partnerSelectedPersonnelNum.toString(), 162, 34);

      doc.setFontSize(11);
      doc.setTextColor('#000000')

      doc.text('Deployment', 10, 56);
      doc.setDrawColor(0, 0, 0);
      doc.line(10, 57, 33, 57);

      doc.setFont(undefined, 'bold'); 
      doc.text('Deployed To Entity: ', 10, 63);
      doc.setFont(undefined, 'normal');
      doc.text(this.deployedToEntity, 60, 63);
      doc.setFont(undefined, 'bold'); 
      doc.text('GMU: ', 120, 63);
      doc.setFont(undefined, 'normal');
      doc.text(this.GMU_id, 155, 63);

      doc.setFont(undefined, 'bold'); 
      doc.text('Operating Entity: ', 10, 70);
      doc.setFont(undefined, 'normal');
      doc.text(optEnt, 60, 70);
      doc.setFont(undefined, 'bold'); 
      doc.text('', 120, 70)
      doc.setFont(undefined, 'normal');
      doc.text(this.GMU_desc, 155, 70);

      doc.setFont(undefined, 'bold'); 
      doc.text('Client Group: ', 10, 77);
      doc.setFont(undefined, 'normal');
      doc.text(this.clientGroup, 60, 77);
      doc.setFont(undefined, 'bold'); 
      doc.text('LMU: ', 120, 77);
      doc.setFont(undefined, 'normal');
      doc.text(this.LMU_id, 155, 77);

      doc.setFont(undefined, 'bold'); 
      doc.text('Service Line: ', 10, 84);
      doc.setFont(undefined, 'normal');
      doc.text(this.serviceLine, 60, 84);
      doc.setFont(undefined, 'bold'); 
      doc.text('', 120, 84);
      doc.setFont(undefined, 'normal');
      doc.text(this.LMU_desc, 155, 84);

      doc.setFont(undefined, 'bold'); 
      doc.text('Facility: ', 10, 91);
      doc.setFont(undefined, 'normal');
      doc.text(this.facility, 60, 91);
      doc.setDrawColor(0, 0, 0);
      doc.setFont(undefined, 'bold'); //doc.setFontType('bold')
      doc.text('Office Location', 120, 91);
      doc.line(120, 92, 148, 92);

      doc.setFont(undefined, 'bold'); 
      doc.text('Work Force/Work Group: ', 10, 98);
      doc.setFont(undefined, 'normal');
      doc.text(this.workForce, 60, 98);
      doc.setFont(undefined, 'bold'); 
      doc.text('Physical Office', 120, 98);
      doc.text('Location: ', 120, 105)
      doc.setFont(undefined, 'normal');
      doc.text(this.officeName, 155, 98);

      doc.setFont(undefined, 'bold'); 
      doc.text('Competency', 10, 105);
      doc.setFont(undefined, 'normal');
      doc.text(this.competency, 60, 105);

      doc.text('Highly Confidential-Internal Use', 80, 290);

      doc.autoPrint({ variant: 'non-conform' });
      doc.save(fullname + '(BusinessInfo).pdf');

    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('printBusinessInfo', 'businessinformation');
      throw error;
    }
  }
  disableInput(event: KeyboardEvent) {
    event.preventDefault();
  }
}



