import { Component, OnInit } from '@angular/core';
import { PageSection, MyHoldingsPagesResources } from '../../services/enumerations/enumerations';
import { StaticContentService } from '../../services/StaticContentService';
import { Router } from '@angular/router';
import { Globals } from '../../globals';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-trainingandfaqs',
  templateUrl: './trainingandfaqs.component.html',
  styleUrls: ['./trainingandfaqs.component.css']
})
export class TrainingandfaqsComponent implements OnInit {
  loading: boolean = true;
  errorLocation: string = " + [Component: Resourcespage, Method: methodName]";
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  equityTrainingContent: any;
  errorTrainingFaqs: boolean = false;
  accentureDividendsHeader: string;
  accentureDividendsContent: any;
  showaccentureDividendsHeader: boolean = false;
  sneTradingHeader: string;
  sneTradingContent: any;
  showsneTradingHeader: boolean = false;
  veipFaqHeader: string;
  veipFaqContent: any;
  showveipFaqHeader: boolean = false;
  esppFaqHeader: string;
  esppFaqContent: any;
  showesppFaqHeader: boolean = false;
  archiveDividendsContent: any;
  archiveEsppContent: any;
  archiveVeipContent: any;
  departureConsiderationHeader: string;
  departureConsiderationContent: any;
  showdepartureConsiderationHeader: boolean = false;
  optionsRsuFaqHeader: string;
  optionsRsuFaqContent: any;
  showoptionsRsuFaqHeader: boolean = false;
  fctFaqHeader: string;
  fctFaqContent: any;
  showfctFaqHeader: boolean = false;

  constructor(private staticContent: StaticContentService, private globals: Globals, private router: Router) {
    
   }

  ngOnInit() {
      this.loading = true;
      this.getEquityTraining();
      this.getAccentureDividends();
      this.getSneTrading();
      this.getVeipFAQ();
      this.getEsppFAQ();
      this.getFctFAQ();
      this.getOptionsRsuFAQ();
      this.getDepartureConsiderations();
      this.getTrainingVeip();
      this.getTrainingEspp();
  }
  
  getFctFAQ(){
    try {
      this.bodyData[5] = PageSection.trainingFAQFCT;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.fctFaqHeader = contentBody.data[0].List_Name;
              this.fctFaqContent = contentBody.data[0].Body_Desc;  
              this.showfctFaqHeader = true;        
            }
            else{
              this.fctFaqHeader = null;
              this.fctFaqContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getFctFAQ");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getFctFAQ");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getFctFAQ");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getOptionsRsuFAQ(){
    try {
      this.bodyData[5] = PageSection.trainingFAQOptionsAndRSU;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.optionsRsuFaqHeader = contentBody.data[0].List_Name;
              this.optionsRsuFaqContent = contentBody.data[0].Body_Desc; 
              this.showoptionsRsuFaqHeader = true;
            }
            else{
              this.optionsRsuFaqHeader = null;
              this.optionsRsuFaqContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getOptionsRsuFAQ");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getOptionsRsuFAQ");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getOptionsRsuFAQ");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getDepartureConsiderations(){
    try {
      this.bodyData[5] = PageSection.trainingFAQDepartureConsiderations;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.departureConsiderationHeader = contentBody.data[0].List_Name;
              this.departureConsiderationContent = contentBody.data[0].Body_Desc;  
              this.showdepartureConsiderationHeader = true;     
            }
            else{
              this.departureConsiderationContent = null;
            }
          }          
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getTrainingVeip(){
    try {
      this.bodyData[5] = PageSection.trainingArchiveVeip;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);     
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.archiveVeipContent = contentBody.data;       
              
              for(let i = 0; i < this.archiveVeipContent.length; i++) {
                this.archiveVeipContent[i].Document_Name = this.archiveVeipContent[i].Document_Name + "." + this.archiveVeipContent[i].File_Type;
              }
            }
            else{
              this.archiveVeipContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTrainingVeip");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTrainingFaqs = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTrainingVeip");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTrainingVeip");
      error.message += errorLocation;
      throw error;
    }
  }

  getTrainingEspp(){
    try {
      this.bodyData[5] = PageSection.trainingArchiveEspp;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.archiveEsppContent = contentBody.data;      
              
              for(let i = 0; i < this.archiveEsppContent.length; i++) {
                this.archiveEsppContent[i].Document_Name = this.archiveEsppContent[i].Document_Name + "." + this.archiveEsppContent[i].File_Type;
                this.archiveEsppContent[i].Files = this.archiveEsppContent[i].File_Type
              }
            }
            else{
              this.archiveEsppContent = null;
            }
          }
          this.getTrainingDividends();
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTrainingEspp");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTrainingFaqs = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTrainingEspp");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTrainingEspp");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getTrainingDividends(){
    try {
      this.bodyData[5] = PageSection.trainingArchiveDividends;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body); 
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.archiveDividendsContent = contentBody.data;  
              for(let i = 0; i < this.archiveDividendsContent.length; i++) {
                this.archiveDividendsContent[i].Document_Name = this.archiveDividendsContent[i].Document_Name + "." + this.archiveDividendsContent[i].File_Type;
              }        
            }
            else{
              this.archiveDividendsContent = null;
            }
          }
          setTimeout(()=>{ this.staticContent.inContentIconAppend() }, 500);
          this.loading = false;
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTrainingDividends");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTrainingFaqs = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTrainingDividends");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTrainingDividends");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getEsppFAQ(){
    try {
      this.bodyData[5] = PageSection.trainingFAQEspp;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.esppFaqHeader = contentBody.data[0].List_Name;
              this.esppFaqContent = contentBody.data[0].Body_Desc; 
              this.showesppFaqHeader = true;         
            }
            else{
              this.esppFaqContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEsppFAQ");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getEsppFAQ");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getEsppFAQ");
      error.message += errorLocation;
      throw error;
    }
  }

  getEquityTraining(){
    try {
      // this. trainingFAQLoading = true;
      this.bodyData[5] = PageSection.trainingFAQEquityTraining;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);    
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);            
    
            if(contentBody.data.length > 0){
              this.equityTrainingContent = contentBody.data[0].Body_Desc;         
            }
            else{
              this.equityTrainingContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEquityTraining");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getEquityTraining");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getEquityTraining");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getAccentureDividends(){
    try {
      this.bodyData[5] = PageSection.trainingFAQAccentureDividends;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.accentureDividendsHeader = contentBody.data[0].List_Name;
              this.accentureDividendsContent = contentBody.data[0].Body_Desc;       
              this.showaccentureDividendsHeader = true;   
            }
            else{
              this.accentureDividendsHeader = null;
              this.accentureDividendsContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getAccentureDividends");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getAccentureDividends");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getAccentureDividends");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getSneTrading(){
    try {
      this.bodyData[5] = PageSection.trainingFAQSneTrading;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);    
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.sneTradingHeader = contentBody.data[0].List_Name;
              this.sneTradingContent = contentBody.data[0].Body_Desc;    
              this.showsneTradingHeader = true;      
            }
            else{
              this.sneTradingHeader = null;
              this.sneTradingContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getSneTrading");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTrainingFaqs = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getSneTrading");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getSneTrading");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getVeipFAQ(){
    try {
      this.bodyData[5] = PageSection.trainingFAQVeip;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);   
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.veipFaqHeader = contentBody.data[0].List_Name;
              this.veipFaqContent = contentBody.data[0].Body_Desc;         
              this.showveipFaqHeader = true; 
            }
            else{
              this.veipFaqHeader = null;
              this.veipFaqContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getVeipFAQ");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getVeipFAQ");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getVeipFAQ");
      error.message += errorLocation;
      throw error;
    }
  }

  getFileName(eventBody) {
    try {
      let href: string = eventBody.target.href;
      let id: string = eventBody.target.id;
      let srcElement: HTMLImageElement = eventBody.srcElement;
      let target: string = eventBody.target.target;
      let reportId: object;
      
      if(srcElement.src != undefined){
        this.staticContent.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContent.validateFileType(event);
        } else if(href.indexOf("reportid") != -1) {
          id = href.substring(href.lastIndexOf("=") + 1);
          reportId = {'ReportTypeId': id};
          sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
          window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
          return false;
        } else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          let innerTxt = eventBody.target.innerText;
          event.preventDefault();

          if(target == "_blank") {
            if(innerTxt in MyHoldingsPagesResources){
              this.navToMyHoldingsPage(href);
            }else if(href.indexOf("Resources") != -1){
              this.navToMyHoldingsPage("Resources");
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              this.navToMyHoldingsPage("Reports");
            }
          } else {
            if(href.indexOf("Resources") != -1){
              eventBody.target.href = this.globals.currentWebUrl + "/resourcespage";
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              eventBody.target.href = this.globals.currentWebUrl + "/reportspage";
            }
          }
          

        }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
          if(target == "_blank") {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
          } else {
            id == "managementcontactlink" ? eventBody.target.href = this.globals.currentWebUrl + "/technologysupport": "";
          }
        } 
        
        // else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
        //   eventBody.preventDefault();
        //   window.open(href, target);
        // }else{ //if has file but no filename text
        //   event.preventDefault();
        //   this.staticContent.validateFileType(event);
        // }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }
  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "contactspage" : "";
    page.indexOf("Reports") != -1 ? page = "reportspage" : "";

    window.open(this.globals.currentWebUrl + "/" + page, "_blank");
  }

}
