import { Injectable } from '@angular/core';

@Injectable()
export class MobileService {

  displayDesktopView: boolean;
  displayMobileView: boolean;

  constructor() { }

  /**********************************************************************************
    Method Name: getInnerWidth
    Description: Gets the inner width of the screen or browser.

    #     Date            Modified by                 Description
    1     06/19/19        Dalemark P. Suquib          Initial draft
  **********************************************************************************/
  getInnerWidth() {
    if(self.innerWidth < 720) { // mobile
      this.displayDesktopView = false;
      this.displayMobileView = true;
    } else { // desktop
      this.displayMobileView = false;
      this.displayDesktopView = true;
    }
  }

  /**********************************************************************************
    Method Name: onResize
    Description: Gets the available inner width of the screen or browser on resize.

    #     Date            Modified by                 Description
    1     06/19/19        Dalemark P. Suquib          Initial draft
  **********************************************************************************/
  onResize(event) {
    let innerWidth: number = event.target.innerWidth;
    if(innerWidth != null) {
      if(event.target.innerWidth < 720) { // mobile
        this.displayDesktopView = false;
        this.displayMobileView = true;
      } else { // desktop
        this.displayMobileView = false;
        this.displayDesktopView = true;
      }
    }
  }
}
