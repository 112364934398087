import { NativeDateAdapter } from "@angular/material/core";
import * as moment from 'moment';

export class CustomDateAdapter extends NativeDateAdapter {
    parse(value: any): any | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
          const str = value.split('/');
          const year = Number(str[2]);
          const month = Number(str[1]) - 1;
          const date = Number(str[0]);
          return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

   format(date: Date, displayFormat: string): string {
       if (displayFormat == "input") {
            return moment(date).format("DD MMM YYYY");
       } else if (displayFormat == "eaInput")
       {
           return moment(date).format("MM/DD/YYYY");
       }
       else if (displayFormat == "inputMonth") {
            return moment(date).format("MMM YYYY");
       } else {
            return date.toDateString();
       }
   }
}

export const CUSTOM_DATE_FORMATS =
{
   parse: {
       dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
   },
   display: {
       // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
       dateInput: 'input',
       // monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
       monthYearLabel: 'inputMonth',
       dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
       monthYearA11yLabel: {year: 'numeric', month: 'long'},
   }
}