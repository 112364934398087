import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { StaticTextState, ReportItemState } from '../../../../../services/ea/states/weeklysummary.state';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Globals } from '../../../../../globals';
import { Weeklysummary } from '../../../../../services/ea/actions/weeklysummary.actions';
import { EjetaccessService } from '../../../../../services/ea/EjetaccessService';

@Component({
  selector: 'app-summaryreports',
  templateUrl: './summaryreports.component.html',
  styleUrls: ['./summaryreports.component.css']
})
export class SummaryreportsComponent implements OnInit {

  weeklySummaryHeader: string;
  weeklySummaryGreetings: string;
  emptyTableText: string;
  weeklySummaryTabs = [];
  activeEjetAward: any;
  activeAward: any;
  rawData: any;
  tableData: any;
  activeTable: any;
  staticTextData: any[];
  isLoading: boolean = true;
  borderStyle: string = "none";
  hasReports: boolean = false;
  tableCols: any = {
    nameCol: '',
    descCol: '',
    weeklyPostDateCol: '',
    transDateCol: '',
    commitCol: '',
    rollbackCol: ''
  }
  isError: boolean = false;
  sumReportError: boolean = false;
  ejetService: any;

  @Select(ReportItemState.getReportItems) reportItems: any;
  @Select(ReportItemState.getEspp) esppItems: any;
  @Select(ReportItemState.getRsu) rsuItems: any;
  @Select(ReportItemState.getOption) optionItems: any;
  @Select(ReportItemState.getVeip) veipItems: any;
  @Select(ReportItemState.getReportTabs) reportTabs: any;
  @Select(StaticTextState.getStaticData) staticData: any;

  constructor(
    private apiService: ApiService,
    private store: Store,
    private globals: Globals,
    private ejetAccessService: EjetaccessService
  ) {
    this.ejetService = this.ejetAccessService.sumReportError$.subscribe(state => {
      this.sumReportError = state;
    });
   }

  ngOnInit() {
    this.fetchData();
    this.getStaticContent();
  }

  ngOnDestroy(){  
    this.ejetService.unsubscribe();
    this.store.dispatch(new Weeklysummary.ClearReportItems);
  }

  /**********************************************************************************
  * Method Name: getStaticContent                                                                 
  * Description: Gets and sets the header and intro text.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getStaticContent(){
    try {
      this.staticData.subscribe(data => {
          if (data.staticData.data) {
            this.staticTextData = data.staticData.data;
            this.weeklySummaryHeader = this.staticTextData[this.getDataIndex("ea.ejet.summary.title")].MESSAGE_VALUE;
            this.weeklySummaryGreetings = this.staticTextData[this.getDataIndex("ea.ejet.summary.intro.text")].MESSAGE_VALUE;
            this.emptyTableText = this.staticTextData[this.getDataIndex("ea.ejet.summary.emptytable")].MESSAGE_VALUE;
            this.tableCols.nameCol = this.staticTextData[this.getDataIndex("ea.ejet.summary.column.name")].MESSAGE_VALUE;
            this.tableCols.descCol = this.staticTextData[this.getDataIndex("ea.ejet.summary.column.desc")].MESSAGE_VALUE;
            this.tableCols.weeklyPostDateCol = this.staticTextData[this.getDataIndex("ea.ejet.summary.column.postdate")].MESSAGE_VALUE;
            this.tableCols.transDateCol = this.staticTextData[this.getDataIndex("ea.ejet.summary.column.transdate")].MESSAGE_VALUE;
            this.tableCols.commitCol = this.staticTextData[this.getDataIndex("ea.ejet.summary.column.commit")].MESSAGE_VALUE;
            this.tableCols.rollbackCol = this.staticTextData[this.getDataIndex("ea.ejet.summary.column.rollback")].MESSAGE_VALUE;
          }
      })
    } catch (error) {
      this.isError = true;
      error.message += this.apiService.setErrorContents('getStaticContent', 'Summaryreports');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getDataIndex                                                                 
  * Description: Returns the index of the given key.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getDataIndex(key){
    let index;
    try {
      for (let i = 0; i < this.staticTextData.length; i++) {
        if (this.staticTextData[i].MESSAGE_NAME == key) {
          index = i
        }
      }
      return index;
    } catch (error) {
      this.isError = true;
      error.message += this.apiService.setErrorContents('getDataIndex', 'Summaryreports');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: fetchData                                                                 
  * Description: Calls the api to fetch data and store in reportstate.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  * 2         03/10/20             John Paul B. Fernando          Updated the API
  ***********************************************************************************/
  fetchData(){
    let body = {
      functionName: "getWeeklySummaryData"
    }

    this.apiService.postData(this.globals.manageWeeklySummaryApi, body).subscribe(data => {
      try {
        if (data) {
          let body = data.body;
          if (body) {
            this.rawData = body;
            if (this.rawData.length > 0) {
              this.store.dispatch(new Weeklysummary.ClearReportItems);
              this.borderStyle = "1px solid #460173"
              this.hasReports = true;
              for (let i = 0; i < this.rawData.length; i++) {
                this.store.dispatch(new Weeklysummary.Add(this.rawData[i]))        
              } 
              this.setReportTabs(this.reportTabs);             
            } else {
              this.hasReports = false;
              this.isLoading = false;
            }
          }
        }
      } catch (error) {
        this.isLoading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('fetchData', 'Summaryreports');
        throw error;
      }
    }, error => {
      this.isLoading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('fetchData', 'Summaryreports');
    });
  }

  /**********************************************************************************
  * Method Name: onLoad                                                                 
  * Description: Runs everytime the reports tabs are change and get the data of each tab.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  onLoad(activeAward){
    try {
      let award = activeAward;
      this.setActiveTab(award);
      switch (award) {
        case 'ESPP':
          this.esppItems.subscribe(data => this.tableData = data);
          break;

        case 'RSU':
          this.rsuItems.subscribe(data => this.tableData = data);
          break;

        case 'Options':
          this.optionItems.subscribe(data => this.tableData = data);
          break;

        case 'VEIP':
          this.veipItems.subscribe(data => this.tableData = data);
          break;
      
        default:
          break;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('onLoad', 'Summaryreports');
      throw error;
    }

  }

  /**********************************************************************************
  * Method Name: setReportTabs                                                                 
  * Description: Create the set of tabs.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  setReportTabs(reportTabs){
    try {
      reportTabs.subscribe(data => {
        for (let i = 0; i < data.length; i++) {
          if (this.weeklySummaryTabs.length <= 0) {
            this.weeklySummaryTabs = data;
          }          
        }
      })
      this.activeEjetAward = this.weeklySummaryTabs[0].name;
      this.onLoad(this.activeEjetAward);   
    } catch (error) {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('setReportTabs', 'Summaryreports');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: setActiveTab                                                                 
  * Description: Sets the current or last visited tab.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  setActiveTab(activeAward){
    try {
      for (let i = 0; i < this.weeklySummaryTabs.length; i++) {
        if(this.weeklySummaryTabs[i].name == activeAward){
          this.weeklySummaryTabs[i].isActive = true;
        }
        else{
          this.weeklySummaryTabs[i].isActive = false;
        }
      }      
    } catch (error) {
      this.isError = true;
      error.message += this.apiService.setErrorContents('setActiveTab', 'Summaryreports');
      throw error;
    }    
  }

}
