import { Component, OnInit } from '@angular/core';
import { PageSection, FileType, MyHoldingsPagesResources } from '../../services/enumerations/enumerations';
import { StaticContentService } from '../../services/StaticContentService';
import { Router } from '@angular/router';
import { Globals } from '../../globals';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-programguidebooks',
  templateUrl: './programguidebooks.component.html',
  styleUrls: ['./programguidebooks.component.css']
})
export class ProgramguidebooksComponent implements OnInit {
  
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  shareIncentiveContent: string = "";
  restrictedShareUnitContent: string = "";
  shareOptionsGuide: object;
  purchaseProgramGuideESPP: string = "";
  purchaseProgramGuideVEIP: string = "";
  expatInformations: string = "";
  programArchiveVEIP: object;
  programArchiveESPP: object;
  errorProgramGuidebooks: boolean = false;
  progragGuideLoading: boolean = true;
  errorLocation: string = " + [Component: Resourcespage, Method: methodName]";

  constructor(private staticContent: StaticContentService, private router: Router, private globals: Globals) { }

  ngOnInit() {
    this.getShareIncentive();
    this.getRSUGuidebooks();
    this.getShareOptions();
    this.getPurchaseProgramVEIP();
    this.getPurchaseProgramESPP();
    this.getExpatInfo();
  }


  async getShareIncentive(){
    try {
      this.bodyData[5] = PageSection.programGuideShareIncentives;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.shareIncentiveContent = contentBody.data[0].Body_Desc;          
            }
            else{
              this.shareIncentiveContent = "";
            }
          }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.progragGuideLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getShareIncentive");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorProgramGuidebooks = true;
        this.progragGuideLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getShareIncentive");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.progragGuideLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getShareIncentive");
      error.message += errorLocation;
      throw error;
    }
  }

  async getRSUGuidebooks(){
    try {
      this.bodyData[5] = PageSection.programGuideRestrictedShare;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.restrictedShareUnitContent = contentBody.data[0].Body_Desc;          
            }
            else{
              this.restrictedShareUnitContent = "";
            }
          }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.progragGuideLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getRSUGuidebooks");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.progragGuideLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getRSUGuidebooks");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.progragGuideLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getRSUGuidebooks");
      error.message += errorLocation;
      throw error;
    }
  }
  
  async getShareOptions(){
    try {
      this.bodyData[5] = PageSection.programGuideShareOption;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);    
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.shareOptionsGuide = contentBody.data;          
            }
            else{
              this.shareOptionsGuide = null;
            }
          }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.progragGuideLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getShareOptions");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.progragGuideLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getShareOptions");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.progragGuideLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getShareOptions");
      error.message += errorLocation;
      throw error;
    }
  }
  
  async getPurchaseProgramVEIP(){
    try {
      this.bodyData[5] = PageSection.programGuidePPVEIP;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body); 
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.purchaseProgramGuideVEIP = contentBody.data[0].Body_Desc;          
            }
            else{
              this.purchaseProgramGuideVEIP = "";
            }
          }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.progragGuideLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramVEIP");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.progragGuideLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramVEIP");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.progragGuideLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramVEIP");
      error.message += errorLocation;
      throw error;
    }
  }
  
  async getPurchaseProgramESPP(){
    try {
      this.bodyData[5] = PageSection.programGuidePPESPP;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.purchaseProgramGuideESPP = contentBody.data[0].Body_Desc;      
            }
            else{
              this.purchaseProgramGuideESPP = "";
            }
          }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.progragGuideLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramESPP");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.progragGuideLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramESPP");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.progragGuideLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramESPP");
      error.message += errorLocation;
      throw error;
    }
  }
  
  async getExpatInfo(){
    try {
      this.bodyData[5] = PageSection.programGuideExpatriate;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.expatInformations = contentBody.data[0].Body_Desc;          
            }
            else{
              this.expatInformations = "";
            }
          }
          this.getArchiveVEIP();
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.progragGuideLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getExpatInfo");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.progragGuideLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getExpatInfo");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.progragGuideLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getExpatInfo");
      error.message += errorLocation;
      throw error;
    }
  }

  async getArchiveVEIP(){
    try {
      this.bodyData[5] = PageSection.programGuideArchiveVEIP;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);      

          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.programArchiveVEIP = contentBody.data;  
              
              for (let i = 0; i < contentBody.data.length; i++){
                // this.currYrTaxPrepDocDesc = this.taxPrepData[i].Document_Desc;
                // this.currYrTaxPrepDocName = this.taxPrepData[i].Document_Name;
                // this.currYrTaxPrepFileTyp = this.taxPrepData[i].File_Type;
      
                if(this.programArchiveVEIP[i].File_Type == "pdf") {
                  this.programArchiveVEIP[i].fileIcon = FileType.pdf;
                 } else if(this.programArchiveVEIP[i].File_Type == "docx") {
                  this.programArchiveVEIP[i].fileIcon = FileType.doc;
                 } else if(this.programArchiveVEIP[i].File_Type == "doc") {
                  this.programArchiveVEIP[i].fileIcon = FileType.doc;
                 } else if(this.programArchiveVEIP[i].File_Type == "xls") {
                  this.programArchiveVEIP[i].fileIcon = FileType.xls;
                 } else if(this.programArchiveVEIP[i].File_Type == "xlsx") {
                  this.programArchiveVEIP[i].fileIcon = FileType.xls;
                 } else if(this.programArchiveVEIP[i].File_Type == "pptx") {
                  this.programArchiveVEIP[i].fileIcon = FileType.ppt;
                 } else if(this.programArchiveVEIP[i].File_Type == "ppt") {
                  this.programArchiveVEIP[i].fileIcon = FileType.ppt;
                 } else if(this.programArchiveVEIP[i].File_Type == "zip") {
                  this.programArchiveVEIP[i].fileIcon = FileType.zip;
                 } 
              }  
            }
            else{
              this.programArchiveVEIP = null;
            }
          }
          this.getArchiveESPP();
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.progragGuideLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getArchiveVEIP");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.progragGuideLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getArchiveVEIP");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.progragGuideLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getArchiveVEIP");
      error.message += errorLocation;
      throw error;
    }
  }

  async getArchiveESPP(){
    try {
      this.bodyData[5] = PageSection.programGuideArchiveESPP;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.body.length > 2){
            let contentBody = JSON.parse(content.body);
    
            if(contentBody.data.length > 0){
              this.programArchiveESPP = contentBody.data; 
              

              
              for (let i = 0; i < contentBody.data.length; i++){
                // this.currYrTaxPrepDocDesc = this.taxPrepData[i].Document_Desc;
                // this.currYrTaxPrepDocName = this.taxPrepData[i].Document_Name;
                // this.currYrTaxPrepFileTyp = this.taxPrepData[i].File_Type;
      
                if(this.programArchiveESPP[i].File_Type == "pdf") {
                  this.programArchiveESPP[i].fileIcon = FileType.pdf;
                 } else if(this.programArchiveESPP[i].File_Type == "docx") {
                  this.programArchiveESPP[i].fileIcon = FileType.doc;
                 } else if(this.programArchiveESPP[i].File_Type == "doc") {
                  this.programArchiveESPP[i].fileIcon = FileType.doc;
                 } else if(this.programArchiveESPP[i].File_Type == "xls") {
                  this.programArchiveESPP[i].fileIcon = FileType.xls;
                 } else if(this.programArchiveESPP[i].File_Type == "xlsx") {
                  this.programArchiveESPP[i].fileIcon = FileType.xls;
                 } else if(this.programArchiveESPP[i].File_Type == "pptx") {
                  this.programArchiveESPP[i].fileIcon = FileType.ppt;
                 } else if(this.programArchiveESPP[i].File_Type == "ppt") {
                  this.programArchiveESPP[i].fileIcon = FileType.ppt;
                 } else if(this.programArchiveESPP[i].File_Type == "zip") {
                  this.programArchiveESPP[i].fileIcon = FileType.zip;
                 } 
              }  
            }
            else{
              this.programArchiveESPP = null;
            }
          }
          this.progragGuideLoading = false;
          setTimeout(()=>{ this.staticContent.inContentIconAppend() }, 100);
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getArchiveESPP");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            let errorLocation = this.errorLocation.replace("methodName", "getArchiveESPP");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getArchiveESPP");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "technologysupport" : "";
    page.indexOf("Reports") != -1 ? page = "reportspage" : "";

    window.open(this.globals.currentWebUrl + "/" + page, "_blank");
  }

  getFileName(eventBody) {
    try {
      let href: string = eventBody.target.href;
      let id: string = eventBody.target.id;
      let srcElement: HTMLImageElement = eventBody.srcElement;
      let target: string = eventBody.target.target;
      let reportId: object;
      
      if(srcElement.src != undefined){
        this.staticContent.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContent.validateFileType(event);
        } else if(href.indexOf("reportid") != -1) {
          id = href.substring(href.lastIndexOf("=") + 1);
          reportId = {'ReportTypeId': id};
          sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
          window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
          return false;
        } else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          let innerTxt = eventBody.target.innerText;
          event.preventDefault();

          if(target == "_blank") {
            if(innerTxt in MyHoldingsPagesResources){
              this.navToMyHoldingsPage(href);
            }else if(href.indexOf("Resources") != -1){
              this.navToMyHoldingsPage("Resources");
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              this.navToMyHoldingsPage("Reports");
            }
          } else {
            if(href.indexOf("Resources") != -1){
              eventBody.target.href = this.globals.currentWebUrl + "/resourcespage";
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              eventBody.target.href = this.globals.currentWebUrl + "/reportspage";
            }
          }
          

        }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
          if(target == "_blank") {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
          } else {
            id == "managementcontactlink" ? eventBody.target.href = this.globals.currentWebUrl + "/contactspage": "";
          }
        } 
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

}
