import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class MsalGuardService {

  headers: Headers;
  user: any; 
  optnum: any;
  errorLocation: string = " + [Service: MSAL Service, Method: navToPage]";
  configData: any = null;
  accessToken: any;
  public eid: any;
  public userId: any;
  public token: any;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  dataSource: any =[];

  constructor( private authService: MsalService, private msalBroadcastService: MsalBroadcastService ) {
  }

  /**********************************************************************************
  * Method Name: isUserAuthenticated
  * Description: Check if user has logged in or not.
  *
  * #         Date                 Modified by                    Description
  * 1         02/19/20             Dalemark P. Suquib             Initial draft
  * 2         04/20/20             Marie Antoinette Masayon       Updated for MyH
  ***********************************************************************************/
  isUserAuthenticated(): Promise<boolean> {
    let auth = new Promise<boolean>((resolve) => {
	  let activeAccount = this.authService.instance.getActiveAccount();

      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
          const accounts = this.authService.instance.getAllAccounts();
          const userAccountId = accounts[0].homeAccountId;
          const userClaims = accounts[0].idTokenClaims;
        } else {
          this.authService.loginRedirect();
          resolve(false);
        }
      })
      return auth;
  }

}





