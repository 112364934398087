import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { StaticContentService } from '../services/StaticContentService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { PageSection, MyHoldingsPagesResources } from '../services/enumerations/enumerations';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-contactspage',
  templateUrl: './contactspage.component.html',
  styleUrls: ['./contactspage.component.css']
})
export class ContactspageComponent implements OnInit {
  
  loading = true;
  eid: any;
  user: any;
  userDetails: boolean;
  staticContent: any;
  legalNotice: string;
  userprofileInfo: any;
  state: boolean = false;
  keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));

  countries: any;
  countryVal: string = "Select Country";
  countryContactsArray: any;
  regEx3: any = /<\/span.*?>|<span>/gi;
  errorLocation: string = " + [Component: Contactspage, Method: methodName]";
  errorTechSupport: boolean = false;
  errorContacts: boolean = false;

  ContactEquityProfessionalList: any;
  contactEquityProfessionalListContent: any;
  showmyRbutton: boolean = false;
  personalDataContent: any;
  personalDataContentData: any;
  contactsLoading: boolean = true;
  dynamicHref: string;
  // employeeButton: boolean = false;
  // leadershipButton: boolean = false;
  formerButton: boolean;
  leadershipEmailButton: boolean;
  UBSlink : any;
  MSlink: any;
  envSetting: any;

  constructor(private sanitizer:DomSanitizer, private router: Router, private route: ActivatedRoute, private globals: Globals, private staticContentService: StaticContentService, private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {
    this.checkErrorMessage();
    // this.loading = false;
    setTimeout(()=>{ this.loading = false }, 1000);
    
    
    this.getCountryContacts();
    this.getUserDetails();
    
    this.getFooterStaticContent();
    sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest));
    sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Homepage"));

    if(this.bodyData != null) {
      this.getPersonalData();
    }
    if(sessionStorage.getItem("footerSetting") == "old"){
      $( "#thePageFooter" ).hide();
      $( "#thePageFooterOld" ).show();
    }

    this.brokersSSO();
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
    * 2    02/17/2020           KMN                                   Added variables in Octopus deploy
  *****************************************************************************************/
 brokersSSO(){
    if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
      let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

      if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }else{
      if(this.bodyData != null){
        if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
        }else{
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
        }
      }
    }
  }

  navToPage(strLoc){
    try {
      this.router.navigate([strLoc]);  
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }
  

GoToPortal(){
  this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));

  if (this.user.EmployeeStatus == "Active" || this.user.EmployeeStatus ==  "Leave with pay" || this.user.EmployeeStatus == "Leave of Absence" || this.user.EmployeeStatus == "Leave without credit"){
    if(this.globals.stageName == "prod"){
      window.open("https://workday.accenture.com", "_blank");
    }
    else{
      window.open("https://wd3-impl.workday.com/accenture10/d/home.htmld", "_blank");
    }
  } else if (this.user.EmployeeStatus == "Terminated" || this.user.EmployeeStatus == "Deceased" || this.user.EmployeeStatus == "Retired"){
    window.open("https://support.accenture.com/former_employee_portal", "_blank");
  }
}


  /**********************************************************************************
      Method Name: getUserDetails
      Description: get user details

      Date            Modified by       Description
      04/09/19        JPF               Initial draft   
      04/10/19        KMN               BUG FIX 278813 - Set user's country when authenticated 
  **********************************************************************************/
 getUserDetails(){
    try {
      if((sessionStorage.getItem(this.globals.esoEnterpriseId) != null && sessionStorage.getItem(this.globals.esoEnterpriseId) != '' && sessionStorage.getItem(this.globals.esoEnterpriseId) != undefined)
      || (sessionStorage.getItem(this.globals.profileInfoKey) != null && sessionStorage.getItem(this.globals.profileInfoKey) != '' && sessionStorage.getItem(this.globals.profileInfoKey) != undefined)){
        this.eid = JSON.parse(sessionStorage.getItem(this.globals.esoEnterpriseId));
        this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));

        this.userprofileInfo = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
      }
      
      if(this.user == null || this.user == undefined){
        this.staticContentService.setNavLoginState(false);
        this.userDetails = false;
        // this.contactsLoading = false;
      }
      else{
        this.userDetails = true;
        this.getCountryContacts(this.userprofileInfo.CountryName);
        this.staticContentService.setNavLoginState(true);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getUserDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  getFooterStaticContent(){
    try {
      let legalNotice = this.globals.staticLegalNotice;
      this.staticContentService.getBluePageStaticContent(legalNotice).subscribe((data) => {
        try {
          let body = data[0];
          this.legalNotice = body.Value;
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
      error.message += errorLocation;
      throw error;
    }
  }

  getCountryContacts(param?){
    try {
      let regEx: any = /&.*?;|<strong>|<em>|<\/em>|<\/strong>|<div.*?>|<\/div>|<blockquote.*?>|<\/blockquote>|<br.*?>|<p>|<\/p>/gi;
      let romaniaRegEx: any = /&.*?;|<div.*?>|<\/div>|<br.*?>|<blockquote.*?>|<p>|<\/p>/gi;
      let regEx2: any = /<span.*?>/gi;
      let queryParam: any;
      let rawData: any;
      let splitedData: any;
      let formattedData: any;
      if(param == null || param == undefined){
        queryParam = "TechnologySupport\\List";
        
      this.staticContentService.getMyRDataNoToken(queryParam).pipe(map(res => res)).subscribe(data => { 
        try {
          if (JSON.parse(data.body) != undefined) {
            if (JSON.parse(data.body).data) {
              rawData = JSON.parse(data.body).data[0].Body_Desc;
              splitedData = rawData.replace(/<[^>]*>/gm,"");
              // this.countries = splitedData.split("/");
              this.countries = splitedData.split("-");
            }
          }

          // this.contactsLoading = false;
        } catch(error) {          
          this.errorTechSupport = true;
          this.errorContacts = true;
          this.contactsLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getCountryContacts");
          error.message += errorLocation;
          throw error;
        }  
      }, error => {        
        this.errorTechSupport = true;
        this.errorContacts = true;
        this.contactsLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getCountryContacts");
        error.error.message += errorLocation;
        throw error.error;
      });
      }
      else{
        queryParam =  "TechnologySupport\\"+param;
        this.countryVal = param;
        this.staticContentService.getMyRDataNoToken(queryParam).pipe(map(res => res)).subscribe(data => { 
          try {
            if (JSON.parse(data.body) != undefined) {
              if (JSON.parse(data.body).data) {
                rawData = JSON.parse(data.body).data[0].Body_Desc;

                if(param == "Romania"){
                  formattedData =rawData.replace(romaniaRegEx,"");
                  this.countryContactsArray = formattedData.split("</blockquote>");
                  this.removeBlankContact(this.countryContactsArray);
                }
                else{
                formattedData = rawData.replace(regEx,"</span>").replace(regEx2,"<span>");
                this.countryContactsArray = formattedData.split(".com");
                this.removeBlankContact(this.countryContactsArray);
                }                
              }              
            }
          } catch(error) {
            this.errorTechSupport = true;
            this.errorContacts = true;
            this.contactsLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getCountryContacts");
            error.message += errorLocation;
            throw error;
          }  
        }, error => {          
          this.errorTechSupport = true;
          this.errorContacts = true;
          this.contactsLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getCountryContacts");
          error.error.message += errorLocation;
          throw error.error;
        });
      }
    } catch(error) {      
      this.errorTechSupport = true;
      this.errorContacts = true;
      this.contactsLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getCountryContacts");
      error.message += errorLocation;
      throw error;
    }
  }

isNullOrWhiteSpace(str){
  try {
    return str == null || str.replace(/\s/g, '').length < 1 || str == "</span>";
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "isNullOrWhiteSpace");
    error.message += errorLocation;
    throw error;
  }
}

removeBlankContact(countryContactsArray){
  try {
    for(let i = countryContactsArray.length - 1; i >= 0; i--){
      if(countryContactsArray[i] == null || this.isNullOrWhiteSpace(countryContactsArray[i]) || this.isNullOrWhiteSpace(countryContactsArray[i].replace(this.regEx3,""))){
        this.countryContactsArray.splice(i, 1);
      }
      else{
        this.countryContactsArray[i] = this.countryContactsArray[i].split("</span>");
        this.removeBlankData(this.countryContactsArray[i]);
      }
  
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "removeBlankContact");
    error.message += errorLocation;
    throw error;
  }
}

removeBlankData(singleContact){
  try {
    for(let i = singleContact.length - 1; i >= 0; i--){
      if(singleContact[i] == null || this.isNullOrWhiteSpace(singleContact[i])){
        singleContact.splice(i,1);
      }
      else{
        singleContact[i] = singleContact[i].replace(this.regEx3,"");
      }
    }
    let lastData = singleContact.length -1;
    if(lastData != 0){
      
      singleContact[lastData] = singleContact[lastData].replace(/.com\s|.com/gi,"") + ".com";

    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "removeBlankData");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Contactspage") != -1 && this.user != null) {
                webPage = "Contactspage";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  /**********************************************************************************
   * Method Name: getContactsContent                                                                 
   * Description: Get contacts content                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 05/15/19             Katrina Narag               Initial draft       
   *********************************************************************************/
  getContactsContent(){
    try {
      this.bodyData[5] = PageSection.ContactEquityProfessionalList;
      this.bodyData[6] = 2;
      this.contactsLoading = true;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
        try {
          this.ContactEquityProfessionalList = JSON.parse(data.body);    
          if (this.ContactEquityProfessionalList != undefined) {
            if (this.ContactEquityProfessionalList.data) {
              let content = this.ContactEquityProfessionalList.data; 

                if(content.length > 0) {
                              
                this.contactEquityProfessionalListContent =  content[0].Body_Desc;
                this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));   
                
                  switch (this.user.EmployeeStatus) {
                    case "Leave without credit":
                    case "Leave of Absence":
                    case "Active":
                    case "Leave with pay":
                      if(this.contactEquityProfessionalListContent.indexOf("FORMER Employees") != -1) this.contactEquityProfessionalListContent = this.contactEquityProfessionalListContent.substring(0, this.contactEquityProfessionalListContent.indexOf("FORMER Employees") - 5);
                    break;
                    
                    case "Terminated":
                    case "Deceased":
                    case "Retired":
                      if (this.contactEquityProfessionalListContent.indexOf("FORMER Employees") != -1 && this.contactEquityProfessionalListContent.indexOf("ACTIVE Employees") != -1 ) {
                        this.contactEquityProfessionalListContent = this.contactEquityProfessionalListContent.substring(0, this.contactEquityProfessionalListContent.indexOf("ACTIVE Employees") - 5) +
                        this.contactEquityProfessionalListContent.substring(this.contactEquityProfessionalListContent.indexOf("FORMER Employees") - 5, this.contactEquityProfessionalListContent.length);
                      }                    
                    break;
                  
                    default:
                      this.contactEquityProfessionalListContent = this.contactEquityProfessionalListContent;
                      break;
                  }

                  if (this.user.JobCd == "10000025") {
                    // this.employeeButton = false;
                    // this.leadershipButton =  true;
                    this.formerButton = false;
                    this.showmyRbutton = false;
                    this.leadershipEmailButton = true; 
                  } else {
                    switch (this.user.EmployeeStatus) {
                      case "Leave without credit":
                      case "Leave of Absence":
                      case "Active":
                      case "Leave with pay":
                        this.formerButton = false;
                        // this.employeeButton = true;
                        this.showmyRbutton = true;
                        // this.leadershipButton =  false;
                        this.leadershipEmailButton = false;
                        
                      break;
                      
                      case "Terminated":
                      case "Deceased":
                      case "Retired":
                        this.formerButton = true;
                        // this.employeeButton = true;
                        this.showmyRbutton = false;
                        // this.leadershipButton =  false;
                        this.leadershipEmailButton = false;

                      break;
                    
                      default:
                        break;
                    }
                    
                  }                           
                
                setTimeout(()=>{ this.contactsLoading = false; }, 1000);
              }
              else{
                this.contactEquityProfessionalListContent = "";
                setTimeout(()=>{ this.contactsLoading = false; }, 1000);
              }
            } else{
              this.contactEquityProfessionalListContent = "";
              setTimeout(()=>{ this.contactsLoading = false; }, 1000);               
            }
          } else{
            this.contactEquityProfessionalListContent = "";
            setTimeout(()=>{ this.contactsLoading = false; }, 1000);            
          } 
        } catch(error) {          
          this.errorContacts = true;
          let errorLocation = this.errorLocation.replace("methodName", "getContactsContent");
          error.message += errorLocation;
          throw error;
        }
      }, error => {        
        this.errorContacts = true;
        let errorLocation = this.errorLocation.replace("methodName", "getContactsContent");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {      
      this.errorContacts = true;
      let errorLocation = this.errorLocation.replace("methodName", "getContactsContent");
      error.message += errorLocation;
      throw error;
    }
  }

  // extractEmail(data){    
  //   let reg = /<div>E-mail.*?<\/div>/gm;
  //   let wholeData = reg.exec(data)[0];            
  //   let href = (/mailto:.*?.com/gm.exec(wholeData)[0]);
  //   let emailText = (/:.*?.com/gm.exec(href)[0]).slice(1);
  //   let emailButton ="<button onclick='location.href=" + '"' + href + '"' + ";' target='_blank' rel='noopener' class='emailButton'>" + "<img src='/assets/email-icon.png' style='width: 18px; height: 18px;' alt='PDF file'>" + " EMAIL: " + emailText + "</button>";

  //   return emailButton;
  // }

  /**********************************************************************************
   * Method Name: getPersonalData                                                                 
   * Description: Get getPersonalData content                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 05/15/19             Katrina Narag               Initial draft       
   *********************************************************************************/
  getPersonalData(){
    try {
      this.bodyData[5] = PageSection.personalDataChange + this.bodyData[0];
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
        try {
          let personalData = JSON.parse(data.body);
          if (personalData != undefined) {
           if (personalData.data) {
              this.personalDataContentData = personalData.data;  
              if (this.personalDataContentData){
              
                let content = this.personalDataContentData; 
                this.personalDataContent =  content[0].Body_Desc; 

              
                this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
                switch (this.user.EmployeeStatus) {
                  case "Leave without credit":
                  case "Leave of Absence":
                  case "Active":
                  case "Leave with pay":
                    if(this.personalDataContent.indexOf("FORMER Employees") != -1){
                      this.personalDataContent = this.personalDataContent.substring(0, this.personalDataContent.indexOf("FORMER Employees")) + 
                    this.personalDataContent.substring(this.personalDataContent.indexOf("Your myHoldings"), this.personalDataContent.length);
                    }
                  break;
                  
                  case "Terminated":
                  case "Deceased":
                  case "Retired":
                    if (this.personalDataContent.indexOf("ACTIVE Employees") != -1 ) {
                      this.personalDataContent = this.personalDataContent.substring(0, this.personalDataContent.indexOf("ACTIVE Employees")) + 
                    this.personalDataContent.substring(this.personalDataContent.indexOf("FORMER Employees"), this.personalDataContent.length);
                    }                    
                  break;
                
                  default:
                    this.personalDataContent = this.personalDataContent;
                    break;
                }
                
                
            }
              
            } else{
              this.personalDataContent = "";
            }
          } else{
            this.personalDataContent = "";
          }
          this.getContactsContent();
        } catch(error) {
          this.errorContacts = true;
          let errorLocation = this.errorLocation.replace("methodName", "getPersonalData");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorContacts = true;
        let errorLocation = this.errorLocation.replace("methodName", "getPersonalData");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorContacts = true;
      let errorLocation = this.errorLocation.replace("methodName", "getPersonalData");
      error.message += errorLocation;
      throw error;
    }
  }

  /**************************************************************************************************
        Method Name: getFileName
        Description: Redirect to a page or download file

        Date            Modified by                 Description    
        05/31/19        KMN                         Bug 320087 fix - when user clicks the link it opens new tab, at the same time it redirected to the the page in the same tab
  *****************************************************************************************************/
  getFileName(eventBody) {
    try {
      let href: string = eventBody.target.href;
      let id: string = eventBody.target.id;
      let srcElement: HTMLImageElement = eventBody.srcElement;
      let target: string = eventBody.target.target;
      let reportId: object;
      
      if(srcElement.src != undefined){
        this.staticContent.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContent.validateFileType(event);
        } else if(href.indexOf("reportid") != -1) {
          id = href.substring(href.lastIndexOf("=") + 1);
          reportId = {'ReportTypeId': id};
          sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
          window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
          return false;
        } else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          let innerTxt = eventBody.target.innerText;
          event.preventDefault();

          if(innerTxt in MyHoldingsPagesResources){
            this.navToMyHoldingsPage(href);
          }else if(href.indexOf("Resources") != -1){
            this.navToMyHoldingsPage("Resources");
          } else if(href.indexOf("Reports")) {
            sessionStorage.setItem("REPORT ID", JSON.stringify(null));
            this.navToMyHoldingsPage("Reports");
          }

        }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
          eventBody.preventDefault();
          id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
        }else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
          if(target == null || target == ""){
            target = "_self"
          }
          eventBody.preventDefault();
          
          window.open(href, target);
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

   /**************************************************************************************************
        Method Name: navToMyHoldingsPage
        Description: Redirect to main myholdings page

        Date            Modified by                 Description    
        05/21/19        KMN                         Iitial draft
  *****************************************************************************************************/
  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "contactspage" : "";
    page.indexOf("Reports") != -1 ? page = "reportspage" : "";

    window.open(this.globals.currentWebUrl + "/" + page, "_blank");
  }

}
