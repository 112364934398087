import { Component, OnInit, Type } from '@angular/core';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
// import { MatTableDataSource} from '@angular/material';
import { ApiService } from '../../services/ea/api/ApiService';
import { SemlinkaccessService } from '../../services/ea/api/SemlinkaccessService';
import { Globals } from '../../globals';


@Component({
  selector: 'app-sem',
  templateUrl: './sem.component.html',
  styleUrls: ['./sem.component.css']
})

export class SemComponent implements OnInit {
  headerTitle: string = 'Partner Data System Archive';
  buttonSelected: boolean = true;
  psdaButtonsDisplay: boolean = true;
  displayedColumns: string[] = ['eid', 'location', 'status'];
  dataSource: any;
  initVal: string;
  selectedReports: boolean = false;

  editPartner: boolean = true;
  roleID: any;
  semPdsaReport: any;

  menuTypeList: any[];
  menuTypeListMain: any = [];

  constructor(    
    private router: Router,
    private slas: SemlinkaccessService,
    private apiService: ApiService,
    private globals: Globals
    
    ) {
      
     }

  ngOnInit() {   
    this.menuTypeList = [
      {menu: 'Partner Data System Archive', access: this.slas.getSemMenuLink()[5]}
    ];
    this.initVal = this.menuTypeList[0].menu;
    this.removeFromMenu();
    this.router.navigate(['equityadmin/sem/pdsa']);
    this.getSemAccess();
  }
  /**********************************************************************************
  * Method Name: partnerSelection                                                                 
  * Description: highlights what options selected                
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1                              Jikho S. Enrique                 Initial draft  
  ***********************************************************************************/
  partnerSelection(button){
    if(button != "ps"){
      this.buttonSelected = false;
      this.selectedReports = true;
      this.router.navigate(['equityadmin/sem/pdsa'])
    }else{
      this.buttonSelected = true;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>this.router.navigate(['equityadmin/sem/pdsa']));
    }
  }
  /**********************************************************************************
  * Method Name: getValue                                                                 
  * Description:                    
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         3/16/2020            Joshua Paul E. Peralta           Initial draft  
  ***********************************************************************************/
  getValue(value): void{ //Gets value from dropdown menu - JPEP
    this.initVal = value
    if(this.initVal == "Senior Executive Equity"){
      this.headerTitle = this.initVal; //sets the value from dropdown to the selected option - JPEP
      //redirect to SEE content
    }else if(this.initVal == "Senior Executive Equity Transaction"){
      this.headerTitle = this.initVal;
      //redirect to SEET content
    }else if(this.initVal == "Reporting Tool"){
      this.headerTitle = this.initVal;
      //redirect to RT content
    }else if(this.initVal == "Access Maintenance"){
      this.headerTitle = this.initVal;
      //redirect to AM content
    }else if(this.initVal == "File Archive"){
      this.headerTitle = this.initVal;
      //redirect to FA content
    }else{
      this.headerTitle = this.initVal;
      //return to PDSA content
    }
  }

  /**********************************************************************************
  * Method Name: redirectTo()                                                                 
  * Description: Redirects user by click the breadcrumbs                    
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         3/16/2020            Joshua Paul E. Peralta           Initial draft  
  ***********************************************************************************/ 
  redirectTo(uri){
    this.headerTitle = uri;
    if(this.headerTitle == "Partner Data System Archive"){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>this.router.navigate(['equityadmin/sem/pdsa']));
    } else if(this.headerTitle == "Equity Admin"){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>this.router.navigate(['equityadmin/eamain']));
    }
  }
  removeFromMenu(){
    try {
      this.menuTypeListMain = [];
      for (let i = 0; i < this.menuTypeList.length; i++) {
        if (this.menuTypeList[i].access == true) {
          this.menuTypeListMain.push({ menu: this.menuTypeList[i].menu })
        }
      }
    } catch (error) {
      error.message += this.apiService.setErrorContents('removeFromMenu', 'sem');
      throw error
    }
  }

   /**********************************************************************************
  * Method Name: getSemAccess                                                                
  * Description: Redirects user by click the breadcrumbs                    
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         6/11/2020            Joshua Paul E. Peralta           Initial draft  
  ***********************************************************************************/ 
  getSemAccess() {

    let accessKey: any = {
      "functionName": "getSecPageAccess"
    }

    this.apiService.postData(this.globals.api_URL_accessTool, accessKey).subscribe(data => {
      try {
        if (data) {
          let contentBody = data.body;
          if (contentBody != null && contentBody != undefined) {
            if ((contentBody['data'][0][0]['semCard'] == "1") && (contentBody['data'][6][0]['semCardPdsa'] == "1")) {
              contentBody['data'][8][0]['ROLE'] == null || contentBody['data'][8][0]['ENTERPRISE_ID'] == null ? this.router.navigate(['equityadmin/eamain']) : "";
              this.roleID = contentBody['data'][8][0]['ROLE'];
              this.semPdsaReport = contentBody['data'][7][0]['semTabPdsReport'];
              if ((this.roleID == 2 || this.roleID == 3) || this.semPdsaReport == 0) {
                this.editPartner = false;
              } else {
                this.editPartner = true;
              }
            } else {
              this.router.navigate(['equityadmin/eamain']);
            }
          } else {
            this.router.navigate(['equityadmin/eamain']);
          }
        }
      } catch (error) {
        error.message += this.apiService.setErrorContents('getSemAccess', 'sem');
        throw error;
      }

    }, error => {
      error.error.message += this.apiService.setErrorContents('getSemAccess', 'sem');
      throw error;
    });

  }

}
