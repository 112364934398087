
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap,  mergeMap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { UserService } from './UserService';
import { Globals } from '../globals';
// import { Http } from '@angular/http';
import { Router } from "@angular/router";
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class MsalInterceptor implements HttpInterceptor {
    constructor(private msalService: MsalService, private auth: MsalService, private userService: UserService, private globals: Globals, private router: Router ) {}

    /********************************************************************************************************************************************
   * Method Name: clearAndReload                                                                 
   * Description: Clearing the cache when token expires.
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    05/09/19             Joshua Paul J. Avecilla(JPA)          Initial draft
   *******************************************************************************************************************************************/
  clearAndReload(){
    //  alert('Your session has expired.');
    //  sessionStorage.clear();
    //  window.location.reload();
    //localStorage.setItem("expire token prev url", window.location.toString());
    window.location.reload(); 
    sessionStorage.clear();
    localStorage.clear();
  }

  relogUser(){
    this.clearAndReload();
    this.navToPage('landingpage');
  }

    /********************************************************************************************************************************************
   * Method Name: expiredTokenReload                                                                 
   * Description: Clearing the cache when token expires.
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    12/09/20             Marie Antoinette Masayon(MAM)          Initial draft
   *******************************************************************************************************************************************/
  expiredTokenReload(){
     sessionStorage.clear();
     //window.location.reload();
     this.navToPage('landingpage');
      //sessionStorage.setItem("expire token prev url", window.location.toString());
      //this.navToPage('homepage');
  }
 /********************************************************************************************************************************************
   * Method Name: redirectErrorPage                                                                 
   * Description: Navigate to Error Page when the request URL is invalid
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    07/31/19             Iris Jane R. Manloloyo               Initial draft
   *******************************************************************************************************************************************/
  redirectErrorPage(){
    let url: string = this.router.url;
    if (url.indexOf("xxx") || url.indexOf("yyy") || url.indexOf("***")){
      this.navToPage('**');
    }
  }

    /********************************************************************************************************************************************
   * Method Name: navToPage                                                                 
   * Description: Navigates to different page.
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    05/09/19             Joshua Paul J. Avecilla(JPA)          Initial draft
   *******************************************************************************************************************************************/
  navToPage(strLoc){
    this.router.navigate([strLoc]);  
  }

  setHeader(req: HttpRequest<any>){
    const { 'msal.idtoken': token }: any = sessionStorage;
      
    let H = new HttpHeaders();
    H = req.headers;
    if(req.url.includes("/profilepic/getProfilePic")){
      return req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` || ''
        }
      });
    } else if(req.url.includes("equityadmin-api")){
      return req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': token || ''
        }
      });
    }
    else{
      return req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` || ''
        }
      });
    }
  }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
  
  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try{
      return next.handle(this.setHeader(req)).pipe(
      tap((event: HttpEvent<any>) => {
        if(event instanceof HttpResponse) {
          const { type, body } = event;
          if(type === 4 && typeof body !== 'undefined' && ('body' in body || body.statusCode )){
            if(body.status == 401 || body.statusCode == 401){
               if (!req.url.includes("equityadmin-api")) {
                  return this.relogUser();
               }
            }else{
              if (!req.url.includes("equityadmin-api")) {
                const parsedBody = JSON.parse(body.body);
                const { statusCode } = parsedBody;
                if(statusCode === 401){
                  return this.relogUser();                  
                }                
              }
            }
          }
        }
        return event;
      }),
      catchError((error: any) => {
        const { status } = error;
        switch(status){
          case 401: 
            this.relogUser();
            return;
          case 500:
          default: return observableThrowError(error);
        }        
      }),); 
    }catch(err){
      return observableThrowError(err);
    }
  }
}

