import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../api/ApiService';
import { Globals } from '../../../../globals';

@Component({
  selector: 'app-perfunitmodal',
  templateUrl: './perfunitmodal.component.html',
  styleUrls: ['./perfunitmodal.component.css']
})
export class PerfunitmodalComponent implements OnInit { 
  header: string;
  label: string;
  label1: string;
  label2: string;
  label3: string;
  result: string;
  result1: string;
  result2: string;
  result3: string;
  hasBtn1: boolean = false;
  hasBtn2: boolean = false;
  btn1Name: string = null;
  btn2Name: string = null;
  coeffData: any = [];
  perfTotalUnit: any;
  perfUnitCash: any;
  perfUnitsEquity: any;
  modalType: boolean;
  optNum: number;
  countryCoeffData: any;
  countryCoeff: number = 0;
  coeffCountryValue: string;
  coeffValue: number;
  coeffPercentValue: number;
  actualUnitsValue: number;
  loading: boolean = true;
  isError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService, 
    private global: Globals
  ) { }

  ngOnInit() {
    this.optNum = parseInt(sessionStorage.getItem("optNum"));
    this.getModal(this.data[0]['type']);
  }

    /**********************************************************************************
  * Method Name: getCoeffData                                                                 
  * Description: To get the Country Coeff values              
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  *1                             Divy O. Ardiente                   API connection 
  ***********************************************************************************/
  getCoeffData() {
    let argCountryCoeff: any = {
      "selected_optnum": this.optNum,
      "uf_year": this.data[0]['fiscalYear'],
      "functionName": "getCountryCoeff"
    }
    this.apiService.postData(this.global.api_URL_pdsUnit, argCountryCoeff).subscribe(data => {
      try {
        if (data) {
          let contentBody = data.body;

          if (contentBody != null && contentBody != undefined) {
            for (let i = 0; i < contentBody['data'][0].length; i++) {
              this.coeffData.push({
                countryName: contentBody['data'][0][i]['COUNTRY_NAME'], coefficient: contentBody['data'][0][i]['COEFFICIENT'],
                coeffPcnt: contentBody['data'][0][i]['COEFF_PCNT'], actualUnits: contentBody['data'][0][i]['ACTUAL_UNITS']
              })
            }
            this.loading = false;
          }
        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getCoeffData', 'perfunitmodal');
        throw error
      }

    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getCoeffData', 'perfunitmodal');
      throw error;
    });
  }

    /**********************************************************************************
  * Method Name: getPerfData                                                                 
  * Description: To get the Perf units values                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  *1                             Divy O. Ardiente                  API connection
  ***********************************************************************************/
  getPerfData(){
    let argPerfUnit: any = {
      "selected_optnum": this.optNum,
      "uf_year": this.data[0]['fiscalYear'],
      "functionName": "getPerfUnit"
    }
    this.apiService.postData(this.global.api_URL_pdsUnit, argPerfUnit).subscribe(data => {
      try {
        let contentBody = data.body;
        if (contentBody != null && contentBody != undefined) {
          this.perfTotalUnit = contentBody['data'][0][0]['PERF_TOTAL_UNIT'];
          this.perfUnitCash = contentBody['data'][0][0]['PERF_UNITS_CASH'];
          this.perfUnitsEquity = contentBody['data'][0][0]['PERF_UNITS_EQUITY'];
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getPerfData', 'perfunitmodal');
        throw error
      }

    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getPerfData', 'perfunitmodal');
      throw error;
    });

  }

  getModal(modalType){
    try {
      if (modalType == 'CC') {
        this.header = this.data[0]['header'];
        this.getCoeffData();
        this.modalType = true;
      } else if (modalType == "P") {
        this.header = this.data[0]['header'];
        this.getPerfData();
        this.modalType = false;
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getModal', 'perfunitmodal');
      throw error;
    }

  }
}
