import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
selector: '[sem-letters-Only]'
})
export class SemLetterDirective {
    constructor(private _el: ElementRef) { }
        @HostListener('input', ['$event']) onInputChange(event) {
            const initialValue = this._el.nativeElement.value;
            this._el.nativeElement.value = initialValue.replace(/[^a-z-A-Z'-]*/g, '');
            if (initialValue !== this._el.nativeElement.value) {
                event.stopPropagation();
            }
            
    }
}
