import { Component, ViewChild, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import 'hammerjs';
import { Location } from '@angular/common';
import { PlatformLocation } from '@angular/common';
import { RoutingState } from './services/RoutingState';
import { EaLoadingService } from './services/ea/EaLoadingService';
import { EaUserAccessService } from './services/ea/EaUserAccessService';
import { myHoldingsPages } from './services/enumerations/enumerations'; 
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { MsalGuardService } from './services/msal/msal-guard.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isIframe = false;
  eaLoading: boolean = false; 
  private readonly _destroying$ = new Subject<void>();
  showNav: boolean = false;
  public appNavHeaderVisible: boolean;
  public appFooterVisible: boolean;
  private previousURL: string;
  private currentURL: string;
  private routeHistory: string[];
  loginDisplay = false;
  hasShares: boolean = true;//MAM
  hasNoShareHistory: boolean = false;//MAM
  errorLocation: string = " + [Component: AppComponent, Method: methodName]";

  hasEaAccess: boolean;
  currentWebUrl = window.location.toString();

constructor(private titleService: Title, 
            private router: Router, 
            private location: Location, 
            private routingState: RoutingState,
            private eaUserAccess: EaUserAccessService,
            private msalBroadcastService: MsalBroadcastService,
            private authService: MsalService,
            private msalService: MsalGuardService,
            private loadingService: EaLoadingService) {

    this.titleService.setTitle('myHoldings'); 
    const self = this;
    this.appNavHeaderVisible = false;
    this.appFooterVisible = false;

    routingState.loadRouting();
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.HANDLE_REDIRECT_END),
    )
    .subscribe((result: EventMessage) => {
      const payload = result.payload as AuthenticationResult;
      let token = sessionStorage.getItem("msal.idtoken");
        if (token == null || token == '' && result.eventType === EventType.LOGIN_SUCCESS) {
          console.log("User session...");
          sessionStorage.setItem("msal.idtoken", payload.idToken);
          sessionStorage.setItem("ddrumsdk.idtoken", payload.idToken); // JRL 941031 - CENTRALIZED RUM SDK
          // console.log("ddrumsdk.idtoken", token);
        } 
    });
  
    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
      this.checkAndSetActiveAccount();
    })
  
    /*Tracks the URL and navigates to "previous" and "forward" URL */
    // this.routeHistory = [];
    // router.events
      // .pipe(filter(event => event instanceof NavigationEnd))
      // .subscribe((event: NavigationEnd) => {
        // this.setURLs(event);
      // });
    /*End of URL Tracker*/

    // this.routeHistory = [];
    // this.router.events
    //   .pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe(({urlAfterRedirects}: NavigationEnd) => {
    //     this.routeHistory = [...this.routeHistory, urlAfterRedirects];
    //   });

      

    // platformLocation.onPopState(() => {
    //   history.back();
    //   history.pushState(null, null, window.location.pathname);
    // });

    // backClicked()
    // {
    //   this.location.back();
    // }
      

    /*Tracks the URL and navigates to "previous" and "forward" URL */
    // router.events.pipe(
    //                     filter(
    //                             (event:NavigationEvent) =>
    //                               {
    //                                 return (event instanceof NavigationStart);
    //                               }
    //                           )
    //                   )
    //              .subscribe(
    //                           (event:NavigationStart) =>
    //                             {
    //                               console.group("NavigationStart Event");
    //                                                            
    //                               console.groupEnd();
    //                             }
    //                        );
    /*End of URL Tracker*/
    

    //FOR LATER USE
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        //MAM
        if (sessionStorage.getItem("No Share Indicator")=="True"){
          if (event['url'] != "/technologysupport" && event['url'] != "/" && event['url'] != "/landingpage") {
            this.hasShares = false;
            this.hasNoShareHistory = true;
          } else{
            this.hasShares = true;
            this.hasNoShareHistory = false;
          }
        }
        //MAM
        if (event['url'] == "/" || event['url'] == "/landingpage") {
          this.showNav = false;
          if (event['url'] == "/"){
            if (sessionStorage.getItem("First Time Login")=="True"){
              //START: KDP

              let currentUrl = window.location.toString();
              var url;
              if (currentUrl.indexOf("myholdings-pt")  > -1) {
                url = "https://myholdings-pt.ciotest.accenture.com/";
              } else if (currentUrl.indexOf("myholdings.stage") > -1) {
                url = "https://myholdings.ciostage.accenture.com/";
              } else {
                url = "https://myholdings.accenture.com/";
              }
              // let url = currentUrl.split('.com/')[1];
              // let url = sessionStorage.getItem("msal.login.request").split('.com/')[1];
              let tempurl;
              let urlChecker = false;
              for (let i = 1; i <= 24; i++) {
                if (url == myHoldingsPages[i]) {
                  tempurl = myHoldingsPages[i]
                  urlChecker = true;
                }
              }
              if (urlChecker) {
                if (tempurl == myHoldingsPages[2] || tempurl == myHoldingsPages[4] || tempurl == myHoldingsPages[6] || tempurl == myHoldingsPages[12] ||
                  tempurl == myHoldingsPages[21] || tempurl == myHoldingsPages[23] || tempurl == myHoldingsPages[24]) {
                  //this.navToPage(url);
                } else {
                  this.navToPage('homepage');
                }
              }
              //END: KDP
            }
          } 
        } else if(event['url'] == "/homepage"){
          this.showNav = true;
          //this.checkAndSetActiveAccount();
        } else{
          this.showNav = true;
        }
      }
    });

		
    if(sessionStorage.getItem("user input url") != undefined && sessionStorage.getItem("user input url") != "" && sessionStorage.getItem("user input url") != null){
      sessionStorage.setItem('postBack', 'true');
    }

    // Equity Admin, DPS - 07/06/2020 - Added loading for equity admin page 
    this.loadingService.loading$.subscribe(state => {
      this.eaLoading = state;
    });

    this.eaUserAccess.userAccess$.subscribe( access => {
      this.hasEaAccess = access;
    });

  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

 checkAndSetActiveAccount() {
    if((window.location.toString() != (this.currentWebUrl + "/homepage") || 0 <= window.location.toString().indexOf(this.currentWebUrl + "/homepage#id_token")) && (sessionStorage.getItem('postBack') == undefined || sessionStorage.getItem('postBack') == null || sessionStorage.getItem('postBack') == '' )){
      sessionStorage.setItem("user input url", window.location.toString());
    }
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    let loginStatus = sessionStorage.getItem("First Time Login");
    const token = JSON.parse(Object.entries(localStorage).filter(([key]) => key.includes('idtoken'))[0][1]).secret;
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0 && loginStatus == "True") {
        let accounts = this.authService.instance.getAllAccounts();
        sessionStorage.setItem("First Login", "True");
        if(sessionStorage.getItem("First Login") == "True") {
          this.router.navigate(['homepage']);
        }
        this.router.navigate(['homepage']);
        console.log("Homepage...");
    } else if (!activeAccount && this.authService.instance.getAllAccounts().length > 0 && loginStatus == "False") {
        console.log("Other page...");
    } else if (token != null && loginStatus == undefined || loginStatus == null) {
        let currentURL = this.currentWebUrl;
        var url;
        if (currentURL.indexOf("myholdings-pt")  > -1) {
          url = "https://myholdings-pt.ciotest.accenture.com/";
        } else if (currentURL.indexOf("myholdings.stage") > -1) {
          url = "https://myholdings.ciostage.accenture.com/";
        } else {
          url = "https://myholdings.accenture.com/";
        } 
          if (url != this.currentWebUrl) {
            this.router.navigate(['landingpage']);
            console.log("Landingpage...");
          }
      } else {
          console.log("No session");
    } 
}

/*Tracks the URL and navigates to "previous" and "forward" URL */
  setURLs(event: NavigationEnd): void
  {
    const tempURL = this.currentURL;
    this.previousURL = tempURL;
    this.currentURL = event.urlAfterRedirects;
    this.routeHistory.push(event.urlAfterRedirects);
  }

  getPreviousURL(): string
  {
    return this.previousURL;
  }

  getCurrentURL(): string
  {
    return this.currentURL;
  }

  getRoutHistory(): string[]
  {
    return this.routeHistory;
  }
/*End of URL Tracker*/

  // runAuthentication() {
  //   const self = this;
    
  //   try{
  //     self.authService.setup();

  //     if(!self.authService.authenticationEnabled()){
  //       self.isVisible = true;    
  //       self.isProcessing = false; 
  //     }else if ((self.authService.authenticationEnabled() && self.authService.IsAuthenticated()) === true){
  //       self.isVisible = true;  
  //       self.isProcessing = false; 
  //     }else{
  //       self.hasError = true;
  //       self.appMessage = self.unAuthorizedMessage;
  //     }
    
  //   }catch(err){
  //     const { message } = err;
  //     self.hasError = true;
  //     self.appMessage = 'Something went wrong. ' + (message ? message : 'Server error');
  //   }
  // }

  backClicked()
  {
    this.location.back();
  }
  //MAM
  navToPage(strLoc) {
    try {
        
        this.router.navigate([strLoc]);

    } catch(error) {
      // sessionStorage.clear();
      // window.location.reload();
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

}
