
import {refCount, publish} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class EaUserAccessService {

  private _userAccess: BehaviorSubject<boolean> = new BehaviorSubject(true);

  readonly userAccess$: Observable<boolean> = this._userAccess.asObservable().pipe(publish(),refCount(),);

  constructor() { }

  setUserAccess(state: boolean): void {
    this._userAccess.next(state);
  }

}
