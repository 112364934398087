import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AccountNumberItem } from '../../../../../services/ea/models/accountnumber-model';
import { AccountNumberItemState } from '../../../../../services/ea/states/accountnum.state';
import { Select, Store } from '@ngxs/store';
import { Globals } from '../../../../../globals';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { BdrLoadingService } from '../../../../../services/ea/api/BdrLoadingService';
import { RoleaccessService } from '../../../../../services/ea/api/RoleaccessService';
import { AccountNumber } from '../../../../../services/ea/actions/accountnum.actions';
import { ModalComponent } from '../../../../../services/ea/shared/modal/modal.component';
import { LoadingOverlayComponent } from '../../../../../services/ea/shared/loading-overlay/loading-overlay.component';
import * as moment from 'moment';
import { BdraccessService } from '../../../../../services/ea/api/BdrAccessService';

@Component({
  selector: 'app-anfieldinfo',
  templateUrl: './anfieldinfo.component.html',
  styleUrls: ['./anfieldinfo.component.css']
})
export class AnfieldinfoComponent implements OnInit {

  displayedColumns: string[];
  dataSource: MatTableDataSource<AccountNumberItem>;
  tableData: AccountNumberItem[] = [];
  dataToUpdate: AccountNumberItem[] = [];
  pageSizeOption: number[];
  pageSizeLabel: string = "Show";
  pageIndex: number;
  totalNumOfPage: number;
  tableHasData: boolean = true;
  disableSubmitButton: boolean = true;
  entriesStart: number;
  entriesEnd: number;
  entriesLength: number;
  origEntriesLength: number;
  filterValue = {
    ACCOUNT_NAME_DESC: '',
    EFFECTIVE_POSTING_DATE: '',
    ACCOUNT_NUMBER: ''
  }

  @Select(AccountNumberItemState.getAccountNumItems) accountNumItems: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input('equityTypeList') equityTypeList: any;
  @Input('accNoStaticText') accNoStaticText: any;
  setDate: string = "Account Number";
  onloadTableDataString: string;
  onloadTableStringArray: string[] = [];
  modalHeader: string;
  modalConfirmBody: string;
  modalUnAuthBody: string;
  modalUnchangedBody: string;
  modalSuccessBody: string;
  modalUnSuccessBody: string;
  tableHeaderAccno: string;
  tableHeaderEqType: string;
  tableHeaderAccName: string;
  tableHeaderEpd: string;
  modalEmptyAccnoBody: string;
  roleId: number = this.roleAccessService.getUserRoleAccess();
  hasBlankAccNumber: boolean;
  isError: boolean = false;

  constructor(
    private globals: Globals,
    private modal: MatDialog,
    private apiService: ApiService,
    private store: Store,
    private accountNumLoadingService: BdrLoadingService,
    private roleAccessService: RoleaccessService,
    private bdrAccessService: BdraccessService
  ) { }

  ngOnInit() {
    this.pageSizeOption = this.globals.reportsPageSizeOptions;
    this.displayedColumns = this.globals.accountNumDisplayedColumns;
    this.setStaticText();
    this.accountNumItems.subscribe( data => {
      try {
        this.onloadTableDataString = JSON.stringify(data);
        for (let i = 0; i < data.length; i++) {
          this.onloadTableStringArray.push(JSON.stringify(data[i]));
        }
        this.tableData = data;
        this.dataSource = new MatTableDataSource(this.tableData);
        this.origEntriesLength = this.tableData.length;
      } catch(error) {
        this.bdrAccessService.setAccountNumberError(true);
        error.message += this.apiService.setErrorContents('ngOnInit', 'Anfieldinfo');
        throw error;
      }
    })
  }

  ngAfterViewInit(){
    this.dataSource.filterPredicate = (data) => (data.ACCOUNT_NAME_DESC.trim().toLowerCase().indexOf(this.filterValue.ACCOUNT_NAME_DESC.trim().toLowerCase()) !== -1 ||
                                                      data.ACCOUNT_NUMBER.trim().toLowerCase().indexOf(this.filterValue.ACCOUNT_NUMBER.trim().toLowerCase()) !== -1 ||
                                                      data.EFFECTIVE_POSTING_DATE.toString().trim().toLowerCase().indexOf(this.filterValue.EFFECTIVE_POSTING_DATE.trim().toLowerCase()) !== -1);
      this.dataSource.paginator = this.paginator;
      this.paginator.pageSize = this.pageSizeOption[0];
  }

  ngAfterContentInit(){
    if(this.paginator) {
      this.paginator._intl.itemsPerPageLabel = this.pageSizeLabel;
    }
  }

  ngAfterContentChecked(){
    if(this.paginator) {
      this.getNumberOfPage();
    }
  }

  /**********************************************************************************
 * Method Name: setStaticText                                                                 
 * Description: Sets the values of modal header and table tabs.                   
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/12/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/ 
 setStaticText(){
    try {
      this.modalHeader = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.header")].MESSAGE_VALUE;
      this.modalConfirmBody = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.modalconfirm.body")].MESSAGE_VALUE;
      this.modalUnAuthBody = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.modalunauth.body")].MESSAGE_VALUE;
      this.modalUnchangedBody = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.modalunchanged.body")].MESSAGE_VALUE;
      this.modalSuccessBody = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.modalsuccess.body")].MESSAGE_VALUE;
      this.modalUnSuccessBody = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.modalunsuccess.body")].MESSAGE_VALUE;
      this.modalEmptyAccnoBody = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.modalemptyaccno.body")].MESSAGE_VALUE;
      this.tableHeaderAccno = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.tableheader.accno")].MESSAGE_VALUE;
      this.tableHeaderEqType = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.tableheader.eqtype")].MESSAGE_VALUE;
      this.tableHeaderAccName = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.tableheader.accname")].MESSAGE_VALUE;
      this.tableHeaderEpd = this.accNoStaticText[this.getDataIndex("ea.bdr.accno.tableheader.epd")].MESSAGE_VALUE;
    } catch (error) {
      this.isError = true;
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('setStaticText', 'Anfieldinfo');
      throw error;
    }
 }

  /**********************************************************************************
   * Method Name: getDataIndex                                                                 
   * Description: Returns the index of the given key.                       
   *                                                                                          
   * #         Date                 Modified by                    Description                             
   * 1         02/19/20             John Paul B. Fernando          Initial draft  
   ***********************************************************************************/
  getDataIndex(key){
    try {
      let index;
      for (let i = 0; i < this.accNoStaticText.length; i++) {
        if (this.accNoStaticText[i].MESSAGE_NAME == key) {
          index = i
        }
      }
      return index;
    } catch (error) {
      this.isError = true;
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('getDataIndex', 'Anfieldinfo');
      throw error;
    }
  }
  
  /**********************************************************************************
  * Method Name: getNumberOfPage                                                                 
  * Description: Sets the number of pages and the index of the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/22/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getNumberOfPage(){
    try {
      let dataLength;
      dataLength = this.filterValue.ACCOUNT_NAME_DESC != "" ? this.dataSource.filteredData.length : this.dataSource.data.length;
      dataLength > 0 ? this.entriesStart = 1 + (this.paginator.pageIndex * this.paginator.pageSize) : this.entriesStart = 0;
      this.entriesEnd = dataLength < this.paginator.pageSize ? dataLength : this.paginator.pageSize + (this.paginator.pageSize * this.paginator.pageIndex);
      this.entriesEnd =  this.entriesEnd > dataLength ? dataLength : this.entriesEnd;
      this.entriesLength = dataLength;
      this.pageIndex = this.paginator.pageIndex + 1;
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;      
    } catch (error) {
      this.isError = true;
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('getNumberOfPage', 'Anfieldinfo');
      throw error;
    }      
  }
  
  /**********************************************************************************
  * Method Name: applyFilter                                                                 
  * Description: Apply filter using the entered word in search box.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         5/14/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  applyFilter(filterVal: string) {
    try { 
      let filterValue = filterVal; 
      this.filterValue.ACCOUNT_NAME_DESC = filterValue;
      this.filterValue.EFFECTIVE_POSTING_DATE = filterValue;
      this.filterValue.ACCOUNT_NUMBER = filterValue;
      this.dataSource.filter = JSON.stringify(this.filterValue);
      this.tableHasData = this.dataSource.filteredData.length > 0;
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;
      this.paginator.firstPage();
    } catch (error) {
      this.isError = true;
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('applyFilter', 'Anfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: updateData                                                                 
  * Description: Updates the data of the table and compare to stringified table data to check if changes were made.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/27/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  updateData(type,event, id){
    try {
      this.hasBlankAccNumber = false;
      switch (type) {
        case 'accNum':
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].ID == id) {
              this.tableData[i].ACCOUNT_NUMBER = event.replace(/\D/g,'');
            }
          }
          break;
        
        case 'date':
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].ID == id) {
              this.tableData[i].EFFECTIVE_POSTING_DATE = moment(new Date(event)).format("MM/DD/YYYY")
            }
          }
          break;
      
        default:
          break;
      }
      for (let i = 0; i < this.onloadTableStringArray.length; i++) {
        if (this.onloadTableStringArray[i] != JSON.stringify(this.tableData[i])) {
          if (this.tableData[i].ACCOUNT_NUMBER == "") {
            this.hasBlankAccNumber = true;
          }
        }
      }
      this.disableSubmitButton = (this.onloadTableDataString == JSON.stringify(this.tableData) || this.hasBlankAccNumber);
    } catch (error) {
      this.isError = true;
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('updateData', 'Anfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openModalOverlay                                                                 
  * Description: Opens an overlay loading icon.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openModalOverlay(){
    try {
      this.modal.open(LoadingOverlayComponent, {disableClose: true, panelClass: 'overlay-modal'});
    } catch(error) {
      this.modal.closeAll();
      this.isError = true;
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('openModalOverlay', 'Anfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getEquityId                                                                 
  * Description: Gets the equity type id.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B. Fernando          Initial draft 
  ***********************************************************************************/
  getEquityId(type){
    try {
      for (let i = 0; i < this.equityTypeList.length; i++) {
        if (type == this.equityTypeList[i].Equity_Type_Desc) {
          return this.equityTypeList[i].Equity_Type_ID;
        }
      }
    } catch (error) {
      this.isError = true;
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('getEquityId', 'Anfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openErrorModal                                                                 
  * Description: Opens an error modal if there is an error in the api call.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openErrorModal(){
    try {
      let content: any = {
        header: this.modalHeader,
        body: this.modalUnSuccessBody,
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: "OK",
        btn2Name: ""
      }
      let errorModal = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });
  
      errorModal.afterClosed().subscribe( result => {
        if (result) {
          this.modal.closeAll();
        }
      })
    } catch(error) {
      this.modal.closeAll();
      this.isError = true;
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('openErrorModal', 'Anfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getAccNumRow                                                                 
  * Description: Creates the acctNumRow based on changed items.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/03/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getAccNumRow(){
    try {
      let result = "";
      for (let i = 0; i < this.dataToUpdate.length; i++) {
        result += this.dataToUpdate[i].ACCOUNT_NUMBER + "~" + this.dataToUpdate[i].ACCOUNT_NAME_ID + "~"  + this.getEquityId(this.dataToUpdate[i].EQUITY_TYPE_DESC) + "~" + this.dataToUpdate[i].EFFECTIVE_POSTING_DATE +"|";
      }
      return result;
    } catch (error) {
      this.bdrAccessService.setAccountNumberError(true);
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccNumRow', 'Anfieldinfo');
      throw error
    }
  }

  
  /**********************************************************************************
  * Method Name: submitChanges                                                                 
  * Description: Submits the changes made in the table and reloads the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/03/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  submitChanges(){
    try {
      window.scroll(0,0);
      if (this.roleId == 7 || this.roleId == 6) {
        this.openUnAuthModal();
      }else{
        if (this.onloadTableDataString == JSON.stringify(this.tableData)) {
          this.openUnchangedModal();
        }else{
          if (this.hasBlankAccNumber) {  
            this.openEmptyAccNumModal();   
            return      
          }
          this.dataToUpdate = [];
          for (let i = 0; i < this.onloadTableStringArray.length; i++) {
            if (this.onloadTableStringArray[i] != JSON.stringify(this.tableData[i])) {
              this.dataToUpdate.push(this.tableData[i]);
            }
          }
          this.openConfirmModal();
        }
      }      
    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('submitChanges', 'Anfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: openUnAuthModal                                                                 
  * Description: Opens an unauth modal when user forces to enable submit button.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
 openUnAuthModal(){
   try {
    let content: any = {
      header: this.modalHeader,
      body: this.modalUnAuthBody,
      hasBtn1: true,
      hasBtn2: false,
      btn1Name: "OK",
      btn2Name: ""
    }
    this.modal.open(ModalComponent, {
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        content
      }
    });
   } catch (error) {
     this.modal.closeAll();
     this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('openUnAuthModal', 'Anfieldinfo');
     throw error;
   }
 }


 /**********************************************************************************
 * Method Name: openUnchangedModal                                                                 
 * Description: Opens a modal when the user tries to submit while there is no changes in the table.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
 openUnchangedModal(){
   try {
    let content: any = {
      header: this.modalHeader,
      body: this.modalUnchangedBody,
      hasBtn1: true,
      hasBtn2: false,
      btn1Name: "OK",
      btn2Name: ""
    }
    this.modal.open(ModalComponent, {
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        content
      }
    });   
   } catch (error) {
     this.modal.closeAll();
     this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('openUnchangedModal', 'Anfieldinfo');
     throw error;
   }
 }

 /**********************************************************************************
  * Method Name: openEmptyAccNumModal                                                                 
  * Description: Opens a modal when the user tries to submit while there is blank account number entry in the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openEmptyAccNumModal(){
    try {
     let content: any = {
       header: this.modalHeader,
       body: this.modalEmptyAccnoBody,
       hasBtn1: true,
       hasBtn2: false,
       btn1Name: "OK",
       btn2Name: ""
     }
     this.modal.open(ModalComponent, {
       panelClass: 'custom-modal',
       disableClose: true,
       data: {
         content
       }
     }); 
    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('openEmptyAccNumModal', 'Anfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openConfirmModal                                                                 
  * Description: Opens a confirmation modal.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openConfirmModal(){
    try {

      let content = {
        header: this.modalHeader,
        body: this.modalConfirmBody,
        hasBtn1: true,
        hasBtn2: true,
        btn1Name: "OK",
        btn2Name: "CANCEL"
      }

      let confirmModal = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });
      
      confirmModal.afterClosed().subscribe( result => {
        if (result) {
          this.openModalOverlay();
          this.postChanges();
        }
      })

    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('openConfirmModal', 'Anfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: postChanges                                                                 
  * Description: Calls the api postdata.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  postChanges(){
    try {
      let body = {
        functionName: "popAcctNumData",
        acctNumRow: this.getAccNumRow()
      }
      this.apiService.postData(this.globals.manageAccNumberApi, body).subscribe( data =>{
        if (data != null && data.statusCode == 200 ) {
          let body = data.body;
          if (body && body.length > 0) {
            let retval = body[0].RetVal;
            if (retval == 1) {
              this.modal.closeAll();
              this.openSuccessModal();
            }else {
              this.openErrorModal();
            }
          }else {
            this.openErrorModal();
          }
        }else {
          this.openErrorModal();
        }
      })
    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('postChanges', 'Anfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: openSuccessModal                                                                 
  * Description: Opens sucess modal after submitting changes.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openSuccessModal(){
    try {

      let content: any = {
        header: this.modalHeader,
        body: this.modalSuccessBody,
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: "OK",
        btn2Name: ""
      }

      let successModal = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });

      successModal.afterClosed().subscribe( result => {
        if (result) {
          this.reloadTable();
        }
      })

    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setAccountNumberError(true);
      error.message += this.apiService.setErrorContents('openSuccessModal', 'Anfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: reloadTable                                                                 
  * Description: Reloads the table after sucessful submit of changes.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  reloadTable(){
    this.accountNumLoadingService.setLoadingState(true);
    let reloadBody = {
      functionName: "getBusDataAcctNum",
      equityTypeId: this.getEquityId(this.dataToUpdate[0].EQUITY_TYPE_DESC)
    }
    this.disableSubmitButton = true;
    this.apiService.postData(this.globals.manageAccNumberApi, reloadBody).subscribe( data => {
      try {
        let body = data.body
        if (body && body != "") {
          let bodyData = body;
          if(bodyData.length > 0 && bodyData != "Invalid Input"){
            this.store.dispatch( new AccountNumber.Clear);
            for (let i = 0; i < bodyData.length; i++) {
              this.store.dispatch( new AccountNumber.Add(bodyData[i]))
            }
            this.onloadTableDataString = JSON.stringify(bodyData);
            this.onloadTableStringArray = [];
            for (let i = 0; i < bodyData.length; i++) {
              this.onloadTableStringArray.push(JSON.stringify(bodyData[i]));
            }
            this.accountNumLoadingService.setLoadingState(false);
          }
        }
      } catch(error) {
        this.bdrAccessService.setAccountNumberError(true);
        error.message += this.apiService.setErrorContents('reloadTable', 'Anfieldinfo');
        throw error;
      }
    }, error => {
      this.bdrAccessService.setAccountNumberError(true);
      error.error.message += this.apiService.setErrorContents('reloadTable', 'Anfieldinfo');
      throw error;
    });
  }

}
