import { Component, OnInit, ElementRef } from '@angular/core';
import { ApiService } from '../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PartnerselectedsearchComponent } from '../../pdsa/partnerselected/partnerselectedsearch/partnerselectedsearch.component';
import { Globals } from '../../../../globals';
import { SemlinkaccessService } from '../../../../services/ea/api/SemlinkaccessService';

@Component({
  selector: 'app-partnerselected',
  templateUrl: './partnerselected.component.html',
  styleUrls: ['./partnerselected.component.css']
})
export class PartnerselectedComponent implements OnInit {


  partnerStatus: string = "1";
  partnerType: string = "3";
  documentList: string;
  documentDropdownSelect: string;
  documentDropdownList: any[];
  partnerSelectedFirstName: string;
  partnerSelectedMiddleName: string;
  partnerSelectedLastName: string;
  partnerSelectedLocalPN: number;
  partnerSelectedPersonnelNum: number;
  partnerSelectedPeopleKey: number;
  optNum: number;
  editNameHistory: string;
  editDateHistory: string;
  selectedFirst: any;
  loading: boolean = true;
  loadingEdit: boolean = true;
  editHistoryView: boolean;
  optNumHolder: any[] = []; //optNumHolder: any[];
  searchInput: string;
  isError: boolean = false;

  constructor(
    private router: Router,
    private modal: MatDialog,
    private apiService: ApiService,
    private globalDec: Globals,
    private semLinkAccessService: SemlinkaccessService
  ) { }
  
  ngOnInit() {
    this.optNum = parseInt(sessionStorage.getItem('optNum') || '{}');
    this.globalDec.searchLoading = false;
    this.semLinkAccessService.getPartnerSelected();
    this.partnerSelectedInfo(this.optNum);
    this.documentDropdown();
  }
  /**********************************************************************************
  * Method Name: openSearched                                                                 
  * Description: Sets filter by selected radio button.                   
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1                              Jikho S. Enrique                 Initial draft   
  ***********************************************************************************/
  openSearched(){
    try {
      if (this.searchInput != "" && this.searchInput != undefined) {
        this.searchInput = this.searchInput.replace(/[^a-z-A-Z'-]*/g, '');
      } else {
        this.searchInput = "";
      }
      this.semLinkAccessService.setSearchModalInput(this.searchInput);
      this.semLinkAccessService.setOptNumHolder(parseInt(sessionStorage.getItem('optNum')));
      this.semLinkAccessService.setPeopleKeyHolder(this.semLinkAccessService.getPeopleKey());
      this.openSearchModal();
      this.partnerSelectedInfo(this.optNum);
    } catch (error) {
      throw error;
    }

  }
  /**********************************************************************************
  * Method Name: openSearchModal                                                                 
  * Description: Sets filter by selected radio button.                   
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1                              Jikho S. Enrique                 Initial draft   
  ***********************************************************************************/
  openSearchModal() {
    this.modal.open(PartnerselectedsearchComponent, {
      panelClass: 'search-partners',
      disableClose: true,
    });
  }
  
  /**********************************************************************************
  * Method Name: partnerSelectedInfo                                                                 
  * Description: get header information for partner selected                   
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         3/17/2020            Almerie T. Dondonay              Initial draft  
  * 2         05/07/2020           Almerie T. Dondonay              Change hardcoded URL into Reusable variable.
  ***********************************************************************************/
 partnerSelectedInfo(optNum) {
   let argPartnerSelected: any = {
     "selected_optnum": sessionStorage.getItem('optNum'),
     "partnerstatus": this.partnerStatus,
     "partnertype": this.partnerType,
     "functionName": "getPersonalData"
   }
   this.apiService.postData(this.globalDec.api_URL_pds, argPartnerSelected).subscribe(data => {
     try {
       if (data) {
         let contentBody = data.body;
         this.semLinkAccessService.setPartnerSelected({
           firstName: contentBody['data'][0][0]['FIRST_NAME'], middleName: contentBody['data'][0][0]['MIDDLE_NAME'],
           lastName: contentBody['data'][0][0]['LAST_NAME'], localPN: contentBody['data'][0][0]['EMPLID'],
           personnelNum: contentBody['data'][0][0]['WWID'], peopleKey: this.semLinkAccessService.getPeopleKey()
         });

         this.partnerSelectedFirstName = this.semLinkAccessService.getPartnerSelected().firstName;
         this.partnerSelectedMiddleName = this.semLinkAccessService.getPartnerSelected().middleName;
         this.partnerSelectedLastName = this.semLinkAccessService.getPartnerSelected().lastName;
         this.partnerSelectedLocalPN = this.semLinkAccessService.getPartnerSelected().localPN;
         this.partnerSelectedPersonnelNum = this.semLinkAccessService.getPartnerSelected().personnelNum;
         this.partnerSelectedPeopleKey = this.semLinkAccessService.getPartnerSelected().peopleKey;

       }
     } catch (error) {
       this.isError = true;
       error.message += this.apiService.setErrorContents('partnerSelectedInfo', 'partnerselected');
       this.router.navigate([this.globalDec.route_documentError])
       throw error
     }

   }, error => {
     
     this.isError = true;
     error.error.message += this.apiService.setErrorContents('partnerSelectedInfo', 'partnerselected');
     this.router.navigate([this.globalDec.route_documentError])
     throw error;
   });

}

    /**********************************************************************************
  * Method Name: documentDropdown                                                                 
  * Description: get dropdown list for document                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         3/12/2020            Almerie T. Dondonay              Initial draft   
  * 2         05/07/2020           Almerie T. Dondonay              Change hardcoded URL into Reusable variable.
  ***********************************************************************************/
  documentDropdown() {
    let argDocumentDropdown: any = {
      "functionName": "getPageDocument"
    }
    this.apiService.postData(this.globalDec.api_URL_pds, argDocumentDropdown).subscribe(data => {
      try {
        let contentBody = data.body;
        this.documentDropdownList = contentBody['data'];
        this.documentDropdownSelect = contentBody['data'][0]['Document_Name'];
      } catch (error) {
        this.isError = true;
        error.message += this.apiService.setErrorContents('documentDropdown', 'partnerselected');
        this.router.navigate([this.globalDec.route_documentError])
        throw error
      }

    }, error => {
      
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('documentDropdown', 'partnerselected');
      this.router.navigate([this.globalDec.route_documentError])
      throw error;
    });

  }   
  /**********************************************************************************
  * Method Name: documentNavigation                                                                 
  * Description: Navigate to another page                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         3/12/2020            Almerie T. Dondonay              Initial draft   
  ***********************************************************************************/
  documentNavigation(document: string, id: number): void {
    try {
      this.documentDropdownSelect = document
      sessionStorage.setItem(this.globalDec.documentID, id.toString());

      this.searchInput = "";

      if (document == 'Personal Data') {
        this.router.navigate([this.globalDec.route_personalData]);
      } else if (document == 'Education') {
        this.router.navigate([this.globalDec.route_education]);
      } else if (document == 'Notes') {
        this.router.navigate([this.globalDec.route_notes]);
      } else if (document == 'Business Information') {
        this.router.navigate([this.globalDec.route_businessInformation]);
      } else if (document == 'Legal Documents') {
        this.router.navigate([this.globalDec.route_legal]);
      } else if (document == 'Unit Award') {
        this.router.navigate([this.globalDec.route_unitAward]);
      } else if (document == "Member Firm") {
        this.router.navigate([this.globalDec.route_memberFirm]);
      } else if (document == 'Unit History') {
        this.router.navigate([this.globalDec.route_unitHistory]);
      } else if (document == 'Change Log') {
        this.router.navigate([this.globalDec.route_changeLog]);
      } else if (document == 'Projected Changes') {
        this.router.navigate([this.globalDec.route_projectedChanges]);
      } else if (document == 'RI') {
        this.router.navigate([this.globalDec.route_ri]);
      }

    } catch (error) {
      throw error;
    }

  }  
/**********************************************************************************
  * Method Name: editHistory(documentId, optNum)                                                                 
  * Description: Get data for edit history                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1                                                               Initial draft   
  * 2         05/07/2020           Almerie T. Dondonay              Change hardcoded URL into Reusable variable.
  ***********************************************************************************/
  editHistory(documentId, optNum) {
    let argEditHistory: any = {
      "docid": documentId,
      "selected_optnum": optNum,
      "functionName": "editHistoryView"
    }
    this.apiService.postData(this.globalDec.api_URL_pdsDocument, argEditHistory).subscribe(data => {
      try {
        if (data) {
          let contentBody = data.body;

          if (contentBody['data'][0] != null && contentBody['data'][0] != undefined) {
            if (contentBody['data'][0] == "") {
              this.editHistoryView = false;
            } else {
              this.editHistoryView = true;
              if (contentBody['data'][0][0]['EDITOR'] != null && contentBody['data'][0][0]['EDITOR'] != undefined) {
                this.editNameHistory = contentBody['data'][0][0]['EDITOR'];
              } if (contentBody['data'][0][0]['EditDate'] != null && contentBody['data'][0][0]['EditDate'] != undefined) {
                this.editDateHistory = contentBody['data'][0][0]['EditDate'];
              }
            }
          }
        }
      } catch (error) {
        this.isError = true;
        error.message += this.apiService.setErrorContents('editHistory', 'partnerselected');
        this.router.navigate([this.globalDec.route_documentError])
        throw error
      }

    }, error => {
      
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('editHistory', 'partnerselected');
      this.router.navigate([this.globalDec.route_documentError])
      throw error;
    });
  }
}

