import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';

export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
    
    constructor(private dateFormat: string) {
        super();
    }

    /******************************************************************************************************************************
    Method Name: format
    Description: Formats the given date to a string & to the specified date format. 

    #    Date             Modified by                               Description
    1    03/29/2019        Dalemark P. Suquib                        Initial draft  
    *******************************************************************************************************************************/
    format(date: NgbDateStruct): string {
        if(date === null) {
            return '';
        }

        return moment(new Date(date.year, date.month - 1, date.day)).format(this.dateFormat);
    }
    
    /******************************************************************************************************************************
    Method Name: parse
    Description: Parses the given value to an NgbDateStruct. 

    #    Date             Modified by                               Description
    1    03/29/2019        Dalemark P. Suquib                        Initial draft  
    *******************************************************************************************************************************/
    parse(value: string): NgbDateStruct {
        let returnValue: NgbDateStruct;

        if(!value) {
            returnValue = null;
        } else {
            let dateParts = moment(value).format('M-DD-YYYY').split("-");
            returnValue = {year: parseInt(dateParts[2]), month: parseInt(dateParts[0]), day: parseInt(dateParts[1])};
        }
        
        return returnValue;
    }    
}