
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { userModel } from './models/userModel';
// import { Http, Headers, RequestOptions } from '@angular/http';
import { Globals } from '../globals';
import * as moment from 'moment';
import { map, retry, catchError } from 'rxjs/operators';


@Injectable()
export class UserService {
  headers: Headers;
  // options: RequestOptions;
  user: any; 
  token: string = sessionStorage.getItem("access_token");
  msaltoken: string = sessionStorage.getItem("msal.idtoken");
  
  constructor(private globals: Globals, private httpClient: HttpClient) { }
  
   
 getUserInfo(optnum): Observable<userModel[]> {
    let apiURL = this.globals.currentAPIUrl;
   try {
        const apiUserInfo = apiURL + this.globals.getUserInfoAPI + optnum; 
        return this.httpClient.get<userModel[]>(apiUserInfo).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res)));
    } catch(err) {
        return err;
    }
  }

  updatePreferenceCd(currencyCd, hideAwardsInd, OptNum){

    let apiURL = this.globals.currentAPIUrl;
    let methodPath = this.globals.updateUserPrefCode;

    let dateToday = new Date();
    let setDate = moment(dateToday).format("YYYY-MM-DD");
    let newcurrencyCd = currencyCd;

    methodPath = methodPath.replace("[param1]", newcurrencyCd);
    methodPath = methodPath.replace("[param2]", hideAwardsInd);
    methodPath = methodPath.replace("[param3]", setDate);
    methodPath = methodPath.replace("[param4]", OptNum);

    try {
      const apiLogin = apiURL + methodPath;      
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.msaltoken}`});
      let options = ({ headers : this.headers});
      let body = JSON.stringify({ newcurrencyCd, hideAwardsInd, setDate , OptNum})

      return this.httpClient.put(apiLogin, body).pipe(retry(3)).subscribe((data) => {});
      
    }catch(err) {
      return err;
    }
  }

  updateLogin(token) {
    let apiURL = this.globals.currentAPIUrl;
    let methodPath = this.globals.updateUserLogin;     
    try {
      const apiLogin = apiURL + methodPath;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${token}`});
      let options = ({ headers : this.headers});       
      let body = JSON.stringify({})      
      return this.httpClient.put(apiLogin, body).pipe(retry(3)).subscribe(data => {});
    }catch(err) {
      return err;
    }
  }

 
// Comment Out for Profile Picture -IJM
getAPIProfilePicture(optnum) {
  
  let apiURL = this.globals.currentAPIUrl;
  {
    let methodPath = this.globals.getProfPicAPI;
    methodPath = methodPath.replace("[param1]", optnum);
    
    try {
      const profPicAPI = apiURL + methodPath;
      return this.httpClient.get(profPicAPI).pipe(map(res => res), retry(3));
      //catchError((res: Response) => this.onError(res));
    }catch(err){
        return err;
    }
  }
}

getUserInfoFromAdminPage(optnum){
  let apiURL = this.globals.currentAPIUrl;

  try {
      const apiUserInfo = apiURL + this.globals.getUserInfoAPI + optnum; 
        return this.httpClient.get<userModel[]>(apiUserInfo).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res)));
    } catch(err) {
        return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
    };

    return observableThrowError(error);
    } 

    insertGdprFlag(flag: any) {
      let apiURL = this.globals.currentAPIUrl;
      let methodPath = this.globals.gdprFlag;
  
      methodPath = methodPath.replace("[param1]", flag);
    
      try {
        const apiLogin = apiURL + methodPath;
        this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.msaltoken}`});
        let options = ({ headers : this.headers});
        let body = JSON.stringify({ flag })
  
        return this.httpClient.put(apiLogin, body).pipe(retry(3));
      }catch(err) {
        return err;
      }
    }

    insertCheckboxPref(currencyCd, hideAwardsInd, OptNum) {
      let apiURL = this.globals.currentAPIUrl;
      let methodPath = this.globals.hideOutstandingShares;
  
      let dateToday = new Date();
      let setDate = moment(dateToday).format("YYYY-MM-DD");
      let newcurrencyCd = currencyCd;
  
      methodPath = methodPath.replace("[param1]", newcurrencyCd);
      methodPath = methodPath.replace("[param2]", hideAwardsInd);
      methodPath = methodPath.replace("[param3]", setDate);
      methodPath = methodPath.replace("[param4]", OptNum);
  
    
      try {
        const apiLogin = apiURL + methodPath;
        this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.msaltoken}`});
        let options = ({ headers : this.headers});
        let body = JSON.stringify({ newcurrencyCd, hideAwardsInd, setDate , OptNum});
  
        return this.httpClient.put(apiLogin, body).pipe(retry(3));
      }catch(err) {
        return err;
      }
    }


}
