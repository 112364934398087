
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, HostListener} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HoldingsSummaryService } from './HoldingSummaryService';
import { Globals } from '../globals';
import { BrokerType, GrantGroup, RedemptionMethod, GrantTransactionType, CurrencySymbol, PageSection } from '../services/enumerations/enumerations';
import { AppComponent } from '../app.component';
import * as moment from 'moment'
import { Router, ActivatedRoute } from '@angular/router';
// import { overrideComponentView } from '@angular/core/src/view';
import { NgModel } from '@angular/forms';
import { UserService }  from '../services/UserService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { tScript, MyHoldingsPagesResources} from '../services/enumerations/enumerations';
import { BrokersPageService } from '../brokerspage/BrokersPageService';
import { StaticContentService } from '../services/StaticContentService';
import { ifError } from 'assert';
// import { P } from '@angular/core/src/render3';
import { Row } from 'jspdf-autotable';
import { PrintService } from '../services/PrintService';
// import { isNumber } from 'util';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from "jquery";

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}



@Component({
  selector: 'app-holdingsummarypage',
  templateUrl: './holdingsummarypage.component.html',
  styleUrls: ['./holdingsummarypage.component.css']
})

export class HoldingsummarypageComponent implements OnInit {
  displayedColumns: string[] = ['AwardName', 'AvailableToSell', 'AvailableToSellValue', 'BrokerId', 'NextRelease', 'SharesReleasing', 'GrantAccepted', 'UnreleasedUnexercisable', 'ReleasedExercisableQty', 'Expiration', 'Forfeited'];
  displayedColumnsFounder: string[] = ['AwardName', 'AvailableToSell', 'AvailableToSellValue', 'BrokerId', 'NextRelease', 'SharesReleasing', 'GrantAccepted', 'UnreleasedUnexercisable', 'ReleasedExercisableQty', 'Expiration', 'Forfeited'];
  displayedColumnsOptions: string[] = ['AwardName', 'AvailableToSell', 'AvailableToSellValue', 'BrokerId', 'NextRelease', 'SharesReleasing', 'GrantAccepted', 'UnreleasedUnexercisable', 'ReleasedExercisableQty', 'Expiration', 'Forfeited'];
  displayedColumnsRSU: string[] = ['AwardName', 'AvailableToSell', 'AvailableToSellValue', 'BrokerId', 'NextRelease', 'SharesReleasing', 'GrantAccepted', 'UnreleasedUnexercisable', 'ReleasedExercisableQty', 'Expiration', 'Forfeited'];
  dataSource:any;
  dataSourceFounderShares: any;
  dataSourceOptions: any;
  dataSourceRSU: any;
  loading = true;
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  marketQuoteLatestPrice: any = this.marketQuoteCurrentPrice.latestPrice;
  currencyList: any;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd:string;
  purchaseTitle:string;
  currencyCdSymbol = '';
  panelOpenState = false;
 tempSharePrice: any = this.marketQuoteCurrentPrice.latestPrice;
  tempExchangeRate: any;
  veipTooltip: boolean = true;
  veipSharesRemainingTotal: any = 0;
  veipSharesValueTotal; any = 0;
  totalRSUSharesOutStanding:any = 0;
  rsuSharesOutStanding:any = 0;
  totalRSUAvailableToSell:any = 0;
  rsuAvailableToSell: any = 0;
  totalRSUUnreleasedUnvested:any = 0;
  rsuUnreleasedUnvested: any = 0;
  totalRSUReleasedVested:any = 0;
  rsuReleasedVested:any = 0;
  
  rsuForfeited:any = 0;
  totalRSUSharesReleasing:any = 0
  rsuSharesReleasing:any = 0;
  rsuOutstandingValue:any = 0;
  rsuUnreleasedOutstanding:any = 0;
  rsuAvailableToSellValue:any = 0;

  //PSU
  psuKeyExecInd: boolean = false;

  totalOptionsSharesOutstanding:any = 0;
  totalOptionsAvailableToSell:any = 0;
  totalGrantPrice:any = 0;
  totalUnreleasedUnvested:any = 0;
  totalReleasedExercisableQty: any = 0;
  optionsOutstandingValue:any = 0;
  optionsAvailableToSellValue: number = 0;
  optionsUnreleasedUnvested: any = 0;

  totalPurchaseSharesOutStanding:any = 0;
  totalPurchaseAvailableToSell:any = 0;
  totalPurchaseUnReleased = 0;
  totalPurchaseAvailableToSellValue:any = 0;
  valueSharesOustanding:any = 0;
  valueAvailableToSell:any = 0;

  //total forfeited
  totalFoundersForfeited:any = 0;
  totalRSUForfeited:any = 0;
  totalPurchaseForfeited:any = 0;
  totalOptionsForfeited:any = 0;

  exchangeRate = 0;
  totalFounderSharesOutstanding: any = 0;
  totalFounderAvailableToSell: any = 0;
  totalFounderUnreleasedUnvested: any = 0;
  totalFounderAvailToSellValue: any = 0;
  founderValue: any = 0; // value from market quote: avghighlow or share price
  founderSharesOutstandingValue: any = 0;
  founderAvailableToSellValue: any = 0;
  founderUnreleasedUnvestedValue: any = 0;
  founderReleasedExercisableQty: any = 0;

  programsTotalShareOutstanding: any = 0;
  programsTotalShareOutstandingValue: any = 0;
  programsTotalAvailableToSell: any = 0;
  programsTotalAvailableToSellValue: any = 0;
  programsTotalUnReleased: any = 0;
  programsTotalUnReleasedValue: any = 0;

  setFounderCollapseText: any = "Expand";
  setRSUCollapseText: any = "Expand";
  setPurchaseCollapseText: any = "Expand";
  setOptionCollapseText: any = "Expand";
  setOverrideCollapseText: any = 'OVERRIDE SHARES';
  setMobileRSUCollapseText: any = 'RSU Details';
  setMobileFounderCollapseText: any = 'Founder Shares Details';
  //setMobilePurchaseCollapseText: any = 'Purchase Programs';
  setMobileOptionCollapseText: any = 'Options Details';
  checkFounderExpand: any = 1;
  checkRSUExpand: any = 1;
  checkPurchaseExpand: any = 1;
  checkOptionExpand: any = 1;
  checkOverrideExpand: any = 1;

  // exchangerate
  currencyCodeList: any;
  exchangeRateList: any;
  defaultValue:any;
  showFounderData: boolean = false;
  showPurchData: boolean = false;
  showOptionsData: boolean = false;
  showRSUData: boolean = false;

  // hide awards
  onlickHide: boolean = false;
  hideRSUAward : boolean = false;
  hideOptionsAward: boolean = false;
  hidePurchaseAward: boolean = false;
  hideFounderAward: boolean = false;
  hideProgAwards: any;
  sortHideData : any;
  totalHideOutstanding : any; 

  keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));
  hideEnabled: boolean = false;
  yearCode: any[] = [];
  planNum: any[] = [];
  methodCounter: number = 0;
  setMaximumVest: boolean = false;
  setTargetVest: boolean = false;
  setTrendVest: boolean = false;

  hideAwardsInd: number = 0;
  isPassDetailsCalled: number = 0;
  displayKeyExecToggle: boolean = true;
  errorLocation: string = " + [Component: Holdingsummary, Method: methodName]";
  errorFounderCard: boolean;
  errorRsuCard: boolean;
  errorPurchaseCard: boolean;
  errorOptionsCard: boolean;
  errorProgramsTotal: boolean;
  highlightChecker: string = JSON.parse(sessionStorage.getItem("Highlight Checker"));
  checkBoxValue: boolean;

  
  rsuCollapse: string = "collapse";
  optCollapse: string = "collapse";
  purchCollapse: string = "collapse";
  founderCollapse: string = "collapse";
  selectedItem : any;
  highlightItem: any;
  activeRow= sessionStorage.getItem("IsActive Row");
  item: string = JSON.parse(sessionStorage.getItem("Row Highlighted"));
  rowData: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));
  active: any;

    //ToolTip Messages
    ketMax: string = tScript.ketMaximum;
    ketTar: string = tScript.ketTarget;
    ketTre: string = tScript.ketTrend;
    h_p_so: string = tScript.holdingsummary_p_so;
    h_p_v: string = tScript.holdingsummary_p_v;
    h_p_ats: string = tScript.holdingsummary_p_ats;
    h_p_uu: string = tScript.holdingsummary_p_uu;
    lc: string = tScript.localCurrency;
    cer: string = tScript.customExchangeRate;
      ocsp: string = tScript.dashboard_ocsp;
    // h_ap_ats: string = tScript.holdingsummary_ap_ats;
    // h_ap_v: string = tScript.holdingsummary_ap_v;
    // h_ap_b: string = tScript.holdingsummary_ap_b;
    // h_ap_nr: string = tScript.holdingsummary_ap_nr;
    // h_ap_sr: string = tScript.holdingsummary_ap_sr;
    // h_ap_uu: string = tScript.holdingsummary_ap_uu;
    // h_ap_rv: string = tScript.holdingsummary_ap_rv;
    // h_ap_e: string = tScript.holdingsummary_ap_e;
    // h_ap_f: string = tScript.holdingsummary_ap_f;
    fileTypeImagePath: string;
    fileTypeAlt: string;


    bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));

    userprofileInfo: any;

    sbDesc: string;
    displayPersonalSb: boolean = false;
    displayPersonalUbs: boolean = false;
    displayPersonalBig: boolean = false;
    displayPersonalNcb: boolean = false;

    ubsDesc: string;
    bigDesc: string;
    ncbDesc: string;

    sbDoc: any[];
    ubsDoc: any[];
    ncbDoc: any[];
    
    globalIDNum1: string;
    globalIDNum2: string;
    phSBGlobalID: boolean = true;

    globalIDs: any;

    
  ubsAccount: number;
  bigAccount: number;
  ncbAccount: number;

  resourceLoading: boolean;
  ubsLoaded: boolean = false;
  sbLoaded: boolean = false;
  bigLoaded: boolean = false;
  ncbLoaded: boolean = false;

  brokerHeader: string;
  UBSlink: any;
  MSlink: any;
  envSetting: any;

  @ViewChild('founderTableSort') public founderTableSort: MatSort;
  @ViewChild('purchaseTableSort') public purchaseTableSort: MatSort;
  @ViewChild('optionsTableSort') public optionsTableSort: MatSort;
  @ViewChild('rsuTableSort') public rsuTableSort: MatSort;
  @ViewChild('inputText') inputText: any;
  @ViewChild('mobileinputText') mobileinputText: any;
  @ViewChild('outStandingCheckbox') checkValue: any;
  @ViewChild('me') me : ElementRef;
  YesGrantAccepted: boolean;
  NoGrantAccepted: boolean;
  keyExecDisplayInd: any = sessionStorage.getItem("showKeyExecToggle");
  
  RSUMobileData: any;
  FoundersMobileData: any;  
  PurchaseMobileData: any;
  OptionMobileData: any;
  render: any;
  matchGrantRemain: any = 0;
  matchGrantValue: any = 0;

  @HostListener('window:keydown',['$event'])
  onKeyPress($event: KeyboardEvent) {

    let founderTable = "#collapseFounder";
    let rsuTable ="#collapseRSU";
    let purchaseTable ="#collapsePurchase";
    let optionsTable = "#collapseOptions";

      if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 80){
          if(this.checkShowExist(founderTable) == 0){
            $(founderTable).addClass("show");
            this.collapse('Founder');
          }

          if(this.checkShowExist(rsuTable) == 0){
            $(rsuTable).addClass("show");
            this.collapse('RSU');
          }

          if(this.checkShowExist(purchaseTable) == 0){
            $(purchaseTable).addClass("show");
            this.collapse('Purchase');
          }

          if(this.checkShowExist(optionsTable) == 0){
            $(optionsTable).addClass("show");
            this.collapse('Option');
          }

      }
    }
  
  constructor(private sanitizer:DomSanitizer, private staticContentService: StaticContentService, private brokerService: BrokersPageService, private userService: UserService, private router: Router, private app: AppComponent, private holdingsummaryservice: HoldingsSummaryService, private globals:Globals, private cdRef: ChangeDetectorRef, private elRef:ElementRef, private errorNotificationService: ErrorNotificationService, private printService : PrintService) { }

  ngOnInit() {

    let hasAwardsChecker = sessionStorage.getItem("hasAwards");
    if(hasAwardsChecker != "1") this.router.navigate(['homepage']);

    // this.checkBoxValue = localStorage.getItem("CheckBoxValue") == "true";
    if(this.user["HideOutstandingAwardsInd"] == '0'){
      if(sessionStorage.getItem("checkBoxSelection") != null){
        
        if(sessionStorage.getItem("checkBoxSelection") == "y"){
         
          this.checkBoxValue = true;
          
        }else if(sessionStorage.getItem("checkBoxSelection") == "n"){
      
          this.checkBoxValue = false;
        }
      }
    }else{
        
      if(this.user["HideOutstandingAwardsInd"] == '1'){
        
        if(sessionStorage.getItem("checkBoxSelection") != null){
        
          if(sessionStorage.getItem("checkBoxSelection") == "y"){
           
            this.checkBoxValue = true;
            
          }else if(sessionStorage.getItem("checkBoxSelection") == "n"){
        
            this.checkBoxValue = false;
          }
        }else{

          this.checkBoxValue = true;

        }

      }
      
      
    }
    
    if(this.rowData != null) {
      this.selected = this.rowData.AwardName;
    }

    // BACK BUTTON BUG - START
    let holdingSummaryBreadcrumb: boolean = JSON.parse(sessionStorage.getItem("HoldingSummary Breadcrumb"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(holdingSummaryBreadcrumb != null) {
      if(holdingSummaryBreadcrumb) {
        // accessed holdingsummary page thru breadcrumb
        sessionStorage.setItem("Back Url", JSON.stringify(false));
      } else {
        // accessed holdingsummary page thru BACK button
        sessionStorage.setItem("Back Url", JSON.stringify(true));

        if(detailsStorage.length > 0) {
          this.selected = detailsStorage[detailsStorage.length - 1].AwardName;
          detailsStorage.splice(detailsStorage.length - 1, 1);
          sessionStorage.setItem("Details Storage", JSON.stringify(detailsStorage));
        } else {
          sessionStorage.setItem("Details Storage", JSON.stringify(null));
        }
      }
    }
    // BACK BUTTON BUG - END

    this.loading = true;
    this.app.appNavHeaderVisible = true;
    this.app.appFooterVisible = true;
    sessionStorage.removeItem("veipAwardDetailsStoredData"); //remove session for veip award details
    sessionStorage.removeItem("RSU Details Data"); //remove session for rsu award details
    sessionStorage.removeItem("Header RSU Details"); //remove session for rsu award details
    sessionStorage.removeItem("getRSUAgreementInfo");

    this.getMatchingGrant();
    this.keyExecVestChecker();
    this.getCodeDetails();
    this.setCurrrencyCd();    

    let purchChecker = sessionStorage.getItem("showPurchData");
    let optCheck = sessionStorage.getItem("showOptionsData");
    let rsuChecker = sessionStorage.getItem("showRSUData");
    let founderChecker = sessionStorage.getItem("showFounderData");    

    if(purchChecker == "1") this.showPurchData = true, this.holdingsummaryservice.showVEIPData = true;
    if(optCheck == "1") this.showOptionsData = true, this.holdingsummaryservice.showOptionsData = true;
    if(rsuChecker == "1") this.showRSUData = true, this.holdingsummaryservice.showRSUData = true;
    if(founderChecker == "1") this.showFounderData = true, this.holdingsummaryservice.showFounderData = true;    
    
    this.checkErrorMessage();
    this.highlightBack();
    this.brokersSSO();
    this.purchaseProgTitle();

  }

  ngAfterViewInit(){
    setTimeout(() => {      
    this.checkTotalValue();
    // ;
    // if(this.user.HideOutstandingAwardsInd == "1"){
    //   this.hideAwards(true);
    // }
    // else this.hideAwards(false);
    // if(this.user["HideOutstandingAwardsInd"] == 0){
    //   if(sessionStorage.getItem("checkBoxSelection") != null){
    //     if(sessionStorage.getItem("checkBoxSelection") == "y"){
    //       this.hideAwards(true)
    //     }else if(sessionStorage.getItem("checkBoxSelection") == "n"){
    //       this.hideAwards(false)
    //     }
    //   }else{
    //     if(this.user["HideOutstandingAwardsInd"] == 1)this.hideAwards(true);
    //     else this.hideAwards(false)
    //   }
    // }
   if (this.checkBoxValue == true){
    this.hideAwards(true);
   }else{
    this.hideAwards(false);
   }

    }, 2500);
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
    if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
      let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

      if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }else{
      if(this.bodyData != null){
        if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
        }else{
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
        }
      }
    }
  }

  navToPage(strLoc) {
    try {
      this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  passDetails(details, checker, grantInd) {
    try {
      this.holdingsummaryservice.setProgramDetails(details);

      // BACK BUTTON BUG - START
      sessionStorage.setItem("Back Url", JSON.stringify(false));
      let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));
      if(detailsStorage != null) {
        detailsStorage.push(details);
      } else {
        detailsStorage = [];
        detailsStorage.push(details);
      }
      sessionStorage.setItem("Details Storage", JSON.stringify(detailsStorage));
      // BACK BUTTON BUG - END
      
      if(this.isPassDetailsCalled != 1) {
        if(grantInd == 1) { 
          if(details.GrantAccepted == "Action Required") {
            this.holdingsummaryservice.setAwardTab("agreement-tab");
          } else {
            this.holdingsummaryservice.setAwardTab("details-tab");
          }
  
          if(checker == 'Rsu') {
            if (details.HoldingsType == 4) {
              this.navToPage('iporsudetailspage')
            } else {
              this.navToPage('rsudetailspage');
            }
          } else if(checker == 'Options'){
            this.navToPage('optionsdetailspage');
          }
        } else {
          this.holdingsummaryservice.setAwardTab("details-tab");
          if(checker == 'Purchase'){
            if(details.AwardName.indexOf("ESPP") > -1) {
              this.navToPage('esppdetailspage');
            } else if(details.AwardName.indexOf("VEIP") > -1) {
              this.navToPage('veipdetailspage');
            }
          } else if(checker == 'Founder'){
            this.navToPage('founderdetailspage')
          } else if(checker == 'Rsu') {
            if (details.HoldingsType == 4) { 
              this.navToPage('iporsudetailspage')
            } else {
              this.navToPage('rsudetailspage');
            }
          } else if(checker == 'Options'){
            this.navToPage('optionsdetailspage');
          }
        }
      }
  
      this.isPassDetailsCalled = 1;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "passDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  changeDisplayLoading(){
    try {
      if(this.loading) {
        return "none";
      } else {
        return "block";
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "changeDisplayLoading");
      error.message += errorLocation;
      throw error;
    }
  }

  async getExchangeRateData(currencyCd){
    // if(sessionStorage.getItem('get exchange rate') != null){
    //   try {
    //     let data = JSON.parse(sessionStorage.getItem('get exchange rate'));
    //     this.exchangeRate = data.Exch_Rt;
    //     this.defaultValue = data.Exch_Rt;
    //     this.tempExchangeRate = data.Exch_Rt;
    //     this.currencyCdSymbol = CurrencySymbol[currencyCd.replace(/\s/g, "")];
    //     this.holdingsummaryservice.currencyCdSymbol = this.currencyCdSymbol;
    //     this.getDataApi();
    //   } catch(error) {
    //     let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateData");
    //     error.message += errorLocation;
    //     throw error;
    //   }
    // }
    // else{
      this.holdingsummaryservice.getExchangeRate(currencyCd).pipe(map(res => res)).subscribe(data => {
        try {
          this.exchangeRate = data.Exch_Rt;
          this.defaultValue = data.Exch_Rt;
          this.tempExchangeRate = data.Exch_Rt;
          this.currencyCdSymbol = CurrencySymbol[currencyCd.replace(/\s/g, "")];
          this.holdingsummaryservice.currencyCdSymbol = this.currencyCdSymbol;
          sessionStorage.setItem('get exchange rate', JSON.stringify(data));
          this.getDataApi();
          this.getExchangeRateList();
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateData");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateData");
        error.error.message += errorLocation;
        throw error.error;
      });
    // }
  }
  
  setNewCurrencyCode(currency){
    try {
      let data = currency;
      let splitData = data.split("-");
      let curCode = splitData[1];
      this.currencyCd = curCode;
      this.holdingsummaryservice.currencyCd = this.currencyCd;
      
      this.userService.updatePreferenceCd(curCode, this.hideAwardsInd, this.user.OptNum);
      let list = this.exchangeRateList;
      let overrideExchangeRateValue = list.find(i => i.CNVT_TO_CURR_CD === curCode);
      
      this.exchangeRate = overrideExchangeRateValue.EXCH_RT;
      this.tempExchangeRate = overrideExchangeRateValue.EXCH_RT;
      
      this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")]; 
      this.holdingsummaryservice.currencyCdSymbol = this.currencyCdSymbol;
      sessionStorage.setItem("isOverride","1");
      sessionStorage.setItem(this.globals.selectedCurrencyCd, JSON.stringify(curCode));
      this.overrideMarketQuote(this.marketQuoteLatestPrice);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "setNewCurrencyCode");
      error.message += errorLocation;
      throw error;
    }
  }
  
  async getCurrencyList(){
    this.holdingsummaryservice.getCurrencyList().pipe(map(res => res)).subscribe(async data => {
      try {
        this.currencyCodeList= await this.sortData(data, 'Value');

        for(let i=0; i<this.currencyCodeList.length; i++){
          let splitData = this.currencyCodeList[i]["Value"].split("-");

          this.currencyCodeList[i]["Value3"] = splitData[0];
          this.currencyCodeList[i]["Value4"] = splitData[1];
        }

      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getCurrencyList");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getCurrencyList");
     error.error.message += errorLocation;
      throw error.error;
    }); 
  }  
  
    //this block of code re-arranges the array to alphabetical order
  async sortData(data, fieldName){
    try {
      data.sort(function(a, b){
        var array = [a[fieldName], b[fieldName]];
        array.sort();
        if (array[0] == a[fieldName])
          return -1;
        else if(array[0] == b[fieldName])
          return 1;
        else
          return 0;
      });
      return data;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "sortData");
      error.message += errorLocation;
      throw error;
    }
  }

    async getExchangeRateList() {
      if(sessionStorage.getItem("Exchange Rate List") != null){
        this.exchangeRateList = JSON.parse(sessionStorage.getItem("Exchange Rate List")); //put to session storage when lag occurs

        if(sessionStorage.getItem('get exchange rate') != null){
          try {
            let data = JSON.parse(sessionStorage.getItem('get exchange rate'));
            this.defaultValue = data.Exch_Rt;
            this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")];
            this.holdingsummaryservice.currencyCdSymbol = this.currencyCdSymbol;
            
            // this.getDataApi();
          } catch(error) {
            let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateList");
            error.message += errorLocation;
            throw error;
          }
        }
        else{
          this.holdingsummaryservice.getExchangeRate(this.currencyCd).pipe(map(res => res)).subscribe(data => { 
            try {
              this.defaultValue = data.Exch_Rt;
              this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")];
              this.holdingsummaryservice.currencyCdSymbol = this.currencyCdSymbol;
              
              // this.getDataApi();
            } catch(error) {
              let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateList");
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateList");
            error.error.message += errorLocation;
            throw error.error;
          });
        }
      }
      else{
        this.holdingsummaryservice.getExhangeRateList().pipe(map(res => res)).subscribe(data => {
          try {
            this.exchangeRateList = data; //put to session storage when lag occurs
  
            this.holdingsummaryservice.getExchangeRate(this.currencyCd).pipe(map(res => res)).subscribe(data => { 
              try {
                this.defaultValue = data.Exch_Rt;
                this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")];
                this.holdingsummaryservice.currencyCdSymbol = this.currencyCdSymbol;

                sessionStorage.setItem('get exchange rate', JSON.stringify(data));
                // this.getDataApi();
              } catch(error) {
                let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateList");
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateList");
              error.error.message += errorLocation;
              throw error.error;
            });
          } catch(error) {
            let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateList");
            error.message += errorLocation;
           throw error;
          }
        }, error => {
          let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateList");
          error.error.message += errorLocation;
          throw error.error;
        });
      }
      
    }

  async overrideMarketQuote(overridePrice){
    try {
      let regex: RegExp = new RegExp(/^\d{0,9}(\.\d{0,9})?$/g);
     
      let checkTicker: any = this.checkValue.nativeElement.checked;
      // this.marketQuoteLatestPrice = this.inputText.nativeElement.value;

      if(overridePrice.toString().match(regex) != null){
        this.marketQuoteLatestPrice = overridePrice;
        this.tempSharePrice = overridePrice;
      }else{
        this.marketQuoteLatestPrice = this.tempSharePrice;
      }
    
      this.getFounderSharesData();
      this.getPurchaseProgramData();
      this.getOptionsData();
      this.getRSUData();
      this.hideAwards(checkTicker);
      this.getMatchingGrant();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "overrideMarketQuote");
      error.message += errorLocation;
      throw error;
    }
  }
  
  async mobileoverrideMarketQuote(){
    try {   

      let checkTicker: any = this.checkValue.nativeElement.checked;      
      this.marketQuoteLatestPrice = this.mobileinputText.nativeElement.value*1;
           
      this.getFounderSharesData();
      this.getPurchaseProgramData();
      this.getOptionsData();
      this.getRSUData();
      this.hideAwards(checkTicker);
      this.getMatchingGrant();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "overrideMarketQuote");
      error.message += errorLocation;
      throw error;
    }
  }

  async keyUpExchangeRate(keyUpExchangeRate){
    // this.exchangeRate = keyUpExchangeRate; //this line of code stores the original value of the currentSharePrice and also the edited values for the orverrideSharePrice [KPA]
    
    let regex: RegExp = new RegExp(/^\d{0,9}(\.\d{0,9})?$/g);

    if(keyUpExchangeRate.match(regex) != null){
      this.exchangeRate = keyUpExchangeRate;
      this.tempExchangeRate = keyUpExchangeRate;
    }else{
      this.exchangeRate = this.tempExchangeRate;
    }

    this.getPurchaseProgramData();
    this.getRSUData();
    this.getFounderSharesData();
    this.getOptionsData();
    this.getMatchingGrant();
  }

  async reset() {
    try {
      
      let currentCurrencyCd = this.currencyCd;
    
      let list = this.exchangeRateList;
      let overrideExchangeRateValue = list.find(i => i.CNVT_TO_CURR_CD === currentCurrencyCd);
      this.exchangeRate = overrideExchangeRateValue.EXCH_RT;
      this.marketQuoteLatestPrice = this.marketQuoteCurrentPrice.latestPrice;
      this.tempSharePrice = this.marketQuoteCurrentPrice.latestPrice;
      this.tempExchangeRate = overrideExchangeRateValue.EXCH_RT;
      
      this.getFounderSharesData();
      this.getPurchaseProgramData();
      this.getOptionsData();
      this.getRSUData();
      this.getMatchingGrant();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "reset");
      error.message += errorLocation;
      throw error;
    }
  }

    /*********************************************************************************************
   * Method Name: getMatchingGrant                                                                 
   * Description: calls the data for matching grant tooltip                         
   *                                                                                          
   * #  Date                 Modified by                 Description                             
   * 1  08/06/19             JPA                         Created for perf test
   *********************************************************************************************/
  async getMatchingGrant(){
        //Matching Grant Data for ToolTip - START
        this.holdingsummaryservice.getMatchingGrantData(this.user.OptNum).pipe(map(res => res)).subscribe(data => { 
          try {
            this.errorPurchaseCard = false;
            let MatchingGrantToolTipData = data;
            this.matchGrantValue = 0;
            this.matchGrantRemain = MatchingGrantToolTipData[0][0]['Total Veip Matching Grant Remaining'];
            this.matchGrantValue = this.matchGrantRemain * this.marketQuoteLatestPrice * this.exchangeRate; 
            this.matchGrantValue = (Math.round( this.matchGrantValue * 100 ) / 100).toFixed(2);
          } catch(error) {
            this.errorPurchaseCard = true;
            let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          this.errorPurchaseCard = true;
          let errorLocation = this.errorLocation.replace("methodName", "getMatchingGrant");
          error.error.message += errorLocation;
          throw error.error;
        });
        //Matching Grant Data for ToolTip - END 
  }
  
  async getDataApi(){
    if(sessionStorage.getItem("RSU Data") != null){
      try {
        this.errorRsuCard = false;
        let body = JSON.parse(sessionStorage.getItem("RSU Data"));
        this.getRSUData(); 
      } catch(error) {
        this.errorRsuCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.message += errorLocation;
        throw error;
      }
    }
    else{
      this.holdingsummaryservice.getRSU(this.user.OptNum).pipe(map(res => res)).subscribe(data => { 
        try {
          this.errorRsuCard = false;
          let body = data;
          sessionStorage.setItem("RSU Data", JSON.stringify(body));
          this.getRSUData(); 
        } catch(error) {
          this.errorRsuCard = true;
          let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorRsuCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.error.message += errorLocation;
        throw error.error;
      });
    }   

    if(sessionStorage.getItem("Options Data") != null){
      try {
        this.errorOptionsCard = false;
        let body = JSON.parse(sessionStorage.getItem("Options Data"));
        this.getOptionsData();
      } catch(error) {
        this.errorFounderCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.message += errorLocation;
        throw error;
      }
    }
    else{
      this.holdingsummaryservice.getOptions(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorOptionsCard = false;
          let body = data;
          
          sessionStorage.setItem("Options Data", JSON.stringify(body));
          this.getOptionsData();
        } catch(error) {
          this.errorFounderCard = true;
          let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorFounderCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.error.message += errorLocation;
        throw error.error;
      });
    }

    if(sessionStorage.getItem("Purchase Program Data") != null){
      try {
        this.errorPurchaseCard = false;
        let body = JSON.parse(sessionStorage.getItem("Purchase Program Data"));
        this.getPurchaseProgramData();
      } catch(error) {
        this.errorPurchaseCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.message += errorLocation;
        throw error;
      }
    }
    else{
      this.holdingsummaryservice.getPurchaseProgram(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorPurchaseCard = false;
          let body = data;
          sessionStorage.setItem("Purchase Program Data", JSON.stringify(body));
          this.getPurchaseProgramData();
        } catch(error) {
          this.errorPurchaseCard = true;
          let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorPurchaseCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.error.message += errorLocation;
        throw error.error;
      });
    }

    if(sessionStorage.getItem("Founder Share Data") != null){
      try {
        this.errorFounderCard = false;
        let body = JSON.parse(sessionStorage.getItem("Founder Share Data"));
        this.getFounderSharesData();
      } catch(error) {
        this.errorFounderCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.message += errorLocation;
        throw error;
      }
    }
    else{
      this.holdingsummaryservice.getFounderShares(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorFounderCard = false;
          let body = data;
          sessionStorage.setItem("Founder Share Data", JSON.stringify(body));
          this.getFounderSharesData();
        } catch(error) {
          this.errorFounderCard = true;
          let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorFounderCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  /*********************************************************************************************
   * Method Name: getSumRSU                                                                 
   * Description: RSU computations                         
   *                                                                                          
   * #  Date                 Modified by                 Description                             
   * 1  05/15/19             KMN                         BUG 314620 fix
   *********************************************************************************************/
  async getRSUData(){
    this.getTotalPSUsharesbyOptNum();
    try {
      this.errorRsuCard = false;
      let data = JSON.parse(sessionStorage.getItem("RSU Data"));
      let body = data;      
      let sortedBody = data[1].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);
      this.totalRSUSharesOutStanding = 0;
      this.totalRSUAvailableToSell = 0;
      this.totalRSUReleasedVested = 0;
      this.rsuReleasedVested = 0
      this.totalRSUSharesReleasing = 0;
      this.totalRSUForfeited = 0;
      this.rsuForfeited = 0;
      this.rsuSharesReleasing = 0;
      
      if(body[1].length > 0) this.totalRSUAvailableToSell = body[0][0].AvailableToSell; 
      if(this.totalRSUAvailableToSell == null)this.totalRSUAvailableToSell = 0;
      this.totalRSUUnreleasedUnvested == 0 ? this.displayKeyExecToggle = false : this.displayKeyExecToggle = true;
      this.keyExecDisplayInd == 0 ? this.displayKeyExecToggle = false : this.displayKeyExecToggle = true;

      // console.log('getRSUData: Start getRSUData');
  
      this.getSumRSU(sortedBody, 0, this.keyExecVest);  
      
      // console.log('getRSUData: End getRSUData');
      
    } catch(error) {
      this.errorRsuCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getRSUData");
      error.message += errorLocation;
      throw error;
    }
  }

  /*********************************************************************************************
   * Method Name: getSumRSU                                                                 
   * Description: RSU computations                         
   *                                                                                          
   * #  Date                 Modified by                 Description                             
   * 1  02/06/19             Dalemark P. Suquib          Added vest param for key exec
   * 2  02/15/19             Dalemark P. Suquib          Updated computation for target & trend
   * 3  05/07/19             KMN                         Remove condition for brokerId
   * 4  12/13/22             JJA                         Added new computation for key exec (plan num 72 & 74), year 2023 and up
   * 5  06/06/2023           Jewel C. Caudilla           Update computation for keyExectoggle from getSumRSU().
   *********************************************************************************************/
  async getSumRSU(values, checker, vest: string){
    // console.log('getSumRSU: Start getSumRSU');
    try {
      this.errorRsuCard = false;
      this.totalRSUForfeited = 0;
    
      if (checker === 1) {
        this.rsuReleasedVested = 0;
        this.rsuForfeited = 0;
      }
      
      this.rsuSharesReleasing = 0; // key exec
      this.totalRSUUnreleasedUnvested = 0; // key exec
      this.totalRSUSharesOutStanding = 0; // key exec
      this.rsuReleasedVested = 0;
      this.rsuForfeited = 0;
      for(let i = 0; i<values.length; i++){
        /************************* Key Exec - START ************************/
        for(let j = 0; j < this.planNum.length; j++) {
          // console.log('getSumRSU: In loop Key Exec');
          if(this.planNum[j].OI == values[i].PlanNum) {
            let psuDetails = JSON.parse(sessionStorage.getItem("PSU Details by OptNum"));
            let psuAttained = [];

            for(let q = 0; q < psuDetails.length; q++) {
              if(psuDetails[q].DisplayInd === "Y"){
                psuAttained.push(psuDetails[q]);
              }
            }

            for(let v = 0; v < psuAttained.length; v++) {
              if(psuAttained[v].GrantNum == values[i].GrantNum) {
                let awardDate = '20' + values[i].AwardName.substring(2, 4);
          
                if(vest == "Maximum" && values[i].GrantYear <= this.globals.yearCondition) {
                  let sharesTargetVestTotal = Math.round(values[i].SharesReleasing * (3/2));
                  values[i].SharesReleasing = sharesTargetVestTotal;
      
                  let unreleasedTargetVestTotal = Math.round(values[i].UnreleasedUnexercisable * (3/2));
                  values[i].UnreleasedUnexercisable = unreleasedTargetVestTotal;
                  // console.log('getSumRSU: In loop Key Exec Maximum');
                } else if(vest == "Maximum" && values[i].GrantYear > this.globals.yearCondition) {
                  let sharesTargetVestTotal = Math.round(values[i].SharesReleasing * (2));
                  values[i].SharesReleasing = sharesTargetVestTotal;
                  // console.log('getSumRSU: In loop Key Exec else if Maximum');
      
                  let unreleasedTargetVestTotal = Math.round(values[i].UnreleasedUnexercisable * (2));
                  values[i].UnreleasedUnexercisable = unreleasedTargetVestTotal;
                } else if(vest == "Trend" && values[i].GrantYear <= this.globals.yearCondition) {
                  let trendObtained: boolean = false;
                  for(let k = 0; k < this.yearCode.length; k++) {
                    if(this.yearCode[k].Code == awardDate) {
                      let sharesTargetVestTotal = Math.round(values[i].SharesReleasing);
                      let sharesOiPortionTarget = Math.round(sharesTargetVestTotal * (3/4));
                      let sharesTsrOptionTarget = Math.round(sharesTargetVestTotal) - Math.round(sharesOiPortionTarget);
                      let sharesOiPortionTrend = Math.round(sharesOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                      let sharesTsrOptionTrend = Math.round(sharesTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                      let sharesTrendVestTotal = Math.round(sharesOiPortionTrend) + Math.round(sharesTsrOptionTrend);
                      values[i].SharesReleasing = sharesTrendVestTotal;
      
                      let unreleasedTargetVestTotal = Math.round(values[i].UnreleasedUnexercisable);
                      let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                      let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                      let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                      let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                      let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                      values[i].UnreleasedUnexercisable = unreleasedTrendVestTotal;
      
                      trendObtained = true;
                    }
                  }
      
                  if(!trendObtained) {
                    let sharesTargetVestTotal = values[i].SharesReleasing;
                    values[i].SharesReleasing = sharesTargetVestTotal;
      
                    let unreleasedTargetVestTotal = values[i].UnreleasedUnexercisable;
                    values[i].UnreleasedUnexercisable = unreleasedTargetVestTotal;
                  }
                  // console.log('getSumRSU: In loop Key Exec Trend');

                } else if(vest == "Trend" && values[i].GrantYear > this.globals.yearCondition) {
                  let trendObtained: boolean = false;
                  for(let k = 0; k < this.yearCode.length; k++) {
                    if(this.yearCode[k].Code == awardDate) {
                      let sharesTargetVestTotal = Math.round(values[i].SharesReleasing);
                      let sharesOiPortionTarget = Math.round(sharesTargetVestTotal * (3/4));
                      let sharesTsrOptionTarget = Math.round(sharesTargetVestTotal) - Math.round(sharesOiPortionTarget);
                      let sharesOiPortionTrend = Math.round(sharesOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                      let sharesTsrOptionTrend = Math.round(sharesTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                      let sharesTrendVestTotal = Math.round(sharesOiPortionTrend) + Math.round(sharesTsrOptionTrend);
                      values[i].SharesReleasing = sharesTrendVestTotal;
      
                      let unreleasedTargetVestTotal = Math.round(values[i].UnreleasedUnexercisable);
                      let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                      let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                      let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                      let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                      let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                      values[i].UnreleasedUnexercisable = unreleasedTrendVestTotal;
    
                      trendObtained = true;
                    }
                  }
      
                  if(!trendObtained) {
                    let sharesTargetVestTotal = values[i].SharesReleasing;
                    values[i].SharesReleasing = sharesTargetVestTotal;
      
                    let unreleasedTargetVestTotal = values[i].UnreleasedUnexercisable;
                    values[i].UnreleasedUnexercisable = unreleasedTargetVestTotal;
                  }
                  // console.log('getSumRSU: In loop Key Exec else if Trend');
                }
              }
            }
          } 
        }
        /************************* Key Exec - END ************************/

        //JCC, 06-29-2023 : Add psuAttained to the Unreleased/Unvested and SharesReleasing.
          let psuDetails = JSON.parse(sessionStorage.getItem("PSU Details by OptNum"));
          let psuAttained = [];
          for(let q = 0; q < psuDetails.length; q++) {
            if(psuDetails[q].DisplayInd === "N") {
              psuAttained.push(psuDetails[q])
            }
          }

          for(let v = 0; v < psuAttained.length; v++) {
            if(values[i].GrantNum == psuAttained[v].GrantNum) {
              psuAttained[v]["Shares Attained by PSU"] = 0; //JCC --Set to zero 08/09/23
              values[i].UnreleasedUnexercisable = values[i].UnreleasedUnexercisable + psuAttained[v]["Shares Attained by PSU"];
              psuAttained[v].PastInd == "N" ? values[i].SharesReleasing = values[i].SharesReleasing + psuAttained[v]["Shares Attained by PSU"] : values[i].SharesReleasing = values[i].SharesReleasing;
            } else {
              values[i].UnreleasedUnexercisable = values[i].UnreleasedUnexercisable;
              values[i].SharesReleasing = values[i].SharesReleasing;
            }
          }
          
        if(values[i].GrantAgreementTabVisibleInd == "Y"){
          this.YesGrantAccepted = true;
        }
        else{
          this.YesGrantAccepted = false;
        }
        
        values[i].highlighted =false;

        if(values[i].GrantCountryCd == "FRQ" && values[i].GrantLongDesc == "Div") {
          values[i].AvailableToSell = "";   
        }

        if(values[i].AvailableToSell !== "") {
          values[i].Outstanding = values[i].AvailableToSell + values[i].UnreleasedUnexercisable; // Available to Sell + Unreleased
        }
        // values[i].Outstanding = values[i].AvailableToSell + values[i].UnreleasedUnexercisable; // Available to Sell + Unreleased

        this.totalRSUSharesOutStanding += (values[i].Outstanding*1); 
        
        this.totalRSUUnreleasedUnvested = this.totalRSUUnreleasedUnvested + values[i].UnreleasedUnexercisable;
        this.rsuSharesReleasing = this.rsuSharesReleasing + values[i].SharesReleasing;
        this.rsuReleasedVested = this.rsuReleasedVested + values[i].ReleasedExercisableQty;
        this.rsuForfeited = this.rsuForfeited + values[i].Forfeited;
  
        if(values[i].AvailableToSell !== "") {
          values[i].AvailableToSellValue = values[i].AvailableToSell * this.marketQuoteLatestPrice * this.exchangeRate;            
        } else {
          values[i].AvailableToSellValue = 0;
        }
        // values[i].AvailableToSellValue = values[i].AvailableToSell * this.marketQuoteLatestPrice * this.exchangeRate;             
      }
      if(this.rsuForfeited == 0) {
        this.totalRSUForfeited = "";
      }else {
        this.totalRSUForfeited = this.rsuForfeited;
      }
  
      if(this.rsuReleasedVested == 0) {
        this.totalRSUReleasedVested = "";
      }else {
        this.totalRSUReleasedVested = this.rsuReleasedVested;
      }
      if(this.rsuSharesReleasing == 0) {
        this.totalRSUSharesReleasing = "";
      }else {
        this.totalRSUSharesReleasing = this.rsuSharesReleasing;
      }
  
      this.rsuOutstandingValue = this.totalRSUSharesOutStanding * this.marketQuoteLatestPrice * this.exchangeRate;
      this.rsuAvailableToSellValue = this.totalRSUAvailableToSell * this.marketQuoteLatestPrice * this.exchangeRate;
      this.rsuUnreleasedOutstanding = this.totalRSUUnreleasedUnvested * this.marketQuoteLatestPrice * this.exchangeRate;
  
      this.methodCounter += 1;
      this.getProgramsTotal();
      this.rsuOutstandingValue = this.rsuOutstandingValue;
      this.rsuAvailableToSellValue = this.rsuAvailableToSellValue;  
      this.holdingsummaryservice.rsuOutstandingValue = this.rsuOutstandingValue;
      this.holdingsummaryservice.totalRSUAvailableToSell = this.totalRSUAvailableToSell;
      this.holdingsummaryservice.rsuAvailableToSellValue = this.rsuAvailableToSellValue;
      this.holdingsummaryservice.totalRSUUnreleasedUnvested = this.totalRSUUnreleasedUnvested;
      this.holdingsummaryservice.rsuUnreleasedOutstanding = this.rsuUnreleasedOutstanding;
      this.holdingsummaryservice.totalRSUSharesReleasing = this.totalRSUSharesReleasing;
      this.holdingsummaryservice.totalRSUReleasedVested = this.totalRSUReleasedVested;
      this.holdingsummaryservice.totalRSUForfeited = this.totalRSUForfeited;


  
      this.dataSourceRSU = new MatTableDataSource(values);
      this.dataSourceRSU.sort = this.rsuTableSort;
      sessionStorage.setItem("Key Exec Holding Summary Value", JSON.stringify(this.keyExecVest));
      sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Holding Summary"));

      this.RSUMobileData = values;
      this.holdingsummaryservice.dataSourceRSU = this.dataSourceRSU;
      this.holdingsummaryservice.totalRSUSharesOutStanding = this.totalRSUSharesOutStanding;
      // console.log('getSumRSU: End getSumRSU');
      
    } catch(error) {
      this.errorRsuCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getSumRSU");
      error.message += errorLocation;
      throw error;
    }
  }

  async getOptionsData(){
    try {
      this.errorOptionsCard = false;
      this.totalOptionsAvailableToSell = 0;
      
      let data = JSON.parse(sessionStorage.getItem("Options Data"));
      let body = data;

      if(body[1].length > 0) {
        let sortedBody = data[1].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);
      
        // for(let i = 0;i<sortedBody.length;i++){
        // sortedBody[i].Expiration = moment(sortedBody[i].Expiration).format("DD MMM YYYY");
        // }

        // sessionStorage.setItem("hasOptionsInHoldingsSummary", "1");
        
        this.totalOptionsSharesOutstanding = body[0][0].Outstanding;
        if(this.totalOptionsSharesOutstanding == null)
        this.totalOptionsSharesOutstanding = 0;
        this.totalOptionsAvailableToSell = body[0][0].AvailableToSell;
        if(this.totalOptionsAvailableToSell == null)
        this.totalOptionsAvailableToSell = 0;
        this.totalUnreleasedUnvested = body[0][0].UnreleasedUnvested;
        if(this.totalUnreleasedUnvested == null)
        this.totalUnreleasedUnvested = 0;
        this.getSumOptions(sortedBody);

        this.holdingsummaryservice.totalOptionsSharesOutstanding = this.totalOptionsSharesOutstanding;
        this.holdingsummaryservice.totalOptionsAvailableToSell = this.totalOptionsAvailableToSell;
        this.holdingsummaryservice.totalGrantPrice = this.totalGrantPrice;
        this.holdingsummaryservice.totalUnreleasedUnvested = this.totalUnreleasedUnvested;
      }
    } catch(error) {
      this.errorOptionsCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getOptionsData");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getSumOptions
      Description: Get summation of options awards

      #     Date            Modified by       Description
      1     02/14/19        Katrina Narag     Reset optionsOutstandingValue to 0
      2     04/10/19        KMN               BUG FIX 280245 - Fix pull data for unreleased/released
      3     05/07/19        KMN               Removed condition for broker id
      4     05/16/19        KMN               BUG 315218 fix - Incorrect Unreleased/Unvested value (Program Summary Options)
  **********************************************************************************/
  async getSumOptions(values){
    try {
      this.errorOptionsCard = false;
      let availableToSellTempo:number = 0;
      let availableToSellTempoString:any = 0;
      this.optionsAvailableToSellValue = 0;
      this.totalReleasedExercisableQty = 0;
      this.optionsOutstandingValue = 0;   //KMN, 1
      this.optionsUnreleasedUnvested = 0;
      this.totalOptionsForfeited = 0;

      for(let i=0; i<Object.keys(values).length; i++){
        
        values[i].highlighted =false;
        // OUTSTANDING VALUE
        if((this.marketQuoteLatestPrice - values[i].GrantPrice) < 0){
          values[i].Outstanding = (values[i].Outstanding*1) * (0) * this.exchangeRate;
          values[i].Outstanding=(values[i].Outstanding).toFixed(2);
          this.optionsOutstandingValue += (values[i].Outstanding*1);
          
        }
        else{
        values[i].Outstanding = (values[i].Outstanding*1) * (this.marketQuoteLatestPrice - values[i].GrantPrice) * this.exchangeRate;
        values[i].Outstanding=(values[i].Outstanding).toFixed(2);
        this.optionsOutstandingValue += (values[i].Outstanding*1);
        }
  
        // AVAILABLE TO SELL VALUE
        if((this.marketQuoteLatestPrice - values[i].GrantPrice) < 0){
          availableToSellTempo = (values[i].AvailableToSell*1) * (0) * this.exchangeRate;
          availableToSellTempoString = availableToSellTempo;
          availableToSellTempoString = (Math.round( availableToSellTempoString * 100 ) / 100).toFixed(2);
          values[i].AvailableToSellValue = availableToSellTempo;
          values[i].AvailableToSellValue = (Math.round( availableToSellTempo * 100 ) / 100).toFixed(2);
          this.optionsAvailableToSellValue += (availableToSellTempoString * 1);
        }
        else{
          availableToSellTempo = (values[i].AvailableToSell*1) * (this.marketQuoteLatestPrice - values[i].GrantPrice) * this.exchangeRate;
          availableToSellTempoString = availableToSellTempo;
          availableToSellTempoString = (Math.round( availableToSellTempoString * 100 ) / 100).toFixed(2);
          values[i].AvailableToSellValue = (availableToSellTempoString*1);
          values[i].AvailableToSellValue = (Math.round( availableToSellTempoString * 100 ) / 100).toFixed(2);
          this.optionsAvailableToSellValue += (availableToSellTempoString * 1);
        }
  
        // Unreleased/Unvested
  
          if((this.marketQuoteLatestPrice - values[i].GrantPrice) < 0){
            this.optionsUnreleasedUnvested = ((values[i].UnreleasedUnexercisable*1) * (0) * this.exchangeRate).toFixed(2); 
          }  else{ 
            this.optionsUnreleasedUnvested = (values[i].UnreleasedUnexercisable*1) * (this.marketQuoteLatestPrice - values[i].GrantPrice) * this.exchangeRate;
          }
          //this.optionsUnreleasedUnvested += (this.optionsUnreleasedUnvested*1); 
  
        values[i].UnreleasedUnexercisable = values[i].UnreleasedUnexercisable;
        // this.optionsUnreleasedUnvested += (values[i].UnreleasedUnexercisable*1);
        if(Object.keys(values).length == 1){
          this.totalUnreleasedUnvested = this.totalUnreleasedUnvested;
        }else{
        this.totalUnreleasedUnvested += (values[i].UnreleasedUnexercisable*1);
      }
        this.totalReleasedExercisableQty += values[i].ReleasedExercisableQty;
        this.totalOptionsForfeited += values[i].Forfeited;
      }
  
      if(this.totalOptionsForfeited == 0 || this.totalOptionsForfeited == null || this.totalOptionsForfeited == undefined)
        this.totalOptionsForfeited = "";
        this.methodCounter += 1;
        this.getProgramsTotal();
        this.optionsOutstandingValue = this.optionsOutstandingValue;
        this.optionsAvailableToSellValue = this.optionsAvailableToSellValue;
        this.optionsUnreleasedUnvested = this.optionsUnreleasedUnvested;
  
        this.dataSourceOptions = new MatTableDataSource(values);
        this.dataSourceOptions.sort = this.optionsTableSort;
        this.holdingsummaryservice.dataSourceOptions = this.dataSourceOptions;    
        this.holdingsummaryservice.totalReleasedExercisableQty = this.totalReleasedExercisableQty;
        this.holdingsummaryservice.optionsOutstandingValue = this.optionsOutstandingValue;
        this.holdingsummaryservice.optionsAvailableToSellValue = this.optionsAvailableToSellValue;
        this.holdingsummaryservice.optionsUnreleasedUnvested = this.optionsUnreleasedUnvested;

        this.OptionMobileData = values;
        
    } catch(error) {
      this.errorOptionsCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getSumOptions");
      error.message += errorLocation;
      throw error;
    }
  }

  async getPurchaseProgramData(){
    try {
      this.errorPurchaseCard = false;
      let data = JSON.parse(sessionStorage.getItem("Purchase Program Data"));
      let body = data;

      if(body[1].length > 0) {
        let sortedBody = data[1].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);
        this.totalPurchaseSharesOutStanding = body[0][0].Outstanding; //Total Shares Outstanding
        if(this.totalPurchaseSharesOutStanding == null)
        this.totalPurchaseSharesOutStanding = 0;
        
        this.totalPurchaseAvailableToSell = body[0][0].AvailableToSell; //Total Available to Sell
        if(this.totalPurchaseAvailableToSell == null)
        this.totalPurchaseAvailableToSell = 0;
        
        this.totalPurchaseUnReleased = body[0][0].UnreleasedUnexercisable;
        this.getSumPurchaseProgram(sortedBody); //get total     
        this.holdingsummaryservice.totalPurchaseSharesOutStanding = this.totalPurchaseSharesOutStanding;
        this.holdingsummaryservice.totalPurchaseAvailableToSell = this.totalPurchaseAvailableToSell;
        this.holdingsummaryservice.totalPurchaseUnReleased = this.totalPurchaseUnReleased;
      }
    } catch(error) {
      this.errorPurchaseCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramData");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getSumPurchaseProgram
      Description: Get summation for Purchase Programs

      #     Date            Modified by       Description
      1     05/07/19        KMN               Remove condition for BrokerID
      2     05/21/19        KMN               Bug 310461 fix - Removed toFixed(2)
      3     03/05/20        JBP               Fix for Zero Value for Available to Sell in Programs Total
  **********************************************************************************/
 async getSumPurchaseProgram(values){
    try {
      this.errorPurchaseCard = false;
      this.totalPurchaseAvailableToSellValue = 0;    
      this.veipSharesRemainingTotal = 0;
      this.veipSharesValueTotal = 0;
      this.veipTooltip = true;
      this.valueSharesOustanding = this.totalPurchaseSharesOutStanding * this.marketQuoteLatestPrice * this.exchangeRate;

      for(let i = 0; i<Object.keys(values).length; i++){
        this.totalPurchaseForfeited += values[i].Forfeited;
        values[i].highlighted =false;
        values[i].AvailableToSellValue = (values[i].AvailableToSell*1) * this.marketQuoteLatestPrice * this.exchangeRate;
        values[i].AvailableToSellValue = (values[i].AvailableToSellValue);
        this.totalPurchaseAvailableToSellValue += (values[i].AvailableToSellValue*1);

      }
      
      for(let k = 0; k<Object.keys(values).length; k++){
        if(values[k].AwardName.includes("VEIP")){
          if(values[k].AvailableToSell != 0 ){
            this.veipSharesRemainingTotal += values[k].AvailableToSell;
          }
          if(values[k].AvailableToSellValue != null){
            this.veipSharesValueTotal += (values[k].AvailableToSellValue*1);
          }
        }
      }
      if(this.veipSharesRemainingTotal == 0 || null){
          this.veipTooltip = false;
      }
      this.veipSharesValueTotal = (Math.round( this.veipSharesValueTotal * 100 ) / 100).toFixed(2);

      if(this.totalPurchaseForfeited == 0 || this.totalPurchaseForfeited == null || this.totalPurchaseForfeited == undefined)
      this.totalPurchaseForfeited = "";
  
      this.valueSharesOustanding = this.valueSharesOustanding;
      this.valueAvailableToSell = this.totalPurchaseAvailableToSellValue;     
      this.valueAvailableToSell = (Math.round( this.valueAvailableToSell * 100 ) / 100).toFixed(2);
      this.valueAvailableToSell = this.valueAvailableToSell * 1; // JBP --3

      this.methodCounter += 1;
      this.getProgramsTotal();      
  
      this.dataSource = new MatTableDataSource(values);
      this.dataSource.sort = this.purchaseTableSort;
      this.holdingsummaryservice.dataSourceVEIP = this.dataSource;
      this.holdingsummaryservice.totalPurchaseAvailableToSellValue = this.totalPurchaseAvailableToSellValue;
      this.holdingsummaryservice.valueSharesOustanding = this.valueSharesOustanding;
      this.holdingsummaryservice.valueAvailableToSell = this.valueAvailableToSell;

      this.PurchaseMobileData = values;
      
    } catch(error) {
      this.errorPurchaseCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getSumPurchaseProgram");
      error.message += errorLocation;
      throw error;
    }
  }

  async getFounderSharesData() {
    try {

      this.errorFounderCard = false;
      let data = JSON.parse(sessionStorage.getItem("Founder Share Data"));

      if(data[1].length > 0) {
        let body = data;//JSON.parse(data['body']);
        let sortedBody = data[1].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);
        this.totalFounderSharesOutstanding = 0;
        this.totalFounderAvailableToSell = 0;
        this.totalFounderAvailToSellValue = 0;

        if(sortedBody.length > 0) {
          this.totalFounderSharesOutstanding = body[0][0].Outstanding;
          this.totalFounderAvailableToSell = body[0][0].AvailableToSell;
        } else {
          this.totalFounderSharesOutstanding = 0;
          this.totalFounderAvailableToSell = 0;
        }

        this.getFounderSharesValue(sortedBody, 0);
        this.holdingsummaryservice.totalFounderSharesOutstanding = this.totalFounderSharesOutstanding;
        this.holdingsummaryservice.totalFounderAvailableToSell = this.totalFounderAvailableToSell;
        this.holdingsummaryservice.totalFounderAvailToSellValue = this.totalFounderAvailToSellValue;
      }
    } catch(error) {
      this.errorFounderCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getFounderSharesData");
      error.message += errorLocation;
      throw error;
    }
  }

  async getFounderSharesValue(values, checker) {
    try {
      this.errorFounderCard = false;
      let avgHighLow = (this.marketQuoteCurrentPrice.high + this.marketQuoteCurrentPrice.low) / 2;
      this.founderReleasedExercisableQty = 0;
      this.totalFounderAvailToSellValue = 0;
      this.founderAvailableToSellValue = 0;
      this.founderSharesOutstandingValue = 0;
      this.totalFoundersForfeited = 0;

      if (checker === 0){
        this.founderAvailableToSellValue = 0;
        this.founderSharesOutstandingValue = 0;
        this.totalFounderAvailToSellValue = 0;
      } 
  
      for(let i = 0; i < values.length; i++) {
        values[i].highlighted = false;
  
        if(values[i].GrantGroupId == GrantGroup.LTDA) {
          this.founderValue = this.marketQuoteLatestPrice; // share price from marketquote
        } else if(values[i].GrantGroupId == GrantGroup.ACHICan || values[i].GrantGroupId == GrantGroup.ACHINz) {
          this.founderValue = avgHighLow; // average high low from marketquote
        } else if(values[i].GrantGroupId == GrantGroup.SCA && values[i].CurrentAllowedTransaction == GrantTransactionType.Sale) {
          this.founderValue = avgHighLow; // average high low from marketquote
        } else if(values[i].GrantGroupId == GrantGroup.SCA && values[i].CurrentAllowedTransaction == GrantTransactionType.Redemption &&
          values[i].RedemptionMethod == RedemptionMethod.Cash) {
            this.founderValue = avgHighLow; // average high low from marketquote
        } else if(values[i].GrantGroupId == GrantGroup.SCA && values[i].CurrentAllowedTransaction == GrantTransactionType.Redemption &&
          values[i].RedemptionMethod == RedemptionMethod.Share) {
            this.founderValue = this.marketQuoteLatestPrice; // share price from marketquote
        }
  
        values[i].Outstanding = ((this.founderValue * (values[i].Outstanding*1)) * this.exchangeRate).toFixed(2);
        this.founderSharesOutstandingValue += (values[i].Outstanding*1);
  
        values[i].AvailableToSellValue = ((this.founderValue * values[i].AvailableToSell) * this.exchangeRate).toFixed(2);
        this.founderAvailableToSellValue += values[i].AvailableToSellValue*1;      
  
        this.founderReleasedExercisableQty += values[i].ReleasedExercisableQty;
        values[i].SharesReleasing = 0;
  
        this.totalFoundersForfeited += values[i].Forfeited;
        
      }
      if(this.totalFoundersForfeited == 0 || this.totalFoundersForfeited == null || this.totalFoundersForfeited == undefined)
      this.totalFoundersForfeited = "";
      
  
      this.founderSharesOutstandingValue = this.founderSharesOutstandingValue;
      this.founderAvailableToSellValue = this.founderAvailableToSellValue;    
      this.founderUnreleasedUnvestedValue = this.founderUnreleasedUnvestedValue;
      this.totalFounderAvailToSellValue = Number(this.founderAvailableToSellValue).valueOf();    
      this.holdingsummaryservice.founderAvailableToSellValue = this.founderAvailableToSellValue;
      this.holdingsummaryservice.founderUnreleasedUnvestedValue = this.founderUnreleasedUnvestedValue;
      this.holdingsummaryservice.totalFounderUnreleasedUnvested = this.totalFounderUnreleasedUnvested;
      this.holdingsummaryservice.totalFounderAvailToSellValue = this.totalFounderAvailToSellValue;
      this.holdingsummaryservice.founderReleasedExercisableQty = this.founderReleasedExercisableQty;
      this.holdingsummaryservice.totalFoundersForfeited = this.totalFoundersForfeited;
  
      this.methodCounter += 1;
      this.getProgramsTotal();
      
      this.dataSourceFounderShares = new MatTableDataSource(values);    
      this.dataSourceFounderShares.sort = this.founderTableSort;

      this.FoundersMobileData = values;    
      this.holdingsummaryservice.dataSourceFounder = this.dataSourceFounderShares;  
      this.holdingsummaryservice.founderSharesOutstandingValue = this.founderSharesOutstandingValue;

    } catch(error) {
      this.errorFounderCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getFounderSharesValue");
      error.message += errorLocation;
      throw error;
    }
  }

  async getProgramsTotal(){
    try {
      this.programsTotalShareOutstanding = this.totalRSUSharesOutStanding + this.totalFounderSharesOutstanding + this.totalPurchaseSharesOutStanding + this.totalOptionsSharesOutstanding;
      this.programsTotalShareOutstandingValue = this.rsuOutstandingValue + this.founderSharesOutstandingValue + this.valueSharesOustanding + this.optionsOutstandingValue;
      this.programsTotalAvailableToSell = this.totalRSUAvailableToSell + this.totalFounderAvailableToSell + this.totalPurchaseAvailableToSell + this.totalOptionsAvailableToSell;
      this.programsTotalAvailableToSellValue = this.rsuAvailableToSellValue + this.founderAvailableToSellValue + this.valueAvailableToSell + this.optionsAvailableToSellValue;
      this.programsTotalUnReleased = this.totalRSUUnreleasedUnvested + 0 + this.totalUnreleasedUnvested + 0;
      this.programsTotalUnReleasedValue = this.rsuUnreleasedOutstanding + 0 + this.optionsUnreleasedUnvested + 0;

      this.holdingsummaryservice.programsTotalShareOutstanding = this.programsTotalShareOutstanding;
      this.holdingsummaryservice.programsTotalShareOutstandingValue = this.programsTotalShareOutstandingValue;
      this.holdingsummaryservice.programsTotalAvailableToSell = this.programsTotalAvailableToSell;
      this.holdingsummaryservice.programsTotalAvailableToSellValue = this.programsTotalAvailableToSellValue;
      this.holdingsummaryservice.programsTotalUnReleased = this.programsTotalUnReleased;
      this.holdingsummaryservice.programsTotalUnReleasedValue = this.programsTotalUnReleasedValue;
  
      if(this.methodCounter == 4) {
        this.loading = false;
        this.checkAwardsForError();
      }
      this.loading = false;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getProgramsTotal");
      error.message += errorLocation;
      throw error;
    }
  }
  
  checkPurchaseAvailToSell(elementAvailToSell, elementData) {
    try {
      if(elementAvailToSell == 0) {
        if(typeof(elementData) == 'number' || elementData == '0') {
          return 0;
        } else {
          return '';
        }
      } else {
          return elementData;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkPurchaseAvailToSell");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: any) {
    try {
      if(num != 0.00 || num != 0) {
        if(value != null || value != undefined) {
          let number = (num*1).toLocaleString('en', {
            //maximumFractionDigits: value
            minimumFractionDigits : value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value == 1) {
          return '0.00';
        }
        
        return 0;
        // return num;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  
  transform(num: any, checker){
    try {
      if (num != 0) {
        if (num.indexOf('-') > -1 ){
          if (checker == 1){
            let number = num.split('-'); 
            return '(' + this.currencyCdSymbol + number[1] + ')'; 
          } 
          else {
            let number = num.split('-');
            return '(' + number[1] + ')'; 
          }
        }else{ 
          return num;
        } 
      } 
      else {
        return '0';
        // return num;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "transform");
      error.message += errorLocation;
      throw error;
    }
  } 

  collapse(program) {
    try {
      if(program == 'Founder') {
        if((this.checkFounderExpand%2) != 0) {
          this.checkFounderExpand += 1;
          this.setFounderCollapseText = "Hide";
          return this.setFounderCollapseText;
        } else {
          this.checkFounderExpand += 1;
          this.setFounderCollapseText = "Expand";
        }
      } else if(program == 'RSU') {
        if((this.checkRSUExpand%2) != 0) {
          this.checkRSUExpand += 1;
          this.setRSUCollapseText = "Hide";
          return this.setRSUCollapseText;
        } else {
          this.checkRSUExpand += 1;
          this.setRSUCollapseText = "Expand";
        }
      } else if(program == 'Purchase') {
        if((this.checkPurchaseExpand%2) != 0) {
          this.checkPurchaseExpand += 1;
          this.setPurchaseCollapseText = "Hide";
          return this.setPurchaseCollapseText;
        } else {
         this.checkPurchaseExpand += 1;
          this.setPurchaseCollapseText = "Expand";
        }
      } else if(program == 'Option') {
        if((this.checkOptionExpand%2) != 0) {
          this.checkOptionExpand += 1;
          this.setOptionCollapseText = "Hide";
          return this.setOptionCollapseText;
        } else {
          this.checkOptionExpand += 1;
          this.setOptionCollapseText = "Expand";
        }
      }
      else if(program == 'mobileOverrides') {
        if((this.checkOverrideExpand%2) != 0) {
          this.checkOverrideExpand += 1;
          this.setOverrideCollapseText = "OVERRIDE SHARES";
          return this.setOverrideCollapseText;
        } else {
          this.checkOverrideExpand += 1;
          this.setOverrideCollapseText = "OVERRIDE SHARES";
        }
      }
      else if(program == 'mobileRSU') {
        if((this.checkOverrideExpand%2) != 0) {
          this.checkOverrideExpand += 1;
          this.setMobileRSUCollapseText = "RSU Details";
          return this.setMobileRSUCollapseText;
        } else {
          this.checkOverrideExpand += 1;
          this.setMobileRSUCollapseText = "RSU Details";
        }
      }
      else if(program == 'mobileFounders') {
        if((this.checkOverrideExpand%2) != 0) {
          this.checkOverrideExpand += 1;
          this.setMobileFounderCollapseText = "Founder Shares Details";
          return this.setMobileFounderCollapseText;
        } else {
          this.checkOverrideExpand += 1;
          this.setMobileFounderCollapseText = "Founder Shares Details";
        }
      }
      else if(program == 'mobilePurchase') {
        if((this.checkOverrideExpand%2) != 0) {
          this.checkOverrideExpand += 1;
          //this.setMobilePurchaseCollapseText = "Purchase Program";
          this.purchaseTitle;
          return this.purchaseTitle;
        } else {
          this.checkOverrideExpand += 1;
          //this.setMobilePurchaseCollapseText = "Purchase Program";
          this.purchaseTitle;
        }
      }
      else if(program == 'mobileOption') {
        if((this.checkOverrideExpand%2) != 0) {
          this.checkOverrideExpand += 1;
          this.setMobileOptionCollapseText = "Options Details";
          return this.setMobileOptionCollapseText;
        } else {
          this.checkOverrideExpand += 1;
          this.setMobileOptionCollapseText = "Options Details";
        }
      }
      
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "collapse");
      error.message += errorLocation;
      throw error;
    }
  }

  unhighlight(dataRows,highlight){
    for(var rows in dataRows.data ){
      dataRows.data[rows].highlighted=false;
      sessionStorage.setItem("Highlighted", "0");
    }
    highlight.highlighted=true;
      sessionStorage.setItem("Highlighted", "1");
  }

  hideAwards(param){
    this.hideRSUAwards(param);
    this.hideOptionsAwards(param);   
    this.hidePurchaseProgram(param);
    this.hideFounderShares(param);
    // localStorage.setItem("CheckBoxValue", param);
  }

  hideRSUAwards(param){
    try {
      let data = JSON.parse(sessionStorage.getItem("RSU Data")); 
      let newData: any[]=[];
      let rsuTotal: number = 0;
      // if(this.rsuChecker == "1") this.showRSUData = true;
  
        if(data[1].length > 0) {
          if (param == true){
            for(let i = 0; i<data[1].length; i++){
              if(data[1][i].Outstanding != 0) {
                  newData.push(data[1][i]);
              }
              rsuTotal += data[1][i].Outstanding;
            }            
            if(rsuTotal == 0){
              this.showRSUData = false;
              this.holdingsummaryservice.showRSUData = false;
            }
            this.sortHideData = newData[1];
            this.dataSourceRSU = newData;
            this.totalRSUForfeited = 0;
            this.hideEnabled = true;
            sessionStorage.setItem("Filtered RSU Data", JSON.stringify(newData));
            this.selectKeyExecVest(this.keyExecVest);
            this.holdingsummaryservice.dataSourceRSU = this.dataSourceRSU;
          }else {
            let rsuCheck = sessionStorage.getItem("showRSUData");
            if(rsuCheck == "1") this.showRSUData = true, this.holdingsummaryservice.showRSUData = true;
            this.hideEnabled = false;
            this.getRSUData();
          }
        }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "hideRSUAwards");
      error.message += errorLocation;
      throw error;
    }  
  }

    hideOptionsAwards(param){
      try {
        let data = JSON.parse(sessionStorage.getItem("Options Data"));
        let newData: any[]=[];
        let optionsTotal: number = 0;
  
        if(data[1].length > 0) {
          if (param == true) {
              for(let i = 0; i<data[1].length; i++)
              {
                if(data[1][i].Outstanding != 0) 
                {
                  newData.push(data[1][i]);
                }
                optionsTotal += data[1][i].Outstanding;
              }
              if(optionsTotal == 0){
                this.showOptionsData = false;
                this.holdingsummaryservice.showOptionsData = false;
              }
              this.sortHideData = newData[1];
              this.dataSourceOptions = newData;
              this.holdingsummaryservice.dataSourceOptions = this.dataSourceOptions;
              this.getSumOptions(this.dataSourceOptions);
            }else {
              let optCheck = sessionStorage.getItem("showOptionsData");
              if(optCheck == "1") this.showOptionsData = true, this.holdingsummaryservice.showOptionsData = true;
              this.getOptionsData();
            }
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "hideOptionsAwards");
        error.message += errorLocation;
        throw error;
      } 
    }

  hidePurchaseProgram(param){
    try {
      let data = JSON.parse(sessionStorage.getItem("Purchase Program Data"));
      let newData: any[]=[];
      let purchaseTotal: number=0;

      if(data[1].length > 0) {
        if (param == true){
          for(let i = 0; i<data[1].length; i++){
              if(data[1][i].Outstanding != 0) {
                newData.push(data[1][i]);
            }
            purchaseTotal += data[1][i].Outstanding;
          }
              
          if (purchaseTotal == 0) this.showPurchData = false, this.holdingsummaryservice.showVEIPData = false;
          this.sortHideData = newData[1];
          this.dataSource = newData;
          this.holdingsummaryservice.dataSourceVEIP = this.dataSource;
          this.getSumPurchaseProgram(this.dataSource);
        }else{
            let purchChecker = sessionStorage.getItem("showPurchData");
            if(purchChecker == "1") this.showPurchData = true, this.holdingsummaryservice.showVEIPData = true;
            this.getPurchaseProgramData();
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "hidePurchaseProgram");
      error.message += errorLocation;
      throw error;
    } 
  }

    hideFounderShares(param){
      try {
        let data = JSON.parse(sessionStorage.getItem("Founder Share Data"));
        let newData: any[]=[];
        let founderTotal: number = 0;
  
        if(data[1].length > 0) {
          if (param == true){
            for(let i = 0; i<data[1].length; i++){
              if(data[1][i].Outstanding != 0) {
                  newData.push(data[1][i]);
              }
              founderTotal += data[1][i].Outstanding;
            }
            if (founderTotal == 0) this.showFounderData = false, this.holdingsummaryservice.showFounderData = false;
                
            this.sortHideData = newData[1];
            this.dataSourceFounderShares = newData;
            this.getFounderSharesValue(this.dataSourceFounderShares, 1);
            this.holdingsummaryservice.dataSourceFounder = this.dataSourceFounderShares;
          }else{
            let founderCheck = sessionStorage.getItem("showFounderData");
            if(founderCheck == "1") this.showFounderData = true, this.holdingsummaryservice.showFounderData = true;
            this.getFounderSharesData();
          }
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "hideFounderShares");
        error.message += errorLocation;
        throw error;
      }   
    }

    setCurrrencyCd() { 
      try {
        let updatedCurrency = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));
        let localCurrency = JSON.parse(sessionStorage.getItem(this.globals.localCurrencyCd));
  
        if(updatedCurrency != null) {
          this.currencyCd = updatedCurrency;
          this.holdingsummaryservice.currencyCd = updatedCurrency;
        } else {
          this.currencyCd = localCurrency;
          this.holdingsummaryservice.currencyCd = localCurrency;
        }
        
        this.getExchangeRateData(this.currencyCd);
        this.getCurrencyList();
        
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "setCurrrencyCd");
       error.message += errorLocation;
        throw error;
      }
    }

    /*****************************************************************************************
     * Method Name: selectKeyExecVest                                                                 
     * Description: Selects the value of key exec vest and execute its computation affecting 
     * share releasing column, unreleased column & value column, & shares outstanding column.                            
     *                                                                                          
     * #    Date                 Modified by                 Description                             
     * 1    02/06/19             Dalemark P. Suquib          Initial draft
     *****************************************************************************************/
    selectKeyExecVest(vest) {
      try {
        this.keyExecVest = vest;
        if(this.hideEnabled == true) {
          let rsuBody = JSON.parse(sessionStorage.getItem("Filtered RSU Data"));
          this.getSumRSU(rsuBody, 1, this.keyExecVest);
        } else {
          let data = JSON.parse(sessionStorage.getItem("RSU Data"));
          let rsuBody = data[1].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);
          this.getSumRSU(rsuBody, 0, this.keyExecVest);
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "selectKeyExecVest");
        error.message = error.message + errorLocation;
        throw error;
      }
    }

    /*****************************************************************************************
     * Method Name: keyExecVestChecker                                                                 
     * Description: Checks the value of key exec toggle from homepage or detailspage to set 
     * as default value for the radio button.                           
     *                                                                                          
     * #    Date                 Modified by                 Description                             
     * 1    02/06/19             Dalemark P. Suquib          Initial draft
     *****************************************************************************************/
    keyExecVestChecker() {
      try {

        let keyExecHoldingSummaryValue = JSON.parse(sessionStorage.getItem("Key Exec Holding Summary Value"));
        let keyExecRsuDetailsValue = JSON.parse(sessionStorage.getItem("Key Exec Rsu Details Value"));
        let keyExecComponentInd = JSON.parse(sessionStorage.getItem("Key Exec Component Indicator"));
        let keyExecDashboardValue = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));

        if(keyExecHoldingSummaryValue != null && keyExecComponentInd == "Holding Summary") {
          this.keyExecVest = keyExecHoldingSummaryValue;
        } else if(keyExecRsuDetailsValue != null && keyExecComponentInd == "Rsu Details") {
          this.keyExecVest = keyExecRsuDetailsValue;
        } else if (keyExecDashboardValue != null && keyExecComponentInd == "Homepage") {
          this.keyExecVest = keyExecDashboardValue;
        } else {
          this.keyExecVest = "Target";
        }
        
        if(this.keyExecVest == "Target") {
          this.setTargetVest = true;
        } else if(this.keyExecVest == "Trend") {
          this.setTrendVest = true;
        } else {
          this.setMaximumVest = true;
        }

      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "keyExecVestChecker");
        error.message = error.message + errorLocation;
        throw error;
      }
    }

  /*****************************************************************************************
   * Method Name: getCodeDetails                                                                 
   * Description: Gets the data of Code Details.                         
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/20/19             Dalemark P. Suquib          Initial draft
   *****************************************************************************************/
   getCodeDetails() {
    try {
      let keyExectData = JSON.parse(sessionStorage.getItem("Key Exec Toggle Data"));
    
      if(keyExectData != null) {
        let code = keyExectData[0];
      
        this.yearCode.push(code[0]);
        this.yearCode.push(code[1]);
        this.yearCode.push(code[2]);
        this.yearCode.push(code[3]);
    
        this.planNum.push(code[4]);
        this.planNum.push(code[5]);
        this.planNum.push(code[6]);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getCodeDetails");
      error.message += errorLocation;
      throw error;
    }
   }
   
   checkShowExist(tableID){
    try {
      let counter = 0;

      let arrayOfClass: any;
      if($(tableID)[0] != null || $(tableID)[0] != undefined){
        arrayOfClass = $(tableID)[0].classList;
  
        for(let i = 0; i < arrayOfClass.length; i++){
          if(arrayOfClass[i] == "show"){
            counter++;
          }            
        }
      }
  
      return counter;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkShowExist");
      error.message += errorLocation;
      throw error;
    }
  }

  printDashboard(){ 
    try {
      let founderTable = "#collapseFounder";
      let rsuTable ="#collapseRSU";
      let purchaseTable ="#collapsePurchase";
      let optionsTable = "#collapseOptions";
  
      if(this.checkShowExist(founderTable) == 0){
        $(founderTable).addClass("show");
        this.collapse('Founder');
      }
  
      if(this.checkShowExist(rsuTable) == 0){
        $(rsuTable).addClass("show");
        this.collapse('RSU');
      }
  
      if(this.checkShowExist(purchaseTable) == 0){
        $(purchaseTable).addClass("show");
        this.collapse('Purchase');
      }
  
      if(this.checkShowExist(optionsTable) == 0){
        $(optionsTable).addClass("show");
        this.collapse('Option');
      }
  
      window.print();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "printDashboard");
      error.message += errorLocation;
      throw error;
    } 
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Holdingsummary") != -1 && this.user != null) {
                webPage = "Holdingsummary";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  /**********************************************************************************
   * Method Name: checkAwardsForError                                                                 
   * Description: Check if there are errors encountered by the method of each award                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 05/14/19             Dalemark P. Suquib          Initial draft       
   *********************************************************************************/
  checkAwardsForError() {
    if(this.errorFounderCard && this.errorRsuCard && this.errorPurchaseCard && this.errorOptionsCard) {
      this.errorProgramsTotal = true;
    } else {
      this.errorProgramsTotal = false;
    }
  }


  getGlobalID() {
    try {
      this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
        try {
          this.globalIDs = data[0];

          if (this.globalIDs.length == 0) {
            this.phSBGlobalID = true;
            this.globalIDNum1 = ""         
          } else if(this.globalIDs.length == 1){
            this.phSBGlobalID = true;
            if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9) { 
                  this.globalIDNum1 = "Your SSN";
                } else {
                  this.globalIDNum1 = this.globalIDs[0].ParticipantNbr; 
                }
          }
          else{
            if (this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9) {
              this.phSBGlobalID = true;
              this.globalIDNum1 = "Your SSN"
            } else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) == 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) == 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else{
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) " + this.globalIDs[0].ParticipantNbr;
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }

          }
  
          // if(this.globalIDs.length == 0) {
          //   this.phSBGlobalID = true;
          //   this.globalIDNum1 = "";
          // } else if(this.globalIDs.length == 1) {
          //   this.phSBGlobalID = true;
    
          //   if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
          //     this.globalIDNum1 = "Your SSN";
          //   } else {
          //     this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
          //   }
          // } 



        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
      error.message += errorLocation;
      throw error;
    }
   }
  

    checkBrokersInfo(broker){
      event.stopPropagation();
     

      if(this.ubsLoaded == true)
        this.resourceLoading = false;
      else
        this.resourceLoading = true;
      
      if(this.sbLoaded == true)
        this.resourceLoading = false;
      else
        this.resourceLoading = true;
      
      if(this.bigLoaded == true)
        this.resourceLoading = false;
      else
        this.resourceLoading = true;
      
      if(this.ncbLoaded == true)
        this.resourceLoading = false;
      else
        this.resourceLoading = true;
      

      
      this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        let brokerAccounts = data[0];
        if(brokerAccounts.length > 0) {

          for(let i = 0; i < brokerAccounts.length; i++) {
    if(broker == "MS"){
      (<any>$("#brokerContact")).modal({
        backdrop: 'static',
        keyboard: false
      });

      this.brokerHeader = "Morgan Stanley";
      
      this.getGlobalID();

      this.displayPersonalUbs = false;
      this.displayPersonalBig = false;
      this.displayPersonalNcb = false;

      this.bodyData[5] = PageSection.sb;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
        
          let msData = JSON.parse(data.body);
          let msBody;

          if(msData.data) { // length > {}
          msBody = msData.data;

            if (msBody.length > 0){
              this.sbDesc = msBody[0].Body_Desc;
              this.displayPersonalSb = true;
              }
            else {
              this.displayPersonalSb = false;
              this.sbDesc = "";
            } 
          }


        this.bodyData[5] = PageSection.sbDoc;
        this.bodyData[6] = 0;

        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        let msDocData = JSON.parse(data.body);

        let msDocBody;
        
        if(msDocData.data) { // length > {}
                        msDocBody = msDocData.data;
                        if(msDocBody.length > 0) {
                          this.sbDoc = msDocBody;
        
                          for(let i=0; i<this.sbDoc.length; i++){
                            this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                          }
                        }
                        else{
                          this.sbDoc = null;
                        }
                      }
                  
        });
        this.sbLoaded = true;
    this.resourceLoading = false;
      });

    }else if(broker == "UBS"){
      (<any>$("#brokerContact")).modal({
        backdrop: 'static',
        keyboard: false
      });
      

      this.brokerHeader = "UBS Financial Services Inc";
      this.displayPersonalSb = false;
      this.displayPersonalBig = false;
      this.displayPersonalNcb = false;

    this.ubsAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.ubs;
    this.bodyData[6] = 1;
    

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      let ubsData = JSON.parse(data.body);

      let ubsBody;

      if(ubsData.data) { // length > {}
      ubsBody = ubsData.data;
      if(ubsBody.length > 0) {
        this.ubsDesc = ubsBody[0].Body_Desc;
        this.displayPersonalUbs = true;
      }
      else {
      this.displayPersonalUbs = false;
        this.ubsDesc = "";
      }
    }

    this.bodyData[5] = PageSection.ubsDoc;
    this.bodyData[6] = 0;
    
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

      let ubsDocData = JSON.parse(data.body);

      let ubsDocBody;
      
      if(ubsDocData.data) { // length > {}
          ubsDocBody = ubsDocData.data;
          if(ubsDocBody.length > 0){
            this.ubsDoc = ubsDocBody;
    
            for(let i=0; i<this.ubsDoc.length; i++){
              this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
            }
          }
          else{
            this.ubsDoc = null;
          }
        }
    });
    this.ubsLoaded = true;
    this.resourceLoading = false;
    });
    }else if(broker == "BIG"){
      (<any>$("#brokerContact")).modal({
        backdrop: 'static',
        keyboard: false
      });
      

      this.brokerHeader = "Banco de Investimento Global";

      this.displayPersonalSb = false;
      this.displayPersonalUbs = false;
      
      this.displayPersonalNcb = false;

      this.bigAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.big;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      
        let bigData = JSON.parse(data.body);

        let bigBody;

        if(bigData.data) { // length > {}
        bigBody = bigData.data;
        if(bigBody.length > 0){
          this.bigDesc = bigBody[0].Body_Desc;
          this.displayPersonalBig = false;
          this.bigDesc = this.bigDesc.replace('<font color="#3966bf">','<span style="color:#3966bf">');
          this.bigDesc = this.bigDesc.replace('</font>','</span>');
        }else {
          this.displayPersonalBig = false;
          this.bigDesc = "";
        }
      }
      this.bigLoaded = true;
    this.resourceLoading = false;
      });

    }else if(broker == "TA"){
      (<any>$("#brokerContact")).modal({
        backdrop: 'static',
        keyboard: false
      });
      

      this.brokerHeader = "Computershare (formerly National City Bank)";
      this.displayPersonalSb = false;
      this.displayPersonalUbs = false;
      this.displayPersonalBig = false;
      

      this.ncbAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.ncb;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

        let taData = JSON.parse(data.body);

        let taBody;

        if(taData.data) { // length > {}
        taBody = taData.data;
        if(taBody.length > 0){
        this.ncbDesc = taBody[0].Body_Desc;
        this.displayPersonalNcb = true;
        }else {
          this.displayPersonalNcb = false;
          this.ncbDesc = "";
        }
      } 
      
      this.bodyData[5] = PageSection.ncbDoc;
      this.bodyData[6] = 0;         

         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

          let taDocData = JSON.parse(data.body);

          let taDocBody;

          if(taDocData.data) { // length > {}
          taDocBody = taDocData.data;
          if(taDocBody.length > 0){
            this.ncbDoc = taDocBody;

            for(let i=0; i<this.ncbDoc.length; i++){
              this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
            }
          }else {
            this.displayPersonalNcb = false;
            this.ncbDoc = null;
          }
        }
         });
         this.ncbLoaded = true;
         this.resourceLoading = false;
      });

    }else if(broker.indexOf('/')){
      this.brokerHeader = broker;
      let splittedBroker = broker.split('/');
      for(let i = 0; i < splittedBroker.length; i++){
        if(splittedBroker[i] == "MS"){
          this.getGlobalID();
          this.bodyData[5] = PageSection.sb;
          this.bodyData[6] = 1;
          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
            
              let msData = JSON.parse(data.body);

              let msBody;
    
              if(msData.data) { // length > {}
              msBody = msData.data;
    
                if (msBody.length > 0){
                  this.sbDesc = msBody[0].Body_Desc;
                  this.displayPersonalSb = true;
                  }
                else {
                  this.displayPersonalSb = false;
                  this.sbDesc = "";
                } 
              }
    
    
            this.bodyData[5] = PageSection.sbDoc;
            this.bodyData[6] = 0;
    
            this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
            let msDocData = JSON.parse(data.body);

            let msDocBody;
            
            if(msDocData.data) { // length > {}
                            msDocBody = msDocData.data;
                            if(msDocBody.length > 0) {
                              this.sbDoc = msDocBody;
            
                              for(let i=0; i<this.sbDoc.length; i++){
                                this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                              }
                            }
                            else{
                              this.sbDoc = null;
                            }
                          }
                      
            });
            this.sbLoaded = true;
        this.resourceLoading = false;
          });
    
        }else if(splittedBroker[i] == "UBS"){
          
        this.ubsAccount = brokerAccounts[i].Account;
        this.bodyData[5] = PageSection.ubs;
        this.bodyData[6] = 1;
        
    
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          let ubsData = JSON.parse(data.body);

          let ubsBody;
    
          if(ubsData.data) { // length > {}
          ubsBody = ubsData.data;
          if(ubsBody.length > 0) {
            this.ubsDesc = ubsBody[0].Body_Desc;
            this.displayPersonalUbs = true;
          }
          else {
          this.displayPersonalUbs = false;
            this.ubsDesc = "";
          }
        }
    
        this.bodyData[5] = PageSection.ubsDoc;
        this.bodyData[6] = 0;
        
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    
          let ubsDocData = JSON.parse(data.body);
          let ubsDocBody;
          
          if(ubsDocData.data) { // length > {}
              ubsDocBody = ubsDocData.data;
              if(ubsDocBody.length > 0){
                this.ubsDoc = ubsDocBody;
        
                for(let i=0; i<this.ubsDoc.length; i++){
                  this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
                }
              }
              else{
                this.ubsDoc = null;
              }
            }
        });
        this.ubsLoaded = true;
        this.resourceLoading = false;
        });
        }else if(splittedBroker[i] == "BIG"){
          
          this.bigAccount = brokerAccounts[i].Account;
          this.bodyData[5] = PageSection.big;
          this.bodyData[6] = 1;
          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          
            let bigData = JSON.parse(data.body);

            let bigBody;
    
            if(bigData.data) { // length > {}
            bigBody = bigData.data;
            if(bigBody.length > 0){
              this.bigDesc = bigBody[0].Body_Desc;
              this.displayPersonalBig = false;
            }else {
              this.displayPersonalBig = false;
              this.bigDesc = "";
            }
          }
          this.bigLoaded = true;
        this.resourceLoading = false;
          });
    
        }else if(splittedBroker[i] == "TA"){
         
          this.displayPersonalSb = false;
          this.displayPersonalUbs = false;
          this.displayPersonalBig = false;
          
    
          this.ncbAccount = brokerAccounts[i].Account;
          this.bodyData[5] = PageSection.ncb;
          this.bodyData[6] = 1;
          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    
            let taData = JSON.parse(data.body);

            let taBody;
    
            if(taData.data) { // length > {}
            taBody = taData.data;
            if(taBody.length > 0){
            this.ncbDesc = taBody[0].Body_Desc;
            this.displayPersonalNcb = true;
            }else {
              this.displayPersonalNcb = false;
              this.ncbDesc = "";
            }
          } 
          
          this.bodyData[5] = PageSection.ncbDoc;
          this.bodyData[6] = 0;         
    
             this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
    
              let taDocData = JSON.parse(data.body);

              let taDocBody;
    
              if(taDocData.data) { // length > {}
              taDocBody = taDocData.data;
              if(taDocBody.length > 0){
                this.ncbDoc = taDocBody;
    
                for(let i=0; i<this.ncbDoc.length; i++){
                  this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
                }
              }else {
                this.displayPersonalNcb = false;
                this.ncbDoc = null;
              }
            }
             });
             this.ncbLoaded = true;
             this.resourceLoading = false;
          });
      }
    }
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    
    }
  }
  }

  });


      


    }

  
  /**********************************************************************************
   * Method Name: checkAwardsForError                                                                 
   * Description: Highlight Award in Holding Summary after visiting the exact Detail Page                             
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 05/20/2019            Iris Jane Manloloyo         Initial draft       
   *********************************************************************************/
  highlightBack(){   
    //let highlighIndicator = JSON.parse(sessionStorage.getItem("Highlighted"));
    let collapseIndicator = JSON.parse(sessionStorage.getItem("CollapseIndicator"));
    let componentIndicator = JSON.parse(sessionStorage.getItem("Program Component Indicator"));
    let founderTable = "#collapseFounder";
    let rsuTable ="#collapseRSU";
    let purchaseTable ="#collapsePurchase";
    let optionsTable = "#collapseOptions";    
    if (this.highlightChecker == "1" && componentIndicator == "ESPP" || componentIndicator == "VEIP"){
      if(this.checkShowExist(purchaseTable) == 0){
        $(purchaseTable).addClass("show");
        this.collapse('Purchase');
       }
      this.purchCollapse = "collapse show";
       
      }
     else if (this.highlightChecker == "1" && componentIndicator == "Founder")
     {
          if(this.checkShowExist(founderTable) == 0){
            $(founderTable).addClass("show");
            this.collapse('Founder');
          }
          this.founderCollapse = "collapse show";
     }
     else if (this.highlightChecker == "1" && componentIndicator == "Options"){
          if (this.checkShowExist(optionsTable) == 0){
            $(optionsTable).addClass("show");
            this.collapse('Option');
          }
          this.optCollapse = "collapse show";
        }
        else if(this.highlightChecker == "1" && componentIndicator == "RSU"){ 
          if(this.checkShowExist(rsuTable) == 0){
            $(rsuTable).addClass("show");
            this.collapse('RSU');
          }
          this.rsuCollapse = "collapse show";
        }
       else {
        this.rsuCollapse = "collapse";
        this.optCollapse= "collapse";
        this.purchCollapse= "collapse";
        this.founderCollapse = "collapse";
       }
      }

      /**********************************************************************************
   * Method Name: isActive                                                                 
   * Description: To return the value for ngClass in Highlight Previous Visited Award                             
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 05/30/2019            Iris Jane Manloloyo         Initial draft       
   *********************************************************************************/
  selected: any;

  isActive(item){    
    // let selected = JSON.parse(sessionStorage.getItem("currentVisitedAward"));
    // this.selected = selected.AwardName;
    return this.selected === item;
    // return this.activeRow;
  };
 

/**********************************************************************************
* Method Name: highlight                                                                 
* Description: onCLick event for Highlight Previous Award                             
*                                                                                          
* Date                 Modified by                 Description                             
* 05/30/2019            Iris Jane Manloloyo         Initial draft       
*********************************************************************************/
  highlight(row){
    this.selected = row.AwardName;
    sessionStorage.setItem("currentVisitedAward", JSON.stringify(row));
    // this.isActive(row);
    return this.selected === row.AwardName;
  }



  /**********************************************************************************
   * Method Name: highlightedRow                                                                 
   * Description: Highlight Award in Holding Summary after visiting the exact Detail Page                             
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 05/20/2019            Iris Jane Manloloyo         Initial draft       
   *********************************************************************************/
  //  active: any;   
   
  //  highlightedRow(row){
  //    this.active = document.getElementsByClassName('active') as HTMLCollectionOf<HTMLElement>;
  //       if (this.selected === row.AwardName){
  //         this.active.style.transform = "background: #666; ";
  //       }
  //     }

  getFile(file, checker) {
    try {
      let fileName: string;
      let fileType: string;
      

      
      if(checker == "static") {
        if(file.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
        
        fileName = file;
      } else {
        if(file.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
    
        fileName = file;
       
      }

      
      this.staticContentService.getFile(fileName, "MD").subscribe(data => { // MD for contacts page
        try {
          let content = JSON.parse(data._body);
    
          if (content != null){    
              let file = JSON.parse(content.body);
              this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
              this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
                let writeLogResponse = data;
              });
          }else{
            this.staticContentService.noFileExists(fileName);
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "navToPage");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.message += errorLocation;
      throw error;
    }
   }
    
   fileImage(filyType){
    if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
      this.fileTypeImagePath = '/assets/pdf.svg';
      this.fileTypeAlt = "PDF File"

      return true;
    }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
      this.fileTypeImagePath = '/assets/zip.svg';
      this.fileTypeAlt = "ZIP File"
      return true;
    }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
      this.fileTypeImagePath = '/assets/xls.svg';
      this.fileTypeAlt = "XLS File"
      return true;
    }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
      this.fileTypeImagePath = '/assets/docx.svg';
      this.fileTypeAlt = "DOC File"
      return true;
    }
   }

    getFileName(event){
      try {
        let href: string = event.target.href;
        let id: string = event.target.id;
        let srcElement: HTMLImageElement = event.srcElement;
        let target: string = event.target.target;
        if (target == ""){
          target = "_self";
        }
        if(srcElement.src != undefined){
          this.staticContentService.validateFileType(event);
        }else if(href != undefined && href != "" ){
          if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
            this.staticContentService.validateFileType(event);
          }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
            let innerTxt = event.target.innerText;
            event.preventDefault();
            if(innerTxt in MyHoldingsPagesResources){
              this.navToMyHoldingsPage(href);
            }else if(href.indexOf("Resources") != -1){
              this.navToMyHoldingsPage("Resources");
            }
          }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
            event.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
          }else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
            event.preventDefault();
            window.open(href, target);
          }else{ //if has file but no filename text
            event.preventDefault();
            this.staticContentService.validateFileType(event);
          }
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFileName");
        error.message += errorLocation;
        throw error;
      }
    }

    navToMyHoldingsPage(page){
      page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
      page.indexOf("Contacts") != -1 ? page = "contactspage" : "";
    
      window.open(this.globals.currentWebUrl + "/" + page, "_blank");
    }

    printFile() {
      try {
        let award: string = "holdingsummarypage";
        this.printService.print(award);
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "printFile");
        error.message += errorLocation;
        throw error;
      }
    }
    
    checkTotalValue(){
      let ptsov = $("#ptsov");
      let ptatsv = $("#ptatsv");
      let pturv = $("#pturv");
      let fsov = $("#fsov");
      let fatsv = $("#fatsv");
      let fuuv = $("#fuuv");
      let rosv = $("#rosv");
      let ravts = $("#ravts");
      let ruov = $("#ruov");
      let vsov = $("#vsov");
      let vatsv = $("#vatsv");
      let oosv = $("#oosv");
      let oatsv = $("#oatsv");
      let ouuv = $("#ouuv");

      ptsov.addClass("displayNone");
      ptatsv.addClass("displayNone");
      pturv.addClass("displayNone");

      if(fsov != null && fsov != undefined && fsov.length > 0) fsov.addClass("displayNone");
      if(fatsv != null && fatsv != undefined && fatsv.length > 0) fatsv.addClass("displayNone");
      if(fuuv != null && fuuv != undefined && fuuv.length > 0) fuuv.addClass("displayNone");

      if(rosv != null && rosv != undefined && rosv.length > 0) rosv.addClass("displayNone");
      if(ravts != null && ravts != undefined && ravts.length > 0) ravts.addClass("displayNone");
      if(ruov != null && ruov != undefined && ruov.length > 0) ruov.addClass("displayNone");

      if(vsov != null && vsov != undefined && vsov.length > 0) vsov.addClass("displayNone");
      if(vatsv != null && vatsv != undefined && vatsv.length > 0) vatsv.addClass("displayNone");

      
      if(oosv != null && oosv != undefined && oosv.length > 0) oosv.addClass("displayNone");
      if(oatsv != null && oatsv != undefined && oatsv.length > 0) oatsv.addClass("displayNone");
      if(ouuv != null && ouuv != undefined && ouuv.length > 0) ouuv.addClass("displayNone");

      setTimeout(() => {
        ptsov.text(this.checkTValue(ptsov[0].innerText));
        ptsov.removeClass("displayNone");

        ptatsv.text(this.checkTValue(ptatsv[0].innerText));
        ptatsv.removeClass("displayNone");                

        pturv.text(this.checkTValue(pturv[0].innerText));
        pturv.removeClass("displayNone");    

        if(fsov != null && fsov != undefined  && fsov.length > 0){
        fsov.text(this.checkTValue(fsov[0].innerText));
        fsov.removeClass("displayNone");  }

        if(fatsv != null && fatsv != undefined && fatsv.length > 0){
        fatsv.text(this.checkTValue(fatsv[0].innerText));
        fatsv.removeClass("displayNone"); }

        if(fuuv != null && fuuv != undefined && fuuv.length > 0){
        fuuv.text(this.checkTValue(fuuv[0].innerText));
        fuuv.removeClass("displayNone");    }             

        if(rosv != null && rosv != undefined && rosv.length > 0){
        rosv.text(this.checkTValue(rosv[0].innerText));
        rosv.removeClass("displayNone");      }           

        if(ravts != null && ravts != undefined && ravts.length > 0){
        ravts.text(this.checkTValue(ravts[0].innerText));
        ravts.removeClass("displayNone");    }             

        if(ruov != null && ruov != undefined && ruov.length > 0){
        ruov.text(this.checkTValue(ruov[0].innerText));
        ruov.removeClass("displayNone");  
        }               

        if(vsov != null && vsov != undefined && vsov.length > 0){
        vsov.text(this.checkTValue(vsov[0].innerText));
        vsov.removeClass("displayNone");    }             

        if(vatsv != null && vatsv != undefined && vatsv.length > 0){
        vatsv.text(this.checkTValue(vatsv[0].innerText));
        vatsv.removeClass("displayNone");                
        } 

        if(oosv != null && oosv != undefined && oosv.length > 0){
        oosv.text(this.checkTValue(oosv[0].innerText));
        oosv.removeClass("displayNone");  }               

        if(oatsv != null && oatsv != undefined && oatsv.length > 0){
        oatsv.text(this.checkTValue(oatsv[0].innerText));
        oatsv.removeClass("displayNone");        }         

        if(ouuv != null && ouuv != undefined && ouuv.length > 0){
        ouuv.text(this.checkTValue(ouuv[0].innerText));
        ouuv.removeClass("displayNone");     
        }            
      }, .0001);
    }

    checkTValue(rawText){
      let newVal: any;
      let preFix: any;
      let raw = rawText;
      let currency: string = this.getCurrency(raw);
      let amount = raw.slice(currency.length,raw.length);
  
      let value = Number(amount.replace(/,/g,""));
  
      if(raw.includes("lion") != true){
        if(value >= 1000000000 && value < 1000000000000){
          preFix = Math.floor(value/1000000000);
          newVal = currency + " "+ preFix + " Billion";
        }
        else if(value >= 1000000000000 && value <= 999000000000000){
          preFix = Math.floor(value/1000000000000);
          newVal = currency + " "+ preFix + " Trillion";
        }
        else if(value > 999000000000000){
          newVal = currency + " 999 Trillion";
          newVal = newVal.replace(/E\+/g, "")
        }
        else{
          newVal = raw;
        }
      }

      return newVal;
      
    }

    getCurrency(raw){
      let currency: string = "";
      for (let i = 0; i < raw.length; i++) {
        const element = raw[i];
        // if (isNaN(element) && !isNumber(element)) {
        //   currency += element;
        // }
        
      }
      currency = currency.replace(/[,.]/g,"");
      return currency;
    }
    checkboxPref(check: number) {
      try {
        
        this.userService.insertCheckboxPref(this.currencyCd, check, this.user.OptNum).subscribe(data => {
        
        }, error => {
          let errorLocation = this.errorLocation.replace("methodName", "checkboxPref");
          error.error.message += errorLocation;
          throw error.error;
        });
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkboxPref");
        error.message += errorLocation;
        throw error;
      }
    }

    hidePref(event){     
      if(sessionStorage.getItem(this.globals.AdminCred)==null){
        if(event){
          this.checkboxPref(1);
          sessionStorage.setItem("checkBoxSelection","y")
        }
      else{
        this.checkboxPref(0);
        sessionStorage.setItem("checkBoxSelection","n")
      }        
    }else{
      if(event){        
        sessionStorage.setItem("checkBoxSelection","y")        
      }
    else{
      sessionStorage.setItem("checkBoxSelection","n")      
     } 
  }
      
}
    purchaseProgTitle(){
    let data = JSON.parse(sessionStorage.getItem("Purchase Program Data"));
    let veip = 0;
    let espp = 0;

    if(data[1].length > 0) {
      for(let i = 0; i<data[1].length; i++){

        if(data[1][i].AwardName.indexOf("ESPP") > -1) {
          espp = 1;
        } else if (data[1][i].AwardName.indexOf("VEIP") > -1) {
          veip = 1;
        }
      }
    } 
    if (veip > 0 && espp > 0){
      this.purchaseTitle = "ESPP and VEIP Purchase Details";
    } else if (espp > 0){
      this.purchaseTitle = "ESPP Purchase Details";
    } else if (veip > 0){
      this.purchaseTitle = "VEIP Purchase Details";
    }
  }

  /*****************************************************************************************
    * Method Name: getTotalPSUsharesbyOptNum
    * Description: get the total PSU Shares
    *
    * #    Date                 Modified by                           Description
    * 1    06/12/2023           Jewel C. Caudilla                     Initial draft
  *****************************************************************************************/
  async getTotalPSUsharesbyOptNum() {
    let data = await JSON.parse(sessionStorage.getItem("PSU Details by OptNum"));
     try {
      // console.log("getTotalPSUSharesbyOptnum: holdingsummary");
      // console.log(data.length);
        if (data.length > 0) {
          this.keyExecDisplay(data);
        } else {
          // console.log("gettotalPSUsharesbyOptnum: Else")
          this.psuKeyExecInd = false;
          this.keyExecVest = "Target";
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getTotalPSUsharesbyOptNum");
        error.message += errorLocation;
        throw error;
      }
  }

  /*****************************************************************************************
  * Method Name: keyExecDisplay
  * Description: keyExecDisplay will decide if keyExecToggle will display.
  *
  * #    Date                 Modified by                           Description
  * 1    06/12/2023           Jewel C. Caudilla                     Initial draft
  *****************************************************************************************/
  keyExecDisplay(body) {
    let keyIndex = body.findIndex(function(body){
      return body["DisplayInd"] === "Y";
    })
    // console.log("hello keyExectDisplay");

    if(keyIndex >= 0){
    this.psuKeyExecInd = true;
    } else {
      this.psuKeyExecInd = false;
      this.keyExecVest = "Target";
    }
  }

}