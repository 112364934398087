import { Component, OnInit } from '@angular/core';
import { EnrollmenthistorypageService } from './EnrollmentHistoryPageService';
import { EspptransactionService } from '../enrollmentwithdraw-espp/EsppTransactionService'
import { Router } from "@angular/router";
import { Globals } from '../globals';
import { PageEvent } from '@angular/material/paginator';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reports-enrollmenthistorypage',
  templateUrl: './reports-enrollmenthistorypage.component.html',
  styleUrls: ['./reports-enrollmenthistorypage.component.css']
})

export class ReportsEnrollmenthistorypageComponent implements OnInit {

  user: any = JSON.parse(sessionStorage.getItem(this.globalVariables.profileInfoKey));

  enrollmentHistoryDetails: any;
  enrollmentHistoryDetailsNextBatch: any;
  enrollmentHistoryDetailsPrevBatch: any;
  displayedColumnsAdmin: string[] = ['Date','PersonnelNbr','LastName','FirstName','EmploymentStatus','PurchaseProgram','PurchasePeriod','Status','ContributionP','MatchingG'];

  pageLength: any = 51;
  pageLengthNext: any = 51;
  prevIndex: any = 0;
  currentIndex: any = 1;
  pageSize: any = 0;
  programType: 1;
  fromDate: any;
  toDate: any;

  loading: any = true;
  enrollHistoryTblLoading: any = false;

  hasEspp: any;
  hasVeip: any;
  isEnrolldCurrEsppOff: any;
  isEnrolldCurrVeipOff: any;
  hasFounder: any;
  hasRsu: any;
  hasOptions: any;


  constructor(private enrollservice: EnrollmenthistorypageService,  private globalVariables: Globals, private esppTransactionService: EspptransactionService) { }

  ngOnInit() {
    this.getEnrollmentHistoryData();
    
  }

  removeUnwantedStyles(){
    $(".mat-paginator-range-label").css("display", "none");
  }

  // initializePagination(){
  //   if(this.enrollmentHistoryDetails.length<50){
  //     this.pageLength = 50;
  //   }
  //   // else()
  // }

  getEnrollmentHistoryData(){
    this.enrollservice.getEnrollmentData(this.user.OptNum, this.programType, this.fromDate, this.toDate, this.currentIndex).subscribe(data => {
      this.enrollmentHistoryDetails = data[0];
      this.enrollmentHistoryDetailsPrevBatch = this.enrollmentHistoryDetails;
      if(this.enrollmentHistoryDetails.length<50){
        this.pageLength = this.enrollmentHistoryDetails.length;
      }
      else
      {
        this.getEnrollmentHistoryDataNextPage(2, "next");
      }
    });
  }

  getEnrollmentHistoryDataNextPage(pageIndex, checker){
    this.enrollservice.getEnrollmentData(this.user.OptNum, this.programType, this.fromDate, this.toDate, pageIndex).subscribe(data => {
      if(checker == "next"){
        this.enrollmentHistoryDetailsNextBatch = data[0];
        if(data[0].length<50){
          this.pageLengthNext = data[0].length;
          this.pageLength = this.pageLength+data[0].length;
        }
        else{
          this.pageLength = this.pageLength+50;
        }
    }
    else{
      this.enrollmentHistoryDetailsPrevBatch = data[0];
      if(data[0].length<50){
        this.pageLengthNext = data[0].length;
        this.pageLength = this.pageLength-data[0].length;
      }
      else{
        this.pageLength = this.pageLength-50;
      }
    }

      this.loading = false;
      this.enrollHistoryTblLoading = false;
      this.removeUnwantedStyles();
    });
  }

  getNext(event: PageEvent){
    this.enrollHistoryTblLoading = true;

    if((event.pageIndex+1) > this.currentIndex){
      this.prevIndex = this.currentIndex;
      this.currentIndex = this.currentIndex+1;
      this.enrollmentHistoryDetailsPrevBatch = this.enrollmentHistoryDetails;
      this.enrollmentHistoryDetails = this.enrollmentHistoryDetailsNextBatch;
      this.getEnrollmentHistoryDataNextPage(this.currentIndex+1, "next");

    }
    else{
      this.prevIndex = this.currentIndex;
      this.currentIndex = this.currentIndex-1;
      this.enrollmentHistoryDetailsNextBatch = this.enrollmentHistoryDetails;
      this.enrollmentHistoryDetails = this.enrollmentHistoryDetailsPrevBatch
      this.getEnrollmentHistoryDataNextPage(this.currentIndex-1, "prev");
    }
  }

  getIsEnrollCurrESPPDetails(){
    this.esppTransactionService.getESPPEnrollDetail(this.user.OptNum).pipe(map(res => res)).subscribe(data =>{
      // this.isEnrolldCurrEsppOff
    });
  }
  

}
