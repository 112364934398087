import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Store } from '@ngxs/store';
import { StaticService } from '../../../services/ea/api/StaticService';
import { ApplicationaccessService } from '../../../services/ea/api/ApplicationaccessService';
import { ToolaccessService } from '../../../services/ea/api/ToolaccessService';
import { RoleaccessService } from '../../../services/ea/api/RoleaccessService';
import { BdrTab } from '../../../services/ea/models/bdrtab-model';
import { BillTo } from '../../../services/ea/actions/billto.actions';
import { ApiService } from '../../../services/ea/api/ApiService';
import { BdraccessService } from '../../../services/ea/api/BdrAccessService';

@Component({
  selector: 'app-bdr',
  templateUrl: './bdr.component.html',
  styleUrls: ['./bdr.component.css']
})
export class BdrComponent implements OnInit {

  bdrHeaderTitle: string;
  bdrItems: any[] = [];

  activeBdrTab: string;

  applicationAccess: boolean = this.applicationAccessService.getUserAppAccess();
  toolAccess: boolean = this.toolAccessService.getUserToolAccess();
  roleId: number = this.roleAccessService.getUserRoleAccess();
  isLoading: boolean = true;
  isError: boolean = false;

  staticTextContent: any;
  activeLink: any;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private store: Store,
    private staticContentService: StaticService,
    private applicationAccessService: ApplicationaccessService,
    private toolAccessService: ToolaccessService,
    private roleAccessService: RoleaccessService,  
    private apiService: ApiService,  
    private bdrAccessService: BdraccessService
    ) { 
      this.activeLink = router.events.subscribe( event => {
        if(event instanceof NavigationEnd) {
          this.setActiveTab(event.url);
        }
      });
      this.bdrAccessService.access$.subscribe(access => {
        this.toolAccess = access;
      });
    }

  ngOnInit() {
    this.checkAccess();
  }

  ngOnDestroy(){
    this.activeLink.unsubscribe();
  }

  /**********************************************************************************
  * Method Name: checkAccess                                                                 
  * Description: Check application and tool access.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/20/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  checkAccess(): void {
    if(this.applicationAccess && this.toolAccess) {
      this.getStaticContents();
    } else {
      this.isLoading = false;
    }
  }

  /**********************************************************************************
  * Method Name: getStaticContents                                                                 
  * Description: Gets the static content data from the api.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/20/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getStaticContents(): void {
    let messageName: string = '%ea.ejet%';
    this.staticContentService.getStaticContent(messageName).then((data) => {
      if(data) {
        this.staticTextContent = data;
        this.store.dispatch(new BillTo.SetStaticData(this.staticTextContent));
        this.getStaticIndex();
      } else { 
        this.isLoading = false;
      }
    }).catch((error) => {
      if(error) {
        this.isLoading = false;
        this.isError = false;
      }
    });
  }

  /**********************************************************************************
  * Method Name: getStaticIndex                                                                 
  * Description: Gets the static content data per tabs.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                            
  * 1         04/20/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getStaticIndex(): void {
    try {
      for(let i = 0; i < this.staticTextContent.length; i++) {
        if(this.staticTextContent[i].MESSAGE_NAME == 'ea.ejet.bdr.header') {
          this.bdrHeaderTitle = this.staticTextContent[i].MESSAGE_VALUE;
        } else if(this.staticTextContent[i].MESSAGE_NAME == 'ea.ejet.bdr.tab1') {
          let bdrTab = new BdrTab();
          bdrTab.id = this.staticTextContent[i].MESSAGE_VALUE;
          bdrTab.path = 'billto';
          bdrTab.isActive = false;
          this.bdrItems.push(bdrTab);
        } else if(this.staticTextContent[i].MESSAGE_NAME == 'ea.ejet.bdr.tab2') {
          let bdrTab = new BdrTab();
          bdrTab.id = this.staticTextContent[i].MESSAGE_VALUE;
          bdrTab.path = 'invoicedescription';
          bdrTab.isActive = false;
          this.bdrItems.push(bdrTab);
        } else if(this.staticTextContent[i].MESSAGE_NAME == 'ea.ejet.bdr.tab3') {
          let bdrTab = new BdrTab();
          bdrTab.id = this.staticTextContent[i].MESSAGE_VALUE;
          bdrTab.path = 'holdingcompanycode';
          bdrTab.isActive = false;
          this.bdrItems.push(bdrTab);
        } else if(this.staticTextContent[i].MESSAGE_NAME == 'ea.ejet.bdr.tab4') {
          let bdrTab = new BdrTab();
          bdrTab.id = this.staticTextContent[i].MESSAGE_VALUE;
          bdrTab.path = 'accountnumber';
          bdrTab.isActive = false;
          this.bdrItems.push(bdrTab);
        }
      }
      this.setActiveTab(this.bdrItems[0].path);
      this.routeToComp(this.bdrItems[0].path);  
      this.isLoading = false;  
    } catch(error) { 
      this.isLoading = false;  
      this.isError = true;
      error.message += this.apiService.setErrorContents('getStaticIndex', 'Bdr');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: routeToComp                                                                 
  * Description: routes to selected component.                   
  *                                                                                          
  * #         Date                 Modified by                    Description                               
  * 1         01/15/20             John Paul B. Fernando          Initial draft    
  ***********************************************************************************/
  routeToComp(path): void {
   try {  
      this.router.navigate([path], {relativeTo: this.activeRoute})  
   } catch (error) {  
      this.isLoading = false;  
      this.isError = true; 
      error.message += this.apiService.setErrorContents('routeToComp', 'Bdr');
      throw error;
   }
 }


  /**********************************************************************************
 * Method Name: setActiveTab                                                                 
 * Description: Sets the active tab                   
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/12/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/ 
  setActiveTab(url){
    try {
      for (let i = 0; i < this.bdrItems.length; i++) {
        if (url.includes(this.bdrItems[i].path)) {
          this.bdrItems[i].isActive = true;
        } else {
          this.bdrItems[i].isActive = false;
        }  
      }  
    } catch (error) { 
      this.isLoading = false;  
      this.isError = true;
      error.message += this.apiService.setErrorContents('setActiveTab', 'Bdr');
      throw error;
    }
  }

}
