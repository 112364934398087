import { Component, OnInit, Input } from '@angular/core';
import { SharePriceHistoryPageService } from './SharePriceHistoryPageService';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reports-sharepricehistorypage',
  templateUrl: './reports-sharepricehistorypage.component.html',
  styleUrls: ['./reports-sharepricehistorypage.component.css']
})
export class ReportsSharepricehistorypageComponent implements OnInit {
  @Input() dateFrom: string;
  @Input() dateTo: string;

  sharePriceHistDataSource: object = [];
  displayedSharePriceHistColumns: string[] = ['PRICE_DT', 'HIGH_PRC', 'LOW_PRC', 'CLOSE_PRC', 'MARKET_PRC', 'VOLUME'];
  sharePriceHistLoading: boolean = true;

  constructor(private sharePriceHistService: SharePriceHistoryPageService) { }

  ngOnInit() {
    let currentDate = new Date();
    this.dateFrom = "'" + moment(currentDate).format("YYYY-MM-DD") + "'";
    this.dateTo = "'" + moment(currentDate).format("YYYY-MM-DD") + "'";

    this.getSharePriceHistory();
  }

  getSharePriceHistory() {
    this.sharePriceHistService.getSharePriceHistory(this.dateFrom, this.dateTo, 1).pipe(map(res => res)).subscribe(data => {
      this.sharePriceHistDataSource = data[0];
      this.sharePriceHistLoading = false;
    })
  }

  setDate(dateFrom, dateTo) {
    this.dateFrom = "'" + dateFrom + "'";
    this.dateTo = "'" + dateTo + "'";
    this.getSharePriceHistory();
  }

}
