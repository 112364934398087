
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
// import { Headers, RequestOptions, Http } from '@angular/http';

@Injectable()
export class HoldingprogramdetailsRsuService {
  headers: Headers;
  // options: RequestOptions;
  token: string = sessionStorage.getItem("msal.idtoken");
  shareAct:any;
  psuDetails:any;
  agreementContent: any;
  agreementVestDataSource: any;
  agreementRelDataSource:any;

  constructor(private globals: Globals, private httpClient: HttpClient) { }

  getPSUDetails(grantID, optNum) {
    let apiUrl = this.globals.currentAPIUrl;
    let psuDetailsApi = apiUrl + this.globals.getPSUDetailsAPI;

    psuDetailsApi = psuDetailsApi.replace("[param1]", grantID);
    psuDetailsApi = psuDetailsApi.replace("[param2]", optNum);

    try {
      const api = psuDetailsApi;      
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getRSUDetails(grantNum) {
    let apiUrl = this.globals.currentAPIUrl;
    let rsuDetailsApi = apiUrl + this.globals.getRSUDetailsAPI;

    rsuDetailsApi = rsuDetailsApi.replace("[param1]", grantNum);

    try {
      const api = rsuDetailsApi;      
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getRSUDetailsRelInfo(grantNum, rsuDate) {
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let rsuDetailsRelApi = apiUrl + this.globals.getRSUDetailsRelInfoAPI;
    
    rsuDetailsRelApi = rsuDetailsRelApi.replace("[param1]", grantNum);
    rsuDetailsRelApi = rsuDetailsRelApi.replace("[param2]", rsuDate);
    rsuDetailsRelApi = rsuDetailsRelApi.replace(regEx, "");
   
    try {
      const api = rsuDetailsRelApi; 
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getRSUDetailsDivInfo(grantNum, rsuDate) {
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let rsuDetailsDivApi = apiUrl +  this.globals.getRSUDetailsDivInfoAPI;

    rsuDetailsDivApi = rsuDetailsDivApi.replace("[param1]", grantNum);
    rsuDetailsDivApi = rsuDetailsDivApi.replace("[param2]", rsuDate);    
    rsuDetailsDivApi = rsuDetailsDivApi.replace(regEx, "");

    try {
      const api = rsuDetailsDivApi;     
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err; 
    }
  }

  getRSUDetailsVestInfo(grantNum, rsuDate) {
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let rsuDetailsVestApi = apiUrl +  this.globals.getRSUDetailsVestInfoAPI;

    rsuDetailsVestApi = rsuDetailsVestApi.replace("[param1]", grantNum);
    rsuDetailsVestApi = rsuDetailsVestApi.replace("[param2]", rsuDate);
    rsuDetailsVestApi = rsuDetailsVestApi.replace(regEx, "");

    try {
      const api = rsuDetailsVestApi; 
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getRSUAgreementInfo(grantNum) {
    let apiUrl = this.globals.currentAPIUrl;
    let rsuAgreementApi = apiUrl + this.globals.getRSUAgreementAPI;

    rsuAgreementApi = rsuAgreementApi.replace("[param1]", grantNum);

    try {
      const api = rsuAgreementApi;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getRSUSalesHistory(grantNum, period, dateTo, dateFrom) {
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let rsuSalesHistUrl = apiUrl +  this.globals.getRSUSalesHistoryAPI;

    rsuSalesHistUrl = rsuSalesHistUrl.replace("[param1]", grantNum);
    rsuSalesHistUrl = rsuSalesHistUrl.replace("[param2]", period);
    rsuSalesHistUrl = rsuSalesHistUrl.replace("[param3]", dateTo);
    rsuSalesHistUrl = rsuSalesHistUrl.replace("[param4]", dateFrom);
    rsuSalesHistUrl = rsuSalesHistUrl.replace(regEx, "");

    try {
      const api = rsuSalesHistUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getSalesHistoryPeriod() {
    let apiUrl = this.globals.currentAPIUrl;
    let salesHistoryPeriodUrl = this.globals.staticContentAPI + this.globals.staticSalesHistoryPeriod;

    try {
      const api = apiUrl + salesHistoryPeriodUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  grantAccepted(userOptnum, adminOptnum, grantNum){
    let apiURL = this.globals.currentAPIUrl;
    let methodPath = this.globals.acceptGrantAcceptBtnRSUAPI;

    methodPath = methodPath.replace("[param1]", userOptnum);
    methodPath = methodPath.replace("[param2]", adminOptnum);
    methodPath = methodPath.replace("[param3]", grantNum);

    try {
      const apiLogin = apiURL + methodPath;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});
      let body = JSON.stringify({ userOptnum, adminOptnum, grantNum})

      return this.httpClient.put(apiLogin, body).pipe(retry(3));
    }catch(err) {
      return err;
    }
  }


  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  }

    getDataFromCodeDetail(grantgroupid): any{
    let apiUrl = this.globals.currentAPIUrl;
    let getValue = this.globals.getValuefromCodeDetail; 

    try {
      const api = apiUrl + getValue + grantgroupid;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getVestCard(grantNum): any{
    let apiURL = this.globals.currentAPIUrl;
    let methodPath = this.globals.getRSUVestAccordionPath; 

    methodPath = methodPath.replace("[param1]", grantNum);

    try { 
      const vestAPI = apiURL + methodPath;
      return this.httpClient.get(vestAPI).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err){
      return err;
    }
  }

  // getDataFromCodeDetail(grantid): any{
  //   let apiUrl = this.globals.currentAPIUrl;
  //   let getValue = this.globals.getValuefromCodeDetail; 

  //   try {
  //     const api = apiUrl + getValue + grantid;
  //     return this.httpClient.get(api).pipe(map(res => res), 
  //     catchError((res: Response) => this.onError(res)));
  //   } catch(err) {
  //     return err;
  //   }
  // }
}
