
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { VeiptransactionService } from './VeipTransactionService';
import { StaticContentService } from '../services/StaticContentService';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone'; 
import { EnrollStatus, AdminType, ActionTypes, PageSection, FileType, MyHoldingsPagesResources, tScript } from '../services/enumerations/enumerations';
import * as $ from 'jquery';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import {TooltipPosition} from '@angular/material/tooltip';
import { UserService } from '../services/UserService';
import { DomSanitizer } from '@angular/platform-browser';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

// Notif framework toast widget // 
declare function enrollVeipToastProd(): any;
declare function veipToastWithdrawProd(): any;
declare function enrollVeipToastStage(): any;
declare function veipToastWithdrawStage(): any;
// Notif framework toast widget // -- END

@Component({
  selector: 'app-enrollmentwithdraw-veip',
  templateUrl: './enrollmentwithdraw-veip.component.html',
  styleUrls: ['./enrollmentwithdraw-veip.component.css']
})
export class EnrollmentwithdrawVeipComponent implements OnInit {

  messagechecker: boolean =  false;
  withdrawnMessageCheckerInput: boolean = false;
  stepTwoTitle: any;

  temp: any = JSON.parse(sessionStorage.getItem("VEIP DETAILS KEY"));
  veiptransoutput: any = JSON.parse(sessionStorage.getItem("VEIP TRANS API OUTPUT"));
  veiptransAPI: any = this.veiptransoutput[0];
  //temp1: any = (sessionStorage.getItem("Veip Transaction Details"));
  checkCounter: number = 0;
  veipTransactionDetails: any = this.temp[0];
  // stepThreeTitle: any;
  // stepFourTitle: any;

  // veiptransaction: any = JSON.parse(sessionStorage.getItem("VEIP "))
  withdrawnStatements: any;
  loading: boolean = true;
  
  showEnrollAccordion: any = "collapse";
  expandEnrollAccordion: boolean = false;
  showWithdrawAccordion: any = "collapse"
  expandWithdrawAccordion: boolean = false;

  expandCommonQuestions: boolean = true;
  expandGuidebook: boolean = false;

  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  adminUser = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));
  veipOfferDetailsData: any;
  veipTransactionDetailsData: any;
  veipAllOffering: any;
  currentDate: any;
  cyNumber: any; //test

  isEnrollDisabled: any = true;
  isContribPercentageDisable: any = false;
  hasAdditional: any = false;
  hasAdditionalVeipPaperRequirement: any = false;
  hasVeipMatchingGrantEligible: any = false;
  hasPreviousContrib: any = false;
  offeringFlagChecker: any = false;

  veipEnrollPeriodStart: any;
  veipEnrollPeriodEnd: any;
  veipEnrollmentStartDt: any;
  veipEnrollmentEndDt: any;

  veipEnrollPeriodYear: any;

  setGrantRSU: boolean = false;
  setGrantOption: boolean = false;
  setTrendValue: boolean = false;

  veipContribPercentage: number;

  veipPreviousContributionPercentage: any = 0;

  enrollmentMessage: any;
  enrollmentMessageChecker: any = false;
  withdrawMessage: any;
  withdrawMessageVEIP: any;
  withdrawnMessageChecker: any = false;

  isOpenEnrollment: any = false;
  isNewParticipant:any = false;
  //displayEnrollNote: any = false;

  // enableControl: any; KMN, 4

  lastUpdatedByUser: any;
  userByOptNumData: any;

  previousContribDateText: any;
  currentContribDateText: any;

  insertTransDetail: any[] = [];
  sendEmailDetails: any[] = [];

  veipAllOfferingEnrolled: any;
  veipOfferingEnrolled:any;

  isWithdrawDisabled:any = true;
  veipDetails: any;
  displayWithdrawNote: boolean = false;
  displayErrorThirtyUp: boolean = false;
  displayErrorZero: boolean = false;
  displayErrorPeriod: boolean = false;
  noInputError: boolean = false;
  isTabChanged: boolean = false;

  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  bodyLearnMoreData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  commonQuestions: any;
  guideBooks: any;
  prospectUs: any;
  countryAndAward: any;
  toolsAndReports: any;
  transferCountryAccordion: string = "";
  stepTwoData: any;
  fileIcon: any;
  errorLocation: string = " + [Component: Veiptransact, Method: methodName]";
  errorEnrollAcc: boolean;
  errorWithdrawAcc: boolean;
  errorCommonQuestion: boolean;
  errorGuideBooks: boolean;
  errorProspectusDoc: boolean;
  errorCountryAward: boolean;
  errorToolsAndReports: boolean;
  commonQAccordionCount: number = 1; 
  veipEnrollNote: any;
  errorVeipEnrollNote: boolean = false;
  veipWithdrawNote: any;
  errorWithdrawNote: boolean = false;

  //Tooltip
  PreviousContributionPercent_mtt: string = tScript.esppveipenrollmentwithdraw_pcp;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];

  showEnrollInvalidMsg: boolean = false;
  showWithdrawInvalidMsg: boolean = false;
  invalidMsg: string = 'Invalid Transaction';

  veipOfferNumber = this.veipTransactionDetails.OfferNum;
  offerNumArr = [];
  UBSlink: any;
  MSlink: any;
  envSetting: any;

  constructor(private sanitizer:DomSanitizer, private router: Router, private globals: Globals, private veipService: VeiptransactionService, private staticContent: StaticContentService, private errorNotificationService: ErrorNotificationService, private userService: UserService) {

   }

  ngOnInit() {
    this.checkErrorMessage();    
    this.getCommonQuestionsData();
    this.getGuidebooksData();
    this.getProspectDocData();
    this.getCountryAndAwardData();
    this.getToolsAndReportsData();
    this.getOfferDetails(0);
    this.brokersSSO();
    this.getVeipEnrollNote();
    this.getVeipWithdrawNote();

  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
  if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
      let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

      if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }else{
      if(this.bodyData != null){
        if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
        }else{
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
        }
      }
    }
}

   /*****************************************************************************************
   * Method Name: navToPage                                                                 
   * Description: Redirect to a specified component.                            
   *                                                                                          
   * #    Date                 Modified by                      Description                             
   * 1    02/19/19             Joshua Paul J. Avecilla          Initial draft
   *****************************************************************************************/
  navToPage(strLoc) {
    try {
      this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

   /*****************************************************************************************
   * Method Name: getOfferDetails                                                                 
   * Description: Pulls date for the offering details using OptNum of the user.                           
   *                                                                                          
   * #    Date                 Modified by                      Description                             
   * 1    02/19/19             Joshua Paul J. Avecilla          Initial draft
   * 2    02/26/19             Joshua Paul J. Avecilla          Added conditions
   * 3    03/05/19             Allan Christopher M. Salac       Remove conditions for period start and end
   * 4    03/13/19             Joshua Paul J. Avecilla          Set veipContribPercentage value
   * 5    03/27/19             Dalemark P. Suquib               Remove isEnrollDisabled under open enrollment condition
   * 6    11/28/19             Katrina Narag (KMN)              Removed next offering withdraw period for current offering page
   *****************************************************************************************/
  async getOfferDetails(action){
    try {
      this.errorEnrollAcc = false;
      this.errorWithdrawAcc = false;

      this.veipService.getVEIPOfferingDetails(this.veipTransactionDetails.OptNum).pipe(map(res => res)).subscribe(data => {
        try{
          this.errorEnrollAcc = false;
          this.errorWithdrawAcc = false;
          
          
          this.veipAllOffering = data[0];
          for(let i: number = 0; i < this.veipAllOffering.length; i++){
            this.veipAllOffering[i].periodChecked = false;
            this.veipAllOffering[i].disabledWithdrawCB = true;
            if(this.veipAllOffering[i].CyNum == this.veipTransactionDetails.CyNum){
              this.veipOfferDetailsData = this.veipAllOffering[i];
              this.veipAllOffering[i].CheckboxId = this.veipOfferDetailsData.PeriodStartDt + "-" + this.veipOfferDetailsData.PeriodEndDt; 
            }
          }
          if(this.veiptransAPI.length > 1){ //2 VEIP cards present in homepage
            let x = Math.max(this.veiptransAPI[0].CyNum, this.veiptransAPI[1].CyNum); // determine next offering cynum

            if(x != this.veipTransactionDetails.CyNum){
                this.veipAllOffering = this.veipAllOffering.filter(item => item.CyNum == this.veipTransactionDetails.CyNum);
            }else{
              //selected offernum is next offering
            }
          }
          this.getTransactionDetails();
  
          this.veipEnrollPeriodStart = moment(this.veipOfferDetailsData.PeriodStartDt).format("YYYY-MM-DD");
          this.veipEnrollPeriodEnd = moment(this.veipOfferDetailsData.PeriodEndDt).format("YYYY-MM-DD");
          this.veipEnrollmentStartDt = moment(this.veipOfferDetailsData.EnrollmentStartDt).format("YYYY-MM-DD");
          this.veipEnrollmentEndDt = moment(this.veipOfferDetailsData.EnrollmentEndDt).format("YYYY-MM-DD");
          this.veipContribPercentage = this.veipOfferDetailsData.ContributionPercent;
  
          if (this.veipTransactionDetails.OfferingFlag == 0) { // 0 - Open enrollment
              this.isOpenEnrollment = true;
              this.offeringFlagChecker = true;
              this.showEnrollAccordion = "collapse show";
              this.expandEnrollAccordion = true;
              this.isContribPercentageDisable = false;
              // this.isEnrollDisabled = false;
          }else { // 1 - Closed enrollment
            this.offeringFlagChecker = false;
            this.showWithdrawAccordion = "collapse show";
            this.expandWithdrawAccordion = true;
            this.isContribPercentageDisable = true;
            this.isEnrollDisabled = true;
            this.isOpenEnrollment = false;
            this.isContribPercentageDisable = true;
            this.isEnrollDisabled = true;
          }
  
          if(this.veipOfferDetailsData.EnrollStatus == EnrollStatus.OnHold || this.veipOfferDetailsData.EnrollStatus == EnrollStatus.Withdraw){
            this.offeringFlagChecker = false;
            this.showWithdrawAccordion = "collapse show";
            this.expandWithdrawAccordion = true;
          }
  
          this.isNewParticipant = this.isNewParticipantChecker();    
          if(this.isNewParticipant == false){
            this.veipPreviousContributionPercentage = this.veipOfferDetailsData.PrevContrib;
          }
          else{
            this.veipPreviousContributionPercentage = this.veipOfferDetailsData.PrevContrib;
          }
  
          this.veipEnrollPeriodYear= moment(this.veipEnrollPeriodStart).format("YYYY");
          this.cyNumber = this.veipOfferDetailsData.CyNum;
  
          this.transactionDetailsChecker();
  
        } catch(error) {
          this.errorEnrollAcc = true;
          this.errorWithdrawAcc = true;
        let errorLocation = this.errorLocation.replace("methodName", "getOfferDetails");
        error.message = error.message + errorLocation;
        throw error;
        }
      }, error => {
        this.errorEnrollAcc = true;
        this.errorWithdrawAcc = true;
        let errorLocation = this.errorLocation.replace("methodName", "getOfferDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorEnrollAcc = true;
      this.errorWithdrawAcc = true;
      let errorLocation = this.errorLocation.replace("methodName", "getOfferDetails");
      error.message += errorLocation;
      throw error;
    }
  }

   /*****************************************************************************************
   * Method Name: transactionDetailsChecker                                                                 
   * Description: Set the initial state of the accordion                          
   *                                                                                          
   * #    Date                 Modified by                      Description                             
   * 1    02/27/19             Joshua Paul J. Avecilla          Initial draft
   *****************************************************************************************/
  transactionDetailsChecker(){
    try {
      if(this.offeringFlagChecker == true){
        this.showEnrollAccordion = "collapse show";
        this.expandEnrollAccordion = true;
      }else{
        this.showWithdrawAccordion = "collapse show";
        this.expandWithdrawAccordion = true;
      }
      this.getWithdrawAccordionDetails();
      this.getEnrollAccordionDetails();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

 /*********************************************************************************************************************************************************************************
   * Method Name: getEnrollAccordionDetails                                                                 
   * Description: Get conditions for the Enroll Accordion                           
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    02/26/19             Joshua Paul J. Avecilla(JPA)          Initial draft
   * 2    04/22/19             JPA                                   Edited first two if else statements for checking "Complete & Fax" and calling myResources API for user story: 
   *                                                                 [FE] As a user, I want to see step 2 Complete & Fax in VEIP transaction page.
   * 3    04/24/19             JPA                                   Added call to method: inContentIcon
   * 4    08/27/19             JPA                                   Removed parameter in calling method getUserByOptNumDetail
   * 5````12/27/19             Jikho                                 Added methods that calls for enrollment/withdrawal message validations
   ********************************************************************************************************************************************************************************/
  async getEnrollAccordionDetails(){
    try {
      this.errorEnrollAcc = false;
      if(this.user.VeipMatchingGrantEligibleInd == 'Y'){
        this.hasVeipMatchingGrantEligible = true;
      }
      else{
        this.hasVeipMatchingGrantEligible = false;
      }
  
      if(this.user.AdditionalVeipPaperRequirementInd == 'Y'){
        this.hasAdditionalVeipPaperRequirement = true;
  
        /* myResources call for step 2 Complete & Fax content. */
        this.bodyData[5] = PageSection.stepTwoVEIP + this.user.CountryName;
        this.bodyData[6] = 0;
        this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
          try {
            let tempContainer = JSON.parse(data.body);
            tempContainer = tempContainer.data;
            this.stepTwoData = tempContainer;

            if(this.stepTwoData != undefined){
              this.stepTwoData.forEach(element => {
                if (element.File_Type == 'pdf'){
                  element.fileIcon = FileType.pdf;
                } else if (element.File_Type == 'docx'){
                  element.fileIcon = FileType.doc;
                } else if (element.File_Type == 'doc'){
                  element.fileIcon = FileType.doc;
                } else if (element.File_Type == 'xls'){
                  element.fileIcon = FileType.xls;
                } else if (element.File_Type == 'xlsx'){
                  element.fileIcon = FileType.xls;
                } else if (element.File_Type == 'pptx'){
                  element.fileIcon = FileType.ppt;
                } else if (element.File_Type == 'ppt'){
                  element.fileIcon = FileType.ppt;
                } else if (element.File_Type == 'zip'){
                  element.fileIcon = FileType.zip;
                } else{
                  // For future use
                }
              });
            }
    
            /* myResources call for transferring to another country accordion content. */
            this.bodyData[5] = PageSection.transferringToNewCountryVEIP;
            this.bodyData[6] = 1;
    
            this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
              try {
                let tempAccordionContainer = JSON.parse(data.body);

                if(tempAccordionContainer.data) { // length > {}
                  tempAccordionContainer = tempAccordionContainer.data;
    
                  if(tempAccordionContainer.length > 0){
                    this.transferCountryAccordion = tempAccordionContainer[0].Body_Desc;
                  }
                }
              

                setTimeout(()=>{ this.staticContent.inContentIconAppend() }, 500);
              } catch(error) {
                this.errorEnrollAcc = true;
                let errorLocation = this.errorLocation.replace("methodName", "getEnrollAccordionDetails");
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              this.errorEnrollAcc = true;
              let errorLocation = this.errorLocation.replace("methodName", "getEnrollAccordionDetails");
              error.error.message += errorLocation;
              throw error.error;
            });
          } catch(error) {
            this.errorEnrollAcc = true;
            let errorLocation = this.errorLocation.replace("methodName", "getEnrollAccordionDetails");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          this.errorEnrollAcc = true;
          let errorLocation = this.errorLocation.replace("methodName", "getEnrollAccordionDetails");
          error.error.message += errorLocation;
          throw error.error;
        });
      }
      else{
        this.hasAdditionalVeipPaperRequirement = false;
        /* myResources call for transferring to another country accordion content. */
        this.bodyData[5] = PageSection.transferringToNewCountryVEIP;
        this.bodyData[6] = 1;
  
        this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
          try {
            let tempAccordionContainer = JSON.parse(data.body);

            if(tempAccordionContainer.data) { // length > {}
              tempAccordionContainer = tempAccordionContainer.data;

              if(tempAccordionContainer.length > 0){
                this.transferCountryAccordion = tempAccordionContainer[0].Body_Desc;
              }
            }

            setTimeout(()=>{ this.staticContent.inContentIconAppend() }, 500);
          } catch(error) {
            this.errorEnrollAcc = true;
            let errorLocation = this.errorLocation.replace("methodName", "getEnrollAccordionDetails");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          this.errorEnrollAcc = true;
          let errorLocation = this.errorLocation.replace("methodName", "getEnrollAccordionDetails");
          error.error.message += errorLocation;
          throw error.error;
        });
      }

      if(this.hasAdditionalVeipPaperRequirement == true ){ //&& this.hasVeipMatchingGrantEligible == true
        this.stepTwoTitle = "Complete and Fax";
      }
      else if(this.hasAdditionalVeipPaperRequirement == false && this.hasVeipMatchingGrantEligible == false){
        this.stepTwoTitle = "Confirm";
      }
      if(this.veipOfferDetailsData.Updated_by == "" || this.veipOfferDetailsData.Updated_by == null || this.veipOfferDetailsData.Updated_by == 0){     
        this.lastUpdatedByUser = "dbo";
        this.getUserByOptNumDetails();
        this.checkMessage();
      }
      else if(this.veipOfferDetailsData.Updated_by != this.user.OptNum ){  
        this.lastUpdatedByUser = "admin";
        // this.getUserByOptNumDetails(this.veipOfferDetailsData.Updated_by);
        this.getUserByOptNumDetails();
        this.checkMessage();
      }
      else{               
        this.lastUpdatedByUser=this.user.EnterpriseId;
        this.getUserByOptNumDetails(); //this.veipOfferDetailsData.Updated_by
        this.checkMessage();
      }
  
      this.loading = false;
      setTimeout(()=>{ this.linkColorChange() }, 500);
    } catch(error) {
      this.errorEnrollAcc = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getEnrollAccordionDetails");
      error.message += errorLocation;
      throw error;
    }
  }

   /*****************************************************************************************
   * Method Name: getWithdrawAccordionDetails                                                                 
   * Description: Get Withdraw Accordion details and conditions                          
   *                                                                                          
   * #    Date                 Modified by                      Description                             
   * 1    02/26/19             Joshua Paul J. Avecilla          Initial draft
   * 2    02/27/19             Joshua Paul J. Avecilla          Added loop and conditions
   *****************************************************************************************/
  async getWithdrawAccordionDetails(){ 
    try {
     
          for(let i = 0; i<this.veipAllOffering.length;i++){
            if(this.veipAllOffering[i].EnrollStatus == EnrollStatus.Withdraw){
              this.veipAllOffering[i].periodChecked = true;
              this.veipAllOffering[i].disabledWithdrawCB = true;
            }
            else if(this.veipAllOffering[i].EnrollStatus == EnrollStatus.EnrollVEIPOptions || this.veipAllOffering[i].EnrollStatus == EnrollStatus.EnrollVEIPRsu || this.veipAllOffering[i].EnrollStatus == EnrollStatus.OnHold){
              this.veipAllOffering[i].disabledWithdrawCB = false;
              this.isWithdrawDisabled = true;
            }
      
            if(this.canTransactMethod() == false){
              this.veipAllOffering[i].disabledWithdrawCB = true;
            }
      
            if(this.veipAllOffering[i].disabledWithdrawCB == false){
              this.isWithdrawDisabled = true;
            }
          }
        
        
      
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getWithdrawAccordionDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  
   /*****************************************************************************************
   * Method Name: getUserByOptNumDetails                                                                 
   * Description: Pull user details using OptNum details                            
   *                                                                                          
   * #    Date                 Modified by                      Description                             
   * 1    02/26/19             Joshua Paul J. Avecilla          Initial draft
   * 2    08/12/19             JPA                              Changed api method to userService.getUserInfo because previous api is deprecated
   * 3    08/27/19             JPA                              Removed api call because of api depricated.
   * 4    11/18/19             Katrina Narag                    Removed initialization of enableControl variable
   * 5    12/6/19              Jikho Enrique                    Reworked the withdrawn message condition to dynamic.
   * 6    06/28/2021           Julienne Marey Celi              Modified based on User Story 332873 - CRIL00051021173444 - Missing Enrollment Email Notifications
   *****************************************************************************************/
  async getUserByOptNumDetails(){
    try {
      // this.userService.getUserInfo(OptNum).subscribe(data => {
        // try{
        //   if(data != null || data != undefined){
        //     this.userByOptNumData = data;
        //     // this.lastUpdatedByUser = this.userByOptNumData.EnterpriseId;
        //   }  
        if(this.veipOfferDetailsData.EnrollStatus == EnrollStatus.EnrollVEIPRsu || this.veipOfferDetailsData.EnrollStatus == EnrollStatus.EnrollVEIPOptions || this.veipOfferDetailsData.EnrollStatus == EnrollStatus.OnHold || this.veipOfferDetailsData.EnrollStatus == EnrollStatus.Withdraw){
          if(this.veipOfferDetailsData.EnrollStatus == EnrollStatus.EnrollVEIPRsu || this.veipOfferDetailsData.EnrollStatus == EnrollStatus.EnrollVEIPOptions){
            this.setGrantRSU = true;
            if(this.lastUpdatedByUser == "dbo"){
              this.enrollmentMessage = "Enrolled on "+ this.veipOfferDetailsData.TransactionDt.substring(0, this.veipOfferDetailsData.TransactionDt.lastIndexOf(" ")) +" by auto re-enroll.";  //JMC, 6
            }
            else if(this.lastUpdatedByUser == "admin"){
              this.enrollmentMessage = "Confirmed enrollment on "+ this.veipOfferDetailsData.TransactionDt.substring(0, this.veipOfferDetailsData.TransactionDt.lastIndexOf(" "));  //JMC, 6
            }
            else{
              this.enrollmentMessage = "Confirmed enrollment on " + this.veipOfferDetailsData.TransactionDt.substring(0, this.veipOfferDetailsData.TransactionDt.lastIndexOf(" ")) + " by " + this.lastUpdatedByUser;  //JMC, 6
            }
            this.enrollmentMessageChecker = true;
            this.withdrawnMessageChecker = false;
            this.withdrawnMessageCheckerInput = false;
            this.messagechecker = true;
          }

          if(this.veipOfferDetailsData.EnrollStatus == EnrollStatus.EnrollVEIPRsu){
            this.setGrantRSU = true;
          }else if(this.veipOfferDetailsData.EnrollStatus == EnrollStatus.EnrollVEIPOptions){
            this.setGrantOption = true;
          }
        }

        this.withdrawnStatements = [];
        for(let x = 0; x < this.veipAllOffering.length; x++){
          if(this.veipAllOffering[x].EnrollStatus == EnrollStatus.Withdraw){
          // this.veipContribPercentage = this.veipOfferDetailsData.ContributionPercent;
            if(this.veipAllOffering[x].Updated_by == "" || this.veipAllOffering[x].Updated_by == null || this.veipAllOffering[x].Updated_by == 0){
              this.withdrawMessage = "Auto-withdrawn on " + this.veipAllOffering[x].TransactionDt.substring(0, this.veipAllOffering[x].TransactionDt.lastIndexOf(" ")) + " for period " + this.veipAllOffering[x].PeriodStartDt +  " - " + this.veipAllOffering[x].PeriodEndDt;  //JMC, 6
            }else if(this.veipAllOffering[x].Updated_by != this.user.OptNum){ 
              this.withdrawMessage = "Withdrawn on " + this.veipAllOffering[x].TransactionDt.substring(0, this.veipAllOffering[x].TransactionDt.lastIndexOf(" ")) + " for period " + this.veipAllOffering[x].PeriodStartDt +  " - " + this.veipAllOffering[x].PeriodEndDt;  //JMC, 6
            }else{
            this.lastUpdatedByUser=this.user.EnterpriseId;
            this.withdrawMessage = "Withdrawn on " + this.veipAllOffering[x].TransactionDt.substring(0, this.veipAllOffering[x].TransactionDt.lastIndexOf(" ")) + " by "+ this.lastUpdatedByUser + " for period " + this.veipAllOffering[x].PeriodStartDt + " - " + this.veipAllOffering[x].PeriodEndDt;  //JMC, 6
            }

            this.withdrawnStatements.push(this.withdrawMessage);
            
            this.withdrawnMessageChecker = true;
            this.withdrawnMessageCheckerInput = true;
            this.enrollmentMessageChecker = false;

            if(this.veipAllOffering[x].CyNum == this.veipTransactionDetails.CyNum){
              this.withdrawMessageVEIP = this.withdrawMessage; 
              //this.enrollmentMessageChecker = true;

              //this.enrollmentMessage != null ? this.enrollmentMessageChecker = true : this.enrollmentMessageChecker = false;
            }
          }

          if(this.veipAllOffering[x].CyNum == this.veipTransactionDetails.CyNum){
            this.enrollmentMessage != null ? this.enrollmentMessageChecker = true : this.enrollmentMessageChecker = false;
          }
        }

        this.loading = false;
          setTimeout(()=>{ this.linkColorChange() }, 500);
  
        // }catch(error) {
        // let errorLocation = this.errorLocation.replace("methodName", "getUserByOptNumDetails");
        // error.message = error.message + errorLocation;
        // throw error;
        // }
      // }, error => {
      //   let errorLocation = this.errorLocation.replace("methodName", "getUserByOptNumDetails");
      //   error.error.message += errorLocation;
      //   throw error.error;
      // });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getUserByOptNumDetails");
      error.message += errorLocation;
      throw error;
    }
  }

   /*****************************************************************************************
   * Method Name: enrollVEIP                                                                 
   * Description: Enroll for VEIP                          
   *                                                                                          
   * #    Date                 Modified by                      Description                             
   * 1    02/26/19             Joshua Paul J. Avecilla(JPA)     Initial draft
   * 2    03/13/19             Iris Jane R. Manloloyo           Modified for VEIPTransactionData
   * 3    03/15/19             Joshua Paul J. Avecilla          Modified to include hours, minutes, and seconds.
   * 4    03/19/19             Katrina Narag(KMN)               Removed withdraw method
   * 5    03/26/19             JPA                              Addedis loading and disabled withdraw boolean checker.
   * 6    03/27/19             Dalemark P. Suquib               Added isEnrollDisabled to disable the enroll button when executed
   * 7    11/18/19             KMN                              Removed currentDate as paramater
   * 8    11/19/19             Dalemark P. Suquib               Added checking for admin type
   * 8    12/27/19             Jikho                            Added message validations
   *****************************************************************************************/
  transactionVEIP(action){ //0 - enroll, 3 withdraw
    if((this.isEnrollDisabled == false  && action == 0) || (this.isWithdrawDisabled == false && action == 3)){
    try {
      if(this.canTransactMethod()) {
        let offNumber: any;
        this.showEnrollInvalidMsg = false;
        this.user.VeipMatchingGrantEligibleInd == 'Y'
        let manualElection = 2;
        let transNum = 0;
        let transOptNum = this.user.OptNum;
        let currentContrib: number;
        let transactType;
        let currentDate = momenttz().tz("America/Chicago").format('YYYY-MM-DD hh:mm:ss');
        this.isWithdrawDisabled = true;
        this.isEnrollDisabled = true;
        this.loading = true;
        this.displayErrorPeriod = false;
        action == 3 ? currentContrib = 0 : currentContrib = this.veipContribPercentage;
        if (action != 3) {
          if(Number(currentContrib) % 1 == 0 && Number(currentContrib) <= 30 && Number(currentContrib) > 0 && action == 0 && Number(currentContrib) != null){
            this.displayErrorThirtyUp = false;
          }else{
            this.displayErrorThirtyUp = true;
          }          
        }
        if(!this.displayErrorThirtyUp || action == 3){
          this.veipService.getVEIPOfferingDetails(this.veipTransactionDetails.OptNum).pipe(map(res => res)).subscribe(data => {
            try{
              this.errorWithdrawAcc = false;
              
      
              
              
              
              if((currentContrib <= 30 && this.isOpenEnrollment == true && action == 0)){
                this.veipAllOffering = data[0];
                let b = this.veipAllOffering;
                this.veipDetails = b.find(x => x.CyNum == this.veipOfferDetailsData.CyNum);
                this.adminUser != null ? transOptNum = this.adminUser.OptNum : transOptNum =  this.user.OptNum;
                transactType = EnrollStatus.EnrollVEIPRsu;
                offNumber = this.veipTransactionDetails.OfferNum;
      
                this.insertTransDetail = [];
                this.insertTransDetail.push(this.user.OptNum);
                // this.insertTransDetail.push(currentDate); //KMN, 7
                this.insertTransDetail.push(currentContrib);
                this.insertTransDetail.push(manualElection);
                this.insertTransDetail.push(transOptNum);
                this.insertTransDetail.push(offNumber);
                this.insertTransDetail.push(transNum);
                // this.insertTransDetail.push(currentDate); //KMN, 7
                this.insertTransDetail.push("Y");  //KMN, Default elect always set to Y as per legacy
                this.insertTransDetail.push(transactType); 
                this.veipService.insertTransactionDetails(this.insertTransDetail).subscribe(data => {
                  if(action == 0){
                    this.veipDetails.EnrollStatus == null ? action = 0 : action = 1; //0 - enroll, 1 - change contrib
                  }else{
                    action = 3;
                  }
                  this.getEnrollmentDetails(action, offNumber, currentDate);
                  });
                this.offerNumArr = [];
                this.messagechecker = true;
                this.checkMessage();
              }else if(action == 3){
                this.adminUser != null ? transOptNum = this.adminUser.OptNum : transOptNum =  this.user.OptNum;
                // offNumber = this.veipOfferNumber : offNumber = this.veipTransactionDetails.OfferNum; //might not be needed
                transactType = EnrollStatus.Withdraw;
                for(let i = 0; i < this.veipAllOffering.length; i++) {
                  if(this.veipAllOffering[i].Checked == true && this.veipAllOffering[i].disabledWithdrawCB == false) {
                    this.insertTransDetail = [];
                    this.insertTransDetail.push(this.user.OptNum);
                    // this.insertTransDetail.push(currentDate); //KMN, 7
                    this.insertTransDetail.push(currentContrib);
                    this.insertTransDetail.push(manualElection);
                    this.insertTransDetail.push(transOptNum);
                    this.insertTransDetail.push(this.veipAllOffering[i].OfferNum);
                    this.insertTransDetail.push(transNum);
                    // this.insertTransDetail.push(currentDate); //KMN, 7
                    this.insertTransDetail.push("Y");  //KMN, Default elect always set to Y as per legacy
                    this.insertTransDetail.push(transactType); 
                    this.veipService.insertTransactionDetails(this.insertTransDetail).subscribe(data => {
                         //action = 3;
                         this.getEnrollmentDetails(action, this.veipAllOffering[i].OfferNum, currentDate);
                    });
                  }
                }
                this.messagechecker = false;
                this.checkMessage()
              }
              // }else{
              //   this.displayErrorThirtyUp = true;
              // }
            }catch(error) {
              this.errorEnrollAcc = true;
              let errorLocation = this.errorLocation.replace("methodName", "transactionVEIP");
              error.message = error.message + errorLocation;
              throw error;
            }
          }, error => {
            let errorLocation = this.errorLocation.replace("methodName", "transactionVEIP");
            error.error.message += errorLocation;
            throw error.error;
          });
        }
      } else {
        if(action == 0) { // for enrollment 
          this.showEnrollInvalidMsg = true;
        } else if(action == 3) { // for withdraw 
          this.showWithdrawInvalidMsg = true;
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "transactionVEIP");
      error.message += errorLocation;
      throw error;
    }
  }
  }

  /*****************************************************************************************
   * Method Name: getEnrollmentDetails                                                                 
   * Description: Format message after clicking enroll button                     
   *                                                                                          
   * #    Date                 Modified by                       Description                             
   * 1    02/28/19             Joshua Paul Avecilla(JPA)         Initial draft
   * 2    03/15/19             JPA                               Modified to include hours, minutes, and seconds.
   * 3    08/22/19             JPA                               Moved the call to the method getOfferDetails so that it won't be dependant on the send email api.
   * 4    06/28/2021           Julienne Marey Celi               Modified based on User Story 332873 - CRIL00051021173444 - Missing Enrollment Email Notifications
   *****************************************************************************************/
  getEnrollmentDetails(action, offNum, currentDate){
    try {    


      let emailAction;
      currentDate = moment(currentDate).format("YYYY");
  
      this.veipService.getVEIPOfferingDetails(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorWithdrawAcc = false;
  
          if(data != undefined){
            this.veipAllOfferingEnrolled = data[0];
            if(action == 0 || action == 1 || action == 3){ //For enroll/change contrib
              this.veipOfferingEnrolled = this.veipAllOfferingEnrolled.find(x => x.OfferNum == offNum);
            }
            let year = moment(this.veipOfferingEnrolled.PeriodStartDt).format("YYYY");
            if(action == 0){              
              this.expandWithdrawAccordion = false;
              emailAction = ActionTypes.Enroll;
              this.showWithdrawAccordion = 'collapse';              
            } 
            if(action == 1){
              this.expandWithdrawAccordion = false;
              emailAction = ActionTypes.ChangeContrib;
              this.showWithdrawAccordion = 'collapse';              
            }  
            if(action == 3){
              this.expandEnrollAccordion = false;
              emailAction = ActionTypes.Withdraw;
              this.showEnrollAccordion = 'collapse';              
            }
            
            this.sendEmailDetails = [];
            this.sendEmailDetails.push(emailAction);        
            this.sendEmailDetails.push("VEIP");
            this.sendEmailDetails.push(year); //year param for VEIP
            this.sendEmailDetails.push(this.veipOfferingEnrolled.PeriodStartDt);
            this.sendEmailDetails.push(this.veipOfferingEnrolled.PeriodEndDt);
            this.sendEmailDetails.push(this.veipOfferingEnrolled.ContributionPercent);
            this.sendEmailDetails.push(this.veipOfferDetailsData.ContributionPercent);
            this.sendEmailDetails.push(this.user.OptNum);  //this.user.EnterpriseId
            this.sendEmailDetails.push(this.veipOfferingEnrolled.TransactionDt.replace(":", "colon")); // JMC, 4
            this.sendEmailDetails.push(this.veipOfferingEnrolled.TransNum);  //JMC, 4
            
            sessionStorage.removeItem("VEIPActionItemCardCacheData");

           // action == 3 ? this.displayWithdrawNote = true : this.displayEnrollNote = true;

             if(action == 3){
              this.displayWithdrawNote =  true;
              veipToastWithdrawProd(); // Notif framework toast widget //
              veipToastWithdrawStage();
              
            }else {
              //this.displayEnrollNote = true;
              enrollVeipToastProd(); // Notif framework toast widget //
              enrollVeipToastStage();
              
            } 
            this.getOfferDetails(action);  
            this.veipService.sendMail(this.sendEmailDetails).subscribe(data => {
              // action == 3 ? this.displayWithdrawNote = true : this.displayEnrollNote = true;
              // this.getOfferDetails(action);       
            });
          }
  
         }catch(error) {
        this.errorWithdrawAcc = true;
        let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentDetails");
        error.message = error.message + errorLocation;
        throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentDetails");
      error.message += errorLocation;
      throw error;
    }
  }
  
  /*****************************************************************************************
   * Method Name: canTransact                                                                 
   * Description: Method returning boolean for enabling button radio buttons                         
   *                                                                                          
   * #    Date                 Modified by                      Description                             
   * 1    02/26/19             Joshua Paul J. Avecilla          Initial draft
   *****************************************************************************************/
  canTransact(): boolean {
    try {
      if(this.adminUser != null || this.adminUser != undefined || this.adminUser != "" ){
        if(this.adminUser.OptNum == this.user.OptNum) {
          return true;
        } else if(this.adminUser.OptNum != this.user.OptNum && this.adminUser.IsAdminCanTransactInd == AdminType.ReadWrite) {
          return true;
        } else if(this.adminUser.IsAdmin == "Y" && this.adminUser.IsAdminCanTransactInd == AdminType.ReadOnly) {
          return false;
        } else if(this.adminUser.OptNum != this.user.OptNum) {
          return false;
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "canTransact");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  //MIGHT BE REMOVED
  canTransactMethod(): boolean{
    try {
      if(this.adminUser != null && this.adminUser != undefined && this.adminUser != "" ){
        if(this.user.OptNum == this.adminUser.OptNum){
          return true;
        }
        else if(this.user.OptNum != this.adminUser.OptNum && this.adminUser.IsAdminCanTransactInd == 2){ // admin type 2, read write
          return true;
        }
        else if(this.adminUser.IsAdmin != null && this.adminUser.IsAdminCanTransactInd == 1){ // admin type 1, read only
          this.isContribPercentageDisable = true; // disable input field
          return false;
        }
        else if(this.adminUser.OptNum != this.user.OptNum){
          return false;
        }
      } else {
        return true;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "canTransactMethod");
      error.message += errorLocation;
      throw error;
    }
  }

  disableContrib(){
    try {
      if(this.isContribPercentageDisable == true){
        return '#f6f6f6';
      }
      else{
        return '#ffffff';
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "disableContrib");
      error.message += errorLocation;
      throw error;
    }
  }

  isNewParticipantChecker(): boolean{
    try {
      if(this.veipOfferDetailsData.EnrollStatus == null){
        return true
      }
      else{
        return false
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "isNewParticipantChecker");
      error.message += errorLocation;
      throw error;
    }
  }

  changeWithdrawDisable(check){
    if(check != true){
    try {
      if(this.canTransactMethod()) {
        this.showWithdrawInvalidMsg = false;
        if(this.isWithdrawDisabled == true) {
          this.isWithdrawDisabled = false;
        } else {
          this.isWithdrawDisabled = true;
        }
      } else {
        this.showWithdrawInvalidMsg = true;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "changeWithdrawDisable");
      error.message += errorLocation;
      throw error;
    }
  }
  }

  changeOfferNum(check,OfferNum){
    if (check == true) {
      this.offerNumArr.push(OfferNum)
    }else{
      var index = this.offerNumArr.indexOf(OfferNum);
      if (index !== -1) this.offerNumArr.splice(index, 1);
    }
    this.veipOfferNumber = this.offerNumArr[0];
  }

  multipleWithdraw(period){
    for(let i = 0; i < this.veipAllOffering.length; i++) {
      if(period.OfferNum == this.veipAllOffering[i].OfferNum) {
        this.veipAllOffering[i].Checked = !(period.Checked);
        this.veipAllOffering[i].Checked == true ? this.checkCounter += 1: this.checkCounter -= 1;
      }
    }
    this.checkCounter > 0 ? this.isWithdrawDisabled = false: this.isWithdrawDisabled = true;

  }

 /*****************************************************************************************
   * Method Name: checkVeipInput                                                                 
   * Description: Checks the value of textbox with a minimum of 1 and a maximum of 30.                
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    03/25/19             Dalemark P. Suquib          Initial draft
   * 2    03/27/19             Dalemark P. Suquib          Added isEnrollDisabled set to false
   * 3    11/4/2019            Jikho S. Enrique            Removed condition for Period
   * 4    11/19/19             Dalemark P. Suquib          Added checking for admin type
   *****************************************************************************************/
  checkVeipInput(value, event){
    try {
      if(this.canTransactMethod()) {
        this.showEnrollInvalidMsg = false;
        this.veipContribPercentage = value;
        this.isEnrollDisabled = false;
        if(value == 0 && value != "" && event != "Backspace") {
          this.displayErrorZero = true;
          this.displayErrorThirtyUp = false;
          this.noInputError = false;
        }
        else if(value == 0 && value !="" && event == "Backspace"){
          this.displayErrorZero = true;
          this.displayErrorThirtyUp = false;
          this.noInputError = false;
        } else if(value > 30 && event != "Backspace") {
          
          this.displayErrorZero = false;
          this.displayErrorThirtyUp = true;
          this.noInputError = false;
        }
        else if(value > 30 && event == "Backspace"){
          
          this.displayErrorZero = false;
          this.displayErrorThirtyUp = true;
          this.noInputError = false;
        } 
        else if(value == "" || value == null){
          this.noInputError = true;
          this.displayErrorZero = false;
          this.displayErrorThirtyUp = false;
        }else {
          this.displayErrorZero = false;
          this.displayErrorThirtyUp = false;
          this.noInputError = false;
        }
      } else {
        this.showEnrollInvalidMsg = true;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkVeipInput");
      error.message = error.message + errorLocation;
      throw error;
    }
  }
  
  // getDefaultEligibility(){
  //   this.veipService.getVEIPDefaultEligibility(this.veipTransactionDetails.OptNum).map(res => res).subscribe(data => {
  //     this.veipDefaultEligibilityData = data;
  //   });
  // }

 getTransactionDetails(){
  try {
    this.veipService.getVEIPTransactionDetails(this.veipTransactionDetails.OptNum, this.veipOfferDetailsData.CyNum).pipe(map(res => res)).subscribe(data => {
      try{
        let body = data;
        this.veipTransactionDetailsData = JSON.stringify(body[0]);
        sessionStorage.setItem("Veip Transaction Details", JSON.stringify(body[0]));
      }
      catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getTransactionDetails");
        error.message = error.message + errorLocation;
        throw error;
      }
   }, error => {
    let errorLocation = this.errorLocation.replace("methodName", "getTransactionDetails");
    error.error.message += errorLocation;
    throw error.error;
   });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getTransactionDetails");
    error.message += errorLocation;
    throw error;
  }
  }

   /*****************************************************************************************
   * Method Name: getCommonQuestionsData                                                                 
   * Description: Call myresources api and get the data for the learn more tab               
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/22/19             Joshua Paul J. Avecilla(JPA)          Initial draft
   * 2    08/082019            JPA                                   removed call to make api call not dependent

   *****************************************************************************************/
  async getCommonQuestionsData(){
    try {
      this.errorCommonQuestion = false;
      /********** COMMON QUESTIONS **********/
      this.bodyLearnMoreData[5] = PageSection.learnMoreCommonQuestionsVEIP;
      this.bodyLearnMoreData[6] = 1;

      this.staticContent.getResourcesDataFromMyR(this.bodyLearnMoreData).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorCommonQuestion = false;
          let tempContainer = JSON.parse(data.body);

          tempContainer = tempContainer.data;
          this.commonQuestions = tempContainer;
          if(this.commonQuestions == null || this.commonQuestions == undefined || this.commonQuestions == "" || this.commonQuestions == "{}"){
            
            //No Data to be displayed
            this.commonQuestions = null;

          }
          else{
            for(let i = 0; i<this.commonQuestions.length;i++){
              this.commonQuestions[i].Id = i;
            }
          }
  
          // this.getGuidebooksData();
        } catch(error) {
          this.errorCommonQuestion = true;
          let errorLocation = this.errorLocation.replace("methodName", "getCommonQuestionsData");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCommonQuestion = true;
        let errorLocation = this.errorLocation.replace("methodName", "getCommonQuestionsData");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCommonQuestion = true;
      let errorLocation = this.errorLocation.replace("methodName", "getCommonQuestionsData");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getGuidebooksData                                                                 
   * Description: Call myresources api and get the data for the learn more tab               
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/25/19             Joshua Paul J. Avecilla(JPA)          Initial draft
   * 2    05/03/19             JPA                                   Only show the first index in the array of object.
   * 3    08/082019            JPA                                   removed call to make api call not dependent
  *****************************************************************************************/
 async getGuidebooksData(){
  try {
    this.errorGuideBooks = false;
    this.bodyLearnMoreData[5] = PageSection.learnMoreGuidebooksVEIP;
    this.bodyLearnMoreData[6] = 1;

    this.staticContent.getResourcesDataFromMyR(this.bodyLearnMoreData).pipe(map(res => res)).subscribe(data => {
      try {
        this.errorGuideBooks = false;
        let tempContainer = JSON.parse(data.body);

        tempContainer = tempContainer.data;
        tempContainer = tempContainer;
        this.guideBooks = tempContainer[0].Body_Desc;

        if(this.guideBooks == null || this.guideBooks == undefined || this.guideBooks == "" || this.guideBooks == "{}"){
          this.guideBooks = "";
        }
        
        // this.getProspectDocData();
      } catch(error) {
        this.errorGuideBooks = true;
        let errorLocation = this.errorLocation.replace("methodName", "getGuidebooksData");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorGuideBooks = true;
      let errorLocation = this.errorLocation.replace("methodName", "getGuidebooksData");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorGuideBooks = true;
    let errorLocation = this.errorLocation.replace("methodName", "getGuidebooksData");
    error.message += errorLocation;
    throw error;
  }
}

  /*****************************************************************************************
   * Method Name: getProspectDocData                                                                 
   * Description: Call myresources api and get the data for the learn more tab               
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/25/19             Joshua Paul J. Avecilla(JPA)          Initial draft
   * 2    05/03/19             JPA                                   Only show the first index in the array of object.
   * 3    08/082019            JPA                                   removed call to make api call not dependent
  *****************************************************************************************/
 async getProspectDocData(){
  try {
    this.errorProspectusDoc = false;
    this.bodyLearnMoreData[5] = PageSection.learnMoreProspectDocumentVEIP;
    this.bodyLearnMoreData[6] = 1;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyLearnMoreData).pipe(map(res => res)).subscribe(data => {
      try {
        this.errorProspectusDoc = false;
        let tempContainer = JSON.parse(data.body);

        tempContainer = tempContainer.data;
        tempContainer = tempContainer;
        this.prospectUs = tempContainer[0].Body_Desc;

        if(this.prospectUs == null || this.prospectUs == undefined || this.prospectUs == "" || this.prospectUs == "{}"){
          this.prospectUs = "";
        }
        
        // this.getCountryAndAwardData();
      } catch(error) {
        this.errorProspectusDoc = true;
        let errorLocation = this.errorLocation.replace("methodName", "getProspectDocData");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorProspectusDoc = true;
      let errorLocation = this.errorLocation.replace("methodName", "getProspectDocData");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorProspectusDoc = true;
    let errorLocation = this.errorLocation.replace("methodName", "getProspectDocData");
    error.message += errorLocation;
    throw error;
  }
}

  /*****************************************************************************************
   * Method Name: getCountryAndAwardData                                                                 
   * Description: Call myresources api and get the data for the learn more tab               
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/25/19             Joshua Paul J. Avecilla(JPA)          Initial draft
   * 2    05/03/19             JPA                                   Only show the first index in the array of object.
   * 3    08/082019            JPA                                   removed call to make api call not dependent
  *****************************************************************************************/
 async getCountryAndAwardData(){
  try {
    this.errorCountryAward = false;
    this.bodyLearnMoreData[5] = PageSection.learnMoreCountryAndAwardVEIP;
    this.bodyLearnMoreData[6] = 1;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyLearnMoreData).pipe(map(res => res)).subscribe(data => {
      try {
        this.errorCountryAward = false;
        let tempContainer = JSON.parse(data.body);

        tempContainer = tempContainer.data;
        tempContainer = tempContainer;
        this.countryAndAward = tempContainer[0].Body_Desc;
        if(this.countryAndAward == null || this.countryAndAward == undefined || this.countryAndAward == "" || this.countryAndAward == "{}"){
          this.countryAndAward = "";
        }
        // this.getToolsAndReportsData()
      } catch(error) {
        this.countryAndAward = true;
        let errorLocation = this.errorLocation.replace("methodName", "getCountryAndAwardData");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.countryAndAward = true;
      let errorLocation = this.errorLocation.replace("methodName", "getCountryAndAwardData");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.countryAndAward = true;
    let errorLocation = this.errorLocation.replace("methodName", "getCountryAndAwardData");
    error.message += errorLocation;
    throw error;
  }
}

  /*****************************************************************************************
   * Method Name: getToolsAndReportsData                                                                 
   * Description: Call myresources api and get the data for the learn more tab               
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/25/19             Joshua Paul J. Avecilla(JPA)          Initial draft
   * 2    08/082019            JPA                                   removed call to make api call not dependent
  *****************************************************************************************/
 async getToolsAndReportsData(){
  try {
    this.errorToolsAndReports = false;
    this.bodyLearnMoreData[5] = PageSection.learnMoreToolsAndReportsVEIP;
    this.bodyLearnMoreData[6] = 1;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyLearnMoreData).pipe(map(res => res)).subscribe(data => {
      try {
        this.errorToolsAndReports = false;
        let tempContainer = JSON.parse(data.body);

        tempContainer = tempContainer.data;

        this.toolsAndReports = tempContainer;

        if(this.toolsAndReports == null || this.toolsAndReports == undefined || this.toolsAndReports == "" || this.toolsAndReports == "{}"){
          this.toolsAndReports = "";
        }
        else{
          for(let i = 0; i<this.toolsAndReports.length;i++){
            this.toolsAndReports[i].Id = i;
          }
        }
       setTimeout(()=>{ this.inContentIcon() }, 500); //this is for default expanded question in Learn More
      } catch(error) {
        this.errorToolsAndReports = true;
        let errorLocation = this.errorLocation.replace("methodName", "getToolsAndReportsData");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorToolsAndReports = true;
      let errorLocation = this.errorLocation.replace("methodName", "getToolsAndReportsData");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorToolsAndReports = true;
    let errorLocation = this.errorLocation.replace("methodName", "getToolsAndReportsData");
    error.message += errorLocation;
    throw error;
  }
}



/*****************************************************************************************
  * Method Name: getFile                                                                 
  * Description: Get file data            
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/23/19             Joshua Paul J. Avecilla(JPA)          Initial draft

*****************************************************************************************/
 getFile(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
  
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
  
      fileName = file;
    }
  
    this.staticContent.getFile(fileName, "MD").subscribe(data => { // MD for contacts page
      try {
        if(fileName.search("fileName") != -1 ){
          fileName = fileName.replace('fileName=', '');
        }
    
        let content = data;

        if(content != "null" && content != null && content != undefined){
          let file = JSON.parse(data.body); 
          
          this.downloadFile(fileName, fileType, this.base64ToArrayBuffer(file.file));

          this.staticContent.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
          });
        }else{
          this.staticContent.noFileExists(fileName);
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    }); 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
 }


/*****************************************************************************************
  * Method Name: downloadFile                                                                 
  * Description: Downloads the file.           
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/23/19             Joshua Paul J. Avecilla(JPA)          Initial draft
*****************************************************************************************/
 downloadFile(reportName, fileType, byte) {
  try {
    if(navigator.appVersion.toString().indexOf('.NET') > 0) { // browser is IE
      let blob = new Blob([byte], {type: fileType});
      window.navigator.msSaveBlob(blob, reportName);
    } else if(navigator.userAgent.indexOf("Firefox") > 0) { // browser is FireFox
      let a = document.createElement('a');
      a.style.display = "none";
      a.style.zIndex = "1002";
      a.style.position = "absolute";
      let blob = new Blob([byte], {type: fileType});
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = reportName;
      document.body.appendChild(a);
      a.click();
  
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
    } else { // browser is Chrome
      let blob = new Blob([byte], {type: fileType});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let fileName = reportName;
      link.download = fileName;
      link.click();
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "downloadFile");
    error.message += errorLocation;
    throw error;
  }
 }

/*****************************************************************************************
  * Method Name: base64ToArrayBuffer                                                                 
  * Description: Converts base 64 to array buffer.          
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/23/19             Joshua Paul J. Avecilla(JPA)          Initial draft
*****************************************************************************************/
 base64ToArrayBuffer(base64): Uint8Array {
  try {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);
    
    for(let i = 0; i < binaryLength; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "base64ToArrayBuffer");
    error.message += errorLocation;
    throw error;
  }
 }

 /*****************************************************************************************
  * Method Name: getFileName                                                                 
  * Description: Gets the file name in the html element.         
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/23/19             Joshua Paul J. Avecilla(JPA)          Initial draft

*****************************************************************************************/
getFileName(event) {
  try {
    let href: string = event.target.href;
    let srcElement: HTMLImageElement = event.srcElement;
    let target: string = event.target.target;


    if(srcElement.src != undefined){
      this.staticContent.validateFileType(event);
    }else if(href != undefined && href != ""){
      if(href.indexOf("fileName") != -1){ //if href value is a file
        event.preventDefault();
        this.staticContent.validateFileType(event);
      }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
        // href = href.split("/Default.aspx")[0]; 
        href = event.target.innerText;
        event.preventDefault();

        href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href, target) : "";
      }else{
        // alert("href " + href);
        event.preventDefault();
        this.staticContent.validateFileType(event);
      }
    }else{
      event.preventDefault();
      this.staticContent.validateFileType(event);
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFileName");
    error.message += errorLocation;
    throw error;
  }
}

navToMyHoldingsPage(page, target){
  page.indexOf("Resources") != -1 ? page = "resourcespage" : ""

  window.open(this.globals.currentWebUrl + "/" + page, target);
}

 /*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths
  * 3    05/03/19             JPA                                   Added other file icons for ppt/x, and doc/x
*****************************************************************************************/
async inContentIcon(){
  $("[id='learn-more-tab']").attr("aria-label","Learn More - VEIP transaction page");
  $("[href='#collapse0']").attr("aria-expanded","true");
  $("[id='collapse0']").attr("class","collapse show");
  $('<img src="/assets/pdf.svg" style="width: 16px; height: 16px;" alt="PDF file">').prependTo('[href$=".pdf"]');

  $('<img src="/assets/zip.svg" style="width: 16px; height: 16px;" alt="ZIP file">').prependTo('[href$=".zip"]');

  $('<img src="/assets/xls.svg" style="width: 16px; height: 16px;" alt="Excel file">').prependTo('[href$=".xls"]');

  $('<img src="/assets/xls.svg" style="width: 16px; height: 16px;" alt="Excel file">').prependTo('[href$=".xlsx"]');

  $('<img src="/assets/docx.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".doc"]');

  $('<img src="/assets/docx.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".docx"]');

  $('<img src="/assets/pdf.svg" style="width: 16px; height: 16px;" alt="pdf file">').prependTo('[href$=".PDF"]');

  $('<img src="/assets/zip.svg" style="width: 16px; height: 16px;" alt="ZIP file">').prependTo('[href$=".ZIP"]');

  $('<img src="/assets/xls.svg" style="width: 16px; height: 16px;" alt="Excel file">').prependTo('[href$=".XLS"]');

  $('<img src="/assets/docx.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".DOC"]');

  $('<img src="/assets/ppt.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".ppt"]');

  $('<img src="/assets/ppt.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".pptx"]');

  $('<img src="/assets/ppt.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".PPT"]');

  $('<img src="/assets/ppt.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".PPTX"]');
}

linkColorChange(){
  $("font").attr("color", "#460173");
  $("a").attr("color", "#460173");
  $("a:hover").attr("color", "#460173");
  $("a:focus").attr("color", "#460173");
}

 /*****************************************************************************************
  * Method Name: onTabChange                                                                 
  * Description: Method that runs a jquery code that inserts the file icons.        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
*****************************************************************************************/
onTabChange(event){
  try {
    setTimeout(()=>{ this.inContentIcon() }, 500);
    setTimeout(()=>{ this.staticContent.inContentIconAppend() }, 500);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToPage");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Veiptransact") != -1 && this.user != null) {
                webPage = "Veiptransact";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

/*****************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description        
  *****************************************************************************************/
 navToReportsPage(event) {
  try {
    let href = event.target.href;
    let id: number;
    let reportId: object;
    let srcElement: HTMLImageElement = event.srcElement;

    if(srcElement.src != undefined){
        event.preventDefault();
        this.staticContent.validateFileType(event);
    }else if(href != undefined) {
      if(href.indexOf("reportid") != -1) {
        id = href.substring(href.lastIndexOf("=") + 1);
        reportId = {'ReportTypeId': id};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
        window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
      }else if(href.indexOf("fileName=") != -1){
        event.preventDefault();
        this.staticContent.validateFileType(event);
      }
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToReportsPage");
    error.message += errorLocation;
    throw error;
  }
}

  /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
  collapseAccordion(index) {
    let className = document.getElementById("collapse" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.commonQuestions.length; i++) {
        document.getElementById("collapse" + i).className = "collapse";
      }
    }
   }
    /**********************************************************************************
   * Method Name: displayDotErrorIE                                                          
   * Description: Promps warning when dot or period is pressed on IE                                          
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 10/16/2019          Jikho Enrique                 Initial draft      
   *********************************************************************************/
  displayDotErrorIE(event){
    
    if(event.charCode == '46'){
      this.displayErrorPeriod = true;
    }else{
      this.displayErrorPeriod = false;
    }
    

  }
  /**********************************************************************************
   * Method Name: checkMessage                                                          
   * Description: Validates message display upon withdrawal/enrollment                                          
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 12/27/2019          Jikho Enrique                 Initial draft      
   *********************************************************************************/
  checkMessage(){
    if(this.messagechecker){
      this.withdrawnMessageCheckerInput = false;
    }else{
      this.withdrawnMessageCheckerInput = true;
    }
  }

  /*****************************************************************************************
   * Method Name: getVeipConfirmNoteDocument                                                                 
   * Description: Gets the VEIP Confirmation Note content in myResources contents.     
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    08/19/2021           Leah Z. Dia                 Initial Draft
   *****************************************************************************************/
 getVeipEnrollNote() {
  try {
    this.bodyData[4] = "No Grants";
    this.bodyData[5] = PageSection.veipEnrollNote;
    this.bodyData[6] = 1;

    this.staticContent.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let veipConfirmNoteFile = JSON.parse(data.body);

        if(veipConfirmNoteFile.data) { // length > {}
          let veipFile = veipConfirmNoteFile.data;
  
          if(veipFile.length > 0) {
            this.veipEnrollNote = veipFile[0].Body_Desc;
          } else {
            this.veipEnrollNote = null;
          }
        }
      } catch(error) {
        this.errorVeipEnrollNote = true;
        let errorLocation = this.errorLocation.replace("methodName", "getVeipEnrollNote");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorVeipEnrollNote = true;
      let errorLocation = this.errorLocation.replace("methodName", "getVeipEnrollNote");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorVeipEnrollNote = true;
    let errorLocation = this.errorLocation.replace("methodName", "getVeipEnrollNote");
    error.message += errorLocation;
    throw error;
  }
}
getVeipWithdrawNote() {
  try {
    this.bodyData[4] = "No Grants";
    this.bodyData[5] = PageSection.veipWithdrawNote;
    this.bodyData[6] = 1;

    this.staticContent.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let veipWithdrawNoteFile = JSON.parse(data.body);

        if(veipWithdrawNoteFile.data) { // length > {}
          let veipFile = veipWithdrawNoteFile.data;
  
          if(veipFile.length > 0) {
            this.veipWithdrawNote = veipFile[0].Body_Desc;

          } else {
            this.veipWithdrawNote = null;
          }
        } 
      } catch(error) {
        this.errorWithdrawNote = true;
        let errorLocation = this.errorLocation.replace("methodName", "getVeipWithdrawNote");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorWithdrawNote = true;
      let errorLocation = this.errorLocation.replace("methodName", "getVeipWithdrawNote");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorWithdrawNote = true;
    let errorLocation = this.errorLocation.replace("methodName", "getVeipWithdrawNote");
    error.message += errorLocation;
    throw error;
  }
}
openFileToNewTab(event) { // Gets the confirmation note for ESPP/VEIP in myResources API
  try {
    let href: string = event.target.href;
    let srcElement: HTMLImageElement = event.srcElement;
    let target: string = event.target.target;
    
    if(srcElement.src != undefined){
      this.staticContent.validateFileType(event);
    }else if(href != undefined && href != "" ){
      if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".xlsx") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file  
        event.preventDefault();
        this.staticContent.validateFileType(event);
      } else if(href.indexOf("https") != -1){ 
        event.preventDefault();
        window.open(href, target);
      } else{ 
        event.preventDefault();
        this.staticContent.validateFileType(event);
      }
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "openFileToNewTab");
    error.message += errorLocation;
    throw error;
  }
}

}
  