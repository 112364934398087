
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { catchError, map, debounceTime, distinctUntilChanged, switchMap, retry } from 'rxjs/operators';
import { encode } from 'punycode';

@Injectable()
export class AdminPageServices {

  constructor(private globals: Globals, private httpClient: HttpClient) { }

  searchAdminDetails(search, param, paramJobCd, checker) { //search = user input, param = other value
    let apiUrl = this.globals.currentAPIUrl;
    let adminDetailsUrl = this.globals.getAdminSearchAPI;

    if(checker == 'lastName'){
        adminDetailsUrl = adminDetailsUrl.replace("[param1]", "");
        adminDetailsUrl = adminDetailsUrl.replace("[param2]", param.replace(/\s*$/,''));
        adminDetailsUrl = adminDetailsUrl.replace("[param3]", search.replace(/\s*$/,''));
        adminDetailsUrl = adminDetailsUrl.replace("[param4]", paramJobCd);
    }
    else if(checker == 'firstName'){
        adminDetailsUrl = adminDetailsUrl.replace("[param1]", "");
        adminDetailsUrl = adminDetailsUrl.replace("[param2]", search.replace(/\s*$/,''));
        adminDetailsUrl = adminDetailsUrl.replace("[param3]", param.replace(/\s*$/,''));
        adminDetailsUrl = adminDetailsUrl.replace("[param4]", paramJobCd);
    }
    else if(checker == 'personnelId'){
        adminDetailsUrl = adminDetailsUrl.replace("[param1]", search);
        adminDetailsUrl = adminDetailsUrl.replace("[param2]", "");
        adminDetailsUrl = adminDetailsUrl.replace("[param3]", "");
        adminDetailsUrl = adminDetailsUrl.replace("[param4]", paramJobCd);
    }

    try {
      const api = apiUrl + adminDetailsUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
    };
    return observableThrowError(error);
  } 
}