
import {map} from 'rxjs/operators';

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
// import { MatInput } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';
import * as moment from 'moment'
import { BrokerType, PageSection, FileType, MyHoldingsPagesResources, tScript, CurrencySymbol } from '../services/enumerations/enumerations';
import { HoldingProgramDetailsESPPService } from './HoldingProgramDetailsESPPService';
import { StaticContentService } from '../services/StaticContentService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { BrokersPageService } from '../brokerspage/BrokersPageService';
import { MobileService } from '../services/MobileService';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {TooltipPosition} from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-holdingprogramdetailspage-espp',
  templateUrl: './holdingprogramdetailspage-espp.component.html',
  styleUrls: ['./holdingprogramdetailspage-espp.component.css']
})
export class HoldingprogramdetailspageEsppComponent implements OnInit {

  // @ViewChild('dateFromInput', {
  //   read: MatInput
  // }) dateFromInput: MatInput; 

  // @ViewChild('dateToInput', {
  //   read: MatInput
  // })  dateToInput: MatInput;

  displayedColumnsEsppSalesHist: string[] = ['Date', 'Type', 'Shares', 'PriceUSD', 'ProceedsUSD', 'PurchaseValueUSD'];
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  dataSourceEsppSalesHist: any;

  loading: boolean = true;
  datePipe = new DatePipe('en-US');
  dateFrom: string = "' '";
  dateTo: string = "' '";
  dateFromClicked: boolean = false;
  dateToClicked: boolean = false;
  dateFromHolder: any;
  dateToHolder: any;
  // dateFromPlaceholder: string = " "; // Ngb Datepicker
  // dateToPlaceholder: string = " "; // Ngb Datepicker
  // dateFromInput: NgbDateStruct; // Ngb Datepicker
  // dateToInput: NgbDateStruct; // Ngb Datepicker

  dateFromInput: Date; // Angular Material Datepicker
  dateToInput: Date; // Angular Material Datepicker

  dateFromChecker: any;
  dateToChecker: any;
  dateErrorChecker: boolean = false;


  brokerType: any;
  noSalesHistory: boolean = false;

  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsNextRelease: any;
  detailsSharesReleasing: any;
  detailsGrantAccepted: any;
  detailsUnreleased: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;

  // ESPP variables - Start
  offeringPeriod: string;
  contribution: number;
  totalContributionUSD: number;
  totalContributionLoc: number;
  purchasePrice: number;
  purchasePriceUSD: number;
  fmvAtPurchaseUSD: number;
  exchangeRateLoc: number;
  taxWithholdingMethod: string;
  account: string;
  displayAccount: boolean = false;
  sharesPurchased: number;
  withholdingForTaxes: number;
  fractionalShares: number;
  soldTransferred: number;
  netRemainAtBroker: number;
  localCurrencyCd: string;
  grossSharesPurchased: number;
  sharesWithheldTaxShares: string;
  sharesWithheldTaxLoc: string;
  sharesWithheldTaxUSD: string;
  fractionalSharesRefundedShares: number;
  fractionalSharesRefundedUSD: number;
  fractionalSharesRefundedLoc: number;
  netSharesPurchaseShares: number;
  netSharesPurchaseUSD: number;
  netSharesPurchaseLoc: number;
  xFmvAtPurchaseUSD: number;
  xFmvAtPurchaseLoc: number;
  fullValOfSharesUSD: number;
  fullValOfSharesLoc: number;
  totalDiscountUSD: number;
  totalDiscountLoc: number;
  actualTax: number;
  displayActualTax: boolean = false;

  datePeriod: number = 4;
  disableDatePicker: boolean = true;
  esppSalesHistShares: any = 0;
  esppSalesHistPriceUSD: any = 0;
  esppSalesHistProceedsUSD: any = 0;
  esppSalesHistPurchaseValueUSD: any = 0;
  // ESPP variables - End
  showAgreementTab: boolean = false;

  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = sessionStorage.getItem(this.globals.selectedCurrencyCd);
  salesTableLoading: boolean;
  periodSelected: string = "All dates";
  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;
  shortAwardName: string;

  orientation: string = "horizontal";

  //Learn More Tab
  esppCommonQuestionsContent: any;
  esppGuidebooksContent: object;
  esppPlanDocuContent: object;
  esppProspectusContent: object;
  esppCountryAwardContent: object;
  esppToolsandReportContent: any;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  allCountryFormsContent: string;
  stepTwoContent: object;
  errorLocation: string = " + [Component: Esppdetails, Method: methodName]";
  errorDetailsTab: boolean = false;
  errorSalesHistTab: boolean = false;
  errorCommonQuestions: boolean = false;
  errorGuideBooks: boolean = false;
  errorPlanDoc: boolean = false;
  errorProspectusDoc: boolean = false;
  errorCountryAward: boolean = false;
  errorToolsAndReports: boolean = false;
  
  currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));

  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;

fileTypeImagePath: string;
fileTypeAlt: string;

//Tooltip
OfferingPeriod_mtt: string = tScript.esppdetailspage_op;
PurchasePrice_mtt_espp: string = tScript.esppdetailspage_pp;
SharesPurchased_mtt: string = tScript.esppdetailspage_sp;
WithholdingForTaxes_mtt: string = tScript.esppdetailspage_wft;
FractionalShares_mtt: string = tScript.esppdetailspage_fs;
TotalContributions_mtt_espp: string = tScript.esppdetailspage_tc;
SharesWithheld_mtt: string = tScript.esppdetailspage_sw;
FractionalSharesRefunded_mtt: string = tScript.esppdetailspage_fsr;
NetSharesPurchased_mtt: string = tScript.esppdetailspage_nsp;
xFMVatPurchase_mtt: string = tScript.esppdetailspage_xfap;
TotalDiscount_mtt: string = tScript.esppdetailspage_td;
SalesHistory_Type: string = tScript.esppveipsaleshistorytab_type;
SalesHistory_Price: string = tScript.esppveipsaleshistorytab_price;
SalesHistory_Proceeds: string = tScript.esppveipsaleshistorytab_proceeds;
SalesHistory_PurchaseDateValue: string = tScript.esppveipsaleshistorytab_pdv;

currencyCdSymbol: any;
commonQAccordionCount: number = 1; // 1 accordion is initially expanded
dashOnExpiration: boolean = false;
UBSlink: any;
MSlink: any;
envSetting: any;

  constructor(private sanitizer:DomSanitizer, private brokerService: BrokersPageService, private router: Router, private app: AppComponent, private globals: Globals, private esppProgramDetails: HoldingProgramDetailsESPPService, private staticContentService: StaticContentService, private errorNotificationService: ErrorNotificationService, private mobileService: MobileService, private ngbDateParserFormatter: NgbDateParserFormatter) {
    this.app.appNavHeaderVisible = true;
    this.app.appFooterVisible = true;

  }

  ngOnInit() {

    let hasESPPChecker1 = sessionStorage.getItem("hasESPP");
    // let hasESPPChecker2 = sessionStorage.getItem("hasESPPinDounghnut");
    if (hasESPPChecker1 != "1") this.router.navigate(['homepage']);

    // BACK BUTTON BUG - START
    sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } else {
      this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
    }
    // BACK BUTTON BUG - END

    if (this.currencyCd == null || this.currencyCd == "" || this.currencyCd == undefined || this.currencyCd == "null") {
      this.currencyCd = this.user.CurrencyCd;
    }
    this.currencyCd = this.currencyCd.split('"').join("");
    this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")];
    try {
      sessionStorage.setItem("Highlighted", "1");
      this.checkErrorMessage();
      if (this.bodyData[1] == 'Leave with pay'){
        this.bodyData[1] = 'Active';
      }
      this.getProgramDetails();
      this.getesppCommonQuestions();
      this.getesppGuideBooks();    
      this.getesppPlanDocument();    
      this.getesppProspectusDocument();    
      this.getesppCountryAward();    
      this.getesppToolsAndReports();
      sessionStorage.setItem("Program Component Indicator", JSON.stringify("ESPP"));
      sessionStorage.setItem("Highlight Checker", "1");
      let active = sessionStorage.getItem("IsActive Row");
      this.selectDatePeriod(4);
      this.brokersSSO();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "espp details ngOnInit");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
  if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
    let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

    if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
    }else{
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
    }
  }else{
    if(this.bodyData != null){
      if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }
  }
}

  navToPage(strLoc) {
    try {
      // BACK BUTTON BUG - START
      if(strLoc == "holdingsummarypage") {
        sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(true));
      }
      // BACK BUTTON BUG - END
      
      this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "contactspage" : "";
  
    window.open(this.globals.currentWebUrl + "/" + page, "_blank");
  }

  /**********************************************************************************
      Method Name: getProgramDetails
      Description: Get ESPP program details from session 

      #     Date            Modified by       Description
      1     01/30/19        Katrina Narag     Initial draft         
      2     02/08/19        KMN                Set award name to grant long desc
      3     02/20/2019      KMN               Add short award name for breadcrumbs
  **********************************************************************************/
  getProgramDetails() {
    try {
      this.esppProgramDetails.programDetails = this.programDetails;
      this.shortAwardName = this.programDetails.AwardName;
      this.awardName = this.shortAwardName;  //KMN, 2
      this.detailsAvailableToSell = this.programDetails.AvailableToSell;
      this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
      this.detailsBroker = this.programDetails.BrokerId;
      this.detailsNextRelease = this.programDetails.NextRelease;
      this.detailsSharesReleasing = this.programDetails.SharesReleasing;
      this.detailsGrantAccepted = this.programDetails.GrantAccepted;
      this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
      this.detailsReleased = this.programDetails.ReleasedExercisableQty;
      this.detailsExpiration = this.programDetails.Expiration;
      this.detailsForfeited = this.programDetails.Forfeited;

      // console.table(this.programDetails);
      // let empties: Array<string>;
      // for(let i = 0; i < Object.keys(this.programDetails).length; i++){
      //   empties.push(Object.values(this.programDetails)[i])
      //   if(Object.values(this.programDetails)[i] == ""){
      //     // if(Object.values(this.programDetails)[i])
      //     //   this.detailsBroker = "-";
      //     // else if(Object.values(this.programDetails.indexOf("Expiration"))[i])
      //     //   this.detailsExpiration = "-";
      //     // else if(Object.values(this.programDetails.indexOf("NextRelease"))[i])
      //     //   this.detailsNextRelease = "-";
      //     // else if(Object.values(this.programDetails.indexOf("Unreleased"))[i])
      //     //   this.detailsUnreleased = "-";
      //   }
      //   console.log(Object.keys(this.programDetails)[i],Object.values(this.programDetails)[i]);
      // }
      // if(this.detailsBroker == "")this.detailsBroker = "-";
      // if(this.detailsExpiration == "")this.dashOnExpiration = true; else this.dashOnExpiration = false;
      // if(this.detailsNextRelease == "")this.detailsNextRelease = "-";
      // if(this.detailsUnreleased == "")this.detailsUnreleased = "-";
  
      this.esppProgramDetails.getSalesHistoryPeriod().pipe(map(res => res)).subscribe(data => {
        try {
          let b = data;
          b = b.sort((a,b) => 0 - (a.Id > b.Id ? -1 : 1));
          this.yearToDate = b[0].Value;
          this.lastYear = b[1].Value;
          this.last3Years = b[2].Value;
          this.allDates = b[3].Value;
          this.custom = b[4].Value;
    
          this.getESPPAwardDetails(this.user.OptNum, this.programDetails.OfferNum);
          this.getESPPAwardSalesHist(this.programDetails.OfferNum);
        } catch(error) {
          this.errorSalesHistTab = true;
          this.getESPPAwardDetails(this.user.OptNum, this.programDetails.OfferNum);
          // this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        // this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      // this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  getESPPAwardDetails(optNum, offerNum) {
    try {
      let offeringDate;
      this.esppProgramDetails.getESPPdetails(optNum, offerNum).pipe(map(res => res)).subscribe(data => {
        try {
          if(Array.isArray(data) && data[0].length > 0) {
            let esppDetails = data[0][0];
            this.esppProgramDetails.esppDetails = esppDetails;
            // offeringDate = esppDetails.OfferingPeriod.split(' - ');
            // this.offeringPeriod = moment(offeringDate[0]).format('DD MMM YYYY') + ' ' + '-' + ' ' + moment(offeringDate[1]).format('DD MMM YYYY');
            this.offeringPeriod = esppDetails.OfferingPeriod;
            this.contribution = esppDetails.Contribution;
            this.totalContributionUSD = esppDetails.TotalContributionUSD;
            this.purchasePriceUSD = esppDetails.PurchasePriceUSD;
            this.fmvAtPurchaseUSD = esppDetails.FMVAtPurchaseUSD;
            this.exchangeRateLoc = esppDetails.ExchangeRateLoc;
            if(esppDetails.TaxWithholdingMethod == "Payroll Withholding") {
              this.sharesWithheldTaxShares = "";
              this.sharesWithheldTaxUSD = "Refer to Earnings Statement";
              this.sharesWithheldTaxLoc = "Refer to Earnings Statement";
            } else {
              this.sharesWithheldTaxShares = esppDetails.SharesWithheldTax;
              this.sharesWithheldTaxUSD = esppDetails.SharesWithheldTax_ValueUSD;
              this.sharesWithheldTaxLoc = esppDetails.SharesWithheldTax_ValueLoc;
            }
            this.taxWithholdingMethod = esppDetails.TaxWithholdingMethod;
            if(esppDetails.Account !== "") { // checks the account if its empty 
              this.displayAccount = true;
            }
            this.account = esppDetails.Account;
            this.sharesPurchased = esppDetails.SharesPurchased;
            this.withholdingForTaxes = esppDetails.WithholdingForTaxes;
            this.fractionalShares = esppDetails.FractionalShares;
            this.soldTransferred = esppDetails.SoldTransferred;
            this.netRemainAtBroker = Math.round(esppDetails.NetRemainingAtBroker);
            this.localCurrencyCd = esppDetails.LocalCurrencyCd;
            this.totalContributionLoc = esppDetails.TotalContributionLoc;
            this.grossSharesPurchased = esppDetails.GrossSharesPurchased;
            this.fractionalSharesRefundedShares = esppDetails.FractionalSharesRefunded;
            this.fractionalSharesRefundedUSD = esppDetails.FractionalSharesRefunded_ValueUSD;
            this.fractionalSharesRefundedLoc = esppDetails.FractionalSharesRefunded_ValueLoc;
            this.netSharesPurchaseShares = esppDetails.NetSharesPurchased;
            this.netSharesPurchaseUSD = esppDetails.NetSharesPurchasedShares_ValueUSD;
            this.netSharesPurchaseLoc = esppDetails.NetSharesPurchasedShares_ValueLoc;
            this.xFmvAtPurchaseUSD = esppDetails.xFMVAtPurchaseUSD;
            this.xFmvAtPurchaseLoc = esppDetails.xFMVAtPurchaseLoc;
            this.fullValOfSharesUSD = esppDetails.FullValueOfSharesUSD;
            this.fullValOfSharesLoc = esppDetails.FullValueOfSharesLoc;
            this.totalDiscountUSD = esppDetails.TotalDiscount_ValueUSD;
            this.totalDiscountLoc = esppDetails.TotalDiscountValueLoc;
            if(offerNum >= 42) {
              this.displayActualTax = true;
            }
            this.actualTax = esppDetails.ActualTax;
      
            // this.loading = false;
          } 
        } catch(error) {
          this.errorDetailsTab = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getESPPAwardDetails");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorDetailsTab = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getESPPAwardDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorDetailsTab = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getESPPAwardDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  selectDatePeriod(period) {
    try {
      if(period != 5){
        $(".MDatePicker input").css("background-color","#d3d0d0")
      }else{
        $(".MDatePicker input").css("background-color","white")
      }

      this.datePeriod = period;

      if(this.datePeriod == 1) { // year to date
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.yearToDate;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = dateToEvent;
        // this.dateToPlaceholder = " "; // Ngb Datepicker

        currentDate.setMonth(0, 1);
        let yearToDate = currentDate;
        let dateFromEvent = this.datePipe.transform(yearToDate, "MM'/'dd'/'yyyy");
        this.dateFrom = dateFromEvent;
        // this.dateFromPlaceholder = " "; // Ngb Datepicker

        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
      } else if(this.datePeriod == 2) { // last year
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.lastYear;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = dateToEvent;
        // this.dateToPlaceholder = " "; // Ngb Datepicker

        currentDate.setFullYear(currentDate.getFullYear() - 1);
        let lastYear = currentDate;
        let dateFromEvent = this.datePipe.transform(lastYear, "MM'/'dd'/'yyyy");
        this.dateFrom = dateFromEvent;
        // this.dateFromPlaceholder = " "; // Ngb Datepicker

        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
      } else if(this.datePeriod == 3) { // last 3 years
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.last3Years;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = dateToEvent;
        // this.dateToPlaceholder = " "; // Ngb Datepicker

        currentDate.setFullYear(currentDate.getFullYear() - 3);
        let lastThreeYear = currentDate;
        let dateFromEvent = this.datePipe.transform(lastThreeYear, "MM'/'dd'/'yyyy");
        this.dateFrom = dateFromEvent;
        // this.dateFromPlaceholder = " "; // Ngb Datepicker

        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
      } else if(this.datePeriod == 4) { // all dates
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.allDates;
        this.dateFromClicked = false;
        this.dateToClicked = false;
        this.dateFrom = "' '";
        // this.dateFromPlaceholder = " "; // Ngb Datepicker
        this.dateTo = "' '";
        // this.dateToPlaceholder = " "; // Ngb Datepicker
        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
      } else { // period = 5, custom
        this.disableDatePicker = false;     
        this.periodSelected = this.custom;
        
        let currentDate = new Date();
        let inputValue = moment(currentDate).format("MM/DD/YYYY");

        this.dateTo = "'" + inputValue + "'";
        if(this.dateToClicked) {
          // this.dateToInput = this.dateToHolder; // Ngb Datepicker
          this.dateToInput = new Date(this.dateToHolder); // Angular Material Datepicker
        } else {
          this.dateToInput = currentDate; // Angular Material Datepicker
          // this.dateToInput = this.ngbDateParserFormatter.parse(
          //   currentDate.getFullYear().toString() + "-" +
          //   (currentDate.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
          //   currentDate.getDate().toString()
          // ); // Ngb Datepicker
        }
        // this.dateToPlaceholder = moment(inputValue).format("DD MMM YYYY"); // Ngb Datepicker

        this.dateFrom = "'" + inputValue + "'";
        if(this.dateFromClicked) {
          // this.dateFromInput = this.dateFromHolder; // Ngb Datepicker
          this.dateFromInput = new Date(this.dateFromHolder); // Angular Material Datepicker
        } else {
          this.dateFromInput = currentDate; // Angular Material Datepicker
          // this.dateFromInput = this.ngbDateParserFormatter.parse(
          //   currentDate.getFullYear().toString() + "-" +
          //   (currentDate.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
          //   currentDate.getDate().toString()
          // ); // Ngb Datepicker
        }
        // this.dateFromPlaceholder = moment(inputValue).format("DD MMM YYYY"); // Ngb Datepicker
  
        this.dateToChecker = inputValue;
        this.dateFromChecker = inputValue;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDatePeriod");
      error.message += errorLocation;
      throw error;
    }
  }

  // selectDateFrom(event) { // Ngb Datepicker
  //   try {
  //     let dateEvent = event.month + '/' + event.day + '/' + event.year;
  //     this.dateFromChecker = dateEvent;
  //     this.dateFrom = "'" + dateEvent + "'";
  //     this.dateFromClicked = true;
  //     this.dateFromHolder = this.dateFromInput;
  //     // this.dateFromPlaceholder = event.toString();
  //   } catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }

  selectDateFrom(value: Date) { // Angular Material Datepicker
    try {
      let dateEvent = moment(value).format("YYYY-MM-DD");
      this.dateFromChecker = dateEvent;
      this.dateFrom = "'" + dateEvent + "'";
      this.dateFromClicked = true;
      this.dateFromHolder = dateEvent;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
      error.message += errorLocation;
      throw error;
    }
  }

  // selectDateTo(event) { // Ngb Datepicker
  //   try {
  //     let dateEvent = event.month + '/' + event.day + '/' + event.year;
  //     this.dateToChecker = dateEvent;
  //     this.dateTo = "'" + dateEvent + "'";
  //     this.dateToClicked = true;
  //     this.dateToHolder = this.dateToInput;
  //     this.dateToPlaceholder = event.toString();
  //   } catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }

  selectDateTo(value: Date) { // Angular Material Datepicker
    try {
      let dateEvent = moment(value).format("YYYY-MM-DD");
      this.dateToChecker = dateEvent;
      this.dateTo = "'" + dateEvent + "'";
      this.dateToClicked = true;
      this.dateToHolder = dateEvent;
   } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
      error.message += errorLocation;
      throw error;
    }
  }

  viewESPPAwardSalesHist() {
    try {
      this.dateErrorChecker = false;
      this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
      this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
  
      let tempDateTo = this.dateTo.replace(/'/g, '');
      let tempDateFrom = this.dateFrom.replace(/'/g, '');
  
      tempDateTo = moment(tempDateTo).format("YYYY-MM-DD");
      tempDateFrom = moment(tempDateFrom).format("YYYY-MM-DD");
  
      if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
        this.dateErrorChecker = true;
        // this.dateToChecker = "";
        // this.dateFromChecker = "";
      }
      else if (tempDateTo < tempDateFrom && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else{
      this.salesTableLoading = true;
      this.esppSalesHistShares = 0;
      this.esppSalesHistPriceUSD = 0;
      this.esppSalesHistProceedsUSD = 0;
      this.esppSalesHistPurchaseValueUSD = 0;
      this.getESPPAwardSalesHist(this.programDetails.OfferNum);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewESPPAwardSalesHist");
      error.message += errorLocation;
      throw error;
    }
  }

  getESPPAwardSalesHist(offerNum) {
    try {
      this.esppProgramDetails.getEsppSalesHistory(this.user.OptNum, offerNum, this.datePeriod, this.dateFrom, this.dateTo).pipe(map(res => res)).subscribe(data => {
        try {
          if(data[1].length == 0) {
            this.dataSourceEsppSalesHist = data[1];
            this.brokerType = BrokerType[data[0][0].BrokerCd];
            this.noSalesHistory = true;
            this.salesTableLoading = false;
          } else {
            this.noSalesHistory = false;
            this.brokerType = BrokerType[data[0][0].BrokerCd];
            this.dataSourceEsppSalesHist = data[1];
    
            for(let i = 0; i < data[1].length; i++) {
              this.esppSalesHistShares += (data[1][i].Shares*1);
              if(data[1][i].PriceUSD != "-") {
                this.esppSalesHistPriceUSD += (data[1][i].PriceUSD*1);
              }
              if(data[1][i].ProceedsUSD != "-") {
                this.esppSalesHistProceedsUSD += (data[1][i].ProceedsUSD*1);
              }
              this.esppSalesHistPurchaseValueUSD += (data[1][i].PurchaseValueUSD*1);
            }
            this.salesTableLoading = false;
          }
        } catch(error) {
          this.errorSalesHistTab = true;
          this.salesTableLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getESPPAwardSalesHist");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorSalesHistTab = true;
        this.salesTableLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getESPPAwardSalesHist");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorSalesHistTab = true;
      this.salesTableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getESPPAwardSalesHist");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
    Method Name: onResize
    Description: Gets the available inner width of the screen or browser on resize.

    #     Date            Modified by                 Description
    1     06/19/19        Dalemark P. Suquib          Initial draft
  **********************************************************************************/
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   try {
  //     this.mobileService.onResize(event);
  //     this.displayDesktopView = this.mobileService.displayDesktopView;
  //     this.displayMobileView = this.mobileService.displayMobileView;
  //   } catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "onResize");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }

  setPeriodAllDates(tab) {
    try {
      if(tab.nextId == "sales-history-tab") {
        this.disableDatePicker = true;
        this.dateFrom = "' '";
        this.dateTo = "' '";
        this.periodSelected = this.allDates;
        this.datePeriod = 4;
        this.dateFromClicked = false;
        this.dateToClicked = false;
        this.dateErrorChecker = false;
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        // this.dateFromPlaceholder = " "; // Ngb Datepicker
        // this.dateToPlaceholder = " "; // Ngb Datepicker
        // this.dateFromInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        // this.dateToInput = this.ngbDateParserFormatter.parse(null); // Ngb Datepicker
        this.salesTableLoading = true;
        this.esppSalesHistShares = 0;
        this.esppSalesHistPriceUSD = 0;
        this.esppSalesHistProceedsUSD = 0;
        this.esppSalesHistPurchaseValueUSD = 0;
        this.getESPPAwardSalesHist(this.programDetails.OfferNum);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "setPeriodAllDates");
      error.message += errorLocation;
      throw error;
    }
  }
  
  /*****************************************************************************************
   * Method Name: getesppCommonQuestions                                                                 
   * Description: Gets the content for common questions from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/25/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getesppCommonQuestions() {
    try {
      this.bodyData[5] = PageSection.esppAwardCommonQuestion;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let commonQuestionData = JSON.parse(data.body);
        
          if(commonQuestionData.data) { // length > {}
            let commonQuestionBody = commonQuestionData.data;

            if(commonQuestionBody.length > 0) {
              this.esppCommonQuestionsContent = commonQuestionBody;

              for(let i=0; i<this.esppCommonQuestionsContent.length; i++){
                if(this.esppCommonQuestionsContent[i]["Body_Desc"].indexOf("<u>") != -1){
                  this.esppCommonQuestionsContent[i]["Body_Desc"] = this.esppCommonQuestionsContent[i]["Body_Desc"].replace("<u>","");
                  this.esppCommonQuestionsContent[i]["Body_Desc"] = this.esppCommonQuestionsContent[i]["Body_Desc"].replace("</u>","");
                }
              }
            } else {
              this.esppCommonQuestionsContent = null;
            }
          } 
        } catch(error) {
          this.errorCommonQuestions = true;
          this.getesppGuideBooks();
          let errorLocation = this.errorLocation.replace("methodName", "getesppCommonQuestions");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCommonQuestions = true;
        this.getesppGuideBooks();
        let errorLocation = this.errorLocation.replace("methodName", "getesppCommonQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCommonQuestions = true;
      this.getesppGuideBooks();
      let errorLocation = this.errorLocation.replace("methodName", "getesppCommonQuestions");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getesppGuideBooks                                                                 
   * Description: Gets the content for guide books from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/25/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getesppGuideBooks() {
    try {
      this.bodyData[5] = PageSection.esppAwardGuidebooks;
      this.bodyData[6] = 1;   
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let guideBooksData = JSON.parse(data.body);
  
          if(guideBooksData.data) { // length > {}
            let guideBooksBody = guideBooksData.data;
            
            if(guideBooksBody.length > 0) {
              this.esppGuidebooksContent = guideBooksBody[0].Body_Desc;
              // if(this.guideBooksContent.indexOf("&#160;") != -1) { // not sure 
              //   this.guideBooksContent = this.guideBooksContent.replace(/&#160;/gi, " ");
              // }
            } else {
              this.esppGuidebooksContent = null;
            }
          }
        } catch(error) {
          this.errorGuideBooks = true;
          this.getesppPlanDocument();
          let errorLocation = this.errorLocation.replace("methodName", "getesppGuideBooks");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorGuideBooks = true;
        this.getesppPlanDocument();
        let errorLocation = this.errorLocation.replace("methodName", "getesppGuideBooks");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorGuideBooks = true;
      this.getesppPlanDocument();
      let errorLocation = this.errorLocation.replace("methodName", "getesppGuideBooks");
      error.message += errorLocation;
      throw error;
    }
  }
  
  /*****************************************************************************************
   * Method Name: getesppPlanDocument                                                                 
   * Description: Gets the content for plan document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getesppPlanDocument() {
    try {
      this.bodyData[5] = PageSection.esppAwardPlanDocu;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let planDocumentData = JSON.parse(data.body);
  
          if(planDocumentData.data) { // length > {}
            let planDocumentBody = planDocumentData.data;
    
            if(planDocumentBody.length > 0) {
              this.esppPlanDocuContent = planDocumentBody[0].Body_Desc;
            } else {
              this.esppPlanDocuContent = null;
            }
          }
        } catch(error) {
          this.errorPlanDoc = true;
          this.getesppProspectusDocument();
          let errorLocation = this.errorLocation.replace("methodName", "getesppPlanDocument");
         error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorPlanDoc = true;
        this.getesppProspectusDocument();
        let errorLocation = this.errorLocation.replace("methodName", "getesppPlanDocument");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorPlanDoc = true;
      this.getesppProspectusDocument();
      let errorLocation = this.errorLocation.replace("methodName", "getesppPlanDocument");
      error.message += errorLocation;
      throw error;
    }
  }
  

  /*****************************************************************************************
   * Method Name: getesppProspectusDocument                                                                 
   * Description: Gets the content for plan document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/25/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getesppProspectusDocument() {
    try {
      this.bodyData[5] = PageSection.esppAwardProspectusDocu;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let prospectusDocumentData = JSON.parse(data.body);
  
          if(prospectusDocumentData.data) { // length > {}
            let prospectusDocumentBody = prospectusDocumentData.data;
    
            if(prospectusDocumentBody.length > 0) {
              this.esppProspectusContent = prospectusDocumentBody[0].Body_Desc;
            } else {
              this.esppProspectusContent = null;
            }
          }
        } catch(error) {
          this.errorProspectusDoc = true;
          this.getesppCountryAward();
          let errorLocation = this.errorLocation.replace("methodName", "getesppProspectusDocument");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorProspectusDoc = true;
        this.getesppCountryAward();
        let errorLocation = this.errorLocation.replace("methodName", "getesppProspectusDocument");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorProspectusDoc = true;
      this.getesppCountryAward();
      let errorLocation = this.errorLocation.replace("methodName", "getesppProspectusDocument");
      error.message += errorLocation;
      throw error;
    }

    
  }
  
  /*****************************************************************************************
   * Method Name: getesppCountryAward                                                                 
   * Description: Gets the content for prospectus document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getesppCountryAward() {
    try {
      this.bodyData[5] = PageSection.esppAwardCountry;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let countryAwardData = JSON.parse(data.body);

          if(countryAwardData.data) { // length > {}
            let countryAwardBody = countryAwardData.data;
    
            if(countryAwardBody.length > 0) {
              this.esppCountryAwardContent = countryAwardBody[0].Body_Desc;
            } else {
              this.esppCountryAwardContent = null;
            }
          }
        } catch(error) {
          this.errorCountryAward = true;
          this.getesppToolsAndReports();
          let errorLocation = this.errorLocation.replace("methodName", "getesppCountryAward");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCountryAward = true;
        this.getesppToolsAndReports();
        let errorLocation = this.errorLocation.replace("methodName", "getesppCountryAward");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCountryAward = true;
      this.getesppToolsAndReports();
      let errorLocation = this.errorLocation.replace("methodName", "getesppCountryAward");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getesppToolsAndReports                                                                 
   * Description: Gets the content for tools and reports from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/25/2019           Iris Jane Manloloyo       Initial Draft
   *****************************************************************************************/
  getesppToolsAndReports() {
    try {
      this.bodyData[5] = PageSection.esppAwardToolsAndReports;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let toolsAndReportsData = JSON.parse(data.body);

          if(toolsAndReportsData.data) { // length > {}
            let toolsAndReportsBody = toolsAndReportsData.data;
            
            if(toolsAndReportsBody.length > 0) {
              this.esppToolsandReportContent = toolsAndReportsBody[0].Body_Desc;

              this.esppToolsandReportContent = this.esppToolsandReportContent.replace('<b> <u>Reports</u></b>','<span style="font-weight: 500; text-decoration: underline;">Reports</span>');
              this.esppToolsandReportContent =  this.esppToolsandReportContent.split('<u>').join('<span style="text-decoration: underline;">');
              this.esppToolsandReportContent =  this.esppToolsandReportContent.split('</u>').join('</span>');
            } else {
              this.esppToolsandReportContent = null;
            }

            setTimeout(()=>{ 
              this.loading = false;
              this.inContentIcon();
            }, 500);
          }
        } catch(error) {
          this.errorToolsAndReports = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getesppToolsAndReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorToolsAndReports = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getesppToolsAndReports");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorToolsAndReports = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getesppToolsAndReports");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   * 2    05/21/19             KMN                         Bug 298844 fix
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let id: string = event.target.id;
      let srcElement: HTMLImageElement = event.srcElement;
      let target: string = event.target.target;
      
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          let innerTxt = event.target.innerText;
          event.preventDefault();

          if(innerTxt in MyHoldingsPagesResources){
            this.navToMyHoldingsPage(href);
          }else if(href.indexOf("Resources") != -1){
            this.navToMyHoldingsPage("Resources");
          }

        }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
          event.preventDefault();
          id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
        }else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
          window.open(href, target);
        }else{ //if has file but no filename text
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getFile(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
  
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
  
      fileName = file;
    }
  
    this.staticContentService.getFile(fileName, "MD").subscribe(data => {
      try {
        let content = JSON.parse(data.body);
        let file = content.data;
    
        this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
        this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
          let writeLogResponse = data;
        });
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    });
 } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
}

getStepTwoContent() {
  try {
    this.bodyData[5] = PageSection.esppTransacStepTwo + this.bodyData[0];
    this.bodyData[6] = 0;
  
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let stepTwoData = JSON.parse(data.body);

        if(stepTwoData.data) { // length > {}
          let stepTwoBody = stepTwoData.data;
     
          if(stepTwoBody.length > 0) {
            this.stepTwoContent = stepTwoBody;
     
            for(let i = 0; i < stepTwoBody.data.length; i++) {
              if(this.stepTwoContent[i].File_Type == "pdf") {
               this.stepTwoContent[i].fileIcon = FileType.pdf;
              } else if(this.stepTwoContent[i].File_Type == "docx") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "doc") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "xls") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "xlsx") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "pptx") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "ppt") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "zip") {
               this.stepTwoContent[i].fileIcon = FileType.zip;
              } 
            }
          } else {
            this.stepTwoContent = null;
          }
        }
     
        this.getAllCountryForms();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
  Method Name: getAllCountryForms
  Description: Gets all the available country forms.

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getAllCountryForms() {
  try {
    this.bodyData[5] = PageSection.esppTransacAllCountryForms;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
     try {
      let allCountryFormsData = JSON.parse(data.body);
     
      if(allCountryFormsData.data) { // length > {}
        let allCountryFormsBody = allCountryFormsData.data;
  
        if(allCountryFormsBody.length > 0) {
          this.allCountryFormsContent = allCountryFormsBody[0].Body_Desc;
          setTimeout(() => { this.inContentIcon() }, 500);
        } else {
          this.allCountryFormsContent = null;
        }
      }
     } catch(error) {
       let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
       error.message += errorLocation;
       throw error;
     }
   }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths

*****************************************************************************************/
async inContentIcon(){
  $("[href='#collapseCQIndex0']").attr("aria-expanded","true");
  $("[id='collapseCQIndex0']").attr("class","collapse show");
  $("[id='learn-more-tab']").attr("aria-label","Learn More for ESPP award");

        //matDatepicker mod
        $(".mat-input-infix span").remove();
        $(".mat-input-underline span").remove();
        $(".mat-input-subscript-wrapper").remove();
        $(".mat-input-underline").css("height","0");
        $(".mat-input-element").addClass("datepicker-input");
        $(".mat-input-element").css("height","30px");
        $(".mat-input-element").css("width","173px");
        $(".mat-input-element").css("margin-bottom","4px");
        $(".mat-input-element").css("margin-left","21px");
        $(".mat-input-element").css("text-indent","10px");
        $(".mat-input-element").css("background-color","#d3d0d0");
        $(".mat-input-infix input").css("height","30px");
        $(".mat-input-infix input").css("width","173px");
        $(".mat-input-infix input").css("margin-bottom","2.8px");
        $(".mat-input-infix input").css("background-color","#d3d0d0");
        $(".mat-form-field-infix").css("border-top","0");
        $(".mat-datepicker-toggle button").removeClass("mat-icon-button").addClass("btn btn-outline-secondary calendar");
        $(".mat-datepicker-toggle button span").remove();
        $(".mat-datepicker-toggle button div").remove();
        $(".mat-datepicker-toggle button").removeAttr("mat-icon-button");
        $(".mat-form-field-infix").css("width","0px");
        $(".mat-datepicker-toggle button").css("width","50px");
        $(".mat-datepicker-toggle button").css("height","32px");
        $(".mat-datepicker-toggle button").css("margin-left","0px");
        $(".mat-datepicker-toggle button").css("margin-bottom","-4px");
        $(".mat-datepicker-toggle button").css("background-image","url(/assets/calendar.png)");
        $(".mat-datepicker-toggle button").css("background-repeat","no-repeat");
        $(".mat-datepicker-toggle button").css("background-size","23px");
        $(".mat-datepicker-toggle button").css("background-position","center");
        $(".mat-input-infix input").css("padding-left","16px");
  //end of matDatepicker Mod

  this.staticContentService.inContentIconAppend();
}

  /*****************************************************************************************
    * Method Name: onTabChange                                                                 
    * Description: Sets timeout for incontenticon method when a tab is selected.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/26/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
onTabChange() {
  try {
    setTimeout(()=>{ this.inContentIcon() }, 500);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "onTabChange");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
navToReportsPage(event) {
  try {
    let href = event.target.href;
    let id: number;
    let reportId: object;
    let srcElement: HTMLImageElement = event.srcElement;

    if(srcElement.src != undefined){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
    }else if(href != undefined) {
      if(href.indexOf("reportid") != -1) {
        id = href.substring(href.lastIndexOf("=") + 1);
        reportId = {'ReportTypeId': id};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
        window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
      }else if(href.indexOf("fileName=") != -1){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
     }
    }

  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToReportsPage");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navtoSpecificReportsPage                                                                 
  * Description: Navigate to specific report
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/05/19             KMN                                   Bug 332329 fix 
  *****************************************************************************************/
navtoSpecificReportsPage(id){
  let reportId: object;

  reportId = {'ReportTypeId': id};
  sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
  window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Esppdetails") != -1 && this.user != null) {
                webPage = "Esppdetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  setJQuery(){
    setTimeout(()=>{ this.inContentIcon() }, 500);
  }


  getGlobalID() {
    try {
      this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
        try {
          this.globalIDs = data[0];

          if (this.globalIDs.length == 0) {
            this.phSBGlobalID = true;
            this.globalIDNum1 = ""         
          } else if(this.globalIDs.length == 1){
            this.phSBGlobalID = true;
            if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9) { 
                  this.globalIDNum1 = "Your SSN";
                } else {
                  this.globalIDNum1 = this.globalIDs[0].ParticipantNbr; 
                }
          }
          else{
            if (this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9) {
              this.phSBGlobalID = true;
              this.globalIDNum1 = "Your SSN"
            } else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) == 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) == 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else{
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) " + this.globalIDs[0].ParticipantNbr;
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
  
          }
  
          // if(this.globalIDs.length == 0) {
          //   this.phSBGlobalID = true;
          //   this.globalIDNum1 = "";
          // } else if(this.globalIDs.length == 1) {
          //   this.phSBGlobalID = true;
    
          //   if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
          //     this.globalIDNum1 = "Your SSN";
          //   } else {
          //     this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
          //   }
          // } 

        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
      error.message += errorLocation;
      throw error;
    }
   }
   checkBrokersInfo(broker){
      event.stopPropagation();
     

      if(this.ubsLoaded == true)
        this.resourceLoading = false;
      else
        this.resourceLoading = true;
      
      if(this.sbLoaded == true)
        this.resourceLoading = false;
      else
        this.resourceLoading = true;
      
      if(this.bigLoaded == true)
        this.resourceLoading = false;
      else
        this.resourceLoading = true;
      
      if(this.ncbLoaded == true)
        this.resourceLoading = false;
      else
        this.resourceLoading = true;
      

      
      this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        let brokerAccounts = data[0];
        if(brokerAccounts.length > 0) {

          for(let i = 0; i < brokerAccounts.length; i++) {
    if(broker == "MS"){
      (<any>$("#brokerContact")).modal({
        backdrop: 'static',
        keyboard: false
      });

      this.brokerHeader = "Morgan Stanley";
      
      this.getGlobalID();

      this.displayPersonalUbs = false;
      this.displayPersonalBig = false;
      this.displayPersonalNcb = false;

      this.bodyData[5] = PageSection.sb;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
        
          let msData = JSON.parse(data.body);

          let msBody;

          if(msData.data) { // length > {}
          msBody = msData.data;

            if (msBody.length > 0){
              this.sbDesc = msBody[0].Body_Desc;
              this.displayPersonalSb = true;
              }
            else {
              this.displayPersonalSb = false;
              this.sbDesc = "";
            } 
          }


        this.bodyData[5] = PageSection.sbDoc;
        this.bodyData[6] = 0;

        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        let msDocData = JSON.parse(data.body);

        let msDocBody;
        
        if(msDocData.data) { // length > {}
                        msDocBody = msDocData.data;
                        if(msDocBody.length > 0) {
                          this.sbDoc = msDocBody;
        
                          for(let i=0; i<this.sbDoc.length; i++){
                            this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                          }
                        }
                        else{
                          this.sbDoc = null;
                        }
                      }
                  
        });
        this.sbLoaded = true;
    this.resourceLoading = false;
      });

    }else if(broker == "UBS"){
      (<any>$("#brokerContact")).modal({
        backdrop: 'static',
        keyboard: false
      });
      

      this.brokerHeader = "UBS Financial Services Inc";
      this.displayPersonalSb = false;
      this.displayPersonalBig = false;
      this.displayPersonalNcb = false;

    this.ubsAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.ubs;
    this.bodyData[6] = 1;
    

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      let ubsData = JSON.parse(data.body);

      let ubsBody;

      if(ubsData.data) { // length > {}
      ubsBody = ubsData.data;
      if(ubsBody.length > 0) {
        this.ubsDesc = ubsBody[0].Body_Desc;
        this.displayPersonalUbs = true;
      }
      else {
      this.displayPersonalUbs = false;
        this.ubsDesc = "";
      }
    }

    this.bodyData[5] = PageSection.ubsDoc;
    this.bodyData[6] = 0;
    
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

      let ubsDocData = JSON.parse(data.body);

      let ubsDocBody;
      
      if(ubsDocData.data) { // length > {}
          ubsDocBody = ubsDocData.data;
          if(ubsDocBody.length > 0){
            this.ubsDoc = ubsDocBody;
    
            for(let i=0; i<this.ubsDoc.length; i++){
              this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
            }
          }
          else{
            this.ubsDoc = null;
          }
        }
    });
    this.ubsLoaded = true;
    this.resourceLoading = false;
    });
    }else if(broker == "BIG"){
      (<any>$("#brokerContact")).modal({
        backdrop: 'static',
        keyboard: false
      });
      

      this.brokerHeader = "Banco de Investimento Global";

      this.displayPersonalSb = false;
      this.displayPersonalUbs = false;
      
      this.displayPersonalNcb = false;

      this.bigAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.big;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      
        let bigData = JSON.parse(data.body);

        let bigBody;

        if(bigData.data) { // length > {}
        bigBody = bigData.data;
        if(bigBody.length > 0){
          this.bigDesc = bigBody[0].Body_Desc;
          this.displayPersonalBig = false;
        }else {
          this.displayPersonalBig = false;
          this.bigDesc = "";
        }
      }
      this.bigLoaded = true;
    this.resourceLoading = false;
      });

    }else if(broker == "TA"){
      (<any>$("#brokerContact")).modal({
        backdrop: 'static',
        keyboard: false
      });
      

      this.brokerHeader = "Computershare (formerly National City Bank)";
      this.displayPersonalSb = false;
      this.displayPersonalUbs = false;
      this.displayPersonalBig = false;
      

      this.ncbAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.ncb;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

        let taData = JSON.parse(data.body);

        let taBody;

        if(taData.data) { // length > {}
        taBody = taData.data;
        if(taBody.length > 0){
        this.ncbDesc = taBody[0].Body_Desc;
        this.displayPersonalNcb = true;
        }else {
          this.displayPersonalNcb = false;
          this.ncbDesc = "";
        }
      } 
      
      this.bodyData[5] = PageSection.ncbDoc;
      this.bodyData[6] = 0;         

         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

          let taDocData = JSON.parse(data.body);

          let taDocBody;

          if(taDocData.data) { // length > {}
          taDocBody = taDocData.data;
          if(taDocBody.length > 0){
            this.ncbDoc = taDocBody;

            for(let i=0; i<this.ncbDoc.length; i++){
              this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
            }
          }else {
            this.displayPersonalNcb = false;
            this.ncbDoc = null;
          }
        }
         });
         this.ncbLoaded = true;
         this.resourceLoading = false;
      });

    }else if(broker.indexOf('/')){
      this.brokerHeader = broker;
      let splittedBroker = broker.split('/');
      for(let i = 0; i < splittedBroker.length; i++){
        if(splittedBroker[i] == "MS"){
          this.getGlobalID();
          this.bodyData[5] = PageSection.sb;
          this.bodyData[6] = 1;
          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
            
              let msData = JSON.parse(data.body);

              let msBody;
    
              if(msData.data) { // length > {}
              msBody = msData.data;
    
                if (msBody.length > 0){
                  this.sbDesc = msBody[0].Body_Desc;
                  this.displayPersonalSb = true;
                  }
                else {
                  this.displayPersonalSb = false;
                  this.sbDesc = "";
                } 
              }
    
    
            this.bodyData[5] = PageSection.sbDoc;
            this.bodyData[6] = 0;
    
            this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
            let msDocData = JSON.parse(data.body);

            let msDocBody;
            
            if(msDocData.data) { // length > {}
                            msDocBody = msDocData.data;
                            if(msDocBody.length > 0) {
                              this.sbDoc = msDocBody;
            
                              for(let i=0; i<this.sbDoc.length; i++){
                                this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                              }
                            }
                            else{
                              this.sbDoc = null;
                            }
                          }
                      
            });
            this.sbLoaded = true;
        this.resourceLoading = false;
          });
    
        }else if(splittedBroker[i] == "UBS"){
          
        this.ubsAccount = brokerAccounts[i].Account;
        this.bodyData[5] = PageSection.ubs;
        this.bodyData[6] = 1;
        
    
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          let ubsData = JSON.parse(data.body);

          let ubsBody;
    
          if(ubsData.data) { // length > {}
          ubsBody = ubsData.data;
          if(ubsBody.length > 0) {
            this.ubsDesc = ubsBody[0].Body_Desc;
            this.displayPersonalUbs = true;
          }
          else {
          this.displayPersonalUbs = false;
            this.ubsDesc = "";
          }
        }
    
        this.bodyData[5] = PageSection.ubsDoc;
        this.bodyData[6] = 0;
        
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    
          let ubsDocData = JSON.parse(data.body);

          let ubsDocBody;
          
          if(ubsDocData.data) { // length > {}
              ubsDocBody = ubsDocData.data;
              if(ubsDocBody.length > 0){
                this.ubsDoc = ubsDocBody;
        
                for(let i=0; i<this.ubsDoc.length; i++){
                  this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
                }
              }
              else{
                this.ubsDoc = null;
              }
            }
        });
        this.ubsLoaded = true;
        this.resourceLoading = false;
        });
        }else if(splittedBroker[i] == "BIG"){
          
          this.bigAccount = brokerAccounts[i].Account;
          this.bodyData[5] = PageSection.big;
          this.bodyData[6] = 1;
          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          
            let bigData = JSON.parse(data.body);

            let bigBody;
    
            if(bigData.data) { // length > {}
            bigBody = bigData.data;
            if(bigBody.length > 0){
              this.bigDesc = bigBody[0].Body_Desc;
              this.displayPersonalBig = false;
            }else {
              this.displayPersonalBig = false;
              this.bigDesc = "";
            }
          }
          this.bigLoaded = true;
        this.resourceLoading = false;
          });
    
        }else if(splittedBroker[i] == "TA"){
         
          this.displayPersonalSb = false;
          this.displayPersonalUbs = false;
          this.displayPersonalBig = false;
          
    
          this.ncbAccount = brokerAccounts[i].Account;
          this.bodyData[5] = PageSection.ncb;
          this.bodyData[6] = 1;
          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    
            let taData = JSON.parse(data.body);

            let taBody;
    
            if(taData.data) { // length > {}
            taBody = taData.data;
            if(taBody.length > 0){
            this.ncbDesc = taBody[0].Body_Desc;
            this.displayPersonalNcb = true;
            }else {
              this.displayPersonalNcb = false;
              this.ncbDesc = "";
            }
          } 
          
          this.bodyData[5] = PageSection.ncbDoc;
          this.bodyData[6] = 0;         
    
             this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
    
              let taDocData = JSON.parse(data.body);

              let taDocBody;
    
              if(taDocData.data) { // length > {}
              taDocBody = taDocData.data;
              if(taDocBody.length > 0){
                this.ncbDoc = taDocBody;
    
                for(let i=0; i<this.ncbDoc.length; i++){
                  this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
                }
              }else {
                this.displayPersonalNcb = false;
                this.ncbDoc = null;
              }
            }
             });
             this.ncbLoaded = true;
             this.resourceLoading = false;
          });
      }
    }
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    
    }
  }
  }

  });


      


    }

    fileImage(filyType){
      if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
        this.fileTypeImagePath = '/assets/pdf.svg';
        this.fileTypeAlt = "PDF File"
        return true;
      }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
        this.fileTypeImagePath = '/assets/zip.svg';
        this.fileTypeAlt = "ZIP File"
        return true;
      }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
        this.fileTypeImagePath = '/assets/xls.svg';
        this.fileTypeAlt = "XLS File"
        return true;
      }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
        this.fileTypeImagePath = '/assets/docx.svg';
        this.fileTypeAlt = "DOC File"
        return true;
      }
     }

  /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
  collapseAccordion(index) {
    let className = document.getElementById("collapseCQIndex" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.esppCommonQuestionsContent.length; i++) {
        document.getElementById("collapseCQIndex" + i).className = "collapse";
      }
    }
   }

}

