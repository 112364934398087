import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import { PartnerselectedComponent } from '../partnerselected.component';
import { Globals } from '../../../../../globals';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';




@Component({
  selector: 'app-legaldocuments',
  templateUrl: './legaldocuments.component.html',
  styleUrls: ['./legaldocuments.component.css']
})
export class LegaldocumentsComponent implements OnInit {
  
  tabSelected: boolean = true;
  switchBool: boolean = true;
  heightSwitch: boolean = true;
  dataSwitch: boolean = false;

  documentType: any;
  optNum: any;
  documentId: any //number;

  corporateDocumentReceived: any = [];
  corporateDocumentAdded: any = [];
  partnershipDocumentReceived: any = [];
  partnershipDocumentAdded: any = [];
  
  fileStatus: any = [];
  cvStatus: any = [];
  cvClass: any = [];
  personnelNotes: any;
  cvdocumentName: any = [];
  cvdocumentAdded: any = [];
  cvLoanedTo: any;
  cvLoanedMonth: any;
  cvLoanedDay: any;
  cvLoanedYear: any;
  cvDate: any;
  cvClassDefault: any;
  cvStatusDefault: any;
  fileStatusDefault: any;

  roleId: any;
  secCheck: any;
  editPartner: boolean = true;
  loading: boolean = true;
  isError: boolean = false;

  

  constructor(
    private apiservice: ApiService,
    private router: Router,
    private global: Globals,
    private partnerSelected: PartnerselectedComponent,
    private access: SemaccessService

  ) { }

  ngOnInit() {
    this.optNum = sessionStorage.getItem('optNum');
    this.documentId = parseInt(sessionStorage.getItem(this.global.documentID) || '{}');
    this.getAccess();
    this.partnerSelected.loadingEdit = true;
  }

  /**********************************************************************************
  * Method Name: getAccess                                                                 
  * Description: Retrieves access from a reasuable service 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         5/19/2020            Jikho Enrique                    Initial draft 
  ***********************************************************************************/
  async getAccess() {
    try {
      await this.access.getSemAccess("legaldocuments").then();
      if (this.access.role != false) {
        this.partnerSelected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerSelected.editHistory(this.documentId, this.optNum);
          this.partnerSelected.partnerSelectedInfo(this.optNum);
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            this.roleId = this.access.role['data'][8][0]['ROLE'];
            await this.access.getDocumentAccess("legaldocuments");
            if (this.access.document != false) {
              if (this.access.document['data'][0][0] != null && this.access.document['data'][0][0] != undefined) {
                this.secCheck = this.access.document['data'][0][0];
                this.getLegalDocuments();
              } else {
                this.router.navigate(['equityadmin/eamain'])
              }
            } else {
              this.router.navigate(['equityadmin/eamain']);
            }
          } else {
            this.router.navigate(['equityadmin/eamain'])
          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } else {
        this.router.navigate(['equityadmin/eamain']);
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
     error.message += this.apiservice.setErrorContents('getAccess', 'legaldocuments');
      throw error;
    }
  }
 
/************************************************************************************
  * Method Name: viewChange                                                           
  * Description: To change the content of the document
  *                                                                                          
  * #         Date                 Modified by                        Description                             
  * 1         04/22/2020           Joshua Paul E. Peralta             Change content
***********************************************************************************/ 
 viewChange(document: string):void{
   try {
     this.documentType = document;
     if (this.documentType == 'Corporate/Personnel Documents') {
       this.switchBool = true;
       this.tabSelected = true; //trigger active tab 
       this.heightSwitch = true;
     } else if (this.documentType == 'CV/File Documents') {
       this.switchBool = false; //change selected tab
       this.tabSelected = false;
       this.heightSwitch = false;
     } else {
       this.switchBool = true;
       this.tabSelected = true;
       this.heightSwitch = true;
     }
   } catch (error) {
    this.loading = false;
    this.isError = true;
    error.message += this.apiservice.setErrorContents('viewChange', 'legaldocuments');
     throw error;
   }
  
 }

 /************************************************************************************
  * Method Name: getLegalDocuments                                                           
  * Description: To change the content of the document
  *                                                                                          
  * #         Date                 Modified by                        Description                             
  * 1         04/22/2020           Joshua Paul E. Peralta             API connection
**************************************************************************************/ 
getLegalDocuments(){
    let accessDocuments: any = {
      "functionName": "getLegalDocuments",
      "selected_optnum": this.optNum
    }

    this.apiservice.postData(this.global.api_URL_pdsDocument, accessDocuments).subscribe(data => {
      try{
        if(data){
          let contentBody = data.body;

          if (contentBody != undefined) {
            for (let index = 0; index < contentBody['data'].length; index++) {
              if (index == 0) {
                if (this.roleId != 1) {
                  for (let index2 = 0; index2 < contentBody['data'][0].length; index2++) {
                    this.corporateDocumentReceived.push(contentBody['data'][0][index2]['CORPORATE_ADDED']);
                  }
                } else if (this.roleId == 1) {
                  for (let index2 = 0; index2 < contentBody['data'][0].length; index2++) {
                    this.corporateDocumentReceived.push(contentBody['data'][0][index2]['CORPORATE_RECEIVED']);
                  }
                }
              } else if (index == 1) {
                if (this.roleId != 1) {
                  for (let index3 = 0; index3 < contentBody['data'][1].length; index3++) {
                    this.partnershipDocumentReceived.push(contentBody['data'][1][index3]['PARTNERSHIP_ADDED']);
                  }
                } else if (this.roleId == 1) {
                  for (let index3 = 0; index3 < contentBody['data'][1].length; index3++) {
                    this.partnershipDocumentReceived.push(contentBody['data'][1][index3]['PARTNERSHIP_RECEIVED']);
                  }
                }
              } else if (index == 2) {
                for (let index4 = 0; index4 < contentBody['data'][2].length; index4++) {
                  this.corporateDocumentAdded.push(contentBody['data'][2][index4]['CORPORATE_ADDED']);
                }
              } else if (index == 3) {
                for (let index5 = 0; index5 < contentBody['data'][3].length; index5++) {
                  this.partnershipDocumentAdded.push(contentBody['data'][3][index5]['PARTNERSHIP_ADDED']);
                }
              }
            }
          }
      
          this.getCVFiles();    
          }
      }catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiservice.setErrorContents('getLegalDocuments', 'legaldocuments');
      throw error;
      }
    }, error => {
      
      this.loading = false;
      this.isError = true;
     error.error.message +=this.apiservice.setErrorContents('getLegalDocuments', 'legaldocuments');
      throw error;
      
    });

}
/************************************************************************************
  * Method Name: getCVFiles                                                        
  * Description: To change the content of the document
  *                                                                                          
  * #         Date                 Modified by                        Description                             
  * 1         04/24/2020           Joshua Paul E. Peralta             API connection
  * 2         05/04/2020           Joshua Paul E. Peralta             Applied changes from BE
**************************************************************************************/ 
getCVFiles(){
    let accessFiles : any = {
      "functionName": "getCVFiles",
      "selected_optnum": this.optNum
    }

    this.apiservice.postData(this.global.api_URL_pdsDocument, accessFiles).subscribe(data=>{
      try{
        if(data){
          let contentBody = data.body;
          if (contentBody != undefined) {
            for (let count = 0; count < contentBody['data'].length; count++) {
              if (count == 0) {
                if (this.roleId != 1) {
                  if (contentBody['data'][count][0] == null) { //check if data at count =  0 is null
                    this.cvClassDefault = null;
                  } else {
                    this.cvClassDefault = contentBody['data'][count][0]['CV_CLASS_DEFAULT'];
                  }
                } else if (this.roleId == 1) {
                  if (contentBody['data'][count][0] == null) { //check if data at count =  0 is null
                    this.cvClassDefault = null;
                  } else {
                    this.cvClassDefault = contentBody['data'][count][0]['CV_CLASS_DEFAULT'];
                  }
                }
              } else if (count == 1) {
                if (this.roleId != 1) {
                  if (contentBody['data'][count][0] == null) { //check if data at count = 1 is null
                    this.cvStatusDefault = null;
                  } else {
                    this.cvStatusDefault = contentBody['data'][count][0]['CV_STATUS_DEFAULT'];
                  }
                } else if (this.roleId == 1) {
                  if (contentBody['data'][count][0] == null) { //check if data at count = 1 is null
                    this.cvStatusDefault = null;
                  } else {
                    this.cvStatusDefault = contentBody['data'][count][0]['CV_STATUS_DEFAULT'];
                  }
                }
              } else if (count == 2) {
                for (let count2 = 0; count2 < contentBody['data'][2].length; count2++) {
                  this.fileStatus.push(contentBody['data'][count][count2]['FILE_STATUS']);
                }
              } else if (count == 3) {
                for (let count3 = 0; count3 < contentBody['data'][3].length; count3++) {
                  this.cvStatus.push(contentBody['data'][count][count3]['CV_STATUS']);
                }
              } else if (count == 4) {
                for (let count4 = 0; count4 < contentBody['data'][4].length; count4++) {
                  this.cvClass.push(contentBody['data'][count][count4]['CV_CLASS']);
                }
              } else if (count == 5) {
                if (contentBody['data'][count].length == 0) {
                  this.fileStatusDefault = ""
                  this.personnelNotes = ""
                  this.cvLoanedTo = ""
                  this.cvLoanedMonth = ""
                  this.cvLoanedDay = ""
                  this.cvLoanedYear = ""
                  this.cvDate = ""
                } else {
                  this.fileStatusDefault = contentBody['data'][count][0]['FILE_STATUS'];
                  this.personnelNotes = contentBody['data'][count][0]['Notes'];
                  this.cvLoanedTo = contentBody['data'][count][0]['Loaned_To'];
                  if (contentBody['data'][count][0]['Loaned_Month'] == null && contentBody['data'][count][0]['Loaned_Day'] == null && contentBody['data'][count][0]['Loaned_Year'] == null) {
                    this.cvDate = ""
                  } else {
                    this.cvLoanedMonth = contentBody['data'][count][0]['Loaned_Month'];
                    this.cvLoanedDay = contentBody['data'][count][0]['Loaned_Day'];
                    this.cvLoanedYear = contentBody['data'][count][0]['Loaned_Year'];
                    this.cvDate = this.cvLoanedMonth + '/' + this.cvLoanedDay + '/' + this.cvLoanedYear;
                  }
                }
              } else if (count == 6) {
                if (this.roleId != 1) {
                  for (let count5 = 0; count5 < contentBody['data'][6].length; count5++) {
                    this.cvdocumentName.push(contentBody['data'][6][count5]['DOCUMENT_RIGHT']);
                  }
                } else if (this.roleId == 1) {
                  for (let count5 = 0; count5 < contentBody['data'][6].length; count5++) {
                    this.cvdocumentName.push(contentBody['data'][6][count5]['DOCUMENT_RIGHT']);
                  }
                }
              } else if (count == 7) {
                for (let count6 = 0; count6 < contentBody['data'][7].length; count6++) {
                  this.cvdocumentAdded.push(contentBody['data'][7][count6]['DOCUMENT_LEFT']);
                }
              }
            }
          }

          if (this.roleId != 1) {
            if (this.secCheck != 'E' || this.secCheck != 'e') { //This condition check for the role and access of the user.
              this.editPartner = false;
              this.dataSwitch = true;                       //when editPartner is false all buttons in the document are not visible
            }
          }

          this.loading = false;
          this.partnerSelected.loadingEdit = false;
          this.global.searchLoading = false;


        } 
      } catch(error){
        this.loading = false;
        this.isError = true;
        error.message += this.apiservice.setErrorContents('getCVFiles', 'legaldocuments');
        throw error;
      }
      }, error => {
        
        this.loading = false;
        this.isError = true;
        error.error.message += this.apiservice.setErrorContents('getCVFiles', 'legaldocuments');
        throw error;
        
      });

} 
disableInput(){
  event.preventDefault();
}



}

