
import {throwError as observableThrowError,  Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

import "rxjs";
// import { Headers, RequestOptions, Http } from '@angular/http';
import { Router } from "@angular/router";

import { staticContentModel } from "./models/staticContentModel";
import { profileInfoModel } from "./models/profileInfoModel";
import { map, catchError, retry } from 'rxjs/operators';
import { BrokerType, PageSection } from './enumerations/enumerations';
import { MsalService } from '@azure/msal-angular';

@Injectable()

export class StaticContentService {
    private navStateSource = new Subject<boolean>();
    navState$ = this.navStateSource.asObservable();
    private navLoginStateSource = new Subject<boolean>();
    navLoginState$ = this.navLoginStateSource.asObservable();
    private navHeaderStateSource = new Subject<boolean>();
    navHeaderState$ = this.navHeaderStateSource.asObservable();
    token: string = sessionStorage.getItem("msal.idtoken");
    headers: Headers;
    // options: RequestOptions;
    errorLocation: string = " + [Component: Homepage, Method: methodName]";
    bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
    listContents: any[];
    sbDoc: any[];

    constructor(private msalService: MsalService, private httpClient: HttpClient, private globalVariables: Globals, private router: Router){

    }

    relogUser(){
      this.clearAndReload();
      this.router.navigate(['landingpage']);
    }

    clearAndReload(){
      window.location.reload(); 
      sessionStorage.clear();
      localStorage.clear();
    }

    setNavBarState( state: boolean ) {
        this.navStateSource.next( state );
      }

    setNavLoginState( state: boolean){
        this.navLoginStateSource.next( state );
      }

    setNavHeaderState( state: boolean){
      this.navHeaderStateSource.next( state );
    }

    getBluePageStaticContent(name: string): Observable<staticContentModel[]>{
        let apiURL = this.globalVariables.currentAPIUrl;

        const api = apiURL + this.globalVariables.staticContentAPI + name.replace(/\s/g, "%20");
        try{
            return this.httpClient.get<staticContentModel[]>(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res)));
        }catch(err){
            return err; 
        }
    }    

    getInAppNotifContent(): any{
        let apiURL = this.globalVariables.currentAPIUrl;
        let inAppAPI = apiURL + this.globalVariables.getNotificationAPI;
        try{
            const api = inAppAPI; 
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res))); 
        }catch(err){
            return err;
        }
    }

    getProfilePage(optNum): Observable<profileInfoModel[]>{
        try{
            const api = '' + optNum;
            return this.httpClient.get<profileInfoModel[]>(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res)));
        }catch(err){
            return err;
        }
    }

    
getBellGrantAPI(OptNum): any {
    let apiUrl = this.globalVariables.currentAPIUrl;
    let BellGrantActionItemUrl = this.globalVariables.getAlertfromBellAPI;
  
    BellGrantActionItemUrl = BellGrantActionItemUrl.replace("[param1]", OptNum);
    
    try {
      const api = apiUrl + BellGrantActionItemUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  // country specific tax service

  getCountryList(): any {
    let apiUrl = this.globalVariables.currentAPIUrl;
    let countryList = this.globalVariables.getCountryList;
    
    try {
      const api = apiUrl + countryList;

      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getCountrySpecificTaxFile(country): any {
    let apiUrl = this.globalVariables.currentAPIUrl;
    let countryFile = this.globalVariables.getCountryFile;
  
    countryFile = countryFile.replace("[param1]", country);
    
    try {
      const api = apiUrl + countryFile;
      this.headers = new Headers({ 'Authorization': `Bearer ${this.token}`});
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  
  getFileFromNewAPI(fileName){
    let apiURL = this.globalVariables.currentAPIUrl;
    var myRData = {
      "fileName": fileName,
      // "filePath": filePath, // MD or GA
      "fileAction": "Download"
    };

    try {
      const apiLogin = apiURL + this.globalVariables.manageFile;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`}); // with token
     
      let options = ({ headers : this.headers});
      let body = myRData;

      return this.httpClient.post(apiLogin, body).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    }catch(err) {
      return err;
    }
  }

  // end of country specific tax service

  getMyRDataNoToken(pageSection){
    let apiURL = this.globalVariables.myResourcesURL;
    
    var myRData = {
        "countryName": "",
        "empStatus": "",
        "empType": "",
        "enrollStatus": "",
        "grantLongDesc": "No Grants",
        "pageSection": pageSection,
        "resourcesPage": 1
      };

    try {
      const apiLogin = apiURL + "/sql/getstaticlistfrommyresources";
      // this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`}); // with token
      this.headers = new Headers({ 'Content-Type' : 'application/json'});
      let options = ({ headers : this.headers});
      let body = JSON.stringify(myRData);

      return this.httpClient.post(apiLogin, body).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    }catch(err) {
      return err;
    }
  }

  getResourcesDataFromMyR(bodyData){
    let apiURL = this.globalVariables.myResourcesURL;
    let pRec = bodyData;
    var myRData = {
        "countryName": pRec[0],
        "empStatus": pRec[1],
        "empType": pRec[2],
        "enrollStatus": pRec[3],
        "grantLongDesc": pRec[4],
        "pageSection": pRec[5],
        "resourcesPage": pRec[6]
    };

    try {
      const apiLogin = apiURL + "/sql/getlistfrommyresources";
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`}); // with token
     
      let options = ({ headers : this.headers});
      let body = myRData;

      return this.httpClient.post(apiLogin, body).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    }catch(err) {
      return err;
    }
  }  

  getmyRContentPerBroker(broker): any{
    let listContents = [];
    broker = "MS";
    if(broker == "MS"){
      this.bodyData[5] = PageSection.sb;
      this.bodyData[6] = 1;

      return this.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
        let msData = JSON.parse(data._body);
          let msBody;

          if(msData.body.length > 2) { // length > {}
          msBody = JSON.parse(msData.body);

            if (msBody.data.length > 0){
              listContents.push(msBody.data[0].Body_Desc);
              listContents.push(true);
            }else {
              listContents.push("");
              listContents.push(false);
            } 
          }

          this.bodyData[5] = PageSection.sbDoc;
          this.bodyData[6] = 0;

          this.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
            let msDocData = JSON.parse(data._body);
            let msDocBody;

            if(msDocData.body.length > 2) {
              msDocBody = JSON.parse(msDocData.body);

              if(msDocBody.data.length > 0) {
                this.sbDoc = msDocBody.data;

                for(let i=0; i<this.sbDoc.length; i++){
                  listContents.push(this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type)
                }
              }else{
                listContents.push(""); // No SB documents
              }
            }

            return listContents;
          });
      });

      
    }

  }

  getFile(fileName, filePath) {
    let apiURL = this.globalVariables.currentAPIUrl;
    var myRData = {
      "fileName": fileName,
      "filePath": filePath, // MD or GA
      "fileAction": "Download"
    };

    try {
      const apiLogin = apiURL + this.globalVariables.manageFile;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`}); // with token
      let options = ({ headers : this.headers});
      let body = myRData;

      return this.httpClient.post(apiLogin, body).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    }catch(err) {
      return err;
    }
  }

  /*********************************************************************************************
    Method Name: writeLog
    Description: Writes a log when file is downloaded.

    #     Date            Modified by             Description
    1     07/16/19        Dalemark P. SUquib      Initial Draft
    **********************************************************************************************/
  writeLog(fileName) {
    let apiURL = this.globalVariables.currentAPIUrl;
    var responseLog = {
      page: 99,
      Description: "Download in myHoldings " + fileName,
      ActionTypeId: 5,
      PageId: 9,
    };

    try {
      const writeLogUrl = apiURL + this.globalVariables.managePageData;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`}); // with token
      let options = ({ headers : this.headers});

      return this.httpClient.post(writeLogUrl, responseLog).pipe(map(res => res),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      throw err;
    }
  }

   /*********************************************************************************************
    Method Name: base64ToArrayBuffer
    Description: Converts base 64 to array buffer.

    #     Date            Modified by             Description
    1     04/09/19        Dalemark P. SUquib      Initial Draft
    **********************************************************************************************/
  base64ToArrayBuffer(base64): Uint8Array {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);
    
    for(let i = 0; i < binaryLength; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  /*********************************************************************************************
    Method Name: downloadFile
    Description: Downloads the file.

    #     Date            Modified by             Description
    1     04/09/19        Dalemark P. SUquib      Initial Draft
    **********************************************************************************************/
  downloadFile(reportName, fileType, byte) {
    // if(navigator.appVersion.toString().indexOf('.NET') > 0) { // browser is IE
    //   let blob = new Blob([byte], {type: fileType});
    //   window.navigator.msSaveOrOpenBlob(blob, reportName);
    if(navigator.userAgent.indexOf("Firefox") > 0) { // browser is FireFox
      let a = document.createElement('a');
      a.style.display = "none";
      a.style.zIndex = "1002";
      a.style.position = "absolute";
      let blob = new Blob([byte], {type: fileType});
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = reportName;
      document.body.appendChild(a);
      a.click();

      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
    } else { // browser is Chrome
      let blob = new Blob([byte], {type: fileType});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let fileName = reportName;
      link.download = fileName;
      link.click();
    }
  }

  noFileExists(fileName){
    alert(fileName + " does not exist. Please contact Technology Support Service Desk for assistance");
  }
    
  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      if(statusCode == 401){
        //this.router.navigate(['homepage']);
        this.relogUser();
      }
      return observableThrowError(error);
  }
  
  inContentIconAppend(){    
    $('.addedIcon').remove();
    $('<img class="addedIcon" src="/assets/pdf.svg" style="width: 16px; height: 16px;" alt="PDF file">').prependTo('[href$=".pdf"]');
    $('<img class="addedIcon" src="/assets/zip.svg" style="width: 16px; height: 16px;" alt="ZIP file">').prependTo('[href$=".zip"]');
    $('<img class="addedIcon" src="/assets/xls.svg" style="width: 16px; height: 16px;" alt="Excel file">').prependTo('[href$=".xls"]');
    $('<img class="addedIcon" src="/assets/xls.svg" style="width: 16px; height: 16px;" alt="Excel file">').prependTo('[href$=".xlsx"]');
    $('<img class="addedIcon" src="/assets/docx.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".doc"]');
    $('<img class="addedIcon" src="/assets/docx.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".docx"]');
    $('<img class="addedIcon" src="/assets/pdf.svg" style="width: 16px; height: 16px;" alt="pdf file">').prependTo('[href$=".PDF"]');
    $('<img class="addedIcon" src="/assets/zip.svg" style="width: 16px; height: 16px;" alt="ZIP file">').prependTo('[href$=".ZIP"]');
    $('<img class="addedIcon" src="/assets/xls.svg" style="width: 16px; height: 16px;" alt="Excel file">').prependTo('[href$=".XLS"]');
    $('<img class="addedIcon" src="/assets/docx.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".DOC"]');
    $('<img class="addedIcon" src="/assets/ppt.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".ppt"]');
    $('<img class="addedIcon" src="/assets/ppt.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".pptx"]');
    $('<img class="addedIcon" src="/assets/ppt.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".PPT"]');
    $('<img class="addedIcon" src="/assets/ppt.svg" style="width: 16px; height: 16px;" alt="DOC file">').prependTo('[href$=".PPTX"]');
  }

  checkForFileExt(href){
    try {
      return href.includes(".pdf") || href.includes(".zip") || href.includes(".xls") || href.includes(".xlsx") || href.includes(".doc") || href.includes(".docx") || href.includes(".PDF") || href.includes(".ZIP") || href.includes(".XLS") || href.includes(".DOC") || href.includes(".ppt") || href.includes(".pptx") || href.includes(".PPT") || href.includes(".PPTX");
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "isNullOrWhiteSpace");
      error.message += errorLocation;
      throw error;
    }
  }

  validateFileType(eventBody, filePath?){
        
      let href: string = eventBody.target.href;
      let parentNode: any = eventBody.target.parentNode;
      let id: string = eventBody.target.id;
      let downloadFile: boolean = false;
      let fileName: string;
      eventBody.preventDefault();
      let srcElement: HTMLImageElement = eventBody.srcElement;
      let nameProp: string = eventBody.target.parentElement.nameProp;
      let isFile: boolean = false;
      let fileUrl: string;
      let pathHref: string, pathBaseURI: string;
      let target: string = eventBody.target.target;


      //To remove temporarily as this affects download of file - KMN
      // if (href == undefined && parentNode.href != ""){
      //   href =  parentNode.href.toString();       
      //   window.open(href);        
      // }
      if(srcElement.src != undefined){
        if(srcElement.src.indexOf(".svg") != -1) {
          pathHref = eventBody.target.parentElement.href;
          pathBaseURI = eventBody.target.parentElement.baseURI;
          
          if(pathHref != undefined) {
            if(pathHref != "" && (pathHref.indexOf(".pdf") != -1 || pathHref.indexOf(".docx") != -1 || pathHref.indexOf(".doc") != -1 || pathHref.indexOf("xls") != -1 ||
            pathHref.indexOf("xlsx") != -1 || pathHref.indexOf("pptx") != -1 || pathHref.indexOf("ppt") != -1 || pathHref.indexOf(".zip") != -1 )) {
              if(pathBaseURI != undefined)  { // chrome, Safari & FIrefox
                fileName = pathHref.substring(pathBaseURI.length, pathHref.length);
              } else { // IE
                fileName = nameProp;
              }

              if(fileName.indexOf("fileName") != -1) {
                fileName = fileName.substring(fileName.indexOf("=") + 1, fileName.length);
              }
      
              if(fileName.indexOf("%20") != -1) {
                fileName = fileName.replace(/%20/gi, " ");
              }

              isFile = true;
              this.getFileDocument(fileName, filePath);
              return false;
            } 
          }
        }
      }else if(href != undefined && href != ""){
        href =  href.toString();

        if((href.indexOf("fileName") != -1) && (href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)) {
          
          fileName = href.substring(href.indexOf("=") + 1, href.length).replace(/%20/g, " ");
          isFile = true;
          this.getFileDocument(fileName, filePath);
        }else if((href.indexOf("fileName") == -1) && (href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){

          if(href.indexOf("/") != -1) {
            href = href.substring(href.lastIndexOf("/") + 1);
          }
          
          href = href.split("%20").join(" ");

          isFile = true;
          this.getFileDocument(href, filePath);
        } else if(href.toLowerCase().indexOf("resources") != -1 || href.indexOf(".com") != 1){
          window.open(href, target);
        }
      }else if(id != undefined && id != "") {
        id =  id.toString();
        if((id.indexOf("fileName") != -1) && (id.indexOf(".pdf") != -1 || id.indexOf(".doc") != -1 || id.indexOf(".docx") != -1 || id.indexOf(".xls") != -1 || id.indexOf(".pdf") != -1 || id.indexOf(".zip") != -1 || id.indexOf(".ppt") != -1 || id.indexOf(".pptx") != -1) ) {
          fileName = href.substring(id.indexOf("=") + 1, id.length).replace(/%20/g, " ");
          
          isFile = true;
          this.getFileDocument(fileName);
        }else if((id.indexOf("fileName") == -1) && (id.indexOf(".pdf") != -1 || id.indexOf(".doc") != -1 || id.indexOf(".docx") != -1 || id.indexOf(".xls") != -1 || id.indexOf(".pdf") != -1 || id.indexOf(".zip") != -1 || id.indexOf(".ppt") != -1 || id.indexOf(".pptx") != -1)){
          
          isFile = true;
          this.getFileDocument(id);
        }
      }else if(parentNode != undefined && parentNode != ""){
        parentNode =  parentNode.toString();
        parentNode = parentNode.substring(parentNode.lastIndexOf("/") + 1);
    
        if((parentNode.indexOf("fileName") != -1) && (parentNode.indexOf(".pdf") != -1 || parentNode.indexOf(".doc") != -1 || parentNode.indexOf(".docx") != -1 || parentNode.indexOf(".xls") != -1 || parentNode.indexOf(".pdf") != -1 || parentNode.indexOf(".zip") != -1 || parentNode.indexOf(".ppt") != -1 || parentNode.indexOf(".pptx") != -1) ) {
          fileName = parentNode.substring(parentNode.indexOf("=") + 1, parentNode.length).replace(/%20/g, " ");
          
          isFile = true;
          this.getFileDocument(fileName);
        }else if((parentNode.indexOf("fileName") == -1) && (parentNode.indexOf(".pdf") != -1 || parentNode.indexOf(".doc") != -1 || parentNode.indexOf(".docx") != -1 || parentNode.indexOf(".xls") != -1 || parentNode.indexOf(".pdf") != -1 || parentNode.indexOf(".zip") != -1 || parentNode.indexOf(".ppt") != -1 || parentNode.indexOf(".pptx") != -1)){
          parentNode = parentNode.split("%20").join(" ");
          
          isFile = true;
          this.getFileDocument(parentNode);
        }
      }

  }

  getFileDocument(fileName, filePath?) {
    try {
      let fileType: string;
      if(fileName.indexOf(".pdf") != -1) {
        fileType = "application/pdf";
      } else {
        fileType = "mime_type";
      }

      if(filePath == undefined) {
        filePath = "MD";

      }
  
      this.getFile(fileName, filePath).subscribe(data => {
        try {
          let content = data;
          try{
           if(content.body != null || content.body != undefined) {
              //if(content != null || content != undefined) {
              let file = JSON.parse(data.body);
              this.downloadFile(fileName, fileType, this.base64ToArrayBuffer(file.file));

              this.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
                let writeLogResponse = data;
              });
            } else {
              this.noFileExists(fileName);
            }
          }catch(error){
            this.noFileExists(fileName);
          }

        
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFile");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.message += errorLocation;
      throw error;
    }
  }

  getDividendsChart(){
    let apiUrl = this.globalVariables.currentAPIUrl;

    try {
      const apiLogin = apiUrl + this.globalVariables.getDividendsChart;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`}); // with token
     
      let options = ({ headers : this.headers});

      return this.httpClient.get(apiLogin).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    }catch(err) {
      return err;
    }
  }
  
}

