
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PerformanceawardService {

  constructor(private httpClient: HttpClient, private globalVariables: Globals) { }

  getPerformanceGrant(optNum): any{
    let apiURL = this.globalVariables.currentAPIUrl;
    let performanceGrantAPI = this.globalVariables.getperformanceGrantAPI;

    performanceGrantAPI = performanceGrantAPI.replace("[param]", optNum);
    
    try{
        const api = apiURL + performanceGrantAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
}

getTotalTargetCash(): any{
    let apiURL = this.globalVariables.currentAPIUrl;
    let totalTargetCashAPI = this.globalVariables.gettotalTargetCashAPI;

    try{
        const api = apiURL +  totalTargetCashAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
}



public onError(res: Response) {
  const statusCode = res.status;
  const body = res.body;
  const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  }


}
