import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule, GuardsCheckEnd} from '@angular/router';

// import { AuthGuard } from './services/auth/auth.guard';
import { AppComponent } from './app.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ProfilepageComponent } from './profilepage/profilepage.component';
import { HighlightspageComponent } from './highlightspage/highlightspage.component';
import { BrokersPageComponent } from './brokerspage/brokerspage.component';
import { UserinfoheaderComponent } from './userinfoheader/userinfoheader.component'; 
import { TechnologySupportComponent } from './technologysupport/technologysupport.component'; 
import { UserInfoDetailsResolve } from './services/UserInfoDetails.resolve';
import { HoldingsummarypageComponent } from './holdingsummarypage/holdingsummarypage.component';
import { HoldingprogramdetailspageEsppComponent } from './holdingprogramdetailspage-espp/holdingprogramdetailspage-espp.component';
import { HoldingprogramdetailspageVeipComponent } from './holdingprogramdetailspage-veip/holdingprogramdetailspage-veip.component';
import { AdminpageComponent } from './adminpage/adminpage.component';
import { HoldingprogramdetailspageFounderComponent } from './holdingprogramdetailspage-founder/holdingprogramdetailspage-founder.component';
import { HoldingprogramdetailspageOptionsComponent } from './holdingprogramdetailspage-options/holdingprogramdetailspage-options.component';
import { PerformanceawardpageComponent } from './performanceawardpage/performanceawardpage.component';
import { HoldingprogramdetailspageRsuComponent } from './holdingprogramdetailspage-rsu/holdingprogramdetailspage-rsu.component';
import { HoldingProgramdetailspageRsuIpoComponent } from './holdingprogramdetailspage-rsu-ipo/holdingprogramdetailspage-rsu-ipo.component';
import { KeyexecComponent } from './keyexec/keyexec.component';
import { EnrollmentwithdrawEsppComponent } from './enrollmentwithdraw-espp/enrollmentwithdraw-espp.component';
import { EnrollmentwithdrawVeipComponent } from './enrollmentwithdraw-veip/enrollmentwithdraw-veip.component';
import { EorpageComponent } from './eorpage/eorpage.component';
import { ReportsEnrollmenthistorypageComponent } from './reports-enrollmenthistorypage/reports-enrollmenthistorypage.component';
import { ReportspageComponent } from './reportspage/reportspage.component';
import { ReportsSharepricehistorypageComponent } from './reportspage/reports-sharepricehistorypage/reports-sharepricehistorypage.component';
import { MultiplegrantsComponent } from './multiplegrants/multiplegrants.component';
import { ResourcespageComponent } from './resourcespage/resourcespage.component';
import { GrantPrintLayoutComponent } from './grant-print-layout/grant-print-layout.component';
import { ContactspageComponent } from './contactspage/contactspage.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { MsalGuardService } from './services/msal/msal-guard.service'
import { EquityadminComponent } from './equityadmin/equityadmin.component';
import { EamainComponent } from './equityadmin/eamain/eamain.component';
import { EjetComponent } from './equityadmin/iea/ejet/ejet.component';
import { ImportinvoiceComponent } from './equityadmin/iea/ejet/importinvoice/importinvoice.component';
import { WeeklysummaryComponent } from './equityadmin/iea/ejet/weeklysummary/weeklysummary.component';
import { SummaryreportsComponent } from './equityadmin/iea/ejet/weeklysummary/summaryreports/summaryreports.component';
import { DataapprovalComponent } from './equityadmin/iea/ejet/weeklysummary/dataapproval/dataapproval.component';
import { SemComponent } from './equityadmin/sem/sem.component';
import { PdsaComponent } from './equityadmin/sem/pdsa/pdsa.component';
import { EquityadminGuard } from './services/ea/guard/equityadmin.guard';
import { BdrComponent } from './equityadmin/iea/bdr/bdr.component';
import { BilltoComponent } from './equityadmin/iea/bdr/billto/billto.component';
import { InvoicedescriptionComponent } from './equityadmin/iea/bdr/invoicedescription/invoicedescription.component';
import { CompanycodeComponent } from './equityadmin/iea/bdr/companycode/companycode.component';
import { AccountnumberComponent } from './equityadmin/iea/bdr/accountnumber/accountnumber.component';
import { PartnerselectedComponent } from './equityadmin/sem/pdsa/partnerselected/partnerselected.component';
import { PersonaldataComponent } from './equityadmin/sem/pdsa/partnerselected/personaldata/personaldata.component';
import { ProjectedchangesComponent } from './equityadmin/sem/pdsa/partnerselected/projectedchanges/projectedchanges.component';
import { LegaldocumentsComponent } from './equityadmin/sem/pdsa/partnerselected/legaldocuments/legaldocuments.component';
import { ReportsComponent } from './equityadmin/sem/pdsa/reports/reports.component';
import { EducationComponent } from './equityadmin/sem/pdsa/partnerselected/education/education.component';
import { UnithistoryComponent } from './equityadmin/sem/pdsa/partnerselected/unithistory/unithistory.component';
import { NotesComponent } from './equityadmin/sem/pdsa/partnerselected/notes/notes.component';
import { RiComponent } from './equityadmin/sem/pdsa/partnerselected/ri/ri.component';
import { UnitawardComponent } from './equityadmin/sem/pdsa/partnerselected/unitaward/unitaward.component';
import { ChangelogComponent } from './equityadmin/sem/pdsa/partnerselected/changelog/changelog.component';
import { MemberfirmComponent } from './equityadmin/sem/pdsa/partnerselected/memberfirm/memberfirm.component';
import { BusinessinformationComponent } from './equityadmin/sem/pdsa/partnerselected/businessinformation/businessinformation.component';
import { BrowserUtils } from '@azure/msal-browser';
import {​​​​​ MsalGuard }​​​​​ from '@azure/msal-angular';
import { CanActivateGuard } from './services/msal/msal.guard';
//import { MsalGuard } from './services/msal/msal.guard';

// export const routes: Routes = [
//     {
//         path: '',
//         pathMatch: 'full',
//         component: LandingpageComponent,
//     },
//     {
//         path: 'landingpage',
//         component: LandingpageComponent
//     },
//     {
//         path: 'homepage',
//         component: HomepageComponent,
//         // resolve: {
//         //     userDetails: UserInfoDetailsResolve 
//         // }
//     },
//     {
//         path: 'profilepage',
//         component: ProfilepageComponent
//     },
//     {
//         path: 'highlightspage',
//         component: HighlightspageComponent
//     },
//     {
//         path: 'brokerspage',
//         component: BrokersPageComponent
//     },
//     {
//         path: 'userinfoheader',
//         component: UserinfoheaderComponent,
//     },
//     {
//         path: 'technologysupport',
//         component: TechnologySupportComponent
//     },
//     {
//         path: 'holdingsummarypage',
//         component: HoldingsummarypageComponent
//     }
// ];

//  export const AppRoutingModule: ModuleWithProviders<any> = RouterModule.forRoot(<Routes>[
const routes: Routes = [
    {
        path: 'landingpage',
        component: LandingpageComponent
        //canActivate: [ AuthGuard ]
    },
    {
        path: 'homepage',
        component: HomepageComponent,
        resolve: {
            userDetails: UserInfoDetailsResolve
        },
        canActivate: [MsalGuard]
    },
    {
        path: 'profilepage',
        component: ProfilepageComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'highlightspage',
        component: HighlightspageComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'brokerspage',
        component: BrokersPageComponent
    },
    {
        path: 'userinfoheader',
        component: UserinfoheaderComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'technologysupport',
        component: TechnologySupportComponent
    },
    {
        path: 'holdingsummarypage',
        component: HoldingsummarypageComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: 'print',
                outlet: 'print',
                component: GrantPrintLayoutComponent
            }
        ]
    },
    {
        path: 'rsudetailspage',
        component: HoldingprogramdetailspageRsuComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: 'print',
                outlet: 'print',
                component: GrantPrintLayoutComponent
            }
        ]
    },
    {
        path: 'iporsudetailspage',
        component: HoldingProgramdetailspageRsuIpoComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'esppdetailspage',
        component: HoldingprogramdetailspageEsppComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'veipdetailspage',
        component: HoldingprogramdetailspageVeipComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'adminpage',
        component: AdminpageComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'founderdetailspage',
        component: HoldingprogramdetailspageFounderComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'optionsdetailspage',
        component: HoldingprogramdetailspageOptionsComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: 'print',
                outlet: 'print',
                component: GrantPrintLayoutComponent
            }
        ]
    },
    // {
    //     path: 'performanceawardpage',
    //     component: PerformanceawardpageComponent,
    //     canActivate: [ MsalGuard ]
    // },
    {
        path: 'keyexec',
        component: KeyexecComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'espptransactionpage',
        component: EnrollmentwithdrawEsppComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'veiptransactionpage',
        component: EnrollmentwithdrawVeipComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'eorpage',
        component: EorpageComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'enrollmenthistorypage',
        component: ReportsEnrollmenthistorypageComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'reportspage',
        component: ReportspageComponent,
        children: [
            {
                path: 'reports-shareprice',
                component: ReportsSharepricehistorypageComponent
            },
            {
                path: 'reports-enrollhistory',
                component: ReportsEnrollmenthistorypageComponent
            }
        ],
        canActivate: [MsalGuard]
    },
    {   
        path: 'reportspage/:reportTypeDescription', 
        component: ReportspageComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'multiplegrantspage',
        component: MultiplegrantsComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: 'print',
                outlet: 'print',
                component: GrantPrintLayoutComponent
            }
        ]
    },
    {
        path: 'resourcespage',
        component: ResourcespageComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'contactspage',
        component: ContactspageComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'equityadmin',
        component: EquityadminComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "eamain"
            },
            {
                path: "eamain",
                component: EamainComponent,
                canActivate: [EquityadminGuard]
            },
            {
                path: "ejet",
                component: EjetComponent,
                canActivate: [EquityadminGuard],
                children: [
                    {
                        path: 'importinvoice',
                        component: ImportinvoiceComponent
                    },
                    {
                        path: 'weeklysummary',
                        component: WeeklysummaryComponent,
                        children: [
                            {
                                path: 'reports',
                                component: SummaryreportsComponent
                            },
                            {
                                path: 'dataapproval',
                                component: DataapprovalComponent
                            }
                        ]
                    }
                ]
            },
            {
                path: 'sem',
                component: SemComponent,
                children: [
                    {
                        path: 'pdsa',
                        component: PdsaComponent,
                        children: [
                            {
                                path: 'partnerselected',
                                component: PartnerselectedComponent,
                                children: [
                                    {
                                        path: 'personaldata',
                                        component: PersonaldataComponent
                                    },
                                    {
                                        path: 'projectedchanges',
                                        component: ProjectedchangesComponent
                                    },
                                    {
                                        path: 'legaldocuments',
                                        component: LegaldocumentsComponent
                                    },
                                    {
                                        path: 'education',
                                        component: EducationComponent
                                    },
                                    {
                                        path: 'unithistory',
                                        component: UnithistoryComponent
                                    },
                                    {
                                        path: 'notes',
                                        component: NotesComponent
                                    },
                                    {
                                        path: 'ri',
                                        component: RiComponent
                                    },
                                    {
                                        path: 'unitaward',
                                        component: UnitawardComponent
                                    },
                                    {
                                        path: 'changelog',
                                        component: ChangelogComponent
                                    },
                                    {
                                        path: 'memberfirm',
                                        component: MemberfirmComponent
                                    },
                                    {
                                        path: 'businessinformation',
                                        component: BusinessinformationComponent
                                    }
                                ]
                            },
                            {
                                path: 'reports',
                                component: ReportsComponent
                            }
                        ]
                    }
                ]
            },
            {
                path: 'bdr',
                component: BdrComponent,
                canActivate: [EquityadminGuard],
                children: [
                    {
                        path: 'billto',
                        component: BilltoComponent
                    },
                    {
                        path: 'invoicedescription',
                        component: InvoicedescriptionComponent
                    },
                    {
                        path: 'holdingcompanycode',
                        component: CompanycodeComponent
                    },
                    {
                        path: 'accountnumber',
                        component: AccountnumberComponent
                    }
                ]
            }
        ]
    },
    {
        path: '',
        pathMatch: 'full',
        component: LandingpageComponent
    },
    { path: '**',
        component: NotfoundComponent }
    // {
    //     useHash: true,
    //     scrollPositionRestoration: "enabled",
    //     anchorScrolling: "enabled",
    //     enableTracing: false
    // }
 ];
//const initialNavigation = (!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()) || window.location.href.indexOf("logout") > 0;;
@NgModule({
    imports: [RouterModule.forRoot(routes, {
      useHash: false,
      // Don't perform initial navigation in iframes or popups, except for logout
    // initialNavigation: initialNavigation ? 'enabled' : 'disabled' // Remove this line to use Angular Universal
    })],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }

