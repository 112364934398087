import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { Globals } from '../../globals';
import { HoldingprogramdetailsRsuService } from '../HoldingProgramDetailsRSUService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { tScript } from '../../services/enumerations/enumerations';
import { LoadingService } from '../../services/LoadingService';

@Component({
  selector: 'app-rsu-details-card',
  templateUrl: './rsu-details-card.component.html',
  styleUrls: ['./rsu-details-card.component.css']
})
export class RsuDetailsCardComponent implements OnInit {

  @Input() keyExecToggleValue: string;

  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  latestMarketPrice: any = sessionStorage.getItem("latestPricefromExternalAPI");
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));

  errorDetailsCard: boolean = false;
  errorLocation: string = " + [Component: Rsudetails, Method: methodName]";

  awardName: string;
  isDivInd: string;

  shareActAcceptance: any;
  shareActForfeited: any;
  shareActGrantDate: any;
  shareActGrantDesc: any;
  shareActGrantId: any;
  shareActGrantPrice: any;
  shareActNetRemainAtBroker: any;
  shareActNextRelease: any;
  shareActNextReleaseVal: number;
  shareActReleased: any;
  shareActSharesReleasing: any;
  shareActSoldTransferred: any;
  shareActTotalAwarded: any;
  shareActUnreleased: number;
  shareActWithholdingForTaxes: any;
  shareActIsGrantAgreementValid: any;
  originalGrant_shares: any;
  originalGrant_grantValue: any;
  additional_shares: any;
  additional_grantValue: any;
  total_shares: any;
  total_grantValue: any;
  unvested_shares: any;
  vestedButUnreleased: any;
  totalUnreleased: any;
  sas_released: any;
  sasReleasedTotal_shares: any;
  sasReleasedTotal_sharesValue: any;
  sas_WithheldForTax_Shares: any;
  sas_WithheldForTax_Value: any;
  sas_WithholdingRefund_Shares: any;
  sas_WithholdingRefund_value: any;
  sas_TotalWithheld_Shares: any;
  sas_TotalWithheld_Value: any;
  shareActDisplayForfeited: boolean;
  shareActActionReq: boolean;
  shareActDisplayAgreementAcceptance: boolean;
  shareActDisplayNextRelease: boolean;
  shareActDisplaySharesReleasing: boolean;
  shareActDisplayNextReleaseVal: boolean;
  shareActAgreementTabInd: boolean;
  displayVestCard: boolean;
  displayReleaseCard: boolean;
  displayDivCard: boolean;
  noShareAct: boolean = false;
  showKeyExectoggle: boolean;
  estForfeit: boolean;
  detailsCardLoading: boolean = true;

  // Tooltip
  details_Forfeited: string = tScript.rsudetailspage_f;
  details_NetRemainingAtBroker: string = tScript.rsudetailspage_nbr;

  yearCode: any[] = [];
  planNum: any[] = [];

  maxUnreleased: number;
  awardSharesReleasing: any;
  awardUnreleased: any;
  awardReleased: any;
  awardForfeited: any;
  estPerForfeit: any;
  trendUnreleased: any;
  awardDate: any;

  SharesAttainedPSU: any;
  PSUPerf1: any;
  PSUPerf2: any;
  PSUDisplayInd: any;
  PSUGrantValue: any;

  constructor(
    private globals: Globals,
    private rsuDetailsService: HoldingprogramdetailsRsuService,
    private errorNotificationService: ErrorNotificationService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.checkErrorMessage();
    this.getProgramDetails();
  }

  getProgramDetails() {
    try {
      if(this.programDetails != null && this.programDetails != undefined) {
        this.awardName = this.programDetails.GrantLongDesc;
        this.awardName = this.programDetails.AwardName;
        this.isDivInd = this.programDetails.IsDivInd;
        this.awardSharesReleasing = this.programDetails.SharesReleasing;
        this.awardUnreleased = this.programDetails.UnreleasedUnexercisable;
        this.awardDate = this.programDetails.GrantYear;
      }
  
      this.getCodeDetails();
    } catch(error) {
      this.errorDetailsCard = true;
      this.getCodeDetails();
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: getRSUDetails                                                                 
   * Description: Get the RSU award details                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 08/06/2019           JPA                         Added if else statement for storring session to prevent api to call multiple api calls.
   * 06/08/2023           Jewel C. Caudilla           Update computation for Max/Target/Trend       
   *********************************************************************************/
  getRSUDetails() {
    try {
      if(sessionStorage.getItem("RSU Details Data") != null){
        let data = JSON.parse(sessionStorage.getItem("RSU Details Data"));

        if(Array.isArray(data) && data.length > 0) {
          let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
          sessionStorage.setItem("RSU Details Data", JSON.stringify(data));
          sessionStorage.setItem("Header RSU Details", JSON.stringify(sortedBody[0]));
          let shareAct = data[0][0];
          this.rsuDetailsService.shareAct = shareAct;
          this.shareActAcceptance = shareAct.Acceptance;
          this.shareActForfeited = shareAct.Forfeited;
          this.shareActGrantDate = shareAct.GrantDate;
          this.shareActGrantDesc = shareAct.GrantDescription;
          this.shareActGrantId = shareAct.GrantID;
          this.shareActGrantPrice = shareAct.GrantPrice;
          this.shareActNetRemainAtBroker = shareAct.NetRemainingAtBroker;
          shareAct.NextRelease != null ? this.shareActNextRelease = moment(shareAct.NextRelease).format('DD MMM YYYY') : "";
          this.shareActNextReleaseVal = shareAct.SharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
          this.shareActReleased = shareAct.Released;
          this.shareActSharesReleasing = shareAct.SharesReleasing;
          this.shareActSoldTransferred = shareAct.SoldTransferred;
          this.shareActTotalAwarded = shareAct.TotalAwarded;
          this.shareActUnreleased = Math.floor(shareAct.Unreleased);
          this.shareActWithholdingForTaxes = shareAct.WithholdingforTaxes;
          this.shareActIsGrantAgreementValid = shareAct.IsGrantAgreementValid;   
          
          let sas = data[1][0];
          this.originalGrant_shares = sas.OriginalGranted;
          this.originalGrant_grantValue = sas.OriginalGrantedValue;
          this.additional_shares = sas.AdditionalRsu;
          this.additional_grantValue = sas.AdditionalRsuValue;
          this.total_shares = sas.TotalAwarded;
          this.total_grantValue = sas.TotalAwardedValue;
          
          let sas_ur = data[2][0];
          this.unvested_shares = sas_ur.SharesUnvested;
          this.vestedButUnreleased = sas_ur.VestedButUnreleased;
          this.totalUnreleased = sas_ur.TotalUnreleased;
          
          this.sas_released = data[4];

          for(let i = 0; i <data[4].length; i++){
            this.sasReleasedTotal_shares += data[4][i].Shares;
            this.sasReleasedTotal_sharesValue += data[4][i].SharesValue;
          }

          this.sas_WithheldForTax_Shares = data[3][0].WithheldForTax;
          this.sas_WithheldForTax_Value = data[3][0].WithheldForTaxValue;
          this.sas_WithholdingRefund_Shares = data[3][0].WithHoldingRefund
          this.sas_WithholdingRefund_value = data[3][0].WithHoldingRefundValue
          this.sas_TotalWithheld_Shares = data[3][0].TotalWithheld;
          this.sas_TotalWithheld_Value = data[3][0].TotalWithheldValue;
          
          /* This block of code show/hides Forfeited Shares row */
          let isPsuSummaryTabSelected: string="Y";
  
          if(isPsuSummaryTabSelected == "Y")
          {
            this.shareActDisplayForfeited = true;
          } 
          else 
          {
            this.shareActDisplayForfeited = false;
          }
          /* End of code to show/hide Forfeited Shares row */
  
          if(this.shareActAcceptance == "Action Required") {
            this.shareActActionReq = true;
          } else {
            this.shareActActionReq = false;
          }
    
          if(this.shareActIsGrantAgreementValid == "N") {
            this.shareActDisplayAgreementAcceptance = false;
            this.shareActDisplayNextRelease = false;
            this.shareActDisplaySharesReleasing = false;
            this.shareActDisplayNextReleaseVal = false;
            this.shareActAgreementTabInd = false;
          } else {
            this.shareActDisplayAgreementAcceptance = true;
            this.shareActAgreementTabInd = true;
            this.shareActDisplaySharesReleasing = true;
          }
    
          if(this.shareActNextRelease == "" || this.shareActNextRelease == null) { // if null
            this.shareActDisplayNextRelease = false;
            this.shareActDisplayNextReleaseVal = false;
          } else {
            this.shareActDisplayNextRelease = true;
            this.shareActDisplayNextReleaseVal = true;
          }
    
          if(this.shareActSharesReleasing == 0 || this.shareActSharesReleasing == null) { // if null
            this.shareActDisplaySharesReleasing = false;          
          } else {
            this.shareActDisplaySharesReleasing = true;
          }
    
          if(this.shareActTotalAwarded.toString() == "Forfeited") { // check other components
            this.displayVestCard = false;
            this.displayReleaseCard = false;
            this.displayDivCard = false;
          }    
  
          if(this.shareActForfeited == null && this.shareActNetRemainAtBroker == null && this.shareActReleased == null &&
            this.shareActSoldTransferred == null && this.shareActTotalAwarded == null && this.shareActUnreleased == null &&
             this.shareActWithholdingForTaxes == null) {
            this.noShareAct = true;
          }

          if(this.programDetails.AwardName.toLowerCase().indexOf("keyex") != -1) {
            this.shareActUnreleased == 0 ? this.showKeyExectoggle = false : this.showKeyExectoggle = true;
          } else {
            this.showKeyExectoggle = false;
          }
  
          this.detailsCardLoading = false;
          // this.showKeyExectoggle != false ? this.getheaderRSUDetails(sortedBody,this.keyExecToggle) : "";
        } else {
          this.detailsCardLoading = false;
        }

        this.loadingService.setExecutedComponent();
      }
      else{
        this.rsuDetailsService.getRSUDetails(this.programDetails.GrantNum).subscribe(data => {
          try {      
            if(Array.isArray(data) && data.length > 0) {
              let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
              sessionStorage.setItem("RSU Details Data", JSON.stringify(data));
              sessionStorage.setItem("Header RSU Details", JSON.stringify(sortedBody[0]));
              let shareAct = data[0][0];
              this.rsuDetailsService.shareAct = shareAct;
              this.shareActAcceptance = shareAct.Acceptance;
              this.shareActForfeited = shareAct.Forfeited;
              this.shareActGrantDate = shareAct.GrantDate;
              this.shareActGrantDesc = shareAct.GrantDescription;
              this.shareActGrantId = shareAct.GrantID;
              this.shareActGrantPrice = shareAct.GrantPrice;
              this.shareActNetRemainAtBroker = shareAct.NetRemainingAtBroker;
              shareAct.NextRelease != null ? this.shareActNextRelease = moment(shareAct.NextRelease).format('DD MMM YYYY') : "";
              this.shareActNextReleaseVal = shareAct.SharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
              this.shareActReleased = shareAct.Released;
              this.shareActSharesReleasing = shareAct.SharesReleasing;
              this.shareActSoldTransferred = shareAct.SoldTransferred;
              this.shareActTotalAwarded = shareAct.TotalAwarded;
              this.shareActUnreleased = Math.floor(shareAct.Unreleased);
              this.shareActWithholdingForTaxes = shareAct.WithholdingforTaxes;
              this.shareActIsGrantAgreementValid = shareAct.IsGrantAgreementValid;   
              
              let sas = data[1][0];
              this.originalGrant_shares = sas.OriginalGranted;
              this.originalGrant_grantValue = sas.OriginalGrantedValue;
              this.additional_shares = sas.AdditionalRsu;
              this.additional_grantValue = sas.AdditionalRsuValue;
              this.total_shares = sas.TotalAwarded;
              this.total_grantValue = sas.TotalAwardedValue;
              
              let sas_ur = data[2][0];
              this.unvested_shares = sas_ur.SharesUnvested;
              this.vestedButUnreleased = sas_ur.VestedButUnreleased;
              this.totalUnreleased = sas_ur.TotalUnreleased;
              
              this.sas_released = data[4];
    
              for(let i = 0; i <data[4].length; i++){
                this.sasReleasedTotal_shares += data[4][i].Shares;
                this.sasReleasedTotal_sharesValue += data[4][i].SharesValue;
              }
    
              this.sas_WithheldForTax_Shares = data[3][0].WithheldForTax;
              this.sas_WithheldForTax_Value = data[3][0].WithheldForTaxValue;
              this.sas_WithholdingRefund_Shares = data[3][0].WithHoldingRefund
              this.sas_WithholdingRefund_value = data[3][0].WithHoldingRefundValue
              this.sas_TotalWithheld_Shares = data[3][0].TotalWithheld;
              this.sas_TotalWithheld_Value = data[3][0].TotalWithheldValue;
              
              /* This block of code show/hides Forfeited Shares row */
              let isPsuSummaryTabSelected: string="Y";
      
              if(isPsuSummaryTabSelected == "Y")
              {
                this.shareActDisplayForfeited = true;
              } 
              else 
              {
                this.shareActDisplayForfeited = false;
              }
              /* End of code to show/hide Forfeited Shares row */
      
              if(this.shareActAcceptance == "Action Required") {
                this.shareActActionReq = true;
              } else {
                this.shareActActionReq = false;
              }
        
              if(this.shareActIsGrantAgreementValid == "N") {
                this.shareActDisplayAgreementAcceptance = false;
                this.shareActDisplayNextRelease = false;
                this.shareActDisplaySharesReleasing = false;
                this.shareActDisplayNextReleaseVal = false;
                this.shareActAgreementTabInd = false;
              } else {
                this.shareActDisplayAgreementAcceptance = true;
                this.shareActAgreementTabInd = true;
                this.shareActDisplaySharesReleasing = true;
              }
        
              if(this.shareActNextRelease == "" || this.shareActNextRelease == null) { // if null
                this.shareActDisplayNextRelease = false;
                this.shareActDisplayNextReleaseVal = false;
              } else {
                this.shareActDisplayNextRelease = true;
                this.shareActDisplayNextReleaseVal = true;
              }
        
              if(this.shareActSharesReleasing == 0 || this.shareActSharesReleasing == null) { // if null
                this.shareActDisplaySharesReleasing = false;          
              } else {
                this.shareActDisplaySharesReleasing = true;
              }
        
              if(this.shareActTotalAwarded.toString() == "Forfeited") { // check other components
                this.displayVestCard = false;
                this.displayReleaseCard = false;
                this.displayDivCard = false;
              }    
      
              if(this.shareActForfeited == null && this.shareActNetRemainAtBroker == null && this.shareActReleased == null &&
                this.shareActSoldTransferred == null && this.shareActTotalAwarded == null && this.shareActUnreleased == null &&
                 this.shareActWithholdingForTaxes == null) {
                this.noShareAct = true;
              }
    
              if(this.programDetails.AwardName.toLowerCase().indexOf("keyex") != -1) {
                this.shareActUnreleased == 0 ? this.showKeyExectoggle = false : this.showKeyExectoggle = true;
              } else {
                this.showKeyExectoggle = false;
              }
      
              this.detailsCardLoading = false;
              // this.showKeyExectoggle != false ? this.getheaderRSUDetails(sortedBody,this.keyExecToggle) : "";
            } else {
              this.detailsCardLoading = false;
            }
  
            this.loadingService.setExecutedComponent();
          } catch(error) {
            this.errorDetailsCard = true;
            this.detailsCardLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getRSUDetails");
            error.message += errorLocation;
            throw error;
          }
    
        }, error => {
          this.errorDetailsCard = true;
          let errorLocation = this.errorLocation.replace("methodName", "getRSUDetails");
          error.error.message += errorLocation;
          throw error.error;
        });
      }

    } catch(error) {
      this.errorDetailsCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getRSUDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  getCodeDetails() { 
    try {
      let keyExectData = JSON.parse(sessionStorage.getItem("Key Exec Toggle Data"));
      let code = keyExectData[0];
      
      this.yearCode.push(code[0]);
      this.yearCode.push(code[1]);
      this.yearCode.push(code[2]);
      this.yearCode.push(code[3]);  
      
      this.planNum.push(code[4]);
      this.planNum.push(code[5]);
      this.planNum.push(code[6]);

      this.getRSUDetails();
      this.getPSUDetails();
    } catch(error) {
      this.errorDetailsCard = true;
      this.getRSUDetails();
      let errorLocation = this.errorLocation.replace("methodName", "getCodeDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  getheaderRSUDetails() {
    try {
      this.estForfeit = false;

      if(this.planNum != null && this.planNum != undefined && this.planNum.length > 0) {
        if(this.programDetails.PlanNum == Number(this.planNum[0].OI) || 
        this.programDetails.PlanNum == Number(this.planNum[1].OI) || 
        this.programDetails.PlanNum == Number(this.planNum[2].OI))
        { 
          let bodyRSUDetails = JSON.parse(sessionStorage.getItem("Header RSU Details"));
          this.maxUnreleased = bodyRSUDetails.Unreleased;
          this.awardSharesReleasing = 0; // key exec
          this.awardUnreleased = 0; // key exec
          this.awardReleased = 0;
          this.awardForfeited = 0;
          this.estPerForfeit = 0;
          this.estForfeit = true;
  
          if(this.keyExecToggleValue == "Maximum")
          {
            //Shares Releasing
            let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing * (3/2));
            bodyRSUDetails.SharesReleasing = sharesTargetVestTotal;
            this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
            this.shareActSharesReleasing = this.awardSharesReleasing;
            this.shareActNextReleaseVal = this.shareActSharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
  
            //Unreleased
            let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased * (3/2));
            bodyRSUDetails.Unreleased = unreleasedTargetVestTotal;
            this.awardUnreleased = bodyRSUDetails.Unreleased;
            this.shareActUnreleased = this.awardUnreleased;
  
            this.trendUnreleased = this.maxUnreleased - this.shareActUnreleased;
            this.estPerForfeit = this.trendUnreleased;
            
          }
  
          else if(this.keyExecToggleValue == "Trend")
          {
            let trendObtained: boolean = false;
            for (let k = 0; k < this.yearCode.length; k++)
            {
              if(this.yearCode[k].Code == this.awardDate) {
  
                //Shares Releasing
                let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing);
                let sharesOiPortionTarget = Math.round(sharesTargetVestTotal * (3/4));
                let sharesTsrOptionTarget = Math.round(sharesTargetVestTotal) - Math.round(sharesOiPortionTarget);
                let sharesOiPortionTrend = Math.round(sharesOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                let sharesTsrOptionTrend = Math.round(sharesTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                let sharesTrendVestTotal = Math.round(sharesOiPortionTrend) + Math.round(sharesTsrOptionTrend);
                bodyRSUDetails.SharesReleasing = sharesTrendVestTotal;
                this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
                this.shareActSharesReleasing = this.awardSharesReleasing;
                this.shareActNextReleaseVal = this.shareActSharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
  
                //Unreleased
                let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased);
                let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                bodyRSUDetails.Unreleased = unreleasedTrendVestTotal;
                this.awardUnreleased = bodyRSUDetails.Unreleased;
                this.shareActUnreleased = this.awardUnreleased;
                
                this.trendUnreleased = this.maxUnreleased - this.shareActUnreleased;
                this.estPerForfeit = this.trendUnreleased;
                
                trendObtained = true;
              }
            }
  
            if(!trendObtained) {
              let sharesTargetVestTotal = bodyRSUDetails.SharesReleasing; 
              bodyRSUDetails.SharesReleasing = sharesTargetVestTotal;
  
              let unreleasedTargetVestTotal = bodyRSUDetails.Unreleased;
              bodyRSUDetails.Unreleased = unreleasedTargetVestTotal;
            }
          }
          else if(this.keyExecToggleValue == "Target")
          {
            this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
            this.awardUnreleased = bodyRSUDetails.Unreleased;
  
            this.shareActSharesReleasing = this.awardSharesReleasing;
            this.awardUnreleased = this.awardUnreleased;
            this.shareActUnreleased = this.awardUnreleased;
            this.shareActNextReleaseVal = this.shareActSharesReleasing * this.marketQuoteCurrentPrice.latestPrice;
            // this.shareActSharesReleasing = this.shareActNextReleaseVal;
  
            this.maxUnreleased = this.maxUnreleased - this.shareActUnreleased;
            this.estPerForfeit = this.maxUnreleased;
          }  
        }
      }
    } catch(error) {
      this.errorDetailsCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getheaderRSUDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value >= 0) {
            let number = (num*1).toLocaleString('en', {
              //maximumFractionDigits: value
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Rsudetails") != -1 && this.user != null) {
                webPage = "Rsudetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
/**********************************************************************************
   * Method Name: getPSUDetails                                                                 
   * Description: Get the PSU Performance award details                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 06/06/2023           JMC                         Initial Draft       
   *********************************************************************************/
  
  getPSUDetails(){
    try {
      if(sessionStorage.getItem("RSU Details Data") != null && (this.shareActGrantId != null)){
        console.log('grantID = ' + this.shareActGrantId);
        this.rsuDetailsService.getPSUDetails(this.shareActGrantId, this.user.OptNum).subscribe(data => {
          try {      
            if(Array.isArray(data) && data.length > 0) {
              let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
              sessionStorage.setItem("PSU Details Data", JSON.stringify(data));
              sessionStorage.setItem("Psu Awards Details", JSON.stringify(sortedBody[0]));
              let psuDetails = data[0][0];
              this.rsuDetailsService.psuDetails = psuDetails;
              this.SharesAttainedPSU = psuDetails.SharesAttainedByPSU;
              this.PSUPerf1 = psuDetails.PSU_Performance_1;
              this.PSUPerf1 = psuDetails.PSU_Performance_2;
              this.PSUDisplayInd = psuDetails.DisplayInd;
              this.PSUGrantValue = (this.SharesAttainedPSU * this.shareActGrantPrice);



              this.detailsCardLoading = false;
              // this.showKeyExectoggle != false ? this.getheaderRSUDetails(sortedBody,this.keyExecToggle) : "";
            } else {
              this.detailsCardLoading = false;
            }
  
            this.loadingService.setExecutedComponent();
          } catch(error) {
            this.errorDetailsCard = true;
            this.detailsCardLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getPSUDetails");
            error.message += errorLocation;
            throw error;
          }
    
        }, error => {
          this.errorDetailsCard = true;
          let errorLocation = this.errorLocation.replace("methodName", "getPSUDetails");
          error.error.message += errorLocation;
          throw error.error;
        });

      }
      else{
        console.log('')
      }

    } catch(error) {
      this.errorDetailsCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getPSUDetails");
      error.message += errorLocation;
      throw error;
    }
  }


}
