import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { HoldingProgramDetailsRSUIPOService } from '.././HoldingProgramDetailsRSUIPOService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import * as moment from 'moment';
import { BrokerType, tScript} from '../../services/enumerations/enumerations';
import {TooltipPosition} from '@angular/material/tooltip';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-iporsu-release-card',
  templateUrl: './iporsu-release-card.component.html',
  styleUrls: ['./iporsu-release-card.component.css']
})
export class IporsuReleaseCardComponent implements OnInit {

  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  user: any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = this.user.CurrencyCd;
  errorLocation: string = " + [Component: Rsuipodetails, Method: methodName]";
  positionOption: TooltipPosition[]=['after', 'before', 'above', 'below', 'left', 'right'];

  ipoGrantDesc: string;
  ipoGrantPrice: number;
  ipoTotalAwarded: number;
  ipoUnreleased: number;
  ipoForfeited: number;
  ipoReleased: number;
  ipoWithholdingTaxes: number;
  ipoSoldTransferred: number;
  ipoNetRemainingAtBroker: number;
  ipoOriginalGranted: number;
  ipoOriginalGrantedValue: any;
  ipoOriginalAdditionalRsu: number;
  ipoOriginalAdditionalRsuValue: any;
  ipototalAwardedValue: any;
  shareActGrantDate: any;
  errorReleaseSection: boolean;

  IPOrelAdditionalRsu: number;
  IPOrelGrossReleased: number;
  IPOrelGrossReleasedLoc: number;
  IPOrelGrossReleasedUsd: number;
  IPOrelLocalCurrencyCd: string;
  IPOrelNetReleased: number;
  IPOrelNetReleasedLoc: number;
  IPOrelNetReleasedUsd: number;
  IPOrelOrigScheduled: number;
  IPOrelReleaseFmv: number;
  IPOrelTaxWithholdingMethod: string;
  IPOrelTotalScheduledRsu: number;
  IPOrelWithheldForTax: any;
  IPOrelWithheldForTaxUsd: any;
  IPOrelWithheldForTaxValueLoc: any;
  IPOrelWithholdingRefund: number;
  IPOrelWithholdingRefundLoc: number;
  IPOrelWithholdingRefundUsd: number;
  IPOrelRsuDate: string = "null";
  IPOrelTotalShares: number;

  IPOrelCurrencySelected: any;
  IPOrelIsSelectedCurrencyUsd: boolean = true;
  IPOreleaseLoading: boolean = false;
  IPOrelDisplayCurrencyDropdown: boolean;
  IPOrelSetCurrencyValue: string;
  IPOreleaseData: any;
  IPOcurrency: any;

  sharesUnvested: any;
sharesUnvestedValue: any;
vestedButUnreleased: any;
vestedButUnreleasedValue: any;
totalUnreleased: any;
totalUnreleasedValue: any;
iporsuDetailsReleased: any;
withheldForTaxData: any;
withheldForTaxValue: any;
withHoldingRefund: any;
withHoldingRefundValue: any;
totalWithheld: any;
totalWithheldValue: any;
totalSharesRelease: any = 0;
totalSharesReleaseValue: any = 0;

    //IPO RELEASE
    IPOreleaseDataSource: any;
    IPOdisplayedRelColumns: string[] = ['ReleaseDate', 'Shares', 'Status']; 
  displayReleaseCard: boolean;
  ipoWithholdingForTaxes: any;
  displayGrantCard: boolean;
  displayVestCard: boolean;

  //tooltip
  releaseColumn: string = tScript.rsuipo_release_column;
  rstatusColumn: string = tScript.rsuipo_rstatus_column;
  origSchedRelease: string = tScript.rsuipo_origSchedRelease;
  addRsuLieuDividendsRelease: string = tScript.rsuipo_addRsuLieuDividendsRelease;
  releasefmv: string = tScript.rsuipo_releasefmv;
  taxWithholdingMethod: string = tScript.rsuipo_taxWithholdingMethod;
  grossReleased: string = tScript.rsuipo_grossReleased;
  withheldForTax: string = tScript.rsuipo_withheldForTax;
  withholdingRefund: string = tScript.rsuipo_withholdingRefund;

  constructor(private globals: Globals, 
    private app: AppComponent, private router: Router, 
    private HoldingProgramDetailsRSUIpoService: HoldingProgramDetailsRSUIPOService,
    private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {
    this.getRSUIPODetails();
    this.getRSUIPODetailsRelInfo();
    this.IPOviewReleaseDate(this.IPOreleaseData);
  }

  // DEFAULT ACCORDION START
getRSUIPODetails() {
  try {
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetails(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      try {
        if(Array.isArray(data) && data.length > 0) {
          let ipoRSUDetails = data[0][0];

          if(ipoRSUDetails != null || ipoRSUDetails != undefined){
            this.ipoGrantDesc = ipoRSUDetails.GrantDescription;
            this.shareActGrantDate = ipoRSUDetails.GrantDate;
            this.ipoGrantPrice = ipoRSUDetails.GrantPrice;
            this.ipoTotalAwarded = ipoRSUDetails.TotalAwarded;
            this.ipoUnreleased = ipoRSUDetails.Unreleased;
            this.ipoForfeited = ipoRSUDetails.Forfeited;
            this.ipoReleased = ipoRSUDetails.Released;
            this.ipoWithholdingTaxes = ipoRSUDetails.WithheldForTax;
            this.ipoSoldTransferred = ipoRSUDetails.SoldTransferred;
            this.ipoNetRemainingAtBroker = ipoRSUDetails.NetRemainingAtBroker;
            this.ipoWithholdingForTaxes = ipoRSUDetails.WithholdingforTaxes;
          }
    
          let iporsudetailsGrantedData = data[1][0];   
          
          if(iporsudetailsGrantedData != null || iporsudetailsGrantedData != undefined){
            this.ipoOriginalGranted = iporsudetailsGrantedData.OriginalGranted;
            this.ipoOriginalGrantedValue = iporsudetailsGrantedData.OriginalGrantedValue;
            this.ipoOriginalAdditionalRsu = iporsudetailsGrantedData.AdditionalRsu;
            this.ipoOriginalAdditionalRsuValue = iporsudetailsGrantedData.AdditionalRsuValue;
            this.ipototalAwardedValue = this.ipoOriginalGrantedValue + this.ipoOriginalAdditionalRsuValue; 
    
            if (this.ipoOriginalGranted == this.ipoForfeited){
              this.displayVestCard = false;
              this.displayReleaseCard = false;
              this.displayGrantCard = false;
            }
            else {
              this.displayVestCard = true;
              this.displayReleaseCard = true;
              this.displayGrantCard = true;
            }
          }

          let iporsuDetailsUnvestedData = data[2][0];

          if(iporsuDetailsUnvestedData != null || iporsuDetailsUnvestedData != undefined){
            this.sharesUnvested = iporsuDetailsUnvestedData.SharesUnvested;
            this.sharesUnvestedValue = this.sharesUnvested*this.ipoGrantPrice;
            this.vestedButUnreleased = iporsuDetailsUnvestedData.VestedButUnreleased;
            this.vestedButUnreleasedValue = this.vestedButUnreleased*this.ipoGrantPrice;
            this.totalUnreleased = iporsuDetailsUnvestedData.TotalUnreleased;
            this.totalUnreleasedValue = this.totalUnreleased*this.ipoGrantPrice;
          }

          this.iporsuDetailsReleased = data[3];

          for(let i=0; i<this.iporsuDetailsReleased.length;i++){
            this.totalSharesRelease += this.iporsuDetailsReleased[i].Shares;
            this.totalSharesReleaseValue += this.iporsuDetailsReleased[i].Value;
          }

          let iporsuDetailsWithheldData = data[4][0];
          
          if(iporsuDetailsWithheldData != null || iporsuDetailsWithheldData != undefined){
            this.withheldForTaxData = iporsuDetailsWithheldData.WithheldForTax;
            this.withheldForTaxValue = iporsuDetailsWithheldData.WithheldForTaxValue;
            this.withHoldingRefund = iporsuDetailsWithheldData.WithHoldingRefund;
            this.withHoldingRefundValue = iporsuDetailsWithheldData.WithHoldingRefundValue;
            this.totalWithheld = iporsuDetailsWithheldData.TotalWithheld;
            this.totalWithheldValue = iporsuDetailsWithheldData.TotalWithheldValue;
          }
        }
    
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
    error.message += errorLocation;
    throw error;
  }
}
// DEFAULT ACCORDION - END

  getRSUIPODetailsRelInfo() {
    try {
      this.HoldingProgramDetailsRSUIpoService.getRSUIPODetailsRelInfo(this.user.OptNum, this.IPOrelRsuDate).pipe(map(res => res)).subscribe(data => {
        try {
          if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0)) {
            this.IPOrelTotalShares = 0;
            this.IPOrelSetCurrencyValue = "USD";
            let IPOrelInfoTable = data[0];
            let IPOrelease = data[1][0];
            
            for(let i = 0; i < IPOrelInfoTable.length; i++) {
              if(IPOrelInfoTable[i].StatusFlag == 1) {
                IPOrelInfoTable[i].Status = "Released";

                if(IPOrelInfoTable[i].BrokerCd != "0"){
                  IPOrelInfoTable[i].Status = IPOrelInfoTable[i].Status + " - " + BrokerType[IPOrelInfoTable[i].BrokerCd];
                }
              } else if(IPOrelInfoTable[i].StatusFlag == 2) {
                IPOrelInfoTable[i].Status = "Released"; // set the status value base on the status flag
              } else if(IPOrelInfoTable[i].StatusFlag == 3) {
                IPOrelInfoTable[i].Status = "Unreleased"; // set the status value base on the status flag
              } else if(IPOrelInfoTable[i].StatusFlag == 4) {
                IPOrelInfoTable[i].Status = "Forfeited"; // set the status value base on the status flag
              }
              this.IPOrelTotalShares += IPOrelInfoTable[i].Shares;
            }
        
            this.IPOreleaseDataSource = IPOrelInfoTable;
            this.IPOrelAdditionalRsu = IPOrelease.AdditionalRsu;
            this.IPOrelGrossReleased = IPOrelease.GrossReleased;
            this.IPOrelGrossReleasedLoc = IPOrelease.GrossReleasedLoc;
            this.IPOrelGrossReleasedUsd = IPOrelease.GrossReleasedUsd;
            this.IPOrelLocalCurrencyCd = IPOrelease.LocalCurrencyCd;

            this.IPOrelNetReleased = IPOrelease.NetReleased;
            this.IPOrelNetReleasedLoc = IPOrelease.NetReleasedLoc;
            this.IPOrelNetReleasedUsd = IPOrelease.NetReleasedUsd;
            this.IPOrelOrigScheduled = IPOrelease.OriginallyScheduled;
            this.IPOrelReleaseFmv = IPOrelease.ReleaseFmv;
            this.IPOrelTaxWithholdingMethod = IPOrelease.TaxWithholdingMethod;
            this.IPOrelTotalScheduledRsu = IPOrelease.TotalScheduledRsu;
            this.IPOrelWithheldForTax = IPOrelease.WithheldForTax;
            this.IPOrelWithheldForTaxUsd = IPOrelease.WithheldForTaxUsd;
            this.IPOrelWithheldForTaxValueLoc = IPOrelease.WithheldForTaxLoc;
            this.IPOrelWithholdingRefund = IPOrelease.WithholdingRefund;
            this.IPOrelWithholdingRefundLoc = IPOrelease.WithholdingRefundLoc;
            this.IPOrelWithholdingRefundUsd = IPOrelease.WithholdingRefundUsd;
        
            this.IPOcheckRelCurrency(this.IPOrelSetCurrencyValue);

            if(this.IPOrelTaxWithholdingMethod == "Payroll Withholding") {
              this.IPOrelWithheldForTax = "Refer to Earnings Statement";
              this.IPOrelWithheldForTaxUsd = "Refer to Earnings Statement";
              this.IPOrelWithheldForTaxValueLoc = "Refer to Earnings Statement";
            }
        
            if(this.IPOrelLocalCurrencyCd == "USD" || this.IPOrelLocalCurrencyCd == "") {
              this.IPOrelDisplayCurrencyDropdown = false;
            } else {
              this.IPOrelDisplayCurrencyDropdown = true;
            }
            this.IPOreleaseLoading = false;
          } else {
            
          }
      
          
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsRelInfo");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsRelInfo");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsRelInfo");
      error.message += errorLocation;
      throw error;
    }
  }
  
  IPOviewReleaseDate(IPOreleaseData) {
    try {
      this.IPOreleaseLoading = true;

      if(IPOreleaseData != undefined){
        this.IPOrelRsuDate = IPOreleaseData.ReleaseDate;
        this.IPOrelRsuDate = "'" + moment(this.IPOrelRsuDate).format('MM/DD/YYYY') + "'";
      }
      this.getRSUIPODetailsRelInfo();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "IPOviewReleaseDate");
      error.message += errorLocation;
      throw error;
    }
  }
  
  IPOcheckRelCurrency(IPOcurrency) {
    try {
      if(IPOcurrency == "USD" || IPOcurrency == null || IPOcurrency == "") {
        this.IPOrelIsSelectedCurrencyUsd = true;
        this.IPOrelSetCurrencyValue = "USD";
      } else {
        this.IPOrelIsSelectedCurrencyUsd = false;
        this.IPOrelSetCurrencyValue = IPOcurrency;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "IPOcheckRelCurrency");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }
  

}
