import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import { Globals } from '../../../../../globals';
import { SemlinkaccessService } from '../../../../../services/ea/api/SemlinkaccessService';
import { PartnerselectedComponent } from '../partnerselected.component';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';

@Component({
  selector: 'app-ri',
  templateUrl: './ri.component.html',
  styleUrls: ['./ri.component.css']
})
export class RiComponent implements OnInit {
  optNum: number;
  documentId:number;

  editPartner: boolean = false;
  letterReceivedCheckBox: boolean = false;
  accessRole: boolean = true;
  loading: boolean = true;

  riStatus: string;
  noticePayment: string;
  riStartDate: string;
  total: string;
  riEndDate: string;
  actualUnits: string;
  dateNotified: string;
  transitionUnits: string;
  improvementPlanReceived: string;
  baseUnits: string;
  improvedDate: string;
  performanceUnits: string;
  duringYear: string;
  LOR: string;
  deployedEntity: string;
  perfRating: string;
  physicalOffice: string;
  age: string;
  coeffCountry: string;
  leaveDate: string;
  designatedPartner: string;
  letterReceived: number;
  checkBox: number;
  annualReviewer: string;
  comments: string;
  isError: boolean = false;


  constructor(
    private router: Router,
    private apiService: ApiService,
    private global: Globals,
    private semLinkAccessService: SemlinkaccessService,
    private partnerSelected: PartnerselectedComponent,
    private access: SemaccessService
  ) { }

  ngOnInit() {
    this.optNum = parseInt(sessionStorage.getItem("optNum"));
    this.documentId = parseInt(sessionStorage.getItem(this.global.documentID));
    this.getAccess();
    this.partnerSelected.loadingEdit = true;
  }

  /**********************************************************************************
    * Method Name: getAccess
    * Description: Retrieves access from a reasuable service 
    *
    * #         Date                 Modified by                    Description
    * 1         03/11/20             Almerie T. Dondonay            Initial draft
  ***********************************************************************************/

  async getAccess(){
    try {
      await this.access.getSemAccess("ri").then();
      if (this.access.role != false) {
        this.partnerSelected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerSelected.editHistory(this.documentId, this.optNum);
          this.partnerSelected.partnerSelectedInfo(this.optNum);
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            if (this.access.role['data'][8][0]['ROLE'] != 1 && this.access.role['data'][8][0]['ROLE'] != 5) {
              this.router.navigate(['equityadmin/sem/pdsa']);
            } else if (this.access.role['data'][8][0]['ROLE'] == 1) {
              this.editPartner = true;
            }
            this.riData();
            this.riCountry();
          } else {
            this.router.navigate(['equityadmin/eamain']);
          }
        } else {
          this.router.navigate(['equityadmin/eamain']);
        }
      } else {
        this.router.navigate(['equityadmin/eamain']);
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccess', 'ri');
      throw error;
    }
  }

/**********************************************************************************
  * Method Name: riData()
  * Description: get RI data or information.
  *
  * #         Date                 Modified by                    Description
  * 1         05/18/20             Almerie T. Dondonay            Initial draft
***********************************************************************************/
  riData() {
    let riDataArg: any = {
      "selected_optnum": this.optNum,
      "functionName": "getRI"
    }

    this.apiService.postData(this.global.api_URL_pds, riDataArg).subscribe(data => {
      try {
        if (data) {
          let contentBody = data.body;
          contentBody == undefined ? contentBody = "" : "";
          for (let key in contentBody['data'][0]) {
            if (contentBody['data'][0].hasOwnProperty(key)) {
              if (contentBody['data'][0][key] == null && contentBody['data'][0][key] == undefined) {
                contentBody['data'][0][key] = '';
              }
            }
          }
          if (contentBody['data'] != undefined && contentBody['data'] != null && contentBody['data'] != 0) {
            this.riStatus = contentBody['data'][0]['EMPLOYEE_STATUS_ID_DESCRIPTION'];
            this.noticePayment = contentBody['data'][0]['SETTLEMENT_MONTHS'];
            this.riStartDate = contentBody['data'][0]['RI_START_DATE'];
            this.total = contentBody['data'][0]['AWARD'];
            this.riEndDate = contentBody['data'][0]['RI_END_DATE'];
            this.actualUnits = contentBody['data'][0]['ACTUAL_UNITS'];
            this.dateNotified = contentBody['data'][0]['DATE_NOTIFIED'];
            if (contentBody['data'][0]['TRANS_UNIT'] == 0) {
              this.transitionUnits = "";
            } else {
              this.transitionUnits = contentBody['data'][0]['TRANS_UNIT'];
            }
            this.improvementPlanReceived = contentBody['data'][0]['DATE_IMPROVEMENT_PLAN'];
            this.baseUnits = contentBody['data'][0]['BASE_UNIT'];
            this.improvedDate = contentBody['data'][0]['IMPROVED_DATE'];
            this.performanceUnits = contentBody['data'][0]['PERF_UNIT'];
            this.duringYear = contentBody['data'][0]['PIC_OR_DURING_YEAR'];
            this.LOR = contentBody['data'][0]['LOR'];
            this.deployedEntity = contentBody['data'][0]['MARKET_ENTITY_DESCRIPTION'];
            this.perfRating = contentBody['data'][0]['PERFORMANCE_RATING'];
            this.physicalOffice = contentBody['data'][0]['OFFICE_NAME'];
            this.age = contentBody['data'][0]['AGE'];
            this.leaveDate = contentBody['data'][0]['LEAVE_DATE'];
            this.designatedPartner = contentBody['data'][0]['DESIGNATED_PARTNER'];
            this.letterReceived = contentBody['data'][0]['LETTER_RECEIVED'];
            this.annualReviewer = contentBody['data'][0]['ANNUAL_REVIEWER'];
            this.comments = contentBody['data'][0]['COMMENTS'];
            this.received();
          } else {
            contentBody['data'] = '';
            this.riStatus = "";
            this.noticePayment = "";
            this.riStartDate = "";
            this.total = "";
            this.riEndDate = "";
            this.actualUnits = "";
            this.dateNotified = "";
            this.transitionUnits = "";
            this.improvementPlanReceived = "";
            this.baseUnits = "";
            this.improvedDate = "";
            this.performanceUnits = "";
            this.duringYear = "";
            this.LOR = "";
            this.deployedEntity = "";
            this.perfRating = "";
            this.physicalOffice = "";
            this.age = "";
            this.leaveDate = "";
            this.designatedPartner = "";
            this.annualReviewer = "";
            this.comments = "";

          }
          this.received();
          this.riCountry();
          this.loading = false;
          this.partnerSelected.loadingEdit = false;
          this.global.searchLoading = false;

        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('riData', 'ri');
        throw error;
      }

    }, error => {

      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('riData', 'ri');
      throw error;
    });

  }

/**********************************************************************************
  * Method Name: riCountry()
  * Description: get RI country Coeff.
  *
  * #         Date                 Modified by                    Description
  * 1         05/18/20             Almerie T. Dondonay            Initial draft
***********************************************************************************/
  riCountry() {
    let riCountryArg: any = {
      "selected_optnum": this.optNum,
      "functionName": "getRICountry"
    }

    this.apiService.postData(this.global.api_URL_pds, riCountryArg).subscribe(data => {
      try {
        if (data) {
          let contentBody = data.body;
          contentBody == undefined ? contentBody = "" : "";
          if (contentBody['data'] != undefined && contentBody['data'] != null && contentBody['data'] != 0) {
            this.coeffCountry = contentBody['data'][0]['COEFF_COUNTRY'];
          } else {
            contentBody['data'] = '';
            this.coeffCountry = "";
          }
        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('riCountry', 'ri');
        throw error;
      }
    }, error => {

      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('riData', 'ri');
      throw error;
    });
  }

received(){
  try {
    if (this.letterReceived == 1) {
      this.letterReceivedCheckBox = true;
      this.checkBox = 1;
    } else {
      this.letterReceivedCheckBox = false;
      this.checkBox = 0;
    }
  } catch (error) {
    throw error;
  }

}

disableInput(){
  event.preventDefault();
}

}
