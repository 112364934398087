// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page-container{
    max-width: 1200px;
}

.auth-banner, .unauth-banner, .tool-card, .error-banner {
    background: #ffffff;
    padding: 16px;
    font-size: 15px;
    font-family: Graphik;
    line-height: 1;
    border-radius: 8px;
}

.unauth-banner, .error-banner {
    text-align: center;
}

.greetings-container{
    margin-top: 20px;
}

.cards-container{
    margin-top: 10px; 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.welcome-header, .tool-title{
    font-weight: 800;
}

.tool-card{
    padding: 16px;
    flex: 1;
    max-width: 545px;
    min-width: 545px;
    box-shadow: 1px 1px 5px 1px #888;
    margin-top: 10px
}

.tool-links{
    color: #7c057c !important;
    cursor: pointer;
}
a:not([href]) {
    /* color: inherit; */
    color: #7c057c !important;
    text-decoration: none;
}

:host ::ng-deep .loading{
    display: flex;
    width: 100%;
    height: 80vh;
    align-items: center;
    justify-content: center;
}


`, "",{"version":3,"sources":["webpack://./src/app/equityadmin/eamain/eamain.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,oBAAoB;IACpB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,OAAO;IACP,gBAAgB;IAChB,gBAAgB;IAChB,gCAAgC;IAChC;AACJ;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".main-page-container{\r\n    max-width: 1200px;\r\n}\r\n\r\n.auth-banner, .unauth-banner, .tool-card, .error-banner {\r\n    background: #ffffff;\r\n    padding: 16px;\r\n    font-size: 15px;\r\n    font-family: Graphik;\r\n    line-height: 1;\r\n    border-radius: 8px;\r\n}\r\n\r\n.unauth-banner, .error-banner {\r\n    text-align: center;\r\n}\r\n\r\n.greetings-container{\r\n    margin-top: 20px;\r\n}\r\n\r\n.cards-container{\r\n    margin-top: 10px; \r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: space-between;\r\n}\r\n\r\n.welcome-header, .tool-title{\r\n    font-weight: 800;\r\n}\r\n\r\n.tool-card{\r\n    padding: 16px;\r\n    flex: 1;\r\n    max-width: 545px;\r\n    min-width: 545px;\r\n    box-shadow: 1px 1px 5px 1px #888;\r\n    margin-top: 10px\r\n}\r\n\r\n.tool-links{\r\n    color: #7c057c !important;\r\n    cursor: pointer;\r\n}\r\na:not([href]) {\r\n    /* color: inherit; */\r\n    color: #7c057c !important;\r\n    text-decoration: none;\r\n}\r\n\r\n:host ::ng-deep .loading{\r\n    display: flex;\r\n    width: 100%;\r\n    height: 80vh;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
