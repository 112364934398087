import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../../services/ea/api/ApiService';
import { SemlinkaccessService } from '../../../../../../services/ea/api/SemlinkaccessService';
import { PerfunitmodalComponent } from '../../../../../../services/ea/shared/perfunitmodal/perfunitmodal.component';
import { MatDialog } from '@angular/material/dialog';
import { PartnerselectedComponent } from '../../partnerselected.component';
import { Globals } from '../../../../../../globals';

@Component({
  selector: 'app-unithistorytable',
  templateUrl: './unithistorytable.component.html',
  styleUrls: ['./unithistorytable.component.css']
})
export class UnithistorytableComponent implements OnInit {
  dataSource: any = this.semLinkAccessService.getUnitHistoryTable();
  optNum:any= sessionStorage.getItem('optNum');
  countryCoeffData: any;
  countryCoeff: number = 0;
  coeffCountryValue: string;
  coeffValue: number;
  coeffPercentValue: number;
  actualUnitsValue: number;
  countryCoeffModalData: any[];
  perfUnit: number = 0;
  UnitCash: number;
  UnitEquity: number;
  UnitTotal: number;
  perUnitData: any;
  hasData: boolean = this.semLinkAccessService.getTableData();
  loading: boolean;
  isError: boolean = false;

  constructor(
    private semLinkAccessService: SemlinkaccessService,
    private apiService: ApiService, 
    private modal: MatDialog,
    private partnerSelected: PartnerselectedComponent,
    private global: Globals
  ) { }

  ngOnInit() {
  }

/**********************************************************************************
    * Method Name: unitHistoryCountryCoeff(type, modalHeader)
    * Description: get data for modal display.
    *
    * #         Date                 Modified by                    Description
    * 1         04/29/2020           Divy O. Ardiente               Initial draft
***********************************************************************************/

unitHistoryCountryCoeff(type, modalHeader, fiscalyr){
  // CoEff
  try {
    this.modal.open(PerfunitmodalComponent, {
      panelClass: 'perfUnit-modal',
      disableClose: true,
      data: [{type:type, header:modalHeader, fiscalYear: fiscalyr}]
    });
  } catch (error) {
    this.loading = false;
    this.isError = true;
    error.message += this.apiService.setErrorContents('unitHistoryCountryCoeff', 'unithistorytable');
    throw error;
  }
  }

/**********************************************************************************
    * Method Name: getPerfUnit(fiscalYear: number)
    * Description: get data for modal display.
    *
    * #         Date                 Modified by                    Description
    * 1
***********************************************************************************/

  unitHistoryPerfUnits(type, modalHeader, fiscalyr){
    // Perf Units 
    try {
      this.modal.open(PerfunitmodalComponent, {
        panelClass: 'perfUnit-modal',
        disableClose: true,
        data: [{data: this.perUnitData, type:type, header:modalHeader, fiscalYear: fiscalyr}]
      });
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('unitHistoryPerfUnits', 'unithistorytable');
      throw error;
    }
  }

}
