import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  header: string;
  body: string;
  hasBtn1: boolean = false;
  hasBtn2: boolean = false;
  btn1Name: string = null;
  btn2Name: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.header = this.data.content.header;
    this.body = this.data.content.body;
    this.hasBtn1 = this.data.content.hasBtn1;
    this.hasBtn2 = this.data.content.hasBtn2;
    this.btn1Name = this.data.content.btn1Name;
    this.btn2Name = this.data.content.btn2Name;
  }

}
