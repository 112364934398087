// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    font-family: Graphik;
    font-size: 14px;
}

.btn {
    margin: 0 4px;
}

h1 {
    font-weight: 700;
}

.btn{
    background-color: #460173;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.header-title{
    margin-bottom: 20px;
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/services/ea/shared/modal/modal.component.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".content {\r\n    font-family: Graphik;\r\n    font-size: 14px;\r\n}\r\n\r\n.btn {\r\n    margin: 0 4px;\r\n}\r\n\r\nh1 {\r\n    font-weight: 700;\r\n}\r\n\r\n.btn{\r\n    background-color: #460173;\r\n    color: #ffffff;\r\n    font-weight: 600;\r\n    text-decoration: none;\r\n    padding: 0.5rem 1rem;\r\n}\r\n\r\n.header-title{\r\n    margin-bottom: 20px;\r\n    display: block;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
