import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/ea/api/ApiService';
import { StaticService } from '../../services/ea/api/StaticService';
import { Router, ActivatedRoute } from '@angular/router';
import { CreatereportsComponent } from '../iea/createreports/createreports.component';
import { MatDialog } from '@angular/material/dialog';
import { SemlinkaccessService } from '../../services/ea/api/SemlinkaccessService';
import { ApplicationaccessService } from '../../services/ea/api/ApplicationaccessService';
import { ToolaccessService } from '../../services/ea/api/ToolaccessService';
import { RoleaccessService } from '../../services/ea/api/RoleaccessService';
import { Globals } from '../../globals';
import { EaLoadingService } from '../../services/ea/EaLoadingService';
@Component({
  selector: 'app-eamain',
  templateUrl: './eamain.component.html',
  styleUrls: ['./eamain.component.css']
})
export class EamainComponent implements OnInit {

  isLoading: boolean = true; 
  adminAccess: boolean = false;
  operatorAccess: boolean = false; 
  auditorAccess: boolean = false; 
  applicationAccess: boolean = this.applicationAccessService.getUserAppAccess();
  toolAccess: boolean = this.toolAccessService.getUserToolAccess();
  roleId: number = this.roleAccessService.getUserRoleAccess();
  ejetAccess: boolean = false;
  isError: boolean = false;

  welcome: string;
  iea: string;
  sem: string = "SENIOR EXECUTIVE MATTERS";

  ieaContent: any = [];
  reportsLink: string = null;
  introductionText: string = null;
  semPDSACheck: boolean = false;
  semCard:boolean = this.semLinkService.getSemCard();
  semCardSee: boolean = this.semLinkService.getSemSee();
  semContentAccess: any = this.semLinkService.getSemLink();
  semContent: any = [
        { name: 'Access Maintenance', access: this.semContentAccess[0] },
        { name: 'File Archive', access: this.semContentAccess[1] },
      ]; 
  semSeeAccess: string[] = ["semCardSemSeet","SEMRt","semCardSemMr"];
  semAccess: string[] = ["semCardAm","semCardFa","semCardPdsa"];
 
  constructor(
    private staticContentService: StaticService,
    public router: Router,
    private modal: MatDialog,
    public activeRoute: ActivatedRoute,
    private semLinkService: SemlinkaccessService,
    private applicationAccessService: ApplicationaccessService,
    private toolAccessService: ToolaccessService,
    private roleAccessService: RoleaccessService,
    private globals: Globals,
    private apiService: ApiService,
    public loadingService: EaLoadingService
  ) { }

  ngOnInit() {
    this.checkUserAccess();
  }

  /**********************************************************************************
  * Method Name: checkUserAccess                                                                 
  * Description: Checks the user access.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/11/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  checkUserAccess() {
    if(this.applicationAccess) { 
      this.getStaticContent();
    } else {
      this.isLoading = false;
    }   
  }

  /**********************************************************************************
  * Method Name: getStaticContent                                                                 
  * Description: Gets the static content data from the api.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/11/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getStaticContent(): void {
    let messageName: string = '%ea.home%';
    this.staticContentService.getStaticContent(messageName).then((data) => {
      if(data) {
        let content = data;
        this.getContent(content);
      } else {
        this.checkPDS();
      }
    }).catch((error) => {
      if(error) {
        this.isLoading = false;
        this.isError = true;
      }
    });
  }

  /**********************************************************************************
  * Method Name: getContent                                                                 
  * Description: Gets the content needed for homepage.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/11/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getContent(data: Array<any>): void {
    try {
      for(let i = 0; i < data.length; i++) {
        if(data[i].MESSAGE_NAME == 'ea.home.iea.content1') {
          this.ieaContent.push({name: data[i].MESSAGE_VALUE});
        } else if(data[i].MESSAGE_NAME == 'ea.home.iea.content2') {
          this.ieaContent.push({name: data[i].MESSAGE_VALUE});
        } else if(data[i].MESSAGE_NAME == 'ea.home.iea.content3') {
          this.ieaContent.push({name: data[i].MESSAGE_VALUE});
        } else if(data[i].MESSAGE_NAME == 'ea.home.reports.link') {
          this.reportsLink = data[i].MESSAGE_VALUE;
        } else if(data[i].MESSAGE_NAME == 'ea.home.intro.text') {
          this.introductionText = data[i].MESSAGE_VALUE;
        } else if(data[i].MESSAGE_NAME == 'ea.home.welcome.text') {
          this.welcome = data[i].MESSAGE_VALUE;
        } else if(data[i].MESSAGE_NAME == 'ea.home.ejet.header') {
          this.iea = data[i].MESSAGE_VALUE;
        } else if(data[i].MESSAGE_NAME == 'ea.home.sem.header') {
          this.sem = data[i].MESSAGE_VALUE;
        }
      }
    
      if(this.toolAccess && this.roleId) { // EJET Tool
        this.ejetAccess = true;
        this.checkRoleId();
      } else {
        this.checkPDS();
      }
    } catch(error) {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getContent', 'Eamain');
      throw error;
    }
  }

  /**********************************************************************************  
  * Method Name: checkRoleId                                                                     
  * Description: Checks the role id of the user.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/20/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  checkRoleId() {
    try {
      for(let i = 0; i < this.ieaContent.length; i++) {
        if(this.ieaContent[i].name == 'Equity Journal Entry' && 
          (this.roleId == 5 || this.roleId == 6 || this.roleId == 7)) { // Equity Journal Entry
            this.ieaContent[i].show = true;
        } else if(this.ieaContent[i].name == 'Business Data Repository' && 
          this.roleId == 5) { // Business Data Repository
            this.ieaContent[i].show = true;
        } else if(this.ieaContent[i].name == 'Create Reports' &&
          (this.roleId == 5 || this.roleId == 6 || this.roleId == 7)) { // Create Reports
            this.ieaContent[i].show = true;
        }
      }
      this.checkPDS();
    } catch(error) {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('checkRoleId', 'Eamain');
      throw error;
    }
  }

  /**********************************************************************************  
  * Method Name: navToPage                                                                   
  * Description: Redirect to a specific page.                          
  *                                                                                            
  * #         Date                 Modified by                    Description                             
  * 1         01/09/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  navToPage(name: string): void{
    if(name == 'Equity Journal Entry') { // ejet\
      this.roleId != 7 ? this.router.navigate(['../ejet/importinvoice'], {relativeTo: this.activeRoute}) : this.router.navigate(['../ejet/weeklysummary'], {relativeTo: this.activeRoute})
    } else if(name == 'Business Data Repository') { // bdr
      this.router.navigate(['../bdr/billto'], {relativeTo: this.activeRoute});
    } else if(name == 'Create Reports') { // createreports
      this.openCreateReports();
    } else if(name == 'Partner Data System Archive'){
      this.isLoading = true;
      this.semLinkService.verifyPDSALinkAccess();
    }
  }  

  /**********************************************************************************
  * Method Name: openCreateReports                                                                 
  * Description: Opens create reports modal.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/  
  /* istanbul ignore next */
  openCreateReports() {
    try {
      let content: any = {
        isError: false,
        hasBtn1: true,
        hasBtn2: true,
        btn1Name: 'CONTINUE'
      };

      let createReportsModal = this.modal.open(CreatereportsComponent, {
        panelClass: 'create-reports',
        disableClose: true,
        data: {
          content
        }
      });

      createReportsModal.afterClosed().subscribe(result => {
        if(result) {
          window.open(this.reportsLink, '_blank');
        }
      });
    } catch(error) {
      this.modal.closeAll();
      let content: any = {
        isError: true,
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: 'OK'
      };

      this.modal.open(CreatereportsComponent, {
        panelClass: 'create-reports',  
        disableClose: true,  
        data: {
          content
        }
      });

      error.message += this.apiService.setErrorContents('openCreateReports', 'Eamain');  
      throw error;
    }
  }

  checkPDS(){
    if (this.semContentAccess[2]) {
      let accessKey: any = {  
        "functionName": "getPDSRole"  
      }
      this.apiService.postData(this.globals.api_URL_accessTool, accessKey).subscribe(data => {
        try {
          if (data != null) {
            if (data.statusCode == 200) {
              let body = data.body;
              if (body != undefined && body != null) {
                if (body.data == "Invalid Transaction") {
                  this.semPDSACheck = false;
                  this.isLoading = false;
                } else {
                  this.semPDSACheck = true;
                  this.isLoading = false;
                }
              }
            } else { 
              this.semPDSACheck = false; 
              this.isLoading = false;
            }
          } else {
            this.isLoading = false;
          }
        } catch (error) {
          this.isLoading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('checkPDS', 'homepage');  
          throw error  
        }
      }, error => {
        this.isLoading = false;  
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('checkPDS', 'homepage');
        throw error;
      });
    } else {  
      /* istanbul ignore next line */
      this.semPDSACheck = false;  /* istanbul ignore next line */
      this.isLoading = false;
    }
  }

}
