
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { StaticContentService } from '../../services/StaticContentService';
import { Globals } from '../../globals';

@Component({
  selector: 'app-countryspecifictaxinformations',
  templateUrl: './countryspecifictaxinformations.component.html',
  styleUrls: ['./countryspecifictaxinformations.component.css']
})
export class CountryspecifictaxinformationsComponent implements OnInit {
  resourceLoading: boolean;
  resourcesCountries: any;
  errorCountrySpecific: boolean;
  countryLoading: boolean;
  errorLocation: string = " + [Component: Resourcespage, Method: methodName]";
  countryValue: string = "Select Country";
  resourcesCountryTaxFiles: any;
  dynamicURL: any;
  env: any = require('../../../../env.json');
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));

  constructor(private staticContent: StaticContentService, private globals: Globals) { }

  ngOnInit() {
    this.getCountryTaxList();
    this.getCountryTaxList(this.user.CountryName);
  }

  getCountryTaxList(country?){
    try {      
      
      if(country == null || country == undefined || country == ""){
        // this.countryLoading = true;
      this.resourceLoading = true;
      
        this.staticContent.getCountryList().subscribe(data => {
          this.resourcesCountries = data[0];
          this.resourceLoading = false
        }, error => {
          this.errorCountrySpecific = true;
          this.countryLoading = false;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getCountryTaxList");
          error.error.message += errorLocation;
          throw error.error;
        });
        }else{
          this.countryLoading = true;
      
          this.countryValue = country;
          this.resourcesCountryTaxFiles = [];
          
          country = country.replace('/','%2F');

          this.staticContent.getCountrySpecificTaxFile(country).subscribe(data => {
            try {
              let files = data[0];
      
              this.resourcesCountryTaxFiles = files;
              setTimeout(()=>{ this.countryLoading = false }, 300);
            } catch(error) {
              this.errorCountrySpecific = true;
              this.countryLoading = false;
              this.resourceLoading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getCountryTaxList");
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            this.errorCountrySpecific = true;
            this.countryLoading = false;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getCountryTaxList");
            error.error.message += errorLocation;
            throw error.error;
          });
        }
    } catch(error) {
      this.errorCountrySpecific = true;
      this.countryLoading = false;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getCountryTaxList");
      error.message += errorLocation;
      throw error;
    }
  }

  urlCheck(file){
    
    if(file.indexOf('solium') != -1){
     
     this.dynamicURL = file;
     return true
    }else{
      this.dynamicURL = this.env.URL + "/resourcespage";
      return true;
    }
    
  }

  getFileResource(file, event) {
    try {
      let filePath = file.SourceURL;     
      let fileName = file.FileDesc + "." + file.FileType;
      let fileType: string;
      let href: string = event.target.href;
  
      event.preventDefault();
     
      if(filePath.includes(".pdf") || filePath.includes(".zip")){
        if(file.FileType.indexOf('pdf') != -1) {
         fileType = 'application/pdf';
        } else {
         fileType = 'mime_type';
        }
  
        if(filePath.includes(".zip")) {
          fileName = filePath;
        }
  
        this.staticContent.getFile(fileName, "MD").subscribe(data =>{
          try {
            let content = JSON.parse(data._body);
    
            if (content != null){    
              let file = JSON.parse(content.body);
              this.downloadFile(fileName, fileType, this.base64ToArrayBuffer(file.file));
              this.staticContent.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
                let writeLogResponse = data;
              });
            }else{
              this.staticContent.noFileExists(fileName);
            }
          } catch(error) {
            let errorLocation = this.errorLocation.replace("methodName", "getFileResource");
            error.message += errorLocation;
            throw error;
          }
       }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFileResource");
        error.error.message += errorLocation;
        throw error.error;
       });
    
      }else{
        window.open(filePath, "_blank")
      }
    
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileResource");
      error.message += errorLocation;
      throw error;
    }
  }

  downloadFile(reportName, fileType, byte) {
    try {
      if(navigator.appVersion.toString().indexOf('.NET') > 0) { // browser is IE
        let blob = new Blob([byte], {type: fileType});
        window.navigator.msSaveBlob(blob, reportName);
        } else if(navigator.userAgent.indexOf("Firefox") > 0) { // browser is FireFox
        let a = document.createElement('a');
        a.style.display = "none";
        a.style.zIndex = "1002";
        a.style.position = "absolute";
        let blob = new Blob([byte], {type: fileType});
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = reportName;
        document.body.appendChild(a);
        a.click();
        
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
        } else { // browser is Chrome
        let blob = new Blob([byte], {type: fileType});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.click();
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "downloadFile");
      error.message += errorLocation;
      throw error;
    }
  }

  base64ToArrayBuffer(base64): Uint8Array {
    try {
      let binaryString = window.atob(base64);
      let binaryLength = binaryString.length;
      let bytes = new Uint8Array(binaryLength);
      
      for(let i = 0; i < binaryLength; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
      }
      return bytes;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "base64ToArrayBuffer");
      error.message += errorLocation;
      throw error;
    }
  }

}
