declare var require: any;
let env: any;

try{
    env = require('../../../env.json');
}catch{
    env = env || {};
    env.environment = 'sandbox';
}

const envSetting = env.environment;

const APP_CONFIG = {
    sandbox: {
        env: "sandbox",
        eso: {
            loginUrl: "https://federation-sts-stage.accenture.com/oauth/ls/connect/authorize", // change
            logoutUrl: "https://federation-sts-stage.accenture.com/oauth/ls/connect/token", // change
            clientId: "1064.newmyholdings.web",
            oidc: true,
            redirectUri: "http://localhost:9091/homepage", // change
            scope: "openid user_profile read_myholdings_members write_myholdings_members read_myholdings_visitors read_profilepicture read_search read_stream write_stream offline_access read_myholdings_admin write_myholdings_admin read_myholdings_nonadmin",
            response_type: "id_token token", // change
            enabled: true
        },
        url: {
            weburl: "https://myholdings-fortify.ciodev.accenture.com",
            apiurl: "https://myholdings-fortify-api.ciodev.accenture.com"
        }
    },
    dev: {
        env: "dev",
        eso: {
            loginUrl: "https://federation-sts-stage.accenture.com/oauth/ls/connect/authorize", // change
            logoutUrl: "https://federation-sts-stage.accenture.com/oauth/ls/connect/token", // change
            clientId: "1064.newmyholdings.web",
            oidc: true,
            redirectUri: "https://myholdings-pt.ciotest.accenture.com/homepage", // change
            scope: "openid user_profile read_myholdings_members write_myholdings_members read_myholdings_visitors read_profilepicture read_search read_stream write_stream offline_access read_myholdings_admin write_myholdings_admin read_myholdings_nonadmin",
            response_type: "id_token token", // change
            enabled: true
        },
        url: {
            weburl: "https://myholdings-pt.ciotest.accenture.com",
            apiurl: "https://myholdings-fortify-api.ciodev.accenture.com"
        }
    },
    test: {
        env: "test",
        eso: {
            loginUrl: "https://federation-sts-stage.accenture.com/oauth/ls/connect/authorize", // change
            logoutUrl: "https://federation-sts-stage.accenture.com/oauth/ls/connect/token", // change
            clientId: "1064.newmyholdings.web",
            oidc: true,
            redirectUri: "https://myholdings-uat.ciotest.accenture.com/homepage", // change
            scope: "openid user_profile read_myholdings_members write_myholdings_members read_myholdings_visitors read_profilepicture read_search read_stream write_stream offline_access read_myholdings_admin write_myholdings_admin read_myholdings_nonadmin",
            response_type: "id_token token", // change
            enabled: true
        },
    },
    stage: {
        env: "stage",
        eso: {
            loginUrl: "https://federation-sts-stage.accenture.com/oauth/ls/connect/authorize", // change
            logoutUrl: "https://federation-sts-stage.accenture.com/oauth/ls/connect/token", // change
            clientId: "1064.newmyholdings.web",
            oidc: true,
            redirectUri: "https://myholdings.ciostage.accenture.com/homepage", // change
            scope: "openid user_profile read_myholdings_members write_myholdings_members read_myholdings_visitors read_profilepicture read_search read_stream write_stream offline_access read_myholdings_admin write_myholdings_admin read_myholdings_nonadmin",
            response_type: "id_token token", // change
            enabled: true
        },
    },
    prod: {
        env: "prod",
        eso: {
            loginUrl: "https://federation-sts.accenture.com/oauth/ls/connect/authorize", // change
            logoutUrl: "https://federation-sts.accenture.com/oauth/ls/connect/token", // change
            clientId: "1064.newmyholdings.web",
            oidc: true,
            redirectUri: "https://myholdings.accenture.com/homepage", // change
            scope: "openid user_profile read_myholdings_members write_myholdings_members read_myholdings_visitors read_profilepicture read_search read_stream write_stream offline_access read_myholdings_admin write_myholdings_admin read_myholdings_nonadmin",
            response_type: "id_token token", // change
            enabled: true
        },
    }
};

export const CONFIG = APP_CONFIG[envSetting];