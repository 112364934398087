
import {map,  count, flatMap } from 'rxjs/operators';
import { Component, OnInit, ContentChild } from '@angular/core';
import {StaticContentService } from '../services/StaticContentService';
import { Globals } from '../globals';
import { ReportsService } from '../reportspage/ReportsService'
import { empty } from 'rxjs';
import * as $ from 'jquery';
import { PageSection,EmployeeType, FileType, MyHoldingsPagesResources } from '../services/enumerations/enumerations';
import { HtmlParser } from '@angular/compiler';
// import { c } from '@angular/core/src/render3';
// import { MAT_OPTION_PARENT_COMPONENT } from '@angular/material';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ImportantdatesComponent } from './importantdates/importantdates.component';
import { TrainingandfaqsComponent } from './trainingandfaqs/trainingandfaqs.component';
import { ProgramguidebooksComponent } from './programguidebooks/programguidebooks.component';
import { EquitycommunicationsComponent } from './equitycommunications/equitycommunications.component';
import { CountryspecifictaxinformationsComponent } from './countryspecifictaxinformations/countryspecifictaxinformations.component';
import { TaxtimeComponent } from './taxtime/taxtime.component';
import { ToolsandreportsComponent } from './toolsandreports/toolsandreports.component';
import { PoliciesandotherlinksComponent } from './policiesandotherlinks/policiesandotherlinks.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { TopquestionsComponent } from './topquestions/topquestions.component';
import { DividendComponent } from './dividend/dividend.component';          //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
import { Chart } from 'chart.js';
import { MsalService } from '@azure/msal-angular';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

declare var require: any;
@Component({
  selector: 'app-resourcespage',
  templateUrl: './resourcespage.component.html',
  styleUrls: ['./resourcespage.component.css']
})
export class ResourcespageComponent implements OnInit {
  getUserLeaderTrade: any; 
  getUserSharePlan: any;
  userR: any;
  exec: boolean;
  userJC: any;
  dataTrading: any;
  dataTradingDesc: any;
  dataSharesPlan: any;
  dividendContent: string = "";        //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
  env: any = require('../../../env.json');
  dataTradingString: any;
  sessPurchase: any;
  sessAny: any;
  sessOpt: any;
  sessRSU: any;
  sessString: string;
  dividendDocs = [];          //  ACS: CRES00082219171733 - Enhancement to Resource page of myHoldings
  dividendChartFY = [];        //  ACS: CRES00082219171733 - Enhancement to Resource page of myHoldings
  dividendChartPrice = [];        //  ACS: CRES00082219171733 - Enhancement to Resource page of myHoldings
  chart: any;         //  ACS: CRES00082219171733 - Enhancement to Resource page of myHoldings

  glossaryString: string[]= ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y"];
  glossaryLoadingItems: boolean = false;
  setAlphabet: string = "A";
  glossaryContent: any;
 
  expanded: string = "EXPAND ALL"
  expandResource: string = "collapse show";
  expandResourceBool: boolean = true;
  expandYETGBool: boolean = false;   //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
  expandYETG: string = "collapse";  //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
  expandPlan: string = "collapse";
  expandPlanBool: boolean = false;
  expandDiv: string = "collapse";         //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
  expandDivBool: boolean = false;         //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
  expandGlossBool: boolean = true;
  expandGloss: string = "collapse show";
  
  pageSectionProgramType: any;
  loading: boolean = true;
  expandResourceAccordion: any = "collapse";
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));

  shareIncentiveContent: object;
  restrictedShareUnitContent: any;
  shareOptionsGuide: object;
  purchaseProgramGuideESPP: object;
  purchaseProgramGuideVEIP: object;
  expatInformations: object;
  programArchiveVEIP: object;
  programArchiveESPP: object;

  equityComsSrExecTradingMemo: object;
  equityComsVeip: object;
  equityComsEspp: object;
  equityComsAccDivMemo: object;
  equityComsLastyear: object;
  equityComsPrevYear: object;
  displaySrExecMemo: boolean = false;
  displayEqComsVeip: boolean = false;
  displayEqComsEspp: boolean = false;

  countryValue: string = "Select Country";
  resourcesCountries: any;
  resourcesCountryTaxFiles: any;

  noContentSharesPlanChecker: boolean = false;
  isESPP: boolean = false;

  displayImportantD: boolean = true;
  displayTrainingF: boolean = false;
  displayProgramG: boolean = false;
  // displayEquityC: boolean = false;         //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
  displayDividend: boolean = false;          //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
  displayCountrySTI: boolean = false;
  displayTaxT: boolean = false;
  displayToolsR: boolean = false;
  displayPoliciesOL: boolean = false;
  displayGlossary: boolean = false;
  displayTopQuestions: boolean = false;
  equityTrainingContent: any;
  accentureDividendsContent: any;
  sneTradingContent: any;
  veipFaqContent: any;
  esppFaqContent: any;
  fctFaqContent: any;
  optionsRsuFaqContent: any;
  departureConsiderationContent: any;
  archiveVeipContent: any;
  archiveEsppContent: any;
  archiveDividendsContent: any;

  //TAX TIME
  currYrTaxPrepDocDesc: any;
  currYrTaxPrepDocName: any;
  currYrTaxPrepFileTyp: any;

  currYrSaleshistDesc: any;
  currYrSaleshistListNm: any;
  displayTaxTime: boolean = false;
  taxPrepcontent: any;
  taxPrepData: any;
  salesHistcontent: any;
  currSalesHistData: any;
  archiveTaxPrepData: any;
  taxTimeLoading: boolean = false;
  glossaryLoading: boolean = false;
  resourceLoading: boolean = false;
  countryLoading: boolean = false;

  topQuestions: any;
  FAQList: any;
  ToolsData: any;
  toolsReportsData: any;
  toolsBodyDesc: any;
  toolsListName: any;
  SaleSHistTry: any;
  toolsReportBodyDesc: any;
  toolsReportListName: any;
  toolsReportsLoading: boolean = false;
  equityRelatedPoliciesData: any;
  otherResourcesData: any;
  equityRelatedPoliciesBodyDesc: any;
  equityRelatedPoliciesListName: any;
  otherResourcesBodyDesc: any;
  otherResourcesListName: any;
  policiesOthersLoading: boolean = false;
  topQuestionsLoading: boolean = false;
  trainingFAQLoading: boolean = false;
  esppFaqHeader: string;
  accentureDividendsHeader: string;
  sneTradingHeader: string;
  veipFaqHeader: string;
  fctFaqHeader: string;
  optionsRsuFaqHeader: string;
  departureConsiderationHeader: string;
  dynamicURL: string;
  errorLocation: string = " + [Component: Resourcespage, Method: methodName]";
  errorImportantDates: boolean = false;
  errorTrainingFaqs: boolean = false;
  errorProgramGuidebooks: boolean = false;
  // errorEquityCommunications: boolean = false;    //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
  errorDividend: boolean = false;                   //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
  errorYearEndTaxGuide: boolean = false;            //  ACS: CRES00082219171733 - Enhancement to Resource page of myHoldings
  errorCountrySpecific: boolean = false;
  errorTaxTime: boolean = false;
  errorToolsReport: boolean = false;
  errorPoliciesResources: boolean = false;
  errorGlossary: boolean = false;
  errorTopQuestions: boolean = false;
  
  showfctFaqHeader: boolean = false;
  showsneTradingHeader: boolean = false;
  showveipFaqHeader: boolean = false;
  showesppFaqHeader: boolean = false;
  showaccentureDividendsHeader: boolean = false;
  showoptionsRsuFaqHeader: boolean = false;
  showdepartureConsiderationHeader: boolean = false;
  showToggler:boolean = true;

  selectedTab: string;

  activeComponent: any;


  purchaseProgram: string;

  // restructured resources declarations
  resourcesContent = [
    {id: "Important Dates", value: ImportantdatesComponent, isActive: false},
    {id: "Top Questions", value: TopquestionsComponent, isActive: false}, 
    {id: "FAQ's & Training", value: TrainingandfaqsComponent, isActive: false},
    {id: "Country Specific Tax Information", value: CountryspecifictaxinformationsComponent, isActive: false},
    {id: "Dividends", value: DividendComponent, isActive: false},
    {id: "Plan Documents & Guidebooks", value: ProgramguidebooksComponent, isActive: false},

    // JBP START: CRES00082219171733 - Enhancement to Resource page of myHoldings

    // {id: "Equity Communications", value: EquitycommunicationsComponent, isActive: false}, 
    // {id: "Tax Time", value: TaxtimeComponent, isActive: false},
    //  JBP END: CRES00082219171733 - Enhancement to Resource page of myHoldings

    // {id: "Tools & Reports", value: ToolsandreportsComponent, isActive: false}, removed as asked via US test
    {id: "Policies & Other Links", value: PoliciesandotherlinksComponent, isActive: false}, 
    {id: "Glossary", value: GlossaryComponent, isActive: false} 
  ];
  activeResourcesTab = sessionStorage.getItem("activeResource");
  expandButtonText = "EXPAND ALL";
  activeMobileTab = "";
  public isClicked= [];


  constructor(private msalService: MsalService, private staticContent: StaticContentService, private globals: Globals, private rservice: ReportsService, private errorNotificationService: ErrorNotificationService, private router: Router) { }

  ngOnInit() {
    this.selectedTab = sessionStorage.getItem("resourcesActiveTab");
    this.checkErrorMessage();
    this.getImportantDate();
    // this.activeComponent = ImportantdatesComponent;
    // this.fromHighlights();
    
    // new structure
    this.activeResourcesTab == null || this.activeResourcesTab == undefined ? this.activeResourcesTab = "Important Dates" : this.activeResourcesTab = this.activeResourcesTab;
    this.onLoad(this.activeResourcesTab);
  }

  // ngAfterViewInit(){
  //   setTimeout(() => {   
  //     if(this.selectedTab == 'topQuestion'){
  //       this.getTopQuestions();
  //       this.setActiveNav('topQuestion');
  //       this.selectResources('TopQuestions');
  //       sessionStorage.setItem("resourcesActiveTab", "importantDates");
  //     } 
      
  //   $( "#sideNavItems>li:first" ).addClass("active");  
  //   }, 4000);
  // }

  relogUser(){
    this.clearAndReload();
    this.router.navigate(['landingpage']);
  }

  clearAndReload(){
    window.location.reload(); 
    sessionStorage.clear();
    localStorage.clear();
  }

  clearArray(){
    this.isClicked.splice(0,this.isClicked.length);
  }

  onLoad(component){
    for (let i = 0; i < this.resourcesContent.length; i++) {
      if(this.resourcesContent[i].id == component){
        this.resourcesContent[i].isActive = true;
        this.activeComponent = this.resourcesContent[i].value;
        this.activeMobileTab = this.resourcesContent[i].id;
      }
      else{
        this.resourcesContent[i].isActive = false;
      }
    }

    // temporary only remove on restructure
    if (component == "Important Dates") {
      this.selectResources('ImportantDates');
      this.getImportantDate();
      this.showToggler = false;
    } 
    else if(component == "FAQ's & Training"){
      this.selectResources('TrainingFAQs');
      this.getTrainingFAQs();
      this.showToggler = true;
      this.expanded = "EXPAND ALL";
    }
    else if(component == "Plan Documents & Guidebooks"){
      this.selectResources('ProgramGuidebooks');
      this.getProgramGuidebooks();
      this.showToggler = true;
      this.expanded = "EXPAND ALL";
    }
    //  JBP START: CRES00082219171733 - Enhancement to Resource page of myHoldings
    else if(component == "Dividends"){
      this.selectResources('Dividend');
      this.getDividend();
      this.showToggler = false;
    }
    // else if(component == "Equity Communications"){
    //   this.selectResources('EquityCommunications');
    //   this.getEquityCommunications();
    //   this.showToggler = true;
    //   this.expanded = "EXPAND ALL";
    // }
    // else if(component == "Tax Time"){
    //   this.selectResources('TaxTime');
    //   this.getTaxTime();
    //   this.showToggler = true;
    //   this.expanded = "EXPAND ALL";
    // }
    //  JBP END: CRES00082219171733 - Enhancement to Resource page of myHoldings
    else if(component == "Country Specific Tax Information"){
      this.selectResources('CountrySTI');
      this.getCountryTaxList();
      this.getTaxTime();
      this.showToggler = true;
      this.expanded = "EXPAND ALL"
    }
    else if(component == "Policies & Other Links"){
      this.selectResources('PoliciesOL');
      this.getPoliciesOthers();
      this.showToggler = true;
      this.expanded = "EXPAND ALL";
    }
    else if(component == "Glossary"){
      this.selectResources('Glossary');
      this.getGlossary();
      this.showToggler = false;
    }
    else if(component == "Top Questions"){
      this.selectResources('TopQuestions');
      this.getTopQuestions();
      this.showToggler = true;
      this.expanded = "EXPAND ALL";
    }
  }

  selectComponent(component){
      sessionStorage.setItem("activeResource", component);
      this.onLoad(component);
  }

  /**********************************************************************************
    Method Name: getTaxTime
    Description: Get and display Tax Time data from myResources

    Date            Modified by                  Description
    04/23/19        Roldan Mazo                  Initial Draft    
    05/14/19        KMN                          BUG 308050 fix
**********************************************************************************/

getTaxTime() {
  try {
    // this.taxTimeLoading = true; 
    this.resourceLoading = true;
 
    this.bodyData[5] = PageSection.countryCurrYrTaxPrep;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
      try {
        this.taxPrepcontent = JSON.parse(data.body); 

        if(this.taxPrepcontent.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
                     
        if (this.taxPrepcontent.data == "{}"){
          //NO TAX PREP DATA TO DISPLAY
          this.taxPrepcontent.data = null;
          } 
        
           if (this.taxPrepcontent.data){
            let currYrTaxPrepContent = this.taxPrepcontent.data;         
            
            this.taxPrepData = currYrTaxPrepContent;
    
            for (let i = 0; i < this.taxPrepData.length; i++){
              this.currYrTaxPrepDocDesc = this.taxPrepData[i].Document_Desc;
              this.currYrTaxPrepDocName = this.taxPrepData[i].Document_Name;
              this.currYrTaxPrepFileTyp = this.taxPrepData[i].File_Type;
    
              if(this.taxPrepData[i].File_Type == "pdf") {
                this.taxPrepData[i].fileIcon = FileType.pdf;
               } else if(this.taxPrepData[i].File_Type == "docx") {
                this.taxPrepData[i].fileIcon = FileType.doc;
               } else if(this.taxPrepData[i].File_Type == "doc") {
                this.taxPrepData[i].fileIcon = FileType.doc;
               } else if(this.taxPrepData[i].File_Type == "xls") {
                this.taxPrepData[i].fileIcon = FileType.xls;
               } else if(this.taxPrepData[i].File_Type == "xlsx") {
                this.taxPrepData[i].fileIcon = FileType.xls;
               } else if(this.taxPrepData[i].File_Type == "pptx") {
                this.taxPrepData[i].fileIcon = FileType.ppt;
               } else if(this.taxPrepData[i].File_Type == "ppt") {
                this.taxPrepData[i].fileIcon = FileType.ppt;
               } else if(this.taxPrepData[i].File_Type == "zip") {
                this.taxPrepData[i].fileIcon = FileType.zip;
               } 
            }          
          }
                        
        //SALES HIST
            
        let regEx: any = /&.*?;|<div.*?>|<[^>]*>|<\/div>|<blockquote.*?>|<\/blockquote>|<br.*?>|<p>|<\/p>/gi;
    
        this.bodyData[5] = PageSection.countryCurrYrSalesHist;
        this.bodyData[6] = 1;
    
        this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
          try {
            this.salesHistcontent = JSON.parse(data.body); 
            
            if(this.salesHistcontent.name == "TokenExpiredError"){
              this.router.navigate(['homepage']);
            }
    
            if (this.salesHistcontent.data == "{}"){
              //NO SALES HIST DATA TO DISPLAY
              this.salesHistcontent.data = null;
            }
      
           if (this.salesHistcontent.data){
      
              let currYrSalesHistContent = this.salesHistcontent.data;  
      
              this.currSalesHistData = currYrSalesHistContent;  
              
              this.SaleSHistTry =  currYrSalesHistContent[0].Body_Desc;
              this.currYrSaleshistListNm = currYrSalesHistContent[0].List_Name;   
              
           }
      
            //Archive
            this.bodyData[5] = PageSection.countryArchTaxPrep;
            this.bodyData[6] = 0;
      
            this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
              try {
                let taxArchivecontent = JSON.parse(data.body); 
                
                if(taxArchivecontent.name == "TokenExpiredError"){
                  this.router.navigate(['homepage']);
                }
      
                if (taxArchivecontent.data == "{}"){
                  //NO ARCHIVE TAX PREP DATA TO DISPLAY
                  taxArchivecontent.data = null;
                } 
                
               if (taxArchivecontent.data){
        
                  let archiveTaxPrepContent = taxArchivecontent.data;       
                  
                  this.archiveTaxPrepData = archiveTaxPrepContent;  
                  
                  for (let i = 0; i < this.archiveTaxPrepData.length; i++){
                  
                    this.currYrTaxPrepDocDesc = this.archiveTaxPrepData[i].Document_Desc;             
                    this.currYrTaxPrepDocName = this.archiveTaxPrepData[i].Document_Name;              
                    this.currYrTaxPrepFileTyp = this.archiveTaxPrepData[i].File_Type;
        
                    if(this.archiveTaxPrepData[i].File_Type == "pdf") {
                      this.archiveTaxPrepData[i].fileIcon = FileType.pdf;
                     } else if(this.archiveTaxPrepData[i].File_Type == "docx") {
                      this.archiveTaxPrepData[i].fileIcon = FileType.doc;
                     } else if(this.archiveTaxPrepData[i].File_Type == "doc") {
                      this.archiveTaxPrepData[i].fileIcon = FileType.doc;
                     } else if(this.archiveTaxPrepData[i].File_Type == "xls") {
                      this.archiveTaxPrepData[i].fileIcon = FileType.xls;
                     } else if(this.archiveTaxPrepData[i].File_Type == "xlsx") {
                      this.archiveTaxPrepData[i].fileIcon = FileType.xls;
                     } else if(this.archiveTaxPrepData[i].File_Type == "pptx") {
                      this.archiveTaxPrepData[i].fileIcon = FileType.ppt;
                     } else if(this.archiveTaxPrepData[i].File_Type == "ppt") {
                      this.archiveTaxPrepData[i].fileIcon = FileType.ppt;
                     } else if(this.archiveTaxPrepData[i].File_Type == "zip") {
                      this.archiveTaxPrepData[i].fileIcon = FileType.zip;
                     } 
                  }           
               }    
                // this.taxTimeLoading = false;
                this.resourceLoading = false; 
               
              } catch(error) {
                // this.errorTaxTime = true;
                this.errorYearEndTaxGuide = true;
                this.resourceLoading = false;
                let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              // this.errorTaxTime = true;
              this.errorYearEndTaxGuide = true;
              this.resourceLoading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
              error.error.message += errorLocation;
              throw error.error;
            });  
          } catch(error) {
            // this.errorTaxTime = true;
            this.errorYearEndTaxGuide = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          // this.errorTaxTime = true;
          this.errorYearEndTaxGuide = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
          error.error.message += errorLocation;
          throw error.error;
        });
      } catch(error) {
        // this.errorTaxTime = true;
        this.errorYearEndTaxGuide = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      // this.errorTaxTime = true;
      this.errorYearEndTaxGuide = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
      error.error.message += errorLocation;
      throw error.error;
    });   
  } catch(error) {
    // this.errorTaxTime = true;
    this.errorYearEndTaxGuide = true;
    this.resourceLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
    error.message += errorLocation;
    throw error;
  }
}

 /***************************************************************************************************************
      Method Name: getToolsReport
      Description: Get and display Tools & Reports data from myResources

      Date            Modified by                  Description
      04/26/19        Roldan Mazo                  Initial Draft     

  ***************************************************************************************************************/

 getToolsReport(){
  try {
    // this.toolsReportsLoading = true;
    this.resourceLoading = true;

    this.bodyData[5] = PageSection.toolsEORModel;
    this.bodyData[6] = 1;
  
    let regEx: any = /&.*?;|<div.*?>|<\/div>|<blockquote.*?>|<\/blockquote>|<br.*?>|<p>|<\/p>/gi;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
      try {
        let toolsModelContent = JSON.parse(data.body);
        
        if(toolsModelContent.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
      
        if (toolsModelContent.data == "{}"){
          //NO TOOLS MODEL DATA TO DISPLAY
          toolsModelContent.data = null;
        } 
        
        if (toolsModelContent.data.length > 2){      
    
          let parsedToolsReportsContent = toolsModelContent.data;              
            this.ToolsData = parsedToolsReportsContent;   
      
           
          for(let i = 0; i < this.ToolsData.length; i++){      
    
            this.toolsBodyDesc = this.ToolsData[i].Body_Desc;        
            this.toolsListName = this.ToolsData[i].List_Name;             
          }            
        }
    
        this.bodyData[5] = PageSection.toolsReports;
        this.bodyData[6] = 1;
    
        this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {    
          try {
            let toolsReportContent = JSON.parse(data.body)
         
            if(toolsReportContent.name == "TokenExpiredError"){
              this.router.navigate(['homepage']);
            }
    
            if (toolsReportContent.data == "{}"){
              //NO REPORTS DATA TO DISPLAY
              toolsReportContent.data = null;
            } 
            
            if (toolsReportContent.data.length > 2){
              
              let parsedReportsContent = toolsReportContent.data;        
                this.toolsReportsData = parsedReportsContent; 
    
              for(let i = 0; i < this.toolsReportsData.length; i++){      
    
                this.toolsReportBodyDesc = this.toolsReportsData[i].Body_Desc;        
                this.toolsReportListName = this.toolsReportsData[i].List_Name;    
    
                
                if(this.toolsReportsData[i].File_Type == "pdf") {
                  this.toolsReportsData[i].fileIcon = FileType.pdf;
                 } else if(this.toolsReportsData[i].File_Type == "docx") {
                  this.toolsReportsData[i].fileIcon = FileType.doc;
                 } else if(this.toolsReportsData[i].File_Type == "doc") {
                  this.toolsReportsData[i].fileIcon = FileType.doc;
                 } else if(this.toolsReportsData[i].File_Type == "xls") {
                  this.toolsReportsData[i].fileIcon = FileType.xls;
                 } else if(this.toolsReportsData[i].File_Type == "xlsx") {
                  this.toolsReportsData[i].fileIcon = FileType.xls;
                 } else if(this.toolsReportsData[i].File_Type == "pptx") {
                  this.toolsReportsData[i].fileIcon = FileType.ppt;
                 } else if(this.toolsReportsData[i].File_Type == "ppt") {
                  this.toolsReportsData[i].fileIcon = FileType.ppt;
                 } else if(this.toolsReportsData[i].File_Type == "zip") {
                  this.toolsReportsData[i].fileIcon = FileType.zip;
                 } 
            }  
          }
          setTimeout(()=>{ this.inContentIconAppend() }, 500); 
          // this.toolsReportsLoading = false;
          this.resourceLoading = false;
          } catch(error) {
            this.errorToolsReport = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getToolsReport");
            error.message += errorLocation;
            throw error;
          }  
        }, error => {
          this.errorToolsReport = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getToolsReport");
          error.error.message += errorLocation;
          throw error.error;
        });    
      } catch(error) {
        this.errorToolsReport = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getToolsReport");
        error.message += errorLocation;
        throw error;
      }     
    }, error => {
      this.errorToolsReport = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getToolsReport");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorToolsReport = true;
    this.resourceLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getToolsReport");
    error.message += errorLocation;
    throw error;
  }
 }

 /***************************************************************************************************************
      Method Name: getPoliciesOthers
      Description: Get and display Tools & Reports data from myResources

      Date            Modified by                  Description
      04/26/19        Roldan Mazo                  Initial Draft     

  ***************************************************************************************************************/
getPoliciesOthers(){  
  try {
    // this.policiesOthersLoading = true;
    this.resourceLoading = true;

    this.bodyData[5] = PageSection.policiesEquity;
    this.bodyData[6] = 1;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {    
      try {
        let equityRelatedPoliciesContent = JSON.parse(data.body);

        if(equityRelatedPoliciesContent.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if (equityRelatedPoliciesContent.data == "{}"){
          //NO EQUITY RELATED POLICIES DATA TO DISPLAY
          equityRelatedPoliciesContent.data = null;
        } 
        
       if (equityRelatedPoliciesContent.data){
    
          let parsedEquityRelatedPoliciesContent = equityRelatedPoliciesContent.data;

          this.equityRelatedPoliciesBodyDesc =  parsedEquityRelatedPoliciesContent[0].Body_Desc;

       }  
      
          this.bodyData[5] = PageSection.policiesOther;
          this.bodyData[6] = 1;
    
          this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {    
            try {
                //Other Resources
                let otherResourcesContent = JSON.parse(data.body);
                
                if(otherResourcesContent.name == "TokenExpiredError"){
                  this.router.navigate(['homepage']);
                }
                
                if (equityRelatedPoliciesContent.data == "{}"){
                  //NO OTHER RESOURCES DATA TO DISPLAY
                  equityRelatedPoliciesContent.data = null;
                } 

               if (otherResourcesContent.data){

                  let parsedOtherResourcesContent = otherResourcesContent.data;

                  this.otherResourcesData = parsedOtherResourcesContent[0].Body_Desc;
              }
              // this.policiesOthersLoading = false;
              this.resourceLoading = false;
              setTimeout(()=>{ this.removeLinksjQuery() }, 500);
            } catch(error) {
              this.errorPoliciesResources = true;
              this.resourceLoading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
              error.message += errorLocation;
              throw error;
            }
        }, error => {
          this.errorPoliciesResources = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
          error.error.message += errorLocation;
          throw error.error;
        });
      } catch(error) {
        this.errorPoliciesResources = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorPoliciesResources = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorPoliciesResources = true;
    this.resourceLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getPoliciesOthers");
    error.message += errorLocation;
    throw error;
  }
}

    /**********************************************************************************
      Method Name: getImportantDate
      Description: Get and display data from myResources

      #         Date            Modified by                     Description
      1         04/11/19        Joshua Paul Avecilla(JPA)       Removed inner condition in shares plan accordion for bug fix.
      2         09/09/19        Janine B. Pogoy (JBP)   

  **********************************************************************************/
  getImportantDate(){
    try {
      this.bodyData[5] = PageSection.SrExecTrading;
      this.bodyData[6] = 1;
  
      if (this.user.JobCd != '10000025') {
       this.purchaseProgram = "Employee Share Purchase Plan(ESPP)";
      }else{
       this.purchaseProgram = "Voluntary Equity Investment Program(VEIP)";
      }     
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {   
        try {
          let content = JSON.parse(data.body);  

          if(content.name == "TokenExpiredError"){
            //this.router.navigate(['homepage']);
            this.relogUser();
          }

          let contentTradingchecker;        
          if(content.data == "{}"){
            this.dataTrading = "";
            this.expandResource = "collapse";
          }
          
         if(content.data) {      
          contentTradingchecker = content.data;
         // contentTradingchecker = JSON.parse(content.body);  
          
            if (contentTradingchecker.length > 0){// length > {}
              this.dataTrading = contentTradingchecker[0];
              this.dataTradingDesc = this.dataTrading.List_Name; 
              this.dataTrading = this.dataTrading.Body_Desc;
              this.expandResourceBool = true;

              if(this.dataTrading.indexOf('<font color="#0000ff">Restricted Persons Trading</font>') != -1){
                this.dataTrading = this.dataTrading.split('<font color="#0000ff">Restricted Persons Trading</font>').join("Restricted Persons Trading");
              }
            }else{
              this.dataTrading = "";
              this.expandResource = "collapse";
              this.expandResourceBool = false;
            }  
       }
          if(this.bodyData[2] != "Employees"){
            this.bodyData[5] = PageSection.importantDateVEIP;
            this.bodyData[6] = 1;
          }else{
            this.bodyData[5] = PageSection.importantDateESPP;
            this.bodyData[6] = 1;
          }      
    
          this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {   
            try {
              let content = JSON.parse(data.body);

              if(content.name == "TokenExpiredError"){
               // this.router.navigate(['homepage']);
                this.relogUser();    
              }
              
              let contentSharePlanChecker;
             if(content.data) { // length > {}
      
              contentSharePlanChecker = content.data; 

                  if(contentSharePlanChecker.length > 0){
                    this.dataSharesPlan = contentSharePlanChecker[0];  
                           
                    this.dataSharesPlan = this.dataSharesPlan.Body_Desc;
                   
                } else {
                  this.dataSharesPlan = "";

                  this.expandPlan = "collapse";
                  this.expandPlanBool = false;
                  this.expandDiv = "collapse";          //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
                  this.expandDivBool = false;         //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
      
                } 
             }
              this.loading = false;
              setTimeout(() => {
                this.inContentIconAppend();
              }, .1);
            } catch(error) {
              this.errorImportantDates = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            this.errorImportantDates = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
            error.error.message += errorLocation;
            throw error.error;
          }); 

          //  JBP START: CRES00082219171733 - Enhancement to Resource page of myHoldings
          this.bodyData[5] = PageSection.importantDateDividends;

          this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
            try {
              let content = JSON.parse(data.body);

              if(content.data.length != 0){
                this.dividendContent = content.data[0].Body_Desc;
              }
              else {
                this.dividendContent = "";
              }
            }
            catch(error) {
              this.errorImportantDates = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
              error.message += errorLocation;
              throw error;
            }
          });

          //  JBP END: CRES00082219171733 - Enhancement to Resource page of myHoldings
        } catch(error) {
          this.errorImportantDates = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorImportantDates = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
        error.error.message += errorLocation;
        throw error.error;
      });    
    } catch(error) {
      this.errorImportantDates = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
      error.message += errorLocation;
      throw error;
    }
  }

  toggler(){
    try {

      this.expanded == "EXPAND ALL" ? this.expand() : this.collapse();
      // if( this.expandResource == "collapse show" ||  this.expandPlan == "collapse show"){
      //   this.expandResource = "collapse";
      //   this.expandPlan = "collapse";
      //   this.expanded = "EXPAND ALL"
      //   this.expandResourceBool = false;
      //   this.expandPlanBool = false;
       
      //  }else{
      //   this.expandResource = "collapse show";
      //   this.expandPlan = "collapse show";
      //   this.expanded = "COLLAPSE ALL"
      //   this.expandResourceBool = true;
      //   this.expandPlanBool = true;
      //  }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "toggler");
      error.message += errorLocation;
      throw error;
    }
   }

   expand(){
     let mainAccordion = $(".resource-arrow-down>a");
     let subAccordion = $(".oval");
     let topQuestionsAccordion = $(".topQuestionsHeader>a");
    $(".collapse").addClass("show");
    // $(".resources-container > div >div > div > div").removeClass("show")
    $(".navbar-collapse").removeClass("show");
    $("#sideNavHeader").removeClass("show");
    for (let i = 0; i < mainAccordion.length; i++) {
      mainAccordion[i].attributes[7] != undefined ? mainAccordion[i].attributes[7].value = "true" : mainAccordion[i].attributes[6].value = "true";
    }
    for (let i = 0; i < subAccordion.length; i++) {
      subAccordion[i].attributes[2].value = "true";
      
    }
    for (let i = 0; i < topQuestionsAccordion.length; i++) {
      topQuestionsAccordion[i].attributes[1].value = "true"
      
    }
    this.expanded = "COLLAPSE ALL";
   }

   collapse(){
    // let mainAccordion = $(".resource-arrow-down>a");
    let subAccordion = $(".oval");
    let topQuestionsAccordion = $(".topQuestionsHeader>a");
    $(".collapse.show").removeClass("show");
    $(".resources-container > div >div > div > div").addClass("show");
    // for (let i = 0; i < mainAccordion.length; i++) {
    //   mainAccordion[i].attributes[7].value = "false";      
    // }
    for (let i = 0; i < subAccordion.length; i++) {
      subAccordion[i].attributes[2].value = "false";
      
    }
    for (let i = 0; i < topQuestionsAccordion.length; i++) {
      topQuestionsAccordion[i].attributes[1].value = "false"
      
    }
    this.expanded = "EXPAND ALL";
   }
 
 cllps(){
  try {
    if(this.expandPlan == "collapse" || this.expandResource == "collapse"){
      this.expanded = "EXPAND ALL";
    } 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "cllps");
    error.message += errorLocation;
    throw error;
  }    
 }
 
 /**********************************************************************************
      Method Name: get Program Guidebooks
      Description: Get and display data from myResources

      Date            Modified by                  Description
      04/24/19        John Paul Fernando(JPF)    Initial draft.     

  **********************************************************************************/

 getProgramGuidebooks(){
  try {
    // get share plan incentive
    this.resourceLoading = true;
    this.getShareIncentive();
    this.getRSUGuidebooks();
    this.getShareOptions();
    this.getPurchaseProgramVEIP();
    this.getPurchaseProgramESPP();
    this.getExpatInfo();
    // this.getArchiveVEIP();
    // this.getArchiveESPP();   
    // this.resourceLoading = false;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getProgramGuidebooks");
    error.message += errorLocation;
    throw error;
  }
}

  getGlossary(){
    try {
      // this.glossaryLoading = true;
      this.resourceLoading = true;
      this.glossaryLoadingItems = false;
          this.bodyData[5] = PageSection.glossary + this.setAlphabet;
          this.bodyData[6] = 1;
      
          this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
            try {
              let content = JSON.parse(data.body);

              if(content.name == "TokenExpiredError"){
                this.router.navigate(['homepage']);
              }

              let contentDataCheck;       
        
             if(content.data){
                contentDataCheck = content.Body;
                contentDataCheck = content.data;
        
                if(contentDataCheck.length > 0){
                  this.glossaryContent = contentDataCheck[0];

                  this.glossaryContent = this.glossaryContent.Body_Desc;

                  if(this.glossaryContent.indexOf("<b>") != -1){
                    this.glossaryContent = this.glossaryContent.split("<b>").join("<span style='font-weight: bold;'>");
                    this.glossaryContent = this.glossaryContent.split("</b>").join("</span>");
                  }
                  if(this.glossaryContent.indexOf('align="left"') != -1){
                    this.glossaryContent = this.glossaryContent.split('align="left"').join("");
                  }
                  if(this.glossaryContent.indexOf('dir="ltr"') != -1){
                    this.glossaryContent = this.glossaryContent.split('dir="ltr"').join("");
                  }
                }
                else{
                  this.glossaryContent = "";
                }
              }
              // this.glossaryLoading = false;
              this.resourceLoading = false;
              this.glossaryLoadingItems = true;
              setTimeout(() => {
                this.inContentIconAppend();
              }, .1);
            } catch(error) {
              this.errorGlossary = true;
              this.glossaryLoadingItems = true;
              this.resourceLoading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getGlossary");
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            this.errorGlossary = true;
            this.glossaryLoadingItems = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getGlossary");
            error.error.message += errorLocation;
            throw error.error;
          });
    } catch(error) {
      this.errorGlossary = true;
      this.glossaryLoadingItems = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getGlossary");
      error.message += errorLocation;
      throw error;
    }
  }
    
/**********************************************************************************
     Method Name: get Training and FAQS
      Description: Get and display data from myResources

   #   Date            Modified by                  Description
   1   04/24/19        Jayson Sevilla               Initial draft     
  **********************************************************************************/

 getTrainingFAQs(){
  try {
    this.resourceLoading = true;
    this.getEquityTraining();
    this.getTrainingFAQList();
    // this.getSneTrading();
    // this.getVeipFAQ();
    // this.getEsppFAQ();
    // this.getFctFAQ();
    // this.getOptionsRsuFAQ();
    // this.getDepartureConsiderations();
    // this.getTrainingVeip();
    // this.getTrainingEspp();
    // this.getTrainingDividends();
    this.getTrainingVeip();
    this.getTrainingEspp();
    this.getTrainingDividends();
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getTrainingFAQs");
    error.message += errorLocation;
    throw error;
  }
}

  async getShareIncentive(){
    try {
      this.bodyData[5] = PageSection.programGuideShareIncentives;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
         if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.shareIncentiveContent = contentBody[0].Body_Desc;          
            }
            else{
              this.shareIncentiveContent = null;
            }
          }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getShareIncentive");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorProgramGuidebooks = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getShareIncentive");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getShareIncentive");
      error.message += errorLocation;
      throw error;
    }
  }

  async getRSUGuidebooks(){
    try {
      this.bodyData[5] = PageSection.programGuideRestrictedShare;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.restrictedShareUnitContent = contentBody[0];  
              
              // for(let i=0; i<this.restrictedShareUnitContent.length; i++){
              //   if(this.restrictedShareUnitContent[i]["Body_Desc"].indexOf("Australia Offer Document") != -1){
              //     this.restrictedShareUnitContent[i]["Body_Desc"] = this.restrictedShareUnitContent[i]["Body_Desc"].replace('<a href="2010%20Amended%20SIP%20Prospectus_Australia%20(2018%20version).pdf" target="_blank">','<a aria-label="Australia Offer Document" href="2010%20Amended%20SIP%20Prospectus_Australia%20(2018%20version).pdf" target="_blank">');
              //   }
              //   if(this.restrictedShareUnitContent[i]["Body_Desc"].indexOf('<a href="RSU%20Guidebook_English.pdf" target="_blank">English</a>') != -1){
              //     this.restrictedShareUnitContent[i]["Body_Desc"] = this.restrictedShareUnitContent[i]["Body_Desc"].replace('<a href="RSU%20Guidebook_English.pdf" target="_blank">English</a>','<a aria-label="Guidebook English" href="RSU%20Guidebook_English.pdf" target="_blank">English</a>');
              //   }
              //   if(this.restrictedShareUnitContent[i]["Body_Desc"].indexOf('<a href="RSU%20Guidebook%20Spanish.pdf" target="_blank">Spanish</a>') != -1){
              //     this.restrictedShareUnitContent[i]["Body_Desc"] = this.restrictedShareUnitContent[i]["Body_Desc"].replace('<a href="RSU%20Guidebook%20Spanish.pdf" target="_blank">Spanish</a>','<a aria-label="Guidebook Spanish" href="RSU%20Guidebook%20Spanish.pdf" target="_blank">Spanish</a>');
              //   }
              // }
              
              if(this.restrictedShareUnitContent["Body_Desc"].indexOf("Australia Offer Document") != -1){
                this.restrictedShareUnitContent["Body_Desc"] = this.restrictedShareUnitContent["Body_Desc"].replace('<a href="2010%20Amended%20SIP%20Prospectus_Australia%20(2018%20version).pdf" target="_blank">','<a aria-label="Australia Offer Document" href="2010%20Amended%20SIP%20Prospectus_Australia%20(2018%20version).pdf" target="_blank">');
              }
              if(this.restrictedShareUnitContent["Body_Desc"].indexOf('<a href="RSU%20Guidebook_English.pdf" target="_blank">English</a>') != -1){
                this.restrictedShareUnitContent["Body_Desc"] = this.restrictedShareUnitContent["Body_Desc"].replace('<a href="RSU%20Guidebook_English.pdf" target="_blank">English</a>','<a aria-label="Guidebook English" href="RSU%20Guidebook_English.pdf" target="_blank">English</a>');
              }
              if(this.restrictedShareUnitContent["Body_Desc"].indexOf('<a href="RSU%20Guidebook%20Spanish.pdf" target="_blank">Spanish</a>') != -1){
                this.restrictedShareUnitContent["Body_Desc"] = this.restrictedShareUnitContent["Body_Desc"].replace('<a href="RSU%20Guidebook%20Spanish.pdf" target="_blank">Spanish</a>','<a aria-label="Guidebook Spanish" href="RSU%20Guidebook%20Spanish.pdf" target="_blank">Spanish</a>');
              }

              this.restrictedShareUnitContent = this.restrictedShareUnitContent.Body_Desc;
            }
            else{
              this.restrictedShareUnitContent = null;
            }
         }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getRSUGuidebooks");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getRSUGuidebooks");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getRSUGuidebooks");
      error.message += errorLocation;
      throw error;
    }
  }

  async getShareOptions(){
    try {
      this.bodyData[5] = PageSection.programGuideShareOption;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);   
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.shareOptionsGuide = contentBody[0].Body_Desc;          
            }
            else{
              this.shareOptionsGuide = null;
            }
         }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getShareOptions");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getShareOptions");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getShareOptions");
      error.message += errorLocation;
      throw error;
    }
  }

  async getPurchaseProgramVEIP(){
    try {
      this.bodyData[5] = PageSection.programGuidePPVEIP;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body); 
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
         if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.purchaseProgramGuideVEIP = contentBody[0].Body_Desc;        
            }
            else{
              this.purchaseProgramGuideVEIP = null;
            }
         }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramVEIP");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramVEIP");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramVEIP");
      error.message += errorLocation;
      throw error;
    }
  }

  async getPurchaseProgramESPP(){
    try {
      this.bodyData[5] = PageSection.programGuidePPESPP;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.purchaseProgramGuideESPP = contentBody[0].Body_Desc; 
            }
            else{
              this.purchaseProgramGuideESPP = null;
            }
          }
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramESPP");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramESPP");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramESPP");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getExpatInfo
      Description: Program Guidebook sub accordion

      Date            Modified by       Description    
      07/04/19        KMN               Bug fix 379248
  **********************************************************************************/
  async getExpatInfo(){
    try {
      this.bodyData[5] = PageSection.programGuideExpatriate;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.expatInformations = contentBody[0].Body_Desc;
            }
            else{
              this.expatInformations = null;
            }
         }
          this.getArchiveVEIP();
        } catch(error) {
          this.errorProgramGuidebooks = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getExpatInfo");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorProgramGuidebooks = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getExpatInfo");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorProgramGuidebooks = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getExpatInfo");
      error.message += errorLocation;
      throw error;
    }
  }

  async getArchiveVEIP(){
    try {
      this.bodyData[5] = PageSection.programGuideArchiveVEIP;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  

          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data.length > 2){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.programArchiveVEIP = contentBody;  
              
              for (let i = 0; i < contentBody.length; i++){
                // this.currYrTaxPrepDocDesc = this.taxPrepData[i].Document_Desc;
                // this.currYrTaxPrepDocName = this.taxPrepData[i].Document_Name;
                // this.currYrTaxPrepFileTyp = this.taxPrepData[i].File_Type;
      
                if(this.programArchiveVEIP[i].File_Type == "pdf") {
                  this.programArchiveVEIP[i].fileIcon = FileType.pdf;
                 } else if(this.programArchiveVEIP[i].File_Type == "docx") {
                  this.programArchiveVEIP[i].fileIcon = FileType.doc;
                 } else if(this.programArchiveVEIP[i].File_Type == "doc") {
                  this.programArchiveVEIP[i].fileIcon = FileType.doc;
                 } else if(this.programArchiveVEIP[i].File_Type == "xls") {
                  this.programArchiveVEIP[i].fileIcon = FileType.xls;
                 } else if(this.programArchiveVEIP[i].File_Type == "xlsx") {
                  this.programArchiveVEIP[i].fileIcon = FileType.xls;
                 } else if(this.programArchiveVEIP[i].File_Type == "pptx") {
                  this.programArchiveVEIP[i].fileIcon = FileType.ppt;
                 } else if(this.programArchiveVEIP[i].File_Type == "ppt") {
                  this.programArchiveVEIP[i].fileIcon = FileType.ppt;
                 } else if(this.programArchiveVEIP[i].File_Type == "zip") {
                  this.programArchiveVEIP[i].fileIcon = FileType.zip;
                 } 
              }  
            }
            else{
              this.programArchiveVEIP = null;
            }
          }
          this.getArchiveESPP();
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getArchiveVEIP");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            let errorLocation = this.errorLocation.replace("methodName", "getArchiveVEIP");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getArchiveVEIP");
      error.message += errorLocation;
      throw error;
    }
  }

  async getArchiveESPP(){
    try {
      this.bodyData[5] = PageSection.programGuideArchiveESPP;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.programArchiveESPP = contentBody; 
              
              for (let i = 0; i < contentBody.length; i++){
                // this.currYrTaxPrepDocDesc = this.taxPrepData[i].Document_Desc;
                // this.currYrTaxPrepDocName = this.taxPrepData[i].Document_Name;
                // this.currYrTaxPrepFileTyp = this.taxPrepData[i].File_Type;
      
                if(this.programArchiveESPP[i].File_Type == "pdf") {
                  this.programArchiveESPP[i].fileIcon = FileType.pdf;
                 } else if(this.programArchiveESPP[i].File_Type == "docx") {
                  this.programArchiveESPP[i].fileIcon = FileType.doc;
                 } else if(this.programArchiveESPP[i].File_Type == "doc") {
                  this.programArchiveESPP[i].fileIcon = FileType.doc;
                 } else if(this.programArchiveESPP[i].File_Type == "xls") {
                  this.programArchiveESPP[i].fileIcon = FileType.xls;
                 } else if(this.programArchiveESPP[i].File_Type == "xlsx") {
                  this.programArchiveESPP[i].fileIcon = FileType.xls;
                 } else if(this.programArchiveESPP[i].File_Type == "pptx") {
                  this.programArchiveESPP[i].fileIcon = FileType.ppt;
                 } else if(this.programArchiveESPP[i].File_Type == "ppt") {
                  this.programArchiveESPP[i].fileIcon = FileType.ppt;
                 } else if(this.programArchiveESPP[i].File_Type == "zip") {
                  this.programArchiveESPP[i].fileIcon = FileType.zip;
                 } 
              }  
            }
            else{
              this.programArchiveESPP = null;
            }
          }
          this.resourceLoading = false;
          setTimeout(()=>{ this.inContentIconAppend() }, 100);
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getArchiveESPP");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            let errorLocation = this.errorLocation.replace("methodName", "getArchiveESPP");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getArchiveESPP");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getEquityTraining
      Description: Training & FAQs - Equity Training

      Date            Modified by       Description    
      03/11/19        Roldan Mazo       Modify Initialization with In-App Data
      3/12/2019       Roldan Mazo       Modify In-App, include Null in the condition
  **********************************************************************************/
  getEquityTraining(){
    try {
      // this. trainingFAQLoading = true;
      this.bodyData[5] = PageSection.trainingFAQEquityTraining;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);    
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;            
    
            if(contentBody.length > 0){
              this.equityTrainingContent = contentBody[0].Body_Desc; 
            }
            else{
              this.equityTrainingContent = null;
            }
         }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEquityTraining");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getEquityTraining");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getEquityTraining");
      error.message += errorLocation;
      throw error;
    }
  }

  getTrainingFAQList(){
    try {
      this.bodyData[5] = PageSection.trainingFAQList;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;

            if(contentBody.length > 0) {
              this.FAQList = contentBody;
  
              for(let i=0; i<this.FAQList.length; i++){

                if(this.FAQList[i]["Body_Desc"].indexOf("<u>") != -1){
                  this.FAQList[i]["Body_Desc"] = this.FAQList[i]["Body_Desc"].split("<u>").join("<span style='text-decoration: underline;'>");
                  this.FAQList[i]["Body_Desc"] = this.FAQList[i]["Body_Desc"].split("</u>").join("</span>");
                }
                if(this.FAQList[i]["Body_Desc"].indexOf('<p align="left">&#160;</p>') != -1){
                  this.FAQList[i]["Body_Desc"] = this.FAQList[i]["Body_Desc"].replace('<p align="left">&#160;</p>','');
                }
                if(this.FAQList[i]["Body_Desc"].indexOf('<font size="1">') != -1){
                  this.FAQList[i]["Body_Desc"] = this.FAQList[i]["Body_Desc"].split('<font size="1">').join("");
                  this.FAQList[i]["Body_Desc"] = this.FAQList[i]["Body_Desc"].split('</font>').join("");
                }
              } 
            } else {
              this.FAQList = null;
            }
    
            // if(contentBody.data.length > 0){
            //   this.accentureDividendsHeader = contentBody.data[0].List_Name;
            //   this.accentureDividendsContent = contentBody.data[0].Body_Desc;       
            //   this.showaccentureDividendsHeader = true;   
            // }
            // else{
            //   this.accentureDividendsHeader = null;
            //   this.accentureDividendsContent = null;
            // }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTrainingFAQList");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getTrainingFAQList");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTrainingFAQList");
      error.message += errorLocation;
      throw error;
    }
  }

  getSneTrading(){
    try {
      this.bodyData[5] = PageSection.trainingFAQSneTrading;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);    
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data.length > 2){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.sneTradingHeader = contentBody[0].List_Name;
              this.sneTradingContent = contentBody[0].Body_Desc;    
              this.showsneTradingHeader = true;      
            }
            else{
              this.sneTradingHeader = null;
              this.sneTradingContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getSneTrading");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTrainingFaqs = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getSneTrading");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getSneTrading");
      error.message += errorLocation;
      throw error;
    }
  }

  getVeipFAQ(){
    try {
      this.bodyData[5] = PageSection.trainingFAQVeip;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);   

          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data.length > 2){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.veipFaqHeader = contentBody[0].List_Name;
              this.veipFaqContent = contentBody[0].Body_Desc;         
              this.showveipFaqHeader = true; 
            }
            else{
              this.veipFaqHeader = null;
              this.veipFaqContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getVeipFAQ");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getVeipFAQ");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getVeipFAQ");
      error.message += errorLocation;
      throw error;
    }
  }

  getEsppFAQ(){
    try {
      this.bodyData[5] = PageSection.trainingFAQEspp;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
        try {
          let content = JSON.parse(data.body); 
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.esppFaqHeader = contentBody[0].List_Name;
              this.esppFaqContent = contentBody[0].Body_Desc; 
              this.showesppFaqHeader = true;         
            }
            else{
              this.esppFaqContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEsppFAQ");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getEsppFAQ");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getEsppFAQ");
      error.message += errorLocation;
      throw error;
    }
  }


  getFctFAQ(){
    try {
      this.bodyData[5] = PageSection.trainingFAQFCT;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.fctFaqHeader = contentBody[0].List_Name;
              this.fctFaqContent = contentBody[0].Body_Desc;  
              this.showfctFaqHeader = true;        
            }
            else{
              this.fctFaqHeader = null;
              this.fctFaqContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getFctFAQ");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getFctFAQ");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getFctFAQ");
      error.message += errorLocation;
      throw error;
    }
  }

  getOptionsRsuFAQ(){
    try {
      this.bodyData[5] = PageSection.trainingFAQOptionsAndRSU;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.optionsRsuFaqHeader = contentBody[0].List_Name;
              this.optionsRsuFaqContent = contentBody[0].Body_Desc; 
              this.showoptionsRsuFaqHeader = true;
            }
            else{
              this.optionsRsuFaqHeader = null;
              this.optionsRsuFaqContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getOptionsRsuFAQ");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getOptionsRsuFAQ");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getOptionsRsuFAQ");
      error.message += errorLocation;
      throw error;
    }
  }

  getDepartureConsiderations(){
    try {
      this.bodyData[5] = PageSection.trainingFAQDepartureConsiderations;
      this.bodyData[6] = 1;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.departureConsiderationHeader = contentBody[0].List_Name;
              this.departureConsiderationContent = contentBody[0].Body_Desc;  
              this.showdepartureConsiderationHeader = true;     
            }
            else{
              this.departureConsiderationContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorTrainingFaqs = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
      error.message += errorLocation;
      throw error;
    }
  }

  getTrainingVeip(){
    try {
      this.bodyData[5] = PageSection.trainingArchiveVeip;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);   
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
         if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.archiveVeipContent = contentBody;     
              
              for(let i = 0; i < this.archiveVeipContent.length; i++) {
                this.archiveVeipContent[i].Document_Name = this.archiveVeipContent[i].Document_Name + "." + this.archiveVeipContent[i].File_Type;
              }
            }
            else{
              this.archiveVeipContent = null;
            }
          }
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTrainingVeip");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTrainingFaqs = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTrainingVeip");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTrainingVeip");
      error.message += errorLocation;
      throw error;
    }
  }

  getTrainingEspp(){
    try {
      this.bodyData[5] = PageSection.trainingArchiveEspp;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
         if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.archiveEsppContent = contentBody;
              
              for(let i = 0; i < this.archiveEsppContent.length; i++) {
                this.archiveEsppContent[i].Document_Name = this.archiveEsppContent[i].Document_Name + "." + this.archiveEsppContent[i].File_Type;
                this.archiveEsppContent[i].Files = this.archiveEsppContent[i].File_Type
              }
            }
            else{
              this.archiveEsppContent = null;
            }
         }
    
          // setTimeout(()=>{ this.inContentIconAppend() }, 500);
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTrainingEspp");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTrainingFaqs = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTrainingEspp");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTrainingEspp");
      error.message += errorLocation;
      throw error;
    }
  }

  getTrainingDividends(){
    try {
      this.bodyData[5] = PageSection.trainingArchiveDividends;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body); 
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.archiveDividendsContent = contentBody;  
              for(let i = 0; i < this.archiveDividendsContent.length; i++) {
                this.archiveDividendsContent[i].Document_Name = this.archiveDividendsContent[i].Document_Name + "." + this.archiveDividendsContent[i].File_Type;
              }        
            }
            else{
              this.archiveDividendsContent = null;
            }
          }
          // this. trainingFAQLoading = false;
          this.resourceLoading = false;
          setTimeout(()=>{ this.inContentIconAppend() }, 500);
        } catch(error) {
          this.errorTrainingFaqs = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTrainingDividends");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTrainingFaqs = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTrainingDividends");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorTrainingFaqs = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTrainingDividends");
      error.message += errorLocation;
      throw error;
    }
  }


  // download file code from dale's build
  /**********************************************************************************
      Method Name: getFileName
      Description: Download file

      #     Date            Modified by         Description   
      1     05/01/19        KMN                 BUG 295531 fix - Remove filename text
      2     05/22/19        KMN                 Bug 298990 fix Page should be redirected to Contacts page after clicking "Contacts" link.
      3     05/22/19        KMN                 Bug 298990 fix - Page should be redirected to Contacts page after clicking "Contacts" link.
      4     05/23/19        KMN                 Bug 294439 fix - Document was not downloaded - Training & FAQs
      5     05/28/19        Dalemark Suquib     Bug 329689 fix - Other links is not working in tools and reports tab in resources page
      6     07/04/19        KMN                 Bug 378648 fix
  **********************************************************************************/
  getFileName(eventBody) {
    try {
      let href: string = eventBody.target.href;
      let id: string = eventBody.target.id;
      let srcElement: HTMLImageElement = eventBody.srcElement;
      let target: string = eventBody.target.target;
      let reportId: object;
      
      if(srcElement.src != undefined){
        this.staticContent.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContent.validateFileType(event);
        } else if(href.indexOf("reportid") != -1) {
          id = href.substring(href.lastIndexOf("=") + 1);
          reportId = {'ReportTypeId': id};
          sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
          window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
          return false;
        } else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          let innerTxt = eventBody.target.innerText;
          event.preventDefault();

          if(target == "_blank") {
            if(innerTxt in MyHoldingsPagesResources){
              this.navToMyHoldingsPage(href, "_blank");
            }else if(href.indexOf("Resources") != -1){
              this.navToMyHoldingsPage("Resources","_blank");
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              this.navToMyHoldingsPage("Reports","_blank");
            }
          } else {
            if(href.indexOf("Resources") != -1){
              eventBody.target.href = this.globals.currentWebUrl + "/resourcespage";
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              eventBody.target.href = this.globals.currentWebUrl + "/reportspage";
            }
          }
          

        }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
          if(target == "_blank") {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts","_blank") : "";
          } else {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts","_self"): "";
          }
        } 
        
        // else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
        //   eventBody.preventDefault();
        //   window.open(href, target);
        // }else{ //if has file but no filename text
        //   event.preventDefault();
        //   this.staticContent.validateFileType(event);
        // }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  /**************************************************************************************************
      Method Name: navToMyHoldingsPage
      Description: Redirect to main myholdings page

      Date            Modified by                 Description    
      05/21/19        KMN                         Iitial draft
      07/04/19        KMN                         Bug 378648 fix
*****************************************************************************************************/
  navToMyHoldingsPage(page, redirect){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "contactspage" : "";
    page.indexOf("Reports") != -1 ? page = "reportspage" : "";

    window.open(this.globals.currentWebUrl + "/" + page, redirect);
    return false;
  }

  /**************************************************************************************************
      Method Name: getFile
      Description: Get file from API then download

      Date            Modified by                 Description    
      04/30/19        Katrina Narag               Added line for no file exists
*****************************************************************************************************/
  getFile(fileName) {
    try {
      let fileType: string;
  
      if(fileName.indexOf(".pdf") != -1) {
        fileType = "application/pdf";
      } else {
        fileType = "mime_type";
      }
    
      this.staticContent.getFile(fileName, "MD").subscribe(data => {
        try {
          let content = data;
  
          if(content == null || content == ""){
            this.staticContent.noFileExists(fileName);
          }else{
            let file = JSON.parse(data.body);
            this.staticContent.downloadFile(fileName, fileType, this.staticContent.base64ToArrayBuffer(file.file));
            this.staticContent.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
              let writeLogResponse = data;
            });
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFile");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.message += errorLocation;
      throw error;
    }
   }

   getFileObject(file, checker) {
    try {
      let fileName: string;
      let fileType: string;
    
      if(checker == "static") {
        if(file.File_Type.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
        
        fileName = file.Document_Name + "." + file.File_Type;
      } else {
        if(file.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
    
        fileName = file;
      }
    
      this.staticContent.getFile(fileName, "MD").subscribe(data => { // MD for contacts page
        try {
          let file = JSON.parse(data.body);

          this.staticContent.downloadFile(fileName, fileType, this.staticContent.base64ToArrayBuffer(file.file));
          this.staticContent.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
          });
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFileObject");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFileObject");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileObject");
      error.message += errorLocation;
      throw error;
    }
   }

 /**********************************************************************************
      Method Name: selectResources
      Description: Display accordion based on param

      #     Date            Modified by         Description   
      1     05/01/19        KMN                 BUG 296045 fix - Set user's country on load
      2     09/02/19        JBP                 CRES00082219171733 - Enhancement to Resource page of myHoldings
  **********************************************************************************/
 selectResources(resource){
  try {
    this.resourceLoading = false;
    if(resource == "ImportantDates"){
      this.resourceHide();
      this.displayImportantD = true;
      }else if(resource == "TrainingFAQs"){
      this.resourceHide();
      this.displayTrainingF = true;
      }else if(resource == "ProgramGuidebooks"){
      this.resourceHide();
      this.displayProgramG = true;
      }
      //  JBP START: CRES00082219171733 - Enhancement to Resource page of myHoldings
      else if(resource == "Dividend"){
      this.resourceHide();
      this.displayDividend = true;
      // else if(resource == "EquityCommunications"){
      // this.resourceHide();
      // this.displayEquityC = true;
      // }else if(resource == "TaxTime"){
      // this.resourceHide();
      // this.displayTaxTime = true;
      // }
      //  JBP END: CRES00082219171733 - Enhancement to Resource page of myHoldings
      }else if(resource == "CountrySTI"){
      this.resourceHide();
      this.displayCountrySTI = true;
      this.countryValue = this.user.CountryName;
      this.getCountryTaxList(this.user.CountryName);
      }else if(resource == "ToolsReports"){  
      this.resourceHide();
      this.displayToolsR = true;
      }else if(resource == "PoliciesOL"){  
      this.resourceHide();
      this.displayPoliciesOL = true;
      }else if(resource == "Glossary"){
      this.resourceHide();
      this.displayGlossary = true;
      }else if(resource == "TopQuestions"){
        this.resourceHide();
        this.displayTopQuestions = true;
      }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "selectResources");
    error.message += errorLocation;
    throw error;
  }
 }

 resourceHide(){
  try {
    this.displayImportantD = false;
    this.displayTrainingF = false;
    this.displayProgramG = false; 
    // this.displayEquityC = false;         //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
    this.displayDividend = false;          //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
    this.displayCountrySTI = false;
    this.displayTaxTime = false;
    this.displayToolsR = false;
    this.displayPoliciesOL = false;
    this.displayGlossary = false; 
    this.displayTopQuestions = false;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "resourceHide");
    error.message += errorLocation;
    throw error;
  }
 }

 selectedAlphabet(alphabet){
  try {
    this.setAlphabet = alphabet;
    this.getGlossary();  
  } catch(error) {
    this.errorGlossary = true;
    this.glossaryLoadingItems = false;
    this.resourceLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "selectedAlphabet");
    error.message += errorLocation;
    throw error;
  }
 }

/*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    05/27/19             Joshua Paul J. Avecilla(JPA)          Calls method for displaying file icons
*****************************************************************************************/
 async inContentIconAppend(){
  this.staticContent.inContentIconAppend();
}

// equity communications start
/*  JBP START: CRES00082219171733 - Enhancement to Resource page of myHoldings
getEquityCommunications(){
  try {
    this.resourceLoading = true;
    let empType = this.bodyData[2];

    if(empType == "Senior Executive"){
      this.displaySrExecMemo = true;
      this.displayEqComsVeip = true;
      this.displayEqComsEspp = false;
      this.getSrExecTrading();
      this.getEqComsVeip();
      this.getAccDivMemo();
      this.getEqComsLastYear();
    }else if(empType == "Employees"){
      this.displayEqComsEspp = true;
      this.displaySrExecMemo = false;
      this.displayEqComsVeip = false;
      this.getEqComsEspp();
      this.getAccDivMemo();
      this.getEqComsLastYear();
    } 
      // this.getAccDivMemo();

      // this.resourceLoading = true;
      // setTimeout(()=>{ this.resourceLoading = false }, 300);
  } catch(error) {
    this.errorEquityCommunications = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getEquityCommunications");
    error.message += errorLocation;
    throw error;
  }
}

getSrExecTrading(){
  try {
    this.bodyData[5] = PageSection.equityCommunicationsSrExecTrading;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
      try {
        let content = JSON.parse(data._body);    

        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if(content.body.length > 2){
          let contentBody = JSON.parse(content.body);
    
          if(contentBody.data.length > 0){
            this.equityComsSrExecTradingMemo = contentBody.data;  
          }
          else{
            this.equityComsSrExecTradingMemo = null;
          }
        }
      } catch(error) {
        this.errorEquityCommunications = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getSrExecTrading");
        error.message += errorLocation;
        throw error;
      }
         }, error => {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getSrExecTrading");
          error.error.message += errorLocation;
          throw error.error;
         });
  } catch(error) {
    this.errorEquityCommunications = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getSrExecTrading");
    error.message += errorLocation;
    throw error;
  }
}

getEqComsVeip(){
  try {
    this.bodyData[5] = PageSection.equityCommunicationsVeip;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
      try {
        let content = JSON.parse(data._body);   
        
        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if(content.body.length > 2){
          let contentBody = JSON.parse(content.body);
    
          if(contentBody.data.length > 0){
            this.equityComsVeip = contentBody.data;        
          }
          else{
            this.equityComsVeip = null;
          }
        }
      } catch(error) {
        this.errorEquityCommunications = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getEqComsVeip");
        error.message += errorLocation;
        throw error;
      }
         }, error => {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEqComsVeip");
          error.error.message += errorLocation;
          throw error.error;
         });
  } catch(error) {
    this.errorEquityCommunications = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getEqComsVeip");
    error.message += errorLocation;
    throw error;
  }
}

getEqComsEspp(){
  try {
    this.bodyData[5] = PageSection.equityCommunicationsEspp;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
      try {
        let content = JSON.parse(data._body); 
        
        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if(content.body.length > 2){
          let contentBody = JSON.parse(content.body);
    
          if(contentBody.data.length > 0){
            this.equityComsEspp = contentBody.data;  
          }
          else{
            this.equityComsEspp = null;
          }
        }
      } catch(error) {
        this.errorEquityCommunications = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getEqComsEspp");
        error.message += errorLocation;
        throw error;
      }
         }, error => {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEqComsEspp");
          error.error.message += errorLocation;
          throw error.error;
         });
  } catch(error) {
    this.errorEquityCommunications = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getEqComsEspp");
    error.message += errorLocation;
    throw error;
  }
}

getAccDivMemo(){
  try {
    this.bodyData[5] = PageSection.equityCommunicationsAccentureDividendMemos;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => {
      try {
        let content = JSON.parse(data._body);  
        
        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if(content.body.length > 2){
          let contentBody = JSON.parse(content.body);
    
          if(contentBody.data.length > 0){
            this.equityComsAccDivMemo = contentBody.data;        
          }
          else{
            this.equityComsAccDivMemo = null;
          }
        }
      } catch(error) {
        this.errorEquityCommunications = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getAccDivMemo");
        error.message += errorLocation;
        throw error;
      }
         }, error => {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getAccDivMemo");
          error.error.message += errorLocation;
          throw error.error;
         });
  } catch(error) {
    this.errorEquityCommunications = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getAccDivMemo");
    error.message += errorLocation;
    throw error;
  }
}

getEqComsLastYear(){
  try {
    this.bodyData[5] = PageSection.equityCommunicationsLastYear;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
      try {
        let content = JSON.parse(data._body); 
        
        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if(content.body.length > 2){
          let contentBody = JSON.parse(content.body);
    
          if(contentBody.data.length > 0){
            this.equityComsLastyear = contentBody.data;        
          }
          else{
            this.equityComsLastyear = null;
          }
        }
        
      this.getEqComsPrevYear();
      } catch(error) {
        this.errorEquityCommunications = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getEqComsLastYear");
        error.message += errorLocation;
        throw error;
      }
         }, error => {
          this.errorEquityCommunications = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getEqComsLastYear");
          error.error.message += errorLocation;
          throw error.error;
         });
  } catch(error) {
    this.errorEquityCommunications = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getEqComsLastYear");
    error.message += errorLocation;
    throw error;
  }
}

getEqComsPrevYear(){
  try {
    this.bodyData[5] = PageSection.equityCommunicationsPreviousYear;
    this.bodyData[6] = 0;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
      try {
        let content = JSON.parse(data._body); 
        
        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }
  
        if(content.body.length > 2){
          let contentBody = JSON.parse(content.body);
    
          if(contentBody.data.length > 0){
            this.equityComsPrevYear = contentBody.data;        
          }
          else{
            this.equityComsPrevYear = null;
          }
        }

        this.resourceLoading = false;
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getEqComsPrevYear");
        error.message += errorLocation;
        throw error;
      }
         }, error => {
          let errorLocation = this.errorLocation.replace("methodName", "getEqComsPrevYear");
          error.error.message += errorLocation;
          throw error.error;
         });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getEqComsPrevYear");
    error.message += errorLocation;
    throw error;
  }
}
JBP END: CRES00082219171733 - Enhancement to Resource page of myHoldings  */
// end of equity communications

getTopQuestions(){
  try {
    // this.topQuestionsLoading = true;
    this.resourceLoading = true;
    this.bodyData[5] = PageSection.topQuestions;
    this.bodyData[6] = 1;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let content = JSON.parse(data.body);

        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }

        if(content.data.length > 2) { // length > {}
          let topQuestionsContent = content.data;
          if(topQuestionsContent.length > 0) {
            this.topQuestions = topQuestionsContent;

            for(let i=0; i<this.topQuestions.length; i++){
              if(this.topQuestions[i]["Body_Desc"].indexOf("<u>") != -1){
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].split("<u>").join("<span style='text-decoration: underline;'>");
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].split("</u>").join("</span>");
              }
              if(this.topQuestions[i]["Body_Desc"].indexOf('<p align="left">&#160;</p>') != -1){
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<p align="left">&#160;</p>','');
              }
              if(this.topQuestions[i]["Body_Desc"].indexOf('<font size="1">') != -1){
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].split('<font size="1">').join("");
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].split('</font>').join("");
              }
            } 
          } else {
            this.topQuestions = null;
          }
        } 
        setTimeout(()=>{ this.inContentIconAppend() }, 500);
        // this.topQuestionsLoading = false;
        this.resourceLoading = false;
      } catch(error) {
        this.errorTopQuestions = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorTopQuestions = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorTopQuestions = true;
    this.resourceLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
    error.message += errorLocation;
    throw error;
  }
}

// contry specific tax

getCountryTaxList(country?){
  try {
    if(country == null || country == undefined || country == ""){
      // this.countryLoading = true;
    this.resourceLoading = true;
    
      this.staticContent.getCountryList().subscribe(data => {
        this.resourcesCountries = data[0];
        this.resourceLoading = false
      }, error => {
        this.errorCountrySpecific = true;
        this.countryLoading = false;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getCountryTaxList");
        error.error.message += errorLocation;
        throw error.error;
      });
      }else{
        this.countryLoading = true;
    
        this.countryValue = country;
        this.resourcesCountryTaxFiles = [];
        
        country = country.replace('/','%2F');

        this.staticContent.getCountrySpecificTaxFile(country).subscribe(data => {
          try {
            let files = data[0];

            this.resourcesCountryTaxFiles = files;
            setTimeout(()=>{ this.countryLoading = false }, 300);
          } catch(error) {
            this.errorCountrySpecific = true;
            this.countryLoading = false;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getCountryTaxList");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          this.errorCountrySpecific = true;
          this.countryLoading = false;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getCountryTaxList");
          error.error.message += errorLocation;
          throw error.error;
        });
      }
  } catch(error) {
    this.errorCountrySpecific = true;
    this.countryLoading = false;
    this.resourceLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getCountryTaxList");
    error.message += errorLocation;
    throw error;
  }
}

getFileResource(file, event) {
  try {
    let filePath = file.SourceURL;     
    let fileName = file.FileDesc + "." + file.FileType;
    let fileType: string;
    let href: string = event.target.href;

    event.preventDefault();
   
    if(filePath.includes(".pdf") || filePath.includes(".zip")){
      if(file.FileType.indexOf('pdf') != -1) {
       fileType = 'application/pdf';
      } else {
       fileType = 'mime_type';
      }

      if(filePath.includes(".zip")) {
        fileName = filePath;
      }

      this.staticContent.getFile(fileName, "MD").subscribe(data =>{
        try {
          let content = data;
  
          if (content != null){    
            let file = JSON.parse(data.body);

            this.downloadFile(fileName, fileType, this.base64ToArrayBuffer(file.file));
            this.staticContent.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
              let writeLogResponse = data;
            });
          }else{
            this.staticContent.noFileExists(fileName);
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFileResource");
          error.message += errorLocation;
          throw error;
        }
     }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFileResource");
      error.error.message += errorLocation;
      throw error.error;
     });
  
    }else{
      window.open(filePath, "_blank")
    }
  
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFileResource");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
Method Name: base64ToArrayBuffer
Description: Converts base 64 to array buffer.

#     Date            Modified by             Description
1     05/28/19        Katrina Narag      Initial Draft
**********************************************************************************************/
base64ToArrayBuffer(base64): Uint8Array {
  try {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);
    
    for(let i = 0; i < binaryLength; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
    }
    return bytes;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "base64ToArrayBuffer");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
Method Name: downloadFile
Description: Downloads the file.

#     Date            Modified by             Description
1     05/28/19        Katrina Narag      Initial Draft
**********************************************************************************************/
downloadFile(reportName, fileType, byte) {
  try {
    if(navigator.appVersion.toString().indexOf('.NET') > 0) { // browser is IE
      let blob = new Blob([byte], {type: fileType});
      window.navigator.msSaveBlob(blob, reportName);
      } else if(navigator.userAgent.indexOf("Firefox") > 0) { // browser is FireFox
      let a = document.createElement('a');
      a.style.display = "none";
      a.style.zIndex = "1002";
      a.style.position = "absolute";
      let blob = new Blob([byte], {type: fileType});
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = reportName;
      document.body.appendChild(a);
      a.click();
      
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
      } else { // browser is Chrome
      let blob = new Blob([byte], {type: fileType});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let fileName = reportName;
      link.download = fileName;
      link.click();
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "downloadFile");
    error.message += errorLocation;
    throw error;
  }
}

// end of country specific tax

fromHighlights(){
  try {


    let highlights = sessionStorage.getItem("ntr");
  
    if(highlights == "fromHighlights" || highlights == "fromHomepage"){

      this.selectResources('TopQuestions');
      this.getTopQuestions();
    }else{
      this.selectResources('ImportantDates');
      this.getImportantDate(); 
    }
  

    sessionStorage.setItem("ntr","no");
  } catch(error) {
    this.errorTopQuestions = true;
    this.resourceLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "fromHighlights");
    error.message += errorLocation;
    throw error;
  }
}

setActiveNav(navID){
  let id = "#" + navID;

  $(".resourcesBodySidenav>.sideNavItemContainer.parentContainer").removeClass("parentContainer");
  $(".resourcesBodySidenav>.sideNavItemContainer>.sideNavItem.active").removeClass("active");

  $(id).addClass("active");
  $(id).parent('div').addClass("parentContainer");

  if(navID == "policiesLink"){
    $("[href='https://publishing.accenture.com/EthicsCompliance']").remove();
  }

  

}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Resourcespage") != -1 && this.user != null) {
                webPage = "Resourcespage";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
  urlCheck(file){
    
    if(file.indexOf('solium') != -1){
     
     this.dynamicURL = file;
     return true
    }else{
      this.dynamicURL = this.env.URL + "/resourcespage";
      return true;
    }
    
  }

  removeLinksjQuery(){
    $("u").contents().unwrap();
    $("[href='https://publishing.accenture.com/EthicsCompliance']").remove();
  }

  normalizeFiletype(orig){
    let transformed = orig.toLowerCase();
    if (transformed.charAt(transformed.length - 1) == "x" && transformed != "docx") {
      transformed = transformed.slice(0, -1);      
    }else if (transformed == "doc") {
      transformed = transformed.concat("x")
    }
    return transformed;
  }

  /***********************************************************************************
   * Method Name: getDividend
   * Description: Calls the service for Dividend Section
   * 
   * #      Date          Modified by                 Description
   * 1      09/17/19      Allan Christopher Salac     Initial draft
   * *********************************************************************************/

  getDividend(){
    try {
      this.resourceLoading = true;
      this.getDividendDoc();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getDividend");
      error.message += errorLocation;
      throw error;
    }
  }

  getDividendDoc(){
    try {
      this.dividendDocs = [];
      this.bodyData[5] = PageSection.dividendDoc;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);

          for(let i = 0; i < content.data.length; i ++){
            var x = content.data[i];
            x["ID"] = i;
            this.dividendDocs.push(x);
          }

          this.getDividendChart();

          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
        } catch(error) {
          this.errorDividend = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getDividendDoc");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorDividend = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getDividendDoc");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorDividend = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDividendDoc");
      error.message += errorLocation;
      throw error;
    }
  }

  getDividendChart(){
    try {
      this.staticContent.getDividendsChart().pipe(map(res => res)).subscribe(data => { 
        try {
          this.dividendChartFY = [];
          this.dividendChartPrice = [];
          let content = data;    

          for(let i = 0; i < content[0].length; i++) {  
            this.dividendChartFY.push(Object.values(content[0][i])[0]);
            this.dividendChartPrice.push(Object.values(content[0][i])[1]);
          }

          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }

          this.chart = new Chart("canvas", {
            type: 'line',
            data: {
                datasets: [{
                    data: this.dividendChartPrice.reverse(),
                    borderColor: "#3cba9f",
                    fill: false
                }],
                labels: this.dividendChartFY.reverse()
            },
            options: {
              legend: {
                display: false
              },
              title: {
                display: true,
                text: 'Annual Dividend Rate'
              },
            },
            responsive: true
          });
  
          this.resourceLoading = false;
        } catch(error) {
          this.errorDividend = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getDividendChart");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorDividend = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getDividendChart");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorDividend = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDividendChart");
      error.message += errorLocation;
      throw error;
    }
  }
 
}
