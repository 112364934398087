import { State, Action, StateContext, Selector, Actions } from '@ngxs/store';
import { ReportItem } from './../models/reportitem-model';
import { Weeklysummary } from './../actions/weeklysummary.actions';

export class ReportItemStateModel{
    reportItems: ReportItem[]
}

@State<ReportItemStateModel>({
    name: 'reportitems',
    defaults: {
        reportItems: []
    }
})

export class ReportItemState {

    @Selector()
    static getReportItems(state: ReportItemStateModel){
        return state.reportItems
    }

    @Selector()
    static getEspp(state: ReportItemStateModel){
        return state.reportItems.filter(item => item.EQUITY_TYPE_ID  == 1)
    }

    @Selector()
    static getRsu(state: ReportItemStateModel){
        return state.reportItems.filter(item => item.EQUITY_TYPE_ID  == 2)
    }
    
    @Selector()
    static getOption(state: ReportItemStateModel){
        return state.reportItems.filter(item => item.EQUITY_TYPE_ID  == 3)
    }

    @Selector()
    static getVeip(state: ReportItemStateModel){
        return state.reportItems.filter(item => item.EQUITY_TYPE_ID  == 4)
    }

    @Selector()
    static getReportTabs(state: ReportItemStateModel){
        const result = [];
        const map = new Map();
        for (const item of state.reportItems) {
            if (item.EQUITY_TYPE_ID > 0 && item.EQUITY_TYPE_ID < 5) {
                if(!map.has(item.EQUITY_TYPE_ID)){
                    map.set(item.EQUITY_TYPE_ID, true);
                    result.push({
                        id: item.EQUITY_TYPE_ID,
                        name: item.EQUITY_TYPE_DESC == "Option" ? "Options" : item.EQUITY_TYPE_DESC,
                        isActive: false
                    });
                }                
            }
        }

        return result
    }

    @Action(Weeklysummary.Add)
    add(
        store : StateContext<ReportItemStateModel>,
        action: Weeklysummary.Add
    ){
        const state = store.getState();
        store.patchState({
            reportItems: [...state.reportItems, action.payload]
        })
    }

    @Action(Weeklysummary.Remove)
    remove(
        store: StateContext<ReportItemStateModel>, 
        action: Weeklysummary.Remove
    ) {
        const state = store.getState();
        store.patchState({
            reportItems: state.reportItems.filter(item => item.SOURCE_ID != action.payload.id)
        })
    } 

    @Action(Weeklysummary.ClearReportItems)
    clear(
        store: StateContext<ReportItemStateModel>
    ) {
        store.setState({
            reportItems: []
        })
    } 

}

export class StaticTextStateModel{
    staticData: object
}

@State<StaticTextStateModel>({
    name: 'weeklysumstatic',
    defaults: {
        staticData: null
    }
})

export class StaticTextState{
    
    @Selector()
    static getStaticData(state: StaticTextStateModel){
        return state
    }
    
    @Action(Weeklysummary.SetStaticData)
    add(
        store : StateContext<StaticTextStateModel>,
        action: Weeklysummary.SetStaticData
    ){
        store.setState({
            staticData: action.payload
        })
    }
}