
import {map} from 'rxjs/operators';
import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Globals } from '../globals';
import { StaticContentService } from '../services/StaticContentService';
import { BrokersPageService } from './BrokersPageService';
import { BrokerType,PageSection, MyHoldingsPagesResources } from '../services/enumerations/enumerations';
import { ErrorNotificationService } from '../services/ErrorNotificationService';

@Component({
  selector: 'app-brokers',
  templateUrl: './brokerspage.component.html',
  styleUrls: ['./brokerspage.component.css']
})
export class BrokersPageComponent implements OnInit {
  loading = true;
  eid: any;
  user: any;
  userDetails: boolean;
  staticContent: any;
  legalNotice: string;
  userprofileInfo: any;
  state: boolean = false;
  displayPersonalBrokers: boolean = true;

  sbDesc: string = "";
  ubsDesc: string = "";
  bigDesc: string = "";
  ncbDesc: string = "";
  globeTaxDesc: string = "";

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];

  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  brokerCount: number;
  fileIcon: string;

  //Personal Data Change
  personalDataChangeContent: any;
  equityProfessionalContent: any;
  assistanceformyHContent: any;
  globalIDs: any;

  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  ubsAccount: number;
  bigAccount: number;
  ncbAccount: number;

  doc: any;
  errorLocation: string = " + [Component: Brokerspage, Method: methodName]";
  errorBrokersPage: boolean = false;

  fileTypeImagePath: string;
  fileTypeAlt: string;


  formerButton: boolean = false;
  showmyRbutton: boolean = false;
  leadershipEmailButton: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private globals: Globals, private staticContentService: StaticContentService, private brokerService: BrokersPageService, private errorNotificationService: ErrorNotificationService) {
    this.staticContentService.navState$.subscribe( (state)=> this.state = state ); 

  }

  ngOnInit() {
    this.checkErrorMessage();
    this.getUserDetails();
    // this.getFooterStaticContent();
    sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest));
    sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Homepage"));
    if(sessionStorage.getItem("footerSetting") == "old"){
      $( "#thePageFooter" ).css("display","none");
      $( "#thePageFooterOld" ).css("display","block");
    }
  }
  
  navToPage(strLoc){
    try {
      this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }
  
  getUserDetails(){
    try {
      if((sessionStorage.getItem(this.globals.esoEnterpriseId) != null && sessionStorage.getItem(this.globals.esoEnterpriseId) != '' && sessionStorage.getItem(this.globals.esoEnterpriseId) != undefined)
      || (sessionStorage.getItem(this.globals.profileInfoKey) != null && sessionStorage.getItem(this.globals.profileInfoKey) != '' && sessionStorage.getItem(this.globals.profileInfoKey) != undefined)){
        this.eid = JSON.parse(sessionStorage.getItem(this.globals.esoEnterpriseId));
        this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
        this.userprofileInfo = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
      }
  
      if(this.user == null || this.user == undefined){
        this.staticContentService.setNavLoginState(false);
        this.userDetails = false;
        this.getAllBrokers();
      }
      else{
        this.staticContentService.setNavLoginState(true);
        this.userDetails = true;
        this.personalDataChange();
        this.getBrokerAccounts();
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getUserDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  // getFooterStaticContent(){
  //   try {
  //     let legalNotice = this.globals.staticLegalNotice;
  //     this.staticContentService.getBluePageStaticContent(legalNotice).subscribe((data) => {
  //       try {
  //         let body = data[0];
  //         this.legalNotice = body.Value;
  //       } catch(error) {
  //         let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
  //         error.message += errorLocation;
  //         throw error;
  //       }
  //     }, error => {
  //       let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
  //       error.error.message += errorLocation;
  //       throw error.error;
  //     }); 
  //   } catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }

  /*********************************************************************************************
    Method Name: getBrokersDetails
    Description: Gets the details of all brokers. 

    #     Date            Modified by             Description
    1     04/02/19        Dalemark P. Suquib      Initial Draft
    2     04/03/19        Dalemark P. Suquib      Added fetching of data of all brokers
    3     04/08/19        Dalemark P. Suquib      Updated the execution of the method
    4     04/12/19        Joshua Paul J. Avecilla Updated conditions for receiving data from myresources.
  **********************************************************************************************/
  getAllBrokers() {
    try {
      this.brokerCount = 0;

      this.staticContentService.getMyRDataNoToken(PageSection.globeTax).pipe(map(res => res)).subscribe(data => {
        try {
          let globeTaxContent = JSON.parse(data.body);
          let globeTaxBody;
          this.brokerCount += 1;
          
         if(globeTaxContent.data) { // length > {}
            globeTaxBody = globeTaxContent.data;
            if (globeTaxBody.length > 0){
            this.globeTaxDesc = globeTaxBody[0].Body_Desc;
            }
            else {
              this.globeTaxDesc = "";
            } 
         }
    
          if(!this.displayPersonalSb) {
            this.staticContentService.getMyRDataNoToken(PageSection.sb).pipe(map(res => res)).subscribe(data => {
              try {
                let sbContent = JSON.parse(data.body);
                let sbBody;
    
                if(sbContent.data) { // length > {}
                  sbBody = sbContent.data;
                  if (sbBody.length > 0){
                  this.sbDesc = sbBody[0].Body_Desc;
                  }
                  else {
                    this.sbDesc = "";
                  }   
               }
                
                this.brokerCount += 1;
                this.checkBrokerCount();
              } catch(error) {
                this.errorBrokersPage = true;
                this.loading = false;
                let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              this.errorBrokersPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
              error.error.message += errorLocation;
              throw error.error;
            });
          } else {
            this.brokerCount += 1;
            this.checkBrokerCount();
          }
      
          if(!this.displayPersonalUbs) {
            this.staticContentService.getMyRDataNoToken(PageSection.ubs).pipe(map(res => res)).subscribe(data => {
              try {
                let ubsContent = JSON.parse(data.body);

                let ubsBody;
      
               if(ubsContent.data) { // length > {}
                  ubsBody = ubsContent.data;
                  if (ubsBody.length > 0){
                  this.ubsDesc = ubsBody[0].Body_Desc;
                  }
                  else {
                    this.ubsDesc = "";
                  }   
                }
                
                this.brokerCount += 1;
                this.checkBrokerCount();
              } catch(error) {
                this.errorBrokersPage = true;
                this.loading = false;
                let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              this.errorBrokersPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
              error.error.message += errorLocation;
              throw error.error;
            });
          } else {
            this.brokerCount += 1;
            this.checkBrokerCount();
          }
    
          if(!this.displayPersonalBig) {
            this.staticContentService.getMyRDataNoToken(PageSection.big).pipe(map(res => res)).subscribe(data => {
              try {
                let bigContent = JSON.parse(data.body);
                let bigBody;
      
               if(bigContent.data) { // length > {}
                  bigBody = bigContent.data;
                  if (bigBody.length > 0){
                  this.bigDesc = bigBody[0].Body_Desc;

                  if(this.bigDesc.indexOf('<font color="#3966bf">') != -1){
                    this.bigDesc=this.bigDesc.replace('<font color="#3966bf">','');
                    this.bigDesc=this.bigDesc.replace('</font>','');
                  }
                  }
                  else {
                    this.bigDesc = "";
                  }  
               }
                
                this.brokerCount += 1;
                this.checkBrokerCount();
              } catch(error) {
                this.errorBrokersPage = true;
                this.loading = false;
                let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              this.errorBrokersPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
              error.error.message += errorLocation;
              throw error.error;
            });
          } else {
            this.brokerCount += 1;
            this.checkBrokerCount();
          }
    
          if(!this.displayPersonalNcb) {
            this.staticContentService.getMyRDataNoToken(PageSection.ncb).pipe(map(res => res)).subscribe(data => {
              try {
                let ncbContent = JSON.parse(data.body);

                let ncbBody;
      
                if(ncbContent.data) { // length > {}
                  ncbBody = ncbContent.data;
      
                  if (ncbBody.length > 0){
                  this.ncbDesc = ncbBody[0].Body_Desc;
                }
                  else {
                    this.ncbDesc = "";
                  } 
                 
                }
                
                this.brokerCount += 1;
                this.checkBrokerCount();
              } catch(error) {
                this.errorBrokersPage = true;
                this.loading = false;
                let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              this.errorBrokersPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
              error.error.message += errorLocation;
              throw error.error;
            });
          } else {
            this.brokerCount += 1;
            this.checkBrokerCount();
          }
    
          this.checkBrokerCount();
        } catch(error) {
          this.errorBrokersPage = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorBrokersPage = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorBrokersPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getAllBrokers");
      error.message += errorLocation;
      throw error;
    }

    
  }

  /*********************************************************************************************
    Method Name: getBrokerAccounts
    Description: Gets the brokers of the user. 

    #     Date            Modified by             Description
    1     04/05/19        Dalemark P. Suquib      Initial Draft
    2     04/08/19        Dalemark P. Suquib      Updated the method that calls the data
    3     04/12/19        Joshua Paul J. Avecilla Updated conditions for receiving data from myresources.
    4     04/16/19        Katrina Narag (KMN)     BUG 282491 - MS Documents are not displayed
  **********************************************************************************************/
 getBrokerAccounts() {
  try {
    this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      try {
        let brokerAccounts = data[0];
  
        if(brokerAccounts.length > 0) {
          // user's brokers
          for(let i = 0; i < brokerAccounts.length; i++) {
            if(brokerAccounts[i].Broker == BrokerType[1]) { // MS
              this.getGlobalID();
    
              this.bodyData[5] = PageSection.sb;
              this.bodyData[6] = 1;
    
              this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
                try {
                  let msData = JSON.parse(data.body);
                  let msBody;
      
                  if(msData.data) { // length > {}
                  msBody = msData.data;
      
                    if (msBody.length > 0){
                      this.sbDesc = msBody[0].Body_Desc;
                      this.displayPersonalSb = true;
                      }
                    else {
                      this.displayPersonalSb = false;
                      this.sbDesc = "";
                    } 
                  }
      
                  this.bodyData[5] = PageSection.sbDoc;
                  this.bodyData[6] = 0;
      
                  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB
                    try {
                      let msDocData = JSON.parse(data.body);
                      let msDocBody;
                      
                      if(msDocData.data) { // length > {}
                        msDocBody = msDocData.data;

                        if(msDocBody.length > 0) {
                          this.sbDoc = msDocBody.data;

                          for(let i=0; i<this.sbDoc.length; i++){
                            this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                          }
                        }
                        else{
                          this.sbDoc = null;
                        }
                      }
                    } catch(error) {
                      let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                      error.message += errorLocation;
                      throw error;
                    }
                  }, error => {
                    let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                    error.error.message += errorLocation;
                    throw error.error;
                  });
                } catch(error) {
                  let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                  error.message += errorLocation;
                  throw error;
                }
              }, error => {
                let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                error.error.message += errorLocation;
                throw error.error;
              });
            } else if(brokerAccounts[i].Broker == BrokerType[2]) { // UBS
              this.ubsAccount = brokerAccounts[i].Account;
              this.bodyData[5] = PageSection.ubs;
              this.bodyData[6] = 1;
    
              this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\UBS
                try {
                  let ubsData = JSON.parse(data.body);
                  let ubsBody;

                  if(ubsData.data) { // length > {}
                    ubsBody = ubsData.data;

                    if(ubsBody.length > 0) {
                      this.ubsDesc = ubsBody[0].Body_Desc;

                      this.displayPersonalUbs = true;
                    }
                    else {
                    this.displayPersonalUbs = false;
                      this.ubsDesc = "";
                    }
                  }
      
                  this.bodyData[5] = PageSection.ubsDoc;
                  this.bodyData[6] = 0;
      
                  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\UBS
                    try {
                      let ubsDocData = JSON.parse(data.body);
                      let ubsDocBody;
        
                      if(ubsDocData.data) { // length > {}
                        ubsDocBody = ubsDocData.data;

                        if(ubsDocBody.length > 0){
                          this.ubsDoc = ubsDocBody;
        
                          for(let i=0; i<this.ubsDoc.length; i++){
                            this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
                          }
                        }
                        else{
                          this.ubsDoc = null;
                        }
                      }
                    } catch(error) {
                      let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                      error.message += errorLocation;
                      throw error;
                    }
                  }, error => {
                    let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                    error.error.message += errorLocation;
                    throw error.error;
                  });
                } catch(error) {
                  let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                  error.message += errorLocation;
                  throw error;
                }
              }, error => {
                let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                error.error.message += errorLocation;
                throw error.error;
              });
            } else if(brokerAccounts[i].Broker == BrokerType[3]) { // BIG
              this.bigAccount = brokerAccounts[i].Account;
              this.bodyData[5] = PageSection.big;
              this.bodyData[6] = 1;
    
              this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\BIG 
                try {
                  let bigData = JSON.parse(data.body);
                  let bigBody;
      
                  if(bigData.data) { // length > {}
                    bigBody = bigData.data;

                    if(bigBody.length > 0){

                      this.bigDesc = bigBody[0].Body_Desc;
                      this.displayPersonalBig = false;
                    }else {
                      this.displayPersonalBig = false;
                      this.bigDesc = "";
                    }
                  } 
                } catch(error) {
                  let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                  error.message += errorLocation;
                  throw error;
                }
              }, error => {
                let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                error.error.message += errorLocation;
                throw error.error;
              });
            } else if(brokerAccounts[i].Broker == BrokerType[4]) { // TA
              this.ncbAccount = brokerAccounts[i].Account;
              this.bodyData[5] = PageSection.ncb;
              this.bodyData[6] = 1;
    
              this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\NCB
                try {
                  let taData = JSON.parse(data.body);

                  let taBody;
                  if(taData.data) { // length > {}
                    taBody = taData.data;
                    if(taBody.length > 0){
                    this.ncbDesc = taBody[0].Body_Desc;
                    this.displayPersonalNcb = true;
                    }else {
                      this.displayPersonalNcb = false;
                      this.ncbDesc = "";
                    }
                  } 
      
                  this.bodyData[5] = PageSection.ncbDoc;
                  this.bodyData[6] = 0;
      
                  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\NCB
                    try {
                      let taDocData = JSON.parse(data.body);
                      let taDocBody;
        
                      if(taDocData.data) { // length > {}
                        taDocBody = taDocData.data;

                        if(taDocBody.length > 0){
                          this.ncbDoc = taDocBody.data;

                          for(let i=0; i<this.ncbDoc.length; i++){
                            this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
                          }
                        }else {
                          this.displayPersonalNcb = false;
                          this.ncbDoc = null;
                        }
                      }
                    } catch(error) {
                      let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                      error.message += errorLocation;
                      throw error;
                    }
                  }, error => {
                    let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                    error.error.message += errorLocation;
                    throw error.error;
                  });
                } catch(error) {
                  let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                  error.message += errorLocation;
                  throw error;
                }
              }, error => {
                let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
                error.error.message += errorLocation;
                throw error.error;
              });
            }
          }
        } else {
          this.displayPersonalBrokers = false;
        }
    
        this.getAllBrokers();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getBrokerAccounts");
    error.message += errorLocation;
    throw error;
  }
 }

 /*********************************************************************************************
  Method Name: getGlobalID
  Description: Gets the global id of user.

  #     Date            Modified by             Description
  1     04/09/19        Katrina Narag           Initial Draft
  2     04/10/19        Dalemark P. Suquib      Updated condition
  3     04/12/19        Joshua Avecilla (JPA)   Updated conditions for receiving data from myresources.
  3     06/27/19        JPA                     Fix for BUG 367547
  **********************************************************************************************/
 getGlobalID() {
  try {
    this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
      try {
        this.globalIDs = data[0];

        if (this.globalIDs.length == 0) {
          this.phSBGlobalID = true;
          this.globalIDNum1 = ""         
        } else if(this.globalIDs.length == 1){
          this.phSBGlobalID = true;
          if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9) { 
                this.globalIDNum1 = "Your SSN";
              } else {
                this.globalIDNum1 = this.globalIDs[0].ParticipantNbr; 
              }
        }
        else{
          if (this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9) {
            this.phSBGlobalID = true;
            this.globalIDNum1 = "Your SSN"
          } else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) == 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9){
            this.phSBGlobalID = false;
            this.globalIDNum1 = "(1) Your SSN"
            this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
          }
          else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) == 9){
            this.phSBGlobalID = false;
            this.globalIDNum1 = "(1) Your SSN"
            this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
          }
          else{
            this.phSBGlobalID = false;
            this.globalIDNum1 = "(1) " + this.globalIDs[0].ParticipantNbr;
            this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
          }

        }

        // if(this.globalIDs.length == 0) {
        //   this.phSBGlobalID = true;
        //   this.globalIDNum1 = "";
        // } else if(this.globalIDs.length == 1) {
        //   this.phSBGlobalID = true;
  
        //   if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
        //     this.globalIDNum1 = this.user.ParticipantNumber; //"Your SSN"; -fix for BUG 367547
        //   } else {
        //     this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
        //   }
        // } 
        // else {
        //   for(let i = 0; i < this.globalIDs.length; i++) {
        //     if(this.globalIDs[i].UserHistoryId.toString().charAt(0) != "9" && this.globalIDs[i].ParticipantNbr.charAt(0) != "9") {
        //       this.phSBGlobalID = true;
        //       this.globalIDNum1 = "Your SSN";
        //     } else if(this.globalIDs[i].UserHistoryId.toString().charAt(0) == "9" && this.globalIDs[i].ParticipantNbr.charAt(0) != "9") {
        //       this.phSBGlobalID = false;
        //       this.globalIDNum1 = "Your SSN";
        //       this.globalIDNum2 = this.globalIDs[i].UserHistoryId;
        //     } else if(this.globalIDs[i].UserHistoryId.toString().charAt(0) != "9" && this.globalIDs[i].ParticipantNbr.charAt(0) == "9") {
        //       this.phSBGlobalID = false;
        //       this.globalIDNum1 = "Your SSN";
        //       this.globalIDNum2 = this.globalIDs[i].ParticipantNbr;
        //     } else {
        //       this.phSBGlobalID = false;
        //       this.globalIDNum1 = "Your SSN";
        //       this.globalIDNum2 = this.globalIDs[i].ParticipantNbr;
        //     }
        //   }
        // }
        
      // this.displayMSGlobalID(this.globalIDs);
      //  if(this.globalIDs.length == 0){
      //     this.phSBGlobalID = true;
      //     this.globalID = "";
      //   }else if(this.globalIDs.length == 1){
      //     this.phSBGlobalID = true;
  
      //     var UserHistoryId = this.globalIDs[0].UserHistoryId;
      //     var ParticipantNbr = this.globalIDs[0].ParticipantNbr;
          
      //     //condition:
      //     //if first char of UserHistoryId != 9 then this.globalID = "Your SSN"
      //     //ELSE  this.globalID = UserHistoryId
      //   }else{  //more than 1 ang count
      //     //if first char of UserHistoryId != 9 AND first char of ParticipantNbr != 9 
      //     //     THEN phSBGlobalID = true
      //     //     this.globalID = "Your SSN"
      //     // else if first char of UserHistoryId == 9 AND first char of ParticipantNbr != 9 
      //     //     THEN phSBGlobalID2 = true
      //     //     lblSSNtext = "Your SSN"
      //     //     lblGlobalId =  UserHistoryId
      //     // else if first char of UserHistoryId != 9 AND first char of ParticipantNbr == 9 
      //     //     THEN phSBGlobalID2 = true
      //     //     lblSSNtext = "Your SSN"
      //     //     lblGlobalId =  ParticipantNbr
      //     // else
      //     //     THEN phSBGlobalID2 = true
      //     //     lblSSNtext = "Your SSN"
      //     //     lblGlobalId =  ParticipantNbr
  
      //   }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
    error.message += errorLocation;
    throw error;
  }
 }

 /*********************************************************************************************
  Method Name: personalDataChange
  Description: Get 3 sections of contacts aside from Brokers

  #     Date            Modified by             Description
  1     04/08/19        Katrina Narag           Initial Draft
  2     04/12/19        Joshua Paul J. Avecilla Updated conditions for receiving data from myresources.
  **********************************************************************************************/
 personalDataChange(){
  try {
    this.bodyData[5] = PageSection.personalDataChange + this.bodyData[0];
    this.bodyData[6] = 1;
  
    //For Contact for Personal Data Change
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: 
      try {
        let content = JSON.parse(data.body);
        let dataChangeContent;

        
       if(content.data) { // length > {}
          dataChangeContent = content.data
          if(dataChangeContent.length > 0){
            this.personalDataChangeContent = dataChangeContent[0].Body_Desc;
            
            this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
            if(this.user.EmployeeStatus == "Active" || this.user.EmployeeStatus ==  "Leave with pay" || this.user.EmployeeStatus == "Leave of Absence" || this.user.EmployeeStatus == "Leave without credit"){ // Terminated User
              if (this.personalDataChangeContent.indexOf("FORMER Employees") != -1 && this.personalDataChangeContent.indexOf("Your myHoldings") != -1) {
              this.personalDataChangeContent = this.personalDataChangeContent.substring(0, this.personalDataChangeContent.indexOf("FORMER Employees")) + 
                this.personalDataChangeContent.substring(this.personalDataChangeContent.indexOf("Your myHoldings"), this.personalDataChangeContent.length);                
              }
            } else if (this.user.EmployeeStatus == "Terminated" || this.user.EmployeeStatus == "Deceased" || this.user.EmployeeStatus == "Retired"){
              if (this.personalDataChangeContent.indexOf("ACTIVE Employees") != -1 && this.personalDataChangeContent.indexOf("FORMER Employees") != -1) {
              this.personalDataChangeContent = this.personalDataChangeContent.substring(0, this.personalDataChangeContent.indexOf("ACTIVE Employees")) + 
                this.personalDataChangeContent.substring(this.personalDataChangeContent.indexOf("FORMER Employees"), this.personalDataChangeContent.length);                
              }
            }
            this.setContactsButton();
          }
          else{
            this.personalDataChangeContent = "";
          }
       }
        
        //For Contact an Accenture Equity Professional
        this.bodyData[5] = PageSection.equityProfessional;
        this.bodyData[6] = 2;
    
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: 
          try {
            let content = JSON.parse(data.body);

            let equityProfessional;
            
            if(content.data) { // length > {}
              equityProfessional = content.data;
            
              if(equityProfessional.length > 0){
                this.equityProfessionalContent = equityProfessional[0].Body_Desc;

                this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
                // if(this.user.JobCd != "10000025") { 
                  if(this.user.EmployeeStatus == "Active" || this.user.EmployeeStatus ==  "Leave with pay" || this.user.EmployeeStatus == "Leave of Absence" || this.user.EmployeeStatus == "Leave without credit"){ // Terminated User
                    if(this.equityProfessionalContent.indexOf("FORMER Employees") != -1) this.equityProfessionalContent = this.equityProfessionalContent.substring(0, this.equityProfessionalContent.indexOf("FORMER Employees") - 5);
                  } else if (this.user.EmployeeStatus == "Terminated" || this.user.EmployeeStatus == "Deceased" || this.user.EmployeeStatus == "Retired"){
                    if (this.equityProfessionalContent.indexOf("ACTIVE Employees") != -1 && this.equityProfessionalContent.indexOf("FORMER Employees") != -1) {
                    this.equityProfessionalContent = this.equityProfessionalContent.substring(0, this.equityProfessionalContent.indexOf("ACTIVE Employees") - 5) +
                      this.equityProfessionalContent.substring(this.equityProfessionalContent.indexOf("FORMER Employees") - 5, this.equityProfessionalContent.length);                      
                    }
                  }
                // }

            this.setContactsButton();
              }
              else{
                this.equityProfessionalContent = "";
              }
            }
      
            //For Request Assistance for myHoldings
            this.bodyData[5] = PageSection.assistanceformyH;
            this.bodyData[6] = 1;
      
            this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: 
              try {
                let content = JSON.parse(data.body);

                let equityProfessional;
                
               if(content.data) { // length > {}
                  equityProfessional = content.data;

                  if(equityProfessional.length > 0){
                    this.assistanceformyHContent = equityProfessional[0].Body_Desc;

                  }
                  else{
                    this.assistanceformyHContent = "";
                  }
               }
              } catch(error) {
                let errorLocation = this.errorLocation.replace("methodName", "personalDataChange");
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              let errorLocation = this.errorLocation.replace("methodName", "personalDataChange");
              error.error.message += errorLocation;
              throw error.error;
            });
          } catch(error) {
            let errorLocation = this.errorLocation.replace("methodName", "personalDataChange");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          let errorLocation = this.errorLocation.replace("methodName", "personalDataChange");
          error.error.message += errorLocation;
          throw error.error;
        });
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "personalDataChange");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "personalDataChange");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "personalDataChange");
    error.message += errorLocation;
    throw error;
  }
 }

 /*********************************************************************************************
  Method Name: checkBrokerCount
  Description: Checks the broker details if all broker are called. 

  #     Date            Modified by             Description
  1     04/08/19        Dalemark P. SUquib      Initial Draft
  **********************************************************************************************/
 checkBrokerCount() {
  try {
    if(this.brokerCount >= 5) {
      this.loading = false;
      setTimeout(()=>{ this.inContentIconAppend() }, 500); 
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "checkBrokerCount");
    error.message += errorLocation;
    throw error;
  }
 }

 /*********************************************************************************************
  Method Name: displayMSGlobalID
  Description: Display SSN/global ID for MS 

  #     Date            Modified by             Description
  1     04/09/19        Katrina Narag(KMN)      Initial Draft
  **********************************************************************************************/
 displayMSGlobalID(globalIDs){
    // globalIDs.length = 0;

    // if(globalIDs.length == 0){
    //   this.phSBGlobalID = true;
    //   // this.globalID = "";
    // }else if(globalIDs.length == 1){
    //   this.phSBGlobalID = true;
    // }
  }

  /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     04/09/19        Dalemark Suquib         Initial Draft
  2     04/17/19        Dalemark Suquib         Added checker for dynamic & static content
  3     05/01/19        KMN                     Add method for no file exists
  **********************************************************************************************/
 getFile(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
  
    if(checker == "static") {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
  
      fileName = file;
    }
  
    this.staticContentService.getFile(fileName, "MD").subscribe(data => { // MD for contacts page
      try {
        let content = data;
  
        if (content != null){    
            let file = JSON.parse(data.body);

            this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
            
            this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
              let writeLogResponse = data;
            });
        }else{
          this.staticContentService.noFileExists(fileName);
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "navToPage");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    }); 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
 }

  /*********************************************************************************************
    Method Name: getFileName
    Description: Gets the file name in the html element.

    #     Date            Modified by             Description
    1     04/17/19        Dalemark P. SUquib      Initial Draft
    2     05/27/19        KMN                     Bug 315318 fix - Document was not downloaded when user clicks the document icon
  **********************************************************************************************/
  getFileName(event){
    try {
      let href: string = event.target.href;
      let id: string = event.target.id;
      let srcElement: HTMLImageElement = event.srcElement;
      let target: string = event.target.target;
      let filePath: string, pathInnerText: string;

      if(srcElement.src != undefined){
        pathInnerText = event.target.parentElement.innerText;

        if(pathInnerText.indexOf("Grant Agreement") != -1) {
          filePath = "GA";
        }

        this.staticContentService.validateFileType(event, filePath);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          let innerText: string = event.target.innerText;
          if(innerText.indexOf("Grant Agreement") != -1) {
            filePath = "GA";
          }
          
          this.staticContentService.validateFileType(event, filePath);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          let innerTxt = event.target.innerText;
          event.preventDefault();

          if(innerTxt in MyHoldingsPagesResources){
            this.navToMyHoldingsPage(href);
          }else if(href.indexOf("Resources") != -1){
            this.navToMyHoldingsPage("Resources");
          }

        }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
          event.preventDefault();
          id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
        }else if(href.indexOf("https") != -1 || href.indexOf("www.") != -1 || href.indexOf("mailto:") != -1){ //redirect to the page if href is not file
          if(target != "") {
            // window.open(href, target);
          } 
        }else{ //if has file but no filename text
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  /**************************************************************************************************
      Method Name: navToMyHoldingsPage
      Description: Redirect to main myholdings page

      Date            Modified by                 Description    
      05/21/19        KMN                         Iitial draft
      05/27/29        KMN                         Bug 315318 fix - Document was not downloaded when user clicks the document icon 
*****************************************************************************************************/
navToMyHoldingsPage(page){
  page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
  page.indexOf("Contacts") != -1 ? page = "contactspage" : "";

  window.open(this.globals.currentWebUrl + "/" + page, "_blank");
}

  /*********************************************************************************************
    Method Name: getFileName
    Description: Gets the file name in the html element.

    #     Date            Modified by             Description
    1     05/01/19        KMN                     Initial draft
  **********************************************************************************************/
  async inContentIconAppend(){
    $("[href='https://www.stockplanconnect.com/']:last").remove();
    $('[href$=".pdf"]').append(' <img src="/assets/pdf.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="PDF file">');
    $('[href$=".zip"]').append(' <img src="/assets/zip.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="ZIP file">');
    $('[href$=".xls"]').append(' <img src="/assets/xls.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="Excel file">');
    $('[href$=".xlsx"]').append(' <img src="/assets/xls.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="Excel file">');
    $('[href$=".doc"]').append(' <img src="/assets/docx.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="DOC file">');
    $('[href$=".docx"]').append(' <img src="/assets/docx.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="DOC file">');
    $('[href$=".PDF"]').append(' <img src="/assets/pdf.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="pdf file">');
    $('[href$=".ZIP"]').append(' <img src="/assets/zip.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="ZIP file">');
    $('[href$=".XLS"]').append(' <img src="/assets/xls.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="Excel file">');
    $('[href$=".DOC"]').append(' <img src="/assets/docx.svg" style="width: 16px; height: 16px; margin-right: 5px;" alt="DOC file">');
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Brokerspage") != -1 && this.user != null) {
                webPage = "Brokerspage";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  fileImage(filyType){
    if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
      this.fileTypeImagePath = '/assets/pdf.svg';
      this.fileTypeAlt = "PDF File"

      return true;
    }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
      this.fileTypeImagePath = '/assets/zip.svg';
      this.fileTypeAlt = "ZIP File"
      return true;
    }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
      this.fileTypeImagePath = '/assets/xls.svg';
      this.fileTypeAlt = "XLS File"
      return true;
    }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
      this.fileTypeImagePath = '/assets/docx.svg';
      this.fileTypeAlt = "DOC File"
      return true;
    }
   }

   /**********************************************************************************
   * Method Name: setContactsButton                                                                 
   * Description: Sets the button depending on the user's job code                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/23/19             Dalemark P. Suquib          Initial draft       
   *********************************************************************************/
   setContactsButton() {
     if(this.user.JobCd == "10000025") { // Leadership
      this.formerButton = false;
      this.showmyRbutton = false;
      this.leadershipEmailButton = true;
     } else { // Employee
      if(this.user.EmployeeStatus == "Terminated" || this.user.EmployeeStatus == "Deceased" || this.user.EmployeeStatus == "Retired") { // Terminated User
        this.formerButton = true;
        this.showmyRbutton = false;
        this.leadershipEmailButton = false;
      } else if(this.user.EmployeeStatus == "Active" || this.user.EmployeeStatus ==  "Leave with pay" || this.user.EmployeeStatus == "Leave of Absence" || this.user.EmployeeStatus == "Leave without credit") { // Active User
        this.formerButton = false;
        this.showmyRbutton = true;
        this.leadershipEmailButton = false;
      }
     }
   }

   GoToPortal(){
    if (this.user.EmployeeStatus == "Active" || this.user.EmployeeStatus ==  "Leave with pay" || this.user.EmployeeStatus == "Leave of Absence" || this.user.EmployeeStatus == "Leave without credit"){
        if(this.globals.stageName == "prod"){
          window.open("https://workday.accenture.com", "_blank");
        }
        else{
          window.open("https://wd3-impl.workday.com/accenture10/d/home.htmld", "_blank");
        }
    } else if (this.user.EmployeeStatus == "Terminated" || this.user.EmployeeStatus == "Deceased" || this.user.EmployeeStatus == "Retired"){
      window.open("https://support.accenture.com/former_employee_portal", "_blank");
    }
  }
}


