import { Component, OnInit } from '@angular/core';
import { PageSection } from '../../services/enumerations/enumerations';
import { Globals } from '../../globals';
import { StaticContentService } from '../../services/StaticContentService';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dividend',
  templateUrl: './dividend.component.html',
  styleUrls: ['./dividend.component.css']
})
export class DividendComponent implements OnInit {

  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  expandResource: string = "collapse show";
  expandResourceBool: boolean = true;
  resourceLoading: boolean = true;
  errorDividend: boolean = false;
  errorLocation: string = " + [Component: Dividend, Method: methodName]";
  dividendDocs = [];          
  dividendChartFY = [];        
  dividendChartPrice = [];
  chart: any;                

  constructor(private globals: Globals, private staticContent : StaticContentService, private router: Router) { }

  ngOnInit() {
    this.getDividend();
  }

  /***********************************************************************************
   * Method Name: getDividend
   * Description: Calls the service for Dividend Section
   * 
   * #      Date          Modified by                 Description
   * 1      09/17/19      Allan Christopher Salac     Initial draft
   * *********************************************************************************/

  getDividend(){
    try {
      this.resourceLoading = true;
      this.getDividendDoc();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getDividend");
      error.message += errorLocation;
      throw error;
    }
  }

  async getDividendDoc(){
    try {
      this.dividendDocs = [];
      this.bodyData[5] = PageSection.dividendDoc;
      this.bodyData[6] = 0;
  
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data._body);

          for(let i = 0; i < JSON.parse(content.body).data.length; i ++){
            var x = JSON.parse(content.body).data[i];
            x["ID"] = i;
            this.dividendDocs.push(x);
          }

          this.getDividendChart();

          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
        } catch(error) {
          this.errorDividend = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getDividendDoc");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorDividend = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getDividendDoc");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorDividend = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDividendDoc");
      error.message += errorLocation;
      throw error;
    }
  }

  async getDividendChart(){
    try {
      this.staticContent.getDividendsChart().pipe(map(res => res)).subscribe(data => { 
        try {
          this.dividendChartFY = [];
          this.dividendChartPrice = [];
          let content = JSON.parse(data._body)[0];

          for(let i = 0; i < content.length; i++) {
            this.dividendChartFY.push(content[i].FY);
            this.dividendChartPrice.push(content[i].DividendPrice)
          }

          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }

          this.chart = new Chart("canvas", {
            type: 'line',
            data: {
                datasets: [{
                    data: this.dividendChartPrice.reverse(),
                    borderColor: "#3cba9f",
                    fill: false
                }],
                labels: this.dividendChartFY.reverse()
            },
            options: {
              legend: {
                display: false
              },
              title: {
                display: true,
                text: 'Annual Dividend Rate'
              },
            },
            responsive: true
          });
  
          this.resourceLoading = false;
        } catch(error) {
          this.errorDividend = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getDividendChart");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorDividend = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getDividendChart");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorDividend = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDividendChart");
      error.message += errorLocation;
      throw error;
    }
  }

}
