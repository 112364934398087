
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators'; 

@Injectable()
export class MarketQuoteService {

  constructor(private globalVariables: Globals, private httpClient: HttpClient) { }

  getMarketQuote(): any{
    let currentAPIURL = this.globalVariables.currentAPIUrl;
    let marketQuoteAPI= this.globalVariables.getMarketQuoteAPI;


      try{
          const api = currentAPIURL + marketQuoteAPI;
          return this.httpClient.get(api).pipe(map(res => res), retry(3),
          catchError((res: Response) => this.onError(res))); 
      }catch(err){
          return err;
      }
  }

  // getMarketQuoteHistorical(checker): any{
  //   let marketQuoteGraphAPI = this.globalVariables.getMarketQuoteGraphAPI; 

  //   try{
  //       const api = marketQuoteGraphAPI + checker + this.globalVariables.getMarketQuoteTokenAPI; 
  //       return this.httpClient.get(api).pipe(map(res => res), retry(3),
  //       catchError((res: Response) => this.onError(res))); 
  //   }catch(err){
  //       return err;
  //   }
  // }

  getMarketQuotefromDB(): any {
    let currentAPIURL = this.globalVariables.currentAPIUrl;
    let marketQuoteAPIfromDB = this.globalVariables.getMarketQuotefromDB;

    try{
      const api = currentAPIURL + marketQuoteAPIfromDB; 
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  //   This part here is for Currency List [KPA]
  getCurrencyList(): any {
    let currentAPIURL = this.globalVariables.currentAPIUrl;
    let staticContentAPI = this.globalVariables.staticContentAPI;

    try{
      const api= currentAPIURL + staticContentAPI + 'Currency';
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }
  // END [KPA]

  getExhangeRateList(): any {
    let apiURL = this.globalVariables.currentAPIUrl;
    let rsuAPI = apiURL + this.globalVariables.exchangeRateListAPI;

    try{
        const api = rsuAPI; 
        return this.httpClient.get(rsuAPI).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  }
}
