
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
// import { MatInput } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import * as moment from 'moment'
import { BrokerType, PageSection, FileType, MyHoldingsPagesResources, tScript, CurrencySymbol} from '../../services/enumerations/enumerations';
import { HoldingProgramDetailsVEIPService } from '.././HoldingProgramDetailsVEIPService';
import { StaticContentService } from '../../services/StaticContentService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { BrokersPageService } from '../../brokerspage/BrokersPageService';
import {TooltipPosition} from '@angular/material/tooltip';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
// import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-veip-saleshistory-tab',
  templateUrl: './veip-saleshistory-tab.component.html',
  styleUrls: ['./veip-saleshistory-tab.component.css']
})
export class VeipSaleshistoryTabComponent implements OnInit {

  displayedColumnsVeip: string[] = ['Date', 'PurchasePriceUSD', 'PurchasedShares' , 'ValueUSD', 'ValueLoc','Disposed', 'RemainingBroker'];
  veipdataSource: any;

  displayedColumnsVeipSalesHist: string[] = ["Date", "Type", "Shares", "PriceUSD", "ProceedsUSD", "PurchaseValueUSD", "GainLossUSD", "GainLossLoc"];
  dataSourceVeipSalesHist: any;

  loading: boolean = true;
  datePipe = new DatePipe('en-US');
  dateFrom: string = "' '";
  dateTo: string = "' '";
  dateFromClicked: boolean = false;
  dateToClicked: boolean = false;
  dateFromHolder: any;
  dateToHolder: any;

  dateFromInput: Date; // Angular Material Datepicker
  dateToInput: Date; // Angular Material Datepicker

  dateFromChecker: any;
  dateToChecker: any;
  dateErrorChecker: boolean = false;

  brokerType: any;
  noSalesHistory: boolean = false;

  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsNextRelease: any;
  detailsSharesReleasing: any;
  detailsGrantAccepted: any;
  detailsUnreleased: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;
  contribution: number;

  datePeriod: number = 4;
  disableDatePicker: boolean = true;
  esppSalesHistShares: any = 0;
  esppSalesHistPriceUSD: any = 0;
  esppSalesHistProceedsUSD: any = 0;
  esppSalesHistPurchaseValueUSD: any = 0;

  // VEIP variables - Start
  programyear: string;  
  totalcontributionloc: number;
  totalpurchasevalueloc: number;
  totalsharespurchased: number;
  soldtransferred: number;
  netremainingatbroker: number;
  localcurrencycd: string;  
  veiplocalcurrencycd: string;
  monthlycontributionusd: number;
  monthlycontributionloc: number;
  priormonthscarryoverusd: number;
  priormonthscarryoverloc: number;
  totalcontributionusd: number;
  detailstotalcontributionloc: number;
  purchasecostusd: number;
  purchasecostloc: number;
  carryovertonextmonthusd: number;
  carryovertonextmonthloc: number;
  exchangerate: number;

  veipSalesHistShares: any = 0;
  veipSalesHistPriceUSD: any = 0;
  veipSalesHistProceedsUSD: any = 0;
  veipSalesHistPurchaseValueUSD: any = 0;
  veipSalesHistGainLossUSD: any = 0;
  veipSalesHistGainLossLoc: any = 0;

  veipIsDisplayRefund: number;

  // VEIP variables - End

  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd: string;
  salesTableLoading: boolean;
  veipTableLoading: boolean;
  periodSelected: string = "All dates";
  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;

  orientation: string = "horizontal";
  shortAwardName: string;

  //Learn More Tab
  veipCommonQuestionsContent: object[];
  veipGuidebooksContent: object;
  veipProspectusContent: object;
  veipCountryAwardContent: object;
  veipToolsandReportContent: object;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  allCountryFormsContent: string;
  stepTwoContent: object;
  errorLocation: string = " + [Component: Veipdetails, Method: methodName]";
  errorDetailsTab: boolean = false;
  errorSalesHistTab: boolean = false;
  errorCommonQuestions: boolean = false;
  errorGuideBooks: boolean = false;
  errorProspectusDoc: boolean = false;
  errorCountryAward: boolean = false;
  errorToolsAndReports: boolean = false;
  isAllDates: boolean = false;
  
 

  currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));


  
  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;

//Tooltip for Veip Details Page
ContributionPercentage_mtt: string = tScript.veipdetailspage_c;
TotalContribution_mtt: string = tScript.veipdetailspage_tc;
PurchasePrice_mtt: string = tScript.veipdetailspage_pp;
Disposed_mtt: string = tScript.veipdetailspage_d;
RemainingBroker_mtt: string = tScript.veipdetailspage_rb;
PurchaseCost_mtt: string = tScript.veipdetailspage_pc;
RefundedViaPayroll_mtt: string = tScript.veipdetailspage_rvp;
ExchangeRate_mtt: string = tScript.veipdetailspage_er;
SalesHistory_Type: string = tScript.esppveipsaleshistorytab_type;
SalesHistory_Price: string = tScript.esppveipsaleshistorytab_price;
SalesHistory_Proceeds: string = tScript.esppveipsaleshistorytab_proceeds;
SalesHistory_PurchaseDateValue: string = tScript.esppveipsaleshistorytab_pdv;

fileTypeImagePath: string;
fileTypeAlt: string;

commonQAccordionCount: number = 1; // 1 accordion is initially expanded
collapseIndexClass: string; 
localCurrencyCode: string;

positionOption: TooltipPosition[]=['after', 'before', 'above', 'below', 'left', 'right'];
  currencyCdSymbol: any;

  constructor(private brokerService: BrokersPageService, private router: Router, private app: AppComponent, private globals: Globals, private veipProgramDetails: HoldingProgramDetailsVEIPService, private staticContentService: StaticContentService, private errorNotificationService: ErrorNotificationService, private ngbDateParserFormatter: NgbDateParserFormatter) {}


  ngOnInit() {
    this.setPeriodAllDates();
    this.getProgramDetails();
    this.inContentIcon();
  }
  

/**********************************************************************************
      Method Name: getProgramDetails
      Description: Get VEIP program details from session 

      #     Date            Modified by       Description
      1     01/30/19        Katrina Narag     Initial draft         
      2     02/08/19        KMN                Set award name to grant long desc
  **********************************************************************************/
 getProgramDetails() {
  try {
    this.shortAwardName =  this.programDetails.AwardName;
    this.awardName = this.shortAwardName;  //KMN, 2
    this.detailsAvailableToSell = this.programDetails.AvailableToSell;
    this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
    this.detailsBroker = this.programDetails.BrokerId;
    this.detailsNextRelease = this.programDetails.NextRelease;
    this.detailsSharesReleasing = this.programDetails.SharesReleasing;
    this.detailsGrantAccepted = this.programDetails.GrantAccepted;
    this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
    this.detailsReleased = this.programDetails.ReleasedExercisableQty;
    this.detailsExpiration = this.programDetails.Expiration;
    this.detailsForfeited = this.programDetails.Forfeited;

    this.getVEIPAwardSalesHist(this.programDetails.OfferNum, this.dateFrom, this.dateTo);

    this.veipProgramDetails.getSalesHistoryPeriod().pipe(map(res => res)).subscribe(data => {
      try {
        let b = data;
        b = b.sort((a,b) => 0 - (a.Id > b.Id ? -1 : 1));
        this.yearToDate = b[0].Value;
        this.lastYear = b[1].Value;
        this.last3Years = b[2].Value;
        this.allDates = b[3].Value;
        this.custom = b[4].Value;

        this.selectDatePeriod(4);
        
      } catch(error) {
        this.errorSalesHistTab = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
    error.message += errorLocation;
    throw error;
  }
}


  /*****************************************************************************************
   * Method Name: getCarryOverDetails                                                                 
   * Description: Gets the content upon award click  
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    08/09/19             JSS                         Mobile Restructure
   * 2    12/18/19             Jikho                       Resets value for veipSalesHistGainLossUSD & veipSalesHistGainLossLoc to avoid incremental concatenation
   *****************************************************************************************/
  getVEIPAwardSalesHist(offerNum, dateFrom: string, dateTo: string) {
    try {

      dateTo = dateTo.replace(/'/g, '');
      dateFrom = dateFrom.replace(/'/g, '');
      this.veipSalesHistGainLossUSD = 0;
      this.veipSalesHistGainLossLoc = 0;

      if(this.isAllDates == true){
        dateFrom = null;
        dateTo = null;
      }   
    
      this.veipProgramDetails.getVeipSalesHistory(this.user.OptNum, offerNum, this.datePeriod, dateFrom, dateTo).pipe(map(res => res)).subscribe(data => {
        try {

          if(data[1].length == 0) {
            this.noSalesHistory = true;
            this.brokerType = BrokerType[data[0][0].BrokerCd];
            this.dataSourceVeipSalesHist = data[1];
            this.salesTableLoading = false;
            this.localCurrencyCode = 'LOC';
          } else {
            
          this.dataSourceVeipSalesHist = data[1];
          this.brokerType = BrokerType[data[0][0].BrokerCd];
    
          for(let i = 0; i < data[1].length; i++) 
          {
            this.veipSalesHistShares += (data[1][i].Shares*1);
            this.localCurrencyCode = data[1][i].LocalCurrencyCd;

            if(data[1][i].PriceUSD != "-") 
            {
              this.veipSalesHistPriceUSD += (data[1][i].PriceUSD*1);
            }
            if(data[1][i].ProceedsUSD != "-") 
            {
              this.veipSalesHistProceedsUSD += (data[1][i].ProceedsUSD*1);
            }
            this.veipSalesHistPurchaseValueUSD = this.veipSalesHistPurchaseValueUSD + (data[1][i].PurchaseValueUSD*1);
            
            if(data[1][i].GainLossUSD != "-")
            {
              this.veipSalesHistGainLossUSD = this.veipSalesHistGainLossUSD + (data[1][i].GainLossUSD*1);
            }

            if(data[1][i].GainLossLoc != "-") 
            {
              this.veipSalesHistGainLossLoc = this.veipSalesHistGainLossLoc +(data[1][i].GainLossLoc*1);
            }
          
          }

          for(let i = 0; i < data[1].length; i++)
          {
            if(data[1][i].GainLossLoc < 0)
            {
              data[1][i].GainLossLoc = this.commafy(data[1][i].GainLossLoc,2);
              data[1][i].GainLossLoc = "(" + data[1][i].GainLossLoc.slice(1) + ")";
            }
            else if(data[1][i].GainLossLoc >= 0)
            {
              data[1][i].GainLossLoc = this.commafy(data[1][i].GainLossLoc,2);
            }
            
            if(data[1][i].GainLossUSD < 0)
            {
              data[1][i].GainLossUSD = this.commafy(data[1][i].GainLossUSD,2);
              data[1][i].GainLossUSD = "(" + data[1][i].GainLossUSD.slice(1) + ")";
            }
            else if(data[1][i].GainLossUSD >= 0)
            {
              data[1][i].GainLossUSD = this.commafy(data[1][i].GainLossUSD,2);
            }
          }

          if(this.veipSalesHistGainLossLoc < 0)
          {
            this.veipSalesHistGainLossLoc = this.commafy(this.veipSalesHistGainLossLoc,2);
            this.veipSalesHistGainLossLoc = "(" + this.veipSalesHistGainLossLoc.slice(1) + ")";
          }
          else if(this.veipSalesHistGainLossLoc >= 0)
          {
            this.veipSalesHistGainLossLoc = this.commafy(this.veipSalesHistGainLossLoc,2);
          }
          if(this.veipSalesHistGainLossUSD < 0)
          {
            this.veipSalesHistGainLossUSD = this.commafy(this.veipSalesHistGainLossUSD,2);
            this.veipSalesHistGainLossUSD = "(" + this.veipSalesHistGainLossUSD.slice(1) + ")";
          }
          else if(this.veipSalesHistGainLossUSD >= 0)
          {
            this.veipSalesHistGainLossUSD = this.commafy(this.veipSalesHistGainLossUSD,2);
          }

          this.noSalesHistory = false;
          this.salesTableLoading = false;
        }
          this.loading = false;
        } catch(error) {
          this.errorSalesHistTab = true;
          this.salesTableLoading = false;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardSalesHist");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorSalesHistTab = true;
        this.salesTableLoading = false;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardSalesHist");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorSalesHistTab = true;
      this.salesTableLoading = false;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardSalesHist");
      error.message += errorLocation;
      throw error;
    }
  }


 /*****************************************************************************************
   * Method Name: getCarryOverDetails                                                                 
   * Description: Gets the content upon award click  
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    08/09/19             JSS                         Mobile Restructure
   *****************************************************************************************/
  viewVEIPAwardSalesHist() {
    if(!this.salesTableLoading){
    try {
      this.dateErrorChecker = false;
      this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
      this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
  
      let tempDateTo = this.dateTo.replace(/'/g, '');
      let tempDateFrom = this.dateFrom.replace(/'/g, '');
  
      tempDateTo = moment(tempDateTo).format("YYYY-MM-DD");
      tempDateFrom = moment(tempDateFrom).format("YYYY-MM-DD");

      if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else if (tempDateTo < tempDateFrom && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else{
        this.salesTableLoading = true;
        this.veipSalesHistShares = 0;
        this.veipSalesHistPriceUSD = 0;
        this.veipSalesHistProceedsUSD = 0;
        this.veipSalesHistPurchaseValueUSD = 0;
        this.getVEIPAwardSalesHist(this.programDetails.OfferNum, this.dateFrom, this.dateTo);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewVEIPAwardSalesHist");
      error.message += errorLocation;
      throw error;
    }
    }
  }

  setPeriodAllDates() {
    try {
      //setTimeout(()=>{ this.inContentIcon() }, 50);
        this.disableDatePicker = true;
        this.dateFrom = "' '";
        this.dateTo = "' '";
        this.periodSelected = this.allDates;
        this.datePeriod = 4;
        this.salesTableLoading = true;
        this.veipSalesHistShares = 0;
        this.veipSalesHistPriceUSD = 0;
        this.veipSalesHistProceedsUSD = 0;
        this.veipSalesHistPurchaseValueUSD = 0;
        this.dateFromClicked = false;
        this.dateToClicked = false;
        this.dateErrorChecker = false;
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
   
        // this.getVEIPAwardSalesHist(this.programDetails.OfferNum, this.dateFrom, this.dateTo);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "setPeriodAllDates");
      error.message += errorLocation;
      throw error;
    }
  }

  selectDatePeriod(period) {
    try {

      if(period != 5){
        $(".MDatePicker input").css("background-color","#d3d0d0")
      }else{
        $(".MDatePicker input").css("background-color","white")
      }


      this.datePeriod = period;
      this.isAllDates = false;

      if(this.datePeriod == 1) { // year to date
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.yearToDate;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = "'" + dateToEvent + "'";

        currentDate.setMonth(0, 1);
        let yearToDate = currentDate;
        let dateFromEvent = this.datePipe.transform(yearToDate, "MM'/'dd'/'yyyy");
        this.dateFrom = "'" +dateFromEvent + "'";
      } else if(this.datePeriod == 2) { // last year
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.lastYear;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = "'" + dateToEvent + "'";

        currentDate.setFullYear(currentDate.getFullYear() - 1);
        let lastYear = currentDate;
        let dateFromEvent = this.datePipe.transform(lastYear, "MM'/'dd'/'yyyy");
        this.dateFrom = "'" + dateFromEvent + "'";
      } else if(this.datePeriod == 3) { // last 3 years
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Amgular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.last3Years;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = "'" + dateToEvent + "'";

        currentDate.setFullYear(currentDate.getFullYear() - 3);
        let lastThreeYear = currentDate;
        let dateFromEvent = this.datePipe.transform(lastThreeYear, "MM'/'dd'/'yyyy");
        this.dateFrom = "'" + dateFromEvent + "'";
      } else if(this.datePeriod == 4) { // all dates
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.allDates;
        this.dateFromClicked = false;
        this.dateToClicked = false;
        this.dateTo = "' '";
        this.dateFrom = "' '";
        this.isAllDates = true;
      } else { // period = 5, custom
        this.disableDatePicker = false;
        this.periodSelected = this.custom;
  
        let currentDate = new Date();
        let inputValue = moment(currentDate).format("MM/DD/YYYY");

        this.dateTo = "'" + inputValue + "'";
        if(this.dateToClicked) {
          this.dateToInput = new Date(this.dateToHolder); // Angular Material Datepicker
        } else {
          this.dateToInput = currentDate; // Angular Material Datepicker
        }

        this.dateFrom = "'" + inputValue + "'";
        if(this.dateFromClicked) {
          this.dateFromInput = new Date(this.dateFromHolder); // Angular Material Datepicker
        } else {
          this.dateFromInput = currentDate; // Angular Material Datepicker
        }
  
        this.dateToChecker = inputValue;
        this.dateFromChecker = inputValue;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDatePeriod");
      error.message += errorLocation;
      throw error;
    }
  }

  selectDateFrom(value: Date) { // Angular Material Datepicker
    try {
      let dateEvent = moment(value).format("YYYY-MM-DD");
      this.dateFromChecker = dateEvent;
      this.dateFrom = "'" + dateEvent + "'";
      this.dateFromClicked = true;
      this.dateFromHolder = dateEvent;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
      error.message += errorLocation;
      throw error;
    }
  }

  selectDateTo(value: Date) { // Angular Material Datepicker
    try {
      let dateEvent = moment(value).format("YYYY-MM-DD");
      this.dateToChecker = dateEvent;
      this.dateTo = "'" + dateEvent + "'";
      this.dateToClicked = true;
      this.dateToHolder = dateEvent;
   } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
      error.message += errorLocation;
      throw error;
    }
  }

  getGlobalID() {
    try {
      this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
        try {
          this.globalIDs = data[0];

          if (this.globalIDs.length == 0) {
            this.phSBGlobalID = true;
            this.globalIDNum1 = ""         
          } else if(this.globalIDs.length == 1){
            this.phSBGlobalID = true;
            if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9) { 
                  this.globalIDNum1 = "Your SSN";
                } else {
                  this.globalIDNum1 = this.globalIDs[0].ParticipantNbr; 
                }
          }
          else{
            if (this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9) {
              this.phSBGlobalID = true;
              this.globalIDNum1 = "Your SSN"
            } else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) == 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) == 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else{
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) " + this.globalIDs[0].ParticipantNbr;
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
  
          }
  
          // if(this.globalIDs.length == 0) {
          //   this.phSBGlobalID = true;
          //   this.globalIDNum1 = "";
          // } else if(this.globalIDs.length == 1) {
          //   this.phSBGlobalID = true;
    
          //   if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
          //     this.globalIDNum1 = "Your SSN";
          //   } else {
          //     this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
          //   }
          // } 

        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
      error.message += errorLocation;
      throw error;
    }
   }
  

   checkBrokersInfo(broker){
    event.stopPropagation();
   

    if(this.ubsLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    
    if(this.sbLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    
    if(this.bigLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    
    if(this.ncbLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    

    
    this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      let brokerAccounts = data[0];
      if(brokerAccounts.length > 0) {

        for(let i = 0; i < brokerAccounts.length; i++) {
  if(broker == "MS"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });

    this.brokerHeader = "Morgan Stanley";
    
    this.getGlobalID();

    this.displayPersonalUbs = false;
    this.displayPersonalBig = false;
    this.displayPersonalNcb = false;

    this.bodyData[5] = PageSection.sb;
    this.bodyData[6] = 1;
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
      
        let msData = JSON.parse(data.body);
        let msBody;

        if(msData.data) { // length > {}
        msBody = msData.data;

          if (msBody.length > 0){
            this.sbDesc = msBody[0].Body_Desc;
            this.displayPersonalSb = true;
            }
          else {
            this.displayPersonalSb = false;
            this.sbDesc = "";
          } 
        }


      this.bodyData[5] = PageSection.sbDoc;
      this.bodyData[6] = 0;

      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      let msDocData = JSON.parse(data.body);
      let msDocBody;
      
      if(msDocData.data) { // length > {}
                      msDocBody = msDocData.data;
                      if(msDocBody.length > 0) {
                        this.sbDoc = msDocBody;
      
                        for(let i=0; i<this.sbDoc.length; i++){
                          this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                        }
                      }
                      else{
                        this.sbDoc = null;
                      }
                    }
                
      });
      this.sbLoaded = true;
  this.resourceLoading = false;
    });

  }else if(broker == "UBS"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    

    this.brokerHeader = "UBS Financial Services Inc";
    this.displayPersonalSb = false;
    this.displayPersonalBig = false;
    this.displayPersonalNcb = false;

  this.ubsAccount = brokerAccounts[i].Account;
  this.bodyData[5] = PageSection.ubs;
  this.bodyData[6] = 1;
  

  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    let ubsData = JSON.parse(data.body);
    let ubsBody;

    if(ubsData.data) { // length > {}
    ubsBody = ubsData.data;
    if(ubsBody.length > 0) {
      this.ubsDesc = ubsBody[0].Body_Desc;
      this.displayPersonalUbs = true;
    }
    else {
    this.displayPersonalUbs = false;
      this.ubsDesc = "";
    }
  }

  this.bodyData[5] = PageSection.ubsDoc;
  this.bodyData[6] = 0;
  
  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

    let ubsDocData = JSON.parse(data.body);
    let ubsDocBody;
    
    if(ubsDocData.data) { // length > {}
        ubsDocBody = ubsDocData.data;
        if(ubsDocBody.length > 0){
          this.ubsDoc = ubsDocBody;
  
          for(let i=0; i<this.ubsDoc.length; i++){
            this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
          }
        }
        else{
          this.ubsDoc = null;
        }
      }
  });
  this.ubsLoaded = true;
  this.resourceLoading = false;
  });
  }else if(broker == "BIG"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    

    this.brokerHeader = "Banco de Investimento Global";

    this.displayPersonalSb = false;
    this.displayPersonalUbs = false;
    
    this.displayPersonalNcb = false;

    this.bigAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.big;
    this.bodyData[6] = 1;
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    
      let bigData = JSON.parse(data.body);
      let bigBody;

      if(bigData.data) { // length > {}
      bigBody = bigData.data;
      if(bigBody.length > 0){
        this.bigDesc = bigBody[0].Body_Desc;
        this.displayPersonalBig = false;
      }else {
        this.displayPersonalBig = false;
        this.bigDesc = "";
      }
    }
    this.bigLoaded = true;
  this.resourceLoading = false;
    });

  }else if(broker == "TA"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    

    this.brokerHeader = "Computershare (formerly National City Bank)";
    this.displayPersonalSb = false;
    this.displayPersonalUbs = false;
    this.displayPersonalBig = false;
    

    this.ncbAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.ncb;
    this.bodyData[6] = 1;
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

      let taData = JSON.parse(data.body);
      let taBody;

      if(taData.data) { // length > {}
      taBody = taData.data;
      if(taBody.length > 0){
      this.ncbDesc = taBody[0].Body_Desc;
      this.displayPersonalNcb = true;
      }else {
        this.displayPersonalNcb = false;
        this.ncbDesc = "";
      }
    } 
    
    this.bodyData[5] = PageSection.ncbDoc;
    this.bodyData[6] = 0;         

       this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

        let taDocData = JSON.parse(data.body);
        let taDocBody;

        if(taDocData.data) { // length > {}
        taDocBody = taDocData.data;
        if(taDocBody.length > 0){
          this.ncbDoc = taDocBody;

          for(let i=0; i<this.ncbDoc.length; i++){
            this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
          }
        }else {
          this.displayPersonalNcb = false;
          this.ncbDoc = null;
        }
      }
       });
       this.ncbLoaded = true;
       this.resourceLoading = false;
    });

  }else if(broker.indexOf('/')){
    this.brokerHeader = broker;
    let splittedBroker = broker.split('/');
    for(let i = 0; i < splittedBroker.length; i++){
      if(splittedBroker[i] == "MS"){
        this.getGlobalID();
        this.bodyData[5] = PageSection.sb;
        this.bodyData[6] = 1;
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
          
            let msData = JSON.parse(data.body);
            let msBody;
  
            if(msData.data) { // length > {}
            msBody = msData.data;
  
              if (msBody.length > 0){
                this.sbDesc = msBody[0].Body_Desc;
                this.displayPersonalSb = true;
                }
              else {
                this.displayPersonalSb = false;
                this.sbDesc = "";
              } 
            }
  
  
          this.bodyData[5] = PageSection.sbDoc;
          this.bodyData[6] = 0;
  
          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          let msDocData = JSON.parse(data.body);
          let msDocBody;
          
          if(msDocData.data) { // length > {}
                          msDocBody = msDocData.data;
                          if(msDocBody.length > 0) {
                            this.sbDoc = msDocBody;
          
                            for(let i=0; i<this.sbDoc.length; i++){
                              this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                            }
                          }
                          else{
                            this.sbDoc = null;
                          }
                        }
                    
          });
          this.sbLoaded = true;
      this.resourceLoading = false;
        });
  
      }else if(splittedBroker[i] == "UBS"){
        
      this.ubsAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.ubs;
      this.bodyData[6] = 1;
      
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        let ubsData = JSON.parse(data.body);
        let ubsBody;
  
        if(ubsData.data) { // length > {}
        ubsBody = ubsData.data;
        if(ubsBody.length > 0) {
          this.ubsDesc = ubsBody[0].Body_Desc;
          this.displayPersonalUbs = true;
        }
        else {
        this.displayPersonalUbs = false;
          this.ubsDesc = "";
        }
      }
  
      this.bodyData[5] = PageSection.ubsDoc;
      this.bodyData[6] = 0;
      
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
        let ubsDocData = JSON.parse(data.body);
        let ubsDocBody;
        
        if(ubsDocData.data) { // length > {}
            ubsDocBody = ubsDocData.data;
            if(ubsDocBody.length > 0){
              this.ubsDoc = ubsDocBody;
      
              for(let i=0; i<this.ubsDoc.length; i++){
                this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
              }
            }
            else{
              this.ubsDoc = null;
            }
          }
      });
      this.ubsLoaded = true;
      this.resourceLoading = false;
      });
      }else if(splittedBroker[i] == "BIG"){
        
        this.bigAccount = brokerAccounts[i].Account;
        this.bodyData[5] = PageSection.big;
        this.bodyData[6] = 1;
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        
          let bigData = JSON.parse(data.body);
          let bigBody;
  
          if(bigData.data) { // length > {}
          bigBody = bigData.data;
          if(bigBody.length > 0){
            this.bigDesc = bigBody[0].Body_Desc;
            this.displayPersonalBig = false;
          }else {
            this.displayPersonalBig = false;
            this.bigDesc = "";
          }
        }
        this.bigLoaded = true;
      this.resourceLoading = false;
        });
  
      }else if(splittedBroker[i] == "TA"){
       
        this.displayPersonalSb = false;
        this.displayPersonalUbs = false;
        this.displayPersonalBig = false;
        
  
        this.ncbAccount = brokerAccounts[i].Account;
        this.bodyData[5] = PageSection.ncb;
        this.bodyData[6] = 1;
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
          let taData = JSON.parse(data.body);
          let taBody;
  
          if(taData.data) { // length > {}
          taBody = taData.data;
          if(taBody.length > 0){
          this.ncbDesc = taBody[0].Body_Desc;
          this.displayPersonalNcb = true;
          }else {
            this.displayPersonalNcb = false;
            this.ncbDesc = "";
          }
        } 
        
        this.bodyData[5] = PageSection.ncbDoc;
        this.bodyData[6] = 0;         
  
           this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
  
            let taDocData = JSON.parse(data.body);
            let taDocBody;
  
            if(taDocData.data) { // length > {}
            taDocBody = taDocData.data;
            if(taDocBody.length > 0){
              this.ncbDoc = taDocBody;
  
              for(let i=0; i<this.ncbDoc.length; i++){
                this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
              }
            }else {
              this.displayPersonalNcb = false;
              this.ncbDoc = null;
            }
          }
           });
           this.ncbLoaded = true;
           this.resourceLoading = false;
        });
    }
  }
  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });
  
  }
}
}

});
  }

  fileImage(filyType){
    if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
      this.fileTypeImagePath = '/assets/pdf.svg';
      this.fileTypeAlt = "PDF File"

      return true;
    }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
      this.fileTypeImagePath = '/assets/zip.svg';
      this.fileTypeAlt = "ZIP File"
      return true;
    }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
      this.fileTypeImagePath = '/assets/xls.svg';
      this.fileTypeAlt = "XLS File"
      return true;
    }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
      this.fileTypeImagePath = '/assets/docx.svg';
      this.fileTypeAlt = "DOC File"
      return true;
    }
   }

   navtoSpecificReportsPage(id){
    let reportId: object;
  
    reportId = {'ReportTypeId': id};
    sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
    window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
  }

  /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
  getFile(file, checker) {
    try {
      let fileName: string;
      let fileType: string;
    
      if(checker == "static") {
        if(file.File_Type.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
        
        fileName = file.Document_Name + "." + file.File_Type;
      } else {
        if(file.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
    
        fileName = file;
      }
    
      this.staticContentService.getFile(fileName, "MD").subscribe(data => {
        try {
          let content = JSON.parse(data.body);
          let file = content;
          this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
          this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
          });
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFile");
          error.message += errorLocation;
          throw error;
        }
    
        
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }
 
  
  async inContentIcon(){   
    //matDatepicker mod
    $(".mat-input-infix span").remove();
    $(".mat-input-underline span").remove();
    $(".mat-input-subscript-wrapper").remove();
    $(".mat-input-underline").css("height","0");
    $(".mat-input-element").addClass("datepicker-input");
    $(".mat-input-element").css("height","30px");
    $(".mat-input-element").css("width","173px");
    $(".mat-input-element").css("margin-bottom","4px");
    $(".mat-input-element").css("margin-left","7px");
    $(".mat-input-element").css("text-indent","10px");
    $(".mat-input-element").css("background-color","#d3d0d0");
    $(".mat-input-infix input").css("height","30px");
    $(".mat-input-infix input").css("width","173px");
    $(".mat-input-infix input").css("margin-bottom","2.8px");
    $(".mat-input-infix input").css("background-color","#d3d0d0");
    $(".mat-form-field-infix").css("border-top","0");
    $(".mat-datepicker-toggle button").removeClass("mat-icon-button").addClass("btn btn-outline-secondary calendar");
    $(".mat-datepicker-toggle button span").remove();
    $(".mat-datepicker-toggle button div").remove();
    $(".mat-datepicker-toggle button").removeAttr("mat-icon-button");
    $(".mat-form-field-infix").css("width","0px");
    $(".mat-datepicker-toggle button").css("width","50px");
    $(".mat-datepicker-toggle button").css("height","32px");
    $(".mat-datepicker-toggle button").css("margin-left","0px");
    $(".mat-datepicker-toggle button").css("margin-bottom","4px");
    $(".mat-datepicker-toggle button").css("background-image","url(/assets/calendar.png)");
    $(".mat-datepicker-toggle button").css("background-repeat","no-repeat");
    $(".mat-datepicker-toggle button").css("background-size","23px");
    $(".mat-datepicker-toggle button").css("background-position","center");
    $(".mat-input-infix input").css("padding-left","16px");
  //end of matDatepicker Mod
}

    /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(eventBody) {  
    try {
      let href: string = eventBody.target.href;
      let id: string = eventBody.target.id;
      let srcElement: HTMLImageElement = eventBody.srcElement;
      let target: string = eventBody.target.target;
      let reportId: object;
      
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContentService.validateFileType(event);
        } else if(href.indexOf("reportid") != -1) {
          id = href.substring(href.lastIndexOf("=") + 1);
          reportId = {'ReportTypeId': id};
          sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
          window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
          return false;
        } else if(href.indexOf("/Default.aspx") != -1){
          let innerTxt = eventBody.target.innerText;
          event.preventDefault();

          if(target == "_blank") {
            if(href.indexOf("Resources") != -1) {
              window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
              return false; // prevent execution of pop up blocker & default href
            } else if(href.indexOf("Reports") != -1) {
              window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
              return false; // prevent execution of pop up blocker & default href
            }

            // if(innerTxt in MyHoldingsPagesResources){
            //   this.navToMyHoldingsPage("_blank");
            // }else if(href.indexOf("Resources") != -1){
            //   this.navToMyHoldingsPage("_blank");
            // } else if(href.indexOf("Reports")) {
            //   sessionStorage.setItem("REPORT ID", JSON.stringify(null));
            //   this.navToMyHoldingsPage("_blank");
            // }
          } else {
            if(href.indexOf("Resources") != -1){
              eventBody.target.href = this.globals.currentWebUrl + "/resourcespage";
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              eventBody.target.href = this.globals.currentWebUrl + "/reportspage";
            }
          }
          

        }else if(href.indexOf("#") != -1 && id != ""){
          if(target == "_blank") {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("_blank") : "";
          } else {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("_self"): "";
          }
        } else {
          if(href.indexOf('resourcespage') != -1) {
            window.open(this.globals.currentWebUrl + '/resourcespage', "_blank");
            return false; // prevents execution of pop up blocker & default href
          }
        }             
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : ""

    window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
  }

}