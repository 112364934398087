import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CompCodeItem } from '../../../../../services/ea/models/compcode-model';
import { Select, Store } from '@ngxs/store';
import { CompCodeItemState } from '../../../../../services/ea/states/compcode.state';
import { Globals } from '../../../../../globals';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { BdrLoadingService } from '../../../../../services/ea/api/BdrLoadingService';
import { RoleaccessService } from '../../../../../services/ea/api/RoleaccessService';
import { ModalComponent } from '../../../../../services/ea/shared/modal/modal.component';
import { LoadingOverlayComponent } from '../../../../../services/ea/shared/loading-overlay/loading-overlay.component';
import { CompanyCode } from '../../../../../services/ea/actions/compcode.actions';
import * as moment from 'moment';
import { BdraccessService } from '../../../../../services/ea/api/BdrAccessService';

@Component({
  selector: 'app-ccfieldinfo',
  templateUrl: './ccfieldinfo.component.html',
  styleUrls: ['./ccfieldinfo.component.css']
})
export class CcfieldinfoComponent implements OnInit {
  
  displayedColumns: string[];
  dataSource: MatTableDataSource<CompCodeItem>;
  tableData: CompCodeItem[] = [];
  dataToUpdate: CompCodeItem[] = [];
  pageSizeOption: number[];
  pageSizeLabel: string = "Show";
  pageIndex: number;
  totalNumOfPage: number;
  tableHasData: boolean = true;
  disableSubmitButton: boolean = true;
  entriesStart: number;
  entriesEnd: number;
  entriesLength: number;
  origEntriesLength: number;
  filterValue = {
    HOLDING_COMPANY_TYPE_DESC: '',
    ISSUE_TYPE_DESC: '',
    CHC_CO_CD: '',
    EFFECTIVE_POSTING_DATE: ''
  }

  @Select(CompCodeItemState.getCompCodeItems) compCodeItems: any;
  @ViewChild(MatPaginator, { static: true }) paginator:  MatPaginator;
  @Input('equityTypeList') equityTypeList: any;
  @Input('chcList') chcList: any;
  @Input('shareTypeList') shareTypeList: any;
  @Input('compCodeStaticText') compCodeStaticText: any;
  setDate: string = "Comp Code";
  onloadTableDataString: string;
  onloadTableStringArray: string[] = [];
  modalHeader: string;
  modalConfirmBody: string;
  modalUnAuthBody: string;
  modalUnchangedBody: string;
  modalSuccessBody: string;
  modalUnSuccessBody: string;
  tableHeaderHct: string;
  tableHeaderEqType: string;
  tableHeaderShareType: string;
  tableHeaderChcCoCd: string;
  tableHeaderEpd: string;
  roleId: number = this.roleAccessService.getUserRoleAccess();

  constructor(
    private globals: Globals,
    private modal: MatDialog,
    private apiService: ApiService,
    private store: Store,
    private compCodeLoadingService: BdrLoadingService,
    private roleAccessService: RoleaccessService,
    private bdrAccessService: BdraccessService
  ) { }

  ngOnInit() {
    this.pageSizeOption = this.globals.reportsPageSizeOptions;
    this.displayedColumns = this.globals.compCodeDisplayedColumns;
    this.setStaticText();
    this.compCodeItems.subscribe( data => {
      this.onloadTableDataString = JSON.stringify(data);
      for (let i = 0; i < data.length; i++) {
        this.onloadTableStringArray.push(JSON.stringify(data[i]));
      }
      this.tableData = data;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.origEntriesLength = this.tableData.length;
    })
  }

  ngAfterViewInit(){
    this.dataSource.filterPredicate = (data) => (data.HOLDING_COMPANY_TYPE_DESC.trim().toLowerCase().indexOf(this.filterValue.HOLDING_COMPANY_TYPE_DESC.trim().toLowerCase()) !== -1 ||
                                                      data.EFFECTIVE_POSTING_DATE.toString().trim().toLowerCase().indexOf(this.filterValue.EFFECTIVE_POSTING_DATE.trim().toLowerCase()) !== -1 ||
                                                      data.CHC_CO_CD.toString().trim().toLowerCase().indexOf(this.filterValue.CHC_CO_CD.trim().toLowerCase()) !== -1 ||
                                                      data.ISSUE_TYPE_DESC.toString().trim().toLowerCase().indexOf(this.filterValue.ISSUE_TYPE_DESC.trim().toLowerCase()) !== -1);
      this.dataSource.paginator = this.paginator;
      this.paginator.pageSize = this.pageSizeOption[0];
  }

  ngAfterContentInit(){
    this.paginator._intl.itemsPerPageLabel = this.pageSizeLabel;
  }

  ngAfterContentChecked(){
    this.getNumberOfPage();
  }

  /**********************************************************************************
 * Method Name: setStaticText                                                                 
 * Description: Sets the values of modal header and table tabs.                   
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/12/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/ 
  setStaticText(){
    try {
      this.modalHeader = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.header")].MESSAGE_VALUE;
      this.modalConfirmBody = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.modalconfirm.body")].MESSAGE_VALUE;
      this.modalUnAuthBody = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.modalunauth.body")].MESSAGE_VALUE;
      this.modalUnchangedBody = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.modalunchanged.body")].MESSAGE_VALUE;
      this.modalSuccessBody = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.modalsuccess.body")].MESSAGE_VALUE;
      this.modalUnSuccessBody = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.modalunsuccess.body")].MESSAGE_VALUE;
      this.tableHeaderHct = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.tableheader.hct")].MESSAGE_VALUE;
      this.tableHeaderEqType = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.tableheader.eqtype")].MESSAGE_VALUE;
      this.tableHeaderShareType = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.tableheader.sharetype")].MESSAGE_VALUE;
      this.tableHeaderChcCoCd = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.tableheader.chccocd")].MESSAGE_VALUE;
      this.tableHeaderEpd = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.tableheader.epd")].MESSAGE_VALUE;
    } catch (error) {
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('setStaticText', 'Ccfieldinfo');
      throw error;
    }
  }

 /**********************************************************************************
 * Method Name: getDataIndex                                                                 
 * Description: Returns the index of the given key.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         02/19/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
  getDataIndex(key){
    try {
      let index;
      for (let i = 0; i < this.compCodeStaticText.length; i++) {
        if (this.compCodeStaticText[i].MESSAGE_NAME == key) {
          index = i
        }
      }
      return index;
    } catch (error) {
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('getDataIndex', 'Ccfieldinfo');
      throw error;
    }
  }
  
  /**********************************************************************************
  * Method Name: getNumberOfPage                                                                 
  * Description: Sets the number of pages and the index of the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/22/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getNumberOfPage(){
    try {
      let dataLength;
      dataLength = this.filterValue.HOLDING_COMPANY_TYPE_DESC != "" ? this.dataSource.filteredData.length : this.dataSource.data.length;
      dataLength > 0 ? this.entriesStart = 1 + (this.paginator.pageIndex * this.paginator.pageSize) : this.entriesStart = 0;
      this.entriesEnd = dataLength < this.paginator.pageSize ? dataLength : this.paginator.pageSize + (this.paginator.pageSize * this.paginator.pageIndex);
      this.entriesEnd =  this.entriesEnd > dataLength ? dataLength : this.entriesEnd;
      this.entriesLength = dataLength;
      this.pageIndex = this.paginator.pageIndex + 1;
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;      
    } catch (error) {
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('getNumberOfPage', 'Ccfieldinfo');
      throw error;
    }      
  }
  
  /**********************************************************************************
  * Method Name: applyFilter                                                                 
  * Description: Apply filter using the entered word in search box.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         5/14/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  applyFilter(filterVal: string) {
    try { 
      let filterValue = filterVal; 
      this.filterValue.HOLDING_COMPANY_TYPE_DESC = filterValue;
      this.filterValue.ISSUE_TYPE_DESC = filterValue;
      /* istanbul ignore next line */
      this.filterValue.CHC_CO_CD = filterValue;
      this.filterValue.EFFECTIVE_POSTING_DATE = filterValue;
      this.dataSource.filter = JSON.stringify(this.filterValue);
      this.tableHasData = this.dataSource.filteredData.length > 0;
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;
      this.paginator.firstPage();
    } catch (error) {
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('applyFilter', 'Ccfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getDefaultVal                                                                 
  * Description: Sets the default value of share type and chc dropdown.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/27/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getDefaultVal(type,val){
    try {
      switch (type) {
        case 'issue':
          for (let i = 0; i < this.shareTypeList.length; i++) {
            if (val == this.shareTypeList[i].ISSUE_TYPE_ID) {
              return this.shareTypeList[i];
            }            
          }
          break;

        case 'chc':
          for (let i = 0; i < this.chcList .length; i++) {
            if (val == this.chcList[i].CHC_CO_CD) {
              return this.chcList[i].CHC_CO_CD;
            }
          }
      
        default:
          break;
      }
    } catch (error) {
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('getDefaultVal', 'Ccfieldinfo');
      throw error;
    }
  }

  /* istanbul ignore next line */
  /**********************************************************************************
  * Method Name: updateData                                                                 
  * Description: Updates the data of the table and compare to stringified table data to check if changes were made.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/27/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  updateData(type, event, id){
    try {
      switch (type) {
        case 'issue':
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].ID == id) {
              this.tableData[i].ISSUE_TYPE_ID = event.value.ISSUE_TYPE_ID;
              this.tableData[i].ISSUE_TYPE_DESC = event.value.ISSUE_TYPE_DESC;
            }
          }
          break;
        
        case 'chc':
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].ID == id) {
              this.tableData[i].CHC_CO_CD = event.value;
            }
          }
          break;
        
        case 'date':
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].ID == id) {
              this.tableData[i].EFFECTIVE_POSTING_DATE = moment(new Date(event)).format("MM/DD/YYYY")
            }
          }
          break;
      
        default:
          break;
      }
      this.disableSubmitButton = this.onloadTableDataString == JSON.stringify(this.tableData);
    } catch (error) {
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('updateData', 'Ccfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openModalOverlay                                                                 
  * Description: Opens an overlay loading icon.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openModalOverlay(){
    try {
      this.modal.open(LoadingOverlayComponent, {disableClose: true, panelClass: 'overlay-modal'});
    } catch(error) {
      this.modal.closeAll();
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('openModalOverlay', 'Ccfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getEquityId                                                                 
  * Description: Gets the equity type id.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B. Fernando          Initial draft 
  ***********************************************************************************/
  getEquityId(type){
    try {
      for (let i = 0; i < this.equityTypeList.length; i++) {
        if (type == this.equityTypeList[i].Equity_Type_Desc) {
          return this.equityTypeList[i].Equity_Type_ID;
        }
      }
    } catch (error) {
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('getEquityId', 'Ccfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openErrorModal                                                                 
  * Description: Opens an error modal if there is an error in the api call.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openErrorModal(){
    try {
      let content: any = {
        header: this.modalHeader,
        body: this.modalUnSuccessBody,
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: "OK",
        btn2Name: ""
      }
      let errorModal = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });
  
      errorModal.afterClosed().subscribe( result => {
        if (result) {
          this.modal.closeAll();
        }
      })
    } catch(error) {
      this.modal.closeAll();
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('openErrorModal', 'Ccfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getHoldingCoRow                                                                 
  * Description: Creates the holdingCoRow based on changed items.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getHoldingCoRow(){
    try {
      let result = "";
      for (let i = 0; i < this.dataToUpdate.length; i++) {
        result += this.dataToUpdate[i].HOLDING_COMPANY_TYPE_ID + "~" + this.getEquityId(this.dataToUpdate[i].EQUITY_TYPE_DESC) + "~" + this.dataToUpdate[i].ISSUE_TYPE_ID + "~" + this.dataToUpdate[i].CHC_CO_CD + "~" + this.dataToUpdate[i].EFFECTIVE_POSTING_DATE +"|";
      }
      return result;
    } catch (error) {
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('getHoldingCoRow', 'Ccfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: submitChanges                                                                 
  * Description: Submits the changes made in the table and reloads the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  submitChanges(){
    try {
      window.scroll(0,0);
      if (this.roleId == 7 || this.roleId == 6) {
        this.openUnAuthModal();
      }else{
        if (this.onloadTableDataString == JSON.stringify(this.tableData)) {
          this.openUnchangedModal();
        }else{
          this.dataToUpdate = [];
          for (let i = 0; i < this.onloadTableStringArray.length; i++) {
            if (this.onloadTableStringArray[i] != JSON.stringify(this.tableData[i])) {
              this.dataToUpdate.push(this.tableData[i]);
            }
          }
          this.openConfirmModal();
        }
      }      
    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('submitChanges', 'Ccfieldinfo');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: openUnAuthModal                                                                 
  * Description: Opens an unauth modal when user forces to enable submit button.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
 openUnAuthModal(){
   try {
    let content: any = {
      header: this.modalHeader,
      body: this.modalUnAuthBody,
      hasBtn1: true,
      hasBtn2: false,
      btn1Name: "OK",
      btn2Name: ""
    }
    this.modal.open(ModalComponent, {
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        content
      }
    });     
   } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('openUnAuthModal', 'Ccfieldinfo');
      throw error;
   }
 }


 /**********************************************************************************
 * Method Name: openUnchangedModal                                                                 
 * Description: Opens a modal when the user tries to submit while there is no changes in the table.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
 openUnchangedModal(){
   try {
     let content: any = {
       header: this.modalHeader,
       body: this.modalUnchangedBody,
       hasBtn1: true,
       hasBtn2: false,
       btn1Name: "OK",
       btn2Name: ""
     }
     this.modal.open(ModalComponent, {
       panelClass: 'custom-modal',
       disableClose: true,
       data: {
         content
       }
     });      
   } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('openUnchangedModal', 'Ccfieldinfo');
      throw error;
   }
 }

 /**********************************************************************************
 * Method Name: openConfirmModal                                                                 
 * Description: Opens a confirmation modal.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
  openConfirmModal(){
    try {
      let content = {
        header: this.modalHeader,
        body: this.modalConfirmBody,
        hasBtn1: true,
        hasBtn2: true,
        btn1Name: "OK",
        btn2Name: "CANCEL"
      }
  
      let confirmModal = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });
  
      confirmModal.afterClosed().subscribe( result => {
        if (result) {
          this.openModalOverlay();
          this.postChanges();
        }
      })
  
    } catch (error) {
      this.modal.closeAll();
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('openConfirmModal', 'Ccfieldinfo');
      throw error;
    }
  }

 /**********************************************************************************
 * Method Name: postChanges                                                                 
 * Description: Calls the api postdata.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
  postChanges(){
    let body = {
      functionName: "popHoldingCoData",
      holdingCoRow: this.getHoldingCoRow()
    }
    this.apiService.postData(this.globals.manageCompCodeApi, body).subscribe( data =>{
      try {
        if (data != null && data.statusCode == 200 ) {
          let body = data.body;
          if (body && body.length > 0) {
            let retval = body[0].RetVal;
            if (retval == 1) {
              this.modal.closeAll();
              this.openSuccessModal();
            }else {
              this.openErrorModal();
            }
          }else {
            this.openErrorModal();
          }
        }else {
          this.openErrorModal();
        }
      } catch(error) {
        this.modal.closeAll();
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('postChanges', 'Ccfieldinfo');
      throw error;
      }
    }, error => {
      this.modal.closeAll();
      this.bdrAccessService.setCompanyCodeError(true);
      error.error.message += this.apiService.setErrorContents('postChanges', 'Ccfieldinfo');
      throw error;
    });
  }

 /**********************************************************************************
 * Method Name: openSuccessModal                                                                 
 * Description: Opens sucess modal after submitting changes.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/26/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
 openSuccessModal(){
   try {
     let content: any = {
       header: this.modalHeader,
       body: this.modalSuccessBody,
       hasBtn1: true,
       hasBtn2: false,
       btn1Name: "OK",
       btn2Name: ""
     }
     let successModal = this.modal.open(ModalComponent, {
       panelClass: 'custom-modal',
       disableClose: true,
       data: {
         content
       }
     });
                 
     successModal.afterClosed().subscribe( result => {
       if (result) {
         this.reloadTable();
       }
     })
 
   } catch (error) {
    this.modal.closeAll();
    this.bdrAccessService.setCompanyCodeError(true);
    error.message += this.apiService.setErrorContents('openSuccessModal', 'Ccfieldinfo');
    throw error;
   }
   /* istanbul ignore next line */
 }
 /* istanbul ignore next line */
 /**********************************************************************************/ 
 /* istanbul ignore next line */
 /* Method Name: reloadTable                                                                 
 * Description: Reloads the table after sucessful submit of changes.                       
 */ /* istanbul ignore next line */                                                                                         
 /* #         Date                 Modified by                    Description */ 
 /* istanbul ignore next line */                            
 /* 1         03/26/20             John Paul B. Fernando          Initial draft */ 
 /* istanbul ignore next line */ 
 /***********************************************************************************/
 /* istanbul ignore next line */
 reloadTable(){
    this.compCodeLoadingService.setLoadingState(true);
    let reloadBody = {
      functionName: "getBusDataHoldingCo",
      equityTypeId: this.getEquityId(this.dataToUpdate[0].EQUITY_TYPE_DESC)
    }
    this.disableSubmitButton = true;
    this.apiService.postData(this.globals.manageCompCodeApi, reloadBody).subscribe( data => {
    try {
      let body = data.body
      if (body && body != "") {
        let bodyData = body;
        if(bodyData.length > 0 && bodyData != "Invalid Input"){
          this.store.dispatch( new CompanyCode.Clear_Comp_Code_Item);
          for (let i = 0; i < bodyData.length; i++) {
            this.store.dispatch( new CompanyCode.Add_Comp_Code_Item(bodyData[i]))
          }
          this.onloadTableDataString = JSON.stringify(bodyData);
          this.onloadTableStringArray = [];
          for (let i = 0; i < bodyData.length; i++) {
            this.onloadTableStringArray.push(JSON.stringify(bodyData[i]));
          }
          this.compCodeLoadingService.setLoadingState(false);
        }
      }
    } catch(error) {
      this.compCodeLoadingService.setLoadingState(false);
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('reloadTable', 'Ccfieldinfo');
      throw error;
    }
    }, error => {
    this.compCodeLoadingService.setLoadingState(false);
    this.bdrAccessService.setCompanyCodeError(true);
    error.error.message += this.apiService.setErrorContents('reloadTable', 'Ccfieldinfo');
    throw error;
    });
 }


}
