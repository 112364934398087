import { Injectable } from '@angular/core';
import { MsalGuardService } from './msal-guard.service'
import { MsalBroadcastService } from '@azure/msal-angular';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject, switchMap, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

declare var require: any;

@Injectable()
export class CanActivateGuard  {
  private readonly _destroying$ = new Subject<void>();
  isActive: string;
  env: any = require('../../../../env.json');
  currentWebUrl = this.env.URL;
  loginDisplay = false;
  dataSource: any =[];

  constructor( private msalBroadcastService: MsalBroadcastService, private msalService: MsalGuardService, private authService: MsalService, private router: Router ) {
    if((window.location.toString() != (this.currentWebUrl + "/homepage") || 0 <= window.location.toString().indexOf(this.currentWebUrl + "/homepage#id_token")) && (sessionStorage.getItem('postBack') == undefined || sessionStorage.getItem('postBack') == null || sessionStorage.getItem('postBack') == '' )){
      sessionStorage.setItem("user input url", window.location.toString());
    }
  }
 
 canActivate(): boolean { 
      this.msalService.isUserAuthenticated();
      return true;
   }

} 

  

