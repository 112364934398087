import { DataAppItem, CountryDetail, ActionComboItem } from "../models/dataapp-model";

export namespace Dataapproval{

    export class AddDataAppItem {
        static readonly type = '[Data Approval] Add_Report';
        constructor(public payload: DataAppItem){
            payload.JOURNAL_GROUP_DESC = payload.JOURNAL_GROUP_DESC != null ? payload.JOURNAL_GROUP_DESC : "";
            payload.HEADER_CO_CD = payload.HEADER_CO_CD != null ? payload.HEADER_CO_CD : "";
            payload.DR_CR = payload.DR_CR != null ? payload.DR_CR : "";
            payload.ACCOUNT_NUMBER = payload.ACCOUNT_NUMBER != null ? payload.ACCOUNT_NUMBER : "";
            payload.ROW_CO_CD = payload.ROW_CO_CD != null ? payload.ROW_CO_CD : "";
            payload.AMOUNT = payload.AMOUNT != null ? payload.AMOUNT : 0;
        }
    }

    export class ClearData {
        static readonly type = '[Data Approval] Clear_Data';
    }

    export class AddCountry {
        static readonly type = '[Data Approval] Add_Country';
        constructor(public payload: CountryDetail){
            payload.Country = payload.Country.trim();
        }
    }

    export class ClearCountries {
        static readonly type = '[Data Approval] Clear_Country';
    }

    export class AddComboItem {
        static readonly type = '[Data Approval] Add_Combo_Item';
        constructor(public payload: ActionComboItem){

        }
    }

    export class ClearComboItem {
        static readonly type = '[Data Approval] Clear_Combo_Item';
    }
}