
import {throwError as observableThrowError,  Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class ReportsService {
  rsuFromDate: any;
  rsuToDate: any;
  dateFrom: any;
  dateTo: any;


  constructor(private globals: Globals, private httpClient: HttpClient) { }

  getUserReports(hasEspp, hasVeip, enrollEspp, enrollVeip, hasFounder, hasRsu, hasOptions) {
    let apiUrl = this.globals.currentAPIUrl;
    let userReportsApi = apiUrl + this.globals.getUserReports;

    userReportsApi = userReportsApi.replace("[param1]", hasEspp);
    userReportsApi = userReportsApi.replace("[param2]", hasVeip);
    userReportsApi = userReportsApi.replace("[param3]", enrollEspp);
    userReportsApi = userReportsApi.replace("[param4]", enrollVeip);
    userReportsApi = userReportsApi.replace("[param5]", hasFounder);
    userReportsApi = userReportsApi.replace("[param6]", hasRsu);
    userReportsApi = userReportsApi.replace("[param7]", hasOptions);

    try {
      const api = userReportsApi;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getHasESPPData(optNum): any{ //calls API //Api= backend 
    let apiURL = this.globals.currentAPIUrl; //
    let hasESPPAPI = this.globals.getHasESPPAPI;

    hasESPPAPI = hasESPPAPI.replace("[param]", optNum);

    try{
        const api = apiURL + hasESPPAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  getHasVEIPData(optNum): any{ //calls API //Api= backend 
    let apiURL = this.globals.currentAPIUrl; //
    let hasVEIPAPI = this.globals.getHasVEIPAPI;

    hasVEIPAPI = hasVEIPAPI.replace("[param]", optNum);

    try{
        const api = apiURL + hasVEIPAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  getHasGrantData(optNum): any{ //calls API //Api= backend 
    let apiURL = this.globals.currentAPIUrl; //
    let hasGrantAPI = this.globals.getHasGrantAPI;

    hasGrantAPI = hasGrantAPI.replace("[param]", optNum);

    try{
        const api = apiURL + hasGrantAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  //This block of code is for Purchase Program Data
  getPurchaseProgramData(optNum, programType, fromDate, toDate, pageIndex)
  {
    let apiURL = this.globals.currentAPIUrl;
    let purchaseProgramAPI;
    let regEx: any = /\'/gi;
    this.dateFrom = moment(fromDate.replace(regEx,"")).format("YYYY-MM-DD");
    this.dateTo = moment(toDate.replace(regEx,"")).format("YYYY-MM-DD");

    if(pageIndex == -99)
    {
      if(programType == "ESPP")
      {
        purchaseProgramAPI = this.globals.generateESPPPurchaseProgramAPI;
      }
      else if(programType == "VEIP")
      {
        purchaseProgramAPI = this.globals.generateVEIPPurchaseProgramAPI;
      }

      purchaseProgramAPI = purchaseProgramAPI.replace("[param1]", optNum);
      purchaseProgramAPI = purchaseProgramAPI.replace("[param2]", this.dateFrom);
      purchaseProgramAPI = purchaseProgramAPI.replace("[param3]", this.dateTo);
    }
    else
    {
      if(programType == "ESPP")
      {
        purchaseProgramAPI = this.globals.getPurchaseProgramESPPAPI;
      }
      else if(programType == "VEIP")
      {
        purchaseProgramAPI = this.globals.getPurchaseProgramVEIPAPI;
      }
   
      purchaseProgramAPI = purchaseProgramAPI.replace("[param1]", optNum);
      purchaseProgramAPI = purchaseProgramAPI.replace("[param2]", this.dateFrom);
      purchaseProgramAPI = purchaseProgramAPI.replace("[param3]", this.dateTo);
      purchaseProgramAPI = purchaseProgramAPI.replace("[param4]", pageIndex);
    }

    try
    {
      const api = apiURL + purchaseProgramAPI; 
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res))); 
    }
    catch(err)
    {
      return err;
    }
  }
  //End of Purchase Program Data

  //This block of code gets the Purchase Program DropDown Data
  getPurchaseProgramDropDownData(optNum)
  {
    let apiURL = this.globals.currentAPIUrl;
    let purchaseProgramDropDownData = this.globals.getPurchaseProgramDropDownAPI;

    purchaseProgramDropDownData = purchaseProgramDropDownData.replace("[param]", optNum);

    try
    {
      const api = apiURL + purchaseProgramDropDownData; 
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res))); 
    }
    catch(err)
    {
      return err;
    }
  }
  //End of Purchase Program DropDown Data

  //This block of code gets the RSU Activity Data
  getRSUActivityDataData(optNum, fromDate, toDate, pageIndex, grantDesc)
  {
    let apiURL = this.globals.currentAPIUrl;
    let rsuActivityData;
    let regEx: any = /\'/gi; 
 
    if(pageIndex == -99){
      rsuActivityData = this.globals.generateRSUActivityAPI;
      this.rsuFromDate = moment(fromDate.replace(regEx,"")).format("YYYY-MM-DD");
      this.rsuToDate = moment(toDate.replace(regEx,"")).format("YYYY-MM-DD");    
  
      rsuActivityData = rsuActivityData.replace("[param1]", optNum);
      rsuActivityData = rsuActivityData.replace("[param2]", this.rsuFromDate);
      rsuActivityData = rsuActivityData.replace("[param3]", this.rsuToDate);
      rsuActivityData = rsuActivityData.replace("[param4]", grantDesc);

    }
    else{
      rsuActivityData = this.globals.getRSUActivityAward;
      this.rsuFromDate = moment(fromDate.replace(regEx,"")).format("YYYY-MM-DD");
      this.rsuToDate = moment(toDate.replace(regEx,"")).format("YYYY-MM-DD");    
  
      rsuActivityData = rsuActivityData.replace("[param1]", optNum);
      rsuActivityData = rsuActivityData.replace("[param2]", this.rsuFromDate);
      rsuActivityData = rsuActivityData.replace("[param3]", this.rsuToDate);
      rsuActivityData = rsuActivityData.replace("[param4]", pageIndex);
      rsuActivityData = rsuActivityData.replace("[param5]", grantDesc);
    }

    try
    {
      const api = apiURL + rsuActivityData;      
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res))); 
    }
    catch(err)
    {
      return err;
    }
  }
  //End of RSU Activity Data

  //This block of code gets the RSU Activity DropDown Data
  getRSUActivityDropDownData(optNum)
  {
    let apiURL = this.globals.currentAPIUrl;
    let getRSUActivityDropDownData = this.globals.getRSUActivityDropDownAPI;

    getRSUActivityDropDownData = getRSUActivityDropDownData.replace("[param]", optNum);

    try
    {
      const api = apiURL + getRSUActivityDropDownData; 
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res))); 
    }
    catch(err)
    {
      return err;
    }
  }
  //This block of code gets the RSU Activity DropDown Data
  getExchangeReportsHistory(currencyCd, fromDate, toDate, pageIndex) //pageIndex -99 means it is generating the report
  {
    let regEx: any = /\'/gi;
    let apiURL = this.globals.currentAPIUrl;
    let getExchangeHistRepData;

    if(pageIndex == -99){
      getExchangeHistRepData = this.globals.generateExchangeRateHistoryAPI;
  
      getExchangeHistRepData = getExchangeHistRepData.replace("[param1]", currencyCd.replace(regEx, ""));
      getExchangeHistRepData = getExchangeHistRepData.replace("[param2]", fromDate.replace(regEx, ""));
      getExchangeHistRepData = getExchangeHistRepData.replace("[param3]", toDate.replace(regEx, ""));
    }
    else{
      getExchangeHistRepData = this.globals.getExchangeRateReportsAPI;
  
      getExchangeHistRepData = getExchangeHistRepData.replace("[param1]", currencyCd.replace(regEx, ""));
      getExchangeHistRepData = getExchangeHistRepData.replace("[param2]", fromDate.replace(regEx, ""));
      getExchangeHistRepData = getExchangeHistRepData.replace("[param3]", toDate.replace(regEx, ""));
      getExchangeHistRepData = getExchangeHistRepData.replace("[param4]", pageIndex);
    }

    try
    {
      const api = apiURL + getExchangeHistRepData; 
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res))); 
    }
    catch(err)
    {
      return err;
    }
  }
  //End of RSU Activity DropDown Data

  getIndividualTransferLimitData(optNum, fromDate, toDate, pageIndex){
    let apiURL = this.globals.currentAPIUrl; //
    let individualTransferLimitApi;
    let regEx: any = /\'/gi;
    this.dateFrom = moment(fromDate.replace(regEx,"")).format("YYYY-MM-DD");
    this.dateTo = moment(toDate.replace(regEx,"")).format("YYYY-MM-DD");     

    if(pageIndex == -99)
    {
      individualTransferLimitApi = this.globals.generateIndividualTransferLimitAPI;

      individualTransferLimitApi = individualTransferLimitApi.replace("[param1]", optNum);
      individualTransferLimitApi = individualTransferLimitApi.replace("[param2]", this.dateFrom);
      individualTransferLimitApi = individualTransferLimitApi.replace("[param3]", this.dateTo);
    }
    else
    {
      individualTransferLimitApi = this.globals.getITLReportAPI;
      
      individualTransferLimitApi = individualTransferLimitApi.replace("[param1]", optNum);
      individualTransferLimitApi = individualTransferLimitApi.replace("[param2]", this.dateFrom);
      individualTransferLimitApi = individualTransferLimitApi.replace("[param3]", this.dateTo);
      individualTransferLimitApi = individualTransferLimitApi.replace("[param4]", pageIndex);
    }

     try{
        const api = apiURL + individualTransferLimitApi; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }

  }

//This block of code gets the RSU Activity DropDown Data
getSalesHistoryCheckboxData(optNum)
{
  let apiURL = this.globals.currentAPIUrl;
  let getSalesHistoryCheckboxData = this.globals.getSalesHistoryProgramDropDownAPI;

  getSalesHistoryCheckboxData = getSalesHistoryCheckboxData.replace("[param]", optNum);

  try
  {
    const api = apiURL + getSalesHistoryCheckboxData; 
    return this.httpClient.get(api).pipe(map(res => res), retry(3),
    catchError((res: Response) => this.onError(res))); 
  }
  catch(err)
  {
    return err;
  }
}
//End of Sales History Activity DropDown Data

//This block of code gets the Sales History DropDown Data
getSalesHistoryGrantData(optNum, holdingsId)
{
  let apiURL = this.globals.currentAPIUrl;
  let getSalesHistoryGrantData = this.globals.getSalesHistoryGrantsDropDownAPI;

  getSalesHistoryGrantData = getSalesHistoryGrantData.replace("[param1]", optNum);
  getSalesHistoryGrantData = getSalesHistoryGrantData.replace("[param2]", holdingsId);

  try{
    const api = apiURL + getSalesHistoryGrantData; 
    return this.httpClient.get(api).pipe(map(res => res), retry(3),
    catchError((res: Response) => this.onError(res))); 
  }catch(err){
    return err;
  }
}
//End of Sales History DropDown Data



   //This block of code gets the Sales History Data
   getSalesHistoryData(optNum, fromDate, toDate, grantDesc, programType, pageIndex)
   {
     let apiURL = this.globals.currentAPIUrl;
     let salesHistoryData;
     let regEx: any = /\'/gi;
     this.dateFrom = moment(fromDate.replace(regEx,"")).format("YYYY-MM-DD");
     this.dateTo = moment(toDate.replace(regEx,"")).format("YYYY-MM-DD");     
 
     if(pageIndex == -99)
     {
        salesHistoryData = this.globals.generateSalesHistoryAPI;

        salesHistoryData = salesHistoryData.replace("[param1]", optNum);
        salesHistoryData = salesHistoryData.replace("[param2]", this.dateFrom);
        salesHistoryData = salesHistoryData.replace("[param3]", this.dateTo);
        salesHistoryData = salesHistoryData.replace("[param4]", grantDesc);
        salesHistoryData = salesHistoryData.replace("[param5]", programType);
     }
     else
     {
        salesHistoryData = this.globals.getSalesHistoryReportsAPI;

        salesHistoryData = salesHistoryData.replace("[param1]", optNum);
        salesHistoryData = salesHistoryData.replace("[param2]", this.dateFrom);
        salesHistoryData = salesHistoryData.replace("[param3]", this.dateTo);
        salesHistoryData = salesHistoryData.replace("[param4]", grantDesc);
        salesHistoryData = salesHistoryData.replace("[param5]", programType);
        salesHistoryData = salesHistoryData.replace("[param6]", pageIndex);
     }
 
     try
     {
       const api = apiURL + salesHistoryData; 
       return this.httpClient.get(api).pipe(map(res => res), retry(3),
       catchError((res: Response) => this.onError(res))); 
     }
     catch(err)
     {
       return err;
     }
   }
   //End of Sales History Data

   getCurrencyList(){
    let apiURL = this.globals.currentAPIUrl;
    let exchangeRtcurrency = this.globals.getExchangeRtCurrencyList;

    try{
       const api = apiURL + exchangeRtcurrency; 
       return this.httpClient.get(api).pipe(map(res => res), retry(3),
       catchError((res: Response) => this.onError(res))); 
    }catch(err){
      return err;
    }
   }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 

}
