import { State, Action, StateContext, Selector, Actions } from '@ngxs/store';
import { DataAppItem, CountryDetail, ActionComboItem } from "../models/dataapp-model";
import { Dataapproval } from "../actions/dataapproval.actions";

export class DataAppItemStateModel{
    items: DataAppItem[]
}

@State<DataAppItemStateModel>({
    name: "dataappitems",
    defaults: {
        items: []
    }
})

export class DataAppItemState {

    @Selector()
    static getDataAppItems(state: DataAppItemStateModel){
        return state.items
    }

    @Selector()
    static getGroupIds(state: DataAppItemStateModel){
        const arraySet = new Set(state.items.map(id => id.JOURNAL_GROUP_ID));
        const result = Array.from(arraySet);
        return result
    }

    @Action(Dataapproval.AddDataAppItem)
    add(
        store : StateContext<DataAppItemStateModel>,
        action: Dataapproval.AddDataAppItem
    ){
        const state = store.getState();
        store.patchState({
            items: [...state.items, action.payload]
        })
    }

    @Action(Dataapproval.ClearData)
    clear(
        store: StateContext<DataAppItemStateModel>
    ){
        store.setState({
            items: []
        })
    }
}

export class DataAppCountryStateModel{
    countries: CountryDetail[]
}

@State<DataAppCountryStateModel>({
    name: "dataappcountries",
    defaults: {
        countries: [{Country_ID: 0, Country: ""}]
    }
})

export class DataAppCountryState {
    @Selector()
    static getCountries(state: DataAppCountryStateModel){
        return state;
    }

    @Action(Dataapproval.AddCountry)
    add(
        store : StateContext<DataAppCountryStateModel>,
        action: Dataapproval.AddCountry
    ){
        const state = store.getState();
        store.patchState({
            countries: [...state.countries, action.payload]
        })
    }

    @Action(Dataapproval.ClearCountries)
    clear(
        store : StateContext<DataAppCountryStateModel>
    ){
        store.setState({
            countries: [{Country_ID: 0, Country: ""}]
        })
    }
}
 
export class DataAppComboItemStateModel {
    comboItems: ActionComboItem[]
}

@State<DataAppComboItemStateModel>({
    name: "dataappcomboitems",
    defaults: {
        comboItems: []
    }
})

export class DataAppComboItemState {
    @Selector()
    static getComboItems(state: DataAppComboItemStateModel){
        return state.comboItems;
    }

    @Action(Dataapproval.AddComboItem)
    add(
        store: StateContext<DataAppComboItemStateModel>,
        action: Dataapproval.AddComboItem
    ){
        const state = store.getState();
        store.patchState({
            comboItems: [...state.comboItems, action.payload]
        })
    }

    @Action(Dataapproval.ClearComboItem)
    clear(
        store: StateContext<DataAppComboItemStateModel>
    ){
        store.setState({
            comboItems: []
        })
    }
}