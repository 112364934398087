import { Injectable } from '@angular/core';
import { ApiService } from '../api/ApiService';
import { Globals } from '../../../globals';

@Injectable()
export class SemaccessService {
  role: any;
  documentId: number = parseInt(sessionStorage.getItem(this.global.documentID));
  document: any;
  constructor(
    private apiService: ApiService, 
    private global: Globals
  ) { }

  /**********************************************************************************
  * Method Name: getSemAccess                                                                 
  * Description: Validates SEM Access
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         5/19/2020            Jikho Enrique                    Initial draft 
  ***********************************************************************************/
 getSemAccess(component){
  let access = new Promise<void>((resolve) => {
    let accessSem: any = {
      "functionName": "getSecPageAccess"
    }
    this.apiService.postData(this.global.api_URL_accessTool, accessSem).subscribe(async data => {
      try{
        if (data != null && data.statusCode == 200) {
          let body = data.body;
          if (body) {
            this.role = body;
            resolve();
          } else {
            this.role = false;
            resolve();
          }
        } else {
          this.role = false;
          resolve();
        }

      } catch (error) {
        error.message += this.apiService.setErrorContents('getSemAccess', component);
        throw error;
      }
    }, error => {
      
      error.error.message +=this.apiService.setErrorContents('getSemAccess', component); 
      throw error;
    });
  });
  return access;
}

/**********************************************************************************
* Method Name: getDocumentAccess                                                                 
* Description: Validates document Access and retrieves admin type
*                                                                                          
* #         Date                 Modified by                      Description                             
* 1         5/19/2020            Jikho Enrique                    Initial draft 
***********************************************************************************/
getDocumentAccess(component) {
  let access = new Promise<void>((resolve) => {
    let accessCheck: any = {
      "docid": this.documentId,
      "functionName": "getSecurityCheck"
    }
    this.apiService.postData(this.global.api_URL_accessTool, accessCheck).subscribe(data => {
      try {
        if (data != null && data.statusCode == 200) {
          let body = data.body;
          if (body) {
            this.document = body;
            resolve();
          } else {
            this.document = false;
            resolve();
          }

        } else {
          this.document = false;
          resolve();
        }
      } catch (error) {
        error.message += this.apiService.setErrorContents('getDocumentAccess', component);
        throw error;
      }
    }, error => {
      
      error.error.message +=this.apiService.setErrorContents('getDocumentAccess', component);
      throw error;
    });
  });
  return access;
}

}
