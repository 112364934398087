import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/ea/api/ApiService';
import { Globals } from '../../../../globals';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";



@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  reportsDropdownList: any[];
  statusDropdownList: any [];
  reportSelected: string;
  statusSelected: string;
  projectedChangeMonth: any[] = [
    {id: 1, month: "January"},
    {id: 2, month: "February"},
    {id: 3, month: "March"},
    {id: 4, month: "April"},
    {id: 5, month: "May"},
    {id: 6, month: "June"},
    {id: 7, month: "July"},
    {id: 8, month: "August"},
    {id: 9, month: "September"},
    {id: 10, month: "October"},
    {id: 11, month: "November"},
    {id: 12, month: "December"}
  ]
  projectedChangeYear: any[] = [
    {id: 1, year: 1998},
    {id: 2, year: 1999},
    {id: 3, year: 2000},
    {id: 4, year: 2001},
    {id: 5, year: 2002},
    {id: 6, year: 2003},
    {id: 7, year: 2004},
    {id: 8, year: 2005}
  ]
  monthlyChanges: any[] = [
    {id: 1, month: "January"}, 
    {id: 2, month: "February"}, 
    {id: 3, month: "March"}, 
    {id: 4, month: "April"}, 
    {id: 5, month: "May"}, 
    {id: 6, month: "June"}, 
    {id: 7, month: "July"}, 
    {id: 8, month: "August"}, 
    {id: 9, month: "September"}, 
    {id: 10, month: "October"}, 
    {id: 11, month: "November"}, 
    {id: 12, month: "December"}];
  monthToUse: any[] = [
    {id: "Primary", month: "Primary"},
    {id: "1", month: "January"}, 
    {id: "2", month: "February"}, 
    {id: "3", month: "March"}, 
    {id: "4", month: "April"}, 
    {id: "5", month: "May"}, 
    {id: "6", month: "June"}, 
    {id: "7", month: "July"}, 
    {id: "8", month: "August"}, 
    {id: "9", month: "September"}, 
    {id: "10", month: "October"}, 
    {id: "11", month: "November"}, 
    {id: "12", month: "December"}];
  RRD: any[] = [
    {year: 1997}, 
    {year: 1998}, 
    {year: 1999}, 
    {year: 2000}, 
    {year: 2001},
    {year: 2002}, 
    {year: 2003}, 
    {year: 2004}, 
    {year: 2005}, 
    {year: 2006}];
  selectedMonthMCL: string;
  selectedYearRRD: string;
  selectedStatus: string;
  isMonthlyChangeList: boolean = true;
  isRRD: boolean = false;
  isPartnerByName: boolean = false;
  runReportBtn: boolean = true;
  startDateInput: string;
  endDateInput: string;
  loading: boolean = true;

  
  displayedColumns: any;
  displayMonthly: string[] = ['Status','Letter Received','Partner Name','Transaction Type','Additional Comments','Office From','List No From','Aria No From','Node No From','Box No From','Office To','List No To','Aria No To','Node No To','Box No To','Effective Date']
  displayLOA: string[] = ['Counter', 'Status', 'WWID', 'Last Name', 'First Name', 'Middle Name', 'Node', 'Box', 'Admit Date', 'Coeff Country', 'Deployed-to Entity', 'LOA Start Date', 'LOA End Date', 'Comments'];
  displayRRD: string[] = ['New', 'Letter Received', 'Partner Name', 'Coeff Country', 'Competency', 'Market Unit', 'Status', 'Leave Date', 'Age', 'Last LOR', 'Last Rating', 'Total Units', 'Actual Units', 'Admit Date', 'Notice Payment', 'Bonus Through', 'Final Draft', 'Waiver', 'Per MU', 'Reason', 'WWID', 'Local PN', 'Circumstance Comments']
  displayRRD2: string[] = ['New', 'Letter Received', 'Partner Name', 'Coeff Country', 'Competency', 'Market Unit', 'Status', 'Leave Date', 'Age', 'Last LOR', 'Last Rating', 'Total Units', 'Actual Units', 'Admit Date', 'Settlement', 'Job Search', 'Final Draft', 'Lump Sum', 'Per BU', 'Reason', 'WWID', 'Local PN', 'Circumstance Comments']
  displayRRD3: string[] = ['New', 'Letter Received', 'Partner Name', 'Coeff Country', 'Market Unit', 'Status', 'Leave Date', 'Age', 'Last LOR', 'Last Rating', 'Total Units', 'Actual Units', 'Admit Date', 'Notice Payment', 'Bonus Through', 'Final Draft', 'Waiver', 'Per MU', 'Reason', 'WWID', 'Local PN', 'Circumstance Comments']
  displayPartTime: string[] = ['Counter', 'Status', 'WWID', 'Last Name', 'First Name', 'Middle Name', 'Node', 'Box', 'Admit Date', 'Coeff Country', 'Deployed-to Entity', 'PT %', 'PT Start Date', 'PT End Date', 'Comments'];
  displayPartnerByName: string[] = ['Partner Name', 'Physical Office', '', 'Partner Name', 'Physical Office'];

  displayRI: string[] = ['Status', 'RI Partners', 'Designated Responsible Partner', 'Annual Reviewer', 'PIC or During the Year', 'RI Start Date', 'RI End Date', 'Date Partner Notified', 'Improvement Plan Received', 'Improved Date', 'Deployed to Entity', 'Office', 'Country', 'Base Units', 'Perf Units', 'Transition Units', 'Units', 'Coeff Adj. Units', 'LOR', 'Perf Rating', 'Age', 'Comments on RI Status', 'Leave Date', 'Notice Period', "Letter Rec'd"]
  displayContractor: string[] = ['Status', 'Last Name', ' First Name', 'Middle Name', 'Country', 'Departure Date', 'Unit Level at Departure', 'Status at Departure', 'Role', 'Start Date', 'End Date', 'Resource Requirement', 'Responsible Partner', 'Daily Rate', 'Deployed-to Entity', 'Operating Group Contact', 'US Cx Representative', 'Comments'];
  displayProjectedChanges: string[] = ['Partner Name', 'Transaction Type', 'Additional Comments', 'Office From', 'List No From', 'Aria No From', 'Node No From', 'Box No From', 'Office To', 'List No To', 'Aria No To', 'Node No To', 'Box No To', 'Effective Date', 'Date Processed'];

  monthlyChangesData = []; 
  monthlyChangesDataNote: string = '';
  sName: string;
  fileName: string;
  excelFileName: string;
  name: string;
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
  colArray = [];
  myArray: any[][];
  data: any;

  montlyChangesSelected: number;
  monthlyChangesSelected: number;
  RRDHeader: string = '';
  RrdData = [];
  partTimeHeader: string = '';
  partTimeData = [];
  loaData = [];
  loaDataNote:string = '';
  riData = [];
  totalActive: number;
  totalLeaving: number;
  totalImproved: number;
  totalCompleted: number;
  totalRemoved: number;
  contractorData = [];

  rrdTotalString: string = "Total Retirements, Resignations, and Deaths"
  rrdTotalCount: any;

  statusId: any;
  totalPartnersString: string = "Total Partners"
  totalPartnersCount: any;
  partnerByNameData = [];
  partnerbyNameHeader: string = '';
  isProjectedChanges: boolean = false;
  yearDropdownList: any = [];
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
  startMonthId: number;
  endMonthId: number;
  PCHeader: string = "";
  projectedChangeData = [];
 
  partnerByLocationData = [];
  partnerByLocationOfficeFormat = [];
  partnerByLocationTotalsFormat = [];
  partnerByLocationNotice: any;
  startYearId: number;
  endYearId: number;
  sheetName: any;
  isError: boolean = false;

  constructor(
    private apiService: ApiService,
    private global: Globals,
  ) { }

  ngOnInit() {
    this.reportsDropdown();
    this.getStatusList();
    this.getReportYear();
  }


/**********************************************************************************
  * Method Name: reportsDropdown                                                                
  * Description: get dropdown list for reports                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1       6/2/2020             Divy O.Ardiente                   Initial draft   
  ***********************************************************************************/

 reportsDropdown(){
   let argDocumentDropdown: any = {
     "functionName": "getReportsList"
   }
   this.apiService.postData(this.global.api_URL_reportList, argDocumentDropdown).subscribe(data => {
     try {
       let contentBody = data.body;

       this.reportsDropdownList = contentBody['data'];
       this.reportSelected = contentBody['data'][0]['REPORT_NAME'];
       this.loading = false;

       if (this.reportSelected == 'Monthly Change List') {
         this.isMonthlyChangeList = true;
         this.isRRD = false;
         this.isProjectedChanges = false;
       } else if (this.reportSelected == 'RR&D') {
         this.isRRD = true;
         this.isMonthlyChangeList = false;
         this.isProjectedChanges = false;
       } else if (this.reportSelected == 'Partner by Name') {
         this.isPartnerByName = true;
         this.isMonthlyChangeList = false;
         this.isRRD = false;
         this.isProjectedChanges = false;
       } else if (this.reportSelected == 'Projected Change Report') {
         this.isPartnerByName = false;
         this.isProjectedChanges = true;
         this.isRRD = false;
         this.isMonthlyChangeList = false;
       } else {
         this.isRRD = false;
         this.isMonthlyChangeList = false;
         this.isPartnerByName = false;
         this.isProjectedChanges = false;
       }
     } catch (error) {
        this.loading = false;
        this.isError = true;
       error.message += this.apiService.setErrorContents('reportsDropdown', 'reports');
       throw error
     }

   }, error => {
     
     this.loading = false;
     this.isError = true;
     error.error.message += this.apiService.setErrorContents('reportsDropdown', 'reports');
     throw error;
   });

 }

 /**********************************************************************************
  * Method Name: getStatusList                                                                
  * Description: get status list for reports                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1       6/25/2020             Joshua Paul E. Peralta            Initial draft   
  ***********************************************************************************/
 getStatusList(){
   let accessList: any = {
     "functionName": "getStatusList"
   }

   this.apiService.postData(this.global.api_URL_reportList, accessList).subscribe(data => {
     try {
       if (data != null && data != undefined) {
         if (data.statusCode == 200) {
           let contentBody = data.body;
           this.statusDropdownList = contentBody['data'];
         } else {
           // error token data
           //redirect to myH
         }
       }
     } catch (error) {
      this.loading = false;
      this.isError = true;
       error.message += this.apiService.setErrorContents('getStatusList', 'reports');
       throw error
     }

   }, error => {
     
     this.loading = false;
     this.isError = true;
     error.error.message += this.apiService.setErrorContents('getStatusList', 'reports');
     throw error;
   });

 }

 /**********************************************************************************
  * Method Name: getReportYear                                                                
  * Description: get status list for reports                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1       7/1/2020             Joshua Paul E. Peralta             Initial draft   
  ***********************************************************************************/
 getReportYear(){
   let accessYear: any = {
     "functionName": "getReportYear"
   }

   this.apiService.postData(this.global.api_URL_reportList, accessYear).subscribe(data => {
     try {
       if (data != null && data != undefined) {
         if (data.statusCode == 200) {
           let contentBody = data.body;
           this.yearDropdownList = contentBody['data']
         }
       }
     } catch (error) {
      this.loading = false;
      this.isError = true;
       error.message += this.apiService.setErrorContents('getReportYear', 'reports');
       throw error
     }

   }, error => {
     
     this.loading = false;
     this.isError = true;
     error.error.message += this.apiService.setErrorContents('getReportYear', 'reports');
     throw error;
   });
 }



 /**********************************************************************************
  * Method Name: selectedReportNavigation                                                          
  * Description:                
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1       6/2/2020             Divy O.Ardiente                   Initial draft   
  ***********************************************************************************/

  selectedReportNavigation(report: string){
    this.reportSelected = report;
    this.selectedMonthMCL = "";
    this.selectedYearRRD = "";
    this.selectedStatus = "";
    this.startMonth = ""
    this.endMonth = "";
    this.startYear = "";
    this.endYear = "";
    this.resetValues();
    this.runReportBtn = true;
    

    if(report == 'Monthly Change List'){
      this.isMonthlyChangeList = true;
      this.isRRD = false;
      this.isPartnerByName = false;
      this.isProjectedChanges = false;
    }else if(report == 'RR&D'){
      this.isRRD = true;
      this.isMonthlyChangeList = false;
      this.isPartnerByName = false;
      this.isProjectedChanges = false;
    }else if(report == 'Partner by Name' || report == 'Partner by Location'){
      this.isPartnerByName = true;
      this.isMonthlyChangeList = false;
      this.isRRD = false;
      this.isProjectedChanges = false;
    }else if(report == 'Projected Change Report'){
      this.isProjectedChanges = true;
      this.isPartnerByName = false;
      this.isRRD = false;
      this.isMonthlyChangeList = false;
    }else{
      this.isMonthlyChangeList = false;
      this.isRRD = false;
      this.isPartnerByName = false;
      this.isProjectedChanges = false;
      this.runReportBtn = false;
      
    }
  }

/**********************************************************************************
 * Method Name: getPCStartMonth, getPCStartYear, getPCEndMonth, getPCEndYear                                                         
 * Description: to get the start month, start year, end month and end year for Projected Change List and enable the Run
 *              this Report button           
 *                                                                                       
 * #         Date                 Modified by                      Description                             
 * 1       7/1/2020             Joshua Peralta                     Initial draft   
 ***********************************************************************************/
getPCStartMonth(month, id){
  this.startMonth = month;
  this.startMonthId = id;

}
getPCStartYear(year, id){
  this.startYear = year;
  this.startYearId = id;
}
getPCEndMonth(month, id){
  this.endMonth = month;
  this.endMonthId = id;

}
getPCEndYear(year, id){
  this.endYear = year;
  this.endYearId = id;
 
}

dateCheck(){
  if((this.startMonth != "" && this.startYear != null) || (this.endMonth != "" && this.endYear != null)){
    if((this.startMonthId != null && this.startYearId != null ) && (this.endMonthId != null && this.endYearId != null)){
     if((this.startMonthId <= this.endMonthId) && (this.startYear <= this.endYear)){
       this.runReportBtn = false;
     }else if((this.startMonthId >= this.endMonthId) && (this.startYear == this.endYear)){
       this.runReportBtn = true;
     }else if((this.startMonthId >= this.endMonthId) && (this.startYear <= this.endYear)){
       this.runReportBtn = false;
     }else{
       this.runReportBtn = true;
     }
    }
  }
}

resetValues(){
  this.startMonth = ""
  this.startYear = ""
  this.endMonth = ""
  this.endYear = ""
  this.startMonthId = null
  this.endMonthId = null
  this.endYearId = null
  this.startYearId = null
}

/**********************************************************************************
  * Method Name: getMonthMCL                                                          
  * Description: to get the selected month for Monthly Change List and enable the Run
  *              this Report button           
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1       6/3/2020             Divy O.Ardiente                   Initial draft   
  ***********************************************************************************/

  getMonthMCL(month, id){
    this.monthlyChangesSelected = id;
    this.selectedMonthMCL = month;
    this.runReportBtn = false
  }

/**********************************************************************************
  * Method Name:   getYearRRD                                                          
  * Description: to get the selected year for RR&D and enable the Run this Report button      
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1       6/3/2020             Divy O.Ardiente                   Initial draft   
  ***********************************************************************************/

  getYearRRD(year){
    this.selectedYearRRD = year;
    this.runReportBtn = false;
  }

  /**********************************************************************************
  * Method Name: getStatus                                                          
  * Description: to get the selected status for Partner by Name and enable the Run this Report button
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1       6/25/2020              Joshua Paul E. Peralta           Initial draft   
  ***********************************************************************************/
  getStatus(id,status){
    this.statusId = id;
    this.selectedStatus = status;
    this.runReportBtn = false;
  }

  runReport() {
    if (!this.runReportBtn) {
      this.loading = true;
      if (this.reportSelected == "Monthly Change List") { //Monthly Change List Report API connection
        this.displayedColumns = this.displayMonthly;
        let getMonthlyChangeListParams: any = {
          "functionName": "getMonthlyChangeList",
          "month": this.monthlyChangesSelected
        }
        this.apiService.postData(this.global.api_URL_reportList, getMonthlyChangeListParams).subscribe(async data => {
          try {
            if (data != null && data != undefined) {
              if (data.statusCode == 200) {
                let body = data.body;
                if (body['data'] != null && body['data'] != undefined) {
                  this.monthlyChangesDataNote = body['data'][0][0]['HEADER'];
                  this.getReportInfo(this.reportSelected, this.displayedColumns, body['data'][1]);
                } else {
                  // redirect to myH
                  // error for token or data
                }
              }
            }
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('runReport', 'reports');
            throw error
          }

        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('runReport', 'reports');
          throw error;
        });
      } else if (this.reportSelected == "RR&D") { //RR&D Report API connection
        if (this.selectedYearRRD == "1997" || this.selectedYearRRD == "1998" || this.selectedYearRRD == "1999" || this.selectedYearRRD == "2000" || this.selectedYearRRD == "2001") { //changes column names when year is 1997-2001
          this.displayedColumns = this.displayRRD2;
        } else if (this.selectedYearRRD == "2002") {
          this.displayedColumns = this.displayRRD3;
        } else {
          this.displayedColumns = this.displayRRD;
        }
        let getRRDReportParams: any = {
          "functionName": "getRrdReport",
          "rrd_yr": this.selectedYearRRD
        }
        this.apiService.postData(this.global.api_URL_reportList, getRRDReportParams).subscribe(async data => {
          try {
            if (data != null && data != undefined) {
              if (data.statusCode == 200) {
                let contentBody = data.body;
                if (contentBody['data'] != null && contentBody['data'] != undefined) {
                  this.RRDHeader = contentBody['data'][0][0]['HEADER'];
                  this.rrdTotalCount = contentBody['data'][2][0]['Total Retirements, Resignations and Deaths'];
                  this.getReportInfo(this.reportSelected, this.displayedColumns, contentBody['data'][1]);
                }
              }
            }
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('runReport', 'reports');
            throw error
          }

        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('runReport', 'reports');
          throw error;
        });
      } else if (this.reportSelected == "LOA") {  //LOA Report API connection
        this.displayedColumns = this.displayLOA;
        let getLOAParam: any = {
          "functionName": "getReportLOA"
        }
        this.apiService.postData(this.global.api_URL_reportList, getLOAParam).subscribe(async data => {
          try {
            if (data != null && data != undefined) {
              if (data.statusCode == 200) {
                let body = data.body;
                if (body['data'] != null && body['data'] != undefined) {
                  this.loaDataNote = body['data'][0][0]['FirstRow'];
                  this.getReportInfo(this.reportSelected, this.displayedColumns, body['data'][1]);
                } else {
                  // error for token or data
                }
              }
            }
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('runReport', 'reports');
            throw error
          }

        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('runReport', 'reports');
          throw error;
        });
      } else if (this.reportSelected == "Part Time") { //Part Time Report API connection
        this.displayedColumns = this.displayPartTime;
        let getPartTimeParams: any = {
          "functionName": "getPartTime"
        }

        this.apiService.postData(this.global.api_URL_reportList, getPartTimeParams).subscribe(async data => {
          try {
            if (data != null && data != undefined) {
              if (data.statusCode == 200) {
                let contentBody = data.body;
                if (contentBody['data'] != null && contentBody['data'] != undefined) {
                  this.partTimeHeader = contentBody['data'][0][0]['FirstRow'];
                  this.getReportInfo(this.reportSelected, this.displayedColumns, contentBody['data'][1]);
                } else {
                  //error for token or data
                }
              }
            }
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('runReport', 'reports');
            throw error
          }

        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('runReport', 'reports');
          throw error;
        });
      } else if (this.reportSelected == "Partner by Name") { //Partner by Name Report API connection
        this.displayedColumns = this.displayPartnerByName;
        let getPartnerNameParams: any = {
          "functionName": "getPartnerByName",
          "partnerStatus": this.statusId
        }

        this.apiService.postData(this.global.api_URL_reportList, getPartnerNameParams).subscribe(async data => {
          try {
            if (data != null && data != undefined) {
              if (data.statusCode == 200) {
                let contentBody = data.body;
                //if (contentBody['data'] != null && contentBody['data'] != undefined) {
                  this.totalPartnersCount = contentBody['data'][1][0]['PARTNER_COUNT'];
                  this.getReportInfo(this.reportSelected, this.displayedColumns, contentBody['data'][0]);
                // } else {

                // }
              }
            }
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('runReport', 'reports');
            throw error
          }

        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('runReport', 'reports');
          throw error;
        });


      } else if (this.reportSelected == "RI") { //RI Report API connection
        this.displayedColumns = this.displayRI;
        let getRIParam: any = {
          "functionName": "getRIReport"
        }
        this.apiService.postData(this.global.api_URL_reportList, getRIParam).subscribe(async data => {
          try {
            if (data != null && data != undefined) {
              if (data.statusCode == 200) {
                let body = data.body;
                //if (body['data'] != null && body['data'] != undefined) {
                  this.totalActive = body['data'][1][0]['TOTAL_ACTIVE'];
                  this.totalLeaving = body['data'][2][0]['TOTAL_LEAVING'];
                  this.totalImproved = body['data'][3][0]['TOTAL_IMPROVED'];
                  this.totalCompleted = body['data'][4][0]['TOTAL_COMPLETED'];
                  this.totalRemoved = body['data'][5][0]['TOTAL_REMOVED'];
                  this.getReportInfo(this.reportSelected, this.displayedColumns, body['data'][0]);
                //} else {
                  // error for token or data
                //}
              }
            }
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('runReport', 'reports');
            throw error
          }

        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('runReport', 'reports');
          throw error;
        });


      } else if (this.reportSelected == "Contractor Report") { //Contractor Report API connection
        this.displayedColumns = this.displayContractor;
        let getContractorParam: any = {
          "functionName": "getContractorReport"
        }
        this.apiService.postData(this.global.api_URL_reportList, getContractorParam).subscribe(async data => {
          try {
            if (data != null  && data != undefined) {
              if (data.statusCode == 200) {
                let body = data.body;
                //if (body['data'] != null && body['data'] != undefined) {
                  this.getReportInfo(this.reportSelected, this.displayedColumns, body['data'][0]);
                // } else {
                //   // error for token or data
                // }
              }
            }
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('runReport', 'reports');
            throw error
          }

        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('runReport', 'reports');
          throw error;
        });


      } else if (this.reportSelected == "Projected Change Report") {
        this.displayedColumns = this.displayProjectedChanges;
        let getPCParams: any = {
          "functionName": "getProjectedChangesReport",
          "start_month": this.startMonthId,
          "start_year": this.startYear,
          "end_month": this.endMonthId,
          "end_year": this.endYear
        }

        this.apiService.postData(this.global.api_URL_reportList, getPCParams).subscribe(async data => {
          try {
            if (data != null && data != undefined) {
              if (data.statusCode == 200) {
                let contentBody = data.body;
                if (contentBody['data'] != null && contentBody['data'] != undefined) {
                  this.PCHeader = contentBody['data'][0][0]['HEADERS'];
                  this.getReportInfo(this.reportSelected, this.displayedColumns, contentBody['data'][1]);
                } else {

                }
              }
            }
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('runReport', 'reports');
            throw error
          }

        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('runReport', 'reports');
          throw error;
        });

      } else if (this.reportSelected == "Partner by Location") { //Partner by Name Report API connection
        // this.displayedColumns = this.displayPartnerByName;
        let getPartnerLocationParams: any = {
          "functionName": "getPartnerByLocation",
          "partnerStatus": this.statusId
        }

        this.apiService.postData(this.global.api_URL_reportList, getPartnerLocationParams).subscribe(async data => {
          try {
            if (data != null && data != undefined) {
              if (data.statusCode == 200) {
                let contentBody = data.body;
                if (contentBody['data'] != null && contentBody['data'] != undefined) {
                  this.getReportInfo(this.reportSelected, [], contentBody['data']);
                } else {

                }
              }
            }
          } catch (error) {
            this.loading = false;
            this.isError = true;
            error.message += this.apiService.setErrorContents('runReport', 'reports');
            throw error
          }

        }, error => {
          
          this.loading = false;
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('runReport', 'reports');
          throw error;
        });


      }

    }
  }

  getReportInfo(report, headers, row){
    try {
      if (report == "Monthly Change List") {
        this.sName = "Monthly Changes";
        this.excelFileName = "Monthly_Change_Report.xls"
        this.monthlyChangesData = [];
        for (let i = 0; i < row.length; i++) {
          this.monthlyChangesData.push({
            col1: row[i]['STATUS'],
            col2: row[i]['LETTER_RECEIVED'],
            col3: row[i]['PARTNER_NAME'],
            col4: row[i]['TRANSACTION_TYPE'],
            col5: row[i]['ADDITIONAL_COMMENTS'],
            col6: row[i]['OFFICE_FROM'],
            col7: row[i]['LIST_NO_FROM'],
            col8: row[i]['ARIA_NO_FROM'],
            col9: row[i]['NODE_NO_FROM'],
            col10: row[i]['BOX_NO_FROM'],
            col11: row[i]['OFFICE_TO'],
            col12: row[i]['LIST_NO_TO'],
            col13: row[i]['ARIA_NO_TO'],
            col14: row[i]['NODE_NO_TO'],
            col15: row[i]['BOX_NO_TO '],
            col16: row[i]['EFFECTIVE_DATE'],
          })
        }
        this.exportToExcel(report, headers, this.monthlyChangesData);
      } else if (report == "RR&D") {
        this.sName = "RR&D Report";
        this.excelFileName = "RRD_Report.xls"
        this.RrdData = [];
        if (this.selectedYearRRD == "1997" || this.selectedYearRRD == "1998" || this.selectedYearRRD == "1999" || this.selectedYearRRD == "2000" || this.selectedYearRRD == "2001") {
          for (let x = 0; x < row.length; x++) {
            this.RrdData.push({
              col1: row[x]['NEW'],
              col2: row[x]['LETTER_RECEIVED'],
              col3: row[x]['PARTNER NAME'],
              col4: row[x]['COEFF_COUNTRY'],
              col5: row[x]['COMPETENCY'],
              col6: row[x]['MARKET UNIT'],
              col7: row[x]['STATUS'],
              col8: row[x]['LEAVE DATE'],
              col9: row[x]['AGE'],
              col10: row[x]['LAST LOR'],
              col11: row[x]['LAST RATING'],
              col12: this.totalUnitsFormat(row[x]['TOTAL_UNITS']),
              col13: row[x]['ACTUAL_UNITS'],
              col14: row[x]['ADMIT DATE'],
              col15: row[x]['NOTICE_PAYMENT'],
              col16: row[x]['JOB_SEARCH'],
              col17: row[x]['FINAL_DRAFT'],
              col18: row[x]['LUMP_SUM'],
              col19: row[x]['PER_BU'],
              col20: row[x]['REASON'],
              col21: row[x]['WWID'],
              col22: row[x]['LOCAL PN'],
              col23: row[x]['COMMENTS'],
            })
          }
        } else if (this.selectedYearRRD == "2002") {
          for (let x = 0; x < row.length; x++) {
            this.RrdData.push({
              col1: row[x]['NEW'],
              col2: row[x]['LETTER_RECEIVED'],
              col3: row[x]['PARTNER NAME'],
              col4: row[x]['COEFF_COUNTRY'],
              col5: row[x]['MARKET UNIT'],
              col6: row[x]['STATUS'],
              col7: row[x]['LEAVE DATE'],
              col8: row[x]['AGE'],
              col9: row[x]['LAST LOR'],
              col10: row[x]['LAST RATING'],
              col11: this.totalUnitsFormat(row[x]['TOTAL_UNITS']),
              col12: row[x]['ACTUAL_UNITS'],
              col13: row[x]['ADMIT DATE'],
              col14: row[x]['NOTICE_PAYMENT'],
              col15: row[x]['BONUS THROUGH'],
              col16: row[x]['FINAL_DRAFT'],
              col17: row[x]['WAIVER'],
              col18: row[x]['PER_MU'],
              col19: row[x]['REASON'],
              col20: row[x]['WWID'],
              col21: row[x]['LOCAL PN'],
              col22: row[x]['COMMENTS'],
            })
          }
        } else {
          for (let x = 0; x < row.length; x++) {
            this.RrdData.push({
              col1: row[x]['NEW'],
              col2: row[x]['LETTER_RECEIVED'],
              col3: row[x]['PARTNER NAME'],
              col4: row[x]['COEFF_COUNTRY'],
              col5: row[x]['COMPETENCY'],
              col6: row[x]['MARKET UNIT'],
              col7: row[x]['STATUS'],
              col8: row[x]['LEAVE DATE'],
              col9: row[x]['AGE'],
              col10: row[x]['LAST LOR'],
              col11: row[x]['LAST RATING'],
              col12: this.totalUnitsFormat(row[x]['TOTAL_UNITS']),
              col13: row[x]['ACTUAL_UNITS'],
              col14: row[x]['ADMIT DATE'],
              col15: row[x]['NOTICE_PAYMENT'],
              col16: row[x]['BONUS THROUGH'],
              col17: row[x]['FINAL_DRAFT'],
              col18: row[x]['WAIVER'],
              col19: row[x]['PER_MU'],
              col20: row[x]['REASON'],
              col21: row[x]['WWID'],
              col22: row[x]['LOCAL PN'],
              col23: row[x]['COMMENTS'],
            })
          }
        }
        this.exportToExcel(report, headers, this.RrdData);
      } else if (report == "LOA") { //LOA REPORT
        this.sName = "LOA";
        this.excelFileName = "LOA_Report.xls"
        this.loaData = [];
        for (let i = 0; i < row.length; i++) {
          this.loaData.push({
            col1: row[i]['Counter'],
            col2: row[i]['STATUS'],
            col3: row[i]['WWID'],
            col4: row[i]['LAST_NAME'],
            col5: row[i]['FIRST_NAME'],
            col6: row[i]['MIDDLE_NAME'],
            col7: row[i]['NODE_NO'],
            col8: row[i]['BOX_NO'],
            col9: row[i]['ADMIT_DATE'],
            col10: row[i]['COUNTRY_NAME'],
            col11: row[i]['MARKET_ENTITY_DESCRIPTION'],
            col12: row[i]['LOA_START_DATE'],
            col13: row[i]['LOA_END_DATE'],
            col14: row[i]['COMMENTS']
          })
        }
        this.exportToExcel(report, headers, this.loaData);
      } else if (report == "Part Time") {
        this.sName = "Part Time";
        this.excelFileName = "Part_Time_Report.xls";
        this.partTimeData = [];
        for (let i = 0; i < row.length; i++) {
          this.partTimeData.push({
            col1: row[i]['Counter'],
            col2: row[i]['Status'],
            col3: row[i]['WWID'],
            col4: row[i]['Last Name'],
            col5: row[i]['First Name'],
            col6: row[i]['Middle Name'],
            col7: row[i]['Node'],
            col8: row[i]['Box'],
            col9: row[i]['Admit Date'],
            col10: row[i]['Coeff Country'],
            col11: row[i]['Deployed-to Entity'],
            col12: row[i]['PT %'],
            col13: row[i]['PT Start Date'],
            col14: row[i]['PT End Date'],
            col15: row[i]['Comments']
          })
        }
        this.exportToExcel(report, headers, this.partTimeData);
      } else if (report == "Partner by Name") {
        this.sName = "Partners by Name";
        this.excelFileName = "Partner_By_Name_Report.xls";
        this.partnerByNameData = [];
        row[row.length] = {PARTNER_NAME: "", PHYSICAL_OFFICE: ""}
        for (let x:number = 0; x < row.length-1; x++) {
          this.partnerByNameData.push({
            col1: row[x]['PARTNER_NAME'],
            col2: row[x]['PHYSICAL_OFFICE'],
            col3: "",
            col4: row[x+1]['PARTNER_NAME'],
            col5: row[x+1]['PHYSICAL_OFFICE']
          })
        }
        this.exportToExcel(report, headers, this.partnerByNameData);
      } else if (report == "RI") { //RI Report
        this.sName = "RI Report";
        this.excelFileName = "RI_Report.xls"
        this.riData = [];
        for (let i = 0; i < row.length; i++) {
          this.riData.push({
            col1: row[i]['STATUS'],
            col2: row[i]['RI_PARTNER'],
            col3: row[i]['DESIGNATED_RESPONSIBLE_PARTNER'],
            col4: row[i]['ANNUAL_REVIEWER'],
            col5: row[i]['PIC_OR_DURING_YEAR'],
            col6: row[i]['RI_START_DATE'],
            col7: row[i]['RI_END_DATE'],
            col8: row[i]['DATE_PARTNER_NOTIFIED'],
            col9: row[i]['IMPROVEMENT_PLAN_RECEIVED'],
            col10: row[i]['IMPROVEMENT_DATE'],
            col11: row[i]['DEPLOYED_TO_ENTITY'],
            col12: row[i]['OFFICE'],
            col13: row[i]['COUNTRY_NAME'],
            col14: row[i]['BASE_UNITS'],
            col15: row[i]['PERF_UNITS'],
            col16: row[i]['TRANSITION_UNITS'],
            col17: row[i]['UNITS'],
            col18: row[i]['COEFF_ADJ_UNITS'],
            col19: row[i]['LOR'],
            col20: row[i]['PERF_RATING'],
            col21: row[i]['AGE'],
            col22: row[i]['COMMENTS_ON_RI_STATUS'],
            col23: row[i]['LEAVE_DATE'],
            col24: row[i]['NOTICE_PERIOD'],
            col25: row[i]['LETTER_RECEIVED']
          })

        }
        this.exportToExcel(report, headers, this.riData);
      } else if (report == "Contractor Report") { //Contractor Report
        this.sName = "CONTRACTORS";
        this.excelFileName = "Contractor_Report.xls"
        this.contractorData = [];
        for (let i = 0; i < row.length; i++) {
          this.contractorData.push({
            col1: row[i]['Status'],
            col2: row[i]['Last Name'],
            col3: row[i]['First Name'],
            col4: row[i]['Middle Name'],
            col5: row[i]['Country'],
            col6: row[i]['Departure Date'],
            col7: row[i]['Unit Level at Departure'],
            col8: row[i]['Status at Departure'],
            col9: row[i]['Role'],
            col10: row[i]['Start Date'],
            col11: row[i]['End Date'],
            col12: row[i]['Resource Requirement'],
            col13: row[i]['Responsible Partner'],
            col14: row[i]['Daily Rate'],
            col15: row[i]['Deployed-to Entity'],
            col16: row[i]['Operating Group Contact'],
            col17: row[i]['US Cx Representative'],
            col18: row[i]['Comments']
          })

        }
        this.exportToExcel(report, headers, this.contractorData);

      } else if(report == "Partner by Location"){
        this.sName = "Partner By Location";
        this.excelFileName = "Partner_By_Location_Report.xls"
        this.partnerByLocationData = [];
        this.partnerByLocationOfficeFormat = [];
        this.partnerByLocationTotalsFormat = [];
        let partnerByLocationOfficeFormatCounter = 0;
        this.partnerByLocationOfficeFormat.push("A1");
        if(row != ""){
          for( let i  = 0; i < row[0][0].length; i++){
            this.partnerByLocationData.push({col1: Object.keys(row[0][0][i])[0], col2:""})
            partnerByLocationOfficeFormatCounter+=2;
            for(let x = 0; x < row[0][0][i][Object.keys(row[0][0][i])[0]].length; x++){
              this.partnerByLocationData.push({col1: row[0][0][i][Object.keys(row[0][0][i])[0]][x], col2:this.partnerByLocationFormat(row[0][0][i][Object.keys(row[0][0][i])[0]][x+1])})
              partnerByLocationOfficeFormatCounter++
            }
            this.partnerByLocationOfficeFormat.push("A".concat((partnerByLocationOfficeFormatCounter+1).toString()))
            this.partnerByLocationTotalsFormat.push("A".concat((partnerByLocationOfficeFormatCounter).toString()),"B".concat((partnerByLocationOfficeFormatCounter).toString()))
            this.partnerByLocationData.push({col1: "Total in "+ Object.keys(row[0][0][i])[0], col2: row[0][0][i]['OFFICE_COUNT']})
          }
          this.partnerByLocationData.push({col1: "Totals US", col2: row[0][1][0]['US_COUNT']});
  
          this.partnerByLocationData.push({col1: "", col2: ""});
          
          this.partnerByLocationOfficeFormat.push("A".concat((partnerByLocationOfficeFormatCounter+3).toString()))
          partnerByLocationOfficeFormatCounter+=2;
          for( let i  = 0; i < row[1][0].length; i++){
            this.partnerByLocationData.push({col1: Object.keys(row[1][0][i])[0], col2:""})
            partnerByLocationOfficeFormatCounter+=2;
            for(let x = 0; x < row[1][0][i][Object.keys(row[1][0][i])[0]].length; x++){
              this.partnerByLocationData.push({col1: row[1][0][i][Object.keys(row[1][0][i])[0]][x], col2:this.partnerByLocationFormat(row[1][0][i][Object.keys(row[1][0][i])[0]][x+1])})
              partnerByLocationOfficeFormatCounter++
            }
            this.partnerByLocationOfficeFormat.push("A".concat((partnerByLocationOfficeFormatCounter+1).toString()))
            this.partnerByLocationTotalsFormat.push("A".concat((partnerByLocationOfficeFormatCounter).toString()),"B".concat((partnerByLocationOfficeFormatCounter).toString()))
  
            this.partnerByLocationData.push({col1: "Total in "+ Object.keys(row[1][0][i])[0], col2: row[1][0][i]['OFFICE_COUNT']})
          }
          this.partnerByLocationOfficeFormat.push("A".concat((partnerByLocationOfficeFormatCounter+3).toString()))
          this.partnerByLocationData.push({col1: "Totals Non US", col2: row[1][1][0]['NON_US_COUNT']});
          this.partnerByLocationData.push({col1: "", col2: ""});
          this.partnerByLocationData.push({col1: "TOTAL WORLDWIDE", col2: row[1][1][0]['NON_US_COUNT'] + row[0][1][0]['US_COUNT']});
  
          this.partnerByLocationNotice = partnerByLocationOfficeFormatCounter + 4;

          this.exportToExcel(report, headers, this.partnerByLocationData); 
        }else{
          this.partnerByLocationNotice = 2;
          this.exportToExcel(report, headers, [{col1: "There is no data to view.", col2: ""}]); 
        }
        
        
        

      }else if(report == "Projected Change Report"){
        this.sName = "Monthly Changes"
        this.excelFileName = "Projected_Change_Report.xls"
        this.projectedChangeData = [];
        for(let i = 0; i < row.length; i++){
          this.projectedChangeData.push({
            col1: row[i]['PARTNER_NAME'],
            col2: row[i]['TRANSACTION_TYPE'],
            col3: row[i]['Additional_Comments'],
            col4: row[i]['OFFICE_FROM'],
            col5: row[i]['LIST_FROM'],
            col6: row[i]['ARIA_FROM'],
            col7: row[i]['NODE_FROM'],
            col8: row[i]['BOX_FROM'],
            col9: row[i]['OFFICE_TO'],
            col10: row[i]['LIST_TO'],
            col11: row[i]['ARIA_TO'],
            col12: row[i]['NODE_TO'],
            col13: row[i]['BOX_TO'],
            col14: row[i]['EFFECTIVE_DATE'],
            col15: row[i]['DATE_PROCESSED']
          })
        }

        this.exportToExcel(report, headers, this.projectedChangeData);

      }

    }catch(error){
      error.message += this.apiService.setErrorContents('getReportInfo', 'reports');
      throw error;
    }
    
  }
  
  exportToExcel(report,headers, rows){
    try{

    
    let workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    // workbook.addWorksheet(this.sName, {views: [{state: 'frozen', ySplit: 3, xSplit: 2, activeCell: 'A1', showGridLines: true }] })
    workbook.addWorksheet(this.sName, { views: [{ width: 1000000000, height: 20000, activeCell: 'A1', showGridLines: true }] })
    let sheet = workbook.getWorksheet(1);

    if(report == "Monthly Change List"){
      sheet.getRow(3).values = headers;//for first merged column row, should be getRow(1) if report has no first merged column row
      sheet.mergeCells('A2', 'E2'); //for first merged column row
      sheet.getCell('A2').value = this.monthlyChangesDataNote; //for first merged column row
      sheet.columns =
        [
          { key: 'col1', width: 15 },
          { key: 'col2', width: 10 },
          { key: 'col3', width: 20 },
          { key: 'col4', width: 18 },
          { key: 'col5', width: 16 },
          { key: 'col6', width: 18 },
          { key: 'col7', width: 10 },
          { key: 'col8', width: 10 },
          { key: 'col9', width: 10 },
          { key: 'col10', width: 10 },
          { key: 'col11', width: 18 },
          { key: 'col12', width: 10 },
          { key: 'col13', width: 10 },
          { key: 'col14', width: 10 },
          { key: 'col15', width: 10 },
          { key: 'col16', width: 8 },
        ];
      this.colArray = ['A3', 'B3','C3','D3','E3','F3','G3','H3','I3','J3','K3','L3','M3','N3','O3','P3']; //varies on column and if report has no first merged column row
    } else if(report == "RR&D"){
      if(this.selectedYearRRD == "2002"){
      sheet.getRow(3).values = headers;
      sheet.mergeCells('A2', 'E2');
      sheet.getCell('A2').value = this.RRDHeader;
      sheet.columns = 
      [
        { key: 'col1', width: 12 },
        { key: 'col2', width: 12 },
        { key: 'col3', width: 20 },
        { key: 'col4', width: 18 },
        { key: 'col5', width: 16 },
        { key: 'col6', width: 18 },
        { key: 'col7', width: 12 },
        { key: 'col8', width: 12 },
        { key: 'col9', width: 12 },
        { key: 'col10', width: 12 },
        { key: 'col11', width: 12 },
        { key: 'col12', width: 12 },
        { key: 'col13', width: 12 },
        { key: 'col14', width: 12 },
        { key: 'col15', width: 13 },
        { key: 'col16', width: 13 },
        { key: 'col17', width: 13 },
        { key: 'col18', width: 13 },
        { key: 'col19', width: 13 },
        { key: 'col20', width: 13 },
        { key: 'col21', width: 13 },
        { key: 'col22', width: 20 },
      ];
      this.colArray = ['A3', 'B3','C3','D3','E3','F3','G3','H3','I3','J3','K3','L3','M3','N3','O3','P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3'];
      
      }else {
      sheet.getRow(3).values = headers;
      sheet.mergeCells('A2', 'E2');
      sheet.getCell('A2').value = this.RRDHeader;
      sheet.columns = 
      [
        { key: 'col1', width: 12 },
        { key: 'col2', width: 12 },
        { key: 'col3', width: 20 },
        { key: 'col4', width: 18 },
        { key: 'col5', width: 16 },
        { key: 'col6', width: 18 },
        { key: 'col7', width: 12 },
        { key: 'col8', width: 12 },
        { key: 'col9', width: 12 },
        { key: 'col10', width: 12 },
        { key: 'col11', width: 12 },
        { key: 'col12', width: 12 },
        { key: 'col13', width: 12 },
        { key: 'col14', width: 12 },
        { key: 'col15', width: 13 },
        { key: 'col16', width: 13 },
        { key: 'col17', width: 13 },
        { key: 'col18', width: 13 },
        { key: 'col19', width: 13 },
        { key: 'col20', width: 13 },
        { key: 'col21', width: 13 },
        { key: 'col22', width: 13 },
        { key: 'col23', width: 20 }
      ];
      this.colArray = ['A3', 'B3','C3','D3','E3','F3','G3','H3','I3','J3','K3','L3','M3','N3','O3','P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3'];
      
      }
    } else if(report == "LOA"){ //LOA REPORT
      sheet.getRow(3).values = headers;//for first merged column row, should be getRow(1) if report has no first merged column row
      sheet.mergeCells('A2', 'E2'); //for first merged column row
      sheet.getCell('A2').value = this.loaDataNote; //for first merged column row
      sheet.columns =
        [
          { key: 'col1', width: 7 },
          { key: 'col2', width: 9 },
          { key: 'col3', width: 10 },
          { key: 'col4', width: 18 },
          { key: 'col5', width: 16 },
          { key: 'col6', width: 7 },
          { key: 'col7', width: 7 },
          { key: 'col8', width: 8 },
          { key: 'col9', width: 9 },
          { key: 'col10', width: 15 },
          { key: 'col11', width: 23 },
          { key: 'col12', width: 9 },
          { key: 'col13', width: 9 },
          { key: 'col14', width: 22 }
        ];
      this.colArray = ['A3', 'B3','C3','D3','E3','F3','G3','H3','I3','J3','K3','L3','M3','N3']; //varies on column and if report has no first merged column row
    } else if(report == "Part Time"){
      sheet.getRow(3).values = headers;
      sheet.mergeCells('A2', 'E2');
      sheet.getCell('A2').value = this.partTimeHeader;
      sheet.columns = 
      [
        { key: 'col1', width: 9 },
        { key: 'col2', width: 9 },
        { key: 'col3', width: 9 },
        { key: 'col4', width: 9 },
        { key: 'col5', width: 9 },
        { key: 'col6', width: 9 },
        { key: 'col7', width: 9 },
        { key: 'col8', width: 9 },
        { key: 'col9', width: 9 },
        { key: 'col10', width: 14 },
        { key: 'col11', width: 14 },
        { key: 'col12', width: 9 },
        { key: 'col13', width: 9 },
        { key: 'col14', width: 9 },
        { key: 'col15', width: 29 },
      ];
      this.colArray =  ['A3','B3','C3','D3','E3','F3','G3','H3','I3','J3','K3','L3','M3','N3','O3'];
    } else if(report == "Partner by Name"){
      sheet.getRow(2).values = headers;
      sheet.columns = 
      [
        { key: 'col1', width: 20},
        { key: 'col2', width: 20},
        { key: 'col3', width: 10},
        { key: 'col4', width: 20},
        { key: 'col5', width: 20},
      ];
      
      this.colArray = ['A2', 'B2','C2', 'D2', 'E2'];
    } else if((report == "RI")){ //RI REPORT
      sheet.getRow(2).values = headers;//for first merged column row, should be getRow(1) if report has no first merged column row
      sheet.columns =
        [
          { key: 'col1', width: 13 },
          { key: 'col2', width: 20 },
          { key: 'col3', width: 20 },
          { key: 'col4', width: 18 },
          { key: 'col5', width: 12 },
          { key: 'col6', width: 10 },
          { key: 'col7', width: 10 },
          { key: 'col8', width: 10 },
          { key: 'col9', width: 12 },
          { key: 'col10', width: 12 },
          { key: 'col11', width: 12 },
          { key: 'col12', width: 12 },
          { key: 'col13', width: 10 },
          { key: 'col14', width: 7 },
          { key: 'col15', width: 7 },
          { key: 'col16', width: 7 },
          { key: 'col17', width: 12 },
          { key: 'col18', width: 10 },
          { key: 'col19', width: 10 },
          { key: 'col20', width: 8 },
          { key: 'col21', width: 9 },
          { key: 'col22', width: 22 },
          { key: 'col23', width: 11 },
          { key: 'col24', width: 12 },
          { key: 'col25', width: 12 }
        ];
      this.colArray = ['A2', 'B2','C2','D2','E2','F2','G2','H2','I2','J2','K2','L2','M2','N2', 'O2', 'P2', 'Q2', 'R2', 'S2', 'T2', 'U2', 'V2', 'W2', 'X2', 'Y2']; //varies on column and if report has no first merged column row
    } else if((report == "Contractor Report")){ //CONTRACTOR REPORT
      sheet.getRow(2).values = headers;//for first merged column row, should be getRow(1) if report has no first merged column row
      sheet.columns =
        [
          { key: 'col1', width: 12 },
          { key: 'col2', width: 15 },
          { key: 'col3', width: 15 },
          { key: 'col4', width: 15 },
          { key: 'col5', width: 13 },
          { key: 'col6', width: 12 },
          { key: 'col7', width: 12 },
          { key: 'col8', width: 13 },
          { key: 'col9', width: 20 },
          { key: 'col10', width: 12 },
          { key: 'col11', width: 12 },
          { key: 'col12', width: 15 },
          { key: 'col13', width: 15 },
          { key: 'col14', width: 12 },
          { key: 'col15', width: 13 },
          { key: 'col16', width: 15 },
          { key: 'col17', width: 15 },
          { key: 'col18', width: 16 }
        ];
      this.colArray = ['A2', 'B2','C2','D2','E2','F2','G2','H2','I2','J2','K2','L2','M2','N2', 'O2', 'P2', 'Q2', 'R2']; //varies on column and if report has no first merged column row
    }else if(report == "Partner by Location"){
      sheet.columns =
        [
          { key: 'col1', width: 25 },
          { key: 'col2', width: 25 },
        ];
        this.colArray = ['A1', 'B1'];
    }else if(report == "Projected Change Report"){
      sheet.getRow(3).values = headers
      sheet.mergeCells('A2', 'E2');
      sheet.getCell('A2').value = this.PCHeader;
      sheet.columns = 
      [
        { key: 'col1', width: 20 },
        { key: 'col2', width: 20 },
        { key: 'col3', width: 20 },
        { key: 'col4', width: 20 },
        { key: 'col5', width: 20 },
        { key: 'col6', width: 20 },
        { key: 'col7', width: 20 },
        { key: 'col8', width: 20 },
        { key: 'col9', width: 20 },
        { key: 'col10', width: 20 },
        { key: 'col11', width: 20 },
        { key: 'col12', width: 20 },
        { key: 'col13', width: 20 },
        { key: 'col14', width: 20 },
        { key: 'col15', width: 20 }
      ];
      this.colArray = ['A3', 'B3','C3','D3','E3','F3','G3','H3','I3','J3','K3','L3','M3','N3', 'O3'];
    }

    sheet.addRows(rows);
    if(report == "RR&D"){
      sheet.mergeCells('A'.concat(rows.length+5), 'C'.concat(rows.length+5));
      sheet.getCell('A'.concat(rows.length+5)).value = this.rrdTotalString;
      sheet.getCell('D'.concat(rows.length+5)).value = this.rrdTotalCount;
      sheet.mergeCells('A1', 'W1');
      sheet.getCell('A1').value = 'Highly Confidential-Internal Use';

      sheet.getCell('A2').font = {
        name: 'Calibri',
        family: 2,
        size: 12,
        bold: true
      };

    }else if(report == "Partner by Name"){
      sheet.getCell('A'.concat(rows.length+4)).value = this.totalPartnersString;
      sheet.getCell('B'.concat(rows.length+4)).value = this.totalPartnersCount;
      sheet.mergeCells('A1', 'E1');
      sheet.getCell('A1').value = 'Highly Confidential-Internal Use';
    } else if(report == "RI"){
      sheet.getCell('A'.concat(rows.length + 3)).value = 'Total Active';
      sheet.getCell('A'.concat(rows.length + 4)).value = 'Total Leaving/Left';
      sheet.getCell('A'.concat(rows.length + 5)).value = 'Total Improved';
      sheet.getCell('A'.concat(rows.length + 6)).value = 'Total Completed';
      sheet.getCell('A'.concat(rows.length + 7)).value = 'Total Removed from RI';

      sheet.getCell('B'.concat(rows.length + 3)).value = this.totalActive;
      sheet.getCell('B'.concat(rows.length + 4)).value = this.totalLeaving;
      sheet.getCell('B'.concat(rows.length + 5)).value = this.totalImproved;
      sheet.getCell('B'.concat(rows.length + 6)).value = this.totalCompleted;
      sheet.getCell('B'.concat(rows.length + 7)).value = this.totalRemoved;

      sheet.mergeCells('A1', 'Y1');
      sheet.getCell('A1').value = 'Highly Confidential-Internal Use';
    }else if(report == "Monthly Change List"){
      sheet.mergeCells('A1', 'P1');
      sheet.getCell('A1').value = 'Highly Confidential-Internal Use';

      //style for the text header 
      sheet.getCell('A2').font = {
        name: 'Calibri',
        family: 2,
        size: 12,
        bold: true
      };
    }else if(report == "Partner by Location"){
      sheet.mergeCells('A'.concat(this.partnerByLocationNotice .toString()), 'B'.concat(this.partnerByLocationNotice.toString()));
      sheet.getCell('A'.concat(this.partnerByLocationNotice.toString())).value = 'Highly Confidential-Internal Use';
    }else if(report == "Projected Change Report"){
      sheet.mergeCells('A1', 'O1');
      sheet.getCell('A1').value = 'Highly Confidential-Internal Use';

      //style for the text header 
      sheet.getCell('A2').font = {
        name: 'Calibri',
        family: 2,
        size: 12,
        bold: true
      };
    }else if(report == "LOA"){
      sheet.mergeCells('A1', 'N1');
      sheet.getCell('A1').value = 'Highly Confidential-Internal Use';
    }else if(report == "Contractor Report"){
      sheet.mergeCells('A1', 'R1');
      sheet.getCell('A1').value = 'Highly Confidential-Internal Use';
    }else if(report == "Part Time"){
      sheet.mergeCells('A1', 'O1');
      sheet.getCell('A1').value = 'Highly Confidential-Internal Use';

      sheet.getCell('A2').font = {
        name: 'Calibri',
        family: 2,
        size: 12,
        bold: true
      };
    }
  
      sheet = this.applyRowStyle(sheet);

        if(report == "Partner by Name"){
          let array = ['A2', 'B2', 'D2', 'E2'];
          array.map(key => {
            sheet.getCell(key).fill = {
                type: 'gradient',
                gradient: 'angle',
                degree: 0,
                stops: [
                    {position: 0, color: {argb: 'faf9fa'} },
                    {position: 0.5, color: {argb: 'faf9fa'} },
                    {position: 1, color: {argb: 'faf9fa'} },
                ],
                
            };
            sheet.getCell(key).alignment = {wrapText: true, vertical: 'middle', horizontal: 'center', width: 1000000000};
            sheet.getCell(key).border = {right: {style: 'thin'}, top: {style: 'thin'}, bottom: {style: 'thin'} };
            sheet.getCell(key).font = {
                name: 'Tahoma',
                family: 2,
                size: 12,
                bold: true
            };
          
            sheet.getCell(key).fill = {
              type: 'pattern',
              pattern:'solid',
              fgColor:{ argb:'cccccc' }
            }
        });
        }else if(report == "Partner by Location"){
          sheet = this.applyRowStyle(sheet);
  
          this.colArray.map(key => {
              sheet.getCell(key).fill = {
                  type: 'gradient',
                  gradient: 'angle',
                  degree: 0,
                  stops: [
                      {position: 0, color: {argb: 'faf9fa'} },
                      {position: 0.5, color: {argb: 'faf9fa'} },
                      {position: 1, color: {argb: 'faf9fa'} },
                  ],
                  
              };
              sheet.getCell(key).alignment = {wrapText: true, vertical: 'middle', horizontal: 'center', width: 1000000000};
              sheet.getCell(key).border = {right: {style: 'thin'}, top: {style: 'thin'}, bottom: {style: 'thin'} };
              sheet.getCell(key).font = {
                name: 'Tahoma',
                family: 2,
                size: 8
              };
  
          });
          this.partnerByLocationOfficeFormat.map(key => {
            
            sheet.getCell(key).font = {
              name: 'Tahoma',
              family: 2,
              size: 8,
              bold: true
            };
        });
        this.partnerByLocationTotalsFormat.map(key => {
          sheet.getCell(key).border = {
            top: {style:'thin', color: {argb:'000000'}},
            bottom : {style:'double', color: {argb:'000000'}},
            right: {style:'thin', color: {argb:'000000'}}
          };
        });
        } else{
    
        
          sheet = this.applyRowStyle(sheet);
          
          if (report == "RI") {
            //to set the bg-color of columns H and I to yellow
            let tempRow = [];
            for (let i = 0; i < rows.length + 2; i++) {
              tempRow.push('H'.concat((i + 1).toString()), 'I'.concat((i + 1).toString()));
            }
            tempRow.map(key => {
              sheet.getCell(key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ffff00' }
              }
            });
            sheet.getCell('A1').fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'ffffff' }
            }
            //style for the RI Totals
            for (let i = 3; i <= 7; i++) {
              sheet.getCell('A'.concat(rows.length + i)).font = {
                name: 'Tahoma',
                family: 2,
                size: 8,
                bold: true
              };
              sheet.getCell('B'.concat(rows.length + i)).font = {
                name: 'Tahoma',
                family: 2,
                size: 8,
                bold: true
              };
            }

            //to set the alignment of columns: A,B,C,D,K,V to the left justified
            let tempRow2 = [];
            for (let i = 0; i < rows.length; i++) {
              tempRow2.push('A'.concat((i + 3).toString()), 'B'.concat((i + 3).toString()), 'C'.concat((i + 3).toString()), 'D'.concat((i + 3).toString()), 'K'.concat((i + 3).toString()), 'V'.concat((i + 3).toString()));
            }
            tempRow2.map(key => {
              sheet.getCell(key).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left', width: 1000000000 };
            });

          } else if (report == "LOA") {
            //style for the text header 
            sheet.getCell('A2').font = {
              name: 'Calibri',
              family: 2,
              size: 12,
              bold: true
            };

            //to set the alignment of columns: D,E,F,J,K,N to the left
            let tempRow = [];
            for (let i = 0; i < rows.length + 1; i++) {
              tempRow.push('D'.concat((i + 4).toString()), 'E'.concat((i + 4).toString()), 'F'.concat((i + 4).toString()), 'J'.concat((i + 4).toString()), 'K'.concat((i + 4).toString()), 'N'.concat((i + 4).toString()));
            }
            tempRow.map(key => {
              sheet.getCell(key).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left', width: 1000000000 };
            });


          }else if(report == "RR&D"){
            for(let i = 5; i <= 5; i++){
              sheet.getCell('A'.concat(rows.length + i)).font = {
                name: 'Tahoma',
                family: 2,
                size: 8,
                bold: true
              };

              sheet.getCell('D'.concat(rows.length + i)).font = {
                name: 'Tahoma',
                family: 2,
                size: 8,
                bold: true
              };
              if(this.selectedYearRRD == "2002"){
                let tempRow = [];
                  for (let i = 0; i < rows.length + 1; i++) {
                    tempRow.push('C'.concat((i + 4).toString()), 'F'.concat((i + 4).toString()), 'R'.concat((i + 4).toString()));
                  }
                    tempRow.map(key => {
                      sheet.getCell(key).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left', width: 1000000000 };
                    });
              }else{
                let tempRow = [];
                for (let i = 0; i < rows.length + 1; i++) {
                  tempRow.push('C'.concat((i + 4).toString()), 'G'.concat((i + 4).toString()), 'S'.concat((i + 4).toString()));
                }
                  tempRow.map(key => {
                    sheet.getCell(key).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left', width: 1000000000 };
                });
              }

            }
          }else if(report == "Part Time"){
            let tempRow = [];
            for (let i = 0; i < rows.length + 1; i++) {
              tempRow.push('D'.concat((i + 4).toString()), 'E'.concat((i + 4).toString()), 'F'.concat((i + 4).toString()), 'J'.concat((i + 4).toString()), 'K'.concat((i + 4).toString()), 'O'.concat((i + 4).toString()));
            }
            tempRow.map(key => {
              sheet.getCell(key).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left', width: 1000000000 };
            });
          }

  
          this.colArray.map(key => {
              sheet.getCell(key).fill = {
                  type: 'gradient',
                  gradient: 'angle',
                  degree: 0,
                  stops: [
                      {position: 0, color: {argb: 'faf9fa'} },
                      {position: 0.5, color: {argb: 'faf9fa'} },
                      {position: 1, color: {argb: 'faf9fa'} },
                  ],
                  
              };
              sheet.getCell(key).alignment = {wrapText: true, vertical: 'middle', horizontal: 'center', width: 1000000000};
              sheet.getCell(key).border = {right: {style: 'thin'}, top: {style: 'thin'}, bottom: {style: 'thin'} };
              sheet.getCell(key).font = {
                  name: 'Tahoma',
                  family: 2,
                  size: 8,
                  bold: true
              };
              
              sheet.getCell(key).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{ argb:'cccccc' }
              }
          });
        }
        
        workbook.xlsx.writeBuffer().then(data => {
            var blob = new Blob([data], {type: this.blobType});
            FileSaver.saveAs(blob, this.excelFileName, true);
        }); 
        this.loading = false;
      } catch(error){
        error.message += this.apiService.setErrorContents('exportToExcel', 'reports');
        throw error;
      }
  }

  applyRowStyle(sheet) {

    if(this.reportSelected == "Partner by Location"){
      sheet.eachRow(function (row, rowNumber) {
        if (rowNumber > 1) {
          row.eachCell({ includeEmpty: true }, function (cell) {
            sheet.getCell(cell.address.toString()).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center', width: 1000000000 };
            sheet.getCell(cell.address.toString()).border = {
              bottom: { style: 'thin' },
              top: { style: 'thin' },
              left: { stlyle: 'thin' },
              right: { style: 'thin' },
            };
            
            sheet.getCell(cell.address.toString()).font = {
              name: 'Tahoma',
              family: 2,
              size: 8
            };
          });
        }
      });
    }else{
      sheet.eachRow(function (row, rowNumber) {
        if (rowNumber > 2) {
          row.eachCell({ includeEmpty: true }, function (cell) {
            sheet.getCell(cell.address.toString()).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center', width: 1000000000 };
            sheet.getCell(cell.address.toString()).border = {
              bottom: { style: 'thin' },
              top: { style: 'thin' },
              left: { stlyle: 'thin' },
              right: { style: 'thin' },
            };
            
            //style for the 'Highly Confidential-Internal Use' text in the first row
            sheet.getCell('A1').alignment = { wrapText: true, vertical: 'middle', horizontal: 'center', width: 1000000000 };
            sheet.getCell('A1').border = { right: { style: 'thin' }, top: { style: 'thin' }, bottom: { style: 'thin' } };
            sheet.getCell('A1').font = {
              name: 'Tahoma',
              family: 2,
              size: 8,
              bold: true
            };
              
  
            sheet.getCell(cell.address.toString()).font = {
              name: 'Tahoma',
              family: 2,
              size: 8
            };
          });
        }
      });
    }
    return sheet;
  }

  totalUnitsFormat(unit){
    if(unit % 1 == 0 && unit != ""){
      return Math.ceil(unit);
    }else if(unit == "" || unit == null) {
      return unit = "";
    } else {
      return unit;
    }
  }

  partnerByLocationFormat(partner){
    if(partner == null || partner == undefined){
      return "";
    }else{
      return partner;
    }
  }

}
