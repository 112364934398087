import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/ea/api/ApiService';
import { Globals } from '../../../../globals';
import { Store } from '@ngxs/store';
import { StaticService } from '../../../../services/ea/api/StaticService';
import { BdraccessService } from '../../../../services/ea/api/BdrAccessService';
import { BdrLoadingService } from '../../../../services/ea/api/BdrLoadingService';
import { InvoiceDescription } from '../../../../services/ea/actions/invoicedescription.actions';

@Component({
  selector: 'app-invoicedescription',
  templateUrl: './invoicedescription.component.html',
  styleUrls: ['./invoicedescription.component.css']
})
export class InvoicedescriptionComponent implements OnInit {

  equityTypeList: any[] = [];
  invoiceStaticText: any[] = [];
  invoiceHeader: string;
  equityTypeId: number;
  tableLoading: boolean;
  displayTable: boolean;
  tableHasData: boolean;
  loadingService: any;
  loading: boolean = true;
  isError: boolean = false;
  bdrService: any;

  constructor(
    private apiService: ApiService,
    private globals: Globals,
    private store: Store,
    private invoiceDescLoading: BdrLoadingService,
    private bdrAccessService: BdraccessService,
    private staticContentService: StaticService
  ) {
    this.bdrService = this.bdrAccessService.invoiceDescError$.subscribe(state => {
      if(state) {
        this.isError = state;
        this.loading = false;
      }
    })
   }

  ngOnInit() {
    this.getStaticContents();
    this.loadingService = this.invoiceDescLoading.BdrLoading$.subscribe(state => {
      this.tableLoading = state;
    });
  }

  ngOnDestroy(){
    this.bdrService.unsubscribe();
    this.loadingService.unsubscribe();
  }

  /**********************************************************************************
 * Method Name: getStaticContents                                                                 
 * Description: Calls an api to retrieve values for page contents.                   
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/12/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/ 
  getStaticContents(){
    let messageName: string = "%ea.bdr.invoicedesc%";
    this.staticContentService.getStaticContent(messageName).then( data => {
      if (data) {
        this.invoiceStaticText = data; 
        this.invoiceHeader = this.invoiceStaticText[this.getDataIndex("ea.bdr.invoicedesc.header")].MESSAGE_VALUE;
      }
      this.getEquityTypes();
    }).catch((error) => {
      if(error) {
        this.loading = false;
        this.isError = true;
      }
    });
  }

 /**********************************************************************************
 * Method Name: getDataIndex                                                                 
 * Description: Returns the index of the given key.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         02/19/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
 getDataIndex(key){
   try {
    let index;
     for (let i = 0; i < this.invoiceStaticText.length; i++) {
       if (this.invoiceStaticText[i].MESSAGE_NAME == key) {
         index = i
       }
     }
     return index;
   } catch (error) {
    this.loading = false;
    this.isError = true;
     error.message += this.apiService.setErrorContents('getDataIndex', 'Invoicedescription');
     throw error;
   }
 }

  /**********************************************************************************
  * Method Name: getValue                                                                 
  * Description: Gets the value.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/05/20             Dalemark P. Suquib             Initial draft  
  * 2         05/13/20             John Paul B Fernando           Added logic to set the selected equity type id.
  ***********************************************************************************/
  getValue(value: number): void {
      this.equityTypeId = value;  
  }

  /**********************************************************************************
  * Method Name: getEquityTypes                                                                 
  * Description: Gets the list of equity types.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getEquityTypes(){
      let body = {
        functionName: "getEquityTypeList"
      }
      this.apiService.postData(this.globals.manageImportInvoiceApi, body).subscribe( data => {
        try {
          let body = data.body
          if (body) {
            let bodyData = body;
            if (bodyData) {
              if(bodyData != this.globals.notAuthorized) {
                this.equityTypeList = bodyData;
                this.getValue(this.equityTypeList[0].Equity_Type_ID);
              } else {
                this.bdrAccessService.setBdrAccess(false);
              }
            }
          }
          this.loading = false;
        } catch(error) {
          this.loading = false;
          this.isError = true;
           error.message += this.apiService.setErrorContents('getEquityTypes', 'Invoicedescription');
           throw error;
        }
      }, error => {
        this.loading = false;
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getEquityTypes', 'Invoicedescription');
        throw error;
      })
  }

  /**********************************************************************************
  * Method Name: loadInvocieDescTable                                                                 
  * Description: Calls several methods to get data and loads the idfieldinfo component.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  loadInvocieDescTable(){
    if (!this.tableLoading) {
      this.getInvoiceDescData();
      this.getInvoiceDescList();
      this.getProfitCenterList();
    }      
  }

  /**********************************************************************************
  * Method Name: getInvoiceDescData                                                                 
  * Description: Gets the data of invoice description.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getInvoiceDescData(){
      let body = {
        functionName: "getBusDataInvDesc",
        equityTypeId: this.equityTypeId
      }
      this.invoiceDescLoading.setLoadingState(true);
      this.apiService.postData(this.globals.manageInvoiceDescApi, body).subscribe( data => {
        try {
          let body = data.body
          if (body && body != "") {
            let bodyData = body;
            if(bodyData.length > 0 && bodyData != "Invalid Input"){
              this.store.dispatch( new InvoiceDescription.Clear);
              for (let i = 0; i < bodyData.length; i++) {
                this.store.dispatch( new InvoiceDescription.Add(bodyData[i]))
              }
              this.displayTable = true;
              this.tableHasData = true;
            }else{
              this.displayTable = true;
              this.tableHasData = false;
            }
          }
          this.invoiceDescLoading.setLoadingState(false);
        } catch(error) {
          this.invoiceDescLoading.setLoadingState(false);
          this.isError = true;
          error.message += this.apiService.setErrorContents('getInvoiceDescData', 'Invoicedescription');
          throw error;
        }
      }, error => {
        this.invoiceDescLoading.setLoadingState(false);
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getInvoiceDescData', 'Invoicedescription');
        throw error;
      });
  }

  /**********************************************************************************
  * Method Name: getInvoiceDescList                                                                 
  * Description: Gets the data of invoice description list.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getInvoiceDescList(){
      let body = {
        functionName: "getInvDescDescriptionList",
        equityTypeId: this.equityTypeId
      }
      this.apiService.postData(this.globals.manageInvoiceDescApi, body).subscribe( data => {
        try {
          let body = data.body
          if (body) {
            let bodyData = body;
            if(bodyData.length > 0){
              this.store.dispatch( new InvoiceDescription.Clear_Invoice_Desc_List);
              for (let i = 0; i < bodyData.length; i++) {
                this.store.dispatch( new InvoiceDescription.Add_Invoice_Desc_List(bodyData[i]))
              }
            }
          }
        } catch(error) {
          this.isError = true;
          error.message += this.apiService.setErrorContents('getInvoiceDescList', 'Invoicedescription');
          throw error;
        }
      }, error => {
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getInvoiceDescList', 'Invoicedescription');
        throw error;
      });
  }

  /**********************************************************************************
  * Method Name: getProfitCenterList                                                                 
  * Description: Gets the data of profit center list.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getProfitCenterList(){
      let body = {
        functionName: "getInvDescProfitCenterList"
      }
      this.apiService.postData(this.globals.manageInvoiceDescApi, body).subscribe( data => {
        try {
          let body = data.body
          if (body) {
            let bodyData = body;
            if(bodyData.length > 0){
              this.store.dispatch( new InvoiceDescription.Clear_Profit_Center);
              for (let i = 0; i < bodyData.length; i++) {
                this.store.dispatch( new InvoiceDescription.Add_Profit_Center(bodyData[i]))
              }
            }
          }
        } catch(error) {
          this.isError = true;
          error.message += this.apiService.setErrorContents('getProfitCenterList', 'Invoicedescription');
          throw error;
        }
      }, error => {
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getProfitCenterList', 'Invoicedescription');
        throw error;
      });
  }
  
}
