import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
// import { MatInput } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import * as moment from 'moment'
import { BrokerType, PageSection, FileType, MyHoldingsPagesResources, tScript} from '../../services/enumerations/enumerations';
import { HoldingProgramDetailsVEIPService } from '.././HoldingProgramDetailsVEIPService';
import {TooltipPosition} from '@angular/material/tooltip';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-veip-details-card',
  templateUrl: './veip-details-card.component.html',
  styleUrls: ['./veip-details-card.component.css']
})
export class VeipDetailsCardComponent implements OnInit {
  programyear: string;
  contribution: number;
  localcurrencycd: string;
  localcurrencycdOther: string;
  totalcontributionloc: number;
  totalcontributionlocOther: number;
  totalpurchasevalueloc: number;
  totalpurchasevaluelocOther: number;
  totalsharespurchased: number;
  hasOtherCurrency: boolean = false;
  soldtransferred: number;
  netremainingatbroker: number;
  loading: boolean = true;
  errorDetailsTab: boolean = false;
  errorLocation: string = " + [Component: Veipdetails, Method: methodName]";
  ContributionPercentage_mtt: string = tScript.veipdetailspage_c;
  TotalContribution_mtt: string = tScript.veipdetailspage_tc;
  positionOption: TooltipPosition[]=['after', 'before', 'above', 'below', 'left', 'right'];
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  OptionsLoading: boolean = true;


  
  constructor(private veipProgramDetails: HoldingProgramDetailsVEIPService, private globals: Globals) { 
    // this.veipProgramDetails.veipState$.subscribe( (state)=> {
    //   if(state == true){ 
    //     this.getVEIPAwardDetails(this.user.OptNum, this.programDetails.OfferNum);
    //   }
    // });

  }

  ngOnInit() {
    // BACK BUTTON BUG - START
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } 
    // BACK BUTTON BUG - END

    this.getVEIPAwardDetails(this.user.OptNum, this.programDetails.OfferNum);
  }
  getVEIPAwardDetails(optNum, offerNum) {  
    try {
      if(sessionStorage.getItem("veipAwardDetailsStoredData") != null){
        try {
          let data = JSON.parse(sessionStorage.getItem("veipAwardDetailsStoredData"));
          if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0)) {
            let veipsummarydetails = data[0][0];  
            let veipsummarydetailsOther = data[0][1];  
            
            this.programyear = veipsummarydetails.ProgramYear;
            this.contribution = veipsummarydetails.Contribution;
            this.netremainingatbroker = JSON.parse(sessionStorage.getItem('Total Net Remaining At Broker'));
            this.totalcontributionloc = veipsummarydetails.TotalContributionLoc;
            this.totalpurchasevalueloc = veipsummarydetails.TotalPurchaseValueLoc;
            this.totalsharespurchased = JSON.parse(sessionStorage.getItem('Total Shares Purchased'));
            this.soldtransferred = veipsummarydetails.SoldTransferred;
            this.localcurrencycd = veipsummarydetails.LocalCurrencyCd;
            if(veipsummarydetailsOther != null && veipsummarydetailsOther != ""){
              this.hasOtherCurrency = true;
              this.totalcontributionlocOther = veipsummarydetailsOther.TotalContributionLoc;
              this.localcurrencycdOther = veipsummarydetailsOther.LocalCurrencyCd;
              this.totalpurchasevaluelocOther = veipsummarydetailsOther.TotalPurchaseValueLoc;
            }
            // this.localcurrencycd = 'EUR';
            this.loading = false;
          }
        } catch(error) {
          this.errorDetailsTab = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardDetails");
          error.message += errorLocation;
          throw error;
        }
      }
      else{
        this.veipProgramDetails.getVEIPdetails(optNum, offerNum).pipe(map(res => res)).subscribe(data => {
          try {
            if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0)) {
              let veipsummarydetails = data[0][0];
              let veipsummarydetailsOther = data[0][1];    
              let totalSharesPurchased: number = 0;
              let totalNetRemainingAtBroker: number = 0;
              for(let i = 0; i < data[0].length; i++) {
                totalSharesPurchased += data[0][i].TotalSharesPurchased;
                totalNetRemainingAtBroker += data[0][i].NetRemainingAtBroker;
              }
              this.programyear = veipsummarydetails.ProgramYear;
              this.contribution = veipsummarydetails.Contribution;
              this.netremainingatbroker = totalNetRemainingAtBroker;
              this.totalcontributionloc = veipsummarydetails.TotalContributionLoc;
              this.totalpurchasevalueloc = veipsummarydetails.TotalPurchaseValueLoc;
              this.totalsharespurchased = totalSharesPurchased;
              this.soldtransferred = veipsummarydetails.SoldTransferred;
              this.localcurrencycd = veipsummarydetails.LocalCurrencyCd;
              if(veipsummarydetailsOther != null && veipsummarydetailsOther != ""){
                this.hasOtherCurrency = true;
                this.totalcontributionlocOther = veipsummarydetailsOther.TotalContributionLoc;
                this.localcurrencycdOther = veipsummarydetailsOther.LocalCurrencyCd;
                this.totalpurchasevaluelocOther = veipsummarydetailsOther.TotalPurchaseValueLoc;
              }
              // this.localcurrencycd = 'EUR';
              sessionStorage.setItem('Total Shares Purchased', JSON.stringify(totalSharesPurchased));
              sessionStorage.setItem('Total Net Remaining At Broker', JSON.stringify(totalNetRemainingAtBroker));
              sessionStorage.setItem("veipAwardDetailsStoredData", JSON.stringify(data));
              this.loading = false;
            }
          } catch(error) {
            this.errorDetailsTab = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardDetails");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          this.errorDetailsTab = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardDetails");
          error.error.message += errorLocation;
          throw error.error;
        });
      }
    this.OptionsLoading = false;
    } catch(error) {
      this.errorDetailsTab = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }


}
