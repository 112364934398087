import { Injectable } from '@angular/core';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "ExcelJS";
import * as FileSaver from "file-saver";



@Injectable()

export class ExportExcelService
{

	name: string;
	sName: string;
	fileName: string;
    excelFileName: string;
	blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
	// cols = ["Column1","Column2","Column3","Column4","Column5"]; // headers
	// data = [{col1:"a1", col2:"b1", col3:"c1", col4:"d1", col5:"e1"}, // rows
	//         {col1:"a2", col2:"b2", col3:"c2", col4:"d2", col5:"e2"},
	//         {col1:"a3", col2:"b3", col3:"c3", col4:"d3", col5:"e3"},
	//         {col1:"a4", col2:"b4", col3:"c4", col4:"d4", col5:"e4"},
    //         {col1:"a5", col2:"b5", col3:"c5", col4:"d5", col5:"e5"}]
    colArray = [];
    myArray: any[][];
    data: any;
    
    displayIndividualTransferLimitColumn: string[] = ['Program', 'Share Type', 'Trading Window Period', 'Total Unrestricted Shares at Period Start', 'Individual Transfer Limit Status', 'Estimated Sales Price in Effect', 'Redemption Method', 'Individual Transfer Limit Shares', 'Additional Shares Available Due to Transfer Limit Waiver', 'Total Shares Sold/Transferred', 'Net Shares Eligible for Sale/Transfer in Period'];
    displayedSharePriceHistColumns: string[] = ['Date', 'High (USD)', 'Low (USD)', 'Close (USD)', 'Fair Market Value (USD)', 'Trading Volume'];
    displayedColumnsAdmin: string[] = ['Date','Personnel Number','Last Name','First Name','Employment Status','Purchase Program','Purchase Period','Status','Contribution Percent','Matching Grant'];

    displayPurchaseProgramColumnVEIP: string[] = ["Program", "Purchase Date", "Grant ID", "Contribution %", "Monthly Contribution Plus Prior Month's Carryover (Local)", "Exchange Rate", "Monthly Contribution Plus Prior Month's Carryover (USD)", "Purchase Price FMV (USD)", "Total Shares Purchased", "Total Value of Shares Purchased (USD)", "Total Value of Shares Purchased (Local)", "Contribution to be Carried Over/Refunded (USD)", "Contribution to be Carried Over/Refunded (Local)", "Shares Disposed", "Shares Available", "Broker for More Information"];
    displayPurchaseProgramColumnESPP: string[] = ["Program", "Offering Period", "Contribution %", "Total Contributions (Loc)", "Exchange Rate", "Total Contributions (USD)", "Discounted Purchase Price (USD)", "Total Shares Purchased", "FMV at Purchase (USD)", "FMV Value of Shares Purchased (Cost Basis) (Loc)", "Value of Discount Received (Loc)", "Shares Sold for Tax Withholding", "Value of Shares Sold for Tax Withholding (Loc)", "Fractional Shares Sold for Refund", "Value of Fractional Shares Sold for Refund (Loc)", "Shares Sold/Disposed", "Shares Available", "Account", "Broker for More Information", "Actual tax reported by Payroll (in Loc)"];

    displayRSUActivityColumn: string[]= ["Program", "RSU Award", "Grant ID", "Grant Date", "Transaction Date", "Transaction Type", "Fair Market Value per Share on Release Date (USD)", "Currency Code", "Exchange Rate in Event Date", "Shares Vested", "Shares Released", "Total Value on Transaction Date in Local Currency", "Shares Withheld For Taxes", "Total Value of Taxes Withheld in Local Currency", "Sale-Fractional Share", "Value of Fractional Share Returned in Local Currency","Total Shares W/H","Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)","Shares Released to Broker"];
    displayedSalesHistoryColumns: string[] = ['Program', 'Award Details', 'Transaction Date', 'Transaction Type', 'Number of Shares in Transaction', 'Sale Price (USD)', 'Gross Proceeds (USD)', 'Original Purchase Date', 'Purchase Date FMV per Share(USD)', 'Purchase Date Value (USD)', 'Gain/(Loss) - USD', 'Gain/(Loss) - Local Currency', 'Value Per Share at Exercise (USD)', 'Total Value on Exercise Date (USD)', 'Total Cost on Exercise Date (USD)', 'Taxable Income(USD)', 'Estimated Taxes Withheld at Exercise (USD)', 'Estimated Proceeds (USD)', 'Broker for More Information'];
    displayExchangeHistoryColumn: string[] = ['Date','Currency Exchange Rate'];
    displayExchangeHistoryGenerateColumn = {'Date': 'Date', 'Currency Exchange Rate': 'Currency Exchange Rate'};

    dataIndividualTransferLimit = [];
    dataSharePriceHistory = [];
    dataEnrollmentHistory = [];
    dataPurchaseProgram = [];
    dataRSUActivity = [];
    dataSalesHistory = [];
    dataExchangeRateHistory = [];
    

	
	constructor()
	{}
    
    getReportInfo(report, program, headers, rows)
    {
        if(report == "Individual Transfer Limit")
        {
            this.sName = "Individual Transfer Limit";
            this.excelFileName = "IndividualTransferLimitReport.xlsx";
            this.dataIndividualTransferLimit = [];

            for(let i=0; i<rows.length; i++)
            {
                this.dataIndividualTransferLimit.push({col1: rows[i]["Program"], col2: rows[i]["Share Type"], col3: rows[i]["Trading Window Period"], col4: rows[i]["Total Unrestricted Shares at Period Start"], 
                                                       col5: rows[i]["Individual Transfer Limit Status"], col6: rows[i]["Estimated Sales Price in Effect"], col7: rows[i]["Redemption Method"],
                                                       col8: rows[i]["Individual Transfer Limit Shares"], col9: rows[i]["Additional Shares Available Due to Transfer Limit Waiver"], col10:rows[i]["Total Shares Sold/Transferred"], col11: rows[i]["Net Shares Eligible for Sale/Transfer in Period"],
                                                      });
            }

                // this.dataIndividualTransferLimit.push({col1: totalsrow[0]["Program"], col2: " ", col3: " ", col4: totalsrow[0]["Total Unrestricted Shares at Period Start"], 
                //                                        col5: " ", col6: " ", col7: " ",
                //                                        col8: totalsrow[0]["Total Individual Transfer Limit Shares"], col9: totalsrow[0]["Total Additional Shares Available Due to Transfer Limit Waiver"], col10: totalsrow[0]["Total Shares Sold/Transferred"], col11: totalsrow[0]["Net Shares Eligible for Sale/Transfer in Period"],
                //                                       });

            this.exportToExcel(report, program, headers, this.dataIndividualTransferLimit);
        }
        else if(report == "Share Price History")
        {
            this.sName = "Share Price History";
            this.excelFileName = "SharePriceHistoryReport.xlsx";
            this.dataSharePriceHistory = [];

            for(let i=0; i<rows.length; i++)
            {
                this.dataSharePriceHistory.push({col1: rows[i]["Date"], col2: rows[i]["High (USD)"], col3: rows[i]["Low (USD)"], col4: rows[i]["Close (USD)"], 
                                                 col5: rows[i]["Fair Market Value (USD)"], col6: rows[i]["Trading Volume"],
                                                });
            }
            this.exportToExcel(report, program, headers, this.dataSharePriceHistory);
        }
        else if(report == "Enrollment History")
        {
            this.sName = "Enrollment History";
            this.excelFileName = "EnrollWithdrawReport.xlsx";
            this.dataEnrollmentHistory = [];

            for(let i=0; i<rows.length; i++)
            {
                this.dataEnrollmentHistory.push({col1: rows[i]["Date"], col2: rows[i]["Personnel Number"], col3: rows[i]["Last Name"], col4: rows[i]["First Name"], 
                                                 col5: rows[i]["Employment Status"], col6: rows[i]["Purchase Program"], col7: rows[i]["Purchase Period"],
                                                 col8: rows[i]["Status"], col9: rows[i]["Contribution Percent"], col10:rows[i]["Matching Grant"],
                                                });
            }
            this.exportToExcel(report, program, headers, this.dataEnrollmentHistory);
        }
        else if(report == "Purchase Program")
        {
            this.sName = "Purchase Program";
            this.dataPurchaseProgram = [];
            
            if(program == "VEIP")
            {
                for(let i=0; i<rows.length; i++)
                {
                this.dataPurchaseProgram.push({col1: rows[i]["PROGRAM"], col2: rows[i]["PURCHASE DATE"], col3: rows[i]["GRANT ID"], col4: rows[i]["CONTRIBUTION %"], 
                                               col5: rows[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"], col6: rows[i]["EXCHANGE RATE"], col7: rows[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (USD)"],
                                               col8: rows[i]["PURCHASE PRICE FMV (USD)"], col9: rows[i]["TOTAL SHARES PURCHASED"], col10:rows[i]["TOTAL VALUE OF SHARES PURCHASED (USD)"], col11: rows[i]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"],
                                               col12: rows[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (USD)"], col13: rows[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"],
                                               col14: rows[i]["SHARES DISPOSED"], col15: rows[i]["SHARES AVAILABLE"], col16: rows[i]["BROKER FOR MORE INFORMATION"],
                                              });
                }
                this.excelFileName = "VeipPurchaseReport.xlsx";
                this.exportToExcel(report, program, headers, this.dataPurchaseProgram);
                
            }
            else
            {
                for(let i=0; i<rows.length; i++)
                {
                    this.dataPurchaseProgram.push({col1: rows[i]["PROGRAM"], col2: rows[i]["OFFERING PERIOD"], col3: rows[i]["CONTRIBUTION %"], col4: rows[i]["TOTAL CONTRIBUTIONS (LOC)"], 
                                                   col5: rows[i]["EXCHANGE RATE"], col6: rows[i]["TOTAL CONTRIBUTIONS (USD)"], col7: rows[i]["DISCOUNTED PURCHASE PRICE (USD)"],
                                                   col8: rows[i]["TOTAL SHARES PURCHASED"], col9: rows[i]["FMV AT PURCHASE (USD)"], col10:rows[i]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"], col11: rows[i]["VALUE OF DISCOUNT RECEIVED (LOC)"],
                                                   col12: rows[i]["SHARES SOLD FOR TAX WITHHOLDING"], col13: rows[i]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"],
                                                   col14: rows[i]["FRACTIONAL SHARES SOLD FOR REFUND"], col15: rows[i]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"], col16: rows[i]["SHARES SOLD/DISPOSED"],
                                                   col17: rows[i]["SHARES AVAILABLE"], col18: rows[i]["ACCOUNT"], col19: rows[i]["BROKER FOR MORE INFORMATION"], col20: rows[i]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"]
                                                  });
                }
                this.excelFileName = "EsppPurchaseReport.xlsx";
                this.exportToExcel(report, program, headers, this.dataPurchaseProgram);
            }
        }
        else if(report == "RSU Activity")
        {
            this.sName = "RSU Activity";
            this.excelFileName = "RSUActivityReport.xlsx";
            this.dataRSUActivity = [];

            for(let i=0; i<rows.length; i++)
            {
                this.dataRSUActivity.push({col1: rows[i]["Program"], col2: rows[i]["RSU Award"], col3: rows[i]["Grant ID"], col4: rows[i]["Grant Date"], 
                                           col5: rows[i]["Transaction Date"], col6: rows[i]["Transaction Type"], col7: rows[i]["Fair Market Value per Share on Release Date (USD)"],
                                           col8: rows[i]["Currency Code"], col9: rows[i]["Exchange Rate in Event Date"], col10:rows[i]["Shares Vested"], col11: rows[i]["Shares Released"],
                                           col12: rows[i]["Total Value on Transaction Date in Local Currency"], col13: rows[i]["Shares Withheld For Taxes"],
                                           col14: rows[i]["Total Value of Taxes Withheld in Local Currency"], col15: rows[i]["Sale-Fractional Share"], col16: rows[i]["Value of Fractional Share Returned in Local Currency"],
                                           col17: rows[i]["Total Shares W/H"], col18: rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"], col19: rows[i]["Shares Released to Broker"]
                                          });
            }
            this.exportToExcel(report, program, headers, this.dataRSUActivity);
        }
        else if(report == "Sales History")
        {
            this.sName = "Sales History";
            this.excelFileName = "SalesHistoryReport.xlsx";
            this.dataSalesHistory = [];

            for(let i=0; i<rows.length; i++)
            {
                this.dataSalesHistory.push({col1: rows[i]["Program"], col2: rows[i]["Award Details"], col3: rows[i]["Transaction Date"], col4: rows[i]["Transaction Type"], 
                                            col5: rows[i]["Number of Shares in Transaction"], col6: rows[i]["Sale Price (USD)"], col7: rows[i]["Gross Proceeds (USD)"],
                                            col8: rows[i]["Original Purchase Date"], col9: rows[i]["Purchase Date FMV per Share(USD)"], col10:rows[i]["Purchase Date Value (USD)"], col11: rows[i]["Gain/(Loss) - USD"],
                                            col12: rows[i]["Gain/(Loss) - Local Currency"], col13: rows[i]["Value Per Share at Exercise (USD)"],
                                            col14: rows[i]["Total Value on Exercise Date (USD)"], col15: rows[i]["Total Cost on Exercise Date (USD)"], col16: rows[i]["Taxable Income(USD)"],
                                            col17: rows[i]["Estimated Taxes Withheld at Exercise (USD)"], col18: rows[i]["Estimated Proceeds (USD)"], col19: rows[i]["Broker for More Information"]
                                          });
            }
                // this.dataSalesHistory.push({col1: totalsrow[0]["Program"], col2: " ", col3: " ", col4: " ", 
                //                             col5: totalsrow[0]["Total Number of Shares in Transaction"], col6: " ", col7: totalsrow[0]["Total Gross Proceeds (USD)"],
                //                             col8: " ", col9: " ", col10: " ", col11: totalsrow[0]["Total Gain/(Loss) - USD"],
                //                             col12: totalsrow[0]["Total Gain/(Loss) - Local Currency"], col13: " ",
                //                             col14: " ", col15: " ", col16: totalsrow[0]["Total Taxable Income(USD)"],
                //                             col17: totalsrow[0]["Total Estimated Taxes Withheld at Exercise (USD)"], col18: totalsrow[0]["Total Estimated Proceeds (USD)"], col19: " "
                //                            });
            this.exportToExcel(report, program, headers, this.dataSalesHistory);
        }
        else if(report == "Exchange Rate History")
        {
            this.sName = "Exchange Rate History";
            this.excelFileName = "ExchangeRateHistoryReport.xlsx";
            this.dataExchangeRateHistory = [];

            for(let i=0; i<rows.length; i++)
            {
                this.dataExchangeRateHistory.push({col1: rows[i]["Date"], col2: rows[i]["Currency Exchange Rate"]});
            }
            
            this.exportToExcel(report, program, headers, this.dataExchangeRateHistory);
        }
        
    }

	applyRowStyle(sheet)
	{
        sheet.eachRow(function (row,rowNumber)
        {
            if(rowNumber > 1)
            {
                row.eachCell({includeEmpty: true}, function(cell,colNumber)
                {
                    sheet.getCell(cell.address.toString()).alignment = {wrapText: true, vertical: 'middle', horizontal: 'center', width: 1000000000};
                    sheet.getCell(cell.address.toString()).border = {
                        bottom: {style: 'thin'},
                        top: {style: 'thin'},
                        left: {stlyle: 'thin'},
                        right: {style: 'thin'},
                    };
                    
                    sheet.getCell(cell.address.toString()).font = {
                        name: 'Tahoma',
                        family: 2,
                        size: 8
                    };
                });
            }
        });
        return sheet; 
    }
    
    exportToExcel(report,program,headers, rows)
    {
        var workbook = new Excel.Workbook();
        workbook.creator = 'Web';
        workbook.lastModifiedBy = 'Web';
        workbook.created = new Date();
        workbook.modified = new Date();
        // workbook.addWorksheet(this.sName, {views: [{state: 'frozen', ySplit: 3, xSplit: 2, activeCell: 'A1', showGridLines: true }] })
        workbook.addWorksheet(this.sName, {views: [{width: 1000000000, height: 20000, state: 'frozen', ySplit: 1, activeCell: 'A1', showGridLines: true}] })
        var sheet = workbook.getWorksheet(1);
        
        sheet.getRow(1).values = headers;

        if(report == "Individual Transfer Limit")
        {
            sheet.columns = 
            [
                {key: 'col1'},
                {key: 'col2'},
                {key: 'col3'},
                {key: 'col4'},
                {key: 'col5'},
                {key: 'col6'},
                {key: 'col7'},
                {key: 'col8'},
                {key: 'col9'},
                {key: 'col10'},
                {key: 'col11'},
            ];
            this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1'];   
        }
        else if(report == "Share Price History")
        {
            sheet.columns = 
            [
                {key: 'col1'},
                {key: 'col2'},
                {key: 'col3'},
                {key: 'col4'},
                {key: 'col5'},
                {key: 'col6'},
            ];
            this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1']
        }
        else if(report == "Enrollment History")
        {
            sheet.columns = 
            [
                {key: 'col1'},
                {key: 'col2'},
                {key: 'col3'},
                {key: 'col4'},
                {key: 'col5'},
                {key: 'col6'},
                {key: 'col7'},
                {key: 'col8'},
                {key: 'col9'},
                {key: 'col10'},
            ];
            this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1'];
        }
        else if(report == "Purchase Program")
        {
            if(program == "VEIP")
            {
                sheet.columns = 
                [
                    {key: 'col1'},
                    {key: 'col2'},
                    {key: 'col3'},
                    {key: 'col4'},
                    {key: 'col5'},
                    {key: 'col6'},
                    {key: 'col7'},
                    {key: 'col8'},
                    {key: 'col9'},
                    {key: 'col10'},
                    {key: 'col11'},
                    {key: 'col12'},
                    {key: 'col13'},
                    {key: 'col14'},
                    {key: 'col15'},
                    {key: 'col16'},
                ];
                this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1'];
            }
            else
            {
                sheet.columns = 
                [
                    {key: 'col1'},
                    {key: 'col2'},
                    {key: 'col3'},
                    {key: 'col4'},
                    {key: 'col5'},
                    {key: 'col6'},
                    {key: 'col7'},
                    {key: 'col8'},
                    {key: 'col9'},
                    {key: 'col10'},
                    {key: 'col11'},
                    {key: 'col12'},
                    {key: 'col13'},
                    {key: 'col14'},
                    {key: 'col15'},
                    {key: 'col16'},
                    {key: 'col17'},
                    {key: 'col18'},
                    {key: 'col19'},
                    {key: 'col20'},
                ];
                this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1', 'T1'];
            }
            
        }
        else if(report == "RSU Activity")
        {
            sheet.columns = 
            [
                {key: 'col1'},
                {key: 'col2'},
                {key: 'col3'},
                {key: 'col4'},
                {key: 'col5'},
                {key: 'col6'},
                {key: 'col7'},
                {key: 'col8'},
                {key: 'col9'},
                {key: 'col10'},
                {key: 'col11'},
                {key: 'col12'},
                {key: 'col13'},
                {key: 'col14'},
                {key: 'col15'},
                {key: 'col16'},
                {key: 'col17'},
                {key: 'col18'},
                {key: 'col19'},
            ];
            this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1'];
        }
        else if(report == "Sales History")
        {
            sheet.columns = 
            [
                {key: 'col1'},
                {key: 'col2'},
                {key: 'col3'},
                {key: 'col4'},
                {key: 'col5'},
                {key: 'col6'},
                {key: 'col7'},
                {key: 'col8'},
                {key: 'col9'},
                {key: 'col10'},
                {key: 'col11'},
                {key: 'col12'},
                {key: 'col13'},
                {key: 'col14'},
                {key: 'col15'},
                {key: 'col16'},
                {key: 'col17'},
                {key: 'col18'},
                {key: 'col19'},
            ];
            this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1'];
        }

        else if(report == "Exchange Rate History")
        {
            sheet.columns = 
            [
                {key: 'col1'},
                {key: 'col2'},
            ];
            this.colArray = ['A1', 'B1'];
        }
        
        sheet.addRows(rows);
        
        sheet = this.applyRowStyle(sheet);
        
        this.colArray.map(key => {
            sheet.getCell(key).fill = {
                type: 'gradient',
                gradient: 'angle',
                degree: 0,
                stops: [
                    {position: 0, color: {argb: 'faf9fa'} },
                    {position: 0.5, color: {argb: 'faf9fa'} },
                    {position: 1, color: {argb: 'faf9fa'} },
                ]
            };
            sheet.getCell(key).alignment = {wrapText: true, vertical: 'middle', horizontal: 'center', width: 1000000000};
            sheet.getCell(key).border = {right: {style: 'thin'}, top: {style: 'thin'}, bottom: {style: 'thin'} };
            sheet.getCell(key).font = {
                name: 'Tahoma',
                family: 2,
                size: 8,
                bold: true
            };
        });
        
        workbook.xlsx.writeBuffer().then(data => {
            var blob = new Blob([data], {type: this.blobType});
            FileSaver.saveAs(blob, this.excelFileName, true);
        }); 
    }
}