
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EspptransactionService } from './EsppTransactionService';
import { Globals } from '../globals';
// import moment = require('moment');
import * as moment from 'moment-timezone';
import { EnrollStatus, AdminType, ActionTypes, PageSection, FileType, MyHoldingsPagesResources, tScript } from '../services/enumerations/enumerations';
import { StaticContentService } from '../services/StaticContentService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import {TooltipPosition} from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

// Notif framework toast widget //
declare function enrollEsppToastProd(): any; 
declare function esppToastWithdrawProd(): any;
declare function enrollEsppToastStage(): any; 
declare function esppToastWithdrawStage(): any;
// Notif framework toast widget // -- END

@Component({
  selector: 'app-enrollmentwithdraw-espp',
  templateUrl: './enrollmentwithdraw-espp.component.html',
  styleUrls: ['./enrollmentwithdraw-espp.component.css']
})
export class EnrollmentwithdrawEsppComponent implements OnInit {

  loading: boolean = true;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  admin = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));

  showEnrollPeriod: string = "collapse"; // enroll period accordion
  expandEnrollPeriod: boolean = false;
  showWithdraw: string = "collapse"; // withdraw accordion
  expandWithdraw: boolean = false;
  showTaxElect: string = "collapse"; // tax election accordion
  expandTaxElect: boolean = false;
  //displayEnrollNote: boolean = false;
  displayPrevContrib: boolean = false;
  currentPeriodChecker: boolean = false; 
  prevContribution: string;
  enrollStatement: string;
  withdrawStatement: string;
  withdrawnStatements: any[];
  withdrawPeriods: any[] = [];
  lastUpdateByUser: string;
  stepTwoText: string = "Confirm"

  enableControl: boolean;
  enrollStartDate: string;
  enrollEndDate: string;
  periodStartDate: string;
  periodEndDate: string;
  taxElectStartDate: string;
  taxElectEndDate: string;
  disableEnrollbtn: boolean = true;
  disableEsppInput: boolean = false;
  displayErrorZero: boolean;
  displayErrorTenUp: boolean;
  displayErrorPeriod: boolean = false;
  displayEnrollStatement: boolean = false;
  displayWithdrawStatement: boolean = false;
  disableWithdrawCheckbox: boolean = true;
  disableWithdrawBtn: boolean = true;
  displayCompleteFax: boolean = false;
  displayEnrollStepThree: boolean = false;
  //freezePeriod: any;
  contribVal: number = null;
  isOpenEnrollment: boolean = false;
  enrollmentDetails: any;
  offeringDetails: any;
  insertDetails: any[] = [];
  insertTaxElectDetails: any[] = [];
  sendEmailDetails: any[] = [];
  action: any;
  withdrawCheckbx: any;
  displayWithdrawNote: boolean = false;

  esppEnrollData: any = JSON.parse(sessionStorage.getItem("ESPP DETAILS KEY"));
  taxElectData: any = JSON.parse(sessionStorage.getItem("TAX ELECT DETAILS KEY"));
  esppActionItems: any = JSON.parse(sessionStorage.getItem("ESPP ACTION ITEMS"));
  esppTransacDetails: any;
  noInputError: boolean = true;
  checkCounter: number = 0;

  commonQuestionsContent: any;
  guideBooksContent: string;
  planDocumentContent: string;
  prospectusDocContent: string;
  countryAndAwardContent: string;
  toolsAndReportContent: any;
  allCountryFormsContent: string;
  stepTwoContent: any;

  disableShareWithholding: boolean;
  disablePayrollWithholding: boolean;
  checkShareWithholding: boolean;
  checkPayrollWithholding: boolean;
  disableElectButton: boolean;
  teWindowText: string;
  taxElection: string;
  taxElectionEndDate: string;
  selectedTaxElect: string;
  taxElectOfferNum: number;
  taxElectUpdatedBy: string;
  taxElectMsg: string;
  displayTaxElectMsg: boolean;
  globalOffernum: any;
  displayTaxElectAcc: boolean;
  errorLocation: string = " + [Component: Espptransact, Method: methodName]";
  errorEnrollmentAcc: boolean = false;
  errorWithdrawAcc: boolean = false;
  errorTaxElectAcc: boolean = false;
  errorCommonQuestions: boolean = false;
  errorGuideBooks: boolean = false;
  errorPlanDoc: boolean = false;
  errorProspectusDoc: boolean = false;
  errorCountryAward: boolean = false;
  errorToolsAndReports: boolean = false;

  commonQAccordionCount: number = 1; 
  isOpenTaxElect: any;
  UBSlink: any;
  MSlink: any;
  envSetting: any;

  esppEnrollNote: any;
  esppWithdrawNote: any;
  esppCompleteFaxNote: any;
  errorEsppEnrollNote: boolean = false;
  errorEsppWithdrawNote: boolean = false;
  errorEsppComFaxNote: boolean = false;

  //Tooltip
  PreviousContributionPercent_mtt: string = tScript.esppveipenrollmentwithdraw_pcp;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];

  constructor(private sanitizer:DomSanitizer, private router: Router, private globals: Globals, private esppTransactionService: EspptransactionService, private staticContentService: StaticContentService, private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() { 
    // if(sessionStorage.getItem("freezePeriod") == null){
    //   this.getFreezePeriod();
    // }
    this.checkErrorMessage();
    this.getCommonQuestions();
    this.getEsppEnrollDetails(0);
    this.getGuideBooks();
    this.getPlanDocument();
    this.getProspectusDocument();
    this.getCountryAndAward();
    this.getToolsAndReports();
    // this.getToolsAndReports();
    this.getAllCountryForms();
    this.brokersSSO();
    // setTimeout(() => { this.inContentIcon() }, 500);
    this.getEsppEnrollNote();
    this.getEsppCompleteFaxNote();
    this.getEsppWithdrawNote();
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
  if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
    let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

    if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
    }else{
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
    }
  }else{
    if(this.bodyData != null){
      if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }
  }
}

  /*****************************************************************************************
   * Method Name: navToPage                                                                 
   * Description: Redirect to a specified component.                            
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/07/19             Dalemark P. Suquib          Initial draft
   *****************************************************************************************/
  navToPage(strLoc) {
    try {
      sessionStorage.setItem("ESPP DETAILS KEY", JSON.stringify(null));
      sessionStorage.setItem("TAX ELECT DETAILS KEY", JSON.stringify(null)); 
  
      this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  /********************************************************************************************************************************
   * Method Name: getFreezePeriod                                                                 
   * Description: Get freeze period                           
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/27/19             Katrina Narag(KMN)          Initial draft
   ********************************************************************************************************************************/
  // getFreezePeriod(){
  //   try {
  //     this.esppTransactionService.getDataFromCodeDetail().map(res => res).subscribe(data => {
  //       try {
  //         let body = data;
  
  //         if(data != undefined){
  //           let period = body[0][0];
  //           this.freezePeriod = period.GroupingValue;
  //           sessionStorage.setItem("freezePeriod", this.freezePeriod);
  //         }
  //       } catch(error) {
  //         this.errorEnrollmentAcc = true;
  //         this.errorWithdrawAcc = true;
  //         let errorLocation = this.errorLocation.replace("methodName", "getFreezePeriod");
  //         error.message += errorLocation;
  //         throw error;
  //       }
  //     }, error => {
  //       this.errorEnrollmentAcc = true;
  //       this.errorWithdrawAcc = true;
  //       let errorLocation = this.errorLocation.replace("methodName", "getFreezePeriod");
  //       error.error.message += errorLocation;
  //       throw error.error;
  //     });
  //   } catch(error) {
  //     this.errorEnrollmentAcc = true;
  //     this.errorWithdrawAcc = true;
  //     let errorLocation = this.errorLocation.replace("methodName", "getFreezePeriod");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }

  checkRadio(){
    try {
      $(document).ready(function(){
        var radShare = $("#radio-share").hasClass("mat-radio-checked");
        var radPay = $("#radio-payroll").hasClass("mat-radio-checked");
  
        radShare ? $("#radio-share").removeClass("mat-radio-checked") : $("#radio-share").addClass("mat-radio-checked");
        radPay ? $("#radio-payroll").removeClass("mat-radio-checked") : $("#radio-payroll").addClass("mat-radio-checked");
      })
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkRadio");
      error.message += errorLocation;
      throw error;
    }
  }

  /********************************************************************************************************************************
   * Method Name: getEsppEnrollDetails                                                                 
   * Description: Gets the enroll details of Espp                           
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/18/19             Dalemark P. Suquib          Initial draft
   * 2    02/19/19             Dalemark P. Suquib          Added conditions to set a default accordion
   * 3    02/26/19             Dalemark P. Suquib          Added conditions for withdraw and display of enroll/withdraw statements
   * 4    02/27/19             Katrina Narag               Get freeze period from API
   * 5    05/01/19             KMN                         Modified param in getTaxElectionData()
   * 6    11/19/19             KMN                         Removed comparison of enrollment period to local date
   * 7    01/17/19             KMN                         Modified display flagging of withdrawn message
   * 8    04/22/2020           RJay B Adrao                Modified tagging of LastUpdateByUser for Withdrawals, Workitem 232985
   * 9    06/28/2021           Julienne Marey Celi         Modified based on User Story 332873 - CRIL00051021173444 - Missing Enrollment Email Notifications
   ********************************************************************************************************************************/
  async getEsppEnrollDetails(transactType) {
    try {
      let checker = 0;
      this.withdrawPeriods = [];
      this.displayTaxElectAcc = false;
  
      if(this.esppEnrollData !=  null) {
        this.esppTransacDetails = this.esppEnrollData;
      } else {
        this.esppTransacDetails = this.taxElectData;
        this.expandTaxElect = true;
      }
  
      for(let i = 0; i < this.esppActionItems.length; i++) {
        this.esppActionItems[i].OfferingFlag == 2 && this.esppActionItems[i].EnrollmentFlag != 0 ? this.displayTaxElectAcc = true : this.displayTaxElectAcc = false;
      }
      
      this.esppTransactionService.getESPPEnrollDetail(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.enrollmentDetails = data[0];
          
          if(Array.isArray(data) && data[0].length > 0) {
            let enrollDetails = data[0];
  
            if(this.user.AdditionalEsppPaperRequirementInd == "Y") { 
              this.displayCompleteFax = true;
              this.stepTwoText = "Complete & Fax";
            }
    
            for(let i: number = 0; i < enrollDetails.length; i++) {
              if(this.esppTransacDetails[0].OfferNum == enrollDetails[i].OfferNum) { // comparing the value of offernum from dashboard             
                // let date = new Date;
                // let currentDate = moment(date).format("YYYY-MM-DD");
                this.enrollStartDate = moment(enrollDetails[i].EnrollmentStartDt).format("YYYY-MM-DD");
                this.enrollEndDate = moment(enrollDetails[i].EnrollmentEndDt).format("YYYY-MM-DD");
                this.periodStartDate = enrollDetails[i].PeriodStartDt;
                this.periodEndDate = enrollDetails[i].PeriodEndDt;
                this.taxElectStartDate = enrollDetails[i].TaxElectionStartDt;
                this.taxElectEndDate = enrollDetails[i].TaxElectionEndDt;
                this.isOpenTaxElect = enrollDetails[i].isOpenTaxElection;
  
                this.globalOffernum = enrollDetails[i].OfferNum;
                this.getTaxElectionData(this.globalOffernum);
                
                if (enrollDetails[i].ContributionPercent != null) {
                  this.contribVal = enrollDetails[i].ContributionPercent;
                }
  
                // Withdraw Checkbox - START
                if(enrollDetails[i].OfferingFlag == 1) {
                  this.withdrawPeriods.push(enrollDetails[i]);
                  // this.withdrawPeriods[0].PeriodStartDt = moment(this.withdrawPeriods[0].PeriodStartDt).format("MMM YY");
                  // this.withdrawPeriods[0].PeriodEndDt = moment(this.withdrawPeriods[0].PeriodEndDt).format("MMM YY");
                } else if(enrollDetails[i].OfferingFlag == 0 || enrollDetails[i].OfferingFlag == 2) { 
                  this.withdrawPeriods = enrollDetails;
                }
                for(let j = 0; j < this.withdrawPeriods.length; j++) {
                  this.withdrawPeriods[j].Checked = false;
                  
                  if(this.withdrawPeriods[j].EnrollStatus == EnrollStatus.Withdraw) {
                    this.withdrawPeriods[j].Checked = true;
                    this.withdrawPeriods[j].disableWithdrawCheckbox = true;
                    this.disableWithdrawBtn = true;
                  } 
                  if(this.canTransact() == true && this.withdrawPeriods[j].EnrollStatus != EnrollStatus.Withdraw) {
                    this.withdrawPeriods[j].disableWithdrawCheckbox = false;
                    this.withdrawPeriods[j].disableWithdrawBtn = true;  
                  }
  
                  if(this.withdrawPeriods[j].EnrollStatus == EnrollStatus.EnrollESPP || this.withdrawPeriods[j].EnrollStatus == EnrollStatus.OnHold || this.withdrawPeriods[j].EnrollStatus == EnrollStatus.ChangeESPPContribution) {
                    this.withdrawPeriods[j].disableWithdrawCheckbox = false;
                    this.withdrawPeriods[j].disableWithdrawBtn = false;
                  }

                  // let date = new Date();
                  // let currentDate = moment(date).format("YYYY-MM-DD");
                  //let freezePeriod;
                  // if(sessionStorage.getItem("freezePeriod") == null){
                  // this.getFreezePeriod();
                  // }
                  //freezePeriod = sessionStorage.getItem("freezePeriod");
                  //let endOfferingPeriod = moment(enrollDetails[i].PeriodEndDt).format("YYYY-MM-DD");
                  //let freezePeriodDt = moment(freezePeriod).format("YYYY-MM-DD");
                  this.withdrawPeriods[j].PeriodStartDt = moment(this.withdrawPeriods[j].PeriodStartDt).format("MMM YY");
                  this.withdrawPeriods[j].PeriodEndDt = moment(this.withdrawPeriods[j].PeriodEndDt).format("MMM YY");

                  if(this.withdrawPeriods[j].isFreezePeriod == 1 && this.withdrawPeriods[j].OfferingFlag == 1) {
                    this.withdrawPeriods[j].disableWithdrawCheckbox = true;
                    this.withdrawPeriods[j]. disableWithdrawBtn = true;
                  }

                  if(this.withdrawPeriods[j].EnrollStatus == null || this.withdrawPeriods[j].EnrollStatus == "") {
                    this.withdrawPeriods[j].disableWithdrawCheckbox = true;
                  }

                  if(!this.canTransact()) {
                    if(this.admin.IsAdmin == "Y" && this.admin.IsAdminCanTransactInd == AdminType.ReadOnly) { // admin type = 1
                      this.withdrawPeriods[j].disableWithdrawCheckbox = true;
                      this.withdrawPeriods[j].disableWithdrawBtn = true;
                    }
                  }
                }
                // Withdraw Checkbox - END
  
                this.checkOpenEnrollment(enrollDetails[i]);
              
                // if(this.enrollStartDate < currentDate && currentDate < this.enrollEndDate) { // if open enrollment
                if(enrollDetails[i].isOpenEnrollment == 1) { // if open enrollment
                  this.showEnrollPeriod = "collapse show";
                  this.expandEnrollPeriod = true;
                  this.isOpenEnrollment = true;
                } else {
                  this.showWithdraw = "collapse show"; 
                  this.expandWithdraw = true;
                }
  
                if(enrollDetails[i].EnrollStatus != null && enrollDetails[i].PrevContrib != null) {
                  this.displayPrevContrib = true;
                  this.prevContribution = enrollDetails[i].PrevContrib; 
                }
  
                if(enrollDetails.EnrollStatus == EnrollStatus.Withdraw) { // if enrollment status = withdraw
                  this.showWithdraw = "collapse show"; 
                  this.expandWithdraw = true;
                }
  
                if(this.expandTaxElect) {
                  this.showWithdraw = "collapse";
                  this.expandWithdraw = false;
                  this.showTaxElect = "collapse show";
                }
  
                // Identifying of last update by user - START
                if(enrollDetails[i].Updated_by == null) {
                  this.lastUpdateByUser = "dbo";
                } else if(enrollDetails[i].Updated_by != this.user.OptNum) {
                  this.lastUpdateByUser = "admin";
                } else {
                  this.lastUpdateByUser = this.user.EnterpriseId; // get value from select user details by optnum
                }
                // Identifying of last update by user - END
  
                // Setting of Enroll / Withdraw display - START
                if(enrollDetails[i].EnrollStatus == EnrollStatus.EnrollESPP || enrollDetails[i].EnrollStatus == EnrollStatus.ChangeESPPContribution) {
                  this.displayEnrollStatement = true;
                  if(this.lastUpdateByUser == "dbo") { 
                    this.enrollStatement = "Enrolled on " + enrollDetails[i].TransactionDt.substring(0, enrollDetails[i].TransactionDt.lastIndexOf(" ")) + " by auto re-enroll"; //JMC, 9
                  } else if(this.lastUpdateByUser == "admin") {
                    this.enrollStatement = "Confirmed enrollment on " + enrollDetails[i].TransactionDt.substring(0, enrollDetails[i].TransactionDt.lastIndexOf(" ")); //JMC, 9
                  } else {
                    this.enrollStatement = "Confirmed enrollment on " + enrollDetails[i].TransactionDt.substring(0, enrollDetails[i].TransactionDt.lastIndexOf(" ")) + " by " + this.lastUpdateByUser; //JMC, 9
                  }
                  this.disableEnrollbtn = true;
                  //RBA, 8 Start
                  this.withdrawnStatements = [];
                  for(let x = 0; x < this.withdrawPeriods.length; x++) {
                    // RBA, 8
                    if(enrollDetails[x].Updated_by == null) {
                      this.lastUpdateByUser = "dbo";
                    } else if(enrollDetails[x].Updated_by != this.user.OptNum) {
                      this.lastUpdateByUser = "admin";
                    } else {
                      this.lastUpdateByUser = this.user.EnterpriseId; // get value from select user details by optnum
                    }
                    // RBA, 8
                    if(this.withdrawPeriods[x].Checked == true) {
                      this.displayWithdrawStatement = true;
                      if(this.lastUpdateByUser == "dbo") { 
                        this.withdrawStatement = "Auto-withdrawn on " + this.withdrawPeriods[x].TransactionDt.substring(0, this.withdrawPeriods[x].TransactionDt.lastIndexOf(" ")) + " for period " + this.withdrawPeriods[x].PeriodStartDt + " - " + this.withdrawPeriods[x].PeriodEndDt; //JMC, 9
                      } else if(this.lastUpdateByUser == "admin") {
                        this.withdrawStatement = "Withdrawn on " + this.withdrawPeriods[x].TransactionDt.substring(0, this.withdrawPeriods[x].TransactionDt.lastIndexOf(" ")) + " for period " + this.withdrawPeriods[x].PeriodStartDt + " - " + this.withdrawPeriods[x].PeriodEndDt; //JMC, 9
                      } else {
                        this.withdrawStatement = "Withdrawn on " + this.withdrawPeriods[x].TransactionDt.substring(0, this.withdrawPeriods[x].TransactionDt.lastIndexOf(" ")) + " by " + this.lastUpdateByUser + " for period " + this.withdrawPeriods[x].PeriodStartDt + " - " + this.withdrawPeriods[x].PeriodEndDt; //JMC, 9
                      }
                      this.withdrawnStatements.push(this.withdrawStatement);
                    }
                  }
                  //RBA, 8 END
                } else if(enrollDetails[i].EnrollStatus == EnrollStatus.Withdraw) {
                  this.withdrawnStatements = [];
                  for(let x = 0; x < this.withdrawPeriods.length; x++) {
                    // RBA, 8
                    if(enrollDetails[x].Updated_by == null) {
                      this.lastUpdateByUser = "dbo";
                    } else if(enrollDetails[x].Updated_by != this.user.OptNum) {
                      this.lastUpdateByUser = "admin";
                    } else {
                      this.lastUpdateByUser = this.user.EnterpriseId; // get value from select user details by optnum
                    }
                    // RBA, 8
                    if(this.withdrawPeriods[x].Checked == true) {
                      this.displayWithdrawStatement = true;
                      if(this.lastUpdateByUser == "dbo") { 
                        this.withdrawStatement = "Auto-withdrawn on " + this.withdrawPeriods[x].TransactionDt.substring(0, this.withdrawPeriods[x].TransactionDt.lastIndexOf(" ")) + " for period " + this.withdrawPeriods[x].PeriodStartDt + " - " + this.withdrawPeriods[x].PeriodEndDt; //JMC, 9
                      } else if(this.lastUpdateByUser == "admin") {
                        this.withdrawStatement = "Withdrawn on " + this.withdrawPeriods[x].TransactionDt.substring(0, this.withdrawPeriods[x].TransactionDt.lastIndexOf(" ")) + " for period " + this.withdrawPeriods[x].PeriodStartDt + " - " + this.withdrawPeriods[x].PeriodEndDt; //JMC, 9
                      } else {
                        this.withdrawStatement = "Withdrawn on " + this.withdrawPeriods[x].TransactionDt.substring(0, this.withdrawPeriods[x].TransactionDt.lastIndexOf(" ")) + " by " + this.lastUpdateByUser + " for period " + this.withdrawPeriods[x].PeriodStartDt + " - " + this.withdrawPeriods[x].PeriodEndDt; //JMC, 9
                      }
  
                      this.withdrawnStatements.push(this.withdrawStatement);
                    }
                  }
                }
  
                if(transactType == "1"){
                  //this.displayEnrollNote = true;
                  enrollEsppToastStage(); // Notif framework toast widget 
                  enrollEsppToastProd(); 
                  this.displayWithdrawStatement = false;
                }else if(transactType == "2"){
                  this.displayWithdrawNote = true;
                  esppToastWithdrawStage(); // Notif framework toast widget
                  esppToastWithdrawProd();
                  this.displayEnrollStatement = true;
                  this.enrollStatement = this.withdrawStatement;
                }else{
                  if(enrollDetails[i].EnrollStatus == EnrollStatus.EnrollESPP || enrollDetails[i].EnrollStatus == EnrollStatus.ChangeESPPContribution){
                    //this.displayEnrollNote = true;
                  }else{
                    this.displayWithdrawNote = true;
                    this.displayEnrollStatement = true;
                    this.enrollStatement = this.withdrawStatement;
                  }
                }
                // Setting of Enroll / Withdraw display - END
              }
            }
          }
  
  
          // this.loading = false;
          this.getStepTwoContent();
        this.setTabIndex();
        } catch(error) {
          this.errorEnrollmentAcc = true;         
          this.errorWithdrawAcc = true;
          let errorLocation = this.errorLocation.replace("methodName", "getEsppEnrollDetails");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorEnrollmentAcc = true;
        this.errorWithdrawAcc = true;
        let errorLocation = this.errorLocation.replace("methodName", "getEsppEnrollDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorEnrollmentAcc = true;
      this.errorWithdrawAcc = true;
      let errorLocation = this.errorLocation.replace("methodName", "getEsppEnrollDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  setTabIndex(){
    try {
      $(document).ready(function(){
        var esppTab = $("#esppTab").hasClass("active");
        var learnMore = $("#learn-more-tab").hasClass("active");
  
        esppTab ? $("#esppTab").attr("tabIndex", "-1") : $("#esppTab").attr("tabIndex", "0");
        learnMore ? $("#learn-more-tab").attr("tabIndex", "-1") : $("#learn-more-tab").attr("tabIndex", "0"); 
      })
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFreezePeriod");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: enrollWithdrawUser                                                                 
   * Description: Enrolls the user, changes their contribution, or withdraw                             
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/19/19             Dalemark P. Suquib          Initial draft
   * 2    02/28/19             Dalemark P. Suquib          Added withdraw condition
   * 3    03/05/19             Allan Christopher M. Salac  Change condition / To verify if user is currently enrolled
   * 4    04/01/19             Joshua Paul J. Avecilla     Added loading when clicking enroll button
   * 5    10/16/19             Katrina Narag (KMN)         Removed SSN and PersonnelNbr as part of security vulnerability
   * 5    10/16/19             Jikho                       Modified condition
   * 6    11/18/19             KMN                         Removed currentDate as param
   *****************************************************************************************/
  enrollUser(transactType) {
    if(Number(this.contribVal % 1 == 0) && Number(this.contribVal) <= 10 && Number(this.contribVal) > 0 && Number(this.contribVal) != null){
      this.displayErrorTenUp = false;
    try {
      this.loading = true;
      transactType = 1;
      this.displayErrorPeriod = false;
      this.esppTransactionService.getESPPEnrollDetail(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.enrollmentDetails = data[0];        
  
          let date = new Date;
          //let currentDate = moment(date).tz("America/Chicago").format('YYYY-MM-DD hh:mm:ss A');
          let isAdmin = true;
          let enrollstat, lastUpdatedbyUser;
          let transOptNum;
    
          let b = this.enrollmentDetails;
          this.offeringDetails = b.find(x => x.OfferNum == this.esppTransacDetails[0].OfferNum);
    
          if(Number(this.contribVal) <= 10 && this.user.EsppEligibleInd == "Y" && this.isOpenEnrollment == true ){          
            if((this.offeringDetails.EnrollStatus != EnrollStatus.Withdraw) && (this.offeringDetails.EnrollStatus != null)) { // if user is currently enrolled                        
              enrollstat = EnrollStatus.ChangeESPPContribution;            
            }else{
              enrollstat = EnrollStatus.EnrollESPP;            
            }
    
            if(this.admin != null){
              transOptNum = this.admin.OptNum;
            } else {
              transOptNum = this.user.OptNum;
            }
            this.insertDetails = [];
 
            // this.insertDetails.push(this.user.ParticipantNumber);  -- KMN, 5
            this.insertDetails.push(this.contribVal);
            // this.insertDetails.push(currentDate);
            // this.insertDetails.push(this.user.PersonnelNumber); -- KMN, 5
            this.insertDetails.push(this.esppTransacDetails[0].OfferNum);
            this.insertDetails.push(this.user.OptNum);
            this.insertDetails.push('N'); //Purchase for agreement flag N/A anymore
            this.insertDetails.push(transOptNum);
            this.insertDetails.push(enrollstat);  
            this.esppTransactionService.insertTransTbl(this.insertDetails).subscribe(data => {
              this.getEnrollmentDetails(this.esppTransacDetails[0].OfferNum, this.offeringDetails.ContributionPercent, transactType);
            }, error => {
              let errorLocation = this.errorLocation.replace("methodName", "enrollUser");
              error.error.message += errorLocation;
              throw error.error;
            });
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "enrollUser");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "enrollUser");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "enrollUser");
      error.message += errorLocation;
      throw error;
    }
  }else{
    this.displayErrorTenUp = true;
  }
}



  /*****************************************************************************************
   * Method Name: withdrawUser                                                                 
   * Description: Withdraw Process                    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/27/19             Katrina Narag                Initial draft
   * 2    04/01/19             Joshua Paul J. Avecilla     Added loading when clicking enroll button
   *****************************************************************************************/
  withdrawUser(transactType){
    transactType = 2;
    this.loading = true;
    try {
      for(let i = 0; i < this.withdrawPeriods.length; i++) {
        if(this.withdrawPeriods[i].Checked == true && this.withdrawPeriods[i].disableWithdrawCheckbox == false) {
          let enrollstat, lastUpdatedbyUser;
          let transOptNum;
          let date = new Date;
          //let currentDate = moment(date).tz("America/Chicago").format('YYYY-MM-DD hh:mm:ss A');
  
          this.admin != null ? transOptNum = this.admin.OptNum :transOptNum = this.user.OptNum;
  
          this.insertDetails = [];
          //this.insertDetails.push(this.user.ParticipantNumber);
          this.insertDetails.push(0);
            enrollstat = EnrollStatus.Withdraw;
          //this.insertDetails.push(currentDate);
          //this.insertDetails.push(this.user.PersonnelNumber);
          this.insertDetails.push(this.withdrawPeriods[i].OfferNum);
          this.insertDetails.push(this.user.OptNum);
          this.insertDetails.push('N'); //Purchase for agreement flag N/A anymore
          this.insertDetails.push(transOptNum);
          this.insertDetails.push(enrollstat);  
          this.esppTransactionService.insertTransTbl(this.insertDetails).subscribe(data => {
            this.getEnrollmentDetails(this.withdrawPeriods[i].OfferNum, this.contribVal, transactType);
          }, error => {
            let errorLocation = this.errorLocation.replace("methodName", "withdrawUser");
            error.error.message += errorLocation;
            throw error.error;
          });
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "withdrawUser");
      error.message = error.message + errorLocation;
      throw error;
    }
  }


  /*****************************************************************************************
   * Method Name: getEnrollmentDetails                                                                 
   * Description: Format message after clicking enroll button                     
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/27/19             Katrina Narag                Initial draft
   *****************************************************************************************/
  getEnrollmentDetails(offerNum, previousContrib, transactType){
    try {
      this.sendEmailDetails = [];
      this.esppTransactionService.getESPPEnrollDetail(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          if(data != undefined){
            this.enrollmentDetails = data[0];
            sessionStorage.removeItem("ESPPActionItemCardCacheData");
            if(transactType == 1) {
              this.offeringDetails = this.enrollmentDetails.find(x => x.OfferNum == offerNum); 
              this.sendEmail(this.offeringDetails, previousContrib, transactType);
            } else if(transactType == 2) {
              this.offeringDetails = this.enrollmentDetails.find(x => x.OfferNum == offerNum);
              this.sendEmail(this.offeringDetails, previousContrib, transactType); 
            }
          }    
        }catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentDetails");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getEnrollmentDetails                                                                 
   * Description: Format message after clicking enroll button                     
   *                                                                                          
   * #    Date                 Modified by                   Description                             
   * 1    08/19/19             Joshua Avecilla               put the call to method getEsppEnrollDetails outside of sendemail api call.
   * 2    11/19/19             Katrina Narag                 Removed checking of TransactionDate
   *****************************************************************************************/
  sendEmail(details, previousContrib, transactType) {
    try {
      let emailAction;
      this.offeringDetails = details;   
  
      if(this.offeringDetails != null || this.offeringDetails != undefined){
        if(this.offeringDetails.EnrollStatus == EnrollStatus.ChangeESPPContribution) {
          emailAction = ActionTypes.ChangeContrib;
        } else if(this.offeringDetails.EnrollStatus == EnrollStatus.Withdraw) {
          emailAction = ActionTypes.Withdraw;
        } else {
          emailAction = ActionTypes.Enroll;
        }
  
        this.sendEmailDetails.push(emailAction);
        this.sendEmailDetails.push("ESPP");
        this.sendEmailDetails.push(null); //year param for VEIP
        this.sendEmailDetails.push(this.offeringDetails.PeriodStartDt);
        this.sendEmailDetails.push(this.offeringDetails.PeriodEndDt);                        
  
        if(this.offeringDetails.EnrollStatus == EnrollStatus.Withdraw) {
          this.sendEmailDetails.push(this.offeringDetails.ContributionPercent);
          this.sendEmailDetails.push(0);
  
        } else if (this.offeringDetails.EnrollStatus == null)
        {                            
          this.sendEmailDetails.push(previousContrib);
          this.sendEmailDetails.push(0);
        }
        else{
          this.sendEmailDetails.push(this.offeringDetails.ContributionPercent);
          this.sendEmailDetails.push(previousContrib); 
        }            
          this.sendEmailDetails.push(this.user.OptNum);  // replace with email add
          this.sendEmailDetails.push(this.offeringDetails.TransactionDt.replace(":", "colon")); // JMC, 9
          this.sendEmailDetails.push(this.offeringDetails.TransNum);  //JMC, 9
          this.getEsppEnrollDetails(transactType);   
          this.esppTransactionService.sendMail(this.sendEmailDetails).subscribe(data =>{

          }, error => {
            let errorLocation = this.errorLocation.replace("methodName", "sendEmail");
            error.error.message += errorLocation;
            throw error.error;
          });
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "sendEmail");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: checkOpenEnrollment                                                                 
   * Description: Checks the offering flag of essp enroll details & sets the value of 
   * enable control.                             
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/21/19             Dalemark P. Suquib          Initial draft
   * 2    03/04/19             Allan Christopher M. Salac  Bug 243369   
   * 3    11/7/19              Jikho S. Enrique            Commented out the withdraw Condition
   * 4    11/19/19             Katrina Narag               Removed comparison of enrollment period to local date
   *****************************************************************************************/
  checkOpenEnrollment(enrollDetails) {
    try {
      // let date = new Date();
      // let currentDate = moment(date).format("YYYY-MM-DD");
      // this.enrollStartDate = moment(enrollDetails.EnrollmentStartDt).format("YYYY-MM-DD");
      // this.enrollEndDate = moment(enrollDetails.EnrollmentEndDt).format("YYYY-MM-DD");

      if(enrollDetails.isOpenEnrollment == 1) { // if open enrollment
        this.enableControl = true;
        this.isOpenEnrollment = true;
      } else {
        this.enableControl = false;
      }
     
      if(enrollDetails.EnrollStatus == EnrollStatus.EnrollESPP || 
        enrollDetails.EnrollStatus == EnrollStatus.ChangeESPPContribution || 
        enrollDetails.EnrollStatus == EnrollStatus.Withdraw) { 
        if(enrollDetails.ContributionPercent != null) {
          this.contribVal = enrollDetails.ContributionPercent;
        } 
      }

      // if(enrollDetails.EnrollStatus == EnrollStatus.Withdraw) {
      //   this.contribVal = enrollDetails.ContributionPercent;
      //   this.disableEsppInput = true;
      // }

      if(enrollDetails.EnrollStatus == EnrollStatus.OnHold) { 
        this.enableControl = false;
        this.contribVal = enrollDetails.PrevContrib;
      }

     this.checkEnableControl();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkOfferingFlag");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: checkEnableControl                                                                 
   * Description: Checks the value of enable control to enable or disable enroll button & 
   * text contribution.                            
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/22/19             Dalemark P. Suquib          Initial draft
   *****************************************************************************************/
  checkEnableControl() {
    try {
      if(this.enableControl) {
        this.disableEnrollbtn = !this.canTransact();
        this.disableEsppInput = !this.canTransact();
      } else {
        this.disableEnrollbtn = !this.enableControl;
        this.disableEsppInput = !this.enableControl;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFreezePeriod");
      error.message += errorLocation;
      throw error;
    }
  }

   /*****************************************************************************************
   * Method Name: canTransact                                                                 
   * Description: Checks the user if admin and can transact                        
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/21/19             Dalemark P. Suquib          Initial draft
   *****************************************************************************************/
  canTransact(): boolean {
    try {
      if(this.admin != null) {
        if(this.admin.OptNum == this.user.OptNum) {
          return true; 
        } else if(this.admin.OptNum != this.user.OptNum && this.admin.IsAdminCanTransactInd == AdminType.ReadWrite) {
          return true;
        } else if(this.admin.IsAdmin == "Y" && this.admin.IsAdminCanTransactInd == AdminType.ReadOnly) {
          return false;
        } else if(this.admin.OptNum != this.user.OptNum) {
          return false;
        }
      } else {
        return true; // since no admin
      }
    } catch(error) {
      this.errorEnrollmentAcc = true;
      this.errorWithdrawAcc = true;
      let errorLocation = this.errorLocation.replace("methodName", "canTransact");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: checkEsppInput                                                                 
   * Description: Checks the value of textbox with a minimum of 1 and a maximum of 10.                
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    02/22/19             Dalemark P. Suquib          Initial draft
   * 2    03/04/19             Allan Christopher M. Salac  Added validation for periods
   * 3    03/15/19             Jayson Sevilla [JSS]        Added condition for null input
   * 4    11/4/2019            Jikho S. Enrique            Removed condition for Period
   *****************************************************************************************/
  checkEsppInput(value, event) {
    try {
      this.contribVal = value;
      this.disableEnrollbtn = false;
      if(value == 0 && value != "" && event != "Backspace") {
        this.displayErrorZero = true;
        this.displayErrorTenUp = false;
        this.noInputError = false;
      }
      else if(value == 0 && value !="" && event == "Backspace"){
        this.displayErrorZero = true;
        this.displayErrorTenUp = false;
        this.noInputError = false;
      } else if(value > 10 && event != "Backspace") {
        this.displayErrorZero = false;
        this.displayErrorTenUp = true;
        this.noInputError = false;
      }
      else if(value > 10 && event == "Backspace"){
        this.displayErrorZero = false;
        this.displayErrorTenUp = true;
        this.noInputError = false;
      } 
      else if(value == "" || value == null){
        this.noInputError = true;
        this.displayErrorZero = false;
        this.displayErrorTenUp = false;
      }else {
        this.displayErrorZero = false;
        this.displayErrorTenUp = false;
        this.noInputError = false;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkEsppInput");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: enableWithdraw                                                                
   * Description: Enables withdraw button.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    03/15/2019           Katrina Narag               Initial Draft
   * 2    03/18/2019           Dalemark Suquib             Updated condition
   *****************************************************************************************/
  enableWithdraw(period) {
    try {
      for(let i = 0; i < this.withdrawPeriods.length; i++) {
        if(period.OfferNum == this.withdrawPeriods[i].OfferNum) {
          this.withdrawPeriods[i].Checked = !(period.Checked);
          this.withdrawPeriods[i].Checked == true ? this.checkCounter += 1: this.checkCounter -= 1;
        }
      }
  
      this.checkCounter > 0 ? this.disableWithdrawBtn = false: this.disableWithdrawBtn = true;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "enableWithdraw");
      error.message += errorLocation;
      throw error;
    }
  }

   /*****************************************************************************************
   * Method Name: numberOnly                                                                 
   * Description: Input only numbers 1-10 for ESPP Enrollment Percentage Field    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    03/15/2019         Iris Jane R. Manloloyo        Initial Draft
   * 2    10/16/2019         Jikho S. Enrique              Re-used the existing method.
   *****************************************************************************************/

  // numberOnly(event): boolean {
  //   try {
  //     const charCode = (event.which) ? event.which : event.keyCode;
  //     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //       return false;
  //     }
  //     return true;
  //   } catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "numberOnly");
  //     error.message += errorLocation;
  //     throw error;
  //   }
    
  // }

  /*****************************************************************************************
   * Method Name: getCommonQuestions                                                                 
   * Description: Gets the content for common questions from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  async getCommonQuestions() {
    try {
      this.bodyData[5] = PageSection.esppTransacCommonQuestions;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let commonQuestionData = JSON.parse(data.body);
        
          if(commonQuestionData.data) { // length > {}
            let commonQuestionBody = commonQuestionData.data;

            if(commonQuestionBody.length > 0) {
              this.commonQuestionsContent = commonQuestionBody;

              for(let i=0; i<this.commonQuestionsContent.length; i++){
                if(this.commonQuestionsContent[i]["Body_Desc"].indexOf('<a href="https://myholdings.accenture.com/Resources/Default.aspx" target="_blank">Resources</a>') != -1){
                  this.commonQuestionsContent[i]["Body_Desc"] = this.commonQuestionsContent[i]["Body_Desc"].replace('<a href="https://myholdings.accenture.com/Resources/Default.aspx" target="_blank">Resources</a>','<a href="https://myholdings.accenture.com/Resources/Default.aspx" aria-label="Resources" target="_blank">Resources</a>');
                }
              } 
            } else {
              this.commonQuestionsContent = null;
            }
          }   
          // this.getGuideBooks();
        } catch(error) {
          this.errorCommonQuestions = true;
          let errorLocation = this.errorLocation.replace("methodName", "getCommonQuestions");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCommonQuestions = true;
        let errorLocation = this.errorLocation.replace("methodName", "getCommonQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCommonQuestions = true;
      let errorLocation = this.errorLocation.replace("methodName", "getCommonQuestions");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getGuideBooks                                                                 
   * Description: Gets the content for guide books from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getGuideBooks() {
    try {
      this.bodyData[5] = PageSection.esppTransacGuideBooks;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let guideBooksData = JSON.parse(data.body);
  
          if(guideBooksData.data) { // length > {}
            let guideBooksBody = guideBooksData.data;
            
            if(guideBooksBody.length > 0) {
              this.guideBooksContent = guideBooksBody[0].Body_Desc;

            } else {
              this.guideBooksContent = null;
            }
          }
    
          // this.getPlanDocument();
        } catch(error) {
          this.errorGuideBooks = true;
          let errorLocation = this.errorLocation.replace("methodName", "getGuideBooks");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorGuideBooks = true;
        let errorLocation = this.errorLocation.replace("methodName", "getGuideBooks");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorGuideBooks = true;
      let errorLocation = this.errorLocation.replace("methodName", "getGuideBooks");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getPlanDocument                                                                 
   * Description: Gets the content for plan document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getPlanDocument() {
    try {
      this.bodyData[5] = PageSection.esppTransacPlanDocument;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let planDocumentData = JSON.parse(data.body);
  
          if(planDocumentData.data) { // length > {}
            let planDocumentBody = planDocumentData.data;
    
            if(planDocumentBody.length > 0) {
              this.planDocumentContent = planDocumentBody[0].Body_Desc;
            } else {
              this.planDocumentContent = null;
            }
          }
    
          // this.getProspectusDocument();
        } catch(error) {
          this.errorPlanDoc = true;
          let errorLocation = this.errorLocation.replace("methodName", "getPlanDocument");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorPlanDoc = true;
        let errorLocation = this.errorLocation.replace("methodName", "getPlanDocument");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorPlanDoc = true;
      let errorLocation = this.errorLocation.replace("methodName", "getPlanDocument");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getProspectusDocument                                                                 
   * Description: Gets the content for prospectus document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   * 2    04/26/2019           KMN                         BUG 291513 - Add display of icons method (inContentIcon)
   *****************************************************************************************/
  getProspectusDocument() {
    try {
      this.bodyData[5] = PageSection.esppTransacProspectusDoc;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let prospectusDocData = JSON.parse(data.body);
  
          if(prospectusDocData.data) { // length > {}
            let prospectusDocBody = prospectusDocData.data;
    
            if(prospectusDocBody.length > 0) {
              this.prospectusDocContent = prospectusDocBody[0].Body_Desc;
           
              if(this.prospectusDocContent.indexOf('2010%20ESPP%20Prospectus%20-%20Final.pdf') != -1){
                this.prospectusDocContent = this.prospectusDocContent.split('<a href="2010%20ESPP%20Prospectus%20-%20Final.pdf" target="_blank" style="padding-left: 23px;"></a>').join("");
              }
                     
            } else {
              this.prospectusDocContent = null;
            }
          }              
        } catch(error) {
          this.errorProspectusDoc = true;
          let errorLocation = this.errorLocation.replace("methodName", "getProspectusDocument");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorProspectusDoc = true;
        let errorLocation = this.errorLocation.replace("methodName", "getProspectusDocument");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorProspectusDoc = true;
      let errorLocation = this.errorLocation.replace("methodName", "getProspectusDocument");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getProspectusDocument                                                                 
   * Description: Gets the content for country and award from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   * 2    06/12/2019           KMN                         Bug 355706 fix - Errors found in ESPP Transaction page (Enroll accordion) using Siteimprove Accessibility Checker
   *****************************************************************************************/
  getCountryAndAward() {
    try {
      this.bodyData[5] = PageSection.esppTransacCountryAndAward;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let countryAndAwardData = JSON.parse(data.body);
  
          if(countryAndAwardData.data) { // length > {}
            let countryAndAwardBody = countryAndAwardData.data;
    
            if(countryAndAwardBody.length > 0) {
              this.countryAndAwardContent = countryAndAwardBody[0].Body_Desc;

              if(this.countryAndAwardContent.indexOf('<a href="https://myholdings.accenture.com/Resources/Default.aspx" target="_blank">Resources</a>') != -1){
                this.countryAndAwardContent = this.countryAndAwardContent.replace('<a href="https://myholdings.accenture.com/Resources/Default.aspx" target="_blank">Resources</a>','<a href="https://myholdings.accenture.com/Resources/Default.aspx" aria-label="Resources" target="_blank">Resources</a>');
              }
            } else {
              this.countryAndAwardContent = null;
            }
          }
    
          // this.getToolsAndReports();
        } catch(error) {
          this.errorCountryAward = true;
          let errorLocation = this.errorLocation.replace("methodName", "getCountryAndAward");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCountryAward = true;
        let errorLocation = this.errorLocation.replace("methodName", "getCountryAndAward");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCountryAward = true;
      let errorLocation = this.errorLocation.replace("methodName", "getCountryAndAward");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getToolsAndReports                                                                 
   * Description: Gets the content for tools and reports from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getToolsAndReports() {
    try {
      this.bodyData[5] = PageSection.esppTransacToolsAndReports;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let toolsAndReportsData = JSON.parse(data.body);
  
          if(toolsAndReportsData.data) { // length > {}
            let toolsAndReportsBody = toolsAndReportsData.data;
            
            if(toolsAndReportsBody.length > 0) {
              this.toolsAndReportContent = toolsAndReportsBody;

              for(let i=0; i<this.toolsAndReportContent.length; i++){
                if(this.toolsAndReportContent[i]["Body_Desc"].indexOf("<u>") != -1){
                  this.toolsAndReportContent[i]["Body_Desc"] = this.toolsAndReportContent[i]["Body_Desc"].replace("<u>","<span style='text-decoration: underline;'>");
                  this.toolsAndReportContent[i]["Body_Desc"] = this.toolsAndReportContent[i]["Body_Desc"].replace("</u>","</span>");
                }
              }
            } else {
              this.toolsAndReportContent = null;
            }
          }
    
          this.loading = false;
          // setTimeout(()=>{ this.inContentIcon() }, 500);          
        } catch(error) {
          this.errorToolsAndReports = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getToolsAndReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorToolsAndReports = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getToolsAndReports");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorToolsAndReports = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getToolsAndReports");
      error.message += errorLocation;
      throw error;
    }
  }

  getFile(file, checker) {
    try {
      let fileName: string;
      let fileType: string;
    
      if(checker == "static") {
        if(file.File_Type.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
        
        fileName = file.Document_Name + "." + file.File_Type;
      } else {
        if(file.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
    
        fileName = file;
      }
    
      this.staticContentService.getFile(fileName, "MD").subscribe(data => { // MD for contacts page
        try {
          if(fileName.search("fileName") != -1 ){
            fileName = fileName.replace('fileName=', '');
          }
          let content = data;

          if(content != "null" && content != null && content != undefined){
            let file = JSON.parse(data.body);    
            
            this.downloadFile(fileName, fileType, this.base64ToArrayBuffer(file.file));
  
            this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
              let writeLogResponse = data;
            });
          }else{
            this.staticContentService.noFileExists(fileName);
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFile");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.message += errorLocation;
      throw error;
    }
   }

   downloadFile(reportName, fileType, byte) {
    try {
      if(navigator.appVersion.toString().indexOf('.NET') > 0) { // browser is IE
        let blob = new Blob([byte], {type: fileType});
        window.navigator.msSaveBlob(blob, reportName);
      } else if(navigator.userAgent.indexOf("Firefox") > 0) { // browser is FireFox
        let a = document.createElement('a');
        a.style.display = "none";
        a.style.zIndex = "1002";
        a.style.position = "absolute";
        let blob = new Blob([byte], {type: fileType});
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = reportName;
        document.body.appendChild(a);
        a.click();
    
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } else { // browser is Chrome
        let blob = new Blob([byte], {type: fileType});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.click();
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "downloadFile");
      error.message += errorLocation;
      throw error;
    }
   }
   base64ToArrayBuffer(base64): Uint8Array {
    try {
      let binaryString = window.atob(base64);
      let binaryLength = binaryString.length;
      let bytes = new Uint8Array(binaryLength);
      
      for(let i = 0; i < binaryLength; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "base64ToArrayBuffer");
      error.message += errorLocation;
      throw error;
    }
   }

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   * 2    06/07/2019           KMN                         Bug 332349 fix - The Link is Not Redirected to Brokers Page in ESPP Transaction in Learn More Tab (Common Questions) Regression
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let id: string = event.target.id;
      let srcElement: HTMLImageElement = event.srcElement;
      let target: string = event.target.target;
      
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          let innerTxt = event.target.innerText;
          event.preventDefault();

          if(innerTxt in MyHoldingsPagesResources){
            this.navToMyHoldingsPage(href);
          }else if(href.indexOf("Resources") != -1){
            this.navToMyHoldingsPage("Resources");
          }

        }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
          event.preventDefault();
          id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
        }else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
          event.preventDefault();
          window.open(href, target);
        }else{ //if has file but no filename text
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "technologysupport" : "";
  
    window.open(this.globals.currentWebUrl + "/" + page, "_blank");
  }


 getStepTwoContent() {
  try {
    this.bodyData[5] = PageSection.esppTransacStepTwo + this.bodyData[0];
    this.bodyData[6] = 0;
  
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let stepTwoData = JSON.parse(data.body);
        if(stepTwoData.data) { // length > {}
          let stepTwoBody = stepTwoData.data;
     
          if(stepTwoBody.length > 0) {
            this.stepTwoContent = stepTwoBody;

            if(this.stepTwoContent != undefined){
              this.stepTwoContent.forEach(element => {
                if (element.File_Type == 'pdf'){
                  element.fileIcon = FileType.pdf;
                } else if (element.File_Type == 'docx'){
                  element.fileIcon = FileType.doc;
                } else if (element.File_Type == 'doc'){
                  element.fileIcon = FileType.doc;
                } else if (element.File_Type == 'xls'){
                  element.fileIcon = FileType.xls;
                } else if (element.File_Type == 'xlsx'){
                  element.fileIcon = FileType.xls;
                } else if (element.File_Type == 'pptx'){
                  element.fileIcon = FileType.ppt;
                } else if (element.File_Type == 'ppt'){
                  element.fileIcon = FileType.ppt;
                } else if (element.File_Type == 'zip'){
                  element.fileIcon = FileType.zip;
                } else{
                  // For future use
                }
              });
            }
          } else {
            this.stepTwoContent = null;
          }
        }
        this.loading = false;
        // this.getAllCountryForms();
      } catch(error) {
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
    error.message += errorLocation;
    throw error;
  }
}

 /*********************************************************************************************
  Method Name: getAllCountryForms
  Description: Gets all the available country forms.

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  2     06/12/19        Dalemark Suquib         Bug 355888: ESPP transaction page keeps on loading after the employee enroll to espp program
  **********************************************************************************************/
 getAllCountryForms() {
  try {
    this.bodyData[5] = PageSection.esppTransacAllCountryForms;
    this.bodyData[6] = 1;
 
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
     try {
      let allCountryFormsData = JSON.parse(data.body);
     
      if(allCountryFormsData.data) { // length > {}
        let allCountryFormsBody = allCountryFormsData.data;

        if(allCountryFormsBody.length > 0) {
          this.allCountryFormsContent = allCountryFormsBody[0].Body_Desc;  
              
          setTimeout(() => { this.inContentIcon() }, 500);                    
        } else {
          this.allCountryFormsContent = null;          
          setTimeout(() => { this.inContentIcon() }, 500);                 
        }
      }

      this.loading = false;
     } catch(error) {
       this.loading = false;
       let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
       error.message += errorLocation;
       throw error;
     }
    }, error => {
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
    error.message += errorLocation;
    throw error;
  }
 }

 /*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
*****************************************************************************************/
async inContentIcon(){  
  $("[id='learn-more-tab']").attr("aria-label","Learn More - ESPP transaction page");
  $("[href='#collapseCQIndex0']").attr("aria-expanded","true");
  $("[id='collapseCQIndex0']").attr("class","collapse show");
  $("b").contents().unwrap();
  $("u").contents().unwrap();
  $("[href='https://myholdings-fortify.ciotest.accenture.com/reportspage?reportid=1']").css("text-decoration","underline");
  $("[href='https://myholdings-fortify.ciotest.accenture.com/reportspage?reportid=1']").css("text-decoration","underline");
  $("[href='https://myholdings-fortify.ciotest.accenture.com/reportspage?reportid=5']").css("text-decoration","underline");
  $("[href='https://myholdings-fortify.ciotest.accenture.com/reportspage?reportid=6']").css("text-decoration","underline");
  $("[href='https://myholdings-fortify.ciotest.accenture.com/reportspage?reportid=7']").css("text-decoration","underline");
  setTimeout(()=>{ this.staticContentService.inContentIconAppend() }, 500);
}

/***************************************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Dalemark P. Suquib                    Initial draft
  * 2    04/29/19             Dalemark P. Suquib                    Updated & added local storage for report id
  * 3    05/16/19             KMN                                   BUG 307166 fix - Add condition for download file
  * 4    05/20/19             KMN                                   Bug 307166 - Handles download file when user clicks the icon
  **************************************************************************************************************/
navToReportsPage(event) {
  try {
    let href = event.target.href;
    let id: number;
    let reportId: object;
    let srcElement: HTMLImageElement = event.srcElement;

    if(srcElement.src != undefined){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
    }else if(href != undefined) {
      if(href.indexOf("reportid") != -1) {
        id = href.substring(href.lastIndexOf("=") + 1);
        reportId = {'ReportTypeId': id};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
        window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
      }else if(href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      } else {
        event.preventDefault();
        window.open(href, "_blank");
      }
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToReportsPage");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: selectElectMethod                                                                 
  * Description: Gets the value of selected elect method.    
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/25/19             Dalemark P. Suquib                    Initial draft
*****************************************************************************************/
selectElectMethod(event) {
  try {
    let electMethod = event.value;

    if(electMethod == "NSD") {
      this.selectedTaxElect = "NSD";
    } else if(electMethod == "PW") {
      this.selectedTaxElect = "PW";
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "selectElectMethod");
    error.message += errorLocation;
    throw error;
  }
}

  /*****************************************************************************************
    * Method Name: getTaxElectionData                                                                 
    * Description: Gets the Tax Election data.  
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/25/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
  getTaxElectionData(offerNum) {
    try {
      this.taxElectOfferNum = offerNum;
      this.displayTaxElectMsg = false;
  
      this.esppTransactionService.getESPPEnrollTaxElection(this.user.OptNum).subscribe(data => {
        try {
          let taxElectData = data;

          let date = new Date();
          let currentDate = moment(date).format("YYYY-MM-DD");
          let taxElectStartDate = moment(this.taxElectStartDate).format("YYYY-MM-DD");
          let taxElectEndDate = moment(this.taxElectEndDate).format("YYYY-MM-DD");
          this.taxElectionEndDate = moment(taxElectEndDate).format("DD MMM YYYY");
    
          if(taxElectData[0].length > 0) {
            taxElectData = taxElectData[0][0];
    
            if(this.isOpenTaxElect == 1) {
              this.disablePayrollWithholding = false;
              this.disableShareWithholding = false;
              this.disableElectButton = false;
              this.teWindowText = "Open";
            } else {
              this.disablePayrollWithholding = true;
              this.disableShareWithholding = true;
              this.disableElectButton = true;
              this.teWindowText = "Closed";
            }
            
            if(taxElectData.TaxElect == "PW") {
              this.checkPayrollWithholding = true;
              this.checkShareWithholding = false;
              this.taxElection = "Payroll Withholding";
              this.selectedTaxElect = "PW"
            } else if(taxElectData.TaxElect == "NSD") {
              this.checkShareWithholding = true;
              this.checkPayrollWithholding = false;
              this.taxElection = "Share Withholding";
              this.selectedTaxElect = "NSD";
            }
    
            if(taxElectData.UPDATED_BY == null) {
              this.taxElectUpdatedBy = "dbo";
            } else if(taxElectData.UPDATED_BY != this.user.OptNum) {
              this.taxElectUpdatedBy = "admin";
            } else {
              this.taxElectUpdatedBy = this.user.EnterpriseId; 
            }
    
            if(this.taxElectUpdatedBy != "dbo") {
              if(this.taxElectUpdatedBy == "admin") {
                this.displayTaxElectMsg = true;
                this.taxElectMsg = "Elected " + this.taxElection + " on " + taxElectData.LAST_UPDATE;
              }else {
                this.displayTaxElectMsg = true;
                this.taxElectMsg = "Elected " + this.taxElection + " on " + taxElectData.LAST_UPDATE + " by " + this.taxElectUpdatedBy;
              }
            }
          }

          this.loading = false;
        } catch(error) {
          this.loading = false;
          this.errorTaxElectAcc = true;
          let errorLocation = this.errorLocation.replace("methodName", "getTaxElectionData");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.loading = false;
        this.errorTaxElectAcc = true;
        let errorLocation = this.errorLocation.replace("methodName", "getTaxElectionData");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.loading = false;
      this.errorTaxElectAcc = true;
      let errorLocation = this.errorLocation.replace("methodName", "getTaxElectionData");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
    * Method Name: onTabChange                                                                 
    * Description: Sets timeout for incontenticon method when a tab is selected.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/26/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
  onTabChange() {
    try {
      setTimeout(()=>{ this.inContentIcon() }, 500);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "onTabChange");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
    * Method Name: insertTaxElect                                                                 
    * Description: Inserts the tax elect selected to db.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/30/19             Dalemark P. Suquib                    Initial draft
    * 2    05/01/19             KMN                                   Modify insert details
  *****************************************************************************************/
  insertTaxElect() {
    try {
      this.loading = true;
      this.insertTaxElectDetails = [];

      this.insertTaxElectDetails.push(this.user.OptNum);
      this.insertTaxElectDetails.push(this.selectedTaxElect);
      this.insertTaxElectDetails.push(this.taxElectOfferNum);
  
      this.esppTransactionService.insertTaxElec(this.insertTaxElectDetails).subscribe(data => {
        //KDP
        //sessionStorage.removeItem("ESPPActionItemCardCacheData");
        this.getTaxElectionData(this.globalOffernum);
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRsuApiData");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "insertTaxElect");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Espptransact") != -1 && this.user != null) {
                webPage = "Espptransact";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
  collapseAccordion(index) {
    let className = document.getElementById("collapseCQIndex" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.commonQuestionsContent.length; i++) {
        document.getElementById("collapseCQIndex" + i).className = "collapse";
      }
    }
   }

  /**********************************************************************************
   * Method Name: setResourceTab                                                                 
   * Description: For espp enroll note link - go to FAQ's tab                            
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 09/20/19             Iris Jane Manloloyo        Initial draft         
   *********************************************************************************/
 setResourceTab(){
    sessionStorage.setItem("activeResource","Training & FAQs");
   }

          /**********************************************************************************
   * Method Name: displayDotErrorIE                                                          
   * Description: Promps warning when dot or period is pressed on IE                                          
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 10/16/2019          Jikho Enrique                 Initial draft      
   *********************************************************************************/
  displayDotErrorIE(event){
    
    if(event.charCode == '46'){
      this.displayErrorPeriod = true;
    }else{
      this.displayErrorPeriod = false;
    }
  }

   /*****************************************************************************************
   * Method Name: getEsppConfirmNoteDocument                                                                 
   * Description: Gets the ESSP Confirmation Note content in myResources contents.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    08/18/2021           Leah Z. Dia                 Initial Draft
   *****************************************************************************************/
 getEsppEnrollNote() {
  try {
    this.bodyData[4] = "No Grants";
    this.bodyData[5] = PageSection.esppEnrollNote;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let EsppConfirmNoteFile = JSON.parse(data.body);

        if(EsppConfirmNoteFile.data) { // length > {}
          let esppFile = EsppConfirmNoteFile.data;

          if(esppFile.length > 0) {
            this.esppEnrollNote = esppFile[0].Body_Desc;

          } else {
            this.esppEnrollNote = null;
          }
        }
      } catch(error) {
        this.errorEsppEnrollNote = true;
        let errorLocation = this.errorLocation.replace("methodName", "getEsppEnrollNote");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorEsppEnrollNote = true;
      let errorLocation = this.errorLocation.replace("methodName", "getEsppEnrollNote");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorEsppEnrollNote = true;
    let errorLocation = this.errorLocation.replace("methodName", "getEsppEnrollNote");
    error.message += errorLocation;
    throw error;
  }
}
getEsppWithdrawNote() {
  try {
    this.bodyData[4] = "No Grants";
    this.bodyData[5] = PageSection.esppWithdrawNote;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let EsppWithdrawNote = JSON.parse(data.body);

        if(EsppWithdrawNote.data) { // length > {}
          let esppFile = EsppWithdrawNote.data;
  
          if(esppFile.length > 0) {
            this.esppWithdrawNote = esppFile[0].Body_Desc;
          } else {
            this.esppWithdrawNote = null;
          }
        }
      } catch(error) {
        this.errorEsppWithdrawNote = true;
        let errorLocation = this.errorLocation.replace("methodName", "getEsppWithdrawNote");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorEsppWithdrawNote = true;
      let errorLocation = this.errorLocation.replace("methodName", "getEsppWithdrawNote");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorEsppWithdrawNote = true;
    let errorLocation = this.errorLocation.replace("methodName", "getEsppWithdrawNote");
    error.message += errorLocation;
    throw error;
  }
} 
getEsppCompleteFaxNote() {
  try {
    this.bodyData[4] = "No Grants";
    this.bodyData[5] = PageSection.esppNoteCompleteFax;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let EsppFaxNoteFile = JSON.parse(data.body);

        if(EsppFaxNoteFile.data) { // length > {}
          let esppFile = EsppFaxNoteFile.data;
  
          if(esppFile.length > 0) {
            this.esppCompleteFaxNote = esppFile[0].Body_Desc;
          } else {
            this.esppCompleteFaxNote = null;
          }
        }
      } catch(error) {
        this.errorEsppComFaxNote = true;
        let errorLocation = this.errorLocation.replace("methodName", "getEsppCompleteFaxNote");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorEsppComFaxNote = true;
      let errorLocation = this.errorLocation.replace("methodName", "getEsppCompleteFaxNote");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorEsppComFaxNote = true;
    let errorLocation = this.errorLocation.replace("methodName", "getEsppCompleteFaxNote");
    error.message += errorLocation;
    throw error;
  }
}
  openFileToNewTab(event) { // Gets the confirmation note for ESPP/VEIP in myResources API
    try {
      let href: string = event.target.href;
      let srcElement: HTMLImageElement = event.srcElement;
      let target: string = event.target.target;
      
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != "" ){

        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".xlsx") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file 
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        } else if(href == "https://myholdings.ciostage.accenture.com/resourcespage" || href == "https://myholdings.accenture.com/resourcespage") { 
          event.preventDefault();
          this.setResourceTab();
          window.open(href, target);
        } else if(href.indexOf("https") != -1){ 
          event.preventDefault();
          window.open(href, target);
        } else{ 
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "openFileToNewTab");
      error.message += errorLocation;
      throw error;
    }
  }
}



