import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
// import { MatInput } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import * as moment from 'moment'
import { BrokerType, PageSection, FileType, MyHoldingsPagesResources, tScript, CurrencySymbol} from '../../services/enumerations/enumerations';
import { HoldingProgramDetailsVEIPService } from '.././HoldingProgramDetailsVEIPService';
import { StaticContentService } from '../../services/StaticContentService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { BrokersPageService } from '../../brokerspage/BrokersPageService';
import {TooltipPosition} from '@angular/material/tooltip';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
// import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-veip-learnmore-tab',
  templateUrl: './veip-learnmore-tab.component.html',
  styleUrls: ['./veip-learnmore-tab.component.css']
})
export class VeipLearnmoreTabComponent implements OnInit {

  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));    
  
  veipCommonQuestionsContent: object[];
  veipGuidebooksContent: object;
  veipProspectusContent: object;
  veipCountryAwardContent: object;
  veipToolsandReportContent: object;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  allCountryFormsContent: string;
  stepTwoContent: object;
  errorLocation: string = " + [Component: Veipdetails, Method: methodName]";
  errorDetailsTab: boolean = false;
  errorSalesHistTab: boolean = false;
  errorCommonQuestions: boolean = false;
  errorGuideBooks: boolean = false;
  errorProspectusDoc: boolean = false;
  errorCountryAward: boolean = false;
  errorToolsAndReports: boolean = false;        

  loading: boolean = true;

  commonQAccordionCount: number = 1; // 1 accordion is initially expanded
  collapseIndexClass: string; 

  methodCount: number = 0;

  constructor(private brokerService: BrokersPageService, private router: Router, private app: AppComponent, private globals: Globals, private veipProgramDetails: HoldingProgramDetailsVEIPService, private staticContentService: StaticContentService, private errorNotificationService: ErrorNotificationService, private ngbDateParserFormatter: NgbDateParserFormatter) {}

  ngOnInit() {
    this.checkErrorMessage();
    this.getveipCommonQuestions();
    this.getveipGuideBooks();
    this.getveipProspectusDocument();
    this.getveipCountryAward();   
    this.getveipToolsAndReports();
  }

  /*****************************************************************************************
   * Method Name: methodCounter                                                                 
   * Description: Checks if all methods are executed.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    08/15/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  methodCounter() {
    if(this.methodCount == 5) {
      setTimeout(()=>{ 
        this.staticContentService.inContentIconAppend() ;
      }, 500);
      this.loading = false;
    } 
  }

  async inContentIcon(){
    $("[href='#collapseCQIndex0']").attr("aria-expanded","true");
    $("[id='collapseCQIndex0']").attr("class","collapse show");
    $("[id='learn-more-tab']").attr("aria-label","Learn More for VEIP award");  
  }

  /*****************************************************************************************
   * Method Name: getveipCommonQuestions                                                                 
   * Description: Gets the content for common questions from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/25/2019           Iris Jane Manloloyo        Initial Draft
   * 2    08/02/2019           JPA                        Moved call to guidebook data
   *****************************************************************************************/
  getveipCommonQuestions() {
    try {
      this.bodyData[5] = PageSection.veipAwardCommonQuestion;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let commonQuestionData = JSON.parse(data.body);
          if(commonQuestionData.data) { // length > {}
            let commonQuestionBody = commonQuestionData.data;

            if(commonQuestionBody.length > 0) {
              this.veipCommonQuestionsContent = commonQuestionBody;          
            } else {
              this.veipCommonQuestionsContent = null;
            }
          } 
          this.methodCount++;
          this.methodCounter();
        } catch(error) {
          this.errorCommonQuestions = true;
          this.methodCount++;
          this.methodCounter();
          let errorLocation = this.errorLocation.replace("methodName", "getveipCommonQuestions");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCommonQuestions = true;
        this.methodCount++;
        this.methodCounter();
        let errorLocation = this.errorLocation.replace("methodName", "getveipCommonQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCommonQuestions = true;
      this.methodCount++;
      this.methodCounter();
      let errorLocation = this.errorLocation.replace("methodName", "getveipCommonQuestions");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getveipGuideBooks                                                                 
   * Description: Gets the content for guide books from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/25/2019           Iris Jane Manloloyo         Initial Draft
   * 2    08/02/2019           JPA                         Removed call to Prospectus Document data
   *****************************************************************************************/
  getveipGuideBooks() {
    try {
      this.bodyData[5] = PageSection.veipAwardGuidebooks;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let guideBooksData = JSON.parse(data.body);
  
          if(guideBooksData.data) { // length > {}
            let guideBooksBody = guideBooksData.data;
            
            if(guideBooksBody.length > 0) {
              this.veipGuidebooksContent = guideBooksBody[0].Body_Desc;                        
            } else {
              this.veipGuidebooksContent = null;
            }
          }
          this.methodCount++;
          this.methodCounter();
        } catch(error) {
          this.errorGuideBooks = true;
          this.methodCount++;
          this.methodCounter();
          let errorLocation = this.errorLocation.replace("methodName", "getveipGuideBooks");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorGuideBooks = true;
        this.methodCount++;
        this.methodCounter();
        let errorLocation = this.errorLocation.replace("methodName", "getveipGuideBooks");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorGuideBooks = true;
      this.methodCount++;
      this.methodCounter();
      let errorLocation = this.errorLocation.replace("methodName", "getveipGuideBooks");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getveipProspectusDocument                                                                 
   * Description: Gets the content for plan document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/25/2019           Iris Jane Manloloyo         Initial Draft
   * 2    08/02/2019           JPA                         Removed call to Country Award data
   *****************************************************************************************/
  getveipProspectusDocument() {
    try {
      this.bodyData[5] = PageSection.veipAwardProspectusDocu;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let prospectusDocumentData = JSON.parse(data.body);
  
          if(prospectusDocumentData.data) { // length > {}
            let prospectusDocumentBody = prospectusDocumentData.data;
    
            if(prospectusDocumentBody.length > 0) {
              this.veipProspectusContent = prospectusDocumentBody[0].Body_Desc;            
            } else {
              this.veipProspectusContent = null;
            }
          }

          this.methodCount++;
          this.methodCounter();
        } catch(error) {
          this.errorProspectusDoc = true;
          this.methodCount++;
          this.methodCounter();
          let errorLocation = this.errorLocation.replace("methodName", "getveipProspectusDocument");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorProspectusDoc = true;
        this.methodCount++;
        this.methodCounter();
        let errorLocation = this.errorLocation.replace("methodName", "getveipProspectusDocument");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorProspectusDoc = true;
      this.methodCount++;
      this.methodCounter();
      let errorLocation = this.errorLocation.replace("methodName", "getveipProspectusDocument");
      error.message += errorLocation;
      throw error;
    }
  }
  
  /*****************************************************************************************
   * Method Name: getveipCountryAward                                                                 
   * Description: Gets the content for prospectus document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Iris Jane Manloloyo         Initial Draft
   * 2    08/02/2019           JPA                         Removed call to Tools and Reports data
   *****************************************************************************************/
  getveipCountryAward() {
    try {
      this.bodyData[5] = PageSection.veipAwardCountry;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let countryAwardData = JSON.parse(data.body);
  
          if(countryAwardData.data) { // length > {}
            let countryAwardBody = countryAwardData.data;
    
            if(countryAwardBody.length > 0) {
              this.veipCountryAwardContent = countryAwardBody[0].Body_Desc;
            } else {
              this.veipCountryAwardContent = null;
            }
          }

          this.methodCount++;
          this.methodCounter();
        } catch(error) {
          this.errorCountryAward = true;
          this.methodCount++;
          this.methodCounter();
          let errorLocation = this.errorLocation.replace("methodName", "getveipCountryAward");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCountryAward = true;
        this.methodCount++;
        this.methodCounter();
        let errorLocation = this.errorLocation.replace("methodName", "getveipCountryAward");
        error.error.message += errorLocation;
        throw error.error;
      });
   
    } catch(error) {
      this.errorCountryAward = true;
      this.methodCount++;
      this.methodCounter();
      let errorLocation = this.errorLocation.replace("methodName", "getveipCountryAward");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getToolsAndReports                                                                 
   * Description: Gets the content for tools and reports from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getveipToolsAndReports() {
    try {
      this.bodyData[5] = PageSection.veipAwardToolsAndReports;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let toolsAndReportsData = JSON.parse(data.body);
  
          if(toolsAndReportsData.data) { // length > {}
            let toolsAndReportsBody = toolsAndReportsData.data;
            
            if(toolsAndReportsBody.length > 0) {
              this.veipToolsandReportContent = toolsAndReportsBody;
            } else {
              this.veipToolsandReportContent = null;
            }
          }

          this.methodCount++;
          this.methodCounter();
          setTimeout(()=>{ this.inContentIcon() }, 500);
        } catch(error) {
          this.errorToolsAndReports = true;
          this.methodCount++;
          this.methodCounter();
          let errorLocation = this.errorLocation.replace("methodName", "getveipToolsAndReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorToolsAndReports = true;
        this.methodCount++;
        this.methodCounter();
        let errorLocation = this.errorLocation.replace("methodName", "getveipToolsAndReports");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorToolsAndReports = true;
      this.methodCount++;
      this.methodCounter();
      let errorLocation = this.errorLocation.replace("methodName", "getveipToolsAndReports");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(eventBody) {  
    try {
      let href: string = eventBody.target.href;
      let id: string = eventBody.target.id;
      let srcElement: HTMLImageElement = eventBody.srcElement;
      let target: string = eventBody.target.target;
      let reportId: object;
      
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContentService.validateFileType(event);
        } else if(href.indexOf("reportid") != -1) {
          id = href.substring(href.lastIndexOf("=") + 1);
          reportId = {'ReportTypeId': id};
          sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
          window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
          return false;
        } else if(href.indexOf("/Default.aspx") != -1){
          let innerTxt = eventBody.target.innerText;
          event.preventDefault();

          if(target == "_blank") {
            if(href.indexOf("Resources") != -1) {
              window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
              return false; // prevent execution of pop up blocker & default href
            } else if(href.indexOf("Reports") != -1) {
              window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
              return false; // prevent execution of pop up blocker & default href
            }      
          } else {
            if(href.indexOf("Resources") != -1){
              eventBody.target.href = this.globals.currentWebUrl + "/resourcespage";
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              eventBody.target.href = this.globals.currentWebUrl + "/reportspage";
            }
          }
          

        }else if(href.indexOf("#") != -1 && id != ""){
          if(target == "_blank") {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("_blank") : "";
          } else {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("_self"): "";
          }
        } else {
          if(href.indexOf('resourcespage') != -1) {
            window.open(this.globals.currentWebUrl + '/resourcespage', "_blank");
            return false; // prevents execution of pop up blocker & default href
          }
        }             
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : ""

    window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
  }

  /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
  collapseAccordion(index) {
    let className = document.getElementById("collapseCQIndex" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.veipCommonQuestionsContent.length; i++) {
        document.getElementById("collapseCQIndex" + i).className = "collapse";
      }
    }
   }

   
/*****************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/25/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
navToReportsPage(event) {
  try {
    let href = event.target.href;
    let id: number;
    let reportId: object;
  
    if(href != undefined) {
      if(href.indexOf("reportid") != -1) {
        id = href.substring(href.lastIndexOf("=") + 1);
       reportId = {'ReportTypeId': id};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
        window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
      }
      
      return false;
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToReportsPage");
    error.message += errorLocation;
    throw error;
  }
}

   /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Veipdetails") != -1 && this.user != null) {
                webPage = "Veipdetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
   
}
