
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HoldingProgramDetailsService {

  constructor(private globals: Globals, private httpClient: HttpClient) { }

  setProgramDetails(details) {
    sessionStorage.setItem(this.globals.programDetailsKey, JSON.stringify(details));
  }

  getESPPdetails(optNum, offerNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let esppDetailsUrl = this.globals.getAwardDetailsESPPAPI;

    esppDetailsUrl = esppDetailsUrl.replace("[param1]", optNum); //'417986'
    esppDetailsUrl = esppDetailsUrl.replace("[param2]", offerNum); //'44'

    try {
      const api = apiUrl + esppDetailsUrl;
      return this.httpClient.get(api).pipe(map(res => res), 
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getVEIPdetails(optNum, offerNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let veipDetailsUrl = this.globals.getAwardDetailsVEIPAPI;

    veipDetailsUrl = veipDetailsUrl.replace("[param1]", optNum);
    veipDetailsUrl = veipDetailsUrl.replace("[param2]", offerNum);

    try {
      const api = apiUrl + veipDetailsUrl;
      
      return this.httpClient.get(api).pipe(map(res => res), 
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getEsppSalesHistory(optNum, offerNum, period, dateFrom, dateTo) {
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let esppSalesHistUrl = this.globals.getSalesHistESPPAPI;

    esppSalesHistUrl = esppSalesHistUrl.replace("[param1]", optNum); //optNum - 199181
    esppSalesHistUrl = esppSalesHistUrl.replace("[param2]", offerNum); //offerNum - 11
    esppSalesHistUrl = esppSalesHistUrl.replace("[param3]", period);
    esppSalesHistUrl = esppSalesHistUrl.replace("[param4]", dateFrom);
    esppSalesHistUrl = esppSalesHistUrl.replace("[param5]", dateTo);
    esppSalesHistUrl = esppSalesHistUrl.replace(regEx, "");

    try {
      const api = apiUrl + esppSalesHistUrl;
      return this.httpClient.get(api).pipe(map(res => res), 
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getVeipSalesHistory(optNum, offerNum, period, dateFrom?, dateTo?) {
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let veipSalesHistUrl = this.globals.getSalesHistVEIPAPI;

    veipSalesHistUrl = veipSalesHistUrl.replace("[param1]", optNum); //optNum
    veipSalesHistUrl = veipSalesHistUrl.replace("[param2]", offerNum); //offerNum
    veipSalesHistUrl = veipSalesHistUrl.replace("[param3]", period);
    veipSalesHistUrl = veipSalesHistUrl.replace("[param4]", dateFrom);
    veipSalesHistUrl = veipSalesHistUrl.replace("[param5]", dateTo);
    veipSalesHistUrl = veipSalesHistUrl.replace(regEx, "");

    try {
      const api = apiUrl + veipSalesHistUrl;
      return this.httpClient.get(api).pipe(map(res => res), 
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getVeipCarryOverAPI(optNum, offerNum, purchasedate) {
    let apiUrl = this.globals.currentAPIUrl;
    let veipCarryOverUrl = this.globals.getCarryOverVEIPAPI;

    veipCarryOverUrl = veipCarryOverUrl.replace("[param1]", optNum); //optNum
    veipCarryOverUrl = veipCarryOverUrl.replace("[param2]", offerNum); //offerNum
    veipCarryOverUrl = veipCarryOverUrl.replace("[param3]", purchasedate); //purchasedate

    try {
      const api = apiUrl + veipCarryOverUrl;
      return this.httpClient.get(api).pipe(map(res => res), 
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 
}