import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef} from '@angular/core';
// import { MatSort, MatTableDataSource } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { catchError, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Globals } from '../globals';
import { Router, ActivatedRoute } from "@angular/router";
import { AdminPageServices } from './AdminPageServices';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'app-adminpage',
  templateUrl: './adminpage.component.html',
  styleUrls: ['./adminpage.component.css']
})
export class AdminpageComponent implements OnInit {
  @ViewChild(NgbDropdown)
  private dropdown: NgbDropdown;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  lastNmSearch$ =new Subject<string>();
  firstNmSearch$ = new Subject<string>();
  personnelIdSearch$ = new Subject<string>();

  dataSourceAdminList: any;
  // dataSourceAdminListTwenty: any;
  displayedColumnsAdmin: string[] = ['PersonnelNbr', 'LastNm', 'FirstNm', 'MiddleInitial', 'Level', 'Status', 'Location'];

  personnelNbr: any = "";
  firstName: any = "";
  lastName: any = "";
  prevFName: any = "";
  prevLName: any = "";
  joblvl: any = 0;
  levelSelected: any = "All";
  jobCd: any = '1';
  

  disableNames: boolean = false;
  disablePersonnelId: boolean = false;
  disableDropDown: boolean = false;
  isRefineSearch: boolean = false;
  // loading: boolean = false;
  isSeeMore: boolean = false;
  seeMoreBtn: boolean = false;
  noUsers: boolean = true;
  loading: boolean = false;
  isEntered: any = false;
  personnelNbrError: any = false;
  errorLocation: string = " + [Component: Admin, Method: methodName]";
  errorAdminPage: boolean = false;
  isAdmin: boolean = false;
  
  keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));

  constructor(private globals: Globals, private adminService: AdminPageServices, private router: Router, private errorNotificationService: ErrorNotificationService) { 
    let debounceLName = this.lastNmSearch$.pipe(debounceTime(2000));
    debounceLName.subscribe(changes => {
      this.isRefineSearch = false;
        this.adminSearchLastName(changes, 'input');
    });

    let debounceFName = this.firstNmSearch$.pipe(debounceTime(2000));
    debounceFName.subscribe(changes => {
      this.isRefineSearch = false;
        this.adminSearchFirstName(changes, 'input');
    });

    let debouncePersonnelId= this.personnelIdSearch$.pipe(debounceTime(2000));
    debouncePersonnelId.subscribe(changes => {
      this.isRefineSearch = false;
        this.adminSearchPersonnelNbr(changes);
    });
  }

  ngOnInit() {
    if(sessionStorage.getItem(this.globals.AdminCred) == null){
      if(this.user.IsAdmin == 'Y' || this.user.IsAdmin == 'y'){
        this.isAdmin = true;
        sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest));
        sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Admin"));
        this.checkErrorMessage();
      }
      else{
        this.isAdmin = false;
        this.router.navigate(['homepage']);
      }
    }
    else{
      this.isAdmin = true;
    }

  }

  closeDropdown(){
    this.dropdown.close();
  }

  

  adminSearchLastName(lname, checker){
    try {
      this.isSeeMore = false;

      // let shimata;
      // let test = shimata.replace("hey", "yey");

      if(checker == 'input'){
        if(this.prevLName != lname){
          if(lname.length > 2){

            this.noUsers = false;
            this.loading = true;
            this.seeMoreBtn = false;
            if(lname == "" && this.firstName == ""){
              this.loading = false;
              this.noUsers = true;
              this.seeMoreBtn = false;
            }
            else{
              this.adminService.searchAdminDetails(lname, this.firstName, this.jobCd, 'lastName').subscribe(results => {
                this.prevLName = lname;
                if(results[0].length>0){
                  if(results[0][0][""] == "Your search did not match any participants."){
                    this.loading = false;
                    this.noUsers = true;
                    this.isSeeMore = false;
                    this.errorAdminPage = false;
                  }
                  else{
                    this.dataSourceAdminList = results[0];
                    sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                    this.loading = false;
                    this.noUsers = false;
                    this.isSeeMore = false;
                    this.isEntered = false;
                    this.errorAdminPage = false;
                    if(this.dataSourceAdminList.length>20)
                      this.seeMoreBtn = true;
                  }
                }
                else{
                  this.loading = false;
                  this.noUsers = true;
                  this.isSeeMore = false;
                  this.errorAdminPage = false;
                }
              }, error => {
                this.errorAdminPage = true;
                this.loading = false;
                let errorLocation = this.errorLocation.replace("methodName", "adminSearchLastName");
                error.error.message += errorLocation;
                throw error.error;
              });
            }
          }
          else if(lname.length > 2 && this.firstName == ""){
            this.adminService.searchAdminDetails(lname, this.firstName, this.jobCd, 'lastName').subscribe(results => {
              this.prevLName = lname;
              if(results[0].length>0){
                if(results[0][0][""] == "Your search did not match any participants."){
                  this.loading = false;
                  this.noUsers = true;
                  this.isSeeMore = false;
                  this.errorAdminPage = false;
                }
                else{
                  this.dataSourceAdminList = results[0];
                  sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                  this.loading = false;
                  this.noUsers = false;
                  this.isSeeMore = false;
                  this.isEntered = false;
                  this.errorAdminPage = false;
                  if(this.dataSourceAdminList.length>20)
                    this.seeMoreBtn = true;
                }
              }
              else{
                this.loading = false;
                this.noUsers = true;
                this.isSeeMore = false;
                this.errorAdminPage = false;
              }
            }, error => {
              this.errorAdminPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "adminSearchLastName");
              error.error.message += errorLocation;
              throw error.error;
            });
          }
          else if(lname.length == 0 && this.firstName.length > 2){
            this.isSeeMore = false;
            this.noUsers = false;
            this.loading = true;
            this.seeMoreBtn = false;
            this.isRefineSearch = false;
            this.adminService.searchAdminDetails(lname, this.firstName, this.jobCd, 'lastName').subscribe(results => {
              this.prevLName = lname;
              if(results[0].length>0){
                if(results[0][0][""] == "Your search did not match any participants."){
                  this.loading = false;
                  this.noUsers = true;
                  this.isSeeMore = false;
                  this.errorAdminPage = false;
                }
                else{
                  this.dataSourceAdminList = results[0];
                  sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                  this.loading = false;
                  this.noUsers = false;
                  this.isSeeMore = false;
                  this.isEntered = false;
                  this.errorAdminPage = false;
                  if(this.dataSourceAdminList.length>20)
                    this.seeMoreBtn = true;
                }
              }
              else{
                this.loading = false;
                this.noUsers = true;
                this.isSeeMore = false;
                this.errorAdminPage = false;
              }
            }, error => {
              this.errorAdminPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "adminSearchLastName");
              error.error.message += errorLocation;
              throw error.error;
            });
          }
          else{
            if(lname == "" && this.firstName == ""){
              this.prevLName = lname;
              this.loading = false;
              this.noUsers = true;
              this.seeMoreBtn = false;
            }
          }
        }
      }
      else if(checker == 'select'){
        if(lname == "" && this.firstName == ""){
          this.loading = false;
          this.noUsers = true;
          this.seeMoreBtn = false;
        }
        else{
          this.adminService.searchAdminDetails(lname, this.firstName, this.jobCd, 'lastName').subscribe(results => {
            if(results[0].length>0){
              if(results[0][0][""] == "Your search did not match any participants."){
                this.loading = false;
                this.noUsers = true;
                this.isSeeMore = false;
                this.errorAdminPage = false;
              }
              else{
                this.dataSourceAdminList = results[0];
                sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                this.loading = false;
                this.noUsers = false;
                this.isSeeMore = false;
                this.isEntered = false;
                this.errorAdminPage = false;
                if(this.dataSourceAdminList.length>20)
                  this.seeMoreBtn = true;
              }
            }
            else{
              this.loading = false;
              this.noUsers = true;
              this.isSeeMore = false;
              this.errorAdminPage = false;
            }
          }, error => {
            this.errorAdminPage = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "adminSearchLastName");
            error.error.message += errorLocation;
            throw error.error;
          });
        }
      }
    } catch(error) {
      this.errorAdminPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "adminSearchLastName");
      error.message += errorLocation;
      throw error;
    }
  }

  adminSearchFirstName(fname, checker){
    try {
      if(checker == 'input'){
        if(this.prevFName != fname){
          if(fname.length > 2){
            this.noUsers = false;
            this.loading = true;
            this.seeMoreBtn = false;
            if(fname == "" && this.lastName == ""){
              this.loading = false;
              this.noUsers = true;
              this.seeMoreBtn = false;
            }
            else{
              this.adminService.searchAdminDetails(fname, this.lastName, this.jobCd, 'firstName').subscribe(results => {
                this.prevFName = fname;
                if(results[0].length>0){
                  if(results[0][0][""] == "Your search did not match any participants."){
                    this.loading = false;
                    this.noUsers = true;
                    this.isSeeMore = false;
                    this.errorAdminPage = false;
                  }
                  else{
                    this.dataSourceAdminList = results[0];
                    sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                    this.loading = false;
                    this.noUsers = false;
                    this.isSeeMore = false;
                    this.isEntered = false;
                    this.errorAdminPage = false;
                    if(this.dataSourceAdminList.length>20)
                      this.seeMoreBtn = true;
                  }
                }
                else{
                  this.loading = false;
                  this.noUsers = true;
                  this.isSeeMore = false;
                  this.errorAdminPage = false;
                }
              }, error => {
                this.errorAdminPage = true;
                this.loading = false;
                let errorLocation = this.errorLocation.replace("methodName", "adminSearchFirstName");
                error.error.message += errorLocation;
                throw error.error;
              });
            }
          }
          else if(fname.length > 2 && this.lastName == ""){
            this.adminService.searchAdminDetails(fname, this.lastName, this.jobCd, 'firstName').subscribe(results => {
              this.prevFName = fname;
              if(results[0].length>0){
                if(results[0][0][""] == "Your search did not match any participants."){
                  this.loading = false;
                  this.noUsers = true;
                  this.isSeeMore = false;
                  this.errorAdminPage = false;
                }
                else{
                  this.dataSourceAdminList = results[0];
                  sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                  this.loading = false;
                  this.noUsers = false;
                  this.isSeeMore = false;
                  this.isEntered = false;
                  this.errorAdminPage = false;
                  if(this.dataSourceAdminList.length>20)
                    this.seeMoreBtn = true;
                }
              }
              else{
                this.loading = false;
                this.noUsers = true;
                this.isSeeMore = false;
                this.errorAdminPage = false;
              }
            }, error => {
              this.errorAdminPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "adminSearchFirstName");
              error.error.message += errorLocation;
              throw error.error;
            });
          }
          else if(fname.length == 0 && this.lastName.length > 2){
            this.isSeeMore = false;
            this.noUsers = false;
            this.loading = true;
            this.seeMoreBtn = false;
            this.isRefineSearch = false;
            this.adminService.searchAdminDetails(fname, this.lastName, this.jobCd, 'firstName').subscribe(results => {
              this.prevFName = fname;
              if(results[0].length>0){
                if(results[0][0][""] == "Your search did not match any participants."){
                  this.loading = false;
                  this.noUsers = true;
                  this.isSeeMore = false;
                  this.errorAdminPage = false;
                }
                else{
                  this.dataSourceAdminList = results[0];
                  sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                  this.loading = false;
                  this.noUsers = false;
                  this.isSeeMore = false;
                  this.isEntered = false;
                  this.errorAdminPage = false;
                  if(this.dataSourceAdminList.length>20)
                    this.seeMoreBtn = true;
                }
              }
              else{
                this.loading = false;
                this.noUsers = true;
                this.isSeeMore = false;
                this.errorAdminPage = false;
              }
            }, error => {
              this.errorAdminPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "adminSearchFirstName");
              error.error.message += errorLocation;
              throw error.error;
            });
          }
          else{
            if(fname == "" && this.lastName == ""){
              this.prevFName = fname;
              this.loading = false;
              this.noUsers = true;
              this.seeMoreBtn = false;
            }
          }
        }
      }
      else if(checker == 'select'){
        this.adminService.searchAdminDetails(fname, this.lastName, this.jobCd, 'firstName').subscribe(results => {
          if(results[0].length>0){
            if(results[0][0][""] == "Your search did not match any participants."){
              this.loading = false;
              this.noUsers = true;
              this.isSeeMore = false;
              this.errorAdminPage = false;
            }
            else{
              this.dataSourceAdminList = results[0];
              sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
              this.loading = false;
              this.noUsers = false;
              this.isSeeMore = false;
              this.isEntered = false;
              this.errorAdminPage = false;
              if(this.dataSourceAdminList.length>20)
                this.seeMoreBtn = true;
            }
          }
          else{
            this.loading = false;
            this.noUsers = true;
            this.isSeeMore = false;
            this.errorAdminPage = false;
          }
        }, error => {
          this.errorAdminPage = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "adminSearchFirstName");
          error.error.message += errorLocation;
          throw error.error;
        });
      }
      else{
        if(fname == "" && this.lastName == ""){
          this.loading = false;
          this.noUsers = true;
          this.seeMoreBtn = false;
        }
      }
    } catch(error) {
      this.errorAdminPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "adminSearchFirstName");
      error.message += errorLocation;
      throw error;
    }
  }

  adminSearchPersonnelNbr(personnelNbr){
    try {
      let lastNameValue = $("#lastName").is(":focus")
      let firstNameValue = $("#firstName").is(":focus");
      if(lastNameValue != true && firstNameValue != true && this.disablePersonnelId == false){
        this.isSeeMore = false;
        if(personnelNbr.length < 8){
          this.personnelNbrError = true;
        }
        else{ 
          this.personnelNbrError = false;
          this.noUsers = false;
          this.loading = true;
          this.seeMoreBtn = false;
          if(personnelNbr == ""){
            this.loading = false;
            this.noUsers = true;
            this.seeMoreBtn = false;
          }
          else{
            this.adminService.searchAdminDetails(personnelNbr, '', '', 'personnelId').subscribe(results => {
              if(results[0].length>0){
                if(results[0][0][""] == "Your search did not match any participants."){
                  this.loading = false;
                  this.noUsers = true;
                  this.isSeeMore = false;
                  this.errorAdminPage = false;
                }
                else{
                  this.dataSourceAdminList = results[0];
                  sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                  this.loading = false;
                  this.noUsers = false;
                  this.isSeeMore = false;
                  this.isEntered = false;
                  this.errorAdminPage = false;
                  if(this.dataSourceAdminList.length>20)
                      this.seeMoreBtn = true;
                }
              }
              else{
                this.loading = false;
                this.noUsers = true;
                this.isSeeMore = false;
                this.errorAdminPage = false;
              }
            }, error => {
              this.errorAdminPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "adminSearchPersonnelNbr");
              error.error.message += errorLocation;
              throw error.error;
            });
          }
        }
      }
    } catch(error) {
      this.errorAdminPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "adminSearchPersonnelNbr");
      error.message += errorLocation;
      throw error;
    }
}

  adminSearchNameEnter(checker){ 
    try {
      this.isSeeMore = false;
      this.isRefineSearch = false;
      if(checker == 'lastName'){
        if(this.lastName.length < 3){
            this.noUsers = false;
            this.loading = true;
            this.seeMoreBtn = false;
          if(this.firstName == "" && this.lastName == ""){
            this.loading = false;
            this.noUsers = true;
          }
          else{
              this.adminService.searchAdminDetails(this.firstName, this.lastName, this.jobCd, 'firstName').subscribe(results => {
                if(results[0].length>0){
                  if(results[0][0][""] == "Your search did not match any participants."){
                    this.loading = false;
                    this.noUsers = true;
                    this.isSeeMore = false;
                    this.errorAdminPage = false;
                  }
                  else{
                    this.dataSourceAdminList = results[0];
                    sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                    this.loading = false;
                    this.noUsers = false;
                    this.isSeeMore = false;
                    this.isEntered = false;
                    this.errorAdminPage = false;
                    if(this.dataSourceAdminList.length>20)
                      this.seeMoreBtn = true;
                  }
                }
                else{
                  this.loading = false;
                  this.noUsers = true;
                  this.isSeeMore = false;
                  this.errorAdminPage = false;
                }
            }, error => {
              this.errorAdminPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "adminSearchNameEnter");
              error.error.message += errorLocation;
              throw error.error;
            });
          }
        }
      }
      else if(checker == 'firstName'){
        if(this.firstName.length < 3){
          this.noUsers = false;
          this.loading = true;
          this.seeMoreBtn = false;
          if(this.firstName == "" && this.lastName == ""){
            this.loading = false;
            this.noUsers = true;
          }
          else{
              this.adminService.searchAdminDetails(this.firstName, this.lastName, this.jobCd, 'firstName').subscribe(results => {
                if(results[0].length>0){
                  if(results[0][0][""] == "Your search did not match any participants."){
                    this.loading = false;
                    this.noUsers = true;
                    this.isSeeMore = false;
                    this.errorAdminPage = false;
                  }
                  else{
                    this.dataSourceAdminList = results[0];
                    sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
                    this.loading = false;
                    this.noUsers = false;
                    this.isSeeMore = false;
                    this.isEntered = false;
                    this.errorAdminPage = false;
                    if(this.dataSourceAdminList.length>20)
                      this.seeMoreBtn = true;
                  }
                }
                else{
                  this.loading = false;
                  this.noUsers = true;
                  this.isSeeMore = false;
                  this.errorAdminPage = false;
                }
              }, error => {
                this.errorAdminPage = true;
                this.loading = false;
                let errorLocation = this.errorLocation.replace("methodName", "adminSearchNameEnter");
                error.error.message += errorLocation;
                throw error.error;
              });
            }
          }
        }
    } catch(error) {
      this.errorAdminPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "adminSearchNameEnter");
      error.message += errorLocation;
      throw error;
    }
  }

//   adminSearchPersonnelNbrEnter(){
//     this.isEntered = true;
//     this.noUsers = false;
//       this.loading = true;
//       this.seeMoreBtn = false;
//     if(this.personnelNbr == ""){
//       this.loading = false;
//       this.noUsers = true;
//     }
//     else{
//       this.adminService.searchAdminDetails(this.personnelNbr, '', this.jobCd, 'personnelId').subscribe(results => {
//       this.dataSourceAdminList = results[0];
//       sessionStorage.setItem("Admin List", JSON.stringify(this.dataSourceAdminList));
//       this.loading = false;
//       this.noUsers = false;
//       this.isSeeMore = false;
//       if(this.dataSourceAdminList.length>20)
//           this.seeMoreBtn = true;
//     });
//   }
// }

  /**********************************************************************************
      Method Name: selectLevel
      Description: Called when level dropdown changes

      #     Date            Modified by             Description
      1     06/12/2019      Joshua Avecilla (JPA)   Added isRefineSearch = false to remove message when choosing from dropdown.

  **********************************************************************************/
  selectLevel(checker){
    try {
      this.isSeeMore = false;
      this.seeMoreBtn = false;
      this.isRefineSearch = false;
      if(checker == '1'){
        if(this.personnelNbr == "" && this.firstName == "" && this.lastName == ""){
          this.jobCd = '1';
          this.levelSelected = 'All';
        }
        else if(this.personnelNbr != "" && this.firstName == "" && this.lastName == ""){
          this.jobCd = '1';
          this.levelSelected = 'All';
          this.loading = true;
          this.adminSearchPersonnelNbr(this.personnelNbr);
        }
        else if(this.personnelNbr == "" && this.firstName != "" && this.lastName == ""){
          this.jobCd = '1';
          this.levelSelected = 'All';
          this.loading = true;
          this.adminSearchFirstName(this.firstName, 'select');
        }
        else if(this.personnelNbr == "" && this.firstName == "" && this.lastName != ""){
          this.jobCd = '1';
          this.levelSelected = 'All';
          this.loading = true;
          this.adminSearchLastName(this.lastName, 'select');
        }
        else if(this.personnelNbr == "" && this.firstName != "" && this.lastName != ""){
          this.jobCd = '1';
          this.levelSelected = 'All';
          this.loading = true;
          this.adminSearchLastName(this.lastName, 'select');
        }
      }
      else if(checker == '2'){
        if(this.personnelNbr == "" && this.firstName == "" && this.lastName == ""){
          this.jobCd = '2';
          this.levelSelected = 'Employee';
        }
        else if(this.personnelNbr != "" && this.firstName == "" && this.lastName == ""){
          this.jobCd = '2';
          this.levelSelected = 'Employee';
          this.loading = true;
          this.adminSearchPersonnelNbr(this.personnelNbr);
        }
        else if(this.personnelNbr == "" && this.firstName != "" && this.lastName == ""){
          this.jobCd = '2';
          this.levelSelected = 'Employee';
          this.loading = true;
          this.adminSearchFirstName(this.firstName, 'select');
        }
        else if(this.personnelNbr == "" && this.firstName == "" && this.lastName != ""){
          this.jobCd = '2';
          this.levelSelected = 'Employee';
          this.loading = true;
          this.adminSearchLastName(this.lastName, 'select');
        }
        else if(this.personnelNbr == "" && this.firstName != "" && this.lastName != ""){
          this.jobCd = '2';
          this.levelSelected = 'Employee';
          this.loading = true;
          this.adminSearchLastName(this.lastName, 'select');
        }
      }
      else if(checker == '3'){
        if(this.personnelNbr == "" && this.firstName == "" && this.lastName == ""){
          this.jobCd = '3';
          this.levelSelected = 'Senior Executive';
        }
        else if(this.personnelNbr != "" && this.firstName == "" && this.lastName == ""){
          this.jobCd = '3';
          this.levelSelected = 'Senior Executive';
          this.loading = true;
          this.adminSearchPersonnelNbr(this.personnelNbr);
        }
        else if(this.personnelNbr == "" && this.firstName != "" && this.lastName == ""){
          this.jobCd = '3';
          this.levelSelected = 'Senior Executive';
          this.loading = true;
          this.adminSearchFirstName(this.firstName, 'select');
        }
        else if(this.personnelNbr == "" && this.firstName == "" && this.lastName != ""){
          this.jobCd = '3';
          this.levelSelected = 'Senior Executive';
          this.loading = true;
          this.adminSearchLastName(this.lastName, 'select');
        }
        else if(this.personnelNbr == "" && this.firstName != "" && this.lastName != ""){
          this.jobCd = '3';
          this.levelSelected = 'Senior Executive';
          this.loading = true;
          this.adminSearchLastName(this.lastName, 'select');
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectLevel");
      error.message += errorLocation;
      throw error;
    }
  }

  disableFields(checker){
    try {
      if(checker == 'personnel'){
        this.disableNames = true;
        this.disableDropDown = true;
        this.disablePersonnelId = false;
        this.personnelNbrError = false;
        this.lastName = '';
        this.firstName = '';
      }
      else if(checker == 'names'){
        this.disableNames = false;
        this.disablePersonnelId = true;
        this.personnelNbrError = false;
        this.disableDropDown = false;
        this.personnelNbr = '';
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "disableFields");
      error.message += errorLocation;
      throw error;
    }
  }

  disableDropdown(){
    try {
      if(this.disableDropDown == true){
        return '#f6f6f6';
      }
        else{
          return '#ffffff';
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "disableDropdown");
      error.message += errorLocation;
      throw error;
    }
  }

  disableDropdownText(){
    try {
      if(this.disableDropDown == true){
        return 'rgba(95, 85, 85, 0.87)';
      }
        else{
          return '#000000';
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "disableDropdown");
      error.message += errorLocation;
      throw error;
    }
  }

  disablePersonnel(){
    try {
      if(this.disablePersonnelId == true){
        return '#f6f6f6';
      }
        else{
          return '#ffffff';
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "disablePersonnel");
      error.message += errorLocation;
      throw error;
    }
  }

  errorPersonnel(){
    try {
      if(this.personnelNbrError == true){
        return '#c80400';
      }
      else{
        return '#ebeef3';
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "errorPersonnel");
      error.message += errorLocation;
      throw error;
    }
  }

  disableUserNames(){
    try {
      if(this.disableNames == true){
        return '#f6f6f6';
      }
      else{
        return '#ffffff';
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "disableUserNames");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: changeView
      Description: Get Options program details from session 

      #     Date            Modified by             Description
      1     02/26/19        Katrina Narag(KMN)      Remove item for Key exec value
      2     03/05/19        Jayson Sevilla(JSS)     Add condition if the admin used the same user
  **********************************************************************************/
 middleInitial = this.user.MiddleInitial;
 emplName = this.firstName +  this.lastName;

  changeView(row){
    try {
      sessionStorage.setItem("Changed User", JSON.stringify(true));

      // sessionStorage.clear();
      if (this.user.PersonnelNumber == this.personnelNbr || this.user.FirstName + this.user.LastName == this.emplName ){
      sessionStorage.removeItem("RSU Data");
      sessionStorage.removeItem("Options Data");
      sessionStorage.removeItem("Founder Share Data");
      sessionStorage.removeItem("Purchase Program Data");
  
      sessionStorage.removeItem("showPurchData");
      sessionStorage.removeItem("showOptionsData");
      sessionStorage.removeItem("showRSUData");
      sessionStorage.removeItem("showFounderData");
      sessionStorage.removeItem("Key Exec Homepage Value");         
      sessionStorage.removeItem("Key Exec Holding Summary Value"); 
      sessionStorage.removeItem("Key Exec Component Indicator"); 
      sessionStorage.removeItem("Key Exec Rsu Details Value");
      sessionStorage.removeItem("isOverride");
      sessionStorage.removeItem("profilePic");
      sessionStorage.removeItem("Key Exec Toggle Data");
      sessionStorage.removeItem("ESPPActionItemCardCacheData");
      sessionStorage.removeItem("VEIPActionItemCardCacheData");
      sessionStorage.removeItem("GrantActionItemCardCacheData");
      sessionStorage.removeItem("displayCardDataCached");
      sessionStorage.removeItem("Action RSU Data");
      sessionStorage.removeItem("Action Options Data");
      sessionStorage.removeItem("CurrencyListCacheData");
      sessionStorage.removeItem("ExchangeRateDataCached");
      sessionStorage.removeItem("homepage topquestions");
      sessionStorage.removeItem(this.globals.highlightsKey);
      sessionStorage.removeItem(this.globals.isHighlightsCached);
      sessionStorage.removeItem("Exchange Rate List");
      sessionStorage.removeItem('RSUDivSumCachedData');
      sessionStorage.removeItem('MarketQuoteGraph 5d');
      sessionStorage.removeItem('MarketQuoteGraph 1m');
      sessionStorage.removeItem('MarketQuoteGraph 1y');
      sessionStorage.removeItem('MarketQuoteGraph 5y');
      sessionStorage.removeItem('get exchange rate');
      sessionStorage.removeItem('performanceStorage');
      sessionStorage.removeItem('hasPEAP');
      sessionStorage.removeItem("hasAlertBell");
      sessionStorage.removeItem("alertBellStorage");
      sessionStorage.removeItem("veipAwardDetailsStoredData");
      sessionStorage.removeItem("RSU Details Data"); //remove session for rsu award details
      sessionStorage.removeItem("Header RSU Details"); //remove session for rsu award details
      sessionStorage.removeItem("getRSUAgreementInfo");
      
      
      this.router.navigate(['homepage']);
      } else{
        sessionStorage.setItem(this.globals.sessionOptNum, JSON.stringify(row.OptNum));
  
  
        sessionStorage.getItem(this.globals.AdminCred)? "":sessionStorage.setItem(this.globals.AdminCred, JSON.stringify(this.user));
  
      sessionStorage.setItem('adminPageDataStore', 'true');
  
      sessionStorage.removeItem("RSU Data");
      sessionStorage.removeItem("Options Data");
      sessionStorage.removeItem("Founder Share Data");
      sessionStorage.removeItem("Purchase Program Data");
  
      sessionStorage.removeItem("showPurchData");
      sessionStorage.removeItem("showOptionsData");
      sessionStorage.removeItem("showRSUData");
      sessionStorage.removeItem("showFounderData");
      sessionStorage.removeItem("Key Exec Homepage Value");         //KMN, 1
      sessionStorage.removeItem("Key Exec Holding Summary Value");  //KMN, 1
      sessionStorage.removeItem("Key Exec Component Indicator"); 
      sessionStorage.removeItem("Key Exec Rsu Details Value");
      sessionStorage.removeItem("isOverride");
      sessionStorage.removeItem(this.globals.highlightsKey);
      sessionStorage.removeItem(this.globals.highlightsPage);
      sessionStorage.removeItem("profilePic");
      sessionStorage.removeItem("Key Exec Toggle Data");
      sessionStorage.removeItem("ESPPActionItemCardCacheData");
      sessionStorage.removeItem("VEIPActionItemCardCacheData");
      sessionStorage.removeItem("GrantActionItemCardCacheData");
      sessionStorage.removeItem("displayCardDataCached");
      sessionStorage.removeItem("Action RSU Data");
      sessionStorage.removeItem("Action Options Data");
      sessionStorage.removeItem("CurrencyListCacheData");
      sessionStorage.removeItem("ExchangeRateDataCached");
      sessionStorage.removeItem("homepage topquestions");
      sessionStorage.removeItem(this.globals.highlightsKey);
      sessionStorage.removeItem(this.globals.isHighlightsCached);
      sessionStorage.removeItem("Exchange Rate List");
      sessionStorage.removeItem('RSUDivSumCachedData');
      sessionStorage.removeItem('MarketQuoteGraph 5d');
      sessionStorage.removeItem('MarketQuoteGraph 1m');
      sessionStorage.removeItem('MarketQuoteGraph 1y');
      sessionStorage.removeItem('MarketQuoteGraph 5y');
      sessionStorage.removeItem('get exchange rate');
      sessionStorage.removeItem('performanceStorage');
      sessionStorage.removeItem('hasPEAP');
      sessionStorage.removeItem("hasAlertBell");
      sessionStorage.removeItem("alertBellStorage");
      sessionStorage.removeItem("veipAwardDetailsStoredData");
      sessionStorage.removeItem("RSU Details Data"); //remove session for rsu award details
      sessionStorage.removeItem("Header RSU Details"); //remove session for rsu award details
      sessionStorage.setItem("currentVisitedAward", JSON.stringify(null));
      sessionStorage.setItem("Program Component Indicator", JSON.stringify(null));
      sessionStorage.removeItem("getRSUAgreementInfo");

      this.router.navigate(['homepage']);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "changeView");
      error.message += errorLocation;
      throw error;
    }
  }

  adminLoadChecker(){
    
    this.loading = true
  }

  seeMoreMethod(){
    this.isSeeMore = true;
    this.seeMoreBtn = false;
    this.isRefineSearch = true;
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Adminpage") != -1 && this.user != null) {
                webPage = "Adminpage";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

}
