import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Globals } from '../../../../../globals';
import { PartnerselectedComponent } from '../partnerselected.component';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';


@Component({
  selector: 'app-memberfirm',
  templateUrl: './memberfirm.component.html',
  styleUrls: ['./memberfirm.component.css']
})

export class MemberfirmComponent implements OnInit {
  displayedColumns: string[] = ['Member_Firm_Type_Description', 'Member_Firm_Country', 'Start_Date', 'End_Date'];
  optNum: any;
  dataSource: any;
  documentId: number;
  editPartner: boolean = true;
  loading: boolean = true;
  isError: boolean = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private global: Globals,
    private partnerSelected: PartnerselectedComponent,
    private access: SemaccessService) { }

  ngOnInit() {
    this.optNum = sessionStorage.getItem('optNum');
    this.documentId = parseInt(sessionStorage.getItem(this.global.documentID));
    this.getAccess();
    this.partnerSelected.loadingEdit = true;
  }

  /**********************************************************************************
  * Method Name: getAccess                                                                 
  * Description: Retrieves access from a reasuable service 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         5/19/2020            Jikho Enrique                    Initial draft 
  ***********************************************************************************/
  async getAccess() {
    try {
      await this.access.getSemAccess("memberfirm").then();
      if (this.access.role != false) {
        this.partnerSelected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerSelected.editHistory(this.documentId, this.optNum);
          this.partnerSelected.partnerSelectedInfo(this.optNum);
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            this.getMembershipFirmData();
          } else {
            this.router.navigate(['equityadmin/eamain'])
          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } else {
        this.router.navigate(['equityadmin/eamain']);
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccess', 'memberfirm');
      throw error;
    }
  }

  /**********************************************************************************
* Method Name: getMembershipFirmData                                                                 
* Description: Retrieves the Membership Data         
*                                                                                          
* #         Date                 Modified by                      Description                             
* 1         4/29/2020            Jikho Enrique                    Initial draft 
***********************************************************************************/
  getMembershipFirmData() {
    let getMembershipFirm = {
      "functionName": "getFirmDetails",
      "selected_optnum": this.optNum
    };
    this.apiService.postData(this.global.api_URL_pdsDocument, getMembershipFirm).subscribe(data => {
      try {
        if (data != null) {
          let body = data.body;
          this.dataSource = new MatTableDataSource(body['data'][0]);
          this.partnerSelected.loadingEdit = false;
          this.loading = false;
          this.global.searchLoading = false;
        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getMembershipFirmData', 'memberfirm');
        throw error;
      }
    }, error => {
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getMembershipFirmData', 'memberfirm');
      throw error;
    });
  }
}
