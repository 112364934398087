import { Component, OnInit, AfterContentInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Globals } from '../globals';
import { AppComponent } from '../app.component';
import * as d3 from 'd3';
import * as moment from 'moment-timezone';
import { Chart } from 'chart.js';
import { DatePipe } from '@angular/common';
// import * as momentjs from 'moment-timezone';

import { UserService } from '../services/UserService';
import { HighlightsService } from '../highlightspage/HighlightsService';
// import { AuthService } from '../services/auth/auth.service';
import { StaticContentService } from '../services/StaticContentService';
import { UserInfoDetailsResolve } from '../services/UserInfoDetails.resolve';
import { MarketQuoteService } from '../services/MarketQuoteService';
import { HoldingsSummaryService } from '../holdingsummarypage/HoldingSummaryService';
import { GrantGroup, RedemptionMethod, GrantTransactionType, CurrencySymbol, myHoldingsPages } from '../services/enumerations/enumerations';

import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { EspptransactionService } from '../enrollmentwithdraw-espp/EsppTransactionService';
import { KeyExecToggleService } from '../services/KeyExecToggleService'
import { VeiptransactionService } from '../enrollmentwithdraw-veip/VeipTransactionService';
// import { Options } from 'ng5-slider';
import { Options } from '@angular-slider/ngx-slider'
import { MultipleGrantsService } from '../multiplegrants/MultipleGrantsService';
import { ReportsService } from '../reportspage/ReportsService';
import { SharePriceHistoryPageService } from '../reportspage/reports-sharepricehistorypage/SharePriceHistoryPageService';
import { PageSection } from '../services/enumerations/enumerations';
import { tScript } from '../services/enumerations/enumerations';
// import { isNull, isNumber } from 'util';
import { TooltipPosition } from '@angular/material/tooltip';
import { format } from 'path';
import { PerformanceawardService } from '../performanceawardpage/PerformanceAwardService';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';


let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
  providers: [MarketQuoteService, HighlightsService, Globals]

})
export class HomepageComponent implements AfterContentInit {


  public isVisible_profile: boolean = false;
  public isVisible_contact: boolean = false;
  public noHighlightLabel: string = "No Highlights";
  public appNavHeaderVisible: any;
  public appFooterVisible: any;
  // public defaultCurrency: Number; //this is a trail variable. this line of code initializes the value for the default currency variable [KPA]
  // public currentCurrency: string; //can be deleted

  // OptionsGraph = true;
  datePipe = new DatePipe('en-US');
  veipActionItemDetails = [];
  sessionCachedHighlights: boolean = false;
  sessionCachedUserInfo: boolean = false;
  hasHighlight: boolean = false;
  userHighlights: any;
  optNum: number;
  esoEid: string;
  tokensample: any;
  token: any;
  userprofileInfo: any;
  loading = true;
  marketQuoteLoad = false;
  // marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem(this.globals.marketQuoteDatafromExtAPI)); //this line of code displays all the data inside the API [KPA]
  // currentSharePrice=this.marketQuoteCurrentPrice.latestPrice; //this line of code only gets the data for the latestPrice and stores it in variable currentSharePrice [KPA]
  overrideSharePrice: any;
  marketQuoteData: any;
  marketQuote: any;
  marketQuotetest: any;
  totalPurchaseSharesOutStanding: any;
  totalPurchaseAvailableToSell: any;
  totalOptionsSharesOutstanding: any;
  totalOptionsAvailableToSell: any;
  totalUnreleasedUnvested: any;
  marketQuoteCurrentPrice: any;
  marketQuoteHistoricalData: any;
  marketQuotePeriodSelected: any = '1 year';

  totalRSUSharesOutStanding: any;
 totalRSUAvailableToSell: any;
  totalRSUUnreleasedUnvested: any;
  rsuOutstandingValue: any = 0;
  rsuUnreleasedOutstanding: any = 0;
  rsuAvailableToSellValue: any = 0;
  rsuReleasedExercisableQty: any = 0;
  totalrsuReleasedExercisableQty: any = 0;

  user: any;
  public userR: any;
  // user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  // currencyCd: any = this.user.CurrencyCd;//.replace(/\s/g, "");
  currencyCdSymbol = '';
  completeCurrencyList: any;
  defaultCurrencyCdSybmol: any;

  exchangeRate: any = 0;
  exchangeRateList: any;
  defaultValue: any;
  tempRateHolder: any;
  tempSharePriceHolder: any;

  valueSharesOustanding: any = 0;
  valueAvailableToSell: any = 0;

  //PSU
  psuKeyExecInd: boolean = false;

  optionsOutstandingValue: any = 0;
  optionsAvailableToSellValue: any = 0;
  optionsUnreleasedUnvested: any = 0;
  optionsVested: any = 0;
  totalOptionsVested: any = 0;
  OptionsVested: any = 0;
  totalOptionsSharesOutstandingGraph: any = 0;
  optionsTotalSharesGraph: any = 0;

  totalFounderSharesOutstanding: any = 0;
  totalFounderAvailableToSell: any = 0;
  totalFounderAvailToSellValue: any = 0;
  founderValue: any = 0; // value from market quote: avghighlow or share price
  founderSharesOutstandingValue: any = 0;
  founderAvailableToSellValue: any = 0;
  founderUnvested: any = 0;
  founderReleasedExercisableQty: any = 0;
  totalPurchaseUnReleased: any = 0;

  showFounderData: boolean = false;
  showPurchData: boolean = false;
  showOptionsData: boolean = false;
  showRSUData: boolean = false;
  hasAwards: boolean = false;
  hasGraph: boolean = false;
  hasNoShareHistory: boolean = false; //MAM
  hasShares: boolean = true; //MAM

  currencyValue: string;
  currencyCodeList: any;
  currencyCd: any;

  // Column 1 cards
  displayEOR: boolean = false;
  displayPerfRewards: boolean = false;
  displayKeyExecCard: boolean = false;
  overrideCardHeight: number = 100;
  eorTotalShares: any;
  eorShares: any;
  eorShortFall: number;
  eorExplain: any;
  peapCard: boolean;
  parentContainerHeight: number;
  eorHeight: number;
  keyExecHeight: number;
  peapHeight: number;

  errorDisplayCard: boolean;
  errorDivSummaryCard: boolean;
  errorMarketQuoteCard: boolean;
  errorTopQuestionCard: boolean;
  errorActionItemCard: boolean;
  errorGrantAlert: boolean;
  errorEsppActionItem: boolean;
  errorVeipActionItem: boolean;
  errorGraph: boolean;
  errorESPP: boolean;
  errorVEIP: boolean;
  errorRSU: boolean;
  errorFounder: boolean;
  errorOption: boolean;

  //column 2 cards
  divSummaryInd: number;
  esppCurrentOPInd: number;
  esppenrollmentInd: number;
  veipCurrentOPInd: number;
  veipDisplay: number;
  veipEnrollmentInd: number;
  cardinfo: any;

  displayDivSummary: boolean = false;
  displayAction: boolean = false;
  displayMarketQuote: boolean = false;
  actionItemsHeight: number;
  //divSummHeight: number;
  marketQuoteHeight: number;
  actionItemspadBot: number;
  highlightsHeight: number;
  faqHeight: number;
  brokerHeight: number;

  purchaseAvailabletoSellESPP: any = 0;
  totalpurchaseAvailabletoSellESPP: any = 0;
  purchaseAvailabletoSellVEIP: any = 0;
  totalpurchaseAvailabletoSellVEIP: any = 0;
  holdingType: any = 0;
  purchaseGraph: any = 0;
  rsuUnreleaseExercisable: any = 0;
  totalrsuUnreleaseExercisable: number = 0;

  totalPurchaseOutStandingSharesESPP: any = 0;
  totalPurchaseOutStandingSharesVEIP: any = 0;
  valuePurchaseOutStandingSharesESPP: any = 0;
  valuePurchaseOutStandingSharesVEIP: any = 0;

  founderGraphHeight: number;
  rsuGraphHeight: number;
  purchaseGraphHeight: number;
  optionsGraphHeight: number;

  founderVestedWidth: number;
  founderUnvestedWidth: number;
  rsuVestedWidth: number;
  rsuUnvestedWidth: number;
  purchaseEsppWidth: number;
  purchaseVeipWidth: number;
  optionShares: boolean;
  purchasewidth: any;
  founderwidthone: number;
  founderwidthtwo: number;
  rsuVestedFullWidth: number;
  rsuUnvestedFullWidth: number;
  isGraphicalMethodCalled: number = 0;
  awardsCounter: number = 1;

  displayEsppPartialGraph: boolean = false;
  displayVeipPartialGraph: boolean = false;
  displayEsppFullGraph: boolean = false;
  displayVeipFullGraph: boolean = false;
  displayNoPurchaseGraph: boolean = false;
  displayFndrVestedPartialGraph: boolean = false;
  displayFndrUnvestedPartialGraph: boolean = false;
  displayFndrVestedFullGraph: boolean = false;
  displayFndrUnvestedFullGraph: boolean = false;
  displayRsuVestedPartialGraph: boolean = false;
  displayRsuUnvestedPartialGraph: boolean = false;
  displayRsuVestedFullGraph: boolean = false;
  displayRsuUnvestedFullGraph: boolean = false;
  belowInd: boolean = false;
  marketQuoteFounders: any;
  hideAwardsInd: number = 0;
  overrideExchangeRate: any;

  perfGrantValueExceed: any;
  perfGrantValueExceedStatic: any;
  peapSlider: any = 100;
  localCurrencySymbolPEAP: any;
  browser: any;
  desktop: boolean = true;
  mobile: boolean = false;

  //market quote container
  currentprice: any;
  changeprice: any;
  volumeprice: any;
  highprice: number;
  lowprice: number;
  avghighlow: number;
  isChangeHigh: boolean = true;
  currentDate: string;
  ifDash: boolean = false;
  marketDate: Date;
  errorGetHighlightsData: boolean = false;

  //Key Exec Toogle
  planNum: any[] = [];
  keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));
  hideEnabled: boolean = false;
  yearCode: any[] = [];
  methodCounter: number = 0;
  setMaxValue: boolean = false;
  setTargetValue: boolean = false;
  setTrendValue: boolean = false;
  sharesTrendVestTotal: number = 0;
  unreleasedTrendVestTotal: number = 0;
  unreleasedTargetVestTotal: number = 0;
  sharesOutstanding: number = 0;
  unreleasedUnvested: number = 0;
  newRSUTarget: any[] = [];
  displaykeyExecToogle: boolean = true;
  displaykeyExecPerfLink: boolean = true;
  targetUnvested: number = 0;
  //sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest))
  keyExecFAQContent: any;
  public SMDChecker: boolean = false;
  public keyExecOutstandingChecker: boolean = false;
  public keyExecLink: string = '<div><a href=\"SMD key executive grant FAQ.pdf\" target=\"_blank\" rel=\"noopener\">SMD Key Executive Award FAQ</a></div>';


  //Dividend Summary Data
  divSumRSUQty: number;
  divSumRSUAmt: number;
  marketExternalData: any;


  //Dan In-App Variables
  inAppNotif: any;

  //ACTION ITEMS
  actionitemtext: string;
  esppenrolldetail: any;
  esppOfferNum: any;

  // showChangeContri: boolean = false;
  showTopActions: boolean = true;
  ShowESPPEnroll: boolean = false;
  ShowESPPEnrollClosedCurrOff: boolean = false;
  ShowESPPEnrollClosedNextOff: boolean = false;
  ShowESPPWithdraw: boolean = false;
  ShowESPPWithdrawn: boolean = false;
  ShowESPPWithdrawnFP: boolean = false;
  ShowVEIPEnroll: boolean = false;
  ShowVEIPWithdraw: boolean = false;
  ShowVEIPWithdrawn: boolean = false;
  ShowVEIPChangeContri: boolean = false;
  ShowESPPChangeContri: boolean = false;
  topGrantAlert: any;
  esppenrollheader: any;
  esppchangeheader: any;
  esppclosedheadercurroff: any; //RBA,232985
  esppclosedheadernextoff: any;
  esppwithdrawheader: any; 
  esppwithdrawnheader: any;
  esppwithdrawnheaderFP: any;
  esppenrollSubmessage: any;
  esppclosedSubmessagecurroff: any; //RBA,232985
  esppclosedSubmessagenextoff: any;
  esppChangeContriSubmessage: any;
  esppWithdrawSubmessage: any;
  esppWithdrawnSubmessage: any;
  esppWithdrawnSubmessageFP: any;
  veipenrollheader: any;
  veipenrollSubmessage: any;
  veipchangeheader: any;
  veipChangeContriSubmessage: any;
  veipwithdrawheader: any;
  veipWithdrawSubmessage: any;
  veipwithdrawnheader: any;
  veipWithdrawnSubmessage: any;
  topGrantHeader: any;
  topGrantSubmessage: any;

  isPassDetailsCalled: number = 0;
  totalRSUReleasedVested: number;
  rsuReleasedVested: number;
  totalRSUSharesReleasing: number;
  totalRSUForfeited: number;
  rsuForfeited: number;
  rsuSharesReleasing: number;
  grantAlertDetails: any[] = [];
  grantChecker: string;

  interval: any;
  marketTime: any;

  hasActionItems: boolean = false;
  hasActionItemsESPP: boolean = false;
  hasActionItemsVEIP: boolean = false;
  ShowGrantAlert: boolean = false;
  ShowMultipleGrantAlert: boolean = false;

  totalAvailableToSellValue: number = 0;
  totalSharesOfAvailableToSell: number = 0;
  totalOutstandingSharesValue: number = 0;
  totalSharesOfOutstandingShares: number = 0;
  origTotalRsuAvailableToSell: number = 0;

  doughnutchart: any;

  hasFounderLegend: boolean = false;
  hasRSULegend: boolean = false;
  hasVEIPLegend: boolean = false;
  hasESPPLegend: boolean = false;
  hasOptionLegend: boolean = false;

  bodyData: any[];
  showTaxElect: boolean = false;
  showTaxElectView: boolean = false;
  taxElectHeader: string;
  taxElectSubMesssage: string;
  currencyChanged: boolean = false;

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];

  optNumForPeap: any;
  transfereeTypeCdInd: boolean;


  // Options for PEAP Slider -JSS, 03/21/19
  sliderValue: number = 100;
  options: Options = {
    floor: 0,
    ceil: 100,
    hideLimitLabels: true,
    showSelectionBar: true,
    hidePointerLabels: true,
    getSelectionBarColor: (value: number): string => { return '#a101ff' },
    getPointerColor: (value: number): string => { return '#460173' },
    // translate: function(value) {
    //   return value + '%';}
  };
  multipleGrantList: any;
  grantNum: any;
  HorizontalBar: any;

  topQuestions: any;
  end: any;
  start: any;

  veipOfferingDetails: any = null;
  esppOfferingDetails: any = null;
  esppTaxElectDetails: any = null;

  errorLocation: string = " + [Component: Homepage, Method: methodName]";
  esppEligible: boolean;
  veipEligible: boolean;
  actionItemCardStatus: any[] = [];
  actionitemcount: any = 0;
  topQuestionsExecuted: boolean = false;

  //ToolTip Messages
  keyexetogMax: string = tScript.ketMaximum;
  keyexetogTar: string = tScript.ketTarget;
  keyexetogTre: string = tScript.ketTrend;
  deor: string = tScript.dashboard_eor;
  ocsp: string = tScript.dashboard_ocsp;
  lc: string = tScript.localCurrency;
  cer: string = tScript.customExchangeRate;
  doughnutchartMobile: any;

  errorTopQuestions: boolean;

  // GDPR
  privacyPolicyUserAgreement: boolean;
  isProceedButtonDisabled: boolean = true;
  userInfoStore: any;
  insertGDPRAgreement: any[] = [];
  // gdprAdminVerify: number = 0;
  compensationValue: any;
  performanceGrantData: any;
  errorPeapMethod: boolean;
  dataPerformance: any;
  exceedsValue: number;

  // piwik
  // _paq;
  // esoenterpriseID = JSON.parse(sessionStorage.getItem("piwikEid"));
  // csusername = this.esoenterpriseID;
  // csusername = 'dummy_eid';

  //PEAP
  aboveValue: any;
  veryTopValue: any;
  standardValue: any;
  doubleValue: any;
  isExceed: boolean = false;
  isAbove: boolean = false;
  isStandardDouble: boolean = false;

  showVeipEnrollEligibility: boolean = true;
  ShowVEIPChangeContriEligibility: boolean = true;
  ShowVEIPWithdrawEligibility: boolean = true;
  ShowVEIPWithdrawnEligibility: boolean = true;
  showEsppEnrollEligibility: boolean = true;
  ShowEsppChangeContriEligibility: boolean = true;
  ShowEsppWithdrawEligibility: boolean = true;
  ShowEsppWithdrawnEligibility: boolean = true;

  userTransferee: any;
  esppenrollOffernum: any;
  taxElectOffernum: any;
  esppWithdrawOffernum: any;
  esppClosedCurrOffernum: any;
  esppClosedNextOffernum: any;
  esppChangeOffernum: any;
  esppWithdrawnOffernum: any;
  esppWithdrawnOffernumFP: any;
  showViewBtn: boolean = false;
  UBSlink: any;
  MSlink: any;
  envSetting: any;
  nonProdModal: boolean;
  prodModal: boolean;

  //MJS 05/21/2024 617951- myHoldings Dashboard Enhancement: Added for Reports Link"
  hasEspp: any;
  hasVeip: any;
  isEnrolldCurrEsppOff: any = 0;
  isEnrolldCurrVeipOff: any = 0;
  hasFounder: any;
  hasRsu: any;
  hasOptions: any;
  veipAllOffering: any;
  userReports: any;
  errorReportsPage: boolean = false;
  reportId: any = JSON.parse(sessionStorage.getItem("REPORT ID"));
  reportsHeight: any;
  reportType: string


  constructor(private sanitizer:DomSanitizer, private app: AppComponent, private marketQuoteService: MarketQuoteService, private route: ActivatedRoute, private router: Router, private userService: UserService, private highlightsService: HighlightsService, private globals: Globals, private userResolve: UserInfoDetailsResolve, private holdingsummaryservice: HoldingsSummaryService, private elRef: ElementRef, private staticContent: StaticContentService, private errorNotificationService: ErrorNotificationService, private espptransactionService: EspptransactionService, private keyExecToggleService: KeyExecToggleService, private veiptransactionService: VeiptransactionService, private globalVariables: Globals, private multiplegrantsservice: MultipleGrantsService, private reportsService: ReportsService, private sharePriceService: SharePriceHistoryPageService, private performanceawardService: PerformanceawardService) {
    this.changeViewport();
    let currentWebUrl = this.globals.currentWebUrl;
    history.pushState('', '', currentWebUrl + '/homepage');
    var x = document.cookie;

    x.split(";").forEach(element => {
      if (element.includes("msal")) {
        document.cookie = element + ";secure";
      }
    });
  }



  async ngOnInit() {
 
    // BACK BUTTON BUG - START
    sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(null));
    sessionStorage.setItem("Back Url", JSON.stringify(null));
    sessionStorage.setItem("Details Storage", JSON.stringify(null));
    // BACK BUTTON BUG - END
    //MAM TRY
    sessionStorage.setItem("First Time Login","False");
    // if(performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    //   sessionStorage.setItem("Piwik Counter", JSON.stringify(null));
    //   this.piwik();
    // } else {
    //   this.piwik();
    // }
    // console.log("ngOnInit: Before Error Message");
    this.changeViewport();
    this.checkErrorMessage();
    
    // console.log("ngOnInit: After Error Message");

    let temp = GrantGroup;
    let getDatetimeFromSession = sessionStorage.getItem("datetimeonLoad");
    if (getDatetimeFromSession != null) {

    }

    this.userprofileInfo = this.route.snapshot.data['userDetails'];
    if (this.userprofileInfo != null) {
      sessionStorage.setItem("Local User Info Storage", JSON.stringify(this.userprofileInfo));
    }
    else {
      this.userprofileInfo = JSON.parse(sessionStorage.getItem("Local User Info Storage"));
    }
    this.userInfoStore = this.userprofileInfo;

    await this.getTotalPSUsharesbyOptNum(this.userprofileInfo.OptNum);

    this.keyExecVestChecker();
    this.getCodeDetails();
    // this.keyExecFAQ();

    $("#thePageFooterOld").css("display", "none");
    $("#thePageFooter").css("display", "block");
    sessionStorage.setItem("footerSetting", "new")
  }

  //MJS 05/21/2024 617951- myHoldings Dashboard Enhancement: Added for Reports Link"
  getUserReportsAccess() {
    try {
      this.reportsService.getHasESPPData(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try{
          this.hasEspp = data.value;
          this.reportsService.getHasVEIPData(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
            try{
              this.hasVeip = data.value;
              
              this.reportsService.getHasGrantData(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
                try{
                this.hasFounder = data.hasFounder;
                this.hasRsu = data.hasRSU;
                this.hasOptions = data.hasOptions;

                this.getUserReports();
                } catch(error) {
                  this.errorReportsPage = true;
                  this.loading = false;
                  let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
                  error.message = error.message + errorLocation;
                  throw error;
                }
              }, error => {
                this.errorReportsPage = true;
                this.loading = false;
                let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
                error.error.message += errorLocation;
                throw error.error;
              });
            } catch(error) {
              this.errorReportsPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
              error.message = error.message + errorLocation;
              throw error;
            }
          }, error => {
            this.errorReportsPage = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
            error.error.message += errorLocation;
            throw error.error;
          });
        } catch(error) {
          this.errorReportsPage = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorReportsPage = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
      error.message += errorLocation;
      throw error;
    }
  }

  getUserReports(){
      this.reportsService.getUserReports(this.hasEspp, this.hasVeip, this.isEnrolldCurrEsppOff, this.isEnrolldCurrVeipOff, this.hasFounder, this.hasRsu, this.hasOptions).pipe(map(res => res)).subscribe(data => {
        this.userReports = data;
        console.log
    });
  }

  navigateToReportsPage(reportTypeDescription: string) {
    const path = reportTypeDescription.replace(/\s+/g, '');
    this.router.navigate(['/reportspage', path]);
  }

  ngAfterContentInit() {
    // console.log("ngAfterContentInit: Before initiateDashboard");
    this.initiateDashboard();
  }


   ngAfterViewInit() {  
    
    if(this.globals.notifFramework.indexOf('https://notificationsadmin.accenture.com/') > -1 ){
      this.prodModal = true;
    }
    else if(this.globals.notifFramework.indexOf('https://notificationsadmin.ciostage.accenture.com/') > -1 ) {                   
       this.nonProdModal = true;
    }
} 

  /**********************************************************************************
    Method Name: initiateDashboard
    Description: Initiates the Dashboard and its data.

    Date            Modified by         Description
    07/11/19        Joshua Avecilla     added this.overrideSharePrice = this.marketQuoteData.LastPrice for bug 385062
**********************************************************************************/
  async initiateDashboard() {
    try {
      this.app.appNavHeaderVisible = true;
      this.app.appFooterVisible = true;
      let esoOptNum = JSON.parse(sessionStorage.getItem(this.globals.sessionOptNum));
      let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

      if (sessionStorage.getItem("marketQuoteDatafromExtAPI") != null) {
        this.marketQuoteData = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
        this.overrideSharePrice = this.marketQuoteData.LastPrice;
        this.tempSharePriceHolder = this.marketQuoteData.LastPrice;
      }


      this.getUserDetails(esoOptNum);
      this.getMarketQuotefromDB();
      //MJS 05/21/2024 617951- myHoldings Dashboard Enhancement: Added for Reports Link"
      this.getUserReportsAccess();
      this.getUserReports();
      
      if (sessionStorage.getItem(this.globals.isHighlightsCached) && (adminCredChecker != null || adminCredChecker != undefined)) {
        if (adminCredChecker.EnterpriseId == this.userprofileInfo.EnterpriseId) {
          this.getSessionData();
        }
        else {
          this.getHighlightsData();
        }
      } else {
        this.getHighlightsData();
      }
      this.ifUserIsTransferee();
      // console.log("initiateDashboard: End initiateDashboard");
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "initiateDashboard");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: changeElementjQuery
      Description: Manipulate elements using jQuery

      Date            Modified by       Description
      02/01/19        Katrina Narag     Initial draft; browser detection
      02/20/19        KMN               Remove setting of height for Chrome browser
  **********************************************************************************/
  changeElementjQuery() {
    try {
      $(document).ready(function () {
        let browser = window.navigator.userAgent;

        if (browser.indexOf("MSIE") != -1 || browser.indexOf("Trident") != -1) {   //For IE
          $(".parentContainer").css("height", "1286px");
        } else if (browser.indexOf("Firefox") != -1) {  //For Firefox
          $(".parentContainer").css("min-height", "1286px");
        } else if (browser.indexOf("Chrome") != -1) { //For Chrome
          //$(".parentContainer").css("height", "1170px");
        }


      });
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "changeElementjQuery");
      error.message += errorLocation;
      throw error;
    }
  }

  async performanceGrantMethod() {
    try {
      if (sessionStorage.getItem("hasPEAP") != null) {
        if (sessionStorage.getItem("hasPEAP") == "true") {
          this.performanceGrantData = JSON.parse(sessionStorage.getItem("performanceStorage"));
          this.localCurrencySymbolPEAP = CurrencySymbol[this.performanceGrantData.CurrencyLoc.replace(/\s/g, "")];
          this.dataPerformance = JSON.parse(sessionStorage.getItem("performanceStorage"));
          this.perfOnLoadView();
        }
      }
      else {
        if (JSON.parse(sessionStorage.getItem("performanceStorage")) != null) {
          this.performanceGrantData = JSON.parse(sessionStorage.getItem("performanceStorage"));
          this.localCurrencySymbolPEAP = CurrencySymbol[this.performanceGrantData.CurrencyLoc.replace(/\s/g, "")];
          this.dataPerformance = JSON.parse(sessionStorage.getItem("performanceStorage"));
          this.perfOnLoadView();
        }
        else {
          if (sessionStorage.getItem(this.globals.sessionOptNum) != null) {
            this.optNumForPeap = JSON.parse(sessionStorage.getItem(this.globals.sessionOptNum));
          } else {
            this.optNumForPeap = this.user.OptNum;
          }
          await this.performanceawardService.getPerformanceGrant(this.optNumForPeap).pipe(map(res => res)).subscribe(data => {
            try {
              this.performanceGrantData = data[0][0];
              if (this.performanceGrantData != undefined && this.performanceGrantData != null) {
                sessionStorage.setItem("performanceStorage", JSON.stringify(this.performanceGrantData));
                this.dataPerformance = JSON.parse(sessionStorage.getItem("performanceStorage"));
                this.localCurrencySymbolPEAP = CurrencySymbol[this.performanceGrantData.CurrencyLoc.replace(/\s/g, "")];
                sessionStorage.setItem("hasPEAP", "true");
                this.perfOnLoadView();
              }
              else {
                sessionStorage.setItem("hasPEAP", "false");
              }
            } catch (error) {
              let errorLocation = this.errorLocation.replace("methodName", "performanceGrantMethod");
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            let errorLocation = this.errorLocation.replace("methodName", "performanceGrantMethod");
            error.error.message += errorLocation;
            throw error.error;
          });
        }
      }

    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "performanceGrantMethod");
      error.message += errorLocation;
      throw error;
    }
  }



  /**************************************************************************************************************
      Method Name: displayCard
      Description: Display card based on user's shares

      #       Date            Modified by           Description
      1       02/07/19        Katrina Narag(KMN)    Hide PEAP card if user is not Active
      2       02/11/19        Roldan Mazo(RCM)      Changed Column to cards heights to accomodate 4 action items
      3       03/20/19        Jayson Sevilla(JSS)   Sprint 7 dashboard update
      4       03/21/19        Jayson Sevilla(JSS)   Modified the conditions to adjust per user type
      5       03/25/19        Jayson Sevilla(JSS)   Added views for FORMER employees
      6       04/11/19        Dalemark P. Suquib    Added condition for users without PEAP and Key Exec
      7       05/17/19        KMN                   BUG 317397 fix - KeyExec toggle is displayed in Holdings Summary page when user has no KeyExec program
  ****************************************************************************************************************/
  async displayCard(optnum) {
    
    // console.log("displayCard: Start displayCard");
    if (sessionStorage.getItem("displayCardDataCached") != null) {
      try {
        let cacheData = JSON.parse(sessionStorage.getItem("displayCardDataCached"));
        let body = cacheData[0][0];
        this.errorDisplayCard = false;

        let user = await this.userprofileInfo;          //KMN, 1
        let userStatus = user.EmployeeStatus;     //KMN, 1

        if (body.LocalCurrency != null) {
          this.localCurrencySymbolPEAP = CurrencySymbol[body.LocalCurrency.replace(/\s/g, "")];
        }

        // this.userTransferee = JSON.parse(sessionStorage.getItem("Local User Info Storage"));
        // let transfer = JSON.stringify(this.userTransferee.TransfereeTypeCd);
        // if (transfer != 'null') {
        //   this.transfereeTypeCdInd = false;
        // }
        // else { this.transfereeTypeCdInd = true; }
        this.ifUserIsTransferee();

        sessionStorage.setItem("showKeyExecToggle", body.KeyExecToggleInd);


        let eorInd = body.EorDisplayInd;
        let peapInd = body.PerfRewardInd;
        this.eorTotalShares = body.EorTotalShares;
        this.eorShares = body.EorShares;
        this.eorShortFall = body.EorShareExcessShortFall;
        this.eorExplain = body.EorResourceIdExplanation;
        let text = this.eorExplain;
        if (text == null || text == "") {
        } else {
          if (text.indexOf("Above") > -1) {
            this.belowInd = true;
          } else {
            this.belowInd = false;
          }
        }

        this.divSummaryInd = body.DivSummaryDisplayInd;
        this.esppCurrentOPInd = 1;
        this.esppenrollmentInd = 1;
        this.veipCurrentOPInd = 1;
        this.veipDisplay = 1;
        this.veipEnrollmentInd = 1;

        this.peapCard = peapInd == 1 && userStatus == "Active";

        let keyExecInd;
        let keyExecPerfLink = body.PerfSummaryDisplayInd;
        let keyExecToggle = body.KeyExecToggleInd;
        if(body.PerfSummaryDisplayInd == 1 || body.KeyExecToggleInd == 1)
        {
          keyExecInd = 1;
        }
        else
        {
          keyExecInd = 0;
        }

        //MJS 05/21/2024 617951- myHoldings Dashboard Enhancement: Adjust Cards Height based on user type"

        // CASE 1 -- Normal User without shares
        if (eorInd != 1 && peapInd != 1 && this.divSummaryInd != 1 && keyExecInd != 1) {
          if (this.actionitemcount == 4) {
            // Column 1
            this.reportsHeight= 35;
            this.highlightsHeight = 65;
            // Column 2
            this.faqHeight = 100; 
            // Column 3
            this.actionItemsHeight = 55;
            this.marketQuoteHeight = 45;

            this.parentContainerHeight = 1000;        // Parent Container Height
            this.displayEOR = false;
            this.displayPerfRewards = false;
            this.displayDivSummary = false;
            this.displayKeyExecCard = false;
          }
          else if (this.actionitemcount == 3) {
            // Column 1
            this.reportsHeight= 35;
            this.highlightsHeight = 65;
            // Column 2
            this.faqHeight = 100;
            // Column 3
            this.actionItemsHeight = 48.7;
            this.marketQuoteHeight = 51.3;

            this.parentContainerHeight = 900;        // Parent Container Height
            this.displayEOR = false;
            this.displayPerfRewards = false;
            this.displayDivSummary = false;
            this.displayKeyExecCard = false;
          }
          else {
            this.parentContainerHeight = 800;        // Parent Container Height
            this.displayEOR = false;
            this.displayPerfRewards = false;
            this.displayDivSummary = false;
            this.displayKeyExecCard = false;

            // Column 1
            this.reportsHeight= 35;
            this.highlightsHeight = 65;
            // Column 2
            this.faqHeight = 100;
            // Column 3
            this.marketQuoteHeight = 55;
            this.actionItemsHeight = 45;
          }
        }
    
   
        // CASE 2A -- User with all cards visible (ACTIVE USER)
        else if (eorInd == 1 && this.peapCard == true && this.divSummaryInd == 1 && keyExecInd == 1) {
          this.parentContainerHeight = 1000;      // Parent Container Height
          this.displayEOR = true;
          this.displayPerfRewards = true;
          this.displayDivSummary = true;
          this.displayKeyExecCard = true;

          sessionStorage.setItem("hasEOR", "1");
          sessionStorage.setItem("hasPerfAwards", "1");
          sessionStorage.setItem("hasKeyExec", "1");

          if(keyExecPerfLink == 1)
          {
            this.displaykeyExecPerfLink = true;
          }
          else
          {
            this.displaykeyExecPerfLink = false;
          }

          if(keyExecToggle == 1)
          {
            this.displaykeyExecToogle = true;
          }
          else
          {
            this.displaykeyExecToogle = false;
          }

          // Column 1
          this.keyExecHeight = 22;
          this.eorHeight = 17;
          this.reportsHeight = 28;
          this.highlightsHeight = 33;

          // Column 2
    
          this.peapHeight = 22.61;
          this.faqHeight = 77.39;
          //this.divSummHeight = 25.15;
          // Column 3
          if (this.actionitemcount == 4) {
            this.marketQuoteHeight = 45;
            this.actionItemsHeight = 55;
          }
          else if (this.actionitemcount == 3) {
            this.marketQuoteHeight = 51.3;
            this.actionItemsHeight = 48.7;
          }
          else {
            this.actionItemsHeight = 42;
            this.marketQuoteHeight = 58;
          }
        }
    
       // CASE 2B -- User with all cards visible (FORMER USER)
       else if (eorInd == 1 && peapInd == 1 && this.divSummaryInd == 1 && keyExecInd == 1) {
        this.parentContainerHeight = 1000;      // Parent Container Height
        this.displayEOR = true;
        this.displayPerfRewards = false;
        this.displayDivSummary = true;
        this.displayKeyExecCard = true;

        sessionStorage.setItem("hasEOR", "1");
        sessionStorage.setItem("hasKeyExec", "1");

        if(keyExecPerfLink == 1)
        {
          this.displaykeyExecPerfLink = true;
        }
        else
        {
          this.displaykeyExecPerfLink = false;
        }

        if(keyExecToggle == 1)
        {
          this.displaykeyExecToogle = true;
        }
        else
        {
          this.displaykeyExecToogle = false;
        }

        // Column 1
        this.keyExecHeight = 22;
        this.eorHeight = 16;
        this.reportsHeight = 28;
        this.highlightsHeight = 34;

        // Column 2
        this.faqHeight = 100;
        //this.divSummHeight = 24;
        // Column 3
        if (this.actionitemcount == 4) {
          this.marketQuoteHeight = 45;
          this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
          this.marketQuoteHeight = 51.3;
          this.actionItemsHeight = 48.7;
        }
        else {
          this.actionItemsHeight = 42;
          this.marketQuoteHeight = 58;
        }
      }
    
        // CASE 3 -- User with all cards visible EXCEPT PEAP
        else if (eorInd == 1 && peapInd != 1 && this.divSummaryInd == 1 && keyExecInd == 1) {
          this.parentContainerHeight = 1000;     // Parent Container Height
          this.displayEOR = true;
          this.displayPerfRewards = false;
          this.displayDivSummary = true;
          this.displayKeyExecCard = true;

          sessionStorage.setItem("hasEOR", "1");
          sessionStorage.setItem("hasKeyExec", "1");

          if(keyExecPerfLink == 1)
          {
            this.displaykeyExecPerfLink = true;
          }
          else
          {
            this.displaykeyExecPerfLink = false;
          }

          if(keyExecToggle == 1)
          {
            this.displaykeyExecToogle = true;
          }
          else
          {
            this.displaykeyExecToogle = false;
          }

          // Column 1
          this.eorHeight = 18;
          this.keyExecHeight = 22;
          this.reportsHeight = 28;
          this.highlightsHeight = 32;
          
          // Column 2
          //this.divSummHeight = 26;
          this.faqHeight = 100;
          // Column 3
          if (this.actionitemcount == 4) {
            this.marketQuoteHeight = 45;
            this.actionItemsHeight = 55;
          }
          else if (this.actionitemcount == 3) {
            this.marketQuoteHeight = 51.3;
            this.actionItemsHeight = 48.7;
          }
          else {
            this.actionItemsHeight = 42;
            this.marketQuoteHeight = 58;
          }
        }
    
        // CASE 4A -- User with all cards visible EXCEPT EOR (ACTIVE USER)
        else if (eorInd != 1 && this.peapCard == true && this.divSummaryInd == 1 && keyExecInd == 1) {
          this.parentContainerHeight = 1000;      // Parent Container Height
          this.displayEOR = false;
          this.displayPerfRewards = true;
          this.displayDivSummary = true;
          this.displayKeyExecCard = true;

          sessionStorage.setItem("hasPerfAwards", "1");
          sessionStorage.setItem("hasKeyExec", "1");

          if(keyExecPerfLink == 1)
          {
            this.displaykeyExecPerfLink = true;
          }
          else
          {
            this.displaykeyExecPerfLink = false;
          }

          if(keyExecToggle == 1)
          {
            this.displaykeyExecToogle = true;
          }
          else
          {
            this.displaykeyExecToogle = false;
          }

          // Column 1
          this.keyExecHeight = 22;
          this.reportsHeight = 28;
          this.highlightsHeight = 50;

          // Column 2
          this.peapHeight = 24;
          this.faqHeight = 76;
          //this.divSummHeight = 24;
          // Column 3
          if (this.actionitemcount == 4) {
            this.marketQuoteHeight = 45;
            this.actionItemsHeight = 55;
          }
          else if (this.actionitemcount == 3) {
            this.marketQuoteHeight = 51.3;
            this.actionItemsHeight = 48.7;
          }
          else {
            this.actionItemsHeight = 42;
            this.marketQuoteHeight = 58;
          }
        }
        // CASE 4B -- User with all cards visible EXCEPT EOR (FORMER USER)
        else if (eorInd != 1 && peapInd == 1 && this.divSummaryInd == 1 && keyExecInd == 1) {
          this.parentContainerHeight = 1000;      // Parent Container Height
          this.displayEOR = false;
          this.displayPerfRewards = false;
          this.displayDivSummary = true;
          this.displayKeyExecCard = true;

          sessionStorage.setItem("hasKeyExec", "1");

          if(keyExecPerfLink == 1)
          {
            this.displaykeyExecPerfLink = true;
          }
          else
          {
            this.displaykeyExecPerfLink = false;
          }

          if(keyExecToggle == 1)
          {
            this.displaykeyExecToogle = true;
          }
          else
          {
            this.displaykeyExecToogle = false;
          }

          // Column 1
          this.keyExecHeight = 22;
          this.reportsHeight = 28;
          this.highlightsHeight = 50;

          // Column 2
          this.faqHeight = 100;
          //this.divSummHeight = 24;
          // Column 3
          if (this.actionitemcount == 4) {
            this.marketQuoteHeight = 45;
            this.actionItemsHeight = 55;
          }
          else if (this.actionitemcount == 3) {
            this.marketQuoteHeight = 51.3;
            this.actionItemsHeight = 48.7;
          }
          else {
            this.actionItemsHeight = 42;
            this.marketQuoteHeight = 58;
          }
        }
       // CASE 5A -- User with all cards visible EXCEPT Key Exec (ACTIVE USER)
       else if (eorInd == 1 && this.peapCard == true && this.divSummaryInd == 1 && keyExecInd != 1) {
        this.parentContainerHeight = 1000;      // Parent Container Height
        this.displayEOR = true;
        this.displayPerfRewards = true;
        this.displayDivSummary = true;
        this.displayKeyExecCard = false;

        sessionStorage.setItem("hasEOR", "1");
        sessionStorage.setItem("hasPerfAwards", "1");

        // Column 1
        this.highlightsHeight = 50;
        this.reportsHeight = 32;
        this.eorHeight = 18;
        // Column 2
        this.peapHeight = 25;
        //this.divSummHeight = 26;
        this.faqHeight = 75;
        // Column 3
        if (this.actionitemcount == 4) {
          this.marketQuoteHeight = 45;
          this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
          this.marketQuoteHeight = 51.3;
          this.actionItemsHeight = 48.7;
        }
        else {
          this.actionItemsHeight = 42;
          this.marketQuoteHeight = 58;
        }
      }

    
           // CASE 5B -- User with all cards visible EXCEPT Key Exec (FORMER USER)
           else if (eorInd == 1 && peapInd == 1 && this.divSummaryInd == 1 && keyExecInd != 1) {
            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = true;
            this.displayPerfRewards = false;
            this.displayDivSummary = true;
            this.displayKeyExecCard = false;
  
            sessionStorage.setItem("hasEOR", "1");
  
            // Column 1
            this.reportsHeight = 32;
            this.highlightsHeight = 50;
            this.eorHeight = 18;
            // Column 2
            //this.divSummHeight = 26;
            this.faqHeight = 100;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
          // CASE 6A -- User with all cards visible EXCEPT Dividend Summary (ACTIVE USER)
          else if (eorInd == 1 && this.peapCard == true && this.divSummaryInd != 1 && keyExecInd == 1) {
            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = true;
            this.displayPerfRewards = true;
            this.displayDivSummary = false;
            this.displayKeyExecCard = true;

            sessionStorage.setItem("hasEOR", "1");
            sessionStorage.setItem("hasPerfAwards", "1");
            sessionStorage.setItem("hasKeyExec", "1");

            if(keyExecPerfLink == 1)
            {
              this.displaykeyExecPerfLink = true;
            }
            else
            {
              this.displaykeyExecPerfLink = false;
            }

            if(keyExecToggle == 1)
            {
              this.displaykeyExecToogle = true;
            }
            else
            {
              this.displaykeyExecToogle = false;
            }

            // Column 1
            this.eorHeight = 16;
            this.keyExecHeight = 22;
            this.reportsHeight = 28;
            this.highlightsHeight = 34;

            // Column 2
            this.peapHeight = 24;
            this.faqHeight= 76;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight  = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }

        // CASE 6B -- User with all cards visible EXCEPT Dividend Summary (FORMER USER)
        else if (eorInd == 1 && peapInd == 1 && this.divSummaryInd != 1 && keyExecInd == 1) {
        this.parentContainerHeight = 1000;      // Parent Container Height
        this.displayEOR = true;
        this.displayPerfRewards = false;
        this.displayDivSummary = false;
        this.displayKeyExecCard = true;

        sessionStorage.setItem("hasEOR", "1");
        sessionStorage.setItem("hasKeyExec", "1");

        if(keyExecPerfLink == 1)
        {
          this.displaykeyExecPerfLink = true;
        }
        else
        {
          this.displaykeyExecPerfLink = false;
        }

        if(keyExecToggle == 1)
        {
          this.displaykeyExecToogle = true;
        }
        else
        {
          this.displaykeyExecToogle = false;
        }

        // Column 1
        this.eorHeight = 16;
        this.keyExecHeight = 22;
        this.reportsHeight= 28;
        this.highlightsHeight = 34;

        // Column 2
        this.faqHeight = 100;
        // Column 3
        if (this.actionitemcount == 4) {
          this.marketQuoteHeight = 45;
          this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
          this.marketQuoteHeight = 51.3;
          this.actionItemsHeight = 48.7;
        }
        else {
          this.actionItemsHeight = 42;
          this.marketQuoteHeight = 58;
        }
        }
        // CASE 7 -- User with all cards visible EXCEPT EOR and PEAP
        else if (eorInd != 1 && peapInd != 1 && this.divSummaryInd == 1 && keyExecInd == 1) {
        this.parentContainerHeight = 1000;      // Parent Container Height
        this.displayEOR = false;
        this.displayPerfRewards = false;
        this.displayDivSummary = true;
        this.displayKeyExecCard = true;

        sessionStorage.setItem("hasKeyExec", "1");

        if(keyExecPerfLink == 1)
        {
        this.displaykeyExecPerfLink = true;
        }
        else
        {
        this.displaykeyExecPerfLink = false;
        }

        if(keyExecToggle == 1)
        {
        this.displaykeyExecToogle = true;
        }
        else
        {
        this.displaykeyExecToogle = false;
        }

        // Column 1
        this.keyExecHeight = 22;
        this.reportsHeight= 28;
        this.highlightsHeight = 50;

        // Column 2
        this.faqHeight = 100;
        //this.divSummHeight = 24;
        // Column 3
        if (this.actionitemcount == 4) {
        this.marketQuoteHeight  = 45;
        this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
        this.marketQuoteHeight = 51.3;
        this.actionItemsHeight = 48.7;
        }
        else {
        this.actionItemsHeight = 42;
        this.marketQuoteHeight = 58;
        }
        }

        // CASE 8A -- User with all cards visible EXCEPT Dividend Summary and Key Exec (ACTIVE USER)
        else if (eorInd == 1 && this.peapCard == true && this.divSummaryInd != 1 && keyExecInd != 1) {
        this.parentContainerHeight = 1000;      // Parent Container Height
        this.displayEOR = true;
        this.displayPerfRewards = true;
        this.displayDivSummary = false;
        this.displayKeyExecCard = false;

        sessionStorage.setItem("hasEOR", "1");
        sessionStorage.setItem("hasPerfAwards", "1");

        // Column 1
        this.eorHeight = 16;
        this.reportsHeight = 34;
        this.highlightsHeight = 50;

        // Column 2
        this.peapHeight = 24;
        this.faqHeight = 76;
        // Column 3
        if (this.actionitemcount == 4) {
        this.marketQuoteHeight = 45;
        this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
        this.marketQuoteHeight = 51.3;
        this.actionItemsHeight = 48.7;
        }
        else {
        this.actionItemsHeight = 42;
        this.marketQuoteHeight = 58;
        }
        }

        // CASE 8B -- User with all cards visible EXCEPT Dividend Summary and Key Exec (FORMER USER)
        else if (eorInd == 1 && peapInd == 1 && this.divSummaryInd != 1 && keyExecInd != 1) {
        this.parentContainerHeight = 1000;      // Parent Container Height
        this.displayEOR = true;
        this.displayPerfRewards = false;
        this.displayDivSummary = false;
        this.displayKeyExecCard = false;

        sessionStorage.setItem("hasEOR", "1");

        // Column 1
        this.eorHeight = 16;
        this.reportsHeight = 34;
        this.highlightsHeight = 50;

        // Column 2
        this.faqHeight = 100;
        // Column 3
        if (this.actionitemcount == 4) {
        this.marketQuoteHeight = 45;
        this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
        this.marketQuoteHeight = 51.3;
        this.actionItemsHeight = 48.7;
        }
        else {
        this.actionItemsHeight = 42;
        this.marketQuoteHeight = 58;
        }
        }

        // CASE 9 -- User with only Dividend Summary is visible
        else if (eorInd != 1 && peapInd != 1 && this.divSummaryInd == 1 && keyExecInd != 1) {
        this.displayEOR = false;
        this.displayPerfRewards = false;
        this.displayDivSummary = true;
        this.displayKeyExecCard = false;

        // Column 1
        this.reportsHeight= 37;
        this.highlightsHeight = 63;

        // Column 2
        //this.divSummHeight = 34;
        this.faqHeight = 100;
        // Column 3
        if (this.actionitemcount == 4) {
        this.parentContainerHeight = 950;
        this.marketQuoteHeight = 45;
        this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
        this.parentContainerHeight = 850;
        this.marketQuoteHeight = 48; // from 51.3%
        this.actionItemsHeight = 52; // from 48.7%
        }
        else {
        this.parentContainerHeight = 750;
        this.actionItemsHeight = 42;
        this.marketQuoteHeight = 58;
        }
        }

        // CASE 10 -- User with only Key Exec is visible
        else if (eorInd != 1 && peapInd != 1 && this.divSummaryInd != 1 && keyExecInd == 1) {
        this.displayEOR = false;
        this.displayPerfRewards = false;
        this.displayDivSummary = false;
        this.displayKeyExecCard = true;

        sessionStorage.setItem("hasKeyExec", "1");

        if(keyExecPerfLink == 1)
        {
          this.displaykeyExecPerfLink = true;
        }
        else
        {
          this.displaykeyExecPerfLink = false;
        }

        if(keyExecToggle == 1)
        {
          this.displaykeyExecToogle = true;
        }
        else
        {
          this.displaykeyExecToogle = false;
        }

        // Column 1
        this.keyExecHeight = 22;
        this.reportsHeight = 28;
        this.highlightsHeight = 50;

        // Column 2
        this.faqHeight = 100;
        // Column 3

        if (this.actionitemcount == 4) {
          this.parentContainerHeight = 950;
          this.marketQuoteHeight = 45;
          this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
          this.parentContainerHeight = 850;
          this.marketQuoteHeight = 51.3;
          this.actionItemsHeight = 48.7;
        }
        else {
          this.parentContainerHeight = 750;
          this.actionItemsHeight = 42;
          this.marketQuoteHeight = 58;
        }
        }

        // CASE 11 -- User with only EOR is visible
        else if (eorInd == 1 && peapInd != 1 && this.divSummaryInd != 1 && keyExecInd != 1) {
        this.displayEOR = true;
        this.displayPerfRewards = false;
        this.displayDivSummary = false;
        this.displayKeyExecCard = false;

        sessionStorage.setItem("hasEOR", "1");

        // Column 1
        this.eorHeight = 25;
        this.reportsHeight= 28;
        this.highlightsHeight = 47;

        // Column 2
        this.faqHeight = 100;
        // Column 3
        if (this.actionitemcount == 4) {
          this.parentContainerHeight = 950;
          this.marketQuoteHeight = 45;
          this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
          this.parentContainerHeight = 850;
          this.marketQuoteHeight = 51.3;
          this.actionItemsHeight = 48.7;
        }
        else {
          this.parentContainerHeight = 750;
          this.actionItemsHeight = 44;
          this.marketQuoteHeight = 56;
        }
        }


        // CASE 12A -- User with only PEAP is visible (ACTIVE USER)
        else if (eorInd != 1 && this.peapCard == true && this.divSummaryInd != 1 && keyExecInd != 1) {
          this.displayEOR = false;
          this.displayPerfRewards = true;
          this.displayDivSummary = false;
          this.displayKeyExecCard = false;

          sessionStorage.setItem("hasPerfAwards", "1");

          // Column 1
          this.reportsHeight= 35;
          this.highlightsHeight = 65;

          // Column 2
          this.peapHeight = 33;
          this.faqHeight = 67;
          // Column 3
          if (this.actionitemcount == 4) {
            this.parentContainerHeight = 950;
            this.marketQuoteHeight = 45;
            this.actionItemsHeight = 55;
          }
          else if (this.actionitemcount == 3) {
            this.parentContainerHeight = 850;
            this.marketQuoteHeight = 51.3;
            this.actionItemsHeight = 48.7;
          }
          else {
            this.parentContainerHeight = 750;
            this.actionItemsHeight = 42;
            this.marketQuoteHeight = 58;
          }
        }

          // CASE 12B -- User with only PEAP is visible (FORMER USER)
          else if (eorInd != 1 && peapInd == 1 && this.divSummaryInd != 1 && keyExecInd != 1) {
            this.displayEOR = false;
            this.displayPerfRewards = false;
            this.displayDivSummary = false;
            this.displayKeyExecCard = false;

            // Column 1
            this.reportsHeight= 35;
            this.highlightsHeight = 65;

            // Column 2
            this.faqHeight = 100;
            // Column 3
            if (this.actionitemcount == 4) {
              this.parentContainerHeight = 950;
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.parentContainerHeight = 850;
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.parentContainerHeight = 750;
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }

        // CASE 13A -- User without EOR and Key Exec (ACTIVE USER)
        else if (eorInd != 1 && this.peapCard == true && this.divSummaryInd == 1 && keyExecInd != 1) {
        // Column 1
        this.parentContainerHeight = 1000;      // Parent Container Height
        this.displayEOR = false;
        this.displayPerfRewards = true;
        this.displayDivSummary = true;
        this.displayKeyExecCard = false;

        sessionStorage.setItem("hasPerfAwards", "1");

        // Column 1
        this.reportsHeight = 30;
        this.highlightsHeight = 70;

        // Column 2
        this.peapHeight = 24;
        this.faqHeight = 76;
        //this.divSummHeight = 24;
        // Column 3
        if (this.actionitemcount == 4) {
        this.marketQuoteHeight = 45;
        this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
        this.marketQuoteHeight = 51.3;
        this.actionItemsHeight = 48.7;
        }
        else {
        this.actionItemsHeight = 42;
        this.marketQuoteHeight = 58;
        }

        }

        // CASE 13B -- User without EOR and Key Exec (FORMER USER)
        else if (eorInd != 1 && peapInd == 1 && this.divSummaryInd == 1 && keyExecInd != 1) {
        // Column 1
        this.parentContainerHeight = 1000;      // Parent Container Height
        this.displayEOR = false;
        this.displayPerfRewards = false;
        this.displayDivSummary = true;
        this.displayKeyExecCard = false;

        // Column 1
        this.reportsHeight = 30;
        this.highlightsHeight = 70;

        // Column 2
        this.faqHeight = 100;
        //this.divSummHeight = 24;
        // Column 3
        if (this.actionitemcount == 4) {
        this.marketQuoteHeight = 45;
        this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
        this.marketQuoteHeight = 51.3;
        this.actionItemsHeight = 48.7;
        }
        else {
        this.actionItemsHeight = 42;
        this.marketQuoteHeight = 58;
        }

        }
        // CASE 14 -- User without Dividend Summary and PEAP
        else if (eorInd == 1 && this.peapCard == false && this.divSummaryInd != 1 && keyExecInd == 1) {
        this.parentContainerHeight = 1000;      // Parent Container Height
        this.displayEOR = true;
        this.displayPerfRewards = false;
        this.displayDivSummary = false;
        this.displayKeyExecCard = true;

        sessionStorage.setItem("hasEOR", "1");
        sessionStorage.setItem("hasKeyExec", "1");

        if(keyExecPerfLink == 1)
        {
          this.displaykeyExecPerfLink = true;
        }
        else
        {
          this.displaykeyExecPerfLink = false;
        }

        if(keyExecToggle == 1)
        {
          this.displaykeyExecToogle = true;
        }
        else
        {
          this.displaykeyExecToogle = false;
        }

        // Column 1
        this.keyExecHeight = 22;
        this.eorHeight = 16;
        this.reportsHeight = 28;
        this.highlightsHeight = 34;

        // Column 2
        this.faqHeight = 100;
        // Column 3
        if (this.actionitemcount == 4) {
          this.marketQuoteHeight = 45;
          this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
          this.marketQuoteHeight = 51.3;
          this.actionItemsHeight = 48.7;
        }
        else {
          this.actionItemsHeight = 42;
          this.marketQuoteHeight = 58;
        }

        }
        // CASE 15 -- User without PEAP & Key Exec
        else if (eorInd == 1 && this.peapCard == false && this.divSummaryInd == 1 && keyExecInd != 1) {
        this.parentContainerHeight = 1000;
        this.displayEOR = true;
        this.displayPerfRewards = false;
        this.displayDivSummary = true;
        this.displayKeyExecCard = false;

        sessionStorage.setItem("hasEOR", "1");

        // Column 1
        this.eorHeight = 16;
        this.reportsHeight = 34;
        this.highlightsHeight = 50;

        // Column 2
        this.faqHeight = 100;
        //this.divSummHeight = 24;

        // Column 3
        if (this.actionitemcount == 4) {
          this.marketQuoteHeight = 45;
          this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
          this.marketQuoteHeight = 51.3;
          this.actionItemsHeight = 48.7;
        }
        else {
          this.actionItemsHeight = 42;
          this.marketQuoteHeight = 58;
        }
        }
        // CASE 16 All Cards Except EOR & DIVSum
        else if (this.peapCard == true && keyExecInd == 1) {
          this.parentContainerHeight = 1000;
          this.displayEOR = false;
          this.displayPerfRewards = true;
          this.displayDivSummary = false;
          this.displayKeyExecCard = true;

          sessionStorage.setItem("hasPerfAwards", "1");
          sessionStorage.setItem("hasKeyExec", "1");

          if(keyExecPerfLink == 1)
          {
            this.displaykeyExecPerfLink = true;
          }
          else
          {
            this.displaykeyExecPerfLink = false;
          }

          if(keyExecToggle == 1)
          {
            this.displaykeyExecToogle = true;
          }
          else
          {
            this.displaykeyExecToogle = false;
          }

        // Column 1

        this.keyExecHeight = 22;
        this.highlightsHeight = 50;
        this.reportsHeight = 28;

        // Column 2

        this.faqHeight = 75.13;
        this.peapHeight = 24.87;

        // Column 3
        if (this.actionitemcount == 4) {
          this.marketQuoteHeight  = 45;
          this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
          this.marketQuoteHeight  = 51.3;
          this.actionItemsHeight = 48.7;
        }
        else {
          this.actionItemsHeight = 42;
          this.marketQuoteHeight = 58;
        }
      }
        else {
          // For future use
        }
        // if(window.navigator.vendor.indexOf("Google") > -1){
        //     this.keyExecHeight = this.keyExecHeight + 4;
        //     this.marketQuoteHeight = this.marketQuoteHeight - 4;
        //   }
        // else {
        //   this.keyExecHeight = this.keyExecHeight + 1;
        //   this.marketQuoteHeight = this.marketQuoteHeight - 1;
        // }
      } catch (error) {
        this.errorDisplayCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "displayCard");
        error.message = error.message + errorLocation;
        throw error;
      }

    }
    else {
      await this.holdingsummaryservice.getcardIndicator(optnum).pipe(map(res => res)).subscribe(data => {
        try {
          let body = data[0][0];
          this.errorDisplayCard = false;

          let user = this.userprofileInfo;          //KMN, 1
          let userStatus = user.EmployeeStatus;     //KMN, 1

          if (body.LocalCurrency != null) {
            this.localCurrencySymbolPEAP = CurrencySymbol[body.LocalCurrency.replace(/\s/g, "")];
          }

          this.perfGrantValueExceed = body.PerfGrantValueExceed;
          this.perfGrantValueExceedStatic = body.PerfGrantValueExceed;

          sessionStorage.setItem("showKeyExecToggle", body.KeyExecToggleInd);

          let eorInd = body.EorDisplayInd;
          let peapInd = body.PerfRewardInd;
          this.eorTotalShares = body.EorTotalShares;
          this.eorShares = body.EorShares;
          this.eorShortFall = body.EorShareExcessShortFall;
          this.eorExplain = body.EorResourceIdExplanation;
          let text = this.eorExplain;
          if (text == null || text == "") {
          } else {
            if (text.indexOf("Above") > -1) {
              this.belowInd = true;
            } else {
              this.belowInd = false;
            }
          }

          this.divSummaryInd = body.DivSummaryDisplayInd;
          this.esppCurrentOPInd = 1;
          this.esppenrollmentInd = 1;
          this.veipCurrentOPInd = 1;
          this.veipDisplay = 1;
          this.veipEnrollmentInd = 1;

          this.peapCard = peapInd == 1 && userStatus == "Active";

          let keyExecInd;
          let keyExecPerfLink = body.PerfSummaryDisplayInd;
          let keyExecToggle = body.KeyExecToggleInd;
          if(body.PerfSummaryDisplayInd == 1 || body.KeyExecToggleInd == 1)
          {
            keyExecInd = 1;
          }
          else
          {
            keyExecInd = 0;
          }

          // CASE 1 -- Normal User without shares
       if (eorInd != 1 && peapInd != 1 && this.divSummaryInd != 1 && keyExecInd != 1) {

        if (this.actionitemcount == 4) {
          this.parentContainerHeight = 1000;        // Parent Container Height
          this.displayEOR = false;
          this.displayPerfRewards = false;
          this.displayDivSummary = false;
          this.displayKeyExecCard = false;

          // Column 1
          this.reportsHeight = 35;
          this.highlightsHeight = 65;
          // Column 2
          this.faqHeight = 100;
          // Column 3
          this.marketQuoteHeight = 45;
          this.actionItemsHeight = 55;
        }
        else if (this.actionitemcount == 3) {
          this.parentContainerHeight = 900;        // Parent Container Height
          this.displayEOR = false;
          this.displayPerfRewards = false;
          this.displayDivSummary = false;
          this.displayKeyExecCard = false;

          // Column 1
          this.reportsHeight = 35;
          this.highlightsHeight = 65;
          // Column 2
          this.faqHeight = 100;
          // Column 3
          this.marketQuoteHeight = 51.3;
          this.actionItemsHeight = 48.7;

        }
        else {
          this.parentContainerHeight = 800;        // Parent Container Height
          this.displayEOR = false;
          this.displayPerfRewards = false;
          this.displayDivSummary = false;
          this.displayKeyExecCard = false;

          // Column 1
          this.reportsHeight = 35;
          this.highlightsHeight = 65;
          // Column 2
          this.faqHeight = 100;
          // Column 3
          this.marketQuoteHeight = 55;
          this.actionItemsHeight = 45;
        }
      }
    
          // CASE 2A -- User with all cards visible (ACTIVE USER)
          else if (eorInd == 1 && this.peapCard == true && this.divSummaryInd == 1 && keyExecInd == 1) {

            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = true;
            this.displayPerfRewards = true;
            this.displayDivSummary = true;
            this.displayKeyExecCard = true;

            sessionStorage.setItem("hasEOR", "1");
            sessionStorage.setItem("hasPerfAwards", "1");
            sessionStorage.setItem("hasKeyExec", "1");

            if(keyExecPerfLink == 1)
            {
              this.displaykeyExecPerfLink = true;
            }
            else
            {
              this.displaykeyExecPerfLink = false;
            }

            if(keyExecToggle == 1)
            {
              this.displaykeyExecToogle = true;
            }
            else
            {
              this.displaykeyExecToogle = false;
            }

            // Column 1
            this.eorHeight = 17;
            this.keyExecHeight = 22;
            this.reportsHeight = 28;
            this.highlightsHeight = 33;

            // Column 2
            this.peapHeight = 22.61
            this.faqHeight = 77.39;
            //this.divSummHeight = 25.24;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
          // CASE 2B -- User with all cards visible (FORMER USER)
          else if (eorInd == 1 && peapInd == 1 && this.divSummaryInd == 1 && keyExecInd == 1) {

            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = true;
            this.displayPerfRewards = false;
            this.displayDivSummary = true;
            this.displayKeyExecCard = true;

            sessionStorage.setItem("hasEOR", "1");
            sessionStorage.setItem("hasKeyExec", "1");

            if(keyExecPerfLink == 1)
            {
              this.displaykeyExecPerfLink = true;
            }
            else
            {
              this.displaykeyExecPerfLink = false;
            }

            if(keyExecToggle == 1)
            {
              this.displaykeyExecToogle = true;
            }
            else
            {
              this.displaykeyExecToogle = false;
            }

            // Column 1
            this.keyExecHeight = 22;
            this.eorHeight = 16;
            this.reportsHeight = 28;
            this.highlightsHeight = 34;

            // Column 2
          
            this.faqHeight = 100;
            //this.divSummHeight = 24;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
    
              // CASE 3 -- User with all cards visible EXCEPT PEAP
              else if (eorInd == 1 && peapInd != 1 && this.divSummaryInd == 1 && keyExecInd == 1) {
    
                this.parentContainerHeight = 1000;     // Parent Container Height
                this.displayEOR = true;
                this.displayPerfRewards = false;
                this.displayDivSummary = true;
                this.displayKeyExecCard = true;
    
                sessionStorage.setItem("hasEOR", "1");
                sessionStorage.setItem("hasKeyExec", "1");
    
                if(keyExecPerfLink == 1)
                {
                  this.displaykeyExecPerfLink = true;
                }
                else
                {
                  this.displaykeyExecPerfLink = false;
                }
    
                if(keyExecToggle == 1)
                {
                  this.displaykeyExecToogle = true;
                }
                else
                {
                  this.displaykeyExecToogle = false;
                }
    
                // Column 1
                this.keyExecHeight = 22;
                this.highlightsHeight = 32;
                this.eorHeight = 18;
                this.reportsHeight = 28;
                // Column 2

                //this.divSummHeight = 26;
                this.faqHeight = 100;
                // Column 3
                if (this.actionitemcount == 4) {
                  this.marketQuoteHeight = 45;
                  this.actionItemsHeight = 55;
                }
                else if (this.actionitemcount == 3) {
                  this.marketQuoteHeight = 51.3;
                  this.actionItemsHeight = 48.7;
                }
                else {
                  this.actionItemsHeight = 42;
                  this.marketQuoteHeight = 58;
                }
              }
    
              
          // CASE 4A -- User with all cards visible EXCEPT EOR (ACTIVE USER)
          else if (eorInd != 1 && this.peapCard == true && this.divSummaryInd == 1 && keyExecInd == 1) {

            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = false;
            this.displayPerfRewards = true;
            this.displayDivSummary = true;
            this.displayKeyExecCard = true;

            sessionStorage.setItem("hasPerfAwards", "1");
            sessionStorage.setItem("hasKeyExec", "1");

            if(keyExecPerfLink == 1)
            {
              this.displaykeyExecPerfLink = true;
            }
            else
            {
              this.displaykeyExecPerfLink = false;
            }

            if(keyExecToggle == 1)
            {
              this.displaykeyExecToogle = true;
            }
            else
            {
              this.displaykeyExecToogle = false;
            }

            // Column 1

            this.keyExecHeight = 22;
            this.highlightsHeight = 50;
            this.reportsHeight = 28;

            // Column 2
            this.peapHeight = 24;
            this.faqHeight = 76;
            //this.divSummHeight = 24;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
    

          // CASE 4B -- User with all cards visible EXCEPT EOR (FORMER USER)
          else if (eorInd != 1 && peapInd == 1 && this.divSummaryInd == 1 && keyExecInd == 1) {

            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = false;
            this.displayPerfRewards = false;
            this.displayDivSummary = true;
            this.displayKeyExecCard = true;

            sessionStorage.setItem("hasKeyExec", "1");

            if(keyExecPerfLink == 1)
            {
              this.displaykeyExecPerfLink = true;
            }
            else
            {
              this.displaykeyExecPerfLink = false;
            }

            if(keyExecToggle == 1)
            {
              this.displaykeyExecToogle = true;
            }
            else
            {
              this.displaykeyExecToogle = false;
            }

            // Column 1
            this.keyExecHeight = 22;
            this.reportsHeight = 28;
            this.highlightsHeight = 50;

            // Column 2
            this.faqHeight = 100;
            //this.divSummHeight = 24;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
          // CASE 5A -- User with all cards visible EXCEPT Key Exec (ACTIVE USER)
          else if (eorInd == 1 && this.peapCard == true && this.divSummaryInd == 1 && keyExecInd != 1) {

            this.parentContainerHeight = 950;      // Parent Container Height
            this.displayEOR = true;
            this.displayPerfRewards = true;
            this.displayDivSummary = true;
            this.displayKeyExecCard = false;

            sessionStorage.setItem("hasEOR", "1");
            sessionStorage.setItem("hasPerfAwards", "1");

            // Column 1
            this.highlightsHeight = 52;
            this.reportsHeight = 30;
            this.eorHeight = 18;
            // Column 2
            this.peapHeight = 25;
            //this.divSummHeight = 26;
            this.faqHeight = 75;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
          // CASE 5B -- User with all cards visible EXCEPT Key Exec (FORMER USER)
          else if (eorInd == 1 && peapInd == 1 && this.divSummaryInd == 1 && keyExecInd != 1) {

            this.parentContainerHeight = 950;      // Parent Container Height
            this.displayEOR = true;
            this.displayPerfRewards = false;
            this.displayDivSummary = true;
            this.displayKeyExecCard = false;

            sessionStorage.setItem("hasEOR", "1");

            // Column 1
            this.highlightsHeight = 50;
            this.eorHeight = 18;
            this.reportsHeight = 32;
            // Column 2
            //this.divSummHeight = 26;
            this.faqHeight = 100;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
    
         // CASE 6A -- User with all cards visible EXCEPT Dividend Summary (ACTIVE USER)
         else if (eorInd == 1 && this.peapCard == true && this.divSummaryInd != 1 && keyExecInd == 1) {

          this.parentContainerHeight = 1000;      // Parent Container Height
          this.displayEOR = true;
          this.displayPerfRewards = true;
          this.displayDivSummary = false;
          this.displayKeyExecCard = true;

          sessionStorage.setItem("hasEOR", "1");
          sessionStorage.setItem("hasPerfAwards", "1");
          sessionStorage.setItem("hasKeyExec", "1");

          if(keyExecPerfLink == 1)
          {
            this.displaykeyExecPerfLink = true;
          }
          else
          {
            this.displaykeyExecPerfLink = false;
          }

          if(keyExecToggle == 1)
          {
            this.displaykeyExecToogle = true;
          }
          else
          {
            this.displaykeyExecToogle = false;
          }

          // Column 1
          this.eorHeight = 16;
          this.reportsHeight = 28;
          this.keyExecHeight = 22;
          this.highlightsHeight = 34;

          // Column 2
          this.peapHeight = 22;
          this.faqHeight = 78;
          // Column 3
          if (this.actionitemcount == 4) {
            this.marketQuoteHeight = 45;
            this.actionItemsHeight = 55;
          }
          else if (this.actionitemcount == 3) {
            this.marketQuoteHeight = 51.3;
            this.actionItemsHeight = 48.7;
          }
          else {
            this.actionItemsHeight = 42;
            this.marketQuoteHeight = 58;
          }
        }
    

          // CASE 6B -- User with all cards visible EXCEPT Dividend Summary (FORMER USER)
          else if (eorInd == 1 && peapInd == 1 && this.divSummaryInd != 1 && keyExecInd == 1) {

            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = true;
            this.displayPerfRewards = false;
            this.displayDivSummary = false;
            this.displayKeyExecCard = true;

            sessionStorage.setItem("hasEOR", "1");
            sessionStorage.setItem("hasKeyExec", "1");

            if(keyExecPerfLink == 1)
            {
              this.displaykeyExecPerfLink = true;
            }
            else
            {
              this.displaykeyExecPerfLink = false;
            }

            if(keyExecToggle == 1)
            {
              this.displaykeyExecToogle = true;
            }
            else
            {
              this.displaykeyExecToogle = false;
            }

            // Column 1
            this.keyExecHeight = 22;
            this.eorHeight = 16;
            this.reportsHeight = 28;
            this.highlightsHeight = 34;

            // Column 2
            this.faqHeight = 100;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
          // CASE 7 -- User with all cards visible EXCEPT EOR and PEAP
          else if (eorInd != 1 && peapInd != 1 && this.divSummaryInd == 1 && keyExecInd == 1) {

            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = false;
            this.displayPerfRewards = false;
            this.displayDivSummary = true;
            this.displayKeyExecCard = true;

            sessionStorage.setItem("hasKeyExec", "1");

            if(keyExecPerfLink == 1)
            {
              this.displaykeyExecPerfLink = true;
            }
            else
            {
              this.displaykeyExecPerfLink = false;
            }

            if(keyExecToggle == 1)
            {
              this.displaykeyExecToogle = true;
            }
            else
            {
              this.displaykeyExecToogle = false;
            }

            // Column 1
            this.keyExecHeight = 22;
            this.reportsHeight = 28;
            this.highlightsHeight = 50;

            // Column 2
            this.faqHeight = 100;
            //this.divSummHeight = 24;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
              // CASE 8A -- User with all cards visible EXCEPT Dividend Summary and Key Exec (ACTIVE USER)
          // CASE 8A -- User with all cards visible EXCEPT Dividend Summary and Key Exec (ACTIVE USER)
          else if (eorInd == 1 && this.peapCard == true && this.divSummaryInd != 1 && keyExecInd != 1) {

            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = true;
            this.displayPerfRewards = true;
            this.displayDivSummary = false;
            this.displayKeyExecCard = false;

            sessionStorage.setItem("hasEOR", "1");
            sessionStorage.setItem("hasPerfAwards", "1");

            // Column 1
            this.eorHeight = 16;
            this.reportsHeight = 34;
            this.highlightsHeight = 50;

            // Column 2
            this.peapHeight = 24;
            this.faqHeight = 76;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
          // CASE 8B -- User with all cards visible EXCEPT Dividend Summary and Key Exec (FORMER USER)
          else if (eorInd == 1 && peapInd == 1 && this.divSummaryInd != 1 && keyExecInd != 1) {

            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = true;
            this.displayPerfRewards = false;
            this.displayDivSummary = false;
            this.displayKeyExecCard = false;

            sessionStorage.setItem("hasEOR", "1");

            // Column 1
            this.eorHeight = 16;
            this.reportsHeight = 34;
            this.highlightsHeight = 50;

            // Column 2
            this.faqHeight  = 100;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }

    
        // CASE 9 -- User with only Dividend Summary is visible
        else if (eorInd != 1 && peapInd != 1 && this.divSummaryInd == 1 && keyExecInd != 1) {

          this.displayEOR = false;
          this.displayPerfRewards = false;
          this.displayDivSummary = true;
          this.displayKeyExecCard = false;

          // Column 1
          this.reportsHeight = 37;
          this.highlightsHeight = 63;

          // Column 2
          //this.divSummHeight = 34;
          this.faqHeight = 100;
          // Column 3
          if (this.actionitemcount == 4) {
            this.parentContainerHeight = 950;
            this.marketQuoteHeight = 45;
            this.actionItemsHeight = 55;
          }
          else if (this.actionitemcount == 3) {
            this.parentContainerHeight = 850;
            this.marketQuoteHeight = 48; // from 51.3%
            this.actionItemsHeight = 52; // from 48.7%
          }
          else {
            this.parentContainerHeight = 750;
            this.actionItemsHeight = 42;
            this.marketQuoteHeight  = 58;
          }
        }
    
          // CASE 10 -- User with only Key Exec is visible
          else if (eorInd != 1 && peapInd != 1 && this.divSummaryInd != 1 && keyExecInd == 1) {

            this.displayEOR = false;
            this.displayPerfRewards = false;
            this.displayDivSummary = false;
            this.displayKeyExecCard = true;

            sessionStorage.setItem("hasKeyExec", "1");

            if(keyExecPerfLink == 1)
            {
              this.displaykeyExecPerfLink = true;
            }
            else
            {
              this.displaykeyExecPerfLink = false;
            }

            if(keyExecToggle == 1)
            {
              this.displaykeyExecToogle = true;
            }
            else
            {
              this.displaykeyExecToogle = false;
            }

            // Column 1
            this.keyExecHeight = 22;
            this.reportsHeight = 28;
            this.highlightsHeight = 50;

            // Column 2
            this.faqHeight = 100;
            // Column 3

            if (this.actionitemcount == 4) {
              this.parentContainerHeight = 950;
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.parentContainerHeight = 850;
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.parentContainerHeight = 750;
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
          // CASE 11 -- User with only EOR is visible
          else if (eorInd == 1 && peapInd != 1 && this.divSummaryInd != 1 && keyExecInd != 1) {

            this.displayEOR = true;
            this.displayPerfRewards = false;
            this.displayDivSummary = false;
            this.displayKeyExecCard = false;

            sessionStorage.setItem("hasEOR", "1");

            // Column 1
            this.eorHeight = 25;
            this.reportsHeight = 28;
            this.highlightsHeight = 47;

            // Column 2
            this.faqHeight = 100;
            // Column 3
            if (this.actionitemcount == 4) {
              this.parentContainerHeight = 950;
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.parentContainerHeight = 850;
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.parentContainerHeight = 750;
              this.actionItemsHeight = 44;
              this.marketQuoteHeight = 56;
            }
          }
    
          // CASE 12A -- User with only PEAP is visible (ACTIVE USER)
          else if (eorInd != 1 && this.peapCard == true && this.divSummaryInd != 1 && keyExecInd != 1) {
            this.displayEOR = false;
            this.displayPerfRewards = true;
            this.displayDivSummary = false;
            this.displayKeyExecCard = false;

            sessionStorage.setItem("hasPerfAwards", "1");

            // Column 1
            this.reportsHeight = 35;
            this.highlightsHeight = 65;

            // Column 2
            this.peapHeight = 33;
            this.faqHeight = 67;
            // Column 3
            if (this.actionitemcount == 4) {
              this.parentContainerHeight = 950;
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.parentContainerHeight = 850;
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.parentContainerHeight = 750;
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
    
          // CASE 12B -- User with only PEAP is visible (FORMER USER)
          else if (eorInd != 1 && peapInd == 1 && this.divSummaryInd != 1 && keyExecInd != 1) {

            this.displayEOR = false;
            this.displayPerfRewards = false;
            this.displayDivSummary = false;
            this.displayKeyExecCard = false;

            // Column 1
            this.reportsHeight = 35;
            this.highlightsHeight = 65;

            // Column 2
            this.faqHeight = 100;
            // Column 3
            if (this.actionitemcount == 4) {
              this.parentContainerHeight = 950;
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.parentContainerHeight = 850;
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.parentContainerHeight = 750;
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
    
          // CASE 13A -- User without EOR and Key Exec (ACTIVE USER)
          else if (eorInd != 1 && this.peapCard == true && this.divSummaryInd == 1 && keyExecInd != 1) {
            // Column 1
            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = false;
            this.displayPerfRewards = true;
            this.displayDivSummary = true;
            this.displayKeyExecCard = false;

            sessionStorage.setItem("hasPerfAwards", "1");

            // Column 1
            this.reportsHeight = 30;
            this.highlightsHeight = 70;

            // Column 2
            this.peapHeight = 24;
            this.faqHeight = 76;
            //this.divSummHeight = 24;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }

          }
          // CASE 13B -- User without EOR and Key Exec (FORMER USER)
          else if (eorInd != 1 && peapInd == 1 && this.divSummaryInd == 1 && keyExecInd != 1) {
            // Column 1
            this.parentContainerHeight = 1000;      // Parent Container Height
            this.displayEOR = false;
            this.displayPerfRewards = false;
            this.displayDivSummary = true;
            this.displayKeyExecCard = false;

            // Column 1
            this.reportsHeight = 30;
            this.highlightsHeight = 70;

            // Column 2
            this.faqHeight = 100;
            //this.divSummHeight = 24;
            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }

          }


         // CASE 14 -- User without Dividend Summary and PEAP
         else if (eorInd == 1 && this.peapCard == false && this.divSummaryInd != 1 && keyExecInd == 1) {
          this.parentContainerHeight = 1000;      // Parent Container Height
          this.displayEOR = true;
          this.displayPerfRewards = false;
          this.displayDivSummary = false;
          this.displayKeyExecCard = true;

          sessionStorage.setItem("hasEOR", "1");
          sessionStorage.setItem("hasKeyExec", "1");

          if(keyExecPerfLink == 1)
          {
            this.displaykeyExecPerfLink = true;
          }
          else
          {
            this.displaykeyExecPerfLink = false;
          }

          if(keyExecToggle == 1)
          {
            this.displaykeyExecToogle = true;
          }
          else
          {
            this.displaykeyExecToogle = false;
          }

          // Column 1
          this.eorHeight = 16;
          this.reportsHeight = 28;
          this.keyExecHeight = 22;
          this.highlightsHeight = 34;

          // Column 2
          this.faqHeight = 100;
          // Column 3
          if (this.actionitemcount == 4) {
            this.marketQuoteHeight = 45;
            this.actionItemsHeight = 55;
          }
          else if (this.actionitemcount == 3) {
            this.marketQuoteHeight = 51.3;
            this.actionItemsHeight = 48.7;
          }
          else {
            this.actionItemsHeight = 42;
            this.marketQuoteHeight = 58;
          }
    
             // CASE 15 -- User without PEAP & Key Exec
            } else if (eorInd == 1 && this.peapCard == false && this.divSummaryInd == 1 && keyExecInd != 1) {
              this.parentContainerHeight = 1000;
              this.displayEOR = true;
              this.displayPerfRewards = false;
              this.displayDivSummary = true;
              this.displayKeyExecCard = false;
  
              sessionStorage.setItem("hasEOR", "1");
  
              // Column 1
              this.eorHeight = 16;
              this.reportsHeight = 34;
              this.highlightsHeight = 50;
  
              // Column 2
              this.faqHeight = 100;
              //this.divSummHeight = 24;
  
              // Column 3
              if (this.actionitemcount == 4) {
                this.marketQuoteHeight = 45;
                this.actionItemsHeight = 55;
              }
              else if (this.actionitemcount == 3) {
                this.marketQuoteHeight = 51.3;
                this.actionItemsHeight = 48.7;
              }
              else {
                this.actionItemsHeight = 42;
                this.marketQuoteHeight = 58;
              }
            }
          // CASE 16 All Cards Except EOR & DIVSum
          else if (this.peapCard == true && keyExecInd == 1) {

            this.parentContainerHeight = 1000;
            this.displayEOR = false;
            this.displayPerfRewards = true;
            this.displayDivSummary = false;
            this.displayKeyExecCard = true;

            sessionStorage.setItem("hasPerfAwards", "1");
            sessionStorage.setItem("hasKeyExec", "1");

            if(keyExecPerfLink == 1)
            {
              this.displaykeyExecPerfLink = true;
            }
            else
            {
              this.displaykeyExecPerfLink = false;
            }

            if(keyExecToggle == 1)
            {
              this.displaykeyExecToogle = true;
            }
            else
            {
              this.displaykeyExecToogle = false;
            }

            // Column 1
            this.keyExecHeight = 22;
            this.reportsHeight = 28;
            this.highlightsHeight = 50;

            // Column 2
            this.faqHeight = 75.13;
            this.peapHeight = 24.87;
        

            // Column 3
            if (this.actionitemcount == 4) {
              this.marketQuoteHeight = 45;
              this.actionItemsHeight = 55;
            }
            else if (this.actionitemcount == 3) {
              this.marketQuoteHeight = 51.3;
              this.actionItemsHeight = 48.7;
            }
            else {
              this.actionItemsHeight = 42;
              this.marketQuoteHeight = 58;
            }
          }
          else {
            // For future use
          }
              sessionStorage.setItem("displayCardDataCached", JSON.stringify(data));
              // if(window.navigator.vendor.indexOf("Google") > -1){
              //   this.keyExecHeight = this.keyExecHeight + 4;
              //   this.marketQuoteHeight = this.marketQuoteHeight - 4;
              // }
            } catch (error) {
              this.errorDisplayCard = true;
              let errorLocation = this.errorLocation.replace("methodName", "displayCard");
              error.message = error.message + errorLocation;
              throw error;
            }
          }, error => {
            this.errorDisplayCard = true;
            let errorLocation = this.errorLocation.replace("methodName", "displayCard");
            error.error.message += errorLocation;
            throw error.error;
          });
    
        }
        // if(window.navigator.vendor.indexOf("Google") > -1){
        //   this.keyExecHeight = this.keyExecHeight + 4;
        //   this.marketQuoteHeight = this.marketQuoteHeight - 4;
        // }
        // if(window.navigator.vendor.indexOf("Google") !> -1) {
        //   this.keyExecHeight = this.keyExecHeight + 1;
        //   this.marketQuoteHeight = this.marketQuoteHeight - 1;
        // }
        // console.log("displayCard: Before getTotalPSUsharesbyOptNum");
      }
    
    
      navToPage(strLoc) {
        try {
          this.router.navigate([strLoc]);
    
    
        } catch (error) {
          let errorLocation = this.errorLocation.replace("methodName", "navToPage");
          error.message += errorLocation;
          throw error;
        }
    
      }
      setViewAllNav() {
    
        sessionStorage.setItem("ntr", "fromHomepage");
    
      }
    
    
  /********************************************************************************************************************************************************
    Method Name: getUserDetails
    Description: Generates the 20minute Interval to refresh Market Quote
    #    Date             Modified by                       Description
    1    04/16/19         Joshua Paul Avecilla (JPA)        Added condition for auth guard to redirect user to the page they initially inputted in the url.
    2    04/26/19         JPA                               Added calling of setBodyData() and condition for calling method to redirect user to page inputted
                                                            in url. (Auth Guard)
    3    05/17/19         JPA                               Added lines to check if
    4    07/29/19         JPA                               Changed session "adminPageDataStore" to false
  ********************************************************************************************************************************************************/
  async getUserDetails(optnum) {
    try {
      // let tokenExpire = new Date(JSON.parse(sessionStorage.getItem('token expire')));
      // let today = moment().format('M/DD/YYYY, h:mm:ss a');
      // if(tokenExpire.toLocaleString() < today && sessionStorage.getItem('token expire') != null){

      // sessionStorage.setItem('token expire', JSON.parse(sessionStorage.getItem('expire_at')));
      // this.navToPage('landingpage');
      // }
      let selectedCurrencyCd = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));
      let sessionAdminInfo = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

      // if(JSON.parse(localStorage.getItem("Local User Info Storage")) != null) {
      //   this.userprofileInfo = JSON.parse(localStorage.getItem("Local User Info Storage"));
      // } else {
      //   this.userprofileInfo = this.route.snapshot.data['userDetails'];
      // }

      this.userprofileInfo = this.route.snapshot.data['userDetails'];
      if (this.userprofileInfo != null) {
        sessionStorage.setItem("Local User Info Storage", JSON.stringify(this.userprofileInfo));
      }
      else {
        this.userprofileInfo = JSON.parse(sessionStorage.getItem("Local User Info Storage"));
      }
      this.userInfoStore = this.userprofileInfo;
      // this.userprofileInfo = this.userprofileInfo[0][0];
      // if(this.userprofileInfo[0][1] == null){
      //   (<any>$("#ppModal")).modal();
      // }

      // this.getTotalPSUsharesbyOptNum(this.userprofileInfo.OptNum);

      if (this.userprofileInfo.EsppEligibleInd == "Y" || this.userprofileInfo.VeipNextOfferingEligibleInd == "Y") {
        this.esppEligible = true;
        this.veipEligible = false;
      }
      else if (this.userprofileInfo.VeipCurrentOfferingEligibleInd == "Y") {
        this.esppEligible = false;
        this.veipEligible = true;
      }

      if (this.userprofileInfo.IsAdmin == 'Y' || this.userprofileInfo.IsAdmin == 'y') {
        sessionStorage.setItem('adminPageDataStore', 'false');
        this.staticContent.setNavBarState(true);
        this.staticContent.setNavLoginState(true);

        if (Object.keys(this.userprofileInfo).length > 0) {
          sessionStorage.setItem(this.globals.profileInfoKey, JSON.stringify(this.userprofileInfo));
          this.sessionCachedUserInfo = true;
          sessionStorage.setItem(this.globals.isUserInfoCached, JSON.stringify(this.sessionCachedUserInfo));
        }
        this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
        this.setBodyData();
        if (sessionStorage.getItem("user input url") != null || sessionStorage.getItem("user input url") != undefined) {
          this.redirectAuthGuard();

        }

        this.getESPPActionItemDetail();
        this.getVEIPActionItemDetail();
        this.isNewlyPromotedMD();
        this.getGrantActionItemDetail();
        this.performanceGrantMethod();
        // this.displayCard(this.user.OptNum);

        this.getCurrencyList();

        let userChanged = JSON.parse(sessionStorage.getItem("Changed User"));

        if (userChanged) {
          sessionStorage.setItem(this.globals.selectedCurrencyCd, JSON.stringify(null));
          sessionStorage.setItem("Changed User", JSON.stringify(false));
        }

        selectedCurrencyCd = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));

        let latestUserCurrencyCd;
        if (selectedCurrencyCd == null || selectedCurrencyCd == "") {
          latestUserCurrencyCd = this.user.CurrencyCd.replace(/\s/g, "");
          this.currencyCd = this.user.CurrencyCd.replace(/\s/g, "");
        } else {
          latestUserCurrencyCd = selectedCurrencyCd;
          this.currencyCd = selectedCurrencyCd;
        }
        this.getExchangeRateData(latestUserCurrencyCd);
        // this.getDataApi();
        // this.getRsuApiData();
        // this.getOptionsApiData();
        // this.getPurchaseApiData();
        // this.getFoundersApiData();
        // this.getRSUDivSumApiData();
      }
      else {
        if (sessionAdminInfo != null || sessionAdminInfo != undefined) {
          if (sessionAdminInfo.EnterpriseId == this.userprofileInfo.EnterpriseId) {
            this.staticContent.setNavBarState(true);
            this.staticContent.setNavLoginState(true);

            await this.userService.getUserInfo(optnum).subscribe(results => {
              this.user = results;
              this.setBodyData();
              if (sessionStorage.getItem("user input url") != null || sessionStorage.getItem("user input url") != undefined) {
                this.redirectAuthGuard();
              }
              sessionStorage.setItem(this.globals.profileInfoKey, JSON.stringify(this.user));
              // this.displayCard(this.user.OptNum);
              this.getESPPActionItemDetail();
              this.getVEIPActionItemDetail();
              this.isNewlyPromotedMD();
              this.getGrantActionItemDetail();
              this.getCurrencyList();
              this.performanceGrantMethod();

              let userChanged = JSON.parse(sessionStorage.getItem("Changed User"));

              if (userChanged) {
                sessionStorage.setItem(this.globals.selectedCurrencyCd, JSON.stringify(null));
                sessionStorage.setItem("Changed User", JSON.stringify(false));
              }

              selectedCurrencyCd = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));

              let latestUserCurrencyCd;

              if (selectedCurrencyCd == null || selectedCurrencyCd == "") {

                latestUserCurrencyCd = this.user.CurrencyCd.replace(/\s/g, "");
                this.currencyCd = this.user.CurrencyCd.replace(/\s/g, "");
              } else {
                latestUserCurrencyCd = selectedCurrencyCd;
                this.currencyCd = selectedCurrencyCd;
              }
              this.getExchangeRateData(latestUserCurrencyCd);
              // this.getDataApi();
              // this.getRsuApiData();
              // this.getOptionsApiData();
              // this.getPurchaseApiData();
              // this.getFoundersApiData();
              // this.getRSUDivSumApiData();
            }, error => {
              let errorLocation = this.errorLocation.replace("methodName", "getUserDetails");
              error.error.message += errorLocation;
              throw error.error;
            });
          }
          else {
            this.staticContent.setNavBarState(true);
            this.staticContent.setNavLoginState(true);
            if (Object.keys(this.userprofileInfo).length > 0) {
              sessionStorage.setItem(this.globals.profileInfoKey, JSON.stringify(this.userprofileInfo));
              this.sessionCachedUserInfo = true;
              sessionStorage.setItem(this.globals.isUserInfoCached, JSON.stringify(this.sessionCachedUserInfo));
            }

            this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
            this.setBodyData();
            if (sessionStorage.getItem("user input url") != null || sessionStorage.getItem("user input url") != undefined) {
              this.redirectAuthGuard();
            }

            this.getESPPActionItemDetail();
            // this.displayCard(this.user.OptNum);
            this.getVEIPActionItemDetail();
            this.getGrantActionItemDetail();
            this.isNewlyPromotedMD();
            this.getCurrencyList();
            this.performanceGrantMethod();

            let userChanged = JSON.parse(sessionStorage.getItem("Changed User"));

            if (userChanged) {
              sessionStorage.setItem(this.globals.selectedCurrencyCd, JSON.stringify(null));
              sessionStorage.setItem("Changed User", JSON.stringify(false));
            }

            selectedCurrencyCd = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));

            let latestUserCurrencyCd;
            if (selectedCurrencyCd == null || selectedCurrencyCd == "") {
              latestUserCurrencyCd = this.user.CurrencyCd.replace(/\s/g, "");
              this.currencyCd = this.user.CurrencyCd.replace(/\s/g, "");
            } else {
              latestUserCurrencyCd = selectedCurrencyCd;
              this.currencyCd = selectedCurrencyCd;
            }
            this.getExchangeRateData(latestUserCurrencyCd);
            // this.getDataApi();
            // this.getRsuApiData();
            // this.getOptionsApiData();
            // this.getPurchaseApiData();
            // this.getFoundersApiData();
            // this.getRSUDivSumApiData();
          }
        }
        else {
          if (Object.keys(this.userprofileInfo).length > 0) {
            sessionStorage.setItem(this.globals.profileInfoKey, JSON.stringify(this.userprofileInfo));
            this.sessionCachedUserInfo = true;
            sessionStorage.setItem(this.globals.isUserInfoCached, JSON.stringify(this.sessionCachedUserInfo));
          }

          this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
          this.setBodyData();
          if (sessionStorage.getItem("user input url") != null || sessionStorage.getItem("user input url") != undefined) {
            this.redirectAuthGuard();
          }
          this.getESPPActionItemDetail();
          // this.displayCard(this.user.OptNum);
          this.getVEIPActionItemDetail();
          this.isNewlyPromotedMD();
          this.getGrantActionItemDetail();
          this.getCurrencyList();
          this.performanceGrantMethod();

          let userChanged = JSON.parse(sessionStorage.getItem("Changed User"));

          if (userChanged) {
            sessionStorage.setItem(this.globals.selectedCurrencyCd, JSON.stringify(null));
            sessionStorage.setItem("Changed User", JSON.stringify(false));
          }

          selectedCurrencyCd = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));


          let latestUserCurrencyCd;
          if (selectedCurrencyCd == null || selectedCurrencyCd == "") {
            latestUserCurrencyCd = this.user.CurrencyCd.replace(/\s/g, "");
            this.currencyCd = this.user.CurrencyCd.replace(/\s/g, "");
          } else {
            latestUserCurrencyCd = selectedCurrencyCd;
            this.currencyCd = selectedCurrencyCd;
          }
          this.getExchangeRateData(latestUserCurrencyCd);
          // this.getDataApi();
          // this.getRsuApiData();
          // this.getOptionsApiData();
          // this.getPurchaseApiData();
          // this.getFoundersApiData();
          // this.getRSUDivSumApiData();
        }
      }

      this.staticContent.setNavHeaderState(true);
      sessionStorage.setItem(this.globals.isUserLoginUpdated, "true");
      // localStorage.clear();

    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getUserDetails");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  async getHighlightsData() {
    this.hasHighlight = false;
    let temp = '@CurrentPrice';
    this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
    let marketPrice = sessionStorage.getItem("latestPricefromExternalAPI");

    if (sessionStorage.getItem(this.globals.highlightsKey) != null) {
      try {
        let highlightsCached = JSON.parse(sessionStorage.getItem(this.globals.highlightsKey));
        if (highlightsCached.length > 0) {

          this.userHighlights = highlightsCached;
          this.hasHighlight = true;

          sessionStorage.setItem("hasHighlights", "1");

          this.sessionCachedHighlights = true;
          for (let i = 0; i < this.userHighlights.length; i++) {
            if (this.userHighlights[i].Text == null || this.userHighlights[i].Text == undefined || this.userHighlights[i].Text == "") { } else {
              this.userHighlights[i].Text = this.userHighlights[i].Text.replace(temp, this.overrideSharePrice);
            }

          }
        }
      } catch (error) {
        this.errorGetHighlightsData = true;
        let errorLocation = this.errorLocation.replace("methodName", "getHighlightsData");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.highlightsService.getHighlights(this.user.OptNum, 0).subscribe((data) => {
        try {
          if (Object.keys(data[0]).length > 0) {

            this.userHighlights = data[0];
            sessionStorage.setItem(this.globals.highlightsPage, JSON.stringify(data[1]));
            this.hasHighlight = true;

            sessionStorage.setItem("hasHighlights", "1");

            this.sessionCachedHighlights = true;
            for (let i = 0; i < this.userHighlights.length; i++) {
              if (this.userHighlights[i].Text == null || this.userHighlights[i].Text == undefined || this.userHighlights[i].Text == "") { } else {
                this.userHighlights[i].Text = this.userHighlights[i].Text.replace(temp, this.overrideSharePrice);
              }

            }
            sessionStorage.setItem(this.globals.highlightsKey, JSON.stringify(this.userHighlights));

            sessionStorage.setItem(this.globals.isHighlightsCached, JSON.stringify(this.sessionCachedHighlights));


          }
          let transfer = JSON.stringify(this.user.TransfereeTypeCd);
          if (transfer != 'null') {
            this.transfereeTypeCdInd = false;
          }
          else { this.transfereeTypeCdInd = true; }
        } catch (error) {
          this.errorGetHighlightsData = true;
          let errorLocation = this.errorLocation.replace("methodName", "getHighlightsData");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorGetHighlightsData = true;
        let errorLocation = this.errorLocation.replace("methodName", "getHighlightsData");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
    // this.getMarketQuote();
  }

  async getSessionData() {
    try {
      this.userHighlights = JSON.parse(sessionStorage.getItem(this.globals.highlightsKey));
      if (this.userHighlights == null)
        this.hasHighlight = false;
      else
        this.hasHighlight = true;

        sessionStorage.setItem("hasHighlights", "1");

      this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
      // this.getMarketQuote();
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getSessionData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /********************************************************************************************************************************************************
    Method Name: redirectAuthGuard
    Description: redirects users to pages that the user initially inputted in the url.
    #    Date             Modified by                       Description
    1    04/26/19         Joshua Paul Avecilla (JPA)        Initial Draft
    2    05/02/19         JPA                               Remoed else statement
  ********************************************************************************************************************************************************/
  redirectAuthGuard() {
    try {
      let url = sessionStorage.getItem("user input url").split('.com/')[1];
      let tempurl;
      let urlChecker = false;
      for (let i = 1; i <= 24; i++) {
        if (url == myHoldingsPages[i]) {
          tempurl = myHoldingsPages[i]
          urlChecker = true;
        }
      }
      if (urlChecker) {
        if (tempurl == myHoldingsPages[2] || tempurl == myHoldingsPages[4] || tempurl == myHoldingsPages[6] || tempurl == myHoldingsPages[12] ||
          tempurl == myHoldingsPages[21] || tempurl == myHoldingsPages[23] || tempurl == myHoldingsPages[24]) {
          this.navToPage(url);
        }else {
          //this.navToPage("homepage"); //KDP: comment out
        }          
        sessionStorage.removeItem("user input url")
      }
      // else{
      //   this.navToPage(" ");
      // }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "redirectAuthGuard");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
        #     Date            Modified by             Description
        1      01/30/19        Katrina Narag(KMN)      Initial draft
        2     02/05/2019      KMN                     Get contents for market quote
        3      02/07/2019      KMN                     Get last update content
        4      02/08/2019      KMN                     Add timezone if market is close
    **********************************************************************************/
  async getMarketQuote() {
    this.currentprice = 0;
    this.changeprice = 0;
    this.volumeprice = 0;
    this.highprice = 0;
    this.lowprice = 0;
    this.avghighlow = 0;
    let avg: number = 0;
    let date = new Date();

    await this.marketQuoteService.getMarketQuote().pipe(map(res => res)).subscribe(data => {
      try {
        if(this.userTransferee != undefined)
        {
          if (this.userTransferee.TransfereeTypeCd == 2)
          {
            this.errorMarketQuoteCard = true;
          }
        }
        this.errorMarketQuoteCard = false;
        this.marketQuoteData = data;
        this.marketQuoteData.latestPrice = this.marketQuoteData.LastPrice;
        this.marketQuoteData.high = this.marketQuoteData.High;
        this.marketQuoteData.low = this.marketQuoteData.Low;
        let a = this.marketQuoteData;

        if (this.user.PersonnelNumber.indexOf('TS') > -1) {
          sessionStorage.setItem("MarketPriceToday", a.LastPrice);
          this.overrideSharePrice = a.LastPrice;
          this.tempSharePriceHolder = a.LastPrice;
          this.currentprice = a.LastPrice;
          this.changeprice = a.Change;
          this.volumeprice = a.Volume;
          this.highprice = a.High;
          this.lowprice = a.Low;
          this.avghighlow = +this.commafy(((this.highprice + this.lowprice) / 2), 2);

          let c = new Date();
          let tempstring = new Date(a.Timestamp.replace(a.Timestamp.substring(a.Timestamp.indexOf("UTC"), 30), "") + ' UTC-4');

          let timeDisplay;
          let currentTime = new Date(moment(c).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
          let openMarket = new Date(moment().tz("America/New_York").hour(9).minute(30).format("MMM DD YYYY hh:mm A"));
          let closeMarket = new Date(moment().tz("America/New_York").hour(16).minute(0).format("MMM DD YYYY hh:mm A"));


          this.marketTime = moment(tempstring).format("MM DD YYYY hh:mm A");

          if (openMarket <= currentTime && closeMarket >= currentTime) { //Open market
            if (a.Timestamp.toString("Sat") > -1 || a.Timestamp.toString("Sun") > -1) { //checker if weekend
              timeDisplay = moment(this.marketTime).tz("America/New_York").format("MM DD YYYY hh:mm A");
              timeDisplay = timeDisplay.substring(0, 2) + '/' + timeDisplay.substring(3, 5) + "/" + timeDisplay.substring(6);
              this.currentDate = timeDisplay + ' ET';
            }
            else {
              this.marketTime = this.marketTime.substring(0, 2) + '/' + this.marketTime.substring(3, 5) + "/" + this.marketTime.substring(6);
              this.currentDate = this.marketTime + ' LT';
            }
          }
          else { //Closed market
            timeDisplay = moment(tempstring).tz("America/New_York").format("MM DD YYYY hh:mm A");
            let stringCurrentTime = timeDisplay + ' ET';

            this.currentDate = stringCurrentTime.substring(0, 2) + '/' + stringCurrentTime.substring(3, 5) + "/" + stringCurrentTime.substring(6);
          }

          sessionStorage.setItem("marketQuoteDatafromExtAPI", JSON.stringify(this.marketQuoteData));
          let date = new Date;
          let currentDtinMinutes = moment(date).format("MM/DD/YYYY hh:mm A");
          sessionStorage.setItem("datetimeonLoad", currentDtinMinutes);
          sessionStorage.setItem("latestPricefromExternalAPI", this.marketQuoteData.latestPrice);
        }
        else {
          sessionStorage.setItem("MarketPriceToday", a.LastPrice);
          this.overrideSharePrice = a.LastPrice;
          this.tempSharePriceHolder = a.LastPrice;
          this.currentprice = a.LastPrice;
          this.changeprice = a.Change;
          this.volumeprice = a.Volume;
          this.highprice = a.High;
          this.lowprice = a.Low;
          this.avghighlow = +this.commafy(((this.highprice + this.lowprice) / 2), 2);

          let c = new Date();
          let tempstring = new Date(a.Timestamp.replace(a.Timestamp.substring(a.Timestamp.indexOf("UTC"), 30), "") + ' UTC-4');

          let timeDisplay;
          let currentTime = new Date(moment(c).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
          let openMarket = new Date(moment().tz("America/New_York").hour(9).minute(30).format("MMM DD YYYY hh:mm A"));
          let closeMarket = new Date(moment().tz("America/New_York").hour(16).minute(0).format("MMM DD YYYY hh:mm A"));


          this.marketTime = moment(tempstring).format("MM DD YYYY hh:mm A");

          if (openMarket <= currentTime && closeMarket >= currentTime) { //Open market
            if (a.Timestamp.toString("Sat") > -1 || a.Timestamp.toString("Sun") > -1) { //checker if weekend
              timeDisplay = moment(this.marketTime).tz("America/New_York").format("MM DD YYYY hh:mm A");
              timeDisplay = timeDisplay.substring(0, 2) + '/' + timeDisplay.substring(3, 5) + "/" + timeDisplay.substring(6);
              this.currentDate = timeDisplay + ' ET';
            }
            else {
              this.marketTime = this.marketTime.substring(0, 2) + '/' + this.marketTime.substring(3, 5) + "/" + this.marketTime.substring(6);
              this.currentDate = this.marketTime + ' LT';
            }
          }
          else { //Closed market
            timeDisplay = moment(tempstring).tz("America/New_York").format("MM DD YYYY hh:mm A");
            let stringCurrentTime = timeDisplay + ' ET';

            this.currentDate = stringCurrentTime.substring(0, 2) + '/' + stringCurrentTime.substring(3, 5) + "/" + stringCurrentTime.substring(6);
          }

          sessionStorage.setItem("marketQuoteDatafromExtAPI", JSON.stringify(this.marketQuoteData));
          let date = new Date;
          let currentDtinMinutes = moment(date).format("MM/DD/YYYY hh:mm A");
          sessionStorage.setItem("datetimeonLoad", currentDtinMinutes);
          sessionStorage.setItem("latestPricefromExternalAPI", this.marketQuoteData.latestPrice); //HERE!
        }

        // START: KDP
        //var marketDateTime = new Date(a.Timestamp);
        var currentDateTemp = a.Timestamp.replace("T"," ");
        currentDateTemp = currentDateTemp.replace("Z","");

        this.currentDate = moment(currentDateTemp).format("MM/DD/YYYY hh:mm A");        
        //this.currentDate = (marketDateTime.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + "/" + marketDateTime.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})  + "/" + marketDateTime.getFullYear() + " " + marketDateTime.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ":" + marketDateTime.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ":" + marketDateTime.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + " EST";
        this.currentDate = this.currentDate + " EST";
        // END: KDP

        sessionStorage.setItem(this.globals.marketQuoteDatafromDynamoDB, JSON.stringify(this.marketQuoteData));
      } catch (error) {
        this.getMarketQuotefromDB();
        let errorLocation = this.errorLocation.replace("methodName", "getMarketQuote");
        error.message = error.message + errorLocation;
        throw error;
      }
    }, error => {
      this.getMarketQuotefromDB();
      let errorLocation = this.errorLocation.replace("methodName", "getMarketQuote");
      error.error.message += errorLocation;
      throw error.error;
    });
  }

  changeViewport() {
    matchMedia('(max-width: 720px)').addListener((mql => {
      if (mql.matches) {
        this.changeToMobileView();
      } else {
        this.changeToDesktopView();

      }
    }));
  }

  getMarketQuoteInterval() {
    try {
      let count = 0;
      if (this.marketTime == null) {
        let a = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
        if (a.UpdateDt == null) {
          this.marketTime = a.latestTime;
        }
        else {
          this.marketTime = a.UpdateDt;
        }
      }

      let pHTime = new Date();
      let timeInNewYork = new Date(moment(pHTime).tz("America/New_York").format('MMM DD YYYY hh:mm A'));


      let dayToday = moment(timeInNewYork).toString();
      dayToday = dayToday.substring(0, 3);

      // timeInNewYork = moment(timeInNewYork).format('hh:mm A');

      let dateToday = new Date();
      dateToday = new Date(moment(dateToday).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      dateToday = new Date(moment(dateToday).format('MMM DD'));

      let holidayLaborDay = new Date("2019-09-02T12:00:00Z");
      holidayLaborDay = new Date(moment(holidayLaborDay).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      holidayLaborDay = new Date(moment(holidayLaborDay).format('MMM DD'));

      let holidayThanksgivingDay = new Date("2019-11-28T12:00:00Z");
      holidayThanksgivingDay = new Date(moment(holidayThanksgivingDay).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      holidayThanksgivingDay = new Date(moment(holidayThanksgivingDay).format('MMM DD'));

      let holidayChristmasDay = new Date("2019-12-25T12:00:00Z");
      holidayChristmasDay = new Date(moment(holidayChristmasDay).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      holidayChristmasDay = new Date(moment(holidayChristmasDay).format('MMM DD'));

      let holidayNewYearDay = new Date("2020-01-01T12:00:00Z");
      holidayNewYearDay = new Date(moment(holidayNewYearDay).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      holidayNewYearDay = new Date(moment(holidayNewYearDay).format('MMM DD'));

      let holidayMartinLutherKingDay = new Date("2020-01-20T12:00:00Z");
      holidayMartinLutherKingDay = new Date(moment(holidayMartinLutherKingDay).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      holidayMartinLutherKingDay = new Date(moment(holidayMartinLutherKingDay).format('MMM DD'));

      let holidayWashingtonBirthday = new Date("2020-02-17T12:00:00Z");
      holidayWashingtonBirthday = new Date(moment(holidayWashingtonBirthday).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      holidayWashingtonBirthday = new Date(moment(holidayWashingtonBirthday).format('MMM DD'));

      let holidayGoodFriday = new Date("2020-04-10T12:00:00Z");
      holidayGoodFriday = new Date(moment(holidayGoodFriday).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      holidayGoodFriday = new Date(moment(holidayGoodFriday).format('MMM DD'));

      let holidayMemorialDay = new Date("2020-05-25T12:00:00Z");
      holidayMemorialDay = new Date(moment(holidayMemorialDay).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      holidayMemorialDay = new Date(moment(holidayMemorialDay).format('MMM DD'));

      let holidayIndependenceDay = new Date("2020-07-03T12:00:00Z");
      holidayIndependenceDay = new Date(moment(holidayIndependenceDay).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      holidayIndependenceDay = new Date(moment(holidayIndependenceDay).format('MMM DD'));

      // let holidayTrialDay = new Date("2019-07-15T12:00:00Z");
      // holidayTrialDay = new Date(moment(holidayTrialDay).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
      // holidayTrialDay = moment(holidayTrialDay).format('MMM DD');

      // let timeInNewYork2 = new Date(moment().tz("America/New_York").format("MMM DD YYYY hh:mm A"));
      // timeInNewYork2 = moment(timeInNewYork2).format('hh:mm A');

      let openTime = new Date(moment().tz("America/New_York").hour(9).minute(30).format("MMM DD YYYY hh:mm A"));
      // openTime = moment(openTime).format('hh:mm A');

      let closeTime = new Date(moment().tz("America/New_York").hour(16).minute(0).format("MMM DD YYYY hh:mm A"));
      // closeTime = moment(closeTime).format('hh:mm A');

      if (dateToday != holidayLaborDay &&
        dateToday != holidayThanksgivingDay &&
        dateToday != holidayChristmasDay &&
        dateToday != holidayNewYearDay &&
        dateToday != holidayMartinLutherKingDay &&
        dateToday != holidayWashingtonBirthday &&
        dateToday != holidayGoodFriday &&
        dateToday != holidayMemorialDay &&
        dateToday != holidayIndependenceDay) {
        if (dayToday != "Sat" && dayToday != "Sun") {
          let interval = setInterval(() => {
            if (openTime <= timeInNewYork && closeTime >= timeInNewYork) {
              this.getMarketQuotefromDB();
              count += 1200000;
            }
            else {
              clearInterval(interval);
            }
          }, 1200000);
        }
        else {
          // this.loading = false;
        }
      }
      else {
        // this.loading = false;
      }

    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getMarketQuoteInterval");
      error.message += errorLocation;
      throw error;
    }
  }

  changeToDesktopView() {

    this.mobile = false;
    this.desktop = true;
  }

  changeToMobileView() {

    this.desktop = false;
    this.mobile = true;

  }
  /******************************************************************************************************************************************************************************************************
      Method Name: getSharePriceHistoryData
      Description: Pull share price History data for market quote graph.

      Date            Modified by                    Description
      07/03/2019      Joshua Paul Avecilla(JPA)      Initial draft
      07/18/2019      JPA                            added timeout for changing the period.
  ******************************************************************************************************************************************************************************************************/
  async getSharePriceHistoryData(checker) {

    if(this.userTransferee != undefined)
    {
      if (this.userTransferee.TransfereeTypeCd == 2)
      {
        this.errorMarketQuoteCard = true;
      }
    }
    this.errorMarketQuoteCard = false;
    let dateTo;
    let dateFrom;
    let cachedYear;

    let currentDateTo = new Date();

    currentDateTo.setDate(currentDateTo.getDate() - 1);
    let currentDate = new Date();
    let dateToEvent = this.datePipe.transform(currentDateTo, "MM'/'dd'/'yyyy");
    dateTo = moment(dateToEvent).format("DD MMM YYYY");

    if (checker == '5d') {
      currentDate.setDate(currentDate.getDate() - 7);

      let dateModify = currentDate;
      let dateFromEvent = this.datePipe.transform(dateModify, "MM'/'dd'/'yyyy");
      dateFrom = moment(dateFromEvent).format("DD MMM YYYY");
      cachedYear = 'MarketQuoteGraph 5d';

    }
    else if (checker == '1m') {
      currentDate.setMonth(currentDate.getMonth() - 1);
      let dateModify = currentDate;
      let dateFromEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      dateFrom = moment(dateFromEvent).format("DD MMM YYYY");
      cachedYear = 'MarketQuoteGraph 1m';
    }
    else if (checker == '1y') {
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      let dateModify = currentDate;
      let dateFromEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      dateFrom = moment(dateFromEvent).format("DD MMM YYYY");
      cachedYear = 'MarketQuoteGraph 1y';
    }
    else if (checker == '5y') {
      currentDate.setFullYear(currentDate.getFullYear() - 5);
      let dateModify = currentDate;
      let dateFromEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      dateFrom = moment(dateFromEvent).format("DD MMM YYYY");
      cachedYear = 'MarketQuoteGraph 5y';
    }

    if (sessionStorage.getItem(cachedYear) != null) {
      this.marketQuoteLoad = false;
      let tempListData = JSON.parse(sessionStorage.getItem(cachedYear));

      try {
        tempListData.sort(function (a, b) {
          let dateA, dateB;
          dateA = new Date(a.Date);
          dateB = new Date(b.Date);
          return dateA - dateB;
        });

        for (let i = 0; i < tempListData.length; i++) {
          tempListData[i]["Trading Volume"] = this.commafy(tempListData["Trading Volume"]);
          tempListData[i].Date = new Date(tempListData[i].Date);
        }
        this.loading = false;
        setTimeout(() => {

          $('#marketQuoteID > g').empty();

          const svgWidth = 320;
          const svgHeight = 120;
          const bisectDate = d3.bisector(d => d.Date).left;
          var margin = { top: 20, right: 10, bottom: 20, left: 10 };
          var width = svgWidth - margin.left - margin.right;
          var height = svgHeight - margin.top - margin.bottom;
          var svg = d3.select('#marketQuoteID').attr('width', svgWidth).attr('height', svgHeight).append('g')
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

          // Set Chart Background by overriding default canvas
          svg.append('rect')
            .attr('width', '350')
            .attr('height', '90') //165.1
            .attr('fill', 'white')
            // .on('mouseout', function() {
            // focus.transition()
            // .delay(100)
            // .style("opacity",0)
            // .style('pointer-events', 'none');
            // })
            .on('mouseover', function () { focus.style('display', null) }) //.style("opacity",100);
            .on('mousemove', function () {
              const x0 = x.invert(d3.mouse(this)[0]); //x.invert(d3.mouse(this)[0]);
              const i = bisectDate(tempListData, x0, 1);
              const d0 = tempListData[i - 1];
              const d1 = tempListData[i];

              if (d0 != undefined && d1 != undefined) {
                const d = x0 - d0.Date > d1.Date - x0 ? d1 : d0;

                focus.attr('transform', `translate(${x(d.Date)}, ${y(d["Close (USD)"])})`);
                focus.select('line.x')
                  .attr('x1', 0)
                  .attr('x2', -x(d.Date))
                  .attr('y1', 0)
                  .attr('y2', 0);

                focus.select('line.y')
                  .attr('x1', 0)
                  .attr('x2', 0)
                  .attr('y1', 0)
                  .attr('y2', svgWidth - y(d["Close (USD)"]));

                if (y(d["Close (USD)"]) >= 49 && x(d.Date) >= 39 && x(d.Date) <= 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '-50')
                    .attr('y', '-50');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '-30')
                    .attr('dy', '-35')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '-40')
                    .attr('dy', '-19')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                } else if (y(d["Close (USD)"]) < 49 && x(d.Date) >= 52 && x(d.Date) <= 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '-50')
                    .attr('y', '7');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '-30')
                    .attr('dy', '25')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '-40')
                    .attr('dy', '39')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                } else if (y(d["Close (USD)"]) >= 49 && x(d.Date) <= 52 && x(d.Date) <= 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '10')
                    .attr('y', '-37');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '35')
                    .attr('dy', '-21')
                    .attr("style", "font-size: 14px;font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '20')
                    .attr('dy', '-7')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                } else if (y(d["Close (USD)"]) < 49 && x(d.Date) <= 40 && x(d.Date) <= 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '10')
                    .attr('y', '0');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '30')
                    .attr('dy', '15')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '20')
                    .attr('dy', '29')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
               } else if (y(d["Close (USD)"]) >= 49 && x(d.Date) > 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '-107')
                    .attr('y', '-21');
                  focus.select('text.price')
                    .text('$' + d["Close (USD)"])
                    .attr('x', '-82')
                    .attr('dy', '-5')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '-95')
                    .attr('dy', '9')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                } else if (y(d["Close (USD)"]) < 49 && x(d.Date) > 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '-100')
                    .attr('y', '5');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '-80')
                    .attr('dy', '20')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '-90')
                    .attr('dy', '34')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                }

                d3.select('circle').attr('opacity', '1')
              }
            })

          var g = svg.append('g').attr('transform', 'translate(' + 3 + ',' + 0.5 + ')');
          var x = d3.scaleTime().rangeRound([0, width]);
          var y = d3.scaleLinear().rangeRound([height, 0]);

          var line = d3.line().x(function (d) {
           return x(d.Date);
          })
            .y(function (d) {
              return y(d["Close (USD)"]);
            });

          x.domain([tempListData[0].Date, tempListData[tempListData.length - 1].Date]);
          y.domain(d3.extent(tempListData, function (d) { return d["Close (USD)"]; }));

          d3.selectAll('.tick')
            .attr('opacity', '0');

          const focus = svg.append('g')
            .attr('class', 'focus')
            .style('display', 'none');

          focus.append('circle')
            .attr('r', 5)
            // .attr('cx', '10')
            .attr('stroke', 'white')
            .attr('stroke-width', '2')
            .attr('fill', '#9013fe');

          focus.append('line')
            .classed('x', true);

          focus.append('line')
            .classed('y', true);

          focus.append('rect')
            .attr('class', 'tooltipRect')
            .attr('fill', '#d8d8d8')
            .attr("width", "100")
            .attr("height", "40")
            .attr("opacity", "0")
            .attr("rx", 4)
          // .attr("style","stroke-width:1; stroke:#9013fe");

          focus.append('text')
            .attr('class', 'tooltipText')
            .classed('price', true)
            .attr('x', 9)
            .attr('dy', '.35em');

          focus.append('text')
            .attr('class', 'tooltipTextDate')
            .classed('date', true);

          g.append('path')
            .datum(tempListData)
            .attr('fill', 'none')
            .attr('stroke', '#9013fe')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 3)
            .attr('d', line)
            .enter().append("text")
            .attr("class", "path")
            .attr("text-anchor", "middle");
        }, 500);
      } catch (error) {
        let errorLocation = this.errorLocation.replace("methodName", "getSharePriceHistoryData");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.sharePriceService.getSharePriceHistory(dateFrom, dateTo, -99, 'N').subscribe(data => {
        this.loading = false;
        this.marketQuoteLoad = false;
        let tempListData = data[0].slice(0, data[0].length);
        sessionStorage.setItem(cachedYear, JSON.stringify(tempListData));

        try {
          tempListData.sort(function (a, b) {
            let dateA, dateB;
            dateA = new Date(a.Date);
            dateB = new Date(b.Date);
            return dateA - dateB;
          });

          for (let i = 0; i < tempListData.length; i++) {
            tempListData[i]["Trading Volume"] = this.commafy(tempListData["Trading Volume"]);
            tempListData[i].Date = new Date(tempListData[i].Date);
          }

          setTimeout(() => {
            $('#marketQuoteID > g').empty();

            const svgWidth = 320;
            const svgHeight = 120;
            const bisectDate = d3.bisector(d => d.Date).left;
            var margin = { top: 20, right: 10, bottom: 20, left: 10 };
            var width = svgWidth - margin.left - margin.right;
            var height = svgHeight - margin.top - margin.bottom;
            var svg = d3.select('#marketQuoteID').attr('width', svgWidth).attr('height', svgHeight).append('g')
              .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            // Set Chart Background by overriding default canvas
            svg.append('rect')
              .attr('width', '350')
              .attr('height', '90') //165.1
              .attr('fill', 'white')
              // .on('mouseout', function() {
              // focus.transition()
              // .delay(100)
              // .style("opacity",0)
              // .style('pointer-events', 'none');
              // })
              .on('mouseover', function () { focus.style('display', null) }) //.style("opacity",100);
              .on('mousemove', function () {
                const x0 = x.invert(d3.mouse(this)[0]); //x.invert(d3.mouse(this)[0]);
                const i = bisectDate(tempListData, x0, 1);
                const d0 = tempListData[i - 1];
                const d1 = tempListData[i];

                if (d0 != undefined && d1 != undefined) {
                  const d = x0 - d0.Date > d1.Date - x0 ? d1 : d0;

                  focus.attr('transform', `translate(${x(d.Date)}, ${y(d["Close (USD)"])})`);
                  focus.select('line.x')
                    .attr('x1', 0)
                    .attr('x2', -x(d.Date))
                    .attr('y1', 0)
                    .attr('y2', 0);

                  focus.select('line.y')
                    .attr('x1', 0)
                    .attr('x2', 0)
                    .attr('y1', 0)
                    .attr('y2', svgWidth - y(d["Close (USD)"]));

                  if (y(d["Close (USD)"]) >= 49 && x(d.Date) >= 39 && x(d.Date) <= 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '-50')
                      .attr('y', '-50');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '-30')
                      .attr('dy', '-35')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '-40')
                      .attr('dy', '-19')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) < 49 && x(d.Date) >= 52 && x(d.Date) <= 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '-50')
                      .attr('y', '7');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '-30')
                      .attr('dy', '25')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '-40')
                      .attr('dy', '39')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) >= 49 && x(d.Date) <= 52 && x(d.Date) <= 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '10')
                      .attr('y', '-37');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '35')
                      .attr('dy', '-21')
                      .attr("style", "font-size: 14px;font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '20')
                      .attr('dy', '-7')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) < 49 && x(d.Date) <= 40 && x(d.Date) <= 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '10')
                      .attr('y', '0');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '30')
                      .attr('dy', '15')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '20')
                      .attr('dy', '29')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) >= 49 && x(d.Date) > 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '-107')
                      .attr('y', '-21');
                    focus.select('text.price')
                      .text('$' + d["Close (USD)"])
                      .attr('x', '-82')
                      .attr('dy', '-5')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '-95')
                      .attr('dy', '9')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) < 49 && x(d.Date) > 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '-100')
                      .attr('y', '5');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '-80')
                      .attr('dy', '20')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '-90')
                      .attr('dy', '34')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  }

                  d3.select('circle').attr('opacity', '1')
                }
              })

            var g = svg.append('g').attr('transform', 'translate(' + 3 + ',' + 0.5 + ')');
            var x = d3.scaleTime().rangeRound([0, width]);
            var y = d3.scaleLinear().rangeRound([height, 0]);

            var line = d3.line().x(function (d) {
              return x(d.Date);
            })
              .y(function (d) {
                return y(d["Close (USD)"]);
              });

            x.domain([tempListData[0].Date, tempListData[tempListData.length - 1].Date]);
            y.domain(d3.extent(tempListData, function (d) { return d["Close (USD)"]; }));

            d3.selectAll('.tick')
              .attr('opacity', '0');

            const focus = svg.append('g')
              .attr('class', 'focus')
              .style('display', 'none');

            focus.append('circle')
              .attr('r', 5)
              // .attr('cx', '10')
              .attr('stroke', 'white')
              .attr('stroke-width', '2')
              .attr('fill', '#9013fe');

            focus.append('line')
              .classed('x', true);

            focus.append('line')
              .classed('y', true);

            focus.append('rect')
              .attr('class', 'tooltipRect')
              .attr('fill', '#d8d8d8')
              .attr("width", "100")
              .attr("height", "40")
              .attr("opacity", "0")
              .attr("rx", 4)
            // .attr("style","stroke-width:1; stroke:#9013fe");

            focus.append('text')
              .attr('class', 'tooltipText')
              .classed('price', true)
              .attr('x', 9)
              .attr('dy', '.35em');

            focus.append('text')
              .attr('class', 'tooltipTextDate')
              .classed('date', true);

            g.append('path')
              .datum(tempListData)
              .attr('fill', 'none')
              .attr('stroke', '#9013fe')
              .attr('stroke-linejoin', 'round')
              .attr('stroke-linecap', 'round')
              .attr('stroke-width', 3)
              .attr('d', line)
              .enter().append("text")
              .attr("class", "path")
              .attr("text-anchor", "middle");
          }, 500);
        } catch (error) {
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getSharePriceHistoryData");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getSharePriceHistoryData");
        error.error.message += errorLocation;
        throw error.error;
      });
    }

    //}

  }


  /******************************************************************************************************************************************************************************************************
      Method Name: marketQuoteGraph
      Description: Builds market quote graph

      Date            Modified by                    Description
      02/06/2019      Joshua Paul Avecilla(JPA)      Initial draft
      03/11/19        Jayson Sevilla(JSS)            Market Quote Graph Tooltip
      04/02/19        JPA                            Change display to label instead of date due to BUG 240834 - Market quote graph tool tip details for previous share price and date is not accurate
      06/20/19        JPA                            Adjustment of graph design, tooltip and graph points.
  ******************************************************************************************************************************************************************************************************/
  // async marketQuoteGraph(checker) {

  //   // let testData = []
  //   // testData.push({"date": "May 29 2019", "label":"May 29", "close": 178.54});
  //   // testData.push({"date": "May 30 2019", "label":"May 30", "close": 179.57});
  //   // testData.push({"date": "May 31 2019", "label":"May 31", "close": 180.39});
  //   // testData.push({"date": "Jun 01 2019", "label":"Jun 01", "close": 178.27});
  //   // testData.push({"date": "Jun 02 2019", "label":"Jun 02", "close": 178.81});
  //   // testData.push({"date": "Jun 03 2019", "label":"Jun 03", "close": 178.39});
  //   // testData.push({"date": "Jun 04 2019", "label":"Jun 04", "close": 177.61});
  //   // testData.push({"date": "Jun 05 2019", "label":"Jun 05", "close": 178.82});
  //   // testData.push({"date": "Jun 06 2019", "label":"Jun 06", "close": 178.07});
  //   // testData.push({"date": "Jun 07 2019", "label":"Jun 07", "close": 175.16});
  //   // testData.push({"date": "Jun 08 2019", "label":"Jun 08", "close": 177.97});
  //   // testData.push({"date": "Jun 09 2019", "label":"Jun 09", "close": 179.56});
  //   // testData.push({"date": "Jun 10 2019", "label":"Jun 10", "close": 180.4});
  //   // testData.push({"date": "Jun 11 2019", "label":"Jun 11", "close": 182.92});
  //   // testData.push({"date": "Jun 12 2019", "label":"Jun 12", "close": 184.44});
  //   // testData.push({"date": "Jun 13 2019", "label":"Jun 13", "close": 183.37});
  //   // testData.push({"date": "Jun 14 2019", "label":"Jun 14", "close": 184.59});
  //   // testData.push({"date": "Jun 15 2019", "label":"Jun 15", "close": 184.95});
  //   // testData.push({"date": "Jun 16 2019", "label":"Jun 16", "close": 184.97});
  //   // testData.push({"date": "Jun 17 2019", "label":"Jun 17", "close": 183.9});
  //   // testData.push({"date": "Jun 18 2019", "label":"Jun 18", "close": 184.5});
  //   // testData.push({"date": "Jun 19 2019", "label":"Jun 19", "close": 183.95});

  //   this.marketQuoteService.getMarketQuoteHistorical(checker).map(res => res).subscribe(data => {
  //     try {

  //       this.marketQuoteHistoricalData = data["chart"];
  //       // this.marketQuoteHistoricalData = testData;

  //       var arr = this.marketQuoteHistoricalData;
  //       for (let i = 0; i < arr.length; i++) {
  //         arr[i].date = new Date(arr[i].date);
  //       }


  //       const svgWidth = 320;
  //       const svgHeight = 90;
  //       const bisectDate = d3.bisector(d => d.date).left;
  //       var margin = { top: 20, right: 20, bottom: 20, left: 20 };
  //       var width = svgWidth - margin.left - margin.right;
  //       var height = svgHeight - margin.top - margin.bottom;
  //       var svg = d3.select('svg').attr('width', svgWidth).attr('height', svgHeight);

  //       // Set Chart Background by overriding default canvas
  //       svg.append('rect')
  //         .attr('width', '350')
  //         .attr('height', '90') //165.1
  //         .attr('fill', 'white')
  //         .on('mouseover', function () { focus.style('display', null); })
  //         .on('mousemove', function () {
  //           const x0 = x.invert(d3.mouse(this)[0]);
  //           const i = bisectDate(arr, x0, 1);
  //           const d0 = arr[i - 1];
  //           const d1 = arr[i];

  //           if (d0 != undefined && d1 != undefined) {
  //             const d = x0 - d0.date > d1.date - x0 ? d1 : d0;

  //             focus.attr('transform', `translate(${x(d.date)}, ${y(d.close)})`);
  //             focus.select('line.x')
  //               .attr('x1', 0)
  //               .attr('x2', -x(d.date))
  //               .attr('y1', 0)
  //               .attr('y2', 0);

  //             focus.select('line.y')
  //               .attr('x1', 0)
  //               .attr('x2', 0)
  //               .attr('y1', 0)
  //               .attr('y2', svgWidth - y(d.close));

  //             if (y(d.close) >= 49 && x(d.date) >= 39 && x(d.date) <= 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-50')
  //                   .attr('y', '-45');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-30')
  //                   .attr('dy', '-29')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '-40')
  //                   .attr('dy', '-14')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-50')
  //                   .attr('y', '7');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-30')
  //                   .attr('dy', '25')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '-40')
  //                   .attr('dy', '39')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) < 49 && x(d.date) >= 41 && x(d.date) <= 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-50')
  //                   .attr('y', '7');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-30')
  //                   .attr('dy', '25')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '-40')
  //                   .attr('dy', '39')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-50')
  //                   .attr('y', '7');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-30')
  //                   .attr('dy', '25')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '-40')
  //                   .attr('dy', '39')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) >= 49 && x(d.date) <= 41 && x(d.date) <= 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '10')
  //                   .attr('y', '-37');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '35')
  //                   .attr('dy', '-21')
  //                   .attr("style", "font-size: 14px;font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '20')
  //                   .attr('dy', '-7')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '10')
  //                   .attr('y', '-37');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '35')
  //                   .attr('dy', '-21')
  //                   .attr("style", "font-size: 14px;font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '20')
  //                   .attr('dy', '-7')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) < 49 && x(d.date) <= 40 && x(d.date) <= 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '10')
  //                   .attr('y', '0');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '15')
  //                   .attr('dy', '15')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '15')
  //                   .attr('dy', '29')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '10')
  //                   .attr('y', '0');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '15')
  //                   .attr('dy', '15')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '15')
  //                   .attr('dy', '29')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) >= 49 && x(d.date) > 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-107')
  //                   .attr('y', '-21');
  //                 focus.select('text.price')
  //                   .text('$' + d.close)
  //                   .attr('x', '-82')
  //                   .attr('dy', '-5')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '-95')
  //                   .attr('dy', '9')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-107')
  //                   .attr('y', '-21');
  //                 focus.select('text.price')
  //                   .text('$' + d.close)
  //                   .attr('x', '-82')
  //                   .attr('dy', '-5')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '-95')
  //                   .attr('dy', '9')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) < 49 && x(d.date) > 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-100')
  //                   .attr('y', '5');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-80')
  //                   .attr('dy', '20')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '-90')
  //                   .attr('dy', '34')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-100')
  //                   .attr('y', '5');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-80')
  //                   .attr('dy', '20')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '-90')
  //                   .attr('dy', '34')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             }

  //             d3.select('circle').attr('opacity', '1')
  //           }
  //         })

  //       var g = svg.append('g').attr('transform', 'translate(' + 3 + ',' + 0.5 + ')');
  //       var x = d3.scaleTime().rangeRound([0, width]);
  //       var y = d3.scaleLinear().rangeRound([height, 0]);

  //       var line = d3.line().x(function (d) {
  //         return x(d.date);
  //       })
  //         .y(function (d) {
  //           return y(d.close);
  //         });

  //       x.domain([arr[0].date, arr[arr.length - 1].date]);
  //       y.domain(d3.extent(arr, function (d) { return d.close; }));

  //       d3.selectAll('.tick')
  //         .attr('opacity', '0');

  //       const focus = svg.append('g')
  //         .attr('class', 'focus')
  //         .style('display', 'none');

  //       focus.append('circle')
  //         .attr('r', 5)
  //         // .attr('cx', '10')
  //         .attr('stroke', 'white')
  //         .attr('stroke-width', '2')
  //         .attr('fill', '#9013fe');

  //       focus.append('line')
  //         .classed('x', true);

  //       focus.append('line')
  //         .classed('y', true);

  //       focus.append('rect')
  //         .attr('fill', '#d8d8d8')
  //         .attr("width", "100")
  //         .attr("height", "40")
  //         .attr("opacity", "0")
  //         .attr("rx", 4)
  //       // .attr("style","stroke-width:1; stroke:#9013fe");

  //       focus.append('text')
  //         .classed('price', true)
  //         .attr('x', 9)
  //         .attr('dy', '.35em');

  //       focus.append('text')
  //         .classed('date', true);

  //       g.append('path')
  //         .datum(arr)
  //         .attr('fill', 'none')
  //         .attr('stroke', '#9013fe')
  //         .attr('stroke-linejoin', 'round')
  //         .attr('stroke-linecap', 'round')
  //         .attr('stroke-width', 3)
  //         .attr('d', line)
  //         .enter().append("text")
  //         .attr("class", "path")
  //         .attr("text-anchor", "middle");




  //     } catch (error) {
  //       let errorLocation = this.errorLocation.replace("methodName", "marketQuoteGraph");
  //       error.message = error.message + errorLocation;
  //       throw error;
  //     }
  //   }, error => {
  //     let errorLocation = this.errorLocation.replace("methodName", "marketQuoteGraph");
  //     error.error.message += errorLocation;
  //     throw error.error;
  //   });


  // }

  selectMarketQuotePeriod(checker) {
    try {
      this.marketQuotePeriodSelected = checker;
      this.marketQuoteLoad = true;
      if (checker == '5 days') {
        this.getSharePriceHistoryData('5d');
        // this.marketQuoteGraph('5d');
      }
      else if (checker == '1 month') {
        this.getSharePriceHistoryData('1m');
        // this.marketQuoteGraph('1m');
      }
      else if (checker == '1 year') {
        this.getSharePriceHistoryData('1y');
        // this.marketQuoteGraph('1y');
      }
      else if (checker == '5 years') {
        this.getSharePriceHistoryData('5y');
        // this.marketQuoteGraph('5y');
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectMarketQuotePeriod");
      error.message += errorLocation;
      throw error;
    }
  }


  colorMe() {
    d3.select('button').style('color', 'red');
  }

  clicked(event: any) {
  }

  async overrideCurrentSharePrice(override) {

    let regex: RegExp = new RegExp(/^\d{0,9}(\.\d{0,9})?$/g);

    try {

      if (override.toString().match(regex) != null) {
        this.overrideSharePrice = override;
        this.tempSharePriceHolder = override;
      } else {
        this.overrideSharePrice = this.tempSharePriceHolder;
      }

      this.getPurchaseProgramData();
      this.getRSUData();
      this.getFounderSharesData();
      this.getOptionsData();
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "overrideCurrentSharePrice");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  async keyUpExchangeRate(keyUpExchangeRate) {
    try {
      let regex: RegExp = new RegExp(/^\d{0,9}(\.\d{0,9})?$/g);

      if (keyUpExchangeRate.match(regex) != null) {
        this.exchangeRate = keyUpExchangeRate;
        this.tempRateHolder = keyUpExchangeRate;
      }
      else {
        this.exchangeRate = this.tempRateHolder;
      }

      this.getPurchaseProgramData();
      this.getRSUData();
      this.getFounderSharesData();
      this.getOptionsData();
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "keyUpExchangeRate");
      error.message = error.message + errorLocation;
      throw error;
    }
  }



  async refreshCurrentSharePrice() {
    try {
      let currentCurrencyCd = this.currencyValue;
      let currentPriceToday = sessionStorage.getItem("MarketPriceToday");
      let list = this.exchangeRateList;

      this.overrideSharePrice = currentPriceToday;
      this.tempSharePriceHolder = currentPriceToday;
      let locExchageRate = list.find(i => i.CNVT_TO_CURR_CD === currentCurrencyCd);
      this.exchangeRate = locExchageRate.EXCH_RT;
      this.tempRateHolder = locExchageRate.EXCH_RT;

      this.getPurchaseProgramData();
      this.getRSUData();
      this.getFounderSharesData();
      this.getOptionsData();
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "refreshCurrentSharePrice");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  async getMarketQuotefromDB() {
    this.currentprice = 0;
    this.changeprice = 0;
    this.volumeprice = 0;
    this.highprice = 0;
    this.lowprice = 0;
    this.avghighlow = 0;
    let avg: number = 0;
    let date = new Date();

    await this.marketQuoteService.getMarketQuotefromDB().pipe(map(res => res)).subscribe(data => {
      try {
        if(this.userTransferee != undefined)
        {
          if (this.userTransferee.TransfereeTypeCd == 2)
          {
            this.errorMarketQuoteCard = true;
          }
        }
        this.errorMarketQuoteCard = false;
        this.marketQuoteData = data;
        this.marketQuoteData.latestPrice = this.marketQuoteData.LastPrice;
        this.marketQuoteData.high = this.marketQuoteData.High;
        this.marketQuoteData.low = this.marketQuoteData.Low;
        let a = this.marketQuoteData;

        if (this.user.PersonnelNumber.indexOf('TS') > -1) {

        } else {
          sessionStorage.setItem("MarketPriceToday", a.LastPrice);
          this.overrideSharePrice = a.LastPrice;
          this.tempSharePriceHolder = a.LastPrice;
          this.currentprice = a.LastPrice;
          this.changeprice = a.Change;
          this.volumeprice = a.Volume;
          this.highprice = a.High;
          this.lowprice = a.Low;
          this.avghighlow = +this.commafy(((this.highprice + this.lowprice) / 2), 2);

          let c = new Date();
          let tempstring = new Date(a.Timestamp);

          let timeDisplay;
          let currentTime = new Date(moment(c).tz("America/New_York").format('MMM DD YYYY hh:mm A'));
          let openMarket = new Date(moment().tz("America/New_York").hour(9).minute(30).format("MMM DD YYYY hh:mm A"));
          let closeMarket = new Date(moment().tz("America/New_York").hour(16).minute(0).format("MMM DD YYYY hh:mm A"));
          this.marketTime = moment(tempstring).format("MM DD YYYY hh:mm A");

          if (openMarket <= currentTime && closeMarket >= currentTime) { //Open market
            if (a.Timestamp.toString("Sat") > -1 || a.Timestamp.toString("Sun") > -1) { //checker if weekend
              timeDisplay = moment(this.marketTime).tz("America/New_York").format("MM DD YYYY hh:mm A");
              timeDisplay = timeDisplay.substring(0, 2) + '/' + timeDisplay.substring(3, 5) + "/" + timeDisplay.substring(6);
              this.currentDate = timeDisplay + ' ET';
            }
            else {
              this.marketTime = this.marketTime.substring(0, 2) + '/' + this.marketTime.substring(3, 5) + "/" + this.marketTime.substring(6);
              this.currentDate = this.marketTime + ' LT';
            }
          }
          else { //Closed market
            timeDisplay = moment(tempstring).tz("America/New_York").format("MM DD YYYY hh:mm A");
            let stringCurrentTime = timeDisplay + ' ET';

            this.currentDate = stringCurrentTime.substring(0, 2) + '/' + stringCurrentTime.substring(3, 5) + "/" + stringCurrentTime.substring(6);
          }

          sessionStorage.setItem("marketQuoteDatafromExtAPI", JSON.stringify(this.marketQuoteData));
          let date = new Date;
          let currentDtinMinutes = moment(date).format("MM/DD/YYYY hh:mm A");
          sessionStorage.setItem("datetimeonLoad", currentDtinMinutes);
          sessionStorage.setItem("latestPricefromExternalAPI", this.marketQuoteData.latestPrice);
        }
        // START: KDP
        //var marketDateTime = new Date(a.Timestamp);
        var currentDateTemp = a.Timestamp.replace("T"," ");
        currentDateTemp = currentDateTemp.replace("Z","");

        this.currentDate = moment(currentDateTemp).format("MM/DD/YYYY hh:mm A");   
     
        //this.currentDate = (marketDateTime.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + "/" + marketDateTime.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})  + "/" + marketDateTime.getFullYear() + " " + marketDateTime.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ":" + marketDateTime.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ":" + marketDateTime.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + " EST";
        this.currentDate = this.currentDate + " EST";
        // END: KDP
        sessionStorage.setItem(this.globals.marketQuoteDatafromDynamoDB, JSON.stringify(this.marketQuoteData));
      } catch (error) {
        let errorLocation = this.errorLocation.replace("methodName", "getMarketQuotefromDB");
        error.message = error.message + errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getMarketQuotefromDB");
      error.error.message += errorLocation;
      throw error.error;
    });
  }

  async getExchangeRateList() {
    try {
      let data = JSON.parse(sessionStorage.getItem("Exchange Rate List"));
      this.exchangeRateList = data;
      this.setLocalCurrency(this.user.CurrencyCd.replace(/\s/g, ""));


    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateList");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  async getExchangeRateData(currencyCd) {
    await this.holdingsummaryservice.getExchangeRate(currencyCd).pipe(map(res => res)).subscribe(data => {
      try {
        this.currencyCdSymbol = CurrencySymbol[currencyCd.replace(/\s/g, "")];
        this.exchangeRate = data.Exch_Rt;
        this.tempRateHolder = data.Exch_Rt;
        this.getDataApi();
        this.getRsuApiData();
        this.getOptionsApiData();
        this.getPurchaseApiData();
        this.getFoundersApiData();
        this.getRSUDivSumApiData();
        
      } catch (error) {
        let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateData");
        error.message = error.message + errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateData");
      error.error.message += errorLocation;
      throw error.error;
    });
  }


  async getCurrencyList() {
    if (sessionStorage.getItem("CurrencyListCacheData") != null) {
      let cacheData = JSON.parse(sessionStorage.getItem("CurrencyListCacheData"));
      this.completeCurrencyList = await this.sortData(cacheData, 'Value');

      for(let i=0; i<this.completeCurrencyList.length; i++){
        let splitData = this.completeCurrencyList[i]["Value"].split("-");

        this.completeCurrencyList[i]["Value3"] = splitData[0];
        this.completeCurrencyList[i]["Value4"] = splitData[1];
      }

    }
    else {
      await this.marketQuoteService.getCurrencyList().pipe(map(res => res)).subscribe(async data => {
        try {
          sessionStorage.setItem("CurrencyListCacheData", JSON.stringify(data));
          this.completeCurrencyList = await this.sortData(data, 'Value');

          for(let i=0; i<this.completeCurrencyList.length; i++){
            let splitData = this.completeCurrencyList[i]["Value"].split("-");
    
            this.completeCurrencyList[i]["Value3"] = splitData[0];
            this.completeCurrencyList[i]["Value4"] = splitData[1];
          }

        } catch (error) {
          let errorLocation = this.errorLocation.replace("methodName", "getCurrencyList");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getCurrencyList");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  //this block of code re-arranges the array to alphabetical order
  async sortData(data, fieldName) {
    try {
      data.sort(function (a, b) {
        var array = [a[fieldName], b[fieldName]];
        array.sort();
        if (array[0] == a[fieldName])
          return -1;
        else if (array[0] == b[fieldName])
          return 1;
        else
          return 0;
      });
      return data;
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "sortData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: setLocalCurrency
      Description: Set currency code value based on user's choice

      #     Date            Modified by         Description
      1     02/12/19        Katrina Narag(KMN)  Added logic in getting latest currency code value
      2     03/27/19        Dalemark P. Suquib  Added condition when currencyValue is equal to null
  **********************************************************************************/
  async setLocalCurrency(localCurrency) {
    try {
      let curCode: any;

      let selectedCurrencyCd = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));  //KMN, 1
      let latestUserCurrencyCd;

      if (selectedCurrencyCd == null || selectedCurrencyCd == "") {
        latestUserCurrencyCd = this.user.CurrencyCd.replace(/\s/g, "");
        this.currencyCd = this.user.CurrencyCd.replace(/\s/g, "");
      } else {
        latestUserCurrencyCd = selectedCurrencyCd;
        this.currencyCd = selectedCurrencyCd;
      }

      this.currencyCd = latestUserCurrencyCd;
      curCode = localCurrency;

      let list = this.exchangeRateList;
      let overrideExchangeRate = list.find(i => i.CNVT_TO_CURR_CD === curCode);

      let k = sessionStorage.getItem("isOverride");

      if (k != "1") {
        this.currencyValue = localCurrency;
        this.exchangeRate = overrideExchangeRate.EXCH_RT;
        this.tempRateHolder = overrideExchangeRate.EXCH_RT;
        this.currencyCd = localCurrency;
        sessionStorage.setItem(this.globals.localCurrencyCd, JSON.stringify(localCurrency));
      } else {
        this.currencyValue = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));

        if (this.currencyValue == null) {
          this.currencyValue = localCurrency;
        }
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "setLocalCurrency");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  async setcurrentCurrency(currentCurrency) {
    try {
      let data = currentCurrency;
      let splitData = data.split("-");
      let curCode = splitData[1];
      this.currencyCd = curCode;
      this.currencyChanged = true;

      this.userService.updatePreferenceCd(curCode, this.hideAwardsInd, this.user.OptNum);

      let list = this.exchangeRateList;
      let overrideExchangeRate = list.find(i => i.CNVT_TO_CURR_CD === curCode);

      this.exchangeRate = overrideExchangeRate.EXCH_RT;
      this.tempRateHolder = overrideExchangeRate.EXCH_RT;
      this.currencyValue = curCode;
      this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")];

      sessionStorage.setItem("isOverride", "1");
      sessionStorage.setItem(this.globals.selectedCurrencyCd, JSON.stringify(curCode));

      this.overrideCurrentSharePrice(this.overrideSharePrice);

    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "setcurrentCurrency");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  async getDataApi() {
    if (sessionStorage.getItem("Exchange Rate List")) {
      try {
        let body = sessionStorage.getItem("Exchange Rate List");

        if (this.user.PersonnelNumber.indexOf('TS') > -1) {
          //DO NOTHING
          this.loading = false;
        }
        else {
          // this.getRsuApiData();
          // this.getOptionsApiData();
          // this.getPurchaseApiData();
          // this.getFoundersApiData();
          this.getExchangeRateList();
          // this.getRSUDivSumApiData();
          this.getMarketQuoteInterval();
        }

      } catch (error) {
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.marketQuoteService.getExhangeRateList().pipe(map(res => res)).subscribe(data => {
        try {
          let body = data;
          sessionStorage.setItem("Exchange Rate List", JSON.stringify(body));

          if (this.user.PersonnelNumber.indexOf('TS') > -1) {
            //DO NOTHING
            this.loading = false;
          }
          else {
            // this.getRsuApiData();
            // this.getOptionsApiData();
            // this.getPurchaseApiData();
            // this.getFoundersApiData();
            // this.getRSUDivSumApiData();
            this.getExchangeRateList();
            this.getMarketQuoteInterval();
          }

        } catch (error) {
          let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getDataApi");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  setBarGraph() {
    try {
      this.HorizontalBar = new Chart("canvas", {
        type: 'horizontalBar',
        data: {
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "setBarGraph");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
    Method Name: getRsuApiData
    Description: Get RSU data

    #     Date            Modified by         Description
    1     07/02/2019      JPA                 Added sessionStorage for data. (Performance test)
**********************************************************************************/
  async getRsuApiData() {

    if (sessionStorage.getItem("RSU Data") != null) {
      try {
        this.errorRSU = false;
        this.getRSUData();
      } catch (error) {
        this.errorRSU = true;
        let errorLocation = this.errorLocation.replace("methodName", "getRsuApiData");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.holdingsummaryservice.getRSU(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorRSU = false;
          let body = data;
          sessionStorage.setItem("RSU Data", JSON.stringify(body));
          this.getRSUData();
        } catch (error) {
          this.errorRSU = true;
          let errorLocation = this.errorLocation.replace("methodName", "getRsuApiData");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorRSU = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getRsuApiData");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  /**********************************************************************************
    Method Name: getFoundersApiData
    Description: Get Founder data

    #     Date            Modified by         Description
    1     07/02/2019      JPA                 Added sessionStorage for data. (Performance test)
  **********************************************************************************/
  async getFoundersApiData() {
    if (sessionStorage.getItem("Founder Share Data") != null) {
      try {
        this.errorFounder = false;
        this.getFounderSharesData();
      } catch (error) {
        this.errorFounder = true;
        let errorLocation = this.errorLocation.replace("methodName", "getFoundersApiData");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.holdingsummaryservice.getFounderShares(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorFounder = false;
          let body = data;
          sessionStorage.setItem("Founder Share Data", JSON.stringify(body));
          this.getFounderSharesData();
        } catch (error) {
          this.errorFounder = true;
          let errorLocation = this.errorLocation.replace("methodName", "getFoundersApiData");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorFounder = true;
        let errorLocation = this.errorLocation.replace("methodName", "getFoudersApiData");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  /**********************************************************************************
    Method Name: getOptionsApiData
    Description: Get Options data

    #     Date            Modified by         Description
    1     07/02/2019      JPA                 Added sessionStorage for data. (Performance test)
  **********************************************************************************/
  async getOptionsApiData() {
    if (sessionStorage.getItem("Options Data") != null) {
      try {
        this.errorOption = false;

        this.getOptionsData();
      } catch (error) {
        this.errorOption = true;
        let errorLocation = this.errorLocation.replace("methodName", "getOptionsApiData");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.holdingsummaryservice.getOptions(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorOption = false;
          let body = data;
          sessionStorage.setItem("Options Data", JSON.stringify(body));

          this.getOptionsData();
        } catch (error) {
          this.errorOption = true;
          let errorLocation = this.errorLocation.replace("methodName", "getOptionsApiData");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorOption = true;
        let errorLocation = this.errorLocation.replace("methodName", "getOptionsApiData");
        error.error.message += errorLocation;
        throw error.error;
      });
    }

  }

  /**********************************************************************************
  Method Name: getPurchaseApiData
  Description: Get Purchase program data

  #     Date            Modified by         Description
  1     07/02/2019      JPA                 Added sessionStorage for data. (Performance test)
**********************************************************************************/
  async getPurchaseApiData() {
    if (sessionStorage.getItem("Purchase Program Data") != null) {
      try {
        this.errorESPP = false;
        this.errorVEIP = false;
        this.getPurchaseProgramData(); //clear
      } catch (error) {
        this.errorESPP = true;
        this.errorVEIP = true;
        let errorLocation = this.errorLocation.replace("methodName", "getPurchaseApiData");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
     await this.holdingsummaryservice.getPurchaseProgram(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorESPP = false;
          this.errorVEIP = false;
          let body = data;
          sessionStorage.setItem("Purchase Program Data", JSON.stringify(body));
          this.getPurchaseProgramData(); //clear
        } catch (error) {
          this.errorESPP = true;
          this.errorVEIP = true;
          let errorLocation = this.errorLocation.replace("methodName", "getPurchaseApiData");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorESPP = true;
        this.errorVEIP = true;
        let errorLocation = this.errorLocation.replace("methodName", "getPurchaseApiData");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  /**********************************************************************************
    Method Name: getRSUDivSumApiData
    Description: Get RSU Div summary data

    #     Date            Modified by         Description
    1     07/02/2019      JPA                 Added sessionStorage for data. (Performance test)
  **********************************************************************************/
  async getRSUDivSumApiData() {
    if (sessionStorage.getItem('RSUDivSumCachedData') != null) {
      try {
        this.errorDivSummaryCard = false;
        let body = JSON.parse(sessionStorage.getItem('RSUDivSumCachedData'));
        if (body[0][0] != undefined) {
          this.divSumRSUQty = body[0][0].TotalDividendsQty;
          this.divSumRSUAmt = body[0][0].TotalDividendsAmt;
        }
        else {
          this.divSumRSUQty = 0;
          this.divSumRSUAmt = 0;
        }
      } catch (error) {
        this.errorDivSummaryCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDivSumApiData");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.holdingsummaryservice.getRSUDivSum(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorDivSummaryCard = false;
          let body = data;
          if (body[0][0] != undefined) {
            this.divSumRSUQty = body[0][0].TotalDividendsQty;
            this.divSumRSUAmt = body[0][0].TotalDividendsAmt;
          }
          else {
            this.divSumRSUQty = 0;
            this.divSumRSUAmt = 0;
          }
          sessionStorage.setItem('RSUDivSumCachedData', JSON.stringify(data));
        } catch (error) {
          this.errorDivSummaryCard = true;
          let errorLocation = this.errorLocation.replace("methodName", "getRSUDivSumApiData");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorDivSummaryCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDivSumApiData");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  /**********************************************************************************
      Method Name: getPurchaseProgramData
      Description: Get purchase program data

      #     Date            Modified by           Description
      1     02/14/19        Katrina Narag(KMN)    Reset to 0 to prevent variables to double values
  **********************************************************************************/
  // PURCHASE PROGRAM DETAILS SUMMARY START
  async getPurchaseProgramData() {
    this.totalpurchaseAvailabletoSellESPP = 0;  //KMN, 1
    this.totalpurchaseAvailabletoSellVEIP = 0;  //KMN, 1
    this.totalPurchaseOutStandingSharesESPP = 0;
    this.totalPurchaseOutStandingSharesVEIP = 0;
    try {
      this.errorESPP = false;
      this.errorVEIP = false;
      let data = JSON.parse(sessionStorage.getItem("Purchase Program Data"));
      let body = data;

      if (data[1].length > 0) {
        this.showPurchData = true;

        sessionStorage.setItem("showPurchData", "1");
        this.totalPurchaseSharesOutStanding = body[0][0].Outstanding; //Total Shares Outstanding
        this.purchaseGraph = body[0][0].Outstanding;
        if (this.totalPurchaseSharesOutStanding == null) this.totalPurchaseSharesOutStanding = 0;

        this.totalPurchaseAvailableToSell = body[0][0].AvailableToSell; //Total Available to Sell
        if (this.totalPurchaseAvailableToSell == null) this.totalPurchaseAvailableToSell = 0;

        this.totalPurchaseUnReleased = body[0][0].UnreleasedUnexercisable;

        let sortedBody = data[1].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);

        if (sortedBody.length > 0) {
          this.hasAwards = true;
          sessionStorage.setItem("hasAwards", "1");
        }

        let PurchaseProgram = data[1];
        for (let i = 0; i < data[1].length; i++) {
          if (PurchaseProgram[i].HoldingsType == 5) {
            this.purchaseAvailabletoSellESPP = data[1][i].AvailableToSell;
            this.totalpurchaseAvailabletoSellESPP += this.purchaseAvailabletoSellESPP;
            this.totalPurchaseOutStandingSharesESPP += data[1][i].Outstanding;
          }
          else {
            this.purchaseAvailabletoSellVEIP = data[1][i].AvailableToSell;
            this.totalpurchaseAvailabletoSellVEIP += this.purchaseAvailabletoSellVEIP;
            this.totalPurchaseOutStandingSharesVEIP += data[1][i].Outstanding;
          }
        }

        sessionStorage.setItem("hasESPP", "1");
        sessionStorage.setItem("hasVEIP", "1");

        this.getSumPurchaseProgram(sortedBody);
      }

      this.checkAwards();
    } catch (error) {
      this.errorESPP = true;
      this.errorVEIP = true;
      let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /**********************************************************************************************************************************************
      Method Name: getSumPurchaseProgram
      Description: Get the total value for Purchase Program shares. (Available to sell and Outstanding shares)

      #     Date            Modified by                Description
      1     02/14/19        Katrina Narag(KMN)         Reset to 0 to prevent variables to double values
      2     03/22/19        Joshua Paul Avecilla(JPA)  Added variables and computation for ESPP and VEIP outstanding shares and value
  *********************************************************************************************************************************************/
  async getSumPurchaseProgram(values) {
    try {
      this.valueAvailableToSell = 0;
      this.valuePurchaseOutStandingSharesESPP = 0;
      this.valuePurchaseOutStandingSharesVEIP = 0;
      this.valueSharesOustanding = this.totalPurchaseSharesOutStanding * this.overrideSharePrice * this.exchangeRate;
      for (let i = 0; i < Object.keys(values).length; i++) {
        values[i].AvailableToSellValue = (values[i].AvailableToSell * 1) * this.overrideSharePrice * this.exchangeRate;
        values[i].AvailableToSellValue = values[i].AvailableToSellValue;
        this.valueAvailableToSell += (values[i].AvailableToSellValue * 1);
        if (values[i].HoldingsType == 5) {
          this.valuePurchaseOutStandingSharesESPP += values[i].Outstanding * this.overrideSharePrice * this.exchangeRate;
        }
        else {
          this.valuePurchaseOutStandingSharesVEIP += values[i].Outstanding * this.overrideSharePrice * this.exchangeRate;
        }
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getSumPurchaseProgram");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  // PURCHASE PROGRAM DETAILS SUMMARY END
  // RSU DETAILS SUMMARY START
  /**********************************************************************************
      Method Name: getRSUData
      Description: Get RSU program data

      #     Date            Modified by           Description
      1     02/14/19        Katrina Narag(KMN)    Reset to 0 to prevent variables to double values
      2     03/26/2019      Joshua Avecilla(JPA)  Added an else that calls the method checkAwards()
  **********************************************************************************/
  async getRSUData() {
    this.totalrsuReleasedExercisableQty = 0;  //KMN, 1
    this.totalrsuUnreleaseExercisable = 0;    //KMN, 1
    this.totalRSUUnreleasedUnvested = 0;
    this.totalRSUSharesOutStanding = 0;

    try {
      // console.log('getRSUData: Start getRSUData');
      this.errorRSU = false;
      let data = JSON.parse(sessionStorage.getItem("RSU Data"));
      let body = data;

      if (data[1].length > 0)
      {
        // console.log('getRSUData: Start getRSUData if');
        let sortedBody = data[1].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);

        this.totalRSUAvailableToSell = body[0][0].AvailableToSell;
        if (this.totalRSUAvailableToSell == null) this.totalRSUAvailableToSell = 0;

        if (sortedBody.length > 0)
        {
          this.showRSUData = true;
          this.hasAwards = true;
          sessionStorage.setItem("showRSUData", "1");
          sessionStorage.setItem("hasAwards", "1");
          // console.log('getRSUData: Before Key Exec');
          this.getKeyExecValues(this.keyExecVest);
          // console.log('getRSUData: After Key Exec');

          //TOTAL RELEASED and UNRELEASED
          for (let i = 0; i < data[1].length; i++)
          {
            this.rsuReleasedExercisableQty = sortedBody[i].ReleasedExercisableQty;
            this.totalrsuReleasedExercisableQty += this.rsuReleasedExercisableQty;

            this.rsuUnreleaseExercisable = sortedBody[i].UnreleasedUnexercisable;
            this.totalrsuUnreleaseExercisable += this.rsuUnreleaseExercisable;
          }
        }
        // else{
        //   this.checkAwards();
        // }
      }

      
      this.checkAwards();
      // console.log('getRSUData: End getRSUData');
    } catch (error) {
      this.errorRSU = true;
      let errorLocation = this.errorLocation.replace("methodName", "getRSUData");
      error.message = error.message + errorLocation;
      throw error;
    }

  }

  // RSU DETAILS SUMMARY END
  // OPTIONS DETAILS SUMMARY START

  async getOptionsData() {
    try {
      this.errorOption = false;
      let data = JSON.parse(sessionStorage.getItem("Options Data"));
      let body = data;

      if (body[1].length > 0) {
        this.hasAwards = true;
        this.showOptionsData = true;
        sessionStorage.setItem("showOptionsData", "1");
        sessionStorage.setItem("hasAwards", "1");

        this.totalOptionsSharesOutstanding = 0;
        if (!isNaN(body[0][0].Outstanding))
          this.totalOptionsSharesOutstanding = body[0][0].Outstanding;

        this.totalOptionsAvailableToSell = 0;
        if (!isNaN(body[0][0].AvailableToSell))
          this.totalOptionsAvailableToSell = body[0][0].AvailableToSell;

        this.totalUnreleasedUnvested = 0;
        if (!isNaN(body[0][0].UnreleasedUnvested))
          this.totalUnreleasedUnvested = body[0][0].UnreleasedUnvested;

        this.getSumOptions(body[1]);
      }
      else {
        this.totalOptionsSharesOutstanding = 0;
        this.totalOptionsAvailableToSell = 0;
        this.totalUnreleasedUnvested = 0;
      }

      this.checkAwards();
    } catch (error) {
      this.errorOption = true;
      let errorLocation = this.errorLocation.replace("methodName", "getOptionsData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getSumOptions
      Description: Get summation of options awards

      #     Date            Modified by       Description
      1     02/14/19        Katrina Narag     Reset optionsOutstandingValue to 0
  **********************************************************************************/
  async getSumOptions(values) {
    this.optionsOutstandingValue = 0;   //KMN, 1
    let optionsAvailableToSellValue = 0;
    try {
      let availableToSellTempo: number = 0;
      let availableToSellTempoString: any = 0;
      for (let i = 0; i < Object.keys(values).length; i++) {
        // OUTSTANDING VALUE
        if ((this.overrideSharePrice - values[i].GrantPrice) < 0) {
          values[i].Outstanding = (values[i].Outstanding * 1) * (0) * this.exchangeRate;
          values[i].Outstanding = values[i].Outstanding;
          this.marketQuoteData += (values[i].Outstanding * 1);
        }
        else {
          values[i].Outstanding = (values[i].Outstanding * 1) * (this.overrideSharePrice - values[i].GrantPrice) * this.exchangeRate;
          values[i].Outstanding = values[i].Outstanding;
          this.optionsOutstandingValue += (values[i].Outstanding * 1);
        }

        // AVAILABLE TO SELL VALUE
        if ((this.overrideSharePrice - values[i].GrantPrice) < 0) {
          availableToSellTempo = (values[i].AvailableToSell * 1) * (0) * this.exchangeRate;
          availableToSellTempoString = availableToSellTempo;
          optionsAvailableToSellValue += (availableToSellTempoString * 1);
        }
        else {
          availableToSellTempo = (values[i].AvailableToSell * 1) * (this.overrideSharePrice - values[i].GrantPrice) * this.exchangeRate;
          availableToSellTempoString = availableToSellTempo;
          optionsAvailableToSellValue += (availableToSellTempoString * 1);
        }

        // UNRELEASED/UNVESTED
        if ((this.overrideSharePrice - values[i].GrantPrice) < 0) {
          values[i].UnreleasedUnexercisable = (values[i].UnreleasedUnexercisable * 1) * (0) * this.exchangeRate;
          values[i].UnreleasedUnexercisable = values[i].UnreleasedUnexercisable;
          this.optionsUnreleasedUnvested += (values[i].UnreleasedUnexercisable * 1);
        }
        else {
          values[i].UnreleasedUnexercisable = (values[i].UnreleasedUnexercisable * 1) * (this.overrideSharePrice - values[i].GrantPrice) * this.exchangeRate;
          values[i].UnreleasedUnexercisable = values[i].UnreleasedUnexercisable;
          this.optionsUnreleasedUnvested += (values[i].UnreleasedUnexercisable * 1);
        }

      }
      this.optionsOutstandingValue = this.optionsOutstandingValue;
      this.optionsAvailableToSellValue = optionsAvailableToSellValue;
      this.optionsUnreleasedUnvested = this.optionsUnreleasedUnvested;
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getSumOptions");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  // OPTIONS DETAILS SUMMARY END
  // FOUNDER SHARES DETAILS SUMMARY START

  /**********************************************************************************
      Method Name: getFounderSharesData
      Description: Get FOUNDERS program data

      #     Date            Modified by           Description
      1     02/14/19        Katrina Narag(KMN)    Reset to 0 to prevent variables to double values
  **********************************************************************************/
  async getFounderSharesData() {
    this.founderUnvested = 0;  //KMN, 1
    try {
      this.errorFounder = false;
      let data = JSON.parse(sessionStorage.getItem("Founder Share Data"));
      let body = data;

      if (body[1].length > 0) {
        this.totalFounderSharesOutstanding = body[0][0].Outstanding;
        this.totalFounderAvailableToSell = body[0][0].AvailableToSell;
        body[0][0].OutstandingShares = body[0][0].Outstanding;
        this.showFounderData = true;
        this.hasAwards = true;
        sessionStorage.setItem("showFounderData", "1");
        sessionStorage.setItem("hasAwards", "1");

        let founderData = data[0][0];
        this.founderUnvested = founderData.UnreleasedUnvested;
      } else {
        this.totalFounderSharesOutstanding = 0;
        this.totalFounderAvailableToSell = 0;
      }

      this.getFounderSharesValue(body);
      this.checkAwards();
    } catch (error) {
      this.errorFounder = true;
      let errorLocation = this.errorLocation.replace("methodName", "getFounderSharesData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getFounderSharesValue
      Description: Get FOUNDERS SHARES VALUE

      #     Date            Modified by         Description
      1     02/14/19        Katrina Narag(KMN)  Reset to 0 to prevent variables to double values
  **********************************************************************************/
  async getFounderSharesValue(data: any) {

    this.founderReleasedExercisableQty = 0;  //KMN, 1
    let founderAvailableToSellValue = 0;
    try {
      this.marketQuoteFounders = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
      let avgHighLow = (this.marketQuoteData.high + this.marketQuoteData.low) / 2;
      this.founderSharesOutstandingValue = 0;

      if (isNaN(avgHighLow)) {
        avgHighLow = (this.marketQuoteFounders.high + this.marketQuoteFounders.low) / 2;
      }

      if (data[1].length > 0) {
        for (let i = 0; i < data[1].length; i++) {
          if (data[1][i].GrantGroupId == GrantGroup.LTDA) {
            this.founderValue = this.overrideSharePrice; // share price from marketquote
          } else if (data[1][i].GrantGroupId == GrantGroup.ACHICan || data[1][i].GrantGroupId == GrantGroup.ACHINz) {
            this.founderValue = avgHighLow; // average high low from marketquote
          } else if (data[1][i].GrantGroupId == GrantGroup.SCA && data[1][i].CurrentAllowedTransaction == GrantTransactionType.Sale) {
            this.founderValue = avgHighLow; // average high low from marketquote
          } else if (data[1][i].GrantGroupId == GrantGroup.SCA && data[1][i].CurrentAllowedTransaction == GrantTransactionType.Redemption &&
            data[1][i].RedemptionMethod == RedemptionMethod.Cash) {
            this.founderValue = avgHighLow; // average high low from marketquote
          } else if (data[1][i].GrantGroupId == GrantGroup.SCA && data[1][i].CurrentAllowedTransaction == GrantTransactionType.Redemption &&
            data[1][i].RedemptionMethod == RedemptionMethod.Share) {
            this.founderValue = this.overrideSharePrice; // share price from marketquote
          }

          data[1][i].OutstandingValue = ((this.founderValue * (data[1][i].Outstanding * 1)) * this.exchangeRate).toFixed(2);
          this.founderSharesOutstandingValue += (data[1][i].OutstandingValue * 1);

          founderAvailableToSellValue += (this.founderValue * data[1][i].AvailableToSell) * this.exchangeRate;
          this.founderReleasedExercisableQty += data[1][i].ReleasedExercisableQty;
        }

        this.founderAvailableToSellValue = founderAvailableToSellValue;

        let founderData = data[0][0];
        this.founderUnvested = founderData.UnreleasedUnvested;
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFounderSharesValue");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  // FOUNDER SHARES DETAILS SUMMARY END


  /*****************************************************************************************************************
        Method Name: setDoughnutGraph
        Description: Generate doughnut graph to represent shares outstanding values of the user

        #     Date            Modified by           Description
        1     03/20/19        Joshua Avecilla(JPA)  Added code in generating doughnut graph
        2     03/27/19        JPA                   Changed the values where the graph is based upon. Instead of the outstanding shares value it is now outstanding shares
                                                    as sections of the graph is not visible as some of the values are too small or too big.
        3     04/02/19        JPA                   Added in the else condition so that even if the exchange rate and share price are zero the graph is still shown.
        4     05/06/19        KMN                   Set to 1m - default
        5     05/20/19        Jayson Sevilla        Doughnut Graph for mobile
   **************************************************************************************************************/
  setDoughnutGraph(flag) {
    try {
      let symbol, founderShares, founderSharesValue, rsuShares, rsuSharesValue, esppShares, veipShares, optionsShares, esppSharesValue, veipSharesValue, optionsShareValue;
      let foundersATS, foundersATSvalue, rsuATS, rsuATSvalue, esppATS, esppATSvalue, veipATS, veipATSvalue, optionATS, optionATSvalue, rsuUnreleased, rsuUnreleasedvalue;

      if (flag == true) {
        symbol = this.currencyCdSymbol;
        founderShares = this.totalFounderSharesOutstanding;
        founderSharesValue = this.founderSharesOutstandingValue;

        rsuShares = this.totalRSUSharesOutStanding;
        rsuSharesValue = this.rsuOutstandingValue;

        esppShares = this.totalPurchaseOutStandingSharesESPP;
        veipShares = this.totalPurchaseOutStandingSharesVEIP;
        esppSharesValue = this.valuePurchaseOutStandingSharesESPP;
        veipSharesValue = this.valuePurchaseOutStandingSharesVEIP;
        optionsShares = this.totalOptionsSharesOutstanding;
        optionsShareValue = this.optionsOutstandingValue;

        foundersATS = this.totalFounderAvailableToSell;
        foundersATSvalue = this.founderAvailableToSellValue;
        rsuATS = this.totalRSUAvailableToSell;
        rsuATSvalue = this.rsuAvailableToSellValue;
        esppATS = this.totalpurchaseAvailabletoSellESPP;
        esppATSvalue = this.totalpurchaseAvailabletoSellESPP * this.overrideSharePrice * this.exchangeRate;;
        veipATS = this.totalpurchaseAvailabletoSellVEIP;
        veipATSvalue = this.totalpurchaseAvailabletoSellVEIP * this.overrideSharePrice * this.exchangeRate;;
        optionATS = this.totalOptionsAvailableToSell;
        optionATSvalue = this.optionsAvailableToSellValue;
        rsuUnreleased = this.totalRSUUnreleasedUnvested;
        rsuUnreleasedvalue = this.rsuUnreleasedOutstanding

        if (founderShares > 0) {
          this.hasFounderLegend = true;
          sessionStorage.setItem("hasFounderinDoughnut", "1");
        }
        if (rsuShares > 0) {
          this.hasRSULegend = true;
          sessionStorage.setItem("hasRSUinDoughnut", "1");
        }
        if (veipShares > 0) {
          this.hasVEIPLegend = true;
          sessionStorage.setItem("hasVEIPinDoughnut", "1");
        }
        if (esppShares > 0) {
          this.hasESPPLegend = true;
          sessionStorage.setItem("hasESPPinDoughnut", "1");
        }
        if (optionsShares > 0) {
          this.hasOptionLegend = true;
          sessionStorage.setItem("hasOptioninDoughnut", "1");
        }
      }
      else {
        symbol = this.currencyCdSymbol;

        founderShares = this.totalFounderSharesOutstanding;
        founderSharesValue = 0;

        rsuShares = this.totalRSUSharesOutStanding;
        rsuSharesValue = 0;

        esppShares = this.totalPurchaseOutStandingSharesESPP;
        veipShares = this.totalPurchaseOutStandingSharesVEIP;
        esppSharesValue = 0;
        veipSharesValue = 0;
        optionsShares = this.totalOptionsSharesOutstanding;
        optionsShareValue = 0;

        foundersATS = this.totalFounderAvailableToSell;
        foundersATSvalue = 0;
        rsuATS = this.totalRSUAvailableToSell;
        rsuATSvalue = 0;
        esppATS = this.totalpurchaseAvailabletoSellESPP;
        esppATSvalue = 0;
        veipATS = this.totalpurchaseAvailabletoSellVEIP;
        veipATSvalue = 0;
        optionATS = this.totalOptionsAvailableToSell;
        optionATSvalue = 0;
        rsuUnreleased = this.totalRSUUnreleasedUnvested;
        rsuUnreleasedvalue = 0;

        if (founderShares > 0) {
          this.hasFounderLegend = true;
          sessionStorage.setItem("hasFounderinDoughnut", "1");
        }
        if (rsuShares > 0) {
          this.hasRSULegend = true;
          sessionStorage.setItem("hasRSUinDoughnut", "1");
        }
        if (veipShares > 0) {
          this.hasVEIPLegend = true;
          sessionStorage.setItem("hasVEIPinDoughnut", "1");
        }
        if (esppShares > 0) {
          this.hasESPPLegend = true;
          sessionStorage.setItem("hasESPPinDoughnut", "1");
        }
        if (optionsShares > 0) {
          this.hasOptionLegend = true;
          sessionStorage.setItem("hasOptioninDoughnut", "1");
        }
      }

      //START - Doughnut graph




      // var ctx = document.getElementById("canvas");

      this.doughnutchart = new Chart("canvas", {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [esppShares, veipShares, rsuShares, founderShares, optionsShares], //this.founderSharesOutstandingValue, this.rsuOutstandingValue, this.totalOptionsSharesOutstanding, this.valueSharesOustanding,
            backgroundColor: [
              "#0b3596", //esppShares
              "#a101ff", //veipShares
              "#7000b2", //rsuShares
              "#004dff", //founderShares
              "#23ef77"  //optionsShares
            ],
            hoverBorderColor: [
              "#0b3596", //esppShares
              "#a101ff", //veipShares
              "#7000b2", //rsuShares
              "#004dff", //founderShares
              "#23ef77"  //optionsShares
            ],
            hoverBorderWidth: 1
          }],
          labels: [
            "ESPP",
            "VEIP",
            "RSU",
            "Founders",
            "Options"
          ],
        },
        options: {
          legend: {
            position: 'right',
            display: false
          },
          animation: {
            animateRotate: true,
            animateScale: true
          },
          maintainAspectRatio: false,
          segmentShowStroke: false,
          cutoutPercentage: 75,
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function (title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function (body, i) {
                  var sharesType = body[0].substring(0, body[0].indexOf(":"));
                  var value;
                  var valueATS;
                  var ATSSharesCount;
                  var sharesCount;
                  var rsuUnrealesedShareCount;
                  var valueRSUUnreleased;
                  // var shares

                  if (sharesType == 'Founders') {
                    sharesCount = (founderShares * 1).toLocaleString('en');
                    value = founderSharesValue;
                    valueATS = foundersATSvalue;
                    ATSSharesCount = (foundersATS * 1).toLocaleString('en');
                  }
                  else if (sharesType == 'RSU') {
                    sharesCount = (rsuShares * 1).toLocaleString('en');
                    value = rsuSharesValue;
                    valueATS = rsuATSvalue;
                    valueRSUUnreleased = rsuUnreleasedvalue;
                    ATSSharesCount = (rsuATS * 1).toLocaleString('en');
                    rsuUnrealesedShareCount = (rsuUnreleased * 1).toLocaleString('en');
                  }
                  else if (sharesType == 'VEIP') {
                    sharesCount = (veipShares * 1).toLocaleString('en');
                    value = veipSharesValue;
                    valueATS = veipATSvalue;
                    ATSSharesCount = (veipATS * 1).toLocaleString('en');
                  }
                  else if (sharesType == 'ESPP') {
                    sharesCount = (esppShares * 1).toLocaleString('en');
                    value = esppSharesValue;
                    valueATS = esppATSvalue;
                    ATSSharesCount = (esppATS * 1).toLocaleString('en');
                  }
                  else if (sharesType == 'Options') {
                    sharesCount = (optionsShares * 1).toLocaleString('en');
                    value = optionsShareValue;
                    valueATS = optionATSvalue;
                    ATSSharesCount = (optionATS * 1).toLocaleString('en');
                  }


                  value = symbol + (value * 1).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                  valueATS = symbol + (valueATS * 1).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                  valueRSUUnreleased = symbol + (valueRSUUnreleased * 1).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });

                  if (sharesType == 'RSU') {
                    var colors = tooltipModel.labelColors[i];
                    var divStyle = 'height: 3.3125rem;';
                    divStyle += ' width: width: 53px;';
                    divStyle += ' border: 1px solid #f2f2f2;';
                    divStyle += ' text-align: center';
                    divStyle += ' display:block;';
                    // divStyle += ' padding: 10px 0;';

                    var style = 'font-family: Graphik;';
                    style += ' font-size: 5px';
                    style += ' font-style: normal';
                    style += ' font-stretch: normal;';
                    style += ' letter-spacing: normal;';
                    style += ' line-height: normal;';
                    style2 += ' display: block;';
                    style += ' color: #000000;';
                    style += ' relative;';
                    style += ' top: 30%;';

                    var style2 = 'font-family: Graphik';
                    style2 += ' font-size: 5px';
                    style2 += ' font-style: normal';
                    style2 += ' font-stretch: normal;';
                    style2 += ' display: block;';
                    style2 += ' color: #646464;';
                    style += ' relative;';
                    style += ' top: 30%;';

                    var sharesTypeSpan = '<span style="' + style + '">' + sharesType + '</span>';
                    var sharesNameSpan = '<span style="' + style + '"> Outstanding Shares </span>';
                    var valueSpan = '<span style="' + style2 + '">' + value + '</span>';
                    var shareSpan = '<span style="' + style2 + '"> ' + sharesCount + ' shares</span>';

                    var sharesNameSpanATS = '<span style="' + style + '"> Available To Sell </span>';
                    var valueSpanATS = '<span style="' + style2 + '">' + valueATS + '</span>';
                    var shareSpanATS = '<span style="' + style2 + '"> ' + ATSSharesCount + ' shares</span>';

                    var shareSpanUnreleased = '<span style="' + style2 + '"> ' + rsuUnrealesedShareCount + ' shares</span>';
                    var valueSpanUnreleased = '<span style="' + style2 + '"> ' + valueRSUUnreleased + ' </span>';
                    innerHtml += '<tr style="height: 1.05rem"><td colspan="3" style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 0; text-align: center; border-top-left-radius: 15px; border-top-right-radius: 15px;">' + sharesTypeSpan + '</div></td></tr>'
                    innerHtml += '<tr style="height: 1.05rem"><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px;">' + sharesNameSpan + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpan + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + shareSpan + '</div></td></tr>';
                    innerHtml += '<tr style="height: 1.05rem"><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px;">' + sharesNameSpanATS + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpanATS + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + shareSpanATS + '</div></td></tr>';
                    innerHtml += '<tr style="height: 1.05rem"><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px; border-bottom-left-radius: 15px;">Unreleased</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpanUnreleased + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px; border-bottom-right-radius: 15px;">' + shareSpanUnreleased + '</div></td></tr>';
                    tooltipEl.style.top = 16 + 'rem';
                  }
                  else {
                    var colors = tooltipModel.labelColors[i];
                    var divStyle = 'height: 3.3125rem;';
                    divStyle += ' width: width: 53px;';
                    divStyle += ' border: 1px solid #f2f2f2;';
                    divStyle += ' text-align: center';
                    divStyle += ' display:block;';
                    divStyle += ' padding: 10px 0;';

                    var style = 'font-family: Graphik;';
                    style += ' font-size: 5px';
                    style += ' font-style: normal';
                    style += ' font-stretch: normal;';
                    style += ' letter-spacing: normal;';
                    style += ' line-height: normal;';
                    style2 += ' display: block;';
                    style += ' color: #000000';

                    var style2 = 'font-family: Graphik';
                    style2 += ' font-size: 5px';
                    style2 += ' font-style: normal';
                    style2 += ' font-stretch: normal;';
                    style2 += ' display: block;';
                    style2 += ' color: #646464;';

                    var sharesTypeSpan = '<span style="' + style + '">' + sharesType + '</span>';
                    var sharesNameSpan = '<span style="' + style + '"> Outstanding Shares </span>';
                    var valueSpan = '<span style="' + style2 + '">' + value + '</span>';
                    var shareSpan = '<span style="' + style2 + '"> ' + sharesCount + ' shares</span>';

                    var sharesNameSpanATS = '<span style="' + style + '"> Available To Sell </span>';
                    var valueSpanATS = '<span style="' + style2 + '">' + valueATS + '</span>';
                    var shareSpanATS = '<span style="' + style2 + '"> ' + ATSSharesCount + ' shares</span>';
                    innerHtml += '<tr><td colspan="3" style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 0; text-align: center; border-top-left-radius: 15px; border-top-right-radius: 15px;">' + sharesTypeSpan + '</div></td></tr>'
                    innerHtml += '<tr><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px;">' + sharesNameSpan + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpan + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + shareSpan + '</div></td></tr>';
                    innerHtml += '<tr><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px; border-bottom-left-radius: 15px;">' + sharesNameSpanATS + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpanATS + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px; border-bottom-right-radius: 15px;">' + shareSpanATS + '</div></td></tr>';
                    tooltipEl.style.top = 16 + 'rem';
                  }



                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              // var position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = '1';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = 48 + '%';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            }
          }
        }
      })

      this.doughnutchartMobile = new Chart("canvasMobile", {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [esppShares, veipShares, rsuShares, founderShares, optionsShares], //this.founderSharesOutstandingValue, this.rsuOutstandingValue, this.totalOptionsSharesOutstanding, this.valueSharesOustanding,
            backgroundColor: [
              "#0b3596", //esppShares
              "#a101ff", //veipShares
              "#7000b2", //rsuShares
              "#004dff", //founderShares
              "#23ef77"  //optionsShares
            ],
            hoverBorderColor: [
              "#0b3596", //esppShares
              "#a101ff", //veipShares
              "#7000b2", //rsuShares
              "#004dff", //founderShares
              "#23ef77"  //optionsShares
            ],
            hoverBorderWidth: 1
          }],
          labels: [
            "ESPP",
            "VEIP",
            "RSU",
            "Founders",
            "Options"
          ],
        },
        options: {
          legend: {
            position: 'right',
            display: false
          },
          animation: {
            animateRotate: true,
            animateScale: true
          },
          maintainAspectRatio: false,
          segmentShowStroke: false,
          cutoutPercentage: 75,
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function (title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function (body, i) {
                  var sharesType = body[0].substring(0, body[0].indexOf(":"));
                  var value;
                  var valueATS;
                  var ATSSharesCount;
                  var sharesCount;
                  var rsuUnrealesedShareCount;
                  var valueRSUUnreleased;
                  // var shares

                  if (sharesType == 'Founders') {
                    sharesCount = (founderShares * 1).toLocaleString('en');
                    value = founderSharesValue;
                    valueATS = foundersATSvalue;
                    ATSSharesCount = (foundersATS * 1).toLocaleString('en');
                  }
                  else if (sharesType == 'RSU') {
                    sharesCount = (rsuShares * 1).toLocaleString('en');
                    value = rsuSharesValue;
                    valueATS = rsuATSvalue;
                    valueRSUUnreleased = rsuUnreleasedvalue;
                    ATSSharesCount = (rsuATS * 1).toLocaleString('en');
                    rsuUnrealesedShareCount = (rsuUnreleased * 1).toLocaleString('en');
                  }
                  else if (sharesType == 'VEIP') {
                    sharesCount = (veipShares * 1).toLocaleString('en');
                    value = veipSharesValue;
                    valueATS = veipATSvalue;
                    ATSSharesCount = (veipATS * 1).toLocaleString('en');
                  }
                  else if (sharesType == 'ESPP') {
                    sharesCount = (esppShares * 1).toLocaleString('en');
                    value = esppSharesValue;
                    valueATS = esppATSvalue;
                    ATSSharesCount = (esppATS * 1).toLocaleString('en');
                  }
                  else if (sharesType == 'Options') {
                    sharesCount = (optionsShares * 1).toLocaleString('en');
                    value = optionsShareValue;
                    valueATS = optionATSvalue;
                    ATSSharesCount = (optionATS * 1).toLocaleString('en');
                  }


                  value = symbol + (value * 1).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                  valueATS = symbol + (valueATS * 1).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                  valueRSUUnreleased = symbol + (valueRSUUnreleased * 1).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });

                  if (sharesType == 'RSU') {
                    var colors = tooltipModel.labelColors[i];
                    var divStyle = 'height: 3.3125rem;';
                    divStyle += ' width: width: 53px;';
                    divStyle += ' border: 1px solid #f2f2f2;';
                    divStyle += ' text-align: center';
                    divStyle += ' display:block;';
                    // divStyle += ' padding: 10px 0;';

                    var style = 'font-family: Graphik;';
                    style += ' font-size: 5px';
                    style += ' font-style: normal';
                    style += ' font-stretch: normal;';
                    style += ' letter-spacing: normal;';
                    style += ' line-height: normal;';
                    style2 += ' display: block;';
                    style += ' color: #000000;';
                    style += ' relative;';
                    style += ' top: 30%;';

                   var style2 = 'font-family: Graphik';
                    style2 += ' font-size: 5px';
                    style2 += ' font-style: normal';
                    style2 += ' font-stretch: normal;';
                    style2 += ' display: block;';
                    style2 += ' color: #646464;';
                    style += ' relative;';
                    style += ' top: 30%;';

                    var sharesTypeSpan = '<span style="' + style + '">' + sharesType + '</span>';
                    var sharesNameSpan = '<span style="' + style + '"> Outstanding Shares </span>';
                    var valueSpan = '<span style="' + style2 + '">' + value + '</span>';
                    var shareSpan = '<span style="' + style2 + '"> ' + sharesCount + ' shares</span>';

                    var sharesNameSpanATS = '<span style="' + style + '"> Available To Sell </span>';
                    var valueSpanATS = '<span style="' + style2 + '">' + valueATS + '</span>';
                    var shareSpanATS = '<span style="' + style2 + '"> ' + ATSSharesCount + ' shares</span>';

                    var shareSpanUnreleased = '<span style="' + style2 + '"> ' + rsuUnrealesedShareCount + ' shares</span>';
                    var valueSpanUnreleased = '<span style="' + style2 + '"> ' + valueRSUUnreleased + ' </span>';
                    innerHtml += '<tr"><td colspan="3" style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 0; text-align: center; border-top-left-radius: 15px; border-top-right-radius: 15px;">' + sharesTypeSpan + '</div></td></tr>'
                    innerHtml += '<tr><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px;">' + sharesNameSpan + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpan + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + shareSpan + '</div></td></tr>';
                    innerHtml += '<tr><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px;">' + sharesNameSpanATS + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpanATS + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + shareSpanATS + '</div></td></tr>';
                    innerHtml += '<tr><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px; border-bottom-left-radius: 15px;">Unreleased</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpanUnreleased + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px; border-bottom-right-radius: 15px;">' + shareSpanUnreleased + '</div></td></tr>';
                    tooltipEl.style.top = 66 + 'rem';
                  }
                  else {
                    var colors = tooltipModel.labelColors[i];
                    var divStyle = 'height: 3.3125rem;';
                    divStyle += ' width: width: 53px;';
                    divStyle += ' border: 1px solid #f2f2f2;';
                    divStyle += ' text-align: center';
                    divStyle += ' display:block;';
                    divStyle += ' padding: 10px 0;';

                    var style = 'font-family: Graphik;';
                    style += ' font-size: 5px';
                    style += ' font-style: normal';
                    style += ' font-stretch: normal;';
                    style += ' letter-spacing: normal;';
                    style += ' line-height: normal;';
                    style2 += ' display: block;';
                    style += ' color: #000000';

                    var style2 = 'font-family: Graphik';
                    style2 += ' font-size: 5px';
                    style2 += ' font-style: normal';
                    style2 += ' font-stretch: normal;';
                    style2 += ' display: block;';
                    style2 += ' color: #646464;';

                    var sharesTypeSpan = '<span style="' + style + '">' + sharesType + '</span>';
                    var sharesNameSpan = '<span style="' + style + '"> Outstanding Shares </span>';
                    var valueSpan = '<span style="' + style2 + '">' + value + '</span>';
                    var shareSpan = '<span style="' + style2 + '"> ' + sharesCount + ' shares</span>';

                    var sharesNameSpanATS = '<span style="' + style + '"> Available To Sell </span>';
                    var valueSpanATS = '<span style="' + style2 + '">' + valueATS + '</span>';
                    var shareSpanATS = '<span style="' + style2 + '"> ' + ATSSharesCount + ' shares</span>';
                    innerHtml += '<tr><td colspan="3" style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 0; text-align: center; border-top-left-radius: 15px; border-top-right-radius: 15px;">' + sharesTypeSpan + '</div></td></tr>'
                    innerHtml += '<tr><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px;">' + sharesNameSpan + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpan + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + shareSpan + '</div></td></tr>';
                    innerHtml += '<tr><td style="padding: 0;"><div style="background-color: #faf9fa; ' + divStyle + ' padding: 10px 5px; border-bottom-left-radius: 15px;">' + sharesNameSpanATS + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px;">' + valueSpanATS + '</div></td>' + '<td style="padding: 0;"><div style="background-color: white; ' + divStyle + ' padding: 10px 5px; border-bottom-right-radius: 15px;">' + shareSpanATS + '</div></td></tr>';
                    tooltipEl.style.top = 66 + 'rem';
                  }



                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              // var position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = '1';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = 8 + '%';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            }
          }
        }
      })
      // END - Doughnut graph

    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "setDoughnutGraph");
      error.message = error.message + errorLocation;
      throw error;
    }

    // this.checkErrorMessage();

    this.changeElementjQuery();
    // this.getSharePriceHistoryData('1y');
    // this.marketQuoteGraph('1m');
  }

  changeSlider(value) {
    try {
      // this.exceedsValue = this.perfGrantValueExceedStatic*(value/100);
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "changeSlider");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: any) {
    try {
      if (num != 0.00 || num != 0) {
        if (value == 1) {
          let number = (num * 1).toLocaleString('en', {
            minimumFractionDigits: 2
          });
          return number;
        }
        else if (value == 0) {
          let number = (num * 1).toLocaleString('en', {
            maximumFractionDigits: value
          });
          return number;
        }
        if (value == 2) {
          let number = (num * 1).toLocaleString('en', {
            maximumFractionDigits: 2
          });
          return number;
        }
        else {
          let number = (num * 1).toLocaleString('en');
          return number;
        }
      } else {
        if (value == 1) {
          return '0.00';
        }
        return 0;
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: transformMarketPrice
      Description: transform value of market price change for negative display

      Date            Modified by           Description
      01/30/19        Katrina Narag(KMN)    Initial draft
      02/07/19        KMN                   Add logic for 0 change
  **********************************************************************************/
  transformMarketPrice(num: any) {
    try {
      if (num != "0" || num != "0.00") {
        if (num.indexOf('-') > -1) {
          this.isChangeHigh = false;
          this.ifDash = false;
          let number = num.split('-');

          return '-$' + number[1];
        } else {
          this.isChangeHigh = true;
          this.ifDash = false;
          return '$' + num;
        }
      } else {
        this.ifDash = true;
        return '$' + num + '  –';
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "transformMarketPrice");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
     Method Name: transform
     Description: transform value of shares for negative display

     Date            Modified by       Description
     01/30/19        Roldan Mazo     Initial draft
 **********************************************************************************/
  transform(num: any, checker) {
    try {
      if (num != 0) {
        if (num.indexOf('-') > -1) {
          if (checker == 1) {
            let number = num.split('-');
            return '(' + this.currencyCdSymbol + number[1] + ')';
          }
          else {
            let number = num.split('-');
            return '(' + number[1] + ')';
          }
        }
        else {
          return num;
        }
      }
      else {
        return '0';
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "transform");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  checkAwards() {
    try {
      if (this.awardsCounter >= 4) {

        this.getSharesAvailableToSell();
      } else {
        // this.setDoughnutGraph(this.isGraphicalMethodCalled);
        this.awardsCounter += 1;

      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkAwards");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  getBrowserInfo(): any {
    try {
      var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null)
        M.splice(1, 1, tem[1]);
      return M.join(' ');
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getBrowserInfo");
      error.message = error.message + errorLocation;
      throw error;
    }
  }


  /**********************************************************************************
     * Method Name: filter
     * Description: Converts the returned output into Absolute value
     *
     * Date                 Modified by                 Description
     * 02/13/19             Jayson                      Initial draft
     * 02/18/19             Katrina Narag(KMN)          Modify logic
     *********************************************************************************/

  filter() {
    try {
      if (this.eorShortFall < 0) {
        return Math.abs(this.eorShortFall);
      } else {
        return this.eorShortFall;
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "filter");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage
   * Description: Checks the errors encountered in the homepage component
   *
   * Date                 Modified by                 Description
   * 01/31/19             Dalemark P. Suquib          Initial draft
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      console.log(error);
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];

        if (error != null && error != undefined) {
          for (let i = 0; i < error.length; i++) {
            if (error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              console.log("checkErrorMessage: Start Loop")
              console.log(errorMsg);
              console.log(errorLocation); 
              console.log("checkErrorMessage: End Loop")


              if (errorLocation.indexOf("Homepage") != -1 && this.user != null) {
                webPage = "Dashboard";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }

          this.errorNotificationService.refreshErrorList();
        }
      } catch (error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
  /*****************************************************************************************
   * Method Name: getKeyExecValues
   * Description: Selects the value of key exec vest and execute its computation affecting
   * share releasing column, unreleased column & value column, & shares outstanding column.
   *
   * #    Date                 Modified by                          Description
   * 1    02/06/19             Iris Jane R. Manloloy (IJM)         Initial draft
   * 2    12/13/22             Johannah Jane Abuel (JJA)           Added new computation for key exec (plan num 72 & 74), year 2023 and up
   * 3    06/06/23             Jewel C. Caudilla                   update computation from getKeyExecValues()
   *****************************************************************************************/
    async getKeyExecValues(vest) {
    try {
      // console.log('getKeyExecValues: vest = ' + vest);
      let data = JSON.parse(sessionStorage.getItem("RSU Data"));
      let body = data;
      let values = data[1].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);
      this.keyExecVest = vest;
      // console.log('data:');
      // console.log(data);
      // console.log('values:');
      // console.log(values);

      this.totalRSUSharesOutStanding = 0;
      this.totalRSUUnreleasedUnvested = 0;
      this.unreleasedTargetVestTotal = 0;
      this.rsuSharesReleasing = 0;
      this.sharesOutstanding = 0;

      for (let i = 0; i < values.length; i++) {
        /************************* Key Exec - START ************************/
        //Only for Maximum and Trend because Target is direct pull from BE which will be computed.
        // console.log('getKeyExecValues: Start Key Exec');
        // console.log(this.planNum.length);
        for (let j = 0; j < this.planNum.length; j++) {
          // console.log('planNum:')
          // console.log(this.planNum);
          if (this.planNum[j].OI == values[i].PlanNum) {
            let psuDetails = await JSON.parse(sessionStorage.getItem("PSU Details by OptNum"));
            let psuAttained = [];
            
            // console.log(psuDetails);
            // console.log('Start of LOOP');
            for(let q = 0; q < psuDetails.length; q++) {
              if(psuDetails[q].DisplayInd === "Y") {
                psuAttained.push(psuDetails[q]);
              }
              // console.log(psuDetails[q]);
            }

            // console.log('psuDetails: ' + psuDetails);
            // console.log('psuAttained: ' + psuDetails);
            for(let v = 0; v < psuAttained.length; v++) {
              if(psuAttained[v].GrantNum == values[i].GrantNum) {
                let awardDate = '20' + values[i].AwardName.substring(2, 4);
    
                if (vest == "Maximum" && values[i].GrantYear <= this.globalVariables.yearCondition) {
                  // console.log('getKeyExecValues: if Maximum');
                  let sharesTargetVestTotal = Math.round(values[i].SharesReleasing * (3/2));//Maximum computation: Target Value by default(target value by default)*1.5, where grantYear <= 2022
                  values[i].SharesReleasing = sharesTargetVestTotal;
    
                  let unreleasedTargetVestTotal = Math.round(values[i].UnreleasedUnexercisable * (3/2));
                  values[i].UnreleasedUnexercisable = unreleasedTargetVestTotal;
                  // console.log('getKeyExecValues: getKeyExecValues Maximum');
                } else if (vest == "Maximum" && values[i].GrantYear > this.globalVariables.yearCondition) {
                  // console.log('getKeyExecValues: else if Maximum');
                  let sharesTargetVestTotal = Math.round(values[i].SharesReleasing * (2));//Maximum computation: Target Value by default(target value by default)*2 where grantYear > 2022
                  values[i].SharesReleasing = sharesTargetVestTotal;
    
                  let unreleasedTargetVestTotal = Math.round(values[i].UnreleasedUnexercisable * (2));
                  values[i].UnreleasedUnexercisable = unreleasedTargetVestTotal;
                  // console.log('getKeyExecValues: getKeyExecValues Maximum if else');
                } else if (vest == "Trend" && values[i].GrantYear <= this.globalVariables.yearCondition) {
                  // console.log('getKeyExecValues: if trend');
                  let trendObtained: boolean = false;
                  for (let k = 0; k < this.yearCode.length; k++) {
                    if (this.yearCode[k].Code == awardDate) {
                      let sharesTargetVestTotal = Math.round(values[i].SharesReleasing);
                      let sharesOiPortionTarget = Math.round(sharesTargetVestTotal * (3 / 4));
                      let sharesTsrOptionTarget = Math.round(sharesTargetVestTotal) - Math.round(sharesOiPortionTarget);
                      let sharesOiPortionTrend = Math.round(sharesOiPortionTarget * (parseFloat(this.yearCode[k].OI) / 100));
                      let sharesTsrOptionTrend = Math.round(sharesTsrOptionTarget * (parseFloat(this.yearCode[k].TSR) / 100));
                      let sharesTrendVestTotal = Math.round(sharesOiPortionTrend) + Math.round(sharesTsrOptionTrend);
                      values[i].SharesReleasing = sharesTrendVestTotal;
    
                      let unreleasedTargetVestTotal = Math.round(values[i].UnreleasedUnexercisable);
                      let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3 / 4));
                      let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                      let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI) / 100));
                      let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR) / 100));
                      let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                      values[i].UnreleasedUnexercisable = unreleasedTrendVestTotal;
    
                      trendObtained = true;
                    }
                  }
    
                  if (!trendObtained) {
                    let sharesTargetVestTotal = values[i].SharesReleasing;
                    values[i].SharesReleasing = sharesTargetVestTotal;
    
                    let unreleasedTargetVestTotal = values[i].UnreleasedUnexercisable;
                    values[i].UnreleasedUnexercisable = unreleasedTargetVestTotal;
                  }
                  // console.log('getKeyExecValues: getKeyExecValues Trend if');

                } else if (vest == "Trend" && values[i].GrantYear > this.globalVariables.yearCondition) {
                  // console.log('getKeyExecValues: else if trend');
                  let trendObtained: boolean = false;
                  for (let k = 0; k < this.yearCode.length; k++) {
                    if (this.yearCode[k].Code == awardDate) {
                      let sharesTargetVestTotal = Math.round(values[i].SharesReleasing);
                      let sharesOiPortionTarget = Math.round(sharesTargetVestTotal * (3 / 4));
                      let sharesTsrOptionTarget = Math.round(sharesTargetVestTotal) - Math.round(sharesOiPortionTarget);
                      let sharesOiPortionTrend = Math.round(sharesOiPortionTarget * (parseFloat(this.yearCode[k].OI) / 100));
                      let sharesTsrOptionTrend = Math.round(sharesTsrOptionTarget * (parseFloat(this.yearCode[k].TSR) / 100));
                      let sharesTrendVestTotal = Math.round(sharesOiPortionTrend) + Math.round(sharesTsrOptionTrend);
                      values[i].SharesReleasing = sharesTrendVestTotal;
    
                      let unreleasedTargetVestTotal = Math.round(values[i].UnreleasedUnexercisable);
                      let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3 / 4));
                      let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                      let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI) / 100));
                      let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR) / 100));
                      let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                      values[i].UnreleasedUnexercisable = unreleasedTrendVestTotal;
    
                      trendObtained = true;
                    }
                  }
    
                  if (!trendObtained) {
                    let sharesTargetVestTotal = values[i].SharesReleasing;
                    values[i].SharesReleasing = sharesTargetVestTotal;
    
                    let unreleasedTargetVestTotal = values[i].UnreleasedUnexercisable;
                    values[i].UnreleasedUnexercisable = unreleasedTargetVestTotal;
                  }
                  // console.log('getKeyExecValues: getKeyExecValues Trend else if');

                }
              }
            }
          }
        }
        /************************* Key Exec - END ************************/
        values[i].highlighted = false;

        if (values[i].GrantCountryCd == "FRQ" && values[i].GrantLongDesc == "Div") {
          values[i].AvailableToSell = 0;
        }

        //JCC, 06-29-2023 : Add psuAttained to the Unreleased/Unvested and SharesReleasing.
        let psuDetails = JSON.parse(sessionStorage.getItem("PSU Details by OptNum"));
        let psuAttained = [];
        for(let q = 0; q < psuDetails.length; q++) {
          if(psuDetails[q].DisplayInd === "N") {
            psuAttained.push(psuDetails[q])
          }
        }

        // console.log('psuDetails: ' + psuDetails);

        for(let v = 0; v < psuAttained.length; v++) {
          if(values[i].GrantNum == psuAttained[v].GrantNum) {
            psuAttained[v]["Shares Attained by PSU"] = 0; //JCC --Set to zero 08/09/23
            values[i].UnreleasedUnexercisable = values[i].UnreleasedUnexercisable + psuAttained[v]["Shares Attained by PSU"];
            psuAttained[v].PastInd == "N" ? values[i].SharesReleasing = values[i].SharesReleasing + psuAttained[v]["Shares Attained by PSU"] : values[i].SharesReleasing = values[i].SharesReleasing;
          } else {
            values[i].UnreleasedUnexercisable = values[i].UnreleasedUnexercisable;
            values[i].SharesReleasing = values[i].SharesReleasing;
          }
        }
        
        // console.log("Loop Completed @getKeyExecValues");

        values[i].Outstanding = values[i].AvailableToSell + values[i].UnreleasedUnexercisable; // Available to Sell + Unreleased
        this.totalRSUSharesOutStanding += (values[i].Outstanding * 1);

        this.totalRSUUnreleasedUnvested = this.totalRSUUnreleasedUnvested + values[i].UnreleasedUnexercisable;
        this.rsuSharesReleasing = this.rsuSharesReleasing + values[i].SharesReleasing;
        this.rsuReleasedVested = this.rsuReleasedVested + values[i].ReleasedExercisableQty;
        this.rsuForfeited = this.rsuForfeited + values[i].Forfeited;

        values[i].AvailableToSellValue = values[i].AvailableToSell * this.overrideSharePrice * this.exchangeRate;

      }
      this.rsuOutstandingValue = this.totalRSUSharesOutStanding * this.overrideSharePrice * this.exchangeRate;
      this.rsuAvailableToSellValue = this.totalRSUAvailableToSell * this.overrideSharePrice * this.exchangeRate;
      this.rsuUnreleasedOutstanding = this.totalRSUUnreleasedUnvested * this.overrideSharePrice * this.exchangeRate;

      this.methodCounter += 1;
      this.rsuOutstandingValue = this.rsuOutstandingValue;
      this.rsuAvailableToSellValue = this.rsuAvailableToSellValue;
      this.rsuOutstandingValue = this.totalRSUSharesOutStanding * this.overrideSharePrice * this.exchangeRate;
      this.rsuAvailableToSellValue = this.totalRSUAvailableToSell * this.overrideSharePrice * this.exchangeRate;
      this.rsuUnreleasedOutstanding = this.totalRSUUnreleasedUnvested * this.overrideSharePrice * this.exchangeRate;

      //TOTAL RELEASED and UNRELEASED
      for (let i = 0; i < data[1].length; i++) {
        this.rsuReleasedExercisableQty = data[1][i].ReleasedExercisableQty;
        this.totalrsuReleasedExercisableQty += this.rsuReleasedExercisableQty;

        this.rsuUnreleaseExercisable = data[1][i].UnreleasedUnexercisable;
        this.totalrsuUnreleaseExercisable += this.rsuUnreleaseExercisable;
      }

      // if (this.totalRSUSharesOutStanding == 0) {
      //   this.displayKeyExecCardToogle = false;
      // }

      // console.log('getKeyExecValues: before checkAwards');
      this.checkAwards();
      // console.log('getKeyExecValues: after checkAwards');


      sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest));
      sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Homepage"));
    }
    catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getKeyExecValues");
      error.message = error.message + errorLocation;
      throw error;
    }
  }


  /*****************************************************************************************
       * Method Name: hideKeyExecToogle
       * Description: To hide KeyExec Maximum, Target, and Trend Radio Button
       *
       * #    Date                 Modified by                           Description
       * 1    02/28/19             Iris Jane R. Manloloyo (IJM)         Initial draft
       *****************************************************************************************/

  // hideToogle()
  // {
  //   try{
  //   if (this.totalRSUSharesOutStanding == 0){
  //     this.displaykeyExecToogle = false;
  //   }
  //    else{
  //     this.displaykeyExecToogle = true;
  //   }
  // }
  //   catch(error) {
  //     let errorLocation = " [Component: Holdingsummarypage, Method: hideKeyExecToogle]";
  //     error.message = error.message + errorLocation;
  //     throw error;
  // }
  // }


  /*****************************************************************************************
       * Method Name: keyExecVestChecker
       * Description: Checks the value of key exec toggle from homepage or detailspage to set
       * as default value for the radio button.
       *
       * #    Date                 Modified by                           Description
       * 1    02/06/19             Iris Jane R. Manloloyo (IJM)         Initial draft
       * 2    03/11/219            Iris Jane R. Manloloyo (IJM)         Additional Session for other Dashboard Pages
       *****************************************************************************************/
  keyExecVestChecker() {
    try {
      sessionStorage.setItem("Key Exec Homepage Value",`"Target"`);

      let keyExecHoldingSummaryValue = JSON.parse(sessionStorage.getItem("Key Exec Holding Summary Value"));
      let keyExecRsuDetailsValue = JSON.parse(sessionStorage.getItem("Key Exec Rsu Details Value"));
      let keyExecComponentInd = JSON.parse(sessionStorage.getItem("Key Exec Component Indicator"));
      let keyExecDashboardValue = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));

      if (keyExecHoldingSummaryValue != null && keyExecComponentInd == "Holding Summary") {
        this.keyExecVest = keyExecHoldingSummaryValue;
      } else if (keyExecRsuDetailsValue != null && keyExecComponentInd == "Rsu Details") {
        this.keyExecVest = keyExecRsuDetailsValue;
      } else if (keyExecDashboardValue != null && keyExecComponentInd == "Homepage") {
        this.keyExecVest = keyExecDashboardValue;
      } else {
        this.keyExecVest = "Target";
      }

      if (this.keyExecVest == "Target") {
        this.setTargetValue = true;
      } else if (this.keyExecVest == "Trend") {
        this.setTrendValue = true;
      } else {
        this.setMaxValue = true;
      }
      sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest));
      sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Homepage"));
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "keyExecVestChecker");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  async getCodeDetails() {
    if (sessionStorage.getItem("Key Exec Toggle Data") != null) {
      try {
        // console.log("HELLO getCodeDetails onto sessionStorage")
        let cachedData = JSON.parse(sessionStorage.getItem("Key Exec Toggle Data"));
        let code = cachedData[0];

        this.yearCode.push(code[0]);
        this.yearCode.push(code[1]);
        this.yearCode.push(code[2]);
        this.yearCode.push(code[3]);

        this.planNum.push(code[4]);
        this.planNum.push(code[5]);
        this.planNum.push(code[6]);

      } catch (error) {
        let errorLocation = this.errorLocation.replace("methodName", "getCodeDetails");
        error.message += errorLocation;
        throw error;
      }
    }
    else {
      
      await this.keyExecToggleService.getKeyExecToggle().pipe(map(res => res)).subscribe(data => {
        // console.log("HELLO getCodeDetails else calling API")
        try {
          sessionStorage.setItem("Key Exec Toggle Data", JSON.stringify(data));
          let code = data[0];

          this.yearCode.push(code[0]);
          this.yearCode.push(code[1]);
          this.yearCode.push(code[2]);
          this.yearCode.push(code[3]);

          this.planNum.push(code[4]);
          this.planNum.push(code[5]);
          this.planNum.push(code[6]);

        } catch (error) {
          let errorLocation = this.errorLocation.replace("methodName", "getCodeDetails");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getCodeDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  /***********************************************************************************************
      Method Name: getESPPActionItemDetail, getVEIPActionItemDetail, getGrantActionItemDetail
      Description: get Veip, Espp and Top 1 Grant to be displayed in Action Item Card

      Date            Modified by       Description
      02/26/19        Roldan Mazo       Initial Draft
      05/17/19        KMN               Bug 316851 fix - Double message displayed in the Dashboard Action Item Card for Tax election
      05/27/19        JPA               Added async for loading
      06/19/19        JPA               Store to cache the data saved in action item for performance enhancement
      08/09/19        JPA               Commented the call to methods getVEIPActionItemDetail, and getGrantActionItemDetail and transferred it getUserDetails
      10/30/2019      JSE               Added new condition for new withdrawal scenarios (eg. esppenrollOffernum) 
      11/7/2019       JSE               Added new condition for withdrawn during freeze period
      12/18/2019      Dale Suquib       Removed "esppActionItemDetails[0][i].EsppEligibility == 1" for tax elect condition
      04/22/2020      RJay B Adrao      Added new condition for closed enrollment based on Workitem 232985 
      04/27/2020      RJay B Adrao      Added new condition for tax elect freeze period based on Workitem 230750  
      02/09/2022      RJay B Adrao      Added Condition to display Action Item Card for Not Enrolled During Offering Period 
*************************************************************************************************/

  //Action Item Method START

  async getESPPActionItemDetail() {
    if (sessionStorage.getItem("ESPPActionItemCardCacheData") != null) {
      try {

        this.errorEsppActionItem = false;
        let esppActionItemDetails = JSON.parse(sessionStorage.getItem("ESPPActionItemCardCacheData"));
        this.esppOfferingDetails = esppActionItemDetails[0];
        if ((Array.isArray(esppActionItemDetails) && esppActionItemDetails[0].length > 0)) {
          this.hasActionItemsESPP = true;
          for (let i = 0; i < esppActionItemDetails[0].length; i++) {
            if (esppActionItemDetails[0][i].EnrollmentFlag == 0 && this.user.EmployeeStatus != 'Terminated' && this.user.EsppEligibleInd != 'N' && esppActionItemDetails[0][i].EsppEligibility == 1) { //ENROLL button text
              this.ShowESPPEnroll = true;
              this.actionItemCardStatus.push(this.ShowESPPEnroll);
            }
            else if (esppActionItemDetails[0][i].EnrollmentFlag == 7 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) //Closed Enrollment, RBA 8
            {
              this.ShowESPPEnrollClosedCurrOff  = true;
              this.actionItemCardStatus.push(this.ShowESPPEnrollClosedCurrOff);
            }
            else if (esppActionItemDetails[0][i].EnrollmentFlag == 7 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) //Closed Enrollment Next Offering, RBA 10
            {
              this.ShowESPPEnrollClosedNextOff  = true;
              this.actionItemCardStatus.push(this.ShowESPPEnrollClosedNextOff);
            }
            else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw button text
              this.ShowESPPWithdraw = true;
              this.actionItemCardStatus.push(this.ShowESPPWithdraw);
            }
            else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw button text
              this.ShowESPPWithdraw = true;
              this.actionItemCardStatus.push(this.ShowESPPWithdraw);
            }
            else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) { //update button text
              this.ShowESPPChangeContri = true;
              this.actionItemCardStatus.push(this.ShowESPPChangeContri);
            }
            else if (esppActionItemDetails[0][i].EnrollmentFlag == 2 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw button text
              this.ShowESPPWithdraw = true;
              this.actionItemCardStatus.push(this.ShowESPPWithdraw);
            }
            else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //view button text
              this.ShowESPPWithdrawn = true;
              this.actionItemCardStatus.push(this.ShowESPPWithdrawn);
            }
            else if (esppActionItemDetails[0][i].EnrollmentFlag == 4 && esppActionItemDetails[0][i].EsppEligibility == 1) { //view button text
              this.ShowESPPWithdrawnFP = true;
              this.showViewBtn = true;
              this.actionItemCardStatus.push(this.ShowESPPWithdrawnFP);
            }else if (esppActionItemDetails[0][i].EnrollmentFlag == 6 && esppActionItemDetails[0][i].EsppEligibility == 1) { //display card but no button
              this.ShowESPPWithdrawnFP = true;
              this.actionItemCardStatus.push(this.ShowESPPWithdrawnFP);
            }else {
              //Future Use
            }

            if (esppActionItemDetails[0][i].OfferingFlag == 2 && esppActionItemDetails[0][i].EnrollmentFlag == 1) { //tax elect scenario
              this.showTaxElect = true;
              this.actionItemCardStatus.push(this.showTaxElect);
            }
            else if (esppActionItemDetails[0][i].OfferingFlag == 2 && esppActionItemDetails[0][i].EnrollmentFlag == 2){//Tax Elect Freeze Period, RBA,9 START
              this.showTaxElectView = true; 
              this.actionItemCardStatus.push(this.showTaxElectView);
            }//Tax Elect Freeze Period, RBA,9 END
          }

          if (this.actionItemCardStatus.length < 1) {
            this.hasActionItemsESPP = false;
          }

          for (let i = 0; i < esppActionItemDetails[0].length; i++) {
            if (esppActionItemDetails[0][i].EnrollmentFlag == 0 && esppActionItemDetails[0][i].OfferingFlag != 2  && esppActionItemDetails[0][i].EsppEligibility == 1) { //enroll 
              this.esppenrollheader = esppActionItemDetails[0][i].Header;
              this.esppenrollSubmessage = esppActionItemDetails[0][i].SubMessage;
              this.esppenrollOffernum = esppActionItemDetails[0][i].OfferNum;
            } else if (esppActionItemDetails[0][i].OfferingFlag == 2 && esppActionItemDetails[0][i].EnrollmentFlag != 0) { //tax elect
              this.getTaxElection();
            }else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw
              this.esppwithdrawheader = esppActionItemDetails[0][i].Header;
              this.esppWithdrawSubmessage = esppActionItemDetails[0][i].SubMessage;
              this.esppWithdrawOffernum = esppActionItemDetails[0][i].OfferNum;
            }else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw button text
              this.esppwithdrawheader = esppActionItemDetails[0][i].Header;
              this.esppWithdrawSubmessage = esppActionItemDetails[0][i].SubMessage;
              this.esppWithdrawOffernum = esppActionItemDetails[0][i].OfferNum;
            }else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) { //update
              this.esppchangeheader = esppActionItemDetails[0][i].Header;
              this.esppChangeContriSubmessage = esppActionItemDetails[0][i].SubMessage;
              this.esppChangeOffernum = esppActionItemDetails[0][i].OfferNum;
            }else if (esppActionItemDetails[0][i].EnrollmentFlag == 7 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //Closed Enrollment, RBA 8
              this.esppclosedheadercurroff = esppActionItemDetails[0][i].Header;
              this.esppclosedSubmessagecurroff = esppActionItemDetails[0][i].SubMessage;
              this.esppClosedCurrOffernum = esppActionItemDetails[0][i].OfferNum;  
            }else if (esppActionItemDetails[0][i].EnrollmentFlag == 7 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) { //Closed Enrollment Next Offering, RBA 10
                this.esppclosedheadernextoff = esppActionItemDetails[0][i].Header;
                this.esppclosedSubmessagenextoff = esppActionItemDetails[0][i].SubMessage;
                this.esppClosedNextOffernum = esppActionItemDetails[0][i].OfferNum;  
            }else if (esppActionItemDetails[0][i].EnrollmentFlag == 2 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw
              this.esppwithdrawheader = esppActionItemDetails[0][i].Header;
              this.esppWithdrawSubmessage = esppActionItemDetails[0][i].SubMessage;
              this.esppWithdrawOffernum = esppActionItemDetails[0][i].OfferNum;
            }else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //view button text
              this.esppwithdrawnheader = esppActionItemDetails[0][i].Header;
              this.esppWithdrawnSubmessage = esppActionItemDetails[0][i].SubMessage;
              this.esppWithdrawnOffernum = esppActionItemDetails[0][i].OfferNum;
  
            }
            else if ((esppActionItemDetails[0][i].EnrollmentFlag == 4 || esppActionItemDetails[0][i].EnrollmentFlag == 6) && esppActionItemDetails[0][i].EsppEligibility == 1) { //view button text
              this.esppwithdrawnheaderFP = esppActionItemDetails[0][i].Header;
              this.esppWithdrawnSubmessageFP = esppActionItemDetails[0][i].SubMessage;
              this.esppWithdrawnOffernumFP = esppActionItemDetails[0][i].OfferNum;
  
            }
            else {

            }
            
            if (esppActionItemDetails[0][i].EnrollmentFlag == 0 && esppActionItemDetails[0][i].VeipEligibility == 0) {
              this.showEsppEnrollEligibility = false;
            } else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].VeipEligibility == 0) {
              this.ShowEsppChangeContriEligibility = false;
            } else if (esppActionItemDetails[0][i].EnrollmentFlag == 2 && esppActionItemDetails[0][i].VeipEligibility == 0) {
              this.ShowEsppWithdrawEligibility = false;
            } else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].VeipEligibility == 0) {
              this.ShowEsppWithdrawnEligibility = false;
            }
          }
        }
        else {
          //No Data and Not Eligible
          this.hasActionItemsESPP = false;
        }
        // this.getVEIPActionItemDetail();
      }
      catch (error) {
        this.errorEsppActionItem = true;
        let errorLocation = this.errorLocation.replace("methodName", "getESPPActionItemDetail");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.espptransactionService.getESPPActionItem(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorEsppActionItem = false;
          let esppActionItemDetails = data;
          this.esppOfferingDetails = esppActionItemDetails[0];
          
          if ((Array.isArray(data) && data[0].length > 0)) {
            this.hasActionItemsESPP = true;

            for (let i = 0; i < esppActionItemDetails[0].length; i++) {
              if (esppActionItemDetails[0][i].EnrollmentFlag == 0 && this.user.EmployeeStatus != 'Terminated' && this.user.EsppEligibleInd != 'N' && esppActionItemDetails[0][i].EsppEligibility == 1) { //ENROLL
                this.ShowESPPEnroll = true;
                this.actionItemCardStatus.push(this.ShowESPPEnroll);          
              }
              else if (esppActionItemDetails[0][i].EnrollmentFlag == 7 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) {//Closed Enrollment, RBA 8              
              this.ShowESPPEnrollClosedCurrOff  = true;
              this.actionItemCardStatus.push(this.ShowESPPEnrollClosedCurrOff);
              }
              else if (esppActionItemDetails[0][i].EnrollmentFlag == 7 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) {//Closed Enrollment Next Offering, RBA 10            
                this.ShowESPPEnrollClosedNextOff  = true;
                this.actionItemCardStatus.push(this.ShowESPPEnrollClosedNextOff);
              }
              else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw button text
                this.ShowESPPWithdraw = true;
                this.actionItemCardStatus.push(this.ShowESPPWithdraw);
              }
              else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) { //UPDATE
                this.ShowESPPChangeContri = true;
                this.actionItemCardStatus.push(this.ShowESPPChangeContri);
              }
              else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw button text
                this.ShowESPPWithdraw = true;
                this.actionItemCardStatus.push(this.ShowESPPWithdraw);
              }
              else if (esppActionItemDetails[0][i].EnrollmentFlag == 2 && esppActionItemDetails[0][i].EsppEligibility == 1) { //WITHDRAW
                this.ShowESPPWithdraw = true;
                this.actionItemCardStatus.push(this.ShowESPPWithdraw);
              }
              else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //VIEW
                this.ShowESPPWithdrawn = true;
                this.actionItemCardStatus.push(this.ShowESPPWithdrawn);
              }
              else if (esppActionItemDetails[0][i].EnrollmentFlag == 4 && esppActionItemDetails[0][i].EsppEligibility == 1) { //view button text
                this.ShowESPPWithdrawnFP = true;
                this.showViewBtn = true;
                this.actionItemCardStatus.push(this.ShowESPPWithdrawnFP);
              }else if (esppActionItemDetails[0][i].EnrollmentFlag == 6 && esppActionItemDetails[0][i].EsppEligibility == 1) { //display card but no button
                this.ShowESPPWithdrawnFP = true;
                this.actionItemCardStatus.push(this.ShowESPPWithdrawnFP);
              }else {
                // this.hasActionItemsESPP = false;                 
              }

              if (esppActionItemDetails[0][i].OfferingFlag == 2 && esppActionItemDetails[0][i].EnrollmentFlag == 1) { //TAX ELECT
                this.showTaxElect = true;
                this.actionItemCardStatus.push(this.showTaxElect);
              }
              else if (esppActionItemDetails[0][i].OfferingFlag == 2 && esppActionItemDetails[0][i].EnrollmentFlag == 2){ //Tax Elect Freeze Period, RBA,9 START
                this.showTaxElectView = true;
                this.actionItemCardStatus.push(this.showTaxElectView);
              }//Tax Elect Freeze Period, RBA,9 START
            }

            if (this.actionItemCardStatus.length < 1) {
              this.hasActionItemsESPP = false;
            }

            for (let i = 0; i < esppActionItemDetails[0].length; i++) {
              if (esppActionItemDetails[0][i].EnrollmentFlag == 0 && esppActionItemDetails[0][i].OfferingFlag != 2 && esppActionItemDetails[0][i].EsppEligibility == 1) { //enroll 
                this.esppenrollheader = esppActionItemDetails[0][i].Header;
                this.esppenrollSubmessage = esppActionItemDetails[0][i].SubMessage;
                this.esppenrollOffernum = esppActionItemDetails[0][i].OfferNum;
              } else if (esppActionItemDetails[0][i].OfferingFlag == 2 && esppActionItemDetails[0][i].EnrollmentFlag != 0) { //tax elect
                this.taxElectHeader = esppActionItemDetails[0][i].Header;
                this.taxElectSubMesssage = esppActionItemDetails[0][i].SubMessage;
                this.taxElectOffernum = esppActionItemDetails[0][i].OfferNum;
              }else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw
                this.esppwithdrawheader = esppActionItemDetails[0][i].Header;
                this.esppWithdrawSubmessage = esppActionItemDetails[0][i].SubMessage;
                this.esppWithdrawOffernum = esppActionItemDetails[0][i].OfferNum;
              }else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw button text
                this.esppwithdrawheader = esppActionItemDetails[0][i].Header;
                this.esppWithdrawSubmessage = esppActionItemDetails[0][i].SubMessage;
                this.esppWithdrawOffernum = esppActionItemDetails[0][i].OfferNum;
              }else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1)  { //update
                this.esppchangeheader = esppActionItemDetails[0][i].Header;
                this.esppChangeContriSubmessage = esppActionItemDetails[0][i].SubMessage;
                this.esppChangeOffernum = esppActionItemDetails[0][i].OfferNum;
              }else if (esppActionItemDetails[0][i].EnrollmentFlag == 7 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //Closed Enrollment, RBA 8
                this.esppclosedheadercurroff = esppActionItemDetails[0][i].Header;
                this.esppclosedSubmessagecurroff = esppActionItemDetails[0][i].SubMessage;
                this.esppClosedCurrOffernum = esppActionItemDetails[0][i].OfferNum;
              }else if (esppActionItemDetails[0][i].EnrollmentFlag == 7 && esppActionItemDetails[0][i].OfferingFlag == 0 && esppActionItemDetails[0][i].EsppEligibility == 1) { //Closed Enrollment Next Offering, RBA 10
                this.esppclosedheadernextoff = esppActionItemDetails[0][i].Header;
                this.esppclosedSubmessagenextoff = esppActionItemDetails[0][i].SubMessage;
                this.esppClosedNextOffernum = esppActionItemDetails[0][i].OfferNum;
              }else if (esppActionItemDetails[0][i].EnrollmentFlag == 2 && esppActionItemDetails[0][i].EsppEligibility == 1) { //withdraw
                this.esppwithdrawheader = esppActionItemDetails[0][i].Header;
                this.esppWithdrawSubmessage = esppActionItemDetails[0][i].SubMessage;
                this.esppWithdrawOffernum = esppActionItemDetails[0][i].OfferNum;
              }else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].OfferingFlag == 1 && esppActionItemDetails[0][i].EsppEligibility == 1) { //view button text
                this.esppwithdrawnheader = esppActionItemDetails[0][i].Header;
                this.esppWithdrawnSubmessage = esppActionItemDetails[0][i].SubMessage;
                this.esppWithdrawnOffernum = esppActionItemDetails[0][i].OfferNum;
              }else if ((esppActionItemDetails[0][i].EnrollmentFlag == 4 || esppActionItemDetails[0][i].EnrollmentFlag == 6) && esppActionItemDetails[0][i].EsppEligibility == 1) { //view button text
                this.esppwithdrawnheaderFP = esppActionItemDetails[0][i].Header;
                this.esppWithdrawnSubmessageFP = esppActionItemDetails[0][i].SubMessage;
                this.esppWithdrawnOffernumFP = esppActionItemDetails[0][i].OfferNum;
              }else {
  
              }


              if (esppActionItemDetails[0][i].EnrollmentFlag == 0 && esppActionItemDetails[0][i].VeipEligibility == 0) {
                this.showEsppEnrollEligibility = false;
              } else if (esppActionItemDetails[0][i].EnrollmentFlag == 1 && esppActionItemDetails[0][i].VeipEligibility == 0) {
                this.ShowEsppChangeContriEligibility = false;
              } else if (esppActionItemDetails[0][i].EnrollmentFlag == 2 && esppActionItemDetails[0][i].VeipEligibility == 0) {
                this.ShowEsppWithdrawEligibility = false;
              } else if (esppActionItemDetails[0][i].EnrollmentFlag == 3 && esppActionItemDetails[0][i].VeipEligibility == 0) {
                this.ShowEsppWithdrawnEligibility = false;
              }
            }
          }
          else {
            //No Data and Not Eligible
            this.hasActionItemsESPP = false;
          }
          sessionStorage.setItem("ESPPActionItemCardCacheData", JSON.stringify(data));
          // this.getVEIPActionItemDetail();
        }
        catch (error) {
          this.errorEsppActionItem = true;
          let errorLocation = this.errorLocation.replace("methodName", "getESPPActionItemDetail");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorEsppActionItem = true;
        let errorLocation = this.errorLocation.replace("methodName", "getESPPActionItemDetail");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  async getVEIPActionItemDetail() {
    let veipActionItemDetailsLocalSession = {'Header':'','SubMessage':'','Button':'','CyNum':0, 'showVEIPcard': false,'offerNum':''};
    let veipActionItemDetailsLocal = {'Header':'','SubMessage':'','Button':'','CyNum':0, 'showVEIPcard': false,'offerNum':''};
    // let data1 = '[[{"OfferingFlag":1,"OfferNum":55,"OptNum":170885,"Header":"VEIP: Enrolled at 10%","SubMessage":"Automatically re-enrolled for 01 Jan 2019 - 30 Dec 2019","EnrollmentFlag":2,"VeipEligibility":1},{"OfferingFlag":2,"OfferNum":69,"OptNum":170885,"Header":"VEIP: Not Enrolled","SubMessage":"Enrollment for 01 Jan 2020 - 31 Dec 2020 is closed.","EnrollmentFlag":4,"VeipEligibility":1}]]';
    // sessionStorage.setItem("VEIPActionItemCardCacheData",data1)
    if (sessionStorage.getItem("VEIPActionItemCardCacheData") != null) {
      try {
        let cachedData = JSON.parse(sessionStorage.getItem("VEIPActionItemCardCacheData"))
        this.errorVeipActionItem = false;
        let veipActionItemDetails = cachedData;
        this.veipOfferingDetails = veipActionItemDetails[0];


        if ((Array.isArray(cachedData) && cachedData[0].length > 0)) {
          this.hasActionItemsVEIP = true;

          for (let i = 0; i < veipActionItemDetails[0].length; i++) {
              if (veipActionItemDetails[0][i].EnrollmentFlag == 0) {
                this.ShowVEIPEnroll = true;
                this.actionItemCardStatus.push(this.ShowVEIPEnroll);
              } else if (veipActionItemDetails[0][i].EnrollmentFlag == 1) {
                this.ShowVEIPChangeContri = true;
                this.actionItemCardStatus.push(this.ShowVEIPChangeContri);
              } else if (veipActionItemDetails[0][i].EnrollmentFlag == 2) {
                this.ShowVEIPWithdraw = true;
                this.actionItemCardStatus.push(this.ShowVEIPWithdraw);
              } else if (veipActionItemDetails[0][i].EnrollmentFlag == 3) {
                this.ShowVEIPWithdrawn = true;
                this.actionItemCardStatus.push(this.ShowVEIPWithdrawn);
              } else if (veipActionItemDetails[0][i].EnrollmentFlag == 4) {
                this.actionItemCardStatus.push(true);
              }

            if (veipActionItemDetails[0][i].EnrollmentFlag == 0 && veipActionItemDetails[0][i].VeipEligibility == 1) { //enroll
              // this.veipenrollheader = veipActionItemDetails[0][i].Header;
              // this.veipenrollSubmessage = veipActionItemDetails[0][i].SubMessage;

              veipActionItemDetailsLocalSession = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"ENROLL","CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
              this.veipActionItemDetails.push(veipActionItemDetailsLocalSession);
            }
            else if (veipActionItemDetails[0][i].EnrollmentFlag == 1 && veipActionItemDetails[0][i].VeipEligibility == 1) { //update
              // this.veipchangeheader = veipActionItemDetails[0][i].Header;
              // this.veipChangeContriSubmessage = veipActionItemDetails[0][i].SubMessage;

              veipActionItemDetailsLocalSession = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"UPDATE","CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
              this.veipActionItemDetails.push(veipActionItemDetailsLocalSession);
              
            }
            else if (veipActionItemDetails[0][i].EnrollmentFlag == 2 && veipActionItemDetails[0][i].VeipEligibility == 1) { //withdraw
              // this.veipwithdrawheader = veipActionItemDetails[0][i].Header;
              // this.veipWithdrawSubmessage = veipActionItemDetails[0][i].SubMessage;

              veipActionItemDetailsLocalSession = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"WITHDRAW","CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
              this.veipActionItemDetails.push(veipActionItemDetailsLocalSession);
            }
            else if (veipActionItemDetails[0][i].EnrollmentFlag == 4 && veipActionItemDetails[0][i].VeipEligibility == 1) { //not enrolled
              // this.veipchangeheader = veipActionItemDetails[0][i].Header;
              // this.veipChangeContriSubmessage = veipActionItemDetails[0][i].SubMessage;

              veipActionItemDetailsLocalSession = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":null,"CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
              this.veipActionItemDetails.push(veipActionItemDetailsLocalSession);
              
            }else if (veipActionItemDetails[0][i].EnrollmentFlag == 3 && veipActionItemDetails[0][i].VeipEligibility == 1) { //withdrawn
              // this.veipchangeheader = veipActionItemDetails[0][i].Header;
              // this.veipChangeContriSubmessage = veipActionItemDetails[0][i].SubMessage;

              veipActionItemDetailsLocalSession = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"VIEW","CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
              this.veipActionItemDetails.push(veipActionItemDetailsLocalSession);
              
            }else { //view
                // this.veipwithdrawnheader = veipActionItemDetails[0][i].Header;
                // this.veipWithdrawnSubmessage = veipActionItemDetails[0][i].SubMessage;
              
                // veipActionItemDetailsLocalSession = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"VIEW","Flag":veipActionItemDetails[0][i].EnrollmentFlag,"OfferNum":veipActionItemDetails[0][i].OfferNum, 'showVEIPcard': true};
                // this.veipActionItemDetails.push(veipActionItemDetailsLocalSession);
            }


            // if (veipActionItemDetails[0][i].EnrollmentFlag == 0 && veipActionItemDetails[0][i].VeipEligibility == 0) {
            //   this.showVeipEnrollEligibility = false;
            // } else if (veipActionItemDetails[0][i].EnrollmentFlag == 1 && veipActionItemDetails[0][i].VeipEligibility == 0) {
            //   this.ShowVEIPChangeContriEligibility = false;
            // } else if (veipActionItemDetails[0][i].EnrollmentFlag == 2 && veipActionItemDetails[0][i].VeipEligibility == 0) {
            //   this.ShowVEIPWithdrawEligibility = false;
            // } else if (veipActionItemDetails[0][i].EnrollmentFlag == 3 && veipActionItemDetails[0][i].VeipEligibility == 0) {
            //   this.ShowVEIPWithdrawnEligibility = false;
            // }
          }
          this.veipActionItemDetails.sort((n1,n2) => n2['offerNum'] - n1['offerNum']);

        } else {
          //No Data
          this.hasActionItemsVEIP = false;
        }
        // this.getGrantActionItemDetail();
      }
      catch (error) {
        this.errorVeipActionItem = true;
        let errorLocation = this.errorLocation.replace("methodName", "getVEIPActionItemDetail");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.veiptransactionService.getVEIPActionItem(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorVeipActionItem = false;
          let veipActionItemDetails = data;
          this.veipOfferingDetails = veipActionItemDetails[0];
          

          if ((Array.isArray(data) && data[0].length > 0)) {
            this.hasActionItemsVEIP = true;
            for (let i = 0; i < veipActionItemDetails[0].length; i++) {
              if (veipActionItemDetails[0][i].EnrollmentFlag == 0) {
                this.ShowVEIPEnroll = true;
                this.actionItemCardStatus.push(this.ShowVEIPEnroll);
              } else if (veipActionItemDetails[0][i].EnrollmentFlag == 1) {
                this.ShowVEIPChangeContri = true;
                this.actionItemCardStatus.push(this.ShowVEIPChangeContri);
              } else if (veipActionItemDetails[0][i].EnrollmentFlag == 2) {
                this.ShowVEIPWithdraw = true;
                this.actionItemCardStatus.push(this.ShowVEIPWithdraw);
              } else if (veipActionItemDetails[0][i].EnrollmentFlag == 3) {
                this.ShowVEIPWithdrawn = true;
                this.actionItemCardStatus.push(this.ShowVEIPWithdrawn);
              } else if(veipActionItemDetails[0][i].EnrollmentFlag == 4) {
                this.actionItemCardStatus.push(true);
              }

              if (veipActionItemDetails[0][i].EnrollmentFlag == 0 && veipActionItemDetails[0][i].VeipEligibility == 1) {
                // this.veipenrollheader = veipActionItemDetails[0][i].Header;
                // this.veipenrollSubmessage = veipActionItemDetails[0][i].SubMessage;
                veipActionItemDetailsLocal = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"ENROLL","CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
                this.veipActionItemDetails.push(veipActionItemDetailsLocal);
              }
              else if (veipActionItemDetails[0][i].EnrollmentFlag == 1 && veipActionItemDetails[0][i].VeipEligibility == 1) {
                // this.veipchangeheader = veipActionItemDetails[0][i].Header;
                // this.veipChangeContriSubmessage = veipActionItemDetails[0][i].SubMessage;
                veipActionItemDetailsLocal = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"UPDATE","CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
                this.veipActionItemDetails.push(veipActionItemDetailsLocal);
              }
              else if (veipActionItemDetails[0][i].EnrollmentFlag == 2 && veipActionItemDetails[0][i].VeipEligibility == 1) {
                // this.veipwithdrawheader = veipActionItemDetails[0][i].Header;
                // this.veipWithdrawSubmessage = veipActionItemDetails[0][i].SubMessage;
                veipActionItemDetailsLocal = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"WITHDRAW","CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
                this.veipActionItemDetails.push(veipActionItemDetailsLocal);
              }
              else if (veipActionItemDetails[0][i].EnrollmentFlag == 4 && veipActionItemDetails[0][i].VeipEligibility == 1) { 
                // this.veipchangeheader = veipActionItemDetails[0][i].Header;
                // this.veipChangeContriSubmessage = veipActionItemDetails[0][i].SubMessage;
  
                veipActionItemDetailsLocal = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":null,"CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
                this.veipActionItemDetails.push(veipActionItemDetailsLocal);
                
              }else if (veipActionItemDetails[0][i].EnrollmentFlag == 3 && veipActionItemDetails[0][i].VeipEligibility == 1) {
                // this.veipchangeheader = veipActionItemDetails[0][i].Header;
                // this.veipChangeContriSubmessage = veipActionItemDetails[0][i].SubMessage;
  
                veipActionItemDetailsLocal = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"VIEW","CyNum":veipActionItemDetails[0][i].CyNum, 'showVEIPcard': true, "offerNum":veipActionItemDetails[0][i].OfferNum};
                this.veipActionItemDetails.push(veipActionItemDetailsLocal);
                
              }else { //view
                  // this.veipwithdrawnheader = veipActionItemDetails[0][i].Header;
                  // this.veipWithdrawnSubmessage = veipActionItemDetails[0][i].SubMessage;
                
                  // veipActionItemDetailsLocalSession = {'Header':veipActionItemDetails[0][i].Header,"SubMessage":veipActionItemDetails[0][i].SubMessage,"Button":"VIEW","Flag":veipActionItemDetails[0][i].EnrollmentFlag,"OfferNum":veipActionItemDetails[0][i].OfferNum, 'showVEIPcard': true};
                  // this.veipActionItemDetails.push(veipActionItemDetailsLocalSession);
              }
          

              // if(veipActionItemDetails[0][i].VeipEligibility == 0){
              //   this.showVeipEnrollEligibility = false;
              // }

              // if (veipActionItemDetails[0][i].EnrollmentFlag == 0 && veipActionItemDetails[0][i].VeipEligibility == 0) {
              //   this.showVeipEnrollEligibility = false;
              // } else if (veipActionItemDetails[0][i].EnrollmentFlag == 1 && veipActionItemDetails[0][i].VeipEligibility == 0) {
              //   this.ShowVEIPChangeContriEligibility = false;
              // } else if (veipActionItemDetails[0][i].EnrollmentFlag == 2 && veipActionItemDetails[0][i].VeipEligibility == 0) {
              //   this.ShowVEIPWithdrawEligibility = false;
              // } else if (veipActionItemDetails[0][i].EnrollmentFlag == 3 && veipActionItemDetails[0][i].VeipEligibility == 0) {
              //   this.ShowVEIPWithdrawnEligibility = false;
              // }
            }           
            this.veipActionItemDetails.sort((n1,n2) => n2['offerNum'] - n1['offerNum']);
          } else {
            //No Data
            this.hasActionItemsVEIP = false;
          }
          sessionStorage.setItem("VEIPActionItemCardCacheData", JSON.stringify(data));
          // this.getGrantActionItemDetail();
        }
        catch (error) {
          this.errorVeipActionItem = true;
          let errorLocation = this.errorLocation.replace("methodName", "getVEIPActionItemDetail");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorVeipActionItem = true;
        let errorLocation = this.errorLocation.replace("methodName", "getVEIPActionItemDetail");
        error.error.message += errorLocation;
        throw error.error;
      });
    }

  }

  /**************************************************************************************************
        Method Name: getGrantActionItemDetail
        Description: Get Grant Action Item Details .

        Date            Modified by       Description
        02/26/19        Roldan Mazo       Initial Draft
        3/14/19         Roldan Mazo       Added condition to set grant Checker Value
        05/27/19        JPA               Added async for loading
  *****************************************************************************************************/
  async getGrantActionItemDetail() {
    await this.espptransactionService.getGrantActionitemAPI(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      try {
        this.errorGrantAlert = false;
        let GrantAlertData = data;

        if ((Array.isArray(GrantAlertData)) && (GrantAlertData.length == 1)) {
          this.ShowGrantAlert = true;
          this.ShowMultipleGrantAlert = false;
          this.actionItemCardStatus.push(this.ShowGrantAlert);

          this.actionItemCardStatus.push(this.ShowMultipleGrantAlert);

          this.topGrantAlert = GrantAlertData[0];

          this.topGrantHeader = GrantAlertData[0].Header;
          this.topGrantSubmessage = GrantAlertData[0].SubMessage;

          sessionStorage.setItem("Top Grant", JSON.stringify(this.topGrantAlert));

          if (GrantAlertData[0].SubMessage.indexOf('Opt') > -1) {
            this.grantChecker = 'Options';
          } else {
            this.grantChecker = 'Rsu';
          }
        }
        else if ((Array.isArray(GrantAlertData)) && (GrantAlertData.length > 1)) {
          this.ShowGrantAlert = false;
          this.ShowMultipleGrantAlert = true;
          this.actionItemCardStatus.push(this.ShowMultipleGrantAlert);

          this.multipleGrantList = GrantAlertData;

          sessionStorage.setItem("Multiple Grant List", JSON.stringify(this.multipleGrantList));

        }
        else {
          this.ShowGrantAlert = false;
          this.ShowMultipleGrantAlert = false;
        }
        //Count Action Items
        for (let i = 0; i < this.actionItemCardStatus.length; ++i) {
          if (this.actionItemCardStatus[i] == true) {
            this.actionitemcount++;
          }
        }
        // sessionStorage.setItem("GrantActionItemCardCacheData", JSON.stringify(data));
        this.displayCard(this.user.OptNum);
      }
      catch (error) {
        this.errorGrantAlert = true;
        let errorLocation = this.errorLocation.replace("methodName", "getGrantActionItemDetail");
        error.message = error.message + errorLocation;
        throw error;
      }
      this.getActionDataApi();
    }, error => {
      this.errorGrantAlert = true;
      let errorLocation = this.errorLocation.replace("methodName", "getGrantActionItemDetail");
      error.error.message += errorLocation;
      throw error.error;
    });
    // if(sessionStorage.getItem("GrantActionItemCardCacheData") != null){
    //   try {
    //     let cacheData = JSON.parse(sessionStorage.getItem("GrantActionItemCardCacheData"));
    //     this.errorGrantAlert = false;
    //     let GrantAlertData = cacheData;

    //     if((Array.isArray(GrantAlertData)) && (GrantAlertData.length == 1)) {
    //       this.ShowGrantAlert = true;
    //       this.ShowMultipleGrantAlert = false;
    //       this.actionItemCardStatus.push(this.ShowGrantAlert);
    //       this.actionItemCardStatus.push(this.ShowMultipleGrantAlert);

    //       this.topGrantAlert = GrantAlertData[0];

    //       this.topGrantHeader = GrantAlertData[0].Header;
    //       this.topGrantSubmessage = GrantAlertData[0].SubMessage;

    //       sessionStorage.setItem("Top Grant", JSON.stringify(this.topGrantAlert));

    //         if (GrantAlertData[0].SubMessage.indexOf('Opt') > -1){
    //           this.grantChecker = 'Options';
    //         }else{
    //           this.grantChecker = 'Rsu';
    //         }
    //     }
    //     else if((Array.isArray(GrantAlertData)) && (GrantAlertData.length > 1)) {

    //       this.ShowGrantAlert = false;
   //       this.ShowMultipleGrantAlert = true;

    //       this.multipleGrantList = GrantAlertData;

    //       sessionStorage.setItem("Multiple Grant List", JSON.stringify(this.multipleGrantList));

    //     }
    //     else {
    //       this.ShowGrantAlert = false;
    //       this.ShowMultipleGrantAlert = false;
    //     }
    //     //Count Action Items
    //     for(let i = 0; i < this.actionItemCardStatus.length; ++i){
    //       if(this.actionItemCardStatus[i] == true){
    //         this.actionitemcount++;
    //       }
    //     }
    //     this.displayCard(this.user.OptNum);
    // }
    // catch(error) {
    //       this.errorGrantAlert = true;
    //       let errorLocation = this.errorLocation.replace("methodName", "getGrantActionItemDetail");
    //       error.message = error.message + errorLocation;
    //       throw error;
    //     }
    //     this.getActionDataApi();
    // }
    // else
    // {
    //   this.espptransactionService.getGrantActionitemAPI(this.user.OptNum).map(res => res).subscribe(data => {
    //     try {
    //       this.errorGrantAlert = false;
    //       let GrantAlertData = data;

    //       if((Array.isArray(GrantAlertData)) && (GrantAlertData.length == 1)) {
    //         this.ShowGrantAlert = true;
    //         this.ShowMultipleGrantAlert = false;
    //         this.actionItemCardStatus.push(this.ShowGrantAlert);
   //         this.actionItemCardStatus.push(this.ShowMultipleGrantAlert);

    //         this.topGrantAlert = GrantAlertData[0];

    //         this.topGrantHeader = GrantAlertData[0].Header;
    //         this.topGrantSubmessage = GrantAlertData[0].SubMessage;

    //         sessionStorage.setItem("Top Grant", JSON.stringify(this.topGrantAlert));

    //           if (GrantAlertData[0].SubMessage.indexOf('Opt') > -1){
    //             this.grantChecker = 'Options';
    //           }else{
    //             this.grantChecker = 'Rsu';
    //           }
    //       }
    //       else if((Array.isArray(GrantAlertData)) && (GrantAlertData.length > 1)) {
    //         this.ShowGrantAlert = false;
    //         this.ShowMultipleGrantAlert = true;

    //         this.multipleGrantList = GrantAlertData;

    //         sessionStorage.setItem("Multiple Grant List", JSON.stringify(this.multipleGrantList));

    //       }
    //       else {
    //         this.ShowGrantAlert = false;
    //         this.ShowMultipleGrantAlert = false;
    //       }
    //       //Count Action Items
    //       for(let i = 0; i < this.actionItemCardStatus.length; ++i){
    //         if(this.actionItemCardStatus[i] == true){
    //           this.actionitemcount++;
    //         }
    //       }
    //       sessionStorage.setItem("GrantActionItemCardCacheData", JSON.stringify(data));
    //       this.displayCard(this.user.OptNum);
    //   }
    //   catch(error) {
    //         this.errorGrantAlert = true;
    //         let errorLocation = this.errorLocation.replace("methodName", "getGrantActionItemDetail");
    //         error.message = error.message + errorLocation;
    //         throw error;
    //       }
    //       this.getActionDataApi();
    //   }, error => {
    //     this.errorGrantAlert = true;
    //     let errorLocation = this.errorLocation.replace("methodName", "getGrantActionItemDetail");
    //     error.error.message += errorLocation;
    //     throw error.error;
    //   });
    // }

  }

  /**************************************************************************************************
        Method Name: sendESPPActionDetails and sendVEIPActionDetails
        Description: Store ESPP and VEIP Details from Action Item Card
  
        Date            Modified by       Description    
        02/26/19        Roldan Mazo       Initial Draft  
        05/27/19        JPA               Added async for loading    
        10/30/19        KMN               Modified logic for dynamic WITHDRAW button text scenarios
        11/25/19        JSE               Modified //edited #1 at sendVEIPActionDetails - used offerNum instead of flag
  *****************************************************************************************************/
 async sendESPPActionDetails(esppclick, checker, offernum) {
  sessionStorage.setItem("ESPP DETAILS KEY", JSON.stringify(null));
  sessionStorage.setItem("TAX ELECT DETAILS KEY", JSON.stringify(null));

  await this.espptransactionService.getESPPActionItem(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
    try {
      let esppDetails = data;
      let esppDetailsData = data[0];
      let NewesppDetails: any[] = [];
      let isESPPTaxElect: boolean = false;
      sessionStorage.setItem("ESPP ACTION ITEMS", JSON.stringify(esppDetails[0]));

      let clickedESPPData = esppDetailsData.find(i => i.OfferNum == offernum);
      //CHECK FOR TAX ELECT DATA
      for (let i = 0, l = esppDetails[0].length; i < l; i++) {
        if (checker == "tax elect") {
          if (esppDetails[0][i].OfferingFlag == esppclick) {
            NewesppDetails.push(esppDetails[0][i]);
            isESPPTaxElect = true;
          }
        }
      }

      //PUSH ANY ESPP TRANSACTION (ENROLL,UPDATE,WITHDRAW,VIEW BUTTON TEXT)
      if(!isESPPTaxElect){
        if((clickedESPPData != null || clickedESPPData != undefined) && checker != "tax elect"){
          NewesppDetails.push(clickedESPPData);
        }
      }

      if (checker == "espp") {
        sessionStorage.setItem("ESPP DETAILS KEY", JSON.stringify(NewesppDetails));
      } else if (checker == "tax elect") {
        sessionStorage.setItem("TAX ELECT DETAILS KEY", JSON.stringify(NewesppDetails));
      }
      this.navToPage('espptransactionpage');
    } catch (error) {
      this.errorActionItemCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "sendESPPActionDetails");
      error.message += errorLocation;
      throw error;
    }
  }, error => {
    this.errorActionItemCard = true;
    let errorLocation = this.errorLocation.replace("methodName", "sendESPPActionDetails");
    error.error.message += errorLocation;
    throw error.error;
  });
}

  async sendVEIPActionDetails(veipclick) {
    await this.veiptransactionService.getVEIPActionItem(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      try {
        let veipDetails = data;
        let NewveipDetails: any[] = [];

          for (let i = 0, l = veipDetails[0].length; i < l; i++) {
            if (veipDetails[0][i].CyNum == veipclick) { //edited #1
              NewveipDetails.push(veipDetails[0][i]);
            }
          }
          
        sessionStorage.setItem("VEIP DETAILS KEY", JSON.stringify(NewveipDetails));
        sessionStorage.setItem("VEIP TRANS API OUTPUT", JSON.stringify(veipDetails));
        this.navToPage('veiptransactionpage');
      } catch (error) {
        let errorLocation = this.errorLocation.replace("methodName", "sendVEIPActionDetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "sendVEIPActionDetails");
      error.error.message += errorLocation;
      throw error.error;
    });
  }

  /**************************************************************************************************
        Method Name: getActionDataApi
        Description: Get RSU and Options Data from holding summary page.

        Date            Modified by       Description
        02/26/19        Roldan Mazo       Initial Draft
        05/27/19        JPA               Added async for loading
  *****************************************************************************************************/

  async getActionDataApi() {
    if (sessionStorage.getItem("Action RSU Data") != null) {
      try {
        let cacheDataRSU = JSON.parse(sessionStorage.getItem("Action RSU Data"));
        let body = cacheDataRSU;
        sessionStorage.setItem("Action RSU Data", JSON.stringify(body));

        if (sessionStorage.getItem("Options Data") != null) {
          try {
            // let cacheDataOption = JSON.parse(sessionStorage.getItem("Action Options Data"));
            // let obody = cacheDataOption;
            this.getGrantAlertData();
          } catch (error) {
            let errorLocation = " + [Component: Homepage, Method: getActionDataApi]";
            error.message += errorLocation;
            throw error;
          }
        }
        else {
          await this.holdingsummaryservice.getOptions(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
            try {
              let obody = data;
              sessionStorage.setItem("Options Data", JSON.stringify(obody));
              this.getGrantAlertData();
            } catch (error) {
              let errorLocation = " + [Component: Homepage, Method: getActionDataApi]";
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            let errorLocation = this.errorLocation.replace("methodName", "getRsuApiData");
            error.error.message += errorLocation;
            throw error.error;
          });
        }
      } catch (error) {
        let errorLocation = this.errorLocation.replace("methodName", "getActionDataApi");
        error.message += errorLocation;
        throw error;
      }
    }
    else {
      await this.holdingsummaryservice.getRSU(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          let body = data;
          sessionStorage.setItem("Action RSU Data", JSON.stringify(body));
          if (sessionStorage.getItem("Options Data") != null) {
            // let cacheDataOption = JSON.parse(sessionStorage.getItem("Action Options Data"));
            // let obody = cacheDataOption;
            this.getGrantAlertData();
          }
          else {
            this.holdingsummaryservice.getOptions(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
              try {
                let obody = data;
                sessionStorage.setItem("Options Data", JSON.stringify(obody));
                this.getGrantAlertData();
              } catch (error) {
                let errorLocation = " + [Component: Homepage, Method: getActionDataApi]";
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              let errorLocation = this.errorLocation.replace("methodName", "getRsuApiData");
              error.error.message += errorLocation;
              throw error.error;
            });
          }


        } catch (error) {
          let errorLocation = this.errorLocation.replace("methodName", "getActionDataApi");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getActionDataApi");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
  }

  /**************************************************************************************************
        Method Name: getGrantAlertData
        Description: Get top 1 grant data to be dispalyed in action item card

        Date            Modified by       Description
        02/26/19        Roldan Mazo       Added inital Action Item Functions
        3/12/19         Roldan Mazo       Added null values in the condition to fix error in homepage
        3/14/19         Roldan Mazo       Added Grant Checker in Condition
        05/27/19        JPA               Added async for loading
  *****************************************************************************************************/

  async getGrantAlertData() {
    let latestPrice = this.marketQuoteData.latestPrice;

    let data = JSON.parse(sessionStorage.getItem("Action RSU Data"));
    let body = data;

    let optionsData = JSON.parse(sessionStorage.getItem("Options Data"));
    let grantOptionsData = optionsData;

    let dataTopGrant = JSON.parse(sessionStorage.getItem("Top Grant"));
    let dataTopGrantDetail = dataTopGrant;

    try {

      if (((body.length > 0) || (body != null)) && (dataTopGrantDetail != null) && (this.grantChecker == 'Rsu')) {
        for (let i = 0; i < body.length; i++) {
          if (i > 0) {

            for (let x = 0; x < body[i].length; x++) {

              if (body[i][x].GrantNum == dataTopGrantDetail.GrantNum) {
                body[i][x].AvailableToSellValue = body[i][x].AvailableToSell * latestPrice * this.exchangeRate;
                this.grantAlertDetails = [];
                this.grantAlertDetails.push(body[i][x]);
                this.hasActionItems = true;
              }
              else {
                //No RSU Data
                this.hasActionItems = false;
              }
            }
          } else {
            //DO NOTHING}
          }
        }
      }
      else if (((grantOptionsData.length > 0) || (grantOptionsData != null)) && (dataTopGrantDetail != null) && (this.grantChecker == 'Options')) {
        for (let i = 0; i < grantOptionsData.length; i++) {
          if (i > 0) {

            for (let x = 0; x < grantOptionsData[i].length; x++) {

              if (grantOptionsData[i][x].GrantNum == dataTopGrantDetail.GrantNum) {
                if ((latestPrice - grantOptionsData[i][x].GrantPrice) < 0) {
                  grantOptionsData[i][x].AvailableToSellValue = grantOptionsData[i][x].AvailableToSell * this.exchangeRate * 0;
                } else {
                  grantOptionsData[i][x].AvailableToSellValue = grantOptionsData[i][x].AvailableToSell * (latestPrice - grantOptionsData[i][x].GrantPrice) * this.exchangeRate;
                }

                this.grantAlertDetails = [];
                this.grantAlertDetails.push(grantOptionsData[i][x]);
                this.hasActionItems = true;
              }
              else {
                //No Options Data
                this.hasActionItems = false;
              }
            }
          }
          else {
            //DO NOTHING
          }
        }
      }
      else {
        //RSU and Options does not have data
        this.hasActionItems = false;
      }

    }
    catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getGrantAlertData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  passDetails(details, checker, grantInd) {
    try {
      this.holdingsummaryservice.setProgramDetails(details);

      if (this.isPassDetailsCalled != 1) {
        if (grantInd == 1) {
          this.holdingsummaryservice.setAwardTab("agreement-tab");
         if (checker == 'Rsu') {
            if (details.HoldingsType == 4) {
              this.navToPage('iporsudetailspage')
            } else {
              this.navToPage('rsudetailspage');
            }
          } else if (checker == 'Options') {
            this.navToPage('optionsdetailspage');
          }
        }
      }
      this.isPassDetailsCalled = 1;
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "passDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  /**************************************************************************************************
        Method Name: getSameValuefromAdmin
        Description: Get same value of KeyExecToogle if you go to Admin and go back to Dashboard without any
                     changes and search

        Date            Modified by                 Description
        03/14/2019     Iris Jane R. Manloloyo     Initial Draft
  *****************************************************************************************************/

  getSameValuefromAdmin() {
    try {
      //For Admin Back Code
      let keyExecHoldingSummaryValue = JSON.parse(sessionStorage.getItem("Key Exec Holding Summary Value"));
      let keyExecRsuDetailsValue = JSON.parse(sessionStorage.getItem("Key Exec Rsu Details Value"));
      let keyExecComponentInd = JSON.parse(sessionStorage.getItem("Key Exec Component Indicator"));
      let keyExecAdmin = JSON.parse(sessionStorage.getItem("Key Exec Admin to Dashboard"));
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getSameValuefromAdmin");
      error.message += errorLocation;
      throw error;
    }
  }
  //Action Item Method END

  /**************************************************************************************************
    Method Name: getSharesAvailableToSell
    Description: Gets the total number of shares of Available to sell.

    Date            Modified by                 Description
    03/20/2019      Dalemark Suquib             Initial Draft
    03/26/2019      Dalemark Suquib             Added conditions for NaN
  *****************************************************************************************************/
  getSharesAvailableToSell() {
    try {
      if (isNaN(this.totalFounderAvailableToSell)) { this.totalFounderAvailableToSell = 0; };
      if (isNaN(this.totalRSUAvailableToSell)) { this.totalRSUAvailableToSell = 0; };
      if (isNaN(this.totalPurchaseAvailableToSell)) { this.totalPurchaseAvailableToSell = 0; };
      if (isNaN(this.totalOptionsAvailableToSell)) { this.totalOptionsAvailableToSell = 0; };

      this.totalSharesOfAvailableToSell = this.totalFounderAvailableToSell + this.totalRSUAvailableToSell + this.totalPurchaseAvailableToSell + this.totalOptionsAvailableToSell;
      this.getValueAvailableToSell();
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getSharesAvailableToSell");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /**************************************************************************************************
    Method Name: getValueAvailableToSell
    Description: Gets the total value of shares of Available to sell.

    Date            Modified by                 Description
    03/20/2019      Dalemark Suquib             Initial Draft
    03/26/2019      Dalemark Suquib             Added conditions for NaN
  *****************************************************************************************************/
  getValueAvailableToSell() {
    try {
      if (isNaN(this.founderAvailableToSellValue)) { this.founderAvailableToSellValue = 0; };
      if (isNaN(this.rsuAvailableToSellValue)) { this.rsuAvailableToSellValue = 0; };
      if (isNaN(this.valueAvailableToSell)) { this.valueAvailableToSell = 0; };
      if (isNaN(this.optionsAvailableToSellValue)) { this.optionsAvailableToSellValue = 0; };

      this.totalAvailableToSellValue = this.founderAvailableToSellValue + this.rsuAvailableToSellValue + this.valueAvailableToSell + this.optionsAvailableToSellValue;
      this.getSharesOutstandingShares();
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getValueAvailableToSell");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /**************************************************************************************************
    Method Name: getSharesOutstandingShares
    Description: Gets the total number of shares of Outstanding Shares.

    Date            Modified by                 Description
    03/20/2019      Dalemark Suquib             Initial Draft
    03/26/2019      Dalemark Suquib             Added conditions for NaN
    06/12/2023      Jewel C. Caudilla           Added to summation the totals shares of participant.
  *****************************************************************************************************/
  getSharesOutstandingShares() {
    try {
      if (isNaN(this.totalFounderSharesOutstanding)) { this.totalFounderSharesOutstanding = 0; };
      if (isNaN(this.totalRSUSharesOutStanding)) { this.totalRSUSharesOutStanding = 0; };
      if (isNaN(this.totalPurchaseSharesOutStanding)) { this.totalPurchaseSharesOutStanding = 0; };
      if (isNaN(this.totalOptionsSharesOutstanding)) { this.totalOptionsSharesOutstanding = 0; };

      this.totalSharesOfOutstandingShares = this.totalFounderSharesOutstanding + this.totalRSUSharesOutStanding + this.totalPurchaseSharesOutStanding + this.totalOptionsSharesOutstanding;
      this.getValueOutstandingShares();



    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getSharesOutstandingShares");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /**************************************************************************************************
    Method Name: getValueOutstandingShares
    Description: Gets the total value of shares of Outstanding Shares.

    Date            Modified by                 Description
    03/20/2019      Dalemark Suquib             Initial Draft
    03/25/2019      Joshua Avecilla (JPA)       Added call to setDoughnutGraph method
    03/26/2019      Dalemark Suquib             Added conditions for NaN
    03/25/2019      JPA                         Added interval in calling table
    0505/19         KMN                         Set to 1m - default
  *****************************************************************************************************/
  getValueOutstandingShares() {

    try {
      if (isNaN(this.founderSharesOutstandingValue)) { this.founderSharesOutstandingValue = 0; };
      if (isNaN(this.rsuOutstandingValue)) { this.rsuOutstandingValue = 0; };
      if (isNaN(this.valueSharesOustanding)) { this.valueSharesOustanding = 0; };
      if (isNaN(this.optionsOutstandingValue)) { this.optionsOutstandingValue = 0; };
      this.totalOutstandingSharesValue = this.founderSharesOutstandingValue + this.rsuOutstandingValue + this.valueSharesOustanding + this.optionsOutstandingValue;

      this.loading = false;
      if (this.currencyChanged == false) {
        setTimeout(() => { this.checkTotalValue() }, 1000);
      }

      if (this.totalSharesOfOutstandingShares > 0 || this.totalRSUSharesOutStanding > 0 || this.totalOptionsSharesOutstanding > 0 || this.totalPurchaseOutStandingSharesESPP > 0 || this.totalPurchaseOutStandingSharesVEIP > 0) {
        if (this.exchangeRate > 0 && this.overrideSharePrice > 0) {
          this.hasGraph = true;
          setTimeout(() => { this.setDoughnutGraph(true) }, 500);

        }
        else {
          this.hasGraph = false;
          this.hasGraph = true;
          setTimeout(() => { this.setDoughnutGraph(false) }, 500);
        }

      }
      else {
        this.hasGraph = false;
      }

      // this.marketQuoteGraph('1m');


      this.getSharePriceHistoryData('1y');
      this.getSharePriceHistoryDataMobile('1y');
      this.setBodyData();
      this.checkActionItemData();
      if (this.userprofileInfo)
        this.loadAgreement();
        

    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getValueOutstandingShares");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /*********************************************************************************************
  Method Name: setBodyData
  Description: Sets the body of data needed for myResources api.

  #     Date            Modified by                       Description
  1     04/08/19        Dalemark P. SUquib                Initial Draft
  2     05/01/19        KMN                               BUG 296055 fix - Added == Active
  3     05/03/19        Joshua Paul Avecilla(JPA)         BUG 297720 fix - Leave with pay should be passed as "Active"
  4     05/06/19        Dalemark P. Suquib                Updated condition for Enrolled & not Enrolled
  5     05/9/19         Jayson Sevilla                    Added condition for "nothing to show"
  6     05/20/19        Dalemark P. Suquib                Removed enrollment flag = 3 condition under Senior Exec
  7     11/5/19         Marie Antoinette M. Masayon(MAM)  Added condition for inactive with no shares
  **********************************************************************************************/
  async setBodyData() {
    try {
      this.bodyData = [];
      let veipFlagCounter: number = 0;
      let esppFlagCounter: number = 0;

      this.bodyData.push(this.user.CountryName);

      if (this.user.EmployeeStatus == "Active" || this.user.EmployeeStatus == "Leave with pay" || this.user.EmployeeStatus == "Leave of Absence" || this.user.EmployeeStatus == "Leave without credit") {
        this.bodyData.push("Active"); // this.bodyData.push(this.user.EmployeeStatus);
      } else {
        this.bodyData.push("Inactive");
      }
      //START:MAM
      if (this.userprofileInfo.IsAdmin == 'Y' || this.userprofileInfo.IsAdmin == 'y'){
        sessionStorage.setItem("Admin Indicator", "True");
      }
      if ((this.user.HasGrantInd == 'N') && (this.user.HasPurchaseInd == 'N') && (this.bodyData[1] == "Inactive") &&(sessionStorage.getItem('Admin Indicator')!='True')){
        this.hasShares = false;
        this.hasNoShareHistory = true;
        sessionStorage.setItem("No Share Indicator", "True");
      //END:MAM
      }
      if (this.user.JobCd == 10000025) { // Senior Executive
        this.bodyData.push("Senior Executive");

        if (this.veipOfferingDetails != null && this.user.EmployeeStatus != "Terminated") {
          for (let i = 0; i < this.veipOfferingDetails.length; i++) {
            if (this.veipOfferingDetails[i].OfferingFlag == 1) { // 1 ~ current
              if (this.veipOfferingDetails[i].EnrollmentFlag == 2) { // || this.veipOfferingDetails[i].EnrollmentFlag == 3
                this.bodyData.push("Enrolled in VEIP");
                veipFlagCounter += 1; // KDP
                break; // KDP
              } /* else {
                this.bodyData.push("Not enrolled in a program");
              } */
            }
          }

          if (veipFlagCounter < 1) { // In case offering flag 1 is not present
            this.bodyData.push("Not enrolled in a program");
          }
        } else {
          this.bodyData.push("Not enrolled in a program");
        }
      } else { // Employees
        this.bodyData.push("Employees");

        if (this.esppOfferingDetails != null && this.user.EmployeeStatus != "Terminated") {
          for (let i = 0; i < this.esppOfferingDetails.length; i++) {
            if (this.esppOfferingDetails[i].OfferingFlag == 1) { // 1 ~ current
              if (this.esppOfferingDetails[i].EnrollmentFlag == 2 || this.esppOfferingDetails[i].EnrollmentFlag == 3) {
                this.bodyData.push("Enrolled in ESPP")
              } else {
                this.bodyData.push("Not enrolled in a program");
              }

              esppFlagCounter += 1;
            }
          }

          if (esppFlagCounter < 1) { // In case offering flag 1 is not present
            this.bodyData.push("Not enrolled in a program");
          }
        } else {
          this.bodyData.push("Not enrolled in a program");
        }
      }

      this.bodyData.push(this.getGrantLongDesc());
      this.bodyData.push("Page Section");
      this.bodyData.push("Resources Page");
      sessionStorage.setItem("Body Data", JSON.stringify(this.bodyData));

      // START: SMD Key Exec PDF Link
      if(this.bodyData[4].indexOf('SMD Key Executive Performance RSU Award') > -1) {
        this.SMDChecker = true;
      }
      else{
        this.SMDChecker = false;
      }
      
      if (!this.topQuestionsExecuted) {
        this.getTopQuestions();
      }
      // this.keyExecFAQ();
      
       let rsuData: any = JSON.parse(sessionStorage.getItem("RSU Data"));

     if(rsuData != null){
      if(rsuData[1].length > 0 ){
        for(let i=0;i < rsuData[1].length; i++){
          if (rsuData[1][i].GrantLongDesc.indexOf('SMD') > -1 && rsuData[1][i].GrantLongDesc != null && rsuData[1][i].GrantLongDesc != undefined && rsuData[1][i].GrantLongDesc != "" && rsuData[1][i].Outstanding > 0) {  
            // if(rsuData[1][i].Outstanding > 0) {
              this.keyExecOutstandingChecker = true;
            //}
          }
          // checker if keyExecOutstadingChecker is already true
          else if (this.keyExecOutstandingChecker == true){
            this.keyExecOutstandingChecker = true
          }
          else {
            this.keyExecOutstandingChecker = false;
          }
        }
      }
      else {
        this.keyExecOutstandingChecker = false;
      }
     }
      // END: SMD Key Exec PDF Link
     
      
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "setBodyData");
      error.message += errorLocation;
      throw error;
    }
  }

  /*********************************************************************************************
  Method Name: getGrantLongDesc
  Description: Gets the long description of grants available.

  #     Date            Modified by             Description
  1     04/08/19        Dalemark P. SUquib      Initial Draft
  2     04/29/19        KMN                     Set RSU length == 2
  3     05/02/19        KMN                     Modify condition for no grants
  **********************************************************************************************/
  getGrantLongDesc(): string {
    try {
      let grantLongDesc: string = "";
      let foundersShareData, optionsData, rsuData;

      foundersShareData = JSON.parse(sessionStorage.getItem("Founder Share Data"));

      if (foundersShareData != null) {
        if (foundersShareData.length == 2) {
          foundersShareData = foundersShareData[1];

          for (let i = 0; i < foundersShareData.length; i++) {
            if (i < foundersShareData.length - 1) {
              grantLongDesc = grantLongDesc + foundersShareData[i].GrantLongDesc + ",";
            } else {
              grantLongDesc = grantLongDesc + foundersShareData[i].GrantLongDesc;
            }
          }
        }
      }

      optionsData = JSON.parse(sessionStorage.getItem("Options Data"));

      if (optionsData != null) {
        if (optionsData.length == 2) {
          optionsData = optionsData[1];

          if (grantLongDesc.length > 0) {
            grantLongDesc += ",";
          }

          for (let i = 0; i < optionsData.length; i++) {
            if (i < optionsData.length - 1) {
              grantLongDesc = grantLongDesc + optionsData[i].GrantLongDesc + ",";
            } else {
              grantLongDesc = grantLongDesc + optionsData[i].GrantLongDesc;
            }
          }
        }
      }

      rsuData = JSON.parse(sessionStorage.getItem("RSU Data"));

      if (rsuData != null) {
        if (rsuData.length == 2) {
          rsuData = rsuData[1];

          if (grantLongDesc.length > 0) {
            grantLongDesc += ",";
          }

          for (let i = 0; i < rsuData.length; i++) {
            if (i < rsuData.length - 1) {
              grantLongDesc = grantLongDesc + rsuData[i].GrantLongDesc + ",";
            } else {
              grantLongDesc = grantLongDesc + rsuData[i].GrantLongDesc;
            }
          }
        }
      }

      grantLongDesc == "null" ? grantLongDesc = "No Grants" : "";
      grantLongDesc == "" ? grantLongDesc = "No Grants" : "";

      return grantLongDesc;
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "getGrantLongDesc");
      error.message += errorLocation;
      throw error;
    }
  }

  /*********************************************************************************************
  Method Name: getTopQuestions
  Description: Gets top questions from myResources

  #     Date            Modified by             Description
  1     04/23/19        Katrina Narag           Initial Draft
  2     06/17/19        KMN                     PWD fix
  **********************************************************************************************/
  async getTopQuestions() {
    this.topQuestionsExecuted = true;
    this.errorTopQuestions = false;
    let bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));

    bodyData[5] = PageSection.topQuestions;
    bodyData[6] = 1;

    if (sessionStorage.getItem("homepage topquestions") != null) {
      try {
        this.errorTopQuestions = false;
        this.errorTopQuestionCard = false;
        let content = JSON.parse(sessionStorage.getItem("homepage topquestions"));
        if (content.data.length > 2) { // length > {}
          let topQuestionsContent = content.data;
          if (topQuestionsContent.length > 0) {
            this.topQuestions = topQuestionsContent;

            for (let i = 0; i < this.topQuestions.length; i++) {
              if (this.topQuestions[i]["Body_Desc"].indexOf("<u></u>") != -1) {
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace("<u></u>", "");
              }
              if (this.topQuestions[i]["Body_Desc"].indexOf('<p align="left">&#160;</p>') != -1) {
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<p align="left">&#160;</p>', '');
              }
              if (this.topQuestions[i]["Body_Desc"].indexOf('<font size="1">') != -1) {
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<font size="1">', '');
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('</font>', '');
              }
              if (this.topQuestions[i]["Body_Desc"].indexOf('<u>') != -1) {
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<u>', '');
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('</u>', '');
              }
            }
          } else {
            this.topQuestions = null;
            await this.staticContent.getResourcesDataFromMyR(bodyData).subscribe(data => {
              try {     
                this.errorTopQuestions = false;
                this.errorTopQuestionCard = false;
                let content = JSON.parse(data.body);
                if (content.data.length > 2) { // length > {}
                  let topQuestionsContent = content.data;
                  if (topQuestionsContent.length > 0) {
                    this.topQuestions = topQuestionsContent;

                    for (let i = 0; i < this.topQuestions.length; i++) {
                      if (this.topQuestions[i]["Body_Desc"].indexOf("<u></u>") != -1) {
                        this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace("<u></u>", "");
                      }
                      if (this.topQuestions[i]["Body_Desc"].indexOf('<p align="left">&#160;</p>') != -1) {
                        this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<p align="left">&#160;</p>', '');
                      }
                      if (this.topQuestions[i]["Body_Desc"].indexOf('<font size="1">') != -1) {
                        this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<font size="1">', '');
                        this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('</font>', '');
                      }
                      if (this.topQuestions[i]["Body_Desc"].indexOf('<u>') != -1) {
                        this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<u>', '');
                        this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('</u>', '');
                      }
                    }
                  } else {
                    this.topQuestions = null;
                  }
                }
                this.end = 5;
                this.start = 0;

               // this.checkErrorMessage();
                sessionStorage.setItem("homepage topquestions", JSON.stringify(content));
                setTimeout(() => { this.staticContent.inContentIconAppend() }, 3000);
              } catch (error) {
                this.errorTopQuestions = true;
                this.errorTopQuestionCard = true;
                let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
                error.message += errorLocation;
                throw error;
              }
            }, error => {
              this.errorTopQuestionCard = true;
              this.errorTopQuestions = true;
              let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
              error.error.message += errorLocation;
              throw error.error;
            });
          }
        }
        this.end = 5;
        this.start = 0;

        // this.checkErrorMessage();

        setTimeout(() => { this.staticContent.inContentIconAppend() }, 3000);

      } catch (error) {
        this.errorTopQuestions = true;
        this.errorTopQuestionCard = true;
        let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
        error.message += errorLocation;
        throw error;
      }
    }
    else {
      await this.staticContent.getResourcesDataFromMyR(bodyData).subscribe(data => {
        try {
          this.errorTopQuestions = false;
          this.errorTopQuestionCard = false;

          let content = JSON.parse(data.body);
          // let content = JSON.parse(data._body);
          if (content.data.length > 2) { // length > {}
            let topQuestionsContent = content.data;
            if (topQuestionsContent.length > 0) {
              this.topQuestions = topQuestionsContent;

              for (let i = 0; i < this.topQuestions.length; i++) {
                if (this.topQuestions[i]["Body_Desc"].indexOf("<u></u>") != -1) {
                  this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace("<u></u>", "");
                }
                if (this.topQuestions[i]["Body_Desc"].indexOf('<p align="left">&#160;</p>') != -1) {
                  this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<p align="left">&#160;</p>', '');
                }
                if (this.topQuestions[i]["Body_Desc"].indexOf('<font size="1">') != -1) {
                  this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<font size="1">', '');
                  this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('</font>', '');
                }
                if (this.topQuestions[i]["Body_Desc"].indexOf('<u>') != -1) {
                  this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<u>', '');
                  this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('</u>', '');
                }
              }
            } else {
              this.topQuestions = null;
            }
          }
          this.end = 5;
          this.start = 0;

          // this.checkErrorMessage();
          sessionStorage.setItem("homepage topquestions", JSON.stringify(content));
          setTimeout(() => { this.staticContent.inContentIconAppend() }, 3000);
        } catch (error) {
          this.errorTopQuestions = true;
          this.errorTopQuestionCard = true;
          let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTopQuestionCard = true;
        this.errorTopQuestions = true;
        let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
    }


  }

  /**********************************************************************************
       Method Name: getFilefromContent
       Description: Get filename from href/id then download file

       #     Date            Modified by       Description
       1     04/17/2019      Katrina Narag     Initial draft
       2     05/06/2019      Iris Manloloyo    Updated for onclick icon to be downloaded
       3     05/08/2019      KMN               BUG 297634 fix - Replace href with parent Node
   **********************************************************************************/
  validateFileType(eventBody) {
    try {
      this.staticContent.validateFileType(eventBody);
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "validateFileType");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
    * Method Name: onTabChange
    * Description: Sets timeout for incontenticon method when a tab is selected.
    *
    * #    Date                 Modified by                           Description
    * 1    04/26/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
  onTabChange() {
    try {
      setTimeout(() => { this.staticContent.inContentIconAppend() }, 500);
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "onTabChange");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
      * Method Name: checkActionItemData
      * Description: Checks the data under action item card if there is an error
      *
      * #    Date                 Modified by                           Description
      * 1    05/10/19             Dalemark P. Suquib                    Initial draft
    *****************************************************************************************/
  checkActionItemData() {
    if (this.errorEsppActionItem || this.errorVeipActionItem || this.errorGrantAlert) {
      this.errorActionItemCard = true;
    } else {
      this.errorActionItemCard = false;
    }

    this.checkGraphData();
  }

  /*****************************************************************************************
    * Method Name: checkGraphData
    * Description: Checks the data for the graph if there is an error
    *
    * #    Date                 Modified by                           Description
    * 1    05/10/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
  checkGraphData() {
    if (this.errorESPP || this.errorVEIP || this.errorRSU || this.errorFounder || this.errorOption) {
      this.errorGraph = true;
    } else {
      this.errorGraph = false;
    }
  }

  checkTotalValue() {
    this.currencyChanged = true;
    let osValue = $("#osValue");
    let availableValue = $("#availableValue");
    osValue.addClass("displayNone");
    availableValue.addClass("displayNone");
    setTimeout(() => {
      osValue.text(this.checkValue(osValue[0].innerText));
      osValue.removeClass("displayNone");
      availableValue.text(this.checkValue(availableValue[0].innerText));
      availableValue.removeClass("displayNone");

      osValue[0].innerText.length >= 15 ? osValue.addClass("changeOSfont") : osValue.removeClass("changeOSfont");
      availableValue[0].innerText.length > 15 ? availableValue.addClass("changeASfont") : availableValue.removeClass("changeASfont");

    }, .0001);
  }

  checkTotalValueMobile() {
    let osValue = $("#mobileOsValue");
    let availableValue = $("#mobileAvailableValue");
    osValue.addClass("displayNone");
    availableValue.addClass("displayNone");
    setTimeout(() => {
      osValue.text(this.checkValue(osValue[0].innerText));
      osValue.removeClass("displayNone");
      availableValue.text(this.checkValue(availableValue[0].innerText));
      availableValue.removeClass("displayNone");

      osValue[0].innerText.length >= 15 ? osValue.addClass("changeOSfont") : osValue.removeClass("changeOSfont");
      availableValue[0].innerText.length > 15 ? availableValue.addClass("changeASfont") : availableValue.removeClass("changeASfont");

    }, .0001);
  }

  checkValue(rawText) {
    let newVal: any;
    let preFix: any;
    let raw = rawText;
    let currency: string = this.getCurrency(raw);
    let amount = raw.slice(currency.length, raw.length);

    let value = Number(amount.replace(/,/g, ""));

    if (raw.includes("lion") != true) {
      if (value >= 1000000000 && value < 1000000000000) {
        preFix = Math.floor(value / 1000000000);
        newVal = currency + " " + preFix + " Billion";
      }
      else if (value >= 1000000000000 && value <= 999000000000000) {
        preFix = Math.floor(value / 1000000000000);
        newVal = currency + " " + preFix + " Trillion";
      }
      else if (value > 999000000000000) {
        newVal = currency + " 999 Trillion";
        newVal = newVal.replace(/E\+/g, "")
      }
      else {
        newVal = raw;
      }
    }

    return newVal;

  }

  getCurrency(raw) {
    let currency: string = "";
    for (let i = 0; i < raw.length; i++) {
      const element = raw[i];
      // if (isNaN(element) && !isNumber(element)) {
      //   currency += element;
      // }

    }
    currency = currency.replace(/[,.]/g, "");
    return currency;
  }

  setResourcesActiveTab(tab) {
    sessionStorage.setItem("resourcesActiveTab", tab);
    sessionStorage.setItem("activeResource", "Top Questions");
  }

  loadAgreement() {
    // let a = sessionStorage.getItem("configuredPP");

    // if(sessionStorage.getItem("configuredPP")=="1"){
   //   this.gdprAdminVerify = 1;
    // }else if(sessionStorage.getItem("configuredPP")=="0"){
    //   this.gdprAdminVerify = 0;
    // }



    // if(this.userInfoStore.GdprFlag == null || this.gdprAdminVerify == 0){

    //   if(this.gdprAdminVerify == 0) {

    //     setTimeout(function(){
    //       (<any>$("#ppModal")).modal({
    //         backdrop: 'static',
    //         keyboard: false
    //       });

    //   }, 2000);

    //   }




    // }else{
    //   sessionStorage.setItem("configuredPP","0");
    // }
    if (sessionStorage.getItem("isPPAccepted") != "yes") {
      if (this.userInfoStore.GdprFlag == null || this.userInfoStore.GdprFlag == 0) {
        if (sessionStorage.getItem(this.globals.AdminCred) == null) {

          (<any>$("#ppModal")).modal({
            backdrop: 'static',
            keyboard: false
          });

        }
      }
    }
  }

  // piwik - start
  // piwik() {
  //   let piwikChecker = JSON.parse(sessionStorage.getItem("Piwik Counter"));

  //   if(piwikChecker == null || piwikChecker == undefined) {
  //     sessionStorage.setItem("Piwik Counter", JSON.stringify('executed'));
  //     this._paq = this._paq || [];
  //     this._paq.push(['setCustomData', {'csusername': this.csusername}]);
  //     this._paq.push(['setCustomUrl', location.href.toLowerCase()]);
  //     this._paq.push(['trackPageView']);
  //     this._paq.push(['enableLinkTracking']);

  //     var u="//webmetrics.ciostage.accenture.com/";
  //     this._paq.push(['setTrackerUrl', u+'piwik.php']);
  //     this._paq.push(['setSiteId', 1015]);
  //     var d = document
  //           ,g = d.createElement('script')
  //           ,s=d.getElementsByTagName('script')[0];
  //     g.type ='text/javascript';
  //     g.async=true;
  //     g.defer=true;
  //     g.src=u+'piwik.js';
  //     s.parentNode.insertBefore(g,s);
  //   }
  // }
  // piwik - end

  async perfOnLoadView() {
    try {

      if (this.performanceGrantData.isSeniorManagingDirector == 1) {
        this.compensationValue = this.performanceGrantData.xValue;
        this.isExceed = true;
        this.exceedsValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
      }
      else {
        if (this.performanceGrantData.isOldView == 1) {
          this.compensationValue = this.performanceGrantData.xValue;
          this.exceedsValue = (this.dataPerformance.PerfGrantPerc / 100) * this.compensationValue * (this.sliderValue / 100);
          this.aboveValue = (this.exceedsValue / 2)
          this.isAbove = true;
        }
        else {
          this.compensationValue = this.performanceGrantData.CurrBasePay;
          this.aboveValue = this.performanceGrantData.PerfGrantValueAbove;
          this.exceedsValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
          this.isStandardDouble = true;
          
        }
      }


    } catch (error) {


      let errorLocation = this.errorLocation.replace("methodName", "perfOnLoadView");
      error.message += errorLocation;
      throw error;
    }
  }

  changeValue() {
    try {
      if (this.isExceed)
        this.exceedsValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
      else if (this.isAbove) {
        this.exceedsValue = (this.dataPerformance.PerfGrantPerc / 100) * this.compensationValue * (this.sliderValue / 100);
        this.aboveValue = (this.exceedsValue / 2);
      } else if (this.isStandardDouble) {
        this.aboveValue = (this.dataPerformance.PerfGrantPerc/100)*this.compensationValue*(this.sliderValue/100);
        this.exceedsValue = Math.round(this.aboveValue); 
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "changeValue");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
    * Method Name: hideGraphTooltip
    * Description: Hides when mouse leaves svg of market quote graph
    *
    * #    Date                 Modified by                           Description
    * 1    07/18/19             Joshua Avecilla                       Initial draft
  *****************************************************************************************/
  hideGraphTooltip() {

    $(".tooltipRect").attr("opacity", "0")
    $(".tooltipText").css("display", "none");
    $(".tooltipTextDate").css("display", "none");
  }

  /******************************************************************************************************************************************************************************************************
      Method Name: getSharePriceHistoryData
      Description: Pull share price History data for market quote graph.

      Date            Modified by                    Description
      07/03/2019      Joshua Paul Avecilla(JPA)      Initial draft
      07/18/2019      JPA                            added timeout for changing the period.
  ******************************************************************************************************************************************************************************************************/
  async getSharePriceHistoryDataMobile(checker) {
    if(this.userTransferee != undefined)
    {
      if (this.userTransferee.TransfereeTypeCd == 2)
      {
        this.errorMarketQuoteCard = true;
      }
    }
    this.errorMarketQuoteCard = false;

    let dateTo;
    let dateFrom;
    let cachedYear;

    let currentDateTo = new Date();

    currentDateTo.setDate(currentDateTo.getDate() - 1);
    let currentDate = new Date();
    let dateToEvent = this.datePipe.transform(currentDateTo, "MM'/'dd'/'yyyy");
    dateTo = moment(dateToEvent).format("DD MMM YYYY");

    if (checker == '5d') {
      currentDate.setDate(currentDate.getDate() - 7);

      let dateModify = currentDate;
      let dateFromEvent = this.datePipe.transform(dateModify, "MM'/'dd'/'yyyy");
      dateFrom = moment(dateFromEvent).format("DD MMM YYYY");
      cachedYear = 'MarketQuoteGraphMobile 5d';
    }
    else if (checker == '1m') {
      currentDate.setMonth(currentDate.getMonth() - 1);
      let dateModify = currentDate;
      let dateFromEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      dateFrom = moment(dateFromEvent).format("DD MMM YYYY");
      cachedYear = 'MarketQuoteGraphMobile 1m';
    }
    else if (checker == '1y') {
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      let dateModify = currentDate;
      let dateFromEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      dateFrom = moment(dateFromEvent).format("DD MMM YYYY");
      cachedYear = 'MarketQuoteGraphMobile 1y';
    }
    else if (checker == '5y') {
      currentDate.setFullYear(currentDate.getFullYear() - 5);
      let dateModify = currentDate;
      let dateFromEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      dateFrom = moment(dateFromEvent).format("DD MMM YYYY");
      cachedYear = 'MarketQuoteGraphMobile 5y';
    }

    if (sessionStorage.getItem(cachedYear) != null) {
      this.marketQuoteLoad = false;
      let tempListData = JSON.parse(sessionStorage.getItem(cachedYear));

      try {
        tempListData.sort(function (a, b) {
          let dateA, dateB;
          dateA = new Date(a.Date);
          dateB = new Date(b.Date);
          return dateA - dateB;
        });

        for (let i = 0; i < tempListData.length; i++) {
          tempListData[i]["Trading Volume"] = this.commafy(tempListData["Trading Volume"]);
          tempListData[i].Date = new Date(tempListData[i].Date);
        }
        this.loading = false;
        setTimeout(() => {

          $('#marketQuoteIDMobile > g').empty();

          const svgWidth = 320;
          const svgHeight = 120;
          const bisectDate = d3.bisector(d => d.Date).left;
          var margin = { top: 20, right: 10, bottom: 20, left: 10 };
          var width = svgWidth - margin.left - margin.right;
          var height = svgHeight - margin.top - margin.bottom;
          var svg = d3.select('#marketQuoteIDMobile').attr('width', svgWidth).attr('height', svgHeight).append('g')
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

          // Set Chart Background by overriding default canvas
          svg.append('rect')
            .attr('width', '350')
            .attr('height', '90') //165.1
            .attr('fill', 'white')
            // .on('mouseout', function() {
            // focus.transition()
            // .delay(100)
            // .style("opacity",0)
            // .style('pointer-events', 'none');
            // })
            .on('mouseover', function () { focus.style('display', null) }) //.style("opacity",100);
            .on('mousemove', function () {
              const x0 = x.invert(d3.mouse(this)[0]); //x.invert(d3.mouse(this)[0]);
              const i = bisectDate(tempListData, x0, 1);
              const d0 = tempListData[i - 1];
              const d1 = tempListData[i];

              if (d0 != undefined && d1 != undefined) {
                const d = x0 - d0.Date > d1.Date - x0 ? d1 : d0;

                focus.attr('transform', `translate(${x(d.Date)}, ${y(d["Close (USD)"])})`);
                focus.select('line.x')
                  .attr('x1', 0)
                  .attr('x2', -x(d.Date))
                  .attr('y1', 0)
                  .attr('y2', 0);

                focus.select('line.y')
                  .attr('x1', 0)
                  .attr('x2', 0)
                  .attr('y1', 0)
                  .attr('y2', svgWidth - y(d["Close (USD)"]));

                if (y(d["Close (USD)"]) >= 49 && x(d.Date) >= 39 && x(d.Date) <= 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '-50')
                    .attr('y', '-50');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '-30')
                    .attr('dy', '-35')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '-40')
                    .attr('dy', '-19')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                } else if (y(d["Close (USD)"]) < 49 && x(d.Date) >= 52 && x(d.Date) <= 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '-50')
                    .attr('y', '7');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '-30')
                    .attr('dy', '25')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '-40')
                    .attr('dy', '39')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                } else if (y(d["Close (USD)"]) >= 49 && x(d.Date) <= 52 && x(d.Date) <= 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '10')
                    .attr('y', '-37');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '35')
                    .attr('dy', '-21')
                    .attr("style", "font-size: 14px;font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '20')
                    .attr('dy', '-7')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                } else if (y(d["Close (USD)"]) < 49 && x(d.Date) <= 40 && x(d.Date) <= 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '10')
                    .attr('y', '0');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '30')
                    .attr('dy', '15')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '20')
                    .attr('dy', '29')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                } else if (y(d["Close (USD)"]) >= 49 && x(d.Date) > 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '-107')
                    .attr('y', '-21');
                  focus.select('text.price')
                    .text('$' + d["Close (USD)"])
                    .attr('x', '-82')
                    .attr('dy', '-5')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '-95')
                    .attr('dy', '9')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                } else if (y(d["Close (USD)"]) < 49 && x(d.Date) > 266) {
                  focus.select('rect')
                    .attr("opacity", "1")
                    .attr('x', '-100')
                    .attr('y', '5');
                  focus.select('text.price').text('$' + d["Close (USD)"])
                    .attr('x', '-80')
                    .attr('dy', '20')
                    .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                  focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                    .attr('x', '-90')
                    .attr('dy', '34')
                    .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                }

                d3.select('circle').attr('opacity', '1')
              }
            })

          var g = svg.append('g').attr('transform', 'translate(' + 3 + ',' + 0.5 + ')');
          var x = d3.scaleTime().rangeRound([0, width]);
          var y = d3.scaleLinear().rangeRound([height, 0]);

          var line = d3.line().x(function (d) {
            return x(d.Date);
          })
            .y(function (d) {
              return y(d["Close (USD)"]);
            });

          x.domain([tempListData[0].Date, tempListData[tempListData.length - 1].Date]);
          y.domain(d3.extent(tempListData, function (d) { return d["Close (USD)"]; }));

          d3.selectAll('.tick')
            .attr('opacity', '0');

          const focus = svg.append('g')
            .attr('class', 'focus')
            .style('display', 'none');

          focus.append('circle')
            .attr('r', 5)
            // .attr('cx', '10')
            .attr('stroke', 'white')
            .attr('stroke-width', '2')
            .attr('fill', '#9013fe');

          focus.append('line')
            .classed('x', true);

          focus.append('line')
            .classed('y', true);

          focus.append('rect')
            .attr('class', 'tooltipRect')
            .attr('fill', '#d8d8d8')
            .attr("width", "100")
            .attr("height", "40")
            .attr("opacity", "0")
            .attr("rx", 4)
          // .attr("style","stroke-width:1; stroke:#9013fe");

          focus.append('text')
            .attr('class', 'tooltipText')
            .classed('price', true)
            .attr('x', 9)
            .attr('dy', '.35em');

          focus.append('text')
            .attr('class', 'tooltipTextDate')
            .classed('date', true);

          g.append('path')
            .datum(tempListData)
            .attr('fill', 'none')
            .attr('stroke', '#9013fe')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 3)
            .attr('d', line)
            .enter().append("text")
            .attr("class", "path")
            .attr("text-anchor", "middle");
        }, 500);
      } catch (error) {
        let errorLocation = this.errorLocation.replace("methodName", "getSharePriceHistoryData");
        error.message = error.message + errorLocation;
        throw error;
      }
    }
    else {
      await this.sharePriceService.getSharePriceHistory(dateFrom, dateTo, -99, 'N').subscribe(data => {
        this.loading = false;
        this.marketQuoteLoad = false;
        let tempListData = data[0].slice(0, data[0].length);
        sessionStorage.setItem(cachedYear, JSON.stringify(tempListData));

        try {
          tempListData.sort(function (a, b) {
            let dateA, dateB;
            dateA = new Date(a.Date);
            dateB = new Date(b.Date);
            return dateA - dateB;
          });

          for (let i = 0; i < tempListData.length; i++) {
            tempListData[i]["Trading Volume"] = this.commafy(tempListData["Trading Volume"]);
            tempListData[i].Date = new Date(tempListData[i].Date);
          }

          setTimeout(() => {
            $('#marketQuoteIDMobile > g').empty();

            const svgWidth = 320;
            const svgHeight = 120;
            const bisectDate = d3.bisector(d => d.Date).left;
            var margin = { top: 20, right: 10, bottom: 20, left: 10 };
            var width = svgWidth - margin.left - margin.right;
            var height = svgHeight - margin.top - margin.bottom;
            var svg = d3.select('#marketQuoteIDMobile').attr('width', svgWidth).attr('height', svgHeight).append('g')
              .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            // Set Chart Background by overriding default canvas
            svg.append('rect')
              .attr('width', '350')
              .attr('height', '90') //165.1
              .attr('fill', 'white')
              // .on('mouseout', function() {
              // focus.transition()
              // .delay(100)
              // .style("opacity",0)
              // .style('pointer-events', 'none');
              // })
              .on('mouseover', function () { focus.style('display', null) }) //.style("opacity",100);
              .on('mousemove', function () {
                const x0 = x.invert(d3.mouse(this)[0]); //x.invert(d3.mouse(this)[0]);
                const i = bisectDate(tempListData, x0, 1);
                const d0 = tempListData[i - 1];
                const d1 = tempListData[i];

                if (d0 != undefined && d1 != undefined) {
                  const d = x0 - d0.Date > d1.Date - x0 ? d1 : d0;

                  focus.attr('transform', `translate(${x(d.Date)}, ${y(d["Close (USD)"])})`);
                  focus.select('line.x')
                    .attr('x1', 0)
                    .attr('x2', -x(d.Date))
                    .attr('y1', 0)
                    .attr('y2', 0);

                  focus.select('line.y')
                    .attr('x1', 0)
                    .attr('x2', 0)
                    .attr('y1', 0)
                    .attr('y2', svgWidth - y(d["Close (USD)"]));

                  if (y(d["Close (USD)"]) >= 49 && x(d.Date) >= 39 && x(d.Date) <= 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '-50')
                      .attr('y', '-50');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '-30')
                      .attr('dy', '-35')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '-40')
                      .attr('dy', '-19')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) < 49 && x(d.Date) >= 52 && x(d.Date) <= 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '-50')
                      .attr('y', '7');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '-30')
                      .attr('dy', '25')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '-40')
                      .attr('dy', '39')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) >= 49 && x(d.Date) <= 52 && x(d.Date) <= 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '10')
                      .attr('y', '-37');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '35')
                      .attr('dy', '-21')
                      .attr("style", "font-size: 14px;font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '20')
                      .attr('dy', '-7')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) < 49 && x(d.Date) <= 40 && x(d.Date) <= 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '10')
                      .attr('y', '0');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '30')
                      .attr('dy', '15')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '20')
                      .attr('dy', '29')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) >= 49 && x(d.Date) > 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '-107')
                      .attr('y', '-21');
                    focus.select('text.price')
                      .text('$' + d["Close (USD)"])
                      .attr('x', '-82')
                      .attr('dy', '-5')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '-95')
                      .attr('dy', '9')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  } else if (y(d["Close (USD)"]) < 49 && x(d.Date) > 266) {
                    focus.select('rect')
                      .attr("opacity", "1")
                      .attr('x', '-100')
                      .attr('y', '5');
                    focus.select('text.price').text('$' + d["Close (USD)"])
                      .attr('x', '-80')
                      .attr('dy', '20')
                      .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
                    focus.select('text.date').text(moment(d.Date).format("DD MMM YYYY"))
                      .attr('x', '-90')
                      .attr('dy', '34')
                      .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
                  }

                  d3.select('circle').attr('opacity', '1')
                }
              })

            var g = svg.append('g').attr('transform', 'translate(' + 3 + ',' + 0.5 + ')');
            var x = d3.scaleTime().rangeRound([0, width]);
            var y = d3.scaleLinear().rangeRound([height, 0]);

            var line = d3.line().x(function (d) {
              return x(d.Date);
            })
              .y(function (d) {
                return y(d["Close (USD)"]);
              });

            x.domain([tempListData[0].Date, tempListData[tempListData.length - 1].Date]);
            y.domain(d3.extent(tempListData, function (d) { return d["Close (USD)"]; }));

            d3.selectAll('.tick')
              .attr('opacity', '0');

            const focus = svg.append('g')
              .attr('class', 'focus')
              .style('display', 'none');

            focus.append('circle')
              .attr('r', 5)
              // .attr('cx', '10')
              .attr('stroke', 'white')
              .attr('stroke-width', '2')
              .attr('fill', '#9013fe');

            focus.append('line')
              .classed('x', true);

            focus.append('line')
              .classed('y', true);

            focus.append('rect')
              .attr('class', 'tooltipRect')
              .attr('fill', '#d8d8d8')
              .attr("width", "100")
              .attr("height", "40")
              .attr("opacity", "0")
              .attr("rx", 4)
            // .attr("style","stroke-width:1; stroke:#9013fe");

            focus.append('text')
              .attr('class', 'tooltipText')
              .classed('price', true)
              .attr('x', 9)
              .attr('dy', '.35em');

            focus.append('text')
              .attr('class', 'tooltipTextDate')
              .classed('date', true);

            g.append('path')
              .datum(tempListData)
              .attr('fill', 'none')
              .attr('stroke', '#9013fe')
              .attr('stroke-linejoin', 'round')
              .attr('stroke-linecap', 'round')
              .attr('stroke-width', 3)
              .attr('d', line)
              .enter().append("text")
              .attr("class", "path")
              .attr("text-anchor", "middle");
          }, 500);
        } catch (error) {
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getSharePriceHistoryData");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getSharePriceHistoryData");
        error.error.message += errorLocation;
        throw error.error;
      });
    }

    //}

  }


  /******************************************************************************************************************************************************************************************************
      Method Name: marketQuoteGraph
      Description: Builds market quote graph

      Date            Modified by                    Description
      02/06/2019      Joshua Paul Avecilla(JPA)      Initial draft
      03/11/19        Jayson Sevilla(JSS)            Market Quote Graph Tooltip
      04/02/19        JPA                            Change display to label instead of date due to BUG 240834 - Market quote graph tool tip details for previous share price and date is not accurate
      06/20/19        JPA                            Adjustment of graph design, tooltip and graph points.
  ******************************************************************************************************************************************************************************************************/
  // async marketQuoteGraphMobile(checker) {

  //   // let testData = []
  //   // testData.push({"date": "May 29 2019", "label":"May 29", "close": 178.54});
  //   // testData.push({"date": "May 30 2019", "label":"May 30", "close": 179.57});
  //   // testData.push({"date": "May 31 2019", "label":"May 31", "close": 180.39});
  //   // testData.push({"date": "Jun 01 2019", "label":"Jun 01", "close": 178.27});
  //   // testData.push({"date": "Jun 02 2019", "label":"Jun 02", "close": 178.81});
  //   // testData.push({"date": "Jun 03 2019", "label":"Jun 03", "close": 178.39});
  //   // testData.push({"date": "Jun 04 2019", "label":"Jun 04", "close": 177.61});
  //   // testData.push({"date": "Jun 05 2019", "label":"Jun 05", "close": 178.82});
  //   // testData.push({"date": "Jun 06 2019", "label":"Jun 06", "close": 178.07});
  //   // testData.push({"date": "Jun 07 2019", "label":"Jun 07", "close": 175.16});
  //   // testData.push({"date": "Jun 08 2019", "label":"Jun 08", "close": 177.97});
  //   // testData.push({"date": "Jun 09 2019", "label":"Jun 09", "close": 179.56});
  //   // testData.push({"date": "Jun 10 2019", "label":"Jun 10", "close": 180.4});
  //   // testData.push({"date": "Jun 11 2019", "label":"Jun 11", "close": 182.92});
  //   // testData.push({"date": "Jun 12 2019", "label":"Jun 12", "close": 184.44});
  //   // testData.push({"date": "Jun 13 2019", "label":"Jun 13", "close": 183.37});
  //   // testData.push({"date": "Jun 14 2019", "label":"Jun 14", "close": 184.59});
  //   // testData.push({"date": "Jun 15 2019", "label":"Jun 15", "close": 184.95});
  //   // testData.push({"date": "Jun 16 2019", "label":"Jun 16", "close": 184.97});
  //   // testData.push({"date": "Jun 17 2019", "label":"Jun 17", "close": 183.9});
  //   // testData.push({"date": "Jun 18 2019", "label":"Jun 18", "close": 184.5});
  //   // testData.push({"date": "Jun 19 2019", "label":"Jun 19", "close": 183.95});

  //   this.marketQuoteService.getMarketQuoteHistorical(checker).map(res => res).subscribe(data => {
  //     try {

  //       this.marketQuoteHistoricalData = data["chart"];
  //       // this.marketQuoteHistoricalData = testData;

  //       var arr = this.marketQuoteHistoricalData;
  //       for (let i = 0; i < arr.length; i++) {
  //         arr[i].date = new Date(arr[i].date);
  //       }


  //       const svgWidth = 320;
  //       const svgHeight = 90;
  //       const bisectDate = d3.bisector(d => d.date).left;
  //       var margin = { top: 20, right: 20, bottom: 20, left: 20 };
  //       var width = svgWidth - margin.left - margin.right;
  //       var height = svgHeight - margin.top - margin.bottom;
  //       var svg = d3.select('svg').attr('width', svgWidth).attr('height', svgHeight);

  //       // Set Chart Background by overriding default canvas
  //       svg.append('rect')
  //         .attr('width', '350')
  //         .attr('height', '90') //165.1
  //         .attr('fill', 'white')
  //         .on('mouseover', function () { focus.style('display', null); })
  //         .on('mousemove', function () {
  //           const x0 = x.invert(d3.mouse(this)[0]);
  //           const i = bisectDate(arr, x0, 1);
  //           const d0 = arr[i - 1];
  //           const d1 = arr[i];

  //           if (d0 != undefined && d1 != undefined) {
  //             const d = x0 - d0.date > d1.date - x0 ? d1 : d0;

  //             focus.attr('transform', `translate(${x(d.date)}, ${y(d.close)})`);
  //             focus.select('line.x')
  //               .attr('x1', 0)
  //               .attr('x2', -x(d.date))
  //               .attr('y1', 0)
  //               .attr('y2', 0);

  //             focus.select('line.y')
  //               .attr('x1', 0)
  //               .attr('x2', 0)
  //               .attr('y1', 0)
  //               .attr('y2', svgWidth - y(d.close));

  //             if (y(d.close) >= 49 && x(d.date) >= 39 && x(d.date) <= 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-50')
  //                   .attr('y', '-45');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-30')
  //                   .attr('dy', '-29')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '-40')
  //                   .attr('dy', '-14')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-50')
  //                   .attr('y', '7');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-30')
  //                   .attr('dy', '25')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '-40')
  //                   .attr('dy', '39')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) < 49 && x(d.date) >= 41 && x(d.date) <= 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-50')
  //                   .attr('y', '7');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-30')
  //                   .attr('dy', '25')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '-40')
  //                   .attr('dy', '39')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-50')
  //                   .attr('y', '7');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-30')
  //                   .attr('dy', '25')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '-40')
  //                   .attr('dy', '39')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) >= 49 && x(d.date) <= 41 && x(d.date) <= 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '10')
  //                   .attr('y', '-37');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '35')
  //                   .attr('dy', '-21')
  //                   .attr("style", "font-size: 14px;font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '20')
  //                   .attr('dy', '-7')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '10')
  //                   .attr('y', '-37');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '35')
  //                   .attr('dy', '-21')
  //                   .attr("style", "font-size: 14px;font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '20')
  //                   .attr('dy', '-7')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) < 49 && x(d.date) <= 40 && x(d.date) <= 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '10')
  //                   .attr('y', '0');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '15')
  //                   .attr('dy', '15')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '15')
  //                   .attr('dy', '29')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '10')
  //                   .attr('y', '0');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '15')
  //                   .attr('dy', '15')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '15')
  //                   .attr('dy', '29')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) >= 49 && x(d.date) > 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-107')
  //                   .attr('y', '-21');
  //                 focus.select('text.price')
  //                   .text('$' + d.close)
  //                   .attr('x', '-82')
  //                   .attr('dy', '-5')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '-95')
  //                   .attr('dy', '9')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-107')
  //                   .attr('y', '-21');
  //                 focus.select('text.price')
  //                   .text('$' + d.close)
  //                   .attr('x', '-82')
  //                   .attr('dy', '-5')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '-95')
  //                   .attr('dy', '9')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             } else if (y(d.close) < 49 && x(d.date) > 266) {
  //               if ((d.label.split(" ").length - 1) < 2) {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-100')
  //                   .attr('y', '5');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-80')
  //                   .attr('dy', '20')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label + " " + moment().year()).format("DD MMM YYYY"))
  //                   .attr('x', '-90')
  //                   .attr('dy', '34')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //               else {
  //                 focus.select('rect')
  //                   .attr("opacity", "1")
  //                   .attr('x', '-100')
  //                   .attr('y', '5');
  //                 focus.select('text.price').text('$' + d.close)
  //                   .attr('x', '-80')
  //                   .attr('dy', '20')
  //                   .attr("style", "font-size: 14px; font-weight: 500; line-height: normal; color: black");
  //                 focus.select('text.date').text(moment(d.label).format("DD MMM YYYY"))
  //                   .attr('x', '-90')
  //                   .attr('dy', '34')
  //                   .attr("style", "font-family: Graphik; font-size: 14px; font-weight: normal; font-style: normal;font-stretch: normal; line-height: normal; letter-spacing: normal; text-align: center; color: var(--black);");
  //               }
  //             }

  //             d3.select('circle').attr('opacity', '1')
  //           }
  //         })

  //       var g = svg.append('g').attr('transform', 'translate(' + 3 + ',' + 0.5 + ')');
  //       var x = d3.scaleTime().rangeRound([0, width]);
  //       var y = d3.scaleLinear().rangeRound([height, 0]);

  //       var line = d3.line().x(function (d) {
  //         return x(d.date);
  //       })
  //         .y(function (d) {
  //           return y(d.close);
  //         });

  //       x.domain([arr[0].date, arr[arr.length - 1].date]);
  //       y.domain(d3.extent(arr, function (d) { return d.close; }));

  //       d3.selectAll('.tick')
  //         .attr('opacity', '0');

  //       const focus = svg.append('g')
  //         .attr('class', 'focus')
  //         .style('display', 'none');

  //       focus.append('circle')
  //         .attr('r', 5)
  //         // .attr('cx', '10')
  //         .attr('stroke', 'white')
  //         .attr('stroke-width', '2')
  //         .attr('fill', '#9013fe');

  //       focus.append('line')
  //         .classed('x', true);

  //       focus.append('line')
  //         .classed('y', true);

  //       focus.append('rect')
  //         .attr('fill', '#d8d8d8')
  //         .attr("width", "100")
  //         .attr("height", "40")
  //         .attr("opacity", "0")
  //         .attr("rx", 4)
  //       // .attr("style","stroke-width:1; stroke:#9013fe");

  //       focus.append('text')
  //         .classed('price', true)
  //         .attr('x', 9)
  //         .attr('dy', '.35em');

  //       focus.append('text')
  //         .classed('date', true);

  //       g.append('path')
  //         .datum(arr)
  //         .attr('fill', 'none')
  //         .attr('stroke', '#9013fe')
  //         .attr('stroke-linejoin', 'round')
  //         .attr('stroke-linecap', 'round')
  //         .attr('stroke-width', 3)
  //         .attr('d', line)
  //         .enter().append("text")
  //         .attr("class", "path")
  //         .attr("text-anchor", "middle");




  //     } catch (error) {
  //       let errorLocation = this.errorLocation.replace("methodName", "marketQuoteGraph");
  //       error.message = error.message + errorLocation;
  //       throw error;
  //     }
  //   }, error => {
  //     let errorLocation = this.errorLocation.replace("methodName", "marketQuoteGraph");
  //     error.error.message += errorLocation;
  //     throw error.error;
  //   });


  // }

  selectMarketQuotePeriodMobile(checker) {
    try {
      this.marketQuotePeriodSelected = checker;
      this.marketQuoteLoad = true;
      if (checker == '5 days') {
        this.getSharePriceHistoryDataMobile('5d');
        // this.marketQuoteGraph('5d');
      }
      else if (checker == '1 month') {
        this.getSharePriceHistoryDataMobile('1m');
        // this.marketQuoteGraph('1m');
      }
      else if (checker == '1 year') {
        this.getSharePriceHistoryDataMobile('1y');
        // this.marketQuoteGraph('1y');
      }
      else if (checker == '5 years') {
        this.getSharePriceHistoryDataMobile('5y');
        // this.marketQuoteGraph('5y');
      }
    } catch (error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectMarketQuotePeriod");
      error.message += errorLocation;
      throw error;
    }
  }

  ifUserIsTransferee(){
    if(sessionStorage.getItem("Local User Info Storage") != null){
  
      this.userTransferee =JSON.parse(sessionStorage.getItem("Local User Info Storage"));
      let transfer = JSON.stringify(this.userTransferee.TransfereeTypeCd);
      if(transfer != 'null'){
        this.transfereeTypeCdInd = false;
      }
      else { this.transfereeTypeCdInd = true; } //Action Item Method START
  
      
    }

    this.brokersSSO();
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
    * 2    02/17/2020           KMN                                   Added ESO URLs in Octopus deploy
  *****************************************************************************************/
  brokersSSO(){
    if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
      let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

      if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }else{
      if(this.user.EmployeeStatus == "Active" || this.user.EmployeeStatus == "Leave with pay" || this.user.EmployeeStatus == "Leave of Absence" || this.user.EmployeeStatus == "Leave without credit") {
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
         this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }
    
  }

  isNewlyPromotedMD(){
    if(this.userInfoStore != null){
    if((this.userInfoStore.EsppEligibleInd == 'Y' || this.userInfoStore.EsppEligibleInd == 'N')  && this.userInfoStore.VeipCurrentOfferingEligibleInd  == 'N' && this.userInfoStore.VeipNextOfferingEligibleInd  == 'Y'){
      this.esppEligible = false;
      this.veipEligible = true;
    }
   }
  }

  GoToRewards(){
    if(this.globals.stageName == "prod"){
        window.open("https://rewards.accenture.com", "_blank");
      }
      else{
        window.open("https://rewards.accenture.com", "_blank");
      }
  }

  /*****************************************************************************************
    * Method Name: keyExecFAQ
    * Description: Gets myResources contents for Key Exec Award FAQ File
    *
    * #    Date                 Modified by                           Description
    * 1    05/29/2020           Denisse Rachel Vetuz(DMV)             Initial draft
    * 2    06/11/2020           Allan Christopher Salac(ACS)          Additional condition for pdf link
*****************************************************************************************/
  // async keyExecFAQ(){
  //   try {
  //     let bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  //     let rsuData: any = JSON.parse(sessionStorage.getItem("RSU Data"));
  //     this.bodyData[5] = PageSection.keyExecFAQ;
  //     this.bodyData[6] = 1;

  //     this.staticContent.getResourcesDataFromMyR(this.bodyData).map(res => res).subscribe(data => { 
  //       try {
  //         let content = JSON.parse(data._body);    
          
  //         if(content.name == "TokenExpiredError"){
  //           this.router.navigate(['homepage']);
  //         }
  
  //         if(content.body.length > 2){
  //           let contentBody = JSON.parse(content.body);            
    
  //           if(contentBody.data.length > 0){
  //             this.keyExecFAQContent = contentBody.data[0].Body_Desc;
  //           }
  //           else{
  //             this.keyExecFAQContent = '';
  //           }

  //           if(this.bodyData[4].indexOf('SMD Key Executive Performance RSU Award') > -1) {
  //             this.SMDChecker = true;
  //           }
  //           else{
  //             this.SMDChecker = false;
  //           }

  //           for(let i=0;i <= rsuData[1].length; i++){
  //             if(rsuData[1][i].GrantLongDesc.indexOf('SMD') > -1){
  //               if(rsuData[1][i].Outstanding > 0) {
  //                 this.keyExecOutstandingChecker = true;
  //               }
  //             }
  //           }
  //         }
  //       } catch(error) {
  //         let errorLocation = this.errorLocation.replace("methodName", "keyExecFAQ");
  //         error.message += errorLocation;
  //         throw error;
  //       }
  //     }, error => {
  //       let errorLocation = this.errorLocation.replace("methodName", "keyExecFAQ");
  //       error.error.message += errorLocation;
  //       throw error.error;
  //     });
      
      
  //   }catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "keyExecFAQ");
  //     error.message += errorLocation;
  //     throw error;
  //   }

  // }
  async getTaxElection(){
    try {
      await this.espptransactionService.getESPPActionItem(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          let esppTaxElect = data;
          this.esppTaxElectDetails = esppTaxElect[0];
          for (let i = 0; i < esppTaxElect[0].length; i++) {
            if (esppTaxElect[0][i].OfferingFlag == 2 && esppTaxElect[0][i].EnrollmentFlag == 1) { //TAX ELECT
              if (esppTaxElect[0][i].OfferingFlag == 2 && esppTaxElect[0][i].EnrollmentFlag != 0) { 
                this.taxElectHeader = esppTaxElect[0][i].Header;
                this.taxElectSubMesssage = esppTaxElect[0][i].SubMessage;
                this.taxElectOffernum = esppTaxElect[0][i].OfferNum;
              }
            }
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getTaxElection");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getTaxElection");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getTaxElection");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
    * Method Name: getTotalPSUsharesbyOptNum
    * Description: get the total PSU Shares
    *
    * #    Date                 Modified by                           Description
    * 1    06/12/2023           Jewel C. Caudilla                     Initial draft
    * 2    12/12/2023           JCC                                   Bug Fixed for OptNum if Null 
  *****************************************************************************************/
  async getTotalPSUsharesbyOptNum(optnum) {
    // console.log("getTotalPSUsharesbyOptNum: Start getTotalPSUsharesbyOptNum");
    let body: any = 0;
      // console.log("getTotalPSUsharesbyOptNum: Call API");
      await this.holdingsummaryservice.getPSUDetailsbyOptNum(optnum).subscribe(data => {
        try {
          // console.log("getTotalPSUsharesbyOptNum: Call API " + data);
          if (Array.isArray(data) && data.length > 0) {
          body = data[0].sort((a,b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);
          sessionStorage.setItem("PSU Details by OptNum", JSON.stringify(body));
  
          this.keyExecDisplayInd(body);
          } else {
            this.psuKeyExecInd = false;
            this.keyExecVest = "Target";
          }
          // console.log("getTotalPSUsharesbyOptNum: Success API");
          
        } 
        catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getTotalPSUsharesbyOptNum");
          error.message += errorLocation;
          throw error;
        }
      })
  }

  /*****************************************************************************************
    * Method Name: keyExecDisplayInd
    * Description: keyExecDisplayInd will decide if keyExecToggle will display.
    *
    * #    Date                 Modified by                           Description
    * 1    06/12/2023           Jewel C. Caudilla                     Initial draft
  *****************************************************************************************/
  keyExecDisplayInd(body) {
    let keyIndex = body.findIndex(function(body){
      return body["DisplayInd"] === "Y";
    })

    if(keyIndex >= 0){
      this.psuKeyExecInd = true;
    } else {
      this.psuKeyExecInd = false;
      this.keyExecVest = "Target";
    }
  }
} 
