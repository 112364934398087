import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';


@Injectable()
export class MatPaginatorIntlChangeLog extends MatPaginatorIntl {
    nextPageLabel     = 'Next';
    previousPageLabel = 'Previous';
    firstPageLabel = 'First';
    lastPageLabel = 'Last';

    getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 of ' + length;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return "Showing ".concat(startIndex + 1 + ' to ' + endIndex + ' of ' + length + ' entries');
  };

}