
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { HoldingProgramDetailsOptionsService } from '../HoldingProgramDetailsOptionsService';
import { Globals } from '../../globals';
import {TooltipPosition} from '@angular/material/tooltip';
import { tScript, AdminType, GrantAgreementStatus } from '../../services/enumerations/enumerations';
import { HoldingprogramdetailspageOptionsComponent } from '../../holdingprogramdetailspage-options/holdingprogramdetailspage-options.component'
import { StaticContentService } from '../../services/StaticContentService';
import { PrintService } from '../../services/PrintService';
import { AwardDetailsSummaryComponent } from '../../reusable/award-details-summary/award-details-summary.component';


@Component({
  selector: 'app-options-agreement',
  templateUrl: './options-agreement.component.html',
  styleUrls: ['./options-agreement.component.css']
})
export class OptionsAgreementComponent implements OnInit {
  @ViewChild('awardDetailsSummary') awardDetailsSummary: AwardDetailsSummaryComponent;
  @Output() childEvent = new EventEmitter<string>();

  loading: boolean = true;
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  activeTab: any = JSON.parse(sessionStorage.getItem(this.globals.grantTabInd));
  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsNextRelease: any;
  detailsSharesReleasing: any;
  detailsGrantAccepted: any;
  detailsUnreleased: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;
  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;
  grantDesc: string;
  grantID: string;
  grantDt: any;
  expirationDt: any;
  grantPriceUSD: number;
  shareOptGranted: number;
  optAcceptance: string;
  nextExercisable: any;
  sharesExercisable: number; 
  unvested: number;
  cancelled: any; 
  vestedbutUnexercisable: number;
  vestedandexercisable: number;
  exercised: number; 
  netremaining: number;
  HasSalable: any;
  HasExercisable: any;
  HasVest: any;
  grantNum: number;
  programType: number;
  agreementValid: string;
  displayContactBroker: boolean = false;
  brokerType: any;

  showCancelled: any = true;
  showVestedButUnexercisable: any = false;
  showAgreementContainer: any = true;
  showAgreementifYes: any = false;
  showNextExercisable: any = false;
  showSharesExercisable: any = false;
  showVest: any = true;
  showExercisable: boolean = false;
  showSalable: boolean = false;

  //Vest Accordion variables
  vestTotalShares: number = 0;
  ExercisableTotalShares: number = 0;
  salableTotalShares: number = 0;
  vestDataSource: any;
  ExercisableDataSource: any;
  salableDataSource: any;
  displayedVestColumns: string[] = ['Date', 'Shares', 'Status'];
  displayedExercisableColumns: string[] = ['Date', 'Shares', 'Status'];
  displayedSalableColumns: string[] = ['Date', 'Shares', 'Status'];

  
  
  displayedColumnsOptSalesHist: string[] = ['DispositionDate', 'TransacionType', 'ShareAmount', 'PriceInUSD', 'TotalValueInUSD', 'CostInUSD','PreTaxInUSD','TaxesInUSD','NetProceedsInUSD'];
  dataSourceOptSalesHist: any;
  salesTableLoading: boolean;
  totalShareAmount: any = 0;
  totalTotalValueInUSD: any = 0;
  totalCostInUSD: any = 0;
  totalPreTaxInUSD: any = 0;
  totalTaxesInUSD: any = 0;
  totalNetProceedsInUSD: any = 0;
  noSalesHistory: boolean = false;

  disableDatePicker: boolean = true;
  showAgreement: boolean = false;

  //Grant Agreement
  step1Text: string;
  step1Status: string;
  urlGrantDocument: string;
  step2Text: string;
  step2Status: string;
  optGranted: number;
  grantPrice: number;
  optgrantID: string;
  optExchRate: number;
  displayVestAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];
  vestdataSource: any;
  displayExercisableAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];
  exerdataSource: any;
  displaySalableAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];
  saleDataSource: any;
  importantNotes: string;
  imptNotesdisplayInd: boolean = false;
  step3Status: string;
  step3Text: string;
  step3ValStatus: any;
  agreementVestTable: Object[] = [];
  agreementSaleTable: Object[] = [];
  agreementExerciseTable: Object[] = [];

  showVestIcon: boolean = true;
  showExercisableIcon: boolean = true;
  showSalableIcon: boolean = true;
  expandVest: boolean = false;
  showVestTbl: boolean = false;
  showExerTbl: boolean =  false;
  showSaleTbl: boolean = false;
  displayReturnSigned: boolean = false;
  stepOneStatus: boolean = false;
  stepTwoStatus: boolean = false;
  stepThreeStatus: boolean = false;
  MailAddVisible: any;
  showNotes: boolean = false;
  disableAcceptGrantBtn: boolean;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  admin = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));
  ifvestOnly: boolean = false;
  ifExerciseOnly: boolean = false;
  ifSaleOnly: boolean = false;
  shareActAgreementActionReq: boolean = false;

  methodCounter: number = 0;
  shortAwardName: string;
  grantAgreementFile: string;

  // Learn More Tab
  optCommonQuestionsContent: any; 
  optGuideBooksContent: any; 
  optShareIncentiveContent: any;
  optCountAwardCOntent: any;
  optToolsandRepContent: any;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  bodyLearnMoreData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  grantCountryCd: any; 
  grantGroupId: any;
  disableDownloadBtn: boolean = false;
  allCountryFormsContent: string;
  stepTwoContent: object;
  errorLocation: string = " + [Component: Optionsdetails, Method: methodName]";
  errorDetailsTab: boolean = false;
  errorAgreementTab: boolean = false;
  errorExerciseHistTab: boolean = false;
  errorCommoneQuestions: boolean = false;
  errorGuideBooks: boolean = false;
  errorShareIncentive: boolean = false;
  errorCountryAward: boolean = false;
  errorToolsAndReports: boolean = false;
  
  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;

currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));


  displayDesktopView: boolean;
  displayMobileView: boolean;

  fileTypeImagePath: string;
  fileTypeAlt: string;  
  showAcceptBtn: boolean;
  showAcceptedBtn: boolean;
  OptionsLoading: boolean = true;  

  constructor(private holdingProgramDetailsOptionsService: HoldingProgramDetailsOptionsService, private printService: PrintService,private staticContentService: StaticContentService,private globals: Globals, private optionsParent: HoldingprogramdetailspageOptionsComponent) { }

  ngOnInit() {
    // BACK BUTTON BUG - START
    sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } else {
      this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
    }
    // BACK BUTTON BUG - END
   
    let grantAgreementGrantNum: any;
          
    if(this.programDetails.GrantNum != undefined){
      grantAgreementGrantNum = this.programDetails.GrantNum;
    }
    else {
      grantAgreementGrantNum = this.programDetails[0].GrantNum;
    }
    this.getGrantAgreement(grantAgreementGrantNum);
  }


   /**********************************************************************************
      Method Name: getGrantAgreement
      Description: Get grant agreement tab details

      Date            Modified by       Description
      08/06/19        JSS               Get file for Step 1     
  **********************************************************************************/
 async getGrantAgreement(grantNum){
   
  try {
    this.agreementVestTable = [];
    this.showNotes = false;
    this.holdingProgramDetailsOptionsService.getGrantAgreement(grantNum).pipe(map(res => res)).subscribe(data => {
      try {
        let optAgreementDetails = data[0][0];
        this.holdingProgramDetailsOptionsService.grantAgreementDetails = optAgreementDetails;
        let optAgreementTbl = data[1];
        let importantNotes = data[2];   

        this.step1Text =  optAgreementDetails.Step1Text;
        this.step1Status =   optAgreementDetails.Step1Status;
        this.urlGrantDocument =  optAgreementDetails.PDFName;
        this.step2Text =  optAgreementDetails.Step2Text;
        this.step2Status =  optAgreementDetails.Step2Status;
        this.optGranted =  optAgreementDetails.OptSharesGranted;
        this.grantPrice =  optAgreementDetails.OptGrantPrice;
        this.optgrantID =  optAgreementDetails.GrantId;
        this.optExchRate =  optAgreementDetails.ExchangeRate;
        this.step3Status  = optAgreementDetails.Step3Status;
        this.step3Text  =  optAgreementDetails.Step3Text;
        this.MailAddVisible = optAgreementDetails.MailAddVisible;
        this.step3ValStatus = optAgreementDetails.Step3ValStatus;
        this.grantAgreementFile = optAgreementDetails.PDFName;  //KMN, 2

        if(this.grantAgreementFile == "NO EGT PAGE"){            
          this.disableDownloadBtn = true;
        }


        if(importantNotes == undefined || importantNotes == "" || importantNotes == null){}
        else{
          this.showNotes = true;
          this.importantNotes = importantNotes.Message;
        }

        if(this.step1Status == "Action Required") {
          this.displayReturnSigned = true;
          this.stepOneStatus = true;
        } 

        if(this.step2Status == "Action Required") {
          this.stepTwoStatus = true;

          if(this.MailAddVisible == 1){
            this.displayReturnSigned = true;
          }
        } 

        if(this.step3ValStatus == GrantAgreementStatus['ActionRequired']) { 
          this.disableAcceptGrantBtn = false;
          this.disableAcceptGrantBtn = this.checkUser();
        }

        if(this.step3Status == "(Action Required)") {
          this.stepThreeStatus = true;            
          this.showAcceptBtn = true;          
          this.showAcceptedBtn = false;

        }else{
          this.showAcceptBtn = false;          
          this.showAcceptedBtn = true;
          this.stepThreeStatus = false;
          this.disableAcceptGrantBtn =  true;
       
        }
  

        for(let i = 0; i < optAgreementTbl.length; i++) {
          if(optAgreementTbl[i].VestReleaseFlag == 1) {
            this.showVestTbl = true;
            this.agreementVestTable.push(optAgreementTbl[i]);
          }else if(optAgreementTbl[i].VestReleaseFlag == 3) {
            this.showExerTbl = true;
            this.agreementExerciseTable.push(optAgreementTbl[i]);
          }else if(optAgreementTbl[i].VestReleaseFlag == 4) {
            this.showSaleTbl = true;
            this.agreementSaleTable.push(optAgreementTbl[i]);
          }
        }

      this.vestdataSource = this.agreementVestTable;
      this.holdingProgramDetailsOptionsService.vestDataSource = this.vestdataSource;
      this.exerdataSource = this.agreementExerciseTable;
      this.saleDataSource = this.agreementSaleTable;
      this.OptionsLoading = false;

    } catch(error) {
        this.errorAgreementTab = true;
        let errorLocation = this.errorLocation.replace("methodName", "getGrantAgreement");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorAgreementTab = true;
      let errorLocation = this.errorLocation.replace("methodName", "getGrantAgreement");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorAgreementTab = true;
    let errorLocation = this.errorLocation.replace("methodName", "getGrantAgreement");
    error.message += errorLocation;
    throw error;
  }
}

  /**********************************************************************************
      Method Name: checkUser
      Description: User checking for admin and non-admin users

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
 checkUser(): boolean {
  try {
    if(this.admin != null) {
    if(this.admin.OptNum == this.user.OptNum) {
      return false; // enables the button
    } else if((this.admin.OptNum != this.user.OptNum) && (AdminType[this.admin.IsAdminCanTransactInd] == AdminType[2])) {
      return false; // enables the button
    } else if((this.admin.IsAdmin == 'Y') && (AdminType[this.admin.IsAdminCanTransactInd] == AdminType[1])) {
      return true; // disables the button
    } else if(this.admin.OptNum == this.user.OptNum) {
      return true; // disables the button
    }
  }
  else {
    return false;
  }
  
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "checkUser");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
      Method Name: commafy
      Description: Number formatting

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
 commafy(num: any, value?: number) {
  try {
    // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value > 0) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message += errorLocation;
    throw error;
  }
}

 /**********************************************************************************
      Method Name: downloadGrantAgreement
      Description: Download step 1 grant agreement file

      Date            Modified by       Description
      04/22/19        Katrina Narag     Initial draft
  **********************************************************************************/
downloadGrantAgreement(){
  try {
    if(this.grantAgreementFile != null || this.grantAgreementFile != null){
      if(this.grantAgreementFile != "NO EGT PAGE"){
        this.getFile(this.grantAgreementFile, 'dynamic');
      }
     }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "downloadGrantAgreement");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
Method Name: getFile
Description: Get file data

#     Date            Modified by             Description
1     04/22/19        Katrina Narag         Initial Draft
2     05/03/19        KMN                   Add method for no file exists
**********************************************************************************************/
getFile(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
    
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file;
    }
    
    this.staticContentService.getFile(fileName, "GA").subscribe(data => { // MD for contacts page
      try {
        let content = JSON.parse(data._body);
        
        if(content == null || content == "" || content.statusCode == "404"){
          this.staticContentService.noFileExists(fileName);
        }else{
          let file = JSON.parse(content.body);
          this.downloadFile(fileName, fileType, this.base64ToArrayBuffer(file.file));
          this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
          });
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    }); 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
Method Name: base64ToArrayBuffer
Description: Converts base 64 to array buffer.

#     Date            Modified by             Description
1     04/22/19        Katrina Narag      Initial Draft
**********************************************************************************************/
base64ToArrayBuffer(base64): Uint8Array {
  try {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);
    
    for(let i = 0; i < binaryLength; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
    }
    return bytes;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "base64ToArrayBuffer");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
Method Name: downloadFile
Description: Downloads the file.

#     Date            Modified by             Description
1     04/22/19        Katrina Narag      Initial Draft
**********************************************************************************************/
downloadFile(reportName, fileType, byte) {
  try {
    // if(navigator.appVersion.toString().indexOf('.NET') > 0) { // browser is IE
    //   let blob = new Blob([byte], {type: fileType});
    //   window.navigator.msSaveOrOpenBlob(blob, reportName);
      if(navigator.userAgent.indexOf("Firefox") > 0) { // browser is FireFox
      let a = document.createElement('a');
      a.style.display = "none";
      a.style.zIndex = "1002";
      a.style.position = "absolute";
      let blob = new Blob([byte], {type: fileType});
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = reportName;
      document.body.appendChild(a);
      a.click();
      
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
      } else { // browser is Chrome
      let blob = new Blob([byte], {type: fileType});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let fileName = reportName;
      link.download = fileName;
      link.click();
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "downloadFile");
    error.message += errorLocation;
    throw error;
  }
}

 /*****************************************************************************************
  * Method Name: printFile                                                                 
  * Description: Prints the file.
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/07/19             Dalemark P. Suquib                    Initial Draft
  *****************************************************************************************/
 printFile() {
  try {
    let award: string = "options";
    this.printService.print(award);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "printFile");
    error.message += errorLocation;
    throw error;
  }
}

fileImage(filyType){
  if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
    this.fileTypeImagePath = '/assets/pdf.svg';
    this.fileTypeAlt = "PDF File"
    return true;
  }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
    this.fileTypeImagePath = '/assets/zip.svg';
    this.fileTypeAlt = "ZIP File"
    return true;
  }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
    this.fileTypeImagePath = '/assets/xls.svg';
    this.fileTypeAlt = "XLS File"
    return true;
  }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
    this.fileTypeImagePath = '/assets/docx.svg';
    this.fileTypeAlt = "DOC File"
    return true;
  }
 }


/**********************************************************************************
      Method Name: acceptGrant
      Description: Accept grant online button method

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft      
      12/27/19        Jikho             Added validation to prevent devtool enablement and invalid method calls   
  **********************************************************************************/
 acceptGrant(){
 if(this.disableAcceptGrantBtn != true){
  try {
  let userOptnum = this.user.OptNum;
  let adminOptnum;
  
  let grantNum: any;
          
    if(this.programDetails.GrantNum != undefined){
      grantNum = this.programDetails.GrantNum;
    }
    else {
      grantNum = this.programDetails[0].GrantNum;
    }
  
  if(this.admin != null || this.admin != undefined){
  adminOptnum = this.admin.OptNum;
    }else{
  adminOptnum = userOptnum;
  }
  
  this.holdingProgramDetailsOptionsService.grantAccepted(userOptnum, adminOptnum, grantNum).subscribe(data => {
  try {
      alert("Thank you for accepting your grant!\n Please check the Alert box on the Holdings page to confirm if you have any additional grants to accept.");
      if(this.programDetails[0] != undefined){
        this.programDetails[0].GrantAccepted = 'Accepted';
      } else {
        this.programDetails.GrantAccepted = 'Accepted';
      }      
      sessionStorage.removeItem("Options Data");
      sessionStorage.setItem(this.globals.programDetailsKey, JSON.stringify(this.programDetails));      
      this.childEvent; 
      this.getProgramDetails(); 
    } 
  catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
    error.message += errorLocation;
    throw error;
  }
  }, error => {
    let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
    error.error.message += errorLocation;
    throw error.error;
  });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
    error.message += errorLocation;
    throw error;
    }
  }
  }


  getProgramDetails() {
    try {    
      this.shortAwardName = this.programDetails.awardName;
      this.awardName = this.programDetails.GrantLongDesc;   //KMN, 1
      this.detailsAvailableToSell = this.programDetails.AvailableToSell;
      this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
      this.detailsBroker = this.programDetails.BrokerId;
      this.detailsNextRelease = this.programDetails.NextRelease;
      this.detailsSharesReleasing = this.programDetails.SharesReleasing;
      this.detailsGrantAccepted = this.programDetails.GrantAccepted;
      this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
      this.detailsReleased = this.programDetails.ReleasedExercisableQty;
      this.detailsExpiration = this.programDetails.Expiration;
      this.detailsForfeited = this.programDetails.Forfeited;
      let grantAgreementInd = this.programDetails.GrantAgreementTabVisibleInd;
      //this.grantCountryCd = this.programDetails.GrantCountryCd;      
      
      if(grantAgreementInd != "N"){
       this.showAgreement = true;
      }
  
      this.holdingProgramDetailsOptionsService.getSalesHistoryPeriod().pipe(map(res => res)).subscribe(data => {
        try {
          let b = data;
          b = b.sort((a,b) => 0 - (a.Id > b.Id ? -1 : 1));
          this.yearToDate = b[0].Value;
          this.lastYear = b[1].Value;
          this.last3Years = b[2].Value;
          this.allDates = b[3].Value;
          this.custom = b[4].Value;

          let grantAgreementGrantNum: any;

          if(this.programDetails.GrantNum != undefined){
            grantAgreementGrantNum = this.programDetails.GrantNum;
          }
          else {
            grantAgreementGrantNum = this.programDetails[0].GrantNum;
          }
          this.getGrantAgreement(grantAgreementGrantNum);
        } catch(error) {
          this.loading = false;
          this.errorDetailsTab = true;
          let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.loading = false;
        this.errorDetailsTab = true;
        this.getGrantAgreement(this.programDetails.GrantNum);
        let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.loading = false;
      this.errorDetailsTab = true;
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.message += errorLocation;
      throw error;
    }
  }
}
