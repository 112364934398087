export class DataAppItem {
    JOURNAL_GROUP_ID: number;
    JOURNAL_GROUP_DESC: string;
    HEADER_CO_CD: string;
    DR_CR: string;
    ACCOUNT_NUMBER: string;
    ROW_CO_CD: string;
    AMOUNT: number;
    JOURNAL_ACTION_ID: number;
    DisplayAction: boolean;
  }
  
  export interface CountryDetail {
    Country_ID: number;
    Country: string;
  }
  
  export class ActionComboItem {
    JournalGroupId: number;
    ComboItems: any;
  }