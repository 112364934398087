import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolsandreports',
  templateUrl: './toolsandreports.component.html',
  styleUrls: ['./toolsandreports.component.css']
})
export class ToolsandreportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
