import { Injectable } from '@angular/core';

@Injectable()
export class FileService {

  constructor() { }

  /**********************************************************************************
  * Method Name: convertToBase64                                                                 
  * Description: Converts file to base64.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/09/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  /* istanbul ignore next */
  convertToBase64(file: File): Promise<any> {
    let binaryFile = new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve((reader.result.toString()).split('base64,'));
      reader.onerror = error => reject(error);
    });

    return binaryFile;
  }

  /**********************************************************************************
  * Method Name: convertToBase64                                                                 
  * Description: Converts base64 to blob.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/09/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  /* istanbul ignore next */
  convertBase64ToBlob(base64: string): Promise<any> {
    let blobFile = new Promise((resolve) => {
      let binaryString = atob(base64);
      let binaryLength = binaryString.length;
      let bytes = new Uint8Array(binaryLength);

      for(let i = 0; i < binaryLength; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      let blob = new Blob([bytes], {type: 'mime_type'});
      resolve(blob);
    });

    return blobFile;
  }
}
