import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataAppItem } from '../../../../../../services/ea/models/dataapp-model';
import { Select, Store } from '@ngxs/store';
import { DataAppItemState, DataAppComboItemState } from '../../../../../../services/ea/states/dataapproval.state';
import { StaticTextState } from '../../../../../../services/ea/states/weeklysummary.state';
import { Globals } from '../../../../../../globals';
import { ApiService } from '../../../../../../services/ea/api/ApiService';
import { Dataapproval } from '../../../../../../services/ea/actions/dataapproval.actions';
import { ModalComponent } from '../../../../../../services/ea/shared/modal/modal.component';
import { LoadingOverlayComponent } from '../../../../../../services/ea/shared/loading-overlay/loading-overlay.component';
import { EjetaccessService } from '../../../../../../services/ea/EjetaccessService';

@Component({
  selector: 'app-dataapptable',
  templateUrl: './dataapptable.component.html',
  styleUrls: ['./dataapptable.component.css']
})
export class DataapptableComponent implements OnInit {

  dataSource: MatTableDataSource<DataAppItem>
  displayedColumns: string[];
  pageSizeOption: number[];
  pageSizeLabel: string = "Show"
  pageIndex: number;
  totalNumOfPage: number;
  tableHasData: boolean = true;
  comboItemArray: any;
  staticTextData: any[];
  onloadActionStatus: string = "";
  onChangeActionStatus: any[] = [];
  tableData: DataAppItem[] = [];
  journalGroupId: number = 0.1;
  tableItem: any;
  disableSubmitButton: boolean = true;
  entriesStart: number;
  entriesEnd: number;
  entriesLength: number;
  origEntriesLength: number;
  modalHeader: string;
  modalUnAuthBody: string;
  modalUnchangedBody: string;
  modalSuccessBody: string;
  modalUnsuccessBody: string;
  modalConfirmBody: string;
  tableHeaderJet: string;
  tableHeaderCompCode: string;
  tableHeaderPosting: string;
  tableHeaderAccno: string;
  tableHeaderRowCoCd: string;
  tableHeaderAmount: string;
  tableHeaderAction: string;
  filterValue = {
    JOURNAL_GROUP_DESC: '',
    HEADER_CO_CD: '',
    DR_CR: '',
    ACCOUNT_NUMBER: '',
    ROW_CO_CD: '',
    AMOUNT: ''
  }
  
  @Select(DataAppItemState.getDataAppItems) dataAppItems: any;
  @ViewChild(MatPaginator, { static: true }) paginator:  MatPaginator;
  @Select(DataAppComboItemState.getComboItems) comboItems: any;
  @Select(StaticTextState.getStaticData) staticData: any;
  @Input('confiCodes') confiCodes: any;

  items: any[];
  actionResult: any[];
  unchangeDModal: any;
  confirmModal: any;
  successModal: any;
  errorModal: any;
  uaComboItems: any[];
  uaActionId: any;

  constructor(
    public globals:Globals,
    private store: Store,
    public modal: MatDialog,
    private apiService: ApiService,
    private ejetAccessService: EjetaccessService
  ) {
   }

  ngOnInit() {
    this.pageSizeOption = this.globals.reportsPageSizeOptions;
    this.displayedColumns = this.globals.dataApprovalDisplayedColumns;
    this.getStaticContent();
    this.setDisplayAction();
    this.comboItems.subscribe( data => {
      this.comboItemArray = data;
    })
  }

  ngAfterViewInit(){
    this.dataSource.filterPredicate = (data) => (data.JOURNAL_GROUP_DESC.trim().toLowerCase().indexOf(this.filterValue.JOURNAL_GROUP_DESC.trim().toLowerCase()) !== -1 ||
                                                      data.HEADER_CO_CD.trim().toLowerCase().indexOf(this.filterValue.HEADER_CO_CD.trim().toLowerCase()) !== -1 ||
                                                      data.DR_CR.trim().toLowerCase().indexOf(this.filterValue.DR_CR.trim().toLowerCase()) !== -1 ||
                                                      data.ACCOUNT_NUMBER.trim().toLowerCase().indexOf(this.filterValue.ACCOUNT_NUMBER.trim().toLowerCase()) !== -1 ||
                                                      data.ROW_CO_CD.trim().toLowerCase().indexOf(this.filterValue.ROW_CO_CD.trim().toLowerCase()) !== -1 ||
                                                      data.AMOUNT.toString().trim().toLowerCase().indexOf(this.filterValue.AMOUNT.trim().toLowerCase()) !== -1);
    this.dataSource.paginator = this.paginator;
    this.paginator.pageSize = this.pageSizeOption[0];
    this.paginator._intl.itemsPerPageLabel = this.pageSizeLabel;
  }

  ngAfterContentChecked(){
    this.getNumberOfPage();
  }

  ngOnDestroy(){
    this.store.dispatch( new Dataapproval.ClearData);
    this.store.dispatch( new Dataapproval.ClearComboItem);
  }

  /**********************************************************************************
  * Method Name: getStaticContent                                                                 
  * Description: Gets and sets the header and intro text.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getStaticContent(){
    this.staticData.subscribe(data => {
      try {
        if (data.staticData.data) {
          this.staticTextData = data.staticData.data;
          this.modalHeader = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.header")].MESSAGE_VALUE;
          this.modalConfirmBody = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.modalconfirm.body")].MESSAGE_VALUE;
          this.modalUnAuthBody = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.modalunauth.body")].MESSAGE_VALUE;
          this.modalUnchangedBody = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.modalunchanged.body")].MESSAGE_VALUE;
          this.modalSuccessBody = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.modalsuccess.body")].MESSAGE_VALUE;
          this.modalUnsuccessBody = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.modalunsuccess.body")].MESSAGE_VALUE;
          this.tableHeaderJet = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.tableheader.jet")].MESSAGE_VALUE;
          this.tableHeaderCompCode = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.tableheader.compcode")].MESSAGE_VALUE;
          this.tableHeaderPosting = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.tableheader.posting")].MESSAGE_VALUE;
          this.tableHeaderAccno = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.tableheader.accno")].MESSAGE_VALUE;
          this.tableHeaderRowCoCd = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.tableheader.rowcocd")].MESSAGE_VALUE;
          this.tableHeaderAmount = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.tableheader.amount")].MESSAGE_VALUE;
          this.tableHeaderAction = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.tableheader.action")].MESSAGE_VALUE;
        }
      } catch(error) {
        this.ejetAccessService.setDataAppError(true);
        error.message += this.apiService.setErrorContents('getStaticContent', 'Dataapptable');
        throw error;
      }
    });
  }


  /**********************************************************************************
  * Method Name: setDisplayAction                                                                 
  * Description: Gets the list of reports and sets the display action status.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  setDisplayAction(){
    try {
      this.dataAppItems.subscribe(data => {
        try {
          this.origEntriesLength = data.length;
          for (let l = data.length - 1; l >= 0; l--) {
            this.tableItem = new DataAppItem();
            this.tableItem.JOURNAL_GROUP_ID = data[l].JOURNAL_GROUP_ID;
            this.tableItem.JOURNAL_GROUP_DESC = data[l].JOURNAL_GROUP_DESC;
            this.tableItem.HEADER_CO_CD = data[l].HEADER_CO_CD;
            this.tableItem.DR_CR = data[l].DR_CR;
            this.tableItem.ACCOUNT_NUMBER = data[l].ACCOUNT_NUMBER;
            this.tableItem.ROW_CO_CD = data[l].ROW_CO_CD;
            this.tableItem.AMOUNT = data[l].AMOUNT;
            this.tableItem.JOURNAL_ACTION_ID = data[l].JOURNAL_ACTION_ID;
            this.tableItem.DisplayAction = data[l].JOURNAL_GROUP_ID != this.journalGroupId;
            
            this.tableData.unshift(this.tableItem);
    
            this.journalGroupId = data[l].JOURNAL_GROUP_ID;
          }
        } catch(error) {
          this.ejetAccessService.setDataAppError(true);
          error.message += this.apiService.setErrorContents('setDisplayAction', 'Dataapptable');
          throw error;
        }
      });

      this.dataSource = new MatTableDataSource(this.tableData);
      this.getOnloadActionStatus();
    } catch (error) {
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('setDisplayAction', 'Dataapptable');
      throw error;
    }    
  }


  /**********************************************************************************
  * Method Name: getOnloadActionStatus                                                                 
  * Description: Gets the current active item for each combo items.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getOnloadActionStatus(){
    try {
      this.actionResult = [];
      const map = new Map();
      for (const item of this.tableData) {
          if(!map.has(item.JOURNAL_GROUP_ID)){
              map.set(item.JOURNAL_GROUP_ID, true);
              this.actionResult.push({
                  journalID: item.JOURNAL_GROUP_ID,
                  actionID: item.JOURNAL_ACTION_ID
              });
          }
      }
      this.onloadActionStatus = JSON.stringify(this.actionResult);
      this.onChangeActionStatus = this.actionResult;
    } catch (error) {
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('getOnloadActionStatus', 'Dataapptable');
      throw error;
    }
  }
  
  /**********************************************************************************
  * Method Name: getNumberOfPage                                                                 
  * Description: Sets the number of pages and the index of the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/22/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getNumberOfPage(){
    try {
      let dataLength;
      dataLength = this.filterValue.JOURNAL_GROUP_DESC != "" ? this.dataSource.filteredData.length : this.dataSource.data.length;
      dataLength > 0 ? this.entriesStart = 1 + (this.paginator.pageIndex * this.paginator.pageSize) : this.entriesStart = 0;
      this.entriesEnd = dataLength < this.paginator.pageSize ? dataLength : this.paginator.pageSize + (this.paginator.pageSize * this.paginator.pageIndex);
      this.entriesEnd =  this.entriesEnd > dataLength ? dataLength : this.entriesEnd;
      this.entriesLength = dataLength;
      this.pageIndex = this.paginator.pageIndex + 1;
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;      
    } catch (error) {
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('getNumberOfPage', 'Dataapptable');
      throw error;
    }      
  }

  /**********************************************************************************
  * Method Name: getDataIndex                                                                 
  * Description: Returns the index of the given key.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getDataIndex(key){
    try {
      let index;
      for (let i = 0; i < this.staticTextData.length; i++) {
        if (this.staticTextData[i].MESSAGE_NAME == key) {
          index = i
        }
      }
      return index;
    } catch (error) {
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('getDataIndex', 'Dataapptable');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: submitChanges                                                                 
  * Description: Opens a modal if there is no change made in the combo items and a confirmation before calling the api to submit the changes.                        
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  * 2         03/31/20             John Paul B. Fernando          Change header label to weekly data approval.
  * 3         06/03/20             John Paul B. Fernando          Added succesful pop up message
  ***********************************************************************************/
  submitChanges(){
    try {
      window.scroll(0,0);
      if (this.confiCodes.roleId == 7) {
        let content: any = {
          header: this.modalHeader,
          body: this.modalUnAuthBody,
          hasBtn1: true,
          hasBtn2: false,
          btn1Name: "OK",
          btn2Name: ""
        }
        this.modal.open(ModalComponent, {
          panelClass: 'custom-modal',
          disableClose: true,
          data: {
            content
          }
        });
      }else{
        if (this.onloadActionStatus == JSON.stringify(this.onChangeActionStatus)) {
          let content: any = {
            header: this.modalHeader,
            body: this.modalUnchangedBody,
            hasBtn1: true,
            hasBtn2: false,
            btn1Name: "OK",
            btn2Name: ""
          }
          this.unchangeDModal = this.modal.open(ModalComponent, {
            panelClass: 'custom-modal',
            disableClose: true,
            data: {
              content
            }
          });
        }else{
          let content = {
            header: this.modalHeader,
            body: this.modalConfirmBody,
            hasBtn1: true,
            hasBtn2: true,
            btn1Name: "OK",
            btn2Name: "CANCEL"
          }
  
          this.confirmModal = this.modal.open(ModalComponent, {
            panelClass: 'custom-modal',
            disableClose: true,
            data: {
              content
            }
          });
  
          this.confirmModal.afterClosed().subscribe( result => {
            if (result) {
              this.openModalOverlay();
              this.postChanges();
            }
          })
        }
      }
    } catch (error) {
      this.modal.closeAll();
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('submitChanges', 'Dataapptable');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: postChanges                                                                 
  * Description: Calls the api postdata.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  postChanges(){
    let body = {
      functionName: "saveWeeklyDataApproval",
      sourceId: this.confiCodes.sourceId,
      countryId: this.confiCodes.countryId,
      companyCode: this.confiCodes.companyCode,
      fieldString: this.getFieldString()
    }
    this.apiService.postData(this.globals.manageWeeklySummaryApi, body).subscribe( data => {
      try {
        if (data && data.statusCode == 200 ) {
          let body = data.body;
          if (body && body.length > 0) {
            let retval = body[0].RetVal;
            if (retval == 1) {
              this.modal.closeAll();
              this.openSuccessModal();
            }else{
              this.openErrorModal();
            }
          }else{
            this.openErrorModal();
          }
        }else{
          this.openErrorModal();
        }
      } catch(error) {
        this.modal.closeAll();
        this.ejetAccessService.setDataAppError(true);
        error.message += this.apiService.setErrorContents('submitChanges', 'Dataapptable');
        throw error;
      }
    }, error => {
      this.modal.closeAll();
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('submitChanges', 'Dataapptable');
      throw error;
    });
  }

  /**********************************************************************************
  * Method Name: openSuccessModal                                                                 
  * Description: Opens sucess modal after submitting changes.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openSuccessModal(){
    let content: any = {
      header: this.modalHeader,
      body: this.modalSuccessBody,
      hasBtn1: true,
      hasBtn2: false,
      btn1Name: "OK",
      btn2Name: ""
    }
    this.successModal = this.modal.open(ModalComponent, {
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        content
      }
    });

    this.successModal.afterClosed().subscribe( result => {
      if (result) {
        this.onloadActionStatus = JSON.stringify(this.onChangeActionStatus);
        this.disableSubmitButton = true;
        this.modal.closeAll();
      }
    })
  }


  /**********************************************************************************
  * Method Name: openModalOverlay                                                                 
  * Description: Opens an overlay loading icon.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openModalOverlay(){
    try{
      this.modal.open(LoadingOverlayComponent, {disableClose: true, panelClass: 'overlay-modal'})
    } catch(error) {
      this.modal.closeAll();
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('openModalOverlay', 'Dataapptable');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getFieldString                                                                 
  * Description: Creates the fields string of action combo items.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getFieldString(){
    try {
      let result = "";
      for (let i = 0; i < this.onChangeActionStatus.length; i++) {
        result += this.onChangeActionStatus[i].actionID + "~" + this.onChangeActionStatus[i].journalID + "|";
      }
      return result;
    } catch (error) {
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('getFieldString', 'Dataapptable');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: openErrorModal                                                                 
  * Description: Opens an error modal if there is an error in the api call.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  * 2         03/31/20             John Paul B. Fernando          Change header label to weekly data approval.
  ***********************************************************************************/
  openErrorModal() {
    try {
      let content: any = {
        header: this.modalHeader,
        body: this.modalUnsuccessBody,
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: "OK",
        btn2Name: ""
      }
      this.errorModal = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });
  
      this.errorModal.afterClosed().subscribe( result => {
        if (result) {
          this.modal.closeAll();
        }
      })
    } catch(error) {
      this.modal.closeAll();
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('openErrorModal', 'Dataapptable');
      throw error;
    }
  }
  
  /**********************************************************************************
  * Method Name: applyFilter                                                                 
  * Description: Apply filter using the enterd word in search box.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/26/20             John Paul B Fernando           Initial draft  
  * 2         03/26/20             John Paul B Fernando           Added a method to update the display action status
  * 3         04/01/20             John Paul B Fernando           Added trim and lowercase for search value.
  ***********************************************************************************/
  applyFilter(filterVal: string) {
    try { 
      let filterValue = filterVal;     
      this.journalGroupId = 0.1;
      this.filterValue.JOURNAL_GROUP_DESC = filterValue;
      this.filterValue.HEADER_CO_CD = filterValue;
      this.filterValue.DR_CR = filterValue;
      this.filterValue.ACCOUNT_NUMBER = filterValue;
      this.filterValue.ROW_CO_CD = filterValue;
      this.filterValue.AMOUNT = filterValue;
      this.dataSource.filter = JSON.stringify(this.filterValue);
      this.tableHasData = this.dataSource.filteredData.length > 0;
      for (let l = this.dataSource.filteredData.length - 1; l >= 0; l--) {
        this.dataSource.filteredData[l].DisplayAction = this.dataSource.filteredData[l].JOURNAL_GROUP_ID != this.journalGroupId;
        this.journalGroupId = this.dataSource.filteredData[l].JOURNAL_GROUP_ID;
      }
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;
      this.paginator.firstPage();
    } catch (error) {
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('applyFilter', 'Dataapptable');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getActiveComboItem                                                                 
  * Description: Sets the active item of action dropdown.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getActiveComboItem(groupId){
    try {
      this.items = [];
      let actionId;
      for (let a = 0; a < this.onChangeActionStatus.length; a++) {
        if (this.onChangeActionStatus[a].journalID == groupId) {
          actionId = this.onChangeActionStatus[a].actionID;
        }
      }
      for (let i = 0; i < this.comboItemArray.length; i++) {
        if (this.comboItemArray[i].JournalGroupId == groupId ) {
          this.items = this.comboItemArray[i].ComboItems
        }
      }
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].Journal_Action_ID == actionId) {
          return this.items[i].Journal_Action_Desc;
        }
      }
    } catch (error) {
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('getActiveComboItem', 'Dataapptable');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: updateActionStatus                                                                 
  * Description: Updates the current selected item of each combo item.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  updateActionStatus(actionDesc, groupID){
    try {
      this.uaComboItems = [];
      this.uaActionId;
      for (let i = 0; i < this.comboItemArray.length; i++) {
        if (this.comboItemArray[i].JournalGroupId == groupID ) {
          this.uaComboItems = this.comboItemArray[i].ComboItems
        }
      }
      for (let i = 0; i < this.uaComboItems.length; i++) {
        if (this.uaComboItems[i].Journal_Action_Desc == actionDesc) {
          this.uaActionId = this.uaComboItems[i].Journal_Action_ID;
        }
      }
      for (let i = 0; i < this.onChangeActionStatus.length; i++) {
        if (this.onChangeActionStatus[i].journalID == groupID) {
          this.onChangeActionStatus[i].actionID = parseInt(this.uaActionId);
        }
      }
      if (this.confiCodes.roleId != 7) {
        this.disableSubmitButton = this.onloadActionStatus == JSON.stringify(this.onChangeActionStatus);
      }
    } catch (error) {
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('updateActionStatus', 'Dataapptable');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getComboItems                                                                 
  * Description: Get combo items for specific journal group id.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getComboItems(groupId){
    try {
      for (let i = 0; i < this.comboItemArray.length; i++) {
        if (this.comboItemArray[i].JournalGroupId == groupId) {
          return this.comboItemArray[i].ComboItems;
        }
      }
    } catch (error) {
      this.ejetAccessService.setDataAppError(true);
      error.message += this.apiService.setErrorContents('getComboItems', 'Dataapptable');
      throw error;
    }
  }

}
