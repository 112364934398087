import { Component, OnInit } from '@angular/core';
import { PageSection } from '../../services/enumerations/enumerations';
import { StaticContentService } from '../../services/StaticContentService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topquestions',
  templateUrl: './topquestions.component.html',
  styleUrls: ['./topquestions.component.css']
})
export class TopquestionsComponent implements OnInit {
  resourceLoading: boolean;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  topQuestions: any;
  errorTopQuestions: boolean = false;
  errorLocation: string = " + [Component: Resourcespage, Method: methodName]";

  constructor(private staticContent: StaticContentService, private router: Router) { }

  ngOnInit() {
    this.getTopQuestions();
  }

  
getTopQuestions(){
  try {
    // this.topQuestionsLoading = true;
    this.resourceLoading = true;
    this.bodyData[5] = PageSection.topQuestions;
    this.bodyData[6] = 1;
  
    this.staticContent.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let content = JSON.parse(data._body);

        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }

        if(content.body.length > 2) { // length > {}
          let topQuestionsContent = JSON.parse(content.body);
          if(topQuestionsContent.data.length > 0) {
            this.topQuestions = topQuestionsContent.data;

            for(let i=0; i<this.topQuestions.length; i++){
              if(this.topQuestions[i]["Body_Desc"].indexOf("<u>") != -1){
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].split("<u>").join("<span style='text-decoration: underline;'>");
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].split("</u>").join("</span>");
              }
              if(this.topQuestions[i]["Body_Desc"].indexOf('<p align="left">&#160;</p>') != -1){
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].replace('<p align="left">&#160;</p>','');
              }
              if(this.topQuestions[i]["Body_Desc"].indexOf('<font size="1">') != -1){
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].split('<font size="1">').join("");
                this.topQuestions[i]["Body_Desc"] = this.topQuestions[i]["Body_Desc"].split('</font>').join("");
              }
            } 
          } else {
            this.topQuestions = null;
          }
        } 
        setTimeout(()=>{ this.inContentIconAppend() }, 500);
        // this.topQuestionsLoading = false;
        this.resourceLoading = false;
      } catch(error) {
        this.errorTopQuestions = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorTopQuestions = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorTopQuestions = true;
    this.resourceLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
    error.message += errorLocation;
    throw error;
  }
}

async inContentIconAppend(){
  this.staticContent.inContentIconAppend();
}


}
