
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
//import moment = require('moment');
// import { Http, Headers, RequestOptions } from '@angular/http';

@Injectable()
export class EspptransactionService {
  headers: Headers;
  // options: RequestOptions;
  token: string = sessionStorage.getItem("msal.idtoken");
  
  constructor(private globals: Globals, private httpClient: HttpClient) { }

  getDataFromCodeDetail(): any{
    let apiUrl = this.globals.currentAPIUrl; 
    let getValue = this.globals.getValuefromCodeDetail;

    try {
      const api = apiUrl + getValue + "19";
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getESPPEnrollDetail(OptNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let esppEnrollDetailsUrl = this.globals.getESPPEnrollDetailsAPI;

    esppEnrollDetailsUrl = esppEnrollDetailsUrl.replace("[param1]", OptNum);
    // esppEnrollDetailsUrl = esppEnrollDetailsUrl.replace("[param2]", OfferNum);
    
    try {
      const api = apiUrl + esppEnrollDetailsUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getESPPEnrollPercentage(OptNum, OfferNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let esppEnrollPercentsUrl = this.globals.getESPPEnrollPercentAPI;

    esppEnrollPercentsUrl = esppEnrollPercentsUrl.replace("[param1]", OptNum);
    esppEnrollPercentsUrl = esppEnrollPercentsUrl.replace("[param2]", OfferNum);
    
    try {
      const api = apiUrl + esppEnrollPercentsUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getESPPEnrollTaxElection(OptNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let esppEnrollPercentsUrl = this.globals.getESPPTaxElectionAPI;

    esppEnrollPercentsUrl = esppEnrollPercentsUrl.replace("[param1]", OptNum);
    
    try {
      const api = apiUrl + esppEnrollPercentsUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  sendMail(data){
    let apiURL = this.globals.currentAPIUrl;
    let sendmailPath = this.globals.sendMailAPI;
    
    sendmailPath = sendmailPath.replace("[param1]", data[0]);
    sendmailPath = sendmailPath.replace("[param2]", data[1]);
    sendmailPath = sendmailPath.replace("[param3]", data[2]);
    sendmailPath = sendmailPath.replace("[param4]", data[3]);
    sendmailPath = sendmailPath.replace("[param5]", data[4]);
    sendmailPath = sendmailPath.replace("[param6]", data[5]);
    sendmailPath = sendmailPath.replace("[param7]", data[6]);
    sendmailPath = sendmailPath.replace("[param8]", data[7]);
    sendmailPath = sendmailPath.replace("[param9]", data[8]);
    sendmailPath = sendmailPath.replace("[param10]",data[9]);
    sendmailPath = sendmailPath.replace("[param11]",data[10]);  // JMC, 06/28/2021 - Modified based on User Story 332873 - CRIL00051021173444 - Missing Enrollment Email Notifications

    try {
      const apiSendMail = apiURL + sendmailPath;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});
      let body = JSON.stringify({ data })

      return this.httpClient.post(apiSendMail, body);

      // return null;
    }catch(err) {
      return err;
    }
  }

  //Action Items Service
  
  getESPPActionItem(OptNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let esppActionItemUrl = this.globals.getESPPActionitemAPI;

    esppActionItemUrl = esppActionItemUrl.replace("[param1]", OptNum);
    
    try {
      const api = apiUrl + esppActionItemUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getGrantActionitemAPI(OptNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let GrantActionItemUrl = this.globals.getGrantAlertAPI;
  
    GrantActionItemUrl = GrantActionItemUrl.replace("[param1]", OptNum);
    
    try {
      const api = apiUrl + GrantActionItemUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 

  insertTransTbl(data: any) {
    let regEx: any = /\'/gi;
    let apiURL = this.globals.currentAPIUrl;
    let methodPath = this.globals.postEsppTransTable;

    methodPath = methodPath.replace("[param2]", data[0]);
    // methodPath = methodPath.replace("[param3]", data[1].replace(regEx,""));
    methodPath = methodPath.replace("[param5]", data[1]);
    methodPath = methodPath.replace("[param6]", data[2]);
    methodPath = methodPath.replace("[param7]", data[3]);
    methodPath = methodPath.replace("[param8]", data[4]);
    methodPath = methodPath.replace("[param9]", data[5]);


    try {
      const apiLogin = apiURL + methodPath;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});
      let body = JSON.stringify({ data })

      return this.httpClient.put(apiLogin, body).pipe(retry(3));
    }catch(err) {
      return err;
    }
  }

  insertTaxElec(data: any) {
    let apiURL = this.globals.currentAPIUrl;
    let methodPath = this.globals.insertTaxElect;

    methodPath = methodPath.replace("[param1]", data[0]);
    methodPath = methodPath.replace("[param2]", "'" + data[1] + "'");
    methodPath = methodPath.replace("[param3]", data[2]);

    try {
      const apiLogin = apiURL + methodPath;
      
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});       
      let body = JSON.stringify({ data })      

      return this.httpClient.put(apiLogin, body).pipe(retry(3));
    }catch(err) {
      return err;
    }
  }
}