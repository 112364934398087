
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatInput } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';
import * as moment from 'moment'
import { BrokerType, MyHoldingsPagesResources, tScript, CurrencySymbol} from '../services/enumerations/enumerations';
import { HoldingProgramDetailsFounderService } from './HoldingProgramDetailsFounderService';
// import { IfObservable } from 'rxjs/observable/IfObservable';
import { PageSection, FileType } from '../services/enumerations/enumerations';
import { StaticContentService } from '../services/StaticContentService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { BrokersPageService } from '../brokerspage/BrokersPageService';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {TooltipPosition} from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-holdingprogramdetailspage-founder',
  templateUrl: './holdingprogramdetailspage-founder.component.html',
  styleUrls: ['./holdingprogramdetailspage-founder.component.css']
})
export class HoldingprogramdetailspageFounderComponent implements OnInit {

  // @ViewChild('dateFromInput', {
  //   read: MatInput
  // }) dateFromInput: MatInput;

  // @ViewChild('dateToInput', {
  //   read: MatInput
  // })  dateToInput: MatInput;

  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = sessionStorage.getItem(this.globals.selectedCurrencyCd);
  loading:boolean = true;
  loadingDetails:boolean = false;
  datePipe = new DatePipe('en-US');
  dateFrom: string = "' '";
  dateTo: string = "' '";
  voteShareInd:any = 0;
  grantInd:any = 0;
  dateFromClicked: boolean = false;
  dateToClicked: boolean = false;
  dateFromHolder: any;
  dateToHolder: any;
  dateFromPlaceholder: string = " ";
  dateToPlaceholder: string = " ";
  // dateFromInput: NgbDateStruct; //Ngb Datepicker
  // dateToInput: NgbDateStruct; //Ngb Datepicker

  dateFromInput: Date; // Angular Material Datepicker
  dateToInput: Date; // Angular Material Datepicker

  currentSalesHistOptNum: any;
  transactionHistList:any;

  dateFromChecker: any;
  dateToChecker: any;
  dateErrorChecker: boolean = false;

  dataSourceFoundersSalesHist: any;
  displayedColumnsSalesHist: string[] = ['Date', 'Type', 'Shares', 'Price', 'ProceedsUSD'];
  displayedReleaseSchedule: string[] = ['release', 'amount'];

  founderPrimaryAccountDetails: any;
  founderPrimaryReleaseSched: any;
  founderTransfereeDetails: any;
  founderTransfereeDropDown: any;
  founderTransfereeReleaseSched: any;

  founderTransfereeDropDownChecker: any;

  founderPrimaryReleaseTotal: any = 0;
  founderTransfereeReleaseTotal: any = 0
  totalSharesSalesHistory: any;
  totalProceedsSalesHistory: any;

  hasReleaseSchedPrimary:boolean = true;
  hasTradingWindow:boolean = true;
  hasPricingMethod:boolean = true;
  hasRedemptionMethod: boolean = false;
  hasTotalTransferredToFCT: boolean = true;
  hasTotalVotingShares: boolean = true;

  hasTransfereeAccount:boolean = false;
  hasReleaseSchedTransferee:boolean = true;
  hasTradingWindowTransferee:boolean = true;
  hasPricingMethodTransferee:boolean = true;
  hasRedemptionMethodTransferee:boolean = false;
  hasTotalVotingSharesTransferee:boolean = true;

  salesTableLoading: boolean;
  noSalesHistory: boolean = false;
  disableDatePicker: boolean = true;
  votingShareChecker: boolean = false;
  votingDisplayChecker: boolean = true;

  brokerCode: any = [];
  brokerCodes: boolean = false;
  periodSelected: string = "All Dates";
  transactAcctName: any;
  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;

  datePeriod: number;

  //Learn More Tab
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  foundersCommonQuestionsContents: any;
  foundersGuidebooksTaxImpContents: any;
  foundersToolsandReports: any;
  allCountryFormsContent: string;
  stepTwoContent: object;
  errorLocation: string = " + [Component: Founderdetails, Method: methodName]";
  foundersCountryAwardContent: any;
  errorDetailsTab: boolean = false;
  errorSalesHistTab: boolean = false;
  errorCommonQuestions: boolean = false;
  errorGuideBooks: boolean = false;
  errorToolsAndReports: boolean = false;
  

  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;

fileTypeImagePath: string;
fileTypeAlt: string;

//tooltip
shareIssued_mt: string = tScript.founders_SharesIssued;
pricingMethod_mt: string = tScript.founders_PricingMethod;
netIssued_mt: string = tScript.founders_NetIssued;
eligibleForSaleOrTransfer_mt: string = tScript.founders_EligibleForSaleOrTransfer;
date_mt: string = tScript.founders_Date;
type_mt: string = tScript.founders_Type;
price_mt: string = tScript.founders_Price;
proceeds_mt: string = tScript.founders_Proceeds;

LearnMoreLoading:boolean = true;
commonQAccordionCount: number = 1; // 1 accordion is initially expanded

currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));
currencyCdSymbol: any;

positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
UBSlink: any;
MSlink: any;
envSetting: any;


  constructor(private sanitizer:DomSanitizer, private brokerService: BrokersPageService, private router: Router, private app: AppComponent, private globals: Globals, private founderDetails: HoldingProgramDetailsFounderService,  private staticContentService: StaticContentService, private errorNotificationService: ErrorNotificationService, private ngbDateParserFormatter: NgbDateParserFormatter) {
    this.app.appNavHeaderVisible = true;
    this.app.appFooterVisible = true;
  }

  ngOnInit() {
    this.grantInd = 1;
    let hasFounderChecker = sessionStorage.getItem("showFounderData");
    if (hasFounderChecker != "1") this.router.navigate(['homepage']);

    // BACK BUTTON BUG - START
    sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } else {
      this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
    }
    // BACK BUTTON BUG - END

    if (this.currencyCd == null || this.currencyCd == "" || this.currencyCd == undefined || this.currencyCd == "null") {
      this.currencyCd = this.user.CurrencyCd;
    }
    this.currencyCd = this.currencyCd.split('"').join("");
    this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")];
    this.checkErrorMessage();
    this.getFoundersStaticData();
    this.getCommonQuestions();
    sessionStorage.setItem("Program Component Indicator", JSON.stringify("Founder"));
    sessionStorage.setItem("Highlight Checker", "1");
    sessionStorage.getItem("Row Highligted");
    let active = sessionStorage.getItem("IsActive Row");
    this.selectDatePeriod(4);
    this.brokersSSO();
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
  if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
    let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

    if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
    }else{
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
    }
  }else{
    if(this.bodyData != null){
      if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }
  }
}

  navToPage(strLoc) {
    try {
      // BACK BUTTON BUG - START
      if(strLoc == "holdingsummarypage") {
        sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(true));
      }
      // BACK BUTTON BUG - END

      this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  async getFoundersStaticData(){
    try {    

      this.founderDetails.getSalesHistoryPeriod().pipe(map(res => res)).subscribe(data => {
        try {
          let b = data;
          b = b.sort((a,b) => 0 - (a.Id > b.Id ? -1 : 1));
          this.yearToDate = b[0].Value;
          this.lastYear = b[1].Value;
          this.last3Years = b[2].Value;
          this.allDates = b[3].Value;
          this.custom = b[4].Value;     
          
          this.getFounderDetails(this.user.OptNum, this.programDetails.GrantNum);
          
        } catch(error) {
          this.errorDetailsTab = true;
          this.errorSalesHistTab = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getFoundersStaticData");
         error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFoundersStaticData");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFoundersStaticData");
      error.message += errorLocation;
      throw error;
    }
  }

  async getSalesHistoryData(period, optNum, transactionChecker){
    try {

      this.datePeriod = period;
      if(this.votingShareChecker == true )
        this.voteShareInd = 1;
      else
        this.voteShareInd = 0;
      this.founderDetails.getFounderSalesHist(optNum, this.programDetails.GrantNum, this.datePeriod, this.dateFrom, this.dateTo, this.voteShareInd, this.grantInd).pipe(map(res => res)).subscribe(data => {
        try {
          if(data[2].length == 0 && transactionChecker == 0){
            for( let i = 0; i < data[0].length; i++ ){
              this.brokerCode[i] = BrokerType[data[0][i].BrokerCd];
              if( i > 0){
                this.brokerCodes = true;
              }
            }
            this.transactionHistList = data[1];
            this.transactAcctName = this.transactionHistList[0].AccountName;
            this.currentSalesHistOptNum = this.transactionHistList[0].OptNum;
                this.dataSourceFoundersSalesHist = data[2];
            this.salesTableLoading = false;
            this.noSalesHistory = true;
          }
          else if(data[2].length != 0 && transactionChecker == 0){
            this.transactionHistList = data[1];
            this.transactAcctName = this.transactionHistList[0].AccountName;
            this.currentSalesHistOptNum = this.transactionHistList[0].OptNum;
                this.dataSourceFoundersSalesHist = data[2];
            for( let i = 0; i < data[0].length; i++ ){
              this.brokerCode[i] = BrokerType[data[0][i].BrokerCd];
              if( i > 0){
                this.brokerCodes = true;
              }
            }
            this.noSalesHistory = false;
            this.getsalesHistoryDataDetails();
          }
          else if(data[2].length == 0 && transactionChecker == 1){
            for( let i = 0; i < data[0].length; i++ ){
              this.brokerCode[i] = BrokerType[data[0][i].BrokerCd];
              if( i > 0){
                this.brokerCodes = true;
              }
            }
            this.transactionHistList = data[1];
            if(Object.keys(this.transactionHistList).length>0){
              let temp = this.transactionHistList.find(i => i.OptNum === optNum )
              this.transactAcctName = temp.AccountName;
            }
            this.currentSalesHistOptNum = optNum;
            this.dataSourceFoundersSalesHist = data[2];
            this.salesTableLoading = false
            this.noSalesHistory = true;
          }
          else if(data[2].length != 0 && transactionChecker == 1){
            this.transactionHistList = data[1];
            if(Object.keys(this.transactionHistList).length>0){
              let temp = this.transactionHistList.find(i => i.OptNum === optNum )
              this.transactAcctName = temp.AccountName;
            }
            this.currentSalesHistOptNum = optNum;
            this.dataSourceFoundersSalesHist = data[2];
            for( let i = 0; i < data[0].length; i++ ){
              this.brokerCode[i] = BrokerType[data[0][i].BrokerCd];
              if( i > 0){
                this.brokerCodes = true;
              }
            }
            this.noSalesHistory = false;
            this.getsalesHistoryDataDetails();
          }
        } catch(error) {
          this.errorSalesHistTab = true;
          this.salesTableLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryData");
          error.message += errorLocation;
          throw error;
        }
    }, error => {
      this.errorSalesHistTab = true;
      this.salesTableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryData");
      error.error.message += errorLocation;
      throw error.error;
    });
    } catch(error) {
      this.errorSalesHistTab = true;
      this.salesTableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryData");
      error.message += errorLocation;
      throw error;
    }
  }

  async getsalesHistoryDataDetails(){
    try {
      this.totalSharesSalesHistory = 0;
      this.totalProceedsSalesHistory = 0;
      this.dataSourceFoundersSalesHist.forEach(a => {
        this.totalSharesSalesHistory += a.Shares;
        let temp: number = +a.ProceedsInUSD;
        this.totalProceedsSalesHistory += temp;
        if(a.ProceedsInUSD == 0){
          a.ProceedsInUSD = '-';
        }
        if(a.TransactionPrice == 0 || a.TransactionPrice == '0.00' || a.TransactionPrice == '0'){
          a.TransactionPrice = '-';
        }
        this.salesTableLoading = false
        this.noSalesHistory = false;
      });
  
      if(this.totalProceedsSalesHistory == 0 || this.totalProceedsSalesHistory == '0.00' || this.totalProceedsSalesHistory == '0' ){
        this.totalProceedsSalesHistory = ' ';
      }
    } catch(error) {
      this.errorSalesHistTab = true;
      this.salesTableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getsalesHistoryDataDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  async getFounderDetails(optNum, grantNum){
    try {
      this.founderDetails.getFounderDetails(optNum, grantNum).pipe(map(res => res)).subscribe(data => {
        try {
        // 0 - Primary Account
        // 1 - Primary Release Sched
        // 2 - Tranferee Account Details Dropdown
        // 3 - Transferee Account Details
        // 4 - Primary Release Schedule/ Transferee Account Details
        this.founderTransfereeDropDown = data[2];       
          if(this.founderTransfereeDropDown.length == 0){
            this.founderPrimaryAccountDetails = data[0];
            this.founderPrimaryReleaseSched = data[4];
  
            this.hasTransfereeAccount = false;            
            this.arrangeFounderDetailsDataPrimary(0);
            this.getSalesHistoryData(4, this.user.OptNum, 0); //4 is the default value for period
            this.loading = false;
            this.setTabIndex();
          }
          else{           
            this.hasTransfereeAccount = true;            
            this.getFounderDetailsTransferee(this.founderTransfereeDropDown[0].OptNum, this.programDetails.GrantNum, 0);
            this.getSalesHistoryData(4, this.user.OptNum, 0);
          }
        } catch(error) {
          this.errorDetailsTab = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getFounderDetails");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorDetailsTab = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getFounderDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorDetailsTab = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getFounderDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  async getFounderDetailsTransferee(optNum, grantNum, entityNameChecker){  
    try {     
      this.loadingDetails = true;
      this.founderDetails.getFounderDetails(optNum, grantNum).pipe(map(res => res)).subscribe(data => {
        try {

          this.founderPrimaryAccountDetails = data[0];
          this.founderPrimaryReleaseSched = data[1];          
          this.founderTransfereeDropDown = data[2];         
          this.founderTransfereeDetails = data[3];
          this.founderTransfereeReleaseSched = data[4];        
          this.arrangeFounderDetailsDataPrimary(entityNameChecker);
          this.loading = false;
          this.loadingDetails = false;
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFounderDetailsTransferee");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFounderDetailsTransferee");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFounderDetailsTransferee");
      error.message += errorLocation;
      throw error;
    }
  }

  async arrangeFounderDetailsDataPrimary(dropdownChecker){ 
    try {
      this.founderPrimaryReleaseTotal = 0;
      //Check for Trading Window
      if(this.founderPrimaryAccountDetails[0].TradingWindow == null || this.founderPrimaryAccountDetails[0].TradingWindow == undefined || this.founderPrimaryAccountDetails[0].TradingWindow == "")
        this.hasTradingWindow = false;
        else
        this.hasTradingWindow = true;
      //check for Pricing Method
      if(this.founderPrimaryAccountDetails[0].PricingMethod == null || this.founderPrimaryAccountDetails[0].PricingMethod == undefined || this.founderPrimaryAccountDetails[0].PricingMethod == ""){
        this.hasPricingMethod = false;
        this.hasRedemptionMethod = false;
      }
      else{ //Redemption method under pricing method
        this.hasPricingMethod = false;
        this.hasRedemptionMethod = true;
        if(this.founderPrimaryAccountDetails[0].RedemptionMethod == 1)
          this.founderPrimaryAccountDetails[0].RedemptionMethod = 'Cash'
        else if(this.founderPrimaryAccountDetails[0].RedemptionMethod == 2)
          this.founderPrimaryAccountDetails[0].RedemptionMethod = 'Share'
      }
      //check for Total Transferred To FCT
      if(this.founderPrimaryAccountDetails[0].TotalTransferredToFCT == null || this.founderPrimaryAccountDetails[0].TotalTransferredToFCT == undefined || this.founderPrimaryAccountDetails[0].TotalTransferredToFCT == "")
        this.hasTotalTransferredToFCT = false;
        else
        this.hasTotalTransferredToFCT = true;
  
      //Check for Total Voting Shares, if true display below Eligible for Sale/Transfer
      if(this.founderPrimaryAccountDetails[0].TotalVotingShares == null || this.founderPrimaryAccountDetails[0].TotalVotingShares == undefined || this.founderPrimaryAccountDetails[0].TotalVotingShares == "" || this.founderPrimaryAccountDetails[0].TotalVotingShares == 0)
        this.hasTotalVotingShares = false;
        else
        this.hasTotalVotingShares = true;
  
      //check for Primary Release Schedule
      if(this.founderPrimaryReleaseSched.length == 0){
        this.hasReleaseSchedPrimary = false;
      }
      else{
        for(let i=0;i<this.founderPrimaryReleaseSched.length;i++){
          this.founderPrimaryReleaseTotal = this.founderPrimaryReleaseTotal + this.founderPrimaryReleaseSched[i].Amount;
          this.hasReleaseSchedPrimary = true;
        }        
      }      
      this.arrangeFounderDetailsDataTransferee(dropdownChecker);
    } catch(error) {
      this.errorDetailsTab = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "arrangeFounderDetailsDataPrimary");
      error.message += errorLocation;
      throw error;
    }
  }

  async arrangeFounderDetailsDataTransferee(dropdownChecker){
    try {            
      this.founderTransfereeReleaseTotal = 0;
      this.founderTransfereeDropDownChecker = this.founderTransfereeDropDown[dropdownChecker];   
      if(this.founderTransfereeDetails != null || this.founderTransfereeDetails != undefined){
        //Check for Trading Window        
        if(this.founderTransfereeDetails[0].TradingWindow == null || this.founderTransfereeDetails[0].TradingWindow == undefined || this.founderTransfereeDetails[0].TradingWindow == "")
          this.hasTradingWindowTransferee = false;
        else
          this.hasTradingWindowTransferee = true;

          //Check for Pricing Method
        if(this.founderTransfereeDetails[0].PricingMethod == null || this.founderTransfereeDetails[0].PricingMethod == undefined || this.founderTransfereeDetails[0].PricingMethod == ""){
          this.hasPricingMethodTransferee = false;
          this.hasRedemptionMethodTransferee = false;
        }
        else{
          this.hasPricingMethodTransferee = true;
          this.hasRedemptionMethodTransferee = true;
          if(this.founderTransfereeDetails[0].RedemptionMethod == 1)
            this.founderTransfereeDetails[0].RedemptionMethod = 'Cash'
          else if(this.founderTransfereeDetails[0].RedemptionMethod == 2)
            this.founderTransfereeDetails[0].RedemptionMethod = 'Share'
        }

        //Check for Total Voting Shares, if true display below Eligible for Sale/Transfer
        if(this.founderTransfereeDetails[0].TotalVotingShares == null || this.founderTransfereeDetails[0].TotalVotingShares == undefined || this.founderTransfereeDetails[0].TotalVotingShares == "" || this.founderTransfereeDetails[0].TotalVotingShares == 0)
          this.hasTotalVotingSharesTransferee = false;
        else
          this.hasTotalVotingSharesTransferee = true;
      }
       
      if(this.founderTransfereeReleaseSched == undefined){
        this.hasReleaseSchedTransferee = false;
      }else{
        for(let i=0;i<this.founderTransfereeReleaseSched.length;i++){          
          this.founderTransfereeReleaseTotal = this.founderTransfereeReleaseTotal + this.founderTransfereeReleaseSched[i].Amount;        
          this.hasReleaseSchedTransferee = true;
        }        
      }
    
    } catch(error) {
      this.errorDetailsTab = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "arrangeFounderDetailsDataTransferee");
      error.message += errorLocation;
      throw error;
    }
  }

  changeTransfereeAccount(optNum){
    try {
      let checker = 0;
      for(let i=0;i<this.founderTransfereeDropDown.length;i++){
        if(this.founderTransfereeDropDown[i].OptNum == optNum)
          checker = i;
        }     
      this.getFounderDetailsTransferee(optNum, this.programDetails.GrantNum, checker);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "changeTransfereeAccount");
      error.message += errorLocation;
      throw error;
    }
  }


  selectTransactHistory(optNum, checker){
    try {
     
      if(this.user.OptNum == optNum){
        this.grantInd = 1;
      }else{
        this.grantInd = 0;
      }
      if(checker == 1)
      this.currentSalesHistOptNum = +optNum;
      else
      this.currentSalesHistOptNum = this.currentSalesHistOptNum;
  
      if(Object.keys(this.transactionHistList).length>0){
       let temp = this.transactionHistList.find(i => i.OptNum === this.currentSalesHistOptNum )
        this.transactAcctName = temp.AccountName;
      }
      if(this.transactionHistList[0].OptNum == this.currentSalesHistOptNum){
        this.votingDisplayChecker = true;
        this.votingShareChecker = false;
      }
      else{
        this.votingDisplayChecker = false;
        this.votingShareChecker = false;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectTransactHistory");
      error.message += errorLocation;
      throw error;
    }
  }

  viewFounderAwardSalesHist(){
    try {
      this.dateErrorChecker = false;
      this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
      this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
      let tempDateTo = this.dateTo.replace(/'/g, '');
      let tempDateFrom = this.dateFrom.replace(/'/g, '');
  
      tempDateTo = moment(tempDateTo).format("YYYY-MM-DD");
      tempDateFrom = moment(tempDateFrom).format("YYYY-MM-DD");
  
      if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
        this.dateErrorChecker = true; 
      }
      else if (tempDateTo < tempDateFrom && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else{
      this.salesTableLoading = true;
      this.totalSharesSalesHistory = 0;
      this.totalProceedsSalesHistory = 0;
      this.getSalesHistoryData(this.datePeriod, this.currentSalesHistOptNum, 1);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewFounderAwardSalesHist");
      error.message += errorLocation;
      throw error;
    }
  }

  selectVotingShare(checker){
    try {
      if(checker == true)
      this.votingShareChecker = false;
    else
      this.votingShareChecker = true;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectVotingShare");
      error.message += errorLocation;
      throw error;
    }
  } 

  selectDateFrom(value: Date) { // Angular Material Datepicker
    try {
      let dateEvent = moment(value).format("YYYY-MM-DD");
      this.dateFromChecker = dateEvent;
      this.dateFrom = "'" + dateEvent + "'";
      this.dateFromClicked = true;
      this.dateFromHolder = dateEvent;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
      error.message += errorLocation;
      throw error;
    }
  }

  selectDateTo(value: Date) { // Angular Material Datepicker
    try {
      let dateEvent = moment(value).format("YYYY-MM-DD");
      this.dateToChecker = dateEvent;
      this.dateTo = "'" + dateEvent + "'";
      this.dateToClicked = true;
      this.dateToHolder = dateEvent;
   } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
      error.message += errorLocation;
      throw error;
    }
  }

  setPeriodAllDates(tab) {
    try {
      setTimeout(()=>{ this.inContentIcon() }, 50);
      if(tab.nextId == "sales-history-tab") {
        this.disableDatePicker = true;
        this.dateFrom = "' '";
        this.dateTo = "' '";
        this.periodSelected = this.allDates;
        this.datePeriod = 4;
        this.dateFromClicked = false;
        this.dateToClicked = false;
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker   
        this.dateErrorChecker = false;
        this.salesTableLoading = true;
        this.getSalesHistoryData(4, this.user.OptNum, 0);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "setPeriodAllDates");
      error.message += errorLocation;
      throw error;
    }
  }

  selectDatePeriod(period) {
    try {

      if(period != 5){
        $(".MDatePicker input").css("background-color","#d3d0d0")
      }else{
        $(".MDatePicker input").css("background-color","white")
      }

      this.datePeriod = period;

      if(this.datePeriod == 1) { // year to date
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.yearToDate;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = dateToEvent;
        // this.dateToPlaceholder = " ";

        currentDate.setMonth(0, 1);
        let yearToDate = currentDate;
        let dateFromEvent = this.datePipe.transform(yearToDate, "MM'/'dd'/'yyyy");
        this.dateFrom = dateFromEvent; 

      } else if(this.datePeriod == 2) { // last year
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Angular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.lastYear;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = dateToEvent;
        // this.dateToPlaceholder = " "; // Ngb Datepicker

        currentDate.setFullYear(currentDate.getFullYear() - 1);
        let lastYear = currentDate;
        let dateFromEvent = this.datePipe.transform(lastYear, "MM'/'dd'/'yyyy");
        this.dateFrom = dateFromEvent;
 
      } else if(this.datePeriod == 3) { // last 3 years
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Amgular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.last3Years;
        this.dateFromClicked = false;
        this.dateToClicked = false;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = dateToEvent;        

        currentDate.setFullYear(currentDate.getFullYear() - 3);
        let lastThreeYear = currentDate;
        let dateFromEvent = this.datePipe.transform(lastThreeYear, "MM'/'dd'/'yyyy");
        this.dateFrom = dateFromEvent;
    
      } else if(this.datePeriod == 4) { // all dates
        this.dateFromInput = null; // Angular Material Datepicker
        this.dateToInput = null; // Amgular Material Datepicker
        this.disableDatePicker = true;
        this.periodSelected = this.allDates;
        this.dateFromClicked = false;
        this.dateToClicked = false;

        this.dateTo = "' '";        
        this.dateFrom = "' '";
        
      } else { // period = 5, custom
        this.disableDatePicker = false;      
        this.periodSelected = this.custom;
        
        let currentDate = new Date();
        let inputValue = moment(currentDate).format("YYYY-MM-DD");

        this.dateTo = "'" + inputValue + "'";
        if(this.dateToClicked) {          
          this.dateToInput = new Date(this.dateToHolder);
        } else {
          this.dateToInput = currentDate;        
        }
        this.dateToPlaceholder = moment(inputValue).format("DD MMM YYYY");

        this.dateFrom = "'" + inputValue + "'";
        if(this.dateFromClicked) {          
          this.dateFromInput = new Date(this.dateFromHolder); // Angular Material Datepicker
        } else {
          this.dateFromInput = currentDate; // Angular Material Datepicker     
        }        
  
        this.dateToChecker = inputValue;
        this.dateFromChecker = inputValue; 
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDatePeriod");
      error.message += errorLocation;
      throw error;
    }
  }

  //SALE HISTORY DATE PICKER END//

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

/**********************************************************************************
   * Method Name: setTabIndex                                                                
   * Description: Manipulate Tab Index when Tab is active/inactive                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 03/27/19             John Paul Fernando          Initial draft; browser detection   
   *********************************************************************************/
setTabIndex(){
  try {
    $(document).ready(function(){
      var detailsTab = $("#detailsTab").hasClass("active");
      var salesHistory = $("#sales-history-tab").hasClass("active");
      var learnMore = $("#learnMore").hasClass("active");
    
      detailsTab ? $("#detailsTab").attr("tabIndex","-1") : $("#detailsTab").attr("tabIndex","0");
      salesHistory ? $("#sales-history-tab").attr("tabIndex","-1") : $("#sales-history-tab").attr("tabIndex","0");
      learnMore ? $("#learnMore").attr("tabIndex", "-1") : $("#learnMore").attr("tabIndex", "0");
    })
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "setTabIndex");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
  Method Name: eorCommonQuestions
  Description: Get 2 Sections for EOR Learn More Tab

  #     Date            Modified by                  Description
1     4/22/2019     Iris Jane R. Manloloyo(IJM)    Initial draft
  **********************************************************************************************/
getCommonQuestions(){
  try {
    this.bodyData[5] = PageSection.foundersCommonQuestions;
    this.bodyData[6] = 1;
  
    //For EOR Learn More Common Questions 
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: 
      try {
        let founderCommonQuestions = JSON.parse(data.body);
        let founderCommonQuestionsData;
        
        if(founderCommonQuestions.data.length > 2) { // length > {}
        founderCommonQuestionsData = founderCommonQuestions.data;

          if(founderCommonQuestionsData.length > 0){
            this.foundersCommonQuestionsContents = founderCommonQuestionsData;

            this.foundersCommonQuestionsContents[0]["Body_Desc"] = this.foundersCommonQuestionsContents[0]["Body_Desc"].split('<a href="google.com" target="_blank" rel="noopener" title="trylink1">').join('<a href="google.com" target="_blank" rel="noopener" title="trylink1" aria-label="Click Here Link - Founders">');
            this.foundersCommonQuestionsContents[3]["Body_Desc"] = this.foundersCommonQuestionsContents[3]["Body_Desc"].split('<a id="managementcontactlink" href="#">').join('<a id="managementcontactlink1" href="#">');
            for(let i=0; i<this.foundersCommonQuestionsContents.length; i++){
              if(this.foundersCommonQuestionsContents[i]["Body_Desc"].indexOf("<u>") != -1){
                this.foundersCommonQuestionsContents[i]["Body_Desc"] = this.foundersCommonQuestionsContents[i]["Body_Desc"].split("<u>").join("<span style='text-decoration: underline;'>");
                this.foundersCommonQuestionsContents[i]["Body_Desc"] = this.foundersCommonQuestionsContents[i]["Body_Desc"].split("</u>").join("</span>");
              }
              if(this.foundersCommonQuestionsContents[i]["Body_Desc"].indexOf('<font color="#0000ff">') != -1){
                this.foundersCommonQuestionsContents[i]["Body_Desc"] = this.foundersCommonQuestionsContents[i]["Body_Desc"].replace('<font color="#0000ff">','');
                this.foundersCommonQuestionsContents[i]["Body_Desc"] = this.foundersCommonQuestionsContents[i]["Body_Desc"].replace('</font>','');
              }

            }
          }
          else{
            this.foundersCommonQuestionsContents = null;
          }
          
        }
    
        this.getGuidebooksTaxImp();
      } catch(error) {
        this.errorCommonQuestions = true;
        this.getGuidebooksTaxImp();
        let errorLocation = this.errorLocation.replace("methodName", "getCommonQuestions");
        error.message += errorLocation;
        throw error;
      }
      }, error => {
        this.errorCommonQuestions = true;
        this.getGuidebooksTaxImp();
        let errorLocation = this.errorLocation.replace("methodName", "getCommonQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
  } catch(error) {
    this.errorCommonQuestions = true;
    this.getGuidebooksTaxImp();
    let errorLocation = this.errorLocation.replace("methodName", "getCommonQuestions");
    error.message += errorLocation;
    throw error;
  }
}

  /*********************************************************************************************
  Method Name: eorToolsandReports
  Description: Get 2 Sections for EOR Learn More Tab

  #     Date            Modified by                  Description
1     4/22/2019     Iris Jane R. Manloloyo(IJM)    Initial draft
2     05/22/2019    KMN                            Bug 319717 fix
  **********************************************************************************************/

getGuidebooksTaxImp(){
  try {
    this.bodyData[5] = PageSection.foundersGuidebooksTaxImp;
    this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: 
        try {
          let foundersGuidebooksTaxImp = JSON.parse(data.body);

          let eorToolsReportsContent;
          
          if(foundersGuidebooksTaxImp.data) { // length > {}
          eorToolsReportsContent = foundersGuidebooksTaxImp.data;

            if(eorToolsReportsContent.length > 0){
              this.foundersGuidebooksTaxImpContents = eorToolsReportsContent[0].Body_Desc;

            }
            else{
              this.foundersGuidebooksTaxImpContents = null;
            }
            this.getfoundersCountryAward();
          }
        } catch(error) {
          this.errorGuideBooks = true;
          this.getfoundersCountryAward();
          let errorLocation = this.errorLocation.replace("methodName", "getGuidebooksTaxImp");
          error.message += errorLocation;
          throw error;
        }
        }, error => {
          this.errorGuideBooks = true;
          this.getfoundersCountryAward();
          let errorLocation = this.errorLocation.replace("methodName", "getGuidebooksTaxImp");
          error.error.message += errorLocation;
          throw error.error;
        });
  } catch(error) {
    this.errorGuideBooks = true;
    this.getfoundersCountryAward();
    let errorLocation = this.errorLocation.replace("methodName", "getGuidebooksTaxImp");
    error.message += errorLocation;
    throw error;
  }
  }

   /*****************************************************************************************
   * Method Name: getesppCountryAward                                                                 
   * Description: Gets the content for prospectus document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getfoundersCountryAward() {
    try {
      this.bodyData[5] = PageSection.foundersCountryAward;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let countryAwardData = JSON.parse(data.body);
 
          if(countryAwardData.data) { // length > {}
            let countryAwardBody = countryAwardData.data;
    
            if(countryAwardBody.length > 0) {
              this.foundersCountryAwardContent = countryAwardBody[0].Body_Desc;
            } else {
              this.foundersCountryAwardContent = null;
            }
          }
    
          this.getToolsAndReports();
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getesppCountryAward");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getesppCountryAward");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getesppCountryAward");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getToolsAndReports                                                                 
   * Description: Gets the content for tools and reports from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getToolsAndReports() {
    try {
      this.bodyData[5] = PageSection.foundersToolsandReports;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let toolsAndReportsData = JSON.parse(data.body);
  
          if(toolsAndReportsData.data) { // length > {}
            let toolsAndReportsBody = toolsAndReportsData.data;

            if(toolsAndReportsBody.length > 0) {
              this.foundersToolsandReports = toolsAndReportsBody[0].Body_Desc;

              this.foundersToolsandReports = this.foundersToolsandReports.replace('<b><u>Reports</u></b>','<span style="font-weight: 500; text-decoration: underline;">Reports</span>');
              this.foundersToolsandReports =  this.foundersToolsandReports.split('<u>').join('<span style="text-decoration: underline;">');
              this.foundersToolsandReports =  this.foundersToolsandReports.split('</u>').join('</span>');
              
            } else {
              this.foundersToolsandReports = null;
            }
            setTimeout(()=>{ this.inContentIcon() }, 50);
            this.LearnMoreLoading=false;
          }
        } catch(error) {
          this.errorToolsAndReports = true;
          let errorLocation = this.errorLocation.replace("methodName", "getToolsAndReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorToolsAndReports = true;
        let errorLocation = this.errorLocation.replace("methodName", "getToolsAndReports");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorToolsAndReports = true;
      let errorLocation = this.errorLocation.replace("methodName", "getToolsAndReports");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let id: string = event.target.id;
      let srcElement: HTMLImageElement = event.srcElement;
      let target: string = event.target.target;

      if (target == ""){
        target = "_self";
      }

      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != ""){
        if(href.indexOf("fileName") != -1){ //if href value is a file
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          // href = href.split("/Default.aspx")[0]; 
          href = event.target.innerText;
          event.preventDefault();

          href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
        } else if(href.indexOf("#") != -1 && id != "") {
          if(id.indexOf("managementcontactlink") != -1) {
            window.open(this.globals.currentWebUrl + "/contactspage", "_blank");
            return false; // prevents the execution of the link and pop up blocker
          } else {
            window.open(this.globals.currentWebUrl, "_blank");
            return false; // prevents the execution of the link and pop up blocker
          }
        } else{
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }
      }else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
        event.preventDefault();
        window.open(href, target);
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : ""

    window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
  }

  /*********************************************************************************************
Method Name: getFile
Description: Get file data

#     Date            Modified by             Description
1     04/22/19        Katrina Narag         Initial Draft
2     05/03/19        KMN                   Add method for no file exists
**********************************************************************************************/
getFile(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
    
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file;
    }
    
    this.staticContentService.getFile(fileName, "MD").subscribe(data => { 
      try {
        let content = JSON.parse(data.body);
    
        if(content == null || content == ""){
          this.staticContentService.noFileExists(fileName);
        }else{
          let file = content.data;
          this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
          this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
          });
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    }); 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
}

getStepTwoContent() {
  try {
    this.bodyData[5] = PageSection.esppTransacStepTwo + this.bodyData[0];
    this.bodyData[6] = 0;
  
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let stepTwoData = JSON.parse(data.body);

        if(stepTwoData.data) { // length > {}
          let stepTwoBody = JSON.parse(stepTwoData.body);
     
          if(stepTwoBody.length > 0) {
            this.stepTwoContent = stepTwoBody.data;
     
            for(let i = 0; i < stepTwoBody.data.length; i++) {
              if(this.stepTwoContent[i].File_Type == "pdf") {
               this.stepTwoContent[i].fileIcon = FileType.pdf;
              } else if(this.stepTwoContent[i].File_Type == "docx") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "doc") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "xls") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "xlsx") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "pptx") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "ppt") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "zip") {
               this.stepTwoContent[i].fileIcon = FileType.zip;
              } 
            }
          } else {
            this.stepTwoContent = null;
          }
        }
     
        this.getAllCountryForms();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
        error.message += errorLocation;
       throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
  Method Name: getAllCountryForms
  Description: Gets all the available country forms.

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getAllCountryForms() {
  try {
    this.bodyData[5] = PageSection.esppTransacAllCountryForms;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
     try {
      let allCountryFormsData = JSON.parse(data.body);
     
      if(allCountryFormsData.data) { // length > {}
        let allCountryFormsBody = allCountryFormsData.data;
  
        if(allCountryFormsBody.length > 0) {
          this.allCountryFormsContent = allCountryFormsBody[0].Body_Desc;
          setTimeout(() => { this.inContentIcon() }, 500);
        } else {
          this.allCountryFormsContent = null;
        }
      }
     } catch(error) {
       let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
       error.message += errorLocation;
       throw error;
     }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths

*****************************************************************************************/
async inContentIcon(){
  $("[href='#collapseCQIndex0']").attr("aria-expanded","true");
  $("[id='collapseCQIndex0']").attr("class","collapse show");
  $("[id='learnMore']").attr("aria-label","Learn More for Founders award");
  $("[id='sales-history-tab']").attr("aria-label","Sales History - Founders Award");

    //matDatepicker mod
    $(".mat-input-infix span").remove();
    $(".mat-input-underline span").remove();
    $(".mat-input-subscript-wrapper").remove();
    $(".mat-input-underline").css("height","0");
    $(".mat-input-element").addClass("datepicker-input");
    $(".mat-input-element").css("height","30px");
    $(".mat-input-element").css("width","173px");
    $(".mat-input-element").css("margin-bottom","4px");
    $(".mat-input-element").css("margin-left","21px");
    $(".mat-input-element").css("text-indent","10px");
    $(".mat-input-element").css("background-color","#d3d0d0");
    $(".mat-input-infix input").css("height","30px");
    $(".mat-input-infix input").css("width","173px");
    $(".mat-input-infix input").css("margin-bottom","2.8px");
    $(".mat-input-infix input").css("background-color","#d3d0d0");
    $(".mat-form-field-infix").css("border-top","0");
    $(".mat-datepicker-toggle button").removeClass("mat-icon-button").addClass("btn btn-outline-secondary calendar");
    $(".mat-datepicker-toggle button span").remove();
    $(".mat-datepicker-toggle button div").remove();
    $(".mat-datepicker-toggle button").removeAttr("mat-icon-button");
    $(".mat-form-field-infix").css("width","0px");
    $(".mat-datepicker-toggle button").css("width","50px");
    $(".mat-datepicker-toggle button").css("height","32px");
    $(".mat-datepicker-toggle button").css("margin-left","0px");
    $(".mat-datepicker-toggle button").css("margin-bottom","-3px");
    $(".mat-datepicker-toggle button").css("background-image","url(/assets/calendar.png)");
    $(".mat-datepicker-toggle button").css("background-repeat","no-repeat");
    $(".mat-datepicker-toggle button").css("background-size","23px");
    $(".mat-datepicker-toggle button").css("background-position","center");
    $(".mat-input-infix input").css("padding-left","16px");
  //end of matDatepicker Mod

  this.staticContentService.inContentIconAppend();
}

  /*****************************************************************************************
    * Method Name: onTabChange                                                                 
    * Description: Sets timeout for incontenticon method when a tab is selected.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/26/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
onTabChange() {
  try {
    setTimeout(()=>{ this.inContentIcon() }, 500);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "onTabChange");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Dalemark P. Suquib                    Initial draft
  * 2    05/28/19             Dalemark P. Suquib                    Bug fix: 332322 - 'Reports" was redirected to Reports Page even though there is no link -Regression (Founder Award detail)
  *****************************************************************************************/
navToReportsPage(event) {
  try {
    let href = event.target.href;
    let id: number;
    let reportId: object;

    if(href != undefined) {
      if(href.indexOf("reportid") != -1) {
        id = href.substring(href.lastIndexOf("=") + 1);
        reportId = {'ReportTypeId': id};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
        window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
        return false;
      }else if(href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      } else {
        event.preventDefault();
        window.open(href, "_blank");
      }
    } 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToReportsPage");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navtoSpecificReportsPage                                                                 
  * Description: Navigate to specific report
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/05/19             KMN                                   Bug 332329 fix 
  *****************************************************************************************/
 navtoSpecificReportsPage(id){
  let reportId: object;

  reportId = {'ReportTypeId': id};
  sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
  window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
}


/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Founderdetails") != -1 && this.user != null) {
                webPage = "Founderdetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
     }
    });
  }
  getGlobalID() {
    try {
      this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
        try {
          this.globalIDs = data[0];

          if (this.globalIDs.length == 0) {
            this.phSBGlobalID = true;
            this.globalIDNum1 = ""         
          } else if(this.globalIDs.length == 1){
            this.phSBGlobalID = true;
            if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9) { 
                  this.globalIDNum1 = "Your SSN";
                } else {
                  this.globalIDNum1 = this.globalIDs[0].ParticipantNbr; 
                }
          }
          else{
            if (this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9) {
              this.phSBGlobalID = true;
              this.globalIDNum1 = "Your SSN"
            } else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) == 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) == 9){
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) Your SSN"
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
            else{
              this.phSBGlobalID = false;
              this.globalIDNum1 = "(1) " + this.globalIDs[0].ParticipantNbr;
              this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
            }
  
          }
  
          // if(this.globalIDs.length == 0) {
          //   this.phSBGlobalID = true;
          //   this.globalIDNum1 = "";
          // } else if(this.globalIDs.length == 1) {
          //   this.phSBGlobalID = true;
    
          //   if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
          //     this.globalIDNum1 = "Your SSN";
          //   } else {
          //     this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
          //   }
          // } 

        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
      error.message += errorLocation;
      throw error;
    }
   }
  

   checkBrokersInfo(broker){
    event.stopPropagation();
   

    if(this.ubsLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    
    if(this.sbLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    
    if(this.bigLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    
    if(this.ncbLoaded == true)
      this.resourceLoading = false;
    else
      this.resourceLoading = true;
    

    
    this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      let brokerAccounts = data[0];
      if(brokerAccounts.length > 0) {

        for(let i = 0; i < brokerAccounts.length; i++) {
  if(broker == "MS"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });

    this.brokerHeader = "Morgan Stanley";
    
    this.getGlobalID();

    this.displayPersonalUbs = false;
    this.displayPersonalBig = false;
    this.displayPersonalNcb = false;

    this.bodyData[5] = PageSection.sb;
    this.bodyData[6] = 1;
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
      
        let msData = JSON.parse(data.body);

        let msBody;

        if(msData.data) { // length > {}
        msBody = msData.data;

          if (msBody.length > 0){
            this.sbDesc = msBody[0].Body_Desc;
            this.displayPersonalSb = true;
            }
          else {
            this.displayPersonalSb = false;
            this.sbDesc = "";
          } 
        }


      this.bodyData[5] = PageSection.sbDoc;
      this.bodyData[6] = 0;

      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      let msDocData = JSON.parse(data.body);

      let msDocBody;
      
      if(msDocData.data) { // length > {}
                      msDocBody = msDocData.data;
                      if(msDocBody.length > 0) {
                        this.sbDoc = msDocBody;
      
                        for(let i=0; i<this.sbDoc.length; i++){
                          this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                        }
                      }
                      else{
                        this.sbDoc = null;
                      }
                    }
                
      });
      this.sbLoaded = true;
  this.resourceLoading = false;
    });

  }else if(broker == "UBS"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    

    this.brokerHeader = "UBS Financial Services Inc";
    this.displayPersonalSb = false;
    this.displayPersonalBig = false;
    this.displayPersonalNcb = false;

  this.ubsAccount = brokerAccounts[i].Account;
  this.bodyData[5] = PageSection.ubs;
  this.bodyData[6] = 1;
  

  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    let ubsData = JSON.parse(data.body);

    let ubsBody;

    if(ubsData.data) { // length > {}
    ubsBody = ubsData.data;
    if(ubsBody.length > 0) {
      this.ubsDesc = ubsBody[0].Body_Desc;
      this.displayPersonalUbs = true;
    }
    else {
    this.displayPersonalUbs = false;
      this.ubsDesc = "";
    }
  }

  this.bodyData[5] = PageSection.ubsDoc;
  this.bodyData[6] = 0;
  
  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

    let ubsDocData = JSON.parse(data.body);

    let ubsDocBody;
    
    if(ubsDocData.data) { // length > {}
        ubsDocBody = ubsDocData.data;
        if(ubsDocBody.length > 0){
          this.ubsDoc = ubsDocBody;
  
          for(let i=0; i<this.ubsDoc.length; i++){
            this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
          }
        }
        else{
          this.ubsDoc = null;
        }
      }
  });
  this.ubsLoaded = true;
  this.resourceLoading = false;
  });
  }else if(broker == "BIG"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    

    this.brokerHeader = "Banco de Investimento Global";

    this.displayPersonalSb = false;
    this.displayPersonalUbs = false;
    
    this.displayPersonalNcb = false;

    this.bigAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.big;
    this.bodyData[6] = 1;
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    
      let bigData = JSON.parse(data.body);

      let bigBody;

      if(bigData.data) { // length > {}
      bigBody = bigData.data;
      if(bigBody.length > 0){
        this.bigDesc = bigBody[0].Body_Desc;
        this.displayPersonalBig = false;
      }else {
        this.displayPersonalBig = false;
        this.bigDesc = "";
      }
    }
    this.bigLoaded = true;
  this.resourceLoading = false;
    });

  }else if(broker == "TA"){
    (<any>$("#brokerContact")).modal({
      backdrop: 'static',
      keyboard: false
    });
    

    this.brokerHeader = "Computershare (formerly National City Bank)";
    this.displayPersonalSb = false;
    this.displayPersonalUbs = false;
    this.displayPersonalBig = false;
    

    this.ncbAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.ncb;
    this.bodyData[6] = 1;
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

      let taData = JSON.parse(data.body);

      let taBody;

      if(taData.data) { // length > {}
      taBody = taData.data;
      if(taBody.length > 0){
      this.ncbDesc = taBody[0].Body_Desc;
      this.displayPersonalNcb = true;
      }else {
        this.displayPersonalNcb = false;
        this.ncbDesc = "";
      }
    } 
    
    this.bodyData[5] = PageSection.ncbDoc;
    this.bodyData[6] = 0;         

       this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

        let taDocData = JSON.parse(data.body);
        let taDocBody;

        if(taDocData.data) { // length > {}
        taDocBody = taDocData.data;
        if(taDocBody.length > 0){
          this.ncbDoc = taDocBody;

          for(let i=0; i<this.ncbDoc.length; i++){
            this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
          }
        }else {
          this.displayPersonalNcb = false;
          this.ncbDoc = null;
        }
      }
       });
       this.ncbLoaded = true;
       this.resourceLoading = false;
    });

  }else if(broker.indexOf('/')){
    this.brokerHeader = broker;
    let splittedBroker = broker.split('/');
    for(let i = 0; i < splittedBroker.length; i++){
      if(splittedBroker[i] == "MS"){
        this.getGlobalID();
        this.bodyData[5] = PageSection.sb;
        this.bodyData[6] = 1;
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
          
            let msData = JSON.parse(data.body);

            let msBody;
  
            if(msData.data) { // length > {}
            msBody = msData.data;
  
              if (msBody.length > 0){
                this.sbDesc = msBody[0].Body_Desc;
                this.displayPersonalSb = true;
                }
              else {
                this.displayPersonalSb = false;
                this.sbDesc = "";
              } 
            }
  
  
          this.bodyData[5] = PageSection.sbDoc;
          this.bodyData[6] = 0;
  
          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          let msDocData = JSON.parse(data.body);

          let msDocBody;
          
          if(msDocData.data) { // length > {}
                          msDocBody = msDocData.data;
                          if(msDocBody.length > 0) {
                            this.sbDoc = msDocBody;
          
                            for(let i=0; i<this.sbDoc.length; i++){
                              this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                            }
                          }
                          else{
                            this.sbDoc = null;
                          }
                        }
                    
          });
          this.sbLoaded = true;
      this.resourceLoading = false;
        });
  
      }else if(splittedBroker[i] == "UBS"){
        
      this.ubsAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.ubs;
      this.bodyData[6] = 1;
      
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        let ubsData = JSON.parse(data.body);

        let ubsBody;
  
        if(ubsData.data) { // length > {}
        ubsBody = ubsData.data;
        if(ubsBody.length > 0) {
          this.ubsDesc = ubsBody[0].Body_Desc;
          this.displayPersonalUbs = true;
        }
        else {
        this.displayPersonalUbs = false;
          this.ubsDesc = "";
        }
      }
  
      this.bodyData[5] = PageSection.ubsDoc;
      this.bodyData[6] = 0;
      
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
        let ubsDocData = JSON.parse(data.body);

        let ubsDocBody;
        
        if(ubsDocData.data) { // length > {}
            ubsDocBody = ubsDocData.data;
            if(ubsDocBody.length > 0){
              this.ubsDoc = ubsDocBody;
      
              for(let i=0; i<this.ubsDoc.length; i++){
                this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
              }
            }
            else{
              this.ubsDoc = null;
            }
          }
      });
      this.ubsLoaded = true;
      this.resourceLoading = false;
      });
      }else if(splittedBroker[i] == "BIG"){
        
        this.bigAccount = brokerAccounts[i].Account;
        this.bodyData[5] = PageSection.big;
        this.bodyData[6] = 1;
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        
          let bigData = JSON.parse(data.body);

          let bigBody;
  
          if(bigData.data) { // length > {}
          bigBody = bigData.data;
          if(bigBody.length > 0){
            this.bigDesc = bigBody[0].Body_Desc;
            this.displayPersonalBig = false;
          }else {
            this.displayPersonalBig = false;
            this.bigDesc = "";
          }
        }
        this.bigLoaded = true;
      this.resourceLoading = false;
        });
  
      }else if(splittedBroker[i] == "TA"){
       
        this.displayPersonalSb = false;
        this.displayPersonalUbs = false;
        this.displayPersonalBig = false;
        
  
        this.ncbAccount = brokerAccounts[i].Account;
        this.bodyData[5] = PageSection.ncb;
        this.bodyData[6] = 1;
        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
          let taData = JSON.parse(data.body);

          let taBody;
  
          if(taData.data) { // length > {}
          taBody = taData.data;
          if(taBody.length > 0){
          this.ncbDesc = taBody[0].Body_Desc;
          this.displayPersonalNcb = true;
          }else {
            this.displayPersonalNcb = false;
            this.ncbDesc = "";
          }
        } 
        
        this.bodyData[5] = PageSection.ncbDoc;
        this.bodyData[6] = 0;         
  
           this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
  
            let taDocData = JSON.parse(data.body);

            let taDocBody;
  
            if(taDocData.data) { // length > {}
            taDocBody = taDocData.data;
            if(taDocBody.length > 0){
              this.ncbDoc = taDocBody;
  
              for(let i=0; i<this.ncbDoc.length; i++){
                this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
              }
            }else {
              this.displayPersonalNcb = false;
              this.ncbDoc = null;
            }
          }
           });
           this.ncbLoaded = true;
           this.resourceLoading = false;
        });
    }
  }
        (<any>$("#brokerContact")).modal({
          backdrop: 'static',
          keyboard: false
        });
  
        }
      }
    }
  });
}

  fileImage(filyType){
    if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
      this.fileTypeImagePath = '/assets/pdf.svg';
      this.fileTypeAlt = "PDF File"
      return true;
    }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
      this.fileTypeImagePath = '/assets/zip.svg';
      this.fileTypeAlt = "ZIP File"
      return true;
    }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
      this.fileTypeImagePath = '/assets/xls.svg';
      this.fileTypeAlt = "XLS File"
      return true;
    }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
      this.fileTypeImagePath = '/assets/docx.svg';
      this.fileTypeAlt = "DOC File"
      return true;
    }
   }

   /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
  collapseAccordion(index) {
    let className = document.getElementById("collapseCQIndex" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.foundersCommonQuestionsContents.length; i++) {
        document.getElementById("collapseCQIndex" + i).className = "collapse";
      }
    }
   }

}