
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { EorService } from './EorService';
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from 'jquery';
import { PageSection, FileType, MyHoldingsPagesResources, tScript } from '../services/enumerations/enumerations';
import { StaticContentService } from '../services/StaticContentService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { DomSanitizer } from '@angular/platform-browser';

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-eorpage',
  templateUrl: './eorpage.component.html',
  styleUrls: ['./eorpage.component.css']
})
export class EorpageComponent implements OnInit {
  loading = true;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd: any = this.user.CurrencyCd;//.replace(/\s/g, "");

  eorData: any;
  eorDataCurrentShareHoldings: any;
  eorDataShareExcessShortFall: any;
  eorValues: any;
  GracePeriodExpirationDate: any;
  FairMarketValue: any;
  belowInd: boolean;
  // eorTotal: any;

 keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));
 errorLocation: string = " + [Component: Eorpage, Method: methodName]";
 errorEorDetails: boolean;
 errorCommonQuestions: boolean;
 errorToolsAndReports: boolean;
 errorLearnMoreTab: boolean;

 //Learn More Tab
 bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
 eorCommonQuestionsContents: any;
 eorToolsAndReports: any;
 allCountryFormsContent: string;
 stepTwoContent: object;
//  errorLocation: string = " + [Component: Homepage, Method: methodName]";

// Tooltip
equityOwnershipRequirement: string = tScript.equityOwnershipRequirement;
gracePeriodExpirationDate: string = tScript.gracePeriodExpirationDate;
erroreorBasedCompensation: boolean;
eorBasedCompensation: any;
localCurrency: any;
errorlocalCurrency: boolean;
fairMarketValue: any;
errorfairMarketValue: boolean;

commonQAccordionCount: number = 1; // 1 accordion is initially expanded
UBSlink: any;
MSlink: any;
envSetting: any;

  constructor(private sanitizer:DomSanitizer, private router: Router, private globals: Globals, private eorservice: EorService,  private staticContentService: StaticContentService, private errorNotificationService: ErrorNotificationService) { }
 // this.staticContentService.navState$.subscribe( (state)=> this.state = state ); 

  ngOnInit() {

    let hasEORChecker = sessionStorage.getItem("hasEOR");
    if(hasEORChecker != "1") this.router.navigate(['homepage']);

    this.checkErrorMessage();
    // setTimeout(()=>{ this.loading = false }, 1000);
    this.eorMethod();
    this.eorCommonQuestions();
    this.getTooltipData();
    // this.eorToolsAndReports();
    sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest));
    sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Homepage"));
    this.brokersSSO();
  }

  removeTags(message){
    let tagSelector = /<[^>]*>/g;
    message = (message.replace(tagSelector, "")).replace(/&#160;/g, " ");
    return message;
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
  if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
    let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

    if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
    }else{
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
    }
  }else{
    if(this.bodyData != null){
      if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }
  }
}

  getTooltipData(){
    try {
      this.bodyData[5] = PageSection.eorBasedCompensation;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.eorBasedCompensation = contentBody[0].Body_Desc;  
            }
            else{
              this.eorBasedCompensation = null;
            }
          }          
        } catch(error) {
          this.erroreorBasedCompensation = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTooltipData");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.erroreorBasedCompensation = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getTooltipData");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.erroreorBasedCompensation= true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
      error.message += errorLocation;
      throw error;
    }

    try {
      this.bodyData[5] = PageSection.localCurrency;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.localCurrency = contentBody[0].Body_Desc;  
            }
            else{
              this.localCurrency = null;
            }
          }          
        } catch(error) {
          this.errorlocalCurrency = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTooltipData");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorlocalCurrency = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getTooltipData");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorlocalCurrency = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
      error.message += errorLocation;
      throw error;
    }

    try {
      this.bodyData[5] = PageSection.fairMarketValue;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => { 
        try {
          let content = JSON.parse(data.body);  
          
          if(content.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
  
          if(content.data){
            let contentBody = content.data;
    
            if(contentBody.length > 0){
              this.fairMarketValue = contentBody[0].Body_Desc;  
            }
            else{
              this.fairMarketValue = null;
            }
          }          
        } catch(error) {
          this.errorfairMarketValue = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTooltipData");
          error.message += errorLocation;
          throw error;
        }
           }, error => {
            this.errorfairMarketValue = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getTooltipData");
            error.error.message += errorLocation;
            throw error.error;
           });
    } catch(error) {
      this.errorlocalCurrency = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getDepartureConsiderations");
      error.message += errorLocation;
      throw error;
    }


  }
  
  async eorMethod(){
    try {
      this.eorservice.getEor(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try {
          this.errorEorDetails = false;
          this.eorData = data[0][0];
          this.eorDataCurrentShareHoldings = Math.round(this.eorData.CurrentShareHoldings);
          this.eorDataShareExcessShortFall = Math.round(this.eorData.ShareExcessShortFall);
          let text = this.eorData.ShareExcessShortFall;
          let a = this.eorData.FairMarketValue;
          this.FairMarketValue = this.eorData.FairMarketValue;
  
          if(text  >= 0){
            this.belowInd = true;
          }else {
            this.belowInd = false;
          }
          // this.loading = false;
          this.setTabIndex();
        } catch(error) {
          this.errorEorDetails = true;
          let errorLocation = this.errorLocation.replace("methodName", "eorMethod");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorEorDetails = true;
        let errorLocation = this.errorLocation.replace("methodName", "eorMethod");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorEorDetails = true;
      let errorLocation = this.errorLocation.replace("methodName", "eorMethod");
      error.message += errorLocation;
      throw error;
    }
  }

  
  /*********************************************************************************************
   * Method Name: decimalPlaces                                                                 
   * Description: new formatting with trailing zeros                        
   *                                                                                          
   * #  Date                 Modified by                 Description                             
   * 1  02/26/2019           Katrina Narag               Initial draft
   *********************************************************************************************/
  decimalPlaces(float,length) {
    try {
      let ret = "";
      let str = float.toString();
      let array = str.split(".");
      let i;
  
      if(array.length==2) {
         ret += array[0] + ".";
         for(i=0;i<length;i++) {
            if(i>=array[1].length) ret += '0';
            else ret+= array[1][i];
         }
      }
      else if(array.length == 1) {
        ret += array[0] + ".";
        for(i=0;i<length;i++) {
           ret += '0'
        }
      }
      return ret;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "decimalPlaces");
      error.message += errorLocation;
      throw error;
    }
  }

   /*********************************************************************************************
   * Method Name: addComma                                                                 
   * Description: add comma for new formatting with trailing zeros                        
   *                                                                                          
   * #  Date                 Modified by                 Description                             
   * 1  02/26/2019           Katrina Narag               Initial draft
   * 2  02/27/2019           Ronald Luveros              Modified Logic
   *********************************************************************************************/
  addComma(num){
    try {
      let b = parseFloat(num);
      let number = (b*1).toLocaleString('en');
      return number;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "addComma");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: any) {
    try {
      if(num != 0.00 || num != 0) {
        if(value == 1) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: 2
          });
          return number;
        }
        else if(value == 0) {
          let number = (num*1).toLocaleString('en', {
            maximumFractionDigits: value
          });
          return number;
        }
        else {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: value
          });
          return number;
        }
      } else {
        if(value == 1) {
          return '0.00';
        }
        return 0;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

/**********************************************************************************
   * Method Name: filter                                                                 
   * Description: Converts the returned output into Absolute value                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 02/13/19             Jayson                      Initial draft     
   * 02/15/19             Katrina Narag(KMN)          Modify logic    
   *********************************************************************************/
filter(num){
  try {
    if (num){
      return Math.abs(num);
    }else{
      return num;
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "filter");
    error.message += errorLocation;
    throw error;
  }
}

navToPage(strLoc){
  try {
    this.router.navigate([strLoc]);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToPage");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
   * Method Name: setTabIndex                                                                
   * Description: Manipulate Tab Index when Tab is active/inactive                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 03/27/19             John Paul Fernando          Initial draft; browser detection 
   * 04/02/19             Jayson Sevilla              Modified variables for EOR Tabs  
   *********************************************************************************/
  setTabIndex(){
    try {
      $(document).ready(function(){
        var detailsTab = $("#detailsTab").hasClass("active");
        var learnMore = $("#learnMore").hasClass("active");
      
        detailsTab ? $("#detailsTab").attr("tabIndex","-1") : $("#detailsTab").attr("tabIndex","0");
        learnMore ? $("#learnMore").attr("tabIndex", "-1") : $("#learnMore").attr("tabIndex", "0");
      })
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "setTabIndex");
      error.message += errorLocation;
      throw error;
    }
  }


 /*********************************************************************************************
  Method Name: eorCommonQuestions
  Description: Get 2 Sections for EOR Learn More Tab

  #     Date            Modified by                  Description
 1     4/22/2019     Iris Jane R. Manloloyo(IJM)    Initial draft
 2     06/11/2019    KMN                            Bug 332655 fix - PWD bug
  **********************************************************************************************/
 async eorCommonQuestions(){
  try {
    this.errorCommonQuestions = false;
    this.bodyData[5] = PageSection.eorCommonQuestions;
    this.bodyData[6] = 1;
    if (this.bodyData[1] == 'Leave with pay'){
      this.bodyData[1] = 'Active';
    }
  
    //For EOR Learn More Common Questions 
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: 
      try {
        this.errorCommonQuestions = false;
        let eorCommonQuestion = JSON.parse(data.body);

        let eorCommonQuestionData;
        
        if(eorCommonQuestion.data) { // length > {}
        eorCommonQuestionData = eorCommonQuestion.data;
          if(eorCommonQuestionData.length > 0){
            this.eorCommonQuestionsContents = eorCommonQuestionData;
            for(let i=0; i<this.eorCommonQuestionsContents.length; i++){
              if(this.eorCommonQuestionsContents[i]["Body_Desc"].indexOf("<u>Base Compensation X Level Multiple<br/></u>") != -1){
                this.eorCommonQuestionsContents[i]["Body_Desc"] = this.eorCommonQuestionsContents[i]["Body_Desc"].replace("<u>Base Compensation X Level Multiple<br/></u>","<span style='text-decoration: underline;'>Base Compensation X Level Multiple<br/></span>");
              }else if(this.eorCommonQuestionsContents[i]["Body_Desc"].indexOf('<a href="https://myholdings.accenture.com/Resources/Default.aspx" target="_blank">Resources</a>') != -1){
                this.eorCommonQuestionsContents[i]["Body_Desc"] = this.eorCommonQuestionsContents[i]["Body_Desc"].replace('<a href="https://myholdings.accenture.com/Resources/Default.aspx" target="_blank">Resources</a>','<a href="https://myholdings.accenture.com/Resources/Default.aspx" aria-label="Resources" target="_blank">Resources</a>');
              }
            } 
          }
          else{
            this.eorCommonQuestionsContents = null;
          }
          
        }
          this.geteorToolsandReports();
      } catch(error) {
        this.errorCommonQuestions = true;
        this.geteorToolsandReports();
        let errorLocation = this.errorLocation.replace("methodName", "eorCommonQuestions");
        error.message += errorLocation;
        throw error;
      }
      }, error => {
        this.errorCommonQuestions = true;
        this.geteorToolsandReports();
        let errorLocation = this.errorLocation.replace("methodName", "eorCommonQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
  } catch(error) {
    this.errorCommonQuestions = true;
    this.geteorToolsandReports();
    let errorLocation = this.errorLocation.replace("methodName", "eorCommonQuestions");
    error.message += errorLocation;
    throw error;
  }
 }

  /*********************************************************************************************
  Method Name: geteorToolsandReports
  Description: Get 2 Sections for EOR Learn More Tab

  #     Date            Modified by                  Description
 1     4/22/2019     Iris Jane R. Manloloyo(IJM)    Initial draft
  **********************************************************************************************/
 
geteorToolsandReports(){
  try {
    this.errorToolsAndReports = false;
    this.bodyData[5] = PageSection.eorToolsandReports;
    this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: 
        try {
          this.errorToolsAndReports = false;
          let eorToolsReports = JSON.parse(data.body);

          let eorToolsReportsContent;
          
          if(eorToolsReports.data) { // length > {}
          eorToolsReportsContent = eorToolsReports.data;
            if(eorToolsReportsContent.length > 0){
              this.eorToolsAndReports = eorToolsReportsContent;
              // setTimeout(()=>{ this.inContentIcon() }, 500);
            }
            else{
              this.eorToolsAndReports = null;
            }

            this.loading = false;
            setTimeout(()=>{ this.inContentIcon() }, 500);
          }
        } catch(error) {
          this.errorToolsAndReports = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "geteorToolsandReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorToolsAndReports = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "geteorToolsandReports");
        error.error.message += errorLocation;
        throw error.error;
      });
  } catch(error) {
    this.errorToolsAndReports = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "geteorToolsandReports");
    error.message += errorLocation;
    throw error;
  }

  
  }


  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let srcElement: HTMLImageElement = event.srcElement;

      

      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != ""){
        if(href.indexOf("fileName") != -1){ //if href value is a file
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          // href = href.split("/Default.aspx")[0]; 
          href = event.target.innerText;
          event.preventDefault();

          href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
        } else { // for href with only filename in it
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     05/21/19        KMN                     Bug 298378 fix
  **********************************************************************************************/
  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : ""

    window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
  }

  /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  2     05/03/19        Dalemark Suquib         Added checker for content if null
  **********************************************************************************************/
 getFile(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
  
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
  
      fileName = file;
    }
  
    this.staticContentService.getFile(fileName, "MD").subscribe(data => {
      try {
        let content = JSON.parse(data.body);
  
        if(content != null) {
          let file = content.data;
    
          this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));

          this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
          });
        } else {
          this.staticContentService.noFileExists(fileName);
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
 }

 getStepTwoContent() {
  try {
    this.bodyData[5] = PageSection.esppTransacStepTwo + this.bodyData[0];
    this.bodyData[6] = 0;
  
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let stepTwoData = JSON.parse(data.body);

        if(stepTwoData.data) { // length > {}
          let stepTwoBody = stepTwoData.data;
     
          if(stepTwoBody.length > 0) {
            this.stepTwoContent = stepTwoBody.data;
     
            for(let i = 0; i < stepTwoBody.data.length; i++) {
              if(this.stepTwoContent[i].File_Type == "pdf") {
               this.stepTwoContent[i].fileIcon = FileType.pdf;
              } else if(this.stepTwoContent[i].File_Type == "docx") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "doc") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "xls") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "xlsx") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "pptx") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "ppt") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "zip") {
               this.stepTwoContent[i].fileIcon = FileType.zip;
              } 
            }
          } else {
            this.stepTwoContent = null;
          }
        }
     
        this.getAllCountryForms();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
    error.message += errorLocation;
    throw error;
  }
}

 /*********************************************************************************************
  Method Name: getAllCountryForms
  Description: Gets all the available country forms.

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
 getAllCountryForms() {
  try {
    this.bodyData[5] = PageSection.esppTransacAllCountryForms;
    this.bodyData[6] = 1;
 
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
     try {
      let allCountryFormsData = JSON.parse(data.body);
     
      if(allCountryFormsData.data) { // length > {}
        let allCountryFormsBody = allCountryFormsData.data;
  
        if(allCountryFormsBody.length > 0) {
          this.allCountryFormsContent = allCountryFormsBody[0].Body_Desc;
          setTimeout(() => { this.inContentIcon() }, 500);
        } else {
          this.allCountryFormsContent = null;
        }
      }
     } catch(error) {
       let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
       error.message += errorLocation;
       throw error;
     }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
    error.message += errorLocation;
    throw error;
  }
 }

 /*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths

*****************************************************************************************/
async inContentIcon(){
  $("[href='#collapseCQIndex0']").attr("aria-expanded","true");
  $("[id='collapseCQIndex0']").attr("class","collapse show");
  $("[href='EOR%20FAQs_FY10%20Dec%2009_FINAL.pdf']").remove();
  $("[id='learnMore']").attr("aria-label","Learn More Equity Ownership Requirements");
  
  $("u").contents().unwrap();
  this.staticContentService.inContentIconAppend();
}

  /*****************************************************************************************
    * Method Name: onTabChange                                                                 
    * Description: Sets timeout for incontenticon method when a tab is selected.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/26/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
 onTabChange() {
  try {
    setTimeout(()=>{ this.inContentIcon() },100);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "eorMethod");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
 navToReportsPage(event) {
  try {
    let innerText: string = event.target.innerText.toLowerCase();

    if(innerText == "reports") {
      this.navToPage('reportspage');
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "eorMethod");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Eorpage") != -1 && this.user != null) {
                webPage = "Eorpage";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  /**********************************************************************************
   * Method Name: checkErrorInLearnMore                                                                 
   * Description: Check if error is encountered under learn more tab                             
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 05/14/19             Dalemark P. Suquib          Initial draft        
   *********************************************************************************/
  checkErrorInLearnMore() {
    try {
      if(this.errorCommonQuestions && this.errorToolsAndReports) {
        this.errorLearnMoreTab = true;
      } else {
        this.errorLearnMoreTab = false;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkErrorInLearnMore");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
  collapseAccordion(index) {
    let className = document.getElementById("collapseCQIndex" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.eorCommonQuestionsContents.length; i++) {
        document.getElementById("collapseCQIndex" + i).className = "collapse";
      }
    }
   }

}
