import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import { jsPDF } from 'jspdf';
// import { DatePipe } from '@angular/common';
import { Globals } from '../../../../../globals';
import { SemlinkaccessService } from '../../../../../services/ea/api/SemlinkaccessService';
import { PartnerselectedComponent } from '../partnerselected.component';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';
import * as moment from 'moment';


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  optNum: number;
  documentId:any //number;
  notesDataPeopleKey: number = this.partnerSelected.partnerSelectedPeopleKey;
  notesDataLocalPN: number = this.partnerSelected.partnerSelectedLocalPN;
  notesDataPersonnelNum: number = this.partnerSelected.partnerSelectedPersonnelNum;
  notesData: any;
  hasNotesData: boolean = true;
  // date: string;
  editPartner: boolean = true;
  notes: any;
  message: string;
  loading: boolean = true;
  isError: boolean = false;
  currentDateTime: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private global: Globals,
    private semLinkAccessService: SemlinkaccessService,
    private partnerSelected: PartnerselectedComponent,
    // private datePipe: DatePipe,
    private access: SemaccessService

  ) { 
    this.currentDateTime = moment().format("MM/DD/YYYY");
    // this.date = datePipe.transform(Date.now(), "MM/dd/yyyy");
  }

  ngOnInit() {
    this.optNum = parseInt(sessionStorage.getItem("optNum"));
    this.documentId = parseInt(sessionStorage.getItem(this.global.documentID) || '{}');
    this.getAccess();
    this.partnerSelected.loadingEdit = true;

  }
  /**********************************************************************************
  * Method Name: getAccess                                                                 
  * Description: Retrieves access from a reasuable service 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         5/19/2020            Jikho Enrique                    Initial draft 
  ***********************************************************************************/
  async getAccess() {
    try {
      await this.access.getSemAccess("notes").then();
      if (this.access.role != false) {
        this.partnerSelected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerSelected.editHistory(this.documentId, this.optNum);
          this.partnerSelected.partnerSelectedInfo(this.optNum);
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            this.access.role['data'][8][0]['ROLE'] != 1 ? this.editPartner = false : "";
            this.noteData();
          } else {
            this.router.navigate(['equityadmin/eamain'])
          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } else {
        this.router.navigate(['equityadmin/eamain']);
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccess', 'notes');
      throw error;
    }
  } 
  /**********************************************************************************
  * Method Name: noteData                                                                 
  * Description: For Note data display                 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         03/16/2020           Almerie T. Dondonay             API connection
  * 2         05/07/2020           Almerie T. Dondonay            Change hardcoded URL into Reusable variable.
  ***********************************************************************************/  
 noteData() {
    let argNoteData: any = {
      "selected_optnum": this.optNum,
      "functionName": "getNotes"
    }
    this.apiService.postData(this.global.api_URL_pdsDocument,argNoteData).subscribe(data => {
      try{
        if(data){ 
          let contentBody = data.body;
          for (let key in ['data'][0]['Notes']) {
            if (['data'][0]['Notes'].hasOwnProperty(key)) {
              ['data'][0]['Notes'][key] == null ? ['data'][0]['Notes'][key] = "" : '';
            }
          }
          this.notes = contentBody['data'][0]['Notes'];
          if (contentBody['data'][0]['Notes'] == "" || contentBody['data'][0]['Notes'] == null) {
            this.hasNotesData = false;
          }
          this.loading = false;
          this.partnerSelected.loadingEdit = false;
          this.global.searchLoading = false;

        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('noteData', 'notes');
        throw error;
      }
    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message +=this.apiService.setErrorContents('noteData', 'notes');
      throw error;
    });
    
 }
/**********************************************************************************
  * Method Name:   printNotesData                                                              
  * Description: To print Document                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         03/16/2020            Almerie T. Dondonay             API connection
  ***********************************************************************************/  
 getObjSize(obj){  //returns the length of an object -JSE
   try {
     let size = 0, key;
     for (key in obj) {
       if (obj.hasOwnProperty(key)) size++;
     }
     return size;

   } catch (error) {
     this.loading = false;
     this.isError = true;
    error.message += this.apiService.setErrorContents('getObjSize', 'notes');
     throw error;
   }
  
}
  /* istanbul ignore next */
  printNotesData() {
    try {
      let doc = new jsPDF();
      let fullName;
      this.partnerSelected.partnerSelectedMiddleName != null ? fullName = this.partnerSelected.partnerSelectedFirstName + " " + this.partnerSelected.partnerSelectedMiddleName + " " + this.partnerSelected.partnerSelectedLastName : fullName = this.partnerSelected.partnerSelectedFirstName + " " + this.partnerSelected.partnerSelectedLastName;

      doc.setTextColor('#000000')
      doc.setFontSize(10);
      doc.text(this.currentDateTime, 10, 11)

      doc.setTextColor('#460173');
      doc.setFontSize(10);
      doc.text("Notes", 10, 19);

      doc.setFillColor('#460173');
      doc.rect(10, 20, 190, 20, "F");

      doc.setFontSize(15);
      doc.setTextColor('#FFFFFF');
      doc.setFont(undefined, 'bold'); 
      if(fullName.length > 20){
        var name = doc.splitTextToSize(fullName, 60);
        doc.text(name, 20, 29);
      }else{
        doc.text(fullName, 20, 32);
      }

      doc.setFontSize(13);

      doc.text('People Key #', 90, 29);
      doc.text(this.notesDataPeopleKey.toString(), 97, 34);

      doc.text('Local PN', 130, 29);
      doc.text(this.notesDataLocalPN.toString(), 130, 34);

      doc.text('Personnel #', 160, 29);
      doc.text(this.notesDataPersonnelNum.toString(), 162, 34);

      doc.setFontSize(11);
      doc.setTextColor('#000000');
      doc.setFont(undefined, 'normal');

      doc.text(this.notes, 10, 53);

      doc.text('Highly Confidential-Internal Use', 80, 290);
      doc.autoPrint({ variant: 'non-conform' });
      doc.save(fullName + '(Notes).pdf');
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('printNotesData', 'notes');
      throw error;
    }
  }
  
  disableInput(event: KeyboardEvent){
  event.preventDefault();
  }

}
