
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EorService {

  constructor(private globalVariables: Globals,private httpClient: HttpClient) { }


  getEor(Optnum): any{
    let APIurl = this.globalVariables.currentAPIUrl;
    let eorAPI = this.globalVariables.getEorAPI;
    eorAPI = eorAPI.replace("[param1]", Optnum);

    try{
        const api = APIurl + eorAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
}

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
    };
    return observableThrowError(error);
  }

}
