import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { HoldingprogramdetailsRsuService } from '../../holdingprogramdetailspage-rsu/HoldingProgramDetailsRSUService';
import { CurrencySymbol } from '../../services/enumerations/enumerations';
import { map } from 'rxjs/operators';
// import { HoldingsSummaryService } from 'src/app/holdingsummarypage/HoldingSummaryService';
import { HoldingsSummaryService } from '../../holdingsummarypage/HoldingSummaryService';


@Component({
  selector: 'app-award-details-summary',
  templateUrl: './award-details-summary.component.html',
  styleUrls: ['./award-details-summary.component.css']
})
export class AwardDetailsSummaryComponent implements OnInit {

  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = sessionStorage.getItem(this.globals.selectedCurrencyCd);
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey)); 
  
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  errorLocation: string = " + [Component: Esppdetails, Method: methodName]";

  awardName: string;
  detailsAvailableToSell: number;
  detailsAvailableToSellValue: number;
  detailsBroker: string;
  detailsNextRelease: number;
  detailsSharesReleasing: number;
  detailsGrantAccepted: number;
  detailsUnreleased: number;
  detailsReleased: number;
  detailsExpiration: number;
  detailsForfeited: number;

  yearCode: any[] = [];
  planNum: any[] = [];
  currencyCdSymbol: string = '';
  keyExecToggleValue: string;

  shareActSharesReleasing: any;
  shareActUnreleased: any;
  shareActReleased: any;
  shareActForfeited: any;
  awardSharesReleasing: any;
  awardUnreleased: any;
  awardDate: any;  

  grantID: string = "";
  psuDisplayInd: string;
  psuSharesAttained: number = 0;
  psuGePastInd: string;

  constructor(
    private globals: Globals,
    private rsuDetailsServicec: HoldingprogramdetailsRsuService,
    private errorNotificationService: ErrorNotificationService,
    private holdingProgramDetailsRSUService: HoldingprogramdetailsRsuService,
    private holdingSummaryService: HoldingsSummaryService
  ) { }

  ngOnInit() {
    if (this.currencyCd == null || this.currencyCd == "" || this.currencyCd == undefined || this.currencyCd == "null") {
      this.currencyCd = this.user.CurrencyCd;
    }
    this.currencyCd = this.currencyCd.split('"').join("");
    this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")];
    this.getPsuDetailsbyOptNum();
    // this.getProgramDetails();
  }
  
  getProgramDetails() {
    this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));

    // BACK BUTTON BUG - START
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } 
    // BACK BUTTON BUG - END

    if(this.programDetails[0] !== undefined) {      
      this.programDetails = this.programDetails[0];
    }       
    if(this.programDetails != null && this.programDetails != undefined) {

      this.awardName = this.programDetails.AwardName;       
      this.detailsAvailableToSell = this.programDetails.AvailableToSell;      
      this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;      
      this.detailsBroker = this.programDetails.BrokerId;
      this.detailsNextRelease = this.programDetails.NextRelease;
      this.shareActSharesReleasing = this.programDetails.SharesReleasing;      
      this.detailsGrantAccepted = this.programDetails.GrantAccepted;
      this.shareActUnreleased = this.programDetails.UnreleasedUnexercisable;
      this.detailsReleased = this.programDetails.ReleasedExercisableQty;
      this.shareActReleased = this.programDetails.ReleasedExercisableQty;
      this.detailsExpiration = this.programDetails.Expiration;
      this.shareActForfeited = this.programDetails.Forfeited;
      this.awardDate = this.programDetails.GrantYear;
      
      if(this.awardName.toLowerCase().indexOf('rsu') != -1)
      {         
        this.getCodeDetails();
        this.getRSUDetails();
      }     
    }
  }

  getCodeDetails() { 
    try {
      let keyExectData = JSON.parse(sessionStorage.getItem("Key Exec Toggle Data"));
      let code = keyExectData[0];
      
      this.yearCode.push(code[0]);
      this.yearCode.push(code[1]);
      this.yearCode.push(code[2]);
      this.yearCode.push(code[3]);   
      
      this.planNum.push(code[4]);
      this.planNum.push(code[5]);
      this.planNum.push(code[6]);

    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getCodeDetails");
      error.message += errorLocation;
      throw error;
    }
  }

getRSUDetails() {

  if(sessionStorage.getItem("RSU Details Data")){
    let data = JSON.parse(sessionStorage.getItem("RSU Details Data"));
    try {
        let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
        sessionStorage.setItem("Header RSU Details", JSON.stringify(sortedBody[0]));
        this.grantID = sortedBody[0].GrantID;
        // this.getPSUDetails();

        this.getheaderRSUDetails();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.message += errorLocation;
      throw error;
    }
  }
  else{
    this.rsuDetailsServicec.getRSUDetails(this.programDetails.GrantNum).pipe(map(res => res)).subscribe(data => { 
      try {
        if(Array.isArray(data) && data.length > 0) {
          sessionStorage.setItem("RSU Details Data", JSON.stringify(data));
          let sortedBody = data[0].sort((a, b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0); 
          sessionStorage.setItem("Header RSU Details", JSON.stringify(sortedBody[0]));
          this.grantID = sortedBody[0].GrantID;
          // this.getPSUDetails();

          this.getheaderRSUDetails();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.error.message += errorLocation;
      throw error.error;
    });
  }

}

  /**********************************************************************************
* Method Name: getheaderRSUDetails                                                                 
 * Description: Computation of Shares Releasing and Unreleased Shares                         
 *                                                                                          
 * #         Date                 Modified by                 Description                             
 * 1         26/Feb/19            Kim Phillip Aying           Initial draft  
 * 2         07/Dec/22            Johannah Jane Abuel         Added new computation for key exec (plan num 72 & 74), year 2023 and up
 * 3         06/08/2023           Jewel C. Caudilla           Update computation for Max/Target/Trend
 *********************************************************************************/
  getheaderRSUDetails(){
  try {
    if(this.planNum != null && this.planNum != undefined && this.planNum.length > 0) {
      if(this.programDetails.PlanNum == Number(this.planNum[0].OI) || 
      this.programDetails.PlanNum == Number(this.planNum[1].OI) || 
      this.programDetails.PlanNum == Number(this.planNum[2].OI))
      { 
        let bodyRSUDetails = JSON.parse(sessionStorage.getItem("Header RSU Details"));

        this.awardSharesReleasing = 0; // key exec
        this.awardUnreleased = 0; // key exec

        if(this.keyExecToggleValue == "Maximum" && this.programDetails.GrantYear <= this.globals.yearCondition)
        {
          //Shares Releasing
          let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing * (3/2));
          bodyRSUDetails.SharesReleasing = sharesTargetVestTotal;
          this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
          this.shareActSharesReleasing = this.awardSharesReleasing;

          //Unreleased
          let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased * (3/2));
          bodyRSUDetails.Unreleased = unreleasedTargetVestTotal;
          this.awardUnreleased = bodyRSUDetails.Unreleased;
          this.shareActUnreleased = this.awardUnreleased;
        }

        else if(this.keyExecToggleValue == "Maximum" && this.programDetails.GrantYear > this.globals.yearCondition)
        {
          //Shares Releasing
          let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing * (2));
          bodyRSUDetails.SharesReleasing = sharesTargetVestTotal;
          this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
          this.shareActSharesReleasing = this.awardSharesReleasing;

          //Unreleased
          let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased * (2));
          bodyRSUDetails.Unreleased = unreleasedTargetVestTotal;
          this.awardUnreleased = bodyRSUDetails.Unreleased;
          this.shareActUnreleased = this.awardUnreleased;
        }

        else if(this.keyExecToggleValue == "Trend" && this.programDetails.GrantYear <= this.globals.yearCondition)
        {
          let trendObtained: boolean = false;
          for (let k = 0; k < this.yearCode.length; k++)
          {
            if(this.yearCode[k].Code == this.awardDate) {

              //Shares Releasing
              let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing);
              let sharesOiPortionTarget = Math.round(sharesTargetVestTotal * (3/4));
              let sharesTsrOptionTarget = Math.round(sharesTargetVestTotal) - Math.round(sharesOiPortionTarget);
              let sharesOiPortionTrend = Math.round(sharesOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
              let sharesTsrOptionTrend = Math.round(sharesTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
              let sharesTrendVestTotal = Math.round(sharesOiPortionTrend) + Math.round(sharesTsrOptionTrend);
              bodyRSUDetails.SharesReleasing = sharesTrendVestTotal;
              this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
              this.shareActSharesReleasing = this.awardSharesReleasing;

              //Unreleased
              let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased);
              let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
              let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
              let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
              let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
              let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
              bodyRSUDetails.Unreleased = unreleasedTrendVestTotal;
              this.awardUnreleased = bodyRSUDetails.Unreleased;
              this.shareActUnreleased = this.awardUnreleased;
            }
          }

        }

        else if(this.keyExecToggleValue == "Trend" && this.programDetails.GrantYear > this.globals.yearCondition)
        {
          let trendObtained: boolean = false;
          for (let k = 0; k < this.yearCode.length; k++)
          {
            if(this.yearCode[k].Code == this.awardDate) {

              //Shares Releasing
              let sharesTargetVestTotal = Math.round(bodyRSUDetails.SharesReleasing);
              let sharesOiPortionTarget = Math.round(sharesTargetVestTotal * (3/4));
              let sharesTsrOptionTarget = Math.round(sharesTargetVestTotal) - Math.round(sharesOiPortionTarget);
              let sharesOiPortionTrend = Math.round(sharesOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
              let sharesTsrOptionTrend = Math.round(sharesTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
              let sharesTrendVestTotal = Math.round(sharesOiPortionTrend) + Math.round(sharesTsrOptionTrend);
              bodyRSUDetails.SharesReleasing = sharesTrendVestTotal;
              this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
              this.shareActSharesReleasing = this.awardSharesReleasing;

              //Unreleased
              let unreleasedTargetVestTotal = Math.round(bodyRSUDetails.Unreleased);
              let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
              let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
              let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
              let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
              let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
              bodyRSUDetails.Unreleased = unreleasedTrendVestTotal;
              this.awardUnreleased = bodyRSUDetails.Unreleased;
              this.shareActUnreleased = this.awardUnreleased;
            }
          }

        }

        else if(this.keyExecToggleValue == "Target")
        {
          this.awardSharesReleasing = bodyRSUDetails.SharesReleasing;
          this.awardUnreleased = bodyRSUDetails.Unreleased;

          //JCC, 06-30-2023 : Add psuAttained to the Unreleased/Unvested and SharesReleasing.
          if(this.psuDisplayInd == "N" && this.psuSharesAttained != 0) {
            this.psuGePastInd == "N" ? this.shareActSharesReleasing = this.awardSharesReleasing + this.psuSharesAttained : this.shareActSharesReleasing = this.awardSharesReleasing;
            this.shareActUnreleased = this.awardUnreleased + this.psuSharesAttained;
          } else {
            this.shareActSharesReleasing = this.awardSharesReleasing;
            this.shareActUnreleased = this.awardUnreleased;
          }

        }  
      }
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getheaderRSUDetails");
    error.message += errorLocation;
    throw error;
  }
}

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  transform(num: any, checker){ 
    try {
      if (num != 0 && num != undefined) {
        if (num.indexOf('-') > -1 ){
        if (checker == 1){
        let number = num.split('-'); 
        return '(' + this.currencyCdSymbol + number[1] + ')'; 
        } 
        else {
        let number = num.split('-');
        return '(' + number[1] + ')'; 
        }
        }
        else 
        { 
        return num;
        } 
        } 
        else {
        return '0';
        }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "transform");
      error.message += errorLocation;
      throw error;
    }
    } 

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Rsudetails") != -1 && this.user != null) {
                webPage = "Rsudetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  /**********************************************************************************
   * Method Name: getPSUDetails                                                                 
   * Description: Get PSU Detail: The DisplayInd and SharesAttained                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 06/30/2023           Jewel C. Caudilla           Initial draft       
   *********************************************************************************/
  getPSUDetails() {
    if(this.holdingProgramDetailsRSUService.psuDetails != null) {
      this.psuDisplayInd = this.holdingProgramDetailsRSUService.psuDetails.DisplayInd;
      this.psuSharesAttained = this.holdingProgramDetailsRSUService.psuDetails.SharesAttainedByPSU;
      this.psuSharesAttained = 0; //JCC --Set to zero 08/09/23
      this.getheaderRSUDetails();
    } else if(sessionStorage.getItem("PSU Details Data") != null) {
      let data = JSON.parse(sessionStorage.getItem("PSU Details Data"));
      if(Array.isArray(data) && data.length > 0) {
        let body = data[0][0];
        this.psuDisplayInd = body.DisplayInd;
        this.psuSharesAttained = body.SharesAttainedByPSU;
        this.psuSharesAttained = 0; //JCC --Set to zero 08/09/23
        this.getheaderRSUDetails();
      }
    } else if(this.grantID != "") {
      this.holdingProgramDetailsRSUService.getPSUDetails(this.grantID, this.user.OptNum).subscribe(data => {
        if(Array.isArray(data) && data.length > 0) {
          let body = data[0][0];
          this.psuDisplayInd = body.DisplayInd;
          this.psuSharesAttained = body.SharesAttainedByPSU;
          this.psuSharesAttained = 0; //JCC --Set to zero 08/09/23
          this.getheaderRSUDetails();
        } 
      })
    } else {
      this.psuDisplayInd = "";
      this.psuSharesAttained = 0;
      this.getheaderRSUDetails();
    }
  }

  /**********************************************************************************
   * Method Name: getPSUDetailsbyOptNum                                                                 
   * Description: This function used to get all PSU Details using OptNum with PastInd 
   *              to determine every grantNum if Unreleased or Released.                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/10/2023           Jewel C. Caudilla           Initial draft       
   *********************************************************************************/
  async getPsuDetailsbyOptNum() {
    try {
      let psuData = JSON.parse(sessionStorage.getItem("PSU Details by OptNum"));
      let award = JSON.parse(sessionStorage.getItem("currentVisitedAward"));
      if(award != null) {
        if(psuData != null) {
          for(let i = 0; i < psuData.length; i++) {
            if(psuData[i].GrantNum == award.GrantNum) {
              this.psuGePastInd = psuData[i].PastInd;
              this.psuDisplayInd = psuData[i].DisplayInd;
              this.psuSharesAttained = psuData[i]["Shares Attained by PSU"];
              this.psuSharesAttained = 0; //JCC --Set to zero 08/09/23
            }
          }

          this.getProgramDetails();
        } else {
          let body: any = 0;
          let optNum = JSON.parse(sessionStorage.getItem(this.globals.sessionOptNum));
          await this.holdingSummaryService.getPSUDetailsbyOptNum(optNum).subscribe(data => {
            try {
              if (Array.isArray(data) && data.length > 0) {
                body = data[0].sort((a,b) => a.SortOrder < b.SortOrder ? -1 : a.SortOrder > b.SortOrder ? 1 : 0);
                sessionStorage.setItem("PSU Details by OptNum", JSON.stringify(body));
              }

              let psuData = body;
              for(let i = 0; i < psuData.length; i++) {
                if(psuData[i].GrantNum == award.GrantNum) {
                  this.psuGePastInd = psuData[i].PastInd;
                  this.psuDisplayInd = psuData[i].DisplayInd;
                  this.psuSharesAttained = psuData[i]["Shares Attained by PSU"];
                  this.psuSharesAttained = 0; //JCC --Set to zero 08/09/23
                }
              }
              
              this.getProgramDetails();
            } catch(error) {
              let errorLocation = this.errorLocation.replace("methodName", "getPsuDetailsbyOptNum");
              error.message += errorLocation;
              throw error;
            }
          })
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getPsuDetailsbyOptNum");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

}
