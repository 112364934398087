import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { HoldingProgramDetailsOptionsService } from '../holdingprogramdetailspage-options/HoldingProgramDetailsOptionsService';
import { PrintService } from '../services/PrintService';
import { HoldingprogramdetailsRsuService } from '../holdingprogramdetailspage-rsu/HoldingProgramDetailsRSUService';
import * as moment from 'moment';
import { HoldingsSummaryService } from '../holdingsummarypage/HoldingSummaryService';

@Component({
  selector: 'app-grant-print-layout',
  templateUrl: './grant-print-layout.component.html',
  styleUrls: ['./grant-print-layout.component.css']
})
export class GrantPrintLayoutComponent implements OnInit {  

  rsuAwardDate: any;
  awardname: string;
  rsuExchangeRate: string;
  rsuGranted: any;
  displayedAgreementVestColumns: string[] = ['VestReleaseDate', 'OriginalShares']
  agreementVestDataSource = this.rsuDetailsService.agreementVestDataSource;
  displayedAgreementRelColumns: string[] = ['VestReleaseDate', 'OriginalShares'];
  agreementRelDataSource = this.rsuDetailsService.agreementRelDataSource;

  user: any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  vestDataSource: any = this.optionDetailsService.vestDataSource;
  displayVestAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];

  participant: string;
  employeeId: string;
  numOfShares: number;
  grantPrice: number;
  grantDate: string;
  expirationDate: string;
  exchangeRate: number;

  optionsLayout: boolean;
  rsuLayout: boolean;

  holdingSummaryLayout: boolean;
  dataSourceOptions: any;
  dataSourceVEIP: any;
  dataSourceRSU: any;
  dataSourceFounder: any;

  showOptions:boolean;
  showVeip: boolean;
  showFounder: boolean;
  showRsu: boolean;

  programsTotalShareOutstanding: any = 0;
  programsTotalShareOutstandingValue: any = 0;
  programsTotalAvailableToSell: any = 0;
  programsTotalAvailableToSellValue: any = 0;
  programsTotalUnReleased: any = 0;
  programsTotalUnReleasedValue: any = 0;
  currencyCd: string;
  currencyCdSymbol = "";
  errorLocation: string = " + [Component: Holdingsummary, Method: methodName]";

  displayedColumnsFounder: string[] = ['AwardName', 'AvailableToSell', 'AvailableToSellValue', 'BrokerId', 'NextRelease', 'SharesReleasing', 'GrantAccepted', 'UnreleasedUnexercisable', 'ReleasedExercisableQty', 'Expiration', 'Forfeited'];
  displayedColumnsOptions: string[] = ['AwardName', 'AvailableToSell', 'AvailableToSellValue', 'BrokerId', 'NextRelease', 'SharesReleasing', 'GrantAccepted', 'UnreleasedUnexercisable', 'ReleasedExercisableQty', 'Expiration', 'Forfeited'];
  displayedColumnsVEIP: string[] = ['AwardName', 'AvailableToSell', 'AvailableToSellValue', 'BrokerId', 'NextRelease', 'SharesReleasing', 'GrantAccepted', 'UnreleasedUnexercisable', 'ReleasedExercisableQty', 'Expiration', 'Forfeited'];
  displayedColumnsRSU: string[] = ['AwardName', 'AvailableToSell', 'AvailableToSellValue', 'BrokerId', 'NextRelease', 'SharesReleasing', 'GrantAccepted', 'UnreleasedUnexercisable', 'ReleasedExercisableQty', 'Expiration', 'Forfeited'];
  grantID: string;

  totalFounderSharesOutstanding: any;
  founderSharesOutstandingValue: any;
  totalFounderAvailableToSell: any;
  founderAvailableToSellValue: any;
  totalFounderUnreleasedUnvested: any;
  founderUnreleasedUnvestedValue: any;
  totalFounderAvailToSellValue: any;
  founderReleasedExercisableQty: any;
  totalFoundersForfeited: any;
  totalRSUSharesOutStanding: any;
  rsuOutstandingValue: any;
  totalRSUAvailableToSell: any;
  rsuAvailableToSellValue: any;
  totalRSUUnreleasedUnvested: any;
  rsuUnreleasedOutstanding: any;
  totalRSUSharesReleasing: any;
  totalRSUReleasedVested: any;
  totalRSUForfeited: any;

  totalPurchaseSharesOutStanding:any = 0;
  totalPurchaseAvailableToSell:any = 0;
  totalPurchaseUnReleased = 0;
  totalPurchaseAvailableToSellValue:any = 0;
  valueSharesOustanding:any = 0;
  valueAvailableToSell:any = 0;

  totalOptionsSharesOutstanding:any = 0;
  totalOptionsAvailableToSell:any = 0;
  totalGrantPrice:any = 0;
  totalUnreleasedUnvested:any = 0;
  totalReleasedExercisableQty: any = 0;
  optionsOutstandingValue:any = 0;
  optionsAvailableToSellValue: number = 0;
  optionsUnreleasedUnvested: any = 0;

  constructor(private globals: Globals, private optionDetailsService: HoldingProgramDetailsOptionsService, private printService: PrintService, private rsuDetailsService: HoldingprogramdetailsRsuService, private holdingSumService: HoldingsSummaryService) {}

  ngOnInit() {
    this.holdingSummaryLayout = this.printService.holdingSummaryLayout;
    if(this.holdingSummaryLayout != true){
    this.getAgreementDetails();
    }
    else{
      this.showFounder = this.holdingSumService.showFounderData;
      this.showOptions = this.holdingSumService.showOptionsData;
      this.showRsu = this.holdingSumService.showRSUData;
      this.showVeip = this.holdingSumService.showVEIPData;

      this.dataSourceOptions = this.holdingSumService.dataSourceOptions;
      this.dataSourceVEIP = this.holdingSumService.dataSourceVEIP;
      this.dataSourceRSU = this.holdingSumService.dataSourceRSU;
      this.dataSourceFounder = this.holdingSumService.dataSourceFounder;
      this.programsTotalShareOutstanding = this.holdingSumService.programsTotalShareOutstanding;
      this.programsTotalShareOutstandingValue = this.holdingSumService.programsTotalShareOutstandingValue;
      this.programsTotalAvailableToSell = this.holdingSumService.programsTotalAvailableToSell;
      this.programsTotalAvailableToSellValue = this.holdingSumService.programsTotalAvailableToSellValue;
      this.programsTotalUnReleased = this.holdingSumService.programsTotalUnReleased;
      this.programsTotalUnReleasedValue = this.holdingSumService.programsTotalUnReleasedValue;
      this.currencyCd = this.holdingSumService.currencyCd;
      this.currencyCdSymbol = this.holdingSumService.currencyCdSymbol;
      this.totalFounderSharesOutstanding = this.holdingSumService.totalFounderSharesOutstanding;
      this.founderSharesOutstandingValue = this.holdingSumService.founderSharesOutstandingValue;
      this.totalFounderAvailableToSell = this.holdingSumService.totalFounderAvailableToSell;
      this.founderAvailableToSellValue = this.holdingSumService.founderAvailableToSellValue;
      this.totalFounderUnreleasedUnvested = this.holdingSumService.totalFounderUnreleasedUnvested;
      this.founderUnreleasedUnvestedValue = this.holdingSumService.founderUnreleasedUnvestedValue;
      this.totalFounderAvailToSellValue = this.holdingSumService.totalFounderAvailToSellValue;
      this.founderReleasedExercisableQty = this.holdingSumService.founderReleasedExercisableQty;
      this.totalRSUSharesOutStanding = this.holdingSumService.totalRSUSharesOutStanding;
      this.rsuOutstandingValue = this.holdingSumService.rsuOutstandingValue;
      this.totalRSUAvailableToSell = this.holdingSumService.totalRSUAvailableToSell;
      this.rsuAvailableToSellValue = this.holdingSumService.rsuAvailableToSellValue;
      this.totalRSUUnreleasedUnvested = this.holdingSumService.totalRSUUnreleasedUnvested;
      this.rsuUnreleasedOutstanding = this.holdingSumService.rsuUnreleasedOutstanding;
      this.totalRSUSharesReleasing = this.holdingSumService.totalRSUSharesReleasing;
      this.totalRSUReleasedVested = this.holdingSumService.totalRSUReleasedVested;
      this.totalRSUForfeited = this.holdingSumService.totalRSUForfeited;
      this.totalPurchaseSharesOutStanding = this.holdingSumService.totalPurchaseSharesOutStanding;
      this.totalPurchaseAvailableToSell = this.holdingSumService.totalPurchaseAvailableToSell;
      this.totalPurchaseUnReleased = this.holdingSumService.totalPurchaseUnReleased;
      this.totalPurchaseAvailableToSellValue = this.holdingSumService.totalPurchaseAvailableToSellValue;
      this.valueSharesOustanding = this.holdingSumService.valueSharesOustanding;
      this.valueAvailableToSell = this.holdingSumService.valueAvailableToSell;
      this.totalOptionsSharesOutstanding = this.holdingSumService.totalOptionsSharesOutstanding;
      this.totalOptionsAvailableToSell = this.holdingSumService.totalOptionsAvailableToSell;
      this.totalGrantPrice = this.holdingSumService.totalGrantPrice;
      this.totalUnreleasedUnvested = this.holdingSumService.totalUnreleasedUnvested;
      this.totalReleasedExercisableQty = this.holdingSumService.totalReleasedExercisableQty;
      this.optionsOutstandingValue = this.holdingSumService.optionsOutstandingValue;
      this.optionsAvailableToSellValue = this.holdingSumService.optionsAvailableToSellValue;
      this.optionsUnreleasedUnvested = this.holdingSumService.optionsUnreleasedUnvested;
    }
  }

  /**********************************************************************************
      Method Name: getAgreementDetails
      Description: Gets the details for the printed file.

      Date            Modified by           Description
      06/07/19        Dalemark P. Suquib    Initial draft         
  **********************************************************************************/
  getAgreementDetails() {
    try {
      this.optionsLayout = this.printService.optionsLayout;
      this.rsuLayout = this.printService.rsuLayout;

      if(this.user != null) {
        let middleInitial: string = this.user.MiddleInitial;

        if(middleInitial != null && middleInitial != "") {
          middleInitial = " " + this.user.MiddleInitial + ". ";
        } else {
          middleInitial = " ";
        }
        this.participant = this.user.FirstName + middleInitial + this.user.LastName;
        this.employeeId = this.user.PersonnelNumber;
      }
  
      if(this.optionsLayout) {
        let agreementDetails = this.optionDetailsService.grantAgreementDetails;
        let optionsSummary = this.optionDetailsService.optionsSummary;
        
        if(agreementDetails != null && agreementDetails != undefined) {
          this.numOfShares = agreementDetails.OptSharesGranted;
          this.grantPrice = agreementDetails.OptGrantPrice;
          this.exchangeRate = agreementDetails.ExchangeRate;
        } 

        if(optionsSummary != null && optionsSummary != undefined) {
          this.grantDate = moment(optionsSummary.GrantDate).format("MMM D, YYYY");
          this.expirationDate = moment(optionsSummary.ExpirationDt).format("MMM D, YYYY");
        }
      } else if(this.rsuLayout) {
        let agreeContent = this.rsuDetailsService.agreementContent;
        this.rsuAwardDate = this.rsuDetailsService.shareAct.GrantDate;
        this.awardname = agreeContent.GrantLongDesc;
        this.grantID = "(#" + agreeContent.GrantId + ")";
        this.rsuExchangeRate = agreeContent.ExchangeRate;
        this.rsuGranted = agreeContent.RsuSharesGranted;
      }
    } catch(error) {
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: commafy
      Description: Number formatting

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  commafy(num: any, value?: number) {
    try {
      // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      throw error;
    }
  }

  
  checkPurchaseAvailToSell(elementAvailToSell, elementData) {

      if(elementAvailToSell == 0) {
        if(typeof(elementData) == 'number' || elementData == '0') {
          return 0;
        } else {
          return '';
        }
      } else {
          return elementData;
      }
  }

  transform(num: any, checker){
    try {
      if (num != 0) {
        if (num.indexOf('-') > -1 ){
          if (checker == 1){
            let number = num.split('-'); 
            return '(' + this.currencyCdSymbol + number[1] + ')'; 
          } 
          else {
            let number = num.split('-');
            return '(' + number[1] + ')'; 
          }
        }else{ 
          return num;
        } 
      } 
      else {
        return '0';
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "transform");
      error.message += errorLocation;
      throw error;
    }
  } 

}
