import { Component, OnInit } from '@angular/core';
import { PageSection, FileType } from '../../services/enumerations/enumerations';
import { StaticContentService } from '../../services/StaticContentService';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-taxtime',
  templateUrl: './taxtime.component.html',
  styleUrls: ['./taxtime.component.css']
})
export class TaxtimeComponent implements OnInit {
  resourceLoading: boolean = true;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  taxPrepcontent: any;
  taxPrepData: any;
  currYrTaxPrepDocDesc: any;
  currYrTaxPrepDocName: any;
  currYrTaxPrepFileTyp: any;
  salesHistcontent: any;
  currSalesHistData: any;
  SaleSHistTry: any;
  currYrSaleshistListNm: any;
  archiveTaxPrepData: any;
  errorTaxTime: boolean = false;
  errorLocation: string = " + [Component: Resourcespage, Method: methodName]";

  constructor( private staticContent: StaticContentService, private router: Router) { }

  ngOnInit() {
    this.getTaxTime();
  }

  getTaxTime() {
    try {
      // this.taxTimeLoading = true; 
      this.resourceLoading = true;
   
      this.bodyData[5] = PageSection.countryCurrYrTaxPrep;
      this.bodyData[6] = 0;
    
      this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
        try {
          this.taxPrepcontent = JSON.parse(data._body); 
  
          if(this.taxPrepcontent.name == "TokenExpiredError"){
            this.router.navigate(['homepage']);
          }
                       
          if (this.taxPrepcontent.body == "{}"){
            //NO TAX PREP DATA TO DISPLAY
            this.taxPrepcontent.body = null;
            } 
          
          if (this.taxPrepcontent.body.length > 2){
              let currYrTaxPrepContent = JSON.parse(this.taxPrepcontent.body);         
              
              this.taxPrepData = currYrTaxPrepContent.data;
      
              for (let i = 0; i < this.taxPrepData.length; i++){
                this.currYrTaxPrepDocDesc = this.taxPrepData[i].Document_Desc;
                this.currYrTaxPrepDocName = this.taxPrepData[i].Document_Name;
                this.currYrTaxPrepFileTyp = this.taxPrepData[i].File_Type;
      
                if(this.taxPrepData[i].File_Type == "pdf") {
                  this.taxPrepData[i].fileIcon = FileType.pdf;
                 } else if(this.taxPrepData[i].File_Type == "docx") {
                  this.taxPrepData[i].fileIcon = FileType.doc;
                 } else if(this.taxPrepData[i].File_Type == "doc") {
                  this.taxPrepData[i].fileIcon = FileType.doc;
                 } else if(this.taxPrepData[i].File_Type == "xls") {
                  this.taxPrepData[i].fileIcon = FileType.xls;
                 } else if(this.taxPrepData[i].File_Type == "xlsx") {
                  this.taxPrepData[i].fileIcon = FileType.xls;
                 } else if(this.taxPrepData[i].File_Type == "pptx") {
                  this.taxPrepData[i].fileIcon = FileType.ppt;
                 } else if(this.taxPrepData[i].File_Type == "ppt") {
                  this.taxPrepData[i].fileIcon = FileType.ppt;
                 } else if(this.taxPrepData[i].File_Type == "zip") {
                  this.taxPrepData[i].fileIcon = FileType.zip;
                 } 
              }          
            }
                          
          //SALES HIST
              
          let regEx: any = /&.*?;|<div.*?>|<[^>]*>|<\/div>|<blockquote.*?>|<\/blockquote>|<br.*?>|<p>|<\/p>/gi;
      
          this.bodyData[5] = PageSection.countryCurrYrSalesHist;
          this.bodyData[6] = 1;
      
          this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
            try {
              this.salesHistcontent = JSON.parse(data._body); 
              
              if(this.salesHistcontent.name == "TokenExpiredError"){
                this.router.navigate(['homepage']);
              }
      
              if (this.salesHistcontent.body == "{}"){
                //NO SALES HIST DATA TO DISPLAY
                this.salesHistcontent.body = null;
              }
        
              if (this.salesHistcontent.body.length > 2){
        
                let currYrSalesHistContent = JSON.parse(this.salesHistcontent.body);      
        
                this.currSalesHistData = currYrSalesHistContent.data;  
                
                this.SaleSHistTry =  currYrSalesHistContent.data[0].Body_Desc;
                this.currYrSaleshistListNm = currYrSalesHistContent.data[0].List_Name;       
                
              }
        
              //Archive
              this.bodyData[5] = PageSection.countryArchTaxPrep;
              this.bodyData[6] = 0;
        
              this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {
                try {
                  let taxArchivecontent = JSON.parse(data._body); 
                  
                  if(taxArchivecontent.name == "TokenExpiredError"){
                    this.router.navigate(['homepage']);
                  }
        
                  if (taxArchivecontent.body == "{}"){
                    //NO ARCHIVE TAX PREP DATA TO DISPLAY
                    taxArchivecontent.body = null;
                  } 
                  
                  if (taxArchivecontent.body.length > 2){
          
                    let archiveTaxPrepContent = JSON.parse(taxArchivecontent.body);       
                    
                    this.archiveTaxPrepData = archiveTaxPrepContent.data;  
                    
                    for (let i = 0; i < this.archiveTaxPrepData.length; i++){
                    
                      this.currYrTaxPrepDocDesc = this.archiveTaxPrepData[i].Document_Desc;             
                      this.currYrTaxPrepDocName = this.archiveTaxPrepData[i].Document_Name;              
                      this.currYrTaxPrepFileTyp = this.archiveTaxPrepData[i].File_Type;
          
                      if(this.archiveTaxPrepData[i].File_Type == "pdf") {
                        this.archiveTaxPrepData[i].fileIcon = FileType.pdf;
                       } else if(this.archiveTaxPrepData[i].File_Type == "docx") {
                        this.archiveTaxPrepData[i].fileIcon = FileType.doc;
                       } else if(this.archiveTaxPrepData[i].File_Type == "doc") {
                        this.archiveTaxPrepData[i].fileIcon = FileType.doc;
                       } else if(this.archiveTaxPrepData[i].File_Type == "xls") {
                        this.archiveTaxPrepData[i].fileIcon = FileType.xls;
                       } else if(this.archiveTaxPrepData[i].File_Type == "xlsx") {
                        this.archiveTaxPrepData[i].fileIcon = FileType.xls;
                       } else if(this.archiveTaxPrepData[i].File_Type == "pptx") {
                        this.archiveTaxPrepData[i].fileIcon = FileType.ppt;
                       } else if(this.archiveTaxPrepData[i].File_Type == "ppt") {
                        this.archiveTaxPrepData[i].fileIcon = FileType.ppt;
                       } else if(this.archiveTaxPrepData[i].File_Type == "zip") {
                        this.archiveTaxPrepData[i].fileIcon = FileType.zip;
                       } 
                    }           
                  }    
                  // this.taxTimeLoading = false;
                  this.resourceLoading = false; 
                } catch(error) {
                  this.errorTaxTime = true;
                  this.resourceLoading = false;
                  let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
                  error.message += errorLocation;
                  throw error;
                }
              }, error => {
                this.errorTaxTime = true;
                this.resourceLoading = false;
                let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
                error.error.message += errorLocation;
                throw error.error;
              });  
            } catch(error) {
              this.errorTaxTime = true;
              this.resourceLoading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            this.errorTaxTime = true;
            this.resourceLoading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
            error.error.message += errorLocation;
            throw error.error;
          });
        } catch(error) {
          this.errorTaxTime = true;
          this.resourceLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTaxTime = true;
        this.resourceLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
        error.error.message += errorLocation;
        throw error.error;
      });   
    } catch(error) {
      this.errorTaxTime = true;
      this.resourceLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTaxTime");
      error.message += errorLocation;
      throw error;
    }
  }

}
