
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';

@Injectable()
export class BrokersPageService {

  constructor(private globals: Globals, private httpClient: HttpClient) { }

  getBrokersAccounts(optnum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let brokerAccountsApi = this.globals.getBrokerAccountsAPI + optnum;
    
    try {
      const api = apiUrl + brokerAccountsApi;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getGlobalID(optnum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let brokerAccountsApi = this.globals.getGlobalIDhistAPI + optnum;
    
    try {
      const api = apiUrl + brokerAccountsApi;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 

}
