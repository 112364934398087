import { Component, OnInit } from '@angular/core';
import { HoldingProgramDetailsOptionsService } from '../HoldingProgramDetailsOptionsService';
import { Globals } from '../../globals';
import {TooltipPosition} from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { BrokerType, GrantAgreementStatus, AdminType, EmployeeType, PageSection, FileType, MyHoldingsPagesResources, tScript } from '../../services/enumerations/enumerations';
import { StaticContentService } from '../../services/StaticContentService';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-options-learnmore-tab',
  templateUrl: './options-learnmore-tab.component.html',
  styleUrls: ['./options-learnmore-tab.component.css']
})
export class OptionsLearnmoreTabComponent implements OnInit {
  errorDetailsTab: boolean = false;
  errorLocation: string = " + [Component: Optionsdetails, Method: methodName]";
  errorCommoneQuestions: boolean = false;
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  optCommonQuestionsContent: any; 
  grantCountryCd: any; 
  optGuideBooksContent: any; 
  errorGuideBooks: boolean = false;
  grantGroupId: any;
  optShareIncentiveContent: any;
  errorShareIncentive: boolean = false;
  optToolsandRepContent: any;
  optCountAwardCOntent: any;
  errorCountryAward: boolean = false;
  errorToolsAndReports: boolean = false;
  LocalGroupDetailId7: any;
  LocalGroupDetailId8: any;
  LocalGroupDetailId5: any;
  LearnMoreLoading: boolean =  true

  commonQAccordionCount: number = 1; // 1 accordion is initially expanded




  constructor(private router: Router, private holdingProgramDetailsOptionsService: HoldingProgramDetailsOptionsService, private errorNotificationService: ErrorNotificationService, private globals: Globals, private staticContentService: StaticContentService) { }

  ngOnInit() {
    this.getFromCodeDetail();
  }

  /*****************************************************************************************
   * Method Name:   getrsuCommonQuestions() {
                                                                 
   * Description: Gets the content for common questions from my resource api if the user hasOptionsAward
   *              and GrantCountryCd= CAN   
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    07/01/19             KMN                                   Bug 374679 fix - Incorrect Page Section in Options [Learnmore: Common Questions]
   * 2    07/09/19             KMN                                   Bug fix for Senior Exec page section
   *****************************************************************************************/
  getoptCommonQuestions() {
    try {
      if (this.programDetails.GrantNum == null){
        let temp = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
        this.programDetails = temp[0];
      }

      // BACK BUTTON BUG - START
      sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
      let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
      let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

      if(backUrl) {
        if(detailsStorage.length > 0) {
          this.programDetails = detailsStorage[detailsStorage.length - 1];
        }
      } else {
        this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
      }
      // BACK BUTTON BUG - END

      this.grantCountryCd = this.programDetails.GrantCountryCd;

      let locExchageRate8 = this.LocalGroupDetailId8.find(i => i.GroupingValue == this.programDetails.GrantGroupId);

      if (locExchageRate8 != undefined && this.grantCountryCd == "CAN") {
        this.bodyData[5] = PageSection.optionsCommonQuestionshasAwards;
      } else if (this.bodyData[2] === EmployeeType.seniorExec) {
        this.bodyData[5] = PageSection.optionsCommonQuestionsisSenior;
      } else {
        this.bodyData[5] = PageSection.optionsCommonQuestions;
      }
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let commonQuestionData = JSON.parse(data.body);
          if(commonQuestionData.data) { // length > {}
            let commonQuestionBody = commonQuestionData.data;
            if(commonQuestionBody.length > 0) {
              this.optCommonQuestionsContent = commonQuestionBody;

              for(let i=0; i<this.optCommonQuestionsContent.length; i++){
                if(this.optCommonQuestionsContent[i]["Body_Desc"].indexOf('id="managementcontactlink"') != -1){
                  this.optCommonQuestionsContent[i]["Body_Desc"] = this.optCommonQuestionsContent[i]["Body_Desc"].split('id="managementcontactlink"').join('class="managementcontactlink"');
                }

                if(this.optCommonQuestionsContent[i]["Body_Desc"].indexOf('<u>') != -1){
                  this.optCommonQuestionsContent[i]["Body_Desc"] = this.optCommonQuestionsContent[i]["Body_Desc"].split('<u>').join("<span style='text-decoration: underline;'>");
                  this.optCommonQuestionsContent[i]["Body_Desc"] = this.optCommonQuestionsContent[i]["Body_Desc"].split('</u>').join("</span>");
                }
              } 
            } else {
              this.optCommonQuestionsContent = null;
            }
          } 
          this.getGuideBooks();
        } catch(error) {
          this.errorCommoneQuestions = true;
          this.getGuideBooks();
          let errorLocation = this.errorLocation.replace("methodName", "getoptCommonQuestions");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCommoneQuestions = true;
        this.getGuideBooks();
        let errorLocation = this.errorLocation.replace("methodName", "getoptCommonQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCommoneQuestions = true;
      this.getGuideBooks();
      let errorLocation = this.errorLocation.replace("methodName", "getoptCommonQuestions");
      error.message += errorLocation;
      throw error;
    }
  }
/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Optionsdetails") != -1 && this.user != null) {
                webPage = "Optionsdetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
  getFromCodeDetail(){
    try {
      this.holdingProgramDetailsOptionsService.getDataFromCodeDetail(7).pipe(map(res => res)).subscribe(data => {
        
          let body = data;          

          data != undefined ? this.LocalGroupDetailId7 = body[0] : "";
        
          this.holdingProgramDetailsOptionsService.getDataFromCodeDetail(8).pipe(map(res => res)).subscribe(data => { 
            let body = data;
  
            data != undefined ? this.LocalGroupDetailId8 = body[0] : "";
  
            this.holdingProgramDetailsOptionsService.getDataFromCodeDetail(5).pipe(map(res => res)).subscribe(data => { 
              let body = data;
    
              data != undefined ? this.LocalGroupDetailId5 = body[0] : "";
  
              this.getoptCommonQuestions();
            });
          });
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFromCodeDetail");
      error.message += errorLocation;
      throw error;
    }
  }
  getGuideBooks(){
    try {
      this.bodyData[5] = PageSection.optionsGuidebooks;
      this.bodyData[6] = 1;      
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let guideBooksData = JSON.parse(data.body);

          if(guideBooksData.data) { // length > {}
            let guideBooksBody = guideBooksData.data;
            
            if(guideBooksBody.length > 0) {
              this.optGuideBooksContent = guideBooksBody[0].Body_Desc;
            } else {
              this.optGuideBooksContent = null;
            }
          }
          this.getOptShareIncentive();
        } catch(error) {
          this.errorGuideBooks = true;
          this.getOptShareIncentive();
          let errorLocation = this.errorLocation.replace("methodName", "getGuideBooks");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorGuideBooks = true;
        this.getOptShareIncentive();
        let errorLocation = this.errorLocation.replace("methodName", "getGuideBooks");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorGuideBooks = true;
      this.getOptShareIncentive();
      let errorLocation = this.errorLocation.replace("methodName", "getGuideBooks");
      error.message += errorLocation;
      throw error;
    }
  }
  getOptShareIncentive() {
    try {
      this.bodyData[5] = PageSection.optionsShareIncentiveProspectus;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let shareIncenDocumentData = JSON.parse(data.body);

          if(shareIncenDocumentData.data) { // length > {}
            let optDocumentBody = shareIncenDocumentData.data;
    
            if(optDocumentBody.length > 0) {
              this.optShareIncentiveContent = optDocumentBody[0].Body_Desc;
            } else {
              this.optShareIncentiveContent = null;
            }
          }
          this.getOptCountryAward();
        } catch(error) {
          this.errorShareIncentive = true;
          this.getOptCountryAward();
          let errorLocation = this.errorLocation.replace("methodName", "getOptShareIncentive");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorShareIncentive = true;
        this.getOptCountryAward();
        let errorLocation = this.errorLocation.replace("methodName", "getOptShareIncentive");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorShareIncentive = true;
      this.getOptCountryAward();
      let errorLocation = this.errorLocation.replace("methodName", "getOptShareIncentive");
      error.message += errorLocation;
      throw error;
    }
  }
    /*****************************************************************************************
   * Method Name: getProspectusDocument                                                                 
   * Description: Gets the content for prospectus document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getOptCountryAward() {
    try {
      this.bodyData[5] = PageSection.optionsCountryAwardsTaxImp;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let CountryAwardDocData = JSON.parse(data.body);
  
          if(CountryAwardDocData.data) { // length > {}
            let countryAwardDocBody = CountryAwardDocData.data;
    
            if(countryAwardDocBody.length > 0) {
              this.optCountAwardCOntent = countryAwardDocBody[0].Body_Desc;
            } else {
              this.optCountAwardCOntent = null;
            }
          }
          this.getOptToolsAndReports();
        } catch(error) {
          this.errorCountryAward = true;
          this.getOptToolsAndReports();
          let errorLocation = this.errorLocation.replace("methodName", "getOptCountryAward");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCountryAward = true;
        this.getOptToolsAndReports();
        let errorLocation = this.errorLocation.replace("methodName", "getOptCountryAward");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCountryAward = true;
      this.getOptToolsAndReports();
      let errorLocation = this.errorLocation.replace("methodName", "getOptCountryAward");
      error.message += errorLocation;
      throw error;
    }
  } 
    /*****************************************************************************************
   * Method Name: getToolsAndReports                                                                 
   * Description: Gets the content for tools and reports from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getOptToolsAndReports() {
    try {
      this.bodyData[5] = PageSection.optionsToolsReports;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let toolsAndReportsData = JSON.parse(data.body);
  
          if(toolsAndReportsData.data) { // length > {}
            let toolsAndReportsBody = toolsAndReportsData.data;
            
            if(toolsAndReportsBody.length > 0) {
              this.optToolsandRepContent = toolsAndReportsBody;
              for(let i=0; i<this.optToolsandRepContent.length; i++){
                if(this.optToolsandRepContent[i]["Body_Desc"].indexOf("<u>") != -1){
                  this.optToolsandRepContent[i]["Body_Desc"] = this.optToolsandRepContent[i]["Body_Desc"].split("<u>").join("<span style='text-decoration: underline;'>");
                  this.optToolsandRepContent[i]["Body_Desc"] = this.optToolsandRepContent[i]["Body_Desc"].split("</u>").join("</span>");
                }

                if(this.optToolsandRepContent[i]["Body_Desc"].indexOf("<b>") != -1){
                  this.optToolsandRepContent[i]["Body_Desc"] = this.optToolsandRepContent[i]["Body_Desc"].split("<b>").join("<span style='font-weight: bold;'>");
                  this.optToolsandRepContent[i]["Body_Desc"] = this.optToolsandRepContent[i]["Body_Desc"].split("</b>").join("</span>");
                }
              } 

            } else {
              this.optToolsandRepContent = null;
            }
            
            this.LearnMoreLoading=false;
            setTimeout(()=>{ this.inContentIcon() }, 100);
          }
        } catch(error) {
          this.errorToolsAndReports = true;
          let errorLocation = this.errorLocation.replace("methodName", "getOptToolsAndReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorToolsAndReports = true;
        let errorLocation = this.errorLocation.replace("methodName", "getOptToolsAndReports");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorToolsAndReports = true;
      let errorLocation = this.errorLocation.replace("methodName", "getOptToolsAndReports");
      error.message += errorLocation;
      throw error;
    }
  }
  async inContentIcon(){ 
    $("[href='#collapseCQIndex0']").attr("aria-expanded","true");
    $("[id='collapseCQIndex0']").attr("class","collapse show");
    $("[href='op.pdf']").remove();
    $("[id='learn-more-tab']").attr("aria-label","Learn More for Options award");
    $("[href='https://myholdings.accenture.com/Resources/Default.aspx']").attr("aria-label","Resources Link - Common Questions (Options)");
    this.staticContentService.inContentIconAppend();
  }

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let srcElement: HTMLImageElement = event.srcElement;
      let className: string = event.target.className;

      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != ""){
        if(href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1){ //if href value is a file
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){  //Redirect to another myHoldings page
          // href = href.split("/Default.aspx")[0]; 
          href = event.target.innerText;
          event.preventDefault();
          
          href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
        } else if(href.indexOf("#") != -1) {
          event.preventDefault();
          className == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
        }
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "contactspage" : "";

    window.open(this.globals.currentWebUrl + "/" + page, "_blank");
    return false;
  }

  /*****************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
navToReportsPage(event) {
  try {
    let href = event.target.href;
    let id: number;
    let reportId: object;
    let srcElement: HTMLImageElement = event.srcElement;

    if(srcElement.src != undefined){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
    }else if(href != undefined) {
      if(href.indexOf("reportid") != -1) {
        event.preventDefault();
        id = href.substring(href.lastIndexOf("=") + 1);
        reportId = {'ReportTypeId': id};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
        window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
      }else if(href.indexOf("fileName=") != -1){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    }

  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToReportsPage");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
      Method Name: navToPage
      Description: Redirect to other page

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
 navToPage(strLoc) {
  try {
    this.router.navigate([strLoc]);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToPage");
    error.message += errorLocation;
    throw error;
  }
}

  /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
  collapseAccordion(index) {
    let className = document.getElementById("collapseCQIndex" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.optCommonQuestionsContent.length; i++) {
        document.getElementById("collapseCQIndex" + i).className = "collapse";
      }
    }
   }
}
