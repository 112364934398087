import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { HoldingProgramDetailsRSUIPOService } from '.././HoldingProgramDetailsRSUIPOService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import * as moment from 'moment';
import {tScript} from '../../services/enumerations/enumerations';
import {TooltipPosition} from '@angular/material/tooltip';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-iporsu-vest-card',
  templateUrl: './iporsu-vest-card.component.html',
  styleUrls: ['./iporsu-vest-card.component.css']
})
export class IporsuVestCardComponent implements OnInit {

  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  user: any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = this.user.CurrencyCd;
  errorLocation: string = " + [Component: Rsuipodetails, Method: methodName]";
  positionOption: TooltipPosition[]=['after', 'before', 'above', 'below', 'left', 'right'];

  IPOvestDataSource: any;
  IPOdisplayedVestColumns: string[] = ['VestDate', 'Shares', 'Status'];

  errorVestSection: boolean;

  IPOvestAdditionalRsu: number;
  IPOvestLocalCurrency: string;
  IPOvestOrigGrantedRsu: number;
  IPOvestTotalAward: number;
  IPOvestValue: number;
  IPOvestValueLocal: number;
  IPOvestVestFmv: number;
  IPOvestDisplayValueLocal: boolean;
  IPOvestTotalShares: number;
  IPOvestLoading: boolean = false;
  IPOvestRsuDate: string = "null";
  IPOvestData: any;
  ipoForfeited: number;

  ipoGrantDesc: string;
  ipoGrantPrice: number;
  ipoTotalAwarded: number;
  ipoUnreleased: number;
  ipoReleased: number;
  ipoWithholdingTaxes: number;
  ipoSoldTransferred: number;
  ipoNetRemainingAtBroker: number;
  ipoOriginalGranted: number;
  ipoOriginalGrantedValue: any;
  ipoOriginalAdditionalRsu: number;
  ipoOriginalAdditionalRsuValue: any;
  ipototalAwardedValue: any;
  shareActGrantDate: any;
  displayVestCard: boolean;

  sharesUnvested: any;
sharesUnvestedValue: any;
vestedButUnreleased: any;
vestedButUnreleasedValue: any;
totalUnreleased: any;
totalUnreleasedValue: any;
iporsuDetailsReleased: any;
withheldForTaxData: any;
withheldForTaxValue: any;
withHoldingRefund: any;
withHoldingRefundValue: any;
totalWithheld: any;
totalWithheldValue: any;
totalSharesRelease: any = 0;
totalSharesReleaseValue: any = 0;
  ipoWithholdingForTaxes: any;
  displayReleaseCard: boolean;
  displayGrantCard: boolean;

  //tooltip variable
  vestColumn: string = tScript.rsuipo_vest_column;
  vstatusColumn: string = tScript.rsuipo_vstatus_column;
  origSchedVest: string = tScript.rsuipo_origSchedVest;
  addRsuLieuDividends: string = tScript.rsuipo_addRsuLieuDividends;
  totalSchedVest: string = tScript.rsuipo_totalSchedVest;
  vestfmv: string = tScript.rsuipo_vestfmv;
  tooltipVestValue: string = tScript.rsuipo_vestvalue;


  constructor(private globals: Globals, 
    private app: AppComponent, private router: Router, 
    private HoldingProgramDetailsRSUIpoService: HoldingProgramDetailsRSUIPOService,
    private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {
    this.getRSUIPODetails();
    this.getRSUIPODetailsVestInfo();
    this.IPOviewVestDate(this.IPOvestData);
    
  }

  // DEFAULT ACCORDION START
getRSUIPODetails() {
  try {
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetails(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      try {
        if(Array.isArray(data) && data.length > 0) {
          let ipoRSUDetails = data[0][0];

          if(ipoRSUDetails != null || ipoRSUDetails != undefined){
            this.ipoGrantDesc = ipoRSUDetails.GrantDescription;
            this.shareActGrantDate = ipoRSUDetails.GrantDate;
            this.ipoGrantPrice = ipoRSUDetails.GrantPrice;
            this.ipoTotalAwarded = ipoRSUDetails.TotalAwarded;
            this.ipoUnreleased = ipoRSUDetails.Unreleased;
            this.ipoForfeited = ipoRSUDetails.Forfeited;
            this.ipoReleased = ipoRSUDetails.Released;
            this.ipoWithholdingTaxes = ipoRSUDetails.WithheldForTax;
            this.ipoSoldTransferred = ipoRSUDetails.SoldTransferred;
            this.ipoNetRemainingAtBroker = ipoRSUDetails.NetRemainingAtBroker;
            this.ipoWithholdingForTaxes = ipoRSUDetails.WithholdingforTaxes;
          }
    
          let iporsudetailsGrantedData = data[1][0];   
          
          if(iporsudetailsGrantedData != null || iporsudetailsGrantedData != undefined){
            this.ipoOriginalGranted = iporsudetailsGrantedData.OriginalGranted;
            this.ipoOriginalGrantedValue = iporsudetailsGrantedData.OriginalGrantedValue;
            this.ipoOriginalAdditionalRsu = iporsudetailsGrantedData.AdditionalRsu;
            this.ipoOriginalAdditionalRsuValue = iporsudetailsGrantedData.AdditionalRsuValue;
            this.ipototalAwardedValue = this.ipoOriginalGrantedValue + this.ipoOriginalAdditionalRsuValue; 
    
            if (this.ipoOriginalGranted == this.ipoForfeited){
              this.displayVestCard = false;
              this.displayReleaseCard = false;
              this.displayGrantCard = false;
            }
            else {
              this.displayVestCard = true;
              this.displayReleaseCard = true;
              this.displayGrantCard = true;
            }
          }

          let iporsuDetailsUnvestedData = data[2][0];

          if(iporsuDetailsUnvestedData != null || iporsuDetailsUnvestedData != undefined){
            this.sharesUnvested = iporsuDetailsUnvestedData.SharesUnvested;
            this.sharesUnvestedValue = this.sharesUnvested*this.ipoGrantPrice;
            this.vestedButUnreleased = iporsuDetailsUnvestedData.VestedButUnreleased;
            this.vestedButUnreleasedValue = this.vestedButUnreleased*this.ipoGrantPrice;
            this.totalUnreleased = iporsuDetailsUnvestedData.TotalUnreleased;
            this.totalUnreleasedValue = this.totalUnreleased*this.ipoGrantPrice;
          }

          this.iporsuDetailsReleased = data[3];

          for(let i=0; i<this.iporsuDetailsReleased.length;i++){
            this.totalSharesRelease += this.iporsuDetailsReleased[i].Shares;
            this.totalSharesReleaseValue += this.iporsuDetailsReleased[i].Value;
          }

          let iporsuDetailsWithheldData = data[4][0];
          
          if(iporsuDetailsWithheldData != null || iporsuDetailsWithheldData != undefined){
            this.withheldForTaxData = iporsuDetailsWithheldData.WithheldForTax;
            this.withheldForTaxValue = iporsuDetailsWithheldData.WithheldForTaxValue;
            this.withHoldingRefund = iporsuDetailsWithheldData.WithHoldingRefund;
            this.withHoldingRefundValue = iporsuDetailsWithheldData.WithHoldingRefundValue;
            this.totalWithheld = iporsuDetailsWithheldData.TotalWithheld;
            this.totalWithheldValue = iporsuDetailsWithheldData.TotalWithheldValue;
          }
        }
    
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
    error.message += errorLocation;
    throw error;
  }
}
// DEFAULT ACCORDION - END
  

  // VEST ACCORDION START
getRSUIPODetailsVestInfo() {
  try {
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetailsVestInfo(this.user.OptNum, this.IPOvestRsuDate).pipe(map(res => res)).subscribe(data => {
      try {
        if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0)) {
          this.IPOvestTotalShares = 0;
          this.IPOvestDataSource = data[0];
    
          let IPOvest = data[1][0];
    
          for(let i = 0; i < this.IPOvestDataSource.length; i++) {
            this.IPOvestTotalShares += this.IPOvestDataSource[i].Shares;
          }

          if(IPOvest != null || IPOvest != undefined){
            this.IPOvestAdditionalRsu = IPOvest.AdditionalRsu;
            this.IPOvestLocalCurrency = IPOvest.LocalCurrency;
            this.IPOvestOrigGrantedRsu = IPOvest.OriginalGrantedRsu;
            this.IPOvestTotalAward = IPOvest.TotalAward;
            this.IPOvestValue = IPOvest.Value;
            this.IPOvestValueLocal = IPOvest.ValueLocal;
            this.IPOvestVestFmv = IPOvest.VestFMV;
          }
    
          
    
          if(this.IPOvestLocalCurrency == "USD" || this.IPOvestLocalCurrency == "") {
            this.IPOvestDisplayValueLocal = false;
          } else {
            this.IPOvestDisplayValueLocal = true;
          }
    
          if(this.ipoForfeited) this.IPOvestLoading = false;
          this.IPOvestLoading = false;
          
        } else {
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsVestInfo");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsVestInfo");
      error.error.message += errorLocation;
      throw error.error;
    });
  
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsVestInfo");
    error.message += errorLocation;
    throw error;
  }
}

IPOviewVestDate(IPOvestData) {
  try {
    this.IPOvestLoading = true;
    if(IPOvestData != undefined){
      this.IPOvestRsuDate = IPOvestData.VestDate;
      this.IPOvestRsuDate = "'" + moment(this.IPOvestRsuDate).format('MM/DD/YYYY') + "'";
    }
    
    this.getRSUIPODetailsVestInfo();
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "IPOviewVestDate");
    error.message += errorLocation;
    throw error;
  }
}
// VEST ACCORDION END

commafy(num: any, value?: number) {
  try {
  // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value > 0) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message += errorLocation;
    throw error;
  }
}
}
