import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Globals } from '../../globals';
import { HoldingprogramdetailsRsuService } from '../HoldingProgramDetailsRSUService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { tScript } from '../../services/enumerations/enumerations';
import { LoadingService } from '../../services/LoadingService';

@Component({
  selector: 'app-rsu-vest-card',
  templateUrl: './rsu-vest-card.component.html',
  styleUrls: ['./rsu-vest-card.component.css']
})
export class RsuVestCardComponent implements OnInit {

  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));

  awardDate: any;
  errorVestCard: boolean = false;
  errorLocation: string = " + [Component: Rsudetails, Method: methodName]";

  vestRsuDate: string = "null";
  vestTotalShares: number;
  vestDataSource: any;
  displayedVestColumns: string[] = ['VestDate', 'Shares', 'Status'];
  vestAdditionalRsu: any;
  vestLocalCurrency: any;
  vestOrigGrantedRsu: any;
  vestTotalAward: any;
  vestValue: any;
  vestValueLocal: any;
  vestVestFmv: any;
  vestDisplayValueLocal: boolean;
  vestLoading: boolean;
  displayVestCard: boolean;
  vestCardLoading: boolean = true;
  vestTableLoading: boolean;

  keyExecVestHideShow: boolean = false; 
  keyExecToggleValue: string;

  // Tooltip
  detailsVest_Vest: string = tScript.rsudetailspage_v;
  detailsVest_Status: string = tScript.rsudetailspage_s;
  detailsVest_OriginallyScheduledForVest: string = tScript.rsudetailspage_osv;
  detailsVest_AdditionalRSUsInLieuOfDividend: string = tScript.rsudetailspage_ald;
  detailsVest_TotalScheduledForVest: string = tScript.rsudetailspage_tsv;
  detailsVest_VestFMV: string = tScript.rsudetailspage_vf;
  detailsVest_Value: string = tScript.rsudetailspage_value;

  yearCode: any[] = [];
  planNum: any[] = [];

  constructor(
    private globals: Globals,
    private rsuDetailsService: HoldingprogramdetailsRsuService,
    private errorNotificationService: ErrorNotificationService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.checkErrorMessage();
    this.getCodeDetails();
  }

  getRSUDetailsVestInfo() {
    try {
      this.awardDate = this.programDetails.GrantYear;
      this.rsuDetailsService.getRSUDetailsVestInfo(this.programDetails.GrantNum, this.vestRsuDate).subscribe(data => {
        try {
          if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0)) { 
            this.vestTotalShares = 0;
            sessionStorage.setItem("Vest Data Source",JSON.stringify(data[0])); //data for the Vest is Session Storaged
            this.vestDataSource = data[0];
            let vest = data[1][0];
    
            for(let i = 0; i < this.vestDataSource.length; i++) {
              this.vestTotalShares += this.vestDataSource[i].Shares;
            }
  
            if(vest != null || vest != undefined){
              this.vestAdditionalRsu = vest.AdditionalRsu;
              this.vestLocalCurrency = vest.LocalCurrency;
              this.vestOrigGrantedRsu = vest.OriginalGrantedRsu;
              this.vestTotalAward = vest.TotalAward;
              this.vestValue = vest.Value;
              this.vestValueLocal = vest.ValueLocal;
              this.vestVestFmv = vest.VestFMV;
      
              if(this.vestLocalCurrency == "USD" || this.vestLocalCurrency == "") {
                this.vestDisplayValueLocal = false;
              } else {
                this.vestDisplayValueLocal = true;
              }
            }

            if(!this.vestTableLoading) {
              this.loadingService.setExecutedComponent();
            }
    
            this.vestCardLoading = false;
            this.vestTableLoading = false;
          } else {
            if(!this.vestTableLoading) {
              this.loadingService.setExecutedComponent();
            }

            this.vestCardLoading = false;
            this.vestTableLoading = false;
            this.displayVestCard = false;
          }
  
          // this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
        } catch(error) {
          this.errorVestCard = true;	
          this.vestCardLoading = false;	
          // this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
          let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsVestInfo");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorVestCard = true;	
        this.vestCardLoading = false;	
        // this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsVestInfo");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorVestCard = true;	
      this.vestCardLoading = false;		
      // this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
      let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsVestInfo");
      error.message += errorLocation;
      throw error;
    }
  }

  viewVestDate(vestData) {  
    try {
      this.vestTableLoading = true;    
      this.vestRsuDate = vestData.VestDate;
      this.vestRsuDate = moment(this.vestRsuDate).format('MM/DD/YYYY');
      this.vestRsuDate = "'" + this.vestRsuDate + "'";
      this.getRSUDetailsVestInfo();
    } catch(error) {
      this.errorVestCard = true;
      this.vestTableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "viewVestDate");
      error.message += errorLocation;
      throw error;
    }
  }

  getCodeDetails() { 
    try {
      let keyExectData = JSON.parse(sessionStorage.getItem("Key Exec Toggle Data"));
      let code = keyExectData[0];
      
      this.yearCode.push(code[0]);
      this.yearCode.push(code[1]);
      this.yearCode.push(code[2]);
      this.yearCode.push(code[3]);   
      
      this.planNum.push(code[4]);
      this.planNum.push(code[5]);
      this.planNum.push(code[6]);

      this.getRSUDetailsVestInfo();
    } catch(error) {
      this.errorVestCard = true;
      this.vestCardLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getCodeDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: displayKeyExecAwardStatus                                                                 
   * Description: Method that shows and hides awards based on the Toggle Value                              
   *                                                                                          
   * #         Date                 Modified by                 Description                             
   * 1         19/Feb/19            Kim Phillip Aying           Initial draft
   * 2         26/Feb/19            Kim Phillip Aying           Computation was added on the Key Exec Toggle
   * 3         06/08/2023           Jewel C. Caudilla           Update Computation for Max/Target/Trend      
   *********************************************************************************/
  displayKeyExecAwardStatus() {
    try {
      this.vestTotalShares = 0;
      let data = JSON.parse(sessionStorage.getItem("Vest Data Source"));

      let newVestData: any[] = [];
        if(this.keyExecToggleValue == "Target")
        {
          newVestData.push(data);
                
          this.vestDataSource = data;
  
          for(let i = 0; i < this.vestDataSource.length; i++) {
            this.vestTotalShares += this.vestDataSource[i].Shares;
          }
  
          this.keyExecVestHideShow = true;
        }
        else if(this.keyExecToggleValue == "Maximum")
        {
          this.keyExecVestHideShow = false;
          for(let i=0; i<data.length; i++)
          {
            if(data[i].Status == "Unvested")
            {
              newVestData.push(data[i]);
              this.vestDataSource = newVestData;
              
              let unreleasedTargetVestTotal = Math.round(this.vestDataSource[i].Shares * (3/2));
              this.vestDataSource[i].Shares = unreleasedTargetVestTotal;
              this.vestTotalShares += this.vestDataSource[i].Shares;
            }
            else if(data[i].Status != "Unvested")
            {
              let array = [];
              this.vestDataSource = array;
            }
            this.keyExecVestHideShow = false;
          }
        }
        else if(this.keyExecToggleValue == "Trend")
        {
          this.keyExecVestHideShow = false;
          for(let i=0; i<data.length; i++)
          {
            if(data[i].Status == "Unvested")
            {
              newVestData.push(data[i]);
              this.vestDataSource = newVestData;
                          
              let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) {
                    
                    let unreleasedTargetVestTotal = Math.round(this.vestDataSource[i].Shares);
                    let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                    let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                    let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                    let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                    let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                    this.vestDataSource[i].Shares = unreleasedTrendVestTotal;
                    this.vestTotalShares += this.vestDataSource[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //c/o Kim
                if(!trendObtained) {
                  let unreleasedTargetVestTotal = Math.round(this.vestDataSource[i].Shares);
                  this.vestDataSource[i].Shares = unreleasedTargetVestTotal;
                  this.vestTotalShares += this.vestDataSource[i].Shares;
                }
            }
            else if(data[i].Status != "Unvested")
            {
              let array = [];
              this.vestDataSource = array;
            }
            this.keyExecVestHideShow = false;
          }       
        }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "displayKeyExecAwardStatus");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value >= 0) {
            let number = (num*1).toLocaleString('en', {
              //maximumFractionDigits: value
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Rsudetails") != -1 && this.user != null) {
                webPage = "Rsudetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
}
