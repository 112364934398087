import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationaccessService } from '../api/ApplicationaccessService';
import { RoleaccessService } from '../api/RoleaccessService';
import { ToolaccessService } from '../api/ToolaccessService';
import { EaLoadingService } from '../EaLoadingService';

@Injectable()
export class EquityadminGuard  {

  constructor(
    private loadingService: EaLoadingService,
    private applicationAccessService: ApplicationaccessService,
    private toolAccessService: ToolaccessService,
    private roleAccessService: RoleaccessService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let url = state.url;
    this.loadingService.setLoadingState(true);
    return this.getUserAccess(url).then((val) => {
      this.loadingService.setLoadingState(false);
      return val;
    });
  }

  /**********************************************************************************
  * Method Name: getUserAccess                                                                 
  * Description: Gets the user access.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/24/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getUserAccess(url: string): Promise<boolean> {
    let userAccess = new Promise<any>((resolve) => {
      Promise.all([
        url.includes('eamain')? this.toolAccessService.getSEMToolAccess(): true,
        this.applicationAccessService.getApplicationAccess(),
        this.toolAccessService.getToolAccess(),
        this.roleAccessService.getRoleAccess()
      ]).then((val) => {
        if(val) {
          resolve(true);
        }
      });
    });

    return userAccess;
  }

}
