import { Component, OnInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { StaticDataState } from '../../../../services/ea/states/billto.state';
import { ApiService } from '../../../../services/ea/api/ApiService';
import { Globals } from '../../../../globals';
import { BdraccessService } from '../../../../services/ea/api/BdrAccessService';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../../services/ea/shared/modal/modal.component';
import { BillTo } from '../../../../services/ea/actions/billto.actions';

@Component({
  selector: 'app-billto',
  templateUrl: './billto.component.html',
  styleUrls: ['./billto.component.css']
})
export class BilltoComponent implements OnInit {

  @Select(StaticDataState.getStaticData) staticData: any;

  staticContent: Array<any>;
  billToHeader: string;
  billToEquityType: string;
  billToJournalEntryType: string;
  billToCountry: string;
  billToConfirmMessage: string;
  billToSubmitMessage: string;
  billToErrorMessage: string;
  billToStaticContent: Array<any> = [];

  equityTypeList: Array<any> = [{ Equity_Type_Desc: '', Equity_Type_ID: 0}];
  equityTypeId: number;

  journalEntryList: Array<any> = [{ JOURNAL_GROUP_DESC: '', JOURNAL_GROUP_ID: -1}];
  journalEntryValue: string = "";
  journalEntryTypeId: number;

  countryList: Array<any> = [{ COUNTRY_DESC: '', COUNTRY_ID: 0 }];
  countryValue: string = "";
  countryId: number;

  companyCode: Array<any>;
  deltaFlag: Array<any>;
  dataSource: any[] = []

  hasBillToItems: boolean = false;
  hasEquityTypeId: boolean = false;
  hasJournalEntryTypeId: boolean = false;  
  hasCountryId: boolean = false;  
  disabledSearchBtn: boolean = true;  
   
  showFieldInfo: boolean = false;
  isLoading: boolean = true;
  itemLoading: boolean;
  apiCount: number = 0;
  isError: boolean = false;

  constructor(
    private apiService: ApiService,
    private globals: Globals,
    private store: Store,
    private bdrAccessService: BdraccessService,
    private modal: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) { 
    this.bdrAccessService.billToError$.subscribe(state => {
      if(state) {
        this.isError = state;
        this.isLoading = false;
        if(!(this.changeDetectorRef as ViewRef).destroyed) {
          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  ngOnInit() {
    this.getStaticContent();
    this.getEquityTypeList();
    this.getJournalEntryTypeList();
    this.getCountryList();
    this.getDeltaFlag();
  }

  /**********************************************************************************
  * Method Name: getStaticContent */ /* istanbul ignore next line */                                                                  
  /* Description: Gets the static contents for bill to. */ /* istanbul ignore next line */                                                                                                            
  /* #         Date                 Modified by                    Description */ /* istanbul ignore next line */                               
  /* 1         04/27/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getStaticContent(): void {
    this.staticData.subscribe(data => {
      try {
        this.staticContent = data.staticData;
        if(this.staticContent != null) {
          this.billToHeader = this.staticContent[this.getStaticIndex('ea.ejet.billto.header')].MESSAGE_VALUE;
          this.billToEquityType = this.staticContent[this.getStaticIndex('ea.ejet.import.form.equity.type')].MESSAGE_VALUE;
          this.billToJournalEntryType = this.staticContent[this.getStaticIndex('ea.ejet.billto.journal.entry')].MESSAGE_VALUE;
          this.billToCountry = this.staticContent[this.getStaticIndex('ea.ejet.billto.country')].MESSAGE_VALUE;
          this.billToConfirmMessage = this.staticContent[this.getStaticIndex('ea.ejet.billto.confirm.message')].MESSAGE_VALUE;
          this.billToSubmitMessage = this.staticContent[this.getStaticIndex('ea.ejet.billto.submit.message')].MESSAGE_VALUE;
          this.billToErrorMessage = this.staticContent[this.getStaticIndex('ea.ejet.billto.error.message')].MESSAGE_VALUE;
        }
      } catch(error) {
        this.isLoading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getStaticContents', 'Billto');
        throw error;
      }
    });  
  }  
   
  /**********************************************************************************  
  * Method Name: getStaticIndex                                                                 
  * Description: Gets the static index for static content.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/27/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getStaticIndex(key: string): number {
    try {
      let index: number = null;
      for(let i = 0; i < this.staticContent.length; i++) {
        if(this.staticContent[i].MESSAGE_NAME == key) {
          index = i;
        }
      }
      return index;
    } catch(error) {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getStaticIndex', 'Billto');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getEquityTypeList                                                                   
  * Description: Gets the equity type list.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getEquityTypeList(): void {
    let body = { functionName: 'getEquityTypeList' };
     
    this.apiService.postData(this.globals.manageImportInvoiceApi, body).subscribe(data => {  
      try {  
        if(data) {  
          let body = data.body;
          if(body.length > 0) {
            let bodyData = body;
            if(bodyData != this.globals.notAuthorized) {
              for(let i = 0; i < body.length; i++) {
                this.equityTypeList.push(body[i]);
              }
            } else {
              this.bdrAccessService.setBdrAccess(false);
            }
          }
        }

        this.apiCount++;
        this.checkApiCall();
      } catch(error) {
        this.isLoading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getEquityTypeList', 'Billto');
        throw error;
      }
    }, error => {
      this.isLoading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getEquityTypeList', 'Billto');
      throw error;
    });
  }
   
  /********************************************************************************** 
  * Method Name: setEquityType                                                                   
  * Description: Sets the selected equity type.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setEquityType(equityType: number): void {
    if(equityType > 0) {
      this.hasEquityTypeId = true;
      this.equityTypeId = equityType;
      this.disableSearchButton();
    } else {
      this.hasEquityTypeId = false;
      this.disableSearchButton();
    }
  }

  /**********************************************************************************
  * Method Name: getJournalTypeList                                                                 
  * Description: Gets the journal entry type list.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/  
  getJournalEntryTypeList(): void {  
    let body = {  
      functionName: 'getBillToJrnalTypeList'  
    };

    this.apiService.postData(this.globals.manageBillToApi, body).subscribe(data => {
      try {
        if(data != null) {
          let body = data.body;
          if(body.length > 0) {
            for(let i =0; i < body.length; i++) {
              this.journalEntryList.push(body[i]);
            }
          }
        }

        this.apiCount++;
        this.checkApiCall();
      } catch(error) {
        this.isLoading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getJournalEntryTypeList', 'Billto');
        throw error;
      }
    }, error => {
      this.isLoading = false;
      /* istanbul ignore next line */
      this.isError = true;  
      /* istanbul ignore next line */
      error.error.message += this.apiService.setErrorContents('getJournalEntryTypeList', 'Billto');  
      throw error;
    });
  }
   
  /**********************************************************************************  
  * Method Name: setJournalEntryType                                                                   
  * Description: Sets the selected journal entry type.              
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setJournalEntryType(journalEntry: number): void {
    if(journalEntry >= 0) {
      this.hasJournalEntryTypeId = true;
      this.journalEntryTypeId = journalEntry;
      this.disableSearchButton();
    } else {
      this.hasJournalEntryTypeId = false;
      this.disableSearchButton();
    }
  }

  /**********************************************************************************
  * Method Name: getCountryList                                                                 
  * Description: Gets the country list.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/  
  getCountryList(): void {  
    let body = {  
      functionName: 'getBillToCountryList'  
    };

    this.apiService.postData(this.globals.manageBillToApi, body).subscribe(data => {
      try {
        if(data != null) {
          let body = data.body;
          if(body.length > 0) {
            for(let i =0; i < body.length; i++) {
              this.countryList.push(body[i]);
            }
          } 
        }

        this.apiCount++;
        this.checkApiCall();
      } catch(error) {
        this.isLoading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getCountryList', 'Billto');
        throw error;
      }
    }, error => {
      this.isLoading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getCountryList', 'Billto');
      throw error;
    });
  }
   
  /********************************************************************************** 
  * Method Name: setCountry                                                                    
  * Description: Sets the selected country.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setCountry(countryId: number): void {
    if(countryId > 0) {
      this.hasCountryId = true;
      this.countryId = countryId;
      this.disableSearchButton();
    } else {
      this.hasCountryId = false;
      this.disableSearchButton();
    }
  }
  
  /**********************************************************************************
  * Method Name: getDeltaFlag                                                                 
  * Description: Gets the delta flag.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                               
  * 1         04/22/20             Dalemark P. Suquib             Initial draft    
  ***********************************************************************************/  
  getDeltaFlag(): void {  
    let body = { 
      functionName: 'getBillToDeltaFlag'
    };

    this.apiService.postData(this.globals.manageBillToApi, body).subscribe(data => {
      try {
        if(data != null) {
          let body = data.body;
          if(body.length > 0) {
            this.deltaFlag = body;
          }
        }

        this.apiCount++;
        this.checkApiCall();
      } catch(error) {
        this.isLoading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getDeltaFlag', 'Billto');
        throw error;
      }
    }, error => {
      this.isLoading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getDeltaFlag', 'Billto');
      throw error;
    });
  }

  /**********************************************************************************
  * Method Name: getCompanyCode                                                                 
  * Description: Gets the company code.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/  
  getCompanyCode(): void {  
    if(!this.disabledSearchBtn && !this.itemLoading) {
      this.showFieldInfo = true;
      this.itemLoading = true;  
        
      let body = {  
        functionName: 'getBillToCompanyCode'
      };

      this.apiService.postData(this.globals.manageBillToApi, body).subscribe(data => {
        try {  
          if(data != null) {  
            let body = data.body;  
            if(body.length > 0) {
              this.companyCode = body;
            }

            this.getBusData();
          }
        } catch(error) {
          this.isError = true;
          error.message += this.apiService.setErrorContents('getCompanyCode', 'Billto');
          throw error;
        }
      }, error => {
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getCompanyCode', 'Billto');
        throw error;
      });
    }
  }

  /**********************************************************************************
  * Method Name: getBusData                                                                 
  * Description: Gets the bus data.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
 /* istanbul ignore next line */
  getBusData(): void {
    /* istanbul ignore next line */
    this.dataSource = [];
    let body = { 
      functionName: 'getBusDataBillTo',  
      countryId: this.countryId,  
      equityTypeId: this.equityTypeId,
      journalEntryTypeId: this.journalEntryTypeId
    };

    this.store.dispatch(new BillTo.ClearBillToItem);  
    this.apiService.postData(this.globals.manageBillToApi, body).subscribe(data => {  
      try {  
        if(data != null) {
          let body = data.body;
          if(body.length > 0) {  
            let bodyData = body;  
            if(bodyData != this.globals.notAuthorized) {  
              this.dataSource = body;
              this.setBillToItems();
            } else {
              this.openModal();
            }
          } else {
            this.itemLoading = false;
            this.hasBillToItems = false;
          } 
        }
      } catch(error) {  
        this.isError = true;  
        error.message += this.apiService.setErrorContents('getBusData', 'Billto');  
        throw error;
      }  
    }, error => {  
      this.isError = true;  
      error.error.message += this.apiService.setErrorContents('getBusData', 'Billto');
      throw error;
    });
  }  
   
  /**********************************************************************************  
  * Method Name: setBillToItems                                                                 
  * Description: Sets the bus data and to be displayed on the table.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setBillToItems(): void {
    try {
      for(let i = 0; i < this.dataSource.length; i++) {
        this.store.dispatch(new BillTo.AddBillToItem(this.dataSource[i]));
      }
  
      setTimeout(() => {
        this.itemLoading = false;
        this.hasBillToItems = true;
      });
    } catch(error) {
      this.isError = true;
      error.message += this.apiService.setErrorContents('setBillToItems', 'Billto');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: disableSearchButton                                                                 
  * Description: Checking for the button to be disabled or enabled.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  disableSearchButton(): void {
    if(this.hasEquityTypeId && this.hasJournalEntryTypeId && this.hasCountryId) {
      this.disabledSearchBtn = false;
    } else {
      this.disabledSearchBtn = true;
    }
  }

  /**********************************************************************************
  * Method Name: openModal                                                                 
  * Description: Opens the modal.                 
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/10/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  openModal(): void {
    let content: any = {
      header: this.billToHeader,
      body: "You're not Authorized to do this action.",
      hasBtn1: true,  
      hasBtn2: false,  
      btn1Name: 'OK',  
      btn2Name: null  
    }
    let modalRef = this.modal.open(ModalComponent, {
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        content
      }
    });

    modalRef.afterClosed().subscribe(result => {
      if(result) {  
        this.itemLoading = false;
        this.showFieldInfo = false;
      }
    });
  }

  /**********************************************************************************
  * Method Name: checkApiCall                                                                 
  * Description: Checks api call of method.                 
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/10/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  checkApiCall(): void {
    if(this.apiCount > 3) {
      this.isLoading = false;
    } else {
      this.isLoading = true;
    }
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
    this.store.dispatch(new BillTo.ClearBillToItem);
  }

}
