import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
selector: 'input[lettersOnly]'
})
export class LetterDirective {

    constructor(private _el: ElementRef) { }

        @HostListener('input', ['$event']) onInputChange(event) {
            const initialValue = this._el.nativeElement.value;
            this._el.nativeElement.value = initialValue.replace(/[^a-zA-Z\. -\']*/g, ''); ///[^0-9\.]*/g
            if (initialValue !== this._el.nativeElement.value) {
                event.stopPropagation();
            }
            
    }

} 