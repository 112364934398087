import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Globals } from '../../globals';
import { HoldingprogramdetailsRsuService } from '../HoldingProgramDetailsRSUService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { BrokerType, tScript } from '../../services/enumerations/enumerations';
import { LoadingService } from '../../services/LoadingService';

@Component({
  selector: 'app-rsu-rel-card',
  templateUrl: './rsu-rel-card.component.html',
  styleUrls: ['./rsu-rel-card.component.css']
})
export class RsuRelCardComponent implements OnInit {

  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));

  keyExecToggleValue: string;

  awardDate: any;
  errorRelCard: boolean = false;
  errorLocation: string = " + [Component: Rsudetails, Method: methodName]";

  relRsuDate: string = "null";
  relTotalShares: number;
  relSetCurrencyValue: string;
  relAdditionalRsu: any;
  relGrossReleased: any;
  relGrossReleasedLoc: any;
  relGrossReleasedUsd: any;
  relLocalCurrencyCd: any;
  relNetReleased: any;
  relNetReleasedLoc: any;
  relNetReleasedUsd: any;
  relOrigScheduled: any;
  relReleaseFmv: any;
  relTaxWithholdingMethod: any;
  relTotalScheduledRsu: any;
  relWithheldForTax: any;
  relWithheldForTaxUsd: any;
  relWithheldForTaxValueLoc: any;
  relWithholdingRefund: any;
  relWithholdingRefundLoc: any;
  relWithholdingRefundUsd: any;
  netReleased: any;
  relDisplayCurrencyDropdown: boolean;
  releaseLoading: boolean;
  releaseDataSource: any;
  displayedRelColumns: string[] = ['ReleaseDate', 'Shares', 'Status']; 
  displayReleaseCard: boolean;
  releaseCardDetails: boolean;
  relCardLoading: boolean = true;
  relTableLoading: boolean;

  keyExecRelHideShow: boolean = false; 
  divCardPresent: boolean;

  // Tooltip
  detailsRelease_Release: string = tScript.rsudetailspage_release;
  detailsRelease_Status: string = tScript.rsudetailspage_status;
  detailsRelease_OriginallyScheduledForRelease: string = tScript.rsudetailspage_osr;
  detailsRelease_AdditionalRSUsInLieuOfDividend: string = tScript.rsudetailspage_ard;
  detailsRelease_ReleaseFMV: string = tScript.rsudetailspage_rf;
  detailsRelease_TaxWithholdingMethod: string = tScript.rsudetailspage_twm;
  detailsRelease_GrossReleased: string = tScript.rsudetailspage_gr;
  detailsRelease_WithheldForTax: string = tScript.rsudetailspage_wft;
  detailsRelease_WithholdingRefund: string = tScript.rsudetailspage_wr;

  yearCode: any[] = [];
  planNum: any[] = [];

  constructor(
    private globals: Globals,
    private rsuDetailsService: HoldingprogramdetailsRsuService,
    private errorNotificationService: ErrorNotificationService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.checkErrorMessage();
    this.getCodeDetails();
  }

  getRSUDetailsRelInfo() {
    try {
      this.awardDate = this.programDetails.GrantYear;

      this.rsuDetailsService.getRSUDetailsRelInfo(this.programDetails.GrantNum, this.relRsuDate).subscribe(data => {
        try {
          if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0 || data[3].length > 0)) { 
            this.relTotalShares = 0;
            this.relSetCurrencyValue = "USD";        
            let relInfoTable = data[0];
            let release = data[1][0];
            
            for(let i = 0; i < relInfoTable.length; i++) {
              if(relInfoTable[i].StatusFlag == 1) {
                relInfoTable[i].Status = "Released - " + BrokerType[relInfoTable[i].BrokerCd]; // set the status value base on the status flag
              } else if(relInfoTable[i].StatusFlag == 2) {
                relInfoTable[i].Status = "Released"; // set the status value base on the status flag
              } else if(relInfoTable[i].StatusFlag == 3) {
                relInfoTable[i].Status = "Unreleased"; // set the status value base on the status flag
              } else if(relInfoTable[i].StatusFlag == 4) {
                relInfoTable[i].Status = "Forfeited"; // set the status value base on the status flag
              }
              this.relTotalShares += relInfoTable[i].Shares;
            }
    
            this.releaseDataSource = relInfoTable;
            sessionStorage.setItem("Rel Data Source",JSON.stringify(this.releaseDataSource)); //data for Release is Session Storaged
            this.relAdditionalRsu = release.AdditionalRsu;
            this.relGrossReleased = release.GrossReleased;
            this.relGrossReleasedLoc = release.GrossReleasedLoc;
            this.relGrossReleasedUsd = release.GrossReleasedUsd;
            this.relLocalCurrencyCd = release.LocalCurrencyCd;
            this.relNetReleased = release.NetReleased;
            this.relNetReleasedLoc = release.NetReleasedLoc;
            this.relNetReleasedUsd = release.NetReleasedUsd;
            this.relOrigScheduled = release.OriginallyScheduled;
            this.relReleaseFmv = release.ReleaseFmv;
            this.relTaxWithholdingMethod = release.TaxWithholdingMethod;
            this.relTotalScheduledRsu = release.TotalScheduledRsu;
            this.relWithheldForTax = release.WithheldForTax;
            this.relWithheldForTaxUsd = release.WithheldForTaxUsd;
            this.relWithheldForTaxValueLoc = release.WithheldForTaxLoc;
            this.relWithholdingRefund = release.WithholdingRefund;
            this.relWithholdingRefundLoc = release.WithholdingRefundLoc;
            this.relWithholdingRefundUsd = release.WithholdingRefundUsd;
    
            this.netReleased = data[3];
  
            if(this.relTaxWithholdingMethod == "Payroll Withholding") {
              this.relWithheldForTax = "Refer to Earnings Statements";
              this.relWithheldForTaxUsd = "Refer to Earnings Statements";
              this.relWithheldForTaxValueLoc = "Refer to Earnings Statements";
            }
    
            if(this.relLocalCurrencyCd == "USD" || this.relLocalCurrencyCd == "") {
              this.relDisplayCurrencyDropdown = false;
            } else {
              this.relDisplayCurrencyDropdown = true;
            }

            if(!this.relTableLoading) {
              this.loadingService.setExecutedComponent();
            }
    
            this.relTableLoading = false;
            this.relCardLoading = false;
          } else {
            if(!this.relTableLoading) {
              this.loadingService.setExecutedComponent();
            }

            this.relTableLoading = false;
            this.relCardLoading = false;
            this.displayReleaseCard = false;
          }

          // this.displayReleaseCard == true ? this.keyExecRelHideShow = true : "";
          // this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
        } catch(error) {
          this.relTableLoading = false;
          this.relCardLoading = false;
          this.errorRelCard = true;	
          // this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
          let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsRelInfo");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.relTableLoading = false;
        this.relCardLoading = false;
        this.errorRelCard = true;	
        // this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsRelInfo");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.relTableLoading = false;
      this.relCardLoading = false;
      this.errorRelCard = true;	
      // this.showKeyExectoggle != false ? this.selectKeyExecToggle(this.keyExecToggle) : "";
      let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsRelInfo");
      error.message += errorLocation;
      throw error;
    }
  }

  viewReleaseDate(releaseData) {  
    try {
      this.relTableLoading = true;
    
      // if(releaseData.Status == "Unreleased")
      // {
      //   this.releaseCardDetails = true;
      // }
      // else if(releaseData.Status != "Unreleased")
      // {
      //   this.releaseCardDetails = false;
      // }
      
      this.relRsuDate = releaseData.ReleaseDate;
      this.relRsuDate = moment(this.relRsuDate).format('MM/DD/YYYY');
      this.relRsuDate = "'" + this.relRsuDate + "'";
      this.getRSUDetailsRelInfo();
    } catch(error) {
      this.relTableLoading = false;
      this.errorRelCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "viewReleaseDate");
      error.message += errorLocation;
      throw error;
    }
  }

  getCodeDetails() { 
    try {
      let keyExectData = JSON.parse(sessionStorage.getItem("Key Exec Toggle Data"));
      let code = keyExectData[0];
      
      this.yearCode.push(code[0]);
      this.yearCode.push(code[1]);
      this.yearCode.push(code[2]);
      this.yearCode.push(code[3]);  
      
      this.planNum.push(code[4]);
      this.planNum.push(code[5]);
      this.planNum.push(code[6]);

      this.getRSUDetailsRelInfo();
    } catch(error) {
      this.relCardLoading = false;
      this.errorRelCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "getCodeDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: displayKeyExecAwardStatus                                                                 
   * Description: Method that shows and hides awards based on the Toggle Value                              
   *                                                                                          
   * #         Date                 Modified by                 Description                             
   * 1         19/Feb/19            Kim Phillip Aying           Initial draft
   * 2         26/Feb/19            Kim Phillip Aying           Computation was added on the Key Exec Toggle 
   * 3         06/08/2023           Jewel C. Caudilla           Update Computation for Max/Target/Trend      
   *********************************************************************************/
  displayKeyExecAwardStatus(){
    try {
      this.relTotalShares = 0;
      let data2 = JSON.parse(sessionStorage.getItem("Rel Data Source"));

      let newRelData: any[] = [];
        if(this.keyExecToggleValue == "Target")
        {
          newRelData.push(data2);
          this.divCardPresent = true;
          this.releaseDataSource = data2;
  
          for(let i = 0; i < this.releaseDataSource.length; i++) {
            this.relTotalShares += this.releaseDataSource[i].Shares;
          }
  
          this.keyExecRelHideShow = true;
        }
        else if(this.keyExecToggleValue == "Maximum")
        {
          this.divCardPresent = false;
          this.releaseCardDetails = true;
          this.keyExecRelHideShow = false;
  
          for(let i=0; i<data2.length; i++)
          {
            if(data2[i].Status == "Unreleased")
            {
              newRelData.push(data2[i]);
              this.releaseDataSource = newRelData;
                          
              let unreleasedTargetVestTotal = Math.round(this.releaseDataSource[i].Shares * (3/2));
              this.releaseDataSource[i].Shares = unreleasedTargetVestTotal;
              this.relTotalShares += this.releaseDataSource[i].Shares;            
            }
            else if(data2[i].Status != "Unreleased")
            {
              newRelData.push(data2[i]);
              this.releaseDataSource = newRelData;            
                             
              this.relTotalShares += this.releaseDataSource[i].Shares; 
            }         
            this.keyExecRelHideShow = false;
          }   
  
        }
        else if(this.keyExecToggleValue == "Trend")
        {
          this.divCardPresent = false;
          this.releaseCardDetails = true;
          this.keyExecRelHideShow = false;
  
          for(let i=0; i<data2.length; i++)
          {
            if(data2[i].Status == "Unreleased")
            {
              newRelData.push(data2[i]);
              this.releaseDataSource = newRelData; 
                          
              let trendObtained: boolean = false;
                for (let k = 0; k < this.yearCode.length; k++)
                {
                  if(this.yearCode[k].Code == this.awardDate) 
                  {                  
                    let unreleasedTargetVestTotal = Math.round(this.releaseDataSource[i].Shares);
                    let unreleasedOiPortionTarget = Math.round(unreleasedTargetVestTotal * (3/4));
                    let unreleasedTsrOptionTarget = Math.round(unreleasedTargetVestTotal) - Math.round(unreleasedOiPortionTarget);
                    let unreleasedOiPortionTrend = Math.round(unreleasedOiPortionTarget * (parseFloat(this.yearCode[k].OI)/100));
                    let unreleasedTsrOptionTrend = Math.round(unreleasedTsrOptionTarget * (parseFloat(this.yearCode[k].TSR)/100));
                    let unreleasedTrendVestTotal = Math.round(unreleasedOiPortionTrend) + Math.round(unreleasedTsrOptionTrend);
                    this.releaseDataSource[i].Shares = unreleasedTrendVestTotal;
                    this.relTotalShares += this.releaseDataSource[i].Shares;
  
                    trendObtained = true;
                  }
                }
  
                //For inquiry c/o Kim
                if(!trendObtained) 
                {
                  let unreleasedTargetVestTotal = Math.round(this.releaseDataSource[i].Shares);
                  this.releaseDataSource[i].Shares = unreleasedTargetVestTotal;
                  this.relTotalShares += this.releaseDataSource[i].Shares;
                }
            }
            else if(data2[i].Status != "Unreleased")
            {
              newRelData.push(data2[i]);
              this.releaseDataSource = newRelData;            
                             
              this.relTotalShares += this.releaseDataSource[i].Shares;   
            }         
            this.keyExecRelHideShow = false;
          }        
        }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "displayKeyExecAwardStatus");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value >= 0) {
            let number = (num*1).toLocaleString('en', {
              //maximumFractionDigits: value
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Rsudetails") != -1 && this.user != null) {
                webPage = "Rsudetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
}
