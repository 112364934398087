import { Injectable } from '@angular/core';
import { Globals } from '../globals';

@Injectable()
export class TokenService {
  esoEid: any;
  token: any;
  token2: any;
  accesstoken: any;
  esoEidFormer: any;

  constructor(private globals: Globals) { }

  getToken() {
      var b2CToken = sessionStorage.getItem("msal.idtoken")
      
      var base64Url = b2CToken.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');
      this.token = JSON.parse(window.atob(base64)); 
      
      if (this.token.idp_access_token != null || this.token.idp_access_token != undefined){
        var activeToken = this.token.idp_access_token;
        var base64Url2 = activeToken.split('.')[1];
        var base642 = base64Url2.replace('-', '+').replace('_', '/');
        this.token2 = JSON.parse(window.atob(base642)); 
        var activeUser = this.token2.enterpriseID;
        var userEid = activeUser;
        this.esoEid = userEid[0];
        var isFormer = "false";
      } else {
        var formerUser = this.token.enterpriseId;
        var userEid = formerUser;
        this.esoEid = userEid;
        var isFormer = "true";
      }


      sessionStorage.setItem(this.globals.isFormer, isFormer);
      sessionStorage.setItem(this.globals.esoEnterpriseId, JSON.stringify(this.esoEid));
      sessionStorage.setItem("access_token", b2CToken);
      sessionStorage.setItem("isTokened", "1");
      sessionStorage.setItem("piwikEid", this.esoEid); 
   
  }
}