
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
// import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { Globals } from '../../../globals';

@Injectable()
export class ApiService {

  headers: Headers;
  // options: RequestOptions;

  constructor(
    private httpClient: HttpClient,
    private globals: Globals
  ) { }

  /**********************************************************************************
  * Method Name: postData                                                                 
  * Description: Calls the api link and returns the data via POST method.                       
  *                                                                                          
  * #         Date                 Modified by                 Description                             
  * 1         01/17/20             Dalemark P. Suquib          Initial draft  
  ***********************************************************************************/
 postData(apiLink: string, params: object): Observable<any> {
  let body = JSON.stringify(params);

  return this.httpClient.post(apiLink, body).pipe(map(res => res), 
    retry(3),
    catchError((res: Response) => this.onError(res)));
}

  /**********************************************************************************
  * Method Name: onError                                                                 
  * Description: Checks the status when error is encountered.                      
  *                                                                                          
  * #         Date                 Modified by                 Description                             
  * 1         01/17/20             Dalemark P. Suquib          Initial draft  
  ***********************************************************************************/
  onError(res: Response) {
    // check status

    return observableThrowError(res);
  }

  /**********************************************************************************
  * Method Name: setErrorContents                                                                 
  * Description: Sets the error contents.                      
  *                                                                                          
  * #         Date                 Modified by                 Description                             
  * 1         06/26/20             Dalemark P. Suquib          Initial draft  
  ***********************************************************************************/
  setErrorContents(methodName: string, webPage: string): string {
    let errorLocation = '[:]Method Name-methodName[:]Web Page-webPage[:]';
    let mapObj = {
      methodName: methodName,
      webPage: webPage
    }

    errorLocation = errorLocation.replace(/methodName|webPage/gi, function(matched) {
      return mapObj[matched];
    })

    return errorLocation;
  }

  /**********************************************************************************
  * Method Name: postErrorContents                                                                 
  * Description: Gets the error contents.                      
  *                                                                                          
  * #         Date                 Modified by                 Description                             
  * 1         06/26/20             Dalemark P. Suquib          Initial draft  
  ***********************************************************************************/
  postErrorContents(errorMessage: string): void {
    if(errorMessage.includes('[:]')) {
      let errorContents = errorMessage.split('[:]');
      let errorMsg = errorContents[0];

      if(errorMsg.indexOf("'") != -1 || errorMsg.indexOf('"') != -1) {
        errorMsg = errorMsg.replace(/'|"/g, "");
      } 

      let methodName = errorContents[1].split('-')[1];
      let webPage = errorContents[2].split('-')[1];
      let body = {
        functionName: "popErrorLogs",
        webComponent: webPage,
        errMessage: "[" + methodName + "] " + errorMsg 
      };

      this.postData(this.globals.manageUserAccessApi, body).subscribe(data => { });
    }
  }

}
