
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class EnrollmenthistorypageService {
    dateFrom: any;
    dateTo: any;

  constructor(private httpClient: HttpClient, private globalVariables: Globals) { }

  getEnrollmentData(optNum, programType, fromDate, toDate, pageIndex): any{ //calls API //Api= backend 
    let apiURL = this.globalVariables.currentAPIUrl; //
    let enrollmentHistoryApi;
    let regEx: any = /\'/gi; 

    if(pageIndex == -99)
    {
        if(programType == "VEIP")
        enrollmentHistoryApi = this.globalVariables.generateVEIPEnrollmentHistoryAPI; // '/dev/sharepricehistory/getSharePriceHistory?From=[param2]&To=[param3]&Page=[param4]'
        else if(programType == "ESPP")
        enrollmentHistoryApi = this.globalVariables.generateESPPEnrollmentHistoryAPI; // '/dev/sharepricehistory/getSharePriceHistory?From=[param2]&To=[param3]&Page=[param4]'
        else if(programType == "All Grants")
        enrollmentHistoryApi = this.globalVariables.generateALLEnrollmentHistoryAPI;

        this.dateFrom = moment(fromDate.replace(regEx,"")).format("YYYY-MM-DD");
        this.dateTo = moment(toDate.replace(regEx,"")).format("YYYY-MM-DD");

        enrollmentHistoryApi = enrollmentHistoryApi.replace("[param1]", optNum);
        enrollmentHistoryApi = enrollmentHistoryApi.replace("[param2]", this.dateFrom);
        enrollmentHistoryApi = enrollmentHistoryApi.replace("[param3]", this.dateTo);
    }
    else
    {
        if(programType == "VEIP")
        enrollmentHistoryApi = this.globalVariables.getEnrollmentVEIPHistoryAPI; // '/dev/sharepricehistory/getSharePriceHistory?From=[param2]&To=[param3]&Page=[param4]'
        else if(programType == "ESPP")
        enrollmentHistoryApi = this.globalVariables.getEnrollmentESPPHistoryAPI; // '/dev/sharepricehistory/getSharePriceHistory?From=[param2]&To=[param3]&Page=[param4]'
        else if(programType == "All Grants")
        enrollmentHistoryApi = this.globalVariables.getEnrollmentAllHistoryAPI;
        
        this.dateFrom = moment(fromDate.replace(regEx,"")).format("YYYY-MM-DD");
        this.dateTo = moment(toDate.replace(regEx,"")).format("YYYY-MM-DD");

        enrollmentHistoryApi = enrollmentHistoryApi.replace("[param1]", optNum);
        enrollmentHistoryApi = enrollmentHistoryApi.replace("[param2]", this.dateFrom);
        enrollmentHistoryApi = enrollmentHistoryApi.replace("[param3]", this.dateTo);
        enrollmentHistoryApi = enrollmentHistoryApi.replace("[param4]", pageIndex);

        // enrollmentHistoryApi = enrollmentHistoryApi.replace("[param1]", "198831");
        // enrollmentHistoryApi = enrollmentHistoryApi.replace("[param2]", "\'2000-03-11\'");
        // enrollmentHistoryApi = enrollmentHistoryApi.replace("[param3]", "\'2019-03-11\'");
    }

    try{
        const api = apiURL + enrollmentHistoryApi; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
}

  getHasESPPData(optNum): any{ //calls API //Api= backend 
    let apiURL = this.globalVariables.currentAPIUrl; //
    let hasESPPAPI = this.globalVariables.getHasESPPAPI;

    hasESPPAPI = hasESPPAPI.replace("[param]", optNum);

    try{
        const api = apiURL + hasESPPAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  getHasVEIPData(optNum): any{ //calls API //Api= backend 
    let apiURL = this.globalVariables.currentAPIUrl; //
    let hasVEIPAPI = this.globalVariables.getHasVEIPAPI;

    hasVEIPAPI = hasVEIPAPI.replace("[param]", optNum);

    try{
        const api = apiURL + hasVEIPAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  getHasGrantData(optNum): any{ //calls API //Api= backend 
    let apiURL = this.globalVariables.currentAPIUrl; //
    let hasGrantAPI = this.globalVariables.getHasGrantAPI;

    hasGrantAPI = hasGrantAPI.replace("[param]", optNum);

    try{
        const api = apiURL + hasGrantAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  getHasRSUData(optNum): any{ //calls API //Api= backend 
    let apiURL = this.globalVariables.currentAPIUrl; //
    let hasRSUAPI = this.globalVariables.getHasRSUAPI;

    hasRSUAPI = hasRSUAPI.replace("[param]", optNum);

    try{
        const api = apiURL + hasRSUAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  getHasOptionsData(optNum): any{ //calls API //Api= backend 
    let apiURL = this.globalVariables.currentAPIUrl;
    let hasOptionsAPI = this.globalVariables.getHasOptionsAPI;

    hasOptionsAPI = hasOptionsAPI.replace("[param]", optNum);

    try{
        const api = apiURL + hasOptionsAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  getEnrollDropDownData(optNum): any{ //calls API //Api= backend 
    let apiURL = this.globalVariables.currentAPIUrl;
    let getEnrollDropDownAPI = this.globalVariables.getEnrollDropDownAPI;

    getEnrollDropDownAPI = getEnrollDropDownAPI.replace("[param]", optNum);

    try{
        const api = apiURL + getEnrollDropDownAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  getUserReportsAPI(param1, param2, param3, param4, param5, param6, param7){
    let apiURL = this.globalVariables.currentAPIUrl;
    let userReportAPI = this.globalVariables.getUserReportsAPI;

    userReportAPI = userReportAPI.replace("[param1]", param1);
    userReportAPI = userReportAPI.replace("[param2]", param2);
    userReportAPI = userReportAPI.replace("[param3]", param3);
    userReportAPI = userReportAPI.replace("[param4]", param4);
    userReportAPI = userReportAPI.replace("[param5]", param5);
    userReportAPI = userReportAPI.replace("[param6]", param6);
    userReportAPI = userReportAPI.replace("[param7]", param7);

    try{
        const api = apiURL + userReportAPI; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }

  getEnrollmentDropdownData(optNum){
    let apiURL = this.globalVariables.currentAPIUrl;
    let enrollmentDropdownApi = this.globalVariables.getEnrollDropDownAPI;

    enrollmentDropdownApi = enrollmentDropdownApi.replace("[param]", optNum);

    try{
        const api = apiURL + enrollmentDropdownApi; 
        return this.httpClient.get(api).pipe(map(res => res), retry(3),
        catchError((res: Response) => this.onError(res))); 
    }catch(err){
        return err;
    }
  }



  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
        statusCode: statusCode,
        error: res
        };
        return observableThrowError(error);
    }


}
