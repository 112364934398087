
import {refCount, publish} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class BdrLoadingService {

  private _BdrLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly BdrLoading$: Observable<boolean> = this._BdrLoading.asObservable().pipe(publish(),refCount(),);

  constructor() { }

  setLoadingState(state: boolean): void {
    this._BdrLoading.next(state);
  }

}
