import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../services/ea/api/ApiService';
import { SemlinkaccessService } from '../../../services/ea/api/SemlinkaccessService';
import { Globals } from '../../../globals';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


export interface partnerSelectionColumns {
  NAME: string;
  OFFICE_NAME: string;
  STATUS: string;
}

@Component({
  selector: 'app-pdsa',
  templateUrl: './pdsa.component.html',
  styleUrls: ['./pdsa.component.css']
})

export class PdsaComponent implements OnInit {

  //variable for validation
  partnerData: any;
  dataSource: any;
  tableContent: any;
  searchInput: string;
  displayedColumns: string[] = ['NAME', 'OFFICE_NAME', 'STATUS'];
  selectBool: boolean = true;
  searchBool: boolean = true;
  selected: any
  selectedFirst: any;
  displayErrorMessage: boolean = false;
  //variable init
  searchCase: boolean = false;
  selectedUser: boolean = false;
  searchOptions: number = 1;
  searchCaseTick: number = 0;
  searchFilterName: boolean = true;
  searchFilterLpn: boolean = false;
  searchOptionDropdownSelect: string
  searchOptionDropdown: any[] = [
    { dropDown: 'Last Name', value: 1 },
    { dropDown: 'First Name', value: 2 },
    { dropDown: 'Local Personnel Number', value: 3 }
  ]
  hasAccess: boolean = false;
  matchSearch: boolean = false;
  initialSearch: boolean = true;
  loading: boolean;
  dataSourceTemp = [];
  isError: boolean = false;
  notice: string = "No items to display. Please fill in the search fields."
  @ViewChild('pdsaPaginator') paginator: MatPaginator;


  constructor(
    private router: Router,
    private globals: Globals,
    private apiService: ApiService,
    private semLinkAccessService: SemlinkaccessService,
    private activeRoute: ActivatedRoute 
  
  ) {
    
  }

  ngOnInit() {
    this.searchOptionDropdownSelect = this.searchOptionDropdown[0].dropDown;
    this.getPdsaAccess();

  }
  /**********************************************************************************
  * Method Name: selectELS                                                                 
  * Description: Stores the optnum & peoplekey of the selected user                 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  *1                               Jikho Enrique                    Initial draft 
  ***********************************************************************************/
  selectELS(row) { //to Highlight the first row
    this.selectedFirst = row.NAME;
    sessionStorage.setItem("optNum", row.OPT_NUM.toString());
    this.semLinkAccessService.setPeopleKey(row.PEOPLE_KEY);
  }

  /**********************************************************************************
  * Method Name: disableSearchButton                                                                 
  * Description: Sets the search button to disabled.                   
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         03/09/20             Joshua Paul E. Peralta          Initial draft  
  * 2         03/13/2020            Almerie T. Dondonay             validation for LPN matchcase
  ***********************************************************************************/
  disableSearchButton(value: string) {
    try {
      if (value == "") { // validation for LPN
        this.searchBool = true;
        this.displayErrorMessage = false;
      } else {
        if (this.searchFilterLpn) {

          if (value.length >= 8 && value.length <= 10) {
            this.searchBool = false;
          } else {
            this.searchBool = true;
          }

        } else {
          this.searchBool = false
        }
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('disableSearchButton', 'pdsa');
      throw error;
    }
  }
  /****************************************************************************************************************
  * Method Name: applyFilter                                                                 
  * Description: For search filter                   
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         03/09/20             Jjoshua Paul E. Peralta          Initial draft  
  * 2         03/13/2020           Almerie T. Dondonay              API connection and validation
  * 3         05/07/2020           Almerie T. Dondonay              Change hardcoded URL into Reusable variable.
  * 4         06/05/2020           Divy O. Ardiente                 Added "page" parameter for getPartnerSelection
  *                                                                 function and added Pagination
  *****************************************************************************************************************/
  applyFilter() {
    if (this.searchInput != null && this.searchBool != true) {
      this.loading = true;
      this.selectBool = true;
      let accessKey: any = {
        "functionName": "getSecPageAccess"
      };
      if (this.searchFilterName == true) {
        this.searchInput = this.searchInput.replace(/[^a-z-A-Z'-]*/g, '');
      }
      if (this.searchFilterLpn == true) {
        this.searchInput = this.searchInput.replace(/[^0-9]*/g, '');
      }

      let argPartnerSelection: any = {
        "search_opt": this.searchOptions,
        "search_by": this.searchInput,
        "match_case": this.searchCaseTick,
        "page": 1,
        "functionName": "getPartnerSelection"
      };
      let tablePage;
      this.apiService.postData(this.globals.api_URL_accessTool, accessKey).subscribe(data => {
        try {          
          let contentBody = data.body;
          if ((contentBody['data'][0][0]['semCard'] == "1") && (contentBody['data'][6][0]['semCardPdsa'] == "1")) {
            this.apiService.postData(this.globals.api_URL_pds, argPartnerSelection).subscribe(async data2 => {
              let contentBody2 = data2.body;
              tablePage = Math.ceil(contentBody2['data'][1][0]['TotalRows'] / 50);
              if (tablePage == 1) {
                this.dataSource = new MatTableDataSource(contentBody2['data'][0]);
                this.tableContent = contentBody2['data'][0];
                if (this.tableContent.length > 0) { //this condition checks the length of the data source. If it is greater than 0 it will enable the button else it will remain disabled.        
                  this.selectedFirst = contentBody2['data'][0][0]['NAME']; //validation for name
                  sessionStorage.setItem("optNum", contentBody2['data'][0][0]["OPT_NUM"].toString());
                  this.semLinkAccessService.setPeopleKey(contentBody2['data'][0][0]["PEOPLE_KEY"]);
                  this.selectBool = false;
                  this.matchSearch = true;
                } else {
                  this.selectBool = true;
                  this.matchSearch = false;
                  this.initialSearch = false;
                  sessionStorage.setItem("optNum", "");
                  this.semLinkAccessService.clearPeopleKey();
                }
                setTimeout(() => this.dataSource.paginator = this.paginator);
              } else if (tablePage > 1) {
                for (let i = 0; i < Math.ceil(contentBody2['data'][1][0]['TotalRows'] / 50); i++) {
                  await this.getMulti(i).then();
                }
                this.dataSource = new MatTableDataSource(this.dataSourceTemp);
                this.tableContent = contentBody2['data'][0];
                if (this.tableContent.length > 0) { //this condition checks the length of the data source. If it is greater than 0 it will enable the button else it will remain disabled.        
                  this.selectedFirst = this.dataSourceTemp[0]['NAME']; //validation for name
                  sessionStorage.setItem("optNum", this.dataSourceTemp[0]['OPT_NUM'].toString());
                  this.semLinkAccessService.setPeopleKey(this.dataSourceTemp[0]['PEOPLE_KEY']);
                  this.selectBool = false;
                  this.matchSearch = true;
                } else {
                  this.selectBool = true;
                  this.matchSearch = false;
                  this.initialSearch = false;
                  sessionStorage.setItem("optNum", "");
                  this.semLinkAccessService.clearPeopleKey();
                }
                this.dataSourceTemp = [];
                setTimeout(() => this.dataSource.paginator = this.paginator);
              } else {
                this.dataSource = [];
                this.matchSearch = false;
                this.initialSearch = true;
                this.selectBool = true;
                this.notice = "No items to display."
              }
              this.loading = false;
            });
          } else {
            this.loading = false;
            this.matchSearch = false;
          }
        } catch (error) {
          this.loading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('applyFilter', 'pdsa');
          throw error
        }

      }, error => {
        
        this.loading = false;
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('applyFilter', 'pdsa');
        throw error;
      });
    }


  }

  /****************************************************************************************************************
  * Method Name: getMulti                                                                
  * Description: for multiple pages                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1      06/05/2020            Divy O. Ardiente                  Initial draft  
  *****************************************************************************************************************/
  getMulti(page) {
    let z = new Promise<void>((resolve) => {
      let argPartnerSelection2: any = {
        "search_opt": this.searchOptions,
        "search_by": this.searchInput,
        "match_case": this.searchCaseTick,
        "page": page + 1,
        "functionName": "getPartnerSelection"
      };
      this.apiService.postData(this.globals.api_URL_pds, argPartnerSelection2).subscribe(async data2 => {
        try {
          if (data2) {
            let contentBody2 = data2.body;
            if (contentBody2 != null && contentBody2 != undefined) {
              for (let r = 0; r < contentBody2['data'][0].length; r++) {
                this.dataSourceTemp.push({
                  NAME: contentBody2['data'][0][r]['NAME'], OFFICE_NAME: contentBody2['data'][0][r]['OFFICE_NAME'], STATUS: contentBody2['data'][0][r]['STATUS'],
                  OPT_NUM: contentBody2['data'][0][r]['OPT_NUM'], PEOPLE_KEY: contentBody2['data'][0][r]['PEOPLE_KEY']
                });
              }
            }
          }
          resolve();
        } catch (error) {
          this.loading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('getMulti', 'pdsa');
          throw error
        }

      }, error => {
        
        this.loading = false;
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getMulti', 'pdsa');
        throw error;
      });
    });
    return z;
  }

  /**********************************************************************************
  * Method Name: toggleCase                                                                 
  * Description: Input Validation for search.                   
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1                              Jikho S. Enrique                 Initial draft  
  ***********************************************************************************/
  toggleCase() { //sets boolean value on toggle -JSE
    try {
      if (this.searchCase) {
        this.searchCase = false;
        this.searchCaseTick = 0;
      } else {
        this.searchCase = true;
        this.searchCaseTick = 1;
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('toggleCase', 'pdsa');
      throw error;
    }
  }

  selectPartner() {
    try {
      if (this.tableContent.length > 0) {
        if (sessionStorage.getItem("optNum") != null) {
          this.selectedUser = true;
          sessionStorage.setItem("documentId", "1")
          this.router.navigate(['equityadmin/sem/pdsa/partnerselected/personaldata']);
        }
        this.selectBool = false;
      } else {
        this.selectBool = true;
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('selectPartner', 'pdsa');
      throw error;
    }


  }
  /**********************************************************************************
  * Method Name: getValue                                                                 
  * Description: Input Validation for search.                   
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         03/06/20             Almerie T. Dondonay              Initial draft  
  ***********************************************************************************/
  getValue(value, option) { //sets a validation 
    try {
      this.searchOptionDropdownSelect = option;
      this.searchOptions = value;
      this.searchInput = ''; // to clear textfield everytime dropdown is change-ATD
      this.searchBool = true; // to disable button everytime dropdown is change-ATD
      if (this.searchOptions == 3) { //set string/int validation in Search by -ATD
        this.searchFilterName = false;
        this.searchFilterLpn = true;
      } else {
        this.searchFilterLpn = false;
        this.searchFilterName = true;
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getValue', 'pdsa');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: isActive                                                                 
  * Description: To highlight first item in the table.                   
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         3/13/2020            Jikho S. Enrique                 Initial draft   
  * 2         05/07/2020           Almerie T. Dondonay              Change hardcoded URL into Reusable variable.
  ***********************************************************************************/
  isActive(item) {
    return this.selectedFirst === item;
  };

  getPdsaAccess() {
    let accessKey: any = {
      "functionName": "getSecPageAccess"
    }

    this.apiService.postData(this.globals.api_URL_accessTool, accessKey).subscribe(data => {
      try {
        let contentBody = data.body;
        if (contentBody != undefined && contentBody != null) {
          if ((contentBody['data'][0][0]['semCard'] == "1") && (contentBody['data'][6][0]['semCardPdsa'] == "1")) {
            this.hasAccess = true;
            contentBody['data'][8][0]['ROLE'] == null || contentBody['data'][8][0]['ENTERPRISE_ID'] == null ? this.router.navigate(['equityadmin/eamain']) : "";

          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getPdsaAccess', 'pdsa');
        throw error
      }

    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getPdsaAccess', 'pdsa');
      throw error;
    });
  }
}
