import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Select } from '@ngxs/store';
import { StaticTextState } from '../../../../services/ea/states/weeklysummary.state';
import { Globals } from '../../../../globals';
import { ApiService } from '../../../../services/ea/api/ApiService';
import { MatDialog } from '@angular/material/dialog';
import { RoleaccessService } from '../../../../services/ea/api/RoleaccessService';
import { FileService } from '../../../../services/ea/FileService';
import { EjetaccessService } from '../../../../services/ea/EjetaccessService';
import { LoadingOverlayComponent } from '../../../../services/ea/shared/loading-overlay/loading-overlay.component';
import { ModalComponent } from '../../../../services/ea/shared/modal/modal.component';

@Component({
  selector: 'app-importinvoice',
  templateUrl: './importinvoice.component.html',
  styleUrls: ['./importinvoice.component.css']
})
export class ImportinvoiceComponent implements OnInit {

  roleId: number = this.roleAccessService.getUserRoleAccess();

  isLoading: boolean = true;
  isError: boolean = false;
  importHeader: string = null;
  importIntroText: string = null;
  importConfirmText: string = null;
  importFileExist: string = null;
  formEquityType: string = null;
  formName: string = null;
  formDesc: string = null;
  formWeeklyPostingDate: string = null;
  formTranslationDate: string = null;
  formInvoiceFile: string = null;
  postingDateNote: string = null;
  translationDateNote: string = null;
  staticContentData: any = null;
  setDate: string = null;
  datepickerUsage: number = 0;

  file: File = null;
  fileName: string = null; 
  fileExtension: string = null;
  fileSelected: string = "No file chosen.";
  textColor: string = "black";
  invalidFile: boolean;
  invalidFileSize: boolean;
  disabledImportBtn: boolean = true;

  equityTypeList: Array<any>;
  equityType: number;
  name: string = null;
  description: string = null;
  datePipe: DatePipe = new DatePipe('en-US');
  dateFormat: string = "MM/dd/yy";
  weeklyPostingDate: string = this.datePipe.transform(new Date(), this.dateFormat);
  translationDate: string = this.datePipe.transform(new Date(), this.dateFormat);
  hasEquityType: boolean = false;
  hasName: boolean = false;
  hasFile: boolean = false;

  @Select(StaticTextState.getStaticData) staticData: any;
  @ViewChild('ejet_file') ejetFile: ElementRef;

  constructor(
    public globals: Globals,
    private fileService: FileService,
    private apiService: ApiService,
    private modal: MatDialog,
    private ejetAccessService: EjetaccessService,
    private roleAccessService: RoleaccessService
  ) {}

  ngOnInit() {
    this.getStaticContent();
  }

  /**********************************************************************************
  * Method Name: getStaticContent                                                                 
  * Description: Gets the static content.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/17/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getStaticContent(): void {
    this.staticData.subscribe(data => {
      if(data.staticData.data != null) {
        this.staticContentData = data.staticData.data;
        this.importHeader = this.staticContentData[this.getDataIndex('ea.ejet.import.header')].MESSAGE_VALUE;
        this.importConfirmText = this.staticContentData[this.getDataIndex('ea.ejet.import.confirm.message')].MESSAGE_VALUE;
        this.importFileExist = this.staticContentData[this.getDataIndex('ea.ejet.import.file.exist')].MESSAGE_VALUE;
        this.importIntroText = this.staticContentData[this.getDataIndex('ea.ejet.import.intro.text')].MESSAGE_VALUE;
        this.formEquityType = this.staticContentData[this.getDataIndex('ea.ejet.import.form.equity.type')].MESSAGE_VALUE;
        this.formName = this.staticContentData[this.getDataIndex('ea.ejet.import.form.name')].MESSAGE_VALUE;
        this.formDesc = this.staticContentData[this.getDataIndex('ea.ejet.import.form.desc')].MESSAGE_VALUE;
        this.formWeeklyPostingDate = this.staticContentData[this.getDataIndex('ea.ejet.import.form.weekly.date')].MESSAGE_VALUE;
        this.formTranslationDate = this.staticContentData[this.getDataIndex('ea.ejet.import.form.translation.date')].MESSAGE_VALUE;
        this.formInvoiceFile = this.staticContentData[this.getDataIndex('ea.ejet.import.form.invoice.file')].MESSAGE_VALUE;
        this.postingDateNote = this.staticContentData[this.getDataIndex('ea.ejet.import.posting.date.note')].MESSAGE_VALUE;
        this.translationDateNote = this.staticContentData[this.getDataIndex('ea.ejet.import.translation.date.note')].MESSAGE_VALUE;
        this.getEquityTypeList();
      } else {
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getStaticContent', 'Importinvoice');
      throw error;
    });
  }

  /**********************************************************************************
  * Method Name: getDataIndex                                                                 
  * Description: Gets the static content value.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/17/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getDataIndex(key: string): number {
    try {
      let index = null;
      for (let i = 0; i < this.staticContentData.length; i++) {
        if (this.staticContentData[i].MESSAGE_NAME == key) {
          index = i
        }
      }
      return index;
    } catch (error) {
      this.isLoading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getDataIndex', 'Importinvoice');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getEquityTypeList                                                                 
  * Description: Gets the equity type list.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/19/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getEquityTypeList() {
    let body = { functionName: 'getEquityTypeList' };

    this.apiService.postData(this.globals.manageImportInvoiceApi, body).subscribe(data => {
      try {
        if(data != null) {
          let body = data.body;
          if(body.length > 0) {
            let bodyData = body;
            if(bodyData != this.globals.notAuthorized) {
              this.equityTypeList = body;
              let equityTypeContent = this.equityTypeList.filter((e: any) => e.Equity_Type_Desc == 'ESPP'); 
              this.equityType = equityTypeContent[0].Equity_Type_ID;
              this.hasEquityType = true;
            } else {
              this.ejetAccessService.setEjetAccess(false);
            }
          }
        }
  
        this.isLoading = false;
      } catch(error) {
        this.isLoading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getEquityTypeList', 'Importinvoice');
        throw error;
      } 
    }, error => {
      this.isLoading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getEquityTypeList', 'Importinvoice');
      throw error;
    });
  }

  /**********************************************************************************
  * Method Name: setEqutiyType                                                                 
  * Description: Sets the equity type.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/24/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setEqutiyType(value: number): void {
    this.equityType = value;
    this.hasEquityType = true;
    this.disableImportBtn();
  }

  /**********************************************************************************
  * Method Name: getWeeklyPostingDate                                                                 
  * Description: Gets the weekly posting date.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/05/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getWeeklyPostingDate(value: any): void {
    this.weeklyPostingDate = this.datePipe.transform(value, this.dateFormat);
  }

  /**********************************************************************************
  * Method Name: getTranslationDate                                                                 
  * Description: Gets the translation date.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/05/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getTranslationDate(value: any): void {
    this.translationDate = this.datePipe.transform(value, this.dateFormat);
  }

  /**********************************************************************************
  * Method Name: triggerChooseFile                                                                 
  * Description: Triggers the chooseFile method if user uses the enter key.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         09/18/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  triggerChooseFile(event: KeyboardEvent): void {
    if(event.key == 'Enter') {
      document.getElementById('ejet-file').click();
    }
  }

  /**********************************************************************************
  * Method Name: chooseFile                                                                 
  * Description: Picks the file to uploaded.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/24/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  chooseFile(files: any): void {
    try {
      if(files.length > 0 && this.roleId != 7) {
        this.file = files[0];
        this.fileSelected = files[0].name;
        this.fileExtension = this.fileSelected.substring(this.fileSelected.lastIndexOf('.'));
        this.fileName = this.fileSelected.split('.')[0];
        let fileSize = (this.file.size / 1024) / 1000;

        if(fileSize < 4) { // file size < 4mb
          this.invalidFileSize = false;
          this.textColor = "black";
        } else {
          this.invalidFileSize = true;
          this.textColor = "red";
        }
        
        if(!this.invalidFileSize) {
          if(!(this.fileExtension.includes('csv')) && ((files[0].type == "application/vnd.ms-excel") || (files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))) {
            this.textColor = "black";
            this.invalidFile = false;
            this.hasFile = true;
          } else {
            this.textColor = "red";
            this.invalidFile = true;
            this.hasFile = false;
          }
        }
      } else {
        this.hasFile = false;
        this.fileSelected = 'No file chosen.';
        this.textColor = "black";
        this.invalidFile = false;
        this.invalidFileSize = false;
      }
  
      this.disableImportBtn();
    } catch(error) {
      this.isError = true;
      error.message += this.apiService.setErrorContents('chooseFile', 'Importinvoice');
      throw error;
    }
    
  }

  /**************************************************************************************************
  * Method Name: importFile                                                                 
  * Description: Check if the role id and calls the api for importing the file if role id is valid.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/24/20             Dalemark P. Suquib             Initial draft  
  * 2         03/09/20             Dalemark P. Suquib             Added conversion of file to base64
  * 3         03/19/20             Dalemark P. Suquib             Added conversion of base64 to blob
  ***************************************************************************************************/
  importFile(): void {
    try {
      if(!this.disabledImportBtn) {
        if(this.file != null && this.roleId != 7) {
          this.fileService.convertToBase64(this.file).then(res => {
            let base64 = res[1];
            let body = { 
              functionName: 'impInvRprtInsData',
              equityType: this.equityType,
              fileName: this.name.trim() + this.fileExtension,
              description: this.description? this.description.trim(): null,
              postDate: this.weeklyPostingDate,
              transDate: this.translationDate,
              base64: base64
            };

            this.impInvRprtInsData(body);
          });
        } 
      } else {
        if(this.roleId != 7) {
          if(this.invalidFileSize) {
            this.openModal('File size > 4mb. lease contact Equity Services Support. Thank you.');
          } else if(this.invalidFile) {
            this.openModal('Please select a valid file. Thank you.')
          } else {
            this.openModal('Please fill in all required fields. Thank you.')
          }
        } else {
          this.openModal("You're not Authorized to do this action.");
        }
      }
    } catch(error) {
      this.modal.closeAll()
      this.isError = true;
      error.message += this.apiService.setErrorContents('importFile', 'Importinvoice');
      throw error;
    }
  }

  /**************************************************************************************************
  * Method Name: impInvRprtInsData                                                                 
  * Description: Calls the api for importing the file.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         08/19/20             Dalemark P. Suquib             Initial draft  
  ***************************************************************************************************/
  impInvRprtInsData(body: any): void {
    this.modal.open(LoadingOverlayComponent, {disableClose: true, panelClass: 'overlay-modal'});
    this.apiService.postData(this.globals.manageImportInvoiceApi, body).subscribe(data => {
      try {
        this.modal.closeAll();
        let bodyText;
        
        if(data) {
          if(data.statusCode == 200) { // Successfully uploaded file!
            bodyText = this.importConfirmText.replace('<file_name>', this.name);
          } else { // FileName Already Exist In The DataBase!
            if(data.message == this.globals.fileExists) {
              bodyText = this.importFileExist.replace('<file_name>', this.name);
            } else if(data.message == this.globals.notAuthorized) {
              bodyText = "You're not Authorized to do this action.";
            } else {
              bodyText = "Unexpected error occurred. Please contact Equity Services Support.";
            }
          }
        } else {
          bodyText = "Unexpected error occurred. Please contact Equity Services Support.";
        }
        
        this.openModal(bodyText);
      } catch(error) {
        this.modal.closeAll();
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('importFile', 'Importinvoice');
        throw error;
      }
    }, error => {
      this.modal.closeAll();
      this.isError = true;
      error.message += this.apiService.setErrorContents('importFile', 'Importinvoice');
      throw error;
    });
  }

  /**********************************************************************************
  * Method Name: setName                                                                 
  * Description: Sets the name of the file.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/05/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setName(name: string): void {
    try {
      if(name.replace(/\s/g, '').length) {
        this.hasName = true;
        this.name = name;
      } else {
        this.hasName = false;
      }
  
      this.disableImportBtn();
    } catch(error) {
      this.isError = true;
      error.message += this.apiService.setErrorContents('setName', 'Importinvoice');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: setDescription                                                                 
  * Description: Sets the description of the file.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/05/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setDescription(description: string): void {
    this.description = description;
  }

  /**********************************************************************************
  * Method Name: disableImportBtn                                                                 
  * Description: Disables the import button.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/05/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  disableImportBtn() {
    try {
      if(this.hasEquityType && this.hasName && this.hasFile) {
        this.disabledImportBtn = false;
      } else {
        this.disabledImportBtn = true;
      }
    } catch(error) {
      this.isError = true;
      error.message += this.apiService.setErrorContents('disableImportBtn', 'Importinvoice');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openModal                                                                 
  * Description: Opens a modal when user clicks commit or rollback button                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/23/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  openModal(body: string): void {
    try {
      let content: any = {
        header: this.importHeader,
        body: body,
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: 'OK',
        btn2Name: null
      }

      let modalRef = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });

      modalRef.afterClosed().subscribe(result => {
        if(result) {
          this.equityType = null;
          this.name = null;
          this.description = null;
          this.file = null;
          this.fileSelected = "No file chosen.";
          this.disabledImportBtn = true;
          this.setDate = "Import Invoice" + this.datepickerUsage++;
          this.hasEquityType = false;
          this.hasName = false;
          this.hasFile = false;
          this.textColor = 'black';
          this.invalidFile = false;
          this.invalidFileSize = false;
          this.ejetFile.nativeElement.value = null;
        }
      });
    } catch (error) {
      this.modal.closeAll();
      this.isError = true;
      error.message += this.apiService.setErrorContents('openModal', 'Importinvoice');
      throw error;
    }
  }
}
