import { Injectable } from '@angular/core';
import { ApiService } from '../api/ApiService';
import { Globals } from '../../../globals';
import { Router } from '@angular/router';

@Injectable()
export class SemlinkaccessService {
  semSeeLink = [];
  semLink = [];
  semMenuLink = [];
  semSee: boolean = false;
  semCard: boolean = false;
  peopleKey: number;
  searchModalInput: string = "";
  editorHistoryName: string = "";
  editDateHistory: string = "";
  semLinkPDSA: boolean = false;
  unitHistory: any;
  hasData: boolean;
  searchFor: string;
  partnerSelected: any;
  optNum: any;
  optNumHolder: any;
  peopleKeyHolder: any;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private global: Globals
  ) { }

  setSemCard(access){
    this.semCard = access;
  }
  
  getSemCard(){
    return this.semCard;
  }

  disableSemCard(){
    this.semCard = false;
    return this.semCard;
  }

  setSemSee(access){
    this.semSee = access;
  }

  getSemSee() {
    return this.semSee;
  }

  disableSemSee(){
    this.semSee = false;
    return this.semSee;
  }

  addToSemSeeLink(access) {
    this.semSeeLink.push(access);
  }

  getSemSeeLink() {
    return this.semSeeLink;
  }

  clearSemSeeLink() {
  this.semSeeLink = [];
    return this.semSeeLink;
  }

  addToSemLink(access) {
    this.semLink.push(access);
  }

  getSemLink() {
    return this.semLink;
  }

  clearSemLink() {
    this.semLink = [];
    return this.semLink;
  }

  setPeopleKey(pk){
    this.peopleKey = pk;
  }

  getPeopleKey(){
    return this.peopleKey;
  }
  
  clearPeopleKey(){
    this.peopleKey = null;
  }
  
  setSearchModalInput(text){
    if(text == null || text == undefined)
    {
      this.searchModalInput = "";
    }else{
      this.searchModalInput = text;
    }
  }

  getSearchModalInput(){
    return this.searchModalInput;
  }

  clearSearchModalInput(){
    this.searchModalInput = " ";
  }

  setEditorHistoryName(text){
    this.editorHistoryName = text;
  }

  getEditorHistoryName(){
    return this.editorHistoryName;
  }

  clearEditHistoryName(){
    this.editorHistoryName = " ";
  }

  setEditDateHistory(text){
    this.editDateHistory = text;
  }

  getEditDateHistory(){
    return this.editDateHistory;
  }

  clearEditDateHistory(){
    this.editDateHistory = " ";
  }
  
  verifyPDSALinkAccess() {
    let getSEMToolAccessBody = {
      "functionName": "getSecPageAccess"
    };
    this.apiService.postData(this.global.api_URL_accessTool, getSEMToolAccessBody).subscribe(data => {
      try {
        if (data != null) {
          if (data.statusCode == 200) {
            let body = data.body;
            if (body['data'][6][0]['semCardPdsa'] != null && body['data'][6][0]['semCardPdsa'] != undefined) {
              if (body['data'][6][0]['semCardPdsa'] == 1) {
                this.router.navigate(['equityadmin/sem/pdsa']);
              }
            }
          } else {
            // redirect to myH
            // error for token or data

          }
        }
      } catch (error) {
        error.message += this.apiService.setErrorContents('verifyPDSALinkAccess', 'semlinkaccessservice');
        throw error
      }
    }, error => {
      
      error.error.message +=this.apiService.setErrorContents('verifyPDSALinkAccess', 'semlinkaccessservice');
      throw error;
    });
  }

    setSemMenuLink(access){
      this.semMenuLink.push(access);
    }

    getSemMenuLink(){
      return this.semMenuLink;
    }

    clearSemMenuLink(){
      this.semMenuLink = [];
      return this.semMenuLink;
    }

    setUnitHistoryTable(value){
      this.unitHistory = value;
    }

    getUnitHistoryTable(){
      return this.unitHistory;
    }

    clearUnitHistoryTable(){
      this.unitHistory = [];
      return this.unitHistory;
    }

    setTableData(value){
      this.hasData = value;
    }

    getTableData(){
      return this.hasData;
    }

    disableTableData(){
      this.hasData = false;
      return this.hasData;
    }

    setPartnerSelected(value){
      this.partnerSelected = value;
    }

    getPartnerSelected(){
      return this.partnerSelected;
    }

    clearPartnerSelected(){
      this.partnerSelected = [];
      return this.partnerSelected;
    }

    setOptNum(optnum){
      this.optNum = optnum;
    }

    getOptNum(){
      return this.optNum;
    }

    clearOptNum(){
      this.optNum = " ";
      return this.optNum;
    }

    setOptNumHolder(optnum){
      this.optNumHolder = optnum;
    }

    getOptNumHolder(){
      return this.optNumHolder;
    }

    clearOptNumHolder(){
      this.optNumHolder = " ";
      return this.optNumHolder;
    }

    setPeopleKeyHolder(pk){
      this.peopleKeyHolder = pk;
    }

    getPeopleKeyHolder(){
      return this.peopleKeyHolder;
    }

    clearPeopleKeyHolder(){
      this.peopleKeyHolder = " ";
      return this.peopleKeyHolder;
    }

}
