
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { HoldingProgramDetailsRSUIPOService } from '.././HoldingProgramDetailsRSUIPOService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import * as moment from 'moment';
import { BrokerType, PageSection, FileType, EmployeeType, MyHoldingsPagesResources, tScript } from '../../services/enumerations/enumerations';
// import { MatInput } from '@angular/material';
import { NgbDatepicker, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'; //for modern datepicker
import { DatePipe } from '@angular/common';                                                        //for modern datepicker
import { StaticContentService } from '../../services/StaticContentService';
import { BrokersPageService } from '../../brokerspage/BrokersPageService';
import {TooltipPosition} from '@angular/material/tooltip';


@Component({
  selector: 'app-iporsu-sales-history',
  templateUrl: './iporsu-sales-history.component.html',
  styleUrls: ['./iporsu-sales-history.component.css']
})
export class IporsuSalesHistoryComponent implements OnInit {
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];


  /**This block of variables is for modern datepicker */
  dateFromInput: Date; // Angular Material Datepicker
  dateToInput: Date; // Angular Material Datepicker
  datePipe = new DatePipe('en-US');
  displayDateError: boolean;
  hasDateFromBeenClicked: boolean = false;
  hasDateToBeenClicked: boolean = false;
  previousDateFrom: any;
  previousDateTo: any;
  holderDateFrom: any;
  holderDateTo: any;
  dateFromInputPlaceHolder: string;
  dateToInputPlaceHolder: string;
  isCustom: boolean = false;
  errorReportsPage: boolean = false;
  /**End of variable list for modern datepicker */

  errorSalesHistTab: boolean;

  salesHistType: string = tScript.rsuipo_salesHistType;
salesHistShare: string = tScript.rsuipo_salesHistShare;
salesHistProceeds: string = tScript.rsuipo_salesHistProceeds;

  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;
  periodSelected: string;
  datePeriod: number = 4;
  dateTo: string = "''";
  dateFrom: string = "''";
  brokerType: any = [];
  brokerTypes: boolean = false;
  disableDatePicker: boolean = true;
  dateFromChecker: string;
  dateToChecker: string;
  dateErrorChecker: boolean = false;
  noSalesHistory: boolean = false;
  salesTableLoading: boolean;
  rsuIPOSalesHistShares: number = 0;
  rsuIPOSalesHistPriceUSD: number  = 0;
  rsuIPOSalesHistProceedsUSD: number = 0;
  rsuIPOSalesHistPurchaseValueUSD: number  = 0;
  ipoWithholdingForTaxes: number;

  methodCounter: number = 0;
  shortAwardName: string;

  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  user: any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = this.user.CurrencyCd;
  errorLocation: string = " + [Component: Rsuipodetails, Method: methodName]";

    //SALES HISTORY
 rsuIPOSalesHistDateSource: any;
 displayedRsuIPOSalesHistColumns: string[] = ['DispositionDate', 'TransactionType', 'ShareAmount', 'PriceInUSD', 'ProceedsInUSD', 'ProceedsInLoc'];

 LocalGroupDetailId7: any;
  LocalGroupDetailId8: any;
  LocalGroupDetailId5: any;
  localCurrency: any;

  fileTypeImagePath: string;
fileTypeAlt: string;

sbDesc: string;
displayPersonalSb: boolean = false;
displayPersonalUbs: boolean = false;
displayPersonalBig: boolean = false;
displayPersonalNcb: boolean = false;

ubsDesc: string;
bigDesc: string;
ncbDesc: string;

sbDoc: any[];
ubsDoc: any[];
ncbDoc: any[];

globalIDNum1: string;
globalIDNum2: string;
phSBGlobalID: boolean = true;

globalIDs: any;


ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;

bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));


  constructor(private globals: Globals, 
    private app: AppComponent, private router: Router, 
    private HoldingProgramDetailsRSUIpoService: HoldingProgramDetailsRSUIPOService,
    private errorNotificationService: ErrorNotificationService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private staticContentService: StaticContentService,
    private brokerService: BrokersPageService
    ) { } //,private ngbDateParserFormatter: NgbDateParserFormatter

  ngOnInit() {
    this.getIPOProgramDetails();
    this.getRSUIPOSalesHistory();
    
    setTimeout(()=>this.modDP(),500);
  }
  getIPOProgramDetails() {
  
    this.HoldingProgramDetailsRSUIpoService.getIPOSalesHistoryPeriod().pipe(map(res => res)).subscribe(data => {
      try {
        let b = data;
        b = b.sort((a,b) => 0 - (a.Id > b.Id ? -1 : 1));
        this.yearToDate = b[0].Value;
        this.lastYear = b[1].Value;
        this.last3Years = b[2].Value;
        this.allDates = b[3].Value;
        this.custom = b[4].Value;
        this.selectDatePeriod(4);
        
        // this.periodSelected = this.allDates;
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getIPOProgramDetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getIPOProgramDetails");
      error.error.message += errorLocation;
      throw error.error;
    })
  
}


   // Sales History Tab Method - START
   getRSUIPOSalesHistory() {
    try {
      this.HoldingProgramDetailsRSUIpoService.getRSUIPOSalesHistory(this.user.OptNum, this.datePeriod, this.dateTo, this.dateFrom).pipe(map(res => res)).subscribe(data => {
        try {
          
          if(data[1].length == 0) {
            this.rsuIPOSalesHistDateSource = data[1];
            for( let i = 0; i < data[0].length; i++ ){
              this.brokerType[i] = BrokerType[data[0][i].BrokerCd];
              if( i > 0){
                this.brokerTypes = true;
              }
            }
            this.noSalesHistory = true;
            this.salesTableLoading = false;
          } else {
            this.noSalesHistory = false;
            this.salesTableLoading = false;
            for( let i = 0; i < data[0].length; i++ ){
              this.brokerType[i] = BrokerType[data[0][i].BrokerCd];
              if( i > 0){
                this.brokerTypes = true;
              }
            }
            this.rsuIPOSalesHistDateSource = data[1];
    
            for(let i = 0; i < data[1].length; i++) {
              this.localCurrency =data[1][i].LocalCurrencyCd;
              this.rsuIPOSalesHistShares += (data[1][i].ShareAmount*1);
              this.rsuIPOSalesHistPriceUSD += (data[1][i].PriceInUSD*1);
              this.rsuIPOSalesHistProceedsUSD += (data[1][i].ProceedsInUSD*1);
              this.rsuIPOSalesHistPurchaseValueUSD += (data[1][i].ProceedsInLoc*1);
            }
          }
          
          
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOSalesHistory");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOSalesHistory");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOSalesHistory");
      error.message += errorLocation;
      throw error;
    }
  }
 
 selectDatePeriod(period) {
  if(period != 5){
    $(".MDatePicker input").css("background-color","#d3d0d0")
  }else{
    $(".MDatePicker input").css("background-color","white")
  }
  this.datePeriod = period;
  this.displayDateError = false;
try{
  if(this.datePeriod == 1) { // year to date
    this.dateFromInput = null; // Angular Material Datepicker
    this.dateToInput = null; // Angular Material Datepicker
    this.disableDatePicker = true;
    this.periodSelected = "Year to Date";
    this.hasDateFromBeenClicked = false;
    this.hasDateToBeenClicked = false;    

    let currentDate = new Date();
    let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
    this.dateTo = "'" + dateToEvent + "'";      

    currentDate.setMonth(0, 1);
    let yearToDate = currentDate;
    let dateFromEvent = this.datePipe.transform(yearToDate, "MM'/'dd'/'yyyy");
    this.dateFrom = "'" + dateFromEvent + "'";               

    this.dateFromChecker = moment(dateFromEvent).format("YYYY-MM-DD");
    this.dateToChecker = moment(dateToEvent).format("YYYY-MM-DD");
    // this.isCustom = false;
  } else if(this.datePeriod == 2) { // last year
    this.dateFromInput = null; // Angular Material Datepicker
    this.dateToInput = null; // Angular Material Datepicker
    this.disableDatePicker = true;
    this.periodSelected = "Last Year"
    this.hasDateFromBeenClicked = false;
    this.hasDateToBeenClicked = false;

    let currentDate = new Date();
    let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
    this.dateTo = "'" + dateToEvent + "'";      

    currentDate.setFullYear(currentDate.getFullYear() - 1);
    let lastYear = currentDate;
    let dateFromEvent = this.datePipe.transform(lastYear, "MM'/'dd'/'yyyy");
    this.dateFrom = "'" + dateFromEvent + "'"; 

    this.dateFromChecker = moment(dateFromEvent).format("YYYY-MM-DD");
    this.dateToChecker = moment(dateToEvent).format("YYYY-MM-DD");
  } else if(this.datePeriod == 3) { // last 3 years
    this.dateFromInput = null; // Angular Material Datepicker
    this.dateToInput = null; // Angular Material Datepicker
    this.disableDatePicker = true;
    this.periodSelected = "Last 3 Years";
    this.hasDateFromBeenClicked = false;
    this.hasDateToBeenClicked = false;

    let currentDate = new Date();
    let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
    this.dateTo = "'" + dateToEvent + "'";      

    currentDate.setFullYear(currentDate.getFullYear() - 3);
    let lastThreeYear = currentDate;
    let dateFromEvent = this.datePipe.transform(lastThreeYear, "MM'/'dd'/'yyyy");
    this.dateFrom = "'" + dateFromEvent + "'";    

    this.dateFromChecker = moment(dateFromEvent).format("YYYY-MM-DD");
    this.dateToChecker = moment(dateFromEvent).format("YYYY-MM-DD");
    // this.isCustom = false;
  } else if(this.datePeriod == 4) { // All Dates
    this.dateFromInput = null; // Angular Material Datepicker
    this.dateToInput = null; // Angular Material Datepicker
    this.previousDateFrom = this.dateFromInput;
    this.previousDateTo = this.dateToInput;
    this.disableDatePicker = true;
    this.hasDateFromBeenClicked = false;
    this.hasDateToBeenClicked = false;
    this.periodSelected = "All Dates";

    this.dateTo = "' '";      

    this.dateFrom = "' '";     
  } else if(this.datePeriod == 5) { // custom
   
      this.disableDatePicker = false;
      this.periodSelected = this.custom;

      let currentDate = new Date();
      let inputValue = moment(currentDate).format("MM/DD/YYYY");
      this.dateTo = "'" + inputValue + "'";

        if(this.hasDateToBeenClicked == true)
        {
          this.dateToInput = new Date(this.holderDateTo);            
        }
        else
        {
          this.dateToInput = currentDate; // Angular Material Datepicker           
        }                  

      this.dateFrom = "'" + inputValue + "'";

      if(this.hasDateFromBeenClicked == true)
      {
        this.dateFromInput = new Date(this.holderDateFrom);
      }
      else
      {
        this.dateFromInput = currentDate; // Angular Material Datepicker                 
      }   
                 
      this.dateToChecker = inputValue;
      this.dateFromChecker = inputValue;
  }    
} catch(error) {
  this.errorReportsPage = true;
  let errorLocation = this.errorLocation.replace("methodName", "selectDatePeriod");
  error.message = error.message + errorLocation;
  throw error;
}

} 

setPeriodAllDates(tab) {
  try {    
    if(tab.nextId == "sales-history-tab") {
      this.disableDatePicker = true;
      this.dateFrom = "' '";
      this.dateTo = "' '";
      this.periodSelected = this.allDates;
      this.datePeriod = 4;
      this.salesTableLoading = true;
      this.rsuIPOSalesHistShares = 0;
      this.rsuIPOSalesHistPriceUSD = 0;
      this.rsuIPOSalesHistProceedsUSD = 0;
      this.rsuIPOSalesHistPurchaseValueUSD = 0;
      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;
      // this.dateFromInputPlaceHolder = " ";
      // this.dateToInputPlaceHolder = " ";
      // this.dateFromInput = this.ngbDateParserFormatter.parse(null);
      // this.dateToInput = this.ngbDateParserFormatter.parse(null);
      this.dateFromInput = null; // Angular Material Datepicker
      this.dateToInput = null; // Angular Material Datepicker
      this.getRSUIPOSalesHistory()
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "setPeriodAllDates");
    error.message += errorLocation;
    throw error;
  }
}

selectDateFrom(value: Date) { // Angular Material Datepicker
  try {
    let dateEvent = moment(value).format("YYYY-MM-DD");
    this.dateFromChecker = dateEvent;
    this.dateFrom = "'" + dateEvent + "'";
    this.hasDateFromBeenClicked = true;
    this.holderDateFrom = dateEvent;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
    error.message += errorLocation;
    throw error;
  }
}

selectDateTo(value: Date) { // Angular Material Datepicker
  try {
    let dateEvent = moment(value).format("YYYY-MM-DD");
    this.dateToChecker = dateEvent;
    this.dateTo = "'" + dateEvent + "'";
    this.hasDateToBeenClicked = true;
    this.holderDateTo = dateEvent;
 } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
    error.message += errorLocation;
    throw error;
  }
}
/**End of code for modern datepicker methods. */  


  viewRSUIPOSalesHist() {
    try {
      this.dateErrorChecker = false;
      this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
      this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
  
      let tempDateTo = this.dateTo.replace(/'/g, '');
      let tempDateFrom = this.dateFrom.replace(/'/g, '');
  
      tempDateTo = moment(tempDateTo).format("YYYY-MM-DD");
      tempDateFrom = moment(tempDateFrom).format("YYYY-MM-DD");
  
      if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else if (tempDateTo < tempDateFrom && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else{
      this.salesTableLoading = true;
      this.rsuIPOSalesHistShares = 0;
      this.rsuIPOSalesHistPriceUSD = 0;
      this.rsuIPOSalesHistProceedsUSD = 0;
      this.rsuIPOSalesHistPurchaseValueUSD = 0;
      this.getRSUIPOSalesHistory();
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewRSUIPOSalesHist");
      error.message += errorLocation;
      throw error;
    }
  }
  // Sales History Tab Method - END

//COMMAFY

commafy(num: any, value?: number) {
  try {
  // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value > 0) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message += errorLocation;
    throw error;
  }
}

modDP(){
  //matDatepicker mod
  $(".mat-input-infix span").remove();
  $(".mat-input-underline span").remove();
  $(".mat-input-subscript-wrapper").remove();
  $(".mat-input-underline").css("height","0");
  $(".mat-input-element").addClass("datepicker-input");
  $(".mat-input-element").css("height","30px");
  $(".mat-input-element").css("width","173px");
  $(".mat-input-element").css("margin-bottom","0px");
  $(".mat-input-element").css("margin-left","10px");
  $(".mat-input-element").css("text-indent","10px");
  $(".mat-input-element").css("font-size","14px");
  $(".mat-input-element").css("background-color","#d3d0d0");
  $(".mat-input-infix input").css("height","30px");
  $(".mat-input-infix input").css("width","173px");
  $(".mat-input-infix input").css("margin-bottom","2.8px");
  $(".mat-input-infix input").css("background-color","#d3d0d0");
  $(".mat-form-field-infix").css("border-top","0");
  $(".mat-datepicker-toggle button").removeClass("mat-icon-button").addClass("btn btn-outline-secondary calendar");
  $(".mat-datepicker-toggle button span").remove();
  $(".mat-datepicker-toggle button div").remove();
  $(".mat-datepicker-toggle button").removeAttr("mat-icon-button");
  $(".mat-form-field-infix").css("width","0px");
  $(".mat-datepicker-toggle button").css("width","50px");
  $(".mat-datepicker-toggle button").css("height","32px");
  $(".mat-datepicker-toggle button").css("margin-left","0px");
  $(".mat-datepicker-toggle button").css("margin-bottom","-4px");
  $(".mat-datepicker-toggle button").css("background-image","url(/assets/calendar.png)");
  $(".mat-datepicker-toggle button").css("background-repeat","no-repeat");
  $(".mat-datepicker-toggle button").css("background-size","23px");
  $(".mat-datepicker-toggle button").css("background-position","center");
  $(".mat-input-infix input").css("padding-left","16px");
//end of matDatepicker Mod
}

getGlobalID() {
  try {
    this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
      try {
        this.globalIDs = data[0];

        if (this.globalIDs.length == 0) {
          this.phSBGlobalID = true;
          this.globalIDNum1 = ""         
        } else if(this.globalIDs.length == 1){
          this.phSBGlobalID = true;
          if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9) { 
                this.globalIDNum1 = "Your SSN";
              } else {
                this.globalIDNum1 = this.globalIDs[0].ParticipantNbr; 
              }
        }
        else{
          if (this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9) {
            this.phSBGlobalID = true;
            this.globalIDNum1 = "Your SSN"
          } else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) == 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9){
            this.phSBGlobalID = false;
            this.globalIDNum1 = "(1) Your SSN"
            this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
          }
          else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) == 9){
            this.phSBGlobalID = false;
            this.globalIDNum1 = "(1) Your SSN"
            this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
          }
          else{
            this.phSBGlobalID = false;
            this.globalIDNum1 = "(1) " + this.globalIDs[0].ParticipantNbr;
            this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
          }

        }

        // if(this.globalIDs.length == 0) {
        //   this.phSBGlobalID = true;
        //   this.globalIDNum1 = "";
        // } else if(this.globalIDs.length == 1) {
        //   this.phSBGlobalID = true;
  
        //   if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
        //     this.globalIDNum1 = "Your SSN";
        //   } else {
        //     this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
        //   }
        // } 

      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
    error.message += errorLocation;
    throw error;
  }
 }


 checkBrokersInfo(broker){
  event.stopPropagation();
 

  if(this.ubsLoaded == true)
    this.resourceLoading = false;
  else
    this.resourceLoading = true;
  
  if(this.sbLoaded == true)
    this.resourceLoading = false;
  else
    this.resourceLoading = true;
  
  if(this.bigLoaded == true)
    this.resourceLoading = false;
  else
    this.resourceLoading = true;
  
  if(this.ncbLoaded == true)
    this.resourceLoading = false;
  else
    this.resourceLoading = true;
  

  
  this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
    let brokerAccounts = data[0];
    if(brokerAccounts.length > 0) {

      for(let i = 0; i < brokerAccounts.length; i++) {
if(broker == "MS"){
  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });

  this.brokerHeader = "Morgan Stanley";
  
  this.getGlobalID();

  this.displayPersonalUbs = false;
  this.displayPersonalBig = false;
  this.displayPersonalNcb = false;

  this.bodyData[5] = PageSection.sb;
  this.bodyData[6] = 1;
  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
    
      let msData = JSON.parse(data.body);
      let msBody;

      if(msData.data) { // length > {}
      msBody = msData.data;

        if (msBody.length > 0){
          this.sbDesc = msBody[0].Body_Desc;
          this.displayPersonalSb = true;
          }
        else {
          this.displayPersonalSb = false;
          this.sbDesc = "";
        } 
      }


    this.bodyData[5] = PageSection.sbDoc;
    this.bodyData[6] = 0;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    let msDocData = JSON.parse(data.body);
    let msDocBody;
    
    if(msDocData.data) { // length > {}
                    msDocBody = msDocData.data;
                    if(msDocBody.length > 0) {
                      this.sbDoc = msDocBody;
                      for(let i=0; i<this.sbDoc.length; i++){
                        this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                      }
                    }
                    else{
                      this.sbDoc = null;
                    }
                  }
              
    });
    this.sbLoaded = true;
this.resourceLoading = false;
  });

}else if(broker == "UBS"){
  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });
  

  this.brokerHeader = "UBS Financial Services Inc";
  this.displayPersonalSb = false;
  this.displayPersonalBig = false;
  this.displayPersonalNcb = false;

this.ubsAccount = brokerAccounts[i].Account;
this.bodyData[5] = PageSection.ubs;
this.bodyData[6] = 1;


this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  let ubsData = JSON.parse(data.body);
  let ubsBody;

  if(ubsData.data) { // length > {}
  ubsBody = ubsData.data;
  if(ubsBody.length > 0) {
    this.ubsDesc = ubsBody[0].Body_Desc;
    this.displayPersonalUbs = true;
  }
  else {
  this.displayPersonalUbs = false;
    this.ubsDesc = "";
  }
}

this.bodyData[5] = PageSection.ubsDoc;
this.bodyData[6] = 0;

this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

  let ubsDocData = JSON.parse(data.body);
  let ubsDocBody;
  
  if(ubsDocData.data) { // length > {}
      ubsDocBody = ubsDocData.data;
      if(ubsDocBody.length > 0){
        this.ubsDoc = ubsDocBody;

        for(let i=0; i<this.ubsDoc.length; i++){
          this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
        }
      }
      else{
        this.ubsDoc = null;
      }
    }
});
this.ubsLoaded = true;
this.resourceLoading = false;
});
}else if(broker == "BIG"){
  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });
  

  this.brokerHeader = "Banco de Investimento Global";

  this.displayPersonalSb = false;
  this.displayPersonalUbs = false;
  
  this.displayPersonalNcb = false;

  this.bigAccount = brokerAccounts[i].Account;
  this.bodyData[5] = PageSection.big;
  this.bodyData[6] = 1;
  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
    let bigData = JSON.parse(data.body);
    let bigBody;

    if(bigData.data) { // length > {}
    bigBody = bigData.data;
    if(bigBody.length > 0){
      this.bigDesc = bigBody[0].Body_Desc;
      this.displayPersonalBig = false;
    }else {
      this.displayPersonalBig = false;
      this.bigDesc = "";
    }
  }
  this.bigLoaded = true;
this.resourceLoading = false;
  });

}else if(broker == "TA"){
  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });
  

  this.brokerHeader = "Computershare (formerly National City Bank)";
  this.displayPersonalSb = false;
  this.displayPersonalUbs = false;
  this.displayPersonalBig = false;
  

  this.ncbAccount = brokerAccounts[i].Account;
  this.bodyData[5] = PageSection.ncb;
  this.bodyData[6] = 1;
  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

    let taData = JSON.parse(data.body);
    let taBody;

    if(taData.data) { // length > {}
    taBody = taData.data;
    if(taBody.length > 0){
    this.ncbDesc = taBody[0].Body_Desc;
    this.displayPersonalNcb = true;
    }else {
      this.displayPersonalNcb = false;
      this.ncbDesc = "";
    }
  } 
  
  this.bodyData[5] = PageSection.ncbDoc;
  this.bodyData[6] = 0;         

     this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

      let taDocData = JSON.parse(data.body);
      let taDocBody;

      if(taDocData.data) { // length > {}
      taDocBody = taDocData.data;
      if(taDocBody.length > 0){
        this.ncbDoc = taDocBody;

        for(let i=0; i<this.ncbDoc.length; i++){
          this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
        }
      }else {
        this.displayPersonalNcb = false;
        this.ncbDoc = null;
      }
    }
     });
     this.ncbLoaded = true;
     this.resourceLoading = false;
  });

}else if(broker.indexOf('/')){
  this.brokerHeader = broker;
  let splittedBroker = broker.split('/');
  for(let i = 0; i < splittedBroker.length; i++){
    if(splittedBroker[i] == "MS"){
      this.getGlobalID();
      this.bodyData[5] = PageSection.sb;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
        
          let msData = JSON.parse(data.body);
          let msBody;

          if(msData.data) { // length > {}
          msBody = msData.data;

            if (msBody.length > 0){
              this.sbDesc = msBody[0].Body_Desc;
              this.displayPersonalSb = true;
              }
            else {
              this.displayPersonalSb = false;
              this.sbDesc = "";
            } 
          }


        this.bodyData[5] = PageSection.sbDoc;
        this.bodyData[6] = 0;

        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        let msDocData = JSON.parse(data.body);
        let msDocBody;
        
        if(msDocData.data) { // length > {}
                        msDocBody = msDocData.data;
                        if(msDocBody.length > 0) {
                          this.sbDoc = msDocBody;
        
                          for(let i=0; i<this.sbDoc.length; i++){
                            this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                          }
                        }
                        else{
                          this.sbDoc = null;
                        }
                      }
                  
        });
        this.sbLoaded = true;
    this.resourceLoading = false;
      });

    }else if(splittedBroker[i] == "UBS"){
      
    this.ubsAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.ubs;
    this.bodyData[6] = 1;
    

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      let ubsData = JSON.parse(data.body);
      let ubsBody;

      if(ubsData.data) { // length > {}
      ubsBody = ubsData.data;
      if(ubsBody.length > 0) {
        this.ubsDesc = ubsBody[0].Body_Desc;
        this.displayPersonalUbs = true;
      }
      else {
      this.displayPersonalUbs = false;
        this.ubsDesc = "";
      }
    }

    this.bodyData[5] = PageSection.ubsDoc;
    this.bodyData[6] = 0;
    
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

      let ubsDocData = JSON.parse(data.body);
      let ubsDocBody;
      
      if(ubsDocData.data) { // length > {}
          ubsDocBody = ubsDocData.data;
          if(ubsDocBody.length > 0){
            this.ubsDoc = ubsDocBody;
    
            for(let i=0; i<this.ubsDoc.length; i++){
              this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
            }
          }
          else{
            this.ubsDoc = null;
          }
        }
    });
    this.ubsLoaded = true;
    this.resourceLoading = false;
    });
    }else if(splittedBroker[i] == "BIG"){
      
      this.bigAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.big;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      
        let bigData = JSON.parse(data.body);
        let bigBody;

        if(bigData.data) { // length > {}
        bigBody = bigData.data;
        if(bigBody.length > 0){
          this.bigDesc = bigBody[0].Body_Desc;
          this.displayPersonalBig = false;
        }else {
          this.displayPersonalBig = false;
          this.bigDesc = "";
        }
      }
      this.bigLoaded = true;
    this.resourceLoading = false;
      });

    }else if(splittedBroker[i] == "TA"){
     
      this.displayPersonalSb = false;
      this.displayPersonalUbs = false;
      this.displayPersonalBig = false;
      

      this.ncbAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.ncb;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

        let taData = JSON.parse(data.body);
        let taBody;

        if(taData.data) { // length > {}
        taBody = taData.data;
        if(taBody.length > 0){
        this.ncbDesc = taBody[0].Body_Desc;
        this.displayPersonalNcb = true;
        }else {
          this.displayPersonalNcb = false;
          this.ncbDesc = "";
        }
      } 
      
      this.bodyData[5] = PageSection.ncbDoc;
      this.bodyData[6] = 0;         

         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

          let taDocData = JSON.parse(data.body);
          let taDocBody;

          if(taDocData.data) { // length > {}
          taDocBody = taDocData.data;
          if(taDocBody.length > 0){
            this.ncbDoc = taDocBody;

            for(let i=0; i<this.ncbDoc.length; i++){
              this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
            }
          }else {
            this.displayPersonalNcb = false;
            this.ncbDoc = null;
          }
        }
         });
         this.ncbLoaded = true;
         this.resourceLoading = false;
      });
  }
}
(<any>$("#brokerContact")).modal({
  backdrop: 'static',
  keyboard: false
});

}
}
}

});


  


}
fileImage(filyType){
  if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
    this.fileTypeImagePath = '/assets/pdf.svg';
    this.fileTypeAlt = "PDF File"
    return true;
  }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
    this.fileTypeImagePath = '/assets/zip.svg';
    this.fileTypeAlt = "ZIP File"
    return true;
  }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
    this.fileTypeImagePath = '/assets/xls.svg';
    this.fileTypeAlt = "XLS File"
    return true;
  }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
    this.fileTypeImagePath = '/assets/docx.svg';
    this.fileTypeAlt = "DOC File"
    return true;
  }
 }

 navtoSpecificReportsPage(id){
  let reportId: object;

  reportId = {'ReportTypeId': id};
  sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
  window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
}

  /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
  getFile(file, checker) {
    try {
      let fileName: string;
      let fileType: string;
    
      if(checker == "static") {
        if(file.File_Type.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
        
        fileName = file.Document_Name + "." + file.File_Type;
      } else {
        if(file.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
    
        fileName = file;
      }
    
      this.staticContentService.getFile(fileName, "MD").subscribe(data => {
        try {
          let content = JSON.parse(data.body);
          let file = content;
          this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
          this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
          });
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFile");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.error.message += errorLocation;
        throw error.error;
      });
  } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.message += errorLocation;
      throw error;
    }
  }

  getFileName(event) {
    try {
      let href: string = event.target.href;
      let srcElement: HTMLImageElement = event.srcElement;
      let target: string = event.target.target;
        if (target == ""){
          target = "_self";
        }
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != ""){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          // href = href.split("/Default.aspx")[0]; 
          href = event.target.innerText;
          event.preventDefault();

          href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
        } else {
          window.open(href, "_blank");
          return false;
        }
      }
      else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
        event.preventDefault();
        window.open(href, target);
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }


  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : ""

    window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
  }

}
