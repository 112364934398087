import { AccountNumberItem } from "../models/accountnumber-model";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import * as moment from 'moment';
import { AccountNumber } from "../actions/accountnum.actions";


export class AccountNumberItemStateModel {
    items: AccountNumberItem[]
}

@State<AccountNumberItemStateModel>({
    name: "accountnumberitems",
    defaults: {
        items: []
    }
})

export class AccountNumberItemState {
    @Selector()
    static getAccountNumItems(state: AccountNumberItemStateModel){
        return state.items
    }

    @Action(AccountNumber.Add)
    add(
        store: StateContext<AccountNumberItemStateModel>,
        action: AccountNumber.Add
    ){
        const state = store.getState();
        action.payload.ID = state.items.length + 1;
        action.payload.EFFECTIVE_POSTING_DATE = moment.utc(new Date(action.payload.EFFECTIVE_POSTING_DATE)).format("MM/DD/YYYY");
        store.patchState({
            items: [...state.items, action.payload]
        })
    }

    @Action(AccountNumber.Clear)
    clear(
        store: StateContext<AccountNumberItemStateModel>
    ){
        store.setState({
            items: []
        })
    }
}