import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
 

@Pipe({ name: 'sanitizeHtml'})
 

export class sanitizeHtmlPipe implements PipeTransform {
 constructor (private _sanitizer:DomSanitizer) {

 }

 transform(value): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
 }

}