// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error{
    max-width: 30%;
    min-width: 175px;
    object-fit: contain;
}
.error-message-container{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/reusable/errormessage/errormessage.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B","sourcesContent":[".error{\r\n    max-width: 30%;\r\n    min-width: 175px;\r\n    object-fit: contain;\r\n}\r\n.error-message-container{\r\n    display: flex;\r\n    width: 100%;\r\n    height: 100%;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
