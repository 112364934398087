// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading{
    display:flex; 
    align-items: center; 
    position: absolute; 
    height: 100%; 
    top: -1%; 
    left:55%; 
    z-index:1
}`, "",{"version":3,"sources":["webpack://./src/app/reusable/loadingmatspinner/loadingmatspinner.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,QAAQ;IACR,QAAQ;IACR;AACJ","sourcesContent":[".loading{\r\n    display:flex; \r\n    align-items: center; \r\n    position: absolute; \r\n    height: 100%; \r\n    top: -1%; \r\n    left:55%; \r\n    z-index:1\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
