
import {refCount, publish} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoadingService {

  private componentNumber: BehaviorSubject<number> = new BehaviorSubject(null);
  readonly executedComponent$: Observable<number> = this.componentNumber.asObservable().pipe(publish(),refCount(),);
  private components: number = 0;

  constructor() { }

  /**********************************************************************************
   * Method Name: setExecutedComponent                                                                 
   * Description: Sets the number of executed components.                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/01/19             Dalemark P. Suquib          Initial draft          
   *********************************************************************************/
  setExecutedComponent() {
    this.components += 1;
    this.componentNumber.next(this.components);
  }

  /**********************************************************************************
   * Method Name: resetExecutedComponent                                                                 
   * Description: Resets the count of executed components.                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/01/19             Dalemark P. Suquib          Initial draft          
   *********************************************************************************/
  resetExecutedComponent() {
    this.components = 0;
  }
}
