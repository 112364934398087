import { Injectable } from '@angular/core';
import { Globals } from '../../../globals';
import { ApiService } from './ApiService';
import { RoleaccessService } from './RoleaccessService';
import { EaLoadingService } from '../EaLoadingService';
import { SemlinkaccessService } from '../api/SemlinkaccessService';

@Injectable()
export class ToolaccessService {

  toolAccess: boolean;

  constructor(
    private globals: Globals,
    private apiService: ApiService,
    private loadingService: EaLoadingService,
    private semLinkService: SemlinkaccessService
  ) { }

  /**********************************************************************************
  * Method Name: getToolAccess                                                                 
  * Description: Gets the tool access of the user.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/12/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getToolAccess(): Promise<boolean> {
    let toolAccess = new Promise<boolean>((resolve) => {
      let getToolAccessBody = {
        functionName: 'getToolAccess'
      };

      this.apiService.postData(this.globals.manageUserAccessApi, getToolAccessBody).subscribe(data => {
        try {
          if(data) {
            if(data.statusCode == 200) {
              let body = data.body;
              
              if(body != this.globals.notAuthorized) {
                this.setUserToolAccess(true);
                resolve(true);
              } else {
                this.setUserToolAccess(false);
                resolve(true);
              }
            } else {
              this.setUserToolAccess(false);
              resolve(true);
            }
          } else {
            this.setUserToolAccess(false);
            resolve(true);
          }
        } catch(error) {
          this.loadingService.setLoadingState(false);
          this.loadingService.setAppError(true);
          error.message += this.apiService.setErrorContents('getToolAccess', 'ToolaccessService');
          throw error;
        }
      }, error => {
        this.loadingService.setLoadingState(false);
        this.loadingService.setAppError(true);
        error.error.message += this.apiService.setErrorContents('getToolAccess', 'ToolaccessService');
        throw error;
      });
    });

    return toolAccess;
  }

  /**********************************************************************************
  * Method Name: setUserToolAccess                                                                 
  * Description: Sets the user's tool access.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/12/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setUserToolAccess(access: boolean): void {
    this.toolAccess = access;
  }

  /**********************************************************************************
  * Method Name: getUserToolAccess                                                                 
  * Description: Gets the user's tool access.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/12/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getUserToolAccess(): boolean {
    return this.toolAccess;
  }

  getSEMToolAccess(): Promise<boolean> {
    let SEMAccess = new Promise<boolean>((resolve) => {
      let getSEMToolAccessBody = {
        "functionName": "getSecPageAccess"
      };

      this.apiService.postData(this.globals.api_URL_accessTool, getSEMToolAccessBody).subscribe(data => {
        try {
          /* istanbul ignore else  */
          if (data != null) {
            /* istanbul ignore else  */
            if (data.statusCode == 200) {
              let body = data.body;
              this.semLinkService.disableSemCard();
              this.semLinkService.clearSemLink();
              this.semLinkService.clearSemSeeLink();
              this.semLinkService.clearSemMenuLink();
              /* istanbul ignore else  */
              if (body['data'][0][0]['semCard'] != null && body['data'][0][0]['semCard'] == 1) {
                this.semLinkService.setSemCard(true);
                for (let i = 0; i < 3; i++) {
                  if (body['data'][i + 1][0][this.globals.semSeeAccess[i]] == 1) {
                    this.semLinkService.setSemSee(true);
                  }
                }
                for (let i = 0; i < 3; i++) {
                  if (body['data'][i + 1][0][this.globals.semSeeAccess[i]] == 1) {
                    this.semLinkService.addToSemSeeLink(true);
                    this.semLinkService.setSemMenuLink(true);
                  } else {
                    this.semLinkService.addToSemSeeLink(false);
                    this.semLinkService.setSemMenuLink(false);
                  }
                }
                for (let i = 0; i < 3; i++) {
                  if (body['data'][i + 4][0][this.globals.semAccess[i]] == 1) {
                    this.semLinkService.addToSemLink(true);
                    this.semLinkService.setSemMenuLink(true);

                  } else {
                    this.semLinkService.addToSemLink(false);
                    this.semLinkService.setSemMenuLink(false);

                  }
                }

                if (this.semLinkService.getSemSee() == true) {
                  for (let i = 0; i < this.semLinkService.getSemLink().length; i++) {
                    if (this.semLinkService.getSemLink()[i] == true) {
                      this.semLinkService.setSemCard(true);
                    }
                  }
                } else {
                  let semLinks = 0;
                  for (let i = 0; i < this.semLinkService.getSemLink().length; i++) {
                    if (this.semLinkService.getSemLink()[i] == false) {
                      semLinks += 1;
                    }
                  }
                  if (semLinks == 3) {
                    this.semLinkService.setSemCard(false);
                  }
                }

                resolve(true);
              } else {
                resolve(true);
              }
            } else {
              resolve(true);
            }
          } else {
            resolve(true);
          }
        } catch (error) {
          error.message += this.apiService.setErrorContents('getSEMToolAccess', 'ToolaccessService');
          throw error
        }
      }, error => {
        error.error.message += this.apiService.setErrorContents('getSEMToolAccess', 'ToolaccessService');
        throw error;
      });
    });

    return SEMAccess;
  }

}
