import { Component, OnInit, ViewChild} from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from '../../../../../globals';
import { SemlinkaccessService } from '../../../../../services/ea/api/SemlinkaccessService';

@Component({
  selector: 'app-partnerselectedsearch',
  templateUrl: './partnerselectedsearch.component.html',
  styleUrls: ['./partnerselectedsearch.component.css']
})
export class PartnerselectedsearchComponent implements OnInit {
  dataSource: any;
  selectedUser: boolean = false; 
  displayedColumns: string[] = ['NAME', 'OFFICE_NAME', 'STATUS'];
  searchModal: any;
  tableContent: any;
  selectedFirst: any;
  selectBool : boolean = true; 
  matchSearch: boolean = false;
  initialSearch: boolean = true;
  loading: boolean = true;
  docId: any;
  optNum: number;
  partnerSelectedFirstName: string = "";
  partnerSelectedMiddleName: string = "";
  partnerSelectedLastName: string = "";
  partnerSelectedLocalPN: number = 0;
  partnerSelectedPersonnelNum: number = 0;
  partnerSelectedPeopleKey: number = 0;
  partnerStatus: string = "1";
  partnerType: string = "3";
  documentList: string;
  documentDropdownSelect: string;
  documentDropdownList: any[];
  searchInput: string;
  dataSourceTemp = [];
  totalRow: number;
  tablePage: number;
  page: number = 0;
  startRowIndex: number = 0;
  endRowIndex: number = 0;
  disableStartPage: boolean = false;
  disableNextPage: boolean = false;
  disablePrevPage: boolean = false;
  disableEndPage: boolean = false;
  isError: boolean = false;
  @ViewChild('partnerselectedsearchPaginator') paginator: MatPaginator;

  constructor(
    private router: Router,
    private modal: MatDialog,
    private apiService: ApiService,
    private global: Globals,
    private semLinkAccessService: SemlinkaccessService,
  ) { }

  ngOnInit() {
    this.optNum = parseInt(sessionStorage.getItem('optNum'));
    this.modalSearch();
  }


  /***********************************************************************************************
  * Method Name: selectELS                                                            
  * Description: To highlight the selected user and setting the value of optNum in sessionStorage           
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1                           Divy O. Ardiente                 
  * **********************************************************************************************/
   selectELS(row){
    this.selectedFirst = row.NAME;
    sessionStorage.setItem("optNum",row.OPT_NUM.toString());
    this.semLinkAccessService.setPeopleKey(row.PEOPLE_KEY);
    
  }

  /**********************************************************************************
  * Method Name: selectPartner                                                              
  * Description: To reload the document data                 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1        5/15/20             Divy O. Ardiente                 
  * ***************************************************************************************/

  selectPartner(){
    try {
      if (this.tableContent.length > 0) {
        if (sessionStorage.getItem("optNum") != null) {
          this.docId = sessionStorage.getItem("documentId");
          if (this.docId == "1") {
            this.router.navigateByUrl('equityadmin/sem/pdsa', { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_personalData]));
          } else if (this.docId == "4") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_education]));
          } else if (this.docId == "5") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_businessInformation]));
          } else if (this.docId == "7") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_unitHistory]));
          } else if (this.docId == "8") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_unitAward]));
          } else if (this.docId == "9") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_legal]));
          } else if (this.docId == "10") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_memberFirm]));
          } else if (this.docId == "12") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_notes]));
          } else if (this.docId == "13") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_changeLog]));
          } else if (this.docId == "15") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_projectedChanges]));
          } else if (this.docId == "16") {
            this.router.navigateByUrl(this.global.route_personalData, { skipLocationChange: true }).then(() => this.router.navigate([this.global.route_ri]));
          }
          this.modal.closeAll();
        } this.selectBool = false;
      } else {
        this.selectBool = true;
      }

    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('selectPartner', 'partnerselectedsearch');
      throw error;
    }
    
  }
/****************************************************************************************************************
  * Method Name: modalSearch                                                                 
  * Description: To get/display the data for the table               
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         03/16/2020           Almerie T. Dondonay              API connection
  * 2                              Divy O. Ardiente                 
  * 3         05/07/2020           Almerie T. Dondonay              Change hardcoded URL into Reusable variable.
  * 4         06/05/2020           Divy O. Ardiente                 Added "page" parameter for getPartnerSelection
  *                                                                 function and added Pagination
  ****************************************************************************************************************/
  modalSearch() {
    if (this.semLinkAccessService.getSearchModalInput() != null && this.semLinkAccessService.getSearchModalInput() != undefined) {
      let accessKey: any = {
        "functionName": "getSecPageAccess"
      };
      let argPartnerSelection: any = {
        "search_opt": "1",
        "search_by": this.semLinkAccessService.getSearchModalInput(),
        "match_case": 0,
        "page": this.page + 1,
        "functionName": "getPartnerSelection"
      };
      this.apiService.postData(this.global.api_URL_accessTool, accessKey).subscribe(data => {
        try {
          if (data) {
            let contentBody = data.body;
            if (contentBody != undefined) {
              this.loading = true;

              if ((contentBody['data'][0][0]['semCard'] == "1") && (contentBody['data'][6][0]['semCardPdsa'] == "1")) {
                this.apiService.postData(this.global.api_URL_pds, argPartnerSelection).subscribe(async data2 => {
                  try {
                    let contentBody2 = data2.body;
                    if (contentBody2 != undefined || contentBody2['data'] != "Invalid Transaction") {
                      this.tablePage = Math.ceil(contentBody2['data'][1][0]['TotalRows'] / 50);
                      this.dataSource = new MatTableDataSource(contentBody2['data'][0]);
                      this.tableContent = contentBody2['data'][0];
                      this.startRowIndex = this.page * 50 + 1;

                      if (this.tablePage == 1) { //condition to check the total rows of all pages. If the table page no. is not equal to 1, then the totalRows will be added by 1. 
                        this.totalRow = contentBody2['data'][1][0]['TotalRows'];
                        this.disableStartPage = true;
                        this.disablePrevPage = true;
                        this.disableNextPage = true;
                        this.disableEndPage = true;
                      } else {
                        this.totalRow = contentBody2['data'][1][0]['TotalRows'] + 1;
                        this.disableStartPage = true;
                        this.disablePrevPage = true;
                      }

                      this.totalRow < 50 ? this.endRowIndex = contentBody2['data'][1][0]['TotalRows'] : this.endRowIndex += 50; //condition to check the last row number of the dataSource.

                      if (this.tableContent.length > 0) { //this condition checks the length of the data source. If it is greater than 0 it will enable the button else it will remain disabled.        
                        this.selectedFirst = contentBody2['data'][0][0]['NAME']; //validation for name
                        sessionStorage.setItem("optNum", contentBody2['data'][0][0]["OPT_NUM"].toString());
                        this.semLinkAccessService.setPeopleKey(contentBody2['data'][0][0]["PEOPLE_KEY"]);
                        this.selectBool = false;
                        this.matchSearch = true;
                      } else {
                        this.selectBool = true;
                        this.matchSearch = false;
                        this.initialSearch = false;
                      }
                      this.loading = false;
                    }
                  } catch (error) {
                    this.loading = false;
                    this.isError = true;
                    error.message += this.apiService.setErrorContents('modalSearch', 'partnerselectedsearch');
                    throw error
                  }

                }, error => {

                  this.loading = false;
                  this.isError = true;
                  error.error.message += this.apiService.setErrorContents('modalSearch', 'partnerselectedsearch');
                  throw error;
                });
              }
            }

          }
        } catch (error) {
          this.loading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('modalSearch', 'partnerselectedsearch');
          throw error
        }

      }, error => {
        
        this.loading = false;
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('modalSearch', 'partnerselectedsearch');
        throw error;
      });
    }
  }

  /****************************************************************************************************************
  * Method Name: getMulti                                                                
  * Description: for multiple pages                  
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1      06/05/2020            Divy O. Ardiente                  Initial draft  
  *****************************************************************************************************************/  
  getMulti() {
    let argPartnerSelection: any = {
      "search_opt": "1",
      "search_by": this.semLinkAccessService.getSearchModalInput(),
      "match_case": 0,
      "page": this.page + 1,
      "functionName": "getPartnerSelection"
    };
    this.apiService.postData(this.global.api_URL_pds, argPartnerSelection).subscribe(async data2 => {
      try {
        if (data2) {
          let contentBody = data2.body;
          if (contentBody != undefined || contentBody != "Invalid Transaction") {
            this.dataSource = new MatTableDataSource(contentBody['data'][0]);
            this.tableContent = contentBody['data'][0];

            if (this.tableContent.length > 0) { //this condition checks the length of the data source. If it is greater than 0 it will enable the button else it will remain disabled.        
              this.selectedFirst = contentBody['data'][0][0]['NAME']; //validation for name
              sessionStorage.setItem("optNum", contentBody['data'][0][0]["OPT_NUM"].toString());
              this.semLinkAccessService.setPeopleKey(contentBody['data'][0][0]["PEOPLE_KEY"]);
              this.selectBool = false;
              this.matchSearch = true;
            } else {
              this.selectBool = true;
              this.matchSearch = false;
              this.initialSearch = false;
            }
          } else {
            this.matchSearch = false;
          }


        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getMulti', 'partnerselectedsearch');
        throw error
      }

    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getMulti', 'partnerselectedsearch');
      throw error;
    });
  }

  getNextPage() {
    let argPartnerSelection: any = {
      "search_opt": "1",
      "search_by": this.semLinkAccessService.getSearchModalInput(),
      "match_case": 0,
      "page": this.page + 1,
      "functionName": "getPartnerSelection"
    };
    this.apiService.postData(this.global.api_URL_pds, argPartnerSelection).subscribe(async data2 => {
      try {
        if (data2) {
          let contentBody = data2.body;
          if (contentBody != undefined || contentBody != "Invalid Transaction") {
            this.dataSource = new MatTableDataSource(contentBody['data'][0]);
            this.tableContent = contentBody['data'][0];
            this.startRowIndex = this.page * 50 + 1;
            if (this.tableContent.length == 50) {
              this.endRowIndex += 50;
            } else {
              this.endRowIndex = this.totalRow;
            }
            if (this.endRowIndex == this.totalRow) {
              this.disableNextPage = true;
              this.disableEndPage = true;
              this.disablePrevPage = false;
              this.disableStartPage = false;
            } else {
              this.disableNextPage = false;
              this.disableEndPage = false;
              this.disablePrevPage = false;
              this.disableStartPage = false;
            }

            if (this.tableContent.length > 0) { //this condition checks the length of the data source. If it is greater than 0 it will enable the button else it will remain disabled.        
              this.selectedFirst = contentBody['data'][0][0]['NAME']; //validation for name
              sessionStorage.setItem("optNum", contentBody['data'][0][0]["OPT_NUM"].toString());
              this.semLinkAccessService.setPeopleKey(contentBody['data'][0][0]["PEOPLE_KEY"]);
              this.selectBool = false;
              this.matchSearch = true;
            } else {
              this.selectBool = true;
              this.matchSearch = false;
              this.initialSearch = false;
            }
          } else {
            this.matchSearch = false;
          }

        }

      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getNextPage', 'partnerselectedsearch');
        throw error
      }

    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getNextPage', 'partnerselectedsearch');
      throw error;
    });
  }

  getLastPage() {
    let argPartnerSelection: any = {
      "search_opt": "1",
      "search_by": this.semLinkAccessService.getSearchModalInput(),
      "match_case": 0,
      "page": this.page + 1,
      "functionName": "getPartnerSelection"
    };
    this.apiService.postData(this.global.api_URL_pds, argPartnerSelection).subscribe(async data2 => {
      try {
        if (data2) {
          let contentBody = data2.body;
          if (contentBody != undefined || contentBody != "Invalid Transaction") {
            this.dataSource = new MatTableDataSource(contentBody['data'][0]);
            this.tableContent = contentBody['data'][0];

            this.startRowIndex = this.totalRow - this.tableContent.length + 1;
            this.endRowIndex = this.totalRow;

            if (this.endRowIndex == this.totalRow) {
              this.disableEndPage = true;
              this.disableNextPage = true;
              this.disablePrevPage = false;
              this.disableStartPage = false;
            } else {
              this.disableEndPage = false;
              this.disableNextPage = false;
              this.disablePrevPage = false;
              this.disableStartPage = false;
            }

            if (this.tableContent.length > 0) { //this condition checks the length of the data source. If it is greater than 0 it will enable the button else it will remain disabled.        
              this.selectedFirst = contentBody['data'][0][0]['NAME']; //validation for name
              sessionStorage.setItem("optNum", contentBody['data'][0][0]["OPT_NUM"].toString());
              this.semLinkAccessService.setPeopleKey(contentBody['data'][0][0]["PEOPLE_KEY"]);
              this.selectBool = false;
              this.matchSearch = true;
            } else {
              this.selectBool = true;
              this.matchSearch = false;
              this.initialSearch = false;
            }
          } else {
            this.matchSearch = false;
          }
        }
      } catch (error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getLastPage', 'partnerselectedsearch');
        throw error
      }

    }, error => {
      
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getLastPage', 'partnerselectedsearch');
      throw error;
    });
  }

  isActive(item){
    return this.selectedFirst === item;
  };

  //Function to retain the value of optNum when clicking Cancel
  cancel(){
    sessionStorage.setItem("optNum", this.semLinkAccessService.getOptNumHolder());
    this.semLinkAccessService.setPeopleKey(this.semLinkAccessService.getPeopleKeyHolder());
  }

  /****************************************************************************************************************
  * Method Name: startPage()/ nextPage()/ prevPage()/ endPage()                                                          
  * Description: for Pagination buttons                 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1      06/05/2020            Divy O. Ardiente                  Initial draft  
  *****************************************************************************************************************/  
 
  startPage(){
    try {
      this.page = 0;
      this.endRowIndex = 0;
      this.getMulti();
      this.startRowIndex = this.page * 50 + 1;
      this.endRowIndex += 50;
      if (this.startRowIndex == 1) {
        this.disableStartPage = true;
        this.disablePrevPage = true;
        this.disableNextPage = false;
        this.disableEndPage = false;
      } else {
        this.disableStartPage = false;
        this.disablePrevPage = false;
        this.disableNextPage = false;
        this.disableEndPage = false;
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('startPage', 'partnerselectedsearch');
      throw error;
    }

  }

  nextPage(){
    this.page += 1;
    this.getNextPage();
  }

  prevPage(){
    try {
      this.page -= 1;
      this.startRowIndex = this.page * 50 + 1;
      if (this.tableContent.length == 50) {
        this.endRowIndex -= 50;
      } else {
        this.endRowIndex -= this.tableContent.length;
      }
      this.getMulti();
      if (this.endRowIndex == 50) {
        this.disablePrevPage = true;
        this.disableStartPage = true;
        this.disableNextPage = false;
        this.disableEndPage = false;
      } else {
        this.disablePrevPage = false;
        this.disableStartPage = false;
        this.disableNextPage = false;
        this.disableEndPage = false;
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('prevPage', 'partnerselectedsearch');
      throw error;
    }

  }

  endPage(){
    this.page = this.tablePage - 1;
    this.getLastPage();
  }
}
