
import {map,  filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { HighlightsService } from './HighlightsService';
import { highlightsModel } from "../services/models/highlightsModel";
import { resource } from 'selenium-webdriver/http';
import { Globals } from '../globals';
import { StaticContentService } from '../services/StaticContentService';
import { MarketQuoteService } from '../services/MarketQuoteService';
import { AppComponent } from '../app.component';
import * as moment from 'moment'
import { PageSection } from '../services/enumerations/enumerations';
import { Router, ActivatedRoute } from "@angular/router";
import { ErrorNotificationService } from '../services/ErrorNotificationService';

// import { IEXClient } from 'iex-api'
// import * as _fetch from 'isomorphic-fetch'

@Component({
  selector: 'app-highlightspage',
  templateUrl: './highlightspage.component.html',
  styleUrls: ['./highlightspage.component.css'],
  providers: [MarketQuoteService, HighlightsService, Globals]
})
export class HighlightspageComponent implements OnInit {

  optNum: number = 224727;
  hasRSUGrant: boolean = false;
  hasRSUReleased: boolean = false;
  hasVIEP: boolean = false;
  hasESPP: boolean = false;
  hasGrantAgreement: boolean = false;
  userHighlights:any;
  // RSUReleased:any;
  // VIEPPurchProg:any;
  // ESPPPurchProg:any;
  // GrantAgreement:any;
  // staticContent: any;
  // iex = new IEXClient(_fetch)
  marketQuote:any;
  items:any;
  user:any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  loading = true;
  loadingFilter = false;
  priceValue: any;
  periodChecker: any;
  yearList:any;
  hasHighlightsChecker:boolean = true;
  disableDropdown:boolean = false;

  topQuestions: any;
  end: any;
  start: any;

  keyExecVest: string = JSON.parse(sessionStorage.getItem("Key Exec Homepage Value"));
  errorLocation: string = " + [Component: Highlightspage, Method: methodName]";
  errorHighlightsContent: boolean;
  errorTopQuestions: boolean;

  constructor(private app: AppComponent, private marketQuoteService: MarketQuoteService, private staticContentService: StaticContentService, private highlightsService: HighlightsService, private globals: Globals,private router: Router, private route: ActivatedRoute, private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {

    let hasHighlightsChecker = sessionStorage.getItem("hasHighlights");
    if(hasHighlightsChecker != "1") this.router.navigate(['homepage']);

    this.checkErrorMessage();
    this.app.appNavHeaderVisible = true;
    this.app.appFooterVisible = true;
    this.getHighlightsStaticContent();
    // this.getMarketQuoteData();
    sessionStorage.setItem("Key Exec Homepage Value", JSON.stringify(this.keyExecVest));
    sessionStorage.setItem("Key Exec Component Indicator", JSON.stringify("Homepage"));
    
    
  }

  async getMarketQuoteData(){
    try {
      this.marketQuote = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));

      if(this.marketQuote == undefined){
        this.getMarketQuotefromDB();
      }else{
        this.priceValue =  this.marketQuote.latestPrice;
        this.getHighlightsContent();
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getMarketQuoteData");
      error.message += errorLocation;
      throw error;
    }
    
    // try {
    //   this.marketQuoteService.getMarketQuote().pipe(map(res => res)).subscribe(data => {
    //     try {
    //       this.marketQuote = data['quote'];
  
    //       if(this.marketQuote == undefined){
    //         this.getMarketQuotefromDB();
    //       }else{
    //         this.priceValue =  this.marketQuote.latestPrice;
    //         this.getHighlightsContent();
    //       }
    //     } catch(error) {
    //       let errorLocation = this.errorLocation.replace("methodName", "getMarketQuoteData");
    //       error.message += errorLocation;
    //       throw error;
    //     }
    //   }, error => {
    //     let errorLocation = this.errorLocation.replace("methodName", "getMarketQuoteData");
    //     error.error.message += errorLocation;
    //     throw error.error;
    //   });
    // } catch(error) {
    //   let errorLocation = this.errorLocation.replace("methodName", "getMarketQuoteData");
    //   error.message += errorLocation;
    //   throw error;
    // }
  }

  getMarketQuotefromDB(){
    try {
      this.marketQuoteService.getMarketQuotefromDB().pipe(map(res => res)).subscribe(data => { 
        try {
          let body = JSON.parse(data["body"]);
          let items = body['Items'];
    
          this.marketQuote = items[0].CurrentSharePrice;
          this.priceValue  = this.marketQuote;
    
          this.getHighlightsContent();
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getMarketQuotefromDB");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getMarketQuotefromDB");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getMarketQuotefromDB");
      error.message += errorLocation;
      throw error;
    }
  }

  getHighlightsContent(){ 
    try {
      this.errorHighlightsContent = false;
      let re = '@CurrentPrice'; //replaced in RSU Granted with LastPrice from Marketquote
      this.userHighlights = JSON.parse(sessionStorage.getItem(this.globals.highlightsPage));

      if(this.userHighlights.length == 0){
        this.hasHighlightsChecker = false;
      }else{
        this.yearList = [this.userHighlights[0].Year];
  
        for(let i=0;i<this.userHighlights.length;i++){
          if(this.yearList.indexOf(this.userHighlights[i].Year) == -1){
            this.yearList.push(this.userHighlights[i].Year);
          }

          if(this.userHighlights[i].Text != null) {
            this.userHighlights[i].Text = this.userHighlights[i].Text.replace(re, this.priceValue);
          }
        }
      }

      this.getTopQuestions();
    } catch(error) {
      this.errorHighlightsContent = true;
      this.getTopQuestions();
      let errorLocation = this.errorLocation.replace("methodName", "getHighlightsContent");
      error.message += errorLocation;
      throw error;
    }
  }

  getHighlightsStaticContent(){
    try {
      this.errorHighlightsContent = false;
      let monthsoptions = this.globals.staticHighlightsSortType;
      this.staticContentService.getBluePageStaticContent(monthsoptions).subscribe((data) => {
        try {
          this.errorHighlightsContent = false;
          this.items = data;
  
          for(let i=0; i<this.items.length;i++){
            this.items[i].Name = this.items[i].Value + " Months";
            this.items[i].Value = parseInt(this.items[i].Value);
            
          }
          let a = this.items.Value;
          this.items.sort(function(b, a) {
            return parseFloat(a.Value) - parseFloat(b.Value); 
          });
          for(let i=0; i<this.items.length;i++){
            this.items[i].Value = i;        
          }
          // setTimeout(()=>{ this.loading = false }, 1000);
          this.periodChecker = this.items[0].Name;

          this.getMarketQuoteData();
        } catch(error) {
          this.errorHighlightsContent = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getHighlightsStaticContent");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorHighlightsContent = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getHighlightsStaticContent");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorHighlightsContent = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getHighlightsStaticContent");
      error.message += errorLocation;
      throw error;
    }
  }

  changeHighlightsFilter(filter){
    try {
      this.errorHighlightsContent = false;
      this.disableDropdown = true;
      this.hasHighlightsChecker = true;
      this.periodChecker = this.items[filter].Name;
      this.loadingFilter = true;
      let re = '@CurrentPrice';
  
      //this is to accomodate the changes in the sp
      if(filter == 1)
        filter = 2;
      else if(filter == 2)
        filter = 4;
  
      this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
      this.highlightsService.getHighlights(this.user.OptNum, filter).subscribe((data) => {
        try {
          this.errorHighlightsContent = false;
          this.userHighlights = data[1];
  
          if(this.userHighlights.length == 0){
            this.hasHighlightsChecker = false;
            this.disableDropdown = false;
            this.loadingFilter = false;
          }
          else{
          this.yearList = [this.userHighlights[0].Year];
    
          for(let i=0;i<this.userHighlights.length;i++){
            if(this.yearList.indexOf(this.userHighlights[i].Year) == -1){
              this.yearList.push(this.userHighlights[i].Year);
            }

            if(this.userHighlights[i].Text != null) {
              this.userHighlights[i].Text = this.userHighlights[i].Text.replace(re, this.priceValue);
            }
          }
          this.disableDropdown = false;
          this.loadingFilter = false;
        }
        } catch(error) {
          this.errorHighlightsContent = true;
          this.loadingFilter = false;
          let errorLocation = this.errorLocation.replace("methodName", "changeHighlightsFilter");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorHighlightsContent = true;
        this.loadingFilter = false;
        let errorLocation = this.errorLocation.replace("methodName", "changeHighlightsFilter");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      this.errorHighlightsContent = true;
      this.loadingFilter = false;
      let errorLocation = this.errorLocation.replace("methodName", "changeHighlightsFilter");
      error.message += errorLocation;
      throw error;
    }
  }

  getTopQuestions(){
    try {
      this.errorTopQuestions = false;
      let bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  
      bodyData[5] = PageSection.topQuestions;
      bodyData[6] = 1;
    
      this.staticContentService.getResourcesDataFromMyR(bodyData).subscribe(data => { 
        try {
          this.errorTopQuestions = false;
          let content = JSON.parse(data.body);
          if(content.data.length > 2) { // length > {}
            let topQuestionsContent = content.data;
            if(topQuestionsContent.length > 0) {
              this.topQuestions = topQuestionsContent;
                if(this.topQuestions[2]['Body_Desc'].indexOf('<u></u>') != -1){
                  this.topQuestions[2]["Body_Desc"]=this.topQuestions[2]["Body_Desc"].replace('<u></u>','');
                }
               
               
            } else {
              this.topQuestions = null;
            }
          } 
          this.end = 4;
          this.start = 0;
    
          this.loading = false;
          setTimeout(()=>{ this.inContentIconAppend() }, 500);
        } catch(error) {
          this.errorTopQuestions = true;
          let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorTopQuestions = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorTopQuestions = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getTopQuestions");
      error.message += errorLocation;
      throw error;
    }
   }

  navToPage(strLoc){
    try {
      this.router.navigate([strLoc]);
 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  setViewAllNav(){
    sessionStorage.setItem("ntr","fromHighlights");
    sessionStorage.setItem("activeResource", "Top Questions");

}

  validateFileType(eventBody){
    try {
      let href: string = eventBody.target.href;
      let parentNode: any = eventBody.target.parentNode;
      let id: string = eventBody.target.id;
      let downloadFile: boolean = false;
      let fileName: string;
      let target: string = eventBody.target.target;
      let srcElement: HTMLImageElement = eventBody.srcElement;
    
      eventBody.preventDefault();
    
      if(href != undefined && href != "") {
        href =  href.toString();
        if((href.indexOf("fileName") != -1) && (href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1)) {
          fileName = href.substring(href.indexOf("=") + 1, href.length).replace(/%20/g, " ");
          this.getFile(fileName);
        }else if((href.indexOf("fileName") == -1) && (href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1)){
          href = href.substring(href.lastIndexOf("/") + 1);
          href = href.split("%20").join(" ");
          this.getFile(href);
        } else if(srcElement.src != undefined) {
          if(srcElement.src.indexOf(".svg") != -1) {
            this.staticContentService.validateFileType(eventBody);
          }
        } else {
          // future use
          window.open(href,target);
        }
      }else if(id != undefined && id != "") {
        id =  id.toString();
        if((id.indexOf("fileName") != -1) && (id.indexOf(".pdf") != -1 || id.indexOf(".doc") != -1 || id.indexOf(".docx") != -1 || id.indexOf(".xls") != -1 || id.indexOf(".pdf") != -1)) {
          fileName = href.substring(id.indexOf("=") + 1, id.length).replace(/%20/g, " ");
          this.getFile(fileName);
        }else if((id.indexOf("fileName") == -1) && (id.indexOf(".pdf") != -1 || id.indexOf(".doc") != -1 || id.indexOf(".docx") != -1 || id.indexOf(".xls") != -1 || id.indexOf(".pdf") != -1)){
          this.getFile(id);
        }
      }else if(parentNode != undefined && parentNode != ""){
        let parentNodeHref = parentNode.href;
        let parentNodeTarget = parentNode.target;
        
        if(parentNodeHref != undefined && parentNodeHref != "") {
          parentNode =  parentNodeHref.toString();
          parentNode = parentNode.substring(parentNode.lastIndexOf("/") + 1);
      
          if((parentNode.indexOf("fileName") != -1) && (parentNode.indexOf(".pdf") != -1 || parentNode.indexOf(".doc") != -1 || parentNode.indexOf(".docx") != -1 || parentNode.indexOf(".xls") != -1 || parentNode.indexOf(".pdf") != -1)) {
            fileName = parentNode.substring(parentNode.indexOf("=") + 1, parentNode.length).replace(/%20/g, " ");
            this.getFile(fileName);
          }else if((parentNode.indexOf("fileName") == -1)) {
            if(parentNode.indexOf(".pdf") != -1 || parentNode.indexOf(".doc") != -1 || parentNode.indexOf(".docx") != -1 || parentNode.indexOf(".xls") != -1 || parentNode.indexOf(".pdf") != -1) {
              parentNode = parentNode.split("%20").join(" ");
              this.getFile(parentNode);
            } else {
              if(parentNodeHref != undefined) {
                if(parentNodeTarget == "_blank") {
                  window.open(parentNodeHref, "_blank");
                } else {
                  // future use
                }
              }
            }
          }
        }
      } 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "validateFileType");
      error.message += errorLocation;
      throw error;
    }
  }
  
  
  getFile(fileName) {
    try {
      let fileType: string;
  
      if(fileName.indexOf(".pdf") != -1) {
        fileType = "application/pdf";
      } else {
        fileType = "mime_type";
      }
    
      this.staticContentService.getFile(fileName, "MD").subscribe(data => {
        try {
          let content = data;
          if(content == null || content == ""){
            this.staticContentService.noFileExists(fileName);
          }else{
            let file = JSON.parse(data.body);

            this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
            this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
              let writeLogResponse = data;
            });
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFile");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.message += errorLocation;
      throw error;
    }
   }
   
   async inContentIconAppend(){
    $("font").contents().unwrap();
    $("u").contents().unwrap();
    $("p[align='left']").contents().unwrap();
    $("u[style]").remove();
    $("[href='Voluntary%20Equity%20Investment%20Plan%20Enrollment%20Runs%20From%20November%2015%20-%20December%2015.pdf']").remove();
    $("[href='Voluntary%20Equity%20Investment%20Plan%20Enrollment%20Runs%20From%20November%2015%20-%20December%2015.pdf'] ~ u").remove();
    $("[href='Dividend%20Timing%20and%20Required%20Actions%20May%202013.pdf']").remove();
    $("[href='Dividend%20Timing%20and%20Required%20Actions%20May%202013.pdf']").remove();
    $("#uhl ul").attr("style", "list-style-position: inside");
    $("ol").attr("style", "list-style-position: inside");
    this.staticContentService.inContentIconAppend();
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Highlightspage") != -1 && this.user != null) {
                webPage = "Highlightspage";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  addJqueryCodes(){
    $("font").contents().unwrap();
  }
  
}
