
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
// import { Http, Headers, RequestOptions } from '@angular/http';

@Injectable()
export class HoldingProgramDetailsOptionsService {
  headers: Headers;
  // options: RequestOptions;
  token: string = sessionStorage.getItem("msal.idtoken");
  grantAgreementDetails: any;
  optionsSummary: any;
  vestDataSource: any;

  constructor(private globals: Globals, private httpClient: HttpClient) { 
  }

  getAwardDetailsOptions(grantNum){
    let apiUrl = this.globals.currentAPIUrl;
    let optDetailsApi = this.globals.getAwardDetailsOptionsAPI;

    optDetailsApi = optDetailsApi.replace("[param1]", grantNum);

    try {
      const api = apiUrl + optDetailsApi;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }

  }

  getSalesHistoryPeriod() {
    let apiUrl = this.globals.currentAPIUrl;
    let salesHistoryPeriodUrl = this.globals.staticContentAPI + this.globals.staticSalesHistoryPeriod;

    try {
      const api = apiUrl + salesHistoryPeriodUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getOptionsSalesHistory(grantNum, period, dateTo, dateFrom){
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let optSalesHistoryAPI = apiUrl + this.globals.getOptionsSalesHistory;

    optSalesHistoryAPI = optSalesHistoryAPI.replace("[param1]", grantNum);
    optSalesHistoryAPI = optSalesHistoryAPI.replace("[param2]", period);
    optSalesHistoryAPI = optSalesHistoryAPI.replace("[param3]", dateFrom);
    optSalesHistoryAPI = optSalesHistoryAPI.replace("[param4]", dateTo);
    optSalesHistoryAPI = optSalesHistoryAPI.replace(regEx, "");

    try {
      return this.httpClient.get(optSalesHistoryAPI).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  grantAccepted(userOptnum, adminOptnum, grantNum){
    let apiURL = this.globals.currentAPIUrl;
    let methodPath = this.globals.acceptGrantAcceptBtnOptionsAPI;

    methodPath = methodPath.replace("[param1]", userOptnum);
    methodPath = methodPath.replace("[param2]", adminOptnum);
    methodPath = methodPath.replace("[param3]", grantNum);

    try {
      const apiLogin = apiURL + methodPath;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});
      let body = JSON.stringify({ userOptnum, adminOptnum, grantNum })

      return this.httpClient.put(apiLogin, body).pipe(retry(3));
    }catch(err) {
      return err;
    }
  }

  getGrantAgreement(grantNum){
    let apiUrl = this.globals.currentAPIUrl;
    let grantAgreementurl = apiUrl + this.globals.getOtionsAgreement;

    grantAgreementurl = grantAgreementurl.replace("[param1]", grantNum);

    try {
      return this.httpClient.get(grantAgreementurl).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 

  getDataFromCodeDetail(grantId): any{
    let apiUrl = this.globals.currentAPIUrl;
    let getValue = this.globals.getValuefromCodeDetail; 

    try {
      const api = apiUrl + getValue + grantId;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  

}
