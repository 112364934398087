import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/ea/api/ApiService';
import { Globals } from '../../../../globals';
import { Store } from '@ngxs/store';
import { BdrLoadingService } from '../../../../services/ea/api/BdrLoadingService';
import { BdraccessService } from '../../../../services/ea/api/BdrAccessService';
import { StaticService } from '../../../../services/ea/api/StaticService';
import { CompanyCode } from '../../../../services/ea/actions/compcode.actions';

@Component({
  selector: 'app-companycode',
  templateUrl: './companycode.component.html',
  styleUrls: ['./companycode.component.css']
})
export class CompanycodeComponent implements OnInit {

  equityTypeList: any[] = [];
  chcList: any[] = [];
  shareTypeList: any[] = [];
  compCodeStaticText: any[] = [];
  compCodeHeader: string;
  displayTable: boolean;
  tableHasData: boolean;
  equityTypeId: number;
  tableLoading: boolean;
  loadingService: any;
  loading: boolean = true;
  isError: boolean = false;
  bdrService: any;

  constructor(
    private apiService: ApiService,
    private globals: Globals,
    private store: Store,
    private compCodeLoading: BdrLoadingService,
    private bdrAccessService: BdraccessService,
    private staticContentService: StaticService
  ) { 
    this.bdrService = this.bdrAccessService.companyCodeError$.subscribe(state => {
      if(state) {
        this.isError = state;
        this.loading = false;
      }
    });
  }

  ngOnInit() {
    this.getStaticContents();
    this.loadingService = this.compCodeLoading.BdrLoading$.subscribe(state => {
      this.tableLoading = state;
    });
  }

  ngOnDestroy(){
    this.bdrService.unsubscribe();
    this.loadingService.unsubscribe();
  }

  /**********************************************************************************
 * Method Name: getStaticContents                                                                 
 * Description: Calls an api to retrieve values for page contents.                   
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         03/12/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/ 
  getStaticContents(){
    let messageName: string = "%ea.bdr.compcode%";
    this.staticContentService.getStaticContent(messageName).then( data => {
      if (data) {
        this.compCodeStaticText = data; 
        this.compCodeHeader = this.compCodeStaticText[this.getDataIndex("ea.bdr.compcode.header")].MESSAGE_VALUE;
      }
      this.getEquityTypes();
    }).catch((error) => {
      if(error) {
        this.loading = false;
        this.isError = true;
      }
    });
  }

 /**********************************************************************************
 * Method Name: getDataIndex                                                                 
 * Description: Returns the index of the given key.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         02/19/20             John Paul B. Fernando          Initial draft  
 ***********************************************************************************/
  getDataIndex(key){
    try {
      let index;
      for (let i = 0; i < this.compCodeStaticText.length; i++) {
        if (this.compCodeStaticText[i].MESSAGE_NAME == key) {
          index = i
        }
      }
      return index;
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getDataIndex', 'Companycode');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getValue                                                                 
  * Description: Gets the value.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/05/20             Dalemark P. Suquib             Initial draft  
  * 2         05/13/20             John Paul B Fernando           Added logic to set the selected equity type id.
  ***********************************************************************************/
  getValue(value: number): void {
    this.equityTypeId = value; 
  }

  /**********************************************************************************
  * Method Name: getEquityTypes                                                                 
  * Description: Gets the list of equity types.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/13/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
  getEquityTypes(){
    let body = {
      functionName: "getEquityTypeList"
    }
    this.apiService.postData(this.globals.manageImportInvoiceApi, body).subscribe( data => {
      try {
        let body = data.body
        if (body) {
          let bodyData = body;
          if (bodyData) {
            if(bodyData != this.globals.notAuthorized) {
              this.equityTypeList = bodyData;
              this.getValue(this.equityTypeList[0].Equity_Type_ID);
            } else {
              this.bdrAccessService.setBdrAccess(false);
            }
          }
        }
        this.loading = false;
      } catch(error) {
        this.loading = false;
        this.isError = true;
        error.message += this.apiService.setErrorContents('getEquityTypes', 'Companycode');
        throw error;
      }
    }, error => {
      this.loading = false;
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getEquityTypes', 'Companycode');
      throw error;
    })
  }

  /**********************************************************************************
  * Method Name: loadCompCodeTable                                                                 
  * Description: Calls several methods to get data and loads the ccfieldinfo component.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/27/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/
 loadCompCodeTable(){
    if (!this.tableLoading) {
    this.getCompCodeData();
    this.getChcList();
    this.getShareTypeList();
    }
 }

 /**********************************************************************************
 * Method Name: getCompCodeData                                                                 
 * Description: Gets the data of company code.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         05/27/20             John Paul B Fernando           Initial draft  
 ***********************************************************************************/
getCompCodeData(){
  let body = {
    functionName: "getBusDataHoldingCo",
    equityTypeId: this.equityTypeId
  }
  this.compCodeLoading.setLoadingState(true);
  this.apiService.postData(this.globals.manageCompCodeApi, body).subscribe( data => {
    try {
      let body = data.body
      if (body && body != "") {
        let bodyData = body;
        if (bodyData.length > 0 && bodyData != "Invalid Input") {
          this.store.dispatch( new CompanyCode.Clear_Comp_Code_Item);
          for (let i = 0; i < bodyData.length; i++) {
            this.store.dispatch( new CompanyCode.Add_Comp_Code_Item(bodyData[i]))
          }
          this.displayTable = true;
          this.tableHasData = true;
        } else {
          this.displayTable = true;
          this.tableHasData = false;
        }
      }
      this.compCodeLoading.setLoadingState(false);
    } catch(error) {
      this.compCodeLoading.setLoadingState(false);
      this.isError = true;
      error.message += this.apiService.setErrorContents('getCompCodeData', 'Companycode');
      throw error;
    }
  }, error => {
    this.compCodeLoading.setLoadingState(false);
    this.isError = true;
    error.error.message += this.apiService.setErrorContents('getCompCodeData', 'Companycode');
    throw error;
  });
}

/**********************************************************************************
* Method Name: getChcList                                                                 
* Description: Gets the data of company code CHC List.                       
*                                                                                          
* #         Date                 Modified by                    Description                             
* 1         05/27/20             John Paul B Fernando           Initial draft  
***********************************************************************************/
getChcList(){
  let body = {
    functionName: "getHoldCoCHCCoCdList",
    equityTypeId: this.equityTypeId
  }
  this.apiService.postData(this.globals.manageCompCodeApi, body).subscribe( data => {
    try {
      let body = data.body
      if (body && body != "") {
        let bodyData = body;
        if (bodyData.length > 0 && bodyData != "Invalid Input") {
          this.chcList = bodyData;
        }
      }
    } catch(error) {
      this.isError = true;
      error.message += this.apiService.setErrorContents('getChcList', 'Companycode');
      throw error;
    }
  }, error => {
    this.isError = true;
    error.error.message += this.apiService.setErrorContents('getChcList', 'Companycode');
    throw error;
  })
}

/**********************************************************************************
* Method Name: getShareTypeList                                                                 
* Description: Gets the data of company code share type list.                       
*                                                                                          
* #         Date                 Modified by                    Description                             
* 1         05/27/20             John Paul B Fernando           Initial draft  
***********************************************************************************/
  getShareTypeList(){
    let body = {
      functionName: "getHoldCoShareTypeList"
    }
    this.apiService.postData(this.globals.manageCompCodeApi, body).subscribe( data => {
      try {
        let body = data.body
        if (body && body != "") {
          let bodyData = body;
          if (bodyData.length > 0 && bodyData != "Invalid Input") {
            this.shareTypeList = bodyData;
          }
        }
      } catch(error) {
        this.isError = true;
        error.message += this.apiService.setErrorContents('getShareTypeList', 'Companycode');
        throw error;
      }
    }, error => {
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getShareTypeList', 'Companycode');
      throw error;
    });
  }
}
