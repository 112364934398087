
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';


@Injectable()
export class HoldingProgramDetailsFounderService {

  constructor(private globals: Globals, private httpClient: HttpClient) { }

  getFounderDetails(optNum, grantNum){
    let apiUrl = this.globals.currentAPIUrl;
    let founderDetailsUrl = this.globals.getAwardDetailsFounderAPI;

    founderDetailsUrl = founderDetailsUrl.replace("[param1]", grantNum);
    founderDetailsUrl = founderDetailsUrl.replace("[param2]", optNum);
    
    try {
      const api = apiUrl + founderDetailsUrl;
      
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getFounderSalesHist(accountOptNum, grantNum, period, fromDate, toDate, voteShareInd, grantInd){
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let founderSalesHistUrl = this.globals.getAwardSalesHistFounderAPI;

    founderSalesHistUrl = founderSalesHistUrl.replace("[param1]", grantNum);
    founderSalesHistUrl = founderSalesHistUrl.replace("[param2]", period);
    founderSalesHistUrl = founderSalesHistUrl.replace("[param3]", fromDate);
    founderSalesHistUrl = founderSalesHistUrl.replace("[param4]", toDate);
    founderSalesHistUrl = founderSalesHistUrl.replace("[param5]", accountOptNum);
    founderSalesHistUrl = founderSalesHistUrl.replace("[param6]", voteShareInd);
    founderSalesHistUrl = founderSalesHistUrl.replace("[param7]", grantInd);
    founderSalesHistUrl = founderSalesHistUrl.replace(regEx, "");

    try{
      const api = apiUrl + founderSalesHistUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
        return err;
    }

  }
  
  getSalesHistoryPeriod() {
    let apiUrl = this.globals.currentAPIUrl;
    let salesHistoryPeriodUrl = this.globals.staticContentAPI + this.globals.staticSalesHistoryPeriod;

    try {
      const api = apiUrl + salesHistoryPeriodUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getFounderTransferee(optNum){
    let apiUrl = this.globals.currentAPIUrl;
    let transfereeFdrUrl = this.globals.getTransfereeFdrAPI;

    transfereeFdrUrl = transfereeFdrUrl.replace("[param1]", optNum);

    try {
      const api = apiUrl + transfereeFdrUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 

}