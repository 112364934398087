import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Router } from '@angular/router';
import { Globals } from '../../../../../globals';
import { SemlinkaccessService } from '../../../../../services/ea/api/SemlinkaccessService';
import { PartnerselectedComponent } from '../partnerselected.component';
import { jsPDF } from 'jspdf';
//import { DatePipe } from '@angular/common';
import { SemaccessService } from '../../../../../services/ea/api/SemaccessService';
import * as moment from 'moment';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
educationData:any;
optNum:any;
documentId:number;
editPartner: boolean=true;
editor: any;
editDate: any;
educationSize: number;
hasEducationData: boolean=true;
documentDropdownSelect: string;
documentDropdownList: any[];
tableRowLength: number = 0;
datePipeString: any;
loading: boolean = true;
isError: boolean = false;
currentDateTime: any;


  constructor(
    private apiService: ApiService, 
    private router: Router,
    private global: Globals,
    private semLinkAccessService: SemlinkaccessService,
    private partnerSelected: PartnerselectedComponent,
    //private datePipe: DatePipe,
    private access: SemaccessService


    ) {
      this.currentDateTime = moment().format("MM/DD/YYYY");
      //this.datePipeString = datePipe.transform(Date.now(), 'MM/dd/yyy');
     }

  ngOnInit() {
    this.optNum = sessionStorage.getItem('optNum');
    this.documentId = parseInt(sessionStorage.getItem(this.global.documentID));
    this.getAccess();
    this.partnerSelected.loadingEdit = true;
  }


   /**********************************************************************************
  * Method Name: getAccess                                                                 
  * Description: Retrieves access from a reasuable service 
  *                                                                                          
  * #         Date                 Modified by                      Description                             
  * 1         5/19/2020            Jikho Enrique                    Initial draft 
  ***********************************************************************************/
  async getAccess() {
    try {
      await this.access.getSemAccess("education").then();
      if (this.access.role != false) {
        this.partnerSelected.searchInput = "";
        if ((this.access.role['data'][0][0]['semCard'] == "1") && (this.access.role['data'][6][0]['semCardPdsa'] == "1")) {
          this.partnerSelected.editHistory(this.documentId, this.optNum);
          this.partnerSelected.partnerSelectedInfo(this.optNum);
          if (this.access.role['data'][8][0]['ROLE'] != null && this.access.role['data'][8][0]['ROLE'] != undefined) {
            this.access.role['data'][8][0]['ROLE'] != 1 ? this.editPartner = false : "";
            this.getEducationData();
          } else {
            this.router.navigate(['equityadmin/eamain'])
          }
        } else {
          this.router.navigate(['equityadmin/eamain'])
        }
      } else {
        this.router.navigate(['equityadmin/eamain']);
      }
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getAccess', 'education');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getEducationData                                                                 
  * Description: To retrieve the Education data      
  *                                                                                          
  * #         Date                 Modified by                      Description     
  * 1        3/27/2020           divy.o.ardiente                   API connection
  * 2        3/30/2020           divy.o.ardiente                   API connection
  ***********************************************************************************/  
  getEducationData(){
      let accessKey: any = { 
        "functionName": "getEducation",
        "selected_optnum": this.optNum
      }
      this.apiService.postData(this.global.api_URL_pdsDocument,accessKey).subscribe(data => {
        try{
          let contentBody = data.body;
          if(contentBody){
            for(let i = 0; i < contentBody['data'].length; i++){
              for (let key in contentBody['data'][i]) {  
                if (contentBody['data'][i].hasOwnProperty(key)) {
                    if (contentBody['data'][i][key] == null || contentBody['data'][i][key] == undefined) {
                      contentBody['data'][i][key] = '';
                    }
                  
                }
              }
            }
            this.educationData = contentBody['data'];
          }
          
          this.getObjSize(contentBody['data']);
          this.getObjSize(contentBody['data']) == 0 ? this.hasEducationData = false : "";
          this.semLinkAccessService.clearOptNum();
          this.semLinkAccessService.setOptNum(this.optNum);
    
          this.tableRowLength = contentBody['data'].length;
          
       
          this.loading= false;
          this.partnerSelected.loadingEdit = false;
          this.global.searchLoading = false;
        }catch (error) {
          this.loading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('getEducationData', 'education');
          throw error;
        }
      }, error => {
        
        this.loading = false;
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getEducationData', 'education');
        throw error;
        
      });
  }
  

  /**********************************************************************************
  * Method Name: getObjSize                                                                 
  * Description: Returns the length of an object             
  *                                                                                          
  * #         Date                 Modified by                      Description     
  * 1         3/31/2020           divy.o.ardiente                   Initial draft
  ***********************************************************************************/ 

  getObjSize(obj){  //returns the length of an object -JSE
    try {
      let size = 0, key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return this.educationSize = size;
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('getObjSize', 'education');
      throw error;
    }

  }

  /**********************************************************************************
  * Method Name:   printEducationData                                                                
  * Description: To print education data          
  *                                                                                          
  * #         Date                 Modified by                      Description     
  * 1         4/13/2020           divy.o.ardiente                   Initial draft
  ***********************************************************************************/ 
  /* istanbul ignore next */
  printEducationData() {
    try {
      let doc = new jsPDF();
      let fullName;
      this.partnerSelected.partnerSelectedMiddleName != null ? fullName = this.partnerSelected.partnerSelectedFirstName + " " + this.partnerSelected.partnerSelectedMiddleName + " " + this.partnerSelected.partnerSelectedLastName : fullName = this.partnerSelected.partnerSelectedFirstName + " " + this.partnerSelected.partnerSelectedLastName;
      let peopleKey = this.partnerSelected.partnerSelectedPeopleKey.toString();
      let localPN = this.partnerSelected.partnerSelectedLocalPN;
      let personnelNum = this.partnerSelected.partnerSelectedPersonnelNum;
      let item;
      let verticalposition = 63;
      let hrLine = 70;
      let rowCount = 1;

      doc.setFontSize(8);
      doc.text(this.datePipeString, 10, 11);

      doc.setTextColor('#460173');
      doc.setFontSize(10);
      doc.text("Education", 10, 19);

      doc.setFillColor('#460173');
      doc.rect(10, 20, 190, 20, "F");

      doc.setFontSize(15);
      doc.setTextColor('#FFFFFF');
      doc.setFont(undefined, 'bold'); 

      if(fullName.length > 20){
        var name = doc.splitTextToSize(fullName, 60);
        doc.text(name, 20, 29);
      }else{
        doc.text(fullName, 20, 32);
      }

      doc.setFontSize(13);

      doc.text('People Key #', 90, 29);
      doc.text(peopleKey, 97, 34);

      doc.text('Local PN', 130, 29);
      doc.text(localPN.toString(), 130, 34);

      doc.text('Personnel #', 160, 29);
      doc.text(personnelNum.toString(), 162, 34);

      doc.setFontSize(11);
      doc.setTextColor('#000000');

      doc.text('School', 30, 50);
      doc.text('Degree', 80, 50);
      doc.text('Major', 130, 50);
      doc.text('Grad. Year', 170, 50);

      doc.setDrawColor(128, 128, 128);
      doc.line(10, 55, 200, 55);

      doc.setFont(undefined, 'normal');
      
      for (item of this.educationData) {
        var splitSchool = doc.splitTextToSize(item.UNIVERSITY_NAME, 50);
        doc.text(splitSchool, 35, verticalposition, { align: 'center' });

        var splitDegree = doc.splitTextToSize(item.DEGREE_TYPE_DESCRIPTION, 45);
        doc.text(splitDegree, 85, verticalposition, { align: 'center' });

        var splitMajor = doc.splitTextToSize(item.MAJOR_TYPE_DESCRIPTION, 45);
        doc.text(splitMajor, 135, verticalposition, { align: 'center' });

        doc.text(item.GRADUATION_YEAR.toString(), 175, verticalposition);

        rowCount += 1;

        if (this.tableRowLength > 1) {
          if (rowCount != this.tableRowLength) {
            doc.setDrawColor(128, 128, 128);
            doc.line(10, hrLine, 200, hrLine);

            hrLine += 15;
          }
        }
        verticalposition += 15;
      }

      doc.setFontSize(10);
      doc.text('1/1', 195, 290);
      doc.text('Highly Confidential-Internal Use', 80, 290);
      doc.autoPrint({ variant: 'non-conform' });
      doc.save(fullName + '(Education).pdf');
    } catch (error) {
      this.loading = false;
      this.isError = true;
      error.message += this.apiService.setErrorContents('printEducationData', 'education');
      throw error;
    }

  }
}
