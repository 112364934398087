
import {throwError as observableThrowError,  Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable()
export class HoldingProgramDetailsVEIPService {

  private veipStateSource = new Subject<boolean>();
  veipState$ = this.veipStateSource.asObservable();

  constructor(private globals: Globals, private httpClient: HttpClient) { }
  

  setVEIPdetailsState( state: boolean){
    this.veipStateSource.next( state );
  }

  getVEIPdetails(optNum, offerNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let veipDetailsUrl = this.globals.getAwardDetailsVEIPAPI;

    veipDetailsUrl = veipDetailsUrl.replace("[param1]", optNum);
    veipDetailsUrl = veipDetailsUrl.replace("[param2]", offerNum);
    
    try {
      const api = apiUrl + veipDetailsUrl;
      
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getVeipSalesHistory(optNum, offerNum, period, dateFrom?, dateTo?) {
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let veipSalesHistUrl = this.globals.getSalesHistVEIPAPI;

    veipSalesHistUrl = veipSalesHistUrl.replace("[param1]", optNum); //optNum
    veipSalesHistUrl = veipSalesHistUrl.replace("[param2]", offerNum); //offerNum
    veipSalesHistUrl = veipSalesHistUrl.replace("[param3]", period);
    veipSalesHistUrl = veipSalesHistUrl.replace("[param4]", dateFrom);
    veipSalesHistUrl = veipSalesHistUrl.replace("[param5]", dateTo);
    veipSalesHistUrl = veipSalesHistUrl.replace(regEx, "");

    try {
      const api = apiUrl + veipSalesHistUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getVeipCarryOverAPI(optNum, offerNum, purchasedate) {
    let apiUrl = this.globals.currentAPIUrl;
    let veipCarryOverUrl = this.globals.getCarryOverVEIPAPI;

    veipCarryOverUrl = veipCarryOverUrl.replace("[param1]", optNum); //optNum
    veipCarryOverUrl = veipCarryOverUrl.replace("[param2]", offerNum); //offerNum
    veipCarryOverUrl = veipCarryOverUrl.replace("[param3]", purchasedate); //purchasedate

    try {
      const api = apiUrl + veipCarryOverUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getSalesHistoryPeriod() {
    let apiUrl = this.globals.currentAPIUrl;
    let salesHistoryPeriodUrl = this.globals.staticContentAPI + this.globals.staticSalesHistoryPeriod;

    try {
      const api = apiUrl + salesHistoryPeriodUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 

}
