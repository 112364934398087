
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../globals';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { MultipleGrantsService } from './MultipleGrantsService';
// import { NumberValueAccessor } from '@angular/forms/src/directives';
import * as moment from 'moment';
import { BrokerType, GrantAgreementStatus, AdminType } from '../services/enumerations/enumerations';
// import { MatInput } from '@angular/material';
import { tick } from '@angular/core/testing';
import { EspptransactionService } from '../enrollmentwithdraw-espp/EsppTransactionService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { PrintService } from '../services/PrintService';
import { HoldingProgramDetailsOptionsService } from '../holdingprogramdetailspage-options/HoldingProgramDetailsOptionsService';
import { HoldingprogramdetailsRsuService } from '../holdingprogramdetailspage-rsu/HoldingProgramDetailsRSUService';
import { StaticContentService } from '../services/StaticContentService';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

@Component({
  selector: 'app-multiplegrants',
  templateUrl: './multiplegrants.component.html',
  styleUrls: ['./multiplegrants.component.css']
})
export class MultiplegrantsComponent implements OnInit {

  displayedAgreementVestColumns: string[] = ['VestReleaseDate', 'OriginalShares'];
  agreementVestDataSource: any = [];
  displayedAgreementRelColumns: string[] = ['VestReleaseDate', 'OriginalShares'];
  agreementRelDataSource: any = [];
  displayVestAgreementTbl: string[] = ['VestReleaseDate', 'OriginalShares'];
  displayExercisableAgreementTbl: string[] = ['VestReleaseDate', 'OriginalShares'];
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));  
  multipleGrant: any;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));  
  admin = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));
  

  agreementImportantNotes: any;
  displayAgreementImptNotes: boolean = false;
  agreementExchangeRate: any;
  agreementGrantId: any;
  agreementGrantPrice: any;
  agreementRsuGranted: any;
  agreementTotalVal: any;
  agreementStepOne: any;
  agreementStepThreeValStatus: string;
  agreementStepOneStatus: any;
  displayReturnSigned: boolean;
  stepOneStatus: boolean;
  disableAcceptGrantBtn: boolean;
  agreementVestTable: any[] = [];
  agreementReleaseTable: any[] = [];
  displayAgreementTable: boolean;  
  agreementMethodExecuted: boolean;
  awardName: string;
  grantNum: any;
  grantHeader: any;
  grantKey: any;
  grantOptNum: any;
  grantSubMessage: any;
  agreementStepTwo: any;
  agreementStepThree: any;
  agreementStepTwoStatus: any;
  agreementStepThreeStatus: any;
  displayAgreementContent: boolean;  
  stepTwoStatus: boolean;
  stepThreeStatus: boolean;
  selectedGrantNum: any;
  grantStatus: boolean;
  selectedGrantStatus: boolean;
  multigrantNumStatus: any[];
  step1Text: any;
  step1Status: any;
  urlGrantDocument: any;
  rsuUrlDocument: any;
  step2Text: any;
  step2Status: any;
  optGranted: any;
  grantPrice: any;
  optgrantID: any;
  optExchRate: any;
  step3Status: any;
  step3Text: any;
  MailAddVisible: any;
  step3ValStatus: any;
  showNotes: boolean;
  importantNotes: any;
  showVestTbl: boolean;
  showExerTbl: boolean;
  agreementExerciseTable: any;
  showSaleTbl: boolean;
  agreementSaleTable: any;
  vestdataSource: any[];
  exerdataSource: any;
  saleDataSource: any;
  showOptionsAgreement: boolean = false;
  showRSUAgreement: boolean = false;
  grantShortDesc: any;
  AwardDescription: any;
  showAcceptBtn: boolean;
  showAcceptedBtn: boolean;
  loading: boolean = true;
  grantInfoLoading: boolean = false;    
  isDisabled: boolean = false;
  errorLocation: string = " + [Component: Multiplegrants, Method: methodName]";
  errorRSUGrants: boolean;
  errorOptionsGrants: boolean;

  indexChecker: number = 0;
  indexLength: number;
  grantIndex: number;
  tabSelector: string;
  disablePrevButton: boolean;
  disableNextButton: boolean;
  userOptnum: any;

  constructor(private staticContentService: StaticContentService, private globals: Globals, private app: AppComponent, private router: Router, private multiplegrantsservice: MultipleGrantsService, private espptransactionService: EspptransactionService, private errorNotificationService: ErrorNotificationService, private printService: PrintService, private optionDetailsService: HoldingProgramDetailsOptionsService, private rsuDetailsService: HoldingprogramdetailsRsuService) { }

  ngOnInit() {     
    this.checkErrorMessage();    
    this.getMultipleGrantDetails();      
  }

  navToPage(strLoc) {
    this.router.navigate([strLoc]);
  }

  /**********************************************************************************
      Method Name: getMultipleGrantDetails
      Description: Get multiple grant agreement details

      #     Date            Modified by       Description
      1     3/20/19         Roldan Mazo       Initial Draft
      2     3/22/19         Dalemark Suquib   Added refresh condition
  **********************************************************************************/
  getMultipleGrantDetails(){              
        try{          

          this.userOptnum = JSON.parse(sessionStorage.getItem("User Optnum"));       
          
          if(performance.navigation.type == performance.navigation.TYPE_RELOAD && this.userOptnum == this.user.OptNum) {            
                       
            this.multipleGrant = JSON.parse(sessionStorage.getItem("Multiple Grant List")); 
            let storedGrantList = JSON.parse(sessionStorage.getItem("Updated Grant List"));                                    

            if(storedGrantList != null){
              storedGrantList = storedGrantList.sort((a, b) => a.GrantShortDesc < b.GrantShortDesc ? -1 : a.GrantShortDesc > b.GrantShortDesc ? 1 : 0);
            }
            
            this.multipleGrant = this.multipleGrant.sort((a, b) => a.GrantShortDesc < b.GrantShortDesc ? -1 : a.GrantShortDesc > b.GrantShortDesc ? 1 : 0);                        

            if(storedGrantList != null) {
              if(this.multipleGrant.length == storedGrantList.length) {
                this.multipleGrant = storedGrantList;
                // this.indexLength = this.multipleGrant.length;
              } 
              else {
                // this.indexLength = this.multipleGrant.length;
              }
            }                             
          } else { // performance navigation type = 0            

            this.multipleGrant = JSON.parse(sessionStorage.getItem("Multiple Grant List"));            
         
            this.multipleGrant = this.multipleGrant.sort((a, b) => a.GrantShortDesc < b.GrantShortDesc ? -1 : a.GrantShortDesc > b.GrantShortDesc ? 1 : 0);
            this.indexLength = this.multipleGrant.length;
            for(let i = 0; i < this.multipleGrant.length; i++) {              
              this.multipleGrant[i].grantStatus = false;       
              sessionStorage.setItem('User Optnum', JSON.stringify(this.user.OptNum))
            }
          }
          
          if(Array.isArray(this.multipleGrant) && this.multipleGrant.length > 0) {                          
            for(let i = 0; i < this.multipleGrant.length; i++){
                
                this.grantNum = this.multipleGrant[i].GrantNum;   
                this.grantHeader = this.multipleGrant[i].Header;
                this.grantKey = this.multipleGrant[i].Key;
                this.grantOptNum = this.multipleGrant[i].OptNum;
                this.grantSubMessage = this.multipleGrant[i].SubMessage;                                                                                                                                                                                                                                                        
            } 
            this.indexLength = this.multipleGrant.length;            
          }

          if(this.multipleGrant[0].SubMessage.indexOf('Opt') > -1){
            this.showOptionsAgreement = true;
            this.tabSelector = "Options";
            this.getOptionSummary(this.multipleGrant[0].GrantNum);
            this.getOptionsAgreementInfo(this.multipleGrant[0].GrantNum); 
            this.grantShortDesc = this.multipleGrant[0].GrantShortDesc;                           
            }            
          else {                          
            this.showRSUAgreement = true;
            this.tabSelector = "RSU"; 
            this.getRsuDetails(this.multipleGrant[0].GrantNum);                  
            this.getRSUAgreementInfo(this.multipleGrant[0].GrantNum);  
            this.grantShortDesc = this.multipleGrant[0].GrantShortDesc;                                             
          }                                   
        }
        catch(error) {
          this.errorRSUGrants = true;
          this.errorOptionsGrants = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getMultipleGrantDetails");
          error.message = error.message + errorLocation;
          throw error;
        }      
  }

  /*****************************************************************************************************************
      Method Name: multipleGrantChecker
      Description: Check which agreement should be displayed and API to call

      #     Date            Modified by       Description
      1     6/17/19         Roldan Mazo       Initial Draft    
  ******************************************************************************************************************/
multipleGrantChecker(multipledata){ 

  if(multipledata.SubMessage.indexOf('Opt') > -1){
    this.showOptionsAgreement = true;
    this.showRSUAgreement = false;
    this.tabSelector = "Options";
    this.getOptionSummary(multipledata.GrantNum);
    this.getOptionsAgreementInfo(multipledata.GrantNum); 
    this.grantShortDesc = multipledata.GrantShortDesc;                        
    }            
  else {
    this.showRSUAgreement = true;  
    this.showOptionsAgreement = false; 
    this.tabSelector = "RSU"; 
    this.getRsuDetails(multipledata.GrantNum);                  
    this.getRSUAgreementInfo(multipledata.GrantNum);  
    this.grantShortDesc = multipledata.GrantShortDesc;                                    
  }   
}

  /*****************************************************************************************************************
      Method Name: getRSUAgreementInfo
      Description: Get RSU grant agreement details

      #     Date            Modified by       Description
      1     3/20/19         Roldan Mazo       Initial Draft
      2     3/22/19         Dalemark Suquib   Updated condition in setting check mark
      3     3/26/19         Roldan Mazo       Updated condition to set vest and rel to zero before placing new data
  ******************************************************************************************************************/

 getRSUAgreementInfo(row) {
  try {    
    
    this.displayAgreementImptNotes = false;
    this.grantInfoLoading = true;  
    this.isDisabled = true;        
    for(let i = 0; i < this.multipleGrant.length; i++) {
      this.multipleGrant[i].highlight = false;
      if(this.multipleGrant[i].GrantNum == row) {
        this.multipleGrant[i].highlight = true;
        this.indexChecker = i;
        this.grantIndex = i + 1;
        this.grantIndex == 1 ? this.disablePrevButton = true: this.disablePrevButton = false;
        this.grantIndex == this.indexLength ? this.disableNextButton = true : this.disableNextButton = false; 
      }
    }
    
    this.multiplegrantsservice.getRSUAgreementInfo(row).pipe(map(res => res)).subscribe(data => {  
      try {
        let agreementContent = data[0][0];  
        this.rsuDetailsService.agreementContent = agreementContent;
        let agreementTable = data[1];
        this.agreementImportantNotes = data[2];    
        
        if(this.agreementImportantNotes.length > 0) {
          this.displayAgreementImptNotes = true;
        }
        this.grantShortDesc =   agreementContent.GrantShortDesc;   
        this.AwardDescription = agreementContent.GrantLongDesc;
        this.selectedGrantNum = agreementContent.GrantNum;
        this.agreementExchangeRate = agreementContent.ExchangeRate;
        this.agreementGrantId = agreementContent.GrantId;
        this.agreementGrantPrice = agreementContent.RsuGrantPrice;
        this.agreementRsuGranted = agreementContent.RsuSharesGranted;
        this.agreementTotalVal = agreementContent.RsuTotalValue;
        this.agreementStepOne = agreementContent.Step1Text;
        this.agreementStepTwo = agreementContent.Step2Text;
        this.agreementStepThree = agreementContent.Step3Text;
        this.agreementStepThreeValStatus = GrantAgreementStatus[agreementContent.Step3ValStatus];
        this.agreementStepOneStatus = agreementContent.Step1Status;
        this.agreementStepTwoStatus = agreementContent.Step2Status;
        this.agreementStepThreeStatus = agreementContent.Step3Status;
        this.rsuUrlDocument = agreementContent.PDFName;
    
        let mailAddVis = agreementContent.MailAddVisible;
    
        if(this.agreementStepOneStatus == "(Action Required)") {
          this.displayReturnSigned = true;
          this.stepOneStatus = true;
        } 
    
        if(this.agreementStepTwoStatus == "(Action Required)" && mailAddVis == 1) {
          this.displayReturnSigned = true;
          this.stepTwoStatus = true;
        } 
    
        if(this.agreementStepThreeValStatus == GrantAgreementStatus[1]) { 
          this.disableAcceptGrantBtn = false;       
          this.disableAcceptGrantBtn = this.checkUser();                   
        }
    
        if(this.agreementStepThreeStatus == "(Action Required)") {
          this.stepThreeStatus = true;
          this.showAcceptBtn = true;          
          this.showAcceptedBtn = false;
          }
        else{      
          this.showAcceptBtn = false;          
          this.showAcceptedBtn = true;
          this.stepThreeStatus = false;
          this.disableAcceptGrantBtn =  true;          
        }            
        for(let i = 0; i < this.multipleGrant.length; i++) {
          if (this.agreementStepThreeStatus != "(Action Required)"){ 
            if(this.multipleGrant[i].GrantNum == row) {              
              this.multipleGrant[i].grantStatus = true;
            }
          }
        }
    
        sessionStorage.setItem("Updated Grant List", JSON.stringify(this.multipleGrant));
    
        if(agreementTable.length > 0) {
    
          this.agreementVestTable = [];
          this.agreementReleaseTable = [];
    
          for(let i = 0; i < agreementTable.length; i++) {
            if(agreementTable[i].OriginalShares != null) {
              if(agreementTable[i].VestReleaseFlag == 5) {
                this.agreementVestTable.push(agreementTable[i]);            
              } else {
                this.agreementReleaseTable.push(agreementTable[i]);            
              }
            } else {
              agreementTable[i].OriginalShares = 0;
              if(agreementTable[i].VestReleaseFlag == 5) {
                this.agreementVestTable.push(agreementTable[i]);            
              } else {
                this.agreementReleaseTable.push(agreementTable[i]);                   
              }
            }
          }
          this.displayAgreementTable = true;
        } else {
          this.displayAgreementTable = false;
        }
    
        if(GrantAgreementStatus[agreementContent.OverallStatus] == "NotYetAvailable" && agreementContent.FrenchQualifiedInd == 1) {
          this.displayAgreementContent = false;
        }
    
        this.agreementVestDataSource = this.agreementVestTable;
        this.rsuDetailsService.agreementVestDataSource = this.agreementVestDataSource;
        this.agreementRelDataSource = this.agreementReleaseTable;
        this.rsuDetailsService.agreementRelDataSource = this.agreementRelDataSource;
        this.agreementMethodExecuted = true;
    
        if(this.awardName == "Div"){ 
          if(GrantAgreementStatus[agreementContent.OverallStatus] == "NotYetAvailable" && agreementContent.FrenchQualifiedInd == 1) {
            this.displayAgreementContent = false;
          }
        }    
        this.grantInfoLoading = false;       
        this.isDisabled = false;    
        this.loading = false;   
      } catch(error) {
        this.errorRSUGrants = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getRSUAgreementInfo");
        error.message += errorLocation;
        throw error;
      }
  }, error => {
    this.errorRSUGrants = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getRSUAgreementInfo");
    error.error.message += errorLocation;
    throw error.error;
  });  
  } catch(error) {
    this.errorRSUGrants = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getRSUAgreementInfo");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
      Method Name: getOptionsAgreementInfo
      Description: Get Options grant agreement details

      #     Date            Modified by       Description
      1     3/22/19         Roldan Mazo       Initial Draft
  **********************************************************************************/

getOptionsAgreementInfo(row){
  try {        
    this.grantInfoLoading = true; 
    this.isDisabled = true; 
    
    for(let i = 0; i < this.multipleGrant.length; i++) {
      this.multipleGrant[i].highlight = false;
      if(this.multipleGrant[i].GrantNum == row) {
        this.multipleGrant[i].highlight = true;
        this.indexChecker = i;
        this.grantIndex = i + 1;
        this.grantIndex == 1 ? this.disablePrevButton = true: this.disablePrevButton = false;
        this.grantIndex == this.indexLength ? this.disableNextButton = true : this.disableNextButton = false; 
      }
    }

    this.multiplegrantsservice.getGrantAgreement(row).pipe(map(res => res)).subscribe(data => { 
      
      try {
        this.agreementVestTable = [];
        this.showNotes = false;
  
        let optAgreementDetails = data[0][0];
        this.optionDetailsService.grantAgreementDetails = optAgreementDetails;
        let optAgreementTbl = data[1];
        let importantNotes = data[2];
  
        this.AwardDescription = optAgreementDetails.GrantLongDesc;
        this.grantShortDesc = optAgreementDetails.GrantShortDesc;
        this.selectedGrantNum = optAgreementDetails.GrantNum;
        this.step1Text =  optAgreementDetails.Step1Text;
        this.step1Status =   optAgreementDetails.Step1Status;
        this.urlGrantDocument =  optAgreementDetails.PDFName;
        this.step2Text =  optAgreementDetails.Step2Text;
        this.step2Status =  optAgreementDetails.Step2Status;
        this.optGranted =  optAgreementDetails.OptSharesGranted;
        this.grantPrice =  optAgreementDetails.OptGrantPrice;
        this.optgrantID =  optAgreementDetails.GrantId;
        this.optExchRate =  optAgreementDetails.ExchangeRate;
        this.step3Status  = optAgreementDetails.Step3Status;
        this.step3Text  =  optAgreementDetails.Step3Text;
        this.MailAddVisible = optAgreementDetails.MailAddVisible;
        this.step3ValStatus = optAgreementDetails.Step3ValStatus;
        // this.agreementStepThreeStatus = optAgreementDetails.Step3Status;
  
        if(importantNotes == undefined || importantNotes == "" || importantNotes == null){}
        else{
          this.showNotes = true;

          if(Array.isArray(importantNotes)) {
            this.importantNotes = importantNotes;
          } else {
            this.importantNotes.push(importantNotes.Message);
          }
        }
  
        if(this.step1Status == "Action Required") {
          this.displayReturnSigned = true;
          this.stepOneStatus = true;
        } 
  
        if(this.step2Status == "Action Required") {
          this.stepTwoStatus = true;
  
          if(this.MailAddVisible == 1){
            this.displayReturnSigned = true;
          }
        } 
  
        if(this.step3ValStatus == GrantAgreementStatus[1]) { 
          this.disableAcceptGrantBtn = false;
          this.disableAcceptGrantBtn = this.checkUser();
        }
  
        if(this.step3Status == "(Action Required)") {
          this.stepThreeStatus = true;
          this.disableAcceptGrantBtn =  false;
          this.showAcceptBtn = true;          
          this.showAcceptedBtn = false;
        }else{
          this.showAcceptBtn = false;          
          this.showAcceptedBtn = true;
          this.stepThreeStatus = false;
          this.disableAcceptGrantBtn =  true;
        }                

        for(let i = 0; i < this.multipleGrant.length; i++) {
          if (this.step3Status != "(Action Required)"){ 
            if(this.multipleGrant[i].GrantNum == row) {              
              this.multipleGrant[i].grantStatus = true;
            }
          }
        }
  
        for(let i = 0; i < optAgreementTbl.length; i++) {
          if(optAgreementTbl[i].VestReleaseFlag == 1) {
            this.showVestTbl = true;
            this.agreementVestTable.push(optAgreementTbl[i]);
          }else if(optAgreementTbl[i].VestReleaseFlag == 3) {
            this.showExerTbl = true;
            this.agreementExerciseTable.push(optAgreementTbl[i]);
          }else if(optAgreementTbl[i].VestReleaseFlag == 4) {
            this.showSaleTbl = true;
            this.agreementSaleTable.push(optAgreementTbl[i]);
          }
        }
      this.vestdataSource = this.agreementVestTable;
      this.optionDetailsService.vestDataSource = this.agreementVestTable;
      this.exerdataSource = this.agreementExerciseTable;
      this.saleDataSource = this.agreementSaleTable;
  
      this.grantInfoLoading = false;    
      this.loading = false;
      this.isDisabled = false;  
      } catch(error) {
        this.errorOptionsGrants = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getOptionsAgreementInfo");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorOptionsGrants = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getOptionsAgreementInfo");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorOptionsGrants = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getOptionsAgreementInfo");
    error.message += errorLocation;
    throw error;
  }
}

acceptGrant(){  
  if(!this.disableAcceptGrantBtn){
  try {
    let userOptnum = this.user.OptNum;
    let adminOptnum;
    let grantNum = this.selectedGrantNum;
  
    if(this.admin != null || this.admin != undefined){
      adminOptnum = this.admin.OptNum;
    }else{
      adminOptnum = userOptnum;
    }
    
    if (this.multipleGrant[0].SubMessage.indexOf('Opt') > -1){            
        this.multiplegrantsservice.OptionsgrantAccepted(userOptnum, adminOptnum, grantNum).subscribe(data => {
        try {          
          alert("Thank you for accepting your grant!\n Please check the Alert box on the Holdings page to confirm if you have any additional grants to accept.");  
          sessionStorage.removeItem("Options Data");       
          this.getOptionsAgreementInfo(grantNum);   
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
          error.message += errorLocation;
          throw error;
        }          
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
   
    else { 
      this.multiplegrantsservice.RSUgrantAccepted(userOptnum, adminOptnum, grantNum).subscribe(data => {        
      try {
        alert("Thank you for accepting your grant!\n Please check the Alert box on the Holdings page to confirm if you have any additional grants to accept."); 
        sessionStorage.removeItem("RSU Data");   
        this.getRSUAgreementInfo(grantNum);
     } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
      error.error.message += errorLocation;
      throw error.error;
    });} 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "acceptGrant");
    error.message += errorLocation;
    throw error;
  }
  }
}

checkUser(): boolean {
  try { 
   if(this.admin != null && this.admin != "" && this.admin != undefined){
    if(this.admin.OptNum == this.user.OptNum) {
      return false; // enables the button
    } else if((this.admin.OptNum != this.user.OptNum) && (AdminType[this.admin.IsAdminCanTransactInd] == AdminType[2])) {
      return false; // enables the button
    } else if((this.admin.IsAdmin == 'Y') && (AdminType[this.admin.IsAdminCanTransactInd] == AdminType[1])) {
      return true; // disables the button
    } else if(this.admin.OptNum == this.user.OptNum) {
      return true; // disables the button
    }
  }
  else {
    return false;
  }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "checkUser");
    error.message += errorLocation;
    throw error;
  }
 }

// Agreement Tab Method - END


// COMMAFY
commafy(num: any, value?: number) {
  try {
  // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value >= 0) {
          let number = (num*1).toLocaleString('en', {
            //maximumFractionDigits: value
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Multiplegrants") != -1 && this.user != null) {
                webPage = "Multiplegrants";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
  
  mobileToggle(operation){   

    let tempIndex;
    operation == "Next" ? tempIndex = this.indexChecker + 1 : tempIndex = this.indexChecker - 1;

    if(tempIndex < 0){
      tempIndex = 0;
    }
    if(tempIndex > this.multipleGrant.length - 1){
      tempIndex = this.multipleGrant.length -1;
    }

    this.multipleGrantChecker(this.multipleGrant[tempIndex]);
    // this.tabSelector == "Options" ? this.getOptionsAgreementInfo(this.multipleGrant[tempIndex].GrantNum) : this.getRSUAgreementInfo(this.multipleGrant[tempIndex].GrantNum);  
    
  }

    /*****************************************************************************************
    * Method Name: printFile                                                                 
    * Description: Prints the file.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    06/07/19             Dalemark P. Suquib                    Initial Draft
    *****************************************************************************************/
  printFile(award: string) {
    try {
      this.printService.print(award);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "printFile");
      error.message += errorLocation;
      throw error;
    } 
  }

  /*****************************************************************************************
  * Method Name: getOptionSummary                                                                 
  * Description: Gets the summary of Options Award available.
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/11/19             Dalemark P. Suquib                    Initial Draft
  *****************************************************************************************/
  getOptionSummary(grantNum) {
    try {
      this.optionDetailsService.getAwardDetailsOptions(grantNum).pipe(map(res => res)).subscribe(data => {
        try {
          if(data != null && data[0].length > 0) {
            let optSummary = data[0][0];
            this.optionDetailsService.optionsSummary = optSummary;
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getOptionSummary");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getOptionSummary");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getOptionSummary");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
  * Method Name: getRsuDetails                                                                 
  * Description: Gets the details of Rsu Award.
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/11/19             Dalemark P. Suquib                    Initial Draft
  *****************************************************************************************/
  getRsuDetails(grantNum) {
    try {
      this.rsuDetailsService.getRSUDetails(grantNum).pipe(map(res => res)).subscribe(data => {
        try {
          if(data != null && data[0].length > 0) {
            let shareAct = data[0][0];
            this.rsuDetailsService.shareAct = shareAct;
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRsuDetails");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getOptionSummary");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRsuDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: downloadGrantAgreement
      Description: Download step 1 grant agreement file

      Date            Modified by       Description
      04/22/19        Katrina Narag     Initial draft
  **********************************************************************************/
  downloadGrantAgreement(award){
    let filename: string;

    award == "options" ? filename = this.urlGrantDocument : filename = this.rsuUrlDocument;

      try {
        if(filename != null || filename != null){
          if(filename != "NO EGT PAGE"){
            this.getFile(filename, 'dynamic');
          }
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "downloadGrantAgreement");
        error.message += errorLocation;
        throw error;
      }
    }

  /*********************************************************************************************
Method Name: getFile
Description: Get file data

#     Date            Modified by             Description
1     04/22/19        Katrina Narag         Initial Draft
2     05/03/19        KMN                   Add method for no file exists
**********************************************************************************************/
getFile(file, checker) {
    try {
      let fileName: string;
      let fileType: string;
      
      if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
      } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file;
      }
      
      this.staticContentService.getFile(fileName, "GA").subscribe(data => { // MD for contacts page
        try {
          let content = data;
          if(content == null || content == ""){
            this.staticContentService.noFileExists(fileName);
          }else{
            let file = JSON.parse(data.body);
            this.downloadFile(fileName, fileType, this.base64ToArrayBuffer(file.file));
            this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
              let writeLogResponse = data;
            });
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFile");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.message += errorLocation;
      throw error;
    }
  }

  /*********************************************************************************************
Method Name: downloadFile
Description: Downloads the file.

#     Date            Modified by             Description
1     04/22/19        Katrina Narag      Initial Draft
**********************************************************************************************/
downloadFile(reportName, fileType, byte) {
    try {
      if(navigator.appVersion.toString().indexOf('.NET') > 0) { // browser is IE
        let blob = new Blob([byte], {type: fileType});
        window.navigator.msSaveBlob(blob, reportName);
        } else if(navigator.userAgent.indexOf("Firefox") > 0) { // browser is FireFox
        let a = document.createElement('a');
        a.style.display = "none";
        a.style.zIndex = "1002";
        a.style.position = "absolute";
        let blob = new Blob([byte], {type: fileType});
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = reportName;
        document.body.appendChild(a);
        a.click();
        
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
        } else { // browser is Chrome
        let blob = new Blob([byte], {type: fileType});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.click();
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "downloadFile");
      error.message += errorLocation;
      throw error;
    }
  }

  
/*********************************************************************************************
Method Name: base64ToArrayBuffer
Description: Converts base 64 to array buffer.

#     Date            Modified by             Description
1     04/22/19        Katrina Narag      Initial Draft
**********************************************************************************************/
base64ToArrayBuffer(base64): Uint8Array {
  try {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);
    
    for(let i = 0; i < binaryLength; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
    }
    return bytes;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "base64ToArrayBuffer");
    error.message += errorLocation;
    throw error;
  }
}
}