import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { HoldingProgramDetailsRSUIPOService } from '.././HoldingProgramDetailsRSUIPOService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { tScript } from '../../services/enumerations/enumerations';
import {TooltipPosition} from '@angular/material/tooltip';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-iporsu-details-card',
  templateUrl: './iporsu-details-card.component.html',
  styleUrls: ['./iporsu-details-card.component.css']
})
export class IporsuDetailsCardComponent implements OnInit {
  
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  user: any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = this.user.CurrencyCd;
  errorLocation: string = " + [Component: Rsuipodetails, Method: methodName]";


  //TOOLTIP Variables
positionOption: TooltipPosition[]=['after', 'before', 'above', 'below', 'left', 'right'];
grantDesc: string = tScript.rsuipo_grantDesc;
netRemaining: string = tScript.rsuipo_netRemainingBroker;
vestColumn: string = tScript.rsuipo_vest_column;
vstatusColumn: string = tScript.rsuipo_vstatus_column;
origSchedVest: string = tScript.rsuipo_origSchedVest;
addRsuLieuDividends: string = tScript.rsuipo_addRsuLieuDividends;
totalSchedVest: string = tScript.rsuipo_totalSchedVest;
vestfmv: string = tScript.rsuipo_vestfmv;
tooltipVestValue: string = tScript.rsuipo_vestvalue;
releaseColumn: string = tScript.rsuipo_release_column;
rstatusColumn: string = tScript.rsuipo_rstatus_column;

origSchedRelease: string = tScript.rsuipo_origSchedRelease;
addRsuLieuDividendsRelease: string = tScript.rsuipo_addRsuLieuDividendsRelease;
releasefmv: string = tScript.rsuipo_releasefmv;
taxWithholdingMethod: string = tScript.rsuipo_taxWithholdingMethod;
grossReleased: string = tScript.rsuipo_grossReleased;
withheldForTax: string = tScript.rsuipo_withheldForTax;
withholdingRefund: string = tScript.rsuipo_withholdingRefund;
forfietedTooltip: string = tScript.rsuipo_forfieted;
salesHistType: string = tScript.rsuipo_salesHistType;
salesHistShare: string = tScript.rsuipo_salesHistShare;
salesHistProceeds: string = tScript.rsuipo_salesHistProceeds;

  shortAwardName: any;
  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsSharesReleasing: any;
  detailsNextRelease: any;
  detailsUnreleased: any;
  detailsGrantAccepted: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;
  displayAgreementTab: boolean;
  yearToDate: any;
  lastYear: any;
  last3Years: any;
  allDates: any;
  custom: any;

  
  ipoGrantDesc: string;
  ipoGrantPrice: number;
  ipoTotalAwarded: number;
  ipoUnreleased: number;
  ipoForfeited: number;
  ipoReleased: number;
  ipoWithholdingTaxes: number;
  ipoSoldTransferred: number;
  ipoNetRemainingAtBroker: number;
  ipoOriginalGranted: number;
  ipoOriginalGrantedValue: any;
  ipoOriginalAdditionalRsu: number;
  IPOvestAdditionalRsu: any;
  ipoOriginalAdditionalRsuValue: any;
  ipototalAwardedValue: any;
  shareActGrantDate: any;
  errorDetailsSection: boolean;

  sharesUnvested: any;
sharesUnvestedValue: any;
vestedButUnreleased: any;
vestedButUnreleasedValue: any;
totalUnreleased: any;
totalUnreleasedValue: any;
iporsuDetailsReleased: any;
withheldForTaxData: any;
withheldForTaxValue: any;
withHoldingRefund: any;
withHoldingRefundValue: any;
totalWithheld: any;
totalWithheldValue: any;
totalSharesRelease: any = 0;
totalSharesReleaseValue: any = 0;
  periodSelected: any;
  ipoWithholdingForTaxes: any;
  OptionsLoading: boolean = true;

  

  constructor(private globals: Globals, 
    private app: AppComponent, private router: Router, 
    private HoldingProgramDetailsRSUIpoService: HoldingProgramDetailsRSUIPOService,
    private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {
     // BACK BUTTON BUG - START
     sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
     let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
     let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));
 
     if(backUrl) {
       if(detailsStorage.length > 0) {
         this.programDetails = detailsStorage[detailsStorage.length - 1];
       }
     } else {
       this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
     }
     // BACK BUTTON BUG - END

    this.getRSUIPODetails();
  }


getRSUIPODetails() {
  try {
    this.shortAwardName = this.programDetails.AwardName;
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetails(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      try {
        
        if(Array.isArray(data) && data.length > 0) {
          let ipoRSUDetails = data[0][0];
          if(ipoRSUDetails != null || ipoRSUDetails != undefined){
            this.ipoGrantDesc = ipoRSUDetails.GrantDescription;
            this.shareActGrantDate = ipoRSUDetails.GrantDate;
            this.ipoGrantPrice = ipoRSUDetails.GrantPrice;
            this.ipoTotalAwarded = ipoRSUDetails.TotalAwarded;
            this.ipoUnreleased = ipoRSUDetails.Unreleased;
            this.ipoForfeited = ipoRSUDetails.Forfeited;
            this.ipoReleased = ipoRSUDetails.Released;
            this.ipoWithholdingTaxes = ipoRSUDetails.WithheldForTax;
            this.ipoSoldTransferred = ipoRSUDetails.SoldTransferred;
            this.ipoNetRemainingAtBroker = ipoRSUDetails.NetRemainingAtBroker;
            this.ipoWithholdingForTaxes = ipoRSUDetails.WithholdingforTaxes;
          }

          let iporsudetailsGrantedData = data[1][0];   
          
          if(iporsudetailsGrantedData != null || iporsudetailsGrantedData != undefined){
            this.ipoOriginalGranted = iporsudetailsGrantedData.OriginalGranted;
            this.ipoOriginalGrantedValue = iporsudetailsGrantedData.OriginalGrantedValue;
            this.ipoOriginalAdditionalRsu = iporsudetailsGrantedData.AdditionalRsu;
            this.ipoOriginalAdditionalRsuValue = iporsudetailsGrantedData.AdditionalRsuValue;
            this.ipototalAwardedValue = this.ipoOriginalGrantedValue + this.ipoOriginalAdditionalRsuValue; 
    
          }
    
          let iporsuDetailsUnvestedData = data[2][0];

          if(iporsuDetailsUnvestedData != null || iporsuDetailsUnvestedData != undefined){
            this.sharesUnvested = iporsuDetailsUnvestedData.SharesUnvested;
            this.sharesUnvestedValue = this.sharesUnvested*this.ipoGrantPrice;
            this.vestedButUnreleased = iporsuDetailsUnvestedData.VestedButUnreleased;
            this.vestedButUnreleasedValue = this.vestedButUnreleased*this.ipoGrantPrice;
            this.totalUnreleased = iporsuDetailsUnvestedData.TotalUnreleased;
            this.totalUnreleasedValue = this.totalUnreleased*this.ipoGrantPrice;
          }

          this.iporsuDetailsReleased = data[3];

          for(let i=0; i<this.iporsuDetailsReleased.length;i++){
            this.totalSharesRelease += this.iporsuDetailsReleased[i].Shares;
            this.totalSharesReleaseValue += this.iporsuDetailsReleased[i].Value;
          }

          let iporsuDetailsWithheldData = data[4][0];
          
          if(iporsuDetailsWithheldData != null || iporsuDetailsWithheldData != undefined){
            this.withheldForTaxData = iporsuDetailsWithheldData.WithheldForTax;
            this.withheldForTaxValue = iporsuDetailsWithheldData.WithheldForTaxValue;
            this.withHoldingRefund = iporsuDetailsWithheldData.WithHoldingRefund;
            this.withHoldingRefundValue = iporsuDetailsWithheldData.WithHoldingRefundValue;
            this.totalWithheld = iporsuDetailsWithheldData.TotalWithheld;
            this.totalWithheldValue = iporsuDetailsWithheldData.TotalWithheldValue;
          }
        }
        this.OptionsLoading = false;
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
    error.message += errorLocation;
    throw error;
  }
}

commafy(num: any, value?: number) {
  try {
  // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value > 0) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message += errorLocation;
    throw error;
  }
}



}
