import { Injectable } from '@angular/core';
import { Globals } from '../../../globals';
import { ApiService } from './ApiService';
import { EaLoadingService } from '../EaLoadingService';

@Injectable()
export class RoleaccessService {

  roleId: number;

  constructor(
    private globals: Globals,
    private apiService: ApiService,
    private loadingService: EaLoadingService
  ) { }

  /**********************************************************************************
  * Method Name: getRoleAccess                                                                 
  * Description: Gets the role access of the user.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/12/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getRoleAccess() {
    let roleAccess = new Promise<boolean>((resolve) => {
      let getEquityAdminRolesBody = {
        functionName: 'getEquityAdminRoles'
      };

      this.apiService.postData(this.globals.manageUserAccessApi, getEquityAdminRolesBody).subscribe(data => {
        try {
          if(data) {
            if(data.statusCode == 200) {
              let body = data.body;
    
              if(body != this.globals.notAuthorized) {
                let roleId = body[0].ROLE_ID;
                
                if(roleId >= 5 && roleId <= 7) {
                  this.setUserRoleAccess(roleId);
                } else {
                  this.setUserRoleAccess(null);
                }

                resolve(true);
              } else {
                this.setUserRoleAccess(null);
                resolve(true);
              }
            } else {
              this.setUserRoleAccess(null);
              resolve(true);
            }
          } else {
            this.setUserRoleAccess(null);
            resolve(true);
          }
        } catch(error) {
          this.loadingService.setLoadingState(false);
          this.loadingService.setAppError(true);
          error.message += this.apiService.setErrorContents('getApplicationAccess', 'ApplicationaccessService');
          throw error;
        }
      }, error => {
        this.loadingService.setLoadingState(false);
        this.loadingService.setAppError(true);
        error.error.message += this.apiService.setErrorContents('getApplicationAccess', 'ApplicationaccessService');
        throw error;
      });
    });

    return roleAccess;
  }

  /**********************************************************************************
  * Method Name: setUserRoleAccess                                                                 
  * Description: Sets the user's role access.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/12/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setUserRoleAccess(roleId: number): void {
    this.roleId = roleId;
  }

  /**********************************************************************************
  * Method Name: getUserRoleAccess                                                                 
  * Description: Gets the user's role access.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/12/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getUserRoleAccess(): number {
    return this.roleId;
  }

}
