
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
// import { MatInput } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import * as moment from 'moment'
import { BrokerType, PageSection, FileType, MyHoldingsPagesResources, tScript} from '../../services/enumerations/enumerations';
import { HoldingProgramDetailsVEIPService } from '.././HoldingProgramDetailsVEIPService';
import { StaticContentService } from '../../services/StaticContentService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { BrokersPageService } from '../../brokerspage/BrokersPageService';
import {TooltipPosition} from '@angular/material/tooltip';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-veip-monthlypurchase-card',
  templateUrl: './veip-monthlypurchase-card.component.html',
  styleUrls: ['./veip-monthlypurchase-card.component.css']
})
export class VeipMonthlypurchaseCardComponent implements OnInit {
  veipdataSource: any;
  loading: boolean = true;
  errorDetailsTab: boolean = false;
  errorLocation: string = " + [Component: Veipdetails, Method: methodName]";
  ContributionPercentage_mtt: string = tScript.veipdetailspage_c;
  TotalContribution_mtt: string = tScript.veipdetailspage_tc;
  positionOption: TooltipPosition[]=['after', 'before', 'above', 'below', 'left', 'right'];
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  displayedColumnsVeip: string[] = ['Date', 'PurchasePriceUSD', 'PurchasedShares' , 'ValueUSD', 'ValueLoc','Disposed', 'RemainingBroker'];
  veipTableLoading: boolean;
  veiplocalcurrencycd: string;
  monthlycontributionusd: number;
  monthlycontributionloc: number;
  priormonthscarryoverusd: number;
  priormonthscarryoverloc: number;
  totalcontributionusd: number;
  detailstotalcontributionloc: number;
  purchasecostusd: number;
  purchasecostloc: number;
  carryovertonextmonthusd: number;
  carryovertonextmonthloc: number;
  exchangerate: number;
  datePipe = new DatePipe('en-US');
  veipIsDisplayRefund: number;
  RemainingBroker_mtt: string = tScript.veipdetailspage_rb;
  Disposed_mtt: string = tScript.veipdetailspage_d;
  PurchasePrice_mtt: string = tScript.veipdetailspage_pp;
  PurchaseCost_mtt: string = tScript.veipdetailspage_pc;
  RefundedViaPayroll_mtt: string = tScript.veipdetailspage_rvp;
  ExchangeRate_mtt: string = tScript.veipdetailspage_er;
  CarryOverNextMonth_mtt: string = tScript.veipdetailspage_conm;
  dateErrorChecker: boolean = false;
  dateToChecker: any;
  dateFromChecker: any;
  dateTo: string = "' '";
  dateFrom: string = "' '";
  disableDatePicker: boolean = true;
  salesTableLoading: boolean;
  veipSalesHistShares: any = 0;
  veipSalesHistPriceUSD: any = 0;
  veipSalesHistProceedsUSD: any = 0;
  veipSalesHistPurchaseValueUSD: any = 0;
  noSalesHistory: boolean = false;
  brokerType: any;
  dataSourceVeipSalesHist: any;
  errorSalesHistTab: boolean = false;
  datePeriod: number = 4;
  localcurrencycd: string;
 OptionsLoading: boolean = true;
  isOtherCurrency: boolean = false;

  
  constructor(private veipProgramDetails: HoldingProgramDetailsVEIPService, private globals: Globals) { 
    // this.veipProgramDetails.veipState$.subscribe( (state)=> {
    //   if(state == true){ 
    //     this.getVEIPAwardDetails(this.user.OptNum, this.programDetails.OfferNum);
    //   }
    // });
  }

  ngOnInit() {
    // BACK BUTTON BUG - START
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } 
    // BACK BUTTON BUG - END

    this.getVEIPAwardDetails(this.user.OptNum, this.programDetails.OfferNum);
  }

  getVEIPAwardDetails(optNum, offerNum) {  
    try {
      if(sessionStorage.getItem("veipAwardDetailsStoredData") != null){
        try {
          let data = JSON.parse(sessionStorage.getItem("veipAwardDetailsStoredData"));
          if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0)) {
              
            let veipsummarydetails = data[0][0];  
            this.localcurrencycd = veipsummarydetails.LocalCurrencyCd;

            let veipsummarydetails_array1 = data[2][0];
            this.monthlycontributionloc = veipsummarydetails_array1.MonthlyContributionLoc;
            this.monthlycontributionusd = veipsummarydetails_array1.MonthlyContributionUSD;
            this.priormonthscarryoverusd = veipsummarydetails_array1.PriorMonthsCarryOverUSD;
            this.priormonthscarryoverloc = veipsummarydetails_array1.PriorMonthsCarryOverLoc;
            this.totalcontributionusd = veipsummarydetails_array1.TotalContributionUSD;
            this.detailstotalcontributionloc = veipsummarydetails_array1.TotalContributionLoc;
            this.purchasecostusd = veipsummarydetails_array1.PurchaseCostUSD;
            this.purchasecostloc = veipsummarydetails_array1.PurchaseCostLoc;
            this.carryovertonextmonthusd = veipsummarydetails_array1.CarryOverToNextMonthUSD;
            this.carryovertonextmonthloc = veipsummarydetails_array1.CarryOverToNextMonthLoc;
            this.veipIsDisplayRefund = data[2][0].IsDisplayRefund;
            this.exchangerate = veipsummarydetails_array1.ExchangeRate;
            
            // this.localcurrencycd = 'EUR';
            this.veipdataSource = data[1];
            if(data[1][0].LocalCurrencyCd != "USD"){
              this.isOtherCurrency = true;
            }else{
              this.isOtherCurrency = false;
            }
            sessionStorage.setItem("veipAwardDetailsStoredData", JSON.stringify(data));
            this.loading = false;
          }
        } catch(error) {
          this.errorDetailsTab = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardDetails");
          error.message += errorLocation;
          throw error;
        }
      }
      else{
        this.veipProgramDetails.getVEIPdetails(optNum, offerNum).pipe(map(res => res)).subscribe(data => {
          try {
            if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0)) {
                
              let veipsummarydetails = data[0][0];
              this.localcurrencycd = veipsummarydetails.LocalCurrencyCd;    
    
              let veipsummarydetails_array1 = data[2][0];
              this.monthlycontributionloc = veipsummarydetails_array1.MonthlyContributionLoc;
              this.monthlycontributionusd = veipsummarydetails_array1.MonthlyContributionUSD;
              this.priormonthscarryoverusd = veipsummarydetails_array1.PriorMonthsCarryOverUSD;
              this.priormonthscarryoverloc = veipsummarydetails_array1.PriorMonthsCarryOverLoc;
              this.totalcontributionusd = veipsummarydetails_array1.TotalContributionUSD;
              this.detailstotalcontributionloc = veipsummarydetails_array1.TotalContributionLoc;
              this.purchasecostusd = veipsummarydetails_array1.PurchaseCostUSD;
              this.purchasecostloc = veipsummarydetails_array1.PurchaseCostLoc;
              this.carryovertonextmonthusd = veipsummarydetails_array1.CarryOverToNextMonthUSD;
              this.carryovertonextmonthloc = veipsummarydetails_array1.CarryOverToNextMonthLoc;
              this.veipIsDisplayRefund = data[2][0].IsDisplayRefund;
              this.exchangerate = veipsummarydetails_array1.ExchangeRate;
              
              // this.localcurrencycd = 'EUR';
              this.veipdataSource = data[1];
              if(data[1][0].LocalCurrencyCd != "USD"){
                this.isOtherCurrency = true;
              }else{
                this.isOtherCurrency = false;
              }
              sessionStorage.setItem("veipAwardDetailsStoredData", JSON.stringify(data));
              this.loading = false;
            }
          } catch(error) {
            this.errorDetailsTab = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardDetails");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          this.errorDetailsTab = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardDetails");
          error.error.message += errorLocation;
          throw error.error;
        });
      }
      this.OptionsLoading = false;
    } catch(error) {
      this.errorDetailsTab = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }
  getCarryOverDetails(row){
    try {
      this.veipTableLoading = true;
      if(row.LocalCurrencyCd != "USD"){
        this.isOtherCurrency = true;
      }else{
        this.isOtherCurrency = false;
      }
      
      let purchasedate = this.datePipe.transform(row.Date, "MM'/'dd'/'yyyy");
      // purchasedate.Date;
      this.veipProgramDetails.getVeipCarryOverAPI(this.user.OptNum, this.programDetails.OfferNum, purchasedate).pipe(map(res => res)).subscribe(data => {
        try {
          this.monthlycontributionusd = data[0][0].MonthlyContributionUSD;
          this.monthlycontributionloc = data[0][0].MonthlyContributionLoc;
          this.priormonthscarryoverusd = data[0][0].PriorMonthsCarryOverUSD;
          this.priormonthscarryoverloc = data[0][0].PriorMonthsCarryOverLoc;
          this.totalcontributionusd = data[0][0].TotalContributionUSD;
          this.detailstotalcontributionloc = data[0][0].TotalContributionLoc;
          this.purchasecostusd = data[0][0].PurchaseCostUSD;
          this.purchasecostloc = data[0][0].PurchaseCostLoc;
          this.carryovertonextmonthusd = data[0][0].CarryOverToNextMonthUSD;
          this.carryovertonextmonthloc = data[0][0].CarryOverToNextMonthLoc;
          this.exchangerate = data[0][0].ExchangeRate;
          this.localcurrencycd = data[0][0].LocalCurrencyCd;
          this.veipIsDisplayRefund = data[0][0].IsDisplayRefund;
          this.veipTableLoading = false;
        } catch(error) {
          this.errorDetailsTab = true;
          this.veipTableLoading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getCarryOverDetails");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorDetailsTab = true;
        this.veipTableLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getCarryOverDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorDetailsTab = true;
      this.veipTableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getCarryOverDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  viewVEIPAwardSalesHist() {
    try {
      this.dateErrorChecker = false;
      this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
      this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
  
      let tempDateTo = this.dateTo.replace(/'/g, '');
      let tempDateFrom = this.dateFrom.replace(/'/g, '');
  
      tempDateTo = moment(tempDateTo).format("YYYY-MM-DD");
      tempDateFrom = moment(tempDateFrom).format("YYYY-MM-DD");
  
      if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
        this.dateErrorChecker = true;
        // this.dateToChecker = "";
        // this.dateFromChecker = "";
      }
      else if (tempDateTo < tempDateFrom && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else{
      this.salesTableLoading = true;
      this.veipSalesHistShares = 0;
      this.veipSalesHistPriceUSD = 0;
      this.veipSalesHistProceedsUSD = 0;
      this.veipSalesHistPurchaseValueUSD = 0;
      this.getVEIPAwardSalesHist(this.programDetails.OfferNum, this.dateFrom, this.dateTo);   
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewVEIPAwardSalesHist");
      error.message += errorLocation;
      throw error;
    }
  }
  getVEIPAwardSalesHist(offerNum, dateFrom: string, dateTo: string) {
    try {
      this.veipProgramDetails.getVeipSalesHistory(this.user.OptNum, offerNum, this.datePeriod, dateFrom, dateTo).pipe(map(res => res)).subscribe(data => {
        try {
          if(data[1].length == 0) {
            this.noSalesHistory = true;
            this.brokerType = BrokerType[data[0][0].BrokerCd];
            this.dataSourceVeipSalesHist = data[1];
            this.salesTableLoading = false;
          } else {
            
          this.dataSourceVeipSalesHist = data[1];
          this.brokerType = BrokerType[data[0][0].BrokerCd];
    
          for(let i = 0; i < data[1].length; i++) {
            this.veipSalesHistShares += (data[1][i].Shares*1);
            if(data[1][i].PriceUSD != "-") {
              this.veipSalesHistPriceUSD += (data[1][i].PriceUSD*1);
            }
            if(data[1][i].ProceedsUSD != "-") {
              this.veipSalesHistProceedsUSD += (data[1][i].ProceedsUSD*1);
            }
            this.veipSalesHistPurchaseValueUSD += (data[1][i].PurchaseValueUSD*1);
          }
          this.noSalesHistory = false;
          this.salesTableLoading = false;
        }
          this.loading = false;
        } catch(error) {
          this.errorSalesHistTab = true;
          this.salesTableLoading = false;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardSalesHist");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorSalesHistTab = true;
        this.salesTableLoading = false;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardSalesHist");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorSalesHistTab = true;
      this.salesTableLoading = false;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getVEIPAwardSalesHist");
      error.message += errorLocation;
      throw error;
    }
  }
}
