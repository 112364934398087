export enum BrokerType{
    MS = 1
    ,UBS = 2
    ,BIG = 3
    ,TA = 4
    ,BNY = 5
    ,JPM = 6
    ,ML = 7
    ,NATL = 8
    ,SCHW = 9
    ,GS = 10
    ,RBS = 11
    ,HSBC = 12
    ,CITI = 13
    ,NFS = 14
    ,FID = 15
    ,DAIW = 16
    ,PERS = 17
}

export enum GrantGroup{
    LTDA = 88
    ,SCA = 91
    ,ACHICan = 89
    ,ACHINz = 90
    ,VEIPMATCHRSU = 86
}

export enum RedemptionMethod{
    Cash = 1
    ,Share = 2
}

export enum GrantTransactionType{
    Sale = 1
    ,Transfer = 2
    ,Redemption = 3
}

export enum GrantAgreementStatus {
    NotYetAvailable = 0,
    ActionRequired = 1,
    Completed = 2,
    Cancelled = 3,
    NoActionRequired = 4
}

export enum EnrollStatus {
    EnrollVEIPRsu = 1,
    EnrollVEIPOptions = 2,
    EnrollESPP = 3,
    NotEnrolled = 4,
    ChangeESPPContribution = 5,
    Terminated = 6,
    OnHold = 7,
    Withdraw = 8
}

export enum ActionTypes{
    Enroll = 1,
    ChangeContrib = 2,
    Withdraw = 3
}

export enum AdminType {
    ReadOnly = 1,
    ReadWrite = 2
}

export enum MyHoldingsPagesResources{
    Resources = "Resources",
    Reports = "Reports",
    Contacts = "Contacts"
}


export enum CurrencySymbol{
USD = '&#36;'
,GBP = '&#163;'
,EUR = '&#8364;'
,JPY = '&#165;'
,INR = '&#8377;'
,AUD = '&#65;&#36;'
,BRL = '&#82;&#36;'
,CAD = '&#67;&#65;&#36;'
// ,XFA = 'XFA'
,CNY = '&#67;&#78;&#165;'
,ILS = '&#8362;'
,NZD = '&#78;&#90;&#36;'
,XAF = '&#70;&#67;&#70;&#65;'
,AOA = '&#75;&#122;'
,ARS = '&#36;'
,BHD = '&#66;&#68;'
,BOB = '&#36;&#98;'
,BND = '&#66;&#36;'
,CLP = '&#36;'
,COP = '&#36;'
,CRC = '&#8353;'
,HRK = '&#107;&#110;'
,CZK = '&#75;&#269;'
,DKK = '&#107;&#114;'
,NLG = '&#402;'
,ECS = '&#83;&#47;&#46;'
,EGP = '&#69;&#163;'
,EEK = '&#107;&#114;'
,FIM = '&#109;&#107;'
,GRD = '&#8367;'
,GTQ = '&#81;'
,HKD = '&#36;'
,HUF = '&#70;&#116;'
,IDR = '&#82;&#112;'
,ITL = '&#163;'
,JOD = 'JOD'
,KWD = '&#75;&#68;'
,LVL = '&#76;&#115;'
,LTL = '&#76;&#116;'
,MYR = '&#82;&#77;'
,MXN = '&#36;'
,MNT = '&#8366;'
,MAD = '&#68;&#72;'
,MUR = '&#8360;'
,MZN = '&#77;&#84;'
,MMK = '&#75;'
,ANG = '&#402;'
,NGN = '&#8358;'
,NOK = '&#107;&#114;'
,TRL = '&#8378;'
,VEB = '&#66;&#115;'
,OMR = '&#65020;'
,PAB = '&#66;&#47;&#46;'
,PEN = '&#83;&#47;'
,PHP = '&#8369;'
,PLN = '&#122;&#322;'
,PTE = '&#36;'
,QAR = '&#65020;'
,RON = '&#108;&#101;&#105;'
,RUB = '&#8381;'
,ZAR = '&#82;'
,SAR = '&#65020;'
,SGD = '&#36;'
,SKK = '&#83;&#107;'
,KRW =  'KRW'//'&#8361;'
,ESP = '&#80;&#116;&#97;'
,SEK = '&#107;&#114;'
,CHF = '&#67;&#72;&#70;'
,TWD = '&#78;&#84;&#36;'
,THB = '&#3647;'
,TRY = '&#8378;'
,AED = '&#x62f;&#x2e;&#x625;'
,VEF = '&#66;&#115;'
,VND = '&#8363;'
,ZMW = '&#75;'
}

 /*********************************************************************************************************
   * Method Name: PageSection                                                                 
   * Description: Enum for the page sections to be used for calling the myResources api.                
   *                                                                                          
   * #    Date                 Modified by                      Description                             
   * 1    04/22/19             Joshua Paul J. Avecilla          Added page section for VEIP learn more tab.
   * 2    04/23/19             Roldan Mazo                      Added Tax Time 
   * 3    09/09/19             Allan Christopher M. Salac       CRES00082219171733 - Enhancement to Resource page of myHoldings
   ********************************************************************************************************/
export enum PageSection{
    sb = "Contacts\\SB",
    ubs = "Contacts\\UBS",
    big = "Contacts\\BIG",
    ncb = "Contacts\\NCB",
    globeTax = "Contacts\\GlobeTax",

    sbDoc = "Contacts\\Document\\SB",
    ubsDoc = "Contacts\\Document\\UBS",
    ncbDoc = "Contacts\\Document\\NCB",

    personalDataChange = 'Contacts\\PersonalDataChange\\',
    equityProfessional = 'ContactEquityProfessionalList',
    assistanceformyH = 'Contacts\\ForAssistance',
    SrExecTrading = 'Resource\\ImportantDates\\SrExecTrading',
    importantDateVEIP = 'Resource\\ImportantDates\\Veip',
    importantDateESPP = 'Resource\\ImportantDates\\Espp',
    importantDateDividends = 'Resource\\ImportantDates\\Dividends',                 //  JBP: CRES00082219171733 - Enhancement to Resource page of myHoldings
    perfRewardDisclaimer = 'PerformanceReward\\LegalDisclaimers',

    eorCommonQuestions = 'LearnMore\\CommonQuestions\\EOR',
    eorToolsandReports = 'LearnMore\\ToolsAndReports\\Tools\\Eor',

    esppTransacCommonQuestions = 'LearnMore\\CommonQuestions\\ESPP',
    esppTransacGuideBooks = 'LearnMore\\Guidebooks\\ESPP\\Guidebooks',
    esppTransacPlanDocument = 'LearnMore\\Guidebooks\\ESPP\\PlanDocument',
    esppTransacProspectusDoc = 'LearnMore\\Guidebooks\\ESPP\\ProspectusDocument',
    esppTransacCountryAndAward = 'LearnMore\\Guidebooks\\ESPP\\CountryAndAward',
    esppTransacToolsAndReports = 'LearnMore\\ToolsAndReports\\Tools\\Espp',
    esppTransacAllCountryForms = 'EsppEnroll\\CountryAllForms',
    esppTransacStepTwo = 'EsppEnroll\\CountryForms\\',
    esppEnrollNote = 'ESPPEnroll\\ConfirmationNote\\CountryAllForms',
    esppNoteCompleteFax = 'ESPP\\ConfirmationNote\\CompleteFax\\CountryAllForms',
    esppWithdrawNote = 'ESPPWithdraw\\ConfirmationNote\\CountryAllForms',

    /* ******************VEIP TRANSACTION PAGE PAGESECTIONS - START *************************** */
    stepTwoVEIP = 'VeipEnroll\\CountryForms\\', //VeipCountryAllForms
    transferringToNewCountryVEIP = 'VeipEnroll\\CountryAllForms',
    
    learnMoreCommonQuestionsVEIP = 'LearnMore\\CommonQuestions\\VEIP',
    learnMoreGuidebooksVEIP = 'LearnMore\\Guidebooks\\VEIP\\Guidebooks',
    learnMoreProspectDocumentVEIP = 'LearnMore\\Guidebooks\\VEIP\\ProspectusDocument',   
    learnMoreCountryAndAwardVEIP = 'LearnMore\\Guidebooks\\VEIP\\CountryAndAward',
    learnMoreToolsAndReportsVEIP = 'LearnMore\\ToolsAndReports\\Tools\\Veip',
    /* ******************VEIP TRANSACTION PAGE PAGESECTIONS - END *************************** */

    /* ******************FOUNDERS PAGE PAGESECTIONS - START *************************** */
    foundersCommonQuestions = 'LearnMore\\CommonQuestions\\Founders',
    foundersGuidebooksTaxImp = 'LearnMore\\Guidebooks\\Founder\\Trading',
    foundersCountryAward = 'LearnMore\\Guidebooks\\Founder\\CountryAndAward',
    foundersToolsandReports = 'LearnMore\\ToolsAndReports\\Tools\\Founders',
    /* ******************FOUNDERS PAGE PAGESECTIONS - END *************************** */

    /* ******************OPTIONS PAGE PAGESECTIONS - START *************************** */
    optionsCommonQuestionshasAwards = 'LearnMore\\CommonQuestions\\MatchVeipCanadaOptions',
    optionsCommonQuestionsisSenior = 'LearnMore\\CommonQuestions\\SnEOptions',
    optionsCommonQuestions = 'LearnMore\\CommonQuestions\\EmployeeOptions',
    optionsGuidebooks = 'LearnMore\\Guidebooks\\Options\\Guidebooks',
    optionsShareIncentiveProspectus = 'LearnMore\\Guidebooks\\Options\\ShareIncentive',
    optionsCountryAwardsTaxImp = 'LearnMore\\Guidebooks\\Options\\CountryAndAward',
    optionsToolsReports = 'LearnMore\\ToolsAndReports\\Tools\\ShareOptions',
    /* ******************OPTIONS PAGE PAGESECTIONS - START *************************** */

     /* ******************VEIP PAGE PAGESECTIONS - START *************************** */
    veipAwardCommonQuestion = 'LearnMore\\CommonQuestions\\VEIP',
    veipAwardGuidebooks = 'LearnMore\\Guidebooks\\VEIP\\Guidebooks',
    veipAwardProspectusDocu = 'LearnMore\\Guidebooks\\VEIP\\ProspectusDocument',
    veipAwardCountry = 'LearnMore\\Guidebooks\\VEIP\\CountryAndAward',
    veipAwardToolsAndReports = 'LearnMore\\ToolsAndReports\\Tools\\Veip',
    veipEnrollNote = 'VEIPEnroll\\ConfirmationNote\\CountryAllForms',
    veipWithdrawNote = 'VEIPWithdraw\\ConfirmationNote\\CountryAllForms',
     /* ******************VEIP PAGE PAGESECTIONS - END *************************** */

     /* ******************VEIP PAGE PAGESECTIONS - START *************************** */
     esppAwardCommonQuestion = 'LearnMore\\CommonQuestions\\ESPP',
     esppAwardGuidebooks = 'LearnMore\\Guidebooks\\ESPP\\Guidebooks',
     esppAwardPlanDocu = 'LearnMore\\Guidebooks\\ESPP\\PlanDocument',
     esppAwardProspectusDocu = 'LearnMore\\Guidebooks\\ESPP\\ProspectusDocument',
     esppAwardCountry = 'LearnMore\\Guidebooks\\ESPP\\CountryAndAward',
     esppAwardToolsAndReports = 'LearnMore\\ToolsAndReports\\Tools\\Espp',
      /* ******************VEIP PAGE PAGESECTIONS - END *************************** */

       /* ******************RSU PAGE PAGESECTIONS - START *************************** */
    //  rsuCommonQuestionGrant = 'LearnMore\\CommonQuestions\\DenmarkBonusRSU',
    //  rsuCommonQuestionDenmark = 'LearnMore\CommonQuestions\DenmarkRSU',
     rsuGuidebooks = 'LearnMore\\Guidebooks\\RSU\\Guidebooks',
     rsuShareIncentive = 'LearnMore\\Guidebooks\\RSU\\ShareIncentive',
     rsuCountryAward = 'LearnMore\\Guidebooks\\RSU\\CountryAndAward',
     rsuToolsAndReports = 'LearnMore\\ToolsAndReports\\Tools\\RSU',
      /* ******************RSU PAGE PAGESECTIONS - END *************************** */

    // /* ******************RSU IPO PAGE PAGESECTIONS - START *************************** */
    // rsuipoGuidebooks = 'LearnMore\\Guidebooks\\RSU\\Guidebooks',
    // rsuipoShareIncentive = 'LearnMore\\Guidebooks\\RSU\\ShareIncentive',
    // rsuipoCountryAward = 'LearnMore\\Guidebooks\\RSU\\CountryAndAward',
    // rsuipoToolsAndReports = 'LearnMore\\ToolsAndReports\\Tools\\RSU',
    //   /* ******************RSU IPO PAGE PAGESECTIONS - END *************************** */

      
    /* ******************RSU/RSUIPO PAGE PAGESECTIONS - START *************************** */
    // If GrantCountryCode = DNK
    DenmarkBonusRSU = 'LearnMore\\CommonQuestions\\DenmarkBonusRSU',
    IPORSUDenmark = 'LearnMore\\CommonQuestions\\IPORSUDenmark',
    DenmarkRSU = 'LearnMore\\CommonQuestions\\DenmarkRSU',
    BonusShareRSU = 'LearnMore\\CommonQuestions\\BonusShareRSU',
    // If FrenchQualified
    GrantFRQSnERSU= 'LearnMore\\CommonQuestions\\GrantFRQSnERSU',
    GrantFRQSMRRSU = 'LearnMore\\CommonQuestions\\GrantFRQSMRRSU',

    // If LocalGroupDetail = 8 
    MatchDenmarkRSUVeip = 'LearnMore\\CommonQuestions\\MatchDenmarkRSUVeip',
    MatchDenmarkVeip = 'LearnMore\\CommonQuestions\\MatchDenmarkVeip',
    MatchFRQVeip = 'LearnMore\\CommonQuestions\\MatchFRQVeip',
    MatchRSUVeip = 'LearnMore\\CommonQuestions\\MatchRSUVeip',
    RSUNullContent = 'LearnMore\\CommonQuestions\\RSU',
      /* ******************RSU IPO PAGE PAGESECTIONS - END *************************** */

    topQuestions = 'TopQuestions',

    // Program Guidebooks
    programGuideShareIncentives = "Resource\\ProgramGuidebooks\\ShareIncentive",
    programGuideRestrictedShare = "Resource\\ProgramGuidebooks\\RestrictedShare",
    programGuideShareOption = "Resource\\ProgramGuidebooks\\ShareOptions",
    programGuidePPVEIP = "Resource\\ProgramGuidebooks\\PurchaseProg\\Veip",
    programGuidePPESPP = "Resource\\ProgramGuidebooks\\PurchaseProg\\Espp",
    programGuideExpatriate = "Resource\\ProgramGuidebooks\\ExpatriateInfo",
    programGuideArchiveVEIP = "Resource\\ProgramGuidebooks\\Archive\\PurchaseProg\\Veip",
    programGuideArchiveESPP = "Resource\\ProgramGuidebooks\\Archive\\PurchaseProg\\Espp",
    // end of Program Guidebooks

    
    // Training & FAQS

    // Equity Training
    trainingFAQEquityTraining = "Resource\\TrainingAndFaqs\\EquityTraining",
    // FAQS
    trainingFAQAccentureDividends = "Resource\\TrainingAndFaqs\\Faqs\\Dividends",
    trainingFAQSneTrading = "Resource\\TrainingAndFaqs\\Faqs\\SneTrading",
    trainingFAQVeip = "Resource\\TrainingAndFaqs\\Faqs\\Veip",
    trainingFAQEspp = "Resource\\TrainingAndFaqs\\Faqs\\Espp",
    trainingFAQFCT = "Resource\\TrainingAndFaqs\\Faqs\\FCT",
    trainingFAQOptionsAndRSU = "Resource\\TrainingAndFaqs\\Faqs\\OptionAndRSU",
    trainingFAQDepartureConsiderations = "Resource\\TrainingAndFaqs\\Faqs\\DepartureConsiderations",
    trainingFAQList = 'Resource\\TrainingAndFaqs\\Faqs\\List',

    trainingArchiveVeip = "Resource\\TrainingAndFaqs\\Archive\\Veip",
    trainingArchiveEspp = "Resource\\TrainingAndFaqs\\Archive\\Espp",
    trainingArchiveDividends = "Resource\\TrainingAndFaqs\\Archive\\Dividends",

    // End of Training & FAQs 
    

    // equity communications
    equityCommunicationsSrExecTrading = "Resource\\EquityCommunications\\SrExecTradingMemos",
    equityCommunicationsVeip = "Resource\\EquityCommunications\\Veip",
    equityCommunicationsEspp = "Resource\\EquityCommunications\\Espp",
    equityCommunicationsAccentureDividendMemos = "Resource\\EquityCommunications\\AccentureDividendMemo",
    equityCommunicationsLastYear = "Resource\\EquityCommunications\\Archive\\LastYear",
    equityCommunicationsPreviousYear = "Resource\\EquityCommunications\\Archive\\PreviousYear",


    //Tax Time
    // taxTimeCurrYrTaxPrep = 'Resource\\TaxTime\\TaxPrepMaterials', //0
    // taxTimeCurrYrSalesHist = 'Resource\\TaxTime\\TransactionRep', //1
    // taxTimeArchTaxPrep = 'Resource\\TaxTime\\Archive\\TaxPrepMaterials', //0

    //Tools & Reports
    toolsEORModel = 'Resource\\ToolsAndReports\\ModelingTools\\EOR', //1
    toolsReports = 'Resource\\ToolsAndReports\\Reports', //1

    policiesEquity = 'Resource\\PoliciesAndOtherLinks\\EquityRelatedPolicies', //1
    policiesOther = 'Resource\\PoliciesAndOtherLinks\\OtherResources', //1
    
    //Glossary
    glossary = "Resource\\Glossary\\",

    //Technology support page
    ContactEquityProfessionalList = 'ContactEquityProfessionalList',

    // PEAP Tooltip
    companyPerfTooltip = 'ToolTip\\PerformanceReward\\CompanyPerformanceFactor',
    annualBonusTooltip = 'ToolTip\\PerformanceReward\\GlobalAnnualBonus',

    eorBasedCompensation = 'ToolTip\\EOR\\BaseCompensation',
 	localCurrency = 'ToolTip\\EOR\\LocalCurrencyToolTipData',
    fairMarketValue = 'ToolTip\\EOR\\FMV',
    
    // Dividend
    dividendDoc = 'Resource\\Dividend',

    // Country Specific Tax Info
    countryCurrYrTaxPrep = 'Resource\\YearEndTaxGuide\\TaxPrepMaterials',
    countryCurrYrSalesHist = 'Resource\\YearEndTaxGuide\\TransactionRep',
    countryArchTaxPrep = 'Resource\\YearEndTaxGuide\\Archive\\TaxPrepMaterials',

    // KeyExecFAQ
    keyExecFAQ = 'Homepage\\KeyExecToggle'
}

export enum EmployeeType{
    seniorExec = 'Senior Executive',
    nonSeniorExec = 'Employees'
}

export enum FileType{
    pdf = "pdf",
    PDF = "pdf",
    doc = "docx",
    docx = "docx",
    DOC = "docx",
    DOCX = "docx",
    xls = "xls",
    xlsx = "xls",
    XLS = "xls",
    XLSX = "xls",
    ppt = "ppt",
    pptx = "ppt",
    PPT = "ppt",
    PPTX = "ppt",
    zip = "zip",
    ZIP = "zip",
    png = 'png',
    PNG = 'png',
}

export enum myHoldingsPages{
    "homepage" = 1,
    "profilepage" = 2,
    "highlightspage" = 3,
    "brokerspage" = 4,
    "userinfoheader" = 5,
    "technologysupport" = 6,
    "holdingsummarypage" = 7,
    "rsudetailspage" = 8,
    "iporsudetailspage" = 9,
    "esppdetailspage" = 10,
    "veipdetailspage" = 11,
    "adminpage" = 12,
    "founderdetailspage" = 13,
    "optionsdetailspage" = 14,
    "performanceawardpage" = 15,
    "keyexec" = 16,
    "espptransactionpage" = 17,
    "veiptransactionpage" = 18,
    "eorpage" = 19,
    "enrollmenthistorypage" = 20,
    "reportspage" = 21,
    "multiplegrantspage" = 22,
    "resourcespage" = 23,
    "equityadmin" = 24,
}

export enum HoldingsId{
    "Founder" = 1,
    "RSU" = 2,
    "Options" = 3,
    "IpoRSU" = 4,
    "ESPP" = 5,
    "VEIP" = 6
}
export enum tScript{
    "ketMaximum" = "Number of RSUs expected to vest at 1.5x target company performance.",
    "ketTarget" = "Number of RSUs expected to vest at target company performance.",
    "ketTrend" = "Number of RSUs forecast to vest based on most recent company performance measures.",
    "dashboard_eor" =       "Current Share Holdings - EOR = Above/Below EOR.",
    "dashboard_ocsp" =      "Overrides the current Market Quote price that is displayed and is the price used to estimate the Value that is displayed on this page only. Changes are not saved upon logout. Override share price is for value modeling purposes only and is not an indicator of the actual share price.",
    "holdingsummary_p_so" = "Includes both released and unreleased RSUs.",
    "holdingsummary_p_v" =  "Value based on Current Price (or specified override).  Shares include any applicable dividends on unreleased Restricted Share Units, but are net of any sales, transfers and/or shares withheld for taxes.  For Share Options, the Value is based on the Current Price (or specified override) less the Share Option's Grant Price.",
    "holdingsummary_p_ats" = "The number of remaining salable shares you have for a given award/ purchase at the company broker.  It excludes any unreleased Restricted Share Units or unexercisable Share Options, as applicable, that you may hold.",
    "holdingsummary_p_uu" =  "This is the number of remaining unvested and/or unreleased Restricted Share Units, or unvested/ unexercisable Share Options.  The total shares in this column plus the shares shown Available To Sell equals your total Shares Outstanding.",
    // "holdingsummary_ap_ats" = "The number of remaining salable shares you have for a given award/ purchase at the company broker.  It excludes any unreleased Restricted Share Units or unexercisable Share Options, as applicable, that you may hold.",
    // "holdingsummary_ap_v" =  "Value based on Current Price (or specified override).  Shares include any applicable dividends on unreleased Restricted Share Units, but are net of any sales, transfers and/or shares withheld for taxes.  For Share Options, the Value is based on the Current Price (or specified override) less the Share Option's Grant Price.",
    // "holdingsummary_ap_b" =  "Click the broker link below to sell or transfer your remaining available shares.  You can sell available shares through the broker website or via telephone. MS stands for Morgan Stanley; UBS stands for UBS Financial Services Inc.; TA stands for Transfer Agent - Computershare (formerly National City Bank); BIG stands for Banco de Investimento Global (Portugal).",
    // "holdingsummary_ap_nr" = "Reflects the date your next tranche of Restricted Share Units is set to release.  May be subject to continued employment.  Refer to your grant agreement for further details.",
    // "holdingsummary_ap_sr" = "Reflects the number of Restricted Share Units scheduled to release on the specified date, prior to any share withholding for taxes.  May be subject to continued employment.  Refer to your grant agreement for details.",
    // "holdingsummary_ap_uu" = "This is the number of remaining unvested and/or unreleased Restricted Share Units, or unvested/ unexercisable Share Options.  The total shares in this column plus the shares shown Available To Sell equals your total Shares Outstanding as shown in the Holding Summary above.",
    // "holdingsummary_ap_rv" = "This is the gross number of released Restricted Share Units or vested Share Options to date, prior to any sales, transfers and/or shares withheld for taxes.  Vesting is subject to continued employment.  Refer to your grant agreement for details.",
    // "holdingsummary_ap_e" =  "Subject to earlier expiration as detailed in your grant agreement.",
    // "holdingsummary_ap_f" =  "Shares may be forfeited due to termination of employment or expiration. Also, shares may forfeit from certain RSU awards if performance targets are not met (applies to KeyEx Perf RSU awards only). Refer to your Grant Agreement and Departure FAQs for details.",
    "localCurrency" = "This will be used as the default currency for all currency selections throughout the site for current and future sessions on the site.",
    "customExchangeRate" = "All currency rates reflect latest available end of day closing market price. If you modify the Custom Exchange Rate, it will override the current exchange rate on this page only. A Modified Custom Exchange Rate is for indicative purposes only and is subject to change. Changes are not saved upon logout.",
    "optionsdetailspage_exp" = "Subject to earlier expiration as detailed in your grant agreement.",
    "optionsdetailspage_gp" = "The price you pay, per share, to exercise your option (also known as the option’s strike price or exercise price).",
    "optionsdetailspage_uv" = "Total number of Share Options still subject to forfeiture.  Refer to your grant agreement for details.",
    "optionsdetailspage_ve" = "Total gross number of Share Options that have become vested and exercisable to date.",
    "optionsdetailspage_exc" = "The number of Share Options that have been exercised to date into salable Accenture plc shares.",
    "optionsdetailspage_nrb" = "Net remaining Share Options available for exercise at the company broker.  Excludes any Accenture plc shares you may still hold from prior option exercises - refer to the company broker for details.",
    "optionsdetailspage_v" = "See the Vesting section of your grant agreement for details regarding vesting during unpaid leave of absence.",
    "optionshistory_np" = "Proceeds net of taxes, but prior to deduction of any transaction fees.  Refer to the transaction history on the company broker's website to obtain the net proceeds applicable for tax reporting purposes in most countries.",
    "veipdetailspage_c" = "Percentage of eligible gross cash compensation to be contributed towards purchasing shares.",
    "veipdetailspage_tc" = "Contributions to the date that were used to purchase shares in this program year.",
    "veipdetailspage_pp" = "Purchase Price per share is based on the Fair Market Value per share in USD on the purchase date.",
    "veipdetailspage_d" = "Disposed includes both sales and transfer of shares.",
    "veipdetailspage_rb" = "Remaining number of salable shares at the company broker, for the given purchase date.",
    "veipdetailspage_pc" = "Payroll contributions for the month prior to purchase plus any remaining excess contributions from prior months.",
    "veipdetailspage_rvp" = "Contributions remaining from last month's purchase in the program year is refunded in cash via payroll.",
    "veipdetailspage_er" = "Exchange rate of local currency on the purchase date used for conversion to USD.",
    "veipdetailspage_conm" = "Excess contributions insufficient to purchase a whole share are carried over and added to the next month's payroll deductions.  Contributions remaining from the last month's purchase in the program year is refunded in cash via payroll.",
    "esppdetailspage_op" = "Generally, the last date of Offering Period is Purchase Date.",
    "esppdetailspage_pp" = "Fair Market Value on the Purchase Date, discounted by 15%.",
    "esppdetailspage_sp" = "Gross shares purchased during the selected offering period, prior to any shares withheld for taxes, sales or transfers.",
    "esppdetailspage_wft" = "Shares withheld for taxes due to purchase discount.",
    "esppdetailspage_fs" = "Remaining partial share left over from share withholding for taxes and/or the purchase. The Fair Market Value of the partial share is refunded to you through local payroll.",
    "esppdetailspage_tc" = "The maximum total contributions for a single offering period is USD 7,500.  Any contributions above that amount will be refunded via local payroll.",
    "esppdetailspage_sw" = "Shares withheld for taxes to satisfy taxable income due from purchase discount.",
    "esppdetailspage_fsr" = "Remaining partial share left over from share withholding for taxes and/or the purchase.  The Fair Market Value of the partial share is refunded to you through your local payroll.",
    "esppdetailspage_nsp" = "Shares purchased and deposited at the company broker after any shares withheld for taxes. The Value of shares deposited to the broker is the Fair Market Value of the shares purchased as of the purchase date. The Fair Market Value is the cost basis for tax purposes in most countries. Some countries may require a special value calculation.",
    "esppdetailspage_xfap" = "This is the per share cost basis in most countries.  Some countries (Germany and Italy) require a special value calculation.  Fair Market Value per share is determined by the mean of the high and low share price on the New York Stock Exchange on the purchase date.",
    "esppdetailspage_td" = "Total value of the purchase discount, equal to the Fair Market Value per share less the purchase price multiplied by the gross shares purchased on the purchase date.  This is considered taxable income in most countries.",
    "optionsdetailspage_eh_t" = "For a cashless exercise, the total value is based on the sale price of the shares. For a cash exercise, the total value is based on the Fair Market Value per share on the exercise date.",
    "optionsdetailspage_eh_p" = "Per share exercise price (in US dollars).  The value of shares not sold at the time of exercise is determined by the Fair Market Value per share on the day of exercise. The value of shares sold at the time of exercise is determined by the actual sales price of the shares.",
    "optionsdetailspage_eh_tv" = "Total value of exercised Option Shares based on the per share exercise price, prior to deduction of any taxes or transaction fees.",
    "optionsdetailspage_eh_c" = "The cost to exercise your Share Options, based on the Share Option's Grant Price.",
    "optionsdetailspage_cancelled" = "The number of Share Options that have been forfeited.  Refer to your grant agreement for details.",
    "esppveipenrollmentwithdraw_pcp" = "As a reference, the prior offering period’s contribution percentage is provided.",
    "esppveipsaleshistorytab_type" = "UBS Financial Services Inc.; MS stands for Morgan Stanley.",
    "esppveipsaleshistorytab_price" = "Price per share in USD on the sale date.",
    "esppveipsaleshistorytab_proceeds" = "Gross proceeds, prior to deduction of any transaction fees.  Refer to the transaction history on the company broker's website to obtain the net proceeds applicable for tax reporting purposes in most countries.",
    "esppveipsaleshistorytab_pdv" = "Cost basis of shares sold, in most countries.  Based on the Fair Market Value of the shares as of the purchase date.",
    "rsudetailspage_f" = "The number of Restricted Share Units that have been forfeited.",
    "rsudetailspage_nbr" = "Net remaining released shares available for sale at the company broker.",
    "rsudetailspage_v" = "If your Restricted Share Unit award is subject to attaining certain performance objectives, please refer to the specific terms and conditions in your grant agreement.",
    "rsudetailspage_s" = "The vesting of a Restricted Share Unit is considered a taxable event in certain countries. For tax information specific to your country, click on the 'Learn More' tab and select Tax Implications.",
    "rsudetailspage_osv" = "The number of Restricted Share Units scheduled to vest on the selected vest date.  Vesting may be subject to continued employment.  Refer to your grant agreement for more details.",
    "rsudetailspage_ald" = "The number represents the additional shares added to the original Restricted Share Unit (RSU) Grant. When Accenture pays a dividend, most outstanding RSU awards (not including certain awards made in France) automatically increase according to the formula in your grant agreement. Release may be subject to continued employment.  Refer to your grant agreement for more details.",
    "rsudetailspage_tsv" = "This includes the Restricted Share Units (RSUs) originally scheduled to vest along with any Additional RSUs granted in lieu of dividends.  Vesting is subject to continued employment.  Refer to your grant agreement for details.",
    "rsudetailspage_vf" = "Fair Market Value of the Restricted Share Units on vest date.",
    "rsudetailspage_value" = "This is the total Fair Market Value of all shares on Vest date.",
    "rsudetailspage_release" = "The Release Date displayed reflects any deferral election that you may have made.",
    "rsudetailspage_status" = "If any RSUs have been forfeited, the displayed share release schedule does not apply. Forfeited RSUs will not be released. MS stands for Morgan Stanley; UBS stands for UBS Financial Services Inc.; TA stands for Transfer Agent - Computershare (formerly National City Bank); BIG stands for Banco de Investimento Global (Portugal).",
    "rsudetailspage_osr" = "The number of Restricted Share Units scheduled to release on the selected release date.  Release may be subject to continued employment.  Refer to your grant agreement for more details.",
    "rsudetailspage_ard" = "The number represents the additional shares added to the original Restricted Share Unit (RSU) Grant. When Accenture pays a dividend, most outstanding RSU awards (not including certain awards made in France) automatically increase according to the formula in your grant agreement. Release may be subject to continued employment.  Refer to your grant agreement for more details.",
    "rsudetailspage_rf" = "The value reflected here is the value which is considered taxable income on the Release Date in most countries (amount shown is in US dollars). This value in most locations is based on the Fair Market Value per share. Some countries (i.e., Germany and Italy) require a special value calculation.",
    "rsudetailspage_twm" = "In most countries the default tax withholding method is share withholding, if your country allows share withholding and has chosen to do so. Please refer to your earnings statement for more details.",
    "rsudetailspage_gr" = "The total number of Restricted Share Units (RSUs), including RSUs in lieu of dividends, that released on the scheduled date, prior to any share whithholding for taxes, sales or transfers.  The value reflected here is the value which is considered taxable income on the Release Date in most countries. This value in most locations is based on the Fair Market Value per share on the release date. Some countries (e.g., Germany and Italy) require a special value calculation.",
    "rsudetailspage_wft" = "The total Shares Withheld for Tax represents shares withheld for taxes (which may change once your local payroll has processed the taxable income associated with this release).  This amount may include various social and local withholding taxes due on this release, but may not include additional taxes which were withheld by your local payroll. Review your earnings statement or contact your local payroll representative for more information.  Value is based on the Fair Market Value per share on the release date.",
    "rsudetailspage_wr" = "Once your taxable income associated with the release is determined, the release date Fair Market Value of any remaining fractional (partial) shares not used to satisfy your taxable income will be returned to you through your local payroll.  This amount may change once your local payroll has processed the taxable income associated with the release.",
    "rsudetailspage_arg" = "Lists additional Restricted Share Units (RSUs) provided in lieu of dividends on unreleased RSUs.  Excludes cash dividends paid on Accenture plc shares held at the company brokers.  Refer to the company broker/agents for cash dividends paid on: Founder Shares (Transfer Agent - Computershare (formerly National City Bank)), Released RSUs & VEIP Purchased shares (UBS Financial Services, Inc.), ESPP shares (Morgan Stanley).",
    "rsusaleshistorytab_type" = "UBS Financial Services Inc.; MS stands for Morgan Stanley.",
    "rsusaleshistorytab_share" = "Number of Shares in the table corresponds to the Number of Shares Sold/Transferred [(amount is calculated in US dollars)].",
    "rsusaleshistorytab_proceeds" = "Gross proceeds, prior to deduction of any transaction fees.  Refer to the transaction history on the company broker's website to obtain the net proceeds applicable for tax reporting purposes in most countries, as well as the cost basis for the shares sold.",


    //RSU IPO Tooltips
    "rsuipo_grantDesc" = "IPO Restricted Share Units (IPO RSUs) granted to employees shown below, if applicable, are actually a combination of two or more separate RSU grants. Click on Grants below to see each IPO RSU grant listed.",
    "rsuipo_netRemainingBroker" = "Net remaining released shares available for sale at the company broker.",
    "rsuipo_vest_column" = "If your Restricted Share Unit award is subject to attaining certain performance objectives, please refer to the specific terms and conditions in your grant agreement.",
    "rsuipo_vstatus_column" = "The vesting of Restricted Share Unit is considered a taxable event in certain countries. For tax information specific to your country, click on the 'Learn More' tab and select Tax Implications.",
    "rsuipo_origSchedVest" = "The number of Restricted Share Units scheduled to vest on the selected vest date. Vesting may be subject to continued employment. Refer to your grant agreement for more details.",
    "rsuipo_addRsuLieuDividends" = "The number represents the additional shares added to the original Restricted Share Unit (RSU) Grant. When Accenture pays a dividend, most outstanding RSU awards (not including certain awards made in France) automatically increase according to the formula in your grant agreement. Release may be subject to continued employment. Refer to your grant agreement for more details.",
    "rsuipo_totalSchedVest" = "This includes the Restricted Share Units (RSU) originally scheduled to vest along with any Additional RSUs granted in lieu of dividends. Vesting is subject to continued employment. Refer to your grant agreement for details.",
    "rsuipo_vestfmv" = "Fair Market Value of the Restricted Share Units on vest date.",
    "rsuipo_vestvalue" = "This is the total Fair Market Value of all shares on Vest date",
    "rsuipo_release_column" = "The Release Date displayed reflects any deferral election that you may have made.",
    "rsuipo_rstatus_column" = "If any RSUs have been forfeited, the displayed share release schedule does not apply. Forfeited RSUs will not be released. MS stands for Morgan Stanley; UBS stands for UBS Financial Services Inc.; TA stands for Transfer Agent - Computershare (formerly National City Bank); BIG stands for Banco de Investimento Global (Portugal).",
    
    "rsuipo_origSchedRelease" = "The number of Restricted Share Units scheduled to release on the selected release date. Release may be subject to continued employment. Refer to your grant agreement for more details.",
    "rsuipo_addRsuLieuDividendsRelease" = "The number represents the additional shares added to the original Restricted Share Unit (RSU) Grant. When Accenture pays a dividend, most outstanding RSU awards (not including certain awards made in France) automatically increase according to the formula in your grant agreement. Release may be subject to continued employment. Refer to your grant agreement for more details.",
    "rsuipo_releasefmv" = "The value reflected here is the value which is considered taxable income on the Release Date in most countries ( amount shown in US dollars). This value in most locations is based on the Fair Market Value per share. Some countries (i.e., Germany and Italy) require a special value calculation.",
    "rsuipo_taxWithholdingMethod" = "In most countries the default tax withholding method is share withholding, if your country allows share withholding and has chosen to do so. Please refer to your earnings statement for more details.",
    "rsuipo_grossReleased" = "The total number of Restricted Share Units (RSUs), including RSUs in lieu of dividends, that released on the scheduled date, prior to any share withholding for taxes, sales or transfer. The value reflected here is the value which is considered taxable income on the Release Date in most countries. This value in most locations is based on the Fair Market Value per share on the release date. Some countries (e.g., Germany and Italy) require a special value calculation.",
    "rsuipo_withheldForTax" = "The total Shares Withheld for Tax represents shares withheld for taxes (which may change once your local payroll has processed the taxable income associated with this release). This amount may include various social and local withholding taxes due on this release. Review your earnings statement or contact local payroll representative for more information. Value is based on the Fair Market Value per share on the release date.",
    "rsuipo_withholdingRefund" = "Once your taxable income associated with the release is determined, the release date Fair Market Value of any remaining fractional (partial) shares used to satisfy your taxable income will be returned to you through your local payroll. This amount may change once your local payroll has processed the taxable income associated with the release.",
    "rsuipo_forfieted" = "The number of Restricted Share Units that have been forfieted.",
    "rsuipo_salesHistType" = "UBS Financial Services Inc.; MS stands for Morgan Stanley.",
    "rsuipo_salesHistShare" = "Number of Shares in the table corresponds to the Number of Shares Sold/Transferred [(amount is calculated in US dollars)].",
    "rsuipo_salesHistProceeds" = "Gross proceeds, prior to deduction of any transaction fees. Refer to the transaction history on the company broker's website to obtain the net proceeds applicable for tax reporting purposes in most countries, as well as the cost basis for the shares sold.",
    // "rsuipo_" = "",
    // "rsuipo_" = "",

    //Founders Award Details Tooltip
    "founders_SharesIssued"="This number is the original founder share award amount, before any donations you have made, if applicable. See the Sales/Transfers History Tab for additional details.",
    "founders_PricingMethod"="The current pricing method in effect for any share sales/ redemptions.  Applicable to AHp redeemers only: at the sole discretion of management, the pricing method may change during the quarter from the average of the high & low to market price.",
    "founders_NetIssued"="This number is the original founder share award amount, before any donations you have made, if applicable. See Sales History for additional details.",
    "founders_EligibleForSaleOrTransfer"="Net remaining shares available for sale/ redemption or transfer during the current quarter's trading window.",
    "founders_Date"="Represents the Date of the Sale or Transfer.",
    "founders_Type"="Sale - Mkt stands for a Market Sell; Redemption - Mkt stands for a Market Redemption; Transfer - Trust stands for a share transfer to a trust account.",
    "founders_Price"="Price per share in USD on the sale/redemption date.",
    "founders_Proceeds"="Gross proceeds, prior to deduction of any transaction fees.  Refer to the transaction history on the company broker's website to obtain the net proceeds applicable for tax reporting purposes in most countries.  For sales transactions prior to September 2005, refer to the Senior Executive Transactions website.  Note that the cost basis of Founder shares is effectively zero.",

    //EOR Tooltop
    "equityOwnershipRequirement" = "Refer to Equity Ownership Policy 1052 for the types of equity that will count towards the EOR and for an explanation of the formula used to calculate the EOR.",
    "gracePeriodExpirationDate" = "You have until the date shown to meet your ownership requirement.  Refer to Equity Ownership Policy 1052 for implications of not meeting your equity ownership requirement" 
}
