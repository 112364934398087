import { Component, OnInit } from '@angular/core';
import { ActionComboItem } from '../../../../../services/ea/models/dataapp-model';
import { Select, Store } from '@ngxs/store';
import { DataAppCountryState, DataAppItemState } from '../../../../../services/ea/states/dataapproval.state';
import { StaticTextState } from '../../../../../services/ea/states/weeklysummary.state';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { Globals } from '../../../../../globals';
import { MatDialog } from '@angular/material/dialog';
import { RoleaccessService } from '../../../../../services/ea/api/RoleaccessService';
import { LoadingOverlayComponent } from '../../../../../services/ea/shared/loading-overlay/loading-overlay.component';
import { Dataapproval } from '../../../../../services/ea/actions/dataapproval.actions';
import * as moment from 'moment';
import { ModalComponent } from '../../../../../services/ea/shared/modal/modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { EjetaccessService } from '../../../../../services/ea/EjetaccessService';

@Component({
  selector: 'app-dataapproval',
  templateUrl: './dataapproval.component.html',
  styleUrls: ['./dataapproval.component.css']
})
export class DataapprovalComponent implements OnInit {

  dataAppHeader: string;
  modalChangeCountryBody: string;
  countries: any[] = [];
  codes: any[];
  lastCountryVal: string = "";
  countryVal: string = "";
  compCodeVal: string = "";
  reportTitle: string;
  sourceId: number;
  disableCompCodeButton: boolean = true;
  disableCountryButton: boolean = true;
  displayAppTable: boolean = false;
  tableHasData: boolean = false;
  tableLoading: boolean;
  equityTypeId: number;
  comboItem: ActionComboItem;
  staticTextData: any[];
  confiCodes = {
    sourceId: null,
    countryId: null,
    companyCode: null,
    roleId: this.roleAccessService.getUserRoleAccess(),
    reportTitle: null,
    postingDate: null
  };
  isError: boolean = false;
  ejetService: any;

  @Select(DataAppCountryState.getCountries) countryDetails: any;
  @Select(DataAppItemState.getGroupIds) groupId: any;
  @Select(StaticTextState.getStaticData) staticData: any;

  loadTableSubs: any;

  constructor(
    private apiService: ApiService,
    private globals: Globals,
    public modal: MatDialog,
    private store: Store,
    private roleAccessService: RoleaccessService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private ejetAccessService: EjetaccessService
  ) { 
    this.ejetService = this.ejetAccessService.dataAppError$.subscribe(state => {
      this.isError = state;
    });
  }

  ngOnInit() {
    const { 'sourceId': sourceId }: any = sessionStorage;
    this.sourceId = sourceId;
    this.confiCodes.sourceId = sourceId;
    this.getCountryList();
    this.getStaticContent();
    this.getInvoiceName();
  }

  ngOnDestroy(){
    this.ejetService.unsubscribe();
    this.store.dispatch(new Dataapproval.ClearCountries);
  }


  /**********************************************************************************
  * Method Name: getCountryList                                                                 
  * Description: Gets the list of country for country dropdown.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getCountryList(){
    try {
      window.scroll(0,0);
      this.modal.open(LoadingOverlayComponent, {disableClose: true, panelClass: 'overlay-modal'})
      let body ={
        functionName: "getJEApprovalCountry"
      }
      this.apiService.postData(this.globals.manageWeeklySummaryApi, body).subscribe( data => {
        try {
          if (data) {
            let body = data.body;
            if (body) {
              for (let i = 0; i < body.length; i++) {
                this.store.dispatch( new Dataapproval.AddCountry(body[i]))         
              }
              this.countryDetails.subscribe(data => {
                this.countries = data.countries;
                this.countryVal = this.countries[0].Country;
              })
            }
          }
          this.disableCountryButton = false;
          this.modal.closeAll();
        } catch(error) {
          this.modal.closeAll();
          this.isError = true;
          error.message += this.apiService.setErrorContents('getCountryList', 'Dataapproval');
          throw error;
        }
      }, error => {
        this.modal.closeAll();
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('getCountryList', 'Dataapproval');
        throw error;
      });
    } catch (error) {
      this.modal.closeAll();
      this.isError = true;
      error.message += this.apiService.setErrorContents('getCountryList', 'Dataapproval');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getStaticContent                                                                 
  * Description: Gets and sets the header and intro text.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getStaticContent(){
    try {
      this.staticData.subscribe(data => {
          if (data.staticData.data) {
            this.staticTextData = data.staticData.data;
            this.dataAppHeader = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.header")].MESSAGE_VALUE;
            this.modalChangeCountryBody = this.staticTextData[this.getDataIndex("ea.ejet.dataapp.modalchangecountry.body")].MESSAGE_VALUE;
          }
      })
    } catch (error) {
      this.modal.closeAll();
      this.ejetAccessService.setEjetError(true);
      error.message += this.apiService.setErrorContents('getStaticContent', 'Dataapproval');
      throw error
    }
  }

  /**********************************************************************************
  * Method Name: getDataIndex                                                                 
  * Description: Returns the index of the given key.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getDataIndex(key){
    try {
      let index;
      for (let i = 0; i < this.staticTextData.length; i++) {
        if (this.staticTextData[i].MESSAGE_NAME == key) {
          index = i
        }
      }
      return index;
    } catch (error) {
      this.modal.closeAll();
      this.ejetAccessService.setEjetError(true);
      error.message += this.apiService.setErrorContents('getDataIndex', 'Dataapproval');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getInvoiceName                                                                 
  * Description: Gets the invoice name and equity type id.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  * 2         03/31/20             John Paul B. Fernando          Added logic for passing report title and company codes.
  ***********************************************************************************/
  getInvoiceName(){
    let body = {
      functionName: "getJEApprovalInvoiceName",
      sourceId: this.sourceId
    }
    this.apiService.postData(this.globals.manageWeeklySummaryApi, body).subscribe( data => {
      try {
        if (data) {
          let body = data.body;
          if (body) {
            this.reportTitle = body[0].SOURCE_NAME.replace('.xlsx', '').replace('.xls', '');
            this.equityTypeId = body[0].EQUITY_TYPE_ID;
            this.confiCodes.reportTitle = this.reportTitle;
            this.confiCodes.postingDate = moment.utc(new Date(body[0].POSTING_DATE)).format("MM/DD/YYYY");
          }
        }
      } catch(error) {
        this.modal.closeAll();
        this.isError = true;
        error.message += this.apiService.setErrorContents('getInvoiceName', 'Dataapproval');
        throw  error;
      }
    }, error => {
      this.modal.closeAll();
      this.isError = true;
      error.error.message += this.apiService.setErrorContents('getInvoiceName', 'Dataapproval');
      throw  error;
    });
  }


  /**********************************************************************************
  * Method Name: loadDataAppTable                                                                 
  * Description: Displays the table for the selected country and company code.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  loadDataAppTable(){
    if (this.countryVal != "" && this.compCodeVal) {
      this.confiCodes.sourceId = this.sourceId;
      this.confiCodes.companyCode = this.compCodeVal;
      this.tableLoading = true;
      let body = {
        functionName: "getJournalEntryData",
        sourceId: this.sourceId,
        companyCode: this.compCodeVal
      }
      let groupIds: number[] = [];
      this.loadTableSubs = this.apiService.postData(this.globals.manageWeeklySummaryApi, body).subscribe( data => {
        try {
          if (data) {
            let body = data.body;
            if (body) {
              if (body.length > 0) {
                for (let i = 0; i < body.length; i++) {
                  this.store.dispatch(new Dataapproval.AddDataAppItem(body[i]));
                }
                this.groupId.subscribe(jGroupId => {
                  groupIds = jGroupId;
                })
                this.getActionComboItems(groupIds);
                this.displayAppTable = true;     
                this.tableHasData = true;           
              }else{
                this.displayAppTable = true;
                this.tableHasData = false;
              }
            }
          }
          this.tableLoading = false;
        } catch(error) {
          this.modal.closeAll();
          this.tableLoading = false;
          this.isError = true;
          error.message += this.apiService.setErrorContents('loadDataAppTable', 'Dataapproval');
          throw error;
        }
      }, error => {
        this.modal.closeAll();
        this.tableLoading = false;
        this.isError = true;
        error.error.message += this.apiService.setErrorContents('loadDataAppTable', 'Dataapproval');
        throw error;
      });
    }
  }

  /**********************************************************************************
  * Method Name: getActionComboItems                                                                 
  * Description: Gets the combo items for each of the journal group id in the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getActionComboItems(groupIds){
    try {
      for (let i = 0; i < groupIds.length; i++) {
        let reqBody = {
          functionName: "getActionComboItems",
          journalTypeId: groupIds[i],
          equityId: this.equityTypeId
        }
        this.apiService.postData(this.globals.manageWeeklySummaryApi, reqBody).subscribe(data =>{
          try {
            this.comboItem = new ActionComboItem();
            this.comboItem.JournalGroupId = groupIds[i];
            this.comboItem.ComboItems = [];
            if (data) {
              let body = data.body;
              if (body) {
                this.comboItem.ComboItems = body
              }
            }
            this.store.dispatch(new Dataapproval.AddComboItem(this.comboItem))
          } catch(error) {
            this.modal.closeAll();
            this.isError = true;
            error.message += this.apiService.setErrorContents('getActionComboItems', 'Dataapproval');
            throw error;
          }
        }, error => {
          this.modal.closeAll();
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('getActionComboItems', 'Dataapproval');
          throw error;
        });
      }
    } catch (error) {
      this.modal.closeAll();
      this.isError = true;
      error.message += this.apiService.setErrorContents('getActionComboItems', 'Dataapproval');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: openConfirmModal                                                                 
  * Description: Opens a modal when the user tries to change the value of the country when the table is already displayed.                     
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  openConfirmModal(value){
    try {
      this.countryVal = value;
      if (this.displayAppTable || this.tableLoading) {
        let content: any = {
          header: this.dataAppHeader,
          body: this.modalChangeCountryBody,
          hasBtn1: true,
          hasBtn2: true,
          btn1Name: "OK",
          btn2Name: "CANCEL"
        }
  
        let confirmModal = this.modal.open(ModalComponent, {
          panelClass: 'custom-modal',
          disableClose: true,
          data: {
            content
          }
        });
  
        confirmModal.afterClosed().subscribe( result => {
          if (result) {
            this.displayAppTable = false;
            this.lastCountryVal = value;
            this.codes = [];
            this.disableCompCodeButton = true;
            this.loadTableSubs.unsubscribe();
            this.tableLoading = false;
            this.getApprovalCode(value);            
          }else{
            this.countryVal = this.lastCountryVal;
          }
        })
      }else{
        this.lastCountryVal = value;
        this.getApprovalCode(value);
      }      
    } catch (error) {
      this.modal.closeAll();
      this.isError = true;
      error.message += this.apiService.setErrorContents('openConfirmModal', 'Dataapproval');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getApprovalCode                                                                 
  * Description: Gets the list of company codes for the given country id.                      
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getApprovalCode(value){
    try {
      if (value != "") {
        this.disableCompCodeButton = true;
        this.compCodeVal = "";
        this.modal.open(LoadingOverlayComponent, {disableClose: true, panelClass: 'overlay-modal'})
        let body = {
          functionName: "getJEApprovalCompanyCode",
          countryId: this.getCountryId(value)
        }
        this.apiService.postData(this.globals.manageWeeklySummaryApi, body).subscribe(data => {
          try {
            if (data) {
              let body = data.body;
              if (body) {
                this.codes = body;
                if (this.codes.length > 0) {
                  this.compCodeVal = this.codes[0].COMPANY_CODE;
                  this.disableCompCodeButton = false;
                }
              }
            }
            this.modal.closeAll();
          } catch(error) {
            this.modal.closeAll();
            this.isError = true;
            error.message += this.apiService.setErrorContents('getApprovalCode', 'Dataapproval');
            throw error;
          }
        }, error => {
          this.modal.closeAll();
          this.isError = true;
          error.error.message += this.apiService.setErrorContents('getApprovalCode', 'Dataapproval');
          throw error;
        });
      }else{
        this.codes = [];
        this.compCodeVal = "";
        this.disableCompCodeButton = true;
      }      
    } catch (error) {
      this.modal.closeAll();
      this.isError = true;
      error.message += this.apiService.setErrorContents('getApprovalCode', 'Dataapproval');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: getCountryId                                                                 
  * Description: Gets the country id of the selected country.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  getCountryId(country){
    try {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].Country == country) {
          this.confiCodes.countryId = this.countries[i].Country_ID;
          return this.countries[i].Country_ID;
        }        
      }
    } catch (error) {
      this.modal.closeAll();
      this.isError = true;
      error.message += this.apiService.setErrorContents('getCountryId', 'Dataapproval');
      throw error;
    }
  }


  /**********************************************************************************
  * Method Name: setCompCodeValue                                                                 
  * Description: Assigns the selected company code value.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/26/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
  setCompCodeValue(value){
    this.compCodeVal = value;
  }

  /**********************************************************************************
  * Method Name: backToReports                                                                 
  * Description: Navigate back to reports page.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B Fernando           Initial draft  
  ***********************************************************************************/ 
  backToReports(){
    this.router.navigate(['../reports'], {relativeTo: this.activeRoute})
  }

}
