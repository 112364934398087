import {ErrorHandler, Injectable, Injector} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorNotificationService } from './ErrorNotificationService';
import { ApiService } from './ea/api/ApiService';

@Injectable()
export class GlobalExceptionHandlerService implements ErrorHandler {
    constructor(
        private injector: Injector,
        private apiService: ApiService
    ) {}

    /*****************************************************************************************
     * Method Name: handleError                                                                 
     * Description: Handles the errors encountered by the application                              
     *                                                                                          
     * Date                 Modified by                 Description                             
     * 01/31/19             Dalemark P. Suquib          Additional condition for server error
     *****************************************************************************************/
    handleError(error : Error | HttpErrorResponse | any) {
        const notificationService = this.injector.get(ErrorNotificationService);
        //global error handler, its a hook for centralized exception handling.
        if (error instanceof HttpErrorResponse) { // server error
            // console.error(error);
            if(!navigator.onLine) { // offline error
                return notificationService.notifyError("No Internet Connection");
            } else { // http error
                this.apiService.postErrorContents(error.message);
                return notificationService.notifyError(error.status + " - " +  error.message);
            }
        } else{ 
            // client error
            // console.error(error);
            this.apiService.postErrorContents(error.message);
            return notificationService.notifyError(error.message);
        }
    }
}
