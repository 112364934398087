import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReportItem } from '../../../../../../services/ea/models/reportitem-model';
import { Globals } from '../../../../../../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ApiService } from '../../../../../../services/ea/api/ApiService';
import { RoleaccessService } from '../../../../../../services/ea/api/RoleaccessService';
import { ModalComponent } from '../../../../../../services/ea/shared/modal/modal.component';
import { LoadingOverlayComponent } from '../../../../../../services/ea/shared/loading-overlay/loading-overlay.component';
import { Weeklysummary } from '../../../../../../services/ea/actions/weeklysummary.actions';
import { EjetaccessService } from '../../../../../../services/ea/EjetaccessService';

@Component({
  selector: 'app-sumreportstable',
  templateUrl: './sumreportstable.component.html',
  styleUrls: ['./sumreportstable.component.css']
})
export class SumreportstableComponent implements OnInit {
  
  dataSource: MatTableDataSource<ReportItem>
  displayedColumns: any;
  pageSizeOption: number[];
  pageSizeLabel: string = "Show"
  pageIndex: number;
  totalNumOfPage: number;
  pageEvent: PageEvent;
  tableHasData: boolean;
  roleID: number = this.roleAccessService.getUserRoleAccess();
  canUpdate: boolean;
  entriesStart: number;
  entriesEnd: number;
  entriesLength: number;
  origEntriesLength: number;

  @ViewChild(MatPaginator, { static: true }) paginator:  MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  sortState: Sort = {active: 'SOURCE_NAME', direction: 'asc'};

  @Input('tableData') tableData;
  @Input('tableCols') tableCols;
  filterValue = {
    SOURCE_NAME: '',
    DESCRIPTION: '',
    POSTING_DATE: '',
    TRANSLATION_DATE: ''
  }
  unauthModal: MatDialogRef<ModalComponent, any>;
  confirmModal: MatDialogRef<ModalComponent, any>;
  unSuccessfulModal: MatDialogRef<ModalComponent, any>;
  successfulModal: MatDialogRef<ModalComponent, any>;

  constructor(
    private globals: Globals,
    public modal: MatDialog,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private store: Store,
    private apiService: ApiService,
    private roleAccessService: RoleaccessService,
    private ejetAccessService: EjetaccessService
  ) { 
    this.displayedColumns = this.globals.weeklySummaryDisplayedColumns;
    this.pageSizeOption = this.globals.reportsPageSizeOptions;
  }

  ngOnInit() {
    this.canUpdate = this.roleID == 5 || this.roleID == 6;
  }

  ngOnChanges(){
    setTimeout(() => {
      try {
        this.dataSource = new MatTableDataSource(this.tableData);
        this.origEntriesLength = this.tableData.length;
        this.dataSource.filterPredicate = (data) => (data.SOURCE_NAME.trim().toLowerCase().indexOf(this.filterValue.SOURCE_NAME.trim().toLocaleLowerCase()) !== -1 ||
                                                      data.DESCRIPTION.trim().toLowerCase().indexOf(this.filterValue.DESCRIPTION.trim().toLocaleLowerCase()) !== -1 ||
                                                      data.POSTING_DATE.trim().toLowerCase().indexOf(this.filterValue.POSTING_DATE.trim().toLocaleLowerCase()) !== -1 ||
                                                      data.TRANSLATION_DATE.trim().toLowerCase().indexOf(this.filterValue.TRANSLATION_DATE.trim().toLocaleLowerCase()) !== -1);
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'SOURCE_NAME': return item.SOURCE_NAME.toLowerCase();
            case 'DESCRIPTION': return item.DESCRIPTION.toLowerCase();
            case 'POSTING_DATE': return (new Date(item.POSTING_DATE)).getTime()/1000;
            case 'TRANSLATION_DATE': return (new Date(item.TRANSLATION_DATE)).getTime()/1000;
            default: return item[property];
          }
        };
        if (this.filterValue.SOURCE_NAME != "") {
          this.dataSource.filter = JSON.stringify(this.filterValue)
          this.tableHasData = this.dataSource.filteredData.length > 0
        }else{
          this.tableHasData = this.dataSource.data.length > 0;
        }
        this.sort.active = this.sortState.active;
        this.sort.direction = this.sortState.direction;
        this.sort.sortChange.emit(this.sortState);
        this.dataSource.paginator = this.paginator;
        this.paginator.firstPage();
        this.paginator.pageSize = this.pageSizeOption[0];
        this.dataSource.sort = this.sort;
        this.paginator._intl.itemsPerPageLabel = this.pageSizeLabel;
        this.pageIndex = this.paginator.pageIndex + 1;
      } catch(error) {
        this.ejetAccessService.setSumReportError(true);
        error.message += this.apiService.setErrorContents('ngOnChanges', 'Sumreportstable');
        throw error;
      }
    }, 1);
  }

  ngAfterContentChecked(){
    this.getNumberOfPage();
  }
  
  /**********************************************************************************
  * Method Name: getNumberOfPage                                                                 
  * Description: Sets the number of pages and the index of the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/26/20             John Paul B Fernando           Initial draft  
  * 2         03/31/20             John Paul B Fernando           Added logic to display number of entries.
  ***********************************************************************************/
  getNumberOfPage(){
    try {
      let dataLength;
      if (this.dataSource) {
        dataLength = this.filterValue.SOURCE_NAME != "" ? this.dataSource.filteredData.length : this.dataSource.data.length;
        dataLength > 0 ? this.entriesStart = 1 + (this.paginator.pageIndex * this.paginator.pageSize) : this.entriesStart = 0;
        this.entriesEnd = dataLength < this.paginator.pageSize ? dataLength : this.paginator.pageSize + (this.paginator.pageSize * this.paginator.pageIndex);
        this.entriesEnd =  this.entriesEnd > dataLength ? dataLength : this.entriesEnd;
        this.entriesLength = dataLength;        
      }
      this.pageIndex = this.paginator.pageIndex + 1;
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;      
    } catch (error) {
      this.ejetAccessService.setSumReportError(true);
      error.message += this.apiService.setErrorContents('getNumberOfPage', 'Sumreportstable');
      throw error;
    }      
  }
  
  /**********************************************************************************
  * Method Name: applyFilter                                                                 
  * Description: Apply filter using the enterd word in search box.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/26/20             John Paul B Fernando           Initial draft  
  * 2         04/01/20             John Paul B Fernando           Removed excess code.
  * 3         04/01/20             John Paul B Fernando           Added trim and lowercase for search value.
  ***********************************************************************************/
  applyFilter(filterVal: string) {
    try {      
      let filterValue = filterVal;
      this.filterValue.SOURCE_NAME = filterValue;
      this.filterValue.DESCRIPTION = filterValue;
      this.filterValue.POSTING_DATE = filterValue;
      this.filterValue.TRANSLATION_DATE = filterValue;
      this.dataSource.filter = JSON.stringify(this.filterValue);
      this.tableHasData = this.dataSource.filteredData.length > 0;
      this.paginator.firstPage();
    } catch (error) {
      this.ejetAccessService.setSumReportError(true);
      error.message += this.apiService.setErrorContents('applyFilter', 'Sumreportstable');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openUnAuthModal                                                                 
  * Description: Opens unauthorized modal                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/23/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
 openUnAuthModal(): void{

  try {
    let content: any = {
      header: "Weekly Summary",
      body: "You are not authorized to perform this action.",
      hasBtn1: true,
      hasBtn2: false,
      btn1Name: "OK",
      btn2Name: ""
    }
    
    this.unauthModal = this.modal.open(ModalComponent, {
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        content
      }
    });
  
    this.unauthModal.afterClosed().subscribe( result => {
      if (result) {
        this.modal.closeAll();
      }
    })    
  } catch (error) {
    this.ejetAccessService.setSumReportError(true);
    error.message += this.apiService.setErrorContents('openUnAuthModal', 'Sumreportstable');
    throw error;
  }

 }

  /**********************************************************************************
  * Method Name: openConfirmModal                                                                 
  * Description: Opens a modal when user clicks commit or rollback button                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/23/20             Dalemark P. Suquib             Initial draft  
  * 2         03/20/20             John Paul B Fernando           Added logic
  * 3         1/30/2023            Julienne Marey M. Celi         Updated based on User Story 741876 - EJET Enhancements
  ***********************************************************************************/
 openConfirmModal(btnName: string, reportID: number, fileName: string){
   try {

    let body = {
      functionName: "updCommitRollback",
      updateFlag: btnName == 'COMMIT' ? 13 : 14,
      sourceId: reportID
    }
/*
    let content: any = {
      header: "Weekly Summary",
      body: "Click 'OK' to confirm the " + btnName + " of all the journal entries created for " + fileName + " invoice file or click 'Cancel' to go back to the previous screen.",
      hasBtn1: true,
      hasBtn2: true,
      btn1Name: "OK",
      btn2Name: "CANCEL"
    }
*/
    let content: any = {
      header: "Weekly Summary",
      body: "You are about to " + btnName + " '" + fileName + "', do you agree to proceed? This action cannot be reversed.",
      hasBtn1: true,
      hasBtn2: true,
      btn1Name: "YES",
      btn2Name: "NO"
    }

    this.confirmModal = this.modal.open(ModalComponent, {
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        content
      }
    });

    this.confirmModal.afterClosed().subscribe(result => {
      if (result) {
        this.modal.open(LoadingOverlayComponent, {disableClose: true, panelClass: 'overlay-modal'});   
        this.apiService.postData(this.globals.manageWeeklySummaryApi, body).subscribe( data => {
          this.modal.closeAll();
          if (data.statusCode == 200) {
            let respBody = data.body;
            if (respBody != this.globals.notAuthorized && respBody[0].RetVal == 1) {
              this.openSuccessModal(btnName,fileName,reportID);
            } else {
              this.openUnSuccessModal(btnName,fileName);
            }
          } else {
            this.openUnSuccessModal(btnName,fileName);
          }
        });     
      }
    });
     
   } catch (error) {
    this.ejetAccessService.setSumReportError(true);
    error.message += this.apiService.setErrorContents('openConfirmModal', 'Sumreportstable');
     throw error;
   }
 }


  /**********************************************************************************
  * Method Name: openUnSuccessModal                                                                 
  * Description: Opens unsuccessful modal.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
 openUnSuccessModal(action: string, fileName: string){
   try {

    let content: any = {
      header: "Weekly Summary",
      body: action + " of all journal entries from " + fileName + " was not successful. Click 'OK' button to reload page.",
      hasBtn1: true,
      hasBtn2: false,
      btn1Name: "OK",
      btn2Name: ""
    }
    this.unSuccessfulModal = this.modal.open(ModalComponent, {
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        content
      }
    });
    this.unSuccessfulModal.afterClosed().subscribe( result => {
      if (result) {
        this.reloadWindow();
      }
    });
     
   } catch (error) {
    this.ejetAccessService.setSumReportError(true);
    error.message += this.apiService.setErrorContents('openUnSuccessModal', 'Sumreportstable');
     throw error
   }
 }

 
  /**********************************************************************************
  * Method Name: reloadWindow                                                                 
  * Description: Wrapped window.location.reload to a function for unit testing purposes.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         08/11/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
 reloadWindow(){
   window.location.reload();
 }


  /**********************************************************************************
  * Method Name: openSuccessModal                                                                 
  * Description: Open successful modal confirmation.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  ***********************************************************************************/
 openSuccessModal(action : string, fileName: string, reportID: number){
   try {

    let content: any = {
      header: "Weekly Summary",
      body: action + " of all journal entries from " + fileName + " was successful.",
      hasBtn1: true,
      hasBtn2: false,
      btn1Name: "OK",
      btn2Name: ""
    }
    this.successfulModal = this.modal.open(ModalComponent, {
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        content
      }
    });
    
    this.successfulModal.afterClosed().subscribe( result => {
      if (result) {
        this.store.dispatch( new Weeklysummary.Remove( {id: reportID} ));
        this.dataSource._updatePaginator(this.paginator.pageSize);                  
      }
    }) 
     
   } catch (error) {
    this.ejetAccessService.setSumReportError(true);
    error.message += this.apiService.setErrorContents('openSuccessModal', 'Sumreportstable');
     throw error;
   }
 }

 /**********************************************************************************
 * Method Name: navToDataApp                                                                 
 * Description: Navigate to data approval page.                       
 *                                                                                          
 * #         Date                 Modified by                    Description                             
 * 1         02/19/20             John Paul B Fernando           Initial draft  
 ***********************************************************************************/ 
 navToDataApp(sourceId){
   sessionStorage.setItem("sourceId", sourceId);
   this.router.navigate(['../dataapproval'], {relativeTo: this.activeRoute})
 }

}
