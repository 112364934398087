import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[overrideDirective]'
})
export class OverrideCurrentSharePriceDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d{0,9}(\.\d{0,9})?$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
  private digitsAndDot = /[\d\.]/gi;

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1 || event.key.match(this.digitsAndDot) != null) {
      return;
    }else{
      event.preventDefault();
    }
    // let current: string = this.el.nativeElement.value;
    // let next: string = current.concat(event.key);
    // if (next && !String(next).match(this.regex)) {
    //   event.preventDefault();
    // }
  }
}