import { InvoiceDescItem, InvoiceDescList, InvoiceProfitCenterList } from "../models/invoicedesc-model";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import { InvoiceDescription } from "../actions/invoicedescription.actions";
import * as moment from 'moment';


//State of Invoice Desc Items
export class InvoiceDescItemStateModel{
    items: InvoiceDescItem[]
}

@State<InvoiceDescItemStateModel>({
    name: "invoicedescitems",
    defaults: {
        items: []
    }
})

export class InvoiceDescItemState {
    @Selector()
    static getInvoiceDescItems(state: InvoiceDescItemStateModel){
        return state.items
    }

    @Action(InvoiceDescription.Add)
    add(
        store : StateContext<InvoiceDescItemStateModel>,
        action: InvoiceDescription.Add
    ){
        const state = store.getState();
        action.payload.ID = state.items.length + 1;
        action.payload.EFFECTIVE_POSTING_DATE = moment.utc(new Date(action.payload.EFFECTIVE_POSTING_DATE)).format("MM/DD/YYYY");
        store.patchState({
            items: [...state.items, action.payload]
        })
    }

    @Action(InvoiceDescription.Clear)
    clear(
        store: StateContext<InvoiceDescItemStateModel>
    ){
        store.setState({
            items: []
        })
    }
}

//end of Invoice item state

//State of Invoice Desc List
export class InvoiceDescListStateModel{
    list: InvoiceDescList[]
}

@State<InvoiceDescListStateModel>({
    name: "invoicedesclist",
    defaults: {
        list: []
    }
})

export class InvoiceDescListState {
    @Selector()
    static getInvoiceDescList(state: InvoiceDescListStateModel){
        return state.list
    }

    @Action(InvoiceDescription.Add_Invoice_Desc_List)
    add(
        store : StateContext<InvoiceDescListStateModel>,
        action: InvoiceDescription.Add_Invoice_Desc_List
    ){
        const state = store.getState();
        store.patchState({
            list: [...state.list, action.payload]
        })
    }

    @Action(InvoiceDescription.Clear_Invoice_Desc_List)
    clear(
        store: StateContext<InvoiceDescListStateModel>
    ){
        store.setState({
            list: []
        })
    }
}
//end of Invoice Desc List state

//State of Profit Center List
export class InvoiceProfitCenterStateModel{
    profitList: InvoiceProfitCenterList[]
}

@State<InvoiceProfitCenterStateModel>({
    name: "profitcenterlist",
    defaults: {
        profitList: []
    }
})

export class InvoiceProfitCenterState{
    @Selector()
    static getProfitCenterList(state: InvoiceProfitCenterStateModel){
        return state.profitList
    }

    @Action(InvoiceDescription.Add_Profit_Center)
    add(
        store : StateContext<InvoiceProfitCenterStateModel>,
        action: InvoiceDescription.Add_Profit_Center
    ){
        const state = store.getState();
        store.patchState({
            profitList: [...state.profitList, action.payload]
        })
    }

    @Action(InvoiceDescription.Clear_Profit_Center)
    clear(
        store: StateContext<InvoiceProfitCenterStateModel>
    ){
        store.setState({
            profitList: []
        })
    }
}
//end of Profit Center List state