
import {refCount, publish} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class EaLoadingService {

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public _appError: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly loading$: Observable<boolean> = this._loading.asObservable().pipe(publish(),refCount(),);
  readonly appError$: Observable<boolean> = this._appError.asObservable().pipe(publish(),refCount(),);

  constructor() { }

  setLoadingState(state: boolean): void {
    this._loading.next(state);
  }

  setAppError(state: boolean): void {
    this._appError.next(state);
  }

}
