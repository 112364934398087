import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, RouterStateSnapshot } from '@angular/router';
import { Globals } from '../globals';
import { StaticContentService } from '../services/StaticContentService';
import { Title } from '@angular/platform-browser';
import 'hammerjs';
import { UserService } from '../services/UserService';
// import { AuthService } from '../services/auth/auth.service';
// import { Http } from '@angular/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { ApiService } from '../services/ea/api/ApiService';
import { EaUserAccessService } from '../services/ea/EaUserAccessService';


@Component({
  selector: 'app-navheader',
  templateUrl: './navheader.component.html',
  styleUrls: ['./navheader.component.css']
})
export class NavheaderComponent implements OnInit{

  isCollapsed: boolean;

  userprofileInfo: any;
  state: boolean = false;
  loginState: boolean = true; //assuming that user always login before going to other pages.

  showNotif: boolean = false;
  setDashboardActive: string;
  public appInAppVisible: boolean;
  
  user: any;
  firstName: any;
  middleInitial: any;
  lastName: any;
  personnelId: any;
  emplName: string;
  globalId: string;
  closeView: boolean= true;
  imagePath: any;
  bellGrantList: any;
  notifCount: any;
  showBellNotif: boolean = false;
  bellGrantMessage: any;
  showNoDataBellNotif: boolean = false;
  sessionCheckerForRefresh : boolean = false;
  errorLocation: string = " + [Component: Navheader, Method: methodName]";
  mobileView: boolean = false;
  hasShares: boolean = true; //MAM
  empStatus: string; //MAM

  showManage: boolean; //JPF EA - show manage tab condition
  showMyhTabs: boolean = true; //JPF EA - for contractors view
 

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private globals: Globals, 
    private staticService: StaticContentService, 
    private titleService: Title,  
    private userService: UserService,   
    // private authService: AuthService, 
    private staticcontentService: StaticContentService, 
    // private http: Http, 
    private _sanitizer: DomSanitizer, 
    private errorNotificationService: ErrorNotificationService,
    private eaUserAccess: EaUserAccessService,
    private apiService: ApiService
    ) {
    
    try {
      if(sessionStorage.getItem("access_token") != undefined ||  sessionStorage.getItem("access_token") != null){
        this.getEaAccess(); //calls an api to check if the user has access to manage tab
        this.getUserStatus(); //calls an api to check if the user is a contractor or not
      }

      //MAM
      if (sessionStorage.getItem("No Share Indicator")=="True" && sessionStorage.getItem("Admin Indicator")!="True"){
        this.hasShares = false;
      }
      this.staticService.navState$.subscribe( (state)=> this.state = state );
      this.staticService.navLoginState$.subscribe( (loginState)=> { this.loginState = loginState; });
      this.staticService.navHeaderState$.subscribe( (state)=> {
        if(state == true){ 
          
          this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
          
          this.getImageFromUserService(); 
          // this.getBellGrantData()
          this.getSessionUserDetails();
          this.changeViewport();
          
          
          this.userprofileInfo = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
          let sessionAdminInfo = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

          //START:MAM
          if (this.userprofileInfo.EmployeeStatus == "Active" || this.userprofileInfo.EmployeeStatus == "Leave with pay" || this.userprofileInfo.EmployeeStatus == "Leave of Absence" || this.userprofileInfo.EmployeeStatus == "Leave without credit") {
            this.empStatus = "Active"; 
          } else {
            this.empStatus="Inactive";
          }
          if ((this.userprofileInfo.HasGrantInd == 'N') && (this.userprofileInfo.HasPurchaseInd == 'N') && (this.empStatus == "Inactive")&&(sessionStorage.getItem("Admin Indicator")!="True")){
            this.hasShares = false;
          }
          //END:MAM

        if(sessionAdminInfo != null || sessionAdminInfo != undefined){
       
            if(sessionAdminInfo.EnterpriseId == this.userprofileInfo.EnterpriseId){     
              this.state = true;     
            }
              else{
                this.state = true;
              }
            }
          }
        });
  
      this.titleService.setTitle('myHoldings'); 
      const self = this;
      this.appInAppVisible = false;
      this.isCollapsed = true
  
      if(JSON.parse(sessionStorage.getItem("adminPageDataStore"))){
        this.state = true;
      }
      
      router.events.forEach((event) => {
        if (event instanceof NavigationStart) {
          if (event['url'] == "/") {          
          } else if(event['url'] == "/homepage"){
            this.showNotif = true;
            this.setDashboardActive = "active";       
          }
          else{
            this.setDashboardActive = "";
            this.showNotif = true;
          }
        }
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navheader constructor");
      error.message += errorLocation;
      throw error;
    }
  }
  
  ngOnInit() {
    this.changeViewport();
    this.checkErrorMessage();
    this.autoHide();
    // setTimeout(()=>
    // this.setNavbarSticky()
    // ,500);

    try {
      if(JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey)) != null && JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey)) != "" && JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey)) != undefined) {
        this.sessionCheckerForRefresh = true;
         
          this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
          this.getImageFromUserService(); 
          // this.getBellGrantData()
          this.getSessionUserDetails();
          
          this.userprofileInfo = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
          let sessionAdminInfo = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));
          //START:MAM
          if (this.userprofileInfo.EmployeeStatus == "Active" || this.userprofileInfo.EmployeeStatus == "Leave with pay" || this.userprofileInfo.EmployeeStatus == "Leave of Absence" || this.userprofileInfo.EmployeeStatus == "Leave without credit") {
            this.empStatus = "Active"; 
          } else {
            this.empStatus="Inactive";
          }
          if ((this.userprofileInfo.HasGrantInd == 'N') && (this.userprofileInfo.HasPurchaseInd == 'N') && (this.empStatus == "Inactive") &&(sessionStorage.getItem("Admin Indicator")!="True")){
            this.hasShares = false;
          }
          //END:MAM
        if(sessionAdminInfo != null || sessionAdminInfo != undefined){
       
          if(sessionAdminInfo.EnterpriseId == this.userprofileInfo.EnterpriseId){     
            this.state = true;     
          }
          else{
            this.state = true;
          }
        }
      } 
    } catch(error) {
      // sessionStorage.clear();
      // this.navToPage("landingpage");
      let errorLocation = this.errorLocation.replace("methodName", "navheader ngOnInit");
      error.message += errorLocation;
      throw error;
    }
  }

 changeViewport(){
   this.mobileView= true;
 }

 hideHeader() {
  $("#sideNavHeader").toggle();
}

showHeader(){
  $("#sideNavHeader").css('display','block');
}

autoHide(){
  $('body').on('click', function(e) {
    if($(e.target).closest('#sideNavHeader').length == 0 && $(e.target).closest('#navheaderDesktop').length == 0) {
      $("#sideNavHeader").css('display','none');
    }
});
}

 

  closeNav(){
    try {
      if (window.matchMedia('min-width: 992px')){
        this.closeView = false;
      }
    } catch(error) {
      // sessionStorage.clear();
      // this.navToPage("landingpage");
      let errorLocation = this.errorLocation.replace("methodName", "closeNav");
      error.message += errorLocation;
      throw error;
    }
  }

  

  getImageFromUserService() {
    try {
      // alert('will attempt to get your face');
      let tdata = null;
      let apiURL = this.globals.currentAPIUrl;
      let temp = this.globals.getProfPicAPI;
      
      if(this.user != null || this.user != undefined) {
        if(sessionStorage.getItem("profilePic") != null){
          try {
            let body = sessionStorage.getItem("profilePic");

            if(body != undefined){
              this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + body);
            }
          } catch(error) {
            // sessionStorage.clear();
            // this.navToPage("landingpage");
            let errorLocation = this.errorLocation.replace("methodName", "getImageFromUserService");
            error.message += errorLocation;
            throw error;
          }
        }
        else{
          this.userService.getAPIProfilePicture(this.user.OptNum).subscribe(data => {
            try {
              let body = data;
  
              if(body.image != undefined){
                this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + body.image);
              }else
                this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + sessionStorage.getItem("profilePic"));
              
            } catch(error) {
              // sessionStorage.clear();
              // this.navToPage("landingpage");
              let errorLocation = this.errorLocation.replace("methodName", "getImageFromUserService");
              error.message += errorLocation;
              throw error;
            }
          }, error => {
            // sessionStorage.clear();
            // this.navToPage("landingpage");
            let errorLocation = this.errorLocation.replace("methodName", "getImageFromUserService");
            error.error.message += errorLocation;
            throw error.error;
          });
        }


      }
    } catch(error) {
      // sessionStorage.clear();
      // this.navToPage("landingpage");
      let errorLocation = this.errorLocation.replace("methodName", "getImageFromUserService");
      error.message += errorLocation;
      throw error;
    }
  }  


  navToPage(strLoc) {
    try {
        
        this.router.navigate([strLoc]);

    } catch(error) {
      // sessionStorage.clear();
      // this.navToPage("landingpage");
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }



  getSessionUserDetails() {
    try {
      let period = "";
        
      this.user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));  
      
      if(this.user != null || this.user != undefined) {
        this.firstName = this.user.FirstName;
        this.middleInitial = this.user.MiddleInitial;
        this.middleInitial = this.middleInitial.replace(/\s/g, "");
    
        if(this.middleInitial != ""){
          period = ".";
        }
        this.lastName = this.user.LastName;
        this.emplName = this.firstName + " " + this.middleInitial + period + " " + this.lastName;
        this.personnelId = this.user.PersonnelNumber;
    
        let SSN =  this.user.ParticipantNumber;
        SSN = SSN.substr(0, 2);
    
        if(SSN == "99"){
          this.globalId = this.user.ParticipantNumber;
        }else{
          this.globalId = "Your SSN";
        }
      }
    } catch(error) {
      // sessionStorage.clear();
      // this.navToPage("landingpage");
      let errorLocation = this.errorLocation.replace("methodName", "getSessionUserDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  // getBellGrantData(){ 
  //   try {
  //     if(sessionStorage.getItem("hasAlertBell") == "true"){
  //       if(sessionStorage.getItem("alertBellStorage") != null){
  //         let data = JSON.parse(sessionStorage.getItem("alertBellStorage"));
  //         this.bellGrantList = data;
  //             this.notifCount = data.length;        
      
  //             if(Array.isArray(this.bellGrantList) && this.bellGrantList.length > 0) {  
  //               this.showBellNotif = true;
  //               this.showNoDataBellNotif = false;
  //               for(let i = 0; i < this.bellGrantList.length; i++){
  //                 // this.bellGrantNum = this.bellGrantList[i].GrantNum;      
  //                 // this.bellGrantHeader = this.bellGrantList[i].Header;            
  //                 this.bellGrantMessage = this.bellGrantList[i].AlertMessage;              
  //               }
  //             }
  //             else {
  //               this.showNoDataBellNotif = true;  
  //               this.showBellNotif = false;          
  //             }
  //       }
  //       else{
  //         this.showBellNotif = true;
  //         this.showNoDataBellNotif = false;
  //       }
  //     }
  //     else{
  //       this.staticcontentService.getBellGrantAPI(this.user.OptNum).map(res => res).subscribe(data => { 
  //         try {
  //           if(data != undefined){
  //             if(data.length > 0){
  //               sessionStorage.setItem("alertBellStorage", JSON.stringify(data));
  //               sessionStorage.setItem("hasAlertBell", "true");
  //               this.bellGrantList = data;
  //               this.notifCount = data.length;        
        
  //               if(Array.isArray(this.bellGrantList) && this.bellGrantList.length > 0) {  
  //                 this.showBellNotif = true;
  //                 this.showNoDataBellNotif = false;
  //                 for(let i = 0; i < this.bellGrantList.length; i++){
  //                   // this.bellGrantNum = this.bellGrantList[i].GrantNum;      
  //                   // this.bellGrantHeader = this.bellGrantList[i].Header;            
  //                   this.bellGrantMessage = this.bellGrantList[i].AlertMessage;              
  //                 }
  //               }
  //               else {
  //                 this.showNoDataBellNotif = true;  
  //                 this.showBellNotif = false;          
  //               }
  //             }
  //             else{
  //               sessionStorage.setItem("hasAlertBell", "false");
  //             }
  //           }
  //         } catch(error) {
  //           // sessionStorage.clear();
  //           // this.navToPage("landingpage");
  //           let errorLocation = this.errorLocation.replace("methodName", "getBellGrantData");
  //           error.message += errorLocation;
  //           throw error;
  //         }
  //       }, error => {
  //         // sessionStorage.clear();
  //         // this.navToPage("landingpage");
  //         let errorLocation = this.errorLocation.replace("methodName", "getBellGrantData");
  //         error.error.message += errorLocation;
  //         throw error.error;
  //       });
  //     }
  //   } catch(error) {
  //     // sessionStorage.clear();
  //     // this.navToPage("landingpage");
  //     let errorLocation = this.errorLocation.replace("methodName", "getBellGrantData");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }
  
  showNotifs(){
    try {
      document.getElementById("bellNotif").click();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "showNotifs");
      error.message += errorLocation;
      throw error;
    }
  }

  // hideNav(){
  //   $(document).on('click', function (e) {
  //     if ($(e.target).closest("#sideNavHeader").length === 0) {
  //         $("#sideNavHeader").hide();
  //     }
  // });
  // }

  // showNav(){
  //   $("#headerToggler").click(function(){
  //     $("#sideNavHeader").show();
  //   });
  // }

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft     
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Navheader") != -1 && this.user != null) {
                webPage = "Navheader";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  
  setResourcesActiveTab(tab){
    sessionStorage.setItem("resourcesActiveTab", tab);
  }

  // setNavbarSticky(){

  //   var navbar = document.getElementById("lpNav");
  //   var sticky = navbar.offsetTop;
  
  //   $(window).scroll(function() {
  //     if (window.pageYOffset >= sticky) {
  //       navbar.classList.add("sticky")
  //     } else {
  //       navbar.classList.remove("sticky");
  //     }
  //   });
  // }

   /***************************************************************************************************************
     * Method Name: getEaAccess                                                                 
     * Description: Gets the EA application access of the user.                       
     *                                                                                          
     * #         Date                 Modified by                    Description                             
     * 1         07/20/20             John Paul B Fernando           Initial draft  
     ****************************************************************************************************************/
   getEaAccess(){
     
       let getApplicationAccessBody = { 
         functionName: 'getApplicationAccess'
       };
   
       this.apiService.postData(this.globals.manageUserAccessApi, getApplicationAccessBody).subscribe( accessData => {
         try {
           if (accessData && accessData.statusCode == 200) {
             let body = accessData.body;
             if (body != this.globals.noAccess) {
               this.showManage = true;
             } else {
              this.eaUserAccess.setUserAccess(false);
             }
           } else {
             this.eaUserAccess.setUserAccess(false);
           }
         } catch (error) {
           error.message += this.apiService.setErrorContents('getEaAccess', 'Navheader');
           throw error
         }
       }, error => {
         error.error.message += this.apiService.setErrorContents('getEaAccess', 'Navheader');
       })
   }


   /***************************************************************************************************************
     * Method Name: getUserStatus                                                                 
     * Description: Gets the user status.                       
     *                                                                                          
     * #         Date                 Modified by                    Description                             
     * 1         08/06/20             John Paul B Fernando           Initial draft  
     ****************************************************************************************************************/
   getUserStatus(){
     let checkUserBody = {
       functionName: 'checkCtr'
     }

     this.apiService.postData(this.globals.manageCheckCtr, checkUserBody).subscribe( userData => {
       try {
         if (userData) {
           let body = userData.body
           if (body.length == 0) {
             this.showMyhTabs = false;
           }
         }
       } catch (error) {
         error.message += this.apiService.setErrorContents('getUserStatus', 'Navheader');
         throw error
       }
     }, error => {
       error.error.message += this.apiService.setErrorContents('getUserStatus', 'Navheader')
     })

   }


}
  
