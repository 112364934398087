
import { debounceTime, map } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsService } from './ReportsService';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import * as $ from 'jquery';
// import { MatInput, PageEvent } from '@angular/material';
// import { Body } from '@angular/http/src/body';
import { ExportExcelService } from '../services/ExportExcelService';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { NgbDatepicker, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../services/UserService';
import { StaticContentService } from '../services/StaticContentService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { ReportsSharepricehistorypageComponent } from './reports-sharepricehistorypage/reports-sharepricehistorypage.component';
import { SharePriceHistoryPageService } from './reports-sharepricehistorypage/SharePriceHistoryPageService';
import { EnrollmenthistorypageService } from '../reports-enrollmenthistorypage/EnrollmentHistoryPageService';
import { EspptransactionService } from '../enrollmentwithdraw-espp/EsppTransactionService'
import { VeiptransactionService } from '../enrollmentwithdraw-veip/VeipTransactionService';
import { EnrollStatus, HoldingsId } from '../services/enumerations/enumerations';
import { Globals } from '../globals';

import { Subject, Observable } from 'rxjs';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-reportspage',
  templateUrl: './reportspage.component.html',
  styleUrls: ['./reportspage.component.css'],
  providers: [ReportsSharepricehistorypageComponent]

})

/* This block of code is for the dynamic response of reports */
// export class TablePagination
// {
//   @ViewChild(MatPaginator) paginator: MatPaginator;

//   columnsSharePriceHistory =
//   [
//     { columnDef: 'date',                header: 'PRICE_DT',          cell:(element:any) => `${element.date}` },
//     { columnDef: 'high',                header: 'High',              cell:(element:any) => `${element.high}` },
//     { columnDef: 'low',                 header: 'Low',               cell:(element:any) => `${element.low}` },
//     { columnDef: 'close',               header: 'Close',             cell:(element:any) => `${element.close}` },
//     { columnDef: 'fair market value',   header: 'Fair Market Value', cell:(element:any) => `${element.fmv}` },
//     { columnDef: 'trading volume',      header: 'Trading Volume',    cell:(element:any) => `${element.tradvol}` },
//   ];

//     displayedColumns = this.columnsSharePriceHistory.map(sharePrice => sharePrice.columnDef);
//     dataSourceSharePriceHistory = new GetSharePriceHistory();
// }

export class ReportspageComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild('dateFrom', {read: NgbDatepicker}) closeDateFrom: NgbDatepicker;
  @ViewChild('tableReports') tableReports: ElementRef;
  @ViewChild('salesHistTotal') salesHistTotal: ElementRef;
  // @ViewChild('dateFromInput',{
  //   read: MatInput
  // })
  // @ViewChild('dateToInput',{
  //   read: MatInput
  // })

  nextPageDebounce$ = new Subject<string>();

  // dateFromInput: NgbDateStruct; //Ngb Datepicker
  // dateToInput: NgbDateStruct; //Ngb Datepicker
  dateFromInput: Date; //Angular Material Datepicker
  dateToInput: Date; //Angular Material Datepicker

  datePipe = new DatePipe('en-US');

  userReports: any;
  reportType: string = "Select Report";
  dateFrom: string;
  dateTo: string;
  dateFromChecker: string;
  dateToChecker: string;
  displayDateError: boolean;
  legitDateFrom: string;
  legitDateTo: string;

  displaySharePrice: boolean = false;
  displaySharePriceFilter: boolean = false;
  noSharePriceHistory: boolean = false;

  sharePriceHistDataSource: any = [];
  displayedSharePriceHistColumns: string[] = ['Date', 'High (USD)', 'Low (USD)', 'Close (USD)', 'Fair Market Value (USD)', 'Trading Volume'];
  displayedSharePriceHistGenerateColumns = {'Date': 'Date', 'High (USD)': 'High (USD)', 'Low (USD)': 'Low (USD)', 'Close (USD)': 'Close (USD)', 'Fair Market Value (USD)':'Fair Market Value (USD)', 'Trading Volume': 'Trading Volume' };
  sharePriceHistLoading: boolean;
  // sharePricePageLength: number = 51;
  // ReportType: string = "Select Report";
  // sharePricePageNum: number = 1;
  // sharePriceHistoryPrevData: any;
  // sharePriceHistoryNextData: any;
  // sharePricePrevIndex: number = 0;
  // sharePriceColumnCount: number;
  displayedColumns: any;
  dataSource: any = [];
  displayedTotalColumns: any;
  //completeCurrencyList: string = JSON.parse(sessionStorage.getItem("currency_list"));
  completeCurrencyList: any;
  currentSelected: string;
  // noExchangeRage: boolean = false;
  currentCode: string;

  //START - Enrollment History variables
  user: any = JSON.parse(sessionStorage.getItem(this.globalVariables.profileInfoKey));

  displayEnrollHist: boolean = false;
  noEnrollmentHist: boolean = false;
  displayProgramList: boolean = false;
  displayTotalsTable: boolean = false;
  totalsTable: any = [];

  displayCurrencyList: boolean = false;
  enrollmentHistoryDetails: any;
  // enrollmentHistoryDetailsNextBatch: any;
  // enrollmentHistoryDetailsPrevBatch: any;
  enrollmentTypeDropdown: any;
  // enrollmentTypeDropdownText: any;
  displayedColumnsAdmin: string[] = ['Date','Employee ID','Last Name','First Name','Employment Status','Purchase Program','Purchase Period','Status','Contribution Percent','Matching Grant'];
  displayedColumnsAdminGenerateColumn = {'Date':'Date','Personnel Number':'Employee ID','Last Name':'Last Name','First Name':'First Name','Employment Status':'Employment Status','Purchase Program':'Purchase Program', 'Purchase Period':'Purchase Period','Status':'Status','Contribution Percent':'Contribution Percent'};

  pageLength: any = 51;
  // pageLengthNext: any = 51;
  prevIndex: any = 0;
  // currentIndex: any = 1;
  programType: any;
  programSelected: any;
  programTypeDropDownData: any;
  fromDate: any;
  toDate: any;
  currencyType: any;

  loading: any = true;
  noDataInTable: boolean = false;
  tableLoading: boolean = false;
  // enrollHistoryTblLoading: any = false;

  hasEspp: any;
  hasVeip: any;
  isEnrolldCurrEsppOff: any = 0;
  isEnrolldCurrVeipOff: any = 0;
  hasFounder: any;
  hasRsu: any;
  hasOptions: any;
  veipAllOffering: any;

  periodSelected: string = "Year to Date";

  disableDatePicker: boolean = false;
  // noEnrollment: boolean = false;
  // displayEnrollTable: boolean = false;
  isCustom: boolean = false;

  //END - Enrollment History variables

  //SHARE PRICE
  // SPcurrentIndex: any = 1;
  // SPprevIndex: any = 0;
  pageSize = 50;
  length: any;
  lengthContainer: any;
  pageIndex: any;
  pageSizeOptions = [1, 2, 10, 20, 50];
  displayPageNavigator: boolean = true;

  previousDateFrom: any;
  previousDateTo: any;
  userprofileInfo: any;
  sessionCachedUserInfo: boolean;
  // dataSource: MatTableDataSource<object>;

  //Purchase Program
  purchaseProgramDropDownData: any;
  displayPurchaseProgramColumnVEIP: string[] = ["PROGRAM", "PURCHASE DATE", "GRANT ID", "CONTRIBUTION %", "MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)", "EXCHANGE RATE", "MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (USD)", "PURCHASE PRICE FMV (USD)", "TOTAL SHARES PURCHASED", "TOTAL VALUE OF SHARES PURCHASED (USD)", "TOTAL VALUE OF SHARES PURCHASED (LOCAL)", "CONTRIBUTION TO BE CARRIED OVER/REFUNDED (USD)", "CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)", "SHARES DISPOSED", "SHARES AVAILABLE", "BROKER FOR MORE INFORMATION"];
  displayPurchaseProgramColumnESPP: string[] = ["PROGRAM", "OFFERING PERIOD", "CONTRIBUTION %", "TOTAL CONTRIBUTIONS (LOC)", "EXCHANGE RATE", "TOTAL CONTRIBUTIONS (USD)", "DISCOUNTED PURCHASE PRICE (USD)", "TOTAL SHARES PURCHASED", "FMV AT PURCHASE (USD)", "FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)", "VALUE OF DISCOUNT RECEIVED (LOC)", "SHARES SOLD FOR TAX WITHHOLDING", "VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)", "FRACTIONAL SHARES SOLD FOR REFUND", "VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)", "SHARES SOLD/DISPOSED", "SHARES AVAILABLE", "ACCOUNT", "BROKER FOR MORE INFORMATION", "ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"];
  noPurchaseProgram: boolean = false;
  displayPurchaseProgramVEIPGenerateColumn = {"Program":"Program", "Purchase Date":"Purchase Date", "Grant ID":"Grant ID", "Contribution %":"Contribution %", "Monthly Contribution Plus Prior Month's Carryover (Local)":"Monthly Contribution Plus Prior Month's Carryover (Local)", "Exchange Rate":"Exchange Rate", "Monthly Contribution Plus Prior Month's Carryover (USD)":"Monthly Contribution Plus Prior Month's Carryover (USD)"};
  displayPurchaseProgramESPPGenerateColumn = {"Program":"Program", "Offering Period":"Offering Period", "Contribution %":"Contribution %", "Total Contributions (Loc)":"Total Contributions (Loc)", "Exchange Rate":"Exchange Rate", "Total Contributions (USD)":"Total Contributions (USD)", "Discounted Purchase Price (USD)":"Discounted Purchase Price (USD)", "Total Shares Purchased":"Total Shares Purchased"};
  // noPurchaseProgramData: boolean = false;

  totalMonthlyContributionPriorMonthContributionLoc: any;
  totalMonthlyContributionPriorMonthContributionUSD: any;
  totalPurchasePriceUSD: any;
  totalTotalSharesPurchasedVEIP: any;
  totalTotalValuesSharesPurchasedUSD: any;
  totalTotalValuesSharesPurchasedLoc: any;
  totalContributionCarriedOverRefundedUSD: any;
  totalContributionCarriedOverRefundedLoc: any;
  totalSharesDisposed: any;
  totalSharesAvailableVEIP: any;

  totalTotalContributionsLoc: any;
  totalTotalContributionsUSD: any;
  totalTotalSharesPurchasedESPP: any;
  totalValueSharesPurchasedCostBasisLoc: any;
  totalValueDiscountRecievedLoc: any;
  totalSharesSoldTaxWithholding: any;
  totalValueSharesSoldTaxWithholdingLoc: any;
  totalFractionalSharesSoldRefund: any;
  totalValueFractionalSharesSoldRefundLoc: any;
  totalSharesSoldDisposed: any;
  totalSharesAvailableESPP: any;
  totalActualTaxReportPayrollLoc: any;

  displayTotalsPurchaseProgram: boolean = false;
  totalsPurchaseProgram: any[] = [];

  displayExchangeHistoryColumn: string[] = ['Date','Currency Exchange Rate'];
  displayExchangeHistoryGenerateColumn = {'Date': 'Date', 'Currency Exchange Rate': 'Currency Exchange Rate'};

  //RSU Actvity
  rsuActivityDropDownData: any;
  displayRSUActivityColumn: string[]= ["Program", "RSU Award", "Grant ID", "Grant Date", "Transaction Date", "Transaction Type", "Fair Market Value per Share on Release Date (USD)", "Currency Code", "Exchange Rate in Event Date", "Shares Vested", "Shares Released", "Total Value on Transaction Date in Local Currency", "Shares Withheld For Taxes", "Total Value of Taxes Withheld in Local Currency", "Sale-Fractional Share", "Value of Fractional Share Returned in Local Currency","Total Shares W/H","Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)","Shares Released to Broker"];
  displayRSUActivityGenerateColumn = {'Program': 'Program', 'RSU Award': 'RSU Award', 'Grant ID': 'Grant ID', 'Grant Date': 'Grant Date', 'Transaction Date': 'Transaction Date', 'Transaction Type': 'Transaction Type', 'Fair Market Value per Share on Release Date (USD)': 'Fair Market Value per Share on Release Date (USD)', 'Currency Code': 'Currency Code'}
  noRSUActivity: boolean = false;
  displayGrantDesc: boolean = false;
  grantDescList: any;
  grantDescription: any[] = [];
  selectedAllRSUDropDownData: any;
  selectedRSUGrants: any = [];
  selectAllGrantsInList: boolean = true;;
  grantsRSU: any = [];
  counterGrantsRSU: number;
  selectedGrantsFromRSUGrantList: string;
  rsuActgrantsOnLoad: string;
  disableViewRSUActivity: boolean = false;

  // myForm:FormGroup;
	disabled = false;
	ShowFilter = false;
	limitSelection = false;
	dataDropDownRSU: any = [];
	selectedItems: any = [];
  dropdownSettings: any = {};
  grantVisible: boolean;

  /* Individual Transfer Limit - START */
  displayIndividualTransferLimitColumn: string[] = ['Program', 'Share Type', 'Trading Window Period', 'Total Unrestricted Shares at Period Start', 'Individual Transfer Limit Status', 'Estimated Sales Price in Effect', 'Redemption Method', 'Individual Transfer Limit Shares', 'Additional Shares Available Due to Transfer Limit Waiver', 'Total Shares Sold/Transferred', 'Net Shares Eligible for Sale/Transfer in Period']
  displayIndividualTransferLimitGenerateColumn = {'Program': 'Program', 'Share Type': 'Share Type', 'Trading Window Period': 'Trading Window Period', 'Total Unrestricted Shares at Period Start': 'Total Unrestricted Shares at Period Start', 'Individual Transfer Limit Status': 'Individual Transfer Limit Status', 'Estimated Sales Price in Effect': 'Estimated Sales Price in Effect', 'Redemption Method': 'Redemption Method'};
  displayIndividualTransferLimit: boolean = false;
  allFiscalYearBeginDate: string = "01/01/1900";
  fiscalYearMonth: any = 8;  //Fiscal Year starts at September but Date.getMonth() returns [0-11]
  totalIndividualTransferLimit: boolean = false;
  displayedTotalColumnsIndividualTransferLimit: string[] = ['Total', 'Share Type', 'Trading Window Period', 'Total Unrestricted Shares at Period Start', 'Individual Transfer Limit Status', 'Estimated Sales Price in Effect', 'Redemption Method', 'Individual Transfer Limit Shares', 'Additional Shares Available Due to Transfer Limit Waiver', 'Total Shares Sold/Transferred', 'Net Shares Eligible for Sale/Transfer in Period']

  fiscalYearBeginDate: any;
  fiscalYearEndDate: any;
  /* Individual Transfer Limit - END */
  noSalesProgram: boolean;
  displaySalesProgramColumn: any;
  noSalesHistory: boolean;
  salesHistoryDropDownData: any;
  grantDesc: any;
  salesHistory: boolean = false;
  SalesHistoryCheckboxData: any[];
  SalesHistoryGrantData: any[];
  programTypeGrantData: any[];
  holdingsId: any;
  displayedSalesHistoryColumns: string[] = ['Program', 'Award Details', 'Transaction Date', 'Transaction Type', 'Number of Shares in Transaction', 'Sale Price (USD)', 'Gross Proceeds (USD)', 'Original Purchase Date', 'Purchase Date FMV per Share(USD)', 'Purchase Date Value (USD)', 'Gain/(Loss) - USD', 'Gain/(Loss) - Local Currency', 'Value Per Share at Exercise (USD)', 'Total Value on Exercise Date (USD)', 'Total Cost on Exercise Date (USD)', 'Taxable Income(USD)', 'Estimated Taxes Withheld at Exercise (USD)', 'Estimated Proceeds (USD)', 'Broker for More Information'];
  displayedSalesHistoryGenerateColumns = {'Program': 'Program', 'Award Details': 'Award Details', 'Transaction Date': 'Transaction Date', 'Transaction Type': 'Transaction Type', 'Number of Shares in Transaction': 'Number of Shares in Transaction', 'Sale Price (USD)': 'Sale Price (USD)', 'Gross Proceeds (USD)': 'Gross Proceeds (USD)'};
  exchangeRateHist: boolean;
  programChecked: boolean;
  progSel: string;

  reportId: any = JSON.parse(sessionStorage.getItem("REPORT ID"));
  selectedPrograms: any[] = [];
  selectedRSUActivityGrants: any[] = [];
  selectedHoldingIds: any[] = [];

  salesHistoryIDs: number[];
  selectedGrantsList:any[] = [];
  salesHisttableLoading: boolean;
  checkPrograms: boolean = true;
  checkGrants: boolean = true;
  checkAllGrantsText: boolean = true;
  allGrantsisCheck: boolean = false;
  isViewDisable: boolean = false;
  checkProgramsText: boolean = false;
  checkProgramsAll: boolean = true;
  checkAllPrograms: boolean = true;
  showGrantsList: boolean;
  counterSalesHistoryPrograms: number;
  counterSalesHistoryGrants: number;
  salesHistoryID: number;
  salesHistoryProgramTypeDropDownData: any = [];
  disableViewSalesHistory: boolean = false;
  salesHistoryProgramsOnLoad: string = ""; 
  salesHistoryHoldingIDsOnLoad: string = "";
  salesHistoryHoldingIDsOnLoad2: any[] = [];
  salesHistoryGrantsOnLoad: string = "";
  salesHistoryPrograms: string;
  salesHistoryGrants: string;
  hasRSUProgramBeenTriggered: boolean = false;
  numberOfRSUsInProgramList: any;

  individualTransferLimitData: any;
  salesHistoryData: any;
  rsuActivitytData: any;
  csvData: any;
  rsuRowCount: any[] = [];
  rsuTotalCount: number;
  rsuTotalPageIndex: number;
  displayTotalRow: boolean = false;
  totalSharesVested: any;
  totalSharesReleased: any;
  totalSharesWithheldForTaxes: any;
  TotalValueofTaxesWithheld: any;
  totalSaleFractionalShare: any;
  totalValueofFractionalShareReturned: any;
  TotalShares: any;
  TotalValueShares: any;
  totalSharesReleasedBroker: any;
  totalsColumn: any[] = [];

  salesHistLastTable: boolean = false;
  saleHistTotal: boolean = false;
  salesNoSiT: any;
  salesGPUSD: any;

  rsuTotal: boolean = false;
  rsuSV: any;
  rsuSR: any;
  rsuSWfT: any;
  rsuTVoTWiLC: any;
  rsuSFS: any;
  rsuVoFSRiLC: any;
  rsuTSWH :any;
  rsuTVoSWHiLC:any;
  rsuSRB: any;
  rsu: any;
  rsuTotalRow: any;
  itlTotalRow: any;

  errorLocation: string = " + [Component: Reportspage, Method: methodName]";
  errorReportsPage: boolean = false;

  selectedFileExtension: string = "Select an Export Format";
  isExportBtnDisable: boolean = true;
  totalRowChecker: boolean = false;

  rsuCalendar: boolean = false;

  displayExportAndPagination: boolean = false;
  display: boolean = false;
  totalPages: number;
  // dateFromInputPlaceHolder: string; //Ngb Datepicker
  // dateToInputPlaceHolder: string; //Ngb Datepicker
  displayAllGrantCheckbox: boolean;
  disableProgram: boolean = false;
  hasDateFromBeenClicked: boolean = false;
  hasDateToBeenClicked: boolean = false;
  holderDateFrom: any;
  holderDateTo: any;

  //MJS 05/21/2024 617951- myHoldings Dashboard Enhancement
  selectedReportType: string;

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService, private reportsService: ReportsService, private staticContent: StaticContentService, private sharePriceHist: ReportsSharepricehistorypageComponent, private sharePriceService: SharePriceHistoryPageService, private enrollservice: EnrollmenthistorypageService,  private globalVariables: Globals, private esppTransactionService: EspptransactionService, private veipService: VeiptransactionService, private ngbDateParserFormatter: NgbDateParserFormatter, private errorNotificationService: ErrorNotificationService, private exportExcelService: ExportExcelService) {
    let debounceLName = this.nextPageDebounce$.pipe(debounceTime(2000));
    debounceLName.subscribe(changes => {
      this.onPageChange(changes);
    });
   }

  ngOnInit() {
    this.checkErrorMessage();
    this.getEnrollmentDropdownData();
    this.getIsEnrollCurrESPPDetails();
    this.getPurchaseProgramDropDownData();
    this.getExchangeRtCurrencyList();
    // this.getSalesHistoryCheckboxData();
    this.getRSUActivityDropDownData();
    let esoOptNum = JSON.parse(sessionStorage.getItem(this.globalVariables.sessionOptNum));
    this.getUserDetails(esoOptNum);
    //MJS 05/21/2024 617951- myHoldings Dashboard Enhancement: Call getUserReports inside the subscription
    this.route.params.subscribe(params => {
    this.reportType = params['reportTypeDescription'];
    this.selectedReportType = this.reportType;
   
    });
  }
  
// Navigates to a specific report page based on the report type description and removed spaces to '/reportspage'
  navigateToReport(reportTypeDescription: string) {
    this.router.navigate(['/reportspage', reportTypeDescription.replace(/\s+/g, '')]);
  }
  /********************************************************************************************************************************************
   * Method Name: generateToPDF
   * Description: This is to generate and download the tables to a pdf file.
   *
   * #    Date                 Modified by                           Description
   * 1    05/09/19             Joshua Paul J. Avecilla(JPA)          Initial draft
   *******************************************************************************************************************************************/
  generateToPDF(){
  try{

    if(this.reportType == "RSU Activity"){ // RSU
        let grantDescription = sessionStorage.getItem("rsuActivitySelectedGrant");
        let isAllGrantsChecked = sessionStorage.getItem("isAllGrantsChecked");
      
        grantDescription != null ? "" : grantDescription = sessionStorage.getItem("rsuActgrantsOnLoad");
        grantDescription != "" ? "" : grantDescription = sessionStorage.getItem("rsuActgrantsOnLoad");
        isAllGrantsChecked == "Y" ? grantDescription = sessionStorage.getItem("rsuActgrantsOnLoad") : "";

        let headerFirstTable = this.displayRSUActivityGenerateColumn;
        let headerSecondTable = {'Exchange Rate in Event Date': 'Exchange Rate', 'Shares Vested': 'Shares Vested', 'Shares Released': 'Shares Released', 'Total Value on Transaction Date in Local Currency': 'Total Value on Transaction', 'Shares Withheld For Taxes': 'Shares Withheld For Taxes', 'Total Value of Taxes Withheld in Local Currency': 'Total Value of Taxes Withheld in Local Currency', 'Sale-Fractional Share': 'Sale-Fractional Share'}
        let headerThirdTable = {'Value of Fractional Share Returned in Local Currency': 'Value of Fractional Share Returned in Local Currency', 'Total Shares W/H': 'Total Shares W/H', 'Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)': 'Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)', 'Shares Released to Broker': 'Shares Released to Broker'};

        let doc = new jsPDF({orientation: 'landscape'}); 

        this.reportsService.getRSUActivityDataData(this.user.OptNum, this.legitDateFrom, this.legitDateTo, -99, grantDescription).subscribe(data => {

          // let mainDataSource = data[0].sort((a, b) =>  a["Transaction Date"].format("YYYY/MM/DD") < b["Transaction Date"].format("YYYY/MM/DD") ? 1: a["Transaction Date"].format("YYYY/MM/DD") > b["Transaction Date"].format("YYYY/MM/DD") ? -1: 0);
          let mainDataSource = data[0].sort((a, b) =>  moment(a["Transaction Date"]).format("YYYY/MM/DD") < moment(b["Transaction Date"]).format("YYYY/MM/DD") ? 1: moment(a["Transaction Date"]).format("YYYY/MM/DD") > moment(b["Transaction Date"]).format("YYYY/MM/DD") ? -1: 0);
          // let mainDataSource = data[0].sort((a, b) =>  a["Transaction Date"] < b["Transaction Date"] ? 1: a["Transaction Date"] > b["Transaction Date"] ? -1: 0);
          // let mainDataSource = data[0];
          let lengthForLoop = mainDataSource.length/21;
          let firstDataSource;
          let secondDataSource = [];
          let thirdDataSource = [];
          let contentLength, staticLength = 21;
          lengthForLoop = Math.ceil(lengthForLoop)

          for(let i=0; i<mainDataSource.length; i++)
          {
            mainDataSource[i]["Shares Vested"] = this.commafy(mainDataSource[i]["Shares Vested"]);
            mainDataSource[i]["Shares Released"] = this.commafy(mainDataSource[i]["Shares Released"]);
            mainDataSource[i]["Total Value on Transaction Date in Local Currency"] = parseFloat(mainDataSource[i]["Total Value on Transaction Date in Local Currency"]).toFixed(2);
            mainDataSource[i]["Total Value on Transaction Date in Local Currency"] = this.commafy(mainDataSource[i]["Total Value on Transaction Date in Local Currency"],2);
            mainDataSource[i]["Shares Withheld For Taxes"] = parseFloat(mainDataSource[i]["Shares Withheld For Taxes"]).toFixed(2);
            mainDataSource[i]["Shares Withheld For Taxes"] = this.commafy(mainDataSource[i]["Shares Withheld For Taxes"],2);
            mainDataSource[i]["Total Value of Taxes Withheld in Local Currency"] = this.commafy(mainDataSource[i]["Total Value of Taxes Withheld in Local Currency"]);
            
            mainDataSource[i]["Total Shares W/H"] = parseFloat(mainDataSource[i]["Total Shares W/H"]).toFixed(2);
            mainDataSource[i]["Total Shares W/H"] = this.commafy(mainDataSource[i]["Total Shares W/H"],2);
            
            mainDataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = parseFloat(mainDataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"]).toFixed(2);
            mainDataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = this.commafy(mainDataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"],2);
            
            mainDataSource[i]["Shares Released to Broker"] = parseFloat(mainDataSource[i]["Shares Released to Broker"]).toFixed(2);
            mainDataSource[i]["Shares Released to Broker"] = this.commafy(mainDataSource[i]["Shares Released to Broker"],2);

            mainDataSource[i]["Fair Market Value per Share on Release Date (USD)"] = parseFloat(mainDataSource[i]["Fair Market Value per Share on Release Date (USD)"]).toFixed(4);
            mainDataSource[i]["Fair Market Value per Share on Release Date (USD)"] = this.commafy(mainDataSource[i]["Fair Market Value per Share on Release Date (USD)"], 4);

            mainDataSource[i]["Value of Fractional Share Returned in Local Currency"] = parseFloat(mainDataSource[i]["Value of Fractional Share Returned in Local Currency"]).toFixed(2);
            mainDataSource[i]["Value of Fractional Share Returned in Local Currency"] = this.commafy(mainDataSource[i]["Value of Fractional Share Returned in Local Currency"], 2);

            if(mainDataSource[i]["Exchange Rate in Event Date"] != null){
              mainDataSource[i]["Exchange Rate in Event Date"] = parseFloat(mainDataSource[i]["Exchange Rate in Event Date"]).toFixed(4);
              mainDataSource[i]["Exchange Rate in Event Date"] = this.commafy(mainDataSource[i]["Exchange Rate in Event Date"], 4);
            }
            else
            {
              mainDataSource[i]["Exchange Rate in Event Date"] = " ";
            }

            if(mainDataSource[i]["Sale-Fractional Share"] != null)
            {
              mainDataSource[i]["Sale-Fractional Share"] = parseFloat(mainDataSource[i]["Sale-Fractional Share"]).toFixed(4);
              mainDataSource[i]["Sale-Fractional Share"] = this.commafy(mainDataSource[i]["Sale-Fractional Share"], 4); 
            }
            else
            {
              mainDataSource[i]["Sale-Fractional Share"] = " ";
            }
            if(mainDataSource[i]["Total Value of Taxes Withheld in Local Currency"] != null)
            {
              mainDataSource[i]["Total Value of Taxes Withheld in Local Currency"] = parseFloat(mainDataSource[i]["Total Value of Taxes Withheld in Local Currency"]).toFixed(2);
              mainDataSource[i]["Total Value of Taxes Withheld in Local Currency"] = this.commafy(mainDataSource[i]["Total Value of Taxes Withheld in Local Currency"],2);
            }
            else
            {
              mainDataSource[i]["Total Value of Taxes Withheld in Local Currency"] = " ";
            }
          }

          mainDataSource.push({"Program":"Totals", "RSU Award": "", "Grant ID": "", "Grant Date": "", "Transaction Date": "", "Transaction Type":"", 
                               "Fair Market Value per Share on Release Date (USD)": "", "Currency Code": "", 
                               "Shares Vested": data[1][0]["Total Shares Vested"], "Shares Released": data[1][0]["Total Shares Released"], 
                               "Total Value on Transaction Date in Local Currency": "", "Shares Withheld For Taxes": parseFloat(data[1][0]["Total Shares Withheld For Taxes"]).toFixed(2), 
                               "Total Value of Taxes Withheld in Local Currency": parseFloat(data[1][0]["Total Value of Taxes Withheld in Local Currency"]).toFixed(2), 
                               "Sale-Fractional Share": parseFloat(data[1][0]["Total Sale-Fractional Share"]).toFixed(4), "Value of Fractional Share Returned in Local Currency": parseFloat(data[1][0]["Total Value of Fractional Share Returned in Local Currency"]).toFixed(2), 
                               "Total Shares W/H": parseFloat(data[1][0]["Total Shares W/H"]).toFixed(2), "Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)": parseFloat(data[1][0]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"]).toFixed(2),
                               "Shares Released to Broker": parseFloat(data[1][0]["Total Shares Released to Broker"]).toFixed(2) });

          // for(let i=0; i<lengthForLoop; i++)
          for(let i=1; i<=lengthForLoop; i++){
            contentLength = staticLength*i;
            firstDataSource = mainDataSource.slice(contentLength-21, contentLength);
            secondDataSource = [];
            thirdDataSource = [];

            firstDataSource.forEach( cell => {
              secondDataSource.push({'Exchange Rate in Event Date': cell['Exchange Rate in Event Date'], 'Shares Vested': cell['Shares Vested'], 'Shares Released': cell['Shares Released'], 'Total Value on Transaction Date in Local Currency': cell['Total Value on Transaction Date in Local Currency'], 'Shares Withheld For Taxes': cell['Shares Withheld For Taxes'], 'Total Value of Taxes Withheld in Local Currency': cell['Total Value of Taxes Withheld in Local Currency'], 'Sale-Fractional Share': cell['Sale-Fractional Share']})
              thirdDataSource.push({'Value of Fractional Share Returned in Local Currency': cell['Value of Fractional Share Returned in Local Currency'], 'Total Shares W/H': cell['Total Shares W/H'], 'Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)': cell['Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)'], 'Shares Released to Broker': cell['Shares Released to Broker']})
  
              delete cell.OptNum;
              delete cell.RootGrantNum;
              delete cell.grantNum;
              delete cell.GrantLongDesc;
              delete cell.HoldingsTypeId;
              delete cell['Exchange Rate in Event Date'];
              delete cell['Shares Vested'];
              delete cell['Shares Released'];
              delete cell['Total Value on Transaction Date in Local Currency'];
              delete cell['Shares Withheld For Taxes'];
              delete cell['Total Value of Taxes Withheld in Local Currency'];
              delete cell['Sale-Fractional Share'];
              delete cell['Value of Fractional Share Returned in Local Currency'];
              delete cell['Total Shares W/H'];
              delete cell['Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)'];
              delete cell['Shares Released to Broker'];
            });
            
            if(contentLength > mainDataSource.length){
              firstDataSource = mainDataSource.slice(contentLength-21, mainDataSource.length);
            }
            // else{
              // for(let j=contentLength-21; j<contentLength; j++){
            
                if(i>1){
                  doc.addPage();
                }
                
                (doc as any).autoTable({ 
                  head: [headerFirstTable],
                  body: firstDataSource, 
                  tableWidth: 'auto',
                  theme: 'grid',
                  styles: {},
                  headStyles: {
                    fontSize: 8,
                    textColor: [0, 0, 0],
                    fillColor: 230, //[250, 249, 250]
                    halign: 'center',
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                    cellPadding: { 
                      bottom: 5, top: 4 
                    },
                    overflow: 'linebreak',
                  },
                  bodyStyles: {
                    halign: 'center',
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0]
                  }                 
                });
                doc.addPage(); 
                (doc as any).autoTable({
                  head: [headerSecondTable],
                  body: secondDataSource, 
                  tableWidth: 'auto',
                  theme: 'grid',
                  styles: {},
                  headStyles: {
                    fontSize: 8,
                    textColor: [0, 0, 0],
                    fillColor: 230, //[250, 249, 250]       
                    halign: 'center',
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                    cellPadding: { 
                      bottom: 5, top: 4 
                    },
                    overflow: 'linebreak',
                  },
                  bodyStyles: {
                    halign: 'center',
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                    overflow: 'linebreak'
                  },
                  didParseCell: element => {
                    if(element.cell.raw == "Exchange Rate"){
                      element.cell.text[0] = "Exchange Rate";
                      element.cell.text[1] = "in Event Date";
                    }
        
                    if(element.cell.raw == "Total Value on Transaction"){
                      element.cell.text[0] = "Total Value on Transaction Date";
                      element.cell.text[1] = "in Local Currency";                      
                      // element.column.width = 50;
                      // element.column.wrappedWidth = 50;
                    }
                  }    
                });
                doc.addPage();
                (doc as any).autoTable({
                  head: [headerThirdTable],
                  body: thirdDataSource, 
                  tableWidth: 'auto',
                  theme: 'grid',
                  styles: {},
                  headStyles: {
                    fontSize: 8,
                    textColor: [0, 0, 0],
                    fillColor: 230, //[250, 249, 250]
                    halign: 'center',
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                    cellPadding: { 
                      bottom: 5, top: 4 
                    },
                    overflow: 'linebreak'
                  },
                  bodyStyles: {
                    halign: 'center',
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                    overflow: 'linebreak',
                  }     
                });
        }
        doc.save('RSUActivityReport.pdf')
        })
      }
      else if(this.reportType == "Share Price History"){
        this.sharePriceService.getSharePriceHistory(this.legitDateFrom.replace(/\'/g, ""), this.legitDateTo.replace(/\'/g, ""), -99, 'Y').subscribe(data => {
          let doc = new jsPDF(); 
          let tempListData = data[0].slice(0, data[0].length);

          try{
            for(let i=0; i<tempListData.length; i++){ 
              // tempListData[i].Date = moment(tempListData[i].Date).format("DD MMM YYYY");
              tempListData[i]["Trading Volume"] = parseFloat(tempListData[i]["Trading Volume"]).toFixed();
              tempListData[i]["Trading Volume"] = this.commafy(tempListData[i]["Trading Volume"]);

              // 12/Dec/2019 get latest from ITI_DEV Branch
              // tempListData[i]["Trading Volume"] = parseFloat(tempListData[i]["Trading Volume"]).toFixed(2);
              // tempListData[i]["Trading Volume"] = this.commafy(tempListData[i]["Trading Volume"], 2);

              tempListData[i]["Fair Market Value (USD)"] = parseFloat(tempListData[i]["Fair Market Value (USD)"]).toFixed(4);
              tempListData[i]["Fair Market Value (USD)"] = this.commafy(tempListData[i]["Fair Market Value (USD)"],4);
  
              tempListData[i]["High (USD)"] = parseFloat(tempListData[i]["High (USD)"]).toFixed(4);
              tempListData[i]["High (USD)"] = this.commafy(tempListData[i]["High (USD)"],4);
  
              tempListData[i]["Low (USD)"] = parseFloat(tempListData[i]["Low (USD)"]).toFixed(4);
              tempListData[i]["Low (USD)"] = this.commafy(tempListData[i]["Low (USD)"],4);

              // tempListData[i]["Trading Volume"] = parseFloat(tempListData[i]["Trading Volume"]).toFixed();
              // tempListData[i]["Trading Volume"] = this.commafy(tempListData[i]["Trading Volume"]);
  
              tempListData[i]["Close (USD)"] = parseFloat(tempListData[i]["Close (USD)"]).toFixed(4);
              tempListData[i]["Close (USD)"] = this.commafy(tempListData[i]["Close (USD)"],4);
            }

            (doc as any).autoTable({
              head: [this.displayedSharePriceHistGenerateColumns],
              body: tempListData,
              theme: 'grid',
              tableWidth: 'auto',
              headStyles: {
                fontSize: 8,
                textColor: [0, 0, 0],
                fillColor: 230, //[250, 249, 250]
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                cellPadding: { 
                  bottom: 5, top: 4 
                }
              },
              bodyStyles: {
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              },
            });
            doc.save('SharePriceHistoryReport.pdf');
          } catch(error) {
            let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
            error.message = error.message + errorLocation;
            throw error;
          }
        }, error => {
          let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
          error.error.message += errorLocation;
          throw error.error;
        });
      }
      else if(this.reportType == "Exchange Rate History"){
        let list = this.completeCurrencyList;
        let currencyDecode = list.find(i => i.Decode === this.currentSelected);
        let currencyCode = currencyDecode.Code;

          this.reportsService.getExchangeReportsHistory("'"+this.currentCode+"'", this.legitDateFrom, this.legitDateTo, -99).subscribe(data => {
            let currencyData = data[0];
            let doc = new jsPDF(); 
            let tempListData = currencyData.slice(0, currencyData.length);
            try{
              for(let i=0; i<tempListData.length; i++){
                // tempListData[i].Date = moment(tempListData[i].Date).format("DD MMM YYYY");
                tempListData[i]["Currency Exchange Rate"] = tempListData[i]["Currency Exchange Rate"].toFixed(4);
              } 
      
              tempListData.forEach(element => {
                element["Currency Exchange Rate"] = currencyCode + ' ' + element["Currency Exchange Rate"];
              });

              (doc as any).autoTable({
                head: [this.displayExchangeHistoryGenerateColumn],
                body: tempListData, 
                theme: 'grid',
                tableWidth: 'auto',
                headStyles: {
                  fontSize: 8,
                  textColor: [0, 0, 0],
                  fillColor: 230, //[250, 249, 250]
                  halign: 'center',
                  lineWidth: 0.5,
                  lineColor: [0, 0, 0],
                  cellPadding: { 
                    bottom: 10, top: 4
                  }
                },
                bodyStyles: {
                  halign: 'center',
                  lineWidth: 0.5,
                  lineColor: [0, 0, 0],
                }

              });
              doc.save('ExchangeRateHistoryReport.pdf');
            } catch(error) {
              let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
              error.message = error.message + errorLocation;
              throw error;
            }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
        error.error.message += errorLocation;
        throw error.error;
      });
    }
    else if(this.reportType == "Enrollment History"){
      let headerFirstTable = this.displayedColumnsAdminGenerateColumn;
      let headerSecondTable = {"Matching Grant" : "Matching Grant"};
      
      if(this.programType == "All")
      this.programType = "All Grants";
      this.enrollservice.getEnrollmentData(this.user.OptNum, this.programType, this.legitDateFrom, this.legitDateTo, -99).subscribe(data => {
        let mainDataSource = data[0];
        let lengthForLoop = mainDataSource.length/21;
        // let firstDataSource = mainDataSource.slice(0, mainDataSource.length);
        lengthForLoop = Math.ceil(lengthForLoop);
        let firstDataSource;
        let secondDataSource = [];
        let contentLength, staticLength = 21;

        let doc = new jsPDF({orientation: 'landscape'});  

        for(let i=0; i<mainDataSource.length; i++)
        {
          mainDataSource[i]["Contribution Percent"] = mainDataSource[i]["Contribution Percent"].toString() + "%";
        }

      try {
        for(let i = 1; i <= lengthForLoop; i++){
          contentLength = staticLength * i;
          firstDataSource = mainDataSource.slice(contentLength - 21, contentLength);
          secondDataSource = [];

          firstDataSource.forEach(cell => {
            secondDataSource.push({'Matching Grant': cell['Matching Grant']})

            delete cell['Matching Grant'];
          });

          if(contentLength > mainDataSource.lengt){
            firstDataSource = mainDataSource.slice(contentLength - 21, mainDataSource.length);
          }
          if(i > 1){
            doc.addPage();
          }

          (doc as any).autoTable({ 
            head: [headerFirstTable],
            body: firstDataSource, 
            theme: 'grid',
            tableWidth: 'auto',
            headStyles: {
              fontSize: 8,
              textColor: [0, 0, 0],
              fillColor: 230, //[250, 249, 250]
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              cellPadding: { 
                bottom: 10, top: 4
              }
            },
            bodyStyles: {
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
            }
          });

          doc.addPage();
          (doc as any).autoTable({ 
            head: [headerSecondTable],
            body: secondDataSource, 
            theme: 'grid',
            tableWidth: 'auto',
            headStyles: {
              fontSize: 8,
              textColor: [0, 0, 0],
              fillColor: 230, //[250, 249, 250]
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              cellPadding: { 
                bottom: 10, top: 4
              }
            },
            bodyStyles: {
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
            }
          });
        }
        doc.save('EnrollWithdrawReport.pdf');
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
        error.message = error.message + errorLocation;
        throw error;
      }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
        error.error.message += errorLocation;
         throw error.error;
      });

    }
    else if(this.reportType == "Individual Transfer Limit"){
      let headerFirstTable = this.displayIndividualTransferLimitGenerateColumn;
      let headerSecondTable = {'Individual Transfer Limit Shares': 'Individual Transfer Limit Shares', 'Additional Shares Available Due to Transfer Limit Waiver': 'Additional Shares Available Due to Transfer Limit Waiver', 'Total Shares Sold/Transferred': 'Total Shares Sold/Transferred', 'Net Shares Eligible for Sale/Transfer in Period': 'Net Shares Eligible for Sale/Transfer in Period'};

      this.reportsService.getIndividualTransferLimitData(this.user.OptNum,this.legitDateFrom.replace(/\'/g, ""), this.legitDateTo.replace(/\'/g, ""), -99).subscribe(data => {
        let mainDataSource = data[0];        
        let lengthForLoop = mainDataSource.length/21;
        let firstDataSource;
        let secondDataSource = [];
        let contentLength, staticLength = 21;
        lengthForLoop = Math.ceil(lengthForLoop);

        let doc = new jsPDF({orientation: 'landscape'});  

        for(let i=0; i<mainDataSource.length; i++)
          {
            mainDataSource[i]["Total Unrestricted Shares at Period Start"] = this.commafy(mainDataSource[i]["Total Unrestricted Shares at Period Start"].toString());
            mainDataSource[i]["Individual Transfer Limit Shares"] = this.commafy(mainDataSource[i]["Individual Transfer Limit Shares"].toString());
            mainDataSource[i]["Additional Shares Available Due to Transfer Limit Waiver"] = this.commafy(mainDataSource[i]["Additional Shares Available Due to Transfer Limit Waiver"].toString());
            mainDataSource[i]["Total Shares Sold/Transferred"] = this.commafy(mainDataSource[i]["Total Shares Sold/Transferred"].toString());
            mainDataSource[i]["Net Shares Eligible for Sale/Transfer in Period"] = this.commafy(mainDataSource[i]["Net Shares Eligible for Sale/Transfer in Period"].toString());
          }

        mainDataSource.push({'Program': 'Totals', 'Share Type': '', 'Trading Window Period': '', 'Total Unrestricted Shares at Period Start': this.commafy(data[1][0]["Total Unrestricted Shares at Period Start"].toString()), 'Individual Transfer Limit Status': '', 'Estimated Sales Price in Effect': '', 'Redemption Method': '', 'Individual Transfer Limit Shares': this.commafy(data[1][0]["Total Individual Transfer Limit Shares"].toString()), 'Additional Shares Available Due to Transfer Limit Waiver': this.commafy(data[1][0]["Total Additional Shares Available Due to Transfer Limit Waiver"].toString()), 'Total Shares Sold/Transferred': this.commafy(data[1][0]["Total Shares Sold/Transferred"].toString()), 'Net Shares Eligible for Sale/Transfer in Period': this.commafy(data[1][0]["Net Shares Eligible for Sale/Transfer in Period"].toString())})
        try {
          for(let i=1; i<=lengthForLoop; i++){
            contentLength = staticLength*i;
            firstDataSource = mainDataSource.slice(contentLength-21, contentLength);
            secondDataSource = [];
            
            firstDataSource.forEach( cell => {
              secondDataSource.push({'Individual Transfer Limit Shares': cell['Individual Transfer Limit Shares'], 'Additional Shares Available Due to Transfer Limit Waiver': cell['Additional Shares Available Due to Transfer Limit Waiver'], 'Total Shares Sold/Transferred': cell['Total Shares Sold/Transferred'], 'Net Shares Eligible for Sale/Transfer in Period': cell['Net Shares Eligible for Sale/Transfer in Period']})
  
              delete cell['Individual Transfer Limit Shares'];
              delete cell['Additional Shares Available Due to Transfer Limit Waiver'];
              delete cell['Total Shares Sold/Transferred'];
              delete cell['Net Shares Eligible for Sale/Transfer in Period'];
            });
            
            if(contentLength > mainDataSource.length){
              firstDataSource = mainDataSource.slice(contentLength-21, mainDataSource.length);
            }

            if(i>1){
              doc.addPage();
            }

            (doc as any).autoTable({
              head: [headerFirstTable],
              body: firstDataSource, 
              theme: 'grid',
              tableWidth: 'auto',
              headStyles: {
                fontSize: 8,
                textColor: [0, 0, 0],
                fillColor: 230, //[250, 249, 250]
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                cellPadding: {
                  bottom: 10, top: 4
                }
              },
              bodyStyles: {
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              }

            });
            doc.addPage();

            (doc as any).autoTable({
              head: [headerSecondTable],
              body: secondDataSource, 
              theme: 'grid',
              tableWidth: 'auto',
              headStyles: {
                fontSize: 8,
                textColor: [0, 0, 0],
                fillColor: 230, //[250, 249, 250]
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                cellPadding: {
                  bottom: 10, top: 4
                }
              },
              bodyStyles: {
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              }
            });
          }
          doc.save('IndividualTransferLimit.pdf');
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
        error.message = error.message + errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
      error.error.message += errorLocation;
      throw error.error;
    });
    }
    else if(this.reportType == "Purchase Program"){

      this.reportsService.getPurchaseProgramData(this.user.OptNum, this.programType, this.legitDateFrom, this.legitDateTo, -99).subscribe(data => {
        try{
          let currencyCode = data[0][0]["LocalCurrencyCd"];
          // let mainDataSource = data[0];
          let mainDataSource = data[0].sort((a, b) => a["PURCHASE DATE"] < b["PURCHASE DATE"] ? 1 : a["PURCHASE DATE"] > b["PURCHASE DATE"] ? -1 : 0);
          let lengthForLoop = mainDataSource.length/21;
          let firstDataSource;
          
          let secondDataSource = [];
          let thirdDataSource = [];
          let contentLength, staticLength = 21;
          lengthForLoop = Math.ceil(lengthForLoop);
          this.user = JSON.parse(sessionStorage.getItem(this.globalVariables.profileInfoKey));
  
          let doc = new jsPDF({orientation: 'landscape'});  

        if(this.programType == "VEIP")
        {
          let headerFirstTable = this.displayPurchaseProgramVEIPGenerateColumn;
          let headerSecondTable = {"Purchase Price FMV (USD)":"Purchase Price FMV (USD)", "Total Shares Purchased":"Total Shares Purchased", "Total Value of Shares Purchased (USD)":"Total Value of Shares Purchased (USD)", "Total Value of Shares Purchased (Local)":"Total Value of Shares Purchased (Local)", "Contribution to be Carried Over/Refunded (USD)":"Contribution to be Carried Over/Refunded (USD)"};
          let headerThirdTable = {"Contribution to be Carried Over/Refunded (Local)":"Contribution to be Carried Over/Refunded (Local)", "Shares Disposed":"Shares Disposed", "Shares Available":"Shares Available", "Broker for More Information":"Broker for More Information"};

          for(let i=0; i<mainDataSource.length; i++){
            mainDataSource[i]["Contribution %"] = mainDataSource[i]["Contribution %"].toString() + "%";
            mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (USD)"] = parseFloat(mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (USD)"]).toFixed(2);
            mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (USD)"] = this.commafy(mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (USD)"], 2);
            mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"] = parseFloat(mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"]).toFixed(2);
            // mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"] = currencyCode + ' ' + this.commafy(mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"], 2);
            mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"] = this.commafy(mainDataSource[i]["LocalCurrencyCd"]) + ' ' + this.commafy(mainDataSource[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"], 2);
            mainDataSource[i]["Exchange Rate"] = parseFloat(mainDataSource[i]["Exchange Rate"]).toFixed(2);
            mainDataSource[i]["Exchange Rate"] = this.commafy(mainDataSource[i]["Exchange Rate"], 2);
            mainDataSource[i]["Total Value of Shares Purchased (USD)"] = mainDataSource[i]["Total Value of Shares Purchased (USD)"].toFixed(4);
            mainDataSource[i]["Total Value of Shares Purchased (USD)"] = this.commafy(mainDataSource[i]["Total Value of Shares Purchased (USD)"], 4);
            mainDataSource[i]["Total Value of Shares Purchased (Local)"] = mainDataSource[i]["Total Value of Shares Purchased (Local)"].toFixed(2);
            // mainDataSource[i]["Total Value of Shares Purchased (Local)"] = currencyCode + ' ' + this.commafy(mainDataSource[i]["Total Value of Shares Purchased (Local)"], 2);
            mainDataSource[i]["Total Value of Shares Purchased (Local)"] = this.commafy(mainDataSource[i]["LocalCurrencyCd"]) + ' ' + this.commafy(mainDataSource[i]["Total Value of Shares Purchased (Local)"], 2);
            mainDataSource[i]["Contribution to be Carried Over/Refunded (USD)"] = mainDataSource[i]["Contribution to be Carried Over/Refunded (USD)"].toFixed(2);
            mainDataSource[i]["Contribution to be Carried Over/Refunded (USD)"] = this.commafy(mainDataSource[i]["Contribution to be Carried Over/Refunded (USD)"], 2);
            mainDataSource[i]["Contribution to be Carried Over/Refunded (Local)"] = mainDataSource[i]["Contribution to be Carried Over/Refunded (Local)"].toFixed(2);
            // mainDataSource[i]["Contribution to be Carried Over/Refunded (Local)"] = currencyCode + ' ' + this.commafy(mainDataSource[i]["Contribution to be Carried Over/Refunded (Local)"], 2);
            mainDataSource[i]["Contribution to be Carried Over/Refunded (Local)"] = this.commafy(mainDataSource[i]["LocalCurrencyCd"]) + ' ' + this.commafy(mainDataSource[i]["Contribution to be Carried Over/Refunded (Local)"], 2);
            mainDataSource[i]["Purchase Price FMV (USD)"] = mainDataSource[i]["Purchase Price FMV (USD)"].toFixed(4);
            mainDataSource[i]["Purchase Price FMV (USD)"] = this.commafy(mainDataSource[i]["Purchase Price FMV (USD)"], 4);
            mainDataSource[i]["Total Shares Purchased"] = this.commafy(mainDataSource[i]["Total Shares Purchased"]);
            mainDataSource[i]["Shares Disposed"] = this.commafy(mainDataSource[i]["Shares Disposed"]);
            mainDataSource[i]["Shares Available"] = this.commafy(mainDataSource[i]["Shares Available"]);
        }

        data[1][0]["Total Contribution %"] = " ";
        data[1][0]["Total Exchange Rate"] = " ";
        // data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"] = data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"].toFixed(2);
        // data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"] = currencyCode + ' ' + this.commafy(data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"], 2);
        data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"] = " ";
        data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"] = data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"].toFixed(2);
        data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"] = this.commafy(data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"], 2);
        data[1][0]["Total Purchase Price FMV (USD)"] = data[1][0]["Total Purchase Price FMV (USD)"].toFixed(4);
        data[1][0]["Total Purchase Price FMV (USD)"] = this.commafy(data[1][0]["Total Purchase Price FMV (USD)"],4);
        data[1][0]["Total Shares Purchased"] = this.commafy(data[1][0]["Total Shares Purchased"]);
        data[1][0]["Total Value of Shares Purchased (USD)"] = data[1][0]["Total Value of Shares Purchased (USD)"].toFixed(4);
        data[1][0]["Total Value of Shares Purchased (USD)"] = this.commafy(data[1][0]["Total Value of Shares Purchased (USD)"],4);
        // data[1][0]["Total Value of Shares Purchased (Local)"] = data[1][0]["Total Value of Shares Purchased (Local)"].toFixed(2);
        // data[1][0]["Total Value of Shares Purchased (Local)"] = currencyCode + ' ' + this.commafy(data[1][0]["Total Value of Shares Purchased (Local)"],2);
        data[1][0]["Total Value of Shares Purchased (Local)"] = " ";
        data[1][0]["Total Contribution to be Carried Over/Refunded (USD)"] = data[1][0]["Total Contribution to be Carried Over/Refunded (USD)"].toFixed(2);
        data[1][0]["Total Contribution to be Carried Over/Refunded (USD)"] = this.commafy(data[1][0]["Total Contribution to be Carried Over/Refunded (USD)"],2);
        // data[1][0]["Total Contribution to be Carried Over/Refunded (Local)"] = data[1][0]["Total Contribution to be Carried Over/Refunded (Local)"].toFixed(2);
        // data[1][0]["Total Contribution to be Carried Over/Refunded (Local)"] = currencyCode + ' ' + this.commafy(data[1][0]["Total Contribution to be Carried Over/Refunded (Local)"],2);
        data[1][0]["Total Contribution to be Carried Over/Refunded (Local)"] = " ";
        data[1][0]["Total Shares Disposed"] = this.commafy(data[1][0]["Total Shares Disposed"]);
        data[1][0]["Total Shares Available"] = this.commafy(data[1][0]["Total Shares Available"]);
        
        mainDataSource.push({"Program":"Totals", "Purchase Date":"", "Grant ID":"", "Contribution %":"", "Monthly Contribution Plus Prior Month's Carryover (Local)": data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"], "Exchange Rate":"", "Monthly Contribution Plus Prior Month's Carryover (USD)": data[1][0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"], "Purchase Price FMV (USD)": data[1][0]["Total Purchase Price FMV (USD)"], "Total Shares Purchased": data[1][0]["Total Shares Purchased"], "Total Value of Shares Purchased (USD)": data[1][0]["Total Value of Shares Purchased (USD)"], "Total Value of Shares Purchased (Local)": data[1][0]["Total Value of Shares Purchased (Local)"], "Contribution to be Carried Over/Refunded (USD)": data[1][0]["Total Contribution to be Carried Over/Refunded (USD)"], "Contribution to be Carried Over/Refunded (Local)": data[1][0]["Total Contribution to be Carried Over/Refunded (Local)"], "Shares Disposed": data[1][0]["Total Shares Disposed"], "Shares Available": data[1][0]["Total Shares Available"], "Broker for More Information":""});

        firstDataSource = mainDataSource.slice(0, mainDataSource.length);
          for(let i=1; i<=lengthForLoop; i++){
            contentLength = staticLength*i;
            firstDataSource = mainDataSource.slice(contentLength-21, contentLength);
            secondDataSource = [];
            thirdDataSource = [];
            
            firstDataSource.forEach( cell => {
              secondDataSource.push({'Purchase Price FMV (USD)': cell['Purchase Price FMV (USD)'], 'Total Shares Purchased': cell['Total Shares Purchased'], 'Total Value of Shares Purchased (USD)': cell['Total Value of Shares Purchased (USD)'], 'Total Value of Shares Purchased (Local)': cell['Total Value of Shares Purchased (Local)'], 'Contribution to be Carried Over/Refunded (USD)': cell['Contribution to be Carried Over/Refunded (USD)']});
              thirdDataSource.push({'Contribution to be Carried Over/Refunded (Local)': cell['Contribution to be Carried Over/Refunded (Local)'], 'Shares Disposed': cell['Shares Disposed'], 'Shares Available': cell['Shares Available'], 'Broker for More Information': cell['Broker for More Information']});

              delete cell.OptNum;
              delete cell.LocalCurrencyCd;
              delete cell['Purchase Price FMV (USD)'];
              delete cell['Total Shares Purchased'];
              delete cell['Total Value of Shares Purchased (USD)'];
              delete cell['Total Value of Shares Purchased (Local)'];
              delete cell['Contribution to be Carried Over/Refunded (USD)'];
              delete cell['Contribution to be Carried Over/Refunded (Local)'];
              delete cell['Shares Disposed'];
              delete cell['Shares Available'];
              delete cell['Broker for More Information'];
            });
            
            if(contentLength > mainDataSource.length){
              firstDataSource = mainDataSource.slice(contentLength-21, mainDataSource.length);
            }

            if(i>1){
              doc.addPage();
            }

            (doc as any).autoTable({
              head: [headerFirstTable],
              body: firstDataSource, 
              theme: 'grid',
              tableWidth: 'auto',
              headStyles: {
                fontSize: 8,
                textColor: [0, 0, 0],
                fillColor: 230, //[250, 249, 250]
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                cellPadding: {
                  bottom: 10, top: 4
                }
              },
              bodyStyles: {
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              }
            });

            doc.addPage();
            (doc as any).autoTable({
              head: [headerSecondTable],
              body: secondDataSource, 
              theme: 'grid',
              tableWidth: 'auto',
              headStyles: {
                fontSize: 8,
                textColor: [0, 0, 0],
                fillColor: 230, //[250, 249, 250]
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                cellPadding: {
                  bottom: 10, top: 4
                }
              },
              bodyStyles: {
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              }
            });

            doc.addPage();
            (doc as any).autoTable({
              head: [headerThirdTable],
              body: thirdDataSource, 
              theme: 'grid',
              tableWidth: 'auto',
              headStyles: {
                fontSize: 8,
                textColor: [0, 0, 0],
                fillColor: 230, //[250, 249, 250]
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                cellPadding: {
                  bottom: 10, top: 4
                }
              },
              bodyStyles: {
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              }
            });
          } 
          doc.save('VeipPurchaseReport.pdf');
        }
        else if(this.programType == "ESPP")
        {
          let headerFirstTable = this.displayPurchaseProgramESPPGenerateColumn;
          let headerSecondTable = {"FMV at Purchase (USD)":"FMV at Purchase (USD)", "FMV Value of Shares Purchased (Cost Basis) (Loc)":"FMV Value of Shares Purchased (Cost Basis) (Loc)", "Value of Discount Received (Loc)":"Value of Discount Received (Loc)", "Shares Sold for Tax Withholding":"Shares Sold for Tax Withholding", "Value of Shares Sold for Tax Withholding (Loc)":"Value of Shares Sold for Tax Withholding (Loc)"};
          let headerThirdTable = {"Fractional Shares Sold for Refund":"Fractional Shares Sold for Refund", "Value of Fractional Shares Sold for Refund (Loc)": "Value of Fractional Shares", "Shares Sold/Disposed":"Shares Sold/Disposed", "Shares Available":"Shares Available", "Account":"Account", "Broker for More Information":"Broker for More Information", "Actual tax reported by Payroll (in Loc)":"Actual tax reported by Payroll (in Loc)"};


          for(let i=0; i<mainDataSource.length; i++)
          {
            mainDataSource[i]["Contribution %"] = mainDataSource[i]["Contribution %"].toString() + "%";
            mainDataSource[i]["Total Contributions (Loc)"] = parseFloat(mainDataSource[i]["Total Contributions (Loc)"].toFixed(2));
            // mainDataSource[i]["Total Contributions (Loc)"] = currencyCode + ' ' + this.commafy(mainDataSource[i]["Total Contributions (Loc)"].toString(),2);
            mainDataSource[i]["Total Contributions (Loc)"] = this.commafy(mainDataSource[i]["LocalCurrencyCd"]) + ' ' + this.commafy(mainDataSource[i]["Total Contributions (Loc)"].toString(),2);
            mainDataSource[i]["Total Contributions (USD)"] = parseFloat(mainDataSource[i]["Total Contributions (USD)"].toFixed(2));
            mainDataSource[i]["Total Contributions (USD)"] = this.commafy(mainDataSource[i]["Total Contributions (USD)"].toString(),2);
            mainDataSource[i]["Discounted Purchase Price (USD)"] = parseFloat(mainDataSource[i]["Discounted Purchase Price (USD)"].toFixed(4))
            mainDataSource[i]["Discounted Purchase Price (USD)"] = this.commafy(mainDataSource[i]["Discounted Purchase Price (USD)"].toString(),4);
            mainDataSource[i]["Total Shares Purchased"] = parseFloat(mainDataSource[i]["Total Shares Purchased"].toFixed(4));
            mainDataSource[i]["Total Shares Purchased"] = this.commafy(mainDataSource[i]["Total Shares Purchased"].toString(),4);
            mainDataSource[i]["FMV at Purchase (USD)"] = parseFloat(mainDataSource[i]["FMV at Purchase (USD)"].toFixed(4));
            mainDataSource[i]["FMV at Purchase (USD)"] = this.commafy(mainDataSource[i]["FMV at Purchase (USD)"].toString(),4);
            mainDataSource[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"] = parseFloat(mainDataSource[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"].toFixed(4));
            // mainDataSource[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"] = currencyCode + ' ' + this.commafy(mainDataSource[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"].toString(),4);
            mainDataSource[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"] = this.commafy(mainDataSource[i]["LocalCurrencyCd"]) + ' ' + this.commafy(mainDataSource[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"].toString(),4);
            mainDataSource[i]["Value of Discount Received (Loc)"] = parseFloat(mainDataSource[i]["Value of Discount Received (Loc)"].toFixed(2));
            mainDataSource[i]["Value of Discount Received (Loc)"] = this.commafy(mainDataSource[i]["LocalCurrencyCd"]) + ' ' + this.commafy(mainDataSource[i]["Value of Discount Received (Loc)"].toString(),2);
            mainDataSource[i]["Shares Sold for Tax Withholding"] = parseFloat(mainDataSource[i]["Shares Sold for Tax Withholding"].toFixed(4));
            mainDataSource[i]["Shares Sold for Tax Withholding"] = this.commafy(mainDataSource[i]["Shares Sold for Tax Withholding"].toString(),4);
            mainDataSource[i]["Value of Shares Sold for Tax Withholding (Loc)"] = parseFloat(mainDataSource[i]["Value of Shares Sold for Tax Withholding (Loc)"].toFixed(2));
            // mainDataSource[i]["Value of Shares Sold for Tax Withholding (Loc)"] = currencyCode + ' ' + this.commafy(mainDataSource[i]["Value of Shares Sold for Tax Withholding (Loc)"].toString(),2);
            mainDataSource[i]["Value of Shares Sold for Tax Withholding (Loc)"] = this.commafy(mainDataSource[i]["LocalCurrencyCd"]) + ' ' + this.commafy(mainDataSource[i]["Value of Shares Sold for Tax Withholding (Loc)"].toString(),2);
            mainDataSource[i]["Shares Sold/Disposed"] = parseFloat(mainDataSource[i]["Shares Sold/Disposed"].toFixed());
            mainDataSource[i]["Shares Sold/Disposed"] = this.commafy(mainDataSource[i]["Shares Sold/Disposed"].toString());
            mainDataSource[i]["Value of Fractional Shares Sold for Refund (Loc)"] = parseFloat(mainDataSource[i]["Value of Fractional Shares Sold for Refund (Loc)"].toFixed(2));
            // mainDataSource[i]["Value of Fractional Shares Sold for Refund (Loc)"] = currencyCode + ' ' + this.commafy(mainDataSource[i]["Value of Fractional Shares Sold for Refund (Loc)"].toString(),2);
            mainDataSource[i]["Value of Fractional Shares Sold for Refund (Loc)"] = this.commafy(mainDataSource[i]["LocalCurrencyCd"]) + ' ' + this.commafy(mainDataSource[i]["Value of Fractional Shares Sold for Refund (Loc)"].toString(),2);
            mainDataSource[i]["Contribution %"] = mainDataSource[i]["Contribution %"].toString() + "%";
            mainDataSource[i]["Fractional Shares Sold for Refund"] = parseFloat(mainDataSource[i]["Fractional Shares Sold for Refund"].toFixed(4));
            mainDataSource[i]["Fractional Shares Sold for Refund"] = this.commafy(mainDataSource[i]["Fractional Shares Sold for Refund"].toString(),4);
            mainDataSource[i]["Shares Available"] = parseFloat(mainDataSource[i]["Shares Available"].toFixed());
            mainDataSource[i]["Shares Available"] = this.commafy(mainDataSource[i]["Shares Available"].toString());
            if(mainDataSource[i]["Actual tax reported by Payroll (in Loc)"] != null) 
            {
              mainDataSource[i]["Actual tax reported by Payroll (in Loc)"] = this.commafy(mainDataSource[i]["LocalCurrencyCd"]) + ' ' + this.commafy(mainDataSource[i]["Actual tax reported by Payroll (in Loc)"].toString(),2);
            }
            else
            {
              mainDataSource[i]["Actual tax reported by Payroll (in Loc)"] = " ";
            }
          }

          data[1][0]["Total Contribution %"] = " ";
          data[1][0]["Total Exchange Rate"] = " ";
          // data[1][0]["Total Actual tax reported by Payroll (in Loc)"] = this.commafy(data[1][0]["Total Actual tax reported by Payroll (in Loc)"], 2);
          data[1][0]["Total Actual tax reported by Payroll (in Loc)"] = " ";
          // data[1][0]["Total Contributions (Loc)"] = parseFloat(data[1][0]["Total Contributions (Loc)"].toFixed(2));
          // data[1][0]["Total Contributions (Loc)"] = currencyCode + ' ' + this.commafy(data[1][0]["Total Contributions (Loc)"].toString(),2);
          data[1][0]["Total Contributions (Loc)"] = " ";
          data[1][0]["Total Contributions (USD)"] = parseFloat(data[1][0]["Total Contributions (USD)"].toFixed(2));
          data[1][0]["Total Contributions (USD)"] = this.commafy(data[1][0]["Total Contributions (USD)"].toString(),2);
          data[1][0]["Total Discounted Purchase Price (USD)"] = parseFloat(data[1][0]["Total Discounted Purchase Price (USD)"].toFixed(4));
          data[1][0]["Total Discounted Purchase Price (USD)"] = this.commafy(data[1][0]["Total Discounted Purchase Price (USD)"].toString(),4);
          // data[1][0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"] = parseFloat(data[1][0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"].toFixed(4));
          // data[1][0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"] = currencyCode + ' ' + this.commafy(data[1][0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"].toString(),4);
          data[1][0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"] = " ";
          data[1][0]["Total FMV at Purchase (USD)"] = parseFloat(data[1][0]["Total FMV at Purchase (USD)"].toFixed(4));
          data[1][0]["Total FMV at Purchase (USD)"] = this.commafy(data[1][0]["Total FMV at Purchase (USD)"].toString(),4);
          data[1][0]["Total Fractional Shares Sold for Refund"] = parseFloat(data[1][0]["Total Fractional Shares Sold for Refund"].toFixed(4));
          data[1][0]["Total Fractional Shares Sold for Refund"] = this.commafy(data[1][0]["Total Fractional Shares Sold for Refund"].toString(),4);
          data[1][0]["Total Shares Available"] = parseFloat(data[1][0]["Total Shares Available"].toFixed());
          data[1][0]["Total Shares Available"] = this.commafy(data[1][0]["Total Shares Available"].toString());
          data[1][0]["Total Shares Purchased"] = parseFloat(data[1][0]["Total Shares Purchased"].toFixed(4));
          data[1][0]["Total Shares Purchased"] = this.commafy(data[1][0]["Total Shares Purchased"].toString(),4);
          data[1][0]["Total Shares Sold for Tax Withholding"] = parseFloat(data[1][0]["Total Shares Sold for Tax Withholding"].toFixed(4));
          data[1][0]["Total Shares Sold for Tax Withholding"] = this.commafy(data[1][0]["Total Shares Sold for Tax Withholding"].toString(),4);
          data[1][0]["Total Shares Sold/Disposed"] = parseFloat(data[1][0]["Total Shares Sold/Disposed"].toFixed());
          data[1][0]["Total Shares Sold/Disposed"] = this.commafy(data[1][0]["Total Shares Sold/Disposed"].toString());
          // data[1][0]["Total Value of Discount Received (Loc)"] = parseFloat(data[1][0]["Total Value of Discount Received (Loc)"].toFixed(2));
          // data[1][0]["Total Value of Discount Received (Loc)"] = this.commafy(data[1][0]["Total Value of Discount Received (Loc)"].toString(),2);
          data[1][0]["Total Value of Discount Received (Loc)"] = " ";
          // data[1][0]["Total Value of Fractional Shares Sold for Refund (Loc)"] = parseFloat(data[1][0]["Total Value of Fractional Shares Sold for Refund (Loc)"].toFixed(2));
          // data[1][0]["Total Value of Fractional Shares Sold for Refund (Loc)"] = currencyCode + ' ' + this.commafy(data[1][0]["Total Value of Fractional Shares Sold for Refund (Loc)"].toString(), 2);
          data[1][0]["Total Value of Fractional Shares Sold for Refund (Loc)"] =  " ";
          // data[1][0]["Total Value of Shares Sold for Tax Withholding (Loc)"] = parseFloat(data[1][0]["Total Value of Shares Sold for Tax Withholding (Loc)"].toFixed(2));
          // data[1][0]["Total Value of Shares Sold for Tax Withholding (Loc)"] = currencyCode + ' ' + this.commafy(data[1][0]["Total Value of Shares Sold for Tax Withholding (Loc)"].toString(),2);
          data[1][0]["Total Value of Shares Sold for Tax Withholding (Loc)"] = " ";

          mainDataSource.push({"Program":"Totals", "Offering Period": "", "Contribution %": "", "Total Contributions (Loc)": data[1][0]["Total Contributions (Loc)"], "Exchange Rate":"", "Total Contributions (USD)": data[1][0]["Total Contributions (USD)"], "Discounted Purchase Price (USD)": "", "Total Shares Purchased": data[1][0]["Total Shares Purchased"], "FMV at Purchase (USD)": "", "FMV Value of Shares Purchased (Cost Basis) (Loc)": data[1][0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"], "Value of Discount Received (Loc)": data[1][0]["Total Value of Discount Received (Loc)"], "Shares Sold for Tax Withholding": data[1][0]["Total Shares Sold for Tax Withholding"], "Value of Shares Sold for Tax Withholding (Loc)": data[1][0]["Total Value of Shares Sold for Tax Withholding (Loc)"], "Fractional Shares Sold for Refund": data[1][0]["Total Fractional Shares Sold for Refund"], "Value of Fractional Shares Sold for Refund (Loc)": data[1][0]["Total Value of Fractional Shares Sold for Refund (Loc)"], "Shares Sold/Disposed": data[1][0]["Total Shares Sold/Disposed"], "Shares Available": data[1][0]["Total Shares Available"], "Account": "", "Broker for More Information": "", "Actual tax reported by Payroll (in Loc)": data[1][0]["Total Actual tax reported by Payroll (in Loc)"]});

          firstDataSource = mainDataSource.slice(0, mainDataSource.length);

          for(let i=1; i<=lengthForLoop; i++){
            contentLength = staticLength*i;
            firstDataSource = mainDataSource.slice(contentLength-21, contentLength);
            secondDataSource = [];
            thirdDataSource = [];

            firstDataSource.forEach( cell => {
              secondDataSource.push({'FMV at Purchase (USD)': cell['FMV at Purchase (USD)'], 'FMV Value of Shares Purchased (Cost Basis) (Loc)': cell['FMV Value of Shares Purchased (Cost Basis) (Loc)'], 'Value of Discount Received (Loc)': cell['Value of Discount Received (Loc)'], 'Shares Sold for Tax Withholding': cell['Shares Sold for Tax Withholding'], 'Value of Shares Sold for Tax Withholding (Loc)': cell['Value of Shares Sold for Tax Withholding (Loc)']});
              thirdDataSource.push({'Fractional Shares Sold for Refund': cell['Fractional Shares Sold for Refund'], 'Value of Fractional Shares Sold for Refund (Loc)': cell['Value of Fractional Shares Sold for Refund (Loc)'], 'Shares Sold/Disposed': cell['Shares Sold/Disposed'], 'Shares Available': cell['Shares Available'], 'Account': cell['Account'], 'Broker for More Information': cell['Broker for More Information'], 'Actual tax reported by Payroll (in Loc)': cell['Actual tax reported by Payroll (in Loc)']});

              delete cell.OptNum;
              delete cell.PurchaseDt;
              delete cell.LocalCurrencyCd;
              delete cell['FMV at Purchase (USD)'];
              delete cell['FMV Value of Shares Purchased (Cost Basis) (Loc)'];
              delete cell['Value of Discount Received (Loc)'];
              delete cell['Shares Sold for Tax Withholding'];
              delete cell['Value of Shares Sold for Tax Withholding (Loc)'];
              delete cell['Fractional Shares Sold for Refund'];
              delete cell['Value of Fractional Shares Sold for Refund (Loc)'];
              delete cell['Shares Sold/Disposed'];
              delete cell['Shares Available'];
              delete cell['Account'];
              delete cell['Broker for More Information'];
              delete cell['Actual tax reported by Payroll (in Loc)'];
            });

            if(contentLength > mainDataSource.length){
              firstDataSource = mainDataSource.slice(contentLength-21, mainDataSource.length);
            }

            if(i>1){
              doc.addPage();
            }

            (doc as any).autoTable({
              head: [headerFirstTable],
              body: firstDataSource, 
              theme: 'grid',
              tableWidth: 'auto',
              headStyles: {
                fontSize: 8,
                textColor: [0, 0, 0],
                fillColor: 230, //[250, 249, 250]
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                cellPadding: {
                  bottom: 10, top: 4
                }
              },
              bodyStyles: {
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              }
            });

            doc.addPage();
            (doc as any).autoTable({
              head: [headerSecondTable],
              body: secondDataSource, 
              theme: 'grid',
              tableWidth: 'auto',
              headStyles: {
                fontSize: 8,
                textColor: [0, 0, 0],
                fillColor: 230, //[250, 249, 250]
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                cellPadding: {
                  bottom: 10, top: 4
                }
              },
              bodyStyles: {
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              }
            });

            doc.addPage();
            (doc as any).autoTable({
              head: [headerThirdTable],
              body: thirdDataSource, 
              theme: 'grid',
              tableWidth: 'auto',
              headStyles: {
                fontSize: 8,
                textColor: [0, 0, 0],
                fillColor: 230, //[250, 249, 250]
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                cellPadding: {
                  bottom: 10, top: 4
                }
              },
              bodyStyles: {
                halign: 'center',
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
              },
              didParseCell: element => {
                if(element.cell.raw == "Fraction Shares Sold for Refund"){
                  element.cell.text[0] = "Fraction Shares";
                  element.cell.text[1] = "Sold for Refund";
                }
    
                if(element.cell.raw == "Value of Fractional Shares"){
                  element.cell.text[0] = "Value of Fractional Shares";
                  element.cell.text[1] = "Sold for Refund (Loc)";
                }
              } 
            });
          }
          doc.save('EsppPurchaseReport.pdf');
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
        error.message = error.message + errorLocation;
        throw error;
      }
      });
    }
    else if(this.reportType == "Sales History"){
      //'Program': 'Program', 'Award Details': 'Award Details', 'Transaction Date': 'Transaction Date', 'Transaction Type': 'Transaction Type', 'Number of Shares in Transaction': 'Number of Shares in Transaction', 'Sale Price (USD)': 'Sale Price (USD)', 'Gross Proceeds (USD)': 'Gross Proceeds (USD)', 'Original Purchase Date': 'Original Purchase Date', 'Purchase Date FMV per Share(USD)': 'Purchase Date FMV per Share(USD)', 'Purchase Date Value (USD)': 'Purchase Date Value (USD)', 'Gain/(Loss) - USD': 'Gain/(Loss) - USD', 'Gain/(Loss) - Local Currency': 'Gain/(Loss) - Local Currency', 'Value Per Share at Exercise (USD)': 'Value Per Share at Exercise (USD)', 'Total Value on Exercise Date (USD)': 'Total Value on Exercise Date (USD)', 'Total Cost on Exercise Date (USD)': 'Total Cost on Exercise Date (USD)', 'Taxable Income(USD)': 'Taxable Income(USD)', 'Estimated Taxes Withheld at Exercise (USD)': 'Estimated Taxes Withheld at Exercise (USD)', 'Estimated Proceeds (USD)': 'Estimated Proceeds (USD)', 'Broker for More Information': 'Broker for More Information'
      
      // let headerFirstTable = this.displayedSalesHistoryGenerateColumns;
      let headerFirstTable = {'Program': 'Program', 'Award Details': 'Award Details', 'Transaction Date': 'Transaction Date', 'Transaction Type': 'Transaction Type', 'Number of Shares in Transaction': 'Number of Shares in Transaction', 'Sale Price (USD)': 'Sale Price (USD)'}; //, 'Gross Proceeds (USD)': 'Gross Proceeds (USD)'};
      let headerSecondTable = {'Gross Proceeds (USD)': 'Gross Proceeds (USD)', 'Original Purchase Date': 'Original Purchase Date', 'Purchase Date FMV per Share(USD)': 'Purchase Date FMV per Share(USD)', 'Purchase Date Value (USD)': 'Purchase Date Value (USD)', 'Gain/(Loss) - USD': 'Gain/(Loss) - USD', 'Gain/(Loss) - Local Currency': 'Gain/(Loss) - Local Currency', 'Value Per Share at Exercise (USD)': 'Value Per Share at Exercise (USD)'};
      let headerThirdTable = {'Total Value on Exercise Date (USD)': 'Total Value on Exercise Date (USD)', 'Total Cost on Exercise Date (USD)': 'Total Cost on', 'Taxable Income(USD)': 'Taxable Income(USD)', 'Estimated Taxes Withheld at Exercise (USD)': 'Estimated Taxes Withheld', 'Estimated Proceeds (USD)': 'Estimated Proceeds (USD)'};
      let headerFourthTable = {'Broker for More Information': 'Broker for More Information'};


      this.legitDateFrom = this.legitDateFrom.replace(/\'/g, "");
      this.legitDateTo = this.legitDateTo.replace(/\'/g, "");
      this.legitDateFrom = moment(this.legitDateFrom).format("YYYY-MM-DD HH:mm:ss.SSS");
      this.legitDateTo = moment(this.legitDateTo).format("YYYY-MM-DD HH:mm:ss.SSS");
      let grantList = sessionStorage.getItem("salesHistGrants");
      let programList = sessionStorage.getItem("salesHistProgram");
  
      programList != null ? "" : programList = sessionStorage.getItem("salesHistoryProgramsOnLoad");
      grantList != null ? "" : grantList = sessionStorage.getItem("grantListOnLoad");



      this.reportsService.getSalesHistoryData(this.user.OptNum, this.legitDateFrom, this.legitDateTo, this.salesHistoryGrants, this.salesHistoryPrograms, -99).subscribe(data => {
        let mainDataSource = data[0];
        let lengthForLoop = mainDataSource.length/21;
        let firstDataSource;
        
        let secondDataSource = [];
        let thirdDataSource = [];
        let fourthDataSource = [];
        let contentLength, staticLength = 21;
        lengthForLoop = Math.ceil(lengthForLoop);

        let doc = new jsPDF({orientation: 'landscape'});
        let localCur = "USD";
        let localCurrency = data[0][0]["LocalCurrencyCd"];

        for(let i=0; i<mainDataSource.length; i++)
        {
          // mainDataSource[i]["Transaction Date"] = moment(mainDataSource[i]["Transaction Date"]).format("DD MMM YYYY");
  
          if(mainDataSource[i]["Original Purchase Date"] == null)
          {
            mainDataSource[i]["Original Purchase Date"] = "-";
          }

          if(mainDataSource[i]["Award Details"] == null) {
            if(mainDataSource[i]["Program"] == "VEIP" || mainDataSource[i]["Program"] == "ESPP") {
              mainDataSource[i]["Award Details"] = "-";
            }
          }
          else {
            if(mainDataSource[i]["Program"] == "VEIP" || mainDataSource[i]["Program"] == "ESPP") {
              mainDataSource[i]["Award Details"] = "-";
            }
          }

          mainDataSource[i]["Trading Volume"] = parseFloat(mainDataSource[i]["Trading Volume"]).toFixed(2);
          mainDataSource[i]["Trading Volume"] = this.commafy(mainDataSource[i]["Trading Volume"]);
         
          // mainDataSource[i]["Sale Price (USD)"] = parseFloat(mainDataSource[i]["Sale Price (USD)"]).toFixed(4);
          // mainDataSource[i]["Sale Price (USD)"] = this.commafy(mainDataSource[i]["Sale Price (USD)"].toString(),4);

        if (mainDataSource[i]["Sale Price (USD)"] != null)
        {
          mainDataSource[i]["Sale Price (USD)"] = parseFloat(mainDataSource[i]["Sale Price (USD)"]).toFixed(4);
          mainDataSource[i]["Sale Price (USD)"] = this.commafy(mainDataSource[i]["Sale Price (USD)"].toString(),4);

          if(mainDataSource[i]["Program"] == "Founder"){
            if(mainDataSource[i]["Transaction Type"] == "X Shares Redemption" || mainDataSource[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              mainDataSource[i]["Sale Price (USD)"] = "-";
            }
          }
        }
        else
        {
          if(mainDataSource[i]["Program"] == "Founder"){
            if(mainDataSource[i]["Transaction Type"] == "X Shares Redemption" || mainDataSource[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              mainDataSource[i]["Sale Price (USD)"] = "-";
            }
          }
        }


          // mainDataSource[i]["Number of Shares in Transaction"] = parseFloat(mainDataSource[i]["Number of Shares in Transaction"]).toFixed(2);
          mainDataSource[i]["Number of Shares in Transaction"] = this.commafy(mainDataSource[i]["Number of Shares in Transaction"].toString());
  
          // mainDataSource[i]["Gross Proceeds (USD)"] != null ? mainDataSource[i]["Gross Proceeds (USD)"] = this.commafy(mainDataSource[i]["Gross Proceeds (USD)"].toString(),2) : "";
          // // mainDataSource[i]["Gross Proceeds (USD)"] = this.commafy(mainDataSource[i]["Gross Proceeds (USD)"].toString(),2);
          // mainDataSource[i]["Gross Proceeds (USD)"] = parseFloat(mainDataSource[i]["Gross Proceeds (USD)"]).toFixed(2);

          if(mainDataSource[i]["Gross Proceeds (USD)"] != null)
          {
            mainDataSource[i]["Gross Proceeds (USD)"] = parseFloat(mainDataSource[i]["Gross Proceeds (USD)"]).toFixed(2);
            mainDataSource[i]["Gross Proceeds (USD)"] = this.commafy(mainDataSource[i]["Gross Proceeds (USD)"].toString(),2);
            if(mainDataSource[i]["Program"] == "Founder"){
              if(mainDataSource[i]["Transaction Type"] == "X Shares Redemption" || mainDataSource[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
                mainDataSource[i]["Gross Proceeds (USD)"] = "-";
              }
            }
          }
          else
          {
            mainDataSource[i]["Gross Proceeds (USD)"] = " ";
            if(mainDataSource[i]["Program"] == "Founder"){
              if(mainDataSource[i]["Transaction Type"] == "X Shares Redemption" || mainDataSource[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
                mainDataSource[i]["Gross Proceeds (USD)"] = "-";
              }
            }
          }
  
          // mainDataSource[i]["Gain/(Loss) - USD"] != null ? mainDataSource[i]["Gain/(Loss) - USD"] = this.commafy(mainDataSource[i]["Gain/(Loss) - USD"].toString(),2) : "";
          // // mainDataSource[i]["Gain/(Loss) - USD"] = this.commafy(mainDataSource[i]["Gain/(Loss) - USD"].toString(),2);
          // mainDataSource[i]["Gain/(Loss) - USD"] = parseFloat(mainDataSource[i]["Gain/(Loss) - USD"]).toFixed(2);
  
          if(mainDataSource[i]["Gain/(Loss) - USD"] != null)
          {
            // mainDataSource[i]["Gain/(Loss) - USD"] = this.commafy(mainDataSource[i]["Gain/(Loss) - USD"].toString(),2);
            // mainDataSource[i]["Gain/(Loss) - USD"] = parseFloat(mainDataSource[i]["Gain/(Loss) - USD"]).toFixed(2);
            mainDataSource[i]["Gain/(Loss) - USD"] = localCur + " " + this.commafy(parseFloat(mainDataSource[i]["Gain/(Loss) - USD"]).toFixed(2).toString(),2);

            if(mainDataSource[i]["Program"] == "ESPP") mainDataSource[i]["Gain/(Loss) - USD"] = " - ";
            else if(mainDataSource[i]["Program"] == "RSU" || mainDataSource[i]["Program"] == "IPORSU") mainDataSource[i]["Gain/(Loss) - USD"] = "Refer to Broker";
            // else if(mainDataSource[i]["Program"] == "Founder") mainDataSource[i]["Gain/(Loss) - USD"] = localCur + " " + mainDataSource[i]["Gain/(Loss) - USD"];
            else if(mainDataSource[i]["Program"] == "Founder"){
              if(mainDataSource[i]["Transaction Type"] == "X Shares Redemption"){
                mainDataSource[i]["Gain/(Loss) - USD"] = "-";
              }
            }
          }
          else
          {
            if(mainDataSource[i]["Program"] == "Founder"){
              if(mainDataSource[i]["Transaction Type"] == "X Shares Redemption"){
                mainDataSource[i]["Gain/(Loss) - USD"] = "-";
              }
              else{
                mainDataSource[i]["Gain/(Loss) - USD"] = localCur + " " + "0.00";
              }
            }
            else mainDataSource[i]["Gain/(Loss) - USD"] = "-";
          }
          
          // mainDataSource[i]["Gain/(Loss) - Local Currency"] != null ? mainDataSource[i]["Gain/(Loss) - Local Currency"] = this.commafy(mainDataSource[i]["Gain/(Loss) - Local Currency"].toString(),2) : "";
          // // mainDataSource[i]["Gain/(Loss) - Local Currency"] = this.commafy(mainDataSource[i]["Gain/(Loss) - Local Currency"].toString(),2);
          // mainDataSource[i]["Gain/(Loss) - Local Currency"] = parseFloat(mainDataSource[i]["Gain/(Loss) - Local Currency"]).toFixed(2);
  
          if(mainDataSource[i]["Gain/(Loss) - Local Currency"] != null)
          {
            // mainDataSource[i]["Gain/(Loss) - Local Currency"] = this.commafy(mainDataSource[i]["Gain/(Loss) - Local Currency"].toString(),2);
            // mainDataSource[i]["Gain/(Loss) - Local Currency"] = parseFloat(mainDataSource[i]["Gain/(Loss) - Local Currency"]).toFixed(2);
            mainDataSource[i]["Gain/(Loss) - Local Currency"] = localCurrency + " " + this.commafy(parseFloat(mainDataSource[i]["Gain/(Loss) - Local Currency"]).toFixed(2).toString(),2);

            if(mainDataSource[i]["Program"] == "ESPP") mainDataSource[i]["Gain/(Loss) - Local Currency"] = " - ";
            else if(mainDataSource[i]["Program"] == "RSU" || mainDataSource[i]["Program"] == "IPORSU") mainDataSource[i]["Gain/(Loss) - Local Currency"] = "Refer to Broker";
            // else if(mainDataSource[i]["Program"] == "Founder") mainDataSource[i]["Gain/(Loss) - Local Currency"] = mainDataSource[i]["Gain/(Loss) - Local Currency"];
            else if(mainDataSource[i]["Program"] == "Founder"){
              if(mainDataSource[i]["Transaction Type"] == "X Shares Redemption"){
                mainDataSource[i]["Gain/(Loss) - Local Currency"] = "-";
              }
            }
          }
          else
          {
            // if(mainDataSource[i]["Program"] == "Founder") mainDataSource[i]["Gain/(Loss) - Local Currency"] = "0.00";
            if(mainDataSource[i]["Program"] == "Founder"){
              if(mainDataSource[i]["Transaction Type"] == "X Shares Redemption"){
                mainDataSource[i]["Gain/(Loss) - Local Currency"] = "-";
              }
              else{
                mainDataSource[i]["Gain/(Loss) - Local Currency"] = "0.00";
              }
            }
            else mainDataSource[i]["Gain/(Loss) - Local Currency"] = "-";
          }
  
          mainDataSource[i]["Purchase Date FMV per Share(USD)"] != null ? mainDataSource[i]["Purchase Date FMV per Share(USD)"] = this.commafy(mainDataSource[i]["Purchase Date FMV per Share(USD)"].toString(),4) : mainDataSource[i]["Purchase Date FMV per Share(USD)"] = "-";
          // mainDataSource[i]["Purchase Date FMV per Share(USD)"] = this.commafy(mainDataSource[i]["Purchase Date FMV per Share(USD)"].toString(),2);
          // mainDataSource[i]["Purchase Date FMV per Share(USD)"] = parseFloat(mainDataSource[i]["Purchase Date FMV per Share(USD)"]).toFixed(2);
          
          mainDataSource[i]["Purchase Date Value (USD)"] != null ? mainDataSource[i]["Purchase Date Value (USD)"] = this.commafy(mainDataSource[i]["Purchase Date Value (USD)"].toString(),2) : mainDataSource[i]["Purchase Date Value (USD)"] = "-";
          // mainDataSource[i]["Purchase Date Value (USD)"] = this.commafy(mainDataSource[i]["Purchase Date Value (USD)"].toString(),2);
          // mainDataSource[i]["Purchase Date Value (USD)"] = parseFloat(mainDataSource[i]["Purchase Date Value (USD)"]).toFixed(2);
  
          // mainDataSource[i]["Value Per Share at Exercise (USD)"] != null ? mainDataSource[i]["Value Per Share at Exercise (USD)"] = this.commafy(mainDataSource[i]["Value Per Share at Exercise (USD)"].toString(),2) : mainDataSource[i]["Value Per Share at Exercise (USD)"] = "-";
          // // mainDataSource[i]["Value Per Share at Exercise (USD)"] = this.commafy(mainDataSource[i]["Value Per Share at Exercise (USD)"].toString(),2);
          // mainDataSource[i]["Value Per Share at Exercise (USD)"] = parseFloat(mainDataSource[i]["Value Per Share at Exercise (USD)"]).toFixed(2);
  
          if(mainDataSource[i]["Value Per Share at Exercise (USD)"] != null)
          {
            // mainDataSource[i]["Value Per Share at Exercise (USD)"] = this.commafy(mainDataSource[i]["Value Per Share at Exercise (USD)"].toString(),2);
            mainDataSource[i]["Value Per Share at Exercise (USD)"] = parseFloat(this.commafy(mainDataSource[i]["Value Per Share at Exercise (USD)"].toString(),2)).toFixed(2);
            if(mainDataSource[i]["Program"] == "RSU" || mainDataSource[i]["Program"] == "IPORSU" || mainDataSource[i]["Program"] == "Founder") 
              mainDataSource[i]["Value Per Share at Exercise (USD)"] = "-";

          }
          else
          {
            mainDataSource[i]["Value Per Share at Exercise (USD)"] = "-";
          }
  
  
          // mainDataSource[i]["Total Value on Exercise Date (USD)"] != null ? mainDataSource[i]["Total Value on Exercise Date (USD)"] = this.commafy(mainDataSource[i]["Total Value on Exercise Date (USD)"].toString(),2) : mainDataSource[i]["Total Value on Exercise Date (USD)"] = "-";
          // // mainDataSource[i]["Total Value on Exercise Date (USD)"] = this.commafy(mainDataSource[i]["Total Value on Exercise Date (USD)"].toString(),2);
          // mainDataSource[i]["Total Value on Exercise Date (USD)"] = parseFloat(mainDataSource[i]["Total Value on Exercise Date (USD)"]).toFixed(2);
  
          if(mainDataSource[i]["Total Value on Exercise Date (USD)"] != null)
          {
            // mainDataSource[i]["Total Value on Exercise Date (USD)"] = this.commafy(mainDataSource[i]["Total Value on Exercise Date (USD)"].toString(),2);
            mainDataSource[i]["Total Value on Exercise Date (USD)"] = parseFloat(this.commafy(mainDataSource[i]["Total Value on Exercise Date (USD)"].toString(),2)).toFixed(2);

            if(mainDataSource[i]["Program"] == "RSU" || mainDataSource[i]["Program"] == "IPORSU" || mainDataSource[i]["Program"] == "Founder") 
              mainDataSource[i]["Total Value on Exercise Date (USD)"] = "-";
          }
          else
          {
            mainDataSource[i]["Total Value on Exercise Date (USD)"] = "-";
          }
          
          // mainDataSource[i]["Total Cost on Exercise Date (USD)"] != null ? mainDataSource[i]["Total Cost on Exercise Date (USD)"] = this.commafy(mainDataSource[i]["Total Cost on Exercise Date (USD)"].toString(),2) : mainDataSource[i]["Total Cost on Exercise Date (USD)"] = "-";
          // // mainDataSource[i]["Total Cost on Exercise Date (USD)"] = this.commafy(mainDataSource[i]["Total Cost on Exercise Date (USD)"].toString(),2);
          // mainDataSource[i]["Total Cost on Exercise Date (USD)"] = parseFloat(mainDataSource[i]["Total Cost on Exercise Date (USD)"]).toFixed(2);
  
          if(mainDataSource[i]["Total Cost on Exercise Date (USD)"] != null)
          {
            // mainDataSource[i]["Total Cost on Exercise Date (USD)"] = this.commafy(mainDataSource[i]["Total Cost on Exercise Date (USD)"].toString(),2);
            mainDataSource[i]["Total Cost on Exercise Date (USD)"] = parseFloat(this.commafy(mainDataSource[i]["Total Cost on Exercise Date (USD)"].toString(),2)).toFixed(2);

            if(mainDataSource[i]["Program"] == "RSU" || mainDataSource[i]["Program"] == "IPORSU" || mainDataSource[i]["Program"] == "Founder") 
              mainDataSource[i]["Total Cost on Exercise Date (USD)"] = "-";
          }
          else
          {
            mainDataSource[i]["Total Cost on Exercise Date (USD)"] = "-";
          }
  
          // mainDataSource[i]["Taxable Income(USD)"] != null ? mainDataSource[i]["Taxable Income(USD)"] = this.commafy(mainDataSource[i]["Taxable Income(USD)"].toString(),2) : mainDataSource[i]["Taxable Income(USD)"] = "-";
          // // mainDataSource[i]["Taxable Income(USD)"] = this.commafy(mainDataSource[i]["Taxable Income(USD)"].toString(),2);
          // mainDataSource[i]["Taxable Income(USD)"] = parseFloat(mainDataSource[i]["Taxable Income(USD)"]).toFixed(2);
  
          if(mainDataSource[i]["Taxable Income(USD)"] != null)
        {
          // mainDataSource[i]["Taxable Income(USD)"] = this.commafy(mainDataSource[i]["Taxable Income(USD)"].toString(),2);
          mainDataSource[i]["Taxable Income(USD)"] = parseFloat(this.commafy(mainDataSource[i]["Taxable Income(USD)"].toString(),2)).toFixed(2);

          if(mainDataSource[i]["Program"] == "RSU" || mainDataSource[i]["Program"] == "IPORSU" || mainDataSource[i]["Program"] == "Founder") 
            mainDataSource[i]["Taxable Income(USD)"] = "-";
        }
        else
        {
          mainDataSource[i]["Taxable Income(USD)"] = "-";
        }
  
  
          // mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] != null ? mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2) :mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = "-";
          if(mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] != null)
          {
            mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(this.commafy(mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2)).toFixed(2);

            if(mainDataSource[i]["Program"] == "RSU" || mainDataSource[i]["Program"] == "IPORSU" || mainDataSource[i]["Program"] == "Founder") 
              mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = "-";
          }
          else
          {
            mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = " - ";
          }
          
          // mainDataSource[i]["Estimated Proceeds (USD)"] != null ? mainDataSource[i]["Estimated Proceeds (USD)"] = this.commafy(mainDataSource[i]["Estimated Proceeds (USD)"].toString(),2): mainDataSource[i]["Estimated Proceeds (USD)"] = "-";
          // // mainDataSource[i]["Estimated Proceeds (USD)"] = this.commafy(mainDataSource[i]["Estimated Proceeds (USD)"].toString(),2);
          // mainDataSource[i]["Estimated Proceeds (USD)"] = parseFloat(mainDataSource[i]["Estimated Proceeds (USD)"]).toFixed(2);
  
          if(mainDataSource[i]["Estimated Proceeds (USD)"] != null)
          {
            // mainDataSource[i]["Estimated Proceeds (USD)"] = this.commafy(mainDataSource[i]["Estimated Proceeds (USD)"].toString(),2);
            mainDataSource[i]["Estimated Proceeds (USD)"] = parseFloat(this.commafy(mainDataSource[i]["Estimated Proceeds (USD)"].toString(),2)).toFixed(2);
  
            if(mainDataSource[i]["Program"] == "RSU" || mainDataSource[i]["Program"] == "IPORSU" || mainDataSource[i]["Program"] == "Founder") 
              mainDataSource[i]["Estimated Proceeds (USD)"] = "-";
          }
          else
          {
            mainDataSource[i]["Estimated Proceeds (USD)"] = "-";
          }
  
          // mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] == 0 ? mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = "-" : " ";
          // // mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2);
          // mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(mainDataSource[i]["Estimated Taxes Withheld at Exercise (USD)"]).toFixed(2);
          
          mainDataSource[i]["Original Purchase Date"] == null ? mainDataSource[i]["Original Purchase Date"] = "-" : " ";
          // mainDataSource[i]["Original Purchase Date"] = this.commafy(mainDataSource[i]["Original Purchase Date"].toString(),2);
          // mainDataSource[i]["Original Purchase Date"] = parseFloat(mainDataSource[i]["Original Purchase Date"]).toFixed(2);

          if(mainDataSource[i]["Broker for More Information"] != null)
          {
            if(mainDataSource[i]["Program"] == "IPORSU")
            {
              mainDataSource[i]["Broker for More Information"] = " ";
            }
          }
        }

        if(data[1][0]["Total Cost on Exercise Date (USD)"] != null)
        {
          data[1][0]["Total Cost on Exercise Date (USD)"] = parseFloat(data[1][0]["Total Cost on Exercise Date (USD)"]).toFixed(2);
          data[1][0]["Total Cost on Exercise Date (USD)"] = this.commafy(data[1][0]["Total Cost on Exercise Date (USD)"].toString(),2);
        }
        else
        {
          data[1][0]["Total Cost on Exercise Date (USD)"] = " ";
        }

          data[1][0]["Total CostExerciseUsd"] = parseFloat(data[1][0]["Total CostExerciseUsd"]).toFixed(2);
          data[1][0]["Total CostExerciseUsd"] = this.commafy(data[1][0]["Total CostExerciseUsd"].toString(),2);

        if(data[1][0]["Total Estimated Proceeds (USD)"] != null)
        {
          data[1][0]["Total Estimated Proceeds (USD)"] = parseFloat(data[1][0]["Total Estimated Proceeds (USD)"]).toFixed(2);
          data[1][0]["Total Estimated Proceeds (USD)"] = this.commafy(data[1][0]["Total Estimated Proceeds (USD)"].toString(),2);
        }
        else
        {
          data[1][0]["Total Estimated Proceeds (USD)"] = " ";
        }

        if(data[1][0]["Total Estimated Taxes Withheld at Exercise (USD)"] != null)
        {
          data[1][0]["Total Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(data[1][0]["Total Estimated Taxes Withheld at Exercise (USD)"]).toFixed(2);
          data[1][0]["Total Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(data[1][0]["Total Estimated Taxes Withheld at Exercise (USD)"].toString(),2);
        }
        else
        {
          data[1][0]["Total Estimated Taxes Withheld at Exercise (USD)"] = " ";
        }

        if(data[1][0]["Total Gain/(Loss) - Local Currency"] != null)
        {
          // data[1][0]["Total Gain/(Loss) - Local Currency"] = parseFloat(data[1][0]["Total Gain/(Loss) - Local Currency"]).toFixed(2);
          // data[1][0]["Total Gain/(Loss) - Local Currency"] = this.commafy(data[1][0]["Total Gain/(Loss) - Local Currency"].toString(),2);
          data[1][0]["Total Gain/(Loss) - Local Currency"] = " ";
        }
        else
        {
          data[1][0]["Total Gain/(Loss) - Local Currency"] = " ";
        }

        if(data[1][0]["Total Gain/(Loss) - USD"] != null)
        {
          // data[1][0]["Total Gain/(Loss) - USD"] = parseFloat(data[1][0]["Total Gain/(Loss) - USD"]).toFixed(2);
          // data[1][0]["Total Gain/(Loss) - USD"] = this.commafy(data[1][0]["Total Gain/(Loss) - USD"].toString(),2);
          data[1][0]["Total Gain/(Loss) - USD"] = " ";
        }
        else
        {
          data[1][0]["Total Gain/(Loss) - USD"] = " ";
        }

          data[1][0]["Total Gross Proceeds (USD)"] = parseFloat(data[1][0]["Total Gross Proceeds (USD)"]).toFixed(2);
          data[1][0]["Total Gross Proceeds (USD)"] = this.commafy(data[1][0]["Total Gross Proceeds (USD)"].toString(),2);

          // data[1][0]["Total Number of Shares in Transaction"] = parseFloat(data[1][0]["Total Number of Shares in Transaction"]).toFixed(2);
          data[1][0]["Total Number of Shares in Transaction"] = this.commafy(data[1][0]["Total Number of Shares in Transaction"].toString());

          data[1][0]["Total Sale Price (USD)"] = parseFloat(data[1][0]["Total Sale Price (USD)"]).toFixed(2);
          data[1][0]["Total Sale Price (USD)"] = this.commafy(data[1][0]["Total Sale Price (USD)"].toString(),2);

        if(data[1][0]["Total Taxable Income(USD)"] != null)
        {
          data[1][0]["Total Taxable Income(USD)"] = parseFloat(data[1][0]["Total Taxable Income(USD)"]).toFixed(2);
          data[1][0]["Total Taxable Income(USD)"] = this.commafy(data[1][0]["Total Taxable Income(USD)"].toString(),2);
        }
        else
        {
          data[1][0]["Total Taxable Income(USD)"] = " ";
        }

        if(data[1][0]["Total ValuePerShareExerciseUsd"] != null)
        {
          data[1][0]["Total ValuePerShareExerciseUsd"] = parseFloat(data[1][0]["Total ValuePerShareExerciseUsd"]).toFixed(2);
          data[1][0]["Total ValuePerShareExerciseUsd"] = this.commafy(data[1][0]["Total ValuePerShareExerciseUsd"].toString(),2);
        }
        else
        {
          data[1][0]["Total ValuePerShareExerciseUsd"] = " ";
        }


        // mainDataSource.push({'Program': 'Totals', 'Number of Shares in Transaction': data[1][0]['Total Number of Shares in Transaction'], "Sale Price (USD)": '', 
        //                      'Gross Proceeds (USD)': data[1][0]['Total Gross Proceeds (USD)'], "Gain/(Loss) - USD": data[1][0]['Total Gain/(Loss) - USD'], 
        //                      "Gain/(Loss) - Local Currency": data[1][0]['Total Gain/(Loss) - Local Currency'], 
        //                      "Value Per Share at Exercise (USD)": data[1][0]['Total ValuePerShareExerciseUsd'], 
        //                      "Total Cost on Exercise Date (USD)": data[1][0]['Total Cost on Exercise Date (USD)'], 
        //                      "Taxable Income(USD)": data[1][0]['Total Taxable Income(USD)'], 
        //                      "Estimated Taxes Withheld at Exercise (USD)": data[1][0]['Total Estimated Taxes Withheld at Exercise (USD)'], 
        //                      "Estimated Proceeds (USD)": data[1][0]['Total Estimated Proceeds (USD)'] });   
        
        mainDataSource.push({'Program': 'Totals', 'Number of Shares in Transaction': data[1][0]['Total Number of Shares in Transaction'], "Sale Price (USD)": '', 
                             'Gross Proceeds (USD)': data[1][0]['Total Gross Proceeds (USD)'], "Gain/(Loss) - USD": data[1][0]['Total Gain/(Loss) - USD'], 
                             "Gain/(Loss) - Local Currency": data[1][0]['Total Gain/(Loss) - Local Currency'], 
                             "Value Per Share at Exercise (USD)": " ", 
                             "Total Cost on Exercise Date (USD)": " ", 
                             "Taxable Income(USD)": " ", 
                             "Estimated Taxes Withheld at Exercise (USD)": " ", 
                             "Estimated Proceeds (USD)": " "});  

        firstDataSource = mainDataSource.slice(0, mainDataSource.length);

        for(let i=1; i<=lengthForLoop; i++){
          contentLength = staticLength*i;
          firstDataSource = mainDataSource.slice(contentLength-21, contentLength);
          secondDataSource = [];
          thirdDataSource = [];
          fourthDataSource = [];

          firstDataSource.forEach( cell => {
            secondDataSource.push({'Gross Proceeds (USD)': cell['Gross Proceeds (USD)'], 'Original Purchase Date': cell['Original Purchase Date'], 'Purchase Date FMV per Share(USD)': cell['Purchase Date FMV per Share(USD)'], 'Purchase Date Value (USD)': cell['Purchase Date Value (USD)'], 'Gain/(Loss) - USD': cell['Gain/(Loss) - USD'], 'Gain/(Loss) - Local Currency': cell['Gain/(Loss) - Local Currency'], 'Value Per Share at Exercise (USD)': cell['Value Per Share at Exercise (USD)']});
            thirdDataSource.push({'Total Value on Exercise Date (USD)': cell['Total Value on Exercise Date (USD)'], 'Total Cost on Exercise Date (USD)': cell['Total Cost on Exercise Date (USD)'], 'Taxable Income(USD)': cell['Taxable Income(USD)'], 'Estimated Taxes Withheld at Exercise (USD)': cell['Estimated Taxes Withheld at Exercise (USD)'], 'Estimated Proceeds (USD)': cell['Estimated Proceeds (USD)']});
            fourthDataSource.push({'Broker for More Information': cell['Broker for More Information']});

            delete cell.GrantNum;
            delete cell.HoldingsTypeId;
            delete cell.GrantLongDesc;
            delete cell.LocalCurrencyCd;
            delete cell.OptNum;
            delete cell['Gross Proceeds (USD)'];
            delete cell['Original Purchase Date'];
            delete cell['Purchase Date FMV per Share(USD)'];
            delete cell['Purchase Date Value (USD)'];
            delete cell['Gain/(Loss) - USD'];
            delete cell['Gain/(Loss) - Local Currency'];
            delete cell['Value Per Share at Exercise (USD)'];
            delete cell['Total Value on Exercise Date (USD)'];
            delete cell['Total Cost on Exercise Date (USD)'];
            delete cell['Taxable Income(USD)'];
            delete cell['Estimated Taxes Withheld at Exercise (USD)'];
            delete cell['Estimated Proceeds (USD)'];
            delete cell['Broker for More Information'];
            delete cell['Trading Volume'];
          });

          if(contentLength > mainDataSource.length){
            firstDataSource = mainDataSource.slice(contentLength-21, mainDataSource.length);
          }

          if(i>1){
            doc.addPage();
          }

          (doc as any).autoTable({
            head: [headerFirstTable],
            body: firstDataSource, 
            theme: 'grid',
            tableWidth: 'auto',
            headStyles: {
              fontSize: 8,
              textColor: [0, 0, 0],
              fillColor: 230, //[250, 249, 250]
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              cellPadding: {
                bottom: 10, top: 4
              }
            },
            bodyStyles: {
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
            }

          });

          doc.addPage();
          (doc as any).autoTable({
            head: [headerSecondTable],
            body: secondDataSource, 
            theme: 'grid',
            tableWidth: 'auto',
            headStyles: {
              fontSize: 8,
              textColor: [0, 0, 0],
              fillColor: 230, //[250, 249, 250]
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              cellPadding: {
                bottom: 10, top: 4
              }
            },
            bodyStyles: {
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
            }

          });

          doc.addPage();
          (doc as any).autoTable({
            head: [headerThirdTable],
            body: thirdDataSource, 
            theme: 'grid',
            tableWidth: 'auto',
            headStyles: {
              fontSize: 8,
              textColor: [0, 0, 0],
              fillColor: 230, //[250, 249, 250]
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              cellPadding: {
                bottom: 10, top: 4
              }
            },
            bodyStyles: {
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
            },
            didParseCell: element => {              
              if(element.cell.raw == "Total Cost on"){
                element.cell.text[0] = "Total Cost on";
                element.cell.text[1] = "Exercise Date (USD)";
              }

              if(element.cell.raw == "Estimated Taxes Withheld"){
                element.cell.text[0] = "Estimated Taxes Withheld";
                element.cell.text[1] = "at Exercise (USD)";
              }
            } 

          });

          doc.addPage();
          (doc as any).autoTable({
            head: [headerFourthTable],
            body: fourthDataSource, 
            theme: 'grid',
            tableWidth: 'auto',
            headStyles: {
              fontSize: 8,
              textColor: [0, 0, 0],
              fillColor: 230, //[250, 249, 250]
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              cellPadding: {
                bottom: 10, top: 4
              }
            },
            bodyStyles: {
              halign: 'center',
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
            }
          });
        }
        doc.save('SalesHistory.pdf');
      });
    }
  }catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "generateToPDF");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  getUserDetails(optnum) {
    try {
      this.userprofileInfo = this.route.snapshot.data['userDetails'];
      let selectedCurrencyCd = JSON.parse(sessionStorage.getItem(this.globalVariables.selectedCurrencyCd));
      let sessionAdminInfo = JSON.parse(sessionStorage.getItem(this.globalVariables.AdminCred));
      this.user = JSON.parse(sessionStorage.getItem(this.globalVariables.profileInfoKey));

      if(this.userprofileInfo != undefined && (this.userprofileInfo.IsAdmin == 'Y' || this.userprofileInfo.IsAdmin == 'y')){
        sessionStorage.setItem('adminPageDataStore', 'false');
        this.staticContent.setNavBarState(true);
        if(Object.keys(this.userprofileInfo).length > 0) {
          sessionStorage.setItem(this.globalVariables.profileInfoKey, JSON.stringify(this.userprofileInfo));
          this.sessionCachedUserInfo = true;
          sessionStorage.setItem(this.globalVariables.isUserInfoCached, JSON.stringify(this.sessionCachedUserInfo));

        }

        this.user = JSON.parse(sessionStorage.getItem(this.globalVariables.profileInfoKey));

        let userChanged = JSON.parse(sessionStorage.getItem("Changed User"));

        if(userChanged) {
          sessionStorage.setItem(this.globalVariables.selectedCurrencyCd, JSON.stringify(null));
          sessionStorage.setItem("Changed User", JSON.stringify(false));
        }

        selectedCurrencyCd = JSON.parse(sessionStorage.getItem(this.globalVariables.selectedCurrencyCd));

      }
      else{
        if(sessionAdminInfo != null || sessionAdminInfo != undefined){
          if(this.userprofileInfo != undefined && (sessionAdminInfo.EnterpriseId == this.userprofileInfo.EnterpriseId)){
          this.staticContent.setNavBarState(true);

          this.userService.getUserInfo(optnum).subscribe(results => {
            this.user = results;
            sessionStorage.setItem(this.globalVariables.profileInfoKey, JSON.stringify(this.user));




            let userChanged = JSON.parse(sessionStorage.getItem("Changed User"));

            if(userChanged) {
              sessionStorage.setItem(this.globalVariables.selectedCurrencyCd, JSON.stringify(null));
              sessionStorage.setItem("Changed User", JSON.stringify(false));
            }

          });
          }
          else{
            this.staticContent.setNavBarState(true);
            if(this.userprofileInfo != undefined && Object.keys(this.userprofileInfo).length > 0) {
              sessionStorage.setItem(this.globalVariables.profileInfoKey, JSON.stringify(this.userprofileInfo));
              this.sessionCachedUserInfo = true;
              sessionStorage.setItem(this.globalVariables.isUserInfoCached, JSON.stringify(this.sessionCachedUserInfo));
            }

            this.user = JSON.parse(sessionStorage.getItem(this.globalVariables.profileInfoKey));


            let userChanged = JSON.parse(sessionStorage.getItem("Changed User"));

            if(userChanged) {
              sessionStorage.setItem(this.globalVariables.selectedCurrencyCd, JSON.stringify(null));
              sessionStorage.setItem("Changed User", JSON.stringify(false));
            }


          }
        }
        else{
          if(this.userprofileInfo != undefined && Object.keys(this.userprofileInfo).length > 0) {
            sessionStorage.setItem(this.globalVariables.profileInfoKey, JSON.stringify(this.userprofileInfo));
            this.sessionCachedUserInfo = true;
            sessionStorage.setItem(this.globalVariables.isUserInfoCached, JSON.stringify(this.sessionCachedUserInfo));
          }

          this.user = JSON.parse(sessionStorage.getItem(this.globalVariables.profileInfoKey));


          let userChanged = JSON.parse(sessionStorage.getItem("Changed User"));

          if(userChanged) {
            sessionStorage.setItem(this.globalVariables.selectedCurrencyCd, JSON.stringify(null));
            sessionStorage.setItem("Changed User", JSON.stringify(false));
          }


        }
      }

      this.staticContent.setNavHeaderState(true);
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getUserDetails");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  /********************************************************************************************************************************************
   * Method Name: selectUserReport
   * Description: Sets the type of report on the page.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   * 2    03/29/19             Dalemark P. Suquib                    Added condition for dates
   * 3    04/22/19             Jikho S. Enrique                      Added condition for exchange rate
   * 4    04/24/19             Joshua Paul J. Avecilla(JPA)          Added condition for Individual Transfer Limit
   *                                                                 and boolean checker for fiscal period dropdown
   * 5    04/25/19             JPA                                   Remove else statement and extra if else statement for reportypeid = 4
   * 6    04/25/19             JPA                                   Added boolean (this.noDataInTable = false) when selecting a report.
   * 7    05/03/19             JPA                                   Added boolean (this.salesHistory = false) when selecting Individual Transfer Report
   * 8    06/14/19             JPA                                   Added boolean (this.displayDateError = false;) when selecting a different report to hide error message
   *******************************************************************************************************************************************/
  selectUserReport(report)
  {
    try{
    
    setTimeout(()=>this.matDP(),250);
    this.reportType = report.ReportTypeDescription;

    //MJS 05/21/2024 617951- myHoldings Dashboard Enhancement: Update the browser's address bar with the new URL
    this.selectedReportType = report.ReportTypeDescription
    const newUrl = '/reportspage/' + report.ReportTypeDescription.replace(/\s+/g, '');
    history.pushState({}, '', newUrl);

    if(report.ReportTypeId == 2){ // Individual Transfer Limit
      
      this.dataSource = [];
      this.displayedColumns = [];
      this.length = 0;
      this.displayPageNavigator = true;
      this.display = false;
      this.displayEnrollHist = false;
      this.displaySharePrice = false;
      this.displayProgramList = false;
      // this.selectDatePeriod(1);

      this.displayTotalsTable = false;

      this.rsuCalendar= false; 
      this.displayDateError = false;

      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;
      
      // this.showGrantsList = false;
      this.salesHistory = false;
      this.displayProgramList = false;
      this.displayIndividualTransferLimit = true;
      this.displaySharePriceFilter = false;
      this.noDataInTable = false;
      this.selectDatePeriodByFiscalYear(2);
      // this.getProgramTypeDropDownData(report);
      this.displayCurrencyList = false;
      this.displayGrantDesc = false;
      this.saleHistTotal = false;
      this.rsuTotal = false;
      setTimeout(()=>{ this.returnScroll() }, 500);
    }
    if(report.ReportTypeId == 1) // SALES HISTORY REPORT
    {
      this.displayAllGrantCheckbox = true;
      this.rsuTotal = false;
      setTimeout(()=>{ this.returnScroll() }, 500);
      this.saleHistTotal = false;
      this.rsuCalendar= false; 
      this.hasRSUProgramBeenTriggered = false;
      this.getSalesHistoryCheckboxData();
      this.salesHisttableLoading = false;
      this.dataSource = [];
      this.displayedColumns = [];
      this.length = 0;
      this.displayPageNavigator = true;
      this.display = false;

      this.displayTotalsTable = false;
      this.displayDateError = false;

      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      this.displayProgramList = false;
      this.displayEnrollHist= false;
      this.salesHistory = true;
      this.displaySharePriceFilter = false;
      this.displaySharePrice = false;
      this.selectDatePeriod(1);
      this.displayCurrencyList = false;
      this.displayGrantDesc = false
     
    }
    // RSU Activity
    else if(report.ReportTypeId == 3)
    {
      this.rsuTotal = false;
      setTimeout(()=>{ this.returnScroll() }, 500);
      this.rsuCalendar= true;  
      this.saleHistTotal = false;
      this.dataSource = [];
      this.displayedColumns = [];
      this.totalsColumn = [];
      this.length = 0;
      this.displayPageNavigator = true;
      this.display = false;
      this.salesHistory = false;

      this.displayTotalsTable = false;
      this.displayDateError = false;

      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      this.displaySharePrice = false;
      this.displayEnrollHist = false;
      this.displaySharePriceFilter = false;
      this.disableDatePicker = false;
      this.displayProgramList = false;
      this.displayTotalsTable = false;
      this.displayIndividualTransferLimit = false;
      this.noDataInTable = false;
      this.displayCurrencyList = false;
      this.displayGrantDesc = true;
      this.selectDatePeriod(1);
      this.getRSUActivityDropDownData();
      this.getProgramTypeDropDownData(report);

      setTimeout(()=>{ this.hideCalenderPopUp() }, 500);
    }

    // enrollment history
  
    else if(report.ReportTypeId == 4) 
    { 
      setTimeout(()=>{ this.returnScroll() }, 500);
      this.salesHistory = false; 
      this.rsuCalendar= false;  
      this.saleHistTotal = false;
      this.dataSource = [];
      this.displayedColumns = [];
      this.length = 0;
      this.displayPageNavigator = true;
      this.display = false;
      this.rsuTotal = false;

      this.displayTotalsTable = false;
      this.displayDateError = false;

      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      // this.showGrantsList = false;
      this.displaySharePrice = false;
      this.displayEnrollHist = true;
      // this.enrollHistoryTblLoading = false;
      this.disableDatePicker = false;
      this.displayProgramList = false;
      this.displayTotalsTable = false;
      this.displaySharePriceFilter = false;
      // this.selectDatePeriod(1);
      // this.getEnrollmentDropdownData();
      // this.getProgramTypeDropDownData(report);
      if(this.enrollmentTypeDropdown != undefined){
        if(Object.keys(this.enrollmentTypeDropdown).length<2){
          this.programSelected = this.enrollmentTypeDropdown[0].ProgramNm;
          this.programType = this.enrollmentTypeDropdown[0].ProgramNm;
        }else{
          this.programSelected = this.enrollmentTypeDropdown[2].ProgramNm;
          this.programType = this.enrollmentTypeDropdown[2].ProgramNm;
        }
      }

      this.displayIndividualTransferLimit = false;
      this.noDataInTable = false;
      this.selectDatePeriod(1);
      this.getEnrollmentDropdownData();
      // this.getProgramTypeDropDownData(report);
      this.displayCurrencyList = false;
      this.displayGrantDesc = false;

      setTimeout(()=>{ this.hideCalenderPopUp() }, 500);
    }

    //Purchase Program
    else if(report.ReportTypeId == 5)
    {
      this.rsuTotal = false;
      setTimeout(()=>{ this.returnScroll() }, 500);
      
      this.rsuCalendar= false; 
      this.saleHistTotal = false;
      this.displayEnrollHist = false;
      this.dataSource = [];
      this.displayedColumns = [];
      this.length = 0;
      this.displayPageNavigator = true;
      this.display = false;
      this.salesHistory = false;

      this.displayTotalsTable = false;
      this.displayDateError = false;

      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      // this.showGrantsList = false;
      this.displaySharePrice = false;
      this.displayProgramList = true;
      this.displayTotalsTable = false;
      this.displayIndividualTransferLimit = false;
      this.noDataInTable = false;
      if(Object.keys(this.purchaseProgramDropDownData).length<2){

        this.programSelected = this.purchaseProgramDropDownData[0].ProgramNm;
       
        this.programType = this.purchaseProgramDropDownData[0].ProgramNm;
       
        }
       
        else{
       
        this.programSelected = this.purchaseProgramDropDownData[1].ProgramNm;
       
        this.programType = this.purchaseProgramDropDownData[1].ProgramNm;
       
        }

      this.selectDatePeriod(1);
      // this.getProgramTypeDropDownData(report);
      this.getPurchaseProgramDropDownData();
      this.displayCurrencyList = false;
      this.displaySharePriceFilter = false;
      this.displayGrantDesc = false;


      setTimeout(()=>{ this.hideCalenderPopUp() }, 500);
    }

    else if(report.ReportTypeId == 6) { // share price history
      setTimeout(()=>$(".mat-input-infix input").css("background-color","white"), 300);
      this.rsuTotal = false;
      setTimeout(()=>{ this.returnScroll() }, 500);
       
      this.displayEnrollHist = false;
      this.rsuCalendar= false; 
      this.saleHistTotal = false;
      this.dataSource = [];
      this.displayedColumns = [];
      this.length = 0;
      this.displayPageNavigator = true;
      this.display = false;
      this.salesHistory = false;
      // this.showGrantsList = false;

      this.displayTotalsTable = false;
      this.displayDateError = false;

      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      // let defaultDate = new Date();
      // let endOfMonth = new Date(defaultDate.getFullYear(), defaultDate.getMonth() + 1, 0);
      // let prevDate: string;

      // if(defaultDate.getDate() == endOfMonth.getDate() || defaultDate.getMonth() == 2) {
      //   prevDate = moment(defaultDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      // } else {
      //   prevDate = defaultDate.getFullYear().toString() + "-" + defaultDate.getMonth().toString() + "-" + defaultDate.getDate().toString();
      // }

      // this.dateToChecker = moment(defaultDate).format("YYYY-MM-DD");
      // this.dateFromChecker = prevDate;
      // this.sharePriceHistDataSource = [];

      // this.dateFromInput = this.ngbDateParserFormatter.parse(prevDate);
      // this.selectDateFrom(this.dateFromInput);

      // this.dateToInput = this.ngbDateParserFormatter.parse(
      //   defaultDate.getFullYear().toString() + "-" +
      //   (defaultDate.getMonth() + 1).toString() + "-" +
      //   defaultDate.getDate().toString()
      // );
      // this.selectDateTo(this.dateToInput);

      this.selectDatePeriod(5);

      this.displayProgramList = false;
      // this.enrollHistoryTblLoading = false;
      this.displaySharePriceFilter = true;
      // this.noEnrollment = false;
      // this.displayEnrollTable = false;
      this.disableDatePicker = false;
      this.displayTotalsTable = false;
      this.displayIndividualTransferLimit = false;
      this.noDataInTable = false;
      this.displayCurrencyList = false;
      this.displayGrantDesc = false;

      setTimeout(()=>{ this.hideCalenderPopUp() }, 500);

    }else if(report.ReportTypeId == 7){ //exchange rate history
      setTimeout(()=>$(".mat-input-infix input").css("background-color","white"), 300);
      this.rsuTotal = false;
      setTimeout(()=>{ this.returnScroll() }, 500);
        
      this.rsuCalendar= false; 
      this.saleHistTotal = false;
      this. salesHistory = false;
      this.dataSource = [];
      this.displayedColumns = [];
      this.length = 0;
      this.displayPageNavigator = true;
      this.display = false;
      // this.showGrantsList = false;

      this.displayTotalsTable = false;
      this.displayDateError = false;

      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      // let defaultDate = new Date();
      // let endOfMonth = new Date(defaultDate.getFullYear(), defaultDate.getMonth() + 1, 0);
      // let prevDate: string;

      // if(defaultDate.getDate() == endOfMonth.getDate() || defaultDate.getMonth() == 2) {
      //   prevDate = moment(defaultDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      // } else {
      //   prevDate = defaultDate.getFullYear().toString() + "-" + defaultDate.getMonth().toString() + "-" + defaultDate.getDate().toString();
      // }

      // this.dateToChecker = moment(defaultDate).format("YYYY-MM-DD");
      // this.dateFromChecker = prevDate;
      // this.sharePriceHistDataSource = [];

      // this.dateFromInput = this.ngbDateParserFormatter.parse(prevDate);
      // this.selectDateFrom(this.dateFromInput);

      // this.dateToInput = this.ngbDateParserFormatter.parse(
      //   defaultDate.getFullYear().toString() + "-" +
      //   (defaultDate.getMonth() + 1).toString() + "-" +
      //   defaultDate.getDate().toString()
      // );
      // this.selectDateTo(this.dateToInput);

      this.selectDatePeriod(5);
      this.currentSelected = JSON.parse(sessionStorage.getItem("User Default Currency")); //BUG 392387

      this.displayEnrollHist = false;
      // this.enrollHistoryTblLoading = false;
      this.displaySharePriceFilter = false;
      // this.noEnrollment = false;
      // this.displayEnrollTable = false;
      this.disableDatePicker = false;
      this.displayGrantDesc = false;

      this.displayProgramList = false;
      this.displayCurrencyList = true;
      this.displayIndividualTransferLimit = false;
      this.noDataInTable = false;
    }
  } catch(error) {
    this.errorReportsPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getUserDetails");
    error.message = error.message + errorLocation;
    throw error;
  }


  }

 /*****************************************************************************************
  * Method Name: selectDatePeriodByFiscalYear
  * Description: Initialize and changes the date depending on which period (fiscal year) the user uses.
  *
  * #    Date                 Modified by                           Description
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft

 *****************************************************************************************/
  selectDatePeriodByFiscalYear(period){
    let currentDate = new Date();
    let fiscalYearBeginDate;
    let fiscalYearEndDate;

    try{
    if(currentDate.getMonth() > this.fiscalYearMonth){
      fiscalYearEndDate = new Date(currentDate.getFullYear() + 1, this.fiscalYearMonth, 1);
      fiscalYearEndDate.setSeconds(-1);
    }
    else {
      fiscalYearEndDate = new Date(currentDate.getFullYear(), this.fiscalYearMonth, 1);
      fiscalYearEndDate.setSeconds(-1);
    }

    if(period == 1) { // All Fiscal Years
      this.previousDateFrom = this.dateFromInput;
      this.previousDateTo = this.dateToInput;
      this.disableDatePicker = true;
      this.periodSelected = "All Fiscal Years";

      fiscalYearBeginDate = new Date(1990, 0, 1);

      this.dateFrom = "'" + moment(fiscalYearBeginDate).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";
      this.dateTo = "'" + moment(fiscalYearEndDate).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";

      }
      else if(period == 2) {  //Fiscal Year
        this.periodSelected = "Fiscal Year";
        if(currentDate.getMonth() > this.fiscalYearMonth){
          fiscalYearBeginDate = new Date(currentDate.getFullYear(), this.fiscalYearMonth, 1);
        }
        else {
          fiscalYearBeginDate = new Date(currentDate.getFullYear() - 1, this.fiscalYearMonth, 1);
        }
        this.dateFrom = "'" + moment(fiscalYearBeginDate).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";
        this.dateTo = "'" + moment(fiscalYearEndDate).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";

      }
      else if(period == 3) { //Last Fiscal year
        this.periodSelected = "Last Fiscal year";
        if(currentDate.getMonth() > this.fiscalYearMonth){
          fiscalYearBeginDate = new Date(currentDate.getFullYear() - 1, this.fiscalYearMonth, 1);
        }
        else {
          fiscalYearBeginDate = new Date(currentDate.getFullYear() - 2, this.fiscalYearMonth, 1);
        }
        this.dateFrom = "'" + moment(fiscalYearBeginDate).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";
        this.dateTo = "'" + moment(fiscalYearEndDate).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";
      }
      else if(period == 4) {
        this.periodSelected = "Last 3 Fiscal year";
        // if(currentDate.getMonth() > this.fiscalYearMonth){
        //   fiscalYearBeginDate = new Date(currentDate.getFullYear() - 3, this.fiscalYearMonth, 1);
        // }
        // else {
        //   fiscalYearBeginDate = new Date(currentDate.getFullYear() - 4, this.fiscalYearMonth, 1);
        // }

        fiscalYearBeginDate = new Date(currentDate.getFullYear() - 3, this.fiscalYearMonth, 1);

        this.dateFrom = "'" + moment(fiscalYearBeginDate).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";
        this.dateTo = "'" + moment(fiscalYearEndDate).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDatePeriodByFiscalYear");
      error.message = error.message + errorLocation;
      throw error;
    }

  }

  /*****************************************************************************************
   * Method Name: getExchangeRtCurrencyList
   * Description: Get currency list for exchange rate report
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   *****************************************************************************************/
  async getExchangeRtCurrencyList(){
    try {
      this.reportsService.getCurrencyList().pipe(map(res => res)).subscribe(async data => {
        try {

          this.completeCurrencyList = data[0];
          this.completeCurrencyList = await this.sortData(data[0], 'Decode');
          let list = this.completeCurrencyList;
          let userCurrency = list.find(i => i.Code === this.user.CurrencyCd);
          this.currentCode = this.user.CurrencyCd;
          this.currentSelected = userCurrency.Decode;
          sessionStorage.setItem("User Default Currency",JSON.stringify(this.currentSelected)); //BUG 392387
        } catch(error) {
          this.errorReportsPage = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getExchangeRtCurrencyList");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorReportsPage = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getExchangeRtCurrencyList");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getExchangeRtCurrencyList");
      error.message += errorLocation;
      throw error;
    }


  }

  /*****************************************************************************************
   * Method Name: getIsEnrollCurrESPPDetails
   * Description: Gets espp enroll details.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   *****************************************************************************************/
  getIsEnrollCurrESPPDetails(){
    try {
      this.esppTransactionService.getESPPEnrollDetail(this.user.OptNum).pipe(map(res => res)).subscribe(data =>{
        try{
        let esppDetails = data[0];

        for(let i = 0; i < esppDetails.length; i++) {
          if(esppDetails[i].EnrollStatus == EnrollStatus.EnrollESPP) {
            this.isEnrolldCurrEsppOff = 1;
          }
        }

        this.getVEIPDetails();
      } catch(error) {
        this.errorReportsPage = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getIsEnrollCurrESPPDetails");
        error.message = error.message + errorLocation;
        throw error;
      }
    }, error => {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getIsEnrollCurrESPPDetails");
      error.error.message += errorLocation;
      throw error.error;
    });
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getIsEnrollCurrESPPDetails");
      error.message += errorLocation;
      throw error;
    }


}

  /*****************************************************************************************
   * Method Name: getVEIPDetails
   * Description: Gets veip offering details.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   *****************************************************************************************/
  getVEIPDetails(){
    try {
      this.veipService.getVEIPOfferingDetails(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try{
        this.veipAllOffering = data[0];

        for(let i: number = 0; i < this.veipAllOffering.length; i++){
          if(this.veipAllOffering[i].EnrollStatus == 1 || this.veipAllOffering[i].EnrollStatus == 2)
          this.isEnrolldCurrVeipOff = 1;
        }

        this.getUserReportsAccess();
      } catch(error) {
        this.errorReportsPage = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getVEIPDetails");
        error.message = error.message + errorLocation;
        throw error;
      }
    }, error => {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getVEIPDetails");
      error.error.message += errorLocation;
      throw error.error;
    });
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getVEIPDetails");
      error.message += errorLocation;
      throw error;
    }



  }

  /*****************************************************************************************
   * Method Name: getUserReportsAccess
   * Description: Gets data if user has espp, veip, & grant.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   *****************************************************************************************/
  getUserReportsAccess() {
    try {
      this.reportsService.getHasESPPData(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
        try{
          this.hasEspp = data.value;
          this.reportsService.getHasVEIPData(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
            try{
              this.hasVeip = data.value;

              this.reportsService.getHasGrantData(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
                try{
                this.hasFounder = data.hasFounder;
                this.hasRsu = data.hasRSU;
                this.hasOptions = data.hasOptions;

                this.getUserReports();
                } catch(error) {
                  this.errorReportsPage = true;
                  this.loading = false;
                  let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
                  error.message = error.message + errorLocation;
                  throw error;
                }
              }, error => {
                this.errorReportsPage = true;
                this.loading = false;
                let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
                error.error.message += errorLocation;
                throw error.error;
              });
            } catch(error) {
              this.errorReportsPage = true;
              this.loading = false;
              let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
              error.message = error.message + errorLocation;
              throw error;
            }
          }, error => {
            this.errorReportsPage = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
            error.error.message += errorLocation;
            throw error.error;
          });
        } catch(error) {
          this.errorReportsPage = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        this.errorReportsPage = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getUserReportsAccess");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getUserReports
   * Description: Gets values assigned on the dropdown element.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   *****************************************************************************************/
  getUserReports(){
    try {
      this.reportsService.getUserReports(this.hasEspp, this.hasVeip, this.isEnrolldCurrEsppOff, this.isEnrolldCurrVeipOff, this.hasFounder, this.hasRsu, this.hasOptions).pipe(map(res => res)).subscribe(data => {
        try{
        this.userReports = data;

       //MJS 05/21/2024 617951- myHoldings Dashboard Enhancement: 
       //Check if a report type description has been selected
        if (this.reportType != null) {
          const selectedReport = this.userReports.find(report => report.ReportTypeDescription.replace(/\s+/g, '') === this.reportType);
          if (selectedReport) {
            this.selectUserReport(selectedReport);
          }
        }

        this.loading = false;
      }catch(error) {
        this.errorReportsPage = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getUserReports");
        error.message = error.message + errorLocation;
        throw error;
      }
    }, error => {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getUserReports");
      error.error.message += errorLocation;
      throw error.error;
    });
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getUserReports");
      error.message += errorLocation;
      throw error;
    }



  }

  /*****************************************************************************************
   * Method Name: selectDateFrom
   * Description: Sets the initial date.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   *****************************************************************************************/
  // selectDateFrom(event: NgbDateStruct)  //Ngb Datepicker
  // {
  //   try
  //   {
  //     let dateEvent = event.month + '/' + event.day + '/' + event.year;
  //     let inputValue = moment(dateEvent).format("YYYY-MM-DD");
  //     this.dateFromInput = event;
  //     this.dateFrom = "'" + inputValue + "'";
  //     this.dateFromChecker = inputValue;
  //     this.hasDateFromBeenClicked = true;
  //     this.holderDateFrom = this.dateFromInput;

  //     // this.dateFromInputPlaceHolder = event.toString(); //Ngb Datepicker

  //   } 
  //   catch(error) 
  //   {
  //     let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
  //     error.message = error.message + errorLocation;
  //     throw error;
  //   }
  // }

  selectDateFrom(value: Date) // Angular Material Datepicker
  { 
    try 
    {
      let dateEvent = moment(value).format("YYYY-MM-DD");
      
      this.dateFromChecker = dateEvent;

      this.dateFrom = "'" + dateEvent + "'";

      this.hasDateFromBeenClicked = true;
      this.holderDateFrom = dateEvent;
    }
    catch(error) 
    {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: selectDateTo
   * Description: Sets the final date.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   *****************************************************************************************/
  // selectDateTo(event: NgbDateStruct) //Ngb Datepicker
  // {
  //   try
  //   {
  //     let dateEvent = event.month + '/' + event.day + '/' + event.year;
  //     let inputValue = moment(dateEvent).format("YYYY-MM-DD");
  //     this.dateToInput = event;
  //     this.dateTo = "'" + inputValue + "'";
  //     this.dateToChecker = inputValue;
  //     this.hasDateToBeenClicked = true;
  //     this.holderDateTo = this.dateToInput;

  //   } 
  //   catch(error) 
  //   {
  //     let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
  //     error.message = error.message + errorLocation;
  //     throw error;
  //   }
  // }

  selectDateTo(event: Date)  // Angular Material Datepicker
  { 
    try 
    {
      let dateEvent = moment(event).format("YYYY-MM-DD");

      this.dateToChecker = dateEvent;

      this.dateTo = "'" + dateEvent + "'";

      this.hasDateToBeenClicked = true;
      this.holderDateTo = dateEvent;
    } 
   catch(error) 
   {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
      error.message += errorLocation;
      throw error;
   }
  }

  /*****************************************************************************************
   * Method Name: viewReport
   * Description: Retrieves the data, to be displayed on the table.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   * 2    04/24/19             Jikho S. Enrique                      Added displays for Exchange Rate History Report
   * 3    04/24/19             Jayson Sevilla                        Added displays for Sales History Report
   * 4    04/24/19             Joshua Paul J. Avecilla(JPA)          Added conditions for Individual Transfer Limit
   * 5    04/25/19             JPA                                   Added boolean for Individual Transfer Limit reports for displaying no data in table
   * 6    04/25/19             JPA                                   Added boolean for Individual Transfer Limit reports for loading icon
   * 7    05/16/19             JPA                                   Added an initializer for dataSource, displayedColumns,and totalsTable variables in sales History
   * 8    05/16/19             JPA                                   Added boolean for hiding total row when clicking view button.
   *****************************************************************************************/
  viewReport() {
  try{
    // this.itlDisabler();
    this.displayExportAndPagination = false;
    if(this.reportType == "Individual Transfer Limit"){
      this.dataSource = [];
      this.displayedColumns = [];
      // this.currentIndex = 1;
      this.prevIndex = 0;
      this.display = true;

      this.pageIndex = 1;
      this.lengthContainer = 50;
      this.length = 0;
      this.displayTotalsTable = false;
      this.displayPageNavigator = true;
      this.salesHistory = false;
      // this.displayEnrollTable = false;
      this.noDataInTable = false;
      this.tableLoading = true;

      this.legitDateFrom = this.dateFrom;
      this.legitDateTo = this.dateTo;

      this.getIndividualTransferLimit(this.pageIndex);
    }
    else{
      if(this.dateFromChecker <= this.dateToChecker) {
        this.displayDateError = false;
        // if(this.displaySharePrice == true){
      if(this.reportType == "Share Price History") {

          this.dataSource = [];
          this.displayedColumns = [];
          this.displayPageNavigator = true;
          this.display = true;
          
          this.prevIndex = 0;

          this.noDataInTable = true;
          this.tableLoading = true;
          this.salesHistory = false;
          this.prevIndex = 0;


          this.pageIndex = 1;
          this.lengthContainer = 51;
          this.length = 0;
          this.legitDateFrom = this.dateFrom;
          this.legitDateTo = this.dateTo; 

          this.getSharePriceHistory(this.pageIndex);
          // this.ngAfterViewChecked();
        }
        else if(this.reportType == "Enrollment History"){
          this.dataSource = [];
          this.displayedColumns = [];
          // this.displayEnrollTable = false;
          this.displayPageNavigator = true;
          this.display = true;
          
          //  51;
          this.prevIndex = 0;
          // this.currentIndex = 1;
          this.noDataInTable = true;
          this.tableLoading = true;
          // this.programType =1;
          // this.dateFrom = "";
          // this.dateTo = "";

          // this.loading = true;
          this.salesHistory = false;
          // this.currentIndex = 1;
          this.prevIndex = 0;
          // this.enrollHistoryTblLoading = true;

          this.pageIndex = 1;
          this.lengthContainer = 50;
          this.length = 0;

          this.legitDateFrom = this.dateFrom;
          this.legitDateTo = this.dateTo;

          this.getEnrollmentHistoryData(this.pageIndex);
          // this.ngAfterViewChecked();
        }
        else if(this.reportType == "Purchase Program"){
          this.dataSource = [];
          this.displayedColumns = [];
          this.totalsTable = [];
          this.displayPageNavigator = true;
          this.display = true;
          this.tableLoading = true;
          // this.displayEnrollTable = false;
          
          //  51;
          this.prevIndex = 0;
          // this.currentIndex = 1;
          // this.noEnrollment = false;
          // this.programType =1;
          // this.dateFrom = "";
          // this.dateTo = "";

          // this.loading = true;
          // this.currentIndex = 1;
          this.prevIndex = 0;
          // this.enrollHistoryTblLoading = true;

          this.pageIndex = 1;
          this.lengthContainer = 50;
          this.length = 0;

          this.legitDateFrom = this.dateFrom;
          this.legitDateTo = this.dateTo;

          this.getPurchaseProgramData(this.pageIndex);
          // this.ngAfterViewChecked();
        }

        else if(this.reportType == "RSU Activity")
        {
          this.dataSource = [];
          this.displayedColumns = [];
          this.totalsTable = [];
          this.rsuTotal = false;
          this.displayPageNavigator = true;
          this.display = true;
          this.salesHisttableLoading = true;
          // this.displayEnrollTable = false;
          
          //  51;
          this.prevIndex = 0;
          // this.currentIndex = 1;
          // this.noEnrollment = false;
          // this.programType =1;
          // this.dateFrom = "";
          // this.dateTo = "";

          // this.loading = true;
          // this.grantDescription = "FY17 Accenture Leadership Performance RSU Award";
          // this.currentIndex = 1;
          this.prevIndex = 0;
          // this.enrollHistoryTblLoading = true;

          this.pageIndex = 1;
          this.lengthContainer = 50;
          this.length = 0;

          this.legitDateFrom = this.dateFrom;
          this.legitDateTo = this.dateTo;

          this.getRSUActivityData(this.pageIndex);
          // this.ngAfterViewChecked();
          setTimeout(()=>{ this.collapseGrants() }, 0.0000000000000000001);
        }

        else if(this.reportType == "Sales History"){
          this.dataSource = [];
          this.displayedColumns = [];
          this.totalsTable = [];
          this.salesHisttableLoading = true;
          this.displayPageNavigator = true;
          this.display = true;
          this.saleHistTotal = false;
          // this.displayEnrollTable = false;
          
          //  51;
          this.prevIndex = 0;
          // this.currentIndex = 1;
          this.noSalesHistory = false;
          // this.currentIndex = 1;
          this.prevIndex = 0;
          // this.enrollHistoryTblLoading = true;

          this.pageIndex = 1;
          this.lengthContainer = 50;
          this.length = 0;

          this.legitDateFrom = this.dateFrom;
          this.legitDateTo = this.dateTo;

          this.getSalesHistoryData(this.pageIndex);
          // this.ngAfterViewChecked();
          setTimeout(()=>{ this.collapseGrants() }, 0.0000000000000000001);
        }else if(this.reportType == "Exchange Rate History")
        {
          this.dataSource = [];
          this.displayedColumns = [];
          this.displayPageNavigator = true;
          this.display = true;
        
        //  51;
        this.prevIndex = 0;
        // this.currentIndex = 1;
        // this.noEnrollment = false;
        this.exchangeRateHist = false;
        this.salesHistory = false;
        // this.currentIndex = 1;
        this.prevIndex = 0;
        // this.enrollHistoryTblLoading = true;
        this.tableLoading = true;
        this.pageIndex = 1;
        this.lengthContainer = 51;
        this.length = 0;
        this.legitDateFrom = this.dateFrom;
        this.legitDateTo = this.dateTo;
        this.getExchangeRateHistoryData(this.pageIndex);
      }
    }
    else if(this.dateFromChecker > this.dateToChecker)
    {
        this.displayDateError = true;
    }
  }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "viewReport");
    error.message = error.message + errorLocation;
    throw error;
  }

}

  /*****************************************************************************************************
   * Method Name: getIndividualTransferLimit
   * Description: Gets the Individual Transfer Limit of user.
   *
   * #    Date                 Modified by                                      Description
   * 1    04/24/19             Joshua Paul J. Avecilla (JPA)                    Initial draft
   * 2    04/25/19             JPA                                              Added calling method removeUnwantedStyles()
   * 3    05/Jun/2019          Kim Phillip Aying                                Fixed the Totals Row
   *****************************************************************************************************/
  getIndividualTransferLimit(pageIndex){
    try {
      // let totalUnrestricted = 0, totalITLShares = 0, totalAdditionalShares = 0, totalSharesSold = 0, totalNetShares = 0;

      this.reportsService.getIndividualTransferLimitData(this.user.OptNum,this.legitDateFrom.replace(/\'/g, ""), this.legitDateTo.replace(/\'/g, ""), pageIndex).subscribe(data => {
        try{
        let sortedData: Element[];
        let totals: Element[];
        // let ITLTotalityRow: Element[] = data[2];
        // this.length = ITLTotalityRow[0]['TotalRows'];
        this.totalPages = 0;
        this.totalPages = Math.ceil(data[2][0].TotalRows/50);

        if(Array.isArray(data) && data[0].length > 0) {
          let tempSortedData = data[0];
          let totality = data[1];
          this.individualTransferLimitData = tempSortedData;
          this.displayPageNavigator = false;
          this.noDataInTable = false;
          sortedData = [tempSortedData];
          totals = [totality];

          this.displayedColumns = this.displayIndividualTransferLimitColumn;
          this.dataSource = new MatTableDataSource<Element>(sortedData);
          this.dataSource = this.dataSource.filteredData[0];
          this.length = data[2][0].TotalRows;

          this.displayTotalsTable = this.totalIndividualTransferLimit;
          this.displayedTotalColumns = this.displayedTotalColumnsIndividualTransferLimit;
          this.totalsTable = new MatTableDataSource<Element>(totals);
          this.totalsTable = this.totalsTable.filteredData[0];

          for(let i=0; i<this.dataSource.length; i++)
          {
            this.dataSource[i]["Total Unrestricted Shares at Period Start"] = this.commafy(this.dataSource[i]["Total Unrestricted Shares at Period Start"].toString());
            this.dataSource[i]["Individual Transfer Limit Shares"] = this.commafy(this.dataSource[i]["Individual Transfer Limit Shares"].toString());
            this.dataSource[i]["Additional Shares Available Due to Transfer Limit Waiver"] = this.commafy(this.dataSource[i]["Additional Shares Available Due to Transfer Limit Waiver"].toString());
            this.dataSource[i]["Total Shares Sold/Transferred"] = this.commafy(this.dataSource[i]["Total Shares Sold/Transferred"].toString());
            this.dataSource[i]["Net Shares Eligible for Sale/Transfer in Period"] = this.commafy(this.dataSource[i]["Net Shares Eligible for Sale/Transfer in Period"].toString());
          }

            this.totalsTable[0]["Total"] = "Totals";
            this.totalsTable[0]["Net Shares Eligible for Sale/Transfer in Period"] = this.commafy(this.totalsTable[0]["Net Shares Eligible for Sale/Transfer in Period"].toString());
            this.totalsTable[0]["Total Additional Shares Available Due to Transfer Limit Waiver"] = this.commafy(this.totalsTable[0]["Total Additional Shares Available Due to Transfer Limit Waiver"].toString());
            this.totalsTable[0]["Total Individual Transfer Limit Shares"] = this.commafy(this.totalsTable[0]["Total Individual Transfer Limit Shares"].toString());
            this.totalsTable[0]["Total Shares Sold/Transferred"] = this.commafy(this.totalsTable[0]["Total Shares Sold/Transferred"].toString());
            this.totalsTable[0]["Total Unrestricted Shares at Period Start"] = this.commafy(this.totalsTable[0]["Total Unrestricted Shares at Period Start"].toString());

            this.totalsTable[0]["Program"] = this.totalsTable[0]["Total"];
            this.totalsTable[0]["Total Unrestricted Shares at Period Start"] = this.totalsTable[0]["Total Unrestricted Shares at Period Start"];
            this.totalsTable[0]["Individual Transfer Limit Shares"] = this.totalsTable[0]["Total Individual Transfer Limit Shares"];
            this.totalsTable[0]["Additional Shares Available Due to Transfer Limit Waiver"] = this.totalsTable[0]["Total Additional Shares Available Due to Transfer Limit Waiver"];
            this.totalsTable[0]["Total Shares Sold/Transferred"] = this.totalsTable[0]["Total Shares Sold/Transferred"];
            this.totalsTable[0]["Net Shares Eligible for Sale/Transfer in Period"] = this.totalsTable[0]["Net Shares Eligible for Sale/Transfer in Period"];
            

          if(this.length == this.lengthContainer || this.length < this.lengthContainer)
          {
            this.dataSource.push(this.totalsTable[0]);
            this.loading = false;
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
            setTimeout(()=>{ this.scrollS() }, 500);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustIndividualTransferLimitDataTable() }, 0.0000000000000000001);
            setTimeout(()=>{ this.totalsRowDesign3() }, 0.0000000000000000001);
          }
          else
          {
            this.loading = false;
            setTimeout(()=>{ this.returnScroll() }, 500);
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustIndividualTransferLimitDataTable() }, 0.0000000000000000001);
          }

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          this.tableLoading = false;
          setTimeout(()=>{ this.scrollS() }, 500);
          setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
          setTimeout(()=>{ this.adjustIndividualTransferLimitDataTable() }, 0.0000000000000000001);
          setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
          this.ddEnabler();
          this.displayExportAndPagination = true;
        }
        else
        {
          this.displayExportAndPagination = false;
          this.displayPageNavigator = true;
          this.noDataInTable = true;
          this.displayTotalsTable = false;
        }
        

        this.tableLoading = false;
        // setTimeout(()=>{ this.scrollS() }, 500);
        setTimeout(()=>{ this.removeUnwantedStyles() }, 500);
        setTimeout(()=>{ this.adjustIndividualTransferLimitDataTable() }, 0.0000000000000000001);
        // this.paginatorEnabler();
        // this.ddEnabler();

      } catch(error) {
        this.errorReportsPage = true;
        this.tableLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getIndividualTransferLimit");
        error.message = error.message + errorLocation;
        throw error;
      }
    }, error => {
      this.errorReportsPage = true;
      this.tableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getIndividualTransferLimit");
      error.error.message += errorLocation;
      throw error.error;
    });
    } catch(error) {
      this.errorReportsPage = true;
      this.tableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getIndividualTransferLimit");
      error.message += errorLocation;
      throw error;
    }



  }

  /*****************************************************************************************
   * Method Name: getSharePriceHistory
   * Description: Gets the share price history of user.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   *****************************************************************************************/
  getSharePriceHistory(pageIndex) {
    try {
      this.sharePriceService.getSharePriceHistory(this.legitDateFrom.replace(/\'/g, ""), this.legitDateTo.replace(/\'/g, ""), pageIndex).subscribe(data => {
        try{

          this.totalPages = 0;
          this.totalPages = Math.ceil(data[1][0]["Total Rows"]/50);

        if(Array.isArray(data) && data[0].length > 0) {
          this.noSharePriceHistory = false;
          this.displayPageNavigator = this.noSharePriceHistory;
          this.displayTotalsPurchaseProgram = false;
          this.noDataInTable = false;
          this.displayTotalsTable = this.displayTotalsPurchaseProgram;
          // let sortedData: Element[] = [data[0].sort((a, b) => a.Date < b.Date ? 1 : a.Date > b.Date ? -1 : 0)];
          // let sortedData = [data[0].sort((a, b) => a.Date < b.Date ? 1 : a.Date > b.Date ? -1 : 0)];
          // let sortedData: Element[] = data[0].slice(0, data[0].length);
          let sortedData: Element[] = [data[0]];
          this.sharePriceHistDataSource = sortedData;
          this.displayedColumns  = this.displayedSharePriceHistColumns;

          // this.dataSource = new MatTableDataSource<Element>(sortedData);
          this.dataSource = new MatTableDataSource<Element>(sortedData);
          // this.dataSource = sortedData;
          this.dataSource = this.dataSource.filteredData[0];

          for(let i=0; i<this.dataSource.length; i++)
          {

            this.dataSource[i]["Trading Volume"] = parseFloat(this.dataSource[i]["Trading Volume"]).toFixed();
            this.dataSource[i]["Trading Volume"] = this.commafy(this.dataSource[i]["Trading Volume"]);

            this.dataSource[i]["Close (USD)"] = parseFloat(this.dataSource[i]["Close (USD)"]).toFixed(4);
            this.dataSource[i]["Close (USD)"] = this.commafy(this.dataSource[i]["Close (USD)"],4);

            // this.dataSource[i]["Fair Market Value (USD)"] = parseFloat(this.dataSource[i]["Fair Market Value (USD)"]).toFixed(4);
            this.dataSource[i]["Fair Market Value (USD)"] = this.commafy(parseFloat(this.dataSource[i]["Fair Market Value (USD)"]).toFixed(4),4);

            this.dataSource[i]["High (USD)"] = parseFloat(this.dataSource[i]["High (USD)"]).toFixed(4);
            this.dataSource[i]["High (USD)"] = this.commafy(this.dataSource[i]["High (USD)"],4);

            this.dataSource[i]["Low (USD)"] = parseFloat(this.dataSource[i]["Low (USD)"]).toFixed(4);
            this.dataSource[i]["Low (USD)"] = this.commafy(this.dataSource[i]["Low (USD)"],4);
          }
          

          // if(this.length > this.lengthContainer)
          // {
          //   if((this.length-this.lengthContainer) > 50)
          //   {
          //     this.lengthContainer += 50;
          //   }
          //   else
          //   {
          //     this.lengthContainer = this.length-this.lengthContainer;
          //     this.loading = false;
          //   }
          // }

          if(this.dataSource.length == 50)
          {
            this.length = this.lengthContainer;
          }
          else
          {
            this.length = this.dataSource.length;
          }

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.displayExportAndPagination = true;
            this.tableLoading = false;
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustSharePriceHistTable() }, 0.0000000000000000001);
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);

        } else {
          this.noDataInTable = true;
          this.errorReportsPage = false;
          this.displayExportAndPagination = false;
          this.tableLoading = false;
          this.displayPageNavigator = true;
          setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
          setTimeout(()=>{ this.adjustSharePriceHistTable() }, 0.0000000000000000001);
          setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
        }
      } catch(error) {
        this.errorReportsPage = true;
        this.tableLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getSharePriceHistory");
        error.message = error.message + errorLocation;
        throw error;
      }
    }, error => {
      this.errorReportsPage = true;
      this.tableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getSharePriceHistory");
      error.error.message += errorLocation;
      throw error.error;
    });
    } catch(error) {
      this.errorReportsPage = true;
      this.tableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getSharePriceHistory");
      error.message += errorLocation;
      throw error;
    }




  }


  /*****************************************************************************************
   * Method Name: onPageChange
   * Description: Controls the navigation of the mat-pagination, i.e. forward and backward
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Kim Phillip Aying                     Initial draft
   * 2    04/24/19             Joshua Paul J. Avecilla               Added condition for Individual Transfer Limit
   * 3    04/24/19             Jikho Enrique                         Added condition for Exchange Rate
   *****************************************************************************************/
  onPageChange(event)
  {
    try{

    if(this.reportType == "Share Price History")
    {

      if((event.pageIndex+1)>this.pageIndex)
      {
        this.pageIndex += 1;
        this.lengthContainer += 50;
        this.getSharePriceHistory(this.pageIndex);
        // this.ngAfterViewChecked();
      }
      else
      {
        this.pageIndex -= 1;
        this.lengthContainer -= 50;
        this.getSharePriceHistory(this.pageIndex);
        // this.ngAfterViewChecked();
      }
    }
    

    else if(this.reportType == "Enrollment History")
    {
      if((event.pageIndex+1)>this.pageIndex)
      {
        this.pageIndex += 1;
        this.lengthContainer += 50;
        this.getEnrollmentHistoryData(this.pageIndex);
        // this.ngAfterViewChecked();
      }
      else
      {
        this.pageIndex -= 1;
        this.lengthContainer -= 50;
        this.getEnrollmentHistoryData(this.pageIndex);
        // this.ngAfterViewChecked();
      }
    }

    else if(this.reportType == "RSU Activity")
    {
      if(this.length > this.lengthContainer)
      {
        if((this.length-this.lengthContainer) > 50)
        {
          this.lengthContainer += 50;
        }
        else
        {
          this.lengthContainer = this.lengthContainer + (this.length-this.lengthContainer);
        }
      }
      else{
        this.length = this.lengthContainer;
      }

      if((event.pageIndex+1)>this.pageIndex)
      {
        this.pageIndex += 1;
        this.getRSUActivityData(this.pageIndex);
        // this.ngAfterViewChecked();
      }
      else
      {
        this.pageIndex -= 1;
        this.lengthContainer -= 50;
        this.getRSUActivityData(this.pageIndex);
        // this.ngAfterViewChecked();
      }
    }

    else if(this.reportType == "Purchase Program")
    {
      if(this.length > this.lengthContainer)
      {
        if((this.length-this.lengthContainer) > 50)
        {
          this.lengthContainer += 50;
        }
        else
        {
          this.lengthContainer = this.lengthContainer + (this.length-this.lengthContainer);
        }
      }
      else{
        this.length = this.lengthContainer;
      }

      if((event.pageIndex+1)>this.pageIndex)
      {
        this.pageIndex += 1;
        this.getPurchaseProgramData(this.pageIndex);
      }
      else
      {
        this.pageIndex -= 1;
        this.lengthContainer -= 50;
        this.getPurchaseProgramData(this.pageIndex);
      }
    }



    else if(this.reportType == "Sales History")
    {
      if(this.length > this.lengthContainer)
      {
        if((this.length-this.lengthContainer) > 50)
        {
          this.lengthContainer += 50;
        }
        else
        {
          this.lengthContainer = this.lengthContainer + (this.length-this.lengthContainer);
        }
      }
      else{
        this.length = this.lengthContainer;
      }

      if((event.pageIndex+1)>this.pageIndex)
      {
        this.pageIndex += 1;
        this.getSalesHistoryData(this.pageIndex);
        // this.ngAfterViewChecked();
      }
      else
      {
        this.pageIndex -= 1;
        this.lengthContainer -= 50;
        this.getSalesHistoryData(this.pageIndex);
        // this.ngAfterViewChecked();
      }
    }
    else if(this.reportType == "Individual Transfer Limit")
    {
      if(this.length > this.lengthContainer)
      {
        if((this.length-this.lengthContainer) > 50)
        {
          this.lengthContainer += 50;
        }
        else
        {
          this.lengthContainer = this.lengthContainer + (this.length-this.lengthContainer);
        }
      }
      else{
        this.length = this.lengthContainer;
      }

      if((event.pageIndex+1)>this.pageIndex)
      {
        this.pageIndex += 1;
        this.getIndividualTransferLimit(this.pageIndex);
      }
      else
      {
        this.pageIndex -= 1;
        this.lengthContainer -= 50;
        this.getIndividualTransferLimit(this.pageIndex);
      }
    }
    else if(this.reportType == "Exchange Rate History")
    {
      if((event.pageIndex+1)>this.pageIndex)
      {
        this.pageIndex += 1;
        this.lengthContainer += 50;
        this.getExchangeRateHistoryData(this.pageIndex);
      }
      else
      {
        this.pageIndex -= 1;
        this.lengthContainer -= 50;
        this.getExchangeRateHistoryData(this.pageIndex);
      }
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "onPageChange");
    error.message = error.message + errorLocation;
    throw error;
  }

}


  // getSharePriceColumnData(data){

  // }

  /*****************************************************************************************
   * Method Name: getSharePriceHistoryNextData
   * Description: Gets the next or previous page of share price history of user.
   *
   * #    Date                 Modified by                           Description
   * 1    03/11/19             Dalemark P. Suquib                    Initial draft
   *****************************************************************************************/
  // getSharePriceHistoryNextData(pageNum, checker) {
  //   this.sharePriceService.getSharePriceHistory(this.dateFrom, this.dateTo, pageNum).subscribe(data => {
  //     if(checker == "next") {
  //       let sortedData = data[0].sort((a, b) => a.PRICE_DT < b.PRICE_DT ? 1 : a.PRICE_DT > b.PRICE_DT ? -1 : 0);

  //       for(let i: number = 0; i < sortedData.length; i++) {
  //         sortedData[i].PRICE_DT = moment(sortedData[i].PRICE_DT).format("DD MMM YYYY");
  //       }

  //       this.sharePriceHistoryNextData = sortedData;
  //       if(this.sharePriceHistoryNextData.length < 50) {
  //         this.sharePricePageLength = this.sharePricePageLength + this.sharePriceHistoryNextData.length;
  //       } else {
  //         this.sharePricePageLength = this.sharePricePageLength + 50;
  //       }
  //     } else {
  //       let sortedData = data[0].sort((a, b) => a.PRICE_DT < b.PRICE_DT ? 1 : a.PRICE_DT > b.PRICE_DT ? -1 : 0);
  //       this.sharePriceHistoryPrevData = sortedData;
  //       if(this.sharePriceHistoryPrevData.length < 50) {
  //         this.sharePricePageLength = this.sharePricePageLength - this.sharePriceHistoryPrevData.length;
  //       } else {
  //         this.sharePricePageLength = this.sharePricePageLength - 50;
  //       }
  //     }

  //     this.sharePriceHistLoading = false;
  //     this.displaySharePrice = true;
  //   })
  // }

  // /*************************************************************************************************************************************************************************************
  //  * Method Name: getNextSharePriceHistory
  //  * Description: Selects the previous or next page of share price history.
  //  *
  //  * #    Date                 Modified by                           Description
  //  * 1    03/11/19             Dalemark P. Suquib                    Initial draft
  //  * 2    03/26/19             Joshua Avecilla (JPA)                 Bug fix for "Next Page button should be disabled when there are no items to be displayed in the next page"
  //  *                                                                 Adding a loading when clicking the next page button
  //  *************************************************************************************************************************************************************************************/
  // getNextSharePriceHistory(event: PageEvent) {
  //   this.sharePriceHistLoading = true;
  //   if((event.pageIndex + 1) > this.sharePricePageNum) {
  //     this.sharePricePrevIndex = this.sharePricePageNum;
  //     this.sharePricePageNum += 1;
  //     this.sharePriceHistoryPrevData = this.sharePriceHistDataSource;
  //     this.sharePriceHistDataSource = this.sharePriceHistoryNextData;
  //     this.getSharePriceHistoryNextData(this.sharePricePageNum + 1, "next");
  //   } else {
  //     this.sharePricePrevIndex = this.sharePricePageNum;
  //     this.sharePricePageNum -= 1;
  //     this.sharePriceHistoryNextData = this.sharePriceHistDataSource;
  //     this.sharePriceHistDataSource = this.sharePriceHistoryPrevData;
  //     this.getSharePriceHistoryNextData(this.sharePricePageNum - 1, "prev");
  //   }
  // }

  // COMMAFY
  commafy(num: any, value?: number) {
    // if(typeof(num) == 'number') {
      try{
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value >= 0) {
          let number = (num*1).toLocaleString('en', {
            // maximumFractionDigits: value
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message = error.message + errorLocation;
    throw error;
  }
  }



//START - ENROLLMENT HISTORY
  /******************************************************************************************************************************
    Method Name: removeUnwantedStyles
    Description: jquery method that modifies to remove the display of certain html in the mat table.

    #    Date             Modified by                    Description
    1    03/8/2019        Katrina Michelle Narag         Initial draft
    2    03/15/2019       Joshua Paul J. Avecilla (JPA)  Added another html element to remove display.
  *******************************************************************************************************************************/
removeUnwantedStyles(){
  // $(".mat-paginator-range-label").css({"display": "block",
  //                                      "margin":"0 35px 0 0",
  //                                     });
  $(".mat-paginator-page-size").css("display", "none");
  $("mat-paginator-page-size-label").css("display", "none");
  $(".mat-paginator-range-label").css("display", "none");
}

  /******************************************************************************************************************************
    Method Name: getEnrollmentHistoryData
    Description: Get enrollment history data from the api by providing the user's optnum for current/first page of the table.

    #    Date             Modified by                    Description
    1    03/08/2019       Joshua Paul J. Avecilla (JPA)  Initial draft
    2    05/02/2019       JPA                            Set displayPageNavigator and noDataInTable to false when no data returned
    3    02/17/2023       Johannah Jane Abuel (JJA)      User Story 849783: [myHoldings] Update Header from Personnel ID to Employee ID
  *******************************************************************************************************************************/
getEnrollmentHistoryData(pageIndex){
  try{
    if(this.programType == "All")
    this.programType = "All Grants";
    this.enrollservice.getEnrollmentData(this.user.OptNum, this.programType, this.legitDateFrom, this.legitDateTo, pageIndex).subscribe(data => {
      try {

        this.totalPages = 0;
        this.totalPages = Math.ceil(data[1][0]["Total Rows"]/50);

        if(Array.isArray(data) && data[0].length > 0) {
          this.noEnrollmentHist = false;
          this.displayPageNavigator = this.noEnrollmentHist;
          this.displayTotalsPurchaseProgram = false;
          this.noDataInTable = false;
          this.displayTotalsTable = this.displayTotalsPurchaseProgram;
          let sortedData: Element[] = [data[0].sort((a, b) => a.PRICE_DT < b.PRICE_DT ? 1 : a.PRICE_DT > b.PRICE_DT ? -1 : 0)];

          this.displayedColumns = this.displayedColumnsAdmin;
          this.dataSource = new MatTableDataSource<Element>(sortedData);
          // JJA, START 849783 //
          this.dataSource = this.dataSource.filteredData[0].map( item => {
            if ( item.hasOwnProperty("Personnel Number") )
            {
              item["Employee ID"] = item["Personnel Number"];
              delete item["Personnel Number"];
            }
            return item;
          });
          // JJA, END 849783 //
          this.length = data[2];

          for(let i=0; i<this.dataSource.length; i++)
          {
            this.dataSource[i]["Contribution Percent"] = this.dataSource[i]["Contribution Percent"].toString() + "%";
          }
          
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.displayExportAndPagination = true;
          this.tableLoading = false;
          this.noDataInTable = false;
          setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
          setTimeout(()=>{ this.adjustEnrollmentHistTable() }, 0.0000000000000000001);
        }
        else
        {
          this.displayExportAndPagination = false;
          this.displayPageNavigator = true;
          this.noDataInTable = true;
          // this.removeUnwantedStyles();
          setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
          setTimeout(()=>{ this.adjustEnrollmentHistTable() }, 0.0000000000000000001);
        }
        this.tableLoading = false;
        setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getExchangeRtCurrencyList");
        error.message += errorLocation;
        throw error;
      }


    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentHistoryData");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = " [Component: Homepage, Method: getEnrollmentHistoryData]";
    error.message = error.message + errorLocation;
    throw error;
  }
}

/*************************************************************************************************************************************************************************************
//  * Method Name: getNextSharePriceHistory
//  * Description: Selects the previous or next page of share price history.
//  *
//  * #    Date                 Modified by                           Description
//  * 1    05/28/19             KMN                                   Check if data is not undefined
// *************************************************************************************************************************************************************************************/
async getEnrollmentDropdownData(){
  try {
    this.salesHistory = false;
    this.enrollservice.getEnrollDropDownData(this.user.OptNum).subscribe(data => {
      try{
      if(Array.isArray(data[0]) && data[0].length > 0) {
        if(data[0].length == 2){

          this.enrollmentTypeDropdown = data[0];
          data[0].unshift({ProgramNm: "All"});


        if(this.enrollmentTypeDropdown != undefined){
          if(Object.keys(this.enrollmentTypeDropdown).length<2){
            this.programSelected = this.enrollmentTypeDropdown[0].ProgramNm;
            this.programType = this.enrollmentTypeDropdown[0].ProgramNm;
          }else{
            this.programSelected = this.enrollmentTypeDropdown[2].ProgramNm;
            this.programType = this.enrollmentTypeDropdown[2].ProgramNm;
          }
        }

          this.programTypeDropDownData = this.enrollmentTypeDropdown;
          sessionStorage.setItem("Enrollment History Drop Down Data",JSON.stringify(this.enrollmentTypeDropdown));
        }
        else{
          this.enrollmentTypeDropdown = data[0];
          this.programSelected = this.enrollmentTypeDropdown[0].ProgramNm;
          this.programType = this.enrollmentTypeDropdown[0].ProgramNm;
          // this.enrollHistoryTblLoading = false;

          this.programTypeDropDownData = this.enrollmentTypeDropdown;
        }
      }
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentDropdownData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }, error => {
    this.errorReportsPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentDropdownData");
    error.error.message += errorLocation;
    throw error.error;
  });
  } catch(error) {
    this.errorReportsPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getEnrollmentDropdownData");
    error.message += errorLocation;
    throw error;
  }
}

/************************************************************************************
 Method Name: adjustEnrollmentHistTable
 Description: Method to adjust the CSS Style for Share Price History Table

#       Date                        Name                    Description
1       15/Jul/2019                 Kim Phillip Aying       Initial Draft
 ************************************************************************************/
adjustEnrollmentHistTable()
{
  $("mat-header-row").css({"width": "102%","min-width": "1136px", "text-align": "center"});
  $("mat-row").css({"width": "102%", "min-width": "1136px", "text-align": "center"});
}

/**End of adjustEnrollmentHistTable method */

/************************************************************************************
 Method Name: adjustSharePriceHistTable
 Description: Method to adjust the CSS Style for Share Price History Table

#       Date                        Name                    Description
1       01/May/2019                 Kim Phillip Aying       Initial Draft
 ************************************************************************************/
adjustSharePriceHistTable()
{
  $("mat-header-row").css({"width": "100%","min-width": "1136px", "text-align": "center"});
  $("mat-row").css({"width": "100%", "min-width": "1136px", "text-align": "center"});
}
/**End of adjustSharePriceHistTable method */

hideCalenderPopUp()
{
  $("ngbDatepicker").css("visibility", "hidden");
}




/************************************************************************************
 Method Name: getProgramTypeDropDownData
 Description: Displays the correct Drop Down Data in the Program Type Option depending on the Report Type chosen.

#       Date                        Name                    Description
1       19/April/2019               Kim Phillip Aying       Initial Draft
 ************************************************************************************/

async getProgramTypeDropDownData(report)
{
  try{
  if(report.ReportTypeId == 3)
  {
    // this.getEnrollmentDropdownData();
    // setTimeout(()=>{ this.getRSUActivityDropDownData() }, 0.00000000001);
    this.programTypeDropDownData = JSON.parse(sessionStorage.getItem("RSU Activity Drop Down Data"));
  }
  else if(report.ReportTypeId == 4)
  {
    // this.getEnrollmentDropdownData();
    // setTimeout(()=>{ this.getEnrollmentDropdownData() }, 0.00000000001);
    this.programTypeDropDownData = JSON.parse(sessionStorage.getItem("Enrollment History Drop Down Data"));
  }
  else if(report.ReportTypeId == 5)
  {
    // this.getPurchaseProgramDropDownData();
    // setTimeout(()=>{ this.getPurchaseProgramDropDownData() }, 0.00000000001);
    this.programTypeDropDownData = JSON.parse(sessionStorage.getItem("Purchase Program Drop Down Data"));
  }
} catch(error) {
  let errorLocation = this.errorLocation.replace("methodName", "getProgramTypeDropDownData");
  error.message = error.message + errorLocation;
  throw error;
}

}
/**End of getProgramTypeDropDownData method */

/************************************************************************************
 Method Name: getPurchaseProgramDropDownData
 Description: Calls the API for the Drop Down Data for Report Type: Purchase Program

#       Date                        Name                    Description
1       19/April/2019               Kim Phillip Aying       Initial Draft
 ************************************************************************************/
async getPurchaseProgramDropDownData()
{
  try {
    this.salesHistory = false;
    this.reportsService.getPurchaseProgramDropDownData(this.user.OptNum).subscribe(data => {
      try{
      if(Array.isArray(data[0]) && data[0].length > 0)
      {
        this.purchaseProgramDropDownData = data[0];
        this.programTypeDropDownData = this.purchaseProgramDropDownData;
        if(Object.keys(this.purchaseProgramDropDownData).length<2){

          this.programSelected = this.purchaseProgramDropDownData[0].ProgramNm;
         
          this.programType = this.purchaseProgramDropDownData[0].ProgramNm;
         
          }
         
          else{
         
          this.programSelected = this.purchaseProgramDropDownData[1].ProgramNm;
         
          this.programType = this.purchaseProgramDropDownData[1].ProgramNm;
         
          }

        sessionStorage.setItem("Purchase Program Drop Down Data", JSON.stringify(this.purchaseProgramDropDownData));
      }
    } 
    catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramDropDownData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }, error => {
    this.errorReportsPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramDropDownData");
    error.error.message += errorLocation;
    throw error.error;
  });
  } 
  catch(error) {
    // this.errorReportsPage = true;
    // this.loading = false;
    // let errorLocation = this.errorLocation.replace("methodName", "getExchangeRtCurrencyList");
    // error.message += errorLocation;
    // throw error;
  }
}
/**End of getPurchaseProgramDropDownData method */

/************************************************************************************
 Method Name: getPurchaseProgramData
 Description: Calls the API for the Data for Report Type: Purchase Program

#       Date                        Name                         Description
1       19/April/2019               Kim Phillip Aying(KPA)       Initial Draft
2       05/14/2019                  Katrina Narag                BUG 299030 FIX
3       05/22/19                    KMN                          Bug 318661 fix - Add % sign
4       04/Jun/2019                 KPA                          Fixed Total Row
 ************************************************************************************/
  getPurchaseProgramData(pageIndex)
  {
    try {
      this.reportsService.getPurchaseProgramData(this.user.OptNum, this.programType, this.legitDateFrom, this.legitDateTo, pageIndex).subscribe(data => {
        try{

      if(Array.isArray(data) && data[0].length > 0)
      {
          this.totalPages = 0;
          this.totalPages = Math.ceil(data[2][0].TotalRows/50);
        
          this.totalsPurchaseProgram = data[1];
          
        if(this.programType == "VEIP")
        {
          this.noPurchaseProgram = false;
          this.displayPageNavigator = this.noPurchaseProgram;
          this.noDataInTable = false;

          // let sortedData: Element[] = data[0].sort((a, b) =>  a["PURCHASE DATE"] < b["PURCHASE DATE"] ? 1: a["PURCHASE DATE"] > b["PURCHASE DATE"] ? -1: 0);
          // let sortedData = [data[0].sort((a, b) => a["PURCHASE DATE"] < b["PURCHASE DATE"] ? 1 : a["PURCHASE DATE"] > b["PURCHASE DATE"] ? -1 : 0)];
          let sortedData: Element[] = [data[0]];

          this.displayedColumns = this.displayPurchaseProgramColumnVEIP;
          // this.dataSource = new MatTableDataSource<Element>(sortedData);
          this.dataSource = new MatTableDataSource<Element>(sortedData);
          this.dataSource = this.dataSource.filteredData[0];

          this.length = data[2][0].TotalRows;
          let localCur = this.dataSource[0]["LOCALCURRENCYCD"];

          for(let i=0; i<this.dataSource.length; i++)
          {
            this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (USD)"] = this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (USD)"].toFixed(2);
            this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (USD)"] = this.commafy(this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (USD)"], 2);

            this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"] = this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"].toFixed(2);
            // this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"] = localCur + " " + this.commafy(this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"], 2);
            this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"] = this.dataSource[i]["LOCALCURRENCYCD"] + " " + this.commafy(this.dataSource[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"], 2);

            this.dataSource[i]["EXCHANGE RATE"] = this.dataSource[i]["EXCHANGE RATE"].toFixed(2);
            this.dataSource[i]["EXCHANGE RATE"] = this.commafy(this.dataSource[i]["EXCHANGE RATE"], 2);

            this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (USD)"] = this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (USD)"].toFixed(4);
            this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (USD)"] = this.commafy(this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (USD)"], 4);

            this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"] = this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"].toFixed(2);
            // this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"] = localCur+ " " + this.commafy(this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"], 2);
            this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"] = this.dataSource[i]["LOCALCURRENCYCD"] + " " + this.commafy(this.dataSource[i]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"], 2);

            this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (USD)"] = this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (USD)"].toFixed(2);
            this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (USD)"] = this.commafy(this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (USD)"], 2);

            this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"] = this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"].toFixed(2);
            // this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"] = localCur + " " + this.commafy(this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"], 2);
            this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"] = this.dataSource[i]["LOCALCURRENCYCD"] + " " + this.commafy(this.dataSource[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"], 2);

            this.dataSource[i]["PURCHASE PRICE FMV (USD)"] = this.dataSource[i]["PURCHASE PRICE FMV (USD)"].toFixed(4);
            this.dataSource[i]["PURCHASE PRICE FMV (USD)"] = this.commafy(this.dataSource[i]["PURCHASE PRICE FMV (USD)"], 4);
            
            this.dataSource[i]["TOTAL SHARES PURCHASED"] = this.commafy(this.dataSource[i]["TOTAL SHARES PURCHASED"]);
            this.dataSource[i]["SHARES DISPOSED"] = this.commafy(this.dataSource[i]["SHARES DISPOSED"]);
            this.dataSource[i]["SHARES AVAILABLE"] = this.commafy(this.dataSource[i]["SHARES AVAILABLE"]);
            this.dataSource[i]["CONTRIBUTION %"] = this.dataSource[i]["CONTRIBUTION %"].toString() + "%";
          }

            this.totalsTable = this.totalsPurchaseProgram;
            this.totalsTable[0]['Totals'] = "Totals";

              // this.totalsTable[0]["Program"] = this.totalsTable[0]["Totals"];
              this.totalsTable[0]["Total Contribution %"] = " ";
              this.totalsTable[0]["Total Exchange Rate"] = this.commafy(this.totalsTable[0]["Total Exchange Rate"]);

              this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (Local)"] = parseFloat(this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (Local)"].toFixed(2));
              this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (Local)"] = this.commafy(this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (Local)"], 2);

              this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (USD)"] = parseFloat(this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (USD)"].toFixed(2));
              this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (USD)"] = this.commafy(this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (USD)"], 2);
              
              this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"] = parseFloat(this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"].toFixed(2));
              this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"] = this.commafy(this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"], 2);

              this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"] = parseFloat(this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"].toFixed(2));
              this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"] = this.commafy(this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"], 2);
              
              // this.totalsTable[0]["Total Purchase Price FMV (USD)"] = parseFloat(this.totalsTable[0]["Total Purchase Price FMV (USD)"].tofixed(2));
              this.totalsTable[0]["Total Purchase Price FMV (USD)"] = this.commafy(this.totalsTable[0]["Total Purchase Price FMV (USD)"], 4);

              this.totalsTable[0]["Total Shares Available"] = this.commafy(this.totalsTable[0]["Total Shares Available"]);
              this.totalsTable[0]["Total Shares Disposed"] = this.commafy(this.totalsTable[0]["Total Shares Disposed"]);
              this.totalsTable[0]["Total Shares Purchased"] = this.commafy(this.totalsTable[0]["Total Shares Purchased"]);

              this.totalsTable[0]["Total Value of Shares Purchased (Local)"] = parseFloat(this.totalsTable[0]["Total Value of Shares Purchased (Local)"].toFixed(2));
              this.totalsTable[0]["Total Value of Shares Purchased (Local)"] = this.commafy(this.totalsTable[0]["Total Value of Shares Purchased (Local)"], 2);

              this.totalsTable[0]["Total Value of Shares Purchased (USD)"] = parseFloat(this.totalsTable[0]["Total Value of Shares Purchased (USD)"].toFixed(4));
              this.totalsTable[0]["Total Value of Shares Purchased (USD)"] = this.commafy(this.totalsTable[0]["Total Value of Shares Purchased (USD)"], 4);

              this.totalsTable[0]["Broker for More Information"] = " ";


            this.totalsTable[0]["PROGRAM"] = this.totalsTable[0]["Totals"];
            // this.totalsTable[0]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"] = localCur + " " + this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"];
            this.totalsTable[0]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"] = " ";
            this.totalsTable[0]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (USD)"] = this.totalsTable[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"];
            this.totalsTable[0]["PURCHASE PRICE FMV (USD)"] = this.totalsTable[0]["Total Purchase Price FMV (USD)"];
            this.totalsTable[0]["TOTAL SHARES PURCHASED"] = this.totalsTable[0]["Total Shares Purchased"];
            this.totalsTable[0]["TOTAL VALUE OF SHARES PURCHASED (USD)"] = this.totalsTable[0]["Total Value of Shares Purchased (USD)"];
            // this.totalsTable[0]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"] = localCur + " " + this.totalsTable[0]["Total Value of Shares Purchased (Local)"];
            this.totalsTable[0]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"] = " ";
            this.totalsTable[0]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (USD)"] = this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (USD)"];
            // this.totalsTable[0]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"] = localCur + " " + this.totalsTable[0]["Total Contribution to be Carried Over/Refunded (Local)"];
            this.totalsTable[0]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"] = " ";
            this.totalsTable[0]["SHARES DISPOSED"] = this.totalsTable[0]["Total Shares Disposed"];
            this.totalsTable[0]["SHARES AVAILABLE"] = this.totalsTable[0]["Total Shares Available"];
            this.totalsTable[0]["EXCHANGE RATE"] = " ";
            
              // this.totalsTable[0]["Total Value of Shares Purchased (Local)"] = parseFloat(this.totalsTable[0]["Total Value of Shares Purchased (Local)"].toFixed(2));
              // this.totalsTable[0]["Total Value of Shares Purchased (Local)"] = this.commafy(this.totalsTable[0]["Total Value of Shares Purchased (Local)"], 2);
              // this.totalsTable[0]["Total Value of Shares Purchased (USD)"] = parseFloat(this.totalsTable[0]["Total Value of Shares Purchased (USD)"].toFixed(2));
              // this.totalsTable[0]["Total Value of Shares Purchased (USD)"] = this.commafy(this.totalsTable[0]["Total Value of Shares Purchased (USD)"], 2);
            
            this.displayExportAndPagination = true;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

          if(this.length == this.lengthContainer || this.length < this.lengthContainer)
          {
            // this.displayTotalsPurchaseProgram = true; //to hide the totals table in the HTML side
            // this.totalRowChecker = true;
            this.dataSource.push(this.totalsTable[0]);
            this.loading = false;
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
            setTimeout(()=>{ this.scrollS() }, 1000);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustPurchaseProgramTable() }, 0.0000000000000000001);
            setTimeout(()=>{ this.totalsRowDesign3() }, 0.0000000000000000001);
            // $("#rsuGrants").css('pointer-events','auto');
          }
          else
          {
            // this.displayTotalsPurchaseProgram = false; //to hide the totals table in the HTML side
            // this.totalRowChecker = false;
            this.loading = false;
            setTimeout(()=>{ this.returnScroll() }, 1000);
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustPurchaseProgramTable() }, 0.0000000000000000001);
            // $("#rsuGrants").css('pointer-events','auto');
          }

            // this.displayTotalsTable = this.displayTotalsPurchaseProgram;
            this.tableLoading = false;
            setTimeout(()=>{ this.scrollS() }, 500);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustPurchaseProgramTable() }, 0.0000000000000000001);
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
            
        }
        else if(this.programType == "ESPP")
        {
          this.noPurchaseProgram = false;
          this.displayPageNavigator = this.noPurchaseProgram;
          this.noDataInTable = false;

          this.totalsPurchaseProgram = []; 
          this.totalsPurchaseProgram = data[1];

          // let sortedData: Element[] = [data[0].sort((a, b) => a.PurchaseDt < b.PurchaseDt ? 1: a.PurchaseDt < b.PurchaseDt ? -1: 0)];
          let sortedData = [data[0].sort((a, b) => a.PurchaseDt < b.PurchaseDt ? 1: a.PurchaseDt < b.PurchaseDt ? -1: 0)];

          this.displayedColumns = this.displayPurchaseProgramColumnESPP;
          // this.dataSource = new MatTableDataSource<Element>(sortedData);
          this.dataSource = new MatTableDataSource(sortedData);
          this.dataSource = this.dataSource.filteredData[0];

          this.length = data[2][0].TotalRows;

          let localCur = this.dataSource[0]["LOCALCURRENCYCD"];

          for(let i=0; i<this.dataSource.length; i++)
          {
            if(this.dataSource[i]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"] != null) 
            {
              this.dataSource[i]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"] = this.dataSource[i]["LOCALCURRENCYCD"] + " " + this.commafy(this.dataSource[i]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"].toString(),2);
            }
            else
            {
              this.dataSource[i]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"] = " ";
            }

            this.dataSource[i]["TOTAL CONTRIBUTIONS (LOC)"] = this.dataSource[i]["TOTAL CONTRIBUTIONS (LOC)"].toFixed(2);
            // this.dataSource[i]["TOTAL CONTRIBUTIONS (LOC)"] = localCur + " " +  this.commafy(this.dataSource[i]["TOTAL CONTRIBUTIONS (LOC)"].toString(),2);
            this.dataSource[i]["TOTAL CONTRIBUTIONS (LOC)"] = this.dataSource[i]["LOCALCURRENCYCD"] + " " +  this.commafy(this.dataSource[i]["TOTAL CONTRIBUTIONS (LOC)"].toString(),2);

            this.dataSource[i]["TOTAL CONTRIBUTIONS (USD)"] = this.dataSource[i]["TOTAL CONTRIBUTIONS (USD)"].toFixed(2);
            this.dataSource[i]["TOTAL CONTRIBUTIONS (USD)"] = this.commafy(this.dataSource[i]["TOTAL CONTRIBUTIONS (USD)"].toString(),2);

            this.dataSource[i]["DISCOUNTED PURCHASE PRICE (USD)"] = parseFloat(this.dataSource[i]["DISCOUNTED PURCHASE PRICE (USD)"]).toFixed(4);
            this.dataSource[i]["DISCOUNTED PURCHASE PRICE (USD)"] = this.commafy(this.dataSource[i]["DISCOUNTED PURCHASE PRICE (USD)"].toString(),4);

            this.dataSource[i]["TOTAL SHARES PURCHASED"] = parseFloat(this.dataSource[i]["TOTAL SHARES PURCHASED"]).toFixed(4);
            this.dataSource[i]["TOTAL SHARES PURCHASED"] = this.commafy(this.dataSource[i]["TOTAL SHARES PURCHASED"].toString(),4);

            this.dataSource[i]["FMV AT PURCHASE (USD)"] = parseFloat(this.dataSource[i]["FMV AT PURCHASE (USD)"]).toFixed(4);
            this.dataSource[i]["FMV AT PURCHASE (USD)"] = this.commafy(this.dataSource[i]["FMV AT PURCHASE (USD)"].toString(),4);

            this.dataSource[i]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"] = parseFloat(this.dataSource[i]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"]).toFixed(4);
            // this.dataSource[i]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"] = localCur + " " +  this.commafy(this.dataSource[i]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"].toString(),4);
            this.dataSource[i]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"] = this.dataSource[i]["LOCALCURRENCYCD"] + " " +  this.commafy(this.dataSource[i]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"].toString(),4);

            this.dataSource[i]["VALUE OF DISCOUNT RECEIVED (LOC)"] = parseFloat(this.dataSource[i]["VALUE OF DISCOUNT RECEIVED (LOC)"]).toFixed(2);
            this.dataSource[i]["VALUE OF DISCOUNT RECEIVED (LOC)"] = this.dataSource[i]["LOCALCURRENCYCD"] + " " + this.commafy(this.dataSource[i]["VALUE OF DISCOUNT RECEIVED (LOC)"].toString(),2);

            this.dataSource[i]["SHARES SOLD FOR TAX WITHHOLDING"] = parseFloat(this.dataSource[i]["SHARES SOLD FOR TAX WITHHOLDING"]).toFixed(4);
            this.dataSource[i]["SHARES SOLD FOR TAX WITHHOLDING"] = this.commafy(this.dataSource[i]["SHARES SOLD FOR TAX WITHHOLDING"].toString(),4);

            this.dataSource[i]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"] = parseFloat(this.dataSource[i]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"]).toFixed(2);
            // this.dataSource[i]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"] = localCur + " " +  this.commafy(this.dataSource[i]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"].toString(),2);
            this.dataSource[i]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"] = this.dataSource[i]["LOCALCURRENCYCD"] + " " +  this.commafy(this.dataSource[i]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"].toString(),2);
            
            this.dataSource[i]["SHARES SOLD/DISPOSED"] = parseFloat(this.dataSource[i]["SHARES SOLD/DISPOSED"]).toFixed();
            this.dataSource[i]["SHARES SOLD/DISPOSED"] = this.commafy(this.dataSource[i]["SHARES SOLD/DISPOSED"].toString());

            this.dataSource[i]["FRACTIONAL SHARES SOLD FOR REFUND"] = parseFloat(this.dataSource[i]["FRACTIONAL SHARES SOLD FOR REFUND"]).toFixed(4);
            this.dataSource[i]["FRACTIONAL SHARES SOLD FOR REFUND"] = this.commafy(this.dataSource[i]["FRACTIONAL SHARES SOLD FOR REFUND"].toString(),4);

            this.dataSource[i]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"] = parseFloat(this.dataSource[i]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"]).toFixed(2);
            // this.dataSource[i]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"] = localCur + " " +  this.commafy(this.dataSource[i]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"].toString(),2);
            this.dataSource[i]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"] = this.dataSource[i]["LOCALCURRENCYCD"] + " " +  this.commafy(this.dataSource[i]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"].toString(),2);
            
            this.dataSource[i]["CONTRIBUTION %"] = this.dataSource[i]["CONTRIBUTION %"].toString() + "%";
          }

            this.totalsTable = this.totalsPurchaseProgram;
            this.totalsTable[0]["Totals"] = "TOTAL";
            this.totalsTable[0]["Total Contribution %"] = " ";
            this.totalsTable[0]["Total Exchange Rate"] = " ";

            // this.totalsTable[0]["Total Actual tax reported by Payroll (in Loc)"] = this.totalsTable[0]["Total Actual tax reported by Payroll (in Loc)"].toFixed(2);
            this.totalsTable[0]["Total Actual tax reported by Payroll (in Loc)"] = this.commafy(this.totalsTable[0]["Total Actual tax reported by Payroll (in Loc)"], 2);

            this.totalsTable[0]["Total Contributions (Loc)"] = parseFloat(this.totalsTable[0]["Total Contributions (Loc)"]).toFixed(2);
            this.totalsTable[0]["Total Contributions (Loc)"] = localCur + " " +  this.commafy(this.totalsTable[0]["Total Contributions (Loc)"], 2);

            this.totalsTable[0]["Total Contributions (USD)"] = parseFloat(this.totalsTable[0]["Total Contributions (USD)"]).toFixed(2);
            this.totalsTable[0]["Total Contributions (USD)"] = this.commafy(this.totalsTable[0]["Total Contributions (USD)"], 2);

            this.totalsTable[0]["Total Discounted Purchase Price (USD)"] = parseFloat(this.totalsTable[0]["Total Discounted Purchase Price (USD)"]).toFixed(4);
            this.totalsTable[0]["Total Discounted Purchase Price (USD)"] = this.commafy(this.totalsTable[0]["Total Discounted Purchase Price (USD)"], 4);

            this.totalsTable[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"] = parseFloat(this.totalsTable[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"]).toFixed(4);
            this.totalsTable[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"] = localCur + " " +  this.commafy(this.totalsTable[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"], 4);

            // this.totalsTable[0]["Total FMV at Purchase (USD)"] = this.totalsTable[0]["Total FMV at Purchase (USD)"].toFixed(2);
            this.totalsTable[0]["Total FMV at Purchase (USD)"] = this.commafy(this.totalsTable[0]["Total FMV at Purchase (USD)"], 2);

            this.totalsTable[0]["Total Fractional Shares Sold for Refund"] = parseFloat(this.totalsTable[0]["Total Fractional Shares Sold for Refund"]).toFixed(4);
            this.totalsTable[0]["Total Fractional Shares Sold for Refund"] = this.commafy(this.totalsTable[0]["Total Fractional Shares Sold for Refund"], 4);

            this.totalsTable[0]["Total Shares Available"] = parseFloat(this.totalsTable[0]["Total Shares Available"]).toFixed();
            this.totalsTable[0]["Total Shares Available"] = this.commafy(this.totalsTable[0]["Total Shares Available"]);

            this.totalsTable[0]["Total Shares Purchased"] = parseFloat(this.totalsTable[0]["Total Shares Purchased"]).toFixed(4);
            this.totalsTable[0]["Total Shares Purchased"] = this.commafy(this.totalsTable[0]["Total Shares Purchased"], 4);

            this.totalsTable[0]["Total Shares Sold for Tax Withholding"] = parseFloat(this.totalsTable[0]["Total Shares Sold for Tax Withholding"]).toFixed(4);
            this.totalsTable[0]["Total Shares Sold for Tax Withholding"] = this.commafy(this.totalsTable[0]["Total Shares Sold for Tax Withholding"], 4);

            this.totalsTable[0]["Total Shares Sold/Disposed"] = parseFloat(this.totalsTable[0]["Total Shares Sold/Disposed"]).toFixed();
            this.totalsTable[0]["Total Shares Sold/Disposed"] = this.commafy(this.totalsTable[0]["Total Shares Sold/Disposed"]);

            this.totalsTable[0]["Total Value of Discount Received (Loc)"] = parseFloat(this.totalsTable[0]["Total Value of Discount Received (Loc)"]).toFixed(2);
            this.totalsTable[0]["Total Value of Discount Received (Loc)"] = this.commafy(this.totalsTable[0]["Total Value of Discount Received (Loc)"], 2);

            this.totalsTable[0]["Total Value of Fractional Shares Sold for Refund (Loc)"] = parseFloat(this.totalsTable[0]["Total Value of Fractional Shares Sold for Refund (Loc)"]).toFixed(2);
            this.totalsTable[0]["Total Value of Fractional Shares Sold for Refund (Loc)"] = localCur + " " +  this.commafy(this.totalsTable[0]["Total Value of Fractional Shares Sold for Refund (Loc)"], 2);

            this.totalsTable[0]["Total Value of Shares Sold for Tax Withholding (Loc)"] = parseFloat(this.totalsTable[0]["Total Value of Shares Sold for Tax Withholding (Loc)"]).toFixed(2);
            this.totalsTable[0]["Total Value of Shares Sold for Tax Withholding (Loc)"] = localCur + " " +  this.commafy(this.totalsTable[0]["Total Value of Shares Sold for Tax Withholding (Loc)"], 2);
            
          // ["PROGRAM", "OFFERING PERIOD", "CONTRIBUTION %", "TOTAL CONTRIBUTIONS (LOC)", "EXCHANGE RATE", "TOTAL CONTRIBUTIONS (USD)", 
          // "DISCOUNTED PURCHASE PRICE (USD)", "TOTAL SHARES PURCHASED", "FMV AT PURCHASE (USD)", "FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)", 
          // "VALUE OF DISCOUNT RECEIVED (LOC)", "SHARES SOLD FOR TAX WITHHOLDING", "VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)", 
          // "FRACTIONAL SHARES SOLD FOR REFUND", "VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)", "SHARES SOLD/DISPOSED", "SHARES AVAILABLE", "ACCOUNT", 
          // "BROKER FOR MORE INFORMATION", "ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"];

          this.totalsTable[0]["PROGRAM"] = this.totalsTable[0]["Totals"];
          // this.totalsTable[0]["TOTAL CONTRIBUTIONS (LOC)"] = this.totalsTable[0]["Total Contributions (Loc)"];
          this.totalsTable[0]["TOTAL CONTRIBUTIONS (LOC)"] = " ";
          this.totalsTable[0]["TOTAL CONTRIBUTIONS (USD)"] = this.totalsTable[0]["Total Contributions (USD)"];
          // this.totalsTable[0]["DISCOUNTED PURCHASE PRICE (USD)"] = this.totalsTable[0]["Total Discounted Purchase Price (USD)"];

          this.totalsTable[0]["DISCOUNTED PURCHASE PRICE (USD)"] = " ";

          this.totalsTable[0]["TOTAL SHARES PURCHASED"] = this.totalsTable[0]["Total Shares Purchased"];
          // this.totalsTable[0]["FMV AT PURCHASE (USD)"] = this.totalsTable[0]["Total FMV at Purchase (USD)"];
          
          this.totalsTable[0]["FMV AT PURCHASE (USD)"] = " ";

          // this.totalsTable[0]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"] = this.totalsTable[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"];
          this.totalsTable[0]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"] = " ";
          // this.totalsTable[0]["VALUE OF DISCOUNT RECEIVED (LOC)"] = this.totalsTable[0]["Total Value of Discount Received (Loc)"];
          this.totalsTable[0]["VALUE OF DISCOUNT RECEIVED (LOC)"] = " ";
          this.totalsTable[0]["SHARES SOLD FOR TAX WITHHOLDING"] = this.totalsTable[0]["Total Shares Sold for Tax Withholding"];
          // this.totalsTable[0]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"] = this.totalsTable[0]["Total Value of Shares Sold for Tax Withholding (Loc)"];
          this.totalsTable[0]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"] = " ";
          this.totalsTable[0]["FRACTIONAL SHARES SOLD FOR REFUND"] = this.totalsTable[0]["Total Fractional Shares Sold for Refund"];
          // this.totalsTable[0]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"] = this.totalsTable[0]["Total Value of Fractional Shares Sold for Refund (Loc)"];
          this.totalsTable[0]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"] = " ";
          this.totalsTable[0]["SHARES SOLD/DISPOSED"] = this.totalsTable[0]["Total Shares Sold/Disposed"];
          this.totalsTable[0]["SHARES AVAILABLE"] = this.totalsTable[0]["Total Shares Available"];
          // this.totalsTable[0]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"] = this.totalsTable[0]["Total Actual tax reported by Payroll (in Loc)"];
          this.totalsTable[0]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"] = " ";

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

          if(this.length == this.lengthContainer || this.length<this.lengthContainer)
          {
            // this.displayTotalsPurchaseProgram = true; //to hide the totals table in the HTML side
            this.dataSource.push(this.totalsTable[0]);
            this.loading = false;
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
            setTimeout(()=>{ this.scrollS() }, 500);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustPurchaseProgramTable() }, 0.0000000000000000001);
            setTimeout(()=>{ this.totalsRowDesign3() }, 0.0000000000000000001);
            // $("#rsuGrants").css('pointer-events','auto');
          }
          else
          {
            // this.displayTotalsPurchaseProgram = false; //to hide the totals table in the HTML side
            this.loading = false;
            setTimeout(()=>{ this.returnScroll() }, 500);
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustPurchaseProgramTable() }, 0.0000000000000000001);
            // $("#rsuGrants").css('pointer-events','auto');
          }
            // this.displayTotalsTable = this.displayTotalsPurchaseProgram;
            this.displayExportAndPagination = true;
            this.tableLoading = false;
            setTimeout(()=>{ this.scrollS() }, 500);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustPurchaseProgramTable() }, 0.0000000000000000001);
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
        }
      }
        else
        {
          this.noPurchaseProgram = true;
          this.displayPageNavigator = this.noPurchaseProgram;
          // this.displayTotalsPurchaseProgram = false;
          // this.displayTotalsTable = this.displayTotalsPurchaseProgram;
          this.displayExportAndPagination = false;
          this.noDataInTable = true;
          this.tableLoading = false;
          setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
          setTimeout(()=>{ this.adjustPurchaseProgramTable() }, 0.0000000000000000001);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }, error => {
    let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramData");
    error.error.message += errorLocation;
    throw error.error;
  });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getPurchaseProgramData");
      error.message += errorLocation;
      throw error;
    }
  }
/**End of getPurchaseProgramData method */

/************************************************************************************
 Method Name: getRSUActivityDropDownData
 Description: Calls the API for the Drop Down Data for Report Type: RSU Activity

#       Date                        Name                    Description
1       19/April/2019               Kim Phillip Aying       Initial Draft
 ************************************************************************************/
async getRSUActivityDropDownData()
{
  try {
    this.rsuActgrantsOnLoad = "";
    this.salesHistory = false;
    this.reportsService.getRSUActivityDropDownData(this.user.OptNum).subscribe(data => {
      try{
      if(Array.isArray(data[0]) && data[0].length > 0)
      {
        this.rsuActivityDropDownData = data[0];
        this.grantDescList = this.rsuActivityDropDownData;
        
        // for(let i=0; i<this.grantDescList.length;i++)
        // {
        //   this.selectedRSUGrants[i] = (this.grantDescList[i]["GrantLongDesc"]);
        // }

        // this.dropdownSettings = {
        //   singleSelection: false,
        //   textField: 'item_text',
        //   selectAllText: 'All Grants',
        //   unSelectAllText: 'All Grants',
        //   itemsShowLimit: 5,
        // };

        this.selectedRSUActivityGrants = this.grantDescList;        
        
        if(this.grantDescList.length > 0){
          for(let i = 0; i < this.grantDescList.length; i++) {
            if(i < this.grantDescList.length - 1) {
              this.rsuActgrantsOnLoad = this.rsuActgrantsOnLoad + this.grantDescList[i].GrantLongDesc + ",";
            } else {
              this.rsuActgrantsOnLoad = this.rsuActgrantsOnLoad + this.grantDescList[i].GrantLongDesc;
            }
            this.grantDescList[i]["isChecked"] = true;
          }
          this.allGrantsisCheck = true;
          this.counterGrantsRSU = this.grantDescList.length;
          this.selectedGrantsFromRSUGrantList = this.rsuActgrantsOnLoad;
          
          sessionStorage.setItem("rsuActgrantsOnLoad",this.rsuActgrantsOnLoad);
        }
        sessionStorage.setItem("RSU Activity Drop Down Data", JSON.stringify(this.rsuActivityDropDownData));
      }
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getRSUActivityDropDownData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }, error => {
    this.errorReportsPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getRSUActivityDropDownData");
    error.error.message += errorLocation;
    throw error.error;
  });
  } catch(error) {
    this.errorReportsPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getRSUActivityDropDownData");
    error.message += errorLocation;
    throw error;
  }
}
/**End of getRSUActivityDropDownData method */

/************************************************************************************
 Method Name: selectAllRSUDropDownData
 Description: Method that selects all Grants for RSU Activity Table

#       Date                        Name                    Description
1       01/May/2019                 Kim Phillip Aying       Initial Draft
 ************************************************************************************/
// selectAllRSUDropDownData()
// {
//   try{
//   for(let i=0; i<this.grantDescList.length; i++)
//   {
//     this.grantDescList[i].selected = this.selectedAllRSUDropDownData;
//     this.grantDescription[i] = this.grantDescList[i];
//   }
// } catch(error) {
//   this.errorReportsPage = true;
//   this.loading = false;
//   let errorLocation = this.errorLocation.replace("methodName", "selectAllRSUDropDownData");
//   error.message = error.message + errorLocation;
//   throw error;
// }

// }
/**End of selectAllRSUDropDownData method */

/************************************************************************************
 Method Name: checkIfAllRSUDropDownData
 Description: Method that checks if all Grants are selected for RSU Activity Table

#       Date                        Name                    Description
1       01/May/2019                 Kim Phillip Aying       Initial Draft
 ************************************************************************************/
// checkIfAllRSUDropDownData(program)
// {
//   try{
//   this.selectedAllRSUDropDownData = this.grantDescList.every(function(item:any)
//   {
//     return item.selected == true;
//   })
// } catch(error) {
//   this.errorReportsPage = true;
//   this.loading = false;
//   let errorLocation = this.errorLocation.replace("methodName", "checkIfAllRSUDropDownData");
//   error.message = error.message + errorLocation;
//   throw error;
// }
// }
/**End of checkIfAllRSUDropDownData method */

/************************************************************************************
 Method Name: adjustPurchaseProgramTable
 Description: Method to adjust the CSS Style for Purchase Program Table

#       Date                        Name                    Description
1       24/April/2019               Kim Phillip Aying       Initial Draft
 ************************************************************************************/
adjustPurchaseProgramTable()
{
  $("mat-header-row").css({"width": "400%","min-width": "3000px", "text-align": "center"});
  $("mat-row").css({"width": "400%", "min-width": "3000px", "text-align": "center"});
}
/**End of adjustPurchaseProgramTable method */

/************************************************************************************
 Method Name: adjustRSUActivityTable
 Description: Method to adjust the CSS Style for RSU Activity Table

#       Date                        Name                    Description
1       01/May/2019                 Kim Phillip Aying       Initial Draft
 ************************************************************************************/
adjustRSUActivityTable()
{
  $("mat-header-row").css({"width": "500%","min-width": "4000px", "text-align": "center"});
  $("mat-row").css({"width": "500%", "min-width": "4000px", "text-align": "center"});
}
/**End of adjustRSUActivityTable method */


/************************************************************************************
 Method Name: adjustExchangeRateHistoryTable
 Description: Method to adjust the CSS Style for Exchange Rate History Table

#       Date                        Name                    Description
1       28/May/2019               Kim Phillip Aying       Initial Draft
 ************************************************************************************/
adjustExchangeRateHistoryTable()
{
  $("mat-header-row").css({"width": "100%","min-width": "900px", "text-align": "center"});
  $("mat-row").css({"width": "100%", "min-width": "900px", "text-align": "center"});
}
/**End of adjustExchangeRateHistoryTable method */

/************************************************************************************
 Method Name: adjustIndividualTransferLimitDataTable
 Description: Method to adjust the CSS Style for Individual Transfer Limit Data Table

#       Date                        Name                    Description
1       05/Jun/2019               Kim Phillip Aying       Initial Draft
 ************************************************************************************/
adjustIndividualTransferLimitDataTable()
{
  $("mat-header-row").css({"width": "200%","min-width": "900px", "text-align": "center"});
  $("mat-row").css({"width": "200%", "min-width": "900px", "text-align": "center"});
}
/**End of adjustIndividualTransferLimitDataTable method */

// checkAll(event){
//   let checker: boolean;
//  try{
//   if (event.checked == false){
//     this.checkPrograms = false;
//     this.checkProgramsAll = false;
//     checker = false;
//    } else if (event.checked == true){
//     checker = true;
//     checker = true;
//     this.checkPrograms = true;
//    }
//   } catch(error) {
//     let errorLocation = this.errorLocation.replace("methodName", "checkAll");
//     error.message = error.message + errorLocation;
//     throw error;
//   }
// }

// checkAllGrants(event){
//   try{
//   let checker: boolean;

//   if(event.checked == false){
//     this.checkGrants = false;
//     this.checkAllGrantsText = false;
//     checker = false;
//   } else if (event.checked == true){
//     this.checkGrants = true;
//     this.checkAllGrantsText = true;
//     checker = true;
//   }

//   if(this.SalesHistoryGrantData.length > 0){
//     for(let i = 0; i < this.SalesHistoryGrantData.length; i++) {
//       this.SalesHistoryGrantData[i].checkGrants = checker;
//     }
//   }

//   } catch(error) {
//     let errorLocation = this.errorLocation.replace("methodName", "checkAllGrants");
//     error.message = error.message + errorLocation;
//     throw error;
//   }
// }

/************************************************************************************
 Method Name: adjustSalesHistoryTable
 Description: Method to adjust the CSS Style for Sales History Table

#       Date               Name                    Description
1       04/30/19           Jayson Sevilla          Initial Draft
 ************************************************************************************/
adjustSalesHistoryTable()
{
  $("mat-header-row").css({"width": "400%","min-width": "900px", "text-align": "center"});
  $("mat-row").css({"width": "400%", "min-width": "900px", "text-align": "center"});
}
/**End of adjustSalesHistoryTable method */

/************************************************************************************
 Method Name: getRSUActivityData
 Description: Calls the API for the Data for Report Type: RSU Activity

#       Date                        Name                    Description
1       19/April/2019               Kim Phillip Aying       Initial Draft
2       09/May/ 2019                Roldan Mazo             Added Totals Row
3       14/May/2019                 Jikho S. Enrique        Modification
4       15/May/2019                 Jayson Sevilla          Total Row Codes Adjust
5       05/Jun/2019                 Kim Phillip Aying       Modified Total Row Codes
 ************************************************************************************/
getRSUActivityData(pageIndex)
{
  try {
    let grantDescription = sessionStorage.getItem("rsuActivitySelectedGrant");
    let selectedRSUActivityGrants = sessionStorage.getItem("selectedRSUActivityGrants");
    let isAllGrantsChecked = sessionStorage.getItem("isAllGrantsChecked");

    grantDescription != null ? "" : grantDescription = sessionStorage.getItem("rsuActgrantsOnLoad");
    grantDescription != "" ? "" : grantDescription = sessionStorage.getItem("rsuActgrantsOnLoad");
    isAllGrantsChecked == "Y" ? grantDescription = sessionStorage.getItem("rsuActgrantsOnLoad") : ""; 

    this.reportsService.getRSUActivityDataData(this.user.OptNum, this.legitDateFrom, this.legitDateTo, pageIndex, this.selectedGrantsFromRSUGrantList).subscribe(data => {
    try{

      let rsuTotal: Element[] = data[1];
      let rsuTotalityRow: Element[] = data[2];
      this.totalPages = 0;
      this.totalPages = Math.ceil(data[2][0].TotalRows/50);
      setTimeout(()=>{ this.adjustRSUActivityTable() }, 0.0000000000000000001);

    if(Array.isArray(data[0]) && data[0].length > 0)
    {
        let tempSortedData = data[0];
        this.rsuActivitytData = tempSortedData;
        this.noRSUActivity = false;
        this.displayPageNavigator = this.noRSUActivity;
        this.noDataInTable = false;
        // let sortedData: Element[] = [data[0].sort((a, b) => a.GrantDate < b.GrantDate ? 1: a.GrantDate > b.GrantDate ? -1: 0)];
        // let sortedData: Element[] = [data[0].sort((a, b) =>  moment(a["Transaction Date"]).format("YYYY/MM/DD") < moment(b["Transaction Date"]).format("YYYY/MM/DD") ? 1: moment(a["Transaction Date"]).format("YYYY/MM/DD") > moment(b["Transaction Date"]).format("YYYY/MM/DD") ? -1: 0)];
        let sortedData = [data[0].sort((a, b) =>  moment(a["Transaction Date"]).format("YYYY/MM/DD") < moment(b["Transaction Date"]).format("YYYY/MM/DD") ? 1: moment(a["Transaction Date"]).format("YYYY/MM/DD") > moment(b["Transaction Date"]).format("YYYY/MM/DD") ? -1: 0)];
        // let sortedData = [data[0].sort((a, b) =>  a["Transaction Date"] < b["Transaction Date"] ? 1: a["Transaction Date"] > b["Transaction Date"] ? -1: 0)];
        this.displayedColumns = this.displayRSUActivityColumn;
        // this.dataSource = new MatTableDataSource<Element>(sortedData);
        this.dataSource = new MatTableDataSource(sortedData);
        this.dataSource = this.dataSource.filteredData[0];
        this.totalsTable = rsuTotal;
        this.length = data[2][0].TotalRows;
        for(let i=0; i<this.dataSource.length; i++)
        {

          if(this.dataSource[i]["Fair Market Value per Share on Release Date (USD)"] != null)
          {
            this.dataSource[i]["Fair Market Value per Share on Release Date (USD)"] = parseFloat(this.dataSource[i]["Fair Market Value per Share on Release Date (USD)"]).toFixed(4);
            this.dataSource[i]["Fair Market Value per Share on Release Date (USD)"] = this.commafy(this.dataSource[i]["Fair Market Value per Share on Release Date (USD)"], 4);
          }
          else
          {
            this.dataSource[i]["Fair Market Value per Share on Release Date (USD)"] = " ";
          }
          // if(this,this.dataSource)
          if(this.dataSource[i]["Exchange Rate in Event Date"] != null)
          {
            this.dataSource[i]["Exchange Rate in Event Date"] = parseFloat(this.dataSource[i]["Exchange Rate in Event Date"]).toFixed(4);
            this.dataSource[i]["Exchange Rate in Event Date"] = this.commafy(this.dataSource[i]["Exchange Rate in Event Date"], 4);
          }
          else
          {
            this.dataSource[i]["Exchange Rate in Event Date"] = " ";
          }
          //end
          if(this.dataSource[i]["Shares Vested"] != null)
          {
            // this.dataSource[i]["Shares Vested"] = parseFloat(this.dataSource[i]["Shares Vested"]).toFixed(2);
            this.dataSource[i]["Shares Vested"] = this.commafy(this.dataSource[i]["Shares Vested"]);
          }
          else
          {
            this.dataSource[i]["Shares Vested"] = " ";
          }
          
          if(this.dataSource[i]["Shares Released"] != null)
          {
            // this.dataSource[i]["Shares Released"] = parseFloat(this.dataSource[i]["Shares Released"]).toFixed(2);
            this.dataSource[i]["Shares Released"] = this.commafy(this.dataSource[i]["Shares Released"]);
          }
          else
          {
            this.dataSource[i]["Shares Released"] = " ";
          }

          if(this.dataSource[i]["Total Value on Transaction Date in Local Currency"] != null)
          {
            this.dataSource[i]["Total Value on Transaction Date in Local Currency"] = parseFloat(this.dataSource[i]["Total Value on Transaction Date in Local Currency"]).toFixed(2);
            this.dataSource[i]["Total Value on Transaction Date in Local Currency"] = this.commafy(this.dataSource[i]["Total Value on Transaction Date in Local Currency"], 2);
          }
          else
          {
            this.dataSource[i]["Total Value on Transaction Date in Local Currency"] = " ";
          }

          if(this.dataSource[i]["Shares Withheld For Taxes"] != null)
          {
            this.dataSource[i]["Shares Withheld For Taxes"] = parseFloat(this.dataSource[i]["Shares Withheld For Taxes"]).toFixed(2);
            this.dataSource[i]["Shares Withheld For Taxes"] = this.commafy(this.dataSource[i]["Shares Withheld For Taxes"], 2);
          }
          else
          {
            this.dataSource[i]["Shares Withheld For Taxes"] = " ";
          }

          if(this.dataSource[i]["Total Value of Taxes Withheld in Local Currency"] != null)
          {
            this.dataSource[i]["Total Value of Taxes Withheld in Local Currency"] = parseFloat(this.dataSource[i]["Total Value of Taxes Withheld in Local Currency"]).toFixed(2);
            this.dataSource[i]["Total Value of Taxes Withheld in Local Currency"] = this.commafy(this.dataSource[i]["Total Value of Taxes Withheld in Local Currency"], 2);
          }
          else
          {
            this.dataSource[i]["Total Value of Taxes Withheld in Local Currency"] = " ";
          }
          
          if(this.dataSource[i]["Sale-Fractional Share"] != null)
          {
            this.dataSource[i]["Sale-Fractional Share"] = parseFloat(this.dataSource[i]["Sale-Fractional Share"]).toFixed(4);
            this.dataSource[i]["Sale-Fractional Share"] = this.commafy(this.dataSource[i]["Sale-Fractional Share"], 4); 
          }
          else
          {
            this.dataSource[i]["Sale-Fractional Share"] = " ";
          }
          
          if(this.dataSource[i]["Value of Fractional Share Returned in Local Currency"] != null)
          {
            this.dataSource[i]["Value of Fractional Share Returned in Local Currency"] = parseFloat(this.dataSource[i]["Value of Fractional Share Returned in Local Currency"]).toFixed(2);
            this.dataSource[i]["Value of Fractional Share Returned in Local Currency"] = this.commafy(this.dataSource[i]["Value of Fractional Share Returned in Local Currency"], 2);
          }
          else
          {
            this.dataSource[i]["Value of Fractional Share Returned in Local Currency"] = " ";
          }

          if(this.dataSource[i]["Total Shares W/H"] != null)
          {
            this.dataSource[i]["Total Shares W/H"] = parseFloat(this.dataSource[i]["Total Shares W/H"]).toFixed(2);
            this.dataSource[i]["Total Shares W/H"] = this.commafy(this.dataSource[i]["Total Shares W/H"], 2);
          }
          else
          {
            this.dataSource[i]["Total Shares W/H"] = " ";
          }
          
          if(this.dataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] != null)
          {
            this.dataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = parseFloat(this.dataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"]).toFixed(2);
            this.dataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = this.commafy(this.dataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"], 2);
          }
          else
          {
            this.dataSource[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = " ";
          }
          
          if(this.dataSource[i]["Shares Released to Broker"] != null)
          {
            this.dataSource[i]["Shares Released to Broker"] = parseFloat(this.dataSource[i]["Shares Released to Broker"]).toFixed(2);
            this.dataSource[i]["Shares Released to Broker"] = this.commafy(this.dataSource[i]["Shares Released to Broker"], 2);
          }
          else
          {
            this.dataSource[i]["Shares Released to Broker"] = " ";
          }
        }

          this.totalsTable[0]["Total"] = "Totals";
          this.totalsTable[0]["Total Fair Market Value per Share on Release Date (USD)"] = parseFloat(this.totalsTable[0]["Total Fair Market Value per Share on Release Date (USD)"]).toFixed(4);
          this.totalsTable[0]["Total Fair Market Value per Share on Release Date (USD)"] = this.commafy(this.totalsTable[0]["Total Fair Market Value per Share on Release Date (USD)"], 4);
          this.totalsTable[0]["Total Sale-Fractional Share"] = parseFloat(this.totalsTable[0]["Total Sale-Fractional Share"]).toFixed(4);
          this.totalsTable[0]["Total Sale-Fractional Share"] = this.commafy(this.totalsTable[0]["Total Sale-Fractional Share"], 4);
          // this.totalsTable[0]["Total Shares Released"] = parseFloat(this.totalsTable[0]["Total Shares Released"]).toFixed(2);
          this.totalsTable[0]["Total Shares Released"] = this.commafy(this.totalsTable[0]["Total Shares Released"]);
          this.totalsTable[0]["Total Shares Released to Broker"] = parseFloat(this.totalsTable[0]["Total Shares Released to Broker"]).toFixed(2);
          this.totalsTable[0]["Total Shares Released to Broker"] = this.commafy(this.totalsTable[0]["Total Shares Released to Broker"], 2);
          // this.totalsTable[0]["Total Shares Vested"] = parseFloat(this.totalsTable[0]["Total Shares Vested"]).toFixed(2);
          this.totalsTable[0]["Total Shares Vested"] = this.commafy(this.totalsTable[0]["Total Shares Vested"]);
          this.totalsTable[0]["Total Shares W/H"] = parseFloat(this.totalsTable[0]["Total Shares W/H"]).toFixed(2);
          this.totalsTable[0]["Total Shares W/H"] = this.commafy(this.totalsTable[0]["Total Shares W/H"], 2);
          this.totalsTable[0]["Total Shares Withheld For Taxes"] = parseFloat(this.totalsTable[0]["Total Shares Withheld For Taxes"]).toFixed(2);
          this.totalsTable[0]["Total Shares Withheld For Taxes"] = this.commafy(this.totalsTable[0]["Total Shares Withheld For Taxes"], 2);
          this.totalsTable[0]["Total Value of Fractional Share Returned in Local Currency"] = parseFloat(this.totalsTable[0]["Total Value of Fractional Share Returned in Local Currency"]).toFixed(2);
          this.totalsTable[0]["Total Value of Fractional Share Returned in Local Currency"] = this.commafy(this.totalsTable[0]["Total Value of Fractional Share Returned in Local Currency"], 2);
          this.totalsTable[0]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = parseFloat(this.totalsTable[0]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"]).toFixed(2);
          this.totalsTable[0]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = this.commafy(this.totalsTable[0]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"], 2);
          this.totalsTable[0]["Total Value of Taxes Withheld in Local Currency"] = parseFloat(this.totalsTable[0]["Total Value of Taxes Withheld in Local Currency"]).toFixed(2);
          this.totalsTable[0]["Total Value of Taxes Withheld in Local Currency"] = this.commafy(this.totalsTable[0]["Total Value of Taxes Withheld in Local Currency"], 2);
          this.totalsTable[0]["Total Value on Transaction Date in Local Currency"] = parseFloat(this.totalsTable[0]["Total Value on Transaction Date in Local Currency"]).toFixed(2);
          this.totalsTable[0]["Total Value on Transaction Date in Local Currency"] = this.commafy(this.totalsTable[0]["Total Value on Transaction Date in Local Currency"], 2);

          this.totalsTable[0]["Program"] = this.totalsTable[0]["Total"];
          // this.totalsTable[0]["Fair Market Value per Share on Release Date (USD)"] = this.totalsTable[0]["Total Fair Market Value per Share on Release Date (USD)"];
          this.totalsTable[0]["Fair Market Value per Share on Release Date (USD)"] = " ";
          this.totalsTable[0]["Shares Vested"] = this.totalsTable[0]["Total Shares Vested"];
          this.totalsTable[0]["Shares Released"] = this.totalsTable[0]["Total Shares Released"];
          // this.totalsTable[0]["Total Value on Transaction Date in Local Currency"] = this.totalsTable[0]["Total Value on Transaction Date in Local Currency"];
          this.totalsTable[0]["Total Value on Transaction Date in Local Currency"] = " ";
          this.totalsTable[0]["Shares Withheld For Taxes"] = this.totalsTable[0]["Total Shares Withheld For Taxes"];
          this.totalsTable[0]["Total Value of Taxes Withheld in Local Currency"] = this.totalsTable[0]["Total Value of Taxes Withheld in Local Currency"];
          this.totalsTable[0]["Sale-Fractional Share"] = this.totalsTable[0]["Total Sale-Fractional Share"];
          this.totalsTable[0]["Value of Fractional Share Returned in Local Currency"] = this.totalsTable[0]["Total Value of Fractional Share Returned in Local Currency"];
          this.totalsTable[0]["Total Shares W/H"] = this.totalsTable[0]["Total Shares W/H"];
          this.totalsTable[0]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = this.totalsTable[0]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"];
          this.totalsTable[0]["Shares Released to Broker"] = this.totalsTable[0]["Total Shares Released to Broker"];

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.displayExportAndPagination = true;
      this.salesHisttableLoading = false;
      
      if(this.length == this.lengthContainer || this.length<this.lengthContainer)
      {
        // this.displayTotalsPurchaseProgram = true; //to hide the totals table in the HTML side
        // this.totalRowChecker = true;
        this.dataSource.push(this.totalsTable[0]);
        this.loading = false;
        setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
        setTimeout(()=>{ this.scrollS() }, 1000);
        setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
        setTimeout(()=>{ this.adjustRSUActivityTable() }, 0.0000000000000000001);
        setTimeout(()=>{ this.totalsRowDesign3() }, 0.0000000000000000001);
        $("#rsuGrants").css('pointer-events','auto');
      }
      else
      {
        // this.displayTotalsPurchaseProgram = false; //to hide the totals table in the HTML side
        // this.totalRowChecker = false;
        this.loading = false;
        setTimeout(()=>{ this.returnScroll() }, 1000);
        setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
        setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
        setTimeout(()=>{ this.adjustRSUActivityTable() }, 0.0000000000000000001);
        $("#rsuGrants").css('pointer-events','auto');
      }

      // if(this.length > this.lengthContainer){

      //       if((this.length-this.lengthContainer) > 50){
      //       this.lengthContainer += 50;
      //   this.length = this.lengthContainer;
      //   this.rsuTotal = false;
      //   setTimeout(()=>{ this.returnScroll() }, 500);
      //   $("#rsuGrants").css('pointer-events','auto');
        
      //       }else{
      //       this.lengthContainer = this.length-this.lengthContainer;
      //   this.length = this.dataSource.length;
      //   this.loading = false;
      //   this.rsuTotal = true;
      //   setTimeout(()=>{ this.scrollS() }, 500);
      //   $("#rsuGrants").css('pointer-events','auto');      
      //       }
      // }

      // if(this.rsuTotalRow > this.lengthContainer)
      // {
      //   this.length = this.lengthContainer;
      //   this.rsuTotal = false;
      //   setTimeout(()=>{ this.returnScroll() }, 500);
      //   $("#rsuGrants").css('pointer-events','auto');
      //   this.paginatorEnabler();
      // }
      // else
      // {
      //   this.length = this.dataSource.length;
      //   this.loading = false;
      //   this.rsuTotal = true;
      //   setTimeout(()=>{ this.scrollS() }, 500);
      //   $("#rsuGrants").css('pointer-events','auto');
      //   this.paginatorEnabler();
      //   // setTimeout(()=>{ this.removeUnwantedStyles() }, 500);
      // }

    }else{
      this.noRSUActivity = true;
      this.displayExportAndPagination = false;
      this.displayPageNavigator = this.noRSUActivity;
      this.noDataInTable = true;
      this.salesHisttableLoading = false;
      $("#rsuGrants").css('pointer-events','auto');
      this.paginatorEnabler();
   
    }
    setTimeout(()=>{ this.removeUnwantedStyles() }, 500);
    setTimeout(()=>{ this.adjustRSUActivityTable() }, 0.0000000000000000001);
    this.paginatorEnabler();
  } catch(error) {
    this.errorReportsPage = true;
    this.salesHisttableLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getRSUActivityData");
    error.message = error.message + errorLocation;
    throw error;
  }
  }, error => {
    this.errorReportsPage = true;
    this.salesHisttableLoading = false;
  let errorLocation = this.errorLocation.replace("methodName", "getRSUActivityData");
  error.error.message += errorLocation;
  throw error.error;
  });
  } catch(error) {
    this.errorReportsPage = true;
    this.salesHisttableLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getRSUActivityData");
    error.message += errorLocation;
    throw error;
  }
}
/**End of getRSUActivityData method */

  /******************************************************************************************************************************
    Method Name: selectDatePeriod
    Description: Initialize the date depending on which period the user uses.

    #    Date             Modified by                               Description
    1    03/15/2019       Joshua Paul J. Avecilla (JPA)             Initial draft
    2    03/18/2019       JPA                                       Changed initialization of Last Year period for BUG 258316,
                                                                    257699
    3    03/23/2019       Katrina Narag                             BUG 260288: Resetting values for dateFromChecker / dateToChecker
    4    03/25/2019       JPA                                       bUG 260329 - Added a checker "isCustom" for not resetting values
                                                                    if custom period is chosen already and if it's chosen again
                                                                    don't reset dates.
    5   03/29/2019        Dalemark P. Suquib                        Updated the setting of value of dateFromInput & dateToInput
    6   05/07/2019        Jayson Sevilla                            Added 'All Dates' option
    7   0/29/2019         Jayson Sevilla                            BUG 332750 FIX
  *******************************************************************************************************************************/
selectDatePeriod(period) 
{
  if(period != 5){
    $(".MDatePicker input").css("background-color","#d3d0d0")
  }else{
    $(".MDatePicker input").css("background-color","white")
  }
  
  this.displayDateError = false;
  try{
    if(period == 1) // year to date
    { 
      // this.previousDateFrom = this.dateFromInput;
      // this.previousDateTo = this.dateToInput;
      this.disableDatePicker = true;
      this.periodSelected = "Year to Date";
      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      let currentDateTo = new Date();
      let dateToEvent = this.datePipe.transform(currentDateTo, "MM'/'dd'/'yyyy");
      this.dateTo = "'" + moment(dateToEvent).format("DD MMM YYYY") + "'";

      this.dateToInput = currentDateTo; //Angular Material Datepicker

        // this.dateToInput = this.ngbDateParserFormatter.parse(
        //   currentDateTo.getFullYear().toString() + "-" +
        //   (currentDateTo.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
        //   currentDateTo.getDate().toString()
        // ); //Ngb Datepicker

      // this.dateToInputPlaceHolder = moment(dateToEvent).format("DD MMM YYYY"); //Ngb Datepicker

      let currentDateFrom = new Date();
      currentDateFrom.setMonth(0, 1);
      let yearToDate = currentDateFrom;
      let dateFromEvent = this.datePipe.transform(yearToDate, "MM'/'dd'/'yyyy");
      this.dateFrom = "'" + moment(dateFromEvent).format("DD MMM YYYY") + "'";

      this.dateFromInput = currentDateFrom; //Angular Material Datepicker

        // this.dateFromInput = this.ngbDateParserFormatter.parse(
        //   yearToDate.getFullYear().toString() + "-" +
        //   (yearToDate.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
        //   yearToDate.getDate().toString()
        // ); //Ngb Datepicker

      // this.dateFromInputPlaceHolder = moment(dateFromEvent).format("DD MMM YYYY"); //Ngb Datepicker

      this.dateFromChecker = moment(dateFromEvent).format("YYYY-MM-DD");
      this.dateToChecker = moment(dateToEvent).format("YYYY-MM-DD");
      // this.isCustom = false;
    
    } 
    else if(period == 2) // last year
    { 
      this.previousDateFrom = this.dateFromInput;
      this.previousDateTo = this.dateToInput;
      this.disableDatePicker = true;
      this.periodSelected = "Last Year"
      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      let fromDateLastYear = new Date();
      let toDateLastYear = new Date();

      toDateLastYear.setMonth(11, 31);
      toDateLastYear.setFullYear(toDateLastYear.getFullYear() - 1);
      let dateToEvent = this.datePipe.transform(toDateLastYear, "MM'/'dd'/'yyyy");

      this.dateTo = "'" +  moment(dateToEvent).format("DD MMM YYYY") + "'";

      this.dateToInput = toDateLastYear; //Angular Material Datepicker

        // this.dateToInput = this.ngbDateParserFormatter.parse(
        //   toDateLastYear.getFullYear().toString() + "-" +
        //   (toDateLastYear.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
        //   toDateLastYear.getDate().toString()
        // ); //Ngb Datepicker

        // this.dateToInputPlaceHolder = moment(dateToEvent).format("DD MMM YYYY"); //Ngb Datepicker

      fromDateLastYear.setMonth(0, 1);
      fromDateLastYear.setFullYear(fromDateLastYear.getFullYear() - 1);

      let lastYearFrom = fromDateLastYear;
      let dateFromEvent = this.datePipe.transform(lastYearFrom, "MM'/'dd'/'yyyy");
      this.dateFrom = "'" + moment(dateFromEvent).format("DD MMM YYYY") + "'";

      this.dateFromInput = lastYearFrom; //Angular Material Datepicker

        // this.dateFromInput = this.ngbDateParserFormatter.parse(
        //   fromDateLastYear.getFullYear().toString() + "-" +
        //   (fromDateLastYear.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
        //   fromDateLastYear.getDate().toString()
        // ); //Ngb Datepicker

        // this.dateFromInputPlaceHolder = moment(dateFromEvent).format("DD MMM YYYY"); //Ngb Datepicker

      this.dateFromChecker = moment(dateFromEvent).format("YYYY-MM-DD");
      this.dateToChecker = moment(dateToEvent).format("YYYY-MM-DD");
      // this.isCustom = false;

    } 
    else if(period == 3)  // last 3 years
    { 
      this.previousDateFrom = this.dateFromInput;
      this.previousDateTo = this.dateToInput;
      this.disableDatePicker = true;
      this.periodSelected = "Last 3 Years";
      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      let currentDateTo = new Date();
      let dateToEvent = this.datePipe.transform(currentDateTo, "MM'/'dd'/'yyyy");
      this.dateTo = "'" + moment(dateToEvent).format("DD MMM YYYY") + "'";
      this.dateToInput = currentDateTo; //Angular Material Datepicker

        // this.dateToInput = this.ngbDateParserFormatter.parse(
        //   currentDateTo.getFullYear().toString() + "-" +
        //   (currentDateTo.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
        //   currentDateTo.getDate().toString()
        // ); //Ngb Datepicker

        // this.dateToInputPlaceHolder = moment(dateToEvent).format("DD MMM YYYY"); //Ngb Datepicker
      this.dateToChecker = moment(dateToEvent).format("YYYY-MM-DD");

      let currentDateFrom = new Date();
      currentDateFrom.setMonth(0, 1);
      currentDateFrom.setFullYear(currentDateFrom.getFullYear() - 2);
      let lastThreeYear = currentDateFrom;
      let dateFromEvent = this.datePipe.transform(lastThreeYear, "MM'/'dd'/'yyyy");
      this.dateFrom = "'" + moment(dateFromEvent).format("DD MMM YYYY") + "'";
      this.dateFromInput = lastThreeYear; //Angular Material Datepicker

        // this.dateFromInput = this.ngbDateParserFormatter.parse(
        //   lastThreeYear.getFullYear().toString() + "-" +
        //   (lastThreeYear.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
        //   lastThreeYear.getDate().toString()
        // ); //Ngb Datepicker

        // this.dateFromInputPlaceHolder = moment(dateFromEvent).format("DD MMM YYYY"); //Ngb Datepicker
      this.dateFromChecker = moment(dateFromEvent).format("YYYY-MM-DD");
      // this.isCustom = false;
    } 
    else if(period == 4) // All Dates
    { 
      this.previousDateFrom = this.dateFromInput;
      this.previousDateTo = this.dateToInput;
      this.disableDatePicker = true;
      this.hasDateFromBeenClicked = false;
      this.hasDateToBeenClicked = false;

      this.periodSelected = "All Dates";
      let toDate = new Date();
      let dateToEvent = this.datePipe.transform(toDate, "MM'/'dd'/'yyyy");
      this.dateTo = "'" + moment(dateToEvent).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";

      if(this.reportType == "Sales History")
      {
        toDate = new Date(new Date().setDate(new Date().getDate() - 1));
        this.dateToInput = toDate; //Angular Material Datepicker
      }
      else
      {
        this.dateToInput = toDate; //Angular Material Datepicker
      }
     
      // this.dateToInput = this.ngbDateParserFormatter.parse(
      //   toDate.getFullYear().toString() + "-" +
      //   (toDate.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
      //   toDate.getDate().toString()
      // ); //Ngb Datepicker

      // this.dateToInputPlaceHolder = moment(dateToEvent).format("DD MMM YYYY"); //Ngb Datepicker

      let fromDate;
      if(this.reportType == "Purchase Program")
      {
        fromDate = new Date(1900, 0, 1);
      }
      else if(this.reportType == "Sales History")
      {
        fromDate = new Date(2001, 6, 19);
      }
      
      // fromDate = new Date(1990, 1, 1);
      this.dateFrom = "'" + moment(fromDate).format("YYYY-MM-DD HH:mm:ss.SSS") + "'";
      this.dateFromInput = fromDate;
      // this.dateFromInput = this.ngbDateParserFormatter.parse(
      //   fromDate.getFullYear().toString() + "-" +
      //   (fromDate.getMonth()).toString() + "-" + 
      //   fromDate.getDate().toString()
      // ); //Ngb Datepicker
      // this.dateFromInputPlaceHolder = moment(fromDate).format("DD MMM YYYY"); //Ngb Datepicker
    }
    else if(period == 5) // custom
    { 
      if(this.isCustom == false)
      {
        this.disableDatePicker = false;
        this.periodSelected = "Custom";
        let placeHolderCurrentDate;
        let customDate;

        let currentDateTo = new Date();

        let dateToEvent = this.datePipe.transform(currentDateTo, "MM'/'dd'/'yyyy");
        this.dateTo = "'" + moment(dateToEvent).format("DD MMM YYYY") + "'";


          if(this.hasDateToBeenClicked == true)
          {
            // this.dateToInput = this.holderDateTo; //Ngb Datepicker
            this.dateToInput = new Date(this.holderDateTo); //Angular Material Datepicker
          }
          else
          {
            this.dateToInput = currentDateTo; //Angular Material Datepicker

            // this.dateToInput = this.ngbDateParserFormatter.parse(
            //   currentDateTo.getFullYear().toString() + "-" +
            //   (currentDateTo.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
            //   currentDateTo.getDate().toString()
            // ); //Ngb Datepicker
          }          
          // this.dateToInputPlaceHolder = moment(dateToEvent).format("DD MMM YYYY"); //Ngb Datepicker
        // currentDate.setDate(1);

        //Ngb Datepicker
        // if(this.reportType == "Sales History" || 
        //   this.reportType == "Enrollment History" ||
        //   this.reportType == "RSU Activity" ||
        //   this.reportType == "Purchase Program")
        // {
        //   placeHolderCurrentDate = new Date(2019, 6, 1);
        // }
        // else
        // {
        //   placeHolderCurrentDate = new Date();
        // }
        
        // placeHolderCurrentDate.setMonth(placeHolderCurrentDate.getMonth()-1);
        // let placeHolderCustomDate = placeHolderCurrentDate;
        // let placeHolderDateFrom = this.datePipe.transform(placeHolderCustomDate, "MM'/'dd'/'yyyy");

        let customDateFrom;
        if(this.reportType == "Sales History" || 
           this.reportType == "Enrollment History" ||
           this.reportType == "RSU Activity" ||
           this.reportType == "Purchase Program")
        {
          customDateFrom = new Date(2019, 6, 1);
        }
        else
        {
          customDateFrom = new Date();
        }
        
        customDateFrom.setMonth(customDateFrom.getMonth()-1);
        let dateFromEvent = this.datePipe.transform(customDateFrom, "MM'/'dd'/'yyyy");

        this.dateFrom = "'" + moment(dateFromEvent).format("DD MMM YYYY") + "'";
        if(this.hasDateFromBeenClicked == true)
        {
          // this.dateFromInput = this.holderDateFrom; //Ngb Datepicker
          this.dateFromInput = new Date(this.holderDateFrom); //Angular Material Datepicker
        }
        else
        {
          this.dateFromInput = customDateFrom; //Angular Material Datepicker
          // this.dateFromInput = this.ngbDateParserFormatter.parse(
          //   customDate.getFullYear().toString() + "-" +
          //   // (currentDate.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
          //   (customDate.getMonth() + 1).toString() + "-" + // 1 ~ Jan, 2 ~ Feb, 3 ~ Mar ...
          //   customDate.getDate().toString()
          // ); //Ngb Datepicker
        }
        // this.dateFromInputPlaceHolder = moment(placeHolderDateFrom).format("DD MMM YYYY"); //Ngb Datepicker

        this.dateFromChecker = moment(dateFromEvent).format("YYYY-MM-DD");
        this.dateToChecker = moment(dateToEvent).format("YYYY-MM-DD");
        // this.isCustom = true;     
      }
      else
      {
        this.dateFromInput = this.previousDateFrom;

        this.dateToInput = this.previousDateTo;

        this.disableDatePicker = false;
        this.periodSelected = "Custom";
      }
    }
  } 
  catch(error) 
  {
    this.errorReportsPage = true;
    this.salesHisttableLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "selectDatePeriod");
    error.message = error.message + errorLocation;
    throw error;
  }
}

selectProgram(program, event)
{
  try{
  sessionStorage.removeItem('rsuActivitySelectedGrant');
  this.displayTotalsTable = false;

  if(this.display == true)
  {
    this.displayPageNavigator = false;
  }
  else
  {
    this.displayPageNavigator = true;
  }
  
  this.programType = program;
  this.programSelected = program;

  if(this.reportType == "RSU Activity")
  {
    let rsuGrantList: string  = "";
 
    if(event.checked == true)
    {
      this.counterGrantsRSU++;
      this.disableViewRSUActivity = false;    
            
      if(this.counterGrantsRSU == this.grantDescList.length)
      {
        
        this.selectedRSUActivityGrants.push(program);
        this.allGrantsisCheck = true;
      }
      else
      {
        this.selectedRSUActivityGrants.push(program);
      
      }

      for(let i=0;i<this.grantDescList.length;i++)
      {
        if(this.grantDescList[i].GrantLongDesc == program.GrantLongDesc)
        {
          this.grantDescList[i]["isChecked"] = true;
        }
      }
      
    }
    else if(event.checked == false)
    {
      this.selectedRSUActivityGrants = this.selectedRSUActivityGrants.filter(item => item.GrantLongDesc !== program.GrantLongDesc);
 
      for(let i=0;i<this.grantDescList.length;i++)
      {
        if(this.grantDescList[i].GrantLongDesc == program.GrantLongDesc)
        {
          this.grantDescList[i]["isChecked"] = false;
        }
      }
      
      this.allGrantsisCheck = false;
      this.counterGrantsRSU--;

      if(this.selectedRSUActivityGrants.length == 0)
      {
        this.disableViewRSUActivity = true;
      }
      else
      {
        this.disableViewRSUActivity = false;
      }   
    }

    this.isViewDisable = false;

    if(this.selectedRSUActivityGrants.length > 0)
    {
      for(let i = 0; i < this.selectedRSUActivityGrants.length; i++) 
      {
        if(i < this.selectedRSUActivityGrants.length - 1) 
        {
          rsuGrantList = rsuGrantList + this.selectedRSUActivityGrants[i].GrantLongDesc + ",";
        } 
        else 
        {
          rsuGrantList = rsuGrantList + this.selectedRSUActivityGrants[i].GrantLongDesc;
        }
      }
    
      sessionStorage.setItem("selectedRSUActivityGrants", JSON.stringify(this.selectedRSUActivityGrants));
      sessionStorage.setItem("rsuActivitySelectedGrant", JSON.stringify(rsuGrantList));
      this.selectedGrantsFromRSUGrantList = rsuGrantList;
    }
  }
} catch(error) {
  let errorLocation = this.errorLocation.replace("methodName", "selectProgram");
  error.message = error.message + errorLocation;
  throw error;
}
}

selectAllGrants(event)
 {
   try{
     
  if(event.checked == true)
  {
    this.allGrantsisCheck = true;
    this.isViewDisable = false;
    this.counterGrantsRSU = this.grantDescList.length;
    this.selectedRSUActivityGrants = this.grantDescList;
    this.selectedGrantsFromRSUGrantList = this.rsuActgrantsOnLoad;
    this.disableViewRSUActivity = false;
 
    for(let i = 0; i < this.grantDescList.length; i++) 
    {
      this.grantDescList[i]["isChecked"] = true;
    }
    sessionStorage.setItem("isAllGrantsChecked", "Y");

    // if(this.grantDescList.length > 0){
    //   for(let i = 0; i < this.grantDescList.length; i++) {
    //     this.grantDescList[i]["isChecked"] = true;
    //   }
    //   sessionStorage.setItem("isAllGrantsChecked", "Y");
    // }
  }
  else
  {
    this.allGrantsisCheck = false;
    this.isViewDisable = true;
    this.counterGrantsRSU = 0;
    this.selectedRSUActivityGrants = [];
    this.selectedGrantsFromRSUGrantList = "";
    if(this.grantDescList.length > 0){
      for(let i = 0; i < this.grantDescList.length; i++) {

        this.grantDescList[i]["isChecked"] = false;
      }
      
      sessionStorage.setItem("isAllGrantsChecked", "N");
    }
    this.disableViewRSUActivity = true;
  }
} catch(error) {
  let errorLocation = this.errorLocation.replace("methodName", "selectAllGrants");
  error.message = error.message + errorLocation;
  throw error;
}
 }

selectCurrency(currency){
  try{
  let list = this.completeCurrencyList;
  let currencyCode = list.find(i => i.Code === currency);

  this.currentSelected = currencyCode.Decode;
  this.currentCode = currency;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "selectCurrency");
    error.message = error.message + errorLocation;
    throw error;
  }
}
/**End of selectCurrency method */

  /******************************************************************************************************************************
    Method Name: getExchangeRateHistoryData
    Description: Initialize the date depending on which period the user uses.

    #    Date             Modified by                               Description
    1    04/25/2019       Jikho S. Enrique (JSE)                    Initial draft

  *******************************************************************************************************************************/

getExchangeRateHistoryData(pageIndex)
{
  try {

    this.reportsService.getExchangeReportsHistory("'"+this.currentCode+"'", this.legitDateFrom, this.legitDateTo, pageIndex).subscribe(data => {
      try{

        this.totalPages = 0;
        this.totalPages = Math.ceil(data[1][0]["Total Rows"]/50);

        if(Array.isArray(data[0]) && data[0].length > 0)
        {
          this.exchangeRateHist = false;
          this.displayPageNavigator = this.exchangeRateHist;
          // let sortedData: Element[] = [data[0].sort((a, b) => a.PurchaseDt < b.PurchaseDt ? 1: a.PurchaseDt > b.PurchaseDt ? -1: 0)];
                                      // [data[0].sort((a, b) => a.PRICE_DT < b.PRICE_DT ? 1 : a.PRICE_DT > b.PRICE_DT ? -1 : 0)];
          let sortedData = [data[0].sort((a, b) => a.PurchaseDt < b.PurchaseDt ? 1: a.PurchaseDt > b.PurchaseDt ? -1: 0)];
          this.displayedColumns = this.displayExchangeHistoryColumn;

          // this.dataSource = new MatTableDataSource<Element>(sortedData);
          this.dataSource = new MatTableDataSource(sortedData);
          this.dataSource = this.dataSource.filteredData[0];

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.noDataInTable = false;

          for(let i=0; i<this.dataSource.length; i++){
            this.dataSource[i]["Currency Exchange Rate"] = this.currentCode + ' ' + this.dataSource[i]["Currency Exchange Rate"].toFixed(4);
          }

          if(this.dataSource.length == 50){
            this.length = this.lengthContainer;
          }else{
            this.length = this.dataSource.length;
          }
          this.displayExportAndPagination = true;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }else{
          this.displayExportAndPagination = false;
          this.exchangeRateHist = true;
          this.displayPageNavigator = this.exchangeRateHist;
          this.noDataInTable = true;
        }
        this.tableLoading = false;
        setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
        setTimeout(()=>{ this.adjustExchangeRateHistoryTable() }, 0.0000000000000000001);
        setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);

      } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateHistoryData");
          error.message = error.message + errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getExchangeRateHistoryData");
        error.error.message += errorLocation;
        throw error.error;
      });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getExchangeRtCurrencyList");
    error.message += errorLocation;
    throw error;
  }
}
//END - getExchangeRateHistoryData


checkboxControl(event){
  this.getSalesHistoryCheckboxData();
try{
  for (let i = 0; i< this.SalesHistoryCheckboxData.length;i++){
    if(event.checked == true){
      this.showGrantsList = true;
    } else{
      this.showGrantsList = false;
    }
  }
} catch(error) {
  let errorLocation = this.errorLocation.replace("methodName", "checkboxControl");
  error.message = error.message + errorLocation;
  throw error;
}
}

/************************************************************************************
 Method Name: getSalesHistoryGrantData
 Description: Calls the API for the Drop Down Data for Report Type: Sales History Grants

#       Date               Name                        Description
1       04/23/19           Jayson Sevilla             Initial Draft
 ************************************************************************************/
async getSalesHistoryGrantData(holdingIds)
{
  try 
  {
    this.counterSalesHistoryGrants = 0;
    this.salesHistoryGrantsOnLoad = "";
    // this.salesHistoryGrants = this.salesHistoryGrantsOnLoad;

    if(holdingIds != " ")
    {
      this.reportsService.getSalesHistoryGrantData(this.user.OptNum, holdingIds).subscribe(data => {
        try
        {
          if(Array.isArray(data[0]) && data[0].length > 0)
          {
            this.SalesHistoryGrantData = data;
            this.SalesHistoryGrantData = this.SalesHistoryGrantData[0];
            this.counterSalesHistoryGrants = this.SalesHistoryGrantData.length;
            this.selectedGrantsList = this.SalesHistoryGrantData;
            this.checkGrants = true;

            if(this.SalesHistoryGrantData.length > 0)
            {
              for(let i = 0; i < this.SalesHistoryGrantData.length; i++) 
              {
                if(i < this.SalesHistoryGrantData.length - 1) 
                {
                  this.salesHistoryGrantsOnLoad = this.salesHistoryGrantsOnLoad + this.SalesHistoryGrantData[i].GrantLongDesc + ",";
                } 
                else 
                {
                  this.salesHistoryGrantsOnLoad = this.salesHistoryGrantsOnLoad + this.SalesHistoryGrantData[i].GrantLongDesc;
                }

                this.SalesHistoryGrantData[i].checkGrants = true;
              }
            }
            this.salesHistoryGrants = this.salesHistoryGrantsOnLoad;
            this.disableProgram = false;
          }
          else
          {
            this.salesHistoryGrants = "";
            this.disableProgram = false;
          }
        } 
    catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryGrantData");
      error.message = error.message + errorLocation;
      throw error;
    }
    
      sessionStorage.setItem("grantListOnLoad",this.salesHistoryGrantsOnLoad);
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryGrantData");
      error.error.message += errorLocation;
      throw error.error;
    });
  }
  else
  {
    this.SalesHistoryGrantData = [];
  }
    this.tableLoading = false;
  }
   catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryGrantData");
    error.message += errorLocation;
    throw error;
  }
}

/************************************************************************************
 Method Name: getHoldingsID
 Description: Get holdings id based on program parameter

#       Date               Name                        Description
1       05/02/19           KMN                         Initial draft
 ************************************************************************************/
getHoldingsID(programName){
  let holdingsID: number;
try{
  if(programName == "RSU")
  {
    if(this.hasRSUProgramBeenTriggered != true)
    {
      holdingsID = HoldingsId.RSU;
    }
    else
    {
      holdingsID = HoldingsId.RSU + 2;
    }
  }
  else if(programName == "Founder")
  {
    holdingsID = HoldingsId.Founder;
  }
  else if(programName == "Options")
  {
    holdingsID = HoldingsId.Options;
    this.hasRSUProgramBeenTriggered = true;
  }
  else if(programName == "ESPP")
  {
    holdingsID = HoldingsId.ESPP;
  }
  else if(programName == "VEIP")
  {
    holdingsID = HoldingsId.VEIP;
  }

  return holdingsID;
} catch(error) {
  let errorLocation = this.errorLocation.replace("methodName", "getCurrencyList");
  error.message = error.message + errorLocation;
  throw error;
}
}
/**End of getHoldingsID method */


/************************************************************************************
 Method Name: getSalesHistoryCheckboxData
 Description: Calls the API for the Drop Down Data for Report Type: Sales History

#       Date               Name                        Description
1       04/23/19           Jayson Sevilla             Initial Draft
2       06/27/19           KMN                        BUG FIX 332141
3       07/01/19           KMN                        BUG FIX 332141 - Add condition
 ************************************************************************************/
async getSalesHistoryCheckboxData()
{
  try {
    this.reportsService.getSalesHistoryCheckboxData(this.user.OptNum).subscribe(data => {
      try{
      if(Array.isArray(data) && data.length > 0)
      {
        this.salesHistoryProgramsOnLoad = ""; 
        this.salesHistoryHoldingIDsOnLoad = "";
        this.salesHistoryHoldingIDsOnLoad2 = [];
        let trial: any;
        
        this.SalesHistoryCheckboxData = data;
        // this.SalesHistoryCheckboxData = ["Founder", "RSU", "Options", "ESPP", "VEIP"];
        this.programTypeDropDownData = this.SalesHistoryCheckboxData;
        for(let i=0; i<this.programTypeDropDownData.length; i++)
        {
          this.salesHistoryProgramTypeDropDownData[i] = {programDescription: this.programTypeDropDownData[i], isChecked: true};
        }

        this.numberOfRSUsInProgramList = this.salesHistoryProgramTypeDropDownData.filter(item => item.programDescription === "RSU").length;

        if(this.numberOfRSUsInProgramList > 1)
        {
          // this.removeElement(this.salesHistoryProgramTypeDropDownData, "RSU");
          let trigger: boolean = false;
          let unique = [];
          for(let i=0; i<this.salesHistoryProgramTypeDropDownData.length; i++)
          {
            if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "RSU" && trigger == false)
            {
              unique.push(this.salesHistoryProgramTypeDropDownData[i]);
              trigger = true;
            }
            else if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "RSU" && trigger == true)
            {
              //DO NOTHING
              this.loading = false;
            }
            else
            {
              unique.push(this.salesHistoryProgramTypeDropDownData[i]);
            }
          }
          this.salesHistoryProgramTypeDropDownData = unique;
        }

        if(this.programTypeDropDownData.length > 0)
        {
          for(let i = 0; i < this.programTypeDropDownData.length; i++) 
          {
            if(i < this.programTypeDropDownData.length - 1) 
            {
              this.salesHistoryID = this.getHoldingsID(this.programTypeDropDownData[i]);

              if(this.salesHistoryID == 2)
              {
                this.hasRSUProgramBeenTriggered = true;
              }
              else if(this.hasRSUProgramBeenTriggered == true)
              {
                this.hasRSUProgramBeenTriggered = true;
              }
              else
              {
                this.hasRSUProgramBeenTriggered = false;
              }
              
                this.salesHistoryHoldingIDsOnLoad = this.salesHistoryHoldingIDsOnLoad + this.salesHistoryID + ",";

              this.salesHistoryProgramsOnLoad = this.salesHistoryProgramsOnLoad + this.programTypeDropDownData[i] + ",";

              this.salesHistoryHoldingIDsOnLoad2.push(this.salesHistoryID);
            } 
            else 
            {
              this.salesHistoryID = this.getHoldingsID(this.programTypeDropDownData[i]);
              
              this.salesHistoryHoldingIDsOnLoad = this.salesHistoryHoldingIDsOnLoad + this.salesHistoryID;
              this.salesHistoryProgramsOnLoad = this.salesHistoryProgramsOnLoad + this.programTypeDropDownData[i];

              this.salesHistoryHoldingIDsOnLoad2.push(this.salesHistoryID);
            }

          }
          this.checkAllPrograms = true;

        // For TASK 205039
        let numberHolder = [];
        let programHolder =  [];

        for (let i = 0; i < this.salesHistoryProgramTypeDropDownData.length; i++)
        { 
          if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "Founder") numberHolder.push(1);
          else if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "RSU") numberHolder.push(2);
          else if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "Options") numberHolder.push(3);
          else if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "ESPP") numberHolder.push(5);
          else if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "VEIP") numberHolder.push(6);

          
          // if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "RSU") numberHolder.push(1);
          // else if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "Options") numberHolder.push(2);
          // else if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "Founder") numberHolder.push(3);
          // else if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "ESPP") numberHolder.push(5);
          // else if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "VEIP") numberHolder.push(6);
        }
        numberHolder = numberHolder.sort((a,b) => (a > b) ? 1 : (b > a) ? -1 : 0);

        for(let i = 0; i<numberHolder.length; i++)
        {
            if(numberHolder[i] == 1) programHolder.push("Founder");
            else if(numberHolder[i] == 2) programHolder.push("RSU");
            else if(numberHolder[i] == 3) programHolder.push("Options");
            else if(numberHolder[i] == 5) programHolder.push("ESPP");
            else if(numberHolder[i] == 6) programHolder.push("VEIP");  
            // if(numberHolder[i] == 1) programHolder.push("RSU");
            // else if(numberHolder[i] == 2) programHolder.push("Options");
            // else if(numberHolder[i] == 3) programHolder.push("Founder");
            // else if(numberHolder[i] == 5) programHolder.push("ESPP");
            // else if(numberHolder[i] == 6) programHolder.push("VEIP");
        }
        for(let i = 0; i < this.salesHistoryProgramTypeDropDownData.length; i++) this.salesHistoryProgramTypeDropDownData[i].programDescription = programHolder[i];

          this.counterSalesHistoryPrograms = this.salesHistoryProgramTypeDropDownData.length;
          this.selectedPrograms = this.salesHistoryProgramTypeDropDownData;
          this.selectedHoldingIds = this.salesHistoryHoldingIDsOnLoad2;

          this.salesHistoryPrograms = this.salesHistoryProgramsOnLoad;

          sessionStorage.setItem("salesHistoryProgramsOnLoad",this.programTypeDropDownData);
          sessionStorage.setItem("salesHistoryHoldingIDsOnLoad",this.salesHistoryHoldingIDsOnLoad);  
        } 
      }

      this.getSalesHistoryGrantData(this.salesHistoryHoldingIDsOnLoad);
    } catch(error) {
      this.errorReportsPage = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryCheckboxData");
      error.message = error.message + errorLocation;
      throw error;
    }
  }, error => {
    this.errorReportsPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryCheckboxData");
    error.error.message += errorLocation;
    throw error.error;
  });
  } catch(error) {
    this.errorReportsPage = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryCheckboxData");
    error.message += errorLocation;
    throw error;
  }
}
/**End of getSalesHistoryCheckboxData method */

/************************************************************************************
 Method Name: removeElement
 Description: Removes duplicate value in array

#       Date               Name                        Description
1       06/27/19           KMN                         Initial draft (BUG 332141)
 ************************************************************************************/
removeElement(array, elem) { 
  var index =  array.findIndex(x => x.programDescription === elem);

  if (index > -1) 
  {
     array.splice(index, 1);
  }
}


/************************************************************************************
 Method Name: getSalesHistoryData
 Description: Calls the API for the Data for Report Type: Sales History

#       Date                        Name                    Description
1       19/April/2019               Jayson Sevilla           Initial Draft
2       05/03/19                    KMN                      BUG 296941 FIX - Modified Date to Transaction Date
3       05/16/19                    JPA                      Added boolean to hide total row
4       05/30/19                    KMN                     Bug 307179 fix - Incorrect Display of Decimal Places in Sales History Report
 ************************************************************************************/
getSalesHistoryData(pageIndex)
{
  try {
    
    let grantList = sessionStorage.getItem("salesHistGrants");
    let programList = sessionStorage.getItem("salesHistProgram");

    this.legitDateFrom = this.legitDateFrom.replace(/\'/g, "");
    this.legitDateTo = this.legitDateTo.replace(/\'/g, "");

    this.legitDateFrom = moment(this.legitDateFrom).format("YYYY-MM-DD HH:mm:ss.SSS");
    this.legitDateTo = moment(this.legitDateTo).format("YYYY-MM-DD HH:mm:ss.SSS");

    programList != null ? "" : programList = sessionStorage.getItem("salesHistoryProgramsOnLoad"); //okay
    grantList != null ? "" : grantList = sessionStorage.getItem("grantListOnLoad"); //okay

   
    this.reportsService.getSalesHistoryData(this.user.OptNum, this.legitDateFrom, this.legitDateTo, this.salesHistoryGrants, this.salesHistoryPrograms, pageIndex).subscribe(data => {
    try{
      if(Array.isArray(data[0]) && data[0].length > 0){
      let localCur = "USD";
      this.length = data[2][0]["Total Rows"];
      this.displayPageNavigator = false;
      // let sortedData: Element[] = [data[0].sort((a, b) => a.PurchaseDt < b.PurchaseDt ? 1: a.PurchaseDt > b.PurchaseDt ? -1: 0)];
      let sortedData = [data[0].sort((a, b) => a.PurchaseDt < b.PurchaseDt ? 1: a.PurchaseDt > b.PurchaseDt ? -1: 0)];
      // let totals: Element[] = data[1];
      this.totalPages = 0;
      this.totalPages = Math.ceil(data[2][0]["Total Rows"]/50);
      this.displayedColumns = this.displayedSalesHistoryColumns;
      this.dataSource = new MatTableDataSource(sortedData);
      this.dataSource = this.dataSource.filteredData[0];
      // this.totalsTable = new MatTableDataSource<Element>(totals);
      // this.totalsTable = this.totalsTable.filteredData[0];

      this.totalsTable = data[1];
      let localCurrency = data[0][0]["LocalCurrencyCd"];

      for(let i=0; i<this.dataSource.length; i++)
      {
        // this.dataSource[i]["Transaction Date"] = moment(this.dataSource[i]["Transaction Date"]).format("DD MMM YYYY");
        if(this.dataSource[i]["Original Purchase Date"] == null){
          this.dataSource[i]["Original Purchase Date"] = "-";
        }

        if(this.dataSource[i]["Award Details"] == null) {
          if(this.dataSource[i]["Program"] == "VEIP" || this.dataSource[i]["Program"] == "ESPP") {
            this.dataSource[i]["Award Details"] = "-";
          }
        }
        else {
          if(this.dataSource[i]["Program"] == "VEIP" || this.dataSource[i]["Program"] == "ESPP") {
            this.dataSource[i]["Award Details"] = "-";
          }
        }
        
        this.dataSource[i]["Trading Volume"] = parseFloat(this.dataSource[i]["Trading Volume"]).toFixed(2);
        this.dataSource[i]["Trading Volume"] = this.commafy(this.dataSource[i]["Trading Volume"]);

        if (this.dataSource[i]["Sale Price (USD)"] != null)
        {
          this.dataSource[i]["Sale Price (USD)"] = parseFloat(this.dataSource[i]["Sale Price (USD)"]).toFixed(4);
          this.dataSource[i]["Sale Price (USD)"] = this.commafy(this.dataSource[i]["Sale Price (USD)"].toString(),4);

          if(this.dataSource[i]["Program"] == "Founder"){
            if(this.dataSource[i]["Transaction Type"] == "X Shares Redemption" || this.dataSource[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              this.dataSource[i]["Sale Price (USD)"] = "-";
            }
          }
        }
        else
        {
          if(this.dataSource[i]["Program"] == "Founder"){
            if(this.dataSource[i]["Transaction Type"] == "X Shares Redemption" || this.dataSource[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              this.dataSource[i]["Sale Price (USD)"] = "-";
            }
          }
        }
        
        // this.dataSource[i]["Number of Shares in Transaction"] = parseFloat(this.dataSource[i]["Number of Shares in Transaction"]).toFixed(2);
        this.dataSource[i]["Number of Shares in Transaction"] = this.commafy(this.dataSource[i]["Number of Shares in Transaction"].toString());

        // this.dataSource[i]["Gross Proceeds (USD)"] != null ? this.dataSource[i]["Gross Proceeds (USD)"] = this.commafy(this.dataSource[i]["Gross Proceeds (USD)"].toString(),2) : "";
        if(this.dataSource[i]["Gross Proceeds (USD)"] != null)
        {
          this.dataSource[i]["Gross Proceeds (USD)"] = parseFloat(this.dataSource[i]["Gross Proceeds (USD)"]).toFixed(2);
          this.dataSource[i]["Gross Proceeds (USD)"] = this.commafy(this.dataSource[i]["Gross Proceeds (USD)"].toString(),2);
          if(this.dataSource[i]["Program"] == "Founder"){
            if(this.dataSource[i]["Transaction Type"] == "X Shares Redemption" || this.dataSource[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              this.dataSource[i]["Gross Proceeds (USD)"] = "-";
            }
          }
        }
        else
        {
          this.dataSource[i]["Gross Proceeds (USD)"] = " ";
          if(this.dataSource[i]["Program"] == "Founder"){
            if(this.dataSource[i]["Transaction Type"] == "X Shares Redemption" || this.dataSource[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              this.dataSource[i]["Gross Proceeds (USD)"] = "-";
            }
          }
        }
        
        // this.dataSource[i]["Gain/(Loss) - USD"] != null ? this.dataSource[i]["Gain/(Loss) - USD"] = this.commafy(this.dataSource[i]["Gain/(Loss) - USD"].toString(),2) : "";
        // // this.dataSource[i]["Gain/(Loss) - USD"] = this.commafy(this.dataSource[i]["Gain/(Loss) - USD"].toString(),2);
        // this.dataSource[i]["Gain/(Loss) - USD"] = parseFloat(this.dataSource[i]["Gain/(Loss) - USD"]).toFixed(2);

        if(this.dataSource[i]["Gain/(Loss) - USD"] != null)
        {        
            // this.dataSource[i]["Gain/(Loss) - USD"] = this.commafy(this.dataSource[i]["Gain/(Loss) - USD"].toString(),2);
            // this.dataSource[i]["Gain/(Loss) - USD"] = parseFloat(this.dataSource[i]["Gain/(Loss) - USD"]).toFixed(2);
            this.dataSource[i]["Gain/(Loss) - USD"] = localCur + " " + this.commafy(parseFloat(this.dataSource[i]["Gain/(Loss) - USD"]).toFixed(2).toString(),2);

            if(this.dataSource[i]["Program"] == "ESPP") this.dataSource[i]["Gain/(Loss) - USD"] = " - ";
            else if(this.dataSource[i]["Program"] == "RSU" || this.dataSource[i]["Program"] == "IPORSU") this.dataSource[i]["Gain/(Loss) - USD"] = "Refer to Broker";
            // else if(this.dataSource[i]["Program"] == "Founder") this.dataSource[i]["Gain/(Loss) - USD"] = localCur + " " + this.dataSource[i]["Gain/(Loss) - USD"];
            else if(this.dataSource[i]["Program"] == "Founder"){
              if(this.dataSource[i]["Transaction Type"] == "X Shares Redemption"){
                this.dataSource[i]["Gain/(Loss) - USD"] = "-";
              }
            }
        }
        else
        {
          if(this.dataSource[i]["Program"] == "Founder"){
            if(this.dataSource[i]["Transaction Type"] == "X Shares Redemption"){
              this.dataSource[i]["Gain/(Loss) - USD"] = "-";
            }
            else{
              this.dataSource[i]["Gain/(Loss) - USD"] = localCur + " " + "0.00";
            }
          }
          else this.dataSource[i]["Gain/(Loss) - USD"] = "-";
        }
        
        // this.dataSource[i]["Gain/(Loss) - Local Currency"] != null ? this.dataSource[i]["Gain/(Loss) - Local Currency"] = this.commafy(this.dataSource[i]["Gain/(Loss) - Local Currency"].toString(),2) : "";
        // // this.dataSource[i]["Gain/(Loss) - Local Currency"] = this.commafy(this.dataSource[i]["Gain/(Loss) - Local Currency"].toString(),2);
        // this.dataSource[i]["Gain/(Loss) - Local Currency"] = parseFloat(this.dataSource[i]["Gain/(Loss) - Local Currency"]).toFixed(2);

        if(this.dataSource[i]["Gain/(Loss) - Local Currency"] != null)
        {
            // this.dataSource[i]["Gain/(Loss) - Local Currency"] = this.commafy(this.dataSource[i]["Gain/(Loss) - Local Currency"].toString(),2);
            // this.dataSource[i]["Gain/(Loss) - Local Currency"] = parseFloat(this.dataSource[i]["Gain/(Loss) - Local Currency"]).toFixed(2);
            if(this.dataSource[i]["Program"] == "Options") {
              this.dataSource[i]["Gain/(Loss) - Local Currency"] = this.commafy(parseFloat(this.dataSource[i]["Gain/(Loss) - Local Currency"]).toFixed(2).toString(),2);
            }
            else {
              this.dataSource[i]["Gain/(Loss) - Local Currency"] = localCurrency + " " + this.commafy(parseFloat(this.dataSource[i]["Gain/(Loss) - Local Currency"]).toFixed(2).toString(),2);
            }
            
            if(this.dataSource[i]["Program"] == "ESPP") this.dataSource[i]["Gain/(Loss) - Local Currency"] = " - ";
            else if(this.dataSource[i]["Program"] == "RSU" || this.dataSource[i]["Program"] == "IPORSU") this.dataSource[i]["Gain/(Loss) - Local Currency"] = "Refer to Broker";
            // else if(this.dataSource[i]["Program"] == "Founder") this.dataSource[i]["Gain/(Loss) - Local Currency"] = this.dataSource[i]["Gain/(Loss) - Local Currency"];
            else if(this.dataSource[i]["Program"] == "Founder"){
              if(this.dataSource[i]["Transaction Type"] == "X Shares Redemption"){
                this.dataSource[i]["Gain/(Loss) - Local Currency"] = "-";
              }
            }
        }
        else
        {
          // if(this.dataSource[i]["Program"] == "Founder") this.dataSource[i]["Gain/(Loss) - Local Currency"] = "0.00";
          if(this.dataSource[i]["Program"] == "Founder"){
            if(this.dataSource[i]["Transaction Type"] == "X Shares Redemption"){
              this.dataSource[i]["Gain/(Loss) - Local Currency"] = "-";
            }
            else{
              this.dataSource[i]["Gain/(Loss) - Local Currency"] = "0.00";
            }
          }
          else this.dataSource[i]["Gain/(Loss) - Local Currency"] = "-";
        }

        this.dataSource[i]["Purchase Date FMV per Share(USD)"] != null ? this.dataSource[i]["Purchase Date FMV per Share(USD)"] = this.commafy(this.dataSource[i]["Purchase Date FMV per Share(USD)"].toString(),4) : this.dataSource[i]["Purchase Date FMV per Share(USD)"] = "-";
        // this.dataSource[i]["Purchase Date FMV per Share(USD)"] = this.commafy(this.dataSource[i]["Purchase Date FMV per Share(USD)"].toString(),2);
        // this.dataSource[i]["Purchase Date FMV per Share(USD)"] = parseFloat(this.dataSource[i]["Purchase Date FMV per Share(USD)"]).toFixed(2);
        
        this.dataSource[i]["Purchase Date Value (USD)"] != null ? this.dataSource[i]["Purchase Date Value (USD)"] = this.commafy(this.dataSource[i]["Purchase Date Value (USD)"].toString(),2) : this.dataSource[i]["Purchase Date Value (USD)"] = "-";
        // this.dataSource[i]["Purchase Date Value (USD)"] = this.commafy(this.dataSource[i]["Purchase Date Value (USD)"].toString(),2);
        // this.dataSource[i]["Purchase Date Value (USD)"] = parseFloat(this.dataSource[i]["Purchase Date Value (USD)"]).toFixed(2);

        // this.dataSource[i]["Value Per Share at Exercise (USD)"] != null ? this.dataSource[i]["Value Per Share at Exercise (USD)"] = this.commafy(this.dataSource[i]["Value Per Share at Exercise (USD)"].toString(),2) : this.dataSource[i]["Value Per Share at Exercise (USD)"] = "-";
        // // this.dataSource[i]["Value Per Share at Exercise (USD)"] = this.commafy(this.dataSource[i]["Value Per Share at Exercise (USD)"].toString(),2);
        // this.dataSource[i]["Value Per Share at Exercise (USD)"] = parseFloat(this.dataSource[i]["Value Per Share at Exercise (USD)"]).toFixed(2);

        if(this.dataSource[i]["Value Per Share at Exercise (USD)"] != null)
        {
          // this.dataSource[i]["Value Per Share at Exercise (USD)"] = this.commafy(this.dataSource[i]["Value Per Share at Exercise (USD)"].toString(),2);
          this.dataSource[i]["Value Per Share at Exercise (USD)"] = parseFloat(this.commafy(this.dataSource[i]["Value Per Share at Exercise (USD)"].toString(),2)).toFixed(2);
          if(this.dataSource[i]["Program"] == "RSU" || this.dataSource[i]["Program"] == "IPORSU" || this.dataSource[i]["Program"] == "Founder") 
            this.dataSource[i]["Value Per Share at Exercise (USD)"] = "-";

        }
        else
        {
          this.dataSource[i]["Value Per Share at Exercise (USD)"] = "-";
        }

        // this.dataSource[i]["Total Value on Exercise Date (USD)"] != null ? this.dataSource[i]["Total Value on Exercise Date (USD)"] = this.commafy(this.dataSource[i]["Total Value on Exercise Date (USD)"].toString(),2) : this.dataSource[i]["Total Value on Exercise Date (USD)"] = "-";
        // // this.dataSource[i]["Total Value on Exercise Date (USD)"] = this.commafy(this.dataSource[i]["Total Value on Exercise Date (USD)"].toString(),2);
        // this.dataSource[i]["Total Value on Exercise Date (USD)"] = parseFloat(this.dataSource[i]["Total Value on Exercise Date (USD)"]).toFixed(2);

        if(this.dataSource[i]["Total Value on Exercise Date (USD)"] != null)
        {
          // this.dataSource[i]["Total Value on Exercise Date (USD)"] = this.commafy(this.dataSource[i]["Total Value on Exercise Date (USD)"].toString(),2);
          this.dataSource[i]["Total Value on Exercise Date (USD)"] = parseFloat(this.commafy(this.dataSource[i]["Total Value on Exercise Date (USD)"].toString(),2)).toFixed(2);

          if(this.dataSource[i]["Program"] == "RSU" || this.dataSource[i]["Program"] == "IPORSU" || this.dataSource[i]["Program"] == "Founder") 
            this.dataSource[i]["Total Value on Exercise Date (USD)"] = "-";
        }
        else
        {
          this.dataSource[i]["Total Value on Exercise Date (USD)"] = "-";
        }
        
        // this.dataSource[i]["Total Cost on Exercise Date (USD)"] != null ? this.dataSource[i]["Total Cost on Exercise Date (USD)"] = this.commafy(this.dataSource[i]["Total Cost on Exercise Date (USD)"].toString(),2) : this.dataSource[i]["Total Cost on Exercise Date (USD)"] = "-";
        // // this.dataSource[i]["Total Cost on Exercise Date (USD)"] = this.commafy(this.dataSource[i]["Total Cost on Exercise Date (USD)"].toString(),2);
        // this.dataSource[i]["Total Cost on Exercise Date (USD)"] = parseFloat(this.dataSource[i]["Total Cost on Exercise Date (USD)"]).toFixed(2);

        if(this.dataSource[i]["Total Cost on Exercise Date (USD)"] != null)
        {
          // this.dataSource[i]["Total Cost on Exercise Date (USD)"] = this.commafy(this.dataSource[i]["Total Cost on Exercise Date (USD)"].toString(),2);
          this.dataSource[i]["Total Cost on Exercise Date (USD)"] = parseFloat(this.commafy(this.dataSource[i]["Total Cost on Exercise Date (USD)"].toString(),2)).toFixed(2);

          if(this.dataSource[i]["Program"] == "RSU" || this.dataSource[i]["Program"] == "IPORSU" || this.dataSource[i]["Program"] == "Founder") 
            this.dataSource[i]["Total Cost on Exercise Date (USD)"] = "-";
        }
        else
        {
          this.dataSource[i]["Total Cost on Exercise Date (USD)"] = "-";
        }

        // this.dataSource[i]["Taxable Income(USD)"] != null ? this.dataSource[i]["Taxable Income(USD)"] = this.commafy(this.dataSource[i]["Taxable Income(USD)"].toString(),2) : this.dataSource[i]["Taxable Income(USD)"] = "-";
        // // this.dataSource[i]["Taxable Income(USD)"] = this.commafy(this.dataSource[i]["Taxable Income(USD)"].toString(),2);
        // this.dataSource[i]["Taxable Income(USD)"] = parseFloat(this.dataSource[i]["Taxable Income(USD)"]).toFixed(2);

        if(this.dataSource[i]["Taxable Income(USD)"] != null)
        {
          // this.dataSource[i]["Taxable Income(USD)"] = this.commafy(this.dataSource[i]["Taxable Income(USD)"].toString(),2);
          this.dataSource[i]["Taxable Income(USD)"] = parseFloat(this.commafy(this.dataSource[i]["Taxable Income(USD)"].toString(),2)).toFixed(2);

          if(this.dataSource[i]["Program"] == "RSU" || this.dataSource[i]["Program"] == "IPORSU" || this.dataSource[i]["Program"] == "Founder") 
            this.dataSource[i]["Taxable Income(USD)"] = "-";
        }
        else
        {
          this.dataSource[i]["Taxable Income(USD)"] = "-";
        }
       
        // this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] != null ? this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2) :this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = "-";
        if(this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] != null)
        {
          this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(this.commafy(this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2)).toFixed(2);

          if(this.dataSource[i]["Program"] == "RSU" || this.dataSource[i]["Program"] == "IPORSU" || this.dataSource[i]["Program"] == "Founder") 
            this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = "-";
        }
        else
        {
          this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = " - ";
        }
        // this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2);
        // this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"]).toFixed(2);

        if(this.dataSource[i]["Estimated Proceeds (USD)"] != null)
        {
          // this.dataSource[i]["Estimated Proceeds (USD)"] = this.commafy(this.dataSource[i]["Estimated Proceeds (USD)"].toString(),2);
          this.dataSource[i]["Estimated Proceeds (USD)"] = parseFloat(this.commafy(this.dataSource[i]["Estimated Proceeds (USD)"].toString(),2)).toFixed(2);

          if(this.dataSource[i]["Program"] == "RSU" || this.dataSource[i]["Program"] == "IPORSU" || this.dataSource[i]["Program"] == "Founder") 
            this.dataSource[i]["Estimated Proceeds (USD)"] = "-";
        }
        else
        {
          this.dataSource[i]["Estimated Proceeds (USD)"] = "-";
        }

        // this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] == 0 ? this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = "-" : " ";
        // // this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2);
        // this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(this.dataSource[i]["Estimated Taxes Withheld at Exercise (USD)"]).toFixed(2);        
        this.dataSource[i]["Original Purchase Date"] == null ? this.dataSource[i]["Original Purchase Date"] = "-" : " ";
        // this.dataSource[i]["Original Purchase Date"] = this.commafy(this.dataSource[i]["Original Purchase Date"].toString(),2);
        // this.dataSource[i]["Original Purchase Date"] = parseFloat(this.dataSource[i]["Original Purchase Date"]).toFixed(2);

        if(this.dataSource[i]["Broker for More Information"] != null)
        {
          if(this.dataSource[i]["Program"] == "IPORSU")
          {
            this.dataSource[i]["Broker for More Information"] = " ";
          }
        }
      }

        this.totalsTable[0]["Total"] = "Totals";

        if(this.totalsTable[0]["Total Cost on Exercise Date (USD)"] != null)
        {
          this.totalsTable[0]["Total Cost on Exercise Date (USD)"] = parseFloat(this.totalsTable[0]["Total Cost on Exercise Date (USD)"]).toFixed(2);
          this.totalsTable[0]["Total Cost on Exercise Date (USD)"] = this.commafy(this.totalsTable[0]["Total Cost on Exercise Date (USD)"].toString(),2);
        }
        else
        {
          this.totalsTable[0]["Total Cost on Exercise Date (USD)"] = " ";
        }

        this.totalsTable[0]["Total CostExerciseUsd"] = parseFloat(this.totalsTable[0]["Total CostExerciseUsd"]).toFixed(2);
        this.totalsTable[0]["Total CostExerciseUsd"] = this.commafy(this.totalsTable[0]["Total CostExerciseUsd"].toString(),2);

        if(this.totalsTable[0]["Total Estimated Proceeds (USD)"] != null)
        {
          this.totalsTable[0]["Total Estimated Proceeds (USD)"] = parseFloat(this.totalsTable[0]["Total Estimated Proceeds (USD)"]).toFixed(2);
          this.totalsTable[0]["Total Estimated Proceeds (USD)"] = this.commafy(this.totalsTable[0]["Total Estimated Proceeds (USD)"].toString(),2);
        }
        else
        {
          this.totalsTable[0]["Total Estimated Proceeds (USD)"] = " ";
        }

        if(this.totalsTable[0]["Total Estimated Taxes Withheld at Exercise (USD)"] != null)
        {
          this.totalsTable[0]["Total Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(this.totalsTable[0]["Total Estimated Taxes Withheld at Exercise (USD)"]).toFixed(2);
          this.totalsTable[0]["Total Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(this.totalsTable[0]["Total Estimated Taxes Withheld at Exercise (USD)"].toString(),2);
        }
        else
        {
          this.totalsTable[0]["Total Estimated Taxes Withheld at Exercise (USD)"] = " ";
        }

        if(this.totalsTable[0]["Total Gain/(Loss) - Local Currency"] != null)
        {
          // this.totalsTable[0]["Total Gain/(Loss) - Local Currency"] = parseFloat(this.totalsTable[0]["Total Gain/(Loss) - Local Currency"]).toFixed(2);
          // this.totalsTable[0]["Total Gain/(Loss) - Local Currency"] = this.commafy(this.totalsTable[0]["Total Gain/(Loss) - Local Currency"].toString(),2);
          this.totalsTable[0]["Total Gain/(Loss) - Local Currency"] = " ";
        }
        else
        {
          this.totalsTable[0]["Total Gain/(Loss) - Local Currency"] = " ";
        }

        if(this.totalsTable[0]["Total Gain/(Loss) - USD"] != null)
        {
          // this.totalsTable[0]["Total Gain/(Loss) - USD"] = parseFloat(this.totalsTable[0]["Total Gain/(Loss) - USD"]).toFixed(2);
          // this.totalsTable[0]["Total Gain/(Loss) - USD"] = this.commafy(this.totalsTable[0]["Total Gain/(Loss) - USD"].toString(),2);
          this.totalsTable[0]["Total Gain/(Loss) - USD"] = " ";
        }
        else
        {
          this.totalsTable[0]["Total Gain/(Loss) - USD"] = " ";
        }

        this.totalsTable[0]["Total Gross Proceeds (USD)"] = parseFloat(this.totalsTable[0]["Total Gross Proceeds (USD)"]).toFixed(2);
        this.totalsTable[0]["Total Gross Proceeds (USD)"] = this.commafy(this.totalsTable[0]["Total Gross Proceeds (USD)"].toString(),2);

        // this.totalsTable[0]["Total Number of Shares in Transaction"] = parseFloat(this.totalsTable[0]["Total Number of Shares in Transaction"]).toFixed(2);
        this.totalsTable[0]["Total Number of Shares in Transaction"] = this.commafy(this.totalsTable[0]["Total Number of Shares in Transaction"].toString());

        this.totalsTable[0]["Total Sale Price (USD)"] = parseFloat(this.totalsTable[0]["Total Sale Price (USD)"]).toFixed(2);
        this.totalsTable[0]["Total Sale Price (USD)"] = this.commafy(this.totalsTable[0]["Total Sale Price (USD)"].toString(),2);
        
        if(this.totalsTable[0]["Total Taxable Income(USD)"] != null)
        {
          this.totalsTable[0]["Total Taxable Income(USD)"] = parseFloat(this.totalsTable[0]["Total Taxable Income(USD)"]).toFixed(2);
          this.totalsTable[0]["Total Taxable Income(USD)"] = this.commafy(this.totalsTable[0]["Total Taxable Income(USD)"].toString(),2);
        }
        else
        {
          this.totalsTable[0]["Total Taxable Income(USD)"] = " ";
        }

        if(this.totalsTable[0]["Total ValuePerShareExerciseUsd"] != null)
        {
          this.totalsTable[0]["Total ValuePerShareExerciseUsd"] = parseFloat(this.totalsTable[0]["Total ValuePerShareExerciseUsd"]).toFixed(2);
          this.totalsTable[0]["Total ValuePerShareExerciseUsd"] = this.commafy(this.totalsTable[0]["Total ValuePerShareExerciseUsd"].toString(),2);
        }
        else
        {
          this.totalsTable[0]["Total ValuePerShareExerciseUsd"] = " ";
        }

        this.totalsTable[0]["Program"] = this.totalsTable[0]["Total"];
        this.totalsTable[0]["Number of Shares in Transaction"] = this.totalsTable[0]["Total Number of Shares in Transaction"];
        this.totalsTable[0]["Sale Price (USD)"] = "";
        this.totalsTable[0]["Gross Proceeds (USD)"] = this.totalsTable[0]["Total Gross Proceeds (USD)"];
        this.totalsTable[0]["Gain/(Loss) - USD"] = this.totalsTable[0]["Total Gain/(Loss) - USD"];
        this.totalsTable[0]["Gain/(Loss) - Local Currency"] = this.totalsTable[0]["Total Gain/(Loss) - Local Currency"];
        // this.totalsTable[0]["Value Per Share at Exercise (USD)"] = this.totalsTable[0]["Total ValuePerShareExerciseUsd"];
        // this.totalsTable[0]["Total Value on Exercise Date (USD)"] = this.totalsTable[0][""];
        // this.totalsTable[0]["Total Cost on Exercise Date (USD)"] = this.totalsTable[0]["Total Cost on Exercise Date (USD)"];
        // this.totalsTable[0]["Taxable Income(USD)"] = this.totalsTable[0]["Total Taxable Income(USD)"];
        // this.totalsTable[0]["Estimated Taxes Withheld at Exercise (USD)"] = this.totalsTable[0]["Total Estimated Taxes Withheld at Exercise (USD)"];
        // this.totalsTable[0]["Estimated Proceeds (USD)"] = this.totalsTable[0]["Total Estimated Proceeds (USD)"];
        this.totalsTable[0]["Value Per Share at Exercise (USD)"] = " ";
        this.totalsTable[0]["Total Value on Exercise Date (USD)"] = " ";
        this.totalsTable[0]["Total Cost on Exercise Date (USD)"] = " ";
        this.totalsTable[0]["Taxable Income(USD)"] = " ";
        this.totalsTable[0]["Estimated Taxes Withheld at Exercise (USD)"] = " ";
        this.totalsTable[0]["Estimated Proceeds (USD)"] = " ";

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.noDataInTable = false;

          if(this.length == this.lengthContainer || this.length<this.lengthContainer)
          {
            this.dataSource.push(this.totalsTable[0]);
            this.loading = false;
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
            setTimeout(()=>{ this.scrollS() }, 1000);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustSalesHistoryTable() }, 0.0000000000000000001);
            setTimeout(()=>{ this.totalsRowDesign3() }, 0.0000000000000000001);
            // $("#rsuGrants").css('pointer-events','auto');
          }
          else
          {            
            setTimeout(()=>{ this.returnScroll() }, 1000);
            setTimeout(()=>{ this.paginatorEnabler() }, 0.0000000000000000001);
            setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
            setTimeout(()=>{ this.adjustSalesHistoryTable() }, 0.0000000000000000001);
            // $("#rsuGrants").css('pointer-events','auto');
          }
          
          this.displayExportAndPagination = true;
          setTimeout(()=>{ this.removeUnwantedStyles() }, 0.0000000000000000001);
      setTimeout(()=>{ this.adjustSalesHistoryTable() }, 0.0000000000000000001);
      $("#shGrants").css('pointer-events','auto');
    }else{
      this.displayExportAndPagination = false;
      this.displayPageNavigator = true;
      this.noDataInTable = true;
      this.saleHistTotal = false;
      $("#shGrants").css('pointer-events','auto');
      this.paginatorEnabler();
    }
    this.salesHisttableLoading = false;
   } catch(error) {
    this.errorReportsPage = true;
    this.salesHisttableLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryData");
    error.message = error.message + errorLocation;
    throw error;
  }
  }, error => {
    this.errorReportsPage = true;
    this.salesHisttableLoading = false;
  let errorLocation = this.errorLocation.replace("methodName", "getSalesHistoryData");
  error.error.message += errorLocation;
  throw error.error;
  });
  } catch(error) {
    this.errorReportsPage = true;
    this.salesHisttableLoading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getExchangeRtCurrencyList");
    error.message += errorLocation;
    throw error;
  }
}
/**End of getSalesHistoryData method */

checkAll(event)
{
  this.SalesHistoryGrantData = [];
  try
  {
    if(event.checked == true)
    {
      this.checkAllPrograms = true;
      this.checkGrants = true;
      this.counterSalesHistoryPrograms = this.salesHistoryProgramTypeDropDownData.length;
      this.disableViewSalesHistory = false;
      this.displayAllGrantCheckbox = true;

      this.selectedPrograms = this.salesHistoryProgramTypeDropDownData;
      this.selectedHoldingIds = this.salesHistoryHoldingIDsOnLoad2;
      this.salesHistoryPrograms = this.salesHistoryProgramsOnLoad;

      for(let i=0; i<this.salesHistoryProgramTypeDropDownData.length; i++)
      {
        this.salesHistoryProgramTypeDropDownData[i]["isChecked"] = true;
      }
      // this.selectedHoldingIds = this.salesHistoryID;
      this.getSalesHistoryGrantData(this.selectedHoldingIds);
      // this.getSalesHistoryCheckboxData();
      setTimeout(()=>{ this.showAllGrantsSalesHistory() }, 0.0000000000000000001);

      sessionStorage.getItem("salesHistoryProgramsOnLoad");
    }
    else
    {
      this.checkAllPrograms = false;
      this.counterSalesHistoryPrograms =  0;
      this.disableViewSalesHistory = true;
      this.displayAllGrantCheckbox = false;
      this.hasRSUProgramBeenTriggered = false;
      
      for(let i=0; i<this.salesHistoryProgramTypeDropDownData.length; i++)
      {
        this.salesHistoryProgramTypeDropDownData[i]["isChecked"] = false;
      }

      this.selectedPrograms = [];
      this.selectedHoldingIds = [];
      this.SalesHistoryGrantData = [];
      setTimeout(()=>{ this.hideAllGrantsSalesHistory() }, 0.0000000000000000001);
    }
  }
  catch(error)
  {
    let errorLocation = this.errorLocation.replace("methodName", "checkAll");
    error.message = error.message + errorLocation;
    throw error;
  }
}

/************************************************************************************
 Method Name: selectedProg
 Description: Sales history report - Choose program based on dropdown

#       Date                   Name                    Description
1       04/19/19               Jayson Sevilla          Initial Draft
2       04/29/19               Katrina Narag(KMN)      Add conditions for other programs
************************************************************************************/
selectedProg(programName, event){
  try
  {
  // this.checkAllPrograms = false;
  // let program = event.source;
  // let programName = program._elementRef.nativeElement.innerText;
  let programList: string = "", holdingsIdList: string = "", holdingsId;
  
  //Program Name
  if(event.checked == true)
  {
    this.counterSalesHistoryPrograms++;
    this.disableViewSalesHistory = false;
    setTimeout(()=>{ this.showAllGrantsSalesHistory() }, 0.0000000000000000001);

    if(programName.programDescription == "RSU" && this.numberOfRSUsInProgramList == 1)
    {
      this.hasRSUProgramBeenTriggered = false;
      holdingsId = this.getHoldingsID(programName.programDescription);
      this.hasRSUProgramBeenTriggered = true;
    }
    else if(programName.programDescription == "RSU" && this.numberOfRSUsInProgramList > 1)
    {
      this.hasRSUProgramBeenTriggered = false;
      holdingsId = this.getHoldingsID(programName.programDescription);
      this.hasRSUProgramBeenTriggered = true;
    }
    else
    {
      this.hasRSUProgramBeenTriggered = false;
      holdingsId = this.getHoldingsID(programName.programDescription);
      this.hasRSUProgramBeenTriggered = false;
    }

    if(this.counterSalesHistoryPrograms == this.salesHistoryProgramTypeDropDownData.length)
    {
      this.selectedPrograms.push(programName);
      this.selectedHoldingIds.push(holdingsId);
      this.checkAllPrograms = true;
      this.disableProgram = true;
      // this.checkGrants = true;
    }
    else
    {
      this.selectedPrograms.push(programName);
      this.selectedHoldingIds.push(holdingsId);
      this.disableProgram = true;
      // this.checkGrants = true;
    }

    for(let i=0; i<this.salesHistoryProgramTypeDropDownData.length; i++)
    {
      if(this.salesHistoryProgramTypeDropDownData[i].programDescription == programName.programDescription)
      {
        this.salesHistoryProgramTypeDropDownData[i]["isChecked"] = true;
      }
    }
  }

  else if(event.checked == false)
  {
    if(programName.programDescription == "RSU" && this.numberOfRSUsInProgramList == 1)
    {
      this.hasRSUProgramBeenTriggered = false;
      holdingsId = this.getHoldingsID(programName.programDescription);
    }
    else if(programName.programDescription == "RSU" && this.numberOfRSUsInProgramList > 1)
    {
      this.hasRSUProgramBeenTriggered = false;
      holdingsId = this.getHoldingsID(programName.programDescription);
    }
    else
    {
      this.hasRSUProgramBeenTriggered = false;
      holdingsId = this.getHoldingsID(programName.programDescription);
      this.hasRSUProgramBeenTriggered = false;
    }

    this.selectedPrograms = this.selectedPrograms.filter(item => item.programDescription !== programName.programDescription);
    this.selectedHoldingIds = this.selectedHoldingIds.filter(item => item !== holdingsId);
    this.disableProgram = true;

    for(let i=0; i<this.salesHistoryProgramTypeDropDownData.length; i++)
    {
      if(this.salesHistoryProgramTypeDropDownData[i].programDescription == programName.programDescription)
      {
        this.salesHistoryProgramTypeDropDownData[i]["isChecked"] = false;
      }
    }

    this.checkAllPrograms = false;
    this.counterSalesHistoryPrograms--;

    if(this.counterSalesHistoryPrograms == 0)
    {
      this.disableViewSalesHistory = true;
      setTimeout(()=>{ this.hideAllGrantsSalesHistory() }, 0.0000000000000000001);
      this.disableProgram = false;
    }
  }


  if(this.selectedPrograms.length > 0)
  {
    for(let i = 0; i < this.selectedPrograms.length; i++) 
    {
      if(i < this.selectedPrograms.length - 1) 
      {
        programList = programList + this.selectedPrograms[i].programDescription + ",";
      } 
      else 
      {
        programList = programList + this.selectedPrograms[i].programDescription;
      }
    }

    if(programList == "ESPP" || programList == "VEIP")
    {
      this.displayAllGrantCheckbox = false;
      this.SalesHistoryGrantData = [];
    }
    else if(programList == "ESPP,VEIP" || programList == "VEIP,ESPP")
    {
      this.displayAllGrantCheckbox = false;
      this.SalesHistoryGrantData = [];
    }
    else
    {
      this.displayAllGrantCheckbox = true;
    }
  }


  if(this.selectedHoldingIds.length > 0)
  {
    for(let i = 0; i < this.selectedHoldingIds.length; i++) 
    {
      if(i < this.selectedHoldingIds.length - 1) 
      {
        holdingsIdList = holdingsIdList + this.selectedHoldingIds[i] + ",";
      } 
      else 
      {
        holdingsIdList = holdingsIdList + this.selectedHoldingIds[i];
      }
    }
  }
  else
  {
    holdingsIdList = " ";
  }


  if(programName.programDescription == "RSU" && this.numberOfRSUsInProgramList > 1)
  {
    if(this.hasRSUProgramBeenTriggered == true)
    {
      holdingsIdList = holdingsIdList.concat(",4");
      holdingsIdList = holdingsIdList.replace("4,","");
    }
    else if(this.hasRSUProgramBeenTriggered == false)
    {
      holdingsIdList = holdingsIdList.replace("4,", "");
      holdingsIdList = holdingsIdList.replace("2,4,", " ");
      holdingsIdList = holdingsIdList.replace(",4", " ");
    }
  }
  else if(programName.programDescription == "RSU" && this.numberOfRSUsInProgramList == 1)
  {
    if(this.hasRSUProgramBeenTriggered == true)
    {
      holdingsIdList = holdingsIdList.concat(",4");
      holdingsIdList = holdingsIdList.replace("4,","");
    }
    else if(this.hasRSUProgramBeenTriggered == false)
    {
      holdingsIdList = holdingsIdList.replace("4,", "");
      holdingsIdList = holdingsIdList.replace("2,4,", " ");
      holdingsIdList = holdingsIdList.replace(",4", " ");
    }
  }
  else if(programName.programDescription != "RSU" && this.numberOfRSUsInProgramList > 1)
  {
    for(let i=0; i<this.salesHistoryProgramTypeDropDownData.length; i++)
    {
      if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "RSU")
      {
        if(this.salesHistoryProgramTypeDropDownData[i]["isChecked"] == true)
        {
          holdingsIdList = holdingsIdList.concat(",4");
          holdingsIdList = holdingsIdList.replace("4,","");
        }
        else if(this.salesHistoryProgramTypeDropDownData[i]["isChecked"] == false)
        {
          holdingsIdList = holdingsIdList.replace("4,", "");
          holdingsIdList = holdingsIdList.replace(",4", " ");
        }
      }
    }
  }
  else if(programName.programDescription != "RSU" && this.numberOfRSUsInProgramList == 1)
  {
    for(let i=0; i<this.salesHistoryProgramTypeDropDownData.length; i++)
    {
      if(this.salesHistoryProgramTypeDropDownData[i].programDescription == "RSU")
      {
        if(this.salesHistoryProgramTypeDropDownData[i]["isChecked"] == true)
        {
          holdingsIdList = holdingsIdList.concat(",4");
          holdingsIdList = holdingsIdList.replace("4,","");
        }
        else if(this.salesHistoryProgramTypeDropDownData[i]["isChecked"] == false)
        {
          holdingsIdList = holdingsIdList.replace("4,", "");
          holdingsIdList = holdingsIdList.replace(",4", " ");
        }
      }
    }
  }


  //Program's Holding ID
    this.salesHistoryPrograms = programList;
    sessionStorage.setItem("salesHistProgram",programList);
    sessionStorage.setItem("salesHistIds",holdingsIdList);

    // this.getSalesHistoryGrantData(holdingsIdList);

    if(programName.programDescription == "ESPP" || programName.programDescription == "VEIP")
    {
      this.loading = false;
    }
    else
    {
      setTimeout(()=>{ this.executeSalesHistoryGrantData(holdingsIdList) }, 1000); 
    }
  }

catch(error) 
{
  let errorLocation = this.errorLocation.replace("methodName", "selectedProg");
  error.message = error.message + errorLocation;
  throw error;
}
}


executeSalesHistoryGrantData(holdingsIdList)
{
  this.getSalesHistoryGrantData(holdingsIdList);
}

async sortData(data, fieldName){
  try {;
    data.sort(function(a, b){
      var array = [a[fieldName], b[fieldName]];
      array.sort();
      if (array[0] == a[fieldName])
        return -1;
      else if(array[0] == b[fieldName])
        return 1;
      else
        return 0;
    });
    return data;
  } catch(error) {
    let errorLocation = " [Component: Homepage, Method: sortData]";
    error.message = error.message + errorLocation;
    throw error;
  }
}

expandGrants(){
  $("#allGrants").css('height','658px') ;
  $("#allGrantsDiv").css('height','658px') ;
  $("#parentGrantDiv").css('position','absolute') ;
  $("#allGrants").css('overflow-y','auto') ;
  $("#allGrants").css('overflow-x','hidden') ;
  $("#hideButton").css('display','block') ;
  $("#seeAllButton").css('display','none') ;
  $("#containerFluid").css('min-height','1000px') ;

}

collapseGrants(){
  $("#allGrants").css('height','157px') ;
  $("#allGrantsDiv").css('height','157px') ;
  $("#parentGrantDiv").css('position','relative') ;
  $("#allGrants").css('overflow','hidden') ;
  $("#hideButton").css('display','none') ;
  $("#seeAllButton").css('display','block') ;
  $("#containerFluid").css('min-height','0') ;
}

hideAllGrantsSalesHistory()
{
  $("#allGrants").css('display','none');
  $("#seeAllButton").css('display','none');
  // $("#hideButton").css('display','none');
  // this.collapseGrants();

  $("#allGrants").css('height','157px') ;
  $("#allGrantsDiv").css('height','157px') ;
  $("#parentGrantDiv").css('position','relative') ;
  $("#allGrants").css('overflow','hidden') ;
  $("#hideButton").css('display','none') ;
  
  $("#containerFluid").css('min-height','0') ;
}

showAllGrantsSalesHistory()
{
  $("#allGrants").css('display','block');
  $("#seeAllButton").css('display','block');
  // $("#hideButton").css('display','block');
}


/************************************************************************************
 Method Name: checkCheckBoxvalue
 Description: Sales history report - Choose grants based on dropdown
# Date Name Description
1 04/19/19 Jayson Sevilla Initial Draft
2 04/29/19 Katrina Narag(KMN) Add conditions for other programs
************************************************************************************/
checkCheckBoxvalue(program, event){
  try{
  // let program = event.source;
  // let grantName = program._elementRef.nativeElement.innerText;
  let grantList: string = "";
 
  //Grant Name

  if(event.checked == true)
  {
    this.disableViewSalesHistory = false;
    this.counterSalesHistoryGrants++;
    if(this.counterSalesHistoryGrants == this.SalesHistoryGrantData.length)
    {
      this.selectedGrantsList.push(program);
      this.checkGrants = true;
    }
    else
    {
      this.selectedGrantsList.push(program);
    }

    for(let i=0; i<this.SalesHistoryGrantData.length; i++)
    {
      if(this.SalesHistoryGrantData[i].GrantLongDesc == program.GrantLongDesc)
      {
        this.SalesHistoryGrantData[i]["checkGrants"] = true;
      }
    }
  }
  else if(event.checked == false)
  {
    this.counterSalesHistoryGrants--;
    this.checkGrants = false;
    this.selectedGrantsList = this.selectedGrantsList.filter(item => item.GrantLongDesc !== program.GrantLongDesc);

    for(let i=0; i<this.SalesHistoryGrantData.length; i++)
    {
      if(this.SalesHistoryGrantData[i].GrantLongDesc == program.GrantLongDesc)
      {
        this.SalesHistoryGrantData[i]["checkGrants"] = false;
      }
    }

    if(this.counterSalesHistoryPrograms == 0)
    {
      this.disableViewSalesHistory = true;
    }
  }


  if(this.selectedGrantsList.length > 0)
  {
    for(let i = 0; i < this.selectedGrantsList.length; i++) 
    {
      if(i < this.selectedGrantsList.length - 1) 
      {
        grantList = grantList + this.selectedGrantsList[i].GrantLongDesc + ",";
      } 
      else 
      {
        grantList = grantList + this.selectedGrantsList[i].GrantLongDesc;
      }
    }
  }
  this.salesHistoryGrants = grantList;
  sessionStorage.setItem("salesHistGrants",grantList);

} catch(error) {
  let errorLocation = this.errorLocation.replace("methodName", "checkCheckBoxvalue");
  error.message = error.message + errorLocation;
  throw error;
}
 }

 checkAllGrants(event)
 {
  if(event.checked == true)
  { 
    this.disableViewSalesHistory = false;
    this.counterSalesHistoryGrants = this.SalesHistoryGrantData.length;
    this.checkGrants = true;
    // this.selectedGrantsList = 
    this.salesHistoryGrants = this.salesHistoryGrantsOnLoad;
    this.selectedGrantsList = this.SalesHistoryGrantData;
    for(let i=0; i<this.SalesHistoryGrantData.length; i++)
    {
      this.SalesHistoryGrantData[i]["checkGrants"] = true;
    }
  }
  else
  {
    this.disableViewSalesHistory = false;
    this.counterSalesHistoryGrants = 0;
    this.checkGrants = false;
    
    for(let i=0; i<this.SalesHistoryGrantData.length; i++)
    {
      this.SalesHistoryGrantData[i]["checkGrants"] = false;
    }
    this.salesHistoryGrants = "";
    this.selectedGrantsList = [];

    if(this.salesHistoryPrograms == "ESPP")
    {
      this.salesHistoryPrograms = "ESPP";
    }
    else if(this.salesHistoryPrograms == "VEIP")
    {
      this.salesHistoryPrograms = "VEIP";
    }
    else if(this.salesHistoryPrograms == "ESPP,VEIP" || this.salesHistoryPrograms == "VEIP,ESPP")
    {
      this.salesHistoryPrograms = "ESPP,VEIP";
    }
  }
 }

 exportFile()
 { 
    if(this.reportType == "RSU Activity")
    { // RSU
        let grantDescription = sessionStorage.getItem("rsuActivitySelectedGrant");
        let isAllGrantsChecked = sessionStorage.getItem("isAllGrantsChecked");

        grantDescription != null ? "" : grantDescription = sessionStorage.getItem("rsuActgrantsOnLoad");
        grantDescription != "" ? "" : grantDescription = sessionStorage.getItem("rsuActgrantsOnLoad");
        isAllGrantsChecked == "Y" ? grantDescription = sessionStorage.getItem("rsuActgrantsOnLoad") : "";

        this.reportsService.getRSUActivityDataData(this.user.OptNum, this.legitDateFrom, this.legitDateTo, -99, grantDescription).subscribe(data => {     

          let rows = data[0];data[0].sort((a, b) =>  moment(a["Transaction Date"]).format("YYYY/MM/DD") < moment(b["Transaction Date"]).format("YYYY/MM/DD") ? 1: moment(a["Transaction Date"]).format("YYYY/MM/DD") > moment(b["Transaction Date"]).format("YYYY/MM/DD") ? -1: 0);
          // let rows = data[0];data[0].sort((a, b) =>  a["Transaction Date"] < b["Transaction Date"] ? 1: a["Transaction Date"] > b["Transaction Date"] ? -1: 0);
          // let rows = data[0];
          // let totalrows = data[1];

          for(let i=0; i<rows.length; i++)
        {
          // rows[i]["Grant Date"] = moment(rows[i]["Grant Date"]).format("DD MMM YYYY");
          // rows[i]["Transaction Date"] = moment(rows[i]["Transaction Date"]).format("DD MMM YYYY");
          // rows[i]["Fair Market Value per Share on Release Date (USD)"] = parseFloat(rows[i]["Fair Market Value per Share on Release Date (USD)"]).toFixed(2);
          // rows[i]["Fair Market Value per Share on Release Date (USD)"] = this.commafy(rows[i]["Fair Market Value per Share on Release Date (USD)"], 2);
          // rows[i]["Shares Vested"] = parseFloat(rows[i]["Shares Vested"]).toFixed(2);
          // rows[i]["Shares Vested"] = this.commafy(rows[i]["Shares Vested"], 2);
          // rows[i]["Shares Released"] = parseFloat(rows[i]["Shares Released"]).toFixed(2);
          // rows[i]["Shares Released"] = this.commafy(rows[i]["Shares Released"], 2);
          // rows[i]["Total Value on Transaction Date in Local Currency"] = parseFloat(rows[i]["Total Value on Transaction Date in Local Currency"]).toFixed(2);
          // rows[i]["Total Value on Transaction Date in Local Currency"] = this.commafy(rows[i]["Total Value on Transaction Date in Local Currency"], 2);
          // rows[i]["Shares Withheld For Taxes"] = parseFloat(rows[i]["Shares Withheld For Taxes"]).toFixed(2);
          // rows[i]["Shares Withheld For Taxes"] = this.commafy(rows[i]["Shares Withheld For Taxes"], 2);
          // rows[i]["Total Value of Taxes Withheld in Local Currency"] = parseFloat(rows[i]["Total Value of Taxes Withheld in Local Currency"]).toFixed(2);
          // rows[i]["Total Value of Taxes Withheld in Local Currency"] = this.commafy(rows[i]["Total Value of Taxes Withheld in Local Currency"], 2);
          // rows[i]["Sale-Fractional Share"] = parseFloat(rows[i]["Sale-Fractional Share"]).toFixed(2);
          // rows[i]["Sale-Fractional Share"] = this.commafy(rows[i]["Sale-Fractional Share"], 2); 
          // rows[i]["Value of Fractional Share Returned in Local Currency"] = parseFloat(rows[i]["Value of Fractional Share Returned in Local Currency"]).toFixed(2);
          // rows[i]["Value of Fractional Share Returned in Local Currency"] = this.commafy(rows[i]["Value of Fractional Share Returned in Local Currency"], 2);
          // rows[i]["Total Shares W/H"] = parseFloat(rows[i]["Total Shares W/H"]).toFixed(2);
          // rows[i]["Total Shares W/H"] = this.commafy(rows[i]["Total Shares W/H"], 2);
          // rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = parseFloat(rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"]).toFixed(2);
          // rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = this.commafy(rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"], 2);
          // rows[i]["Shares Released to Broker"] = parseFloat(rows[i]["Shares Released to Broker"]).toFixed(2);
          // rows[i]["Shares Released to Broker"] = this.commafy(rows[i]["Shares Released to Broker"], 2);

          if(rows[i]["Fair Market Value per Share on Release Date (USD)"] != null)
          {
            rows[i]["Fair Market Value per Share on Release Date (USD)"] = parseFloat(rows[i]["Fair Market Value per Share on Release Date (USD)"]).toFixed(4);
            rows[i]["Fair Market Value per Share on Release Date (USD)"] = this.commafy(rows[i]["Fair Market Value per Share on Release Date (USD)"], 4);
          }
          else
          {
            rows[i]["Fair Market Value per Share on Release Date (USD)"] = " ";
          }

          if(rows[i]["Exchange Rate in Event Date"] != null)
          {
            rows[i]["Exchange Rate in Event Date"] = parseFloat(rows[i]["Exchange Rate in Event Date"]).toFixed(4);
            rows[i]["Exchange Rate in Event Date"] = this.commafy(rows[i]["Exchange Rate in Event Date"], 4);
          }
          else
          {
            rows[i]["Exchange Rate in Event Date"] = " ";
          }
          
          if(rows[i]["Shares Vested"] != null)
          {
            // rows[i]["Shares Vested"] = parseFloat(rows[i]["Shares Vested"]).toFixed(2);
            rows[i]["Shares Vested"] = this.commafy(rows[i]["Shares Vested"]);
          }
          else
          {
            rows[i]["Shares Vested"] = " ";
          }
          
          if(rows[i]["Shares Released"] != null)
          {
            // rows[i]["Shares Released"] = parseFloat(rows[i]["Shares Released"]).toFixed(2);
            rows[i]["Shares Released"] = this.commafy(rows[i]["Shares Released"]);
          }
          else
          {
            rows[i]["Shares Released"] = " ";
          }

          if(rows[i]["Total Value on Transaction Date in Local Currency"] != null)
          {
            rows[i]["Total Value on Transaction Date in Local Currency"] = parseFloat(rows[i]["Total Value on Transaction Date in Local Currency"]).toFixed(2);
            rows[i]["Total Value on Transaction Date in Local Currency"] = this.commafy(rows[i]["Total Value on Transaction Date in Local Currency"], 2);
          }
          else
          {
            rows[i]["Total Value on Transaction Date in Local Currency"] = " ";
          }

          if(rows[i]["Shares Withheld For Taxes"] != null)
          {
            rows[i]["Shares Withheld For Taxes"] = parseFloat(rows[i]["Shares Withheld For Taxes"]).toFixed(2);
            rows[i]["Shares Withheld For Taxes"] = this.commafy(rows[i]["Shares Withheld For Taxes"], 2);
          }
          else
          {
            rows[i]["Shares Withheld For Taxes"] = " ";
          }

          if(rows[i]["Total Value of Taxes Withheld in Local Currency"] != null)
          {
            rows[i]["Total Value of Taxes Withheld in Local Currency"] = parseFloat(rows[i]["Total Value of Taxes Withheld in Local Currency"]).toFixed(2);
            rows[i]["Total Value of Taxes Withheld in Local Currency"] = this.commafy(rows[i]["Total Value of Taxes Withheld in Local Currency"], 2);
          }
          else
          {
            rows[i]["Total Value of Taxes Withheld in Local Currency"] = " ";
          }
          
          if(rows[i]["Sale-Fractional Share"] != null)
          {
            rows[i]["Sale-Fractional Share"] = parseFloat(rows[i]["Sale-Fractional Share"]).toFixed(4);
            rows[i]["Sale-Fractional Share"] = this.commafy(rows[i]["Sale-Fractional Share"], 4); 
          }
          else
          {
            rows[i]["Sale-Fractional Share"] = " ";
          }
          
          if(rows[i]["Value of Fractional Share Returned in Local Currency"] != null)
          {
            rows[i]["Value of Fractional Share Returned in Local Currency"] = parseFloat(rows[i]["Value of Fractional Share Returned in Local Currency"]).toFixed(2);
            rows[i]["Value of Fractional Share Returned in Local Currency"] = this.commafy(rows[i]["Value of Fractional Share Returned in Local Currency"], 2);
          }
          else
          {
            rows[i]["Value of Fractional Share Returned in Local Currency"] = " ";
          }

          if(rows[i]["Total Shares W/H"] != null)
          {
            rows[i]["Total Shares W/H"] = parseFloat(rows[i]["Total Shares W/H"]).toFixed(2);
            rows[i]["Total Shares W/H"] = this.commafy(rows[i]["Total Shares W/H"], 2);
          }
          else
          {
            rows[i]["Total Shares W/H"] = " ";
          }
          
          if(rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] != null)
          {
            rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = parseFloat(rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"]).toFixed(2);
            rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = this.commafy(rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"], 2);
          }
          else
          {
            rows[i]["Total Value of Shares W/H in Local Currency (Taxes Withheld + Fractional Shares Returned)"] = " ";
          }
          
          if(rows[i]["Shares Released to Broker"] != null)
          {
            rows[i]["Shares Released to Broker"] = parseFloat(rows[i]["Shares Released to Broker"]).toFixed(2);
            rows[i]["Shares Released to Broker"] = this.commafy(rows[i]["Shares Released to Broker"], 2);
          }
          else
          {
            rows[i]["Shares Released to Broker"] = " ";
          }
        }

          rows.push(this.totalsTable[0]);
          
          this.exportExcelService.getReportInfo(this.reportType, this.programType, this.displayedColumns, rows);
          });
          
    }
    else if(this.reportType == "Share Price History")
    {
        this.sharePriceService.getSharePriceHistory(this.dateFrom.replace(/\'/g, ""), this.dateTo.replace(/\'/g, ""), -99, 'Y').subscribe(data => { 
          
          let rows = data[0];
          let totalsrow = data[1];

          for(let i=0; i<rows.length; i++)
          {
            // rows[i]["Date"] = moment(rows[i]["Date"]).format("DD MMM YYYY");           
            rows[i]["Trading Volume"] = this.commafy(rows[i]["Trading Volume"]);
            rows[i]["High (USD)"] = parseFloat(rows[i]["High (USD)"]).toFixed(4);
            rows[i]["High (USD)"] = this.commafy(rows[i]["High (USD)"].toString(),4);
            rows[i]["Low (USD)"] = parseFloat(rows[i]["Low (USD)"]).toFixed(4);
            rows[i]["Low (USD)"] = this.commafy(rows[i]["Low (USD)"].toString(),4);
            rows[i]["Close (USD)"] = parseFloat(rows[i]["Close (USD)"]).toFixed(4);
            rows[i]["Close (USD)"] = this.commafy(rows[i]["Close (USD)"].toString(),4);
            rows[i]["Fair Market Value (USD)"] = parseFloat(rows[i]["Fair Market Value (USD)"]).toFixed(4);
            rows[i]["Fair Market Value (USD)"] = this.commafy(rows[i]["Fair Market Value (USD)"].toString(),4);
          }
          this.exportExcelService.getReportInfo(this.reportType, this.programType, this.displayedColumns, rows);
        });
    }
    else if(this.reportType == "Exchange Rate History")
    {
      this.reportsService.getExchangeReportsHistory("'"+this.currentCode+"'", this.dateFrom, this.dateTo, -99).subscribe(data => {
        
        let rows = data[0];
        let totalsrow = [];
        let list = this.completeCurrencyList;
        let currencyDecode = list.find(i => i.Decode === this.currentSelected);
        let currencyCode = currencyDecode.Code;

        for(let i=0; i<rows.length; i++)
        {
          
          rows[i]["Currency Exchange Rate"] = rows[i]["Currency Exchange Rate"].toFixed(4);
        }

        rows.forEach(element => {
          element["Currency Exchange Rate"] = currencyCode + ' ' + element["Currency Exchange Rate"];
        });

        this.exportExcelService.getReportInfo(this.reportType, this.programType, this.displayedColumns, rows);
      }); 
    }
    else if(this.reportType == "Enrollment History")
    {
      this.enrollservice.getEnrollmentData(this.user.OptNum, this.programType, this.dateFrom, this.dateTo, -99).subscribe(data => {
        let rows = data[0];
        let totalrows = []

        for(let i=0; i<rows.length; i++)
        {
          rows[i]["Contribution Percent"] = rows[i]["Contribution Percent"].toString() + "%";
        }
        
        this.exportExcelService.getReportInfo(this.reportType, this.programType, this.displayedColumns, rows);
      });
    }
    else if(this.reportType == "Purchase Program")
    {
      this.reportsService.getPurchaseProgramData(this.user.OptNum, this.programType, this.legitDateFrom, this.legitDateTo, -99).subscribe(data => {
        let currencyCode = data[0][0]["LocalCurrencyCd"];
        let rows = data[0];
        let totalrows = data[1];
        
        if(this.programType == "VEIP")
        {
          for(let i=0; i<rows.length; i++)
          {
            rows[i]["Monthly Contribution Plus Prior Month's Carryover (USD)"] = parseFloat(rows[i]["Monthly Contribution Plus Prior Month's Carryover (USD)"]).toFixed(2);
            rows[i]["Monthly Contribution Plus Prior Month's Carryover (USD)"] = this.commafy(rows[i]["Monthly Contribution Plus Prior Month's Carryover (USD)"], 2);
            rows[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"] = parseFloat(rows[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"]).toFixed(2);
            // rows[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"] = currencyCode + ' ' + this.commafy(rows[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"], 2);
            rows[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"] = this.commafy(rows[i]["LocalCurrencyCd"]) + ' ' + this.commafy(rows[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"], 2);
            rows[i]["Exchange Rate"] = parseFloat(rows[i]["Exchange Rate"]).toFixed(2);
            rows[i]["Exchange Rate"] = this.commafy(rows[i]["Exchange Rate"], 2);
            rows[i]["Total Value of Shares Purchased (USD)"] = rows[i]["Total Value of Shares Purchased (USD)"].toFixed(4);
            rows[i]["Total Value of Shares Purchased (USD)"] = this.commafy(rows[i]["Total Value of Shares Purchased (USD)"], 4);
            rows[i]["Total Value of Shares Purchased (Local)"] = rows[i]["Total Value of Shares Purchased (Local)"].toFixed(2);
            // rows[i]["Total Value of Shares Purchased (Local)"] = currencyCode + ' ' + this.commafy(rows[i]["Total Value of Shares Purchased (Local)"], 2);
            rows[i]["Total Value of Shares Purchased (Local)"] = this.commafy(rows[i]["LocalCurrencyCd"]) + ' ' + this.commafy(rows[i]["Total Value of Shares Purchased (Local)"], 2);
            rows[i]["Contribution to be Carried Over/Refunded (USD)"] = rows[i]["Contribution to be Carried Over/Refunded (USD)"].toFixed(2);
            rows[i]["Contribution to be Carried Over/Refunded (USD)"] = this.commafy(rows[i]["Contribution to be Carried Over/Refunded (USD)"], 2);
            rows[i]["Contribution to be Carried Over/Refunded (Local)"] = rows[i]["Contribution to be Carried Over/Refunded (Local)"].toFixed(2);
            // rows[i]["Contribution to be Carried Over/Refunded (Local)"] = currencyCode + ' ' + this.commafy(rows[i]["Contribution to be Carried Over/Refunded (Local)"], 2);
            rows[i]["Contribution to be Carried Over/Refunded (Local)"] = this.commafy(rows[i]["LocalCurrencyCd"]) + ' ' + this.commafy(rows[i]["Contribution to be Carried Over/Refunded (Local)"], 2);
            rows[i]["Purchase Price FMV (USD)"] = parseFloat(rows[i]["Purchase Price FMV (USD)"]).toFixed(4);
            rows[i]["Purchase Price FMV (USD)"] = this.commafy(rows[i]["Purchase Price FMV (USD)"], 4);
            rows[i]["Total Shares Purchased"] = this.commafy(rows[i]["Total Shares Purchased"]);
            rows[i]["Shares Disposed"] = this.commafy(rows[i]["Shares Disposed"]);
            rows[i]["Shares Available"] = this.commafy(rows[i]["Shares Available"]);
            rows[i]["Contribution %"] = rows[i]["Contribution %"].toString() + "%";
            rows[i]["PROGRAM"] = rows[i]["Program"];
            rows[i]["PURCHASE DATE"] = rows[i]["Purchase Date"];
            rows[i]["GRANT ID"] = rows[i]["Grant ID"];
            rows[i]["CONTRIBUTION %"] = rows[i]["Contribution %"];
            rows[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"] = rows[i]["Monthly Contribution Plus Prior Month's Carryover (Local)"];
            rows[i]["EXCHANGE RATE"] = rows[i]["Exchange Rate"];
            rows[i]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (USD)"] = rows[i]["Monthly Contribution Plus Prior Month's Carryover (USD)"];
            rows[i]["PURCHASE PRICE FMV (USD)"] = rows[i]["Purchase Price FMV (USD)"];
            rows[i]["TOTAL SHARES PURCHASED"] = rows[i]["Total Shares Purchased"];
            rows[i]["TOTAL VALUE OF SHARES PURCHASED (USD)"] = rows[i]["Total Value of Shares Purchased (USD)"];
            rows[i]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"] = rows[i]["Total Value of Shares Purchased (Local)"];
            rows[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (USD)"] = rows[i]["Contribution to be Carried Over/Refunded (USD)"];
            rows[i]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"] = rows[i]["Contribution to be Carried Over/Refunded (Local)"];
            rows[i]["SHARES DISPOSED"] = rows[i]["Shares Disposed"];
            rows[i]["SHARES AVAILABLE"] = rows[i]["Shares Available"];
            rows[i]["BROKER FOR MORE INFORMATION"] = rows[i]["Broker for More Information"];
          }
          
            totalrows[0]['Totals'] = "Total";
            totalrows[0]["Total Contribution %"] = " ";

            totalrows[0]["Total Contribution to be Carried Over/Refunded (Local)"] = parseFloat(totalrows[0]["Total Contribution to be Carried Over/Refunded (Local)"]).toFixed(2);
            totalrows[0]["Total Contribution to be Carried Over/Refunded (Local)"] = currencyCode + ' ' +  this.commafy(totalrows[0]["Total Contribution to be Carried Over/Refunded (Local)"], 2);

            totalrows[0]["Total Contribution to be Carried Over/Refunded (USD)"] = parseFloat(totalrows[0]["Total Contribution to be Carried Over/Refunded (USD)"]).toFixed(2);
            totalrows[0]["Total Contribution to be Carried Over/Refunded (USD)"] = this.commafy(totalrows[0]["Total Contribution to be Carried Over/Refunded (USD)"], 2);

            totalrows[0]["Total Exchange Rate"] = this.commafy(totalrows[0]["Total Exchange Rate"]);

            totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"] = parseFloat(totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"]).toFixed(2);
            totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"] = currencyCode + ' ' + this.commafy(totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"], 2);
            
            totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"] = parseFloat(totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"]).toFixed(2);
            totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"] = this.commafy(totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"], 2);

            totalrows[0]["Total Purchase Price FMV (USD)"] = this.commafy(totalrows[0]["Total Purchase Price FMV (USD)"], 4);
            totalrows[0]["Total Shares Available"] = this.commafy(totalrows[0]["Total Shares Available"]);
            totalrows[0]["Total Shares Disposed"] = this.commafy(totalrows[0]["Total Shares Disposed"]);
            totalrows[0]["Total Shares Purchased"] = this.commafy(totalrows[0]["Total Shares Purchased"]);
            
            totalrows[0]["Total Value of Shares Purchased (Local)"] = totalrows[0]["Total Value of Shares Purchased (Local)"].toFixed(2);
            totalrows[0]["Total Value of Shares Purchased (Local)"] = currencyCode + ' ' + this.commafy(totalrows[0]["Total Value of Shares Purchased (Local)"], 2);
            
            totalrows[0]["Total Value of Shares Purchased (USD)"] = totalrows[0]["Total Value of Shares Purchased (USD)"].toFixed(4);
            totalrows[0]["Total Value of Shares Purchased (USD)"] = this.commafy(totalrows[0]["Total Value of Shares Purchased (USD)"], 4);
            
            totalrows[0]["Broker for More Information"] = " ";

            totalrows[0]["PROGRAM"] = totalrows[0]["Totals"];
            // totalrows[0]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"] = totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (Local)"];
            totalrows[0]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (LOCAL)"] = " ";
            totalrows[0]["MONTHLY CONTRIBUTION PLUS PRIOR MONTH'S CARRYOVER (USD)"] = totalrows[0]["Total Monthly Contribution Plus Prior Month's Carryover (USD)"];
            totalrows[0]["PURCHASE PRICE FMV (USD)"] = totalrows[0]["Total Purchase Price FMV (USD)"];
            totalrows[0]["TOTAL SHARES PURCHASED"] = totalrows[0]["Total Shares Purchased"];
            totalrows[0]["TOTAL VALUE OF SHARES PURCHASED (USD)"] = totalrows[0]["Total Value of Shares Purchased (USD)"];
            // totalrows[0]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"] = totalrows[0]["Total Value of Shares Purchased (Local)"];
            totalrows[0]["TOTAL VALUE OF SHARES PURCHASED (LOCAL)"] = " ";
            totalrows[0]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (USD)"] = totalrows[0]["Total Contribution to be Carried Over/Refunded (USD)"];
            // totalrows[0]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"] = totalrows[0]["Total Contribution to be Carried Over/Refunded (Local)"];
            totalrows[0]["CONTRIBUTION TO BE CARRIED OVER/REFUNDED (LOCAL)"] = " ";
            totalrows[0]["SHARES DISPOSED"] = totalrows[0]["Total Shares Disposed"];
            totalrows[0]["SHARES AVAILABLE"] = totalrows[0]["Total Shares Available"];
            totalrows[0]["BROKER FOR MORE INFORMATION"] = totalrows[0]["Broker for More Information"];
            totalrows[0]["EXCHANGE RATE"] = " ";

            rows.push(totalrows[0]);

          this.exportExcelService.getReportInfo(this.reportType, this.programType, this.displayedColumns, rows);
        }
        else
        {
          for(let i=0; i<rows.length; i++)
          {
            rows[i]["Total Contributions (Loc)"] = parseFloat(rows[i]["Total Contributions (Loc)"]).toFixed(2);
            // rows[i]["Total Contributions (Loc)"] = currencyCode + ' ' + this.commafy(rows[i]["Total Contributions (Loc)"].toString(),2);
            rows[i]["Total Contributions (Loc)"] = this.commafy(rows[i]["LocalCurrencyCd"]) + ' ' + this.commafy(rows[i]["Total Contributions (Loc)"].toString(),2);
            rows[i]["Total Contributions (USD)"] = parseFloat(rows[i]["Total Contributions (USD)"]).toFixed(2);
            rows[i]["Total Contributions (USD)"] = this.commafy(rows[i]["Total Contributions (USD)"].toString(),2);
            rows[i]["Discounted Purchase Price (USD)"] = parseFloat(rows[i]["Discounted Purchase Price (USD)"]).toFixed(4);
            rows[i]["Discounted Purchase Price (USD)"] = this.commafy(rows[i]["Discounted Purchase Price (USD)"].toString(),4);
            rows[i]["Total Shares Purchased"] = parseFloat(rows[i]["Total Shares Purchased"]).toFixed(4);
            rows[i]["Total Shares Purchased"] = this.commafy(rows[i]["Total Shares Purchased"].toString(),4);
            rows[i]["FMV at Purchase (USD)"] = parseFloat(rows[i]["FMV at Purchase (USD)"]).toFixed(4);
            rows[i]["FMV at Purchase (USD)"] = this.commafy(rows[i]["FMV at Purchase (USD)"].toString(),4);
            rows[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"] = parseFloat(rows[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"]).toFixed(4);
            // rows[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"] = currencyCode + ' ' + this.commafy(rows[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"].toString(),4);
            rows[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"] = this.commafy(rows[i]["LocalCurrencyCd"]) + ' ' + this.commafy(rows[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"].toString(),4);
            rows[i]["Value of Discount Received (Loc)"] = parseFloat(rows[i]["Value of Discount Received (Loc)"]).toFixed(2);
            rows[i]["Value of Discount Received (Loc)"] = this.commafy(rows[i]["LocalCurrencyCd"]) + ' ' + this.commafy(rows[i]["Value of Discount Received (Loc)"].toString(),2);
            rows[i]["Shares Sold for Tax Withholding"] = parseFloat(rows[i]["Shares Sold for Tax Withholding"]).toFixed(4);
            rows[i]["Shares Sold for Tax Withholding"] = this.commafy(rows[i]["Shares Sold for Tax Withholding"].toString(),4);
            rows[i]["Value of Shares Sold for Tax Withholding (Loc)"] = parseFloat(rows[i]["Value of Shares Sold for Tax Withholding (Loc)"]).toFixed(2);
            // rows[i]["Value of Shares Sold for Tax Withholding (Loc)"] = currencyCode + ' ' + this.commafy(rows[i]["Value of Shares Sold for Tax Withholding (Loc)"].toString(),2);
            rows[i]["Value of Shares Sold for Tax Withholding (Loc)"] = this.commafy(rows[i]["LocalCurrencyCd"]) + ' ' + this.commafy(rows[i]["Value of Shares Sold for Tax Withholding (Loc)"].toString(),2);
            rows[i]["Shares Sold/Disposed"] = parseFloat(rows[i]["Shares Sold/Disposed"]).toFixed();
            rows[i]["Shares Sold/Disposed"] = this.commafy(rows[i]["Shares Sold/Disposed"].toString());
            rows[i]["Shares Available"] = parseFloat(rows[i]["Shares Available"]).toFixed();
            rows[i]["Shares Available"] = this.commafy(rows[i]["Shares Available"].toString());
            rows[i]["Fractional Shares Sold for Refund"] = parseFloat(rows[i]["Fractional Shares Sold for Refund"]).toFixed(4);
            rows[i]["Fractional Shares Sold for Refund"] = this.commafy(rows[i]["Fractional Shares Sold for Refund"].toString(),4);
            rows[i]["Value of Fractional Shares Sold for Refund (Loc)"] = parseFloat(rows[i]["Value of Fractional Shares Sold for Refund (Loc)"]).toFixed(2);
            // rows[i]["Value of Fractional Shares Sold for Refund (Loc)"] = currencyCode + ' ' + this.commafy(rows[i]["Value of Fractional Shares Sold for Refund (Loc)"].toString(),2);
            rows[i]["Value of Fractional Shares Sold for Refund (Loc)"] = this.commafy(rows[i]["LocalCurrencyCd"]) + ' ' + this.commafy(rows[i]["Value of Fractional Shares Sold for Refund (Loc)"].toString(),2);
            rows[i]["Contribution %"] = rows[i]["Contribution %"].toString() + "%";
            if(rows[i]["Actual tax reported by Payroll (in Loc)"] != null) 
            {
              rows[i]["Actual tax reported by Payroll (in Loc)"] = this.commafy(rows[i]["LocalCurrencyCd"]) + ' ' + this.commafy(rows[i]["Actual tax reported by Payroll (in Loc)"].toString(),2);
            }
            else
            {
              rows[i]["Actual tax reported by Payroll (in Loc)"] = " ";
            }

            rows[i]["PROGRAM"] = rows[i]["Program"];
            rows[i]["OFFERING PERIOD"] = rows[i]["Offering Period"];
            rows[i]["CONTRIBUTION %"] = rows[i]["Contribution %"];
            rows[i]["TOTAL CONTRIBUTIONS (LOC)"] = rows[i]["Total Contributions (Loc)"];
            rows[i]["EXCHANGE RATE"] = rows[i]["Exchange Rate"];
            rows[i]["TOTAL CONTRIBUTIONS (USD)"] = rows[i]["Total Contributions (USD)"];
            rows[i]["DISCOUNTED PURCHASE PRICE (USD)"] = rows[i]["Discounted Purchase Price (USD)"];
            rows[i]["TOTAL SHARES PURCHASED"] = rows[i]["Total Shares Purchased"];
            rows[i]["FMV AT PURCHASE (USD)"] = rows[i]["FMV at Purchase (USD)"];
            rows[i]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"] = rows[i]["FMV Value of Shares Purchased (Cost Basis) (Loc)"];
            rows[i]["VALUE OF DISCOUNT RECEIVED (LOC)"] = rows[i]["Value of Discount Received (Loc)"];
            rows[i]["SHARES SOLD FOR TAX WITHHOLDING"] = rows[i]["Shares Sold for Tax Withholding"];
            rows[i]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"] = rows[i]["Value of Shares Sold for Tax Withholding (Loc)"];
            rows[i]["FRACTIONAL SHARES SOLD FOR REFUND"] = rows[i]["Fractional Shares Sold for Refund"];
            rows[i]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"] = rows[i]["Value of Fractional Shares Sold for Refund (Loc)"];
            rows[i]["SHARES SOLD/DISPOSED"] = rows[i]["Shares Sold/Disposed"];
            rows[i]["SHARES AVAILABLE"] = rows[i]["Shares Available"];
            rows[i]["ACCOUNT"] = rows[i]["Account"];
            rows[i]["BROKER FOR MORE INFORMATION"] = rows[i]["Broker for More Information"];
            rows[i]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"] = rows[i]["Actual tax reported by Payroll (in Loc)"];
          }

            totalrows[0]["Totals"] = "Total";
            totalrows[0]["Total Contribution %"] = " ";
            totalrows[0]["Total Exchange Rate"] = " ";
            // totalrows[0]["Total Actual tax reported by Payroll (in Loc)"] = currencyCode + ' ' + this.commafy(totalrows[0]["Total Actual tax reported by Payroll (in Loc)"], 2);
            totalrows[0]["Total Actual tax reported by Payroll (in Loc)"] = this.commafy(totalrows[0]["Total Actual tax reported by Payroll (in Loc)"], 2);
            totalrows[0]["Total Contributions (Loc)"] = parseFloat(totalrows[0]["Total Contributions (Loc)"].toFixed(2));
            totalrows[0]["Total Contributions (Loc)"] = currencyCode + ' ' + this.commafy(totalrows[0]["Total Contributions (Loc)"].toString(),2);
            totalrows[0]["Total Contributions (USD)"] = parseFloat(totalrows[0]["Total Contributions (USD)"].toFixed(2));
            totalrows[0]["Total Contributions (USD)"] = this.commafy(totalrows[0]["Total Contributions (USD)"].toString(), 2);
            totalrows[0]["Total Discounted Purchase Price (USD)"] = parseFloat(totalrows[0]["Total Discounted Purchase Price (USD)"].toFixed(4));
            totalrows[0]["Total Discounted Purchase Price (USD)"] = this.commafy(totalrows[0]["Total Discounted Purchase Price (USD)"].toString(), 4);
            totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"] = parseFloat(totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"].toFixed(4));
            totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"] = currencyCode + ' ' + this.commafy(totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"].toString(), 4);
            totalrows[0]["Total FMV at Purchase (USD)"] = parseFloat(totalrows[0]["Total FMV at Purchase (USD)"].toFixed(4));
            totalrows[0]["Total FMV at Purchase (USD)"] = this.commafy(totalrows[0]["Total FMV at Purchase (USD)"].toString(), 4);
            totalrows[0]["Total Fractional Shares Sold for Refund"] = parseFloat(totalrows[0]["Total Fractional Shares Sold for Refund"].toFixed(4));
            totalrows[0]["Total Fractional Shares Sold for Refund"] = this.commafy(totalrows[0]["Total Fractional Shares Sold for Refund"].toString(), 4);
            totalrows[0]["Total Shares Available"] = parseFloat(totalrows[0]["Total Shares Available"].toFixed());
            totalrows[0]["Total Shares Available"] = this.commafy(totalrows[0]["Total Shares Available"].toString());
            totalrows[0]["Total Shares Purchased"] = parseFloat(totalrows[0]["Total Shares Purchased"].toFixed(4));
            totalrows[0]["Total Shares Purchased"] = this.commafy(totalrows[0]["Total Shares Purchased"].toString(),4);
            totalrows[0]["Total Shares Sold for Tax Withholding"] = parseFloat(totalrows[0]["Total Shares Sold for Tax Withholding"].toFixed(4));
            totalrows[0]["Total Shares Sold for Tax Withholding"] = this.commafy(totalrows[0]["Total Shares Sold for Tax Withholding"].toString(),4);
            totalrows[0]["Total Shares Sold/Disposed"] = parseFloat(totalrows[0]["Total Shares Sold/Disposed"].toFixed());
            totalrows[0]["Total Shares Sold/Disposed"] = this.commafy(totalrows[0]["Total Shares Sold/Disposed"].toString());
            totalrows[0]["Total Value of Discount Received (Loc)"] = parseFloat(totalrows[0]["Total Value of Discount Received (Loc)"].toFixed(2))
            totalrows[0]["Total Value of Discount Received (Loc)"] = this.commafy(totalrows[0]["Total Value of Discount Received (Loc)"].toString(),2);
            totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"] = parseFloat(totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"].toFixed(2));
            totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"] = currencyCode + ' ' + this.commafy(totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"].toString(),2);
            totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"] = parseFloat(totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"].toFixed(2));
            totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"] = currencyCode + ' ' + this.commafy(totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"].toString(),2);

            totalrows[0]["PROGRAM"] = totalrows[0]["Totals"];
            // totalrows[0]["TOTAL CONTRIBUTIONS (LOC)"] = totalrows[0]["Total Contributions (Loc)"];
            totalrows[0]["TOTAL CONTRIBUTIONS (LOC)"] = " ";
            totalrows[0]["TOTAL CONTRIBUTIONS (USD)"] = totalrows[0]["Total Contributions (USD)"];
            // totalrows[0]["DISCOUNTED PURCHASE PRICE (USD)"] = totalrows[0]["Total Discounted Purchase Price (USD)"];

            totalrows[0]["DISCOUNTED PURCHASE PRICE (USD)"] = " ";

            totalrows[0]["TOTAL SHARES PURCHASED"] = totalrows[0]["Total Shares Purchased"];
            // totalrows[0]["FMV AT PURCHASE (USD)"] = totalrows[0]["Total FMV at Purchase (USD)"];

            totalrows[0]["FMV AT PURCHASE (USD)"] = " ";

            // totalrows[0]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"] = totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"];
            // totalrows[0]["VALUE OF DISCOUNT RECEIVED (LOC)"] = totalrows[0]["Total Value of Discount Received (Loc)"];
            totalrows[0]["FMV VALUE OF SHARES PURCHASED (COST BASIS) (LOC)"] = " ";
            totalrows[0]["VALUE OF DISCOUNT RECEIVED (LOC)"] = " ";
            totalrows[0]["SHARES SOLD FOR TAX WITHHOLDING"] = totalrows[0]["Total Shares Sold for Tax Withholding"];
            // totalrows[0]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"] = totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"];
            totalrows[0]["VALUE OF SHARES SOLD FOR TAX WITHHOLDING (LOC)"] = " ";
            totalrows[0]["FRACTIONAL SHARES SOLD FOR REFUND"] = totalrows[0]["Total Fractional Shares Sold for Refund"];
            // totalrows[0]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"] = totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"];
            totalrows[0]["VALUE OF FRACTIONAL SHARES SOLD FOR REFUND (LOC)"] = " ";
            totalrows[0]["SHARES SOLD/DISPOSED"] = totalrows[0]["Total Shares Sold/Disposed"];
            totalrows[0]["SHARES AVAILABLE"] = totalrows[0]["Total Shares Available"];
            // totalrows[0]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"] = totalrows[0]["Total Actual tax reported by Payroll (in Loc)"];
            totalrows[0]["ACTUAL TAX REPORTED BY PAYROLL (IN LOC)"] = " ";

            // totalrows[0]["Program"] = totalrows[0]["Totals"];
            // totalrows[0]["Discounted Purchase Price (USD)"] = totalrows[0]["Total Discounted Purchase Price (USD)"];
            // totalrows[0]["FMV at Purchase (USD)"] = totalrows[0]["Total FMV at Purchase (USD)"];
            // totalrows[0]["FMV Value of Shares Purchased (Cost Basis) (Loc)"] = totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"];
            // totalrows[0]["Value of Discount Received (Loc)"] = totalrows[0]["Total Value of Discount Received (Loc)"];
            // totalrows[0]["Shares Sold for Tax Withholding"] = totalrows[0]["Total Shares Sold for Tax Withholding"];
            // totalrows[0]["Value of Shares Sold for Tax Withholding (Loc)"] = totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"];
            // totalrows[0]["Fractional Shares Sold for Refund"] = totalrows[0]["Total Fractional Shares Sold for Refund"];
            // totalrows[0]["Value of Fractional Shares Sold for Refund (Loc)"] = totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"];
            // totalrows[0]["Shares Sold/Disposed"] = totalrows[0]["Total Shares Sold/Disposed"];
            // totalrows[0]["Shares Available"] = totalrows[0]["Total Shares Available"];
            // totalrows[0]["Actual tax reported by Payroll (in Loc)"] = totalrows[0]["Total Actual tax reported by Payroll (in Loc)"];
           
            // totalrows[0]["Totals"] = "TOTAL";
            // totalrows[0]["Total Contribution %"] = " ";
            // totalrows[0]["Total Exchange Rate"] = " ";

            // // totalrows[0]["Total Actual tax reported by Payroll (in Loc)"] = totalrows[0]["Total Actual tax reported by Payroll (in Loc)"].toFixed(2);
            // totalrows[0]["Total Actual tax reported by Payroll (in Loc)"] = this.commafy(totalrows[0]["Total Actual tax reported by Payroll (in Loc)"], 2);

            // // totalrows[0]["Total Contributions (Loc)"] = totalrows[0]["Total Contributions (Loc)"].toFixed(2);
            // totalrows[0]["Total Contributions (Loc)"] = this.commafy(totalrows[0]["Total Contributions (Loc)"], 2);

            // // totalrows[0]["Total Contributions (USD)"] = totalrows[0]["Total Contributions (USD)"].toFixed(2);
            // totalrows[0]["Total Contributions (USD)"] = this.commafy(totalrows[0]["Total Contributions (USD)"], 2);

            // // totalrows[0]["Total Discounted Purchase Price (USD)"] = totalrows[0]["Total Discounted Purchase Price (USD)"].toFixed(2);
            // totalrows[0]["Total Discounted Purchase Price (USD)"] = this.commafy(totalrows[0]["Total Discounted Purchase Price (USD)"], 2);

            // // totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"] = totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"].toFixed(2);
            // totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"] = this.commafy(totalrows[0]["Total FMV Value of Shares Purchased (Cost Basis) (Loc)"], 2);

            // // totalrows[0]["Total FMV at Purchase (USD)"] = totalrows[0]["Total FMV at Purchase (USD)"].toFixed(2);
            // totalrows[0]["Total FMV at Purchase (USD)"] = this.commafy(totalrows[0]["Total FMV at Purchase (USD)"], 2);

            // // totalrows[0]["Total Fractional Shares Sold for Refund"] = totalrows[0]["Total Fractional Shares Sold for Refund"].toFixed(2);
            // totalrows[0]["Total Fractional Shares Sold for Refund"] = this.commafy(totalrows[0]["Total Fractional Shares Sold for Refund"], 2);

            // // totalrows[0]["Total Shares Available"] = totalrows[0]["Total Shares Available"].toFixed(2);
            // totalrows[0]["Total Shares Available"] = this.commafy(totalrows[0]["Total Shares Available"], 2);

            // // totalrows[0]["Total Shares Purchased"] = totalrows[0]["Total Shares Purchased"].toFixed(2);
            // totalrows[0]["Total Shares Purchased"] = this.commafy(totalrows[0]["Total Shares Purchased"], 2);

            // // totalrows[0]["Total Shares Sold for Tax Withholding"] = totalrows[0]["Total Shares Sold for Tax Withholding"].toFixed(2);
            // totalrows[0]["Total Shares Sold for Tax Withholding"] = this.commafy(totalrows[0]["Total Shares Sold for Tax Withholding"], 2);

            // // totalrows[0]["Total Shares Sold/Disposed"] = totalrows[0]["Total Shares Sold/Disposed"].toFixed(2);
            // totalrows[0]["Total Shares Sold/Disposed"] = this.commafy(totalrows[0]["Total Shares Sold/Disposed"], 2);

            // // totalrows[0]["Total Value of Discount Received (Loc)"] = totalrows[0]["Total Value of Discount Received (Loc)"].toFixed(2);
            // totalrows[0]["Total Value of Discount Received (Loc)"] = this.commafy(totalrows[0]["Total Value of Discount Received (Loc)"], 2);

            // // totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"] = totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"].toFixed(2);
            // totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"] = this.commafy(totalrows[0]["Total Value of Fractional Shares Sold for Refund (Loc)"], 2);

            // // totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"] = totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"].toFixed(2);
            // totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"] = this.commafy(totalrows[0]["Total Value of Shares Sold for Tax Withholding (Loc)"], 2);

            rows.push(totalrows[0]);
          
          this.exportExcelService.getReportInfo(this.reportType, this.programType, this.displayedColumns, rows);
        }
      });
    }
    else if(this.reportType == "Individual Transfer Limit")
    {
      
      this.reportsService.getIndividualTransferLimitData(this.user.OptNum,this.legitDateFrom.replace(/\'/g, ""), this.legitDateTo.replace(/\'/g, ""), -99).subscribe(data => {
        let rows = data[0];
        let totalrows = data[1];

        for(let i=0; i<rows.length; i++)
          {
            rows[i]["Total Unrestricted Shares at Period Start"] = this.commafy(rows[i]["Total Unrestricted Shares at Period Start"].toString());
            rows[i]["Individual Transfer Limit Shares"] = this.commafy(rows[i]["Individual Transfer Limit Shares"].toString());
            rows[i]["Additional Shares Available Due to Transfer Limit Waiver"] = this.commafy(rows[i]["Additional Shares Available Due to Transfer Limit Waiver"].toString());
            rows[i]["Total Shares Sold/Transferred"] = this.commafy(rows[i]["Total Shares Sold/Transferred"].toString());
            rows[i]["Net Shares Eligible for Sale/Transfer in Period"] = this.commafy(rows[i]["Net Shares Eligible for Sale/Transfer in Period"].toString());
          }
            totalrows[0]["Total"] = "Total";
            totalrows[0]["Net Shares Eligible for Sale/Transfer in Period"] = this.commafy(totalrows[0]["Net Shares Eligible for Sale/Transfer in Period"].toString());
            totalrows[0]["Total Additional Shares Available Due to Transfer Limit Waiver"] = this.commafy(totalrows[0]["Total Additional Shares Available Due to Transfer Limit Waiver"].toString());
            totalrows[0]["Total Individual Transfer Limit Shares"] = this.commafy(totalrows[0]["Total Individual Transfer Limit Shares"].toString());
            totalrows[0]["Total Shares Sold/Transferred"] = this.commafy(totalrows[0]["Total Shares Sold/Transferred"].toString());
            totalrows[0]["Total Unrestricted Shares at Period Start"] = this.commafy(totalrows[0]["Total Unrestricted Shares at Period Start"].toString());

            totalrows[0]["Program"] = totalrows[0]["Total"];
            totalrows[0]["Total Unrestricted Shares at Period Start"] = totalrows[0]["Total Unrestricted Shares at Period Start"];
            totalrows[0]["Individual Transfer Limit Shares"] = totalrows[0]["Total Individual Transfer Limit Shares"];
            totalrows[0]["Additional Shares Available Due to Transfer Limit Waiver"] = totalrows[0]["Total Additional Shares Available Due to Transfer Limit Waiver"];
            totalrows[0]["Total Shares Sold/Transferred"] = totalrows[0]["Total Shares Sold/Transferred"];
            totalrows[0]["Net Shares Eligible for Sale/Transfer in Period"] = totalrows[0]["Net Shares Eligible for Sale/Transfer in Period"];

            rows.push(totalrows[0]);
        
        this.exportExcelService.getReportInfo(this.reportType, this.programType, this.displayedColumns, rows);
      });
    }
    else if(this.reportType == "Sales History")
    {
      this.legitDateFrom = moment(this.legitDateFrom).format("YYYY-MM-DD HH:mm:ss.SSS");
      this.legitDateTo = moment(this.legitDateTo).format("YYYY-MM-DD HH:mm:ss.SSS");
      let grantList = sessionStorage.getItem("salesHistGrants");
      let programList = sessionStorage.getItem("salesHistProgram");

      programList != null ? "" : programList = sessionStorage.getItem("salesHistoryProgramsOnLoad");
      grantList != null ? "" : grantList = sessionStorage.getItem("grantListOnLoad");

      this.reportsService.getSalesHistoryData(this.user.OptNum, this.legitDateFrom, this.legitDateTo, this.salesHistoryGrants, this.salesHistoryPrograms, -99).subscribe(data => {
        let rows = data[0];
        let totalrows = data[1];
        let localCur = "USD";
        let localCurrency = data[0][0]["LocalCurrencyCd"];

      for(let i=0; i<rows.length; i++)
      {
        // rows[i]["Transaction Date"] = moment(rows[i]["Transaction Date"]).format("DD MMM YYYY");

        if(rows[i]["Original Purchase Date"] == null)
        {
          rows[i]["Original Purchase Date"] = "-";
        }

        if(rows[i]["Award Details"] == null) {
          if(rows[i]["Program"] == "VEIP" || rows[i]["Program"] == "ESPP") {
            rows[i]["Award Details"] = "-";
          }
        }
        else {
          if(rows[i]["Program"] == "VEIP" || rows[i]["Program"] == "ESPP") {
            rows[i]["Award Details"] = "-";
          }
        }
               
        rows[i]["Trading Volume"] = parseFloat(rows[i]["Trading Volume"]).toFixed(2);
        rows[i]["Trading Volume"] = this.commafy(rows[i]["Trading Volume"]);

        if (rows[i]["Sale Price (USD)"] != null)
        {
          rows[i]["Sale Price (USD)"] = parseFloat(rows[i]["Sale Price (USD)"]).toFixed(4);
          rows[i]["Sale Price (USD)"] = this.commafy(rows[i]["Sale Price (USD)"].toString(),4);

          if(rows[i]["Program"] == "Founder"){
            if(rows[i]["Transaction Type"] == "X Shares Redemption" || rows[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              rows[i]["Sale Price (USD)"] = "-";
            }
          }
        }
        else
        {
          if(rows[i]["Program"] == "Founder"){
            if(rows[i]["Transaction Type"] == "X Shares Redemption" || rows[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              rows[i]["Sale Price (USD)"] = "-";
            }
          }
        }

        // rows[i]["Number of Shares in Transaction"] = parseFloat(rows[i]["Number of Shares in Transaction"]).toFixed(2);
        rows[i]["Number of Shares in Transaction"] = this.commafy(rows[i]["Number of Shares in Transaction"].toString());

        // rows[i]["Gross Proceeds (USD)"] != null ? rows[i]["Gross Proceeds (USD)"] = this.commafy(rows[i]["Gross Proceeds (USD)"].toString(),2) : "";
        // // rows[i]["Gross Proceeds (USD)"] = this.commafy(rows[i]["Gross Proceeds (USD)"].toString(),2);
        // rows[i]["Gross Proceeds (USD)"] = parseFloat(rows[i]["Gross Proceeds (USD)"]).toFixed(2);

        if(rows[i]["Gross Proceeds (USD)"] != null)
        {
          rows[i]["Gross Proceeds (USD)"] = parseFloat(rows[i]["Gross Proceeds (USD)"]).toFixed(2);
          rows[i]["Gross Proceeds (USD)"] = this.commafy(rows[i]["Gross Proceeds (USD)"].toString(),2);
          if(rows[i]["Program"] == "Founder"){
            if(rows[i]["Transaction Type"] == "X Shares Redemption" || rows[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              rows[i]["Gross Proceeds (USD)"] = "-";
            }
          }
        }
        else
        {
          rows[i]["Gross Proceeds (USD)"] = " ";
          if(rows[i]["Program"] == "Founder"){
            if(rows[i]["Transaction Type"] == "X Shares Redemption" || rows[i]["Transaction Type"].toLowerCase().indexOf("transfer") > -1 ){ //== "Transfer - UBS"){
              rows[i]["Gross Proceeds (USD)"] = "-";
            }
          }
        }
        // rows[i]["Gain/(Loss) - USD"] != null ? rows[i]["Gain/(Loss) - USD"] = this.commafy(rows[i]["Gain/(Loss) - USD"].toString(),2) : "";
        // // rows[i]["Gain/(Loss) - USD"] = this.commafy(rows[i]["Gain/(Loss) - USD"].toString(),2);
        // rows[i]["Gain/(Loss) - USD"] = parseFloat(rows[i]["Gain/(Loss) - USD"]).toFixed(2);

        if(rows[i]["Gain/(Loss) - USD"] != null)
        {        
          // rows[i]["Gain/(Loss) - USD"] = this.commafy(rows[i]["Gain/(Loss) - USD"].toString(),2);
          // rows[i]["Gain/(Loss) - USD"] = parseFloat(rows[i]["Gain/(Loss) - USD"]).toFixed(2);
          rows[i]["Gain/(Loss) - USD"] = localCur + " " + this.commafy(parseFloat(rows[i]["Gain/(Loss) - USD"]).toFixed(2).toString(),2);

          if(rows[i]["Program"] == "ESPP") rows[i]["Gain/(Loss) - USD"] = " - ";
          else if(rows[i]["Program"] == "RSU" || rows[i]["Program"] == "IPORSU") rows[i]["Gain/(Loss) - USD"] = "Refer to Broker";
          // else if(rows[i]["Program"] == "Founder") rows[i]["Gain/(Loss) - USD"] = localCur + " " + rows[i]["Gain/(Loss) - USD"];
          else if(rows[i]["Program"] == "Founder"){
            if(rows[i]["Transaction Type"] == "X Shares Redemption"){
              rows[i]["Gain/(Loss) - USD"] = "-";
            }
          }
        }
        else
        {
          if(rows[i]["Program"] == "Founder"){
            if(rows[i]["Transaction Type"] == "X Shares Redemption"){
              rows[i]["Gain/(Loss) - USD"] = "-";
            }
            else{
              rows[i]["Gain/(Loss) - USD"] = localCur + " " + "0.00";
            }
          }
          else  rows[i]["Gain/(Loss) - USD"] = "-";
        }        
        // rows[i]["Gain/(Loss) - Local Currency"] != null ? rows[i]["Gain/(Loss) - Local Currency"] = this.commafy(rows[i]["Gain/(Loss) - Local Currency"].toString(),2) : "";
        // // rows[i]["Gain/(Loss) - Local Currency"] = this.commafy(rows[i]["Gain/(Loss) - Local Currency"].toString(),2);
        // rows[i]["Gain/(Loss) - Local Currency"] = parseFloat(rows[i]["Gain/(Loss) - Local Currency"]).toFixed(2);

        if(rows[i]["Gain/(Loss) - Local Currency"] != null)
        {
         
          // rows[i]["Gain/(Loss) - Local Currency"] = this.commafy(rows[i]["Gain/(Loss) - Local Currency"].toString(),2);
          // rows[i]["Gain/(Loss) - Local Currency"] = parseFloat(rows[i]["Gain/(Loss) - Local Currency"]).toFixed(2);
          rows[i]["Gain/(Loss) - Local Currency"] = localCurrency + " " + this.commafy(parseFloat(rows[i]["Gain/(Loss) - Local Currency"]).toFixed(2).toString(),2);

          if(rows[i]["Program"] == "ESPP") rows[i]["Gain/(Loss) - Local Currency"] = " - ";
          else if(rows[i]["Program"] == "RSU" || rows[i]["Program"] == "IPORSU") rows[i]["Gain/(Loss) - Local Currency"] = "Refer to Broker";
          // else if(rows[i]["Program"] == "Founder") rows[i]["Gain/(Loss) - Local Currency"] = rows[i]["Gain/(Loss) - Local Currency"];
          else if(rows[i]["Program"] == "Founder"){
            if(rows[i]["Transaction Type"] == "X Shares Redemption"){
              rows[i]["Gain/(Loss) - Local Currency"] = "-";
            }
          }
        }
        else
        {
          // if(rows[i]["Program"] == "Founder") rows[i]["Gain/(Loss) - Local Currency"] = "0.00";
          if(rows[i]["Program"] == "Founder"){
            if(rows[i]["Transaction Type"] == "X Shares Redemption"){
              rows[i]["Gain/(Loss) - Local Currency"] = "-";
            }
            else{
              rows[i]["Gain/(Loss) - Local Currency"] = "0.00";
            }
          }
          else rows[i]["Gain/(Loss) - Local Currency"] = "-";
        }

        rows[i]["Purchase Date FMV per Share(USD)"] != null ? rows[i]["Purchase Date FMV per Share(USD)"] = this.commafy(rows[i]["Purchase Date FMV per Share(USD)"].toString(),4) : rows[i]["Purchase Date FMV per Share(USD)"] = "-";
        // rows[i]["Purchase Date FMV per Share(USD)"] = this.commafy(rows[i]["Purchase Date FMV per Share(USD)"].toString(),2);
        // rows[i]["Purchase Date FMV per Share(USD)"] = parseFloat(rows[i]["Purchase Date FMV per Share(USD)"]).toFixed(2);
        
        rows[i]["Purchase Date Value (USD)"] != null ? rows[i]["Purchase Date Value (USD)"] = this.commafy(rows[i]["Purchase Date Value (USD)"].toString(),2) : rows[i]["Purchase Date Value (USD)"] = "-";
        // rows[i]["Purchase Date Value (USD)"] = this.commafy(rows[i]["Purchase Date Value (USD)"].toString(),2);
        // rows[i]["Purchase Date Value (USD)"] = parseFloat(rows[i]["Purchase Date Value (USD)"]).toFixed(2);

        // rows[i]["Value Per Share at Exercise (USD)"] != null ? rows[i]["Value Per Share at Exercise (USD)"] = this.commafy(rows[i]["Value Per Share at Exercise (USD)"].toString(),2) : rows[i]["Value Per Share at Exercise (USD)"] = "-";
        // // rows[i]["Value Per Share at Exercise (USD)"] = this.commafy(rows[i]["Value Per Share at Exercise (USD)"].toString(),2);
        // rows[i]["Value Per Share at Exercise (USD)"] = parseFloat(rows[i]["Value Per Share at Exercise (USD)"]).toFixed(2);

        if(rows[i]["Value Per Share at Exercise (USD)"] != null)
        {
          // rows[i]["Value Per Share at Exercise (USD)"] = this.commafy(rows[i]["Value Per Share at Exercise (USD)"].toString(),2);
          rows[i]["Value Per Share at Exercise (USD)"] = parseFloat(this.commafy(rows[i]["Value Per Share at Exercise (USD)"].toString(),2)).toFixed(2);
          if(rows[i]["Program"] == "RSU" || rows[i]["Program"] == "IPORSU" || rows[i]["Program"] == "Founder") 
            rows[i]["Value Per Share at Exercise (USD)"] = "-";
        }
        else
        {
          rows[i]["Value Per Share at Exercise (USD)"] = "-";
        }
        // rows[i]["Total Value on Exercise Date (USD)"] != null ? rows[i]["Total Value on Exercise Date (USD)"] = this.commafy(rows[i]["Total Value on Exercise Date (USD)"].toString(),2) : rows[i]["Total Value on Exercise Date (USD)"] = "-";
        // // rows[i]["Total Value on Exercise Date (USD)"] = this.commafy(rows[i]["Total Value on Exercise Date (USD)"].toString(),2);
        // rows[i]["Total Value on Exercise Date (USD)"] = parseFloat(rows[i]["Total Value on Exercise Date (USD)"]).toFixed(2);

        if(rows[i]["Total Value on Exercise Date (USD)"] != null)
        {
          // rows[i]["Total Value on Exercise Date (USD)"] = this.commafy(rows[i]["Total Value on Exercise Date (USD)"].toString(),2);
          rows[i]["Total Value on Exercise Date (USD)"] = parseFloat(this.commafy(rows[i]["Total Value on Exercise Date (USD)"].toString(),2)).toFixed(2);

          if(rows[i]["Program"] == "RSU" || rows[i]["Program"] == "IPORSU" || rows[i]["Program"] == "Founder") 
            rows[i]["Total Value on Exercise Date (USD)"] = "-";
        }
        else
        {
          rows[i]["Total Value on Exercise Date (USD)"] = "-";
        }       
        // rows[i]["Total Cost on Exercise Date (USD)"] != null ? rows[i]["Total Cost on Exercise Date (USD)"] = this.commafy(rows[i]["Total Cost on Exercise Date (USD)"].toString(),2) : rows[i]["Total Cost on Exercise Date (USD)"] = "-";
        // // rows[i]["Total Cost on Exercise Date (USD)"] = this.commafy(rows[i]["Total Cost on Exercise Date (USD)"].toString(),2);
        // rows[i]["Total Cost on Exercise Date (USD)"] = parseFloat(rows[i]["Total Cost on Exercise Date (USD)"]).toFixed(2);

        if(rows[i]["Total Cost on Exercise Date (USD)"] != null)
        {
          // rows[i]["Total Cost on Exercise Date (USD)"] = this.commafy(rows[i]["Total Cost on Exercise Date (USD)"].toString(),2);
          rows[i]["Total Cost on Exercise Date (USD)"] = parseFloat(this.commafy(rows[i]["Total Cost on Exercise Date (USD)"].toString(),2)).toFixed(2);

          if(rows[i]["Program"] == "RSU" || rows[i]["Program"] == "IPORSU" || rows[i]["Program"] == "Founder") 
            rows[i]["Total Cost on Exercise Date (USD)"] = "-";
        }
        else
        {
          rows[i]["Total Cost on Exercise Date (USD)"] = "-";
        }
        // rows[i]["Taxable Income(USD)"] != null ? rows[i]["Taxable Income(USD)"] = this.commafy(rows[i]["Taxable Income(USD)"].toString(),2) : rows[i]["Taxable Income(USD)"] = "-";
        // // rows[i]["Taxable Income(USD)"] = this.commafy(rows[i]["Taxable Income(USD)"].toString(),2);
        // rows[i]["Taxable Income(USD)"] = parseFloat(rows[i]["Taxable Income(USD)"]).toFixed(2);

        if(rows[i]["Taxable Income(USD)"] != null)
        {
          // rows[i]["Taxable Income(USD)"] = this.commafy(rows[i]["Taxable Income(USD)"].toString(),2);
          rows[i]["Taxable Income(USD)"] = parseFloat(this.commafy(rows[i]["Taxable Income(USD)"].toString(),2)).toFixed(2);

          if(rows[i]["Program"] == "RSU" || rows[i]["Program"] == "IPORSU" || rows[i]["Program"] == "Founder") 
            rows[i]["Taxable Income(USD)"] = "-";
        }
        else
        {
          rows[i]["Taxable Income(USD)"] = "-";
        }
      
        // rows[i]["Estimated Taxes Withheld at Exercise (USD)"] != null ? rows[i]["Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(rows[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2) :rows[i]["Estimated Taxes Withheld at Exercise (USD)"] = "-";
        if(rows[i]["Estimated Taxes Withheld at Exercise (USD)"] != null)
        {
          rows[i]["Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(this.commafy(rows[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2)).toFixed(2);

          if(rows[i]["Program"] == "RSU" || rows[i]["Program"] == "IPORSU" || rows[i]["Program"] == "Founder") 
            rows[i]["Estimated Taxes Withheld at Exercise (USD)"] = "-";
        }
        else
        {
          rows[i]["Estimated Taxes Withheld at Exercise (USD)"] = " - ";
        }        
        // rows[i]["Estimated Proceeds (USD)"] != null ? rows[i]["Estimated Proceeds (USD)"] = this.commafy(rows[i]["Estimated Proceeds (USD)"].toString(),2): rows[i]["Estimated Proceeds (USD)"] = "-";
        // // rows[i]["Estimated Proceeds (USD)"] = this.commafy(rows[i]["Estimated Proceeds (USD)"].toString(),2);
        // rows[i]["Estimated Proceeds (USD)"] = parseFloat(rows[i]["Estimated Proceeds (USD)"]).toFixed(2);

        if(rows[i]["Estimated Proceeds (USD)"] != null)
        {
          // rows[i]["Estimated Proceeds (USD)"] = this.commafy(rows[i]["Estimated Proceeds (USD)"].toString(),2);
          rows[i]["Estimated Proceeds (USD)"] = parseFloat(this.commafy(rows[i]["Estimated Proceeds (USD)"].toString(),2)).toFixed(2);

          if(rows[i]["Program"] == "RSU" || rows[i]["Program"] == "IPORSU" || rows[i]["Program"] == "Founder") 
            rows[i]["Estimated Proceeds (USD)"] = "-";
        }
        else
        {
          rows[i]["Estimated Proceeds (USD)"] = "-";
        }
        // rows[i]["Estimated Taxes Withheld at Exercise (USD)"] == 0 ? rows[i]["Estimated Taxes Withheld at Exercise (USD)"] = "-" : " ";
        // // rows[i]["Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(rows[i]["Estimated Taxes Withheld at Exercise (USD)"].toString(),2);
        // rows[i]["Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(rows[i]["Estimated Taxes Withheld at Exercise (USD)"]).toFixed(2);
        
        rows[i]["Original Purchase Date"] == null ? rows[i]["Original Purchase Date"] = "-" : " ";
        // rows[i]["Original Purchase Date"] = this.commafy(rows[i]["Original Purchase Date"].toString(),2);
        // rows[i]["Original Purchase Date"] = parseFloat(rows[i]["Original Purchase Date"]).toFixed(2);

        if(rows[i]["Broker for More Information"] != null)
        {
          if(rows[i]["Program"] == "IPORSU")
          {
            rows[i]["Broker for More Information"] = " ";
          }
        }
      }

      totalrows[0]["Total"] = "Totals";

      if(totalrows[0]["Total Cost on Exercise Date (USD)"] != null)
      {
        totalrows[0]["Total Cost on Exercise Date (USD)"] = parseFloat(totalrows[0]["Total Cost on Exercise Date (USD)"]).toFixed(2);
        totalrows[0]["Total Cost on Exercise Date (USD)"] = this.commafy(totalrows[0]["Total Cost on Exercise Date (USD)"].toString(),2);
      }
      else
      {
        totalrows[0]["Total Cost on Exercise Date (USD)"] = " ";
      }

      totalrows[0]["Total CostExerciseUsd"] = parseFloat(totalrows[0]["Total CostExerciseUsd"]).toFixed(2);
      totalrows[0]["Total CostExerciseUsd"] = this.commafy(totalrows[0]["Total CostExerciseUsd"].toString(),2);

      if(totalrows[0]["Total Estimated Proceeds (USD)"] != null)
      {
        totalrows[0]["Total Estimated Proceeds (USD)"] = parseFloat(totalrows[0]["Total Estimated Proceeds (USD)"]).toFixed(2);
        totalrows[0]["Total Estimated Proceeds (USD)"] = this.commafy(totalrows[0]["Total Estimated Proceeds (USD)"].toString(),2);
      }
      else
      {
        totalrows[0]["Total Estimated Proceeds (USD)"] = " ";
      }

      if(totalrows[0]["Total Estimated Taxes Withheld at Exercise (USD)"] != null)
      {
        totalrows[0]["Total Estimated Taxes Withheld at Exercise (USD)"] = parseFloat(totalrows[0]["Total Estimated Taxes Withheld at Exercise (USD)"]).toFixed(2);
        totalrows[0]["Total Estimated Taxes Withheld at Exercise (USD)"] = this.commafy(totalrows[0]["Total Estimated Taxes Withheld at Exercise (USD)"].toString(),2);
      }
      else
      {
        totalrows[0]["Total Estimated Taxes Withheld at Exercise (USD)"] = " ";
      }

      if(totalrows[0]["Total Gain/(Loss) - Local Currency"] != null)
      {
        // totalrows[0]["Total Gain/(Loss) - Local Currency"] = parseFloat(totalrows[0]["Total Gain/(Loss) - Local Currency"]).toFixed(2);
        // totalrows[0]["Total Gain/(Loss) - Local Currency"] = this.commafy(totalrows[0]["Total Gain/(Loss) - Local Currency"].toString(),2);
        totalrows[0]["Total Gain/(Loss) - Local Currency"] = " ";
      }
      else
      {
        totalrows[0]["Total Gain/(Loss) - Local Currency"] = " ";
      }

      if(totalrows[0]["Total Gain/(Loss) - USD"] != null)
      {
        // totalrows[0]["Total Gain/(Loss) - USD"] = parseFloat(totalrows[0]["Total Gain/(Loss) - USD"]).toFixed(2);
        // totalrows[0]["Total Gain/(Loss) - USD"] = this.commafy(totalrows[0]["Total Gain/(Loss) - USD"].toString(),2);
        totalrows[0]["Total Gain/(Loss) - USD"] = " ";
      }
      else
      {
        totalrows[0]["Total Gain/(Loss) - USD"] = " ";
      }

      totalrows[0]["Total Gross Proceeds (USD)"] = parseFloat(totalrows[0]["Total Gross Proceeds (USD)"]).toFixed(2);
      totalrows[0]["Total Gross Proceeds (USD)"] = this.commafy(totalrows[0]["Total Gross Proceeds (USD)"].toString(),2);

      // totalrows[0]["Total Number of Shares in Transaction"] = parseFloat(totalrows[0]["Total Number of Shares in Transaction"]).toFixed(2);
      totalrows[0]["Total Number of Shares in Transaction"] = this.commafy(totalrows[0]["Total Number of Shares in Transaction"].toString());

      totalrows[0]["Total Sale Price (USD)"] = parseFloat(totalrows[0]["Total Sale Price (USD)"]).toFixed(2);
      totalrows[0]["Total Sale Price (USD)"] = this.commafy(totalrows[0]["Total Sale Price (USD)"].toString(),2);
      
      if(totalrows[0]["Total Taxable Income(USD)"] != null)
      {
        totalrows[0]["Total Taxable Income(USD)"] = parseFloat(totalrows[0]["Total Taxable Income(USD)"]).toFixed(2);
        totalrows[0]["Total Taxable Income(USD)"] = this.commafy(totalrows[0]["Total Taxable Income(USD)"].toString(),2);
      }
      else
      {
        totalrows[0]["Total Taxable Income(USD)"] = " ";
      }

      if(totalrows[0]["Total ValuePerShareExerciseUsd"] != null)
      {
        totalrows[0]["Total ValuePerShareExerciseUsd"] = parseFloat(totalrows[0]["Total ValuePerShareExerciseUsd"]).toFixed(2);
        totalrows[0]["Total ValuePerShareExerciseUsd"] = this.commafy(totalrows[0]["Total ValuePerShareExerciseUsd"].toString(),2);
      }
      else
      {
        totalrows[0]["Total ValuePerShareExerciseUsd"] = " ";
      }

      totalrows[0]["Program"] = totalrows[0]["Total"];
      totalrows[0]["Number of Shares in Transaction"] = totalrows[0]["Total Number of Shares in Transaction"];
      totalrows[0]["Sale Price (USD)"] = "";
      totalrows[0]["Gross Proceeds (USD)"] = totalrows[0]["Total Gross Proceeds (USD)"];
      totalrows[0]["Gain/(Loss) - USD"] = totalrows[0]["Total Gain/(Loss) - USD"];
      totalrows[0]["Gain/(Loss) - Local Currency"] = totalrows[0]["Total Gain/(Loss) - Local Currency"];
      // totalrows[0]["Value Per Share at Exercise (USD)"] = totalrows[0]["Total ValuePerShareExerciseUsd"];
      // totalrows[0]["Total Value on Exercise Date (USD)"] = totalrows[0][""];
      // totalrows[0]["Total Cost on Exercise Date (USD)"] = totalrows[0]["Total Cost on Exercise Date (USD)"];
      // totalrows[0]["Taxable Income(USD)"] = totalrows[0]["Total Taxable Income(USD)"];
      // totalrows[0]["Estimated Taxes Withheld at Exercise (USD)"] = totalrows[0]["Total Estimated Taxes Withheld at Exercise (USD)"];
      // totalrows[0]["Estimated Proceeds (USD)"] = totalrows[0]["Total Estimated Proceeds (USD)"];
      // totalrows[0]["Broker for More Information"] = " ";
      totalrows[0]["Value Per Share at Exercise (USD)"] = " ";
      totalrows[0]["Total Value on Exercise Date (USD)"] = " ";
      totalrows[0]["Total Cost on Exercise Date (USD)"] = " ";
      totalrows[0]["Taxable Income(USD)"] = " ";
      totalrows[0]["Estimated Taxes Withheld at Exercise (USD)"] = " ";
      totalrows[0]["Estimated Proceeds (USD)"] = " ";
      totalrows[0]["Broker for More Information"] = " ";

        rows.push(totalrows[0]);

        this.exportExcelService.getReportInfo(this.reportType, this.programType, this.displayedColumns, rows);
      });
    }
 } 
 
//  exportFile()
//  {
//    try{
//     this.csvData = this.objectToCSV(this.displayedColumns,this.dataSource);
//     this.downloadCSV(this.csvData);
//    } catch(error) {
//     let errorLocation = this.errorLocation.replace("methodName", "exportFile");
//     error.message = error.message + errorLocation;
//     throw error;
//   }
//  }

//  objectToCSV(headers,rows)
//  {
//    try{
//     const csvRows = [];
//     csvRows.push(headers.join(','));
//     for(const row of rows)
//     {
//       const values = headers.map(header =>
//       {
//         const escaped = (''+row[header]).replace(/"/g,'\\"');
//         return `"${escaped}"`;
//       })
//       csvRows.push(values.join(','));
//     }
//     return (csvRows.join('\n'));
//   } catch(error) {
//     let errorLocation = this.errorLocation.replace("methodName", "objectToCSV");
//     error.message = error.message + errorLocation;
//     throw error;
//   }
//  }

//  downloadCSV(csvData)
//  {
//    try{
//   const blob = new Blob([csvData], {type: 'text/csv' });
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.setAttribute('hidden', '');
//   a.setAttribute('href',url);
//   a.setAttribute('download','download.csv');
//   document.body.appendChild(a);
//   a.click();
//   document.body.removeChild(a);
//    } catch(error) {
//     let errorLocation = this.errorLocation.replace("methodName", "downloadCSV");
//     error.message = error.message + errorLocation;
//     throw error;
//   }
//  }

 
/**********************************************************************************
  * Method Name: selectFileExtension                                                                 
  * Description: Changes the selected when file dropdown for export is clicked.                              
  *                                                                                          
  * Date                 Modified by                      Description                             
  * 05/14/19             Joshua Paul J. Avecilla          Initial draft       
 *********************************************************************************/
 selectFileExtension(checker){
   this.isExportBtnDisable = false;
    if(checker == 0){
      this.selectedFileExtension = "Excel";
    }
    else{
      this.selectedFileExtension = "PDF";
    }
 }

/**********************************************************************************
  * Method Name: exportReport                                                                 
  * Description: Calls method when Export button is clicked.                            
  *                                                                                          
  * Date                 Modified by                      Description                             
  * 05/14/19             Joshua Paul J. Avecilla          Initial draft       
 *********************************************************************************/
 exportReport(){
   if(this.selectedFileExtension == "Excel"){
    this.exportFile();
    this.selectedFileExtension = "Select an Export Format";
    this.isExportBtnDisable = true;
   }
   else if(this.selectedFileExtension == "PDF"){
    this.generateToPDF();
    this.selectedFileExtension = "Select an Export Format";
    this.isExportBtnDisable = true;
   }
 }

  /**********************************************************************************
   * Method Name: checkErrorMessage
   * Description: Checks the errors encountered in the homepage component
   *
   * Date                 Modified by                 Description
   * 01/31/19             Dalemark P. Suquib          Initial draft
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];

        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Reportspage") != -1 && this.user != null) {
                webPage = "Reportspage";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }

          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

  putTotalsText(){
    $(".mat-column-Totals")[0].innerText = "Total";
  }

 async scrollS(){

  $("#tableReports2").width($("#tableReports").width());
  $("#tableReports").on("scroll", function(){
      $("#tableReports2").scrollLeft($(this).scrollLeft());
  });
  $("#tableReports2").on("scroll", function(){
      $("#tableReports").scrollLeft($(this).scrollLeft());
  });
  $("#tableReports").css('overflow-x','hidden') ;
}
async returnScroll(){
  $("#tableReports").css('overflow-x','auto') ;


}
async purchaseprogramScroll(){
  $("#tableReports").css('overflow-x','scroll');
}

itlDisabler(){
  
  $(".mat-paginator-navigation-previous, .mat-icon-button").css('pointer-events','none');
  $(".mat-paginator-navigation-next, .mat-icon-button").css('pointer-events','none');
  $("#shGrants").css('pointer-events','none');
  $("#rsuGrants").css('pointer-events','none');
  if(this.reportType == "Individual Transfer Limit"){
  $("#btnRprts").attr("disabled",'true');
  $("#ddBtnAfy").attr("disabled",'true');
  $("#ddBtnFY").attr("disabled",'true');
  $("#ddBtnLFY").attr("disabled",'true');
  $("#ddBtnL3FY").attr("disabled",'true');
  
}else{
  $("#btnRprts").removeAttr("disabled");
  $("#ddBtnAfy").removeAttr("disabled");
  $("#ddBtnFY").removeAttr("disabled");
  $("#ddBtnLFY").removeAttr("disabled");
  $("#ddBtnL3FY").removeAttr("disabled");
}
}

ddEnabler(){
  $("#btnRprts").removeAttr("disabled");
  $("#ddBtnAfy").removeAttr("disabled");
  $("#ddBtnFY").removeAttr("disabled");
  $("#ddBtnLFY").removeAttr("disabled");
  $("#ddBtnL3FY").removeAttr("disabled");
}

paginatorEnabler(){
  $(".mat-paginator-navigation-previous, .mat-icon-button").css('pointer-events','auto');
  $(".mat-paginator-navigation-next, .mat-icon-button").css('pointer-events','auto');
}

totalsRowDesign()
{
  $(".ppClass, .mat-cell").css({"font-family": "Graphik",
                                "font-size": "0.75rem", 
                                "font-weight": "600",
                                "font-style": "normal",
                                "font-stretch": "normal",
                                "line-height": "normal",
                                "letter-spacing": "normal",
                                "color": "#000000",
                                "margin-right": "10px"
                              });
  $(".ppClass, .mat-row").css({"border-top": "3px solid #460173%", 
                               "border-bottom": "1px solid #460173", 
                               "background-color": "#faf9fa"
                              });
}

totalsRowDesign2()
{
  $(".ppClass, .row").css({"font-family": "Graphik",
                                "font-size": "0.75rem", 
                                "font-weight": "600",
                                "font-style": "normal",
                                "font-stretch": "normal",
                                "line-height": "normal",
                                "letter-spacing": "normal",
                                "color": "#000000",
                                "margin-right": "10px",
                                "border-top": "3px solid #460173%", 
                                "border-bottom": "1px solid #460173", 
                                "background-color": "#faf9fa"
                              });
}

totalsRowDesign3()
{
  // $("mat-header-row").css({"width": "100%"});
  // $("mat-row").css({"width": "100% max-content", 
  //                   "min-width": "1136px"
  //                  });
  $(".mat-row:last-child > .mat-cell").css({"font-family": "Graphik",
                                             "font-size": "0.75rem", 
                                             "font-weight": "600",
                                             "font-style": "normal",
                                             "font-stretch": "normal",
                                             "line-height": "normal",
                                             "letter-spacing": "normal",
                                             "color": "#000000",
                                            });
  $(".mat-row:last-child").css({"border-top": "3px solid #460173", 
                                "border-bottom": "1px solid #460173", 
                                "background-color": "#faf9fa",
                                "width": "100%", 
                                "min-width": "1136px", 
                                "text-align": "center"
                               });
}

// ngAfterViewChecked() 
// {
//   const list = document.getElementsByClassName('mat-paginator-range-label');
//   // list[0].innerHTML = "of" + " " + this.totalPages.toString();
// }

// inputPageIndex(pageIndex:any)
// {
//   this.pageIndex = pageIndex;
//   this.getSalesHistoryData(this.pageIndex);
// }

matDP(){
  $(".mat-input-infix span").remove();
  $(".mat-input-underline span").remove();
  $(".mat-input-subscript-wrapper").remove();
  $(".mat-input-underline").css("height","0");
  $(".mat-input-element").addClass("datepicker-input");
  $(".mat-input-infix input").css("height","40px");
  $(".mat-input-infix input").css("width","173px");
  $(".mat-input-infix input").css("background-color","#d3d0d0");
  $(".mat-input-infix input").css("margin-bottom","2.8px");
  $(".mat-form-field-infix").css("border-top","0");
  $(".mat-datepicker-toggle button").removeClass("mat-icon-button").addClass("btn btn-outline-secondary calendar");
  $(".mat-datepicker-toggle button span").remove();
  $(".mat-datepicker-toggle button div").remove();
  $(".mat-datepicker-toggle button").removeAttr("mat-icon-button");
  $(".mat-form-field-infix").css("width","173px");
  $(".mat-datepicker-toggle button").css("width","50px");
  $(".mat-datepicker-toggle button").css("height","40px");
  $(".mat-datepicker-toggle button").css("margin-left","-4px");
  $(".mat-datepicker-toggle button").css("margin-bottom","2.5px");
  $(".toMatDP > .mat-datepicker-toggle button").css("margin-bottom","2.5px !important");
  $(".mat-datepicker-toggle button").css("background-image","url(/assets/calendar.png)");
  $(".mat-datepicker-toggle button").css("background-repeat","no-repeat");
  $(".mat-datepicker-toggle button").css("background-size","23px");
  $(".mat-datepicker-toggle button").css("background-position","center");
  $(".mat-input-infix input").css("border","1px solid #d3d0d0");
  $(".mat-input-infix input").css("padding-left","16px");

  $(".mat-form-field-wrapper").css("padding-bottom", "10px");
}
}

/* This block of code is for the dynamic response of reports */
// const sharePriceHistory: any[] = this.sharePriceHistDataSource;

// export class GetSharePriceHistory extends DataSource<any>
// {
//   connect(): Observable<Element[]>
//   {
//     return Observable.of(sharePriceHistory);
//   }
//   disconnect() {}
// }