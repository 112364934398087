
import {map} from 'rxjs/operators';

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
// import { MatInput } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';
import * as moment from 'moment'
import { BrokerType, PageSection, FileType, MyHoldingsPagesResources, tScript, CurrencySymbol} from '../services/enumerations/enumerations';
import { HoldingProgramDetailsVEIPService } from './HoldingProgramDetailsVEIPService';
import { StaticContentService } from '../services/StaticContentService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { BrokersPageService } from '../brokerspage/BrokersPageService';
import {TooltipPosition} from '@angular/material/tooltip';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DomSanitizer } from '@angular/platform-browser';
// import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-holdingprogramdetailspage-veip',
  templateUrl: './holdingprogramdetailspage-veip.component.html',
  styleUrls: ['./holdingprogramdetailspage-veip.component.css']
})
export class HoldingprogramdetailspageVeipComponent implements OnInit {

  // @ViewChild('dateFromInput', {
  //   read: MatInput
  // }) dateFromMatInput: MatInput; 

  // @ViewChild('dateToInput', {
  //   read: MatInput
  // })  dateToMatInput: MatInput;
  events: string[] = [];

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }
  displayedColumnsVeip: string[] = ['Date', 'PurchasePriceUSD', 'PurchasedShares' , 'ValueUSD', 'ValueLoc','Disposed', 'RemainingBroker'];
  veipdataSource: any;

  displayedColumnsVeipSalesHist: string[] = ['Date', 'Type', 'Shares', 'PriceUSD', 'ProceedsUSD', 'PurchaseValueUSD'];
  dataSourceVeipSalesHist: any;

  loading: boolean = true;
  datePipe = new DatePipe('en-US');
  dateFrom: string = "' '";
  dateTo: string = "' '";
  dateFromClicked: boolean = false;
  dateToClicked: boolean = false;
  dateFromHolder: any;
  dateToHolder: any;
  dateFromPlaceholder: string = " "; // Ngb Datepicker
  dateToPlaceholder: string = " "; // Ngb Datepicker
  // dateFromInput: NgbDateStruct; // Ngb Datepicker
  // dateToInput: NgbDateStruct; // Ngb Datepicker

  dateFromInput: Date; // Angular Material Datepicker
  dateToInput: Date; // Angular Material Datepicker

  dateFromChecker: any;
  dateToChecker: any;
  dateErrorChecker: boolean = false;

  brokerType: any;
  noSalesHistory: boolean = false;

  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsNextRelease: any;
  detailsSharesReleasing: any;
  detailsGrantAccepted: any;
  detailsUnreleased: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;
  contribution: number;

  datePeriod: number = 4;
  disableDatePicker: boolean = true;
  esppSalesHistShares: any = 0;
  esppSalesHistPriceUSD: any = 0;
  esppSalesHistProceedsUSD: any = 0;
  esppSalesHistPurchaseValueUSD: any = 0;

  // VEIP variables - Start
  programyear: string;
  //contribution: number; //already in ESPP
  totalcontributionloc: number;
  totalpurchasevalueloc: number;
  totalsharespurchased: number;
  soldtransferred: number;
  netremainingatbroker: number;
  localcurrencycd: string;
  // date: string;
  // purchasepriceusd: number;
  // purchasedshares: number;
  // valueusd: number;
  // valueloc: number;
  // disposed: number;
  // remainingbroker: number;
  // localcurrencycd: string; //already in ESPP
  veiplocalcurrencycd: string;
  monthlycontributionusd: number;
  monthlycontributionloc: number;
  priormonthscarryoverusd: number;
  priormonthscarryoverloc: number;
  totalcontributionusd: number;
  detailstotalcontributionloc: number;
  purchasecostusd: number;
  purchasecostloc: number;
  carryovertonextmonthusd: number;
  carryovertonextmonthloc: number;
  exchangerate: number;

  veipSalesHistShares: any = 0;
  veipSalesHistPriceUSD: any = 0;
  veipSalesHistProceedsUSD: any = 0;
  veipSalesHistPurchaseValueUSD: any = 0;

  veipIsDisplayRefund: number;

  // VEIP variables - End

  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd: string;
  salesTableLoading: boolean;
  veipTableLoading: boolean;
  periodSelected: string = "All dates";
  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;

  orientation: string = "horizontal";
  shortAwardName: string;

  //Learn More Tab
  veipCommonQuestionsContent: object[];
  veipGuidebooksContent: object;
  veipProspectusContent: object;
  veipCountryAwardContent: object;
  veipToolsandReportContent: object;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  allCountryFormsContent: string;
  stepTwoContent: object;
  errorLocation: string = " + [Component: Veipdetails, Method: methodName]";
  errorDetailsTab: boolean = false;
  errorSalesHistTab: boolean = false;
  errorCommonQuestions: boolean = false;
  errorGuideBooks: boolean = false;
  errorProspectusDoc: boolean = false;
  errorCountryAward: boolean = false;
  errorToolsAndReports: boolean = false;
  isAllDates: boolean = false;
  
 

  currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));


  
  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;

//Tooltip for Veip Details Page
ContributionPercentage_mtt: string = tScript.veipdetailspage_c;
TotalContribution_mtt: string = tScript.veipdetailspage_tc;
PurchasePrice_mtt: string = tScript.veipdetailspage_pp;
Disposed_mtt: string = tScript.veipdetailspage_d;
RemainingBroker_mtt: string = tScript.veipdetailspage_rb;
PurchaseCost_mtt: string = tScript.veipdetailspage_pc;
RefundedViaPayroll_mtt: string = tScript.veipdetailspage_rvp;
ExchangeRate_mtt: string = tScript.veipdetailspage_er;
SalesHistory_Type: string = tScript.esppveipsaleshistorytab_type;
SalesHistory_Price: string = tScript.esppveipsaleshistorytab_price;
SalesHistory_Proceeds: string = tScript.esppveipsaleshistorytab_proceeds;
SalesHistory_PurchaseDateValue: string = tScript.esppveipsaleshistorytab_pdv;

fileTypeImagePath: string;
fileTypeAlt: string;

commonQAccordionCount: number = 1; // 1 accordion is initially expanded
collapseIndexClass: string; 

positionOption: TooltipPosition[]=['after', 'before', 'above', 'below', 'left', 'right'];
currencyCdSymbol: any;
UBSlink: any;
MSlink: any;
envSetting: any;

  constructor(private sanitizer:DomSanitizer, private brokerService: BrokersPageService, private router: Router, private app: AppComponent, private globals: Globals, private veipProgramDetails: HoldingProgramDetailsVEIPService, private staticContentService: StaticContentService, private errorNotificationService: ErrorNotificationService, private ngbDateParserFormatter: NgbDateParserFormatter) {
    this.app.appNavHeaderVisible = true;
    this.app.appFooterVisible = true;
  }

  ngOnInit() {

    let hasVEIPChecker = sessionStorage.getItem("hasVEIP");
    if(hasVEIPChecker != "1") this.router.navigate(['homepage']);

    // BACK BUTTON BUG - START
    sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } else {
      this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
    }
    // BACK BUTTON BUG - END

    // this.currencyCd = this.currencyCd.split('"').join("");
    let updatedCurrency = JSON.parse(sessionStorage.getItem(this.globals.selectedCurrencyCd));
    let localCurrency = JSON.parse(sessionStorage.getItem(this.globals.localCurrencyCd));
  
    if(updatedCurrency != null) {
      this.currencyCd = updatedCurrency;
    } else {
      this.currencyCd = localCurrency;
    }

    this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")];
    sessionStorage.setItem("Highlighted", "1");
    this.checkErrorMessage();
    this.getProgramDetails();                
    sessionStorage.setItem("Program Component Indicator", JSON.stringify("VEIP"));
    sessionStorage.setItem("Highlight Checker", "1");
    let active = sessionStorage.getItem("IsActive Row");
    

    $(".mat-input-infix span").remove();
    this.brokersSSO();

  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
    if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
      let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

      if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }else{
      if(this.bodyData != null){
        if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
        }else{
          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
        }
      }
    }
  }

  navToPage(strLoc) {
    try {
      // BACK BUTTON BUG - START
      if(strLoc == "holdingsummarypage") {
        sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(true));
      }
      // BACK BUTTON BUG - END

      this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getProgramDetails
      Description: Get VEIP program details from session 

      #     Date            Modified by       Description
      1     01/30/19        Katrina Narag     Initial draft         
      2     02/08/19        KMN                Set award name to grant long desc
  **********************************************************************************/
  getProgramDetails() {
    try {
      this.shortAwardName =  this.programDetails.AwardName;
      this.awardName = this.shortAwardName;  //KMN, 2
      this.detailsAvailableToSell = this.programDetails.AvailableToSell;
      this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
      this.detailsBroker = this.programDetails.BrokerId;
      this.detailsNextRelease = this.programDetails.NextRelease;
      this.detailsSharesReleasing = this.programDetails.SharesReleasing;
      this.detailsGrantAccepted = this.programDetails.GrantAccepted;
      this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
      this.detailsReleased = this.programDetails.ReleasedExercisableQty;
      this.detailsExpiration = this.programDetails.Expiration;
      this.detailsForfeited = this.programDetails.Forfeited;           
      this.loading = false;

    } catch(error) {
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.message += errorLocation;
      throw error;
    }
  }
  
  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    try {
      if(event.target.innerWidth < 600) {
        this.orientation = 'vertical';
      } else {
        this.orientation = 'horizontal';
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "onResize");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(eventBody) {  
    try {
      let href: string = eventBody.target.href;
      let id: string = eventBody.target.id;
      let srcElement: HTMLImageElement = eventBody.srcElement;
      let target: string = eventBody.target.target;
      let reportId: object;
      
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContentService.validateFileType(event);
        } else if(href.indexOf("reportid") != -1) {
          id = href.substring(href.lastIndexOf("=") + 1);
          reportId = {'ReportTypeId': id};
          sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
          window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
          return false;
        } else if(href.indexOf("/Default.aspx") != -1){
          let innerTxt = eventBody.target.innerText;
          event.preventDefault();

          if(target == "_blank") {
            if(href.indexOf("Resources") != -1) {
              window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
              return false; // prevent execution of pop up blocker & default href
            } else if(href.indexOf("Reports") != -1) {
              window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
              return false; // prevent execution of pop up blocker & default href
            }
          } else {
            if(href.indexOf("Resources") != -1){
              eventBody.target.href = this.globals.currentWebUrl + "/resourcespage";
            } else if(href.indexOf("Reports")) {
              sessionStorage.setItem("REPORT ID", JSON.stringify(null));
              eventBody.target.href = this.globals.currentWebUrl + "/reportspage";
            }
          }
          

        }else if(href.indexOf("#") != -1 && id != ""){
          if(target == "_blank") {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("_blank") : "";
          } else {
            eventBody.preventDefault();
            id == "managementcontactlink" ? this.navToMyHoldingsPage("_self"): "";
          }
        } else {
          if(href.indexOf('resourcespage') != -1) {
            window.open(this.globals.currentWebUrl + '/resourcespage', "_blank");
            return false; // prevents execution of pop up blocker & default href
          }
        }             
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : ""

    window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
  }

  /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getFile(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
  
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
  
      fileName = file;
    }
  
    this.staticContentService.getFile(fileName, "MD").subscribe(data => {
      try {
        let content = JSON.parse(data.body);
        let file = content.data;
    
        this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
        this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
          let writeLogResponse = data;
        });
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.message += errorLocation;
        throw error;
      }
  
      
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths
  * 3    05/06/19             Dalemark P. Suquib                    Updated code to append images within the html element
*****************************************************************************************/
async inContentIcon(){
  $("[href='#collapseCQIndex0']").attr("aria-expanded","true");
  $("[id='collapseCQIndex0']").attr("class","collapse show");
  $("[id='learn-more-tab']").attr("aria-label","Learn More for VEIP award");  
  $("u").contents().unwrap();
  $("[href='https://myholdings.accenture.com/Resources/Default.aspx']").attr("aria-label","Resources");
  $("[href='https://myholdings.accenture.com/Resources/Default.aspx']").attr("aria-label","Resources");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=1']").attr("aria-label","Sales History");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=1']").css("text-decoration","underline");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=4']").css("text-decoration","underline");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=4']").attr("aria-label","VEIP Enrollment History");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=5']").css("text-decoration","underline");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=5']").attr("aria-label","VEIP Purchase History");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=6']").css("text-decoration","underline");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=6']").attr("aria-label","Accenture Share Price History");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=7']").css("text-decoration","underline");
  $("[href='https://myholdings.accenture.com/Reports/Default.aspx?reportid=7']").attr("aria-label","US Dollar Exchange Rate History");    
  this.staticContentService.inContentIconAppend();
}

  /*****************************************************************************************
    * Method Name: onTabChange                                                                 
    * Description: Sets timeout for incontenticon method when a tab is selected.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/26/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
onTabChange() {
  try {
    setTimeout(()=>{ this.inContentIcon() }, 500);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "onTabChange");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navtoSpecificReportsPage                                                                 
  * Description: Navigate to specific report
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/05/19             KMN                                   Bug 332329 fix 
  *****************************************************************************************/
 navtoSpecificReportsPage(id){
  let reportId: object;

  reportId = {'ReportTypeId': id};
  sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
  window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Veipdetails") != -1 && this.user != null) {
                webPage = "Veipdetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
  
   /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
   collapseAccordion(index) {
    let className = document.getElementById("collapseCQIndex" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.veipCommonQuestionsContent.length; i++) {
        document.getElementById("collapseCQIndex" + i).className = "collapse";
      }
    }
   }

}


