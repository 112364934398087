import { Injectable } from '@angular/core';
import { ApiService } from './ApiService';
import { Globals } from '../../../globals';

@Injectable()
export class StaticService {

  staticBody: Array<any>;

  constructor(
    private apiService: ApiService,
    private globals: Globals
  ) { }

  /**********************************************************************************
  * Method Name: getStaticContent                                                                 
  * Description: Gets the static content.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         03/17/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getStaticContent(messageName: string): Promise<any> {
    let staticContent = new Promise((resolve, reject) => {
      let body = { 
        functionName: 'getStaticContent',
        messageName: messageName
      };

      this.apiService.postData(this.globals.manageImportInvoiceApi, body).subscribe(data => {
        if(data) {
          if(data.statusCode == 200) {
            this.staticBody = data.body;
            resolve(this.staticBody);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      }, error => {
        reject(error.error.message);
        error.error.message += this.apiService.setErrorContents('getStaticContent', 'StaticService');
        throw error;
      });
    })

    return staticContent;
  }

}
