import { CompCodeItem } from "../models/compcode-model";


export namespace CompanyCode {
    export class Add_Comp_Code_Item {
        static readonly type = '[Company Code] Add_Item';
        constructor( public payload: CompCodeItem ){
        }
    }

    export class Clear_Comp_Code_Item {
        static readonly type = '[Company Code] Clear_Items';
    }
}