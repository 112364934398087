import { Component, OnInit,ViewChild, HostListener } from '@angular/core';
import { Globals } from '../globals';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { HoldingProgramDetailsRSUIPOService } from './HoldingProgramDetailsRSUIPOService';
// import { NumberValueAccessor } from '@angular/forms/src/directives';
import * as moment from 'moment';
import { BrokerType, PageSection, FileType, EmployeeType, MyHoldingsPagesResources, tScript } from '../services/enumerations/enumerations';
// import { MatInput } from '@angular/material';
import { StaticContentService } from '../services/StaticContentService';
import { HoldingsSummaryService } from '../holdingsummarypage/HoldingSummaryService';
import { MultipleGrantsService } from '../multiplegrants/MultipleGrantsService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { BrokersPageService } from '../brokerspage/BrokersPageService';
import { MobileService } from '../services/MobileService';
import { NgbDatepicker, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'; //for modern datepicker
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-holdingprogramdetailspage-rsu-ipo',
  templateUrl: './holdingprogramdetailspage-rsu-ipo.component.html',
  styleUrls: ['./holdingprogramdetailspage-rsu-ipo.component.css']
})
export class HoldingProgramdetailspageRsuIpoComponent implements OnInit {

  // @ViewChild('dateFromInput', {
  //   read: MatInput
  // }) dateFromInput: MatInput; 

  // @ViewChild('dateToInput', {
  //   read: MatInput
  // })  dateToInput: MatInput;
  // @ViewChild('dateFrom', {read: NgbDatepicker}) closeDateFrom: NgbDatepicker;

  /**This block of variables is for modern datepicker */

  datePipe = new DatePipe('en-US');
  displayDateError: boolean;
  hasDateFromBeenClicked: boolean = false;
  hasDateToBeenClicked: boolean = false;
  previousDateFrom: any;
  previousDateTo: any;
  holderDateFrom: any;
  holderDateTo: any;
  dateFromInputPlaceHolder: string;
  dateToInputPlaceHolder: string;
  isCustom: boolean = false;
  errorReportsPage: boolean = false;
/**End of variable list for modern datepicker */

  
  //IPO VEST
  IPOvestDataSource: any;
  IPOdisplayedVestColumns: string[] = ['VestDate', 'Shares', 'Status'];

  //IPO RELEASE
  IPOreleaseDataSource: any;
  IPOdisplayedRelColumns: string[] = ['ReleaseDate', 'Shares', 'Status']; 

  //IPO GRANTS
  IPOgrantDataSource: any;
  IPOdisplayedGrantColumns: string[] = ['GrantId', 'Shares'];

  //SALES HISTORY
 rsuIPOSalesHistDateSource: any;
  displayedRsuIPOSalesHistColumns: string[] = ['DispositionDate', 'TransactionType', 'ShareAmount', 'PriceInUSD', 'ProceedsInUSD', 'ProceedsInLoc'];

  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  user: any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = this.user.CurrencyCd;

  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsNextRelease: any;
  detailsSharesReleasing: any;
  detailsGrantAccepted: any;
  detailsUnreleased: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;
  grantAgreementIndicator: any;
  
  loading: boolean = true;

  displayVestCard: boolean;
  displayReleaseCard: boolean;
  displayGrantCard: boolean;

  displayAgreementTab: boolean;
  
  //IPO - DETAILS

  ipoGrantDesc: string;
  ipoGrantPrice: number;
  ipoTotalAwarded: number;
  ipoUnreleased: number;
  ipoForfeited: number;
  ipoReleased: number;
  ipoWithholdingTaxes: number;
  ipoSoldTransferred: number;
  ipoNetRemainingAtBroker: number;
  ipoOriginalGranted: number;
  ipoOriginalGrantedValue: any;
  ipoOriginalAdditionalRsu: number;
  ipoOriginalAdditionalRsuValue: any;
  ipototalAwardedValue: any;
  shareActGrantDate: any;

  // IPO - VEST
  IPOvestAdditionalRsu: number;
  IPOvestLocalCurrency: string;
  IPOvestOrigGrantedRsu: number;
  IPOvestTotalAward: number;
  IPOvestValue: number;
  IPOvestValueLocal: number;
  IPOvestVestFmv: number;
  IPOvestDisplayValueLocal: boolean;
  IPOvestTotalShares: number;
  IPOvestLoading: boolean = false;
  IPOvestRsuDate: string = "null";

  // IPO - RELEASE

  IPOrelAdditionalRsu: number;
  IPOrelGrossReleased: number;
  IPOrelGrossReleasedLoc: number;
  IPOrelGrossReleasedUsd: number;
  IPOrelLocalCurrencyCd: string;
  IPOrelNetReleased: number;
  IPOrelNetReleasedLoc: number;
  IPOrelNetReleasedUsd: number;
  IPOrelOrigScheduled: number;
  IPOrelReleaseFmv: number;
  IPOrelTaxWithholdingMethod: string;
  IPOrelTotalScheduledRsu: number;
  IPOrelWithheldForTax: any;
  IPOrelWithheldForTaxUsd: any;
  IPOrelWithheldForTaxValueLoc: any;
  IPOrelWithholdingRefund: number;
  IPOrelWithholdingRefundLoc: number;
  IPOrelWithholdingRefundUsd: number;
  IPOrelRsuDate: string = "null";
  IPOrelTotalShares: number;

  IPOrelCurrencySelected: any;
  IPOrelIsSelectedCurrencyUsd: boolean = true;
  IPOreleaseLoading: boolean = false;
  IPOrelDisplayCurrencyDropdown: boolean;
  IPOrelSetCurrencyValue: string;

  // IPO - GRANT 
  // IPOgrantRsuDate: string = "null";
  IPOgrantRsuDate: string = "null";
  IPOgrantTotalShares: number;

  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;
  periodSelected: string = "All Dates";
  datePeriod: number = 4;
  dateTo: string = "''";
  dateFrom: string = "''";
  brokerType: any;
  disableDatePicker: boolean = true;
  dateFromChecker: string;
  dateToChecker: string;
  dateErrorChecker: boolean = false;
  noSalesHistory: boolean = false;
  salesTableLoading: boolean;
  rsuIPOSalesHistShares: number = 0;
  rsuIPOSalesHistPriceUSD: number  = 0;
  rsuIPOSalesHistProceedsUSD: number = 0;
  rsuIPOSalesHistPurchaseValueUSD: number  = 0;
  ipoWithholdingForTaxes: number;

  methodCounter: number = 0;
  shortAwardName: string;

   // Learn More Tab
   rsuipoCommonQuestionsContent: any; 
   rsuipoGuideBooksContent: any; 
   rsuipoShareIncentiveContent: any;
   rsuipoCountAwardCOntent: any;
   rsuipoToolsandRepContent: any;
   bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
   bodyLearnMoreData: any = JSON.parse(sessionStorage.getItem("Body Data"));
   allCountryFormsContent: string;
   stepTwoContent: object;
   grantCountryCode: any;
   grantGroupId: any;
   rsuCommonQuestion: any;
   FrenchQualified: any;
   grantGroupIdProgramDetails: any;
   groupingId: any;
  
   errorLocation: string = " + [Component: Rsuipodetails, Method: methodName]";

  LocalGroupDetailId7: any;
  LocalGroupDetailId8: any;
  LocalGroupDetailId5: any;
  localCurrency: any;

  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;

currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));

//TOOLTIP Variables
grantDesc: string = tScript.rsuipo_grantDesc;
netRemaining: string = tScript.rsuipo_netRemainingBroker;
vestColumn: string = tScript.rsuipo_vest_column;
vstatusColumn: string = tScript.rsuipo_vstatus_column;
origSchedVest: string = tScript.rsuipo_origSchedVest;
addRsuLieuDividends: string = tScript.rsuipo_addRsuLieuDividends;
totalSchedVest: string = tScript.rsuipo_totalSchedVest;
vestfmv: string = tScript.rsuipo_vestfmv;
tooltipVestValue: string = tScript.rsuipo_vestvalue;
releaseColumn: string = tScript.rsuipo_release_column;
rstatusColumn: string = tScript.rsuipo_rstatus_column;

origSchedRelease: string = tScript.rsuipo_origSchedRelease;
addRsuLieuDividendsRelease: string = tScript.rsuipo_addRsuLieuDividendsRelease;
releasefmv: string = tScript.rsuipo_releasefmv;
taxWithholdingMethod: string = tScript.rsuipo_taxWithholdingMethod;
grossReleased: string = tScript.rsuipo_grossReleased;
withheldForTax: string = tScript.rsuipo_withheldForTax;
withholdingRefund: string = tScript.rsuipo_withholdingRefund;
forfietedTooltip: string = tScript.rsuipo_forfieted;
salesHistType: string = tScript.rsuipo_salesHistType;
salesHistShare: string = tScript.rsuipo_salesHistShare;
salesHistProceeds: string = tScript.rsuipo_salesHistProceeds;
// netRemainingTooltip: string = tScript.rsuipo_netRemainingBroker;

sharesUnvested: any;
sharesUnvestedValue: any;
vestedButUnreleased: any;
vestedButUnreleasedValue: any;
totalUnreleased: any;
totalUnreleasedValue: any;
iporsuDetailsReleased: any;
withheldForTaxData: any;
withheldForTaxValue: any;
withHoldingRefund: any;
withHoldingRefundValue: any;
totalWithheld: any;
totalWithheldValue: any;
totalSharesRelease: any = 0;
totalSharesReleaseValue: any = 0;

displayDesktopView: boolean;
displayMobileView: boolean;

fileTypeImagePath: string;
fileTypeAlt: string;
UBSlink: any;
MSlink: any;
envSetting: any;

  constructor(private sanitizer:DomSanitizer, private brokerService: BrokersPageService, private globals: Globals, private app: AppComponent, private router: Router, private HoldingProgramDetailsRSUIpoService: HoldingProgramDetailsRSUIPOService, private staticContentService: StaticContentService, private holdingSummaryService :HoldingsSummaryService, private multiplegrantsService: MultipleGrantsService, private errorNotificationService: ErrorNotificationService, private mobileService: MobileService, private ngbDateParserFormatter: NgbDateParserFormatter) {
    this.app.appNavHeaderVisible = true;
    this.app.appFooterVisible = true;
    this.getInnerWidth();
   }//

   ngOnInit() {
     // BACK BUTTON BUG - START
     sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
     let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
     let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));
 
     if(backUrl) {
       if(detailsStorage.length > 0) {
         this.programDetails = detailsStorage[detailsStorage.length - 1];
       }
     } 
     // BACK BUTTON BUG - END

    this.checkErrorMessage();
    this.getIPOProgramDetails();

    this.getRSUIPODetails();
    this.getRSUIPODetailsVestInfo();
    this.getRSUIPODetailsRelInfo();
    this.getRSUDetailsGrantInfo();

    this.getRSUIPOGuideBooks();
    this.getRsuIPOShareIncentive();
    this.getRSUIPOCountryAward();
    this.getRSUIPOToolsAndReports();
    sessionStorage.setItem("Program Component Indicator", JSON.stringify("RSU"));
    sessionStorage.setItem("Highlight Checker", "1");
    let active = sessionStorage.getItem("IsActive Row");
    this.brokersSSO();
    // this.getFromCodeDetail();

    // this.dateToInput = this.ngbDateParserFormatter.parse("' '");
    // this.dateFromInput = this.ngbDateParserFormatter.parse("' '");    
   
  }

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
  if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
    let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

    if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
    }else{
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
    }
  }else{
    if(this.bodyData != null){
      if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }
  }
}

  /**********************************************************************************
    Method Name: getInnerWidth
    Description: Gets the inner width of the screen or browser.

    #     Date            Modified by                 Description
    1     06/19/19        Dalemark P. Suquib          Initial draft
    2     06/25/19        Jayson S. Sevilla           Implemented to IPORSU
  **********************************************************************************/
 getInnerWidth() {
  this.mobileService.getInnerWidth();
  this.displayDesktopView = this.mobileService.displayDesktopView;
  this.displayMobileView = this.mobileService.displayMobileView;
}

/**********************************************************************************
    Method Name: onResize
    Description: Gets the available inner width of the screen or browser on resize.

    #     Date            Modified by                 Description
    1     06/19/19        Dalemark P. Suquib          Initial draft
    2     06/25/19        Jayson S. Sevilla           Implemented to IPORSU
  **********************************************************************************/
 @HostListener('window:resize', ['$event'])
 onResize(event) {
   try {
     this.mobileService.onResize(event);
     this.displayDesktopView = this.mobileService.displayDesktopView;
     this.displayMobileView = this.mobileService.displayMobileView;
   } catch(error) {
     let errorLocation = this.errorLocation.replace("methodName", "onResize");
     error.message += errorLocation;
     throw error;
   }
 }

  navToPage(strLoc) {
    try {
      // BACK BUTTON BUG - START
      if(strLoc == "holdingsummarypage") {
        sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(true));
      }
      // BACK BUTTON BUG - END

      this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }
  
  /**********************************************************************************
      Method Name: getRSUIPOProgramDetails
      Description: Get RSU IPO program details from session 

      #     Date            Modified by           Description
      1     01/30/19        Katrina Narag         Initial draft         
      2     02/08/19        KMN                   Set award name to grant long desc
      3     03/14/19        Dalemark P. Suquib    Added condition for award name
      4     08/08/19        JPA                   Removed calling the details method and put it in a different method
  **********************************************************************************/
  getIPOProgramDetails() {
    try {
      this.shortAwardName = this.programDetails.AwardName;
      if(this.programDetails.GrantLongDesc != null) {
        this.awardName = this.programDetails.GrantLongDesc;  //KMN, 2
      } else {
        this.awardName = this.shortAwardName;
      }
      this.detailsAvailableToSell = this.programDetails.AvailableToSell;
      this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
      this.detailsBroker = this.programDetails.BrokerId;
      this.detailsNextRelease = this.programDetails.NextRelease;
      this.detailsSharesReleasing = this.programDetails.SharesReleasing;
      this.detailsGrantAccepted = this.programDetails.GrantAccepted;
      this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
      this.detailsReleased = this.programDetails.ReleasedExercisableQty;
      this.detailsExpiration = this.programDetails.Expiration;
      this.detailsForfeited = this.programDetails.Forfeited;
  
      if(this.programDetails.GrantAgreementTabVisibleInd == 'Y') {
        this.displayAgreementTab = true;
      } else {
        this.displayAgreementTab = false;
      }
  
      this.HoldingProgramDetailsRSUIpoService.getIPOSalesHistoryPeriod().pipe(map(res => res)).subscribe(data => {
        try {
          let b = data;
          b = b.sort((a,b) => 0 - (a.Id > b.Id ? -1 : 1));
          this.yearToDate = b[0].Value;
          this.lastYear = b[1].Value;
          this.last3Years = b[2].Value;
          this.allDates = b[3].Value;
          this.custom = b[4].Value;
          
          // this.periodSelected = this.allDates;
    
          // this.getRSUIPODetails();
          // this.getRSUIPODetailsVestInfo();
          // this.getRSUIPODetailsRelInfo();
          // this.getRSUDetailsGrantInfo();
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getIPOProgramDetails");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getIPOProgramDetails");
        error.error.message += errorLocation;
        throw error.error;
      })
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getIPOProgramDetails");
      error.message += errorLocation;
      throw error;
    }
  }

// <<<<<<<<<<<<<<<<<<<<<<<<<<<  RSU IPO Details Tab Method - END >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> //

// DEFAULT ACCORDION START
getRSUIPODetails() {
  try {
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetails(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
      try {
        if(Array.isArray(data) && data.length > 0) {
          let ipoRSUDetails = data[0][0];

          if(ipoRSUDetails != null || ipoRSUDetails != undefined){
            this.ipoGrantDesc = ipoRSUDetails.GrantDescription;
            this.shareActGrantDate = ipoRSUDetails.GrantDate;
            this.ipoGrantPrice = ipoRSUDetails.GrantPrice;
            this.ipoTotalAwarded = ipoRSUDetails.TotalAwarded;
            this.ipoUnreleased = ipoRSUDetails.Unreleased;
            this.ipoForfeited = ipoRSUDetails.Forfeited;
            this.ipoReleased = ipoRSUDetails.Released;
            this.ipoWithholdingTaxes = ipoRSUDetails.WithheldForTax;
            this.ipoSoldTransferred = ipoRSUDetails.SoldTransferred;
            this.ipoNetRemainingAtBroker = ipoRSUDetails.NetRemainingAtBroker;
            this.ipoWithholdingForTaxes = ipoRSUDetails.WithholdingforTaxes;
          }
    
          let iporsudetailsGrantedData = data[1][0];   
          
          if(iporsudetailsGrantedData != null || iporsudetailsGrantedData != undefined){
            this.ipoOriginalGranted = iporsudetailsGrantedData.OriginalGranted;
            this.ipoOriginalGrantedValue = iporsudetailsGrantedData.OriginalGrantedValue;
            this.ipoOriginalAdditionalRsu = iporsudetailsGrantedData.AdditionalRsu;
            this.ipoOriginalAdditionalRsuValue = iporsudetailsGrantedData.AdditionalRsuValue;
            this.ipototalAwardedValue = this.ipoOriginalGrantedValue + this.ipoOriginalAdditionalRsuValue; 
    
            if (this.ipoOriginalGranted == this.ipoForfeited){
              this.displayVestCard = false;
              this.displayReleaseCard = false;
              this.displayGrantCard = false;
            }
            else {
              this.displayVestCard = true;
              this.displayReleaseCard = true;
              this.displayGrantCard = true;
            }
          }

          let iporsuDetailsUnvestedData = data[2][0];

          if(iporsuDetailsUnvestedData != null || iporsuDetailsUnvestedData != undefined){
            this.sharesUnvested = iporsuDetailsUnvestedData.SharesUnvested;
            this.sharesUnvestedValue = this.sharesUnvested*this.ipoGrantPrice;
            this.vestedButUnreleased = iporsuDetailsUnvestedData.VestedButUnreleased;
            this.vestedButUnreleasedValue = this.vestedButUnreleased*this.ipoGrantPrice;
            this.totalUnreleased = iporsuDetailsUnvestedData.TotalUnreleased;
            this.totalUnreleasedValue = this.totalUnreleased*this.ipoGrantPrice;
          }

          this.iporsuDetailsReleased = data[3];

          for(let i=0; i<this.iporsuDetailsReleased.length;i++){
            this.totalSharesRelease += this.iporsuDetailsReleased[i].Shares;
            this.totalSharesReleaseValue += this.iporsuDetailsReleased[i].Value;
          }

          let iporsuDetailsWithheldData = data[4][0];
          
          if(iporsuDetailsWithheldData != null || iporsuDetailsWithheldData != undefined){
            this.withheldForTaxData = iporsuDetailsWithheldData.WithheldForTax;
            this.withheldForTaxValue = iporsuDetailsWithheldData.WithheldForTaxValue;
            this.withHoldingRefund = iporsuDetailsWithheldData.WithHoldingRefund;
            this.withHoldingRefundValue = iporsuDetailsWithheldData.WithHoldingRefundValue;
            this.totalWithheld = iporsuDetailsWithheldData.TotalWithheld;
            this.totalWithheldValue = iporsuDetailsWithheldData.TotalWithheldValue;
          }
        }
    
        this.executeMethod();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetails");
    error.message += errorLocation;
    throw error;
  }
}
// DEFAULT ACCORDION - END

// VEST ACCORDION START
getRSUIPODetailsVestInfo() {
  try {
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetailsVestInfo(this.user.OptNum, this.IPOvestRsuDate).pipe(map(res => res)).subscribe(data => {
      try {
        if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0)) {
          this.IPOvestTotalShares = 0;
          this.IPOvestDataSource = data[0];
    
          let IPOvest = data[1][0];
    
          for(let i = 0; i < this.IPOvestDataSource.length; i++) {
            this.IPOvestTotalShares += this.IPOvestDataSource[i].Shares;
          }

          if(IPOvest != null || IPOvest != undefined){
            this.IPOvestAdditionalRsu = IPOvest.AdditionalRsu;
            this.IPOvestLocalCurrency = IPOvest.LocalCurrency;
            this.IPOvestOrigGrantedRsu = IPOvest.OriginalGrantedRsu;
            this.IPOvestTotalAward = IPOvest.TotalAward;
            this.IPOvestValue = IPOvest.Value;
            this.IPOvestValueLocal = IPOvest.ValueLocal;
            this.IPOvestVestFmv = IPOvest.VestFMV;
          }
    
          
    
          if(this.IPOvestLocalCurrency == "USD" || this.IPOvestLocalCurrency == "") {
            this.IPOvestDisplayValueLocal = false;
          } else {
            this.IPOvestDisplayValueLocal = true;
          }
    
          if(this.ipoForfeited) this.IPOvestLoading = false;
          this.IPOvestLoading = false;
          
        } else {
          this.displayVestCard = false;
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsVestInfo");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsVestInfo");
      error.error.message += errorLocation;
      throw error.error;
    });
  
    this.executeMethod();
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsVestInfo");
    error.message += errorLocation;
    throw error;
  }
}

IPOviewVestDate(IPOvestData) {
  try {
    this.IPOvestLoading = true;
    this.IPOvestRsuDate = IPOvestData.VestDate;
    this.IPOvestRsuDate = "'" + moment(this.IPOvestRsuDate).format('MM/DD/YYYY') + "'";
    this.getRSUIPODetailsVestInfo();
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "IPOviewVestDate");
    error.message += errorLocation;
    throw error;
  }
}
// VEST ACCORDION END

// RELEASE ACCORDION START
/******************************************************************************************************************************
    Method Name: getRSUIPODetailsRelInfo
    Description: Initialize the date depending on which period the user uses.

    #    Date             Modified by                               Description
    1    06/27/19         KMN                                       Bug 355310 fix - Undefined values when value is clicked in RSU Realease Value Dropdown in Holding Summary Page
  *******************************************************************************************************************************/
getRSUIPODetailsRelInfo() {
  try {
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetailsRelInfo(this.user.OptNum, this.IPOrelRsuDate).pipe(map(res => res)).subscribe(data => {
      try {
        if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0)) {
          this.IPOrelTotalShares = 0;
          this.IPOrelSetCurrencyValue = "USD";
          let IPOrelInfoTable = data[0];
          let IPOrelease = data[1][0];
          
          for(let i = 0; i < IPOrelInfoTable.length; i++) {
            if(IPOrelInfoTable[i].StatusFlag == 1) {
              IPOrelInfoTable[i].Status = "Released"; // set the status value base on the status flag

              if(IPOrelInfoTable[i].BrokerCd != "0"){
                IPOrelInfoTable[i].Status = IPOrelInfoTable[i].Status + " - " + BrokerType[IPOrelInfoTable[i].BrokerCd];
              }
            } else if(IPOrelInfoTable[i].StatusFlag == 2) {
              IPOrelInfoTable[i].Status = "Released"; // set the status value base on the status flag
            } else if(IPOrelInfoTable[i].StatusFlag == 3) {
              IPOrelInfoTable[i].Status = "Unreleased"; // set the status value base on the status flag
            } else if(IPOrelInfoTable[i].StatusFlag == 4) {
              IPOrelInfoTable[i].Status = "Forfeited"; // set the status value base on the status flag
            }
            this.IPOrelTotalShares += IPOrelInfoTable[i].Shares;
          }
      
          this.IPOreleaseDataSource = IPOrelInfoTable;
          this.IPOrelAdditionalRsu = IPOrelease.AdditionalRsu;
          this.IPOrelGrossReleased = IPOrelease.GrossReleased;
          this.IPOrelGrossReleasedLoc = IPOrelease.GrossReleasedLoc;
          this.IPOrelGrossReleasedUsd = IPOrelease.GrossReleasedUsd;
          this.IPOrelLocalCurrencyCd = IPOrelease.LocalCurrencyCd;
          this.IPOrelNetReleased = IPOrelease.NetReleased;
          this.IPOrelNetReleasedLoc = IPOrelease.NetReleasedLoc;
          this.IPOrelNetReleasedUsd = IPOrelease.NetReleasedUsd;
          this.IPOrelOrigScheduled = IPOrelease.OriginallyScheduled;
          this.IPOrelReleaseFmv = IPOrelease.ReleaseFmv;
          this.IPOrelTaxWithholdingMethod = IPOrelease.TaxWithholdingMethod;
          this.IPOrelTotalScheduledRsu = IPOrelease.TotalScheduledRsu;
          this.IPOrelWithheldForTax = IPOrelease.WithheldForTax;
          this.IPOrelWithheldForTaxUsd = IPOrelease.WithheldForTaxUsd;
          this.IPOrelWithheldForTaxValueLoc = IPOrelease.WithheldForTaxLoc;
          this.IPOrelWithholdingRefund = IPOrelease.WithholdingRefund;
          this.IPOrelWithholdingRefundLoc = IPOrelease.WithholdingRefundLoc;
          this.IPOrelWithholdingRefundUsd = IPOrelease.WithholdingRefundUsd;
      
          if(this.IPOrelTaxWithholdingMethod == "Payroll Withholding") {
            this.IPOrelWithheldForTax = "Refer to Earnings Statements";
            this.IPOrelWithheldForTaxUsd = "Refer to Earnings Statements";
            this.IPOrelWithheldForTaxValueLoc = "Refer to Earnings Statements";
          }
      
          if(this.IPOrelLocalCurrencyCd == "USD") {
            this.IPOrelDisplayCurrencyDropdown = false;
          } else {
            this.IPOrelDisplayCurrencyDropdown = true;
          }
          this.IPOreleaseLoading = false;
        } else {
          this.displayReleaseCard = false;
        }
    
        this.executeMethod();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsRelInfo");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsRelInfo");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUIPODetailsRelInfo");
    error.message += errorLocation;
    throw error;
  }
}

IPOviewReleaseDate(IPOreleaseData) {
  try {
    this.IPOreleaseLoading = true;
    this.IPOrelRsuDate = IPOreleaseData.ReleaseDate;
    this.IPOrelRsuDate = "'" + moment(this.IPOrelRsuDate).format('MM/DD/YYYY') + "'";
    this.getRSUIPODetailsRelInfo();
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "IPOviewReleaseDate");
    error.message += errorLocation;
    throw error;
  }
}

IPOcheckRelCurrency(IPOcurrency) {
  try {
    if(IPOcurrency == "USD") {
      this.IPOrelIsSelectedCurrencyUsd = true;
      this.IPOrelSetCurrencyValue = IPOcurrency;
    } else {
      this.IPOrelIsSelectedCurrencyUsd = false;
      this.IPOrelSetCurrencyValue = IPOcurrency;
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "IPOcheckRelCurrency");
    error.message += errorLocation;
    throw error;
  }
}

// RELEASE ACCORDION END

// GRANT ACCORDION START
getRSUDetailsGrantInfo() {
  try {
    this.HoldingProgramDetailsRSUIpoService.getRSUIPODetailsGrantInfo(this.user.OptNum, this.IPOgrantRsuDate).pipe(map(res => res)).subscribe(data => {
      try {
        if(Array.isArray(data) && data[0].length > 0) {
          this.IPOgrantTotalShares = 0;
          this.IPOgrantDataSource = data[0];
      
          for(let i = 0; i < this.IPOgrantDataSource.length; i++) {
            this.IPOgrantTotalShares += this.IPOgrantDataSource[i].Shares;
          }
        } else {
          this.displayGrantCard = false;
       }
    
        this.executeMethod();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsGrantInfo");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsGrantInfo");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsGrantInfo");
    error.message += errorLocation;
    throw error;
  }
}
// GRANT ACCORDION END

  // Sales History Tab Method - START
  getRSUIPOSalesHistory() {
    try {
      

      this.dateTo = moment(this.dateTo).format("YYYY-MM-DD");
      this.dateFrom = moment(this.dateTo).format("YYYY-MM-DD");
      this.HoldingProgramDetailsRSUIpoService.getRSUIPOSalesHistory(this.user.OptNum, this.datePeriod, this.dateTo, this.dateFrom).pipe(map(res => res)).subscribe(data => {
        try {

          if(data[1].length == 0) {
            this.rsuIPOSalesHistDateSource = data[1];
            this.brokerType = BrokerType[data[0][0].BrokerCd];
            this.noSalesHistory = true;
            this.salesTableLoading = false;
          } else {
            this.noSalesHistory = false;
            this.salesTableLoading = false;
            this.brokerType = BrokerType[data[0][0].BrokerCd];
            this.rsuIPOSalesHistDateSource = data[1];
    
            for(let i = 0; i < data[1].length; i++) {
              this.localCurrency =data[1][i].LocalCurrencyCd;
              this.rsuIPOSalesHistShares += (data[1][i].ShareAmount*1);
              this.rsuIPOSalesHistPriceUSD += (data[1][i].PriceInUSD*1);
              this.rsuIPOSalesHistProceedsUSD += (data[1][i].ProceedsInUSD*1);
              this.rsuIPOSalesHistPurchaseValueUSD += (data[1][i].ProceedsInLoc*1);
            }
          }
    
          this.getFromCodeDetail();
          
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOSalesHistory");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOSalesHistory");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOSalesHistory");
      error.message += errorLocation;
      throw error;
    }
  }

  viewRSUIPOSalesHist() {
    try {
      this.dateErrorChecker = false;
      this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
      this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
  
      let tempDateTo = this.dateTo.replace(/'/g, '');
      let tempDateFrom = this.dateFrom.replace(/'/g, '');
  
      tempDateTo = moment(tempDateTo).format("YYYY-MM-DD");
      tempDateFrom = moment(tempDateFrom).format("YYYY-MM-DD");
  
      if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else if (tempDateTo < tempDateFrom && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else{
      this.salesTableLoading = true;
      this.rsuIPOSalesHistShares = 0;
      this.rsuIPOSalesHistPriceUSD = 0;
      this.rsuIPOSalesHistProceedsUSD = 0;
      this.rsuIPOSalesHistPurchaseValueUSD = 0;
      this.getRSUIPOSalesHistory();
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewRSUIPOSalesHist");
      error.message += errorLocation;
      throw error;
    }
  }
  // Sales History Tab Method - END

//COMMAFY

commafy(num: any, value?: number) {
  try {
  // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value > 0) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message += errorLocation;
    throw error;
  }
}

  /**********************************************************************************
   * Method Name: executeMethod                                                                 
   * Description: Ensures that the method with loading set to false execute last                                
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 02/08/19             Dalemark P. Suquib          Initial draft       
   *********************************************************************************/
  executeMethod() {
    try {
      if(this.methodCounter >= 3) {
        this.getRSUIPOSalesHistory();  
      } else {
        this.methodCounter += 1;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "executeMethod");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

  
    /*****************************************************************************************
   * Method Name: getFromCodeDetail                                                                 
   * Description: Get GroupingId from CodeDetails API to be used in Common Question conditions  
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/25/2019           Iris Jane R. Manloloyo (IJM)         Initial Draft
   * 2    08/08/2019           Joshua Paul Avecilla                 changed call of api for performance.
   *****************************************************************************************/

getFromCodeDetail(){
  try {
    let counter = 0;
    this.HoldingProgramDetailsRSUIpoService.getDataFromCodeDetail(7).pipe(map(res => res)).subscribe(data => {
        let body = data;
        data != undefined ? this.LocalGroupDetailId7 = body[0] : "";
        counter++;

      if(counter == 3){
        this.getrsuCommonQuestions();
      }
    });

    this.HoldingProgramDetailsRSUIpoService.getDataFromCodeDetail(8).pipe(map(res => res)).subscribe(data => { 
      let body = data;

      data != undefined ? this.LocalGroupDetailId8 = body[0] : "";
      counter++;
      
      if(counter == 3){
        this.getrsuCommonQuestions();
      }
    });

    this.HoldingProgramDetailsRSUIpoService.getDataFromCodeDetail(5).pipe(map(res => res)).subscribe(data => { 
      let body = data;
      data != undefined ? this.LocalGroupDetailId5 = body[0] : "";
      counter++;
      
      if(counter == 3){
        this.getrsuCommonQuestions();
      }
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFromCodeDetail");
    error.message += errorLocation;
    throw error;
  }
}

    /*****************************************************************************************
   * Method Name:   getrsuCommonQuestions() {
                                                                 
   * Description: Gets the content for common questions from my resource api if the user hasOptionsAward
   *              and GrantCountryCd= CAN   
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/24/2019           Iris Jane R. Manloloyo (IJM)         Initial Draft
   *****************************************************************************************/
  getrsuCommonQuestions() {
    try {
      if (this.programDetails.GrantNum == null){

        let temp = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
        this.programDetails = temp[0];
      }

       // BACK BUTTON BUG - START
      sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
      let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
      let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

      if(backUrl) {
        if(detailsStorage.length > 0) {
          this.programDetails = detailsStorage[detailsStorage.length - 1];
        }
      } 
      // BACK BUTTON BUG - END

      this.grantCountryCode = this.programDetails.GrantCountryCd; 
      this.grantGroupIdProgramDetails = this.programDetails.GrantGroupId;
      let awardName = this.programDetails.AwardName;
      this.bodyData[6] = 1;
  
      if (this.programDetails.GrantNum == 0){
        this.FrenchQualified = 0;
      } else {
      this.multiplegrantsService.getRSUAgreementInfo(this.programDetails.GrantNum).pipe(map(res => res)).subscribe(data => { 
        try {
          let agreementContent = data[0][0]; 
          this.FrenchQualified = agreementContent.FrenchQualifiedInd;
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
    }

    let locExchageRate = this.LocalGroupDetailId7.find(i => i.GroupingValue == this.programDetails.GrantGroupId);      
    locExchageRate == undefined ? locExchageRate = [] : "";         

    this.bodyData[5] = "";

    if(this.programDetails.GrantCountryCd == "DNK"){  
      if(awardName.indexOf("RSU IPO") != -1 || awardName.indexOf("IPO RSU") != -1){
        this.bodyData[5] = PageSection.IPORSUDenmark;      
      }    
      else if(locExchageRate == this.programDetails.GrantGroupId && (awardName.indexOf("RSU") != -1)){
          this.bodyData[5] = PageSection.DenmarkBonusRSU;       
      }else if(awardName.indexOf("RSU") != -1){
          this.bodyData[5] = PageSection.DenmarkRSU; 
        }
        else {
          this.bodyData[5] = PageSection.BonusShareRSU;
        }               
    }else if(this.FrenchQualified == 1){
      this.user.JobCd == 10000025 ? this.bodyData[5] = PageSection.GrantFRQSnERSU : this.bodyData[5] = PageSection.GrantFRQSMRRSU;  
    }

    this.bodyData[5] == "" ? this.bodyData[5] = PageSection.RSUNullContent : "";   

        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          try {
            let rsuCommonQuestionsContents = JSON.parse(data.body);
            
           if(rsuCommonQuestionsContents.data) { // length > {}
            let rsuCommonBody = rsuCommonQuestionsContents.data;
            
            if(rsuCommonBody.length > 0) {
              this.rsuipoCommonQuestionsContent = rsuCommonBody;
            } else {
              this.rsuipoCommonQuestionsContent = null;
            }
         }
            
          } catch(error) {
            let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
          error.error.message += errorLocation;
          throw error.error;
        });

        this.loading = false;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
      error.message += errorLocation;
      throw error;
    }
  }

  
  /*****************************************************************************************
   * Method Name: getGuideBooks                                                                 
   * Description: Gets the content for guide books from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getRSUIPOGuideBooks(){
    try {
      this.bodyData[5] = PageSection.rsuGuidebooks;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let guideBooksData = JSON.parse(data.body);
  
         if(guideBooksData.data) { // length > {}
            let guideBooksBody = guideBooksData.data;
            
            if(guideBooksBody.length > 0) {
              this.rsuipoGuideBooksContent = guideBooksBody[0].Body_Desc;
            } else {
              this.rsuipoGuideBooksContent = null;
            }
          }
          
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOGuideBooks");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOGuideBooks");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOGuideBooks");
      error.message += errorLocation;
      throw error;
    }
  }
  
  /*****************************************************************************************
   * Method Name: getRsuIPOShareIncentive                                                                 
   * Description: Gets the content for plan document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getRsuIPOShareIncentive() {
    try {
      this.bodyData[5] = PageSection.rsuShareIncentive;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let shareIncenDocumentData = JSON.parse(data.body);
  
          if(shareIncenDocumentData.data) { // length > {}
            let optDocumentBody = shareIncenDocumentData.data;

            if(optDocumentBody.length > 0) {
              this.rsuipoShareIncentiveContent = optDocumentBody[0].Body_Desc;
            } else {
              this.rsuipoShareIncentiveContent = null;
            }
         }
          
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRsuIPOShareIncentive");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRsuIPOShareIncentive");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRsuIPOShareIncentive");
      error.message += errorLocation;
      throw error;
    }
  }


  /*****************************************************************************************
   * Method Name: getProspectusDocument                                                                 
   * Description: Gets the content for prospectus document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getRSUIPOCountryAward() {
    try {
      this.bodyData[5] = PageSection.rsuCountryAward;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let CountryAwardDocData = JSON.parse(data.body);
  
         if(CountryAwardDocData.data) { // length > {}
            let countryAwardDocBody = CountryAwardDocData.data;
    
            if(countryAwardDocBody.length > 0) {
              this.rsuipoCountAwardCOntent = countryAwardDocBody[0].Body_Desc;
            } else {
              this.rsuipoCountAwardCOntent = null;
            }
         }
          
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOCountryAward");
         error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOCountryAward");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOCountryAward");
      error.message += errorLocation;
      throw error;
    }
  } 

  
  /*****************************************************************************************
   * Method Name: getToolsAndReports                                                                 
   * Description: Gets the content for tools and reports from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getRSUIPOToolsAndReports() {
    try {
      this.bodyData[5] = PageSection.rsuToolsAndReports;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let toolsAndReportsData = JSON.parse(data.body);
  
         if(toolsAndReportsData.data) { // length > {}
            let toolsAndReportsBody = toolsAndReportsData.data;
            
            if(toolsAndReportsBody.length > 0) {
              this.rsuipoToolsandRepContent = toolsAndReportsBody;
            } else {
              this.rsuipoToolsandRepContent = null;
            }
            
         }
         
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOToolsAndReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOToolsAndReports");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOToolsAndReports");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let srcElement: HTMLImageElement = event.srcElement;
      let target: string = event.target.target;
        if (target == ""){
          target = "_self";
        }
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != ""){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          // href = href.split("/Default.aspx")[0]; 
          href = event.target.innerText;
          event.preventDefault();

          href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
        } else {
          window.open(href, "_blank");
          return false;
        }
      }
      else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
        event.preventDefault();
        window.open(href, target);
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : ""

    window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
  }

getStepTwoContent() {
  try {
    this.bodyData[5] = PageSection.esppTransacStepTwo + this.bodyData[0];
    this.bodyData[6] = 0;
  
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let stepTwoData = JSON.parse(data.body);

        if(stepTwoData.data) { // length > {}
          let stepTwoBody = stepTwoData.data;
     
          if(stepTwoBody.length > 0) {
            this.stepTwoContent = stepTwoBody;
     
            for(let i = 0; i < stepTwoBody.data.length; i++) {
              if(this.stepTwoContent[i].File_Type == "pdf") {
               this.stepTwoContent[i].fileIcon = FileType.pdf;
              } else if(this.stepTwoContent[i].File_Type == "docx") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "doc") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "xls") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "xlsx") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "pptx") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "ppt") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "zip") {
               this.stepTwoContent[i].fileIcon = FileType.zip;
              } 
            }
          } else {
            this.stepTwoContent = null;
          }
        }
     
        this.getAllCountryForms();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
     let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
  Method Name: getAllCountryForms
  Description: Gets all the available country forms.

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getAllCountryForms() {
  try {
    this.bodyData[5] = PageSection.esppTransacAllCountryForms;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
     try {
      let allCountryFormsData = JSON.parse(data.body);
     
      if(allCountryFormsData.data) { // length > {}
        let allCountryFormsBody = allCountryFormsData.data;
  
        if(allCountryFormsBody.length > 0) {
          this.allCountryFormsContent = allCountryFormsBody[0].Body_Desc;
          //setTimeout(() => { this.inContentIcon() }, 500);
        } else {
          this.allCountryFormsContent = null;
        }
      }
     } catch(error) {
       let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
       error.message += errorLocation;
       throw error;
     }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths

*****************************************************************************************/
async inContentIcon(){
  $("[id='learn-more-tab']").attr("aria-label","Learn More - Restricted Share Unit  IPO (RSU IPO)");

      //matDatepicker mod
    // $(".mat-input-infix span").remove();
    // $(".mat-input-underline span").remove();
    // $(".mat-input-subscript-wrapper").remove();
    // $(".mat-input-underline").css("height","0");
    // $(".mat-input-element").addClass("datepicker-input");
    // $(".mat-input-infix input").css("height","30px");
    // $(".mat-input-infix input").css("width","173px");
    // $(".mat-input-infix input").css("margin-bottom","2.8px");
    // $(".mat-form-field-infix").css("border-top","0");
    // $(".mat-datepicker-toggle button").removeClass("mat-icon-button").addClass("btn btn-outline-secondary calendar");
    // $(".mat-datepicker-toggle button span").remove();
    // $(".mat-datepicker-toggle button div").remove();
    // $(".mat-datepicker-toggle button").removeAttr("mat-icon-button");
    // $(".mat-form-field-infix").css("width","173px");
    // $(".mat-datepicker-toggle button").css("width","50px");
    // $(".mat-datepicker-toggle button").css("height","30px");
    // $(".mat-datepicker-toggle button").css("margin-left","-4px");
    // $(".mat-datepicker-toggle button").css("background-image","url(/assets/calendar.png)");
    // $(".mat-datepicker-toggle button").css("background-repeat","no-repeat");
    // $(".mat-datepicker-toggle button").css("background-size","23px");
    // $(".mat-datepicker-toggle button").css("background-position","center");
  //end of matDatepicker Mod

  this.staticContentService.inContentIconAppend();
}

  /*****************************************************************************************
    * Method Name: onTabChange                                                                 
    * Description: Sets timeout for incontenticon method when a tab is selected.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/26/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
onTabChange() {
  try {
    setTimeout(()=>{ this.inContentIcon() }, 500);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "onTabChange");
    error.message += errorLocation;
    throw error;
  }
}


/*****************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
navToReportsPage(event) {
  try {
    let href = event.target.href;
    let id: number;
    let reportId: object;
    let srcElement: HTMLImageElement = event.srcElement;

    if(srcElement.src != undefined){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
    }else if(href != undefined) {
      if(href.indexOf("reportid") != -1) {
        id = href.substring(href.lastIndexOf("=") + 1);
        reportId = {'ReportTypeId': id};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
        window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
      }else if(href.indexOf("fileName=") != -1){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    }

  } catch(error) {
   let errorLocation = this.errorLocation.replace("methodName", "navToReportsPage");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navtoSpecificReportsPage                                                                 
  * Description: Navigate to specific report
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/05/19             KMN                                   Bug 332329 fix 
  *****************************************************************************************/
 navtoSpecificReportsPage(id){
  let reportId: object;

  reportId = {'ReportTypeId': id};
  sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
  window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Rsuipodetails") != -1 && this.user != null) {
                webPage = "Rsuipodetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
//   getGlobalID() {
//     try {
//       this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
//         try {
//           this.globalIDs = data[0];
  
//           if(this.globalIDs.length == 0) {
//             this.phSBGlobalID = true;
//             this.globalIDNum1 = "";
//           } else if(this.globalIDs.length == 1) {
//             this.phSBGlobalID = true;
    
//             if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
//               this.globalIDNum1 = "Your SSN";
//             } else {
//               this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
//             }
//           } 

//         } catch(error) {
//           let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
//           error.message += errorLocation;
//           throw error;
//         }
//       }, error => {
//         let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
//         error.error.message += errorLocation;
//         throw error.error;
//       });
//     } catch(error) {
//       let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
//       error.message += errorLocation;
//       throw error;
//     }
//    }
  

//    checkBrokersInfo(broker){
//     event.stopPropagation();
   

//     if(this.ubsLoaded == true)
//       this.resourceLoading = false;
//     else
//       this.resourceLoading = true;
    
//     if(this.sbLoaded == true)
//       this.resourceLoading = false;
//     else
//       this.resourceLoading = true;
    
//     if(this.bigLoaded == true)
//       this.resourceLoading = false;
//     else
//       this.resourceLoading = true;
    
//     if(this.ncbLoaded == true)
//       this.resourceLoading = false;
//     else
//       this.resourceLoading = true;
    

    
//     this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
//       let brokerAccounts = data[0];
//       if(brokerAccounts.length > 0) {

//         for(let i = 0; i < brokerAccounts.length; i++) {
//   if(broker == "MS"){
//     (<any>$("#brokerContact")).modal({
//       backdrop: 'static',
//       keyboard: false
//     });

//     this.brokerHeader = "Morgan Stanley";
    
//     this.getGlobalID();

//     this.displayPersonalUbs = false;
//     this.displayPersonalBig = false;
//     this.displayPersonalNcb = false;

//     this.bodyData[5] = PageSection.sb;
//     this.bodyData[6] = 1;
//     this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
      
//         let msData = JSON.parse(data._body);
//         let msBody;

//         if(msData.body.length > 2) { // length > {}
//         msBody = JSON.parse(msData.body);

//           if (msBody.data.length > 0){
//             this.sbDesc = msBody.data[0].Body_Desc;
//             this.displayPersonalSb = true;
//             }
//           else {
//             this.displayPersonalSb = false;
//             this.sbDesc = "";
//           } 
//         }


//       this.bodyData[5] = PageSection.sbDoc;
//       this.bodyData[6] = 0;

//       this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
//       let msDocData = JSON.parse(data._body);
//       let msDocBody;
      
//       if(msDocData.body.length > 2) { // length > {}
//                       msDocBody = JSON.parse(msDocData.body);
//                       if(msDocBody.data.length > 0) {
//                         this.sbDoc = msDocBody.data;
      
//                         for(let i=0; i<this.sbDoc.length; i++){
//                           this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
//                         }
//                       }
//                       else{
//                         this.sbDoc = null;
//                       }
//                     }
                
//       });
//       this.sbLoaded = true;
//   this.resourceLoading = false;
//     });

//   }else if(broker == "UBS"){
//     (<any>$("#brokerContact")).modal({
//       backdrop: 'static',
//       keyboard: false
//     });
    

//     this.brokerHeader = "UBS Financial Services Inc";
//     this.displayPersonalSb = false;
//     this.displayPersonalBig = false;
//     this.displayPersonalNcb = false;

//   this.ubsAccount = brokerAccounts[i].Account;
//   this.bodyData[5] = PageSection.ubs;
//   this.bodyData[6] = 1;
  

//   this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
//     let ubsData = JSON.parse(data._body);
//     let ubsBody;

//     if(ubsData.body.length > 2) { // length > {}
//     ubsBody = JSON.parse(ubsData.body);
//     if(ubsBody.data.length > 0) {
//       this.ubsDesc = ubsBody.data[0].Body_Desc;
//       this.displayPersonalUbs = true;
//     }
//     else {
//     this.displayPersonalUbs = false;
//       this.ubsDesc = "";
//     }
//   }

//   this.bodyData[5] = PageSection.ubsDoc;
//   this.bodyData[6] = 0;
  
//   this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

//     let ubsDocData = JSON.parse(data._body);
//     let ubsDocBody;
    
//     if(ubsDocData.body.length > 2) { // length > {}
//         ubsDocBody = JSON.parse(ubsDocData.body);
//         if(ubsDocBody.data.length > 0){
//           this.ubsDoc = ubsDocBody.data;
  
//           for(let i=0; i<this.ubsDoc.length; i++){
//             this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
//           }
//         }
//         else{
//           this.ubsDoc = null;
//         }
//       }
//   });
//   this.ubsLoaded = true;
//   this.resourceLoading = false;
//   });
//   }else if(broker == "BIG"){
//     (<any>$("#brokerContact")).modal({
//       backdrop: 'static',
//       keyboard: false
//     });
    

//     this.brokerHeader = "Banco de Investimento Global";

//     this.displayPersonalSb = false;
//     this.displayPersonalUbs = false;
    
//     this.displayPersonalNcb = false;

//     this.bigAccount = brokerAccounts[i].Account;
//     this.bodyData[5] = PageSection.big;
//     this.bodyData[6] = 1;
//     this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    
//       let bigData = JSON.parse(data._body);
//       let bigBody;

//       if(bigData.body.length > 2) { // length > {}
//       bigBody = JSON.parse(bigData.body);
//       if(bigBody.data.length > 0){
//         this.bigDesc = bigBody.data[0].Body_Desc;
//         this.displayPersonalBig = true;
//       }else {
//         this.displayPersonalBig = false;
//         this.bigDesc = "";
//       }
//     }
//     this.bigLoaded = true;
//   this.resourceLoading = false;
//     });

//   }else if(broker == "TA"){
//     (<any>$("#brokerContact")).modal({
//       backdrop: 'static',
//       keyboard: false
//     });
    

//     this.brokerHeader = "Computershare (formerly National City Bank)";
//     this.displayPersonalSb = false;
//     this.displayPersonalUbs = false;
//     this.displayPersonalBig = false;
    

//     this.ncbAccount = brokerAccounts[i].Account;
//     this.bodyData[5] = PageSection.ncb;
//     this.bodyData[6] = 1;
//     this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

//       let taData = JSON.parse(data._body);
//       let taBody;

//       if(taData.body.length > 2) { // length > {}
//       taBody = JSON.parse(taData.body);
//       if(taBody.data.length > 0){
//       this.ncbDesc = taBody.data[0].Body_Desc;
//       this.displayPersonalNcb = true;
//       }else {
//         this.displayPersonalNcb = false;
//         this.ncbDesc = "";
//       }
//     } 
    
//     this.bodyData[5] = PageSection.ncbDoc;
//     this.bodyData[6] = 0;         

//        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

//         let taDocData = JSON.parse(data._body);
//         let taDocBody;

//         if(taDocData.body.length > 2) { // length > {}
//         taDocBody = JSON.parse(taDocData.body);
//         if(taDocBody.data.length > 0){
//           this.ncbDoc = taDocBody.data;

//           for(let i=0; i<this.ncbDoc.length; i++){
//             this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
//           }
//         }else {
//           this.displayPersonalNcb = false;
//           this.ncbDoc = null;
//         }
//       }
//        });
//        this.ncbLoaded = true;
//        this.resourceLoading = false;
//     });

//   }else if(broker.indexOf('/')){
//     this.brokerHeader = broker;
//     let splittedBroker = broker.split('/');
//     for(let i = 0; i < splittedBroker.length; i++){
//       if(splittedBroker[i] == "MS"){
//         this.getGlobalID();
//         this.bodyData[5] = PageSection.sb;
//         this.bodyData[6] = 1;
//         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
          
//             let msData = JSON.parse(data._body);
//             let msBody;
  
//             if(msData.body.length > 2) { // length > {}
//             msBody = JSON.parse(msData.body);
  
//               if (msBody.data.length > 0){
//                 this.sbDesc = msBody.data[0].Body_Desc;
//                 this.displayPersonalSb = true;
//                 }
//               else {
//                 this.displayPersonalSb = false;
//                 this.sbDesc = "";
//               } 
//             }
  
  
//           this.bodyData[5] = PageSection.sbDoc;
//           this.bodyData[6] = 0;
  
//           this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
//           let msDocData = JSON.parse(data._body);
//           let msDocBody;
          
//           if(msDocData.body.length > 2) { // length > {}
//                           msDocBody = JSON.parse(msDocData.body);
//                           if(msDocBody.data.length > 0) {
//                             this.sbDoc = msDocBody.data;
          
//                             for(let i=0; i<this.sbDoc.length; i++){
//                               this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
//                             }
//                           }
//                           else{
//                             this.sbDoc = null;
//                           }
//                         }
                    
//           });
//           this.sbLoaded = true;
//       this.resourceLoading = false;
//         });
  
//       }else if(splittedBroker[i] == "UBS"){
        
//       this.ubsAccount = brokerAccounts[i].Account;
//       this.bodyData[5] = PageSection.ubs;
//       this.bodyData[6] = 1;
      
  
//       this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
//         let ubsData = JSON.parse(data._body);
//         let ubsBody;
  
//         if(ubsData.body.length > 2) { // length > {}
//         ubsBody = JSON.parse(ubsData.body);
//         if(ubsBody.data.length > 0) {
//           this.ubsDesc = ubsBody.data[0].Body_Desc;
//           this.displayPersonalUbs = true;
//         }
//         else {
//         this.displayPersonalUbs = false;
//           this.ubsDesc = "";
//         }
//       }
  
//       this.bodyData[5] = PageSection.ubsDoc;
//       this.bodyData[6] = 0;
      
//       this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
//         let ubsDocData = JSON.parse(data._body);
//         let ubsDocBody;
        
//         if(ubsDocData.body.length > 2) { // length > {}
//             ubsDocBody = JSON.parse(ubsDocData.body);
//             if(ubsDocBody.data.length > 0){
//               this.ubsDoc = ubsDocBody.data;
      
//               for(let i=0; i<this.ubsDoc.length; i++){
//                 this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
//               }
//             }
//             else{
//               this.ubsDoc = null;
//             }
//           }
//       });
//       this.ubsLoaded = true;
//       this.resourceLoading = false;
//       });
//       }else if(splittedBroker[i] == "BIG"){
        
//         this.bigAccount = brokerAccounts[i].Account;
//         this.bodyData[5] = PageSection.big;
//         this.bodyData[6] = 1;
//         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        
//           let bigData = JSON.parse(data._body);
//           let bigBody;
  
//           if(bigData.body.length > 2) { // length > {}
//           bigBody = JSON.parse(bigData.body);
//           if(bigBody.data.length > 0){
//             this.bigDesc = bigBody.data[0].Body_Desc;
//             this.displayPersonalBig = true;
//           }else {
//             this.displayPersonalBig = false;
//             this.bigDesc = "";
//           }
//         }
//         this.bigLoaded = true;
//       this.resourceLoading = false;
//         });
  
//       }else if(splittedBroker[i] == "TA"){
       
//         this.displayPersonalSb = false;
//         this.displayPersonalUbs = false;
//         this.displayPersonalBig = false;
        
  
//         this.ncbAccount = brokerAccounts[i].Account;
//         this.bodyData[5] = PageSection.ncb;
//         this.bodyData[6] = 1;
//         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
//           let taData = JSON.parse(data._body);
//           let taBody;
  
//           if(taData.body.length > 2) { // length > {}
//           taBody = JSON.parse(taData.body);
//           if(taBody.data.length > 0){
//           this.ncbDesc = taBody.data[0].Body_Desc;
//           this.displayPersonalNcb = true;
//           }else {
//             this.displayPersonalNcb = false;
//             this.ncbDesc = "";
//           }
//         } 
        
//         this.bodyData[5] = PageSection.ncbDoc;
//         this.bodyData[6] = 0;         
  
//            this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
  
//             let taDocData = JSON.parse(data._body);
//             let taDocBody;
  
//             if(taDocData.body.length > 2) { // length > {}
//             taDocBody = JSON.parse(taDocData.body);
//             if(taDocBody.data.length > 0){
//               this.ncbDoc = taDocBody.data;
  
//               for(let i=0; i<this.ncbDoc.length; i++){
//                 this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
//               }
//             }else {
//               this.displayPersonalNcb = false;
//               this.ncbDoc = null;
//             }
//           }
//            });
//            this.ncbLoaded = true;
//            this.resourceLoading = false;
//         });
//     }
//   }
//   (<any>$("#brokerContact")).modal({
//     backdrop: 'static',
//     keyboard: false
//   });
  
//   }
// }
// }

// });


    


//   }
//   fileImage(filyType){
//     if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
//       this.fileTypeImagePath = '/assets/pdf.svg';
//       this.fileTypeAlt = "PDF File"
//       return true;
//     }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
//       this.fileTypeImagePath = '/assets/zip.svg';
//       this.fileTypeAlt = "ZIP File"
//       return true;
//     }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
//       this.fileTypeImagePath = '/assets/xls.svg';
//       this.fileTypeAlt = "XLS File"
//       return true;
//     }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
//       this.fileTypeImagePath = '/assets/docx.svg';
//       this.fileTypeAlt = "DOC File"
//       return true;
//     }
//    }

}


