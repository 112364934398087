
import {refCount, publish} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToolaccessService } from './api/ToolaccessService';

@Injectable()
export class EjetaccessService {

  private _toolAccess: boolean = this.toolAccessService.getUserToolAccess();
  private _access: BehaviorSubject<boolean> = new BehaviorSubject(this._toolAccess);
  private _ejetError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _sumReportError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _dataAppError: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly access$: Observable<boolean> = this._access.asObservable().pipe(publish(),refCount(),);
  readonly ejetError$: Observable<boolean> = this._ejetError.asObservable().pipe(publish(),refCount(),);
  readonly sumReportError$: Observable<boolean> = this._sumReportError.asObservable().pipe(publish(),refCount(),);
  readonly dataAppError$: Observable<boolean> = this._dataAppError.asObservable().pipe(publish(),refCount(),);

  constructor(
    private toolAccessService: ToolaccessService
  ) { }

  /* istanbul ignore next */
  setEjetAccess(state: boolean): void {
    this._access.next(state);
  }

  /* istanbul ignore next */
  setEjetError(state: boolean): void {
    this._ejetError.next(state);
  }

  /* istanbul ignore next */
  setSumReportError(state: boolean): void {
    this._sumReportError.next(state);
  }

  /* istanbul ignore next */
  setDataAppError(state: boolean): void {
    this._dataAppError.next(state);
  }

}
