import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { HoldingProgramDetailsService } from './HoldingProgramDetailsService';
import { DatePipe } from '@angular/common';
import { BrokerType } from '../services/enumerations/enumerations';
import { MatInput } from '@angular/material/input';
import * as moment from 'moment'
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-holdingprogramdetailspage',
  templateUrl: './holdingprogramdetailspage.component.html',
  styleUrls: ['./holdingprogramdetailspage.component.css']
})

export class HoldingprogramdetailspageComponent implements OnInit {
  // @ViewChild('dateFromForm') dateFromForm: NgForm;
  // @ViewChild('dateFrom') dateFromInput: NgbDatepicker;

  @ViewChild('dateFromInput', {
    read: MatInput
  }) dateFromInput: MatInput; 

  @ViewChild('dateToInput', {
    read: MatInput
  })  dateToInput: MatInput;

  displayedColumnsVeip: string[] = ['Date', 'PurchasePriceUSD', 'PurchasedShares' , 'ValueUSD', 'ValueLoc','Disposed', 'RemainingBroker'];
  veipdataSource: any;

  displayedColumnsEsppSalesHist: string[] = ['Date', 'Type', 'Shares', 'PriceUSD', 'ProceedsUSD', 'PurchaseValueUSD'];
  dataSourceEsppSalesHist: any;

  displayedColumnsVeipSalesHist: string[] = ['Date', 'Type', 'Shares', 'PriceUSD', 'ProceedsUSD', 'PurchaseValueUSD'];
  dataSourceVeipSalesHist: any;

  loading: boolean = true;
  datePipe = new DatePipe('en-US');
  dateFrom: string = "' '";
  dateTo: string = "' '";

  dateFromChecker: any;
  dateToChecker: any;
  dateErrorChecker: boolean = false;

  awardNameRSU: boolean = false;
  awardNameESPP: boolean = false;
  awardNameVEIP: boolean = false;
  brokerType: any;
  noSalesHistory: boolean = false;

  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsNextRelease: any;
  detailsSharesReleasing: any;
  detailsGrantAccepted: any;
  detailsUnreleased: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;

  // ESPP variables - Start
  offeringPeriod: string;
  contribution: number;
  totalContributionUSD: number;
  totalContributionLoc: number;
  purchasePrice: number;
  purchasePriceUSD: number;
  fmvAtPurchaseUSD: number;
  exchangeRateLoc: number;
  taxWithholdingMethod: string;
  account: string;
  displayAccount: boolean = false;
  sharesPurchased: number;
  withholdingForTaxes: number;
  fractionalShares: number;
  soldTransferred: number;
  netRemainAtBroker: number;
  localCurrencyCd: string;
  grossSharesPurchased: number;
  sharesWithheldTaxShares: string;
  sharesWithheldTaxLoc: string;
  sharesWithheldTaxUSD: string;
  fractionalSharesRefundedShares: number;
  fractionalSharesRefundedUSD: number;
  fractionalSharesRefundedLoc: number;
  netSharesPurchaseShares: number;
  netSharesPurchaseUSD: number;
  netSharesPurchaseLoc: number;
  xFmvAtPurchaseUSD: number;
  xFmvAtPurchaseLoc: number;
  fullValOfSharesUSD: number;
  fullValOfSharesLoc: number;
  totalDiscountUSD: number;
  totalDiscountLoc: number;
  actualTax: number;
  displayActualTax: boolean = false;

  datePeriod: number = 4;
  disableDatePicker: boolean = true;
  esppSalesHistShares: any = 0;
  esppSalesHistPriceUSD: any = 0;
  esppSalesHistProceedsUSD: any = 0;
  esppSalesHistPurchaseValueUSD: any = 0;
  // ESPP variables - End
  showAgreementTab: boolean = false;

  // VEIP variables - Start
  programyear: string;
  //contribution: number; //already in ESPP
  totalcontributionloc: number;
  totalpurchasevalueloc: number;
  totalsharespurchased: number;
  soldtransferred: number;
  netremainingatbroker: number;
  localcurrencycd: string;
  // date: string;
  // purchasepriceusd: number;
  // purchasedshares: number;
  // valueusd: number;
  // valueloc: number;
  // disposed: number;
  // remainingbroker: number;
  // localcurrencycd: string; //already in ESPP
  veiplocalcurrencycd: string;
  monthlycontributionusd: number;
  monthlycontributionloc: number;
  priormonthscarryoverusd: number;
  priormonthscarryoverloc: number;
  totalcontributionusd: number;
  detailstotalcontributionloc: number;
  purchasecostusd: number;
  purchasecostloc: number;
  carryovertonextmonthusd: number;
  carryovertonextmonthloc: number;
  exchangerate: number;

  veipSalesHistShares: any = 0;
  veipSalesHistPriceUSD: any = 0;
  veipSalesHistProceedsUSD: any = 0;
  veipSalesHistPurchaseValueUSD: any = 0;

  veipIsDisplayRefund: number;

  // VEIP variables - End

  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = this.user.CurrencyCd.replace(/\s/g, "");
  salesTableLoading: boolean;
  veipTableLoading: boolean;

  constructor(private router: Router, private app: AppComponent, private globals: Globals, private holdingProgramDetails: HoldingProgramDetailsService) {
    this.app.appNavHeaderVisible = true;
    this.app.appFooterVisible = true;
  }

  ngOnInit() {
    this.getProgramDetails();
  }

  navToPage(strLoc) {
    this.router.navigate([strLoc]);
  }

  getProgramDetails() {
    this.checkAwardName(this.programDetails);

    this.awardName = this.programDetails.AwardName;
    this.detailsAvailableToSell = this.programDetails.AvailableToSell;
    this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
    this.detailsBroker = this.programDetails.BrokerId;
    this.detailsNextRelease = this.programDetails.NextRelease;
    this.detailsSharesReleasing = this.programDetails.SharesReleasing;
    this.detailsGrantAccepted = this.programDetails.GrantAccepted;
    this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
    this.detailsReleased = this.programDetails.ReleasedExercisableQty;
    this.detailsExpiration = this.programDetails.Expiration;
    this.detailsForfeited = this.programDetails.Forfeited;
  }

  checkAwardName(details) {
    if(details.AwardName.indexOf("ESPP") > -1) {
      this.awardNameESPP = true;

      this.getESPPAwardDetails(this.user.OptNum, details.OfferNum); 
      this.getESPPAwardSalesHist(details.OfferNum);
    } else if(details.AwardName.indexOf("VEIP") > -1) {
      this.awardNameVEIP = true;
      this.getVEIPAwardDetails(this.user.OptNum, details.OfferNum);
      this.getVEIPAwardSalesHist(details.OfferNum, this.dateFrom, this.dateTo);
                  
    } else if(details.AwardName.indexOf("RSU") !== -1) {
      this.awardNameRSU = true;
      // this.loading = false;
    } 
  }

  getESPPAwardDetails(optNum, offerNum) {
    this.holdingProgramDetails.getESPPdetails(optNum, offerNum).pipe(map(res => res)).subscribe(data => {
      let esppDetails = data[0][0];
      this.offeringPeriod = esppDetails.OfferingPeriod;
      this.contribution = esppDetails.Contribution;
      this.totalContributionUSD = esppDetails.TotalContributionUSD;
      this.purchasePriceUSD = esppDetails.PurchasePriceUSD;
      this.fmvAtPurchaseUSD = esppDetails.FMVAtPurchaseUSD;
      this.exchangeRateLoc = esppDetails.ExchangeRateLoc;
      if(esppDetails.TaxWithholdingMethod == "Payroll Withholding") {
        this.sharesWithheldTaxShares = "";
        this.sharesWithheldTaxUSD = "Refer to Earnings Statement";
        this.sharesWithheldTaxLoc = "Refer to Earnings Statement";
      } else {
        this.sharesWithheldTaxShares = esppDetails.SharesWithheldTax;
        this.sharesWithheldTaxUSD = esppDetails.SharesWithheldTax_ValueUSD;
        this.sharesWithheldTaxLoc = esppDetails.SharesWithheldTax_ValueLoc;
      }
      this.taxWithholdingMethod = esppDetails.TaxWithholdingMethod;
      if(esppDetails.Account !== "") { // checks the account if its empty 
        this.displayAccount = true;
      }
      this.account = esppDetails.Account;
      this.sharesPurchased = esppDetails.SharesPurchased;
      this.withholdingForTaxes = esppDetails.WithholdingForTaxes;
      this.fractionalShares = esppDetails.FractionalShares;
      this.soldTransferred = esppDetails.SoldTransferred;
      this.netRemainAtBroker = esppDetails.NetRemainingAtBroker;
      this.localCurrencyCd = esppDetails.LocalCurrencyCd;
      this.totalContributionLoc = esppDetails.TotalContributionLoc;
      this.grossSharesPurchased = esppDetails.GrossSharesPurchased;
      this.fractionalSharesRefundedShares = esppDetails.FractionalSharesRefunded;
      this.fractionalSharesRefundedUSD = esppDetails.FractionalSharesRefunded_ValueUSD;
      this.fractionalSharesRefundedLoc = esppDetails.FractionalSharesRefunded_ValueLoc;
      this.netSharesPurchaseShares = esppDetails.NetSharesPurchased;
      this.netSharesPurchaseUSD = esppDetails.NetSharesPurchasedShares_ValueUSD;
      this.netSharesPurchaseLoc = esppDetails.NetSharesPurchasedShares_ValueLoc;
      this.xFmvAtPurchaseUSD = esppDetails.xFMVAtPurchaseUSD;
      this.xFmvAtPurchaseLoc = esppDetails.xFMVAtPurchaseLoc;
      this.fullValOfSharesUSD = esppDetails.FullValueOfSharesUSD;
      this.fullValOfSharesLoc = esppDetails.FullValueOfSharesLoc;
      this.totalDiscountUSD = esppDetails.TotalDiscount_ValueUSD;
      this.totalDiscountLoc = esppDetails.TotalDiscountValueLoc;
      if(offerNum >= 42) {
        this.displayActualTax = true;
      }
      this.actualTax = esppDetails.ActualTax;

      this.loading = false;
    });
  }

  getVEIPAwardDetails(optNum, offerNum) {
    this.holdingProgramDetails.getVEIPdetails(optNum, offerNum).pipe(map(res => res)).subscribe(data => {     

      let veipsummarydetails = data[0][0];    

      this.programyear = veipsummarydetails.ProgramYear;
      this.contribution = veipsummarydetails.Contribution;
      this.netremainingatbroker = veipsummarydetails.NetRemainingAtBroker;
      this.totalcontributionloc = veipsummarydetails.TotalContributionLoc;
      this.totalpurchasevalueloc = veipsummarydetails.TotalPurchaseValueLoc;
      this.totalsharespurchased = veipsummarydetails.TotalSharesPurchased;
      this.soldtransferred = veipsummarydetails.SoldTransferred;
      this.localcurrencycd = veipsummarydetails.LocalCurrencyCd;
      // this.localcurrencycd = 'EUR';
      
      let veipsummarydetails_array1 = data[2][0];

      this.carryovertonextmonthloc = veipsummarydetails_array1.CarryOverToNextMonthLoc;
      this.carryovertonextmonthusd = veipsummarydetails_array1.CarryOverToNextMonthUSD;
      this.exchangerate = veipsummarydetails_array1.ExchangeRate;
      this.monthlycontributionloc = veipsummarydetails_array1.MonthlyContributionLoc;
      this.monthlycontributionusd = veipsummarydetails_array1.MonthlyContributionUSD;
      this.priormonthscarryoverloc = veipsummarydetails_array1.PriorMonthsCarryOverLoc;
      this.priormonthscarryoverusd = veipsummarydetails_array1.PriorMonthsCarryOverUSD;
      this.purchasecostloc = veipsummarydetails_array1.PurchaseCostLoc;
      this.purchasecostusd = veipsummarydetails_array1.PurchaseCostUSD;
      this.detailstotalcontributionloc = veipsummarydetails_array1.TotalContributionLoc;
      this.totalcontributionusd = veipsummarydetails_array1.TotalContributionUSD;

      this.veipdataSource = data[1];
      this.veipIsDisplayRefund = data[2][0].IsDisplayRefund;

      this.loading = false;
    });
  }

  commafy(num: any, value?: number) {
    // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value > 0) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  }

  selectDatePeriod(period) {
    this.datePeriod = period;

    if(this.datePeriod == 1) { // year to date
      this.disableDatePicker = true;
      this.dateFromInput.value = '';
      this.dateToInput.value = '';

      let currentDate = new Date();
      let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      this.dateTo = "'" +dateToEvent + "'";
      currentDate.setMonth(0, 1);
      let yearToDate = currentDate;
      let dateFromEvent = this.datePipe.transform(yearToDate, "MM'/'dd'/'yyyy");
      this.dateFrom = "'" +dateFromEvent + "'";
    } else if(this.datePeriod == 2) { // last year
      this.disableDatePicker = true;
      this.dateFromInput.value = '';
      this.dateToInput.value = '';

      let currentDate = new Date();
      let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      this.dateTo = "'" +dateToEvent + "'";
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      let lastYear = currentDate;
      let dateFromEvent = this.datePipe.transform(lastYear, "MM'/'dd'/'yyyy");
      this.dateFrom = "'" +dateFromEvent + "'";
    } else if(this.datePeriod == 3) { // last 3 years
      this.disableDatePicker = true;
      this.dateFromInput.value = '';
      this.dateToInput.value = '';

      let currentDate = new Date();
      let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      this.dateTo = "'" +dateToEvent + "'";
      currentDate.setFullYear(currentDate.getFullYear() - 3);
      let lastThreeYear = currentDate;
      let dateFromEvent = this.datePipe.transform(lastThreeYear, "MM'/'dd'/'yyyy");
      this.dateFrom = "'" +dateFromEvent + "'";
    } else if(this.datePeriod == 4) { // all dates
      this.disableDatePicker = true;
      this.dateFromInput.value = '';
      this.dateToInput.value = '';
      this.dateFrom = "' '";
      this.dateTo = "' '";
    } else { // period = 5, custom
      this.disableDatePicker = false;
      this.dateFrom = "' '";
      this.dateTo = "' '";
    }
  }

  selectDateFrom(event) {
    let dateEvent = event.month + '/' + event.day + '/' + event.year;
    // let dateEvent = this.datePipe.transform(event, "MM'/'dd'/'yyyy");
    this.dateFromChecker = dateEvent;
    this.dateFrom = "'" +dateEvent + "'"; 
  }

  selectDateTo(event) {
    let dateEvent = event.month + '/' + event.day + '/' + event.year;
    // let dateEvent = this.datePipe.transform(event, "MM'/'dd'/'yyyy");
    this.dateToChecker = dateEvent;
    this.dateTo = "'" +dateEvent + "'";
  }

  viewESPPAwardSalesHist() {
    this.dateErrorChecker = false;
    this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
    this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
    if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
      this.dateErrorChecker = true;
      // this.dateToChecker = "";
      // this.dateFromChecker = "";
    }
    else{
    this.salesTableLoading = true;
    this.esppSalesHistShares = 0;
    this.esppSalesHistPriceUSD = 0;
    this.esppSalesHistProceedsUSD = 0;
    this.esppSalesHistPurchaseValueUSD = 0;
    this.getESPPAwardSalesHist(this.programDetails.OfferNum);
    }
  }

  getESPPAwardSalesHist(offerNum) {
    this.holdingProgramDetails.getEsppSalesHistory(this.user.OptNum, offerNum, this.datePeriod, this.dateFrom, this.dateTo).pipe(map(res => res)).subscribe(data => {
      if(data[1].length == 0) {
        this.dataSourceEsppSalesHist = data[1];
        this.brokerType = BrokerType[data[0][0].BrokerCd];
        this.noSalesHistory = true;
      } else {
        this.noSalesHistory = false;
        this.brokerType = BrokerType[data[0][0].BrokerCd];
        this.dataSourceEsppSalesHist = data[1];

        for(let i = 0; i < data[1].length; i++) {
          this.esppSalesHistShares += (data[1][i].Shares*1);
          if(data[1][i].PriceUSD != "-") {
            this.esppSalesHistPriceUSD += (data[1][i].PriceUSD*1);
          }
          if(data[1][i].PriceUSD != "-") {
            this.esppSalesHistProceedsUSD += (data[1][i].ProceedsUSD*1);
          }
          this.esppSalesHistPurchaseValueUSD += (data[1][i].PurchaseValueUSD*1);
        }
      }

      this.loading = false;
      this.salesTableLoading = false;
    });
  }

  viewVEIPAwardSalesHist() {
    this.dateErrorChecker = false;
    this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
    this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
    if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
      this.dateErrorChecker = true;
      // this.dateToChecker = "";
      // this.dateFromChecker = "";
    }
    else{
    this.salesTableLoading = true;
    this.veipSalesHistShares = 0;
    this.veipSalesHistPriceUSD = 0;
    this.veipSalesHistProceedsUSD = 0;
    this.veipSalesHistPurchaseValueUSD = 0;
    this.getVEIPAwardSalesHist(this.programDetails.OfferNum, this.dateFrom, this.dateTo);
    // this.dateToChecker = "";
    // this.dateFromChecker = "";
    }
  }

  getVEIPAwardSalesHist(offerNum, dateFrom: string, dateTo: string) {
    this.holdingProgramDetails.getVeipSalesHistory(this.user.OptNum, offerNum, this.datePeriod, dateFrom, dateTo).pipe(map(res => res)).subscribe(data => {
      if(data[1].length == 0) {
        this.noSalesHistory = true;
        this.brokerType = BrokerType[data[0][0].BrokerCd];
        this.dataSourceVeipSalesHist = data[1];
      } else {
        
      this.dataSourceVeipSalesHist = data[1];
      this.brokerType = BrokerType[data[0][0].BrokerCd];

      for(let i = 0; i < data[1].length; i++) {
        this.veipSalesHistShares += (data[1][i].Shares*1);
        if(data[1][i].PriceUSD != "-") {
          this.veipSalesHistPriceUSD += (data[1][i].PriceUSD*1);
        }
        if(data[1][i].PriceUSD != "-") {
          this.veipSalesHistProceedsUSD += (data[1][i].ProceedsUSD*1);
        }
        this.veipSalesHistPurchaseValueUSD += (data[1][i].PurchaseValueUSD*1);
      }
      this.noSalesHistory = false;
    }
      this.loading = false;
      this.salesTableLoading = false;
    });
    
  }

  getCarryOverDetails(row){
    this.veipTableLoading = true;
    let purchasedate = "'" + this.datePipe.transform(row.Date, "MM'/'dd'/'yyyy") + "'";
    // purchasedate.Date;
    this.holdingProgramDetails.getVeipCarryOverAPI(this.user.OptNum, this.programDetails.OfferNum, purchasedate).pipe(map(res => res)).subscribe(data => {
    this.monthlycontributionusd = data[0][0].MonthlyContributionUSD;
    this.monthlycontributionloc = data[0][0].MonthlyContributionLoc;
    this.priormonthscarryoverusd = data[0][0].PriorMonthsCarryOverUSD;
    this.priormonthscarryoverloc = data[0][0].PriorMonthsCarryOverLoc;
    this.totalcontributionusd = data[0][0].TotalContributionUSD;
    this.detailstotalcontributionloc = data[0][0].TotalContributionLoc;
    this.purchasecostusd = data[0][0].PurchaseCostUSD;
    this.purchasecostloc = data[0][0].PurchaseCostLoc;
    this.carryovertonextmonthusd = data[0][0].CarryOverToNextMonthUSD;
    this.carryovertonextmonthloc = data[0][0].CarryOverToNextMonthLoc;
    this.exchangerate = data[0][0].ExchangeRate;

    this.veipIsDisplayRefund = data[0][0].IsDisplayRefund;
    this.veipTableLoading = false;
    });
  }
}