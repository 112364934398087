import { Component, OnInit} from '@angular/core';
import { Globals } from '../../globals';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { HoldingProgramDetailsRSUIPOService } from '.././HoldingProgramDetailsRSUIPOService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { MultipleGrantsService } from '../../multiplegrants/MultipleGrantsService';
import { PageSection, FileType, MyHoldingsPagesResources} from '../../services/enumerations/enumerations';
import { StaticContentService } from '../../services/StaticContentService';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-iporsu-learn-more',
  templateUrl: './iporsu-learn-more.component.html',
  styleUrls: ['./iporsu-learn-more.component.css']
})
export class IporsuLearnMoreComponent implements OnInit {

  rsuipoCommonQuestionsContent: any; 
   rsuipoGuideBooksContent: any; 
   rsuipoShareIncentiveContent: any;
   rsuipoCountAwardCOntent: any;
   rsuipoToolsandRepContent: any;
   bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
   bodyLearnMoreData: any = JSON.parse(sessionStorage.getItem("Body Data"));
   allCountryFormsContent: string;
   stepTwoContent: object;
   grantCountryCode: any;
   grantGroupId: any;
   rsuCommonQuestion: any;
   FrenchQualified: any;
   grantGroupIdProgramDetails: any;
   groupingId: any;
   errorCommonQuestion: boolean;
   errorGuideBooks: boolean;
   errorShareIncentive: boolean;
   errorCountryAward: boolean;
   errorToolsAndReports: boolean;

   LocalGroupDetailId7: any;
  LocalGroupDetailId8: any;
  LocalGroupDetailId5: any;
  localCurrency: any;

  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;
LearnMoreLoading: boolean = true;

currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));

marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  user: any = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = sessionStorage.getItem(this.globals.selectedCurrencyCd);
  errorLocation: string = " + [Component: Rsuipodetails, Method: methodName]";
  loading: boolean;

  commonQAccordionCount: number = 1; // 1 accordion is initially expanded

  constructor(private globals: Globals, 
    private app: AppComponent, private router: Router, 
    private HoldingProgramDetailsRSUIpoService: HoldingProgramDetailsRSUIPOService,
    private errorNotificationService: ErrorNotificationService,
    private multiplegrantsService: MultipleGrantsService,
    private staticContentService: StaticContentService) { }

  ngOnInit() {
    // BACK BUTTON BUG - START
    sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } 
    // BACK BUTTON BUG - END

    if(this.currencyCd != null) {
      this.currencyCd = this.currencyCd.split('"').join("");
    } else {
      let profileInfo = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
      this.currencyCd = profileInfo.CurrencyCd;
    }
    this.getFromCodeDetail();
    this.getAllCountryForms();
  }

      /*****************************************************************************************
   * Method Name: getFromCodeDetail                                                                 
   * Description: Get GroupingId from CodeDetails API to be used in Common Question conditions  
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/25/2019           Iris Jane R. Manloloyo (IJM)         Initial Draft
   *****************************************************************************************/

getFromCodeDetail(){
  try {
    this.HoldingProgramDetailsRSUIpoService.getDataFromCodeDetail(7).pipe(map(res => res)).subscribe(data => {
      
        let body = data;

        data != undefined ? this.LocalGroupDetailId7 = body[0] : "";
      
        this.HoldingProgramDetailsRSUIpoService.getDataFromCodeDetail(8).pipe(map(res => res)).subscribe(data => { 
          let body = data;

          data != undefined ? this.LocalGroupDetailId8 = body[0] : "";

          this.HoldingProgramDetailsRSUIpoService.getDataFromCodeDetail(5).pipe(map(res => res)).subscribe(data => { 
            let body = data;
  
            data != undefined ? this.LocalGroupDetailId5 = body[0] : "";

            this.getrsuCommonQuestions();
          });
        });
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFromCodeDetail");
    error.message += errorLocation;
    throw error;
  }
}

    /*****************************************************************************************
   * Method Name:   getrsuCommonQuestions() {
                                                                 
   * Description: Gets the content for common questions from my resource api if the user hasOptionsAward
   *              and GrantCountryCd= CAN   
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/24/2019           Iris Jane R. Manloloyo (IJM)         Initial Draft
   *****************************************************************************************/
  getrsuCommonQuestions() {
    try {
      if (this.programDetails.GrantNum == null){

        let temp = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
        this.programDetails = temp[0];
      }

      // BACK BUTTON BUG - START
      sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
      let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
      let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

      if(backUrl) {
        if(detailsStorage.length > 0) {
          this.programDetails = detailsStorage[detailsStorage.length - 1];
        }
      } 
      // BACK BUTTON BUG - END

      this.grantCountryCode = this.programDetails.GrantCountryCd; 
      this.grantGroupIdProgramDetails = this.programDetails.GrantGroupId;
      let awardName = this.programDetails.AwardName;
      this.bodyData[6] = 1;
  
      if (this.programDetails.GrantNum == 0){
        this.FrenchQualified = 0;
      } else {
      this.multiplegrantsService.getRSUAgreementInfo(this.programDetails.GrantNum).pipe(map(res => res)).subscribe(data => { 
        try {
          let agreementContent = data[0][0]; 
          this.FrenchQualified = agreementContent.FrenchQualifiedInd;
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
        error.error.message += errorLocation;
        throw error.error;
      });
    }

    let locExchageRate = this.LocalGroupDetailId7.find(i => i.GroupingValue == this.programDetails.GrantGroupId);      
    locExchageRate == undefined ? locExchageRate = [] : "";         

    this.bodyData[5] = "";

    if(this.programDetails.GrantCountryCd == "DNK"){  
      if(awardName.indexOf("RSU IPO") != -1 || awardName.indexOf("IPO RSU") != -1){
        this.bodyData[5] = PageSection.IPORSUDenmark;    
      }    
      else if(locExchageRate == this.programDetails.GrantGroupId && (awardName.indexOf("RSU") != -1)){
          this.bodyData[5] = PageSection.DenmarkBonusRSU;     
      }else if(awardName.indexOf("RSU") != -1){
          this.bodyData[5] = PageSection.DenmarkRSU;     
        }
        else {
          this.bodyData[5] = PageSection.BonusShareRSU;  
        }      
    }else if(this.FrenchQualified == 1){
      this.user.JobCd == 10000025 ? this.bodyData[5] = PageSection.GrantFRQSnERSU : this.bodyData[5] = PageSection.GrantFRQSMRRSU;   
    }  

    this.bodyData[5] == "" ? this.bodyData[5] = PageSection.RSUNullContent : "";        

        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          try {
            let rsuCommonQuestionsContents = JSON.parse(data.body);
            
            if(rsuCommonQuestionsContents.data) { // length > {}
            let rsuCommonBody = rsuCommonQuestionsContents.data;
            
            if(rsuCommonBody.length > 0) {
              this.rsuipoCommonQuestionsContent = rsuCommonBody;
            } else {
              this.rsuipoCommonQuestionsContent = null;
            }
          }
            this.getRSUIPOGuideBooks();
          } catch(error) {
            let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
          error.error.message += errorLocation;
          throw error.error;
        });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getrsuCommonQuestions");
      error.message += errorLocation;
      throw error;
    }
  }

  
  /*****************************************************************************************
   * Method Name: getGuideBooks                                                                 
   * Description: Gets the content for guide books from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getRSUIPOGuideBooks(){
    try {
      this.bodyData[5] = PageSection.rsuGuidebooks;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let guideBooksData = JSON.parse(data.body);
  
          if(guideBooksData.data) { // length > {}
            let guideBooksBody = guideBooksData.data;
            
            if(guideBooksBody.length > 0) {
              this.rsuipoGuideBooksContent = guideBooksBody[0].Body_Desc;
            } else {
              this.rsuipoGuideBooksContent = null;
            }
          }
          this.getRsuIPOShareIncentive();
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOGuideBooks");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOGuideBooks");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOGuideBooks");
      error.message += errorLocation;
      throw error;
    }
  }
  
  /*****************************************************************************************
   * Method Name: getRsuIPOShareIncentive                                                                 
   * Description: Gets the content for plan document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getRsuIPOShareIncentive() {
    try {
      this.bodyData[5] = PageSection.rsuShareIncentive;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let shareIncenDocumentData = JSON.parse(data.body);
  
          if(shareIncenDocumentData.data) { // length > {}
            let optDocumentBody = shareIncenDocumentData.data;
    
            if(optDocumentBody.length > 0) {
              this.rsuipoShareIncentiveContent = optDocumentBody[0].Body_Desc;
            } else {
              this.rsuipoShareIncentiveContent = null;
            }
          }
          this.getRSUIPOCountryAward();
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRsuIPOShareIncentive");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRsuIPOShareIncentive");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRsuIPOShareIncentive");
      error.message += errorLocation;
      throw error;
    }
  }


  /*****************************************************************************************
   * Method Name: getProspectusDocument                                                                 
   * Description: Gets the content for prospectus document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getRSUIPOCountryAward() {
    try {
      this.bodyData[5] = PageSection.rsuCountryAward;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let CountryAwardDocData = JSON.parse(data.body);
  
          if(CountryAwardDocData.data) { // length > {}
            let countryAwardDocBody = CountryAwardDocData.data;
    
            if(countryAwardDocBody.length > 0) {
              this.rsuipoCountAwardCOntent = countryAwardDocBody[0].Body_Desc;
            } else {
              this.rsuipoCountAwardCOntent = null;
            }
          }
          this.getRSUIPOToolsAndReports();
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOCountryAward");
         error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOCountryAward");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOCountryAward");
      error.message += errorLocation;
      throw error;
    }
  } 

  
  /*****************************************************************************************
   * Method Name: getToolsAndReports                                                                 
   * Description: Gets the content for tools and reports from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getRSUIPOToolsAndReports() {
    try {
      this.bodyData[5] = PageSection.rsuToolsAndReports;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let toolsAndReportsData = JSON.parse(data.body);
  
          if(toolsAndReportsData.data) { // length > {}
            let toolsAndReportsBody = toolsAndReportsData.data;
            
            if(toolsAndReportsBody.length > 0) {
              this.rsuipoToolsandRepContent = toolsAndReportsBody;

              for(let i=0; i<this.rsuipoToolsandRepContent.length; i++){
                if(this.rsuipoToolsandRepContent[i]["Body_Desc"].indexOf("<u>") != -1){
                  this.rsuipoToolsandRepContent[i]["Body_Desc"] = this.rsuipoToolsandRepContent[i]["Body_Desc"].split("<u>").join("<span style='text-decoration: underline;'>");
                  this.rsuipoToolsandRepContent[i]["Body_Desc"] = this.rsuipoToolsandRepContent[i]["Body_Desc"].split("</u>").join("</span>");
                }
                if(this.rsuipoToolsandRepContent[i]["Body_Desc"].indexOf("<b>") != -1){
                  this.rsuipoToolsandRepContent[i]["Body_Desc"] = this.rsuipoToolsandRepContent[i]["Body_Desc"].split("<b>").join("<span style='font-weight: bold;'>");
                  this.rsuipoToolsandRepContent[i]["Body_Desc"] = this.rsuipoToolsandRepContent[i]["Body_Desc"].split("</b>").join("</span>");
                }
              } 
            } else {
              this.rsuipoToolsandRepContent = null;
            }
            
            setTimeout(() => { this.inContentIcon() }, 500);
            this.LearnMoreLoading = false;
          }
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOToolsAndReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOToolsAndReports");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getRSUIPOToolsAndReports");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let srcElement: HTMLImageElement = event.srcElement;
      let id: string;
      let reportId: object;

      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != ""){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          // href = href.split("/Default.aspx")[0];
          if(href.indexOf("reportid") != -1) {
            id = href.substring(href.lastIndexOf("=") + 1);
            reportId = {'ReportTypeId': id};
            sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
            window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
            return false;
          }
          else{
          href = event.target.innerText;
          event.preventDefault();

          href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
          }
        } else {
          if(href.indexOf("resourcespage") != -1) {
            window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
            return false;
          } else {
            window.open(href, "_blank");
            return false;
          }
        }
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : ""

    window.open(this.globals.currentWebUrl + "/resourcespage", "_blank");
  }

getStepTwoContent() {
  try {
    this.bodyData[5] = PageSection.esppTransacStepTwo + this.bodyData[0];
    this.bodyData[6] = 0;
  
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let stepTwoData = JSON.parse(data.body);
        if(stepTwoData.data) { // length > {}
          let stepTwoBody = stepTwoData.data;
     
          if(stepTwoBody.length > 0) {
            this.stepTwoContent = stepTwoBody;
     
            for(let i = 0; i < stepTwoBody.data.length; i++) {
              if(this.stepTwoContent[i].File_Type == "pdf") {
               this.stepTwoContent[i].fileIcon = FileType.pdf;
              } else if(this.stepTwoContent[i].File_Type == "docx") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "doc") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "xls") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "xlsx") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "pptx") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "ppt") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "zip") {
               this.stepTwoContent[i].fileIcon = FileType.zip;
              } 
            }
          } else {
            this.stepTwoContent = null;
          }
        }
     
        this.getAllCountryForms();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
     let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
  Method Name: getAllCountryForms
  Description: Gets all the available country forms.

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getAllCountryForms() {
  try {
    this.bodyData[5] = PageSection.esppTransacAllCountryForms;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
     try {
      let allCountryFormsData = JSON.parse(data.body);
     
      if(allCountryFormsData.data) { // length > {}
        let allCountryFormsBody = allCountryFormsData.data;
  
        if(allCountryFormsBody.length > 0) {
          this.allCountryFormsContent = allCountryFormsBody[0].Body_Desc;
          //setTimeout(() => { this.inContentIcon() }, 500);
        } else {
          this.allCountryFormsContent = null;
        }
      }
     } catch(error) {
       let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
       error.message += errorLocation;
       throw error;
     }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths

*****************************************************************************************/
async inContentIcon(){
  $("[href='#collapseCQIndex0']").attr("aria-expanded","true");
  $("[id='collapseCQIndex0']").attr("class","collapse show");
  $("[id='learn-more-tab']").attr("aria-label","Learn More - Restricted Share Unit  IPO (RSU IPO)");
  $("[href='https://myholdings.accenture.com/Resources/Default.aspx']").attr("aria-label","Resources Link - Common Questions (IPO RSU)");
  $("[id='sales-history-tab']").attr("aria-label","Sales History - Restricted Share Unit (IPO RSU)" );
  this.staticContentService.inContentIconAppend();
}

  /**********************************************************************************
   * Method Name: collapseAccordion                                                                 
   * Description: Auto collapse will happen after the 4th drop-down is selected                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 07/24/19             Dalemark P. Suquib          Initial draft         
   *********************************************************************************/
  collapseAccordion(index) {
    let className = document.getElementById("collapseCQIndex" + index).className;

    if(className != "collapse show") {
      this.commonQAccordionCount += 1;
    } else {
      this.commonQAccordionCount -= 1;

      if(this.commonQAccordionCount < 0) {
        this.commonQAccordionCount = 0;
      }
    }

    if(this.commonQAccordionCount == 5) {
      this.commonQAccordionCount = 1;
      for(let i = 0; i < this.rsuipoCommonQuestionsContent.length; i++) {
        document.getElementById("collapseCQIndex" + i).className = "collapse";
      }
    }
   }

}
