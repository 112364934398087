
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';
import * as moment from 'moment'
import { BrokerType, GrantAgreementStatus, AdminType, EmployeeType, PageSection, FileType, MyHoldingsPagesResources, tScript } from '../services/enumerations/enumerations';
import { HoldingProgramDetailsOptionsService } from './HoldingProgramDetailsOptionsService';

import { StaticContentService } from '../services/StaticContentService';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { BrokersPageService } from '../brokerspage/BrokersPageService';
import { PrintService } from '../services/PrintService';
import {TooltipPosition} from '@angular/material/tooltip';
import { MobileService } from '../services/MobileService';
import { AwardDetailsSummaryComponent } from '../reusable/award-details-summary/award-details-summary.component';
import { DomSanitizer } from '@angular/platform-browser';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-holdingprogramdetailspage-options',
  templateUrl: './holdingprogramdetailspage-options.component.html',
  styleUrls: ['./holdingprogramdetailspage-options.component.css']
})
export class HoldingprogramdetailspageOptionsComponent implements OnInit {

  @ViewChild('awardDetailsSummary') awardDetailsSummary: AwardDetailsSummaryComponent;

  @ViewChild('dateFromInput', {
    read: MatInput
  }) dateFromInput: MatInput; 

  @ViewChild('dateToInput', {
    read: MatInput
  })  dateToInput: MatInput;

  loading: boolean = true;
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  activeTab: any = JSON.parse(sessionStorage.getItem(this.globals.grantTabInd));
  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsNextRelease: any;
  detailsSharesReleasing: any;
  detailsGrantAccepted: any;
  detailsUnreleased: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;

  grantDesc: string;
  grantID: string;
  grantDt: any;
  expirationDt: any;
  grantPriceUSD: number;
  shareOptGranted: number;
  optAcceptance: string;
  nextExercisable: any;
  sharesExercisable: number; 
  unvested: number;
  cancelled: any; 
  vestedbutUnexercisable: number;
  vestedandexercisable: number;
  exercised: number; 
  netremaining: number;
  HasSalable: any;
  HasExercisable: any;
  HasVest: any;
  grantNum: number;
  programType: number;
  agreementValid: string;
  displayContactBroker: boolean = false;
  brokerType: any;

  showCancelled: any = true;
  showVestedButUnexercisable: any = false;
  showAgreementContainer: any = true;
  showAgreementifYes: any = false;
  showNextExercisable: any = false;
  showSharesExercisable: any = false;
  showVest: any = true;
  showExercisable: boolean = false;
  showSalable: boolean = false;

  //Vest Accordion variables
  vestTotalShares: number = 0;
  ExercisableTotalShares: number = 0;
  salableTotalShares: number = 0;
  vestDataSource: any;
  ExercisableDataSource: any;
  salableDataSource: any;
  displayedVestColumns: string[] = ['Date', 'Shares', 'Status'];
  displayedExercisableColumns: string[] = ['Date', 'Shares', 'Status'];
  displayedSalableColumns: string[] = ['Date', 'Shares', 'Status'];

  datePeriod: number = 4;
  dateTo: string = "''";
  dateFrom: string = "''";
  periodSelected: string = "All Dates";
  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;
  datePipe = new DatePipe('en-US');
  dateFromChecker: any;
  dateToChecker: any;
  dateErrorChecker: boolean = false;
  from: any;
  to: any;

  
  displayedColumnsOptSalesHist: string[] = ['DispositionDate', 'TransacionType', 'ShareAmount', 'PriceInUSD', 'TotalValueInUSD', 'CostInUSD','PreTaxInUSD','TaxesInUSD','NetProceedsInUSD'];
  dataSourceOptSalesHist: any;
  salesTableLoading: boolean;
  totalShareAmount: any = 0;
  totalTotalValueInUSD: any = 0;
  totalCostInUSD: any = 0;
  totalPreTaxInUSD: any = 0;
  totalTaxesInUSD: any = 0;
  totalNetProceedsInUSD: any = 0;
  noSalesHistory: boolean = false;

  disableDatePicker: boolean = true;
  showAgreement: boolean = false;

  //Grant Agreement
  step1Text: string;
  step1Status: string;
  urlGrantDocument: string;
  step2Text: string;
  step2Status: string;
  optGranted: number;
  grantPrice: number;
  optgrantID: string;
  optExchRate: number;
  displayVestAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];
  vestdataSource: any;
  displayExercisableAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];
  exerdataSource: any;
  displaySalableAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];
  saleDataSource: any;
  importantNotes: string;
  imptNotesdisplayInd: boolean = false;
  step3Status: string;
  step3Text: string;
  step3ValStatus: any;
  agreementVestTable: Object[] = [];
  agreementSaleTable: Object[] = [];
  agreementExerciseTable: Object[] = [];

  showVestIcon: boolean = true;
  showExercisableIcon: boolean = true;
  showSalableIcon: boolean = true;
  expandVest: boolean = false;
  showVestTbl: boolean = false;
  showExerTbl: boolean =  false;
  showSaleTbl: boolean = false;
  displayReturnSigned: boolean = false;
  stepOneStatus: boolean = false;
  stepTwoStatus: boolean = false;
  stepThreeStatus: boolean = false;
  MailAddVisible: any;
  showNotes: boolean = false;
  disableAcceptGrantBtn: boolean;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  admin = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));
  ifvestOnly: boolean = false;
  ifExerciseOnly: boolean = false;
  ifSaleOnly: boolean = false;
  shareActAgreementActionReq: boolean = false;

  methodCounter: number = 0;
  shortAwardName: string;
  grantAgreementFile: string;

  // Learn More Tab
  optCommonQuestionsContent: any; 
  optGuideBooksContent: any; 
  optShareIncentiveContent: any;
  optCountAwardCOntent: any;
  optToolsandRepContent: any;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  bodyLearnMoreData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  grantCountryCd: any; 
  grantGroupId: any;
  disableDownloadBtn: boolean = false;
  allCountryFormsContent: string;
  stepTwoContent: object;
  errorLocation: string = " + [Component: Optionsdetails, Method: methodName]";
  errorDetailsTab: boolean = false;
  errorAgreementTab: boolean = false;
  errorExerciseHistTab: boolean = false;
  errorCommoneQuestions: boolean = false;
  errorGuideBooks: boolean = false;
  errorShareIncentive: boolean = false;
  errorCountryAward: boolean = false;
  errorToolsAndReports: boolean = false;
  
  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;

currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));

//tooltip
pog_expiration: string = tScript.optionsdetailspage_exp;
pog_grantPriceUSD: string = tScript.optionsdetailspage_gp;
sas_unvested: string = tScript.optionsdetailspage_uv;
sas_vestedAndExercisable: string = tScript.optionsdetailspage_ve;
sas_exercised: string = tScript.optionsdetailspage_exc;
sas_netRemainingAtBroker = tScript.optionsdetailspage_nrb;
v_vest: string = tScript.optionsdetailspage_v;

//moved to exercise history tab
// eh_type: string = tScript.optionsdetailspage_eh_t;
// eh_price: string = tScript.optionsdetailspage_eh_p;
// eh_totalValue: string = tScript.optionsdetailspage_eh_tv;
// eh_cost: string = tScript.optionsdetailspage_eh_c;
// NetProceeds_mtt: string = tScript.optionshistory_np;

positionOption: TooltipPosition[]=['after', 'before', 'above', 'below', 'left', 'right'];

displayDesktopView: boolean;
displayMobileView: boolean;

fileTypeImagePath: string;
fileTypeAlt: string;
showAcceptBtn: boolean;
showAcceptedBtn: boolean;

UBSlink: any;
MSlink: any;
envSetting: any;

  constructor(private sanitizer:DomSanitizer, private mobileService: MobileService,private brokerService: BrokersPageService, private staticContentService: StaticContentService, private globals: Globals, private app: AppComponent, private router: Router, private holdingProgramDetailsOptionsService: HoldingProgramDetailsOptionsService, private staticContent: StaticContentService, private errorNotificationService: ErrorNotificationService, private printService: PrintService) { 
    this.getInnerWidth();
  }

  /**********************************************************************************
      Method Name: ngOnInit
      Description: Initialize the page 

      #     Date            Modified by       Description
      1     01/30/19        Katrina Narag     Initial draft 
      2     03/14/19        Roldan Mazo       Added Condition for hompage Grant Accept          sas_unvested: string = tScript.optionsdetailspage_uv;
      sas_vestedAndExercisable: string = tScript.
  **********************************************************************************/
  ngOnInit() {
    try {

      let hasOptionChecker1 = sessionStorage.getItem("showOptionsData");
      // let hasOptionChecker2 = sessionStorage.getItem("hasOptionsInHoldingsSummary");
      if(hasOptionChecker1 != "1") this.router.navigate(['homepage']);

      this.checkErrorMessage();

      if (this.programDetails.GrantNum == null){
        let temp = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
        this.programDetails = temp[0];      
      }

      // BACK BUTTON BUG - START
      sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
      let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
      let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

      if(backUrl) {
        if(detailsStorage.length > 0) {
          this.programDetails = detailsStorage[detailsStorage.length - 1];
        }
      } else {
        this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
      }
      // BACK BUTTON BUG - END

      this.getProgramDetails();
      this.getoptCommonQuestions();
      this.getGuideBooks();
      this.getFromCodeDetail();
      sessionStorage.setItem("Program Component Indicator", JSON.stringify("Options"));
      sessionStorage.setItem("Highlight Checker", "1");
      let active = sessionStorage.getItem("IsActive Row");
      setTimeout(()=>{ this.inContentIcon() }, 500);
      this.brokersSSO();
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "options details ngOnInit");
      error.message += errorLocation;
      throw error;
    }
  } 

  /*****************************************************************************************
    * Method Name: brokersSSO
    * Description: Sets SSO login URL for UBS/MS
    *
    * #    Date                 Modified by                           Description
    * 1    01/27/2020           Katrina Narag (KMN)                   Initial draft
  *****************************************************************************************/
 brokersSSO(){
  if(JSON.parse(sessionStorage.getItem(this.globals.AdminCred)) != null){
    let adminCredChecker = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));

    if(adminCredChecker.EmployeeStatus == "Active" || adminCredChecker.EmployeeStatus == "Leave with pay" || adminCredChecker.EmployeeStatus == "Leave of Absence" || adminCredChecker.EmployeeStatus == "Leave without credit"){
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
    }else{
      this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
    }
  }else{
    if(this.bodyData != null){
      if(this.bodyData[1] != null && this.bodyData[1] == "Active"){
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsESOUrl);
      }else{
        this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
      }
    }
  }
}

  /**********************************************************************************
    Method Name: getInnerWidth
    Description: Gets the inner width of the screen or browser.

    #     Date            Modified by                 Description
    1     06/19/19        Dalemark P. Suquib          Initial draft
    2     06/25/19        Jikho S. Enrique            Implemented to Options Details Page
  **********************************************************************************/
getInnerWidth() {
  this.mobileService.getInnerWidth();
  this.displayDesktopView = this.mobileService.displayDesktopView;
  this.displayMobileView = this.mobileService.displayMobileView;
}

/**********************************************************************************
    Method Name: onResize
    Description: Gets the available inner width of the screen or browser on resize.

    #     Date            Modified by                 Description
    1     06/19/19        Dalemark P. Suquib          Initial draft
    2     06/25/19        Jikho S. Enrique            Implemented to Options Details Page
  **********************************************************************************/
@HostListener('window:resize', ['$event'])
onResize(event) {
   try {
     this.mobileService.onResize(event);
     this.displayDesktopView = this.mobileService.displayDesktopView;
     this.displayMobileView = this.mobileService.displayMobileView;
   } catch(error) {
     let errorLocation = this.errorLocation.replace("methodName", "onResize");
     error.message += errorLocation;
     throw error;
   }
}


  // ngAfterViewInit(){
  //   this.setTabIndex();
  // }


  /**********************************************************************************
      Method Name: getProgramDetails
      Description: Get Options program details from session 

      #     Date            Modified by       Description
      1     01/30/19        Katrina Narag     Initial draft         
      2     02/08/19        KMN                Set award name to grant long desc
  **********************************************************************************/
 getProgramDetails() {
    try {
      this.shortAwardName = this.programDetails.awardName;
      this.awardName = this.programDetails.GrantLongDesc;   //KMN, 1
      this.detailsAvailableToSell = this.programDetails.AvailableToSell;
      this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
      this.detailsBroker = this.programDetails.BrokerId;
      this.detailsNextRelease = this.programDetails.NextRelease;
      this.detailsSharesReleasing = this.programDetails.SharesReleasing;
      this.detailsGrantAccepted = this.programDetails.GrantAccepted;
      this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
      this.detailsReleased = this.programDetails.ReleasedExercisableQty;
      this.detailsExpiration = this.programDetails.Expiration;
      this.detailsForfeited = this.programDetails.Forfeited;
      let grantAgreementInd = this.programDetails.GrantAgreementTabVisibleInd;         
      
      if(grantAgreementInd != "N"){
       this.showAgreement = true;
      }
  
      this.holdingProgramDetailsOptionsService.getSalesHistoryPeriod().pipe(map(res => res)).subscribe(data => {
        try {
          let b = data;
          b = b.sort((a,b) => 0 - (a.Id > b.Id ? -1 : 1));
          this.yearToDate = b[0].Value;
          this.lastYear = b[1].Value;
          this.last3Years = b[2].Value;
          this.allDates = b[3].Value;
          this.custom = b[4].Value;
    
          this.getOptionsSummary(this.programDetails.GrantNum);
          this.getGrantAgreement(this.programDetails.GrantNum);
        } catch(error) {
          this.loading = false;
          this.errorDetailsTab = true;
          let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.loading = false;
        this.errorDetailsTab = true;
        this.getGrantAgreement(this.programDetails.GrantNum);
        let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.loading = false;
      this.errorDetailsTab = true;
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: selectDateFrom
      Description: Select from datepicker on click 

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  selectDateFrom(event) {
    try {
      let dateEvent = event.month + '/' + event.day + '/' + event.year;
      let inputValue = moment(dateEvent).format("DD-MMM-YYYY");
      this.dateFromInput.value = inputValue;
      this.dateFromChecker = dateEvent;
      this.dateFrom = dateEvent; 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: selectDateTo
      Description: Select to datepicker on click 

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  selectDateTo(event) {
    try {
      let dateEvent = event.month + '/' + event.day + '/' + event.year;
      let inputValue = moment(dateEvent).format("DD-MMM-YYYY");
      this.dateToInput.value = inputValue;
      this.dateToChecker = dateEvent;
      this.dateTo = dateEvent;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: selectDatePeriod
      Description: Select date period from dropdown on click

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  selectDatePeriod(period) {
    try {
      this.datePeriod = period;

      if(this.datePeriod == 1) { // year to date
        this.disableDatePicker = true;
        this.dateFromInput.value = '';
        this.dateToInput.value = '';
        this.periodSelected = this.yearToDate;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = dateToEvent;
        currentDate.setMonth(0, 1);
        let yearToDate = currentDate;
        let dateFromEvent = this.datePipe.transform(yearToDate, "MM'/'dd'/'yyyy");
        this.dateFrom = dateFromEvent;
      } else if(this.datePeriod == 2) { // last year
        this.disableDatePicker = true;
        this.dateFromInput.value = '';
        this.dateToInput.value =  '';
        this.periodSelected = this.lastYear;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = dateToEvent;
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        let lastYear = currentDate;
        let dateFromEvent = this.datePipe.transform(lastYear, "MM'/'dd'/'yyyy");
        this.dateFrom = dateFromEvent;
      } else if(this.datePeriod == 3) { // last 3 years
        this.disableDatePicker = true;
        this.dateFromInput.value = '';
        this.dateToInput.value = '';
        this.periodSelected = this.last3Years;
  
        let currentDate = new Date();
        let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
        this.dateTo = dateToEvent;
        currentDate.setFullYear(currentDate.getFullYear() - 3);
        let lastThreeYear = currentDate;
        let dateFromEvent = this.datePipe.transform(lastThreeYear, "MM'/'dd'/'yyyy");
        this.dateFrom = dateFromEvent;
      } else if(this.datePeriod == 4) { // all dates
        this.disableDatePicker = true;
        this.dateFromInput.value = '';
        this.dateToInput.value = '';
        this.dateFrom = "' '";
        this.dateTo = "' '";
        this.periodSelected = this.allDates;
      } else { // period = 5, custom
        this.disableDatePicker = false;
        this.periodSelected = this.custom;
  
        let currentDate = new Date();
        let inputValue = moment(currentDate).format("DD-MMM-YYYY");
        this.dateToInput.value = inputValue;
        this.dateFrom = inputValue;
        this.dateTo = inputValue;
  
        let inputValueFrom = moment(currentDate).subtract(1, 'months').format("DD-MMM-YYYY");
        this.dateFromInput.value = inputValueFrom;
        this.dateToChecker = inputValue;
        this.dateFromChecker = inputValueFrom;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "selectDatePeriod");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: viewOptAwardsSalesHist
      Description: Validation before view of sales history

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  viewOptAwardsSalesHist() {
    try {
      this.dateErrorChecker = false;
      this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
      this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
  
      let tempDateTo = this.dateTo.replace(/'/g, '');
      let tempDateFrom = this.dateFrom.replace(/'/g, '');
  
      tempDateTo = moment(tempDateTo).format("YYYY-MM-DD");
      tempDateFrom = moment(tempDateFrom).format("YYYY-MM-DD");
  
      if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else if (tempDateTo < tempDateFrom && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else{
        this.salesTableLoading = true;
        this.totalTotalValueInUSD = 0;
        this.totalTotalValueInUSD = 0;
        this.totalCostInUSD = 0;
        this.totalPreTaxInUSD = 0;
        this.totalTaxesInUSD = 0;
        this.totalNetProceedsInUSD = 0;
        this.getOptSalesHistory(this.grantNum,this.datePeriod, this.dateTo,this.dateFrom);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewOptAwardsSalesHist");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getOptionsSummary
      Description: Get Options details tab

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  async getOptionsSummary(grantNum){
    try {
      this.holdingProgramDetailsOptionsService.getAwardDetailsOptions(grantNum).pipe(map(res => res)).subscribe(data => {
        try {
          let optSummary = data[0][0];
          this.holdingProgramDetailsOptionsService.optionsSummary = optSummary;
          this.grantNum = optSummary.GrantNum;
          this.grantDesc = optSummary.GrantDesc;
          this.grantID = optSummary.GrantId;
          this.grantDt = optSummary.GrantDate;
          this.expirationDt = optSummary.ExpirationDt;
          this.grantPriceUSD = optSummary.GrantPrice;
          this.shareOptGranted = optSummary.SharesGranted;
          this.optAcceptance = optSummary.GrantAccepted;
          this.nextExercisable = optSummary.NextExerciseable;
          this.sharesExercisable = optSummary.SharesNextExerciseable;
          this.unvested = optSummary.Unvested;
          this.cancelled = optSummary.ForfeitedQty;
          this.vestedbutUnexercisable = optSummary.VestedButUnExercisable;
          this.vestedandexercisable = optSummary.VestedAndExercisable;
          this.exercised = optSummary.Exercised;
          this.netremaining = optSummary.NetRemainingAtBroker;
          this.HasSalable = optSummary.HasSalable;
          this.HasExercisable = optSummary.HasExercisable;
          this.HasVest = optSummary.HasVest;
          this.programType = optSummary.ProgramsType;
          this.agreementValid = optSummary.IsGrantAgreementValid;
    
          //Check conditions
          let awardName = this.grantDesc;
          
          if(this.cancelled == 0) this.showCancelled = false;
          if(this.HasExercisable == 1)  this.showVestedButUnexercisable = true;
          if(this.agreementValid == "Y"){
    
            this.showAgreementContainer = false;
            this.showAgreementifYes = true;
            
            if(this.optAcceptance == "Action Required"){
              this.shareActAgreementActionReq = true;
            }
            if((this.shareOptGranted - this.vestedandexercisable) > 0) {
              this.showNextExercisable = true;
              this.showSharesExercisable = true;
            }
    
            if((this.shareOptGranted - this.cancelled) > 0){
              this.showNextExercisable = true;
              this.showSharesExercisable = true;
            }
    
            if(this.nextExercisable == null || this.nextExercisable == "" || this.nextExercisable.isNull){
              this.showNextExercisable = false;
              this.showSharesExercisable = false;
            }
          }else{
            this.showAgreementContainer = false;
            this.showAgreementifYes = false;
            this.showNextExercisable = false;
            this.showSharesExercisable = false;
          }
    
          if((this.shareOptGranted - this.cancelled) == 0){
            this.showVest = false;
            this.showExercisable = false;
            this.showSalable = false;
          }else{
    
            if(this.HasVest != 0 && this.HasExercisable == 0 && this.HasSalable == 0){
              this.ifvestOnly = true;
              this.showVestIcon = false;
            }else if(this.HasVest == 0 && this.HasExercisable != 0 && this.HasSalable == 0){
              this.ifExerciseOnly = true;
              this.showExercisableIcon = false;
            }else if(this.HasVest == 0 && this.HasExercisable == 0 && this.HasSalable != 0){
              this.ifSaleOnly = true;
              this.showSalableIcon = false;
            }
    
            //Vest accordion data
            if(this.HasVest == 0){
              this.showVest = false;
            }else{
              this.vestDataSource = data[1];
    
              for(let i = 0; i < this.vestDataSource.length; i++) {
                this.vestTotalShares += this.vestDataSource[i].Shares;
              }
            }
    
            //Exercisable accordion data
            if(this.HasExercisable == 0){
              this.showExercisable = false;
            }else{
              this.ExercisableDataSource = data[2];
    
              for(let i = 0; i < this.ExercisableDataSource.length; i++) {
                this.ExercisableTotalShares += this.ExercisableDataSource[i].Shares;
              }
            }
    
            //Salable accordion data
            if(this.HasSalable == 0){
              this.showSalable = false;
            }else{
              this.salableDataSource = data[3];
              
              for(let i = 0; i < this.salableDataSource.length; i++) {
                this.salableTotalShares += this.salableDataSource[i].Shares;
              }
            }
          }
    
          this.executeMethod();
        } catch(error) {
          this.errorDetailsTab = true;
          let errorLocation = this.errorLocation.replace("methodName", "getOptionsSummary");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorDetailsTab = true;
        let errorLocation = this.errorLocation.replace("methodName", "getOptionsSummary");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorDetailsTab = true;
      let errorLocation = this.errorLocation.replace("methodName", "getOptionsSummary");
      error.message += errorLocation;
      throw error;
    }
  }

executeProgramDetails() {
    this.awardDetailsSummary.getProgramDetails();
}

  /**********************************************************************************
      Method Name: getOptSalesHistory
      Description: Get sales history report 

      Date            Modified by             Description
      01/30/19        Katrina Narag(KMN)      Initial draft         
      01/31/19        KMN                     Set variables to 0
  **********************************************************************************/
  async getOptSalesHistory(grantNum,period,To,From){
    try {
      this.totalShareAmount = 0;
      this.totalTotalValueInUSD = 0;
      this.totalCostInUSD = 0;
      this.totalPreTaxInUSD = 0;
      this.totalTaxesInUSD = 0;
      this.totalNetProceedsInUSD = 0;
  
      this.holdingProgramDetailsOptionsService.getOptionsSalesHistory(grantNum, period, To, From).pipe(map(res => res)).subscribe(data => { 
        try {
          this.brokerType = BrokerType[data[0][0].BrokerCd];
          if(this.brokerType != null || this.brokerType != ""){
            this.displayContactBroker = true;
          }
    
          this.dataSourceOptSalesHist = data[1];
    
          if(data[1].length > 0){
            for(let i = 0; i < data[1].length; i++) {
              this.noSalesHistory = false;
              this.totalShareAmount += (data[1][i].ShareAmount * 1);
              this.totalTotalValueInUSD += (data[1][i].TotalValueInUSD * 1);
              this.totalCostInUSD += (data[1][i].CostInUSD * 1);
              this.totalPreTaxInUSD += (data[1][i].PreTaxInUSD * 1);
              this.totalTaxesInUSD += (data[1][i].TaxesInUSD * 1);
              this.totalNetProceedsInUSD += (data[1][i].NetProceedsInUSD * 1);
              this.salesTableLoading = false;
    
            }
          }else{
            this.noSalesHistory = true;
            this.totalShareAmount = 0;
            this.totalTotalValueInUSD = 0;
            this.totalCostInUSD = 0;
            this.totalPreTaxInUSD = 0;
            this.totalTaxesInUSD = 0;
            this.totalNetProceedsInUSD = 0;
            this.salesTableLoading = false;
          }
          this.loading = false;
          this.setTabIndex();
        } catch(error) {
          this.errorExerciseHistTab = true;
          this.salesTableLoading = false;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getOptSalesHistory");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorExerciseHistTab = true;
        this.salesTableLoading = false;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getOptSalesHistory");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorExerciseHistTab = true;
      this.salesTableLoading = false;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getOptSalesHistory");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getGrantAgreement
      Description: Get grant agreement tab details

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft    
      04/22/19        KMN               Get file for Step 1     
  **********************************************************************************/
  async getGrantAgreement(grantNum){
    try {
      this.agreementVestTable = [];
      this.showNotes = false;
      this.holdingProgramDetailsOptionsService.getGrantAgreement(grantNum).pipe(map(res => res)).subscribe(data => {
        try {
          let optAgreementDetails = data[0][0];
          this.holdingProgramDetailsOptionsService.grantAgreementDetails = optAgreementDetails;
          let optAgreementTbl = data[1];
          let importantNotes = data[2];   
  
          this.step1Text =  optAgreementDetails.Step1Text;
          this.step1Status =   optAgreementDetails.Step1Status;
          this.urlGrantDocument =  optAgreementDetails.PDFName;
          this.step2Text =  optAgreementDetails.Step2Text;
          this.step2Status =  optAgreementDetails.Step2Status;
          this.optGranted =  optAgreementDetails.OptSharesGranted;
          this.grantPrice =  optAgreementDetails.OptGrantPrice;
          this.optgrantID =  optAgreementDetails.GrantId;
          this.optExchRate =  optAgreementDetails.ExchangeRate;
          this.step3Status  = optAgreementDetails.Step3Status;
          this.step3Text  =  optAgreementDetails.Step3Text;
          this.MailAddVisible = optAgreementDetails.MailAddVisible;
          this.step3ValStatus = optAgreementDetails.Step3ValStatus;
          this.grantAgreementFile = optAgreementDetails.PDFName;  //KMN, 2
  
          if(this.grantAgreementFile == "NO EGT PAGE"){            
            this.disableDownloadBtn = true;
          }
  
  
          if(importantNotes == undefined || importantNotes == "" || importantNotes == null){}
          else{
            this.showNotes = true;
            this.importantNotes = importantNotes.Message;
          }
  
          if(this.step1Status == "Action Required") {
            this.displayReturnSigned = true;
            this.stepOneStatus = true;
          } 
  
          if(this.step2Status == "Action Required") {
            this.stepTwoStatus = true;
  
            if(this.MailAddVisible == 1){
              this.displayReturnSigned = true;
            }
          } 
  
          if(this.step3ValStatus == GrantAgreementStatus[1]) { 
            this.disableAcceptGrantBtn = false;
            this.disableAcceptGrantBtn = this.checkUser();
          }
  
          if(this.step3Status == "(Action Required)") {
            this.stepThreeStatus = true;            
            this.showAcceptBtn = true;          
            this.showAcceptedBtn = false;

          }else{
            this.showAcceptBtn = false;          
            this.showAcceptedBtn = true;
            this.stepThreeStatus = false;
            this.disableAcceptGrantBtn =  true;
         
          }
    
  
          for(let i = 0; i < optAgreementTbl.length; i++) {
            if(optAgreementTbl[i].VestReleaseFlag == 1) {
              this.showVestTbl = true;
              this.agreementVestTable.push(optAgreementTbl[i]);
            }else if(optAgreementTbl[i].VestReleaseFlag == 3) {
              this.showExerTbl = true;
              this.agreementExerciseTable.push(optAgreementTbl[i]);
            }else if(optAgreementTbl[i].VestReleaseFlag == 4) {
              this.showSaleTbl = true;
              this.agreementSaleTable.push(optAgreementTbl[i]);
            }
          }
  
        this.vestdataSource = this.agreementVestTable;
        this.holdingProgramDetailsOptionsService.vestDataSource = this.vestdataSource;
        this.exerdataSource = this.agreementExerciseTable;
        this.saleDataSource = this.agreementSaleTable;
  
        this.executeMethod();
        } catch(error) {
          this.errorAgreementTab = true;
          let errorLocation = this.errorLocation.replace("methodName", "getGrantAgreement");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorAgreementTab = true;
        let errorLocation = this.errorLocation.replace("methodName", "getGrantAgreement");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorAgreementTab = true;
      let errorLocation = this.errorLocation.replace("methodName", "getGrantAgreement");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: checkUser
      Description: User checking for admin and non-admin users

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  checkUser(): boolean {
    try {
      if(this.admin != null) {
      if(this.admin.OptNum == this.user.OptNum) {
        return false; // enables the button
      } else if((this.admin.OptNum != this.user.OptNum) && (AdminType[this.admin.IsAdminCanTransactInd] == AdminType[2])) {
        return false; // enables the button
      } else if((this.admin.IsAdmin == 'Y') && (AdminType[this.admin.IsAdminCanTransactInd] == AdminType[1])) {
        return true; // disables the button
      } else if(this.admin.OptNum == this.user.OptNum) {
        return true; // disables the button
      }
    }
    else {
      return false;
    }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "checkUser");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: commafy
      Description: Number formatting

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  commafy(num: any, value?: number) {
    try {
      // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: navToPage
      Description: Redirect to other page

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  navToPage(strLoc) {
    try {
      // BACK BUTTON BUG - START
      if(strLoc == "holdingsummarypage") {
        sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(true));
      }
      // BACK BUTTON BUG - END

      this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: setPeriodAllDates
      Description: Sales history tab formatting

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
  setPeriodAllDates(tab) {
    try {
      if(tab.nextId == "sales-history-tab") {
        this.disableDatePicker = true;
        this.dateFrom = "' '";
        this.dateTo = "' '";
        this.periodSelected = this.allDates;
        this.datePeriod = 4;
        this.salesTableLoading = true;
        this.getOptSalesHistory(this.grantNum,this.datePeriod, this.dateTo,this.dateFrom);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "setPeriodAllDates");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: executeMethod                                                                 
   * Description: Ensures that the method with loading set to false execute last                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 02/11/19             Dalemark P. Suquib          Initial draft       
   *********************************************************************************/
  executeMethod() {
    try {
      if(this.methodCounter >= 1) {
        this.getOptSalesHistory(this.programDetails.GrantNum, this.datePeriod, this.dateTo, this.dateFrom);
      } else {
        this.methodCounter += 1;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "executeMethod");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

   /**********************************************************************************
     Method Name: setTabIndex
    Description: Manipulate elements using jQuery
    Date Modified by Description
    03/28/19 John Paul Fernando Initial draft; browser detection 
  **********************************************************************************/
  setTabIndex(){
    try {
      $(document).ready(function(){
        var detailsTab = $("#details-tab").hasClass("active");
        var agreementTab = $("#agreement-tab").hasClass("active");
        var salesHistory = $("#sales-history-tab").hasClass("active");
        var learnMore = $("#learn-tab").hasClass("active");
      
        detailsTab ? $("#details-tab").attr("tabIndex","-1") : $("#details-tab").attr("tabIndex","0");
        agreementTab ? $("#agreement-tab").attr("tabIndex", "-1") : $("#agreement-tab").attr("tabIndex", "0")
        salesHistory ? $("#sales-history-tab").attr("tabIndex","-1") : $("#sales-history-tab").attr("tabIndex","0");
        learnMore ? $("#learn-tab").attr("tabIndex", "-1") : $("#learn-tab").attr("tabIndex", "0");
      })
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "setTabIndex");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: downloadGrantAgreement
      Description: Download step 1 grant agreement file

      Date            Modified by       Description
      04/22/19        Katrina Narag     Initial draft
  **********************************************************************************/
downloadGrantAgreement(){
  try {
    if(this.grantAgreementFile != null || this.grantAgreementFile != null){
      if(this.grantAgreementFile != "NO EGT PAGE"){
        this.getFile(this.grantAgreementFile, 'dynamic');
      }
     }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "downloadGrantAgreement");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
Method Name: getFile
Description: Get file data

#     Date            Modified by             Description
1     04/22/19        Katrina Narag         Initial Draft
2     05/03/19        KMN                   Add method for no file exists
**********************************************************************************************/
getFile(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
    
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file;
    }
    
    this.staticContentService.getFile(fileName, "MD").subscribe(data => { // MD for contacts page
      try {
        let content = JSON.parse(data.body);
    
        if(content == null || content == ""){
          this.staticContentService.noFileExists(fileName);
        }else{
          let file = content.data;
          this.downloadFile(fileName, fileType, this.base64ToArrayBuffer(file.file));
          this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
          });
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.error.message += errorLocation;
      throw error.error;
    }); 
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFile");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
Method Name: base64ToArrayBuffer
Description: Converts base 64 to array buffer.

#     Date            Modified by             Description
1     04/22/19        Katrina Narag      Initial Draft
**********************************************************************************************/
base64ToArrayBuffer(base64): Uint8Array {
  try {
    let binaryString = window.atob(base64);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);
    
    for(let i = 0; i < binaryLength; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
    }
    return bytes;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "base64ToArrayBuffer");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
Method Name: downloadFile
Description: Downloads the file.

#     Date            Modified by             Description
1     04/22/19        Katrina Narag      Initial Draft
**********************************************************************************************/
downloadFile(reportName, fileType, byte) {
  try {
    if(navigator.appVersion.toString().indexOf('.NET') > 0) { // browser is IE
      let blob = new Blob([byte], {type: fileType});
      window.navigator.msSaveBlob(blob, reportName);
      } else if(navigator.userAgent.indexOf("Firefox") > 0) { // browser is FireFox
      let a = document.createElement('a');
      a.style.display = "none";
      a.style.zIndex = "1002";
      a.style.position = "absolute";
      let blob = new Blob([byte], {type: fileType});
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = reportName;
      document.body.appendChild(a);
      a.click();
      
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
      } else { // browser is Chrome
      let blob = new Blob([byte], {type: fileType});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let fileName = reportName;
      link.download = fileName;
      link.click();
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "downloadFile");
    error.message += errorLocation;
    throw error;
  }
}

  /*****************************************************************************************
   * Method Name: getFromCodeDetail                                                                 
   * Description: Get GroupingId from CodeDetails API to be used in Common Question conditions  
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/25/2019           Iris Jane R. Manloloyo (IJM)         Initial Draft
   *****************************************************************************************/

getFromCodeDetail(){  
  try {
    this.holdingProgramDetailsOptionsService.getDataFromCodeDetail(this.programDetails.GrantGroupId).pipe(map(res => res)).subscribe(data => {
      try {
        // let body = data;        
        // if(data !=undefined){
        //   let groupingId = body[0][0];
        //   // this.grantGroupId = groupingId.GroupingId;          
        // }
        // else {
        //   this.grantGroupId = data;
        // }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFromCodeDetail");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getFromCodeDetail");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFromCodeDetail");
    error.message += errorLocation;
    throw error;
  }
}


  /*****************************************************************************************
   * Method Name: getCommonQuestions                                                                 
   * Description: Gets the content for common questions from my resource api if the user hasOptionsAward
   *              and GrantCountryCd= CAN   
   *                                                                                          
   * #    Date                 Modified by                           Description                             
   * 1    04/24/2019           Iris Jane R. Manloloyo (IJM)         Initial Draft
   *****************************************************************************************/
  getoptCommonQuestions() {
    // try {
    //   if (this.programDetails.GrantNum == null){

    //     let temp = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
    //     this.programDetails = temp[0];
    //   }
    //   this.getFromCodeDetail();
    //   let grantGroupId = '8';
    //   this.grantCountryCd = this.user.CurrencyCd; 
    //   let grantGroupIDprofile = this.programDetails.GrantGroupId;

    //   this.grantCountryCd = this.user.CurrencyCd; 
    //   if (grantGroupId === grantGroupIDprofile && this.grantCountryCd === "CAN") {
    //     this.bodyData[5] = PageSection.optionsCommonQuestionshasAwards;
    //   } else if (this.bodyData[3] === EmployeeType.seniorExec) {
    //     this.bodyData[5] = PageSection.optionsCommonQuestionsisSenior;
    //   } else {
    //     this.bodyData[5] = PageSection.optionsCommonQuestions;
    //   }
    //   this.bodyData[6] = 1;
  
    //   this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    //     try {
    //       let commonQuestionData = JSON.parse(data._body);
    //       if(commonQuestionData.body.length > 2) { // length > {}
    //         let commonQuestionBody = JSON.parse(commonQuestionData.body);
    //         if(commonQuestionBody.data.length > 0) {
    //           this.optCommonQuestionsContent = commonQuestionBody.data;
    //         } else {
    //           this.optCommonQuestionsContent = null;
    //         }
    //       } 
    //       this.getGuideBooks();
    //     } catch(error) {
    //       this.errorCommoneQuestions = true;
    //       this.getGuideBooks();
    //       let errorLocation = this.errorLocation.replace("methodName", "getoptCommonQuestions");
    //       error.message += errorLocation;
    //       throw error;
    //     }
    //   }, error => {
    //     this.errorCommoneQuestions = true;
    //     this.getGuideBooks();
    //     let errorLocation = this.errorLocation.replace("methodName", "getoptCommonQuestions");
    //     error.error.message += errorLocation;
    //     throw error.error;
    //   });
    // } catch(error) {
    //   this.errorCommoneQuestions = true;
    //   this.getGuideBooks();
    //   let errorLocation = this.errorLocation.replace("methodName", "getoptCommonQuestions");
    //   error.message += errorLocation;
    //   throw error;
    // }
  }


  /*****************************************************************************************
  * Method Name: getGuideBooks                                                                 
   * Description: Gets the content for guide books from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getGuideBooks(){
    try {
      this.bodyData[5] = PageSection.optionsGuidebooks;
      this.bodyData[6] = 1;      
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let guideBooksData = JSON.parse(data.body);
  
          if(guideBooksData.data) { // length > {}
            let guideBooksBody = guideBooksData.data;
            
            if(guideBooksBody.length > 0) {
              this.optGuideBooksContent = guideBooksBody;              
            } else {
              this.optGuideBooksContent = null;
            }
          }
          this.getOptShareIncentive();
        } catch(error) {
          this.errorGuideBooks = true;
          this.getOptShareIncentive();
          let errorLocation = this.errorLocation.replace("methodName", "getGuideBooks");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorGuideBooks = true;
        this.getOptShareIncentive();
        let errorLocation = this.errorLocation.replace("methodName", "getGuideBooks");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorGuideBooks = true;
      this.getOptShareIncentive();
      let errorLocation = this.errorLocation.replace("methodName", "getGuideBooks");
      error.message += errorLocation;
      throw error;
    }
  }
  
  /*****************************************************************************************
   * Method Name: getPlanDocument                                                                 
   * Description: Gets the content for plan document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo         Initial Draft
   *****************************************************************************************/
  getOptShareIncentive() {
    try {
      this.bodyData[5] = PageSection.optionsShareIncentiveProspectus;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let shareIncenDocumentData = JSON.parse(data.body);
  
          if(shareIncenDocumentData.data) { // length > {}
            let optDocumentBody = shareIncenDocumentData.data;
    
            if(optDocumentBody.length > 0) {
              this.optShareIncentiveContent = optDocumentBody;
            } else {
              this.optShareIncentiveContent = null;
            }
          }
          this.getOptCountryAward();
        } catch(error) {
          this.errorShareIncentive = true;
          this.getOptCountryAward();
          let errorLocation = this.errorLocation.replace("methodName", "getOptShareIncentive");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorShareIncentive = true;
        this.getOptCountryAward();
        let errorLocation = this.errorLocation.replace("methodName", "getOptShareIncentive");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorShareIncentive = true;
      this.getOptCountryAward();
      let errorLocation = this.errorLocation.replace("methodName", "getOptShareIncentive");
      error.message += errorLocation;
      throw error;
    }
  }


  /*****************************************************************************************
   * Method Name: getProspectusDocument                                                                 
   * Description: Gets the content for prospectus document from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/24/2019           Iris Jane Manloloyo        Initial Draft
   *****************************************************************************************/
  getOptCountryAward() {
    try {
      this.bodyData[5] = PageSection.optionsCountryAwardsTaxImp;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let CountryAwardDocData = JSON.parse(data.body);
  
          if(CountryAwardDocData.data) { // length > {}
            let countryAwardDocBody = CountryAwardDocData.data;
    
            if(countryAwardDocBody.length > 0) {
              this.optCountAwardCOntent = countryAwardDocBody;              
            } else {
              this.optCountAwardCOntent = null;
            }
          }
          this.getOptToolsAndReports();
        } catch(error) {
          this.errorCountryAward = true;
          this.getOptToolsAndReports();
          let errorLocation = this.errorLocation.replace("methodName", "getOptCountryAward");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorCountryAward = true;
        this.getOptToolsAndReports();
        let errorLocation = this.errorLocation.replace("methodName", "getOptCountryAward");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorCountryAward = true;
      this.getOptToolsAndReports();
      let errorLocation = this.errorLocation.replace("methodName", "getOptCountryAward");
      error.message += errorLocation;
      throw error;
    }
  } 

  
  /*****************************************************************************************
   * Method Name: getToolsAndReports                                                                 
   * Description: Gets the content for tools and reports from my resource api.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/23/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getOptToolsAndReports() {
    try {
      this.bodyData[5] = PageSection.optionsToolsReports;
      this.bodyData[6] = 1;
  
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        try {
          let toolsAndReportsData = JSON.parse(data.body);
  
          if(toolsAndReportsData.data) { // length > {}
            let toolsAndReportsBody = toolsAndReportsData.data;
            
            if(toolsAndReportsBody.length > 0) {
              this.optToolsandRepContent = toolsAndReportsBody;
            } else {
              this.optToolsandRepContent = null;
            }
            setTimeout(()=>{ this.inContentIcon() }, 500);
          }
        } catch(error) {
          this.errorToolsAndReports = true;
          let errorLocation = this.errorLocation.replace("methodName", "getOptToolsAndReports");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        this.errorToolsAndReports = true;
        let errorLocation = this.errorLocation.replace("methodName", "getOptToolsAndReports");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      this.errorToolsAndReports = true;
      let errorLocation = this.errorLocation.replace("methodName", "getOptToolsAndReports");
      error.message += errorLocation;
      throw error;
    }
  }

  // /*****************************************************************************************
  //  * Method Name: getFileName                                                                 
  //  * Description: Gets the file name from the html element.    
  //  *                                                                                          
  //  * #    Date                 Modified by                 Description                             
  //  * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
  //  *****************************************************************************************/
  // getFileName(event) {
  //   try {
  //     let href: string = event.target.href;
  //     let srcElement: HTMLImageElement = event.srcElement;
  //     let id: string = event.target.id;
  //     let target: string = event.target.target;
  //     if (target == ""){
  //       target = "_self";
  //     }
  //     if(srcElement.src != undefined){
  //       this.staticContentService.validateFileType(event);
  //     }else if(href != undefined && href != ""){
  //       if(href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1){ //if href value is a file
  //         event.preventDefault();
  //         this.staticContentService.validateFileType(event);
  //       }else if(href.indexOf("/Default.aspx") != -1){  //Redirect to another myHoldings page
  //         // href = href.split("/Default.aspx")[0]; 
  //         href = event.target.innerText;
  //         event.preventDefault();
          
  //         href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
  //       } else if(href.indexOf("#") != -1) {
  //         event.preventDefault();
  //         id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
  //       }
  //     }
  //     else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
  //       event.preventDefault();
  //       window.open(href, target);
  //     }else{
  //       event.preventDefault();
  //       this.staticContentService.validateFileType(event);
  //     }
  //   } catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "getFileName");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }

  // navToMyHoldingsPage(page){
  //   page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
  //   page.indexOf("Contacts") != -1 ? page = "contactspage" : "";

  //   window.open(this.globals.currentWebUrl + "/" + page, "_blank");
  //   return false;
  // }


  /*********************************************************************************************
  Method Name: getFile
  Description: Get file data

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getFileData(file, checker) {
  try {
    let fileName: string;
    let fileType: string;
  
    if(checker == "static") {
      if(file.File_Type.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
      
      fileName = file.Document_Name + "." + file.File_Type;
    } else {
      if(file.indexOf('pdf') != -1) {
        fileType = 'application/pdf';
      } else {
        fileType = 'mime_type';
      }
  
      fileName = file;
    }
  
    this.staticContentService.getFile(fileName, "MD").subscribe(data => {
      try {
        let content = JSON.parse(data.body);
        let file = content.data;
    
        this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getFileData");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getRsuApiData");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getFileData");
    error.message += errorLocation;
    throw error;
  }
}

getStepTwoContent() {
  try {
    this.bodyData[5] = PageSection.esppTransacStepTwo + this.bodyData[0];
    this.bodyData[6] = 0;
  
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      try {
        let stepTwoData = JSON.parse(data.body);

        if(stepTwoData.data) { // length > {}
          let stepTwoBody = stepTwoData.data;
     
          if(stepTwoBody.length > 0) {
            this.stepTwoContent = stepTwoBody;
     
            for(let i = 0; i < stepTwoBody.data.length; i++) {
              if(this.stepTwoContent[i].File_Type == "pdf") {
               this.stepTwoContent[i].fileIcon = FileType.pdf;
              } else if(this.stepTwoContent[i].File_Type == "docx") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "doc") {
               this.stepTwoContent[i].fileIcon = FileType.doc;
              } else if(this.stepTwoContent[i].File_Type == "xls") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "xlsx") {
               this.stepTwoContent[i].fileIcon = FileType.xls;
              } else if(this.stepTwoContent[i].File_Type == "pptx") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "ppt") {
               this.stepTwoContent[i].fileIcon = FileType.ppt;
              } else if(this.stepTwoContent[i].File_Type == "zip") {
               this.stepTwoContent[i].fileIcon = FileType.zip;
              } 
            }
          } else {
            this.stepTwoContent = null;
          }
        }
     
        this.getAllCountryForms();
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getStepTwoContent");
    error.message += errorLocation;
    throw error;
  }
}

/*********************************************************************************************
  Method Name: getAllCountryForms
  Description: Gets all the available country forms.

  #     Date            Modified by             Description
  1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
getAllCountryForms() {
  try {
    this.bodyData[5] = PageSection.esppTransacAllCountryForms;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
     try {
      let allCountryFormsData = JSON.parse(data.body);
     
      if(allCountryFormsData.data) { // length > {}
        let allCountryFormsBody = allCountryFormsData.data;
  
        if(allCountryFormsBody.length > 0) {
          this.allCountryFormsContent = allCountryFormsBody[0].Body_Desc;
          setTimeout(() => { this.inContentIcon() }, 500);
        } else {
          this.allCountryFormsContent = null;
        }
      }
     } catch(error) {
       let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
       error.message += errorLocation;
       throw error;
     }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getAllCountryForms");
    error.message += errorLocation;
    throw error;
  }
}
/*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths

*****************************************************************************************/
async inContentIcon(){
  $("[href='op.pdf']").remove();
  $("[id='learn-more-tab']").attr("aria-label","Learn More for Options award");
  this.staticContent.inContentIconAppend();
}

  /*****************************************************************************************
    * Method Name: onTabChange                                                                 
    * Description: Sets timeout for incontenticon method when a tab is selected.
    *                                                                                          
    * #    Date                 Modified by                           Description                             
    * 1    04/26/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
onTabChange() {
  try {
    setTimeout(()=>{ this.inContentIcon() }, 500);
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "onTabChange");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navToReportsPage                                                                 
  * Description: Navigate to reports page     
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Dalemark P. Suquib                    Initial draft
  *****************************************************************************************/
navToReportsPage(event) {
  try {
    let href = event.target.href;
    let id: number;
    let reportId: object;
    let srcElement: HTMLImageElement = event.srcElement;

    if(srcElement.src != undefined){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
    }else if(href != undefined) {
      if(href.indexOf("reportid") != -1) {
        id = href.substring(href.lastIndexOf("=") + 1);
        reportId = {'ReportTypeId': id};
        sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
        window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
      }else if(href.indexOf("fileName=") != -1){
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    }

  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "navToReportsPage");
    error.message += errorLocation;
    throw error;
  }
}

/*****************************************************************************************
  * Method Name: navtoSpecificReportsPage                                                                 
  * Description: Navigate to specific report
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/05/19             KMN                                   Bug 332329 fix 
  *****************************************************************************************/
 navtoSpecificReportsPage(id){
  let reportId: object;

  reportId = {'ReportTypeId': id};
  sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
  window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
}

/**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Optionsdetails") != -1 && this.user != null) {
                webPage = "Optionsdetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
//   getGlobalID() {
//     try {
//       this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
//         try {
//           this.globalIDs = data[0];
  
//           if(this.globalIDs.length == 0) {
//             this.phSBGlobalID = true;
//             this.globalIDNum1 = "";
//           } else if(this.globalIDs.length == 1) {
//             this.phSBGlobalID = true;
    
//             if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
//               this.globalIDNum1 = "Your SSN";
//             } else {
//               this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
//             }
//           } 

//         } catch(error) {
//           let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
//           error.message += errorLocation;
//           throw error;
//         }
//       }, error => {
//         let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
//         error.error.message += errorLocation;
//         throw error.error;
//       });
//     } catch(error) {
//       let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
//       error.message += errorLocation;
//       throw error;
//     }
//    }
  

//    checkBrokersInfo(broker){
//     event.stopPropagation();
   

//     if(this.ubsLoaded == true)
//       this.resourceLoading = false;
//     else
//       this.resourceLoading = true;
    
//     if(this.sbLoaded == true)
//       this.resourceLoading = false;
//     else
//       this.resourceLoading = true;
    
//     if(this.bigLoaded == true)
//       this.resourceLoading = false;
//     else
//       this.resourceLoading = true;
    
//     if(this.ncbLoaded == true)
//       this.resourceLoading = false;
//     else
//       this.resourceLoading = true;
    

    
//     this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
//       let brokerAccounts = data[0];
//       if(brokerAccounts.length > 0) {

//         for(let i = 0; i < brokerAccounts.length; i++) {
//   if(broker == "MS"){

//     (<any>$("#brokerContact")).modal({
//       backdrop: 'static',
//       keyboard: false
//     });

//     this.brokerHeader = "Morgan Stanley";
    
//     this.getGlobalID();

//     this.displayPersonalUbs = false;
//     this.displayPersonalBig = false;
//     this.displayPersonalNcb = false;

//     this.bodyData[5] = PageSection.sb;
//     this.bodyData[6] = 1;
//     this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
      
//         let msData = JSON.parse(data._body);
//         let msBody;

//         if(msData.body.length > 2) { // length > {}
//         msBody = JSON.parse(msData.body);

//           if (msBody.data.length > 0){
//             this.sbDesc = msBody.data[0].Body_Desc;
//             this.displayPersonalSb = true;
//             }
//           else {
//             this.displayPersonalSb = false;
//             this.sbDesc = "";
//           } 
//         }


//       this.bodyData[5] = PageSection.sbDoc;
//       this.bodyData[6] = 0;

//       this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
//       let msDocData = JSON.parse(data._body);
//       let msDocBody;
      
//       if(msDocData.body.length > 2) { // length > {}
//                       msDocBody = JSON.parse(msDocData.body);
//                       if(msDocBody.data.length > 0) {
//                         this.sbDoc = msDocBody.data;
      
//                         for(let i=0; i<this.sbDoc.length; i++){
//                           this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
//                         }
//                       }
//                       else{
//                         this.sbDoc = null;
//                       }
//                     }
                
//       });
//       this.sbLoaded = true;
//   this.resourceLoading = false;
//     });

//   }else if(broker == "UBS"){
//     (<any>$("#brokerContact")).modal({
//       backdrop: 'static',
//       keyboard: false
//     });
    

//     this.brokerHeader = "UBS Financial Services Inc";
//     this.displayPersonalSb = false;
//     this.displayPersonalBig = false;
//     this.displayPersonalNcb = false;

//   this.ubsAccount = brokerAccounts[i].Account;
//   this.bodyData[5] = PageSection.ubs;
//   this.bodyData[6] = 1;
  

//   this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
//     let ubsData = JSON.parse(data._body);
//     let ubsBody;

//     if(ubsData.body.length > 2) { // length > {}
//     ubsBody = JSON.parse(ubsData.body);
//     if(ubsBody.data.length > 0) {
//       this.ubsDesc = ubsBody.data[0].Body_Desc;
//       this.displayPersonalUbs = true;
//     }
//     else {
//     this.displayPersonalUbs = false;
//       this.ubsDesc = "";
//     }
//   }

//   this.bodyData[5] = PageSection.ubsDoc;
//   this.bodyData[6] = 0;
  
//   this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

//     let ubsDocData = JSON.parse(data._body);
//     let ubsDocBody;
    
//     if(ubsDocData.body.length > 2) { // length > {}
//         ubsDocBody = JSON.parse(ubsDocData.body);
//         if(ubsDocBody.data.length > 0){
//           this.ubsDoc = ubsDocBody.data;
  
//           for(let i=0; i<this.ubsDoc.length; i++){
//             this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
//           }
//         }
//         else{
//           this.ubsDoc = null;
//         }
//       }
//   });
//   this.ubsLoaded = true;
//   this.resourceLoading = false;
//   });
//   }else if(broker == "BIG"){
//     (<any>$("#brokerContact")).modal({
//       backdrop: 'static',
//       keyboard: false
//     });
    

//     this.brokerHeader = "Banco de Investimento Global";

//     this.displayPersonalSb = false;
//     this.displayPersonalUbs = false;
    
//     this.displayPersonalNcb = false;

//     this.bigAccount = brokerAccounts[i].Account;
//     this.bodyData[5] = PageSection.big;
//     this.bodyData[6] = 1;
//     this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    
//       let bigData = JSON.parse(data._body);
//       let bigBody;

//       if(bigData.body.length > 2) { // length > {}
//       bigBody = JSON.parse(bigData.body);
//       if(bigBody.data.length > 0){
//         this.bigDesc = bigBody.data[0].Body_Desc;
//         this.displayPersonalBig = true;
//       }else {
//         this.displayPersonalBig = false;
//         this.bigDesc = "";
//       }
//     }
//     this.bigLoaded = true;
//   this.resourceLoading = false;
//     });

//   }else if(broker == "TA"){
//     (<any>$("#brokerContact")).modal({
//       backdrop: 'static',
//       keyboard: false
//     });
    

//     this.brokerHeader = "Computershare (formerly National City Bank)";
//     this.displayPersonalSb = false;
//     this.displayPersonalUbs = false;
//     this.displayPersonalBig = false;
    

//     this.ncbAccount = brokerAccounts[i].Account;
//     this.bodyData[5] = PageSection.ncb;
//     this.bodyData[6] = 1;
//     this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

//       let taData = JSON.parse(data._body);
//       let taBody;

//       if(taData.body.length > 2) { // length > {}
//       taBody = JSON.parse(taData.body);
//       if(taBody.data.length > 0){
//       this.ncbDesc = taBody.data[0].Body_Desc;
//       this.displayPersonalNcb = true;
//       }else {
//         this.displayPersonalNcb = false;
//         this.ncbDesc = "";
//       }
//     } 
    
//     this.bodyData[5] = PageSection.ncbDoc;
//     this.bodyData[6] = 0;         

//        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

//         let taDocData = JSON.parse(data._body);
//         let taDocBody;

//         if(taDocData.body.length > 2) { // length > {}
//         taDocBody = JSON.parse(taDocData.body);
//         if(taDocBody.data.length > 0){
//           this.ncbDoc = taDocBody.data;

//           for(let i=0; i<this.ncbDoc.length; i++){
//             this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
//           }
//         }else {
//           this.displayPersonalNcb = false;
//           this.ncbDoc = null;
//         }
//       }
//        });
//        this.ncbLoaded = true;
//        this.resourceLoading = false;
//     });

//   }else if(broker.indexOf('/')){
//     this.brokerHeader = broker;
//     let splittedBroker = broker.split('/');
//     for(let i = 0; i < splittedBroker.length; i++){
//       if(splittedBroker[i] == "MS"){
//         this.getGlobalID();
//         this.bodyData[5] = PageSection.sb;
//         this.bodyData[6] = 1;
//         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
          
//             let msData = JSON.parse(data._body);
//             let msBody;
  
//             if(msData.body.length > 2) { // length > {}
//             msBody = JSON.parse(msData.body);
  
//               if (msBody.data.length > 0){
//                 this.sbDesc = msBody.data[0].Body_Desc;
//                 this.displayPersonalSb = true;
//                 }
//               else {
//                 this.displayPersonalSb = false;
//                 this.sbDesc = "";
//               } 
//             }
  
  
//           this.bodyData[5] = PageSection.sbDoc;
//           this.bodyData[6] = 0;
  
//           this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
//           let msDocData = JSON.parse(data._body);
//           let msDocBody;
          
//           if(msDocData.body.length > 2) { // length > {}
//                           msDocBody = JSON.parse(msDocData.body);
//                           if(msDocBody.data.length > 0) {
//                             this.sbDoc = msDocBody.data;
          
//                             for(let i=0; i<this.sbDoc.length; i++){
//                               this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
//                             }
//                           }
//                           else{
//                             this.sbDoc = null;
//                           }
//                         }
                    
//           });
//           this.sbLoaded = true;
//       this.resourceLoading = false;
//         });
  
//       }else if(splittedBroker[i] == "UBS"){
        
//       this.ubsAccount = brokerAccounts[i].Account;
//       this.bodyData[5] = PageSection.ubs;
//       this.bodyData[6] = 1;
      
  
//       this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
//         let ubsData = JSON.parse(data._body);
//         let ubsBody;
  
//         if(ubsData.body.length > 2) { // length > {}
//         ubsBody = JSON.parse(ubsData.body);
//         if(ubsBody.data.length > 0) {
//           this.ubsDesc = ubsBody.data[0].Body_Desc;
//           this.displayPersonalUbs = true;
//         }
//         else {
//         this.displayPersonalUbs = false;
//           this.ubsDesc = "";
//         }
//       }
  
//       this.bodyData[5] = PageSection.ubsDoc;
//       this.bodyData[6] = 0;
      
//       this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
//         let ubsDocData = JSON.parse(data._body);
//         let ubsDocBody;
        
//         if(ubsDocData.body.length > 2) { // length > {}
//             ubsDocBody = JSON.parse(ubsDocData.body);
//             if(ubsDocBody.data.length > 0){
//               this.ubsDoc = ubsDocBody.data;
      
//               for(let i=0; i<this.ubsDoc.length; i++){
//                 this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
//               }
//             }
//             else{
//               this.ubsDoc = null;
//             }
//           }
//       });
//       this.ubsLoaded = true;
//       this.resourceLoading = false;
//       });
//       }else if(splittedBroker[i] == "BIG"){
        
//         this.bigAccount = brokerAccounts[i].Account;
//         this.bodyData[5] = PageSection.big;
//         this.bodyData[6] = 1;
//         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        
//           let bigData = JSON.parse(data._body);
//           let bigBody;
  
//           if(bigData.body.length > 2) { // length > {}
//           bigBody = JSON.parse(bigData.body);
//           if(bigBody.data.length > 0){
//             this.bigDesc = bigBody.data[0].Body_Desc;
//             this.displayPersonalBig = true;
//           }else {
//             this.displayPersonalBig = false;
//             this.bigDesc = "";
//           }
//         }
//         this.bigLoaded = true;
//       this.resourceLoading = false;
//         });
  
//       }else if(splittedBroker[i] == "TA"){
       
//         this.displayPersonalSb = false;
//         this.displayPersonalUbs = false;
//         this.displayPersonalBig = false;
        
  
//         this.ncbAccount = brokerAccounts[i].Account;
//         this.bodyData[5] = PageSection.ncb;
//         this.bodyData[6] = 1;
//         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
//           let taData = JSON.parse(data._body);
//           let taBody;
  
//           if(taData.body.length > 2) { // length > {}
//           taBody = JSON.parse(taData.body);
//           if(taBody.data.length > 0){
//           this.ncbDesc = taBody.data[0].Body_Desc;
//           this.displayPersonalNcb = true;
//           }else {
//             this.displayPersonalNcb = false;
//             this.ncbDesc = "";
//           }
//         } 
        
//         this.bodyData[5] = PageSection.ncbDoc;
//         this.bodyData[6] = 0;         
  
//            this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 
  
//             let taDocData = JSON.parse(data._body);
//             let taDocBody;
  
//             if(taDocData.body.length > 2) { // length > {}
//             taDocBody = JSON.parse(taDocData.body);
//             if(taDocBody.data.length > 0){
//               this.ncbDoc = taDocBody.data;
  
//               for(let i=0; i<this.ncbDoc.length; i++){
//                 this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
//               }
//             }else {
//               this.displayPersonalNcb = false;
//               this.ncbDoc = null;
//             }
//           }
//            });
//            this.ncbLoaded = true;
//            this.resourceLoading = false;
//         });
//     }
//   }
//   (<any>$("#brokerContact")).modal({
//     backdrop: 'static',
//     keyboard: false
//   });
  
//   }
// }
// }

// });


    


//   }

  /*****************************************************************************************
  * Method Name: printFile                                                                 
  * Description: Prints the file.
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    06/07/19             Dalemark P. Suquib                    Initial Draft
  *****************************************************************************************/
  printFile() {
    try {
      let award: string = "options";
      this.printService.print(award);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "printFile");
      error.message += errorLocation;
      throw error;
    }
  }

  fileImage(filyType){
    if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
      this.fileTypeImagePath = '/assets/pdf.svg';
      this.fileTypeAlt = "PDF File"
      return true;
    }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
      this.fileTypeImagePath = '/assets/zip.svg';
      this.fileTypeAlt = "ZIP File"
      return true;
    }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
      this.fileTypeImagePath = '/assets/xls.svg';
      this.fileTypeAlt = "XLS File"
      return true;
    }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
      this.fileTypeImagePath = '/assets/docx.svg';
      this.fileTypeAlt = "DOC File"
      return true;
    }
   }

}
