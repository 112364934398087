import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Globals } from '../../globals';
import { HoldingprogramdetailsRsuService } from '../HoldingProgramDetailsRSUService';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { tScript } from '../../services/enumerations/enumerations';
import { LoadingService } from '../../services/LoadingService';

@Component({
  selector: 'app-rsu-div-card',
  templateUrl: './rsu-div-card.component.html',
  styleUrls: ['./rsu-div-card.component.css']
})
export class RsuDivCardComponent implements OnInit {

  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));

  errorDivCard: boolean = false;
  errorLocation: string = " + [Component: Rsudetails, Method: methodName]";

  keyExecToggleValue: string;

  divRsuDate: string = "null";
  divGrantDate: any;
  divVestTable: any[];
  divReleaseTable: any[];
  isDivInitialized: boolean;
  divDropdownSelected: any;
  divVestDataSource: any[];
  displayedDivVestColumns: string[] = ['EventDate', 'Shares'];
  divRelDataSource: any[];
  displayedDivRelColumns: string[] = ['EventDate', 'Shares'];
  divGrantId: any;
  divAdditionalRsusGranted: any;
  divLoading: boolean;
  displayDivCard: boolean = false;
  divCardLoading: boolean = true;
  divTableLoading: boolean = false;

  // Tooltip
  detailsARLD_AdditionalRSUsGranted: string = tScript.rsudetailspage_arg;

  constructor(
    private globals: Globals,
    private rsuDetailsService: HoldingprogramdetailsRsuService,
    private errorNotificationService: ErrorNotificationService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.checkErrorMessage();
    this.getRSUDetailsDivInfo();
  }

  getRSUDetailsDivInfo() {
    this.rsuDetailsService.getRSUDetailsDivInfo(this.programDetails.GrantNum, this.divRsuDate).subscribe(data => {
      try {
        if(Array.isArray(data) && (data[0].length > 0 || data[1].length > 0 || data[2].length > 0)) { 
          this.divGrantDate = data[0];
          let div = data[1][0];
          let divTable = data[2];
          this.divVestTable = [];
          this.divReleaseTable = [];
          this.displayDivCard = true;

          //KMN
          for(let i=0; i<this.divGrantDate.length; i++){
            this.divGrantDate[i]["GrantDate"] = moment(this.divGrantDate[i]["GrantDate"]).format("DD MMM YYYY");
            
          }
  
          if(this.isDivInitialized != true) {
            this.divDropdownSelected = data[0][0].GrantDate;
          }

          if(divTable != null || divTable != undefined){
            for(let i = 0; i < divTable.length; i++) {
              if(divTable[i].VestReleaseFlag == 1) {
                this.divVestTable.push(divTable[i]);
              } else {
                this.divReleaseTable.push(divTable[i]);
              }
            }
          }

          this.divVestDataSource = this.divVestTable;
          this.divRelDataSource = this.divReleaseTable;

          if(div != null || div != undefined){
            this.divGrantId = div.GrantId;
            this.divAdditionalRsusGranted = div.AdditionalRSUsGranted;
          }

          if(!this.divTableLoading) {
            this.loadingService.setExecutedComponent();
          }
  
          this.isDivInitialized = true;
          this.divTableLoading = false;
          this.divCardLoading = false;
        } else {
          if(!this.divTableLoading) {
            this.loadingService.setExecutedComponent();
          }

          this.divCardLoading = false;
          this.divTableLoading = false;
          this.displayDivCard = false;
        }

        this.displayDividendCard();
      } catch(error) {	
        this.errorDivCard = true;		
        this.displayDivCard = true;
        this.divTableLoading = false;
        this.divCardLoading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getRSUDetailsDivInfo");
        error.message += errorLocation;
        throw error;
      }
    });
  }

  selectRsuDate(rsuDate) {
    try {
      this.divTableLoading = true;
      this.divDropdownSelected = "'" + rsuDate + "'";
      
      this.divRsuDate = this.divDropdownSelected;
      this.divDropdownSelected = rsuDate;
      this.getRSUDetailsDivInfo();
    } catch(error) {
      this.errorDivCard = true;
      this.divTableLoading = false;
      let errorLocation = this.errorLocation.replace("methodName", "selectRsuDate");
      error.message += errorLocation;
      throw error;
    }
  }

  displayDividendCard() {
    try {
      if(this.keyExecToggleValue == "Target") {
        this.displayDivCard = true;
      } else if(this.keyExecToggleValue == "Maximum") {
        this.displayDivCard = false;
      } else {
        this.displayDivCard = false;
      }
    } catch(error) {
      this.displayDivCard = true;
      this.divTableLoading = false;
      this.divCardLoading = false;
      this.errorDivCard = true;
      let errorLocation = this.errorLocation.replace("methodName", "selectRsuDate");
      error.message += errorLocation;
      throw error;
    }
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value >= 0) {
            let number = (num*1).toLocaleString('en', {
              //maximumFractionDigits: value
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Rsudetails") != -1 && this.user != null) {
                webPage = "Rsudetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }
}
