import { Injectable } from '@angular/core';
import { CONFIG } from '../../config/config';

@Injectable()
export class AppConfig {

    public config: any;    

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig() {

        return this.config;
    }

    /**
     * This method:
     *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
     */
    public load() {
        this.config = CONFIG;        
    }
    
}