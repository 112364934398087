// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    font-family: Graphik;
    font-size: 14px;
}
::ng-deep .perfUnit-modal .mat-dialog-container{
    border-radius: 8px;
    width: 500px;
    min-height: 200px;
    max-height: 430px;
}

.btn {
    /* margin: 0 4px; */
    display: contents;
    color: #474747;
    background-color: white;
    border: none;
    outline: 0;
    box-shadow: none;
    text-decoration: none;
}
.x-button{
    margin-top: 16px;
}

h5 {
    font-weight: 700;
    /* font-size: 16px; */
    margin-bottom: 20px;
    margin-top: 20px;
    color: #460173;
}
.mat-dialog-actions{
    padding: 0px;
    margin-top: -20px;
}
.body {
    margin-left: 0px;
}

.pf-units{
    font-size: 15px;
    font-style: Graphik;

}`, "",{"version":3,"sources":["webpack://./src/app/services/ea/shared/perfunitmodal/perfunitmodal.component.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;IACd,uBAAuB;IACvB,YAAY;IACZ,UAAU;IACV,gBAAgB;IAChB,qBAAqB;AACzB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mBAAmB;;AAEvB","sourcesContent":[".content {\r\n    font-family: Graphik;\r\n    font-size: 14px;\r\n}\r\n::ng-deep .perfUnit-modal .mat-dialog-container{\r\n    border-radius: 8px;\r\n    width: 500px;\r\n    min-height: 200px;\r\n    max-height: 430px;\r\n}\r\n\r\n.btn {\r\n    /* margin: 0 4px; */\r\n    display: contents;\r\n    color: #474747;\r\n    background-color: white;\r\n    border: none;\r\n    outline: 0;\r\n    box-shadow: none;\r\n    text-decoration: none;\r\n}\r\n.x-button{\r\n    margin-top: 16px;\r\n}\r\n\r\nh5 {\r\n    font-weight: 700;\r\n    /* font-size: 16px; */\r\n    margin-bottom: 20px;\r\n    margin-top: 20px;\r\n    color: #460173;\r\n}\r\n.mat-dialog-actions{\r\n    padding: 0px;\r\n    margin-top: -20px;\r\n}\r\n.body {\r\n    margin-left: 0px;\r\n}\r\n\r\n.pf-units{\r\n    font-size: 15px;\r\n    font-style: Graphik;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
