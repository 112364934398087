// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep app-navheader{
    display: none !important;
}
::ng-deep app-userinfoheader{
    display: none !important;
}
::ng-deep app-pagefooter{
    display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/notfound/notfound.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B","sourcesContent":["::ng-deep app-navheader{\r\n    display: none !important;\r\n}\r\n::ng-deep app-userinfoheader{\r\n    display: none !important;\r\n}\r\n::ng-deep app-pagefooter{\r\n    display: none !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
