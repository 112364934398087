
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { highlightsModel } from "../services/models/highlightsModel";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators'; 
import { Globals } from '../globals';
import "rxjs";
import { holdingSummaryModel } from '../services/models/holdingSummaryModel'
import { PageSection, EmployeeType } from '../services/enumerations/enumerations';
import { MultipleGrantsService } from '../multiplegrants/MultipleGrantsService';

const headerJson = {
    'Access-Control-Allow-Origin': '*',
    };
    const headersConfig = new HttpHeaders(headerJson); 


@Injectable()


export class HoldingsSummaryService {

// Learn More RSU Tab
programDetails: any = JSON.parse(sessionStorage.getItem(this.globalVariables.programDetailsKey));
bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
user = JSON.parse(sessionStorage.getItem(this.globalVariables.profileInfoKey));
grantCountryCode: any;
grantGroupId: any;
rsuCommonQuestion: any;

dataSourceOptions:any;
dataSourceVEIP:any;
dataSourceRSU: any;
dataSourceFounder: any;

showOptionsData: boolean = false;
showVEIPData: boolean = false;
showRSUData: boolean = false;
showFounderData: boolean = false;

programsTotalShareOutstanding: any = 0;
programsTotalShareOutstandingValue: any = 0;
programsTotalAvailableToSell: any = 0;
programsTotalAvailableToSellValue: any = 0;
programsTotalUnReleased: any = 0;
programsTotalUnReleasedValue: any = 0;
currencyCd: string;
currencyCdSymbol = "";

totalFounderSharesOutstanding: any;
founderSharesOutstandingValue: any;
totalFounderAvailableToSell: any;
founderAvailableToSellValue: any;
totalFounderUnreleasedUnvested: any;
founderUnreleasedUnvestedValue: any;
totalFounderAvailToSellValue: any;
founderReleasedExercisableQty: any;
totalFoundersForfeited: any;
totalRSUSharesOutStanding: any;
rsuOutstandingValue: any;
totalRSUAvailableToSell: any;
rsuAvailableToSellValue: any;
totalRSUUnreleasedUnvested: any;
rsuUnreleasedOutstanding: any;
totalRSUSharesReleasing: any;
totalRSUReleasedVested: any;
totalRSUForfeited: any;
totalPurchaseSharesOutStanding:any = 0;
totalPurchaseAvailableToSell:any = 0;
totalPurchaseUnReleased = 0;
totalPurchaseAvailableToSellValue:any = 0;
valueSharesOustanding:any = 0;
valueAvailableToSell:any = 0;
totalOptionsSharesOutstanding:any = 0;
totalOptionsAvailableToSell:any = 0;
totalGrantPrice:any = 0;
totalUnreleasedUnvested:any = 0;
totalReleasedExercisableQty: any = 0;
optionsOutstandingValue:any = 0;
optionsAvailableToSellValue: number = 0;
optionsUnreleasedUnvested: any = 0;

constructor(private globalVariables: Globals, private httpClient: HttpClient, private multiplegrantsservice: MultipleGrantsService){ }

    getRSU(optNum): any{ 
        let apiURL = this.globalVariables.currentAPIUrl;
        let rsuAPI = apiURL + this.globalVariables.getRSUAPI;
  
        try{
            const api = rsuAPI + optNum; 
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res))); 
        }catch(err){
            return err;
        }
    }

    getOptions(optNum): any{ 
        let apiURL = this.globalVariables.currentAPIUrl;
        let optionsAPI = apiURL + this.globalVariables.getOptionAPI;
  
        try{
            const api = optionsAPI + optNum; 
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res))); 
        }catch(err){
            return err;
        }
    }

    getPurchaseProgram(optNum): any{ 
        let apiURL = this.globalVariables.currentAPIUrl;
        let purchaseProgramAPI = apiURL + this.globalVariables.getPurchaseProgramAPI;
  
        try{
            const api = purchaseProgramAPI + optNum; 
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res))); 
        }catch(err){
            return err;
        }
    }

    getMatchingGrantData(optNum): any{
        let apiURL = this.globalVariables.currentAPIUrl;
        let veipMatchingGrantAPI = apiURL + this.globalVariables.getveipMatchingGrantAPI;
        try{
            const api = veipMatchingGrantAPI + optNum; 
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res))); 
        }catch(err){
            return err;
        }
    }

    getRSUDivSum(optNum): any{
        let apiURL = this.globalVariables.currentAPIUrl;
        let rsuDivSumAPI = apiURL + this.globalVariables.getRSUDivSumAPI;
  
        try{
            const api = rsuDivSumAPI + optNum; 
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res))); 
        }catch(err){
            return err;
        }
    }



    getExchangeRate(currencyCd): any{
        let apiURL = this.globalVariables.currentAPIUrl;
        let exchangeRateAPI = apiURL + this.globalVariables.getExchangeRateAPI;
        try{
            const api = exchangeRateAPI + currencyCd; 
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res))); 
        }catch(err){
            return err;
        }
    }

    getcardIndicator(optnum){
        let apiUrl = this.globalVariables.currentAPIUrl;
        let getCardAPI = apiUrl + this.globalVariables.getCardIndicatoraAPI;

        getCardAPI = getCardAPI.replace("[param1]",optnum);

        try {
            return this.httpClient.get(getCardAPI).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res)));
        } catch(err) {
            return err;
        }
    }

    getFounderShares(optNum): any {
        let apiUrl = this.globalVariables.currentAPIUrl;
        let founderSharesApi = apiUrl + this.globalVariables.getFounderSharesAPI;

        try {
            const api = founderSharesApi + optNum;
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res)));
        } catch(err) {
            return err;
        }
    }

    public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
        statusCode: statusCode,
        error: res
        };
        return observableThrowError(error);
    } 

    setProgramDetails(details) {      
        sessionStorage.setItem(this.globalVariables.programDetailsKey, JSON.stringify(details));
    }

    getCurrencyList(): any{
        let currentAPIURL = this.globalVariables.currentAPIUrl;
        let staticContentAPI = this.globalVariables.staticContentAPI;
            
        try{
            const api= currentAPIURL + staticContentAPI + 'Currency';
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res)));
        } catch(err) {
            return err;
        }   
    }

    getExhangeRateList(): any {
         let apiURL = this.globalVariables.currentAPIUrl;
         let currencyListApi = this.globalVariables.exchangeRateListAPI;
        // let rsuAPI = apiURL + this.globalVariables.getRSUAPI;
  
        try{
            const api = apiURL + currencyListApi; 
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res))); 
        }catch(err){
            return err;
        }
    }

    setAwardTab(tab) {
        sessionStorage.setItem(this.globalVariables.grantTabInd, JSON.stringify(tab))
    }

    getPSUDetailsbyOptNum(optNum) {
        let apiURL = this.globalVariables.currentAPIUrl;
        let PSUDetailsByOptNumAPIAPI = apiURL +this.globalVariables.getPSUDetailsByOptNumAPI;

        console.log(PSUDetailsByOptNumAPIAPI);

        PSUDetailsByOptNumAPIAPI = PSUDetailsByOptNumAPIAPI.replace("[param]", optNum);

        try {
            const api = PSUDetailsByOptNumAPIAPI;  
            console.log(api);
            return this.httpClient.get(api).pipe(map(res => res), retry(3),
            catchError((res: Response) => this.onError(res)));
          } catch(err) {
            return err;
          }
    }

}


