
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';

@Injectable()
export class HoldingProgramDetailsRSUIPOService {

  constructor(private globals: Globals, private httpClient: HttpClient) { }

//RSU IPO DETAILS

getRSUIPODetails(OptNum) {
  let apiUrl = this.globals.currentAPIUrl;
  let rsuIPODetailsApi = this.globals.getRSUIPODetailsAPI;

  rsuIPODetailsApi = rsuIPODetailsApi.replace("[param1]", OptNum);
  // rsuIPODetailsApi = rsuIPODetailsApi.replace("[param1]", '173130');

  try {    
    const api = apiUrl + rsuIPODetailsApi;   
    return this.httpClient.get(api).pipe(map(res => res), retry(3),
    catchError((res: Response) => this.onError(res)));
  } catch(err) {
    return err;
  }
}

//RSU IPO VEST

getRSUIPODetailsVestInfo(optNum, rsuDate) {
  let regEx: any = /\'/gi;
  let apiUrl = this.globals.currentAPIUrl;
  let rsuIPODetailsVestApi = this.globals.getRSUIPODetailsVestInfoAPI;

  rsuIPODetailsVestApi = rsuIPODetailsVestApi.replace("[param1]", optNum);
  rsuIPODetailsVestApi = rsuIPODetailsVestApi.replace("[param2]", rsuDate);
  rsuIPODetailsVestApi = rsuIPODetailsVestApi.replace(regEx, "");
  try {
    const api = apiUrl + rsuIPODetailsVestApi;  
    return this.httpClient.get(api).pipe(map(res => res), retry(3),
    catchError((res: Response) => this.onError(res)));
  } catch(err) {
    return err;
  }
}

//RSU IPO RELEASE

getRSUIPODetailsRelInfo(optNum, rsuDate) {
  let regEx: any = /\'/gi;
  let apiUrl = this.globals.currentAPIUrl;
  let rsuIPODetailsRelApi = this.globals.getRSUIPODetailsRelInfoAPI;

  rsuIPODetailsRelApi = rsuIPODetailsRelApi.replace("[param1]", optNum);
  rsuIPODetailsRelApi = rsuIPODetailsRelApi.replace("[param2]", rsuDate);
  rsuIPODetailsRelApi = rsuIPODetailsRelApi.replace(regEx, "");

  try {
    const api = apiUrl + rsuIPODetailsRelApi;   
    return this.httpClient.get(api).pipe(map(res => res), retry(3),
    catchError((res: Response) => this.onError(res)));
  } catch(err) {
    return err;
}
}

//RSU IPO GRANT

getRSUIPODetailsGrantInfo(optNum, rsuDate) {
  let regEx: any = /\'/gi;
  let apiUrl = this.globals.currentAPIUrl;
  let rsuIPODetailsGrantApi = this.globals.getRSUIPODetailsGrantInfoAPI;

  rsuIPODetailsGrantApi = rsuIPODetailsGrantApi.replace("[param1]", optNum);
  rsuIPODetailsGrantApi = rsuIPODetailsGrantApi.replace("[param2]", rsuDate);  
  rsuIPODetailsGrantApi = rsuIPODetailsGrantApi.replace(regEx, "");

  try {
    const api = apiUrl + rsuIPODetailsGrantApi; 
    return this.httpClient.get(api).pipe(map(res => res), retry(3),
    catchError((res: Response) => this.onError(res)));
  } catch(err) {
    return err;
  }
}

//RSU IPO SALES HISTORY

getRSUIPOSalesHistory(optNum, period, dateTo, dateFrom) {
  let regEx: any = /\'/gi;
  let apiUrl = this.globals.currentAPIUrl;
  let rsuIPOSalesHistUrl = apiUrl +  this.globals.getRSUIPOSalesHistoryAPI;

  rsuIPOSalesHistUrl = rsuIPOSalesHistUrl.replace("[param1]", optNum);   
  rsuIPOSalesHistUrl = rsuIPOSalesHistUrl.replace("[param2]", period);
  rsuIPOSalesHistUrl = rsuIPOSalesHistUrl.replace("[param3]", dateTo);
  rsuIPOSalesHistUrl = rsuIPOSalesHistUrl.replace("[param4]", dateFrom);
  rsuIPOSalesHistUrl = rsuIPOSalesHistUrl.replace(regEx, "");

  try {
    const api = rsuIPOSalesHistUrl;
    return this.httpClient.get(api).pipe(map(res => res), retry(3),
    catchError((res: Response) => this.onError(res)));
  } catch(err) {
    return err;
  }
}

getIPOSalesHistoryPeriod() {
  let apiUrl = this.globals.currentAPIUrl;
  let IPOsalesHistoryPeriodUrl = this.globals.staticContentAPI + this.globals.staticSalesHistoryPeriod;

  try {
    const api = apiUrl + IPOsalesHistoryPeriodUrl;
    return this.httpClient.get(api).pipe(map(res => res), retry(3),
    catchError((res: Response) => this.onError(res)));
  } catch(err) {
    return err;
  }
}

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 

  getDataFromCodeDetail(grantgroupid): any{
    let apiUrl = this.globals.currentAPIUrl;
    let getValue = this.globals.getValuefromCodeDetail; 

    try {
      const api = apiUrl + getValue + grantgroupid;
      
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }
  
}
