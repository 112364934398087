
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HoldingProgramDetailsOptionsService } from '../HoldingProgramDetailsOptionsService';
import { Globals } from '../../globals';
import * as moment from 'moment'
// import { MatInput } from '@angular/material';
import { DatePipe } from '@angular/common';
import {TooltipPosition} from '@angular/material/tooltip';
import { BrokerType,tScript, AdminType, GrantAgreementStatus, PageSection, MyHoldingsPagesResources } from '../../services/enumerations/enumerations';
import { HoldingprogramdetailspageOptionsComponent } from '../../holdingprogramdetailspage-options/holdingprogramdetailspage-options.component'
import { StaticContentService } from '../../services/StaticContentService';
import { BrokersPageService } from '../../brokerspage/BrokersPageService';

@Component({
  selector: 'app-options-exercisehistory-tab',
  templateUrl: './options-exercisehistory-tab.component.html',
  styleUrls: ['./options-exercisehistory-tab.component.css']
})
export class OptionsExercisehistoryTabComponent implements OnInit {
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];

  dateFromInput: Date; // Angular Material Datepicker
  dateToInput: Date; // Angular Material Datepicker
  dateFromClicked: boolean = false;
  dateToClicked: boolean = false;
  dateFromHolder: any;
  dateToHolder: any;

  loading: boolean = true;
  marketQuoteCurrentPrice = JSON.parse(sessionStorage.getItem("marketQuoteDatafromExtAPI"));
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  activeTab: any = JSON.parse(sessionStorage.getItem(this.globals.grantTabInd));
  awardName: any;
  detailsAvailableToSell: any;
  detailsAvailableToSellValue: any;
  detailsBroker: any;
  detailsNextRelease: any;
  detailsSharesReleasing: any;
  detailsGrantAccepted: any;
  detailsUnreleased: any;
  detailsReleased: any;
  detailsExpiration: any;
  detailsForfeited: any;

  grantDesc: string;
  grantID: string;
  grantDt: any;
  expirationDt: any;
  grantPriceUSD: number;
  shareOptGranted: number;
  optAcceptance: string;
  nextExercisable: any;
  sharesExercisable: number; 
  unvested: number;
  cancelled: any; 
  vestedbutUnexercisable: number;
  vestedandexercisable: number;
  exercised: number; 
  netremaining: number;
  HasSalable: any;
  HasExercisable: any;
  HasVest: any;
  grantNum: number;
  programType: number;
  agreementValid: string;
  displayContactBroker: boolean = false;
  brokerType: any = [];
  brokerTypes: boolean = false;
  showCancelled: any = true;
  showVestedButUnexercisable: any = false;
  showAgreementContainer: any = true;
  showAgreementifYes: any = false;
  showNextExercisable: any = false;
  showSharesExercisable: any = false;
  showVest: any = true;
  showExercisable: boolean = false;
  showSalable: boolean = false;

  //Vest Accordion variables
  vestTotalShares: number = 0;
  ExercisableTotalShares: number = 0;
  salableTotalShares: number = 0;
  vestDataSource: any;
  ExercisableDataSource: any;
  salableDataSource: any;
  displayedVestColumns: string[] = ['Date', 'Shares', 'Status'];
  displayedExercisableColumns: string[] = ['Date', 'Shares', 'Status'];
  displayedSalableColumns: string[] = ['Date', 'Shares', 'Status'];

  
  
  displayedColumnsOptSalesHist: string[] = ['DispositionDate', 'TransacionType', 'ShareAmount', 'PriceInUSD', 'TotalValueInUSD', 'CostInUSD','PreTaxInUSD','TaxesInUSD','NetProceedsInUSD'];
  dataSourceOptSalesHist: any;
  salesTableLoading: boolean;
  totalShareAmount: any = 0;
  totalTotalValueInUSD: any = 0;
  totalCostInUSD: any = 0;
  totalPreTaxInUSD: any = 0;
  totalTaxesInUSD: any = 0;
  totalNetProceedsInUSD: any = 0;
  noSalesHistory: boolean = false;

  disableDatePicker: boolean = true;
  showAgreement: boolean = false;

  //Grant Agreement
  step1Text: string;
  step1Status: string;
  urlGrantDocument: string;
  step2Text: string;
  step2Status: string;
  optGranted: number;
  grantPrice: number;
  optgrantID: string;
  optExchRate: number;
  displayVestAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];
  vestdataSource: any;
  displayExercisableAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];
  exerdataSource: any;
  displaySalableAgreementTbl: string[] = ["VestReleaseDate", "OriginalShares"];
  saleDataSource: any;
  importantNotes: string;
  imptNotesdisplayInd: boolean = false;
  step3Status: string;
  step3Text: string;
  step3ValStatus: any;
  agreementVestTable: Object[] = [];
  agreementSaleTable: Object[] = [];
  agreementExerciseTable: Object[] = [];

  showVestIcon: boolean = true;
  showExercisableIcon: boolean = true;
  showSalableIcon: boolean = true;
  expandVest: boolean = false;
  showVestTbl: boolean = false;
  showExerTbl: boolean =  false;
  showSaleTbl: boolean = false;
  displayReturnSigned: boolean = false;
  stepOneStatus: boolean = false;
  stepTwoStatus: boolean = false;
  stepThreeStatus: boolean = false;
  MailAddVisible: any;
  showNotes: boolean = false;
  disableAcceptGrantBtn: boolean;
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  admin = JSON.parse(sessionStorage.getItem(this.globals.AdminCred));
  ifvestOnly: boolean = false;
  ifExerciseOnly: boolean = false;
  ifSaleOnly: boolean = false;
  shareActAgreementActionReq: boolean = false;

  methodCounter: number = 0;
  shortAwardName: string;
  grantAgreementFile: string;

  // Learn More Tab
  optCommonQuestionsContent: any; 
  optGuideBooksContent: any; 
  optShareIncentiveContent: any;
  optCountAwardCOntent: any;
  optToolsandRepContent: any;
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  bodyLearnMoreData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  grantCountryCd: any; 
  grantGroupId: any;
  disableDownloadBtn: boolean = false;
  allCountryFormsContent: string;
  stepTwoContent: object;
  errorLocation: string = " + [Component: Optionsdetails, Method: methodName]";
  errorDetailsTab: boolean = false;
  errorAgreementTab: boolean = false;
  errorExerciseHistTab: boolean = false;
  errorCommoneQuestions: boolean = false;
  errorGuideBooks: boolean = false;
  errorShareIncentive: boolean = false;
  errorCountryAward: boolean = false;
  errorToolsAndReports: boolean = false;
  
  sbDesc: string;
  displayPersonalSb: boolean = false;
  displayPersonalUbs: boolean = false;
  displayPersonalBig: boolean = false;
  displayPersonalNcb: boolean = false;

  ubsDesc: string;
  bigDesc: string;
  ncbDesc: string;

  sbDoc: any[];
  ubsDoc: any[];
  ncbDoc: any[];
  
  globalIDNum1: string;
  globalIDNum2: string;
  phSBGlobalID: boolean = true;

  globalIDs: any;

  datePeriod: number = 4;
  dateTo: string = "''";
  dateFrom: string = "''";
  periodSelected: string = "All Dates";
  yearToDate: string;
  lastYear: string;
  last3Years: string;
  allDates: string;
  custom: string;
  datePipe = new DatePipe('en-US');
  dateFromChecker: any;
  dateToChecker: any;
  dateErrorChecker: boolean = false;
  from: any;
  to: any;
  
ubsAccount: number;
bigAccount: number;
ncbAccount: number;

resourceLoading: boolean;
ubsLoaded: boolean = false;
sbLoaded: boolean = false;
bigLoaded: boolean = false;
ncbLoaded: boolean = false;

brokerHeader: string;

currentVisitedAward: any = JSON.parse(sessionStorage.getItem("currentVisitedAward"));


  displayDesktopView: boolean;
  displayMobileView: boolean;

  fileTypeImagePath: string;
  fileTypeAlt: string;
  showAcceptBtn: boolean;
  showAcceptedBtn: boolean;

  eh_type: string = tScript.optionsdetailspage_eh_t;
  eh_price: string = tScript.optionsdetailspage_eh_p;
  eh_totalValue: string = tScript.optionsdetailspage_eh_tv;
  eh_cost: string = tScript.optionsdetailspage_eh_c;
  NetProceeds_mtt: string = tScript.optionshistory_np;

  constructor(private brokerService: BrokersPageService, private staticContentService: StaticContentService, private holdingProgramDetailsOptionsService: HoldingProgramDetailsOptionsService, private globals: Globals, private optionsParent: HoldingprogramdetailspageOptionsComponent) { }

  ngOnInit() {
    // BACK BUTTON BUG - START
    sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } else {
      this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
    }
    // BACK BUTTON BUG - END

    this.getProgramDetails();
    this.setPeriodAllDates();
    
    setTimeout(()=>this.modDP(),500);
    
    
  }


    /**********************************************************************************
      Method Name: setPeriodAllDates
      Description: Sales history tab formatting

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
 setPeriodAllDates() {
  try {
    
      this.disableDatePicker = true;
      this.dateFrom = "' '";
      this.dateTo = "' '";
      this.periodSelected = this.allDates;
      this.datePeriod = 4;
      this.dateFromClicked = false;
      this.dateToClicked = false;
      this.dateErrorChecker = false;
      this.dateFromInput = null; // Angular Material Datepicker
      this.dateToInput = null; // Angular Material Datepicker
      this.salesTableLoading = true;
    
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "setPeriodAllDates");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
      Method Name: getOptionsSummary
      Description: Get Options details tab

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
 async getOptionsSummary(grantNum){
  try {
    this.holdingProgramDetailsOptionsService.getAwardDetailsOptions(grantNum).pipe(map(res => res)).subscribe(data => {
      try {
        let optSummary = data[0][0];
        this.holdingProgramDetailsOptionsService.optionsSummary = optSummary;
        this.grantNum = optSummary.GrantNum;
        this.grantDesc = optSummary.GrantDesc;
        this.grantID = optSummary.GrantId;
        this.grantDt = optSummary.GrantDate;
        this.expirationDt = optSummary.ExpirationDt;
        this.grantPriceUSD = optSummary.GrantPrice;
        this.shareOptGranted = optSummary.SharesGranted;
        this.optAcceptance = optSummary.GrantAccepted;
        this.nextExercisable = optSummary.NextExerciseable;
        this.sharesExercisable = optSummary.SharesNextExerciseable;
        this.unvested = optSummary.Unvested;
        this.cancelled = optSummary.ForfeitedQty;
        this.vestedbutUnexercisable = optSummary.VestedButUnExercisable;
        this.vestedandexercisable = optSummary.VestedAndExercisable;
        this.exercised = optSummary.Exercised;
        this.netremaining = optSummary.NetRemainingAtBroker;
        this.HasSalable = optSummary.HasSalable;
        this.HasExercisable = optSummary.HasExercisable;
        this.HasVest = optSummary.HasVest;
        this.programType = optSummary.ProgramsType;
        this.agreementValid = optSummary.IsGrantAgreementValid;
  
        //Check conditions
        let awardName = this.grantDesc;
        
        if(this.cancelled == 0) this.showCancelled = false;
        if(this.HasExercisable == 1)  this.showVestedButUnexercisable = true;
        if(this.agreementValid == "Y"){
  
          this.showAgreementContainer = false;
          this.showAgreementifYes = true;
          
          if(this.optAcceptance == "Action Required"){
            this.shareActAgreementActionReq = true;
          }
          if((this.shareOptGranted - this.vestedandexercisable) > 0) {
            this.showNextExercisable = true;
            this.showSharesExercisable = true;
          }
  
          if((this.shareOptGranted - this.cancelled) > 0){
            this.showNextExercisable = true;
            this.showSharesExercisable = true;
          }
  
          if(this.nextExercisable == null || this.nextExercisable == "" || this.nextExercisable.isNull){
            this.showNextExercisable = false;
            this.showSharesExercisable = false;
          }
        }else{
          this.showAgreementContainer = false;
          this.showAgreementifYes = false;
          this.showNextExercisable = false;
          this.showSharesExercisable = false;
        }
  
        if((this.shareOptGranted - this.cancelled) == 0){
          this.showVest = false;
          this.showExercisable = false;
          this.showSalable = false;
        }else{
  
          if(this.HasVest != 0 && this.HasExercisable == 0 && this.HasSalable == 0){
            this.ifvestOnly = true;
            this.showVestIcon = false;
          }else if(this.HasVest == 0 && this.HasExercisable != 0 && this.HasSalable == 0){
            this.ifExerciseOnly = true;
            this.showExercisableIcon = false;
          }else if(this.HasVest == 0 && this.HasExercisable == 0 && this.HasSalable != 0){
            this.ifSaleOnly = true;
            this.showSalableIcon = false;
          }
  
          //Vest accordion data
          if(this.HasVest == 0){
            this.showVest = false;
          }else{
            this.vestDataSource = data[1];
  
            for(let i = 0; i < this.vestDataSource.length; i++) {
              this.vestTotalShares += this.vestDataSource[i].Shares;
            }
          }
  
          //Exercisable accordion data
          if(this.HasExercisable == 0){
            this.showExercisable = false;
          }else{
            this.ExercisableDataSource = data[2];
  
            for(let i = 0; i < this.ExercisableDataSource.length; i++) {
              this.ExercisableTotalShares += this.ExercisableDataSource[i].Shares;
            }
          }
  
          //Salable accordion data
          if(this.HasSalable == 0){
            this.showSalable = false;
          }else{
            this.salableDataSource = data[3];
            
            for(let i = 0; i < this.salableDataSource.length; i++) {
              this.salableTotalShares += this.salableDataSource[i].Shares;
            }
          }
        }
  
        this.executeMethod();
      } catch(error) {
        this.errorDetailsTab = true;
        let errorLocation = this.errorLocation.replace("methodName", "getOptionsSummary");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorDetailsTab = true;
      let errorLocation = this.errorLocation.replace("methodName", "getOptionsSummary");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorDetailsTab = true;
    let errorLocation = this.errorLocation.replace("methodName", "getOptionsSummary");
    error.message += errorLocation;
    throw error;
  }
}

 /**********************************************************************************
      Method Name: getOptSalesHistory
      Description: Get sales history report 

      Date            Modified by             Description
      01/30/19        Katrina Narag(KMN)      Initial draft         
      01/31/19        KMN                     Set variables to 0
  **********************************************************************************/
 async getOptSalesHistory(grantNum,period,To,From){
  try {
    this.totalShareAmount = 0;
    this.totalTotalValueInUSD = 0;
    this.totalCostInUSD = 0;
    this.totalPreTaxInUSD = 0;
    this.totalTaxesInUSD = 0;
    this.totalNetProceedsInUSD = 0;

    this.holdingProgramDetailsOptionsService.getOptionsSalesHistory(grantNum, period, To, From).pipe(map(res => res)).subscribe(data => { 
      try {
        for( let i = 0; i < data[0].length; i++ ){
          this.brokerType[i] = BrokerType[data[0][i].BrokerCd];
          if( i > 0){
            this.brokerTypes = true;
          }
        }
        if(this.brokerType != null || this.brokerType != ""){
          this.displayContactBroker = true;
        }
  
        this.dataSourceOptSalesHist = data[1];
  
        if(data[1].length > 0){
          for(let i = 0; i < data[1].length; i++) {
            this.noSalesHistory = false;
            this.totalShareAmount += (data[1][i].ShareAmount * 1);
            this.totalTotalValueInUSD += (data[1][i].TotalValueInUSD * 1);
            this.totalCostInUSD += (data[1][i].CostInUSD * 1);
            this.totalPreTaxInUSD += (data[1][i].PreTaxInUSD * 1);
            this.totalTaxesInUSD += (data[1][i].TaxesInUSD * 1);
            this.totalNetProceedsInUSD += (data[1][i].NetProceedsInUSD * 1);
            this.salesTableLoading = false;
  
          }
        }else{
          this.noSalesHistory = true;
          this.totalShareAmount = 0;
          this.totalTotalValueInUSD = 0;
          this.totalCostInUSD = 0;
          this.totalPreTaxInUSD = 0;
          this.totalTaxesInUSD = 0;
          this.totalNetProceedsInUSD = 0;
          this.salesTableLoading = false;
        }
        this.loading = false;
      } catch(error) {
        this.errorExerciseHistTab = true;
        this.salesTableLoading = false;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getOptSalesHistory");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorExerciseHistTab = true;
      this.salesTableLoading = false;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getOptSalesHistory");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorExerciseHistTab = true;
    this.salesTableLoading = false;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getOptSalesHistory");
    error.message += errorLocation;
    throw error;
  }
}


 /**********************************************************************************
      Method Name: commafy
      Description: Number formatting

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
 commafy(num: any, value?: number) {
  try {
    // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value > 0) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
      Method Name: getProgramDetails
      Description: Get Options program details from session 

      #     Date            Modified by       Description
      1     01/30/19        Katrina Narag     Initial draft         
      2     02/08/19        KMN                Set award name to grant long desc
  **********************************************************************************/
 getProgramDetails() {
  try {
    this.shortAwardName = this.programDetails.awardName;
    this.awardName = this.programDetails.GrantLongDesc;   //KMN, 1
    this.detailsAvailableToSell = this.programDetails.AvailableToSell;
    this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
    this.detailsBroker = this.programDetails.BrokerId;
    this.detailsNextRelease = this.programDetails.NextRelease;
    this.detailsSharesReleasing = this.programDetails.SharesReleasing;
    this.detailsGrantAccepted = this.programDetails.GrantAccepted;
    this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
    this.detailsReleased = this.programDetails.ReleasedExercisableQty;
    this.detailsExpiration = this.programDetails.Expiration;
    this.detailsForfeited = this.programDetails.Forfeited;
    let grantAgreementInd = this.programDetails.GrantAgreementTabVisibleInd;
    //this.grantCountryCd = this.programDetails.GrantCountryCd;      
    
    if(grantAgreementInd != "N"){
     this.showAgreement = true;
    }

    this.holdingProgramDetailsOptionsService.getSalesHistoryPeriod().pipe(map(res => res)).subscribe(data => {
      try {
        let b = data;
        b = b.sort((a,b) => 0 - (a.Id > b.Id ? -1 : 1));
        this.yearToDate = b[0].Value;
        this.lastYear = b[1].Value;
        this.last3Years = b[2].Value;
        this.allDates = b[3].Value;
        this.custom = b[4].Value;

        this.getOptionsSummary(this.programDetails.GrantNum);
        this.selectDatePeriod(4);
  
      } catch(error) {
        this.loading = false;
        this.errorDetailsTab = true;
        let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
        error.message += errorLocation;
        throw error;
      }
      this.executeMethod();
    }
    
    , error => {
      this.loading = false;
      this.errorDetailsTab = true;
      let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.loading = false;
    this.errorDetailsTab = true;
    let errorLocation = this.errorLocation.replace("methodName", "getProgramDetails");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
    Method Name: selectDateFrom
    Description: Select from datepicker on click 

    Date            Modified by       Description
    01/30/19        Katrina Narag     Initial draft         
**********************************************************************************/
selectDateFrom(value: Date) { // Angular Material Datepicker
  try {
    let dateEvent = moment(value).format("YYYY-MM-DD");
    this.dateFromChecker = dateEvent;
    this.dateFrom = "'" + dateEvent + "'";
    this.dateFromClicked = true;
    this.dateFromHolder = dateEvent;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "selectDateFrom");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
    Method Name: selectDateTo
    Description: Select to datepicker on click 

    Date            Modified by       Description
    01/30/19        Katrina Narag     Initial draft         
**********************************************************************************/
selectDateTo(value: Date) { // Angular Material Datepicker
  try {
    let dateEvent = moment(value).format("YYYY-MM-DD");
    this.dateToChecker = dateEvent;
    this.dateTo = "'" + dateEvent + "'";
    this.dateToClicked = true;
    this.dateToHolder = dateEvent;
 } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "selectDateTo");
    error.message += errorLocation;
    throw error;
  }
}

/**********************************************************************************
      Method Name: viewOptAwardsSalesHist
      Description: Validation before view of sales history

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft      
      12/18/19        Jikho Enrique     Added loading checker to prevent multiple calls when user clicked it multiple times(fast)   
  **********************************************************************************/
  viewOptAwardsSalesHist() {
    if(this.salesTableLoading != true){
    try {
      this.dateErrorChecker = false;
      this.dateToChecker = moment(this.dateToChecker).format("YYYY-MM-DD");
      this.dateFromChecker = moment(this.dateFromChecker).format("YYYY-MM-DD");
  
      let tempDateTo = this.dateTo.replace(/'/g, '');
      let tempDateFrom = this.dateFrom.replace(/'/g, '');
  
      tempDateTo = moment(tempDateTo).format("YYYY-MM-DD");
      tempDateFrom = moment(tempDateFrom).format("YYYY-MM-DD");
  
      if(this.dateToChecker < this.dateFromChecker && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else if (tempDateTo < tempDateFrom && this.disableDatePicker == false){
        this.dateErrorChecker = true;
      }
      else{
        this.salesTableLoading = true;
        this.totalTotalValueInUSD = 0;
        this.totalTotalValueInUSD = 0;
        this.totalCostInUSD = 0;
        this.totalPreTaxInUSD = 0;
        this.totalTaxesInUSD = 0;
        this.totalNetProceedsInUSD = 0;
        this.getOptSalesHistory(this.grantNum,this.datePeriod, this.dateTo,this.dateFrom);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "viewOptAwardsSalesHist");
      error.message += errorLocation;
      throw error;
    }
    }
  }

   /**********************************************************************************
   * Method Name: executeMethod                                                                 
   * Description: Ensures that the method with loading set to false execute last                              
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 02/11/19             Dalemark P. Suquib          Initial draft       
   *********************************************************************************/
  executeMethod() {
    try {
      if(this.methodCounter >= 1) {
        this.getOptSalesHistory(this.programDetails.GrantNum, this.datePeriod, this.dateTo, this.dateFrom);
      } else {
        this.methodCounter += 1;
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "executeMethod");
      error.message = error.message + errorLocation;
      throw error;
    }
  }

/**********************************************************************************
    Method Name: selectDatePeriod
    Description: Select date period from dropdown on click

    Date            Modified by       Description
    01/30/19        Katrina Narag     Initial draft         
**********************************************************************************/
selectDatePeriod(period) {
  try {
    if(period != 5){
      $(".MDatePicker input").css("background-color","#d3d0d0")
    }else{
      $(".MDatePicker input").css("background-color","white")
    }
    this.datePeriod = period;

    if(this.datePeriod == 1) { // year to date
      this.dateFromInput = null; // Angular Material Datepicker
      this.dateToInput = null; // Angular Material Datepicker
      this.disableDatePicker = true;
      this.periodSelected = this.yearToDate;
      this.dateFromClicked = false;
      this.dateToClicked = false;

      let currentDate = new Date();
      let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      this.dateTo = dateToEvent;

      currentDate.setMonth(0, 1);
      let yearToDate = currentDate;
      let dateFromEvent = this.datePipe.transform(yearToDate, "MM'/'dd'/'yyyy");
      this.dateFrom = dateFromEvent;
    } else if(this.datePeriod == 2) { // last year
      this.dateFromInput = null; // Angular Material Datepicker
      this.dateToInput = null; // Angular Material Datepicker
      this.disableDatePicker = true;
      this.periodSelected = this.lastYear;
      this.dateFromClicked = false;
      this.dateToClicked = false;

      let currentDate = new Date();
      let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      this.dateTo = dateToEvent;
      currentDate.setFullYear(currentDate.getFullYear() - 1);

      let lastYear = currentDate;
      let dateFromEvent = this.datePipe.transform(lastYear, "MM'/'dd'/'yyyy");
      this.dateFrom = dateFromEvent;
    } else if(this.datePeriod == 3) { // last 3 years
      this.dateFromInput = null; // Angular Material Datepicker
      this.dateToInput = null; // Angular Material Datepicker
      this.disableDatePicker = true;
      this.periodSelected = this.last3Years;
      this.dateFromClicked = false;
      this.dateToClicked = false;

      let currentDate = new Date();
      let dateToEvent = this.datePipe.transform(currentDate, "MM'/'dd'/'yyyy");
      this.dateTo = dateToEvent;

      currentDate.setFullYear(currentDate.getFullYear() - 3);
      let lastThreeYear = currentDate;
      let dateFromEvent = this.datePipe.transform(lastThreeYear, "MM'/'dd'/'yyyy");
      this.dateFrom = dateFromEvent;
    } else if(this.datePeriod == 4) { // all dates
      this.dateFromInput = null; // Angular Material Datepicker
      this.dateToInput = null; // Angular Material Datepicker
      this.disableDatePicker = true;
      this.dateFromClicked = false;
      this.dateToClicked = false;
      this.dateFrom = "' '";
      this.dateTo = "' '";
      this.periodSelected = this.allDates;
    } else { // period = 5, custom
      this.disableDatePicker = false;
      this.periodSelected = this.custom;

      let currentDate = new Date();
      let inputValue = moment(currentDate).format("YYYY-MM-DD");

      this.dateFrom = inputValue;
      if(this.dateToClicked) {
        this.dateToInput = new Date(this.dateToHolder); // Angular Material Datepicker
      } else {
        this.dateToInput = currentDate; // Angular Material Datepicker
      }

      this.dateTo = inputValue;
      if(this.dateFromClicked) {
        this.dateFromInput = new Date(this.dateFromHolder); // Angular Material Datepicker
      } else {
        this.dateFromInput = currentDate; // Angular Material Datepicker
      }

      this.dateToChecker = inputValue;
      this.dateFromChecker = inputValue;
    }
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "selectDatePeriod");
    error.message += errorLocation;
    throw error;
  }
}
modDP(){
       //matDatepicker mod
       $(".mat-input-infix span").remove();
       $(".mat-input-underline span").remove();
       $(".mat-input-subscript-wrapper").remove();
       $(".mat-input-underline").css("height","0");
       $(".mat-input-element").addClass("datepicker-input");
       $(".mat-input-element").css("height","30px");
       $(".mat-input-element").css("width","173px");
       $(".mat-input-element").css("margin-bottom","3px");
       $(".mat-input-element").css("margin-left","24px");
       $(".mat-input-element").css("text-indent","10px");
       $(".mat-input-element").css("border","1px solid #d3d0d0");
       $(".mat-input-element").css("font-size","14px");
       $(".mat-input-element").css("background-color","#d3d0d0");
       $(".mat-input-infix input").css("height","30px");
       $(".mat-input-infix input").css("width","173px");
       $(".mat-input-infix input").css("margin-bottom","2.8px");
       $(".mat-input-infix input").css("background-color","#d3d0d0");
       $(".mat-form-field-infix").css("border-top","0");
       $(".mat-datepicker-toggle button").removeClass("mat-icon-button").addClass("btn btn-outline-secondary calendar");
       $(".mat-datepicker-toggle button span").remove();
       $(".mat-datepicker-toggle button div").remove();
       $(".mat-datepicker-toggle button").removeAttr("mat-icon-button");
       $(".mat-form-field-infix").css("width","0px");
       $(".mat-datepicker-toggle button").css("width","50px");
       $(".mat-datepicker-toggle button").css("height","32px");
       $(".mat-datepicker-toggle button").css("margin-left","0.5px");
       $(".mat-datepicker-toggle button").css("margin-bottom","2px");
       $(".mat-datepicker-toggle button").css("background-image","url(/assets/calendar.png)");
       $(".mat-datepicker-toggle button").css("background-repeat","no-repeat");
       $(".mat-datepicker-toggle button").css("background-size","23px");
       $(".mat-datepicker-toggle button").css("background-position","center");
       $(".mat-input-infix input").css("padding-left","16px");
     //end of matDatepicker Mod
}

fileImage(filyType){
  if(filyType.indexOf('pdf') != -1 || filyType.indexOf('PDF') != -1) {
    this.fileTypeImagePath = '/assets/pdf.svg';
    this.fileTypeAlt = "PDF File"
    return true;
  }else if(filyType.indexOf('zip') != -1 || filyType.indexOf('ZIP') != -1){
    this.fileTypeImagePath = '/assets/zip.svg';
    this.fileTypeAlt = "ZIP File"
    return true;
  }else if(filyType.indexOf('xls') != -1 || filyType.indexOf('xlsx') != -1 || filyType.indexOf('XLS') != -1){
    this.fileTypeImagePath = '/assets/xls.svg';
    this.fileTypeAlt = "XLS File"
    return true;
  }else if(filyType.indexOf('doc') != -1 || filyType.indexOf('docx') != -1 || filyType.indexOf('DOC') != -1){
    this.fileTypeImagePath = '/assets/docx.svg';
    this.fileTypeAlt = "DOC File"
    return true;
  }
 }

 getGlobalID() {
  try {
    this.brokerService.getGlobalID(this.user.OptNum).subscribe(data => { // ResourcePage: 0 - Page Section: Contacts\\Document\\SB , 126911
      try {
        this.globalIDs = data[0];

        if (this.globalIDs.length == 0) {
          this.phSBGlobalID = true;
          this.globalIDNum1 = ""         
        } else if(this.globalIDs.length == 1){
          this.phSBGlobalID = true;
          if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9) { 
                this.globalIDNum1 = "Your SSN";
              } else {
                this.globalIDNum1 = this.globalIDs[0].ParticipantNbr; 
              }
        }
        else{
          if (this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9) {
            this.phSBGlobalID = true;
            this.globalIDNum1 = "Your SSN"
          } else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) == 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) != 9){
            this.phSBGlobalID = false;
            this.globalIDNum1 = "(1) Your SSN"
            this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
          }
          else if(this.globalIDs[0].ParticipantNbr.toString().charAt(0) != 9 && this.globalIDs[1].ParticipantNbr.toString().charAt(0) == 9){
            this.phSBGlobalID = false;
            this.globalIDNum1 = "(1) Your SSN"
            this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
          }
          else{
            this.phSBGlobalID = false;
            this.globalIDNum1 = "(1) " + this.globalIDs[0].ParticipantNbr;
            this.globalIDNum2 = "(2) " + this.globalIDs[0].ParticipantNbr;
          }

        }

        // if(this.globalIDs.length == 0) {
        //   this.phSBGlobalID = true;
        //   this.globalIDNum1 = "";
        // } else if(this.globalIDs.length == 1) {
        //   this.phSBGlobalID = true;
  
        //   if(this.globalIDs[0].UserHistoryId.toString().charAt(0) != 9) { 
        //     this.globalIDNum1 = "Your SSN";
        //   } else {
        //     this.globalIDNum1 = this.globalIDs[0].UserHistoryId; 
        //   }
        // } 

      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "getGlobalID");
    error.message += errorLocation;
    throw error;
  }
 }


 checkBrokersInfo(broker){
  event.stopPropagation();
 

  if(this.ubsLoaded == true)
    this.resourceLoading = false;
  else
    this.resourceLoading = true;
  
  if(this.sbLoaded == true)
    this.resourceLoading = false;
  else
    this.resourceLoading = true;
  
  if(this.bigLoaded == true)
    this.resourceLoading = false;
  else
    this.resourceLoading = true;
  
  if(this.ncbLoaded == true)
    this.resourceLoading = false;
  else
    this.resourceLoading = true;
  

  
  this.brokerService.getBrokersAccounts(this.user.OptNum).pipe(map(res => res)).subscribe(data => {
    let brokerAccounts = data[0];
    if(brokerAccounts.length > 0) {

      for(let i = 0; i < brokerAccounts.length; i++) {
if(broker == "MS"){

  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });

  this.brokerHeader = "Morgan Stanley";
  
  this.getGlobalID();

  this.displayPersonalUbs = false;
  this.displayPersonalBig = false;
  this.displayPersonalNcb = false;

  this.bodyData[5] = PageSection.sb;
  this.bodyData[6] = 1;
  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
    
      let msData = JSON.parse(data.body);
      let msBody;

      if(msData.data) { // length > {}
      msBody = msData.data;

        if (msBody.length > 0){
          this.sbDesc = msBody[0].Body_Desc;
          this.displayPersonalSb = true;
          }
        else {
          this.displayPersonalSb = false;
          this.sbDesc = "";
        } 
      }


    this.bodyData[5] = PageSection.sbDoc;
    this.bodyData[6] = 0;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
    let msDocData = JSON.parse(data.body);
    let msDocBody;
    
    if(msDocData.data) { // length > {}
                    msDocBody = msDocData.data;
                    if(msDocBody.length > 0) {
                      this.sbDoc = msDocBody;
    
                      for(let i=0; i<this.sbDoc.length; i++){
                        this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                      }
                    }
                    else{
                      this.sbDoc = null;
                    }
                  }
              
    });
    this.sbLoaded = true;
this.resourceLoading = false;
  });

}else if(broker == "UBS"){
  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });
  

  this.brokerHeader = "UBS Financial Services Inc";
  this.displayPersonalSb = false;
  this.displayPersonalBig = false;
  this.displayPersonalNcb = false;

this.ubsAccount = brokerAccounts[i].Account;
this.bodyData[5] = PageSection.ubs;
this.bodyData[6] = 1;


this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  let ubsData = JSON.parse(data.body);
  let ubsBody;

  if(ubsData.data) { // length > {}
  ubsBody = ubsData.data;
  if(ubsBody.length > 0) {
    this.ubsDesc = ubsBody[0].Body_Desc;
    this.displayPersonalUbs = true;
  }
  else {
  this.displayPersonalUbs = false;
    this.ubsDesc = "";
  }
}

this.bodyData[5] = PageSection.ubsDoc;
this.bodyData[6] = 0;

this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

  let ubsDocData = JSON.parse(data.body);
  let ubsDocBody;
  
  if(ubsDocData.data) { // length > {}
      ubsDocBody = ubsDocData.data;
      if(ubsDocBody.length > 0){
        this.ubsDoc = ubsDocBody;

        for(let i=0; i<this.ubsDoc.length; i++){
          this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
        }
      }
      else{
        this.ubsDoc = null;
      }
    }
});
this.ubsLoaded = true;
this.resourceLoading = false;
});
}else if(broker == "BIG"){
  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });
  

  this.brokerHeader = "Banco de Investimento Global";

  this.displayPersonalSb = false;
  this.displayPersonalUbs = false;
  
  this.displayPersonalNcb = false;

  this.bigAccount = brokerAccounts[i].Account;
  this.bodyData[5] = PageSection.big;
  this.bodyData[6] = 1;
  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
  
    let bigData = JSON.parse(data.body);
    let bigBody;

    if(bigData.data) { // length > {}
    bigBody = bigData.data;
    if(bigBody.length > 0){
      this.bigDesc = bigBody[0].Body_Desc;
      this.displayPersonalBig = false;
    }else {
      this.displayPersonalBig = false;
      this.bigDesc = "";
    }
  }
  this.bigLoaded = true;
this.resourceLoading = false;
  });

}else if(broker == "TA"){
  (<any>$("#brokerContact")).modal({
    backdrop: 'static',
    keyboard: false
  });
  

  this.brokerHeader = "Computershare (formerly National City Bank)";
  this.displayPersonalSb = false;
  this.displayPersonalUbs = false;
  this.displayPersonalBig = false;
  

  this.ncbAccount = brokerAccounts[i].Account;
  this.bodyData[5] = PageSection.ncb;
  this.bodyData[6] = 1;
  this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

    let taData = JSON.parse(data.body);
    let taBody;

    if(taData.data) { // length > {}
    taBody = taData.data;
    if(taBody.length > 0){
    this.ncbDesc = taBody[0].Body_Desc;
    this.displayPersonalNcb = true;
    }else {
      this.displayPersonalNcb = false;
      this.ncbDesc = "";
    }
  } 
  
  this.bodyData[5] = PageSection.ncbDoc;
  this.bodyData[6] = 0;         

     this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

      let taDocData = JSON.parse(data.body);
      let taDocBody;

      if(taDocData.data) { // length > {}
      taDocBody = taDocData.data;
      if(taDocBody.length > 0){
        this.ncbDoc = taDocBody;

        for(let i=0; i<this.ncbDoc.length; i++){
          this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
        }
      }else {
        this.displayPersonalNcb = false;
        this.ncbDoc = null;
      }
    }
     });
     this.ncbLoaded = true;
     this.resourceLoading = false;
  });

}else if(broker.indexOf('/')){
  this.brokerHeader = broker;
  let splittedBroker = broker.split('/');
  for(let i = 0; i < splittedBroker.length; i++){
    if(splittedBroker[i] == "MS"){
      this.getGlobalID();
      this.bodyData[5] = PageSection.sb;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { // ResourcePage: 1 - Page Section: Contacts\\SB
        
          let msData = JSON.parse(data.body);
          let msBody;

          if(msData.data) { // length > {}
          msBody = msData.data;

            if (msBody.length > 0){
              this.sbDesc = msBody[0].Body_Desc;
              this.displayPersonalSb = true;
              }
            else {
              this.displayPersonalSb = false;
              this.sbDesc = "";
            } 
          }


        this.bodyData[5] = PageSection.sbDoc;
        this.bodyData[6] = 0;

        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        let msDocData = JSON.parse(data.body);
        let msDocBody;
        
        if(msDocData.data) { // length > {}
                        msDocBody = msDocData.data;
                        if(msDocBody.length > 0) {
                          this.sbDoc = msDocBody;
        
                          for(let i=0; i<this.sbDoc.length; i++){
                            this.sbDoc[i]["DocumentFileName"] = this.sbDoc[i].Document_Name + "." + this.sbDoc[i].File_Type;
                          }
                        }
                        else{
                          this.sbDoc = null;
                        }
                      }
                  
        });
        this.sbLoaded = true;
    this.resourceLoading = false;
      });

    }else if(splittedBroker[i] == "UBS"){
      
    this.ubsAccount = brokerAccounts[i].Account;
    this.bodyData[5] = PageSection.ubs;
    this.bodyData[6] = 1;
    

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      let ubsData = JSON.parse(data.body);
      let ubsBody;

      if(ubsData.data) { // length > {}
      ubsBody = ubsData.data;
      if(ubsBody.length > 0) {
        this.ubsDesc = ubsBody[0].Body_Desc;
        this.displayPersonalUbs = true;
      }
      else {
      this.displayPersonalUbs = false;
        this.ubsDesc = "";
      }
    }

    this.bodyData[5] = PageSection.ubsDoc;
    this.bodyData[6] = 0;
    
    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

      let ubsDocData = JSON.parse(data.body);
      let ubsDocBody;
      
      if(ubsDocData.data) { // length > {}
          ubsDocBody = ubsDocData.data;
          if(ubsDocBody.length > 0){
            this.ubsDoc = ubsDocBody;
    
            for(let i=0; i<this.ubsDoc.length; i++){
              this.ubsDoc[i]["DocumentFileName"] = this.ubsDoc[i].Document_Name + "." + this.ubsDoc[i].File_Type;
            }
          }
          else{
            this.ubsDoc = null;
          }
        }
    });
    this.ubsLoaded = true;
    this.resourceLoading = false;
    });
    }else if(splittedBroker[i] == "BIG"){
      
      this.bigAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.big;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      
        let bigData = JSON.parse(data.body);
        let bigBody;

        if(bigData.data) { // length > {}
        bigBody = bigData.data;
        if(bigBody.length > 0){
          this.bigDesc = bigBody[0].Body_Desc;
          this.displayPersonalBig = false;
        }else {
          this.displayPersonalBig = false;
          this.bigDesc = "";
        }
      }
      this.bigLoaded = true;
    this.resourceLoading = false;
      });

    }else if(splittedBroker[i] == "TA"){
     
      this.displayPersonalSb = false;
      this.displayPersonalUbs = false;
      this.displayPersonalBig = false;
      

      this.ncbAccount = brokerAccounts[i].Account;
      this.bodyData[5] = PageSection.ncb;
      this.bodyData[6] = 1;
      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {

        let taData = JSON.parse(data.body);
        let taBody;

        if(taData.data) { // length > {}
        taBody = taData.data
        if(taBody.length > 0){
        this.ncbDesc = taBody[0].Body_Desc;
        this.displayPersonalNcb = true;
        }else {
          this.displayPersonalNcb = false;
          this.ncbDesc = "";
        }
      } 
      
      this.bodyData[5] = PageSection.ncbDoc;
      this.bodyData[6] = 0;         

         this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => { 

          let taDocData = JSON.parse(data.body);
          let taDocBody;

          if(taDocData.data) { // length > {}
          taDocBody = taDocData.data;
          if(taDocBody.length > 0){
            this.ncbDoc = taDocBody;

            for(let i=0; i<this.ncbDoc.length; i++){
              this.ncbDoc[i]["DocumentFileName"] = this.ncbDoc[i].Document_Name + "." + this.ncbDoc[i].File_Type;
            }
          }else {
            this.displayPersonalNcb = false;
            this.ncbDoc = null;
          }
        }
         });
         this.ncbLoaded = true;
         this.resourceLoading = false;
      });
  }
}
(<any>$("#brokerContact")).modal({
  backdrop: 'static',
  keyboard: false
});

}
}
}

});


  


}

navtoSpecificReportsPage(id){
  let reportId: object;

  reportId = {'ReportTypeId': id};
  sessionStorage.setItem("REPORT ID", JSON.stringify(reportId));
  window.open(this.globals.currentWebUrl + "/reportspage", "_blank");
}

  /*********************************************************************************************
    Method Name: getFile
    Description: Get file data

    #     Date            Modified by             Description
    1     04/23/19        Dalemark Suquib         Initial Draft
  **********************************************************************************************/
  getFile(file, checker) {
    try {
      let fileName: string;
      let fileType: string;
    
      if(checker == "static") {
        if(file.File_Type.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
        
        fileName = file.Document_Name + "." + file.File_Type;
      } else {
        if(file.indexOf('pdf') != -1) {
          fileType = 'application/pdf';
        } else {
          fileType = 'mime_type';
        }
    
        fileName = file;
      }
    
      this.staticContentService.getFile(fileName, "MD").subscribe(data => {
        try {
          let content = JSON.parse(data.body);
          let file = content;

          this.staticContentService.downloadFile(fileName, fileType, this.staticContentService.base64ToArrayBuffer(file.file));
          this.staticContentService.writeLog(fileName).pipe(map(res => res)).subscribe(data => {
            let writeLogResponse = data;
          });
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFile");
          error.message += errorLocation;
          throw error;
        }
    
        
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFile");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFile");
      error.message += errorLocation;
      throw error;
    }
  }

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let srcElement: HTMLImageElement = event.srcElement;
      let id: string = event.target.id;
      let target: string = event.target.target;
      if (target == ""){
        target = "_self";
      }
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != ""){
        if(href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1){ //if href value is a file
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){  //Redirect to another myHoldings page
          // href = href.split("/Default.aspx")[0]; 
          href = event.target.innerText;
          event.preventDefault();
          
          href in MyHoldingsPagesResources ? this.navToMyHoldingsPage(href) : "";
        } else if(href.indexOf("#") != -1) {
          event.preventDefault();
          id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
        }
      }
      else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
        event.preventDefault();
        window.open(href, target);
      }else{
        event.preventDefault();
        this.staticContentService.validateFileType(event);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "contactspage" : "";

    window.open(this.globals.currentWebUrl + "/" + page, "_blank");
    return false;
  }


} //end tag



