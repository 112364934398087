import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BillToItemState } from '../../../../../services/ea/states/billto.state';
import { Select, Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BillToItem } from '../../../../../services/ea/models/billto-model';
import { Globals } from '../../../../../globals';
import { ApiService } from '../../../../../services/ea/api/ApiService';
import { BillTo } from '../../../../../services/ea/actions/billto.actions';
import { ModalComponent } from '../../../../../services/ea/shared/modal/modal.component';
import { LoadingOverlayComponent } from '../../../../../services/ea/shared/loading-overlay/loading-overlay.component';
import * as moment from 'moment';
import { BdraccessService } from '../../../../../services/ea/api/BdrAccessService';

@Component({
  selector: 'app-btfieldinfo',
  templateUrl: './btfieldinfo.component.html',
  styleUrls: ['./btfieldinfo.component.css']
})
export class BtfieldinfoComponent implements OnInit {
  
  @Input('deltaFlag') deltaFlag: Array<any>;
  @Input('companyCode') companyCode: Array<any>;
  @Input('countryList') countryList: Array<any>;
  @Input('equityTypeList') equityTypeList: Array<any>;
  @Input('journalEntryTypeList') journalEntryTypeList: Array<any>;
  @Input('billToHeader') billToHeader: string;
  @Input('confirmMessage') confirmMessage: string;
  @Input('submitMessage') submitMessage: string;
  @Input('errorMessage') errorMessage: string;
  @Input('countryId') countryId: number;
  @Input('journalEntryTypeId') journalEntryTypeId: number;
  @Input('equityTypeId') equityTypeId: number;
  @Select(BillToItemState.getBillToItems) billToItems: any;
  @ViewChild(MatPaginator, { static: true }) paginator:  MatPaginator;
  @ViewChild('search_filter', { static: true }) searchFilter: ElementRef;

  dataSource: MatTableDataSource<BillToItem>;
  tableItems: any[] = [];
  displayedColumns: string[] = this.globals.billToDisplayedColumns;
  hasBillToItems: boolean = true;

  updatedTableItemsContent: string;
  setDate: string = "Bill To";
  pageSizeOption: number[] = [10, 25];
  pageIndex: number;
  totalNumOfPage: number;
  entriesStart: number;
  entriesEnd: number;
  entriesLength: number;
  origEntriesLength: number;

  filterValue: any = {
    COUNTRY: '',
    BILL_TO_CO_CD: '',
    INVOICE_FILE_CO_CD: '',
    EQUITY_TYPE: '',
    JOURNAL_ENTRY_TYPE: '',
    EFFECTIVE_POSTING_DATE: ''
  }

  updatedTableItems: any[] = [];
  newItem: boolean[] = [];
  hasNullCompanyCode: Array<any> = [];
  hasNullDeltaFlag: Array<any> = [];

  isLoading: boolean = true;
  disabledSubmitButton: boolean = true;

  origTableItems: string;
  origTableItemsArray: string[];

  constructor(
    private globals: Globals,
    private store: Store,
    private modal: MatDialog,
    private apiService: ApiService,
    private bdrAccessService: BdraccessService
  ) { }

  ngOnInit() {
    this.setDeltaFlag();
    this.setCompanyCode();
    this.setBillToItems();
  }

  ngAfterViewInit() {
    this.setDataSourceProperties();
  }

  ngAfterContentInit(){
    this.paginator._intl.itemsPerPageLabel = 'Show';
  }

  ngAfterContentChecked(){
    this.getNumberOfPage();
  }
 
  ngOnDestroy() {
    this.store.dispatch(new BillTo.ClearBillToItem);
  }

  /**********************************************************************************
  * Method Name: setDeltaFlag                                                                 
  * Description: Sets the delta flag lists.                 
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/29/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setDeltaFlag(): void {
    try {
      let deltaFlag = [{ DESC: '', VALUE: -1 }];

      for(let i = 0; i < this.deltaFlag.length; i++) {
        deltaFlag.push(this.deltaFlag[i]);
      }

      this.hasNullDeltaFlag = deltaFlag;
    } catch(error) {
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('setDeltaFlag', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: setCompanyCode                                                                 
  * Description: Sets the company code lists.               
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/29/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setCompanyCode(): void {
    try {
      let companyCode = [{ BILL_TO_CO_CD: '' }];

      for(let i = 0; i < this.companyCode.length; i++) {
        companyCode.push(this.companyCode[i]);
      }

      this.hasNullCompanyCode = companyCode;
    } catch(error) {
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('setCompanyCode', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: setBillToItems                                                                 
  * Description: Sets the bus data and to be displayed on the table.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/22/20             Dalemark P. Suquib             Initial draft  
  * 2         02/09/23             Johannah Jane Abuel            User Story 741876: EJET Enhancements
  ***********************************************************************************/
  setBillToItems(): void {
    this.billToItems.subscribe(data => {
      try {
        // START - 741876 //
        let newArr = data.slice().reverse();

        let filteredData = newArr.filter(function({BILL, BILL_TO_CO_CD, CANCEL_WITHHOLDING, COUNTRY, EFFECTIVE_POSTING_DATE, EQUITY_TYPE,
                                                   ID, INVOICE_FILE_CO_CD, JOURNAL_ENTRY_TYPE, JOURNAL_GROUP_ID, NEW_CO_CD, PAGE_ID, PARTNER_REC}){
          var key = `${INVOICE_FILE_CO_CD}${JOURNAL_ENTRY_TYPE}`;
          return !this.has(key) && this.add(key);
          }, new Set);
      
        let sortedData = filteredData.reverse();
        // END - 741876 //

        this.origTableItemsArray = [];
        this.origTableItems = JSON.stringify(sortedData);

        for(let i = 0; i < sortedData.length; i++) {
          this.origTableItemsArray.push(JSON.stringify(sortedData[i]));
        }

        this.tableItems = sortedData;
        this.dataSource = new MatTableDataSource(this.tableItems);
      } catch(error) {
        this.isLoading = false;
        this.bdrAccessService.setBillToError(true);
        error.message += this.apiService.setErrorContents('setBillToItems', 'Btfieldinfo');
        throw error;
      }
    });

    setTimeout(() => {
      this.checkNewItems();
      this.isLoading = false;
    });
  }

  /**********************************************************************************
  * Method Name: checkNewItems                                                                 
  * Description: Checks the new items in the table.                 
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         05/01/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  checkNewItems(): void {
    try {
      for(let i = 0; i < this.tableItems.length; i++) {
        if(this.tableItems[i].BILL_TO_CO_CD == null && this.tableItems[i].BILL == null &&
          this.tableItems[i].CANCEL_WITHHOLDING == null && this.tableItems[i].PARTNER_REC == null &&
          this.tableItems[i].EFFECTIVE_POSTING_DATE == null) {
            this.newItem[i] = true;
        } else {
            this.newItem[i] = false;
        }
      }
    } catch(error) {
      this.isLoading = false;
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('checkNewItems', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: setDataSourceProperties                                                                 
  * Description: Sets the datasource properties.               
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         06/18/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  setDataSourceProperties(): void {
    this.dataSource.filterPredicate = (data) => this.createFilter(data);
    this.dataSource.paginator = this.paginator;
    this.paginator.pageSize = this.pageSizeOption[0];
  }

  /**********************************************************************************************
  * Method Name: createFilter                                                                 
  * Description: Creates a filter predicate to be used in table search box.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         02/19/20             John Paul B. Fernando          Initial draft  
  * 2         04/23/20             Dalemark P. Suquib             Updated columns to be filtered  
  ***********************************************************************************************/
  createFilter(data: any): any {
    try {
      return (data.COUNTRY.toLowerCase().indexOf(this.filterValue.COUNTRY.toLowerCase()) !== -1)
        || (data.BILL_TO_CO_CD? data.BILL_TO_CO_CD.trim().toLowerCase().indexOf(this.filterValue.BILL_TO_CO_CD.trim().toLowerCase()) !== -1: false)
        || (data.INVOICE_FILE_CO_CD.trim().toLowerCase().indexOf(this.filterValue.INVOICE_FILE_CO_CD.trim().toLowerCase()) !== -1)
        || (data.EQUITY_TYPE.trim().toLowerCase().indexOf(this.filterValue.EQUITY_TYPE.trim().toLowerCase()) !== -1)
        || (data.JOURNAL_ENTRY_TYPE.trim().toLowerCase().indexOf(this.filterValue.JOURNAL_ENTRY_TYPE.trim().toLowerCase()) !== -1)
        || (data.EFFECTIVE_POSTING_DATE? data.EFFECTIVE_POSTING_DATE.trim().toLowerCase().indexOf(this.filterValue.EFFECTIVE_POSTING_DATE.trim().toLowerCase()) !== -1: false);
    } catch(error) {
      this.bdrAccessService.setCompanyCodeError(true);
      error.message += this.apiService.setErrorContents('createFilter', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************************
  * Method Name: applyFilter                                                                 
  * Description: Apply filter using the enterd word in search box.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/26/20             John Paul B Fernando           Initial draft  
  * 2         04/23/20             Dalemark P. Suquib             Updated columns to be filtered  
  ***********************************************************************************************/
  applyFilter(filterVal: string): void {
    try {      
      let filterValue = filterVal.trim().toLocaleLowerCase();
      this.filterValue.COUNTRY = filterValue;
      this.filterValue.BILL_TO_CO_CD = filterValue
      this.filterValue.INVOICE_FILE_CO_CD = filterValue;
      this.filterValue.EQUITY_TYPE = filterValue;
      this.filterValue.JOURNAL_ENTRY_TYPE = filterValue;
      this.filterValue.EFFECTIVE_POSTING_DATE = filterValue;
      this.dataSource.filter = JSON.stringify(this.filterValue);
      this.hasBillToItems = this.dataSource.filteredData.length > 0;

      this.paginator.firstPage();
      this.pageIndex = 1;
    } catch (error) {
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('applyFilter', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getNumberOfPage                                                                 
  * Description: Sets the number of pages and the index of the table.                       
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         01/26/20             John Paul B Fernando           Initial draft  
  * 2         03/31/20             John Paul B Fernando           Added logic to display number of entries.
  ***********************************************************************************/
  getNumberOfPage(){
    try {
      let dataLength;
      if(this.dataSource) {
        dataLength = this.filterValue.COUNTRY != "" ? this.dataSource.filteredData.length : this.dataSource.data.length;
        dataLength > 0 ? this.entriesStart = 1 + (this.paginator.pageIndex * this.paginator.pageSize) : this.entriesStart = 0;
        this.entriesEnd = dataLength < this.paginator.pageSize ? dataLength : this.paginator.pageSize + (this.paginator.pageSize * this.paginator.pageIndex);
        this.entriesEnd =  this.entriesEnd > dataLength ? dataLength : this.entriesEnd;
        this.entriesLength = dataLength;        
      }

      this.pageIndex = this.paginator.pageIndex + 1;
      this.paginator.length % this.paginator.pageSize == 0 ?  this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) : this.totalNumOfPage = Math.floor(this.paginator.length/this.paginator.pageSize) + 1;      
    } catch (error) {
      this.isLoading = false;
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('getNumberOfPage', 'Btfieldinfo');
      throw error;
    }      
  }

  /**********************************************************************************
  * Method Name: getIndex                                                                 
  * Description: Gets the index of the row with changes.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/27/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  getIndex(index: number, value: any, columnName: string): void {
    try {
      if(value === "" || value == -1) {
        value = null;
      }

      if(value != null && columnName == 'EFFECTIVE_POSTING_DATE') {
        value = moment(new Date(value)).format('MM/DD/YYYY');
      }

      if(columnName == 'BILL' || columnName == 'CANCEL_WITHHOLDING' || columnName == 'PARTNER_REC') {
        if(value) {
          value = Number(value);
        }
      }

      this.tableItems[index][columnName] = value;
      let tableRow = JSON.stringify(this.tableItems[index]);

      if(this.origTableItemsArray[index] != tableRow) {
        this.addUpdatedIndex(this.tableItems[index], index);
      } else {
        this.removeUpdatedIndex(index, value, columnName);
      }
    } catch(error) {
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('getIndex', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: addUpdatedIndex                                                                 
  * Description: Adds the index to the list of the updated rows.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/27/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  addUpdatedIndex(rowData: any, index: number): void {
    try {
      let itemIndex: number, hasItem: boolean = false;

      if(this.updatedTableItems.length > 0) {
        for(let i = 0; i < this.updatedTableItems.length; i++) {
          if(this.updatedTableItems[i].ID == index) {
            hasItem = true;
            itemIndex = i;
          }
        }

        if(hasItem) {
          this.updatedTableItems.splice(itemIndex, 1, rowData);
        } else {
          this.updatedTableItems.push(rowData);
        }
      } else {
        this.updatedTableItems.push(rowData);
      }

      if(this.updatedTableItems.length > 0) {
        this.disabledSubmitButton = false;
      }
    } catch(error) {
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('addUpdatedIndex', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: removeUpdatedIndex                                                                 
  * Description: Removes the index to the list of the updated rows.                  
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/27/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  removeUpdatedIndex(index: number, value: any, columnName: string): void {
    try {
      for(let i = 0; i < this.updatedTableItems.length; i++) {
        if(this.updatedTableItems[i].ID == index) {
          this.updatedTableItems[i][columnName] = value;

          if(JSON.stringify(this.updatedTableItems[i]) == this.origTableItemsArray[index]) {
            this.updatedTableItems.splice(i, 1);
          }
        }
      }

      if(this.updatedTableItems.length > 0) {
        this.disabledSubmitButton = false;
      } else {
        this.disabledSubmitButton = true;
      }
    } catch(error) {
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('removeUpdatedIndex', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: submitUpdatedTableItems                                                                 
  * Description: Submit the list of updated rows.                 
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/27/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  submitUpdatedTableItems(): void {
    try {
      window.scroll(0,0);
      if(this.updatedTableItems.length > 0 && !this.disabledSubmitButton) {
        this.updatedTableItemsContent = "";

        for(let i = 0; i < this.updatedTableItems.length; i++) {
          let countryIdContent = null;
          let equityTypeIdContent = null;
          let journalEntryTypeIdContent = null;

          for(let j = 0; j < this.equityTypeList.length; j++) {
            if(this.updatedTableItems[i].EQUITY_TYPE == this.equityTypeList[j].Equity_Type_Desc) {
              equityTypeIdContent = this.equityTypeList[j].Equity_Type_ID;
    
              for(let k = 0; k < this.countryList.length; k++) {
                if(this.updatedTableItems[i].COUNTRY == this.countryList[k].COUNTRY_DESC) {
                  countryIdContent = this.countryList[k].COUNTRY_ID;

                  for(let l = 0; l < this.journalEntryTypeList.length; l++) {
                    if(this.updatedTableItems[i].JOURNAL_ENTRY_TYPE == this.journalEntryTypeList[l].JOURNAL_GROUP_DESC) {
                      journalEntryTypeIdContent = this.journalEntryTypeList[l].JOURNAL_GROUP_ID;
                    }
                  }
                }
              }
            }
          }

          if(countryIdContent && equityTypeIdContent && journalEntryTypeIdContent) {
            this.updatedTableItemsContent = this.updatedTableItemsContent + 
            countryIdContent + '~' +
            this.updatedTableItems[i].INVOICE_FILE_CO_CD + '~' +
            this.updatedTableItems[i].BILL_TO_CO_CD + '~' +
            equityTypeIdContent + '~' +
            journalEntryTypeIdContent + '~' +
            this.updatedTableItems[i].BILL + '~' +
            this.updatedTableItems[i].CANCEL_WITHHOLDING + '~' +
            this.updatedTableItems[i].PARTNER_REC + '~' +
            this.updatedTableItems[i].EFFECTIVE_POSTING_DATE + '|';
          }
        }

        if(this.updatedTableItemsContent.includes('null')) {
          this.openConfirmModal(this.errorMessage, false);
        } else {
          this.openConfirmModal(this.confirmMessage, true, this.updatedTableItemsContent);
        }
      }
    } catch(error) {
      this.modal.closeAll();
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('submitUpdatedTableItems', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: openConfirmModal                                                                 
  * Description: Open confirmation modal.                 
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/29/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  openConfirmModal(body: string, hasBtn2: boolean, updatedTableItems?: string): void {
    try {
      let content: any = {
        header: this.billToHeader,
        body: body,
        hasBtn1: true,
        hasBtn2: hasBtn2,
        btn1Name: 'OK',
        btn2Name: 'Cancel'
      };

      let modalRef = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });

      modalRef.afterClosed().subscribe(result => {
        if(result && updatedTableItems) {
          this.modal.open(LoadingOverlayComponent, {disableClose: true, panelClass: 'overlay-modal'});
          this.popBillToData(updatedTableItems);
        }
      });
    } catch(error) {
      this.modal.closeAll();
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('openConfirmModal', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: popBillToData                                                                 
  * Description: Passes the final list of updated rows to the api.                 
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/29/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  popBillToData(updatedTableItems: string): void {
    let body = { 
      functionName: 'popBillToData',
      chvDataString: updatedTableItems 
    };

    this.apiService.postData(this.globals.manageBillToApi, body).subscribe(data => {
      try {
        this.modal.closeAll();
        if(data != null) {
          let body = data.body;
          if(body.length > 0) {
            let retVal = body[0].RetVal;
            if(retVal == 1) { // success
              this.updatedTableItems = [];
              this.disabledSubmitButton = true;
              this.openStatusModal('Successful');
            } else { // fail
              this.openStatusModal('Failed');
            }
          }
        }
      } catch(error) {
        this.modal.closeAll();
        this.bdrAccessService.setBillToError(true);
        error.message += this.apiService.setErrorContents('popBillToData', 'Btfieldinfo');
        throw error;
      } 
    }, error => {
      this.modal.closeAll();
      this.bdrAccessService.setBillToError(true);
      error.error.message += this.apiService.setErrorContents('popBillToData', 'Btfieldinfo');
      throw error;
    });
  }

  /**********************************************************************************
  * Method Name: openStatusModal                                                                 
  * Description: Open status modal.                 
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/29/20             Dalemark P. Suquib             Initial draft  
  ***********************************************************************************/
  openStatusModal(status: string) {
    try {
      let content: any = {
        header: this.billToHeader,
        body: this.submitMessage.replace('<status>', status),
        hasBtn1: true,
        hasBtn2: false,
        btn1Name: 'OK',
        btn2Name: null
      };

      let statusRef = this.modal.open(ModalComponent, {
        panelClass: 'custom-modal',
        disableClose: true,
        data: {
          content
        }
      });

      statusRef.afterClosed().subscribe(result => {
        if(result && status == 'Successful') {
          this.isLoading = true;
          this.searchFilter.nativeElement.value = null;
          this.getBusDataBillTo();
        }
      });
    } catch(error) {
      this.modal.closeAll();
      this.bdrAccessService.setBillToError(true);
      error.message += this.apiService.setErrorContents('openStatusModal', 'Btfieldinfo');
      throw error;
    }
  }

  /**********************************************************************************
  * Method Name: getBusDataBillTo                                                                 
  * Description: Calls the getBusDataBillTo function to reload table.                 
  *                                                                                          
  * #         Date                 Modified by                    Description                             
  * 1         04/29/20             Dalemark P. Suquib             Initial draft  
  * 2         02/13/23             Johannah Jane Abuel            User Story 741876: EJET Enhancements
  ***********************************************************************************/
  getBusDataBillTo(): void {
    let body = { 
      functionName: 'getBusDataBillTo',
      countryId: this.countryId,
      equityTypeId: this.equityTypeId,
      journalEntryTypeId: this.journalEntryTypeId
    };

    this.store.dispatch(new BillTo.ClearBillToItem);
    this.apiService.postData(this.globals.manageBillToApi, body).subscribe(data => {
      try {
        if(data != null) {
          let body = data.body;
          if(body.length > 0) {
            this.tableItems = [];

            // START - 741876 //
            let newArr = body.slice().reverse();

            let filteredData = newArr.filter(function({BILL, BILL_TO_CO_CD, CANCEL_WITHHOLDING, COUNTRY, EFFECTIVE_POSTING_DATE, EQUITY_TYPE,
                                                       ID, INVOICE_FILE_CO_CD, JOURNAL_ENTRY_TYPE, JOURNAL_GROUP_ID, NEW_CO_CD, PAGE_ID, PARTNER_REC}){
              var key = `${INVOICE_FILE_CO_CD}${JOURNAL_ENTRY_TYPE}`;
              return !this.has(key) && this.add(key);
              }, new Set);
      
            let sortedData = filteredData.reverse();
            // END - 741876 //
            
            for(let i = 0; i < sortedData.length; i++) {
              this.store.dispatch(new BillTo.AddBillToItem(sortedData[i]));
            }

            this.tableItems = sortedData;
            this.checkNewItems();
            this.dataSource = new MatTableDataSource(this.tableItems);
            this.setDataSourceProperties();
            this.paginator._changePageSize(this.pageSizeOption[0]);
            this.isLoading = false;
          } else {
            let errorMsg = 'Unexpected error occurred. Please contact Equity Services Support.';
            this.openConfirmModal(errorMsg, false);
          }
        }

      } catch(error) {
        this.isLoading = false;
        this.modal.closeAll();
        this.bdrAccessService.setBillToError(true);
        error.message += this.apiService.setErrorContents('getBusDataBillTo', 'Btfieldinfo');
        throw error;
      }
    }, error => {
      this.isLoading = false;
      this.modal.closeAll();
      this.bdrAccessService.setBillToError(true);
      error.error.message += this.apiService.setErrorContents('getBusDataBillTo', 'Btfieldinfo');
      throw error;
    });
  }

}
