import { Component, OnInit} from '@angular/core';
import { ErrorNotificationService } from '../../services/ErrorNotificationService';
import { Globals } from '../../globals';
import { HoldingProgramDetailsESPPService } from '../HoldingProgramDetailsESPPService';
import * as moment from 'moment'
import { Router } from '@angular/router';
import { HoldingprogramdetailspageEsppComponent } from '../holdingprogramdetailspage-espp.component';
import { StaticContentService } from '../../services/StaticContentService';
import { PageSection, MyHoldingsPagesResources, tScript, CurrencySymbol } from '../../services/enumerations/enumerations';

@Component({
  selector: 'app-espp-mobile',
  templateUrl: './espp-mobile.component.html',
  styleUrls: ['./espp-mobile.component.css']
})
export class EsppMobileComponent implements OnInit {

  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  currencyCd = sessionStorage.getItem(this.globals.selectedCurrencyCd);
  errorLocation: string = " + [Component: Esppdetails, Method: methodName]";

  // Details Tab - Start
  programDetails: any;
  awardName: string;
  detailsAvailableToSell: number;
  detailsAvailableToSellValue: number;
  detailsBroker: string;
  detailsNextRelease: number;
  detailsSharesReleasing: number;
  detailsGrantAccepted: number;
  detailsUnreleased: number;
  detailsReleased: number;
  detailsExpiration: number;
  detailsForfeited: number;

  esppDetails: any;
  offeringPeriod: string;
  contribution: number;
  totalContributionUSD: number;
  totalContributionLoc: number;
  purchasePriceUSD: number;
  fmvAtPurchaseUSD: number;
  exchangeRateLoc: number;
  taxWithholdingMethod: string;
  account: string;
  sharesPurchased: number;
  withholdingForTaxes: number;
  fractionalShares: number;
  soldTransferred: number;
  netRemainAtBroker: number;
  localCurrencyCd: string;
  grossSharesPurchased: number;
  sharesWithheldTaxShares: string;
  sharesWithheldTaxUSD: string;
  sharesWithheldTaxLoc: string;
  fractionalSharesRefundedShares: number;
  fractionalSharesRefundedUSD: number;
  fractionalSharesRefundedLoc: number;
  netSharesPurchaseShares: number;
  netSharesPurchaseUSD: number;
  netSharesPurchaseLoc: number;
  xFmvAtPurchaseUSD: number;
  xFmvAtPurchaseLoc: number;
  fullValOfSharesUSD: number;
  fullValOfSharesLoc: any;
  totalDiscountUSD: number;
  totalDiscountLoc: number;
  actualTax: number;
  displayAccount: boolean = false;
  displayActualTax: boolean = false;
  hasLocCurrency: boolean;
  // Details Tab - End

  // Sales History Tab - Start
  period: number = 4;
  // Sales History Tab - End

  // Learn More Tab - Start
  commonQuestionsData: any;
    // temportary solution
    bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  guideBooksData: any;
  planDocumentData: any;
  prospectusDocumentData: any;
  countryAwardData: any;
  toolsAndReportsData: any;
  // Learn More Tab - End

  // Tooltip data  
  OfferingPeriod_mtt: string = tScript.esppdetailspage_op;
  PurchasePrice_mtt_espp: string = tScript.esppdetailspage_pp;
  SharesPurchased_mtt: string = tScript.esppdetailspage_sp;
  WithholdingForTaxes_mtt: string = tScript.esppdetailspage_wft;
  FractionalShares_mtt: string = tScript.esppdetailspage_fs;
  TotalContributions_mtt_espp: string = tScript.esppdetailspage_tc;
  SharesWithheld_mtt: string = tScript.esppdetailspage_sw;
  FractionalSharesRefunded_mtt: string = tScript.esppdetailspage_fsr;
  NetSharesPurchased_mtt: string = tScript.esppdetailspage_nsp;
  xFMVatPurchase_mtt: string = tScript.esppdetailspage_xfap;
  TotalDiscount_mtt: string = tScript.esppdetailspage_td;
  SalesHistory_Type: string = tScript.esppveipsaleshistorytab_type;
  SalesHistory_Price: string = tScript.esppveipsaleshistorytab_price;
  SalesHistory_Proceeds: string = tScript.esppveipsaleshistorytab_proceeds;
  SalesHistory_PurchaseDateValue: string = tScript.esppveipsaleshistorytab_pdv;
  currencyCdSymbol: any;

  constructor(
    private globals: Globals, 
    private router: Router,
    private errorNotificationService: ErrorNotificationService,
    private esppDetailsService: HoldingProgramDetailsESPPService,
    private esppComponent: HoldingprogramdetailspageEsppComponent,
    private staticContentService: StaticContentService
  ) { }

  ngOnInit() {
    if (this.currencyCd == null || this.currencyCd == "" || this.currencyCd == undefined || this.currencyCd == "null") {
      this.currencyCd = this.user.CurrencyCd;
    }
    this.currencyCd = this.currencyCd.split('"').join("");
    this.currencyCdSymbol = CurrencySymbol[this.currencyCd.replace(/\s/g, "")];
    if(this.bodyData != null) {
      if (this.bodyData[1] == 'Leave with pay'){
        this.bodyData[1] = 'Active';
      }
    }

    this.getProgramDetails();
  }

  /**********************************************************************************
    Method Name: navToPage
    Description: Redirects to a specified page

    #     Date            Modified by             Description
    1     06/20/19        Dalemark P. Suquib      Initial draft         
  **********************************************************************************/
  navToPage(strLoc) {
    this.router.navigate([strLoc]);
  }

  /**********************************************************************************
    Method Name: getProgramDetails
    Description: Get ESPP program details from session 

    #     Date            Modified by             Description
    1     06/20/19        Dalemark P. Suquib      Initial draft         
  **********************************************************************************/
  getProgramDetails() {
    this.programDetails = this.esppDetailsService.programDetails;

    if(this.programDetails != null && this.programDetails != undefined) {
      this.awardName = this.programDetails.AwardName;
      this.detailsAvailableToSell = this.programDetails.AvailableToSell;
      this.detailsAvailableToSellValue = this.programDetails.AvailableToSellValue;
      this.detailsBroker = this.programDetails.BrokerId;
      this.detailsNextRelease = this.programDetails.NextRelease;
      this.detailsSharesReleasing = this.programDetails.SharesReleasing;
      this.detailsGrantAccepted = this.programDetails.GrantAccepted;
      this.detailsUnreleased = this.programDetails.UnreleasedUnexercisable;
      this.detailsReleased = this.programDetails.ReleasedExercisableQty;
      this.detailsExpiration = this.programDetails.Expiration;
      this.detailsForfeited = this.programDetails.Forfeited;
    }

    this.getEsppDetails();
  }

  /**********************************************************************************
    Method Name: getEsppDetails
    Description: Get ESPP details from the parent component 

    #     Date            Modified by             Description
    1     06/20/19        Dalemark P. Suquib      Initial draft         
  **********************************************************************************/
  getEsppDetails() {
    this.esppDetails = this.esppDetailsService.esppDetails;

    if(this.esppDetails != null && this.esppDetails != undefined) {
      let offeringDate: string = this.esppDetails.OfferingPeriod.split(' - ');

      this.offeringPeriod = moment(offeringDate[0]).format('DD MMM YYYY') + ' ' + '-' + ' ' + moment(offeringDate[1]).format('DD MMM YYYY');
      this.contribution = this.esppDetails.Contribution;
      this.totalContributionUSD = this.esppDetails.TotalContributionUSD;
      this.purchasePriceUSD = this.esppDetails.PurchasePriceUSD;
      this.fmvAtPurchaseUSD = this.esppDetails.FMVAtPurchaseUSD;
      this.exchangeRateLoc = this.esppDetails.ExchangeRateLoc;

      if(this.esppDetails.TaxWithholdingMethod == "Payroll Withholding") {
        this.sharesWithheldTaxShares = "";
        this.sharesWithheldTaxUSD = "Refer to Earnings Statement";
        this.sharesWithheldTaxLoc = "Refer to Earnings Statement";
      } else {
        this.sharesWithheldTaxShares = this.esppDetails.SharesWithheldTax;
        this.sharesWithheldTaxUSD = this.esppDetails.SharesWithheldTax_ValueUSD;
        this.sharesWithheldTaxLoc = this.esppDetails.SharesWithheldTax_ValueLoc;
      }

      this.taxWithholdingMethod = this.esppDetails.TaxWithholdingMethod;

      if(this.esppDetails.Account !== "") { // checks the account if its empty 
        this.displayAccount = true;
      }

      this.account = this.esppDetails.Account;
      this.sharesPurchased = this.esppDetails.SharesPurchased;
      this.withholdingForTaxes = this.esppDetails.WithholdingForTaxes;
      this.fractionalShares = this.esppDetails.FractionalShares;
      this.soldTransferred = this.esppDetails.SoldTransferred;
      this.netRemainAtBroker = this.esppDetails.NetRemainingAtBroker; 
      this.localCurrencyCd = this.esppDetails.LocalCurrencyCd;

      if(this.localCurrencyCd != "USD") { 
        this.hasLocCurrency = true; 
      } else {
        this.hasLocCurrency = false; 
      }

      this.totalContributionLoc = this.esppDetails.TotalContributionLoc;
      this.grossSharesPurchased = this.esppDetails.GrossSharesPurchased;
      this.fractionalSharesRefundedShares = this.esppDetails.FractionalSharesRefunded;
      this.fractionalSharesRefundedUSD = this.esppDetails.FractionalSharesRefunded_ValueUSD;
      this.fractionalSharesRefundedLoc = this.esppDetails.FractionalSharesRefunded_ValueLoc;
      this.netSharesPurchaseShares = this.esppDetails.NetSharesPurchased;
      this.netSharesPurchaseUSD = this.esppDetails.NetSharesPurchasedShares_ValueUSD;
      this.netSharesPurchaseLoc = this.esppDetails.NetSharesPurchasedShares_ValueLoc;
      this.xFmvAtPurchaseUSD = this.esppDetails.xFMVAtPurchaseUSD;
      this.xFmvAtPurchaseLoc = this.esppDetails.xFMVAtPurchaseLoc;
      this.fullValOfSharesUSD = this.esppDetails.FullValueOfSharesUSD;
      this.fullValOfSharesLoc = this.esppDetails.FullValueOfSharesLoc;
      this.totalDiscountUSD = this.esppDetails.TotalDiscount_ValueUSD;
      this.totalDiscountLoc = this.esppDetails.TotalDiscountValueLoc;

      if(this.programDetails.OfferNum >= 42) {
        this.displayActualTax = true;
      }

      this.actualTax = this.esppDetails.ActualTax;
    }

    this.getCommonQuestionsData();
    this.getEsppSalesHist();
  }

   /**********************************************************************************
    Method Name: getEsppDetails
    Description: Get ESPP details from the parent component 

    #     Date            Modified by             Description
    1     06/20/19        Dalemark P. Suquib      Initial draft         
  **********************************************************************************/
  getEsppSalesHist() {
    // this.esppComponent.getESPPAwardSalesHist(this.programDetails.OfferNum);
  }

  /**********************************************************************************
    Method Name: getEsppCommonQuestions
    Description: Get ESPP common questions data from the parent component 

    #     Date            Modified by             Description
    1     06/20/19        Dalemark P. Suquib      Initial draft         
  **********************************************************************************/
  getCommonQuestionsData() {
    this.commonQuestionsData = this.esppComponent.esppCommonQuestionsContent;
    this.getGuidebooksTaxImplicationData();
  }

  /**********************************************************************************
    Method Name: getGuidebooksTaxImplicationData
    Description: Get ESPP guidebooks and tax implications data from the parent component 

    #     Date            Modified by             Description
    1     06/20/19        Dalemark P. Suquib      Initial draft         
  **********************************************************************************/
  getGuidebooksTaxImplicationData() {
    this.bodyData[5] = PageSection.esppAwardGuidebooks;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      let guideBooksData = JSON.parse(data._body);

      if(guideBooksData.body.length > 2) { // length > {}
        let guideBooksBody = JSON.parse(guideBooksData.body);
        
        if(guideBooksBody.data.length > 0) {
          this.guideBooksData = guideBooksBody.data[0].Body_Desc;
        } else {
          this.guideBooksData = null;
        }
      }

      this.bodyData[5] = PageSection.esppAwardPlanDocu;
      this.bodyData[6] = 1;

      this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
        let planDocumentData = JSON.parse(data._body);

        if(planDocumentData.body.length > 2) { // length > {}
          let planDocumentBody = JSON.parse(planDocumentData.body);
  
          if(planDocumentBody.data.length > 0) {
            this.planDocumentData = planDocumentBody.data[0].Body_Desc;
          } else {
            this.planDocumentData = null;
          }
        }
  
        this.bodyData[5] = PageSection.esppAwardProspectusDocu;
        this.bodyData[6] = 1;

        this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
          let prospectusDocumentData = JSON.parse(data._body);
  
          if(prospectusDocumentData.body.length > 2) { // length > {}
            let prospectusDocumentBody = JSON.parse(prospectusDocumentData.body);
                
            if(prospectusDocumentBody.data.length > 0) {
              this.prospectusDocumentData = prospectusDocumentBody.data[0].Body_Desc;
            } else {
              this.prospectusDocumentData = null;
            }
          }
    
          this.bodyData[5] = PageSection.esppAwardCountry;
          this.bodyData[6] = 1;

          this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
            let countryAwardData = JSON.parse(data._body);
    
            if(countryAwardData.body.length > 2) { // length > {}
              let countryAwardBody = JSON.parse(countryAwardData.body);
      
              if(countryAwardBody.data.length > 0) {
                this.countryAwardData = countryAwardBody.data[0].Body_Desc;
              } else {
                this.countryAwardData = null;
              }
            }
      
            this.getToolsAndReportsData();
          });
        });
      });
    });
  }

  /**********************************************************************************
    Method Name: getToolsAndReportsData
    Description: Get ESPP tools and reports data from the parent component 

    #     Date            Modified by             Description
    1     06/20/19        Dalemark P. Suquib      Initial draft         
  **********************************************************************************/
  getToolsAndReportsData() {
    this.bodyData[5] = PageSection.esppAwardToolsAndReports;
    this.bodyData[6] = 1;

    this.staticContentService.getResourcesDataFromMyR(this.bodyData).subscribe(data => {
      let toolsAndReportsData = JSON.parse(data._body);
  
      if(toolsAndReportsData.body.length > 2) { // length > {}
        let toolsAndReportsBody = JSON.parse(toolsAndReportsData.body);
        
        if(toolsAndReportsBody.data.length > 0) {
          this.toolsAndReportsData = toolsAndReportsBody.data;
        } else {
          this.toolsAndReportsData = null;
        }

        setTimeout(()=>{ this.staticContentService.inContentIconAppend() }, 500);
      }
    });
  }

  /**********************************************************************************
    Method Name: getSalesHistPeriod
    Description: Get ESPP tools and reports data from the parent component 

    #     Date            Modified by             Description
    1     06/20/19        Dalemark P. Suquib      Initial draft         
  **********************************************************************************/
 getSalesHistPeriod() {
   this.esppDetailsService.getSalesHistoryPeriod().subscribe(data => {

   });
 }

 /*****************************************************************************************
  * Method Name: inContentIcon                                                                 
  * Description: Call to insert icon besides in-content downloadable files        
  *                                                                                          
  * #    Date                 Modified by                           Description                             
  * 1    04/24/19             Joshua Paul J. Avecilla(JPA)          Initial draft
  * 2    04/25/19             JPA                                   Change file icon paths
*****************************************************************************************/
async inContentIcon(){
  $("[id='learn-more-tab']").attr("aria-label","Learn More for ESPP award");
  this.staticContentService.inContentIconAppend();
}

  /*****************************************************************************************
   * Method Name: getFileName                                                                 
   * Description: Gets the file name from the html element.    
   *                                                                                          
   * #    Date                 Modified by                 Description                             
   * 1    04/22/2019           Dalemark P. Suquib          Initial Draft
   * 2    05/21/19             KMN                         Bug 298844 fix
   *****************************************************************************************/
  getFileName(event) {
    try {
      let href: string = event.target.href;
      let id: string = event.target.id;
      let srcElement: HTMLImageElement = event.srcElement;
      let target: string = event.target.target;
      
      if(srcElement.src != undefined){
        this.staticContentService.validateFileType(event);
      }else if(href != undefined && href != "" ){
        if((href.indexOf(".pdf") != -1 || href.indexOf(".doc") != -1 || href.indexOf(".docx") != -1 || href.indexOf(".xls") != -1 || href.indexOf(".pdf") != -1 || href.indexOf(".zip") != -1 || href.indexOf(".ppt") != -1 || href.indexOf(".pptx") != -1)){ //if href value is a file
          this.staticContentService.validateFileType(event);
        }else if(href.indexOf("/Default.aspx") != -1){ //Redirect to another myHoldings page
          let innerTxt = event.target.innerText;
          event.preventDefault();

          if(innerTxt in MyHoldingsPagesResources){
            this.navToMyHoldingsPage(href);
          }else if(href.indexOf("Resources") != -1){
            this.navToMyHoldingsPage("Resources");
          }

        }else if(href.indexOf("#") != -1 && id != ""){ //redirect to the page if href is not file
          event.preventDefault();
          id == "managementcontactlink" ? this.navToMyHoldingsPage("Contacts") : "";
        }else if(href.indexOf("https") != -1){ //redirect to the page if href is not file
          window.open(href, target);
        }else{ //if has file but no filename text
          event.preventDefault();
          this.staticContentService.validateFileType(event);
        }
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFileName");
      error.message += errorLocation;
      throw error;
    }
  }

  navToMyHoldingsPage(page){
    page.indexOf("Resources") != -1 ? page = "resourcespage" : "";
    page.indexOf("Contacts") != -1 ? page = "contactspage" : "";
  
    window.open(this.globals.currentWebUrl + "/" + page, "_blank");
  }

  commafy(num: any, value?: number) {
    try {
    // if(typeof(num) == 'number') {
      if(/\d/.test(num)) {
        if(num != 0.00 || num != 0) {
          if(value > 0) {
            let number = (num*1).toLocaleString('en', {
              minimumFractionDigits: value
            });
            return number;
          } else {
            let number = (num*1).toLocaleString('en');
            return number;
          }
        } else {
          if(value < 3) {
            return '0.00';
          }else if(value == 4){
            return '0.0000';
          }
          return 0;
        }
      }
      return num;
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "commafy");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft    
   * 02/04/19             Dalemark P. Suquib          Added conditions for errors      
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Esppdetails") != -1 && this.user != null) {
                webPage = "Esppdetails";
                errorData.push(this.user.OptNum);
                errorData.push(webPage);
                errorData.push(errorMsg);

                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }

}
