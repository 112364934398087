import { Component, OnInit } from '@angular/core';
import { HoldingProgramDetailsOptionsService } from '../HoldingProgramDetailsOptionsService';
import { Globals } from '../../globals';
import {TooltipPosition} from '@angular/material/tooltip';
import { tScript } from '../../services/enumerations/enumerations';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-options-salable-card',
  templateUrl: './options-salable-card.component.html',
  styleUrls: ['./options-salable-card.component.css']
})
export class OptionsSalableCardComponent implements OnInit {


  grantDesc: string;
  grantNum: number;
  programType: number;
  grantID: string;
  grantDt: any;
  expirationDt: any;
  grantPriceUSD: number;
  shareOptGranted: number;
  optAcceptance: string;
  nextExercisable: any;
  sharesExercisable: number; 
  unvested: number;
  cancelled: any; 
  vestedbutUnexercisable: number;
  vestedandexercisable: number;
  exercised: number; 
  netremaining: number;
  HasSalable: any;
  HasExercisable: any;
  HasVest: any;
  agreementValid: string;
  showCancelled: any = true;
  showVestedButUnexercisable: any = false;
  showAgreementContainer: any = true;
  showAgreementifYes: any = false;
  showNextExercisable: any = false;
  showSharesExercisable: any = false;
  showVest: any = true;
  showExercisable: boolean = false;
  showSalable: boolean = false;
  ifvestOnly: boolean = false;
  ifExerciseOnly: boolean = false;
  shareActAgreementActionReq: boolean = false;
  showVestIcon: boolean = true;
  showExercisableIcon: boolean = true;
  showSalableIcon: boolean = true;
  ifSaleOnly: boolean = false;
  vestDataSource: any;
  vestTotalShares: number = 0;
  ExercisableDataSource: any;
  ExercisableTotalShares: number = 0;
  salableDataSource: any;
  salableTotalShares: number = 0;
  errorDetailsTab: boolean = false;
  errorLocation: string = " + [Component: Optionsdetails, Method: methodName]";
  programDetails: any = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
  positionOption: TooltipPosition[]=['after', 'before', 'above', 'below', 'left', 'right'];
  pog_expiration: string = tScript.optionsdetailspage_exp;
  pog_grantPriceUSD: string = tScript.optionsdetailspage_gp;
  sas_unvested: string = tScript.optionsdetailspage_uv;
  sas_vestedAndExercisable: string = tScript.optionsdetailspage_ve;
  sas_exercised: string = tScript.optionsdetailspage_exc;
  sas_netRemainingAtBroker = tScript.optionsdetailspage_nrb;
  displayedSalableColumns: string[] = ['Date', 'Shares', 'Status'];
  OptionsLoading: boolean = true;
  constructor(private holdingProgramDetailsOptionsService: HoldingProgramDetailsOptionsService, private globals: Globals) { }

  ngOnInit() {
    // BACK BUTTON BUG - START
    sessionStorage.setItem("HoldingSummary Breadcrumb", JSON.stringify(false));
    let backUrl: boolean = JSON.parse(sessionStorage.getItem("Back Url"));
    let detailsStorage = JSON.parse(sessionStorage.getItem("Details Storage"));

    if(backUrl) {
      if(detailsStorage.length > 0) {
        this.programDetails = detailsStorage[detailsStorage.length - 1];
      }
    } else {
      this.programDetails = JSON.parse(sessionStorage.getItem(this.globals.programDetailsKey));
    }
    // BACK BUTTON BUG - END

    this.getOptionsSummary(this.programDetails.GrantNum);
  }

/**********************************************************************************
      Method Name: getOptionsSummary
      Description: Get Options details tab

      Date            Modified by       Description
      01/30/19        Katrina Narag     Initial draft         
  **********************************************************************************/
 async getOptionsSummary(grantNum){
  try {
    this.holdingProgramDetailsOptionsService.getAwardDetailsOptions(grantNum).pipe(map(res => res)).subscribe(data => {
      try {
        let optSummary = data[0][0];
        this.holdingProgramDetailsOptionsService.optionsSummary = optSummary;
        this.grantNum = optSummary.GrantNum;
        this.grantDesc = optSummary.GrantDesc;
        this.grantID = optSummary.GrantId;
        this.grantDt = optSummary.GrantDate;
        this.expirationDt = optSummary.ExpirationDt;
        this.grantPriceUSD = optSummary.GrantPrice;
        this.shareOptGranted = optSummary.SharesGranted;
        this.optAcceptance = optSummary.GrantAccepted;
        this.nextExercisable = optSummary.NextExerciseable;
        this.sharesExercisable = optSummary.SharesNextExerciseable;
        this.unvested = optSummary.Unvested;
        this.cancelled = optSummary.ForfeitedQty;
        this.vestedbutUnexercisable = optSummary.VestedButUnExercisable;
        this.vestedandexercisable = optSummary.VestedAndExercisable;
        this.exercised = optSummary.Exercised;
        this.netremaining = optSummary.NetRemainingAtBroker;
        this.HasSalable = optSummary.HasSalable;
        this.HasExercisable = optSummary.HasExercisable;
        this.HasVest = optSummary.HasVest;
        this.programType = optSummary.ProgramsType;
        this.agreementValid = optSummary.IsGrantAgreementValid;
  
        //Check conditions
        let awardName = this.grantDesc;
        
        if(this.cancelled == 0) this.showCancelled = false;
        if(this.HasExercisable == 1)  this.showVestedButUnexercisable = true;
        if(this.agreementValid == "Y"){
  
          this.showAgreementContainer = false;
          this.showAgreementifYes = true;
          
          if(this.optAcceptance == "Action Required"){
            this.shareActAgreementActionReq = true;
          }
          if((this.shareOptGranted - this.vestedandexercisable) > 0) {
            this.showNextExercisable = true;
            this.showSharesExercisable = true;
          }
  
          if((this.shareOptGranted - this.cancelled) > 0){
            this.showNextExercisable = true;
            this.showSharesExercisable = true;
          }
  
          if(this.nextExercisable == null || this.nextExercisable == "" || this.nextExercisable.isNull){
            this.showNextExercisable = false;
            this.showSharesExercisable = false;
          }
        }else{
          this.showAgreementContainer = false;
          this.showAgreementifYes = false;
          this.showNextExercisable = false;
          this.showSharesExercisable = false;
        }
  
        if((this.shareOptGranted - this.cancelled) == 0){
          this.showVest = false;
          this.showExercisable = false;
          this.showSalable = false;
        }else{
  
          if(this.HasVest != 0 && this.HasExercisable == 0 && this.HasSalable == 0){
            this.ifvestOnly = true;
            this.showVestIcon = false;
          }else if(this.HasVest == 0 && this.HasExercisable != 0 && this.HasSalable == 0){
            this.ifExerciseOnly = true;
            this.showExercisableIcon = false;
          }else if(this.HasVest == 0 && this.HasExercisable == 0 && this.HasSalable != 0){
            this.ifSaleOnly = true;
            this.showSalableIcon = false;
          }
  
          //Vest accordion data
          if(this.HasVest == 0){
            this.showVest = false;
          }else{
            this.vestDataSource = data[1];
  
            for(let i = 0; i < this.vestDataSource.length; i++) {
              this.vestTotalShares += this.vestDataSource[i].Shares;
            }
          }
  
          //Exercisable accordion data
          if(this.HasExercisable == 0){
            this.showExercisable = false;
          }else{
            this.ExercisableDataSource = data[2];
  
            for(let i = 0; i < this.ExercisableDataSource.length; i++) {
              this.ExercisableTotalShares += this.ExercisableDataSource[i].Shares;
            }
          }
  
          //Salable accordion data
          if(this.HasSalable == 0){
            this.showSalable = false;
          }else{
            this.salableDataSource = data[3];
            
            for(let i = 0; i < this.salableDataSource.length; i++) {
              this.salableTotalShares += this.salableDataSource[i].Shares;
            }
          }
        }
  
        this.OptionsLoading = false;
      } catch(error) {
        this.errorDetailsTab = true;
        let errorLocation = this.errorLocation.replace("methodName", "getOptionsSummary");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorDetailsTab = true;
      let errorLocation = this.errorLocation.replace("methodName", "getOptionsSummary");
      error.error.message += errorLocation;
      throw error.error;
    });
  } catch(error) {
    this.errorDetailsTab = true;
    let errorLocation = this.errorLocation.replace("methodName", "getOptionsSummary");
    error.message += errorLocation;
    throw error;
  }
}

commafy(num: any, value?: number) {
  try {
    // if(typeof(num) == 'number') {
    if(/\d/.test(num)) {
      if(num != 0.00 || num != 0) {
        if(value > 0) {
          let number = (num*1).toLocaleString('en', {
            minimumFractionDigits: value
          });
          return number;
        } else {
          let number = (num*1).toLocaleString('en');
          return number;
        }
      } else {
        if(value < 3) {
          return '0.00';
        }else if(value == 4){
          return '0.0000';
        }
        return 0;
      }
    }
    return num;
  } catch(error) {
    let errorLocation = this.errorLocation.replace("methodName", "commafy");
    error.message += errorLocation;
    throw error;
  }
}



}
