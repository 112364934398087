import { Component, OnInit } from '@angular/core';
import { PageSection } from '../../services/enumerations/enumerations';
import { Globals } from '../../globals';
import { StaticContentService } from '../../services/StaticContentService';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-importantdates',
  templateUrl: './importantdates.component.html',
  styleUrls: ['./importantdates.component.css']
})
export class ImportantdatesComponent implements OnInit {
  
  bodyData: any = JSON.parse(sessionStorage.getItem("Body Data"));
  user = JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
  purchaseProgram: string;
  dataTrading: any;
  expandResource: string = "collapse show";
  dataTradingDesc: any;
  expandResourceBool: boolean = true;
  dataSharesPlan: any;
  expandPlan: string = "collapse";
  expandPlanBool: boolean = false;
  loading: boolean = true;
  errorImportantDates: boolean = false;
  errorLocation: string = " + [Component: Importantdates, Method: methodName]";

  constructor(private globals: Globals, private staticContent : StaticContentService, private router: Router) { }

  ngOnInit() {
    this.getImportantDate();
  }
  
      /**********************************************************************************
      Method Name: getImportantDate
      Description: Get and display data from myResources

      Date            Modified by                  Description
      04/11/19        Joshua Paul Avecilla(JPA)    Removed inner condition in shares plan accordion for bug fix.     

  **********************************************************************************/
 getImportantDate(){
  try {
    this.bodyData[5] = PageSection.SrExecTrading;
    this.bodyData[6] = 1;

    if (this.user.JobCd != '10000025') {
     this.purchaseProgram = "Employee Share Purchase Plan(ESPP)";
    }else{
     this.purchaseProgram = "Voluntary Equity Investment Program(VEIP)";
    }     

    this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {   
      try {
        let content = JSON.parse(data._body);  

        if(content.name == "TokenExpiredError"){
          this.router.navigate(['homepage']);
        }

        let contentTradingchecker;        
        if(content.body == "{}"){
          this.dataTrading = "";
          this.expandResource = "collapse";
        }
        
        if(content.body.length > 2) {      
        contentTradingchecker = content.body;      
        contentTradingchecker = JSON.parse(content.body);  
        
          if (contentTradingchecker.data.length > 0){// length > {}
            this.dataTrading = contentTradingchecker.data[0];
            this.dataTradingDesc = this.dataTrading.List_Name; 
            this.dataTrading = this.dataTrading.Body_Desc;
            this.expandResourceBool = true;

            if(this.dataTrading.indexOf('<font color="#0000ff">Restricted Persons Trading</font>') != -1){
              this.dataTrading = this.dataTrading.split('<font color="#0000ff">Restricted Persons Trading</font>').join("Restricted Persons Trading");
            }
          }else{
            this.dataTrading = "";
            this.expandResource = "collapse";
            this.expandResourceBool = false;
          }                   
      }
  
        if(this.bodyData[2] != "Employees"){
          this.bodyData[5] = PageSection.importantDateVEIP;
          this.bodyData[6] = 1;
        }else{
          this.bodyData[5] = PageSection.importantDateESPP;
          this.bodyData[6] = 1;
        }      
  
        this.staticContent.getResourcesDataFromMyR(this.bodyData).pipe(map(res => res)).subscribe(data => {   
          try {
            let content = JSON.parse(data._body);

            if(content.name == "TokenExpiredError"){
              this.router.navigate(['homepage']);
            }
            
            let contentSharePlanChecker;
            if(content.body.length > 2) { // length > {}
    
            contentSharePlanChecker = JSON.parse(content.body); 
    
                if(contentSharePlanChecker.data.length > 0){
                  this.dataSharesPlan = contentSharePlanChecker.data[0];       
                         
                  this.dataSharesPlan = this.dataSharesPlan.Body_Desc;
                 
              } else {
                this.expandPlan = "collapse";
                this.expandPlanBool = false;
    
              } 
            }         
            this.loading = false;
            // setTimeout(()=>{ this.manipulateDataHtml() }, 500); 
          } catch(error) {
            this.errorImportantDates = true;
            this.loading = false;
            let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
            error.message += errorLocation;
            throw error;
          }
        }, error => {
          this.errorImportantDates = true;
          this.loading = false;
          let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
          error.error.message += errorLocation;
          throw error.error;
        }); 
      } catch(error) {
        this.errorImportantDates = true;
        this.loading = false;
        let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
        error.message += errorLocation;
        throw error;
      }
    }, error => {
      this.errorImportantDates = true;
      this.loading = false;
      let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
      error.error.message += errorLocation;
      throw error.error;
    });    
  } catch(error) {
    this.errorImportantDates = true;
    this.loading = false;
    let errorLocation = this.errorLocation.replace("methodName", "getImportantDate");
    error.message += errorLocation;
    throw error;
  }
}

// manipulateDataHtml(){
//   $("a").css('text-decoration', 'underline');
// }

}
