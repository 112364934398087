
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable()
export class HoldingProgramDetailsESPPService {

  programDetails: any;
  esppDetails: any;

  constructor(private globals: Globals, private httpClient: HttpClient) { }

  getESPPdetails(optNum, offerNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let esppDetailsUrl = this.globals.getAwardDetailsESPPAPI;

    esppDetailsUrl = esppDetailsUrl.replace("[param1]", optNum); //'417986'
    esppDetailsUrl = esppDetailsUrl.replace("[param2]", offerNum); //'44'

    try {
      const api = apiUrl + esppDetailsUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getEsppSalesHistory(optNum, offerNum, period, dateFrom, dateTo) {
    let regEx: any = /\'/gi;
    let apiUrl = this.globals.currentAPIUrl;
    let esppSalesHistUrl = this.globals.getSalesHistESPPAPI;

    esppSalesHistUrl = esppSalesHistUrl.replace("[param1]", optNum); //optNum - 199181
    esppSalesHistUrl = esppSalesHistUrl.replace("[param2]", offerNum); //offerNum - 11
    esppSalesHistUrl = esppSalesHistUrl.replace("[param3]", period);
    esppSalesHistUrl = esppSalesHistUrl.replace("[param4]", dateFrom);
    esppSalesHistUrl = esppSalesHistUrl.replace("[param5]", dateTo);
    esppSalesHistUrl = esppSalesHistUrl.replace(regEx, "");

    try {
      const api = apiUrl + esppSalesHistUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getSalesHistoryPeriod() {
    let apiUrl = this.globals.currentAPIUrl;
    let salesHistoryPeriodUrl = this.globals.staticContentAPI + this.globals.staticSalesHistoryPeriod;

    try {
      const api = apiUrl + salesHistoryPeriodUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  } 
}
