import { CompCodeItem } from "../models/compcode-model";
import { Selector, State, Action, StateContext } from "@ngxs/store";
import * as moment from 'moment';
import { CompanyCode } from "../actions/compcode.actions";


export class CompCodeItemStateModel{
    items: CompCodeItem[]
}

@State<CompCodeItemStateModel>({
    name: "compcodeitems",
    defaults: {
        items: []
    }
})

export class CompCodeItemState{
    @Selector()
    static getCompCodeItems(state: CompCodeItemStateModel){
        return state.items
    }

    @Action(CompanyCode.Add_Comp_Code_Item)
    add(
        store: StateContext<CompCodeItemStateModel>,
        action: CompanyCode.Add_Comp_Code_Item
    ){
        const state = store.getState();
        action.payload.ID = state.items.length + 1;
        action.payload.EFFECTIVE_POSTING_DATE = moment.utc(new Date(action.payload.EFFECTIVE_POSTING_DATE)).format("MM/DD/YYYY");
        store.patchState({
            items: [...state.items, action.payload]
        })
    }

    @Action(CompanyCode.Clear_Comp_Code_Item)
    clear(
        store: StateContext<CompCodeItemStateModel>
    ){
        store.setState({
            items: []
        })
    }
}