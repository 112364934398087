import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-createreports',
  templateUrl: './createreports.component.html',
  styleUrls: ['./createreports.component.css']
})
export class CreatereportsComponent implements OnInit {

  isError: boolean = false;
  hasBtn1: boolean = false;
  hasBtn2: boolean = false;
  btn1Name: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.isError = this.data.content.isError;
    this.hasBtn1 = this.data.content.hasBtn1;
    this.hasBtn2 = this.data.content.hasBtn2;
    this.btn1Name = this.data.content.btn1Name;
  }

}
