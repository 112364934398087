
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
// import { Http, Headers, RequestOptions } from '@angular/http';
import { map, catchError, retry } from 'rxjs/operators';

@Injectable()
export class VeiptransactionService {

  headers: Headers;
  // options: RequestOptions;
  token: string = sessionStorage.getItem("msal.idtoken");
  

  constructor(private globals: Globals, private httpClient: HttpClient) { 
    
  }

  
  getVEIPActionItem(OptNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let veipActionItemUrl = this.globals.getVEIPActionItemAPI;

    veipActionItemUrl = veipActionItemUrl.replace("[param1]", OptNum);
    
    try {
      const api = apiUrl + veipActionItemUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getVEIPOfferingDetails(OptNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let veipOfferingDetailsUrl = this.globals.getVEIPOfferingDetailsAPI;

    veipOfferingDetailsUrl = veipOfferingDetailsUrl.replace("[param1]", OptNum);
    
    try {
      const api = apiUrl + veipOfferingDetailsUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getVEIPDefaultEligibility(OptNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let veipDefaultEligibilityUrl = this.globals.getVEIPDefaultEligibilityAPI;

    veipDefaultEligibilityUrl = veipDefaultEligibilityUrl.replace("[param1]", OptNum);
    // esppEnrollDetailsUrl = esppEnrollDetailsUrl.replace("[param2]", OfferNum);
    
    try {
      const api = apiUrl + veipDefaultEligibilityUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getVEIPTransactionDetails(OptNum, cyNumber): any {
    let apiUrl = this.globals.currentAPIUrl;
    let veipTransactionDetailsUrl = this.globals.getVEIPTransactionDetailsAPI;

    veipTransactionDetailsUrl = veipTransactionDetailsUrl.replace("[param1]", OptNum);
    veipTransactionDetailsUrl = veipTransactionDetailsUrl.replace("[param2]", cyNumber);
    // esppEnrollDetailsUrl = esppEnrollDetailsUrl.replace("[param2]", OfferNum);
    
    try {
      const api = apiUrl + veipTransactionDetailsUrl;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  getUserByOptNum(OptNum): any {
    let apiUrl = this.globals.currentAPIUrl;
    let getUserDetailsByOptNum = this.globals.getUserDetailsByOptNumAPI;

    getUserDetailsByOptNum = getUserDetailsByOptNum.replace("[param]", OptNum);
    // esppEnrollDetailsUrl = esppEnrollDetailsUrl.replace("[param2]", OfferNum);
    
    try {
      const api = apiUrl + getUserDetailsByOptNum;
      return this.httpClient.get(api).pipe(map(res => res), retry(3),
      catchError((res: Response) => this.onError(res)));
    } catch(err) {
      return err;
    }
  }

  sendMail(data){
    let apiURL = this.globals.currentAPIUrl;
    let sendmailPath = this.globals.sendMailAPI;

    sendmailPath = sendmailPath.replace("[param1]", data[0]);
    sendmailPath = sendmailPath.replace("[param2]", data[1]);
    sendmailPath = sendmailPath.replace("[param3]", data[2]);
    sendmailPath = sendmailPath.replace("[param4]", data[3]);
    sendmailPath = sendmailPath.replace("[param5]", data[4]);
    sendmailPath = sendmailPath.replace("[param6]", data[5]);
    sendmailPath = sendmailPath.replace("[param7]", data[6]);
    sendmailPath = sendmailPath.replace("[param8]", data[7]);
    sendmailPath = sendmailPath.replace("[param9]", data[8]);
    sendmailPath = sendmailPath.replace("[param10]",data[9]);
    sendmailPath = sendmailPath.replace("[param11]",data[10]);  //JMC, 06/28/2021 - Modified based on User Story 332873 - CRIL00051021173444 - Missing Enrollment Email Notifications
      
    try {
      const apiSendMail = apiURL + sendmailPath;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});
      let body = JSON.stringify({ data })

      return this.httpClient.post(apiSendMail, body);

    }catch(err) {
      return err;
    }
  }

  insertTransactionDetails(data: any){ //manualelection = 2
    let apiUrl = this.globals.currentAPIUrl;
    let insertVEIPTranDetails = this.globals.postVEIPTransactionDetails;
    
    insertVEIPTranDetails = insertVEIPTranDetails.replace("[param1]", data[0]);
    insertVEIPTranDetails = insertVEIPTranDetails.replace("[param3]", data[1]);
    insertVEIPTranDetails = insertVEIPTranDetails.replace("[param4]", data[2]);
    insertVEIPTranDetails = insertVEIPTranDetails.replace("[param5]", data[3]);
    insertVEIPTranDetails = insertVEIPTranDetails.replace("[param6]", data[4]);
    insertVEIPTranDetails = insertVEIPTranDetails.replace("[param7]", data[5]);
    insertVEIPTranDetails = insertVEIPTranDetails.replace("[param9]", data[6]);
    insertVEIPTranDetails = insertVEIPTranDetails.replace("[param10]", data[7]);
    // insertVEIPTranDetails = insertVEIPTranDetails.replace("[param9]", data[8]);
    // insertVEIPTranDetails = insertVEIPTranDetails.replace("[param10]", data[9]);
    insertVEIPTranDetails = insertVEIPTranDetails.replace("[param11]", "0");
    // insertVEIPTranDetails = insertVEIPTranDetails.replace("[param11]", "0"); //transact type waiting for BE
    
    try {
      const api = apiUrl + insertVEIPTranDetails;
      this.headers = new Headers({ 'Content-Type' : 'application/json', 'Authorization': `Bearer ${this.token}`});
      let options = ({ headers : this.headers});
      let body = JSON.stringify({ data })

      return this.httpClient.put(api, body).pipe(retry(3));
    } catch(err) {
      return err;
    }
  }

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
    };
    return observableThrowError(error);
  } 
}
