import { Injectable } from '@angular/core';

import { UserService } from './UserService';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { TokenService } from './TokenService';
import { MsalGuardService } from './msal/msal-guard.service'

@Injectable()
export class UserInfoDetailsResolve  {
  eid: any;
  optnum: any;
  
  constructor(private msalService: MsalGuardService, private token: TokenService, private userService: UserService, private globals: Globals, private location: Location, private accesstoken: TokenService) { }

  resolve(): Observable<any> {
    if(sessionStorage.getItem(this.globals.isUserInfoCached)) {      
      if(JSON.parse(sessionStorage.getItem('adminPageDataStore'))) {
        this.optnum = JSON.parse(sessionStorage.getItem(this.globals.sessionOptNum));
        return this.userService.getUserInfo(this.optnum);
      } 
      else{ 
        if(sessionStorage.getItem("GDPRdisagree") == "fromLandingPage"){
          this.optnum = JSON.parse(sessionStorage.getItem(this.globals.sessionOptNum));
          
          return this.userService.getUserInfo(this.optnum);
        
        }else
          return JSON.parse(sessionStorage.getItem(this.globals.profileInfoKey));
      }
    } else {
      const webUrl = this.globals.currentWebUrl; 
      this.token.getToken();  
      this.optnum = JSON.parse(sessionStorage.getItem(this.globals.sessionOptNum));
      var userinfo =  this.userService.getUserInfo(this.optnum);
      /* 
      window.addEventListener('popstate', function(event) {
          window.location.href = webUrl + '/homepage';
      }); 
      */
      return userinfo;
    }
  }
}