
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators'; 

@Injectable()
export class KeyExecToggleService {

  public onError(res: Response) {
    const statusCode = res.status;
    const body = res.body;
    const error = {
      statusCode: statusCode,
      error: res
      };
      return observableThrowError(error);
  }

  constructor(private globals: Globals, private httpClient: HttpClient) { }
  
  getKeyExecToggle(): any{
    let apiUrl = this.globals.currentAPIUrl;
    let getKeyExecToogleAPI = this.globals.getkeyExecToggleAPI;

    try {
      const api = apiUrl + getKeyExecToogleAPI;
      return this.httpClient.get(api).pipe(map(res=> res), retry(3),
      catchError((res: Response) => this.onError(res)));
    }
    catch(err){
      return err;
    }
  }
}
